import { Button, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CourseSimilar from "../components/Course Details/CourseSimilar";
import MainBanner5 from "../components/Main/MainBanner5";
import { ReadMore } from "../components/ReadMore";
import ReviewRatingsDisplay from "../components/Reviews/ReviewRatingsDisplay";
import { api as apiproto } from "../apiproto";
import Layout from "../layout/Layout";
import PageTitle from "../layout/PageTitle";
import { calculateUserRatings, checkIfTopicProfile, generateProfileTopicURL, generateSEOTitle, getTopicShareStatus, getUserPhotoURL, PROFILE_NO_PHOTO } from "../utils/eduutils";
import { useAPI } from "../utils/useAPI";
import { useUser } from "../utils/useUser";
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

type PersonParams = {
  seotitle: string,
  id: string
}

const PersonProfile = () => {

  const api = useAPI();
  const user = useUser();
  const personparams = useParams<PersonParams>();
  const [bookSessionPublic, setBookSessionPublic] = useState(false); // If profile topic is not public do not show book session.
  const [invalidUser, setInvalidUser] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (personparams.id && !personparams.id.startsWith("u_")) {
        try {
          // This could be a bitly link      
          const result = await api.lookupTinyCode(personparams.id);
          if (result && result.objectId) {
            const redirect = '/topic/' + generateSEOTitle(result.name ? result.name : 't') + '/c_' + window.encodeURIComponent(result.objectId!) + window.location.search;
            window.location.href = redirect;
          }
          else {
            setInvalidUser(true);
          }
          setIsLoading(false);
          return;
        }
        catch (err: any) {
          // That means user has not set to public , so unable to find.
          if (err.name === 'INVALID_TINY_CODE') {
          }
          setInvalidUser(true);
        }

      }
      try {
        const profiletopicId = "c_" + personparams.id;
        const topicInfo = await api.getTopic({ includeDescription: false, members: { entity: { user: { includeDescription: false } } } }, profiletopicId);
        if (topicInfo && topicInfo !== null && topicInfo.topic && topicInfo !== null) {
          if (getTopicShareStatus(topicInfo.topic) !== "private") {
            setBookSessionPublic(true);
          }
        }
      }
      catch (err: any) {
        // That means user has not set to public , so unable to find.
        //        tdrAlert(err.name + " " + err.description);
        setInvalidUser(true);
      }

      getUserProfile().then(userinfo => {
        if (userinfo) {
          getSessionsByUser(userinfo);
        }
      })
    })();
  }, [personparams.id]);

  const [fullDescription, setFullDescription] = useState({ about_me: "" })
  const [profileState, setProfileState] = useState<apiproto.IUser>({ lastName: "", firstName: "", description: "{\"about_me\": \"\"}" });
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [similarCourses, setSimilarCourses] = useState<apiproto.ITopic[] | null>(null);
  const [userRatingsCount, setUserRatingsCount] = useState([0, 0, 0, 0, 0, 0]);
  const [averageScore, setAverageScore] = useState(0);
  const navigate = useNavigate();

  const getUserProfile = async () => {
    try {
      const userResult = await api.getUser({ includeDescription: true, reviewsOfUser: {}, reviewsByUser: {}, assets: {} }, { userId: personparams.id });
      if (userResult && userResult.user) {
        const userInfo = userResult.user;
        setProfileState(userInfo);
        try {
          var tmpdesc = JSON.parse(userInfo.description!);
          setFullDescription(tmpdesc);
        }
        catch (err: any) {
          // No description written yet;
        }

        setImageUrl(getUserPhotoURL(userInfo));
        setIsLoading(false);
        return userInfo;
      }
    }
    catch (err: any) {
      // We don't throw this error back since the IDs may have been deleted for some reason
      // and this not necessarily a fatal error.
    }
    setIsLoading(false);
    return null;

  }
  const getSessionsByUser = async (profileuser: apiproto.IUser) => {
    const courses: apiproto.ITopic[] = [];

    if (user.userId === profileuser.userId) {
      // We use this if the user is the login user , so they can see all their sesssions indepedent of whether they are private, public, restricted
      const userInfoResult = await api.getUser({ assets: {}, membersOf: { ownerFilter: { filterValue: true }, instructorFilter: { filterValue: true }, obj: { topic: { includeDescription: true, assets: {}, tags: {}, members: { entity: { user: { assets: {} } } }, rooms: { scheduleSet: { schedules: { times: {} } } } } } } }, { userId: profileuser.userId });
      userInfoResult.user?.membersOf?.forEach((m) => {
        if (m.obj?.topic?.topic && !checkIfTopicProfile(m.obj.topic.topicId)) {
          courses.push(m.obj.topic.topic)
        }
      })
      if (courses) {
        setSimilarCourses(courses);
      }
    }
    else {
      // If user is not the same as topic owner, then we only show the topics that are made public
      const allResults = await api.findTopics({ includeDescription: true, reviews: {}, members: { entity: { user: { includeDescription: false, assets: {} } } }, rooms: { scheduleSet: { schedules: { times: {} } } }, tags: {}, assets: {} }, { instructorUserId: profileuser.userId! }, 100);
      if (allResults.topics) {
        var coursestmp = allResults.topics;
        // We filter all topics that is not a profile type topic
        const courses = coursestmp.filter((m) => !checkIfTopicProfile(m.topicId));
        setSimilarCourses(courses);
        // We now have to add up the rating
      }
    }
    if (courses && courses.length > 0) {
      const userRatings = calculateUserRatings(courses);
      setUserRatingsCount(userRatings.userRatingsCount);
      setAverageScore(userRatings.averageScore);
    }
  }

  const goProfileTopic = () => {
    if (profileState !== null) {
      navigate(generateProfileTopicURL(profileState.firstName!, profileState.lastName!, profileState.userId!))
    }
  }

  const updateSchedule = () => {
    navigate('/createtopic/' + window.encodeURIComponent("c_" + profileState.userId));
  }
  const updateProfile = () => {
    navigate('/profile');
  }

  return (
    <Layout headerBtn footer={2}>
      <div className="inner-page-wrapper">
        <PageTitle
          motherTitle="Profile"
          url="person-profile"
          pageTitle={profileState.firstName + ' ' + profileState.lastName + ' | Timedora'}
        />

        <div className=" divpadding">
          <div className="row">
            {/* Person PHOTO */}
            <div className="col-md-4 border">
              <div className="team-3-photo text-center divpadding">
                {isLoading ?
                  <Skeleton variant="rectangular" sx={{ width: "100%" }} >
                    <img
                      className="img-fluid-small"
                      src={PROFILE_NO_PHOTO}
                      alt="team-member-foto"
                    />
                  </Skeleton>
                  :
                  <div>
                    <img
                      className="img-fluid-portrait-medium"
                      src={((imageUrl && imageUrl.length > 0) ? imageUrl : PROFILE_NO_PHOTO)}
                      alt={profileState.firstName + ' ' + profileState.lastName}
                    />
                    {user.userId === personparams.id && imageUrl.length === 0 ?
                      <Link to={'/profile'}>Upload your photo. Build your profile.</Link> : ""}
                    <br /><br />
                    {(user.userId !== personparams.id) ?
                      <Button variant="text"
                        sx={{ width: 180, height: 40, marginTop: 2 }}
                        startIcon={<DateRangeIcon />}
                        size="small"
                        hidden={!bookSessionPublic}
                        onClick={goProfileTopic}>
                        Book Session
                      </Button> :
                      <Button variant="text"
                        sx={{ width: 180, height: 40, marginTop: 2 }}
                        startIcon={<DateRangeIcon />}
                        size="small"
                        onClick={goProfileTopic}>
                        View Session Page
                      </Button>
                    }
                    {(personparams.id === user.userId && user.authenticated) ?
                      <div>
                        <Button sx={{ width: 180, height: 40, marginTop: 2 }} variant="text" size="small" color="primary" component="span" startIcon={<AccountBoxIcon />} onClick={updateProfile}>
                          Update Profile
                        </Button>
                        <Button sx={{ width: 180, height: 40, marginTop: 2 }} variant="text" hidden={true} size="small" color="primary" component="span" startIcon={<DateRangeIcon />} onClick={updateSchedule}>
                          Set Schedule
                        </Button>
                      </div>
                      : ""}

                  </div>
                }
                {/* Social Icons 
                <div className="tm-3-social clearfix text-center">
                  <ul className="text-center clearfix">
                    <li>
                      <Link to="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="ico-facebook"
                      >
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="ico-twitter"
                      >
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="ico-google-plus"
                      >
                        <i className="fab fa-google-plus-g" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="ico-linkedin"
                      >
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                  </ul>
                </div>
                */}
                {/* Links */}
                {/*
                <div className="t-3-links">
                  <Link to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="btn btn-md btn-tra-grey rose-hover"
                  >
                    Website
                  </Link>
                  <Link to="mailto:yourdomain@mail.com"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="btn btn-md btn-tra-grey rose-hover"
                  >
                    hello@yourdomain.com
                  </Link>
                  </div> */}
              </div>
            </div>
            {/* END TEACHER PHOTO */}
            {/* TEACHER DATA */}
            <div className="col-md-8">
              {(isLoading) ?
                <div>
                  <Skeleton variant="rectangular" sx={{ width: "60%" }} height={30} />
                  <br />
                  <Skeleton variant="rectangular" sx={{ width: "90%" }} height={230} />
                  <br />
                  <Skeleton variant="rectangular" sx={{ width: "90%" }} height={230} />
                  <br />
                  <Skeleton variant="rectangular" sx={{ width: "90%" }} height={230} />
                </div>
                :
                <div className="team-3-txt pc-45">
                  {/* Name */}
                  <h3 className="h3-xs">{profileState.firstName + ' ' + profileState.lastName}</h3>
                  {/* Maybe when we add something here <span>IT &amp; Software Courses</span> */}
                  {/* Data */}
                  <div className="teacher-data">
                    {(similarCourses && similarCourses?.length > 0) ? similarCourses.length + ' ' + (similarCourses.length === 1 ? "Course • " : "Courses • ") : ""}
                    {profileState.reviewsOfUser ?
                      <ReviewRatingsDisplay ratingsAverage={averageScore} ratingsCount={userRatingsCount} reviews={[]} bar={false} /> :
                      ""}
                  </div>
                  {/* Small Title */}
                  {fullDescription.about_me.length === 0 ? <div>
                    {invalidUser ? 'Unable to find user profile. User profile does not exists or it is set to private. Please make sure you have the correct link.' : 'No profile description.'}
                  </div> :
                    <div className="newline">
                      <h5 className="h5-xl mt-40">About</h5>
                      {/* Text */}
                      <ReadMore min={800} ideal={900} max={1000}>
                        {fullDescription.about_me}
                      </ReadMore>
                    </div>}
                  {(user.authenticated && user.userId === personparams.id && fullDescription.about_me.length === 0) ?
                    <Link to={'/profile'}>Build your profile by sharing a description of your background.</Link> : ""}

                </div>
              }
            </div>
          </div>
        </div>
        <hr className="mb-20" />
        {(similarCourses !== null && similarCourses?.length > 0) &&
          <CourseSimilar courses={similarCourses} user={profileState} />
        }
        <div className="container">
          {profileState.reviewsOfUser && profileState.reviewsOfUser.length > 0 ?
            <div>
              <h4 className="h4-xl">Reviews</h4>
              <ReviewRatingsDisplay ratingsAverage={averageScore} ratingsCount={userRatingsCount} reviews={profileState.reviewsOfUser} bar={true} /></div> :
            <div className="text-center"><br /><br /><br /><br /><h3>No reviews yet</h3><br /><br /><br /><br /> </div>}
          {/* No need to show banner if user is already signed up */}
        </div>
        <hr />
        {user.authenticated ? "" : <MainBanner5 />}
      </div>
    </Layout>
  );
};

export default PersonProfile;
