import React, { Dispatch } from "react";
import internal from "stream";
import { VideoSource } from "./ConferenceTypes";

export enum LayoutType {
    Auto = 1,
    Presenter = 2,
    Grid = 3
}

export interface DisplayOptions {
    selectedParticipant: string | null;
    selectedVideoSource: VideoSource;
    stageLayout: LayoutType;
    connectState: boolean;
    cameraEnabled: boolean;
    microphoneEnabled: boolean;
    videoDeviceId: string | null;
    audioDeviceId: string | null;
    videoDevices: number;
    /** display debugging stats */
    showStats: boolean;

}

export interface DisplayContextActionType {
    type: "setLayout" | "setStat" | "setSelectedParticipant" | "setSource" | "setConnectState" | "setCameraEnabled" | "setVideoDeviceId" | "setMicrophoneEnabled" | "setAudioDeviceId" | "setVideoDevices";
    payload?: {
        newLayout?: LayoutType;
        newStat?: boolean;
        newParticipant?: string | null;
        newSource?: VideoSource;
        newConnectState?: boolean;
        newCameraEnabled?: boolean;
        newVideoDeviceId?: string;
        newMicrophoneEnabled?: boolean;
        newAudioDeviceId?: string;
        newVideoDevices?: number;
    }
}

export const DisplayContext = React.createContext<{ displayOptions: DisplayOptions, displayOptionsDispatch: Dispatch<DisplayContextActionType> }>({
    displayOptions: {
        selectedParticipant: null,
        selectedVideoSource: VideoSource.Auto,
        stageLayout: LayoutType.Auto,
        showStats: false,
        connectState: false,
        cameraEnabled: false,
        videoDeviceId: null,
        microphoneEnabled: false,
        audioDeviceId: null,
        videoDevices: 0
    }, displayOptionsDispatch: () => { }
});