
import React from "react";
import PageTitle from "../layout/PageTitle";

const Terms = () => {
    return (
        <div className="container">
            <PageTitle pageTitle="Timedora -Terms of Use" />

            <h1>Terms of Use</h1>
            <br />
            Effective date: Nov 1st, 2022.
            <br /><br />
            By using our provided services and software, you are agreeing to our terms and conditions detailed below. If you disagree or do not understand any of the terms, you should immediately stop using our services.<br />
            ‍Following is an agreement contract between you (the "User", "You") and Timedora (the "Service Provider", "We").
            <br /><br />
            <h3>Services & Agreement</h3>
            Timedora (the "Services") is a calendering, marketing and productivity tool that automates appointment booking, marketing of services, payments, session management, client management and other services for the service provider and the service economy.

            The Terms of Service (the "Terms", defined in https://timedora.com/terms) govern your use of our Services. Your right to access and use the Services is contingent upon your access and use of the Services in accordance with these Terms.

            Timedora may make changes to the Terms from time to time with or without informing you. You may reject the changes by stopping the user of our service or unsubscribing if you are a premium user. You understand and agree that if You use the Service after the date on which the Terms have changed, We will treat Your use as acceptance of the updated Terms.
            <br /><br />

            <h3>User Privacy</h3>
            We take your privacy very seriously. We will exercise commercially reasonable efforts to protect and prevent any unauthorized access to any information collected from our users. We are not in the business of renting nor selling your personal information in personally identifiable form to anyone. From time to time, we may use ads via third party providers such as Google or others which may make use of some information such as cookies.
            <br /><br />
            <h3>Service Usage Restrictions</h3>
            You may not use the Services in a way that violates any law or regulation. This includes any use that is considered  fraudulent, deceptive, harmful, threatening, harassing, defamatory, obscene, or otherwise objectionable.<br />
            You will not use our software and Services to engage in any activites or communication that involves criminal acts, sex, hate, alcohol, firearms, drugs and tobacco.
            <br /><br />
            Decompiling code, reverse engineering code, or otherwise attempt to obtain the source code or underlying ideas or information of or relating to the Services.
            <br /><br />
            You may not use the Services in a way that generates unreasonable number of email bounces, unacceptable number of spam or UCE complaints (even if the messages themselves are not actually spam or UCE), or use our Services or APIs in a way that generates unreasonable load on our infrastructure.
            <br /><br />
            We may seek legal action against any user who violates our Service Usage Restriction.
            <br /><br />
            <h2>Fees for Use of the Service</h2>
            We may provide the Service to You for free, with certain trial periods or with certain limits on use or charge you for premium features.
            <br />
            If you choose to subscribe to our premium services, we will charge Your credit card on a monthly or annual basis. Payments are non-refundable. There will be no refunds or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open account.
            <br />
            All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and You shall be responsible for payment of all such taxes, levies, or duties.
            <br />
            You acknowledge and agree that any credit card and related billing and payment information that You provide to Timedora or its providers may be shared by Timedora with companies who work on Timedora's behalf, such as payment processors and/or credit agencies, solely for the purposes of checking credit, effecting payment to Timedora and servicing Your account.
            <br /><br />
            <h2>Cancellation & Termination</h2>
            You may cancel Your premium plans at anytime by unsubscribing if you are a premium user. You understand that solely stopping, disabling, uninstalling, , or by forgoing the use of the Services will not be considered a cancellation of your subscription.
            <br />
            You will not receive any refunds or pro-rated refunds if You cancel Your account.
            If You cancel the Service before the end of Your current paid up month, You will not be charged again and Your cancellation will take effect immediately.
            <br />
            You agree that Timedora, in its sole discretion and for any or no reason, may terminate or suspend Your account. You agree that any termination of Your access to the Service may be without prior notice, and You agree that Timedora will not be liable to You or any third party for such termination.
            <br /><br />
            <h2>Warranty</h2>
            Service Provider disclaims to the fullest extent authorized by law any and all warranties, whether express or implied, including, without limitation. Service Provider expressly does not warrant that: (a) the software will meet your requirements or expectations; (b) the software or the software content will be free of bugs, errors, or other defects; (c) any results, output, or data provided through or generated by the software will be accurate, up-to-date, complete or reliable; (d) the software will be compatible with third party software; (e) any errors in the software will be corrected.
            <br /><br />
            <h2>Indemnification</h2>
            The User, at its expense, shall indemnify, defend and hold the Service Provider and affiliates harmless from and against any and all liability, damages, injuries, losses, costs and expenses (including attorney’s fees) arising out of or relating to User’s use of the Service, including but not limited to liability, damages, injuries, losses, costs and expenses arising from any claims relating to User’s breach of any representations, warranties, or covenants in this agreement.
            <br /><br />
            <h2>Liability for our Services</h2>
            When permitted by law, the Service Provider will not be responsible for lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages. To the extent permitted by law, the total liability of Service Provider for any claims under these terms, including for any implied warranties, is limited to the amount you paid us to use the Services in the thirty (30) days prior to the accrual of the applicable claim. In all cases, the Service Provider will not be liable for any loss or damage that is not reasonably foreseeable.
            <br /><br />
            <h2>Contact and Questions</h2>
            If you have any questions about this privacy statement or questions about your account, don't hesitate to contact us on support at timedora.com.</div>);
};

export default Terms