import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Box, FormControl, FormControlLabel, IconButton, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ProductCheckout from "../account/productCheckout";
import { ValidatorForm } from "react-material-ui-form-validator";
import { getNumberWithSuffix, getTimeZone } from "../../utils/eduutils";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { tdrAlert } from "../../utils/utils";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as apiproto from "../../apiproto";
import { DialogMessageBox } from "../../components/DialogMessageBox";

type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};
const ProductPurchase = () => {
    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();
    const [search] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [stripeKeys, setStripeKeys] = useState<StripeKeys>({ clientSecret: "", publishableKey: "" });
    const [existingPayment, setExistingPayment] = useState("");
    const [selectedPlan, setSelectedPlan] = useState({ name: "Pro", monthly: 15, yearly: 12, total: 0, monthlyStr: "USD$15", yearlyInMonthStr: "USD$12", yearlyStr: "USD$144", totalStr: "$0" });
    const [renewalDateStr, setRenewalDateStr] = useState({ monthly: '', yearly: '' });
    const [license, setLicense] = useState("1");
    const [licenseNum, setLicenseNum] = useState(1);

    const billType = new URLSearchParams(search).get('type');
    const planName = new URLSearchParams(search).get("id");
    const [billingCycleRadioValue, setBillingCycleRadioValue] = useState(billType !== null ? billType : "monthly")
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [currentPlan, setCurrentPlan] = useState({ planType: "Free", billing: "", autorenew: false });


    const handleBillingCycleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBillingCycleRadioValue((event.target as HTMLInputElement).value);
    };
    const paymentSuccess = () => {
        (async () => {
            var serviceSubscriptionType: apiproto.api.ServiceSubscriptionType = apiproto.api.ServiceSubscriptionType.ServiceTypeFree;
            var serviceSubscriptionInterval: apiproto.api.ServiceSubscriptionInterval = apiproto.api.ServiceSubscriptionInterval.ServiceIntervalNone;
            try {
                // Type of plans
                if (selectedPlan.name === 'Pro') {
                    serviceSubscriptionType = apiproto.api.ServiceSubscriptionType.ServiceTypeGold;
                }
                else if (selectedPlan.name === 'Individual') {
                    serviceSubscriptionType = apiproto.api.ServiceSubscriptionType.ServiceTypeSilver;
                }
                // Recurrence type
                if (billingCycleRadioValue === 'monthly') {
                    serviceSubscriptionInterval = apiproto.api.ServiceSubscriptionInterval.ServiceIntervalMonthly;
                } else if (billingCycleRadioValue === 'yearly') {
                    serviceSubscriptionInterval = apiproto.api.ServiceSubscriptionInterval.ServiceIntervalYearly;
                }
                const result = await api.updateServiceSubscription(serviceSubscriptionType, serviceSubscriptionInterval)
                // Success here if no error caught.
                if (result !== null) {
                    setDialogMessage({ title: 'Plan purchased', message: "You have successfully purchased your selected plan.", meta: {}, confirm: false });
                }
                else {
                    setDialogMessage({ title: 'Plan purchase error', message: "An error may have occurred during the purchase of your selected plan.", meta: {}, confirm: false });
                }
            } catch (e: any) {
                setDialogMessage({ title: 'Plan purchase error', message: "An error occurred during the purchase of your selected plan.", meta: {}, confirm: false });
                tdrAlert("Error upgrading user " + e.name + " " + e.description);
            }
        })();
    }

    const paymentCancelled = () => {
        closeWindow();
    }

    const closeWindow = () => {
        navigate("/pricing");
    }

    const setupPayment = async (free: boolean) => {
        try {
            if (user !== null && user.authenticated) {
                // Only need to set up if user is authenticated
                const result = await api.updatePayment()
                if (result !== null && result.publishableKey && result.publishableKey?.length > 0) {
                    const stripeKeysResult = { clientSecret: result.paymentClientSecret!, publishableKey: result.publishableKey! };
                    setStripeKeys(stripeKeysResult)
                    if (result.paymentClientSecret && result.paymentClientSecret.length > 0 && stripeKeysResult.publishableKey.length > 0) {
                        setStripePromise(loadStripe
                            (stripeKeysResult.publishableKey!, {
                            }));
                    }
                    if (result !== null && result.existingPaymentDescription && result.existingPaymentDescription.length > 0) {
                        setExistingPayment(result.existingPaymentDescription);
                    }
                    else {
                        setExistingPayment("");
                    }
                }
            }
        }
        catch (err: any) {
        }
    }

    const handleIncrement = () => {
        const tmp = parseInt(license)
        if (tmp < 100)
            changeLicense((tmp + 1) + "");
    }
    const handleDecrement = () => {
        const tmp = parseInt(license)
        if (tmp > 1)
            changeLicense((tmp - 1) + "");
    }

    const changeLicense = (value: string) => {
        try {
            if (license.length > 3) {
                return;
            }
            if (value === "") {
                setLicense("");
            }
            const value1 = parseInt(value);
            if (!isNaN(value1)) {
                setLicense(value1 + "");
            }
        }
        catch (err: any) {
            tdrAlert('herel')
        }
    }

    useEffect(() => {
        if (user.authenticated) {
            setErrorMessage("");
            setupPayment(false);

            (async () => {
                const userInfo = await api.getUser({ serviceSubscription: {} }, { userId: user.userId });
                let plantype = "Free";
                let billtype = "";
                let autorenew = false;
                if (userInfo.user?.serviceSubscriptionType === apiproto.api.ServiceSubscriptionType.ServiceTypeGold) {
                    plantype = "Pro";
                }
                else if (userInfo.user?.serviceSubscriptionType === apiproto.api.ServiceSubscriptionType.ServiceTypeSilver) {
                    plantype = "Individual"
                }
                if (userInfo.user?.serviceSubscriptionInterval === apiproto.api.ServiceSubscriptionInterval.ServiceIntervalMonthly) {
                    billtype = "monthly";
                    // Already monthly, so not possible to do yearly
                    setBillingCycleRadioValue("yearly");
                }
                else if (userInfo.user?.serviceSubscriptionInterval === apiproto.api.ServiceSubscriptionInterval.ServiceIntervalYearly) {
                    billtype = "yearly";
                    // Already yearly, so not possible to do monthly
                    setBillingCycleRadioValue("monthly");
                }

                console.log('existing subscriptions:');
                if (userInfo.user?.serviceSubscriptions) {
                    for (const ss of userInfo.user?.serviceSubscriptions) {
                        console.log('subscription level:' + ss.serviceSubscriptionType + ',interval:' + ss.serviceSubscriptionInterval + ',start:' + ss.startDate + ',cancelDate:' + ss.cancelDate);
                        if (ss.autoRenew) {
                            autorenew = true;
                        }
                    }
                }
                setCurrentPlan({ ...currentPlan, billing: billtype, planType: plantype, autorenew: autorenew })
            })();
        }

    }, [user.authenticated]);

    useEffect(() => {

        var theplan = { name: "Pro", monthly: 10, yearly: 8, total: 0, monthlyStr: "USD$10", yearlyInMonthStr: "USD$8", yearlyStr: "USD$96", totalStr: "$0" };
        if (planName === "individual") {
            theplan = { name: "Individual", monthly: 5, yearly: 4, total: 0, monthlyStr: "USD$5", yearlyInMonthStr: "USD$4", yearlyStr: "USD$48", totalStr: "$0" }

        }
        let tmpLicenseNum = parseInt(license);
        if (isNaN(tmpLicenseNum) || license.length === 0) {
            tmpLicenseNum = 1;
        }
        setLicenseNum(tmpLicenseNum);
        var monthlyprice = theplan.monthly.toLocaleString("en-US", { style: "currency", currency: "USD" });
        const yearlypriceInMonth = theplan.yearly.toLocaleString("en-US", { style: "currency", currency: "USD" });
        const tmpyearly = theplan.yearly * 12;
        const yearly = tmpyearly.toLocaleString("en-US", { style: "currency", currency: "USD" });

        let total = +theplan.yearly * 12 * tmpLicenseNum;
        if (billingCycleRadioValue === "monthly") {
            total = +theplan.monthly * tmpLicenseNum;
        }
        const totalStr = total.toLocaleString("en-US", { style: "currency", currency: "USD" });
        theplan.totalStr = totalStr;
        theplan.total = total;
        setSelectedPlan(theplan)

        // Get the current date
        const currentDate = new Date();
        // Extract the month and date from the current date
        const month = currentDate.toLocaleString('en-us', { timeZone: getTimeZone(), month: 'short' }); // returns the short form of the month (e.g., "Jan", "Feb", etc.)
        const date = currentDate.getDate(); // returns a number representing the date of the month
        const dateStr = getNumberWithSuffix(date);
        setRenewalDateStr({ monthly: dateStr, yearly: month + ' ' + date });

    }, [license, billingCycleRadioValue, planName]);

    const confirmWindow = () => {

    }

    const handleSubmit = () => {

    }

    return (
        <div>
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={closeWindow} onConfirm={confirmWindow} visible={dialogMessage.title.length > 0} />

            <RequireAuth>
                <ValidatorForm id="payment-form" onSubmit={handleSubmit}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="800"
                        padding={2}
                    >
                        <div className="form-group">
                            <br />
                            <Paper elevation={6} sx={{ maxWidth: 1000 }}>
                                <Row style={{ padding: 20 }}>
                                    <Col style={{ padding: 40 }} md={6}>
                                        <Row>
                                            <Col lg="12">
                                                <h3>{selectedPlan.name} Plan Subscription</h3><br />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <b>Choose billing cycle</b>
                                                <br />
                                                <FormControl>
                                                    <RadioGroup
                                                        aria-labelledby="billing radio group"
                                                        name="controlled-radio-buttons-group"
                                                        value={billingCycleRadioValue}
                                                        onChange={handleBillingCycleRadioChange}
                                                    >
                                                        <FormControlLabel disabled={currentPlan.billing === "yearly" && currentPlan.planType === selectedPlan.name && currentPlan.autorenew} value="yearly" control={<Radio />} label={"Yearly Billing - $" + selectedPlan.yearly + "/month"} />
                                                        <FormControlLabel disabled={currentPlan.billing === "monthly" && currentPlan.planType === selectedPlan.name && currentPlan.autorenew} value="monthly" control={<Radio />} label={"Monthly Billing - $" + selectedPlan.monthly + "/month"} />
                                                    </RadioGroup>
                                                </FormControl>
                                                <br />
                                                <br />
                                                <div style={{ maxWidth: '600px', minHeight: '80px' }}>
                                                    You will be charged {billingCycleRadioValue === "monthly" ? selectedPlan.totalStr + ' every month '
                                                        : selectedPlan.totalStr} on {billingCycleRadioValue === "monthly" ? renewalDateStr.monthly + ' of every month ' : renewalDateStr.yearly + ' every year'} unless you change or cancel the subscription.
                                                </div>
                                                <hr />
                                                <div className="divHide">
                                                    <b>Add licenses to your subscription</b>
                                                    <br /><br />
                                                    <TextField
                                                        label="License"
                                                        InputLabelProps={{ shrink: true }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <IconButton sx={{ width: '40px' }} onClick={handleDecrement}>
                                                                    <ArrowLeft sx={{ fontSize: "28px" }} />

                                                                </IconButton>
                                                            ),
                                                            endAdornment: (
                                                                <IconButton sx={{ width: '40px' }} onClick={handleIncrement}>
                                                                    <ArrowRight sx={{ fontSize: "28px" }} />
                                                                </IconButton>
                                                            ),
                                                            style: {
                                                                textAlign: 'center',
                                                                padding: '0px'
                                                            },
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                        style={{ width: '120px' }}
                                                        value={license}
                                                        onChange={event => changeLicense(event.target.value)}

                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    < Col style={{ border: '1px solid gray', padding: 40 }} md={6}>
                                        <Row>
                                            <Col>
                                                <div className="form-group form-input">
                                                    {errorMessage && (
                                                        <p className="form-error">
                                                            Error:{errorMessage}
                                                        </p>)
                                                    }
                                                    <Elements options={{
                                                        clientSecret: stripeKeys.clientSecret, appearance: {
                                                            theme: 'stripe', variables: {
                                                                colorPrimary: '#0570de',
                                                                colorBackground: '#ffffff',
                                                                colorText: '#30313d',
                                                                colorDanger: '#df1b41',
                                                                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                                                fontSizeBase: '18px',
                                                                spacingUnit: '4px',
                                                                borderRadius: '4px',
                                                                // See all possible variables below
                                                            }
                                                        }

                                                    }} stripe={stripePromise} key={stripeKeys.clientSecret}>
                                                        <ProductCheckout planSelected={selectedPlan} recur={billingCycleRadioValue} license={licenseNum} stripeKeys={stripeKeys} existingPayment={existingPayment} paymentCancel={paymentCancelled} paymentSuccess={paymentSuccess} />
                                                    </Elements>
                                                </div>

                                            </Col>
                                        </Row>
                                    </Col>


                                </Row>

                            </Paper>
                        </div>
                        <br /><br /><br />
                    </Box>
                </ValidatorForm>
            </RequireAuth>
        </div >
    )
}

export default ProductPurchase;
