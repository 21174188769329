import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Layout from "../../layout/Layout";
import { api as apiproto } from "../../apiproto";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { tdrLog } from "../../utils/utils";
import PageTitle from "../../layout/PageTitle";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { getAppIntegrationStatus } from "../../utils/eduutils";
import DialogMessageBox from "../../components/DialogMessageBox";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Integration = () => {
    return (<RequireAuth><GoogleOAuthProvider clientId="860747522814-a50s3geksanrc7ptngic24gjulgfn8q4.apps.googleusercontent.com"><AppsIntegration /></GoogleOAuthProvider></RequireAuth>);
}

const AppsIntegration = () => {
    const [snackMessage, setSnackMessage] = useState({ message: "", success: true });
    const [submitStatus, setSubmitStatus] = useState(false);
    const [appStatus, setAppStatus] = useState({ googleMeet: false, zoom: false, googleCalendar: false, stripe: false })
    const navigate = useNavigate();
    const api = useAPI();
    const user = useUser();
    const [searchParams] = useSearchParams();
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true, action: 0 });
    const [zoomMeta, setZoomMeta] = useState({ authCode: "", redirectUrl: "" });
    const [googleMeetMeta, setGoogleMeetMeta] = useState({ code: "" });

    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true, action: 0 });
    }

    const handleCancel = () => {
        if (dialogMessage.action === 1) {
            zoomConnected(false);
        }
        else if (dialogMessage.action === 2) {
            googleMeetIntegrated(false);
        }
        else if (dialogMessage.action === 3) {
            setSnackMessage({ message: "Zoom integration removed.", success: true });
        }
        else if (dialogMessage.action === 4) {
            setSnackMessage({ message: "Google Calendar & Meet integration removed.", success: true });
        }

        clearDialog();
    }

    const handleActionConfirm = () => {
        if (dialogMessage.action === 1) {
            zoomConnected(true);
        }
        else if (dialogMessage.action === 2) {
            googleMeetIntegrated(true);
        }
        else if (dialogMessage.action === 3) {
            // Nothing to do here since it is zoom remove and there is no confirm
        }
        else if (dialogMessage.action === 4) {
            // Nothing to do here since it is Google Meet remove and there is no confirm
        }
        clearDialog();
    }

    const getUserProfile = async () => {
        try {
            const userResult = await api.getUser({ integrations: {} }, { userId: user.userId });
            tdrLog(JSON.stringify(userResult) + ' ' + user.currentView)

            if (userResult && userResult.user && userResult.user !== null) {
                const userInfo = userResult.user;
                setAppStatus(getAppIntegrationStatus(userInfo));
            }
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
        }
    }

    const zoomRedirectUrl = 'https://' + process.env.REACT_APP_URL + '/integration?zoom=true';

    useEffect(() => {
        if (searchParams.get("zoom") === "true") {
            const t = async () => {
                // okay, this is a return from zoom integration
                const zoomAuthCode = searchParams.get("code")
                if (zoomAuthCode) {
                    setZoomMeta({ authCode: zoomAuthCode, redirectUrl: zoomRedirectUrl });
                    setDialogMessage({ title: 'Zoom Integrated', message: 'Zoom is successfully integrated. Do you want to us to automatically change all your existing topic locations from Timedora Video to use Zoom?', meta: {}, confirm: true, action: 1 });
                }
            }
            t();
        }
    }, [])


    useEffect(() => {
        getUserProfile();
    }, [user.userId]);

    const stripeConnect = () => {
        navigate("/setuppayment")
    }

    const googleMeetIntegrate = useGoogleLogin({
        scope: "https://www.googleapis.com/auth/calendar.events.owned",
        onSuccess: async (codeResponse) => {
            try {
                setSubmitStatus(true);
                setGoogleMeetMeta({ code: codeResponse.code });
                setDialogMessage({ title: 'Google Calendar & Meet Integrated', message: 'Google Calendar & Meet are successfully connected. Do you want to us to automatically change all your topic locations from Timedora video to use Google Meet?', meta: {}, confirm: true, action: 2 });
            } catch (err: any) {
                if (err.name === "INVALID_PERMISSIONS") {
                    setDialogMessage({ title: 'Google Calendar & Meet Permissions', message: 'You need to grant all the permissions for Google Calendar & Meet to successfully connect. Please consent and check all the permission boxes. Press Connect again to grant the missing permissions.', meta: {}, confirm: false, action: 0 });
                }
                else {
                    setDialogMessage({ title: 'Google Calendar & Meet Error', message: "Error adding Google Calendar & Meet" + (err as Error).message, meta: {}, confirm: false, action: 0 });
                }
            }
            getUserProfile();
            setSubmitStatus(false);
        },
        flow: 'auth-code',
        state: 'state-token',

    });

    const googleMeetIntegrated = async (changeRoom: boolean) => {
        try {
            setSubmitStatus(true);
            await api.addGoogleCalendarAndMeetIntegration(googleMeetMeta.code, false, true, changeRoom)
            await getUserProfile();
            // Success
            setSnackMessage({ message: "Google Calendar & Meet successfully integrated.", success: true });
        } catch (err: any) {
            if (err.name === "INVALID_PERMISSIONS") {
                setDialogMessage({ title: 'Google Calendar & Meet Permissions', message: 'You need to grant all the permissions for Google Calendar & Meet to successfully connect. Please consent and check all the permission boxes. Press Connect again to grant the missing permissions.', meta: {}, confirm: false, action: 0 });
            }
            else {
                setDialogMessage({ title: 'Google Calendar & Meet Error', message: "Error adding Google Calendar & Meet." + (err as Error).message, meta: {}, confirm: false, action: 0 });
            }
        }
        setSubmitStatus(false);
    };


    const googleMeetConnect = () => {
        if (submitStatus) {
            return;
        }
        if (appStatus.googleMeet) {
            googleMeetRemove();
        }
        else {
            googleMeetIntegrate();
        }
    }

    const googleMeetRemove = async () => {
        setSubmitStatus(true);
        try {
            await api.deleteIntegration(apiproto.IntegrationType.GoogleCalendarAndMeet);
            setDialogMessage({ title: 'Google Calendar & Meet Disconnected', message: 'Google Calendar & Meet are now disconnected. All meeting locations previously set to Google Meet will be changed to use Timedora video.', meta: {}, confirm: false, action: 4 });
        }
        catch (err: any) {
            setSnackMessage({ message: "Error removing Google Calendar & Meet." + (err as Error).message, success: false });
        }
        getUserProfile();
        setSubmitStatus(false);
    }

    const zoomConnect = () => {
        if (appStatus.zoom) {
            zoomRemove();
        }
        else {
            zoomIntegrate();
        }
    }

    const zoomConnected = async (changeRoom: boolean) => {
        try {
            await api.addZoomIntegration(zoomMeta.authCode, zoomMeta.redirectUrl, changeRoom);
            // Success
            setSnackMessage({ message: "Zoom successfully integrated.", success: true });
            // when everything is done, we need to navigate away so the zoom=true is true is not in the url
            await getUserProfile();
            navigate('/integration', { replace: true })

        } catch (err: any) {
            if (err.name === "INVALID_PERMISSIONS") {
                setDialogMessage({ title: 'Zoom Permissions', message: 'You need to grant all the permissions for Zoom to successfully connect. Please consent and check all the permission boxes. Press Connect again to grant the missing permissions.', meta: {}, confirm: false, action: 0 });
            }
            else {
                setDialogMessage({ title: 'Error adding Zoom', message: (err as Error).message, meta: {}, confirm: false, action: 0 });
            }
        }
    }

    const zoomRemove = async () => {
        setSubmitStatus(true);
        try {
            await api.deleteIntegration(apiproto.IntegrationType.Zoom);
            setDialogMessage({ title: 'Zoom Disconnected', message: 'Zoom integration is now disconnected. All meeting locations previously set to Zoom will be changed to use Timedora video.', meta: {}, confirm: false, action: 3 });
        }
        catch (err: any) {
            setSnackMessage({ message: "Error removing Zoom integration - " + (err as Error).message, success: false });
        }
        getUserProfile();
        setSubmitStatus(false);
    }

    const zoomIntegrate = () => {
        window.location.href = 'https://zoom.us/oauth/authorize?response_type=code&client_id=KXU7KFdaTaay_ztoxlDcZA&redirect_uri=' + encodeURIComponent(zoomRedirectUrl);
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        handleSnackClose();
    }

    const handleSnackClose = () => {
        setSnackMessage({ message: "", success: true });
    }

    return (

        <Layout headerBtn footer={3}>
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Yes" : ""} cancel={dialogMessage.confirm ? "No" : "Dismiss"} onCancel={handleCancel} onConfirm={handleActionConfirm} visible={dialogMessage.title.length > 0} />
            <Snackbar onClose={handleSnackClose} open={snackMessage.message.length > 0} autoHideDuration={5000}>
                <Alert onClose={handleClose} severity={snackMessage.success ? "success" : "error"} sx={{ width: '100%' }}>
                    {snackMessage.message} </Alert>
            </Snackbar>

            <PageTitle pageTitle="Apps Integration - Timedora" />
            <div className="app-page-wrapper divpadding">
                <section id="appintegration">
                    <div className="container">
                        <div className="divHide">
                            <h4>Calendar</h4>
                            <div className="row pricing-row">
                                <div className="col-md-4 col-lg-3">
                                    <div className="appintegration-table">
                                        <img className="appicon" alt="Google Calendar" src="images/apps/app_google_calendar_icon.png" /><br />
                                        <h4>Google Calendar + Meet</h4>
                                        <div className="appdesc">
                                            Sync events with Google Calendar when someone books or cancel a session.<br />
                                            <br />
                                        </div>
                                        <span className="appconnected"><br />
                                            {appStatus.googleCalendar ?
                                                'Connected' : ''}
                                            <br />
                                        </span>
                                        <br />
                                        {appStatus.googleCalendar ?
                                            <Button disabled={submitStatus} size="small" variant="contained" onClick={googleMeetConnect} sx={{
                                                ':hover': {
                                                    bgcolor: '#ff1f59', // theme.palette.primary.main
                                                    color: 'white',
                                                }, width: 140, height: 40, fontSize: '16px', backgroundColor: 'primary.main', textTransform: 'none'
                                            }}>
                                                Disconnect
                                            </Button> :
                                            <a href="#" onClick={googleMeetConnect} style={{ cursor: 'pointer' }}>
                                                <img alt="Google Sign in" style={{ cursor: "pointer", border: 0, width: '180px', height: '45px' }} src="/images/apps/btn_google_signin_dark_normal_web@2x.png" />
                                            </a>}
                                    </div>
                                </div>
                            </div>

                            <hr />
                        </div>
                        <h4>Video Conference</h4>
                        <div className="row pricing-row">
                            <div className="col-md-4 col-lg-3">
                                <div className="appintegration-table">
                                    <img className="appicon" alt="Google Meet" src="images/apps/app_google_meet_icon.png" />
                                    <h4>Google Calendar + Meet</h4>
                                    <div className="appdesc">
                                        Integrate Google Meet video conference into your sessions.<br />
                                    </div>
                                    <span className="appconnected"><br />
                                        {appStatus.googleMeet ?
                                            'Connected' : ''}
                                        <br />
                                    </span>
                                    <br />
                                    {appStatus.googleMeet ?
                                        <Button disabled={submitStatus} size="small" variant="contained" onClick={googleMeetConnect} sx={{
                                            ':hover': {
                                                bgcolor: '#ff1f59', // theme.palette.primary.main
                                                color: 'white',
                                            }, width: 140, height: 40, fontSize: '16px', backgroundColor: 'primary.main', textTransform: 'none'
                                        }}>
                                            Disconnect
                                        </Button> :
                                        <a href="#" onClick={googleMeetConnect} style={{ cursor: 'pointer' }}>
                                            <img alt="Google Sign in" style={{ cursor: "pointer", border: 0, width: '180px', height: '45px' }} src="/images/apps/btn_google_signin_dark_normal_web@2x.png" />
                                        </a>}
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="appintegration-table">
                                    <img className="appicon" alt="Zoom" src="images/apps/app_zoom_icon.png" />
                                    <h4>Zoom</h4>
                                    <div className="appdesc">
                                        Integrate Zoom video conference into your sessions.<br /><br />
                                    </div>
                                    <span className="appconnected"><br />
                                        {appStatus.zoom ?
                                            'Connected' : ''}
                                        <br />
                                    </span>
                                    <br />

                                    <Button disabled={submitStatus} size="small" variant="contained" onClick={zoomConnect} sx={{
                                        ':hover': {
                                            bgcolor: '#ff1f59', // theme.palette.primary.main
                                            color: 'white',
                                        }, width: 140, height: 40, fontSize: '16px', backgroundColor: 'primary.main', textTransform: 'none'
                                    }}>
                                        {appStatus.zoom ? "Disconnect" : "Connect"}

                                    </Button>
                                </div>
                            </div>

                        </div>
                        <hr />
                        <h4>Payments</h4>
                        <div className="row pricing-row">
                            <div className="col-md-4 col-lg-3">
                                <div className="appintegration-table">
                                    <img className="appicon" alt="Stripe" src="images/apps/app_stripe_icon.png" />
                                    <h4>Stripe</h4>
                                    <div className="appdesc">
                                        Integrate Stripe to accept payments and deposit earnings in your bank.<br /><br />
                                    </div>
                                    <span className="appconnected"><br />
                                        {appStatus.stripe ?
                                            'Connected' : ''}
                                        <br />
                                    </span>
                                    <br />

                                    <Button disabled={submitStatus} size="small" variant="contained" onClick={stripeConnect} sx={{
                                        ':hover': {
                                            bgcolor: '#ff1f59', // theme.palette.primary.main
                                            color: 'white',
                                        }, width: 140, height: 40, fontSize: '16px', backgroundColor: 'primary.main', textTransform: 'none'
                                    }}>
                                        {appStatus.stripe ? "Disconnect" : "Connect"}
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div >
        </Layout >
    );
};

export default Integration;
