import React from "react";

const Aboutservices5 = () => {
    return (
        <section id="services-5" className="wide-60 services-section division">
            <div className="container">
                {/* SECTION TITLE */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title title-centered mb-60">
                            {/* Title 	*/}
                            <h3 className="h3-sm">
                                Earn while sharing your knowlege live
                            </h3>

                            <p className="p-md">
                                Learn more about our tools and services.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* SERVICE BOX #1 */}
                    <div className="col-md-6 col-lg-4">
                        <div className="sbox-5">
                            {/* Icon */}
                            <img
                                className="img-70"
                                src="images/icons/education.png"
                                alt="service-icon"
                            />

                            <div className="sbox-5-txt">
                                <h5 className="h5-md">Schedule your time</h5>

                                <p className="grey-color">
                                    Create blocks of time for users to book to meet with you.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* END SERVICE BOX #1 */}
                    {/* SERVICE BOX #2 */}
                    <div className="col-md-6 col-lg-4">
                        <div className="sbox-5">
                            {/* Icon */}
                            <img
                                className="img-70"
                                src="images/icons/presentation.png"
                                alt="service-icon"
                            />

                            <div className="sbox-5-txt">
                                <h5 className="h5-md">Certified Professionals</h5>

                                <p className="grey-color">
                                    Join our professional community and create a knowledge economy.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* END SERVICE BOX #2 */}
                    {/* SERVICE BOX #3 */}
                    <div className="col-md-6 col-lg-4">
                        <div className="sbox-5">
                            {/* Icon */}
                            <img
                                className="img-70"
                                src="images/icons/certificate.png"
                                alt="service-icon"
                            />
                            {
                                <div className="sbox-5-txt">
                                    <h5 className="h5-md">
                                        Accept payments
                                    </h5>

                                    <p className="grey-color">
                                        Easily accept credit card payments from users.
                                    </p>
                                </div>}
                        </div>
                    </div>
                    {/* END SERVICE BOX #3 */}
                    {/* SERVICE BOX #4 */}
                    <div className="col-md-6 col-lg-4">
                        <div className="sbox-5">
                            {/* Icon */}
                            <img
                                className="img-70"
                                src="images/icons/elearning-1.png"
                                alt="service-icon"
                            />

                            <div className="sbox-5-txt">
                                <h5 className="h5-md">
                                    Powerful Video Conferencing tool
                                </h5>

                                <p className="grey-color">
                                    Integrated video conferencing solution for your live meetings.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* END SERVICE BOX #4 */}
                    {/* SERVICE BOX #5 */}
                    <div className="col-md-6 col-lg-4">
                        <div className="sbox-5">
                            {/* Icon */}
                            <img
                                className="img-70"
                                src="images/icons/reading.png"
                                alt="service-icon"
                            />

                            <div className="sbox-5-txt">
                                <h5 className="h5-md">
                                    No Hassle Billing
                                </h5>

                                <p className="grey-color">
                                    Users book your time and pay immediately. We handle billing for you.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* END SERVICE BOX #5 */}
                    {/* SERVICE BOX #6 */}
                    <div className="col-md-6 col-lg-4">
                        <div className="sbox-5">
                            {/* Icon */}
                            <img
                                className="img-70"
                                src="images/icons/bookshelf.png"
                                alt="service-icon"
                            />

                            <div className="sbox-5-txt">
                                <h5 className="h5-md">Get Discovered</h5>

                                <p className="grey-color">
                                    Your knowledge and time can now be discoverable by others searching for advice.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* END SERVICE BOX #6 */}
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default Aboutservices5;
