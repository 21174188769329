/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const api = $root.api = (() => {

    /**
     * Namespace api.
     * @exports api
     * @namespace
     */
    const api = {};

    api.DirectMessageOption = (function() {

        /**
         * Properties of a DirectMessageOption.
         * @memberof api
         * @interface IDirectMessageOption
         * @property {string|null} [targetUserId] DirectMessageOption targetUserId
         */

        /**
         * Constructs a new DirectMessageOption.
         * @memberof api
         * @classdesc Represents a DirectMessageOption.
         * @implements IDirectMessageOption
         * @constructor
         * @param {api.IDirectMessageOption=} [properties] Properties to set
         */
        function DirectMessageOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DirectMessageOption targetUserId.
         * @member {string} targetUserId
         * @memberof api.DirectMessageOption
         * @instance
         */
        DirectMessageOption.prototype.targetUserId = "";

        /**
         * Creates a new DirectMessageOption instance using the specified properties.
         * @function create
         * @memberof api.DirectMessageOption
         * @static
         * @param {api.IDirectMessageOption=} [properties] Properties to set
         * @returns {api.DirectMessageOption} DirectMessageOption instance
         */
        DirectMessageOption.create = function create(properties) {
            return new DirectMessageOption(properties);
        };

        /**
         * Encodes the specified DirectMessageOption message. Does not implicitly {@link api.DirectMessageOption.verify|verify} messages.
         * @function encode
         * @memberof api.DirectMessageOption
         * @static
         * @param {api.IDirectMessageOption} message DirectMessageOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DirectMessageOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.targetUserId != null && Object.hasOwnProperty.call(message, "targetUserId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.targetUserId);
            return writer;
        };

        /**
         * Encodes the specified DirectMessageOption message, length delimited. Does not implicitly {@link api.DirectMessageOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DirectMessageOption
         * @static
         * @param {api.IDirectMessageOption} message DirectMessageOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DirectMessageOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DirectMessageOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.DirectMessageOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DirectMessageOption} DirectMessageOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DirectMessageOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DirectMessageOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.targetUserId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DirectMessageOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DirectMessageOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DirectMessageOption} DirectMessageOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DirectMessageOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DirectMessageOption message.
         * @function verify
         * @memberof api.DirectMessageOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DirectMessageOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.targetUserId != null && message.hasOwnProperty("targetUserId"))
                if (!$util.isString(message.targetUserId))
                    return "targetUserId: string expected";
            return null;
        };

        /**
         * Creates a DirectMessageOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DirectMessageOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DirectMessageOption} DirectMessageOption
         */
        DirectMessageOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DirectMessageOption)
                return object;
            let message = new $root.api.DirectMessageOption();
            if (object.targetUserId != null)
                message.targetUserId = String(object.targetUserId);
            return message;
        };

        /**
         * Creates a plain object from a DirectMessageOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DirectMessageOption
         * @static
         * @param {api.DirectMessageOption} message DirectMessageOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DirectMessageOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.targetUserId = "";
            if (message.targetUserId != null && message.hasOwnProperty("targetUserId"))
                object.targetUserId = message.targetUserId;
            return object;
        };

        /**
         * Converts this DirectMessageOption to JSON.
         * @function toJSON
         * @memberof api.DirectMessageOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DirectMessageOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DirectMessageOption;
    })();

    api.ChatMessageEvent = (function() {

        /**
         * Properties of a ChatMessageEvent.
         * @memberof api
         * @interface IChatMessageEvent
         * @property {api.IRoomOption|null} [room] ChatMessageEvent room
         * @property {api.ITimeOption|null} [time] ChatMessageEvent time
         * @property {api.IDirectMessageOption|null} [directMessage] ChatMessageEvent directMessage
         * @property {string|null} [userId] ChatMessageEvent userId
         * @property {string|null} [message] ChatMessageEvent message
         */

        /**
         * Constructs a new ChatMessageEvent.
         * @memberof api
         * @classdesc Represents a ChatMessageEvent.
         * @implements IChatMessageEvent
         * @constructor
         * @param {api.IChatMessageEvent=} [properties] Properties to set
         */
        function ChatMessageEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChatMessageEvent room.
         * @member {api.IRoomOption|null|undefined} room
         * @memberof api.ChatMessageEvent
         * @instance
         */
        ChatMessageEvent.prototype.room = null;

        /**
         * ChatMessageEvent time.
         * @member {api.ITimeOption|null|undefined} time
         * @memberof api.ChatMessageEvent
         * @instance
         */
        ChatMessageEvent.prototype.time = null;

        /**
         * ChatMessageEvent directMessage.
         * @member {api.IDirectMessageOption|null|undefined} directMessage
         * @memberof api.ChatMessageEvent
         * @instance
         */
        ChatMessageEvent.prototype.directMessage = null;

        /**
         * ChatMessageEvent userId.
         * @member {string} userId
         * @memberof api.ChatMessageEvent
         * @instance
         */
        ChatMessageEvent.prototype.userId = "";

        /**
         * ChatMessageEvent message.
         * @member {string} message
         * @memberof api.ChatMessageEvent
         * @instance
         */
        ChatMessageEvent.prototype.message = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * ChatMessageEvent option.
         * @member {"room"|"time"|"directMessage"|undefined} option
         * @memberof api.ChatMessageEvent
         * @instance
         */
        Object.defineProperty(ChatMessageEvent.prototype, "option", {
            get: $util.oneOfGetter($oneOfFields = ["room", "time", "directMessage"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new ChatMessageEvent instance using the specified properties.
         * @function create
         * @memberof api.ChatMessageEvent
         * @static
         * @param {api.IChatMessageEvent=} [properties] Properties to set
         * @returns {api.ChatMessageEvent} ChatMessageEvent instance
         */
        ChatMessageEvent.create = function create(properties) {
            return new ChatMessageEvent(properties);
        };

        /**
         * Encodes the specified ChatMessageEvent message. Does not implicitly {@link api.ChatMessageEvent.verify|verify} messages.
         * @function encode
         * @memberof api.ChatMessageEvent
         * @static
         * @param {api.IChatMessageEvent} message ChatMessageEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatMessageEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.RoomOption.encode(message.room, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.TimeOption.encode(message.time, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.directMessage != null && Object.hasOwnProperty.call(message, "directMessage"))
                $root.api.DirectMessageOption.encode(message.directMessage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.userId);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified ChatMessageEvent message, length delimited. Does not implicitly {@link api.ChatMessageEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ChatMessageEvent
         * @static
         * @param {api.IChatMessageEvent} message ChatMessageEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChatMessageEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChatMessageEvent message from the specified reader or buffer.
         * @function decode
         * @memberof api.ChatMessageEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ChatMessageEvent} ChatMessageEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatMessageEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ChatMessageEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.room = $root.api.RoomOption.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.time = $root.api.TimeOption.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.directMessage = $root.api.DirectMessageOption.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.userId = reader.string();
                    break;
                case 5:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChatMessageEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ChatMessageEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ChatMessageEvent} ChatMessageEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChatMessageEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChatMessageEvent message.
         * @function verify
         * @memberof api.ChatMessageEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChatMessageEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.room != null && message.hasOwnProperty("room")) {
                properties.option = 1;
                {
                    let error = $root.api.RoomOption.verify(message.room);
                    if (error)
                        return "room." + error;
                }
            }
            if (message.time != null && message.hasOwnProperty("time")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.TimeOption.verify(message.time);
                    if (error)
                        return "time." + error;
                }
            }
            if (message.directMessage != null && message.hasOwnProperty("directMessage")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.DirectMessageOption.verify(message.directMessage);
                    if (error)
                        return "directMessage." + error;
                }
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a ChatMessageEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ChatMessageEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ChatMessageEvent} ChatMessageEvent
         */
        ChatMessageEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ChatMessageEvent)
                return object;
            let message = new $root.api.ChatMessageEvent();
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.ChatMessageEvent.room: object expected");
                message.room = $root.api.RoomOption.fromObject(object.room);
            }
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.ChatMessageEvent.time: object expected");
                message.time = $root.api.TimeOption.fromObject(object.time);
            }
            if (object.directMessage != null) {
                if (typeof object.directMessage !== "object")
                    throw TypeError(".api.ChatMessageEvent.directMessage: object expected");
                message.directMessage = $root.api.DirectMessageOption.fromObject(object.directMessage);
            }
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a ChatMessageEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ChatMessageEvent
         * @static
         * @param {api.ChatMessageEvent} message ChatMessageEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChatMessageEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.message = "";
            }
            if (message.room != null && message.hasOwnProperty("room")) {
                object.room = $root.api.RoomOption.toObject(message.room, options);
                if (options.oneofs)
                    object.option = "room";
            }
            if (message.time != null && message.hasOwnProperty("time")) {
                object.time = $root.api.TimeOption.toObject(message.time, options);
                if (options.oneofs)
                    object.option = "time";
            }
            if (message.directMessage != null && message.hasOwnProperty("directMessage")) {
                object.directMessage = $root.api.DirectMessageOption.toObject(message.directMessage, options);
                if (options.oneofs)
                    object.option = "directMessage";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this ChatMessageEvent to JSON.
         * @function toJSON
         * @memberof api.ChatMessageEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChatMessageEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChatMessageEvent;
    })();

    api.MemberEntity = (function() {

        /**
         * Properties of a MemberEntity.
         * @memberof api
         * @interface IMemberEntity
         * @property {api.IUserOption|null} [user] MemberEntity user
         * @property {api.IGroupOption|null} [group] MemberEntity group
         */

        /**
         * Constructs a new MemberEntity.
         * @memberof api
         * @classdesc Represents a MemberEntity.
         * @implements IMemberEntity
         * @constructor
         * @param {api.IMemberEntity=} [properties] Properties to set
         */
        function MemberEntity(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberEntity user.
         * @member {api.IUserOption|null|undefined} user
         * @memberof api.MemberEntity
         * @instance
         */
        MemberEntity.prototype.user = null;

        /**
         * MemberEntity group.
         * @member {api.IGroupOption|null|undefined} group
         * @memberof api.MemberEntity
         * @instance
         */
        MemberEntity.prototype.group = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MemberEntity option.
         * @member {"user"|"group"|undefined} option
         * @memberof api.MemberEntity
         * @instance
         */
        Object.defineProperty(MemberEntity.prototype, "option", {
            get: $util.oneOfGetter($oneOfFields = ["user", "group"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MemberEntity instance using the specified properties.
         * @function create
         * @memberof api.MemberEntity
         * @static
         * @param {api.IMemberEntity=} [properties] Properties to set
         * @returns {api.MemberEntity} MemberEntity instance
         */
        MemberEntity.create = function create(properties) {
            return new MemberEntity(properties);
        };

        /**
         * Encodes the specified MemberEntity message. Does not implicitly {@link api.MemberEntity.verify|verify} messages.
         * @function encode
         * @memberof api.MemberEntity
         * @static
         * @param {api.IMemberEntity} message MemberEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntity.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.UserOption.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupOption.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberEntity message, length delimited. Does not implicitly {@link api.MemberEntity.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberEntity
         * @static
         * @param {api.IMemberEntity} message MemberEntity message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntity.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberEntity message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberEntity} MemberEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntity.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberEntity();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.api.UserOption.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.group = $root.api.GroupOption.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberEntity message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberEntity
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberEntity} MemberEntity
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntity.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberEntity message.
         * @function verify
         * @memberof api.MemberEntity
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberEntity.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.user != null && message.hasOwnProperty("user")) {
                properties.option = 1;
                {
                    let error = $root.api.UserOption.verify(message.user);
                    if (error)
                        return "user." + error;
                }
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.GroupOption.verify(message.group);
                    if (error)
                        return "group." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MemberEntity message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberEntity
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberEntity} MemberEntity
         */
        MemberEntity.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberEntity)
                return object;
            let message = new $root.api.MemberEntity();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.MemberEntity.user: object expected");
                message.user = $root.api.UserOption.fromObject(object.user);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberEntity.group: object expected");
                message.group = $root.api.GroupOption.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberEntity message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberEntity
         * @static
         * @param {api.MemberEntity} message MemberEntity
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberEntity.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.user != null && message.hasOwnProperty("user")) {
                object.user = $root.api.UserOption.toObject(message.user, options);
                if (options.oneofs)
                    object.option = "user";
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                object.group = $root.api.GroupOption.toObject(message.group, options);
                if (options.oneofs)
                    object.option = "group";
            }
            return object;
        };

        /**
         * Converts this MemberEntity to JSON.
         * @function toJSON
         * @memberof api.MemberEntity
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberEntity.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberEntity;
    })();

    api.MemberObject = (function() {

        /**
         * Properties of a MemberObject.
         * @memberof api
         * @interface IMemberObject
         * @property {api.ITopicOption|null} [topic] MemberObject topic
         * @property {api.IRoomOption|null} [room] MemberObject room
         * @property {api.IGroupOption|null} [group] MemberObject group
         * @property {api.IScheduleSetOption|null} [scheduleSet] MemberObject scheduleSet
         * @property {api.ITimeOption|null} [time] MemberObject time
         * @property {api.ISyncOption|null} [sync] MemberObject sync
         */

        /**
         * Constructs a new MemberObject.
         * @memberof api
         * @classdesc Represents a MemberObject.
         * @implements IMemberObject
         * @constructor
         * @param {api.IMemberObject=} [properties] Properties to set
         */
        function MemberObject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberObject topic.
         * @member {api.ITopicOption|null|undefined} topic
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.topic = null;

        /**
         * MemberObject room.
         * @member {api.IRoomOption|null|undefined} room
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.room = null;

        /**
         * MemberObject group.
         * @member {api.IGroupOption|null|undefined} group
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.group = null;

        /**
         * MemberObject scheduleSet.
         * @member {api.IScheduleSetOption|null|undefined} scheduleSet
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.scheduleSet = null;

        /**
         * MemberObject time.
         * @member {api.ITimeOption|null|undefined} time
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.time = null;

        /**
         * MemberObject sync.
         * @member {api.ISyncOption|null|undefined} sync
         * @memberof api.MemberObject
         * @instance
         */
        MemberObject.prototype.sync = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MemberObject option.
         * @member {"topic"|"room"|"group"|"scheduleSet"|"time"|"sync"|undefined} option
         * @memberof api.MemberObject
         * @instance
         */
        Object.defineProperty(MemberObject.prototype, "option", {
            get: $util.oneOfGetter($oneOfFields = ["topic", "room", "group", "scheduleSet", "time", "sync"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MemberObject instance using the specified properties.
         * @function create
         * @memberof api.MemberObject
         * @static
         * @param {api.IMemberObject=} [properties] Properties to set
         * @returns {api.MemberObject} MemberObject instance
         */
        MemberObject.create = function create(properties) {
            return new MemberObject(properties);
        };

        /**
         * Encodes the specified MemberObject message. Does not implicitly {@link api.MemberObject.verify|verify} messages.
         * @function encode
         * @memberof api.MemberObject
         * @static
         * @param {api.IMemberObject} message MemberObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.TopicOption.encode(message.topic, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.RoomOption.encode(message.room, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupOption.encode(message.group, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.TimeOption.encode(message.time, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.sync != null && Object.hasOwnProperty.call(message, "sync"))
                $root.api.SyncOption.encode(message.sync, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSetOption.encode(message.scheduleSet, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberObject message, length delimited. Does not implicitly {@link api.MemberObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberObject
         * @static
         * @param {api.IMemberObject} message MemberObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberObject message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberObject} MemberObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topic = $root.api.TopicOption.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.room = $root.api.RoomOption.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.group = $root.api.GroupOption.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.scheduleSet = $root.api.ScheduleSetOption.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.time = $root.api.TimeOption.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.sync = $root.api.SyncOption.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberObject} MemberObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberObject message.
         * @function verify
         * @memberof api.MemberObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.topic != null && message.hasOwnProperty("topic")) {
                properties.option = 1;
                {
                    let error = $root.api.TopicOption.verify(message.topic);
                    if (error)
                        return "topic." + error;
                }
            }
            if (message.room != null && message.hasOwnProperty("room")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.RoomOption.verify(message.room);
                    if (error)
                        return "room." + error;
                }
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.GroupOption.verify(message.group);
                    if (error)
                        return "group." + error;
                }
            }
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.ScheduleSetOption.verify(message.scheduleSet);
                    if (error)
                        return "scheduleSet." + error;
                }
            }
            if (message.time != null && message.hasOwnProperty("time")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.TimeOption.verify(message.time);
                    if (error)
                        return "time." + error;
                }
            }
            if (message.sync != null && message.hasOwnProperty("sync")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                {
                    let error = $root.api.SyncOption.verify(message.sync);
                    if (error)
                        return "sync." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MemberObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberObject} MemberObject
         */
        MemberObject.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberObject)
                return object;
            let message = new $root.api.MemberObject();
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.MemberObject.topic: object expected");
                message.topic = $root.api.TopicOption.fromObject(object.topic);
            }
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.MemberObject.room: object expected");
                message.room = $root.api.RoomOption.fromObject(object.room);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberObject.group: object expected");
                message.group = $root.api.GroupOption.fromObject(object.group);
            }
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.MemberObject.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSetOption.fromObject(object.scheduleSet);
            }
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.MemberObject.time: object expected");
                message.time = $root.api.TimeOption.fromObject(object.time);
            }
            if (object.sync != null) {
                if (typeof object.sync !== "object")
                    throw TypeError(".api.MemberObject.sync: object expected");
                message.sync = $root.api.SyncOption.fromObject(object.sync);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberObject
         * @static
         * @param {api.MemberObject} message MemberObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberObject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.topic != null && message.hasOwnProperty("topic")) {
                object.topic = $root.api.TopicOption.toObject(message.topic, options);
                if (options.oneofs)
                    object.option = "topic";
            }
            if (message.room != null && message.hasOwnProperty("room")) {
                object.room = $root.api.RoomOption.toObject(message.room, options);
                if (options.oneofs)
                    object.option = "room";
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                object.group = $root.api.GroupOption.toObject(message.group, options);
                if (options.oneofs)
                    object.option = "group";
            }
            if (message.time != null && message.hasOwnProperty("time")) {
                object.time = $root.api.TimeOption.toObject(message.time, options);
                if (options.oneofs)
                    object.option = "time";
            }
            if (message.sync != null && message.hasOwnProperty("sync")) {
                object.sync = $root.api.SyncOption.toObject(message.sync, options);
                if (options.oneofs)
                    object.option = "sync";
            }
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                object.scheduleSet = $root.api.ScheduleSetOption.toObject(message.scheduleSet, options);
                if (options.oneofs)
                    object.option = "scheduleSet";
            }
            return object;
        };

        /**
         * Converts this MemberObject to JSON.
         * @function toJSON
         * @memberof api.MemberObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberObject;
    })();

    api.MemberInviteEvent = (function() {

        /**
         * Properties of a MemberInviteEvent.
         * @memberof api
         * @interface IMemberInviteEvent
         * @property {api.IMemberObject|null} [obj] MemberInviteEvent obj
         * @property {api.IMemberEntity|null} [entity] MemberInviteEvent entity
         */

        /**
         * Constructs a new MemberInviteEvent.
         * @memberof api
         * @classdesc Represents a MemberInviteEvent.
         * @implements IMemberInviteEvent
         * @constructor
         * @param {api.IMemberInviteEvent=} [properties] Properties to set
         */
        function MemberInviteEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberInviteEvent obj.
         * @member {api.IMemberObject|null|undefined} obj
         * @memberof api.MemberInviteEvent
         * @instance
         */
        MemberInviteEvent.prototype.obj = null;

        /**
         * MemberInviteEvent entity.
         * @member {api.IMemberEntity|null|undefined} entity
         * @memberof api.MemberInviteEvent
         * @instance
         */
        MemberInviteEvent.prototype.entity = null;

        /**
         * Creates a new MemberInviteEvent instance using the specified properties.
         * @function create
         * @memberof api.MemberInviteEvent
         * @static
         * @param {api.IMemberInviteEvent=} [properties] Properties to set
         * @returns {api.MemberInviteEvent} MemberInviteEvent instance
         */
        MemberInviteEvent.create = function create(properties) {
            return new MemberInviteEvent(properties);
        };

        /**
         * Encodes the specified MemberInviteEvent message. Does not implicitly {@link api.MemberInviteEvent.verify|verify} messages.
         * @function encode
         * @memberof api.MemberInviteEvent
         * @static
         * @param {api.IMemberInviteEvent} message MemberInviteEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberInviteEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObject.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntity.encode(message.entity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberInviteEvent message, length delimited. Does not implicitly {@link api.MemberInviteEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberInviteEvent
         * @static
         * @param {api.IMemberInviteEvent} message MemberInviteEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberInviteEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberInviteEvent message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberInviteEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberInviteEvent} MemberInviteEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberInviteEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberInviteEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObject.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.entity = $root.api.MemberEntity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberInviteEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberInviteEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberInviteEvent} MemberInviteEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberInviteEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberInviteEvent message.
         * @function verify
         * @memberof api.MemberInviteEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberInviteEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObject.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntity.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            return null;
        };

        /**
         * Creates a MemberInviteEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberInviteEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberInviteEvent} MemberInviteEvent
         */
        MemberInviteEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberInviteEvent)
                return object;
            let message = new $root.api.MemberInviteEvent();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.MemberInviteEvent.obj: object expected");
                message.obj = $root.api.MemberObject.fromObject(object.obj);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.MemberInviteEvent.entity: object expected");
                message.entity = $root.api.MemberEntity.fromObject(object.entity);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberInviteEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberInviteEvent
         * @static
         * @param {api.MemberInviteEvent} message MemberInviteEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberInviteEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.entity = null;
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObject.toObject(message.obj, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntity.toObject(message.entity, options);
            return object;
        };

        /**
         * Converts this MemberInviteEvent to JSON.
         * @function toJSON
         * @memberof api.MemberInviteEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberInviteEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberInviteEvent;
    })();

    api.MemberAddedEvent = (function() {

        /**
         * Properties of a MemberAddedEvent.
         * @memberof api
         * @interface IMemberAddedEvent
         * @property {api.IMemberObject|null} [obj] MemberAddedEvent obj
         * @property {api.IMemberEntity|null} [entity] MemberAddedEvent entity
         */

        /**
         * Constructs a new MemberAddedEvent.
         * @memberof api
         * @classdesc Represents a MemberAddedEvent.
         * @implements IMemberAddedEvent
         * @constructor
         * @param {api.IMemberAddedEvent=} [properties] Properties to set
         */
        function MemberAddedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberAddedEvent obj.
         * @member {api.IMemberObject|null|undefined} obj
         * @memberof api.MemberAddedEvent
         * @instance
         */
        MemberAddedEvent.prototype.obj = null;

        /**
         * MemberAddedEvent entity.
         * @member {api.IMemberEntity|null|undefined} entity
         * @memberof api.MemberAddedEvent
         * @instance
         */
        MemberAddedEvent.prototype.entity = null;

        /**
         * Creates a new MemberAddedEvent instance using the specified properties.
         * @function create
         * @memberof api.MemberAddedEvent
         * @static
         * @param {api.IMemberAddedEvent=} [properties] Properties to set
         * @returns {api.MemberAddedEvent} MemberAddedEvent instance
         */
        MemberAddedEvent.create = function create(properties) {
            return new MemberAddedEvent(properties);
        };

        /**
         * Encodes the specified MemberAddedEvent message. Does not implicitly {@link api.MemberAddedEvent.verify|verify} messages.
         * @function encode
         * @memberof api.MemberAddedEvent
         * @static
         * @param {api.IMemberAddedEvent} message MemberAddedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberAddedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObject.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntity.encode(message.entity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberAddedEvent message, length delimited. Does not implicitly {@link api.MemberAddedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberAddedEvent
         * @static
         * @param {api.IMemberAddedEvent} message MemberAddedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberAddedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberAddedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberAddedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberAddedEvent} MemberAddedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberAddedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberAddedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObject.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.entity = $root.api.MemberEntity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberAddedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberAddedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberAddedEvent} MemberAddedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberAddedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberAddedEvent message.
         * @function verify
         * @memberof api.MemberAddedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberAddedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObject.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntity.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            return null;
        };

        /**
         * Creates a MemberAddedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberAddedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberAddedEvent} MemberAddedEvent
         */
        MemberAddedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberAddedEvent)
                return object;
            let message = new $root.api.MemberAddedEvent();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.MemberAddedEvent.obj: object expected");
                message.obj = $root.api.MemberObject.fromObject(object.obj);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.MemberAddedEvent.entity: object expected");
                message.entity = $root.api.MemberEntity.fromObject(object.entity);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberAddedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberAddedEvent
         * @static
         * @param {api.MemberAddedEvent} message MemberAddedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberAddedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.entity = null;
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObject.toObject(message.obj, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntity.toObject(message.entity, options);
            return object;
        };

        /**
         * Converts this MemberAddedEvent to JSON.
         * @function toJSON
         * @memberof api.MemberAddedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberAddedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberAddedEvent;
    })();

    api.MemberRemovedEvent = (function() {

        /**
         * Properties of a MemberRemovedEvent.
         * @memberof api
         * @interface IMemberRemovedEvent
         * @property {api.IMemberObject|null} [obj] MemberRemovedEvent obj
         * @property {api.IMemberEntity|null} [entity] MemberRemovedEvent entity
         */

        /**
         * Constructs a new MemberRemovedEvent.
         * @memberof api
         * @classdesc Represents a MemberRemovedEvent.
         * @implements IMemberRemovedEvent
         * @constructor
         * @param {api.IMemberRemovedEvent=} [properties] Properties to set
         */
        function MemberRemovedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberRemovedEvent obj.
         * @member {api.IMemberObject|null|undefined} obj
         * @memberof api.MemberRemovedEvent
         * @instance
         */
        MemberRemovedEvent.prototype.obj = null;

        /**
         * MemberRemovedEvent entity.
         * @member {api.IMemberEntity|null|undefined} entity
         * @memberof api.MemberRemovedEvent
         * @instance
         */
        MemberRemovedEvent.prototype.entity = null;

        /**
         * Creates a new MemberRemovedEvent instance using the specified properties.
         * @function create
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {api.IMemberRemovedEvent=} [properties] Properties to set
         * @returns {api.MemberRemovedEvent} MemberRemovedEvent instance
         */
        MemberRemovedEvent.create = function create(properties) {
            return new MemberRemovedEvent(properties);
        };

        /**
         * Encodes the specified MemberRemovedEvent message. Does not implicitly {@link api.MemberRemovedEvent.verify|verify} messages.
         * @function encode
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {api.IMemberRemovedEvent} message MemberRemovedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberRemovedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObject.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntity.encode(message.entity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberRemovedEvent message, length delimited. Does not implicitly {@link api.MemberRemovedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {api.IMemberRemovedEvent} message MemberRemovedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberRemovedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberRemovedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberRemovedEvent} MemberRemovedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberRemovedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberRemovedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObject.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.entity = $root.api.MemberEntity.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberRemovedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberRemovedEvent} MemberRemovedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberRemovedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberRemovedEvent message.
         * @function verify
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberRemovedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObject.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntity.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            return null;
        };

        /**
         * Creates a MemberRemovedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberRemovedEvent} MemberRemovedEvent
         */
        MemberRemovedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberRemovedEvent)
                return object;
            let message = new $root.api.MemberRemovedEvent();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.MemberRemovedEvent.obj: object expected");
                message.obj = $root.api.MemberObject.fromObject(object.obj);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.MemberRemovedEvent.entity: object expected");
                message.entity = $root.api.MemberEntity.fromObject(object.entity);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberRemovedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberRemovedEvent
         * @static
         * @param {api.MemberRemovedEvent} message MemberRemovedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberRemovedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.entity = null;
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObject.toObject(message.obj, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntity.toObject(message.entity, options);
            return object;
        };

        /**
         * Converts this MemberRemovedEvent to JSON.
         * @function toJSON
         * @memberof api.MemberRemovedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberRemovedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberRemovedEvent;
    })();

    api.ObjectRemovedEvent = (function() {

        /**
         * Properties of an ObjectRemovedEvent.
         * @memberof api
         * @interface IObjectRemovedEvent
         * @property {api.IMemberObject|null} [obj] ObjectRemovedEvent obj
         */

        /**
         * Constructs a new ObjectRemovedEvent.
         * @memberof api
         * @classdesc Represents an ObjectRemovedEvent.
         * @implements IObjectRemovedEvent
         * @constructor
         * @param {api.IObjectRemovedEvent=} [properties] Properties to set
         */
        function ObjectRemovedEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ObjectRemovedEvent obj.
         * @member {api.IMemberObject|null|undefined} obj
         * @memberof api.ObjectRemovedEvent
         * @instance
         */
        ObjectRemovedEvent.prototype.obj = null;

        /**
         * Creates a new ObjectRemovedEvent instance using the specified properties.
         * @function create
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {api.IObjectRemovedEvent=} [properties] Properties to set
         * @returns {api.ObjectRemovedEvent} ObjectRemovedEvent instance
         */
        ObjectRemovedEvent.create = function create(properties) {
            return new ObjectRemovedEvent(properties);
        };

        /**
         * Encodes the specified ObjectRemovedEvent message. Does not implicitly {@link api.ObjectRemovedEvent.verify|verify} messages.
         * @function encode
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {api.IObjectRemovedEvent} message ObjectRemovedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ObjectRemovedEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObject.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ObjectRemovedEvent message, length delimited. Does not implicitly {@link api.ObjectRemovedEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {api.IObjectRemovedEvent} message ObjectRemovedEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ObjectRemovedEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ObjectRemovedEvent message from the specified reader or buffer.
         * @function decode
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ObjectRemovedEvent} ObjectRemovedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ObjectRemovedEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ObjectRemovedEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObject.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ObjectRemovedEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ObjectRemovedEvent} ObjectRemovedEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ObjectRemovedEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ObjectRemovedEvent message.
         * @function verify
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ObjectRemovedEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObject.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            return null;
        };

        /**
         * Creates an ObjectRemovedEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ObjectRemovedEvent} ObjectRemovedEvent
         */
        ObjectRemovedEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ObjectRemovedEvent)
                return object;
            let message = new $root.api.ObjectRemovedEvent();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.ObjectRemovedEvent.obj: object expected");
                message.obj = $root.api.MemberObject.fromObject(object.obj);
            }
            return message;
        };

        /**
         * Creates a plain object from an ObjectRemovedEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ObjectRemovedEvent
         * @static
         * @param {api.ObjectRemovedEvent} message ObjectRemovedEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ObjectRemovedEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.obj = null;
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObject.toObject(message.obj, options);
            return object;
        };

        /**
         * Converts this ObjectRemovedEvent to JSON.
         * @function toJSON
         * @memberof api.ObjectRemovedEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ObjectRemovedEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ObjectRemovedEvent;
    })();

    api.AddObjectChild = (function() {

        /**
         * Properties of an AddObjectChild.
         * @memberof api
         * @interface IAddObjectChild
         * @property {boolean|null} [after] AddObjectChild after
         * @property {string|null} [position] AddObjectChild position
         * @property {string|null} [key] AddObjectChild key
         * @property {string|null} [value] AddObjectChild value
         */

        /**
         * Constructs a new AddObjectChild.
         * @memberof api
         * @classdesc Represents an AddObjectChild.
         * @implements IAddObjectChild
         * @constructor
         * @param {api.IAddObjectChild=} [properties] Properties to set
         */
        function AddObjectChild(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddObjectChild after.
         * @member {boolean} after
         * @memberof api.AddObjectChild
         * @instance
         */
        AddObjectChild.prototype.after = false;

        /**
         * AddObjectChild position.
         * @member {string} position
         * @memberof api.AddObjectChild
         * @instance
         */
        AddObjectChild.prototype.position = "";

        /**
         * AddObjectChild key.
         * @member {string} key
         * @memberof api.AddObjectChild
         * @instance
         */
        AddObjectChild.prototype.key = "";

        /**
         * AddObjectChild value.
         * @member {string} value
         * @memberof api.AddObjectChild
         * @instance
         */
        AddObjectChild.prototype.value = "";

        /**
         * Creates a new AddObjectChild instance using the specified properties.
         * @function create
         * @memberof api.AddObjectChild
         * @static
         * @param {api.IAddObjectChild=} [properties] Properties to set
         * @returns {api.AddObjectChild} AddObjectChild instance
         */
        AddObjectChild.create = function create(properties) {
            return new AddObjectChild(properties);
        };

        /**
         * Encodes the specified AddObjectChild message. Does not implicitly {@link api.AddObjectChild.verify|verify} messages.
         * @function encode
         * @memberof api.AddObjectChild
         * @static
         * @param {api.IAddObjectChild} message AddObjectChild message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddObjectChild.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.after != null && Object.hasOwnProperty.call(message, "after"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.after);
            if (message.position != null && Object.hasOwnProperty.call(message, "position"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.position);
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified AddObjectChild message, length delimited. Does not implicitly {@link api.AddObjectChild.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddObjectChild
         * @static
         * @param {api.IAddObjectChild} message AddObjectChild message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddObjectChild.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddObjectChild message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddObjectChild
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddObjectChild} AddObjectChild
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddObjectChild.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddObjectChild();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.after = reader.bool();
                    break;
                case 2:
                    message.position = reader.string();
                    break;
                case 3:
                    message.key = reader.string();
                    break;
                case 4:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddObjectChild message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddObjectChild
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddObjectChild} AddObjectChild
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddObjectChild.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddObjectChild message.
         * @function verify
         * @memberof api.AddObjectChild
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddObjectChild.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.after != null && message.hasOwnProperty("after"))
                if (typeof message.after !== "boolean")
                    return "after: boolean expected";
            if (message.position != null && message.hasOwnProperty("position"))
                if (!$util.isString(message.position))
                    return "position: string expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates an AddObjectChild message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddObjectChild
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddObjectChild} AddObjectChild
         */
        AddObjectChild.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddObjectChild)
                return object;
            let message = new $root.api.AddObjectChild();
            if (object.after != null)
                message.after = Boolean(object.after);
            if (object.position != null)
                message.position = String(object.position);
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from an AddObjectChild message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddObjectChild
         * @static
         * @param {api.AddObjectChild} message AddObjectChild
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddObjectChild.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.after = false;
                object.position = "";
                object.key = "";
                object.value = "";
            }
            if (message.after != null && message.hasOwnProperty("after"))
                object.after = message.after;
            if (message.position != null && message.hasOwnProperty("position"))
                object.position = message.position;
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this AddObjectChild to JSON.
         * @function toJSON
         * @memberof api.AddObjectChild
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddObjectChild.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddObjectChild;
    })();

    api.UpdateObject = (function() {

        /**
         * Properties of an UpdateObject.
         * @memberof api
         * @interface IUpdateObject
         * @property {string|null} [newValue] UpdateObject newValue
         */

        /**
         * Constructs a new UpdateObject.
         * @memberof api
         * @classdesc Represents an UpdateObject.
         * @implements IUpdateObject
         * @constructor
         * @param {api.IUpdateObject=} [properties] Properties to set
         */
        function UpdateObject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateObject newValue.
         * @member {string} newValue
         * @memberof api.UpdateObject
         * @instance
         */
        UpdateObject.prototype.newValue = "";

        /**
         * Creates a new UpdateObject instance using the specified properties.
         * @function create
         * @memberof api.UpdateObject
         * @static
         * @param {api.IUpdateObject=} [properties] Properties to set
         * @returns {api.UpdateObject} UpdateObject instance
         */
        UpdateObject.create = function create(properties) {
            return new UpdateObject(properties);
        };

        /**
         * Encodes the specified UpdateObject message. Does not implicitly {@link api.UpdateObject.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateObject
         * @static
         * @param {api.IUpdateObject} message UpdateObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newValue != null && Object.hasOwnProperty.call(message, "newValue"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.newValue);
            return writer;
        };

        /**
         * Encodes the specified UpdateObject message, length delimited. Does not implicitly {@link api.UpdateObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateObject
         * @static
         * @param {api.IUpdateObject} message UpdateObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateObject message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateObject} UpdateObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.newValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateObject} UpdateObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateObject message.
         * @function verify
         * @memberof api.UpdateObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.newValue != null && message.hasOwnProperty("newValue"))
                if (!$util.isString(message.newValue))
                    return "newValue: string expected";
            return null;
        };

        /**
         * Creates an UpdateObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateObject} UpdateObject
         */
        UpdateObject.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateObject)
                return object;
            let message = new $root.api.UpdateObject();
            if (object.newValue != null)
                message.newValue = String(object.newValue);
            return message;
        };

        /**
         * Creates a plain object from an UpdateObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateObject
         * @static
         * @param {api.UpdateObject} message UpdateObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateObject.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.newValue = "";
            if (message.newValue != null && message.hasOwnProperty("newValue"))
                object.newValue = message.newValue;
            return object;
        };

        /**
         * Converts this UpdateObject to JSON.
         * @function toJSON
         * @memberof api.UpdateObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateObject;
    })();

    api.DeleteObject = (function() {

        /**
         * Properties of a DeleteObject.
         * @memberof api
         * @interface IDeleteObject
         */

        /**
         * Constructs a new DeleteObject.
         * @memberof api
         * @classdesc Represents a DeleteObject.
         * @implements IDeleteObject
         * @constructor
         * @param {api.IDeleteObject=} [properties] Properties to set
         */
        function DeleteObject(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteObject instance using the specified properties.
         * @function create
         * @memberof api.DeleteObject
         * @static
         * @param {api.IDeleteObject=} [properties] Properties to set
         * @returns {api.DeleteObject} DeleteObject instance
         */
        DeleteObject.create = function create(properties) {
            return new DeleteObject(properties);
        };

        /**
         * Encodes the specified DeleteObject message. Does not implicitly {@link api.DeleteObject.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteObject
         * @static
         * @param {api.IDeleteObject} message DeleteObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteObject.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteObject message, length delimited. Does not implicitly {@link api.DeleteObject.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteObject
         * @static
         * @param {api.IDeleteObject} message DeleteObject message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteObject.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteObject message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteObject} DeleteObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteObject.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteObject();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteObject message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteObject
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteObject} DeleteObject
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteObject.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteObject message.
         * @function verify
         * @memberof api.DeleteObject
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteObject.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteObject message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteObject
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteObject} DeleteObject
         */
        DeleteObject.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteObject)
                return object;
            return new $root.api.DeleteObject();
        };

        /**
         * Creates a plain object from a DeleteObject message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteObject
         * @static
         * @param {api.DeleteObject} message DeleteObject
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteObject.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteObject to JSON.
         * @function toJSON
         * @memberof api.DeleteObject
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteObject.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteObject;
    })();

    api.SyncUpdateEvent = (function() {

        /**
         * Properties of a SyncUpdateEvent.
         * @memberof api
         * @interface ISyncUpdateEvent
         * @property {string|null} [syncId] SyncUpdateEvent syncId
         * @property {Array.<string>|null} [keyPath] SyncUpdateEvent keyPath
         * @property {api.IAddObjectChild|null} [addChild] SyncUpdateEvent addChild
         * @property {api.IUpdateObject|null} [updateObject] SyncUpdateEvent updateObject
         * @property {api.IDeleteObject|null} [deleteObject] SyncUpdateEvent deleteObject
         */

        /**
         * Constructs a new SyncUpdateEvent.
         * @memberof api
         * @classdesc Represents a SyncUpdateEvent.
         * @implements ISyncUpdateEvent
         * @constructor
         * @param {api.ISyncUpdateEvent=} [properties] Properties to set
         */
        function SyncUpdateEvent(properties) {
            this.keyPath = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncUpdateEvent syncId.
         * @member {string} syncId
         * @memberof api.SyncUpdateEvent
         * @instance
         */
        SyncUpdateEvent.prototype.syncId = "";

        /**
         * SyncUpdateEvent keyPath.
         * @member {Array.<string>} keyPath
         * @memberof api.SyncUpdateEvent
         * @instance
         */
        SyncUpdateEvent.prototype.keyPath = $util.emptyArray;

        /**
         * SyncUpdateEvent addChild.
         * @member {api.IAddObjectChild|null|undefined} addChild
         * @memberof api.SyncUpdateEvent
         * @instance
         */
        SyncUpdateEvent.prototype.addChild = null;

        /**
         * SyncUpdateEvent updateObject.
         * @member {api.IUpdateObject|null|undefined} updateObject
         * @memberof api.SyncUpdateEvent
         * @instance
         */
        SyncUpdateEvent.prototype.updateObject = null;

        /**
         * SyncUpdateEvent deleteObject.
         * @member {api.IDeleteObject|null|undefined} deleteObject
         * @memberof api.SyncUpdateEvent
         * @instance
         */
        SyncUpdateEvent.prototype.deleteObject = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SyncUpdateEvent action.
         * @member {"addChild"|"updateObject"|"deleteObject"|undefined} action
         * @memberof api.SyncUpdateEvent
         * @instance
         */
        Object.defineProperty(SyncUpdateEvent.prototype, "action", {
            get: $util.oneOfGetter($oneOfFields = ["addChild", "updateObject", "deleteObject"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SyncUpdateEvent instance using the specified properties.
         * @function create
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {api.ISyncUpdateEvent=} [properties] Properties to set
         * @returns {api.SyncUpdateEvent} SyncUpdateEvent instance
         */
        SyncUpdateEvent.create = function create(properties) {
            return new SyncUpdateEvent(properties);
        };

        /**
         * Encodes the specified SyncUpdateEvent message. Does not implicitly {@link api.SyncUpdateEvent.verify|verify} messages.
         * @function encode
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {api.ISyncUpdateEvent} message SyncUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncUpdateEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncId != null && Object.hasOwnProperty.call(message, "syncId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.syncId);
            if (message.keyPath != null && message.keyPath.length)
                for (let i = 0; i < message.keyPath.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.keyPath[i]);
            if (message.addChild != null && Object.hasOwnProperty.call(message, "addChild"))
                $root.api.AddObjectChild.encode(message.addChild, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.updateObject != null && Object.hasOwnProperty.call(message, "updateObject"))
                $root.api.UpdateObject.encode(message.updateObject, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.deleteObject != null && Object.hasOwnProperty.call(message, "deleteObject"))
                $root.api.DeleteObject.encode(message.deleteObject, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SyncUpdateEvent message, length delimited. Does not implicitly {@link api.SyncUpdateEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {api.ISyncUpdateEvent} message SyncUpdateEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncUpdateEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncUpdateEvent message from the specified reader or buffer.
         * @function decode
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SyncUpdateEvent} SyncUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncUpdateEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SyncUpdateEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncId = reader.string();
                    break;
                case 2:
                    if (!(message.keyPath && message.keyPath.length))
                        message.keyPath = [];
                    message.keyPath.push(reader.string());
                    break;
                case 3:
                    message.addChild = $root.api.AddObjectChild.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.updateObject = $root.api.UpdateObject.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.deleteObject = $root.api.DeleteObject.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncUpdateEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SyncUpdateEvent} SyncUpdateEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncUpdateEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncUpdateEvent message.
         * @function verify
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncUpdateEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                if (!$util.isString(message.syncId))
                    return "syncId: string expected";
            if (message.keyPath != null && message.hasOwnProperty("keyPath")) {
                if (!Array.isArray(message.keyPath))
                    return "keyPath: array expected";
                for (let i = 0; i < message.keyPath.length; ++i)
                    if (!$util.isString(message.keyPath[i]))
                        return "keyPath: string[] expected";
            }
            if (message.addChild != null && message.hasOwnProperty("addChild")) {
                properties.action = 1;
                {
                    let error = $root.api.AddObjectChild.verify(message.addChild);
                    if (error)
                        return "addChild." + error;
                }
            }
            if (message.updateObject != null && message.hasOwnProperty("updateObject")) {
                if (properties.action === 1)
                    return "action: multiple values";
                properties.action = 1;
                {
                    let error = $root.api.UpdateObject.verify(message.updateObject);
                    if (error)
                        return "updateObject." + error;
                }
            }
            if (message.deleteObject != null && message.hasOwnProperty("deleteObject")) {
                if (properties.action === 1)
                    return "action: multiple values";
                properties.action = 1;
                {
                    let error = $root.api.DeleteObject.verify(message.deleteObject);
                    if (error)
                        return "deleteObject." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SyncUpdateEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SyncUpdateEvent} SyncUpdateEvent
         */
        SyncUpdateEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SyncUpdateEvent)
                return object;
            let message = new $root.api.SyncUpdateEvent();
            if (object.syncId != null)
                message.syncId = String(object.syncId);
            if (object.keyPath) {
                if (!Array.isArray(object.keyPath))
                    throw TypeError(".api.SyncUpdateEvent.keyPath: array expected");
                message.keyPath = [];
                for (let i = 0; i < object.keyPath.length; ++i)
                    message.keyPath[i] = String(object.keyPath[i]);
            }
            if (object.addChild != null) {
                if (typeof object.addChild !== "object")
                    throw TypeError(".api.SyncUpdateEvent.addChild: object expected");
                message.addChild = $root.api.AddObjectChild.fromObject(object.addChild);
            }
            if (object.updateObject != null) {
                if (typeof object.updateObject !== "object")
                    throw TypeError(".api.SyncUpdateEvent.updateObject: object expected");
                message.updateObject = $root.api.UpdateObject.fromObject(object.updateObject);
            }
            if (object.deleteObject != null) {
                if (typeof object.deleteObject !== "object")
                    throw TypeError(".api.SyncUpdateEvent.deleteObject: object expected");
                message.deleteObject = $root.api.DeleteObject.fromObject(object.deleteObject);
            }
            return message;
        };

        /**
         * Creates a plain object from a SyncUpdateEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SyncUpdateEvent
         * @static
         * @param {api.SyncUpdateEvent} message SyncUpdateEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncUpdateEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.keyPath = [];
            if (options.defaults)
                object.syncId = "";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                object.syncId = message.syncId;
            if (message.keyPath && message.keyPath.length) {
                object.keyPath = [];
                for (let j = 0; j < message.keyPath.length; ++j)
                    object.keyPath[j] = message.keyPath[j];
            }
            if (message.addChild != null && message.hasOwnProperty("addChild")) {
                object.addChild = $root.api.AddObjectChild.toObject(message.addChild, options);
                if (options.oneofs)
                    object.action = "addChild";
            }
            if (message.updateObject != null && message.hasOwnProperty("updateObject")) {
                object.updateObject = $root.api.UpdateObject.toObject(message.updateObject, options);
                if (options.oneofs)
                    object.action = "updateObject";
            }
            if (message.deleteObject != null && message.hasOwnProperty("deleteObject")) {
                object.deleteObject = $root.api.DeleteObject.toObject(message.deleteObject, options);
                if (options.oneofs)
                    object.action = "deleteObject";
            }
            return object;
        };

        /**
         * Converts this SyncUpdateEvent to JSON.
         * @function toJSON
         * @memberof api.SyncUpdateEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncUpdateEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncUpdateEvent;
    })();

    api.Event = (function() {

        /**
         * Properties of an Event.
         * @memberof api
         * @interface IEvent
         * @property {number|Long|null} [timeStamp] Event timeStamp
         * @property {api.IChatMessageEvent|null} [chatMessageEvent] Event chatMessageEvent
         * @property {api.IMemberInviteEvent|null} [memberInviteEvent] Event memberInviteEvent
         * @property {api.IMemberAddedEvent|null} [memberAddedEvent] Event memberAddedEvent
         * @property {api.IMemberRemovedEvent|null} [memberRemovedEvent] Event memberRemovedEvent
         * @property {api.IObjectRemovedEvent|null} [objectRemovedEvent] Event objectRemovedEvent
         * @property {api.ISyncUpdateEvent|null} [syncUpdateEvent] Event syncUpdateEvent
         */

        /**
         * Constructs a new Event.
         * @memberof api
         * @classdesc Represents an Event.
         * @implements IEvent
         * @constructor
         * @param {api.IEvent=} [properties] Properties to set
         */
        function Event(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Event timeStamp.
         * @member {number|Long} timeStamp
         * @memberof api.Event
         * @instance
         */
        Event.prototype.timeStamp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Event chatMessageEvent.
         * @member {api.IChatMessageEvent|null|undefined} chatMessageEvent
         * @memberof api.Event
         * @instance
         */
        Event.prototype.chatMessageEvent = null;

        /**
         * Event memberInviteEvent.
         * @member {api.IMemberInviteEvent|null|undefined} memberInviteEvent
         * @memberof api.Event
         * @instance
         */
        Event.prototype.memberInviteEvent = null;

        /**
         * Event memberAddedEvent.
         * @member {api.IMemberAddedEvent|null|undefined} memberAddedEvent
         * @memberof api.Event
         * @instance
         */
        Event.prototype.memberAddedEvent = null;

        /**
         * Event memberRemovedEvent.
         * @member {api.IMemberRemovedEvent|null|undefined} memberRemovedEvent
         * @memberof api.Event
         * @instance
         */
        Event.prototype.memberRemovedEvent = null;

        /**
         * Event objectRemovedEvent.
         * @member {api.IObjectRemovedEvent|null|undefined} objectRemovedEvent
         * @memberof api.Event
         * @instance
         */
        Event.prototype.objectRemovedEvent = null;

        /**
         * Event syncUpdateEvent.
         * @member {api.ISyncUpdateEvent|null|undefined} syncUpdateEvent
         * @memberof api.Event
         * @instance
         */
        Event.prototype.syncUpdateEvent = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Event event.
         * @member {"chatMessageEvent"|"memberInviteEvent"|"memberAddedEvent"|"memberRemovedEvent"|"objectRemovedEvent"|"syncUpdateEvent"|undefined} event
         * @memberof api.Event
         * @instance
         */
        Object.defineProperty(Event.prototype, "event", {
            get: $util.oneOfGetter($oneOfFields = ["chatMessageEvent", "memberInviteEvent", "memberAddedEvent", "memberRemovedEvent", "objectRemovedEvent", "syncUpdateEvent"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Event instance using the specified properties.
         * @function create
         * @memberof api.Event
         * @static
         * @param {api.IEvent=} [properties] Properties to set
         * @returns {api.Event} Event instance
         */
        Event.create = function create(properties) {
            return new Event(properties);
        };

        /**
         * Encodes the specified Event message. Does not implicitly {@link api.Event.verify|verify} messages.
         * @function encode
         * @memberof api.Event
         * @static
         * @param {api.IEvent} message Event message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Event.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeStamp != null && Object.hasOwnProperty.call(message, "timeStamp"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.timeStamp);
            if (message.chatMessageEvent != null && Object.hasOwnProperty.call(message, "chatMessageEvent"))
                $root.api.ChatMessageEvent.encode(message.chatMessageEvent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.memberInviteEvent != null && Object.hasOwnProperty.call(message, "memberInviteEvent"))
                $root.api.MemberInviteEvent.encode(message.memberInviteEvent, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.memberAddedEvent != null && Object.hasOwnProperty.call(message, "memberAddedEvent"))
                $root.api.MemberAddedEvent.encode(message.memberAddedEvent, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.memberRemovedEvent != null && Object.hasOwnProperty.call(message, "memberRemovedEvent"))
                $root.api.MemberRemovedEvent.encode(message.memberRemovedEvent, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.objectRemovedEvent != null && Object.hasOwnProperty.call(message, "objectRemovedEvent"))
                $root.api.ObjectRemovedEvent.encode(message.objectRemovedEvent, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.syncUpdateEvent != null && Object.hasOwnProperty.call(message, "syncUpdateEvent"))
                $root.api.SyncUpdateEvent.encode(message.syncUpdateEvent, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Event message, length delimited. Does not implicitly {@link api.Event.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Event
         * @static
         * @param {api.IEvent} message Event message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Event.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Event message from the specified reader or buffer.
         * @function decode
         * @memberof api.Event
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Event} Event
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Event.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Event();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timeStamp = reader.int64();
                    break;
                case 2:
                    message.chatMessageEvent = $root.api.ChatMessageEvent.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.memberInviteEvent = $root.api.MemberInviteEvent.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.memberAddedEvent = $root.api.MemberAddedEvent.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.memberRemovedEvent = $root.api.MemberRemovedEvent.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.objectRemovedEvent = $root.api.ObjectRemovedEvent.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.syncUpdateEvent = $root.api.SyncUpdateEvent.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Event message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Event
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Event} Event
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Event.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Event message.
         * @function verify
         * @memberof api.Event
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Event.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.timeStamp != null && message.hasOwnProperty("timeStamp"))
                if (!$util.isInteger(message.timeStamp) && !(message.timeStamp && $util.isInteger(message.timeStamp.low) && $util.isInteger(message.timeStamp.high)))
                    return "timeStamp: integer|Long expected";
            if (message.chatMessageEvent != null && message.hasOwnProperty("chatMessageEvent")) {
                properties.event = 1;
                {
                    let error = $root.api.ChatMessageEvent.verify(message.chatMessageEvent);
                    if (error)
                        return "chatMessageEvent." + error;
                }
            }
            if (message.memberInviteEvent != null && message.hasOwnProperty("memberInviteEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.api.MemberInviteEvent.verify(message.memberInviteEvent);
                    if (error)
                        return "memberInviteEvent." + error;
                }
            }
            if (message.memberAddedEvent != null && message.hasOwnProperty("memberAddedEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.api.MemberAddedEvent.verify(message.memberAddedEvent);
                    if (error)
                        return "memberAddedEvent." + error;
                }
            }
            if (message.memberRemovedEvent != null && message.hasOwnProperty("memberRemovedEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.api.MemberRemovedEvent.verify(message.memberRemovedEvent);
                    if (error)
                        return "memberRemovedEvent." + error;
                }
            }
            if (message.objectRemovedEvent != null && message.hasOwnProperty("objectRemovedEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.api.ObjectRemovedEvent.verify(message.objectRemovedEvent);
                    if (error)
                        return "objectRemovedEvent." + error;
                }
            }
            if (message.syncUpdateEvent != null && message.hasOwnProperty("syncUpdateEvent")) {
                if (properties.event === 1)
                    return "event: multiple values";
                properties.event = 1;
                {
                    let error = $root.api.SyncUpdateEvent.verify(message.syncUpdateEvent);
                    if (error)
                        return "syncUpdateEvent." + error;
                }
            }
            return null;
        };

        /**
         * Creates an Event message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Event
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Event} Event
         */
        Event.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Event)
                return object;
            let message = new $root.api.Event();
            if (object.timeStamp != null)
                if ($util.Long)
                    (message.timeStamp = $util.Long.fromValue(object.timeStamp)).unsigned = false;
                else if (typeof object.timeStamp === "string")
                    message.timeStamp = parseInt(object.timeStamp, 10);
                else if (typeof object.timeStamp === "number")
                    message.timeStamp = object.timeStamp;
                else if (typeof object.timeStamp === "object")
                    message.timeStamp = new $util.LongBits(object.timeStamp.low >>> 0, object.timeStamp.high >>> 0).toNumber();
            if (object.chatMessageEvent != null) {
                if (typeof object.chatMessageEvent !== "object")
                    throw TypeError(".api.Event.chatMessageEvent: object expected");
                message.chatMessageEvent = $root.api.ChatMessageEvent.fromObject(object.chatMessageEvent);
            }
            if (object.memberInviteEvent != null) {
                if (typeof object.memberInviteEvent !== "object")
                    throw TypeError(".api.Event.memberInviteEvent: object expected");
                message.memberInviteEvent = $root.api.MemberInviteEvent.fromObject(object.memberInviteEvent);
            }
            if (object.memberAddedEvent != null) {
                if (typeof object.memberAddedEvent !== "object")
                    throw TypeError(".api.Event.memberAddedEvent: object expected");
                message.memberAddedEvent = $root.api.MemberAddedEvent.fromObject(object.memberAddedEvent);
            }
            if (object.memberRemovedEvent != null) {
                if (typeof object.memberRemovedEvent !== "object")
                    throw TypeError(".api.Event.memberRemovedEvent: object expected");
                message.memberRemovedEvent = $root.api.MemberRemovedEvent.fromObject(object.memberRemovedEvent);
            }
            if (object.objectRemovedEvent != null) {
                if (typeof object.objectRemovedEvent !== "object")
                    throw TypeError(".api.Event.objectRemovedEvent: object expected");
                message.objectRemovedEvent = $root.api.ObjectRemovedEvent.fromObject(object.objectRemovedEvent);
            }
            if (object.syncUpdateEvent != null) {
                if (typeof object.syncUpdateEvent !== "object")
                    throw TypeError(".api.Event.syncUpdateEvent: object expected");
                message.syncUpdateEvent = $root.api.SyncUpdateEvent.fromObject(object.syncUpdateEvent);
            }
            return message;
        };

        /**
         * Creates a plain object from an Event message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Event
         * @static
         * @param {api.Event} message Event
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Event.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.timeStamp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.timeStamp = options.longs === String ? "0" : 0;
            if (message.timeStamp != null && message.hasOwnProperty("timeStamp"))
                if (typeof message.timeStamp === "number")
                    object.timeStamp = options.longs === String ? String(message.timeStamp) : message.timeStamp;
                else
                    object.timeStamp = options.longs === String ? $util.Long.prototype.toString.call(message.timeStamp) : options.longs === Number ? new $util.LongBits(message.timeStamp.low >>> 0, message.timeStamp.high >>> 0).toNumber() : message.timeStamp;
            if (message.chatMessageEvent != null && message.hasOwnProperty("chatMessageEvent")) {
                object.chatMessageEvent = $root.api.ChatMessageEvent.toObject(message.chatMessageEvent, options);
                if (options.oneofs)
                    object.event = "chatMessageEvent";
            }
            if (message.memberInviteEvent != null && message.hasOwnProperty("memberInviteEvent")) {
                object.memberInviteEvent = $root.api.MemberInviteEvent.toObject(message.memberInviteEvent, options);
                if (options.oneofs)
                    object.event = "memberInviteEvent";
            }
            if (message.memberAddedEvent != null && message.hasOwnProperty("memberAddedEvent")) {
                object.memberAddedEvent = $root.api.MemberAddedEvent.toObject(message.memberAddedEvent, options);
                if (options.oneofs)
                    object.event = "memberAddedEvent";
            }
            if (message.memberRemovedEvent != null && message.hasOwnProperty("memberRemovedEvent")) {
                object.memberRemovedEvent = $root.api.MemberRemovedEvent.toObject(message.memberRemovedEvent, options);
                if (options.oneofs)
                    object.event = "memberRemovedEvent";
            }
            if (message.objectRemovedEvent != null && message.hasOwnProperty("objectRemovedEvent")) {
                object.objectRemovedEvent = $root.api.ObjectRemovedEvent.toObject(message.objectRemovedEvent, options);
                if (options.oneofs)
                    object.event = "objectRemovedEvent";
            }
            if (message.syncUpdateEvent != null && message.hasOwnProperty("syncUpdateEvent")) {
                object.syncUpdateEvent = $root.api.SyncUpdateEvent.toObject(message.syncUpdateEvent, options);
                if (options.oneofs)
                    object.event = "syncUpdateEvent";
            }
            return object;
        };

        /**
         * Converts this Event to JSON.
         * @function toJSON
         * @memberof api.Event
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Event.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Event;
    })();

    api.EmailPassword = (function() {

        /**
         * Properties of an EmailPassword.
         * @memberof api
         * @interface IEmailPassword
         * @property {string|null} [emailAddress] EmailPassword emailAddress
         * @property {string|null} [password] EmailPassword password
         */

        /**
         * Constructs a new EmailPassword.
         * @memberof api
         * @classdesc Represents an EmailPassword.
         * @implements IEmailPassword
         * @constructor
         * @param {api.IEmailPassword=} [properties] Properties to set
         */
        function EmailPassword(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EmailPassword emailAddress.
         * @member {string} emailAddress
         * @memberof api.EmailPassword
         * @instance
         */
        EmailPassword.prototype.emailAddress = "";

        /**
         * EmailPassword password.
         * @member {string} password
         * @memberof api.EmailPassword
         * @instance
         */
        EmailPassword.prototype.password = "";

        /**
         * Creates a new EmailPassword instance using the specified properties.
         * @function create
         * @memberof api.EmailPassword
         * @static
         * @param {api.IEmailPassword=} [properties] Properties to set
         * @returns {api.EmailPassword} EmailPassword instance
         */
        EmailPassword.create = function create(properties) {
            return new EmailPassword(properties);
        };

        /**
         * Encodes the specified EmailPassword message. Does not implicitly {@link api.EmailPassword.verify|verify} messages.
         * @function encode
         * @memberof api.EmailPassword
         * @static
         * @param {api.IEmailPassword} message EmailPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailPassword.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.emailAddress);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
            return writer;
        };

        /**
         * Encodes the specified EmailPassword message, length delimited. Does not implicitly {@link api.EmailPassword.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.EmailPassword
         * @static
         * @param {api.IEmailPassword} message EmailPassword message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailPassword.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailPassword message from the specified reader or buffer.
         * @function decode
         * @memberof api.EmailPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.EmailPassword} EmailPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailPassword.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.EmailPassword();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.emailAddress = reader.string();
                    break;
                case 2:
                    message.password = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EmailPassword message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.EmailPassword
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.EmailPassword} EmailPassword
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailPassword.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EmailPassword message.
         * @function verify
         * @memberof api.EmailPassword
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EmailPassword.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            return null;
        };

        /**
         * Creates an EmailPassword message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.EmailPassword
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.EmailPassword} EmailPassword
         */
        EmailPassword.fromObject = function fromObject(object) {
            if (object instanceof $root.api.EmailPassword)
                return object;
            let message = new $root.api.EmailPassword();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.password != null)
                message.password = String(object.password);
            return message;
        };

        /**
         * Creates a plain object from an EmailPassword message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.EmailPassword
         * @static
         * @param {api.EmailPassword} message EmailPassword
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EmailPassword.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.emailAddress = "";
                object.password = "";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            return object;
        };

        /**
         * Converts this EmailPassword to JSON.
         * @function toJSON
         * @memberof api.EmailPassword
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EmailPassword.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EmailPassword;
    })();

    api.GoogleCredential = (function() {

        /**
         * Properties of a GoogleCredential.
         * @memberof api
         * @interface IGoogleCredential
         * @property {string|null} [jwtToken] GoogleCredential jwtToken
         */

        /**
         * Constructs a new GoogleCredential.
         * @memberof api
         * @classdesc Represents a GoogleCredential.
         * @implements IGoogleCredential
         * @constructor
         * @param {api.IGoogleCredential=} [properties] Properties to set
         */
        function GoogleCredential(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GoogleCredential jwtToken.
         * @member {string} jwtToken
         * @memberof api.GoogleCredential
         * @instance
         */
        GoogleCredential.prototype.jwtToken = "";

        /**
         * Creates a new GoogleCredential instance using the specified properties.
         * @function create
         * @memberof api.GoogleCredential
         * @static
         * @param {api.IGoogleCredential=} [properties] Properties to set
         * @returns {api.GoogleCredential} GoogleCredential instance
         */
        GoogleCredential.create = function create(properties) {
            return new GoogleCredential(properties);
        };

        /**
         * Encodes the specified GoogleCredential message. Does not implicitly {@link api.GoogleCredential.verify|verify} messages.
         * @function encode
         * @memberof api.GoogleCredential
         * @static
         * @param {api.IGoogleCredential} message GoogleCredential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleCredential.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.jwtToken != null && Object.hasOwnProperty.call(message, "jwtToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.jwtToken);
            return writer;
        };

        /**
         * Encodes the specified GoogleCredential message, length delimited. Does not implicitly {@link api.GoogleCredential.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GoogleCredential
         * @static
         * @param {api.IGoogleCredential} message GoogleCredential message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GoogleCredential.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GoogleCredential message from the specified reader or buffer.
         * @function decode
         * @memberof api.GoogleCredential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GoogleCredential} GoogleCredential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleCredential.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GoogleCredential();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.jwtToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GoogleCredential message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GoogleCredential
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GoogleCredential} GoogleCredential
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GoogleCredential.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GoogleCredential message.
         * @function verify
         * @memberof api.GoogleCredential
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GoogleCredential.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.jwtToken != null && message.hasOwnProperty("jwtToken"))
                if (!$util.isString(message.jwtToken))
                    return "jwtToken: string expected";
            return null;
        };

        /**
         * Creates a GoogleCredential message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GoogleCredential
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GoogleCredential} GoogleCredential
         */
        GoogleCredential.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GoogleCredential)
                return object;
            let message = new $root.api.GoogleCredential();
            if (object.jwtToken != null)
                message.jwtToken = String(object.jwtToken);
            return message;
        };

        /**
         * Creates a plain object from a GoogleCredential message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GoogleCredential
         * @static
         * @param {api.GoogleCredential} message GoogleCredential
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GoogleCredential.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.jwtToken = "";
            if (message.jwtToken != null && message.hasOwnProperty("jwtToken"))
                object.jwtToken = message.jwtToken;
            return object;
        };

        /**
         * Converts this GoogleCredential to JSON.
         * @function toJSON
         * @memberof api.GoogleCredential
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GoogleCredential.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GoogleCredential;
    })();

    api.LoginRequest = (function() {

        /**
         * Properties of a LoginRequest.
         * @memberof api
         * @interface ILoginRequest
         * @property {api.IEmailPassword|null} [emailPassword] LoginRequest emailPassword
         * @property {api.IGoogleCredential|null} [googleCredential] LoginRequest googleCredential
         */

        /**
         * Constructs a new LoginRequest.
         * @memberof api
         * @classdesc Represents a LoginRequest.
         * @implements ILoginRequest
         * @constructor
         * @param {api.ILoginRequest=} [properties] Properties to set
         */
        function LoginRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginRequest emailPassword.
         * @member {api.IEmailPassword|null|undefined} emailPassword
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.emailPassword = null;

        /**
         * LoginRequest googleCredential.
         * @member {api.IGoogleCredential|null|undefined} googleCredential
         * @memberof api.LoginRequest
         * @instance
         */
        LoginRequest.prototype.googleCredential = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * LoginRequest credentialOption.
         * @member {"emailPassword"|"googleCredential"|undefined} credentialOption
         * @memberof api.LoginRequest
         * @instance
         */
        Object.defineProperty(LoginRequest.prototype, "credentialOption", {
            get: $util.oneOfGetter($oneOfFields = ["emailPassword", "googleCredential"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LoginRequest instance using the specified properties.
         * @function create
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest=} [properties] Properties to set
         * @returns {api.LoginRequest} LoginRequest instance
         */
        LoginRequest.create = function create(properties) {
            return new LoginRequest(properties);
        };

        /**
         * Encodes the specified LoginRequest message. Does not implicitly {@link api.LoginRequest.verify|verify} messages.
         * @function encode
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.emailPassword != null && Object.hasOwnProperty.call(message, "emailPassword"))
                $root.api.EmailPassword.encode(message.emailPassword, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.googleCredential != null && Object.hasOwnProperty.call(message, "googleCredential"))
                $root.api.GoogleCredential.encode(message.googleCredential, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link api.LoginRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LoginRequest
         * @static
         * @param {api.ILoginRequest} message LoginRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LoginRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.emailPassword = $root.api.EmailPassword.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.googleCredential = $root.api.GoogleCredential.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LoginRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LoginRequest} LoginRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginRequest message.
         * @function verify
         * @memberof api.LoginRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.emailPassword != null && message.hasOwnProperty("emailPassword")) {
                properties.credentialOption = 1;
                {
                    let error = $root.api.EmailPassword.verify(message.emailPassword);
                    if (error)
                        return "emailPassword." + error;
                }
            }
            if (message.googleCredential != null && message.hasOwnProperty("googleCredential")) {
                if (properties.credentialOption === 1)
                    return "credentialOption: multiple values";
                properties.credentialOption = 1;
                {
                    let error = $root.api.GoogleCredential.verify(message.googleCredential);
                    if (error)
                        return "googleCredential." + error;
                }
            }
            return null;
        };

        /**
         * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LoginRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LoginRequest} LoginRequest
         */
        LoginRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LoginRequest)
                return object;
            let message = new $root.api.LoginRequest();
            if (object.emailPassword != null) {
                if (typeof object.emailPassword !== "object")
                    throw TypeError(".api.LoginRequest.emailPassword: object expected");
                message.emailPassword = $root.api.EmailPassword.fromObject(object.emailPassword);
            }
            if (object.googleCredential != null) {
                if (typeof object.googleCredential !== "object")
                    throw TypeError(".api.LoginRequest.googleCredential: object expected");
                message.googleCredential = $root.api.GoogleCredential.fromObject(object.googleCredential);
            }
            return message;
        };

        /**
         * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LoginRequest
         * @static
         * @param {api.LoginRequest} message LoginRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.emailPassword != null && message.hasOwnProperty("emailPassword")) {
                object.emailPassword = $root.api.EmailPassword.toObject(message.emailPassword, options);
                if (options.oneofs)
                    object.credentialOption = "emailPassword";
            }
            if (message.googleCredential != null && message.hasOwnProperty("googleCredential")) {
                object.googleCredential = $root.api.GoogleCredential.toObject(message.googleCredential, options);
                if (options.oneofs)
                    object.credentialOption = "googleCredential";
            }
            return object;
        };

        /**
         * Converts this LoginRequest to JSON.
         * @function toJSON
         * @memberof api.LoginRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginRequest;
    })();

    api.SendValidationCodeRequest = (function() {

        /**
         * Properties of a SendValidationCodeRequest.
         * @memberof api
         * @interface ISendValidationCodeRequest
         * @property {string|null} [emailAddress] SendValidationCodeRequest emailAddress
         * @property {boolean|null} [newPassword] SendValidationCodeRequest newPassword
         */

        /**
         * Constructs a new SendValidationCodeRequest.
         * @memberof api
         * @classdesc Represents a SendValidationCodeRequest.
         * @implements ISendValidationCodeRequest
         * @constructor
         * @param {api.ISendValidationCodeRequest=} [properties] Properties to set
         */
        function SendValidationCodeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendValidationCodeRequest emailAddress.
         * @member {string} emailAddress
         * @memberof api.SendValidationCodeRequest
         * @instance
         */
        SendValidationCodeRequest.prototype.emailAddress = "";

        /**
         * SendValidationCodeRequest newPassword.
         * @member {boolean} newPassword
         * @memberof api.SendValidationCodeRequest
         * @instance
         */
        SendValidationCodeRequest.prototype.newPassword = false;

        /**
         * Creates a new SendValidationCodeRequest instance using the specified properties.
         * @function create
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.ISendValidationCodeRequest=} [properties] Properties to set
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest instance
         */
        SendValidationCodeRequest.create = function create(properties) {
            return new SendValidationCodeRequest(properties);
        };

        /**
         * Encodes the specified SendValidationCodeRequest message. Does not implicitly {@link api.SendValidationCodeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.ISendValidationCodeRequest} message SendValidationCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.newPassword);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            return writer;
        };

        /**
         * Encodes the specified SendValidationCodeRequest message, length delimited. Does not implicitly {@link api.SendValidationCodeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.ISendValidationCodeRequest} message SendValidationCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendValidationCodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendValidationCodeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 1:
                    message.newPassword = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendValidationCodeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendValidationCodeRequest message.
         * @function verify
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendValidationCodeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (typeof message.newPassword !== "boolean")
                    return "newPassword: boolean expected";
            return null;
        };

        /**
         * Creates a SendValidationCodeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendValidationCodeRequest} SendValidationCodeRequest
         */
        SendValidationCodeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendValidationCodeRequest)
                return object;
            let message = new $root.api.SendValidationCodeRequest();
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.newPassword != null)
                message.newPassword = Boolean(object.newPassword);
            return message;
        };

        /**
         * Creates a plain object from a SendValidationCodeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendValidationCodeRequest
         * @static
         * @param {api.SendValidationCodeRequest} message SendValidationCodeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendValidationCodeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.newPassword = false;
                object.emailAddress = "";
            }
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = message.newPassword;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            return object;
        };

        /**
         * Converts this SendValidationCodeRequest to JSON.
         * @function toJSON
         * @memberof api.SendValidationCodeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendValidationCodeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendValidationCodeRequest;
    })();

    api.ValidateRequest = (function() {

        /**
         * Properties of a ValidateRequest.
         * @memberof api
         * @interface IValidateRequest
         * @property {string|null} [validationCode] ValidateRequest validationCode
         * @property {string|null} [emailAddress] ValidateRequest emailAddress
         * @property {string|null} [newPassword] ValidateRequest newPassword
         */

        /**
         * Constructs a new ValidateRequest.
         * @memberof api
         * @classdesc Represents a ValidateRequest.
         * @implements IValidateRequest
         * @constructor
         * @param {api.IValidateRequest=} [properties] Properties to set
         */
        function ValidateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidateRequest validationCode.
         * @member {string} validationCode
         * @memberof api.ValidateRequest
         * @instance
         */
        ValidateRequest.prototype.validationCode = "";

        /**
         * ValidateRequest emailAddress.
         * @member {string} emailAddress
         * @memberof api.ValidateRequest
         * @instance
         */
        ValidateRequest.prototype.emailAddress = "";

        /**
         * ValidateRequest newPassword.
         * @member {string} newPassword
         * @memberof api.ValidateRequest
         * @instance
         */
        ValidateRequest.prototype.newPassword = "";

        /**
         * Creates a new ValidateRequest instance using the specified properties.
         * @function create
         * @memberof api.ValidateRequest
         * @static
         * @param {api.IValidateRequest=} [properties] Properties to set
         * @returns {api.ValidateRequest} ValidateRequest instance
         */
        ValidateRequest.create = function create(properties) {
            return new ValidateRequest(properties);
        };

        /**
         * Encodes the specified ValidateRequest message. Does not implicitly {@link api.ValidateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ValidateRequest
         * @static
         * @param {api.IValidateRequest} message ValidateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.validationCode != null && Object.hasOwnProperty.call(message, "validationCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.validationCode);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPassword);
            return writer;
        };

        /**
         * Encodes the specified ValidateRequest message, length delimited. Does not implicitly {@link api.ValidateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ValidateRequest
         * @static
         * @param {api.IValidateRequest} message ValidateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ValidateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ValidateRequest} ValidateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ValidateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.validationCode = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.newPassword = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ValidateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ValidateRequest} ValidateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidateRequest message.
         * @function verify
         * @memberof api.ValidateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                if (!$util.isString(message.validationCode))
                    return "validationCode: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                if (!$util.isString(message.newPassword))
                    return "newPassword: string expected";
            return null;
        };

        /**
         * Creates a ValidateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ValidateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ValidateRequest} ValidateRequest
         */
        ValidateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ValidateRequest)
                return object;
            let message = new $root.api.ValidateRequest();
            if (object.validationCode != null)
                message.validationCode = String(object.validationCode);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.newPassword != null)
                message.newPassword = String(object.newPassword);
            return message;
        };

        /**
         * Creates a plain object from a ValidateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ValidateRequest
         * @static
         * @param {api.ValidateRequest} message ValidateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.validationCode = "";
                object.emailAddress = "";
                object.newPassword = "";
            }
            if (message.validationCode != null && message.hasOwnProperty("validationCode"))
                object.validationCode = message.validationCode;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                object.newPassword = message.newPassword;
            return object;
        };

        /**
         * Converts this ValidateRequest to JSON.
         * @function toJSON
         * @memberof api.ValidateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ValidateRequest;
    })();

    api.AuthenticateRequest = (function() {

        /**
         * Properties of an AuthenticateRequest.
         * @memberof api
         * @interface IAuthenticateRequest
         * @property {string|null} [token] AuthenticateRequest token
         */

        /**
         * Constructs a new AuthenticateRequest.
         * @memberof api
         * @classdesc Represents an AuthenticateRequest.
         * @implements IAuthenticateRequest
         * @constructor
         * @param {api.IAuthenticateRequest=} [properties] Properties to set
         */
        function AuthenticateRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateRequest token.
         * @member {string} token
         * @memberof api.AuthenticateRequest
         * @instance
         */
        AuthenticateRequest.prototype.token = "";

        /**
         * Creates a new AuthenticateRequest instance using the specified properties.
         * @function create
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.IAuthenticateRequest=} [properties] Properties to set
         * @returns {api.AuthenticateRequest} AuthenticateRequest instance
         */
        AuthenticateRequest.create = function create(properties) {
            return new AuthenticateRequest(properties);
        };

        /**
         * Encodes the specified AuthenticateRequest message. Does not implicitly {@link api.AuthenticateRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.IAuthenticateRequest} message AuthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
            return writer;
        };

        /**
         * Encodes the specified AuthenticateRequest message, length delimited. Does not implicitly {@link api.AuthenticateRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.IAuthenticateRequest} message AuthenticateRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AuthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AuthenticateRequest} AuthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AuthenticateRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.token = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AuthenticateRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AuthenticateRequest} AuthenticateRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateRequest message.
         * @function verify
         * @memberof api.AuthenticateRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            return null;
        };

        /**
         * Creates an AuthenticateRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AuthenticateRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AuthenticateRequest} AuthenticateRequest
         */
        AuthenticateRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AuthenticateRequest)
                return object;
            let message = new $root.api.AuthenticateRequest();
            if (object.token != null)
                message.token = String(object.token);
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AuthenticateRequest
         * @static
         * @param {api.AuthenticateRequest} message AuthenticateRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.token = "";
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            return object;
        };

        /**
         * Converts this AuthenticateRequest to JSON.
         * @function toJSON
         * @memberof api.AuthenticateRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuthenticateRequest;
    })();

    api.CreateTopicRequest = (function() {

        /**
         * Properties of a CreateTopicRequest.
         * @memberof api
         * @interface ICreateTopicRequest
         * @property {api.ITopic|null} [topic] CreateTopicRequest topic
         */

        /**
         * Constructs a new CreateTopicRequest.
         * @memberof api
         * @classdesc Represents a CreateTopicRequest.
         * @implements ICreateTopicRequest
         * @constructor
         * @param {api.ICreateTopicRequest=} [properties] Properties to set
         */
        function CreateTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateTopicRequest topic.
         * @member {api.ITopic|null|undefined} topic
         * @memberof api.CreateTopicRequest
         * @instance
         */
        CreateTopicRequest.prototype.topic = null;

        /**
         * Creates a new CreateTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateTopicRequest
         * @static
         * @param {api.ICreateTopicRequest=} [properties] Properties to set
         * @returns {api.CreateTopicRequest} CreateTopicRequest instance
         */
        CreateTopicRequest.create = function create(properties) {
            return new CreateTopicRequest(properties);
        };

        /**
         * Encodes the specified CreateTopicRequest message. Does not implicitly {@link api.CreateTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateTopicRequest
         * @static
         * @param {api.ICreateTopicRequest} message CreateTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.Topic.encode(message.topic, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateTopicRequest message, length delimited. Does not implicitly {@link api.CreateTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateTopicRequest
         * @static
         * @param {api.ICreateTopicRequest} message CreateTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateTopicRequest} CreateTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topic = $root.api.Topic.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateTopicRequest} CreateTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateTopicRequest message.
         * @function verify
         * @memberof api.CreateTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.Topic.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            return null;
        };

        /**
         * Creates a CreateTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateTopicRequest} CreateTopicRequest
         */
        CreateTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateTopicRequest)
                return object;
            let message = new $root.api.CreateTopicRequest();
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.CreateTopicRequest.topic: object expected");
                message.topic = $root.api.Topic.fromObject(object.topic);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateTopicRequest
         * @static
         * @param {api.CreateTopicRequest} message CreateTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.topic = null;
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.Topic.toObject(message.topic, options);
            return object;
        };

        /**
         * Converts this CreateTopicRequest to JSON.
         * @function toJSON
         * @memberof api.CreateTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateTopicRequest;
    })();

    api.TopicUpdate = (function() {

        /**
         * Properties of a TopicUpdate.
         * @memberof api
         * @interface ITopicUpdate
         * @property {boolean|null} [name] TopicUpdate name
         * @property {boolean|null} [description] TopicUpdate description
         * @property {boolean|null} [minAge] TopicUpdate minAge
         * @property {boolean|null} [maxAge] TopicUpdate maxAge
         * @property {boolean|null} [maxParticipants] TopicUpdate maxParticipants
         * @property {boolean|null} [tag] TopicUpdate tag
         * @property {boolean|null} [costType] TopicUpdate costType
         * @property {boolean|null} [cost] TopicUpdate cost
         * @property {boolean|null} [currency] TopicUpdate currency
         * @property {boolean|null} [searchable] TopicUpdate searchable
         * @property {boolean|null} [allowSearchable] TopicUpdate allowSearchable
         * @property {boolean|null} [pendingReview] TopicUpdate pendingReview
         * @property {boolean|null} [reviewMessage] TopicUpdate reviewMessage
         * @property {boolean|null} [vanityName] TopicUpdate vanityName
         */

        /**
         * Constructs a new TopicUpdate.
         * @memberof api
         * @classdesc Represents a TopicUpdate.
         * @implements ITopicUpdate
         * @constructor
         * @param {api.ITopicUpdate=} [properties] Properties to set
         */
        function TopicUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TopicUpdate name.
         * @member {boolean} name
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.name = false;

        /**
         * TopicUpdate description.
         * @member {boolean} description
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.description = false;

        /**
         * TopicUpdate minAge.
         * @member {boolean} minAge
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.minAge = false;

        /**
         * TopicUpdate maxAge.
         * @member {boolean} maxAge
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.maxAge = false;

        /**
         * TopicUpdate maxParticipants.
         * @member {boolean} maxParticipants
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.maxParticipants = false;

        /**
         * TopicUpdate tag.
         * @member {boolean} tag
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.tag = false;

        /**
         * TopicUpdate costType.
         * @member {boolean} costType
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.costType = false;

        /**
         * TopicUpdate cost.
         * @member {boolean} cost
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.cost = false;

        /**
         * TopicUpdate currency.
         * @member {boolean} currency
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.currency = false;

        /**
         * TopicUpdate searchable.
         * @member {boolean} searchable
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.searchable = false;

        /**
         * TopicUpdate allowSearchable.
         * @member {boolean} allowSearchable
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.allowSearchable = false;

        /**
         * TopicUpdate pendingReview.
         * @member {boolean} pendingReview
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.pendingReview = false;

        /**
         * TopicUpdate reviewMessage.
         * @member {boolean} reviewMessage
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.reviewMessage = false;

        /**
         * TopicUpdate vanityName.
         * @member {boolean} vanityName
         * @memberof api.TopicUpdate
         * @instance
         */
        TopicUpdate.prototype.vanityName = false;

        /**
         * Creates a new TopicUpdate instance using the specified properties.
         * @function create
         * @memberof api.TopicUpdate
         * @static
         * @param {api.ITopicUpdate=} [properties] Properties to set
         * @returns {api.TopicUpdate} TopicUpdate instance
         */
        TopicUpdate.create = function create(properties) {
            return new TopicUpdate(properties);
        };

        /**
         * Encodes the specified TopicUpdate message. Does not implicitly {@link api.TopicUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.TopicUpdate
         * @static
         * @param {api.ITopicUpdate} message TopicUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.description);
            if (message.minAge != null && Object.hasOwnProperty.call(message, "minAge"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.minAge);
            if (message.maxAge != null && Object.hasOwnProperty.call(message, "maxAge"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.maxAge);
            if (message.maxParticipants != null && Object.hasOwnProperty.call(message, "maxParticipants"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.maxParticipants);
            if (message.tag != null && Object.hasOwnProperty.call(message, "tag"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.tag);
            if (message.costType != null && Object.hasOwnProperty.call(message, "costType"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.costType);
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.cost);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.currency);
            if (message.searchable != null && Object.hasOwnProperty.call(message, "searchable"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.searchable);
            if (message.allowSearchable != null && Object.hasOwnProperty.call(message, "allowSearchable"))
                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.allowSearchable);
            if (message.pendingReview != null && Object.hasOwnProperty.call(message, "pendingReview"))
                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.pendingReview);
            if (message.reviewMessage != null && Object.hasOwnProperty.call(message, "reviewMessage"))
                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.reviewMessage);
            if (message.vanityName != null && Object.hasOwnProperty.call(message, "vanityName"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.vanityName);
            return writer;
        };

        /**
         * Encodes the specified TopicUpdate message, length delimited. Does not implicitly {@link api.TopicUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TopicUpdate
         * @static
         * @param {api.ITopicUpdate} message TopicUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TopicUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.TopicUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TopicUpdate} TopicUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TopicUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.bool();
                    break;
                case 2:
                    message.description = reader.bool();
                    break;
                case 3:
                    message.minAge = reader.bool();
                    break;
                case 4:
                    message.maxAge = reader.bool();
                    break;
                case 5:
                    message.maxParticipants = reader.bool();
                    break;
                case 6:
                    message.tag = reader.bool();
                    break;
                case 8:
                    message.costType = reader.bool();
                    break;
                case 9:
                    message.cost = reader.bool();
                    break;
                case 10:
                    message.currency = reader.bool();
                    break;
                case 11:
                    message.searchable = reader.bool();
                    break;
                case 12:
                    message.allowSearchable = reader.bool();
                    break;
                case 13:
                    message.pendingReview = reader.bool();
                    break;
                case 14:
                    message.reviewMessage = reader.bool();
                    break;
                case 15:
                    message.vanityName = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TopicUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TopicUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TopicUpdate} TopicUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TopicUpdate message.
         * @function verify
         * @memberof api.TopicUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TopicUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (typeof message.name !== "boolean")
                    return "name: boolean expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (typeof message.description !== "boolean")
                    return "description: boolean expected";
            if (message.minAge != null && message.hasOwnProperty("minAge"))
                if (typeof message.minAge !== "boolean")
                    return "minAge: boolean expected";
            if (message.maxAge != null && message.hasOwnProperty("maxAge"))
                if (typeof message.maxAge !== "boolean")
                    return "maxAge: boolean expected";
            if (message.maxParticipants != null && message.hasOwnProperty("maxParticipants"))
                if (typeof message.maxParticipants !== "boolean")
                    return "maxParticipants: boolean expected";
            if (message.tag != null && message.hasOwnProperty("tag"))
                if (typeof message.tag !== "boolean")
                    return "tag: boolean expected";
            if (message.costType != null && message.hasOwnProperty("costType"))
                if (typeof message.costType !== "boolean")
                    return "costType: boolean expected";
            if (message.cost != null && message.hasOwnProperty("cost"))
                if (typeof message.cost !== "boolean")
                    return "cost: boolean expected";
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (typeof message.currency !== "boolean")
                    return "currency: boolean expected";
            if (message.searchable != null && message.hasOwnProperty("searchable"))
                if (typeof message.searchable !== "boolean")
                    return "searchable: boolean expected";
            if (message.allowSearchable != null && message.hasOwnProperty("allowSearchable"))
                if (typeof message.allowSearchable !== "boolean")
                    return "allowSearchable: boolean expected";
            if (message.pendingReview != null && message.hasOwnProperty("pendingReview"))
                if (typeof message.pendingReview !== "boolean")
                    return "pendingReview: boolean expected";
            if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
                if (typeof message.reviewMessage !== "boolean")
                    return "reviewMessage: boolean expected";
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                if (typeof message.vanityName !== "boolean")
                    return "vanityName: boolean expected";
            return null;
        };

        /**
         * Creates a TopicUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TopicUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TopicUpdate} TopicUpdate
         */
        TopicUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TopicUpdate)
                return object;
            let message = new $root.api.TopicUpdate();
            if (object.name != null)
                message.name = Boolean(object.name);
            if (object.description != null)
                message.description = Boolean(object.description);
            if (object.minAge != null)
                message.minAge = Boolean(object.minAge);
            if (object.maxAge != null)
                message.maxAge = Boolean(object.maxAge);
            if (object.maxParticipants != null)
                message.maxParticipants = Boolean(object.maxParticipants);
            if (object.tag != null)
                message.tag = Boolean(object.tag);
            if (object.costType != null)
                message.costType = Boolean(object.costType);
            if (object.cost != null)
                message.cost = Boolean(object.cost);
            if (object.currency != null)
                message.currency = Boolean(object.currency);
            if (object.searchable != null)
                message.searchable = Boolean(object.searchable);
            if (object.allowSearchable != null)
                message.allowSearchable = Boolean(object.allowSearchable);
            if (object.pendingReview != null)
                message.pendingReview = Boolean(object.pendingReview);
            if (object.reviewMessage != null)
                message.reviewMessage = Boolean(object.reviewMessage);
            if (object.vanityName != null)
                message.vanityName = Boolean(object.vanityName);
            return message;
        };

        /**
         * Creates a plain object from a TopicUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TopicUpdate
         * @static
         * @param {api.TopicUpdate} message TopicUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = false;
                object.description = false;
                object.minAge = false;
                object.maxAge = false;
                object.maxParticipants = false;
                object.tag = false;
                object.costType = false;
                object.cost = false;
                object.currency = false;
                object.searchable = false;
                object.allowSearchable = false;
                object.pendingReview = false;
                object.reviewMessage = false;
                object.vanityName = false;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.minAge != null && message.hasOwnProperty("minAge"))
                object.minAge = message.minAge;
            if (message.maxAge != null && message.hasOwnProperty("maxAge"))
                object.maxAge = message.maxAge;
            if (message.maxParticipants != null && message.hasOwnProperty("maxParticipants"))
                object.maxParticipants = message.maxParticipants;
            if (message.tag != null && message.hasOwnProperty("tag"))
                object.tag = message.tag;
            if (message.costType != null && message.hasOwnProperty("costType"))
                object.costType = message.costType;
            if (message.cost != null && message.hasOwnProperty("cost"))
                object.cost = message.cost;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.searchable != null && message.hasOwnProperty("searchable"))
                object.searchable = message.searchable;
            if (message.allowSearchable != null && message.hasOwnProperty("allowSearchable"))
                object.allowSearchable = message.allowSearchable;
            if (message.pendingReview != null && message.hasOwnProperty("pendingReview"))
                object.pendingReview = message.pendingReview;
            if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
                object.reviewMessage = message.reviewMessage;
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                object.vanityName = message.vanityName;
            return object;
        };

        /**
         * Converts this TopicUpdate to JSON.
         * @function toJSON
         * @memberof api.TopicUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicUpdate;
    })();

    api.UpdateTopicRequest = (function() {

        /**
         * Properties of an UpdateTopicRequest.
         * @memberof api
         * @interface IUpdateTopicRequest
         * @property {api.ITopicUpdate|null} [update] UpdateTopicRequest update
         * @property {api.ITopic|null} [topic] UpdateTopicRequest topic
         * @property {boolean|null} [force] UpdateTopicRequest force
         */

        /**
         * Constructs a new UpdateTopicRequest.
         * @memberof api
         * @classdesc Represents an UpdateTopicRequest.
         * @implements IUpdateTopicRequest
         * @constructor
         * @param {api.IUpdateTopicRequest=} [properties] Properties to set
         */
        function UpdateTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateTopicRequest update.
         * @member {api.ITopicUpdate|null|undefined} update
         * @memberof api.UpdateTopicRequest
         * @instance
         */
        UpdateTopicRequest.prototype.update = null;

        /**
         * UpdateTopicRequest topic.
         * @member {api.ITopic|null|undefined} topic
         * @memberof api.UpdateTopicRequest
         * @instance
         */
        UpdateTopicRequest.prototype.topic = null;

        /**
         * UpdateTopicRequest force.
         * @member {boolean} force
         * @memberof api.UpdateTopicRequest
         * @instance
         */
        UpdateTopicRequest.prototype.force = false;

        /**
         * Creates a new UpdateTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {api.IUpdateTopicRequest=} [properties] Properties to set
         * @returns {api.UpdateTopicRequest} UpdateTopicRequest instance
         */
        UpdateTopicRequest.create = function create(properties) {
            return new UpdateTopicRequest(properties);
        };

        /**
         * Encodes the specified UpdateTopicRequest message. Does not implicitly {@link api.UpdateTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {api.IUpdateTopicRequest} message UpdateTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.TopicUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.Topic.encode(message.topic, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified UpdateTopicRequest message, length delimited. Does not implicitly {@link api.UpdateTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {api.IUpdateTopicRequest} message UpdateTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateTopicRequest} UpdateTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.TopicUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.topic = $root.api.Topic.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateTopicRequest} UpdateTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateTopicRequest message.
         * @function verify
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.TopicUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.Topic.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateTopicRequest} UpdateTopicRequest
         */
        UpdateTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateTopicRequest)
                return object;
            let message = new $root.api.UpdateTopicRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateTopicRequest.update: object expected");
                message.update = $root.api.TopicUpdate.fromObject(object.update);
            }
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.UpdateTopicRequest.topic: object expected");
                message.topic = $root.api.Topic.fromObject(object.topic);
            }
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from an UpdateTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateTopicRequest
         * @static
         * @param {api.UpdateTopicRequest} message UpdateTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.topic = null;
                object.force = false;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.TopicUpdate.toObject(message.update, options);
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.Topic.toObject(message.topic, options);
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this UpdateTopicRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateTopicRequest;
    })();

    api.DeleteTopicRequest = (function() {

        /**
         * Properties of a DeleteTopicRequest.
         * @memberof api
         * @interface IDeleteTopicRequest
         * @property {string|null} [topicId] DeleteTopicRequest topicId
         * @property {boolean|null} [force] DeleteTopicRequest force
         */

        /**
         * Constructs a new DeleteTopicRequest.
         * @memberof api
         * @classdesc Represents a DeleteTopicRequest.
         * @implements IDeleteTopicRequest
         * @constructor
         * @param {api.IDeleteTopicRequest=} [properties] Properties to set
         */
        function DeleteTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteTopicRequest topicId.
         * @member {string} topicId
         * @memberof api.DeleteTopicRequest
         * @instance
         */
        DeleteTopicRequest.prototype.topicId = "";

        /**
         * DeleteTopicRequest force.
         * @member {boolean} force
         * @memberof api.DeleteTopicRequest
         * @instance
         */
        DeleteTopicRequest.prototype.force = false;

        /**
         * Creates a new DeleteTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {api.IDeleteTopicRequest=} [properties] Properties to set
         * @returns {api.DeleteTopicRequest} DeleteTopicRequest instance
         */
        DeleteTopicRequest.create = function create(properties) {
            return new DeleteTopicRequest(properties);
        };

        /**
         * Encodes the specified DeleteTopicRequest message. Does not implicitly {@link api.DeleteTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {api.IDeleteTopicRequest} message DeleteTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.topicId);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified DeleteTopicRequest message, length delimited. Does not implicitly {@link api.DeleteTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {api.IDeleteTopicRequest} message DeleteTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteTopicRequest} DeleteTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topicId = reader.string();
                    break;
                case 2:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteTopicRequest} DeleteTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteTopicRequest message.
         * @function verify
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteTopicRequest} DeleteTopicRequest
         */
        DeleteTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteTopicRequest)
                return object;
            let message = new $root.api.DeleteTopicRequest();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a DeleteTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteTopicRequest
         * @static
         * @param {api.DeleteTopicRequest} message DeleteTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.topicId = "";
                object.force = false;
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this DeleteTopicRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteTopicRequest;
    })();

    api.DownloadCalendarRequest = (function() {

        /**
         * Properties of a DownloadCalendarRequest.
         * @memberof api
         * @interface IDownloadCalendarRequest
         * @property {string|null} [roomId] DownloadCalendarRequest roomId
         * @property {string|null} [timeId] DownloadCalendarRequest timeId
         * @property {string|null} [topicId] DownloadCalendarRequest topicId
         * @property {string|null} [format] DownloadCalendarRequest format
         */

        /**
         * Constructs a new DownloadCalendarRequest.
         * @memberof api
         * @classdesc Represents a DownloadCalendarRequest.
         * @implements IDownloadCalendarRequest
         * @constructor
         * @param {api.IDownloadCalendarRequest=} [properties] Properties to set
         */
        function DownloadCalendarRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadCalendarRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.DownloadCalendarRequest
         * @instance
         */
        DownloadCalendarRequest.prototype.roomId = null;

        /**
         * DownloadCalendarRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.DownloadCalendarRequest
         * @instance
         */
        DownloadCalendarRequest.prototype.timeId = null;

        /**
         * DownloadCalendarRequest topicId.
         * @member {string} topicId
         * @memberof api.DownloadCalendarRequest
         * @instance
         */
        DownloadCalendarRequest.prototype.topicId = "";

        /**
         * DownloadCalendarRequest format.
         * @member {string} format
         * @memberof api.DownloadCalendarRequest
         * @instance
         */
        DownloadCalendarRequest.prototype.format = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * DownloadCalendarRequest calendarOption.
         * @member {"roomId"|"timeId"|undefined} calendarOption
         * @memberof api.DownloadCalendarRequest
         * @instance
         */
        Object.defineProperty(DownloadCalendarRequest.prototype, "calendarOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new DownloadCalendarRequest instance using the specified properties.
         * @function create
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {api.IDownloadCalendarRequest=} [properties] Properties to set
         * @returns {api.DownloadCalendarRequest} DownloadCalendarRequest instance
         */
        DownloadCalendarRequest.create = function create(properties) {
            return new DownloadCalendarRequest(properties);
        };

        /**
         * Encodes the specified DownloadCalendarRequest message. Does not implicitly {@link api.DownloadCalendarRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {api.IDownloadCalendarRequest} message DownloadCalendarRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DownloadCalendarRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.format != null && Object.hasOwnProperty.call(message, "format"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.format);
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.topicId);
            return writer;
        };

        /**
         * Encodes the specified DownloadCalendarRequest message, length delimited. Does not implicitly {@link api.DownloadCalendarRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {api.IDownloadCalendarRequest} message DownloadCalendarRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DownloadCalendarRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DownloadCalendarRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DownloadCalendarRequest} DownloadCalendarRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DownloadCalendarRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DownloadCalendarRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 4:
                    message.topicId = reader.string();
                    break;
                case 3:
                    message.format = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DownloadCalendarRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DownloadCalendarRequest} DownloadCalendarRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DownloadCalendarRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DownloadCalendarRequest message.
         * @function verify
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DownloadCalendarRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.calendarOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.calendarOption === 1)
                    return "calendarOption: multiple values";
                properties.calendarOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.format != null && message.hasOwnProperty("format"))
                if (!$util.isString(message.format))
                    return "format: string expected";
            return null;
        };

        /**
         * Creates a DownloadCalendarRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DownloadCalendarRequest} DownloadCalendarRequest
         */
        DownloadCalendarRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DownloadCalendarRequest)
                return object;
            let message = new $root.api.DownloadCalendarRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.format != null)
                message.format = String(object.format);
            return message;
        };

        /**
         * Creates a plain object from a DownloadCalendarRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DownloadCalendarRequest
         * @static
         * @param {api.DownloadCalendarRequest} message DownloadCalendarRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DownloadCalendarRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.format = "";
                object.topicId = "";
            }
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.calendarOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.calendarOption = "timeId";
            }
            if (message.format != null && message.hasOwnProperty("format"))
                object.format = message.format;
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            return object;
        };

        /**
         * Converts this DownloadCalendarRequest to JSON.
         * @function toJSON
         * @memberof api.DownloadCalendarRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DownloadCalendarRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DownloadCalendarRequest;
    })();

    api.JoinTopicRequest = (function() {

        /**
         * Properties of a JoinTopicRequest.
         * @memberof api
         * @interface IJoinTopicRequest
         * @property {string|null} [roomId] JoinTopicRequest roomId
         * @property {string|null} [timeId] JoinTopicRequest timeId
         * @property {string|null} [topicId] JoinTopicRequest topicId
         * @property {string|null} [emailAddress] JoinTopicRequest emailAddress
         * @property {boolean|null} [checkCost] JoinTopicRequest checkCost
         * @property {number|null} [expectedCost] JoinTopicRequest expectedCost
         * @property {boolean|null} [preview] JoinTopicRequest preview
         */

        /**
         * Constructs a new JoinTopicRequest.
         * @memberof api
         * @classdesc Represents a JoinTopicRequest.
         * @implements IJoinTopicRequest
         * @constructor
         * @param {api.IJoinTopicRequest=} [properties] Properties to set
         */
        function JoinTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JoinTopicRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.roomId = null;

        /**
         * JoinTopicRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.timeId = null;

        /**
         * JoinTopicRequest topicId.
         * @member {string} topicId
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.topicId = "";

        /**
         * JoinTopicRequest emailAddress.
         * @member {string} emailAddress
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.emailAddress = "";

        /**
         * JoinTopicRequest checkCost.
         * @member {boolean} checkCost
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.checkCost = false;

        /**
         * JoinTopicRequest expectedCost.
         * @member {number} expectedCost
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.expectedCost = 0;

        /**
         * JoinTopicRequest preview.
         * @member {boolean} preview
         * @memberof api.JoinTopicRequest
         * @instance
         */
        JoinTopicRequest.prototype.preview = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * JoinTopicRequest joinOption.
         * @member {"roomId"|"timeId"|undefined} joinOption
         * @memberof api.JoinTopicRequest
         * @instance
         */
        Object.defineProperty(JoinTopicRequest.prototype, "joinOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new JoinTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.JoinTopicRequest
         * @static
         * @param {api.IJoinTopicRequest=} [properties] Properties to set
         * @returns {api.JoinTopicRequest} JoinTopicRequest instance
         */
        JoinTopicRequest.create = function create(properties) {
            return new JoinTopicRequest(properties);
        };

        /**
         * Encodes the specified JoinTopicRequest message. Does not implicitly {@link api.JoinTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.JoinTopicRequest
         * @static
         * @param {api.IJoinTopicRequest} message JoinTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JoinTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.topicId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.emailAddress);
            if (message.checkCost != null && Object.hasOwnProperty.call(message, "checkCost"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.checkCost);
            if (message.expectedCost != null && Object.hasOwnProperty.call(message, "expectedCost"))
                writer.uint32(/* id 6, wireType 1 =*/49).double(message.expectedCost);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.preview);
            return writer;
        };

        /**
         * Encodes the specified JoinTopicRequest message, length delimited. Does not implicitly {@link api.JoinTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JoinTopicRequest
         * @static
         * @param {api.IJoinTopicRequest} message JoinTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JoinTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JoinTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.JoinTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JoinTopicRequest} JoinTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JoinTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JoinTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.topicId = reader.string();
                    break;
                case 4:
                    message.emailAddress = reader.string();
                    break;
                case 5:
                    message.checkCost = reader.bool();
                    break;
                case 6:
                    message.expectedCost = reader.double();
                    break;
                case 7:
                    message.preview = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JoinTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JoinTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JoinTopicRequest} JoinTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JoinTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JoinTopicRequest message.
         * @function verify
         * @memberof api.JoinTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JoinTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.joinOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.joinOption === 1)
                    return "joinOption: multiple values";
                properties.joinOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.checkCost != null && message.hasOwnProperty("checkCost"))
                if (typeof message.checkCost !== "boolean")
                    return "checkCost: boolean expected";
            if (message.expectedCost != null && message.hasOwnProperty("expectedCost"))
                if (typeof message.expectedCost !== "number")
                    return "expectedCost: number expected";
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (typeof message.preview !== "boolean")
                    return "preview: boolean expected";
            return null;
        };

        /**
         * Creates a JoinTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JoinTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JoinTopicRequest} JoinTopicRequest
         */
        JoinTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JoinTopicRequest)
                return object;
            let message = new $root.api.JoinTopicRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.checkCost != null)
                message.checkCost = Boolean(object.checkCost);
            if (object.expectedCost != null)
                message.expectedCost = Number(object.expectedCost);
            if (object.preview != null)
                message.preview = Boolean(object.preview);
            return message;
        };

        /**
         * Creates a plain object from a JoinTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JoinTopicRequest
         * @static
         * @param {api.JoinTopicRequest} message JoinTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JoinTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.topicId = "";
                object.emailAddress = "";
                object.checkCost = false;
                object.expectedCost = 0;
                object.preview = false;
            }
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.joinOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.joinOption = "timeId";
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.checkCost != null && message.hasOwnProperty("checkCost"))
                object.checkCost = message.checkCost;
            if (message.expectedCost != null && message.hasOwnProperty("expectedCost"))
                object.expectedCost = options.json && !isFinite(message.expectedCost) ? String(message.expectedCost) : message.expectedCost;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            return object;
        };

        /**
         * Converts this JoinTopicRequest to JSON.
         * @function toJSON
         * @memberof api.JoinTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JoinTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JoinTopicRequest;
    })();

    api.LeaveTopicRequest = (function() {

        /**
         * Properties of a LeaveTopicRequest.
         * @memberof api
         * @interface ILeaveTopicRequest
         * @property {string|null} [userId] LeaveTopicRequest userId
         * @property {string|null} [roomId] LeaveTopicRequest roomId
         * @property {string|null} [timeId] LeaveTopicRequest timeId
         */

        /**
         * Constructs a new LeaveTopicRequest.
         * @memberof api
         * @classdesc Represents a LeaveTopicRequest.
         * @implements ILeaveTopicRequest
         * @constructor
         * @param {api.ILeaveTopicRequest=} [properties] Properties to set
         */
        function LeaveTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LeaveTopicRequest userId.
         * @member {string} userId
         * @memberof api.LeaveTopicRequest
         * @instance
         */
        LeaveTopicRequest.prototype.userId = "";

        /**
         * LeaveTopicRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.LeaveTopicRequest
         * @instance
         */
        LeaveTopicRequest.prototype.roomId = null;

        /**
         * LeaveTopicRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.LeaveTopicRequest
         * @instance
         */
        LeaveTopicRequest.prototype.timeId = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * LeaveTopicRequest leaveOption.
         * @member {"roomId"|"timeId"|undefined} leaveOption
         * @memberof api.LeaveTopicRequest
         * @instance
         */
        Object.defineProperty(LeaveTopicRequest.prototype, "leaveOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new LeaveTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {api.ILeaveTopicRequest=} [properties] Properties to set
         * @returns {api.LeaveTopicRequest} LeaveTopicRequest instance
         */
        LeaveTopicRequest.create = function create(properties) {
            return new LeaveTopicRequest(properties);
        };

        /**
         * Encodes the specified LeaveTopicRequest message. Does not implicitly {@link api.LeaveTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {api.ILeaveTopicRequest} message LeaveTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaveTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
            return writer;
        };

        /**
         * Encodes the specified LeaveTopicRequest message, length delimited. Does not implicitly {@link api.LeaveTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {api.ILeaveTopicRequest} message LeaveTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaveTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LeaveTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LeaveTopicRequest} LeaveTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaveTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LeaveTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.userId = reader.string();
                    break;
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LeaveTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LeaveTopicRequest} LeaveTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaveTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LeaveTopicRequest message.
         * @function verify
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LeaveTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.leaveOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.leaveOption === 1)
                    return "leaveOption: multiple values";
                properties.leaveOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            return null;
        };

        /**
         * Creates a LeaveTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LeaveTopicRequest} LeaveTopicRequest
         */
        LeaveTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LeaveTopicRequest)
                return object;
            let message = new $root.api.LeaveTopicRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            return message;
        };

        /**
         * Creates a plain object from a LeaveTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LeaveTopicRequest
         * @static
         * @param {api.LeaveTopicRequest} message LeaveTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LeaveTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = "";
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.leaveOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.leaveOption = "timeId";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            return object;
        };

        /**
         * Converts this LeaveTopicRequest to JSON.
         * @function toJSON
         * @memberof api.LeaveTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LeaveTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LeaveTopicRequest;
    })();

    api.WaitlistTopicRequest = (function() {

        /**
         * Properties of a WaitlistTopicRequest.
         * @memberof api
         * @interface IWaitlistTopicRequest
         * @property {string|null} [roomId] WaitlistTopicRequest roomId
         * @property {string|null} [timeId] WaitlistTopicRequest timeId
         * @property {number|Long|null} [startTime] WaitlistTopicRequest startTime
         */

        /**
         * Constructs a new WaitlistTopicRequest.
         * @memberof api
         * @classdesc Represents a WaitlistTopicRequest.
         * @implements IWaitlistTopicRequest
         * @constructor
         * @param {api.IWaitlistTopicRequest=} [properties] Properties to set
         */
        function WaitlistTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WaitlistTopicRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.WaitlistTopicRequest
         * @instance
         */
        WaitlistTopicRequest.prototype.roomId = null;

        /**
         * WaitlistTopicRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.WaitlistTopicRequest
         * @instance
         */
        WaitlistTopicRequest.prototype.timeId = null;

        /**
         * WaitlistTopicRequest startTime.
         * @member {number|Long} startTime
         * @memberof api.WaitlistTopicRequest
         * @instance
         */
        WaitlistTopicRequest.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * WaitlistTopicRequest waitlistOption.
         * @member {"roomId"|"timeId"|undefined} waitlistOption
         * @memberof api.WaitlistTopicRequest
         * @instance
         */
        Object.defineProperty(WaitlistTopicRequest.prototype, "waitlistOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new WaitlistTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {api.IWaitlistTopicRequest=} [properties] Properties to set
         * @returns {api.WaitlistTopicRequest} WaitlistTopicRequest instance
         */
        WaitlistTopicRequest.create = function create(properties) {
            return new WaitlistTopicRequest(properties);
        };

        /**
         * Encodes the specified WaitlistTopicRequest message. Does not implicitly {@link api.WaitlistTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {api.IWaitlistTopicRequest} message WaitlistTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WaitlistTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.startTime);
            return writer;
        };

        /**
         * Encodes the specified WaitlistTopicRequest message, length delimited. Does not implicitly {@link api.WaitlistTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {api.IWaitlistTopicRequest} message WaitlistTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WaitlistTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WaitlistTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WaitlistTopicRequest} WaitlistTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WaitlistTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WaitlistTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.startTime = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WaitlistTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WaitlistTopicRequest} WaitlistTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WaitlistTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WaitlistTopicRequest message.
         * @function verify
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WaitlistTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.waitlistOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.waitlistOption === 1)
                    return "waitlistOption: multiple values";
                properties.waitlistOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a WaitlistTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WaitlistTopicRequest} WaitlistTopicRequest
         */
        WaitlistTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WaitlistTopicRequest)
                return object;
            let message = new $root.api.WaitlistTopicRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = false;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a WaitlistTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WaitlistTopicRequest
         * @static
         * @param {api.WaitlistTopicRequest} message WaitlistTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WaitlistTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.waitlistOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.waitlistOption = "timeId";
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber() : message.startTime;
            return object;
        };

        /**
         * Converts this WaitlistTopicRequest to JSON.
         * @function toJSON
         * @memberof api.WaitlistTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WaitlistTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WaitlistTopicRequest;
    })();

    api.CreateRoomRequest = (function() {

        /**
         * Properties of a CreateRoomRequest.
         * @memberof api
         * @interface ICreateRoomRequest
         * @property {api.IRoom|null} [room] CreateRoomRequest room
         */

        /**
         * Constructs a new CreateRoomRequest.
         * @memberof api
         * @classdesc Represents a CreateRoomRequest.
         * @implements ICreateRoomRequest
         * @constructor
         * @param {api.ICreateRoomRequest=} [properties] Properties to set
         */
        function CreateRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateRoomRequest room.
         * @member {api.IRoom|null|undefined} room
         * @memberof api.CreateRoomRequest
         * @instance
         */
        CreateRoomRequest.prototype.room = null;

        /**
         * Creates a new CreateRoomRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateRoomRequest
         * @static
         * @param {api.ICreateRoomRequest=} [properties] Properties to set
         * @returns {api.CreateRoomRequest} CreateRoomRequest instance
         */
        CreateRoomRequest.create = function create(properties) {
            return new CreateRoomRequest(properties);
        };

        /**
         * Encodes the specified CreateRoomRequest message. Does not implicitly {@link api.CreateRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateRoomRequest
         * @static
         * @param {api.ICreateRoomRequest} message CreateRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.Room.encode(message.room, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateRoomRequest message, length delimited. Does not implicitly {@link api.CreateRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateRoomRequest
         * @static
         * @param {api.ICreateRoomRequest} message CreateRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateRoomRequest} CreateRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.room = $root.api.Room.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateRoomRequest} CreateRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateRoomRequest message.
         * @function verify
         * @memberof api.CreateRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.Room.verify(message.room);
                if (error)
                    return "room." + error;
            }
            return null;
        };

        /**
         * Creates a CreateRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateRoomRequest} CreateRoomRequest
         */
        CreateRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateRoomRequest)
                return object;
            let message = new $root.api.CreateRoomRequest();
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.CreateRoomRequest.room: object expected");
                message.room = $root.api.Room.fromObject(object.room);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateRoomRequest
         * @static
         * @param {api.CreateRoomRequest} message CreateRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.room = null;
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.Room.toObject(message.room, options);
            return object;
        };

        /**
         * Converts this CreateRoomRequest to JSON.
         * @function toJSON
         * @memberof api.CreateRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateRoomRequest;
    })();

    api.AddTopicReviewRequest = (function() {

        /**
         * Properties of an AddTopicReviewRequest.
         * @memberof api
         * @interface IAddTopicReviewRequest
         * @property {string|null} [roomId] AddTopicReviewRequest roomId
         * @property {string|null} [timeId] AddTopicReviewRequest timeId
         * @property {number|null} [rating] AddTopicReviewRequest rating
         * @property {string|null} [review] AddTopicReviewRequest review
         */

        /**
         * Constructs a new AddTopicReviewRequest.
         * @memberof api
         * @classdesc Represents an AddTopicReviewRequest.
         * @implements IAddTopicReviewRequest
         * @constructor
         * @param {api.IAddTopicReviewRequest=} [properties] Properties to set
         */
        function AddTopicReviewRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddTopicReviewRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.AddTopicReviewRequest
         * @instance
         */
        AddTopicReviewRequest.prototype.roomId = null;

        /**
         * AddTopicReviewRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.AddTopicReviewRequest
         * @instance
         */
        AddTopicReviewRequest.prototype.timeId = null;

        /**
         * AddTopicReviewRequest rating.
         * @member {number} rating
         * @memberof api.AddTopicReviewRequest
         * @instance
         */
        AddTopicReviewRequest.prototype.rating = 0;

        /**
         * AddTopicReviewRequest review.
         * @member {string} review
         * @memberof api.AddTopicReviewRequest
         * @instance
         */
        AddTopicReviewRequest.prototype.review = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * AddTopicReviewRequest reviewOption.
         * @member {"roomId"|"timeId"|undefined} reviewOption
         * @memberof api.AddTopicReviewRequest
         * @instance
         */
        Object.defineProperty(AddTopicReviewRequest.prototype, "reviewOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new AddTopicReviewRequest instance using the specified properties.
         * @function create
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {api.IAddTopicReviewRequest=} [properties] Properties to set
         * @returns {api.AddTopicReviewRequest} AddTopicReviewRequest instance
         */
        AddTopicReviewRequest.create = function create(properties) {
            return new AddTopicReviewRequest(properties);
        };

        /**
         * Encodes the specified AddTopicReviewRequest message. Does not implicitly {@link api.AddTopicReviewRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {api.IAddTopicReviewRequest} message AddTopicReviewRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTopicReviewRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rating);
            if (message.review != null && Object.hasOwnProperty.call(message, "review"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.review);
            return writer;
        };

        /**
         * Encodes the specified AddTopicReviewRequest message, length delimited. Does not implicitly {@link api.AddTopicReviewRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {api.IAddTopicReviewRequest} message AddTopicReviewRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTopicReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddTopicReviewRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddTopicReviewRequest} AddTopicReviewRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTopicReviewRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddTopicReviewRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.rating = reader.int32();
                    break;
                case 4:
                    message.review = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddTopicReviewRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddTopicReviewRequest} AddTopicReviewRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTopicReviewRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddTopicReviewRequest message.
         * @function verify
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddTopicReviewRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.reviewOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.reviewOption === 1)
                    return "reviewOption: multiple values";
                properties.reviewOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.rating != null && message.hasOwnProperty("rating"))
                if (!$util.isInteger(message.rating))
                    return "rating: integer expected";
            if (message.review != null && message.hasOwnProperty("review"))
                if (!$util.isString(message.review))
                    return "review: string expected";
            return null;
        };

        /**
         * Creates an AddTopicReviewRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddTopicReviewRequest} AddTopicReviewRequest
         */
        AddTopicReviewRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddTopicReviewRequest)
                return object;
            let message = new $root.api.AddTopicReviewRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.rating != null)
                message.rating = object.rating | 0;
            if (object.review != null)
                message.review = String(object.review);
            return message;
        };

        /**
         * Creates a plain object from an AddTopicReviewRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddTopicReviewRequest
         * @static
         * @param {api.AddTopicReviewRequest} message AddTopicReviewRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddTopicReviewRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.rating = 0;
                object.review = "";
            }
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.reviewOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.reviewOption = "timeId";
            }
            if (message.rating != null && message.hasOwnProperty("rating"))
                object.rating = message.rating;
            if (message.review != null && message.hasOwnProperty("review"))
                object.review = message.review;
            return object;
        };

        /**
         * Converts this AddTopicReviewRequest to JSON.
         * @function toJSON
         * @memberof api.AddTopicReviewRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddTopicReviewRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTopicReviewRequest;
    })();

    api.UpdateTopicReviewRequest = (function() {

        /**
         * Properties of an UpdateTopicReviewRequest.
         * @memberof api
         * @interface IUpdateTopicReviewRequest
         * @property {string|null} [roomId] UpdateTopicReviewRequest roomId
         * @property {string|null} [timeId] UpdateTopicReviewRequest timeId
         * @property {number|null} [rating] UpdateTopicReviewRequest rating
         * @property {string|null} [review] UpdateTopicReviewRequest review
         */

        /**
         * Constructs a new UpdateTopicReviewRequest.
         * @memberof api
         * @classdesc Represents an UpdateTopicReviewRequest.
         * @implements IUpdateTopicReviewRequest
         * @constructor
         * @param {api.IUpdateTopicReviewRequest=} [properties] Properties to set
         */
        function UpdateTopicReviewRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateTopicReviewRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.UpdateTopicReviewRequest
         * @instance
         */
        UpdateTopicReviewRequest.prototype.roomId = null;

        /**
         * UpdateTopicReviewRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.UpdateTopicReviewRequest
         * @instance
         */
        UpdateTopicReviewRequest.prototype.timeId = null;

        /**
         * UpdateTopicReviewRequest rating.
         * @member {number} rating
         * @memberof api.UpdateTopicReviewRequest
         * @instance
         */
        UpdateTopicReviewRequest.prototype.rating = 0;

        /**
         * UpdateTopicReviewRequest review.
         * @member {string} review
         * @memberof api.UpdateTopicReviewRequest
         * @instance
         */
        UpdateTopicReviewRequest.prototype.review = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * UpdateTopicReviewRequest reviewOption.
         * @member {"roomId"|"timeId"|undefined} reviewOption
         * @memberof api.UpdateTopicReviewRequest
         * @instance
         */
        Object.defineProperty(UpdateTopicReviewRequest.prototype, "reviewOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new UpdateTopicReviewRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {api.IUpdateTopicReviewRequest=} [properties] Properties to set
         * @returns {api.UpdateTopicReviewRequest} UpdateTopicReviewRequest instance
         */
        UpdateTopicReviewRequest.create = function create(properties) {
            return new UpdateTopicReviewRequest(properties);
        };

        /**
         * Encodes the specified UpdateTopicReviewRequest message. Does not implicitly {@link api.UpdateTopicReviewRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {api.IUpdateTopicReviewRequest} message UpdateTopicReviewRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicReviewRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.rating);
            if (message.review != null && Object.hasOwnProperty.call(message, "review"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.review);
            return writer;
        };

        /**
         * Encodes the specified UpdateTopicReviewRequest message, length delimited. Does not implicitly {@link api.UpdateTopicReviewRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {api.IUpdateTopicReviewRequest} message UpdateTopicReviewRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicReviewRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateTopicReviewRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateTopicReviewRequest} UpdateTopicReviewRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicReviewRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateTopicReviewRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.rating = reader.int32();
                    break;
                case 4:
                    message.review = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateTopicReviewRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateTopicReviewRequest} UpdateTopicReviewRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicReviewRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateTopicReviewRequest message.
         * @function verify
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateTopicReviewRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.reviewOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.reviewOption === 1)
                    return "reviewOption: multiple values";
                properties.reviewOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.rating != null && message.hasOwnProperty("rating"))
                if (!$util.isInteger(message.rating))
                    return "rating: integer expected";
            if (message.review != null && message.hasOwnProperty("review"))
                if (!$util.isString(message.review))
                    return "review: string expected";
            return null;
        };

        /**
         * Creates an UpdateTopicReviewRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateTopicReviewRequest} UpdateTopicReviewRequest
         */
        UpdateTopicReviewRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateTopicReviewRequest)
                return object;
            let message = new $root.api.UpdateTopicReviewRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.rating != null)
                message.rating = object.rating | 0;
            if (object.review != null)
                message.review = String(object.review);
            return message;
        };

        /**
         * Creates a plain object from an UpdateTopicReviewRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateTopicReviewRequest
         * @static
         * @param {api.UpdateTopicReviewRequest} message UpdateTopicReviewRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateTopicReviewRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.rating = 0;
                object.review = "";
            }
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.reviewOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.reviewOption = "timeId";
            }
            if (message.rating != null && message.hasOwnProperty("rating"))
                object.rating = message.rating;
            if (message.review != null && message.hasOwnProperty("review"))
                object.review = message.review;
            return object;
        };

        /**
         * Converts this UpdateTopicReviewRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateTopicReviewRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateTopicReviewRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateTopicReviewRequest;
    })();

    api.RoomUpdate = (function() {

        /**
         * Properties of a RoomUpdate.
         * @memberof api
         * @interface IRoomUpdate
         * @property {boolean|null} [section] RoomUpdate section
         * @property {boolean|null} [room] RoomUpdate room
         * @property {boolean|null} [scheduleSetId] RoomUpdate scheduleSetId
         */

        /**
         * Constructs a new RoomUpdate.
         * @memberof api
         * @classdesc Represents a RoomUpdate.
         * @implements IRoomUpdate
         * @constructor
         * @param {api.IRoomUpdate=} [properties] Properties to set
         */
        function RoomUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoomUpdate section.
         * @member {boolean} section
         * @memberof api.RoomUpdate
         * @instance
         */
        RoomUpdate.prototype.section = false;

        /**
         * RoomUpdate room.
         * @member {boolean} room
         * @memberof api.RoomUpdate
         * @instance
         */
        RoomUpdate.prototype.room = false;

        /**
         * RoomUpdate scheduleSetId.
         * @member {boolean} scheduleSetId
         * @memberof api.RoomUpdate
         * @instance
         */
        RoomUpdate.prototype.scheduleSetId = false;

        /**
         * Creates a new RoomUpdate instance using the specified properties.
         * @function create
         * @memberof api.RoomUpdate
         * @static
         * @param {api.IRoomUpdate=} [properties] Properties to set
         * @returns {api.RoomUpdate} RoomUpdate instance
         */
        RoomUpdate.create = function create(properties) {
            return new RoomUpdate(properties);
        };

        /**
         * Encodes the specified RoomUpdate message. Does not implicitly {@link api.RoomUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.RoomUpdate
         * @static
         * @param {api.IRoomUpdate} message RoomUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.section != null && Object.hasOwnProperty.call(message, "section"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.section);
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.room);
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.scheduleSetId);
            return writer;
        };

        /**
         * Encodes the specified RoomUpdate message, length delimited. Does not implicitly {@link api.RoomUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RoomUpdate
         * @static
         * @param {api.IRoomUpdate} message RoomUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoomUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.RoomUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RoomUpdate} RoomUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RoomUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.section = reader.bool();
                    break;
                case 2:
                    message.room = reader.bool();
                    break;
                case 3:
                    message.scheduleSetId = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoomUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RoomUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RoomUpdate} RoomUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoomUpdate message.
         * @function verify
         * @memberof api.RoomUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoomUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.section != null && message.hasOwnProperty("section"))
                if (typeof message.section !== "boolean")
                    return "section: boolean expected";
            if (message.room != null && message.hasOwnProperty("room"))
                if (typeof message.room !== "boolean")
                    return "room: boolean expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (typeof message.scheduleSetId !== "boolean")
                    return "scheduleSetId: boolean expected";
            return null;
        };

        /**
         * Creates a RoomUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RoomUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RoomUpdate} RoomUpdate
         */
        RoomUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RoomUpdate)
                return object;
            let message = new $root.api.RoomUpdate();
            if (object.section != null)
                message.section = Boolean(object.section);
            if (object.room != null)
                message.room = Boolean(object.room);
            if (object.scheduleSetId != null)
                message.scheduleSetId = Boolean(object.scheduleSetId);
            return message;
        };

        /**
         * Creates a plain object from a RoomUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RoomUpdate
         * @static
         * @param {api.RoomUpdate} message RoomUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoomUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.section = false;
                object.room = false;
                object.scheduleSetId = false;
            }
            if (message.section != null && message.hasOwnProperty("section"))
                object.section = message.section;
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = message.room;
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            return object;
        };

        /**
         * Converts this RoomUpdate to JSON.
         * @function toJSON
         * @memberof api.RoomUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoomUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoomUpdate;
    })();

    api.UpdateRoomRequest = (function() {

        /**
         * Properties of an UpdateRoomRequest.
         * @memberof api
         * @interface IUpdateRoomRequest
         * @property {api.IRoomUpdate|null} [update] UpdateRoomRequest update
         * @property {api.IRoom|null} [room] UpdateRoomRequest room
         * @property {boolean|null} [force] UpdateRoomRequest force
         */

        /**
         * Constructs a new UpdateRoomRequest.
         * @memberof api
         * @classdesc Represents an UpdateRoomRequest.
         * @implements IUpdateRoomRequest
         * @constructor
         * @param {api.IUpdateRoomRequest=} [properties] Properties to set
         */
        function UpdateRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateRoomRequest update.
         * @member {api.IRoomUpdate|null|undefined} update
         * @memberof api.UpdateRoomRequest
         * @instance
         */
        UpdateRoomRequest.prototype.update = null;

        /**
         * UpdateRoomRequest room.
         * @member {api.IRoom|null|undefined} room
         * @memberof api.UpdateRoomRequest
         * @instance
         */
        UpdateRoomRequest.prototype.room = null;

        /**
         * UpdateRoomRequest force.
         * @member {boolean} force
         * @memberof api.UpdateRoomRequest
         * @instance
         */
        UpdateRoomRequest.prototype.force = false;

        /**
         * Creates a new UpdateRoomRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {api.IUpdateRoomRequest=} [properties] Properties to set
         * @returns {api.UpdateRoomRequest} UpdateRoomRequest instance
         */
        UpdateRoomRequest.create = function create(properties) {
            return new UpdateRoomRequest(properties);
        };

        /**
         * Encodes the specified UpdateRoomRequest message. Does not implicitly {@link api.UpdateRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {api.IUpdateRoomRequest} message UpdateRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.RoomUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.Room.encode(message.room, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified UpdateRoomRequest message, length delimited. Does not implicitly {@link api.UpdateRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {api.IUpdateRoomRequest} message UpdateRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateRoomRequest} UpdateRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.RoomUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.room = $root.api.Room.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateRoomRequest} UpdateRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateRoomRequest message.
         * @function verify
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.RoomUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.Room.verify(message.room);
                if (error)
                    return "room." + error;
            }
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateRoomRequest} UpdateRoomRequest
         */
        UpdateRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateRoomRequest)
                return object;
            let message = new $root.api.UpdateRoomRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateRoomRequest.update: object expected");
                message.update = $root.api.RoomUpdate.fromObject(object.update);
            }
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.UpdateRoomRequest.room: object expected");
                message.room = $root.api.Room.fromObject(object.room);
            }
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from an UpdateRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateRoomRequest
         * @static
         * @param {api.UpdateRoomRequest} message UpdateRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.room = null;
                object.force = false;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.RoomUpdate.toObject(message.update, options);
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.Room.toObject(message.room, options);
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this UpdateRoomRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateRoomRequest;
    })();

    api.DeleteRoomRequest = (function() {

        /**
         * Properties of a DeleteRoomRequest.
         * @memberof api
         * @interface IDeleteRoomRequest
         * @property {string|null} [roomId] DeleteRoomRequest roomId
         * @property {boolean|null} [force] DeleteRoomRequest force
         */

        /**
         * Constructs a new DeleteRoomRequest.
         * @memberof api
         * @classdesc Represents a DeleteRoomRequest.
         * @implements IDeleteRoomRequest
         * @constructor
         * @param {api.IDeleteRoomRequest=} [properties] Properties to set
         */
        function DeleteRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteRoomRequest roomId.
         * @member {string} roomId
         * @memberof api.DeleteRoomRequest
         * @instance
         */
        DeleteRoomRequest.prototype.roomId = "";

        /**
         * DeleteRoomRequest force.
         * @member {boolean} force
         * @memberof api.DeleteRoomRequest
         * @instance
         */
        DeleteRoomRequest.prototype.force = false;

        /**
         * Creates a new DeleteRoomRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {api.IDeleteRoomRequest=} [properties] Properties to set
         * @returns {api.DeleteRoomRequest} DeleteRoomRequest instance
         */
        DeleteRoomRequest.create = function create(properties) {
            return new DeleteRoomRequest(properties);
        };

        /**
         * Encodes the specified DeleteRoomRequest message. Does not implicitly {@link api.DeleteRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {api.IDeleteRoomRequest} message DeleteRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified DeleteRoomRequest message, length delimited. Does not implicitly {@link api.DeleteRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {api.IDeleteRoomRequest} message DeleteRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteRoomRequest} DeleteRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteRoomRequest} DeleteRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteRoomRequest message.
         * @function verify
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteRoomRequest} DeleteRoomRequest
         */
        DeleteRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteRoomRequest)
                return object;
            let message = new $root.api.DeleteRoomRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a DeleteRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteRoomRequest
         * @static
         * @param {api.DeleteRoomRequest} message DeleteRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.roomId = "";
                object.force = false;
            }
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this DeleteRoomRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteRoomRequest;
    })();

    api.AddMemberRequest = (function() {

        /**
         * Properties of an AddMemberRequest.
         * @memberof api
         * @interface IAddMemberRequest
         * @property {string|null} [objectId] AddMemberRequest objectId
         * @property {api.IMember|null} [member] AddMemberRequest member
         */

        /**
         * Constructs a new AddMemberRequest.
         * @memberof api
         * @classdesc Represents an AddMemberRequest.
         * @implements IAddMemberRequest
         * @constructor
         * @param {api.IAddMemberRequest=} [properties] Properties to set
         */
        function AddMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddMemberRequest objectId.
         * @member {string} objectId
         * @memberof api.AddMemberRequest
         * @instance
         */
        AddMemberRequest.prototype.objectId = "";

        /**
         * AddMemberRequest member.
         * @member {api.IMember|null|undefined} member
         * @memberof api.AddMemberRequest
         * @instance
         */
        AddMemberRequest.prototype.member = null;

        /**
         * Creates a new AddMemberRequest instance using the specified properties.
         * @function create
         * @memberof api.AddMemberRequest
         * @static
         * @param {api.IAddMemberRequest=} [properties] Properties to set
         * @returns {api.AddMemberRequest} AddMemberRequest instance
         */
        AddMemberRequest.create = function create(properties) {
            return new AddMemberRequest(properties);
        };

        /**
         * Encodes the specified AddMemberRequest message. Does not implicitly {@link api.AddMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddMemberRequest
         * @static
         * @param {api.IAddMemberRequest} message AddMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                $root.api.Member.encode(message.member, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddMemberRequest message, length delimited. Does not implicitly {@link api.AddMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddMemberRequest
         * @static
         * @param {api.IAddMemberRequest} message AddMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddMemberRequest} AddMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.member = $root.api.Member.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddMemberRequest} AddMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddMemberRequest message.
         * @function verify
         * @memberof api.AddMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.member != null && message.hasOwnProperty("member")) {
                let error = $root.api.Member.verify(message.member);
                if (error)
                    return "member." + error;
            }
            return null;
        };

        /**
         * Creates an AddMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddMemberRequest} AddMemberRequest
         */
        AddMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddMemberRequest)
                return object;
            let message = new $root.api.AddMemberRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.member != null) {
                if (typeof object.member !== "object")
                    throw TypeError(".api.AddMemberRequest.member: object expected");
                message.member = $root.api.Member.fromObject(object.member);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddMemberRequest
         * @static
         * @param {api.AddMemberRequest} message AddMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.member = null;
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.member != null && message.hasOwnProperty("member"))
                object.member = $root.api.Member.toObject(message.member, options);
            return object;
        };

        /**
         * Converts this AddMemberRequest to JSON.
         * @function toJSON
         * @memberof api.AddMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddMemberRequest;
    })();

    api.MemberUpdate = (function() {

        /**
         * Properties of a MemberUpdate.
         * @memberof api
         * @interface IMemberUpdate
         * @property {boolean|null} [instructor] MemberUpdate instructor
         * @property {boolean|null} [modify] MemberUpdate modify
         * @property {boolean|null} [owner] MemberUpdate owner
         */

        /**
         * Constructs a new MemberUpdate.
         * @memberof api
         * @classdesc Represents a MemberUpdate.
         * @implements IMemberUpdate
         * @constructor
         * @param {api.IMemberUpdate=} [properties] Properties to set
         */
        function MemberUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberUpdate instructor.
         * @member {boolean} instructor
         * @memberof api.MemberUpdate
         * @instance
         */
        MemberUpdate.prototype.instructor = false;

        /**
         * MemberUpdate modify.
         * @member {boolean} modify
         * @memberof api.MemberUpdate
         * @instance
         */
        MemberUpdate.prototype.modify = false;

        /**
         * MemberUpdate owner.
         * @member {boolean} owner
         * @memberof api.MemberUpdate
         * @instance
         */
        MemberUpdate.prototype.owner = false;

        /**
         * Creates a new MemberUpdate instance using the specified properties.
         * @function create
         * @memberof api.MemberUpdate
         * @static
         * @param {api.IMemberUpdate=} [properties] Properties to set
         * @returns {api.MemberUpdate} MemberUpdate instance
         */
        MemberUpdate.create = function create(properties) {
            return new MemberUpdate(properties);
        };

        /**
         * Encodes the specified MemberUpdate message. Does not implicitly {@link api.MemberUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.MemberUpdate
         * @static
         * @param {api.IMemberUpdate} message MemberUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.instructor != null && Object.hasOwnProperty.call(message, "instructor"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.instructor);
            if (message.modify != null && Object.hasOwnProperty.call(message, "modify"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.modify);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.owner);
            return writer;
        };

        /**
         * Encodes the specified MemberUpdate message, length delimited. Does not implicitly {@link api.MemberUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberUpdate
         * @static
         * @param {api.IMemberUpdate} message MemberUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberUpdate} MemberUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.instructor = reader.bool();
                    break;
                case 2:
                    message.modify = reader.bool();
                    break;
                case 3:
                    message.owner = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberUpdate} MemberUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberUpdate message.
         * @function verify
         * @memberof api.MemberUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                if (typeof message.instructor !== "boolean")
                    return "instructor: boolean expected";
            if (message.modify != null && message.hasOwnProperty("modify"))
                if (typeof message.modify !== "boolean")
                    return "modify: boolean expected";
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (typeof message.owner !== "boolean")
                    return "owner: boolean expected";
            return null;
        };

        /**
         * Creates a MemberUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberUpdate} MemberUpdate
         */
        MemberUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberUpdate)
                return object;
            let message = new $root.api.MemberUpdate();
            if (object.instructor != null)
                message.instructor = Boolean(object.instructor);
            if (object.modify != null)
                message.modify = Boolean(object.modify);
            if (object.owner != null)
                message.owner = Boolean(object.owner);
            return message;
        };

        /**
         * Creates a plain object from a MemberUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberUpdate
         * @static
         * @param {api.MemberUpdate} message MemberUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.instructor = false;
                object.modify = false;
                object.owner = false;
            }
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                object.instructor = message.instructor;
            if (message.modify != null && message.hasOwnProperty("modify"))
                object.modify = message.modify;
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            return object;
        };

        /**
         * Converts this MemberUpdate to JSON.
         * @function toJSON
         * @memberof api.MemberUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberUpdate;
    })();

    api.UpdateMemberRequest = (function() {

        /**
         * Properties of an UpdateMemberRequest.
         * @memberof api
         * @interface IUpdateMemberRequest
         * @property {string|null} [objectId] UpdateMemberRequest objectId
         * @property {api.IMemberUpdate|null} [update] UpdateMemberRequest update
         * @property {api.IMember|null} [member] UpdateMemberRequest member
         */

        /**
         * Constructs a new UpdateMemberRequest.
         * @memberof api
         * @classdesc Represents an UpdateMemberRequest.
         * @implements IUpdateMemberRequest
         * @constructor
         * @param {api.IUpdateMemberRequest=} [properties] Properties to set
         */
        function UpdateMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateMemberRequest objectId.
         * @member {string} objectId
         * @memberof api.UpdateMemberRequest
         * @instance
         */
        UpdateMemberRequest.prototype.objectId = "";

        /**
         * UpdateMemberRequest update.
         * @member {api.IMemberUpdate|null|undefined} update
         * @memberof api.UpdateMemberRequest
         * @instance
         */
        UpdateMemberRequest.prototype.update = null;

        /**
         * UpdateMemberRequest member.
         * @member {api.IMember|null|undefined} member
         * @memberof api.UpdateMemberRequest
         * @instance
         */
        UpdateMemberRequest.prototype.member = null;

        /**
         * Creates a new UpdateMemberRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {api.IUpdateMemberRequest=} [properties] Properties to set
         * @returns {api.UpdateMemberRequest} UpdateMemberRequest instance
         */
        UpdateMemberRequest.create = function create(properties) {
            return new UpdateMemberRequest(properties);
        };

        /**
         * Encodes the specified UpdateMemberRequest message. Does not implicitly {@link api.UpdateMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {api.IUpdateMemberRequest} message UpdateMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.MemberUpdate.encode(message.update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.member != null && Object.hasOwnProperty.call(message, "member"))
                $root.api.Member.encode(message.member, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateMemberRequest message, length delimited. Does not implicitly {@link api.UpdateMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {api.IUpdateMemberRequest} message UpdateMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateMemberRequest} UpdateMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.update = $root.api.MemberUpdate.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.member = $root.api.Member.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateMemberRequest} UpdateMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateMemberRequest message.
         * @function verify
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.MemberUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.member != null && message.hasOwnProperty("member")) {
                let error = $root.api.Member.verify(message.member);
                if (error)
                    return "member." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateMemberRequest} UpdateMemberRequest
         */
        UpdateMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateMemberRequest)
                return object;
            let message = new $root.api.UpdateMemberRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateMemberRequest.update: object expected");
                message.update = $root.api.MemberUpdate.fromObject(object.update);
            }
            if (object.member != null) {
                if (typeof object.member !== "object")
                    throw TypeError(".api.UpdateMemberRequest.member: object expected");
                message.member = $root.api.Member.fromObject(object.member);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateMemberRequest
         * @static
         * @param {api.UpdateMemberRequest} message UpdateMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.update = null;
                object.member = null;
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.MemberUpdate.toObject(message.update, options);
            if (message.member != null && message.hasOwnProperty("member"))
                object.member = $root.api.Member.toObject(message.member, options);
            return object;
        };

        /**
         * Converts this UpdateMemberRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateMemberRequest;
    })();

    api.DeleteMemberRequest = (function() {

        /**
         * Properties of a DeleteMemberRequest.
         * @memberof api
         * @interface IDeleteMemberRequest
         * @property {string|null} [objectId] DeleteMemberRequest objectId
         * @property {string|null} [entityId] DeleteMemberRequest entityId
         */

        /**
         * Constructs a new DeleteMemberRequest.
         * @memberof api
         * @classdesc Represents a DeleteMemberRequest.
         * @implements IDeleteMemberRequest
         * @constructor
         * @param {api.IDeleteMemberRequest=} [properties] Properties to set
         */
        function DeleteMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteMemberRequest objectId.
         * @member {string} objectId
         * @memberof api.DeleteMemberRequest
         * @instance
         */
        DeleteMemberRequest.prototype.objectId = "";

        /**
         * DeleteMemberRequest entityId.
         * @member {string} entityId
         * @memberof api.DeleteMemberRequest
         * @instance
         */
        DeleteMemberRequest.prototype.entityId = "";

        /**
         * Creates a new DeleteMemberRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {api.IDeleteMemberRequest=} [properties] Properties to set
         * @returns {api.DeleteMemberRequest} DeleteMemberRequest instance
         */
        DeleteMemberRequest.create = function create(properties) {
            return new DeleteMemberRequest(properties);
        };

        /**
         * Encodes the specified DeleteMemberRequest message. Does not implicitly {@link api.DeleteMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {api.IDeleteMemberRequest} message DeleteMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.entityId);
            return writer;
        };

        /**
         * Encodes the specified DeleteMemberRequest message, length delimited. Does not implicitly {@link api.DeleteMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {api.IDeleteMemberRequest} message DeleteMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteMemberRequest} DeleteMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.entityId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteMemberRequest} DeleteMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteMemberRequest message.
         * @function verify
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isString(message.entityId))
                    return "entityId: string expected";
            return null;
        };

        /**
         * Creates a DeleteMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteMemberRequest} DeleteMemberRequest
         */
        DeleteMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteMemberRequest)
                return object;
            let message = new $root.api.DeleteMemberRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteMemberRequest
         * @static
         * @param {api.DeleteMemberRequest} message DeleteMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.entityId = "";
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            return object;
        };

        /**
         * Converts this DeleteMemberRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteMemberRequest;
    })();

    api.BoolFilter = (function() {

        /**
         * Properties of a BoolFilter.
         * @memberof api
         * @interface IBoolFilter
         * @property {boolean|null} [filterValue] BoolFilter filterValue
         */

        /**
         * Constructs a new BoolFilter.
         * @memberof api
         * @classdesc Represents a BoolFilter.
         * @implements IBoolFilter
         * @constructor
         * @param {api.IBoolFilter=} [properties] Properties to set
         */
        function BoolFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BoolFilter filterValue.
         * @member {boolean} filterValue
         * @memberof api.BoolFilter
         * @instance
         */
        BoolFilter.prototype.filterValue = false;

        /**
         * Creates a new BoolFilter instance using the specified properties.
         * @function create
         * @memberof api.BoolFilter
         * @static
         * @param {api.IBoolFilter=} [properties] Properties to set
         * @returns {api.BoolFilter} BoolFilter instance
         */
        BoolFilter.create = function create(properties) {
            return new BoolFilter(properties);
        };

        /**
         * Encodes the specified BoolFilter message. Does not implicitly {@link api.BoolFilter.verify|verify} messages.
         * @function encode
         * @memberof api.BoolFilter
         * @static
         * @param {api.IBoolFilter} message BoolFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoolFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterValue != null && Object.hasOwnProperty.call(message, "filterValue"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.filterValue);
            return writer;
        };

        /**
         * Encodes the specified BoolFilter message, length delimited. Does not implicitly {@link api.BoolFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.BoolFilter
         * @static
         * @param {api.IBoolFilter} message BoolFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BoolFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BoolFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.BoolFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.BoolFilter} BoolFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoolFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.BoolFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.filterValue = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BoolFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.BoolFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.BoolFilter} BoolFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BoolFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BoolFilter message.
         * @function verify
         * @memberof api.BoolFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BoolFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                if (typeof message.filterValue !== "boolean")
                    return "filterValue: boolean expected";
            return null;
        };

        /**
         * Creates a BoolFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.BoolFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.BoolFilter} BoolFilter
         */
        BoolFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.BoolFilter)
                return object;
            let message = new $root.api.BoolFilter();
            if (object.filterValue != null)
                message.filterValue = Boolean(object.filterValue);
            return message;
        };

        /**
         * Creates a plain object from a BoolFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.BoolFilter
         * @static
         * @param {api.BoolFilter} message BoolFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BoolFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.filterValue = false;
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                object.filterValue = message.filterValue;
            return object;
        };

        /**
         * Converts this BoolFilter to JSON.
         * @function toJSON
         * @memberof api.BoolFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BoolFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BoolFilter;
    })();

    api.StringFilter = (function() {

        /**
         * Properties of a StringFilter.
         * @memberof api
         * @interface IStringFilter
         * @property {string|null} [filterValue] StringFilter filterValue
         */

        /**
         * Constructs a new StringFilter.
         * @memberof api
         * @classdesc Represents a StringFilter.
         * @implements IStringFilter
         * @constructor
         * @param {api.IStringFilter=} [properties] Properties to set
         */
        function StringFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * StringFilter filterValue.
         * @member {string} filterValue
         * @memberof api.StringFilter
         * @instance
         */
        StringFilter.prototype.filterValue = "";

        /**
         * Creates a new StringFilter instance using the specified properties.
         * @function create
         * @memberof api.StringFilter
         * @static
         * @param {api.IStringFilter=} [properties] Properties to set
         * @returns {api.StringFilter} StringFilter instance
         */
        StringFilter.create = function create(properties) {
            return new StringFilter(properties);
        };

        /**
         * Encodes the specified StringFilter message. Does not implicitly {@link api.StringFilter.verify|verify} messages.
         * @function encode
         * @memberof api.StringFilter
         * @static
         * @param {api.IStringFilter} message StringFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.filterValue != null && Object.hasOwnProperty.call(message, "filterValue"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.filterValue);
            return writer;
        };

        /**
         * Encodes the specified StringFilter message, length delimited. Does not implicitly {@link api.StringFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.StringFilter
         * @static
         * @param {api.IStringFilter} message StringFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        StringFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a StringFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.StringFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.StringFilter} StringFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.StringFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.filterValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a StringFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.StringFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.StringFilter} StringFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        StringFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a StringFilter message.
         * @function verify
         * @memberof api.StringFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        StringFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                if (!$util.isString(message.filterValue))
                    return "filterValue: string expected";
            return null;
        };

        /**
         * Creates a StringFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.StringFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.StringFilter} StringFilter
         */
        StringFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.StringFilter)
                return object;
            let message = new $root.api.StringFilter();
            if (object.filterValue != null)
                message.filterValue = String(object.filterValue);
            return message;
        };

        /**
         * Creates a plain object from a StringFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.StringFilter
         * @static
         * @param {api.StringFilter} message StringFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        StringFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.filterValue = "";
            if (message.filterValue != null && message.hasOwnProperty("filterValue"))
                object.filterValue = message.filterValue;
            return object;
        };

        /**
         * Converts this StringFilter to JSON.
         * @function toJSON
         * @memberof api.StringFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        StringFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return StringFilter;
    })();

    api.DeleteMembersRequest = (function() {

        /**
         * Properties of a DeleteMembersRequest.
         * @memberof api
         * @interface IDeleteMembersRequest
         * @property {string|null} [objectId] DeleteMembersRequest objectId
         * @property {api.IBoolFilter|null} [instructorFilter] DeleteMembersRequest instructorFilter
         * @property {api.IBoolFilter|null} [modifyFilter] DeleteMembersRequest modifyFilter
         */

        /**
         * Constructs a new DeleteMembersRequest.
         * @memberof api
         * @classdesc Represents a DeleteMembersRequest.
         * @implements IDeleteMembersRequest
         * @constructor
         * @param {api.IDeleteMembersRequest=} [properties] Properties to set
         */
        function DeleteMembersRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteMembersRequest objectId.
         * @member {string} objectId
         * @memberof api.DeleteMembersRequest
         * @instance
         */
        DeleteMembersRequest.prototype.objectId = "";

        /**
         * DeleteMembersRequest instructorFilter.
         * @member {api.IBoolFilter|null|undefined} instructorFilter
         * @memberof api.DeleteMembersRequest
         * @instance
         */
        DeleteMembersRequest.prototype.instructorFilter = null;

        /**
         * DeleteMembersRequest modifyFilter.
         * @member {api.IBoolFilter|null|undefined} modifyFilter
         * @memberof api.DeleteMembersRequest
         * @instance
         */
        DeleteMembersRequest.prototype.modifyFilter = null;

        /**
         * Creates a new DeleteMembersRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {api.IDeleteMembersRequest=} [properties] Properties to set
         * @returns {api.DeleteMembersRequest} DeleteMembersRequest instance
         */
        DeleteMembersRequest.create = function create(properties) {
            return new DeleteMembersRequest(properties);
        };

        /**
         * Encodes the specified DeleteMembersRequest message. Does not implicitly {@link api.DeleteMembersRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {api.IDeleteMembersRequest} message DeleteMembersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMembersRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.instructorFilter != null && Object.hasOwnProperty.call(message, "instructorFilter"))
                $root.api.BoolFilter.encode(message.instructorFilter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.modifyFilter != null && Object.hasOwnProperty.call(message, "modifyFilter"))
                $root.api.BoolFilter.encode(message.modifyFilter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified DeleteMembersRequest message, length delimited. Does not implicitly {@link api.DeleteMembersRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {api.IDeleteMembersRequest} message DeleteMembersRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMembersRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteMembersRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteMembersRequest} DeleteMembersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMembersRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteMembersRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 3:
                    message.instructorFilter = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.modifyFilter = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteMembersRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteMembersRequest} DeleteMembersRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMembersRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteMembersRequest message.
         * @function verify
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteMembersRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.instructorFilter != null && message.hasOwnProperty("instructorFilter")) {
                let error = $root.api.BoolFilter.verify(message.instructorFilter);
                if (error)
                    return "instructorFilter." + error;
            }
            if (message.modifyFilter != null && message.hasOwnProperty("modifyFilter")) {
                let error = $root.api.BoolFilter.verify(message.modifyFilter);
                if (error)
                    return "modifyFilter." + error;
            }
            return null;
        };

        /**
         * Creates a DeleteMembersRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteMembersRequest} DeleteMembersRequest
         */
        DeleteMembersRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteMembersRequest)
                return object;
            let message = new $root.api.DeleteMembersRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.instructorFilter != null) {
                if (typeof object.instructorFilter !== "object")
                    throw TypeError(".api.DeleteMembersRequest.instructorFilter: object expected");
                message.instructorFilter = $root.api.BoolFilter.fromObject(object.instructorFilter);
            }
            if (object.modifyFilter != null) {
                if (typeof object.modifyFilter !== "object")
                    throw TypeError(".api.DeleteMembersRequest.modifyFilter: object expected");
                message.modifyFilter = $root.api.BoolFilter.fromObject(object.modifyFilter);
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteMembersRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteMembersRequest
         * @static
         * @param {api.DeleteMembersRequest} message DeleteMembersRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMembersRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.instructorFilter = null;
                object.modifyFilter = null;
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.instructorFilter != null && message.hasOwnProperty("instructorFilter"))
                object.instructorFilter = $root.api.BoolFilter.toObject(message.instructorFilter, options);
            if (message.modifyFilter != null && message.hasOwnProperty("modifyFilter"))
                object.modifyFilter = $root.api.BoolFilter.toObject(message.modifyFilter, options);
            return object;
        };

        /**
         * Converts this DeleteMembersRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteMembersRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMembersRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteMembersRequest;
    })();

    api.AnswerMemberRequest = (function() {

        /**
         * Properties of an AnswerMemberRequest.
         * @memberof api
         * @interface IAnswerMemberRequest
         * @property {string|null} [objectId] AnswerMemberRequest objectId
         * @property {string|null} [entityId] AnswerMemberRequest entityId
         * @property {boolean|null} [accept] AnswerMemberRequest accept
         */

        /**
         * Constructs a new AnswerMemberRequest.
         * @memberof api
         * @classdesc Represents an AnswerMemberRequest.
         * @implements IAnswerMemberRequest
         * @constructor
         * @param {api.IAnswerMemberRequest=} [properties] Properties to set
         */
        function AnswerMemberRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AnswerMemberRequest objectId.
         * @member {string} objectId
         * @memberof api.AnswerMemberRequest
         * @instance
         */
        AnswerMemberRequest.prototype.objectId = "";

        /**
         * AnswerMemberRequest entityId.
         * @member {string} entityId
         * @memberof api.AnswerMemberRequest
         * @instance
         */
        AnswerMemberRequest.prototype.entityId = "";

        /**
         * AnswerMemberRequest accept.
         * @member {boolean} accept
         * @memberof api.AnswerMemberRequest
         * @instance
         */
        AnswerMemberRequest.prototype.accept = false;

        /**
         * Creates a new AnswerMemberRequest instance using the specified properties.
         * @function create
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {api.IAnswerMemberRequest=} [properties] Properties to set
         * @returns {api.AnswerMemberRequest} AnswerMemberRequest instance
         */
        AnswerMemberRequest.create = function create(properties) {
            return new AnswerMemberRequest(properties);
        };

        /**
         * Encodes the specified AnswerMemberRequest message. Does not implicitly {@link api.AnswerMemberRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {api.IAnswerMemberRequest} message AnswerMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnswerMemberRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.entityId != null && Object.hasOwnProperty.call(message, "entityId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.entityId);
            if (message.accept != null && Object.hasOwnProperty.call(message, "accept"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.accept);
            return writer;
        };

        /**
         * Encodes the specified AnswerMemberRequest message, length delimited. Does not implicitly {@link api.AnswerMemberRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {api.IAnswerMemberRequest} message AnswerMemberRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnswerMemberRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AnswerMemberRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AnswerMemberRequest} AnswerMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnswerMemberRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AnswerMemberRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.entityId = reader.string();
                    break;
                case 3:
                    message.accept = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AnswerMemberRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AnswerMemberRequest} AnswerMemberRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnswerMemberRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AnswerMemberRequest message.
         * @function verify
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AnswerMemberRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                if (!$util.isString(message.entityId))
                    return "entityId: string expected";
            if (message.accept != null && message.hasOwnProperty("accept"))
                if (typeof message.accept !== "boolean")
                    return "accept: boolean expected";
            return null;
        };

        /**
         * Creates an AnswerMemberRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AnswerMemberRequest} AnswerMemberRequest
         */
        AnswerMemberRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AnswerMemberRequest)
                return object;
            let message = new $root.api.AnswerMemberRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.entityId != null)
                message.entityId = String(object.entityId);
            if (object.accept != null)
                message.accept = Boolean(object.accept);
            return message;
        };

        /**
         * Creates a plain object from an AnswerMemberRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AnswerMemberRequest
         * @static
         * @param {api.AnswerMemberRequest} message AnswerMemberRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AnswerMemberRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.entityId = "";
                object.accept = false;
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.entityId != null && message.hasOwnProperty("entityId"))
                object.entityId = message.entityId;
            if (message.accept != null && message.hasOwnProperty("accept"))
                object.accept = message.accept;
            return object;
        };

        /**
         * Converts this AnswerMemberRequest to JSON.
         * @function toJSON
         * @memberof api.AnswerMemberRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AnswerMemberRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AnswerMemberRequest;
    })();

    api.GetScheduleRequest = (function() {

        /**
         * Properties of a GetScheduleRequest.
         * @memberof api
         * @interface IGetScheduleRequest
         * @property {string|null} [scheduleId] GetScheduleRequest scheduleId
         * @property {api.IScheduleQuery|null} [query] GetScheduleRequest query
         */

        /**
         * Constructs a new GetScheduleRequest.
         * @memberof api
         * @classdesc Represents a GetScheduleRequest.
         * @implements IGetScheduleRequest
         * @constructor
         * @param {api.IGetScheduleRequest=} [properties] Properties to set
         */
        function GetScheduleRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetScheduleRequest scheduleId.
         * @member {string} scheduleId
         * @memberof api.GetScheduleRequest
         * @instance
         */
        GetScheduleRequest.prototype.scheduleId = "";

        /**
         * GetScheduleRequest query.
         * @member {api.IScheduleQuery|null|undefined} query
         * @memberof api.GetScheduleRequest
         * @instance
         */
        GetScheduleRequest.prototype.query = null;

        /**
         * Creates a new GetScheduleRequest instance using the specified properties.
         * @function create
         * @memberof api.GetScheduleRequest
         * @static
         * @param {api.IGetScheduleRequest=} [properties] Properties to set
         * @returns {api.GetScheduleRequest} GetScheduleRequest instance
         */
        GetScheduleRequest.create = function create(properties) {
            return new GetScheduleRequest(properties);
        };

        /**
         * Encodes the specified GetScheduleRequest message. Does not implicitly {@link api.GetScheduleRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetScheduleRequest
         * @static
         * @param {api.IGetScheduleRequest} message GetScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.ScheduleQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetScheduleRequest message, length delimited. Does not implicitly {@link api.GetScheduleRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetScheduleRequest
         * @static
         * @param {api.IGetScheduleRequest} message GetScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetScheduleRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetScheduleRequest} GetScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetScheduleRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleId = reader.string();
                    break;
                case 2:
                    message.query = $root.api.ScheduleQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetScheduleRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetScheduleRequest} GetScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetScheduleRequest message.
         * @function verify
         * @memberof api.GetScheduleRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetScheduleRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                if (!$util.isString(message.scheduleId))
                    return "scheduleId: string expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.ScheduleQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetScheduleRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetScheduleRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetScheduleRequest} GetScheduleRequest
         */
        GetScheduleRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetScheduleRequest)
                return object;
            let message = new $root.api.GetScheduleRequest();
            if (object.scheduleId != null)
                message.scheduleId = String(object.scheduleId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetScheduleRequest.query: object expected");
                message.query = $root.api.ScheduleQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetScheduleRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetScheduleRequest
         * @static
         * @param {api.GetScheduleRequest} message GetScheduleRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetScheduleRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.scheduleId = "";
                object.query = null;
            }
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                object.scheduleId = message.scheduleId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.ScheduleQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetScheduleRequest to JSON.
         * @function toJSON
         * @memberof api.GetScheduleRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetScheduleRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetScheduleRequest;
    })();

    api.GetTimeRequest = (function() {

        /**
         * Properties of a GetTimeRequest.
         * @memberof api
         * @interface IGetTimeRequest
         * @property {string|null} [timeId] GetTimeRequest timeId
         * @property {api.ITimeQuery|null} [query] GetTimeRequest query
         */

        /**
         * Constructs a new GetTimeRequest.
         * @memberof api
         * @classdesc Represents a GetTimeRequest.
         * @implements IGetTimeRequest
         * @constructor
         * @param {api.IGetTimeRequest=} [properties] Properties to set
         */
        function GetTimeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTimeRequest timeId.
         * @member {string} timeId
         * @memberof api.GetTimeRequest
         * @instance
         */
        GetTimeRequest.prototype.timeId = "";

        /**
         * GetTimeRequest query.
         * @member {api.ITimeQuery|null|undefined} query
         * @memberof api.GetTimeRequest
         * @instance
         */
        GetTimeRequest.prototype.query = null;

        /**
         * Creates a new GetTimeRequest instance using the specified properties.
         * @function create
         * @memberof api.GetTimeRequest
         * @static
         * @param {api.IGetTimeRequest=} [properties] Properties to set
         * @returns {api.GetTimeRequest} GetTimeRequest instance
         */
        GetTimeRequest.create = function create(properties) {
            return new GetTimeRequest(properties);
        };

        /**
         * Encodes the specified GetTimeRequest message. Does not implicitly {@link api.GetTimeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetTimeRequest
         * @static
         * @param {api.IGetTimeRequest} message GetTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTimeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.timeId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.TimeQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetTimeRequest message, length delimited. Does not implicitly {@link api.GetTimeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetTimeRequest
         * @static
         * @param {api.IGetTimeRequest} message GetTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTimeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetTimeRequest} GetTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTimeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetTimeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timeId = reader.string();
                    break;
                case 2:
                    message.query = $root.api.TimeQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTimeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetTimeRequest} GetTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTimeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTimeRequest message.
         * @function verify
         * @memberof api.GetTimeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTimeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.TimeQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetTimeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetTimeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetTimeRequest} GetTimeRequest
         */
        GetTimeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetTimeRequest)
                return object;
            let message = new $root.api.GetTimeRequest();
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetTimeRequest.query: object expected");
                message.query = $root.api.TimeQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTimeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetTimeRequest
         * @static
         * @param {api.GetTimeRequest} message GetTimeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTimeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.timeId = "";
                object.query = null;
            }
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                object.timeId = message.timeId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.TimeQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetTimeRequest to JSON.
         * @function toJSON
         * @memberof api.GetTimeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTimeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTimeRequest;
    })();

    api.AddScheduleRequest = (function() {

        /**
         * Properties of an AddScheduleRequest.
         * @memberof api
         * @interface IAddScheduleRequest
         * @property {api.ISchedule|null} [schedule] AddScheduleRequest schedule
         */

        /**
         * Constructs a new AddScheduleRequest.
         * @memberof api
         * @classdesc Represents an AddScheduleRequest.
         * @implements IAddScheduleRequest
         * @constructor
         * @param {api.IAddScheduleRequest=} [properties] Properties to set
         */
        function AddScheduleRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddScheduleRequest schedule.
         * @member {api.ISchedule|null|undefined} schedule
         * @memberof api.AddScheduleRequest
         * @instance
         */
        AddScheduleRequest.prototype.schedule = null;

        /**
         * Creates a new AddScheduleRequest instance using the specified properties.
         * @function create
         * @memberof api.AddScheduleRequest
         * @static
         * @param {api.IAddScheduleRequest=} [properties] Properties to set
         * @returns {api.AddScheduleRequest} AddScheduleRequest instance
         */
        AddScheduleRequest.create = function create(properties) {
            return new AddScheduleRequest(properties);
        };

        /**
         * Encodes the specified AddScheduleRequest message. Does not implicitly {@link api.AddScheduleRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddScheduleRequest
         * @static
         * @param {api.IAddScheduleRequest} message AddScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddScheduleRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                $root.api.Schedule.encode(message.schedule, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddScheduleRequest message, length delimited. Does not implicitly {@link api.AddScheduleRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddScheduleRequest
         * @static
         * @param {api.IAddScheduleRequest} message AddScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddScheduleRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddScheduleRequest} AddScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddScheduleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddScheduleRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.schedule = $root.api.Schedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddScheduleRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddScheduleRequest} AddScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddScheduleRequest message.
         * @function verify
         * @memberof api.AddScheduleRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddScheduleRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.schedule != null && message.hasOwnProperty("schedule")) {
                let error = $root.api.Schedule.verify(message.schedule);
                if (error)
                    return "schedule." + error;
            }
            return null;
        };

        /**
         * Creates an AddScheduleRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddScheduleRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddScheduleRequest} AddScheduleRequest
         */
        AddScheduleRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddScheduleRequest)
                return object;
            let message = new $root.api.AddScheduleRequest();
            if (object.schedule != null) {
                if (typeof object.schedule !== "object")
                    throw TypeError(".api.AddScheduleRequest.schedule: object expected");
                message.schedule = $root.api.Schedule.fromObject(object.schedule);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddScheduleRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddScheduleRequest
         * @static
         * @param {api.AddScheduleRequest} message AddScheduleRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddScheduleRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.schedule = null;
            if (message.schedule != null && message.hasOwnProperty("schedule"))
                object.schedule = $root.api.Schedule.toObject(message.schedule, options);
            return object;
        };

        /**
         * Converts this AddScheduleRequest to JSON.
         * @function toJSON
         * @memberof api.AddScheduleRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddScheduleRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddScheduleRequest;
    })();

    api.UpdateScheduleRequest = (function() {

        /**
         * Properties of an UpdateScheduleRequest.
         * @memberof api
         * @interface IUpdateScheduleRequest
         * @property {boolean|null} [force] UpdateScheduleRequest force
         * @property {api.ISchedule|null} [schedule] UpdateScheduleRequest schedule
         */

        /**
         * Constructs a new UpdateScheduleRequest.
         * @memberof api
         * @classdesc Represents an UpdateScheduleRequest.
         * @implements IUpdateScheduleRequest
         * @constructor
         * @param {api.IUpdateScheduleRequest=} [properties] Properties to set
         */
        function UpdateScheduleRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateScheduleRequest force.
         * @member {boolean} force
         * @memberof api.UpdateScheduleRequest
         * @instance
         */
        UpdateScheduleRequest.prototype.force = false;

        /**
         * UpdateScheduleRequest schedule.
         * @member {api.ISchedule|null|undefined} schedule
         * @memberof api.UpdateScheduleRequest
         * @instance
         */
        UpdateScheduleRequest.prototype.schedule = null;

        /**
         * Creates a new UpdateScheduleRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {api.IUpdateScheduleRequest=} [properties] Properties to set
         * @returns {api.UpdateScheduleRequest} UpdateScheduleRequest instance
         */
        UpdateScheduleRequest.create = function create(properties) {
            return new UpdateScheduleRequest(properties);
        };

        /**
         * Encodes the specified UpdateScheduleRequest message. Does not implicitly {@link api.UpdateScheduleRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {api.IUpdateScheduleRequest} message UpdateScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                $root.api.Schedule.encode(message.schedule, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified UpdateScheduleRequest message, length delimited. Does not implicitly {@link api.UpdateScheduleRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {api.IUpdateScheduleRequest} message UpdateScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateScheduleRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateScheduleRequest} UpdateScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateScheduleRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4:
                    message.force = reader.bool();
                    break;
                case 3:
                    message.schedule = $root.api.Schedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateScheduleRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateScheduleRequest} UpdateScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateScheduleRequest message.
         * @function verify
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateScheduleRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            if (message.schedule != null && message.hasOwnProperty("schedule")) {
                let error = $root.api.Schedule.verify(message.schedule);
                if (error)
                    return "schedule." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateScheduleRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateScheduleRequest} UpdateScheduleRequest
         */
        UpdateScheduleRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateScheduleRequest)
                return object;
            let message = new $root.api.UpdateScheduleRequest();
            if (object.force != null)
                message.force = Boolean(object.force);
            if (object.schedule != null) {
                if (typeof object.schedule !== "object")
                    throw TypeError(".api.UpdateScheduleRequest.schedule: object expected");
                message.schedule = $root.api.Schedule.fromObject(object.schedule);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateScheduleRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateScheduleRequest
         * @static
         * @param {api.UpdateScheduleRequest} message UpdateScheduleRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateScheduleRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.schedule = null;
                object.force = false;
            }
            if (message.schedule != null && message.hasOwnProperty("schedule"))
                object.schedule = $root.api.Schedule.toObject(message.schedule, options);
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this UpdateScheduleRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateScheduleRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateScheduleRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateScheduleRequest;
    })();

    api.TruncateScheduleRequest = (function() {

        /**
         * Properties of a TruncateScheduleRequest.
         * @memberof api
         * @interface ITruncateScheduleRequest
         * @property {boolean|null} [force] TruncateScheduleRequest force
         * @property {string|null} [scheduleId] TruncateScheduleRequest scheduleId
         * @property {number|Long|null} [truncateEndDate] TruncateScheduleRequest truncateEndDate
         */

        /**
         * Constructs a new TruncateScheduleRequest.
         * @memberof api
         * @classdesc Represents a TruncateScheduleRequest.
         * @implements ITruncateScheduleRequest
         * @constructor
         * @param {api.ITruncateScheduleRequest=} [properties] Properties to set
         */
        function TruncateScheduleRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TruncateScheduleRequest force.
         * @member {boolean} force
         * @memberof api.TruncateScheduleRequest
         * @instance
         */
        TruncateScheduleRequest.prototype.force = false;

        /**
         * TruncateScheduleRequest scheduleId.
         * @member {string} scheduleId
         * @memberof api.TruncateScheduleRequest
         * @instance
         */
        TruncateScheduleRequest.prototype.scheduleId = "";

        /**
         * TruncateScheduleRequest truncateEndDate.
         * @member {number|Long} truncateEndDate
         * @memberof api.TruncateScheduleRequest
         * @instance
         */
        TruncateScheduleRequest.prototype.truncateEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TruncateScheduleRequest instance using the specified properties.
         * @function create
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {api.ITruncateScheduleRequest=} [properties] Properties to set
         * @returns {api.TruncateScheduleRequest} TruncateScheduleRequest instance
         */
        TruncateScheduleRequest.create = function create(properties) {
            return new TruncateScheduleRequest(properties);
        };

        /**
         * Encodes the specified TruncateScheduleRequest message. Does not implicitly {@link api.TruncateScheduleRequest.verify|verify} messages.
         * @function encode
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {api.ITruncateScheduleRequest} message TruncateScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TruncateScheduleRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.force);
            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.scheduleId);
            if (message.truncateEndDate != null && Object.hasOwnProperty.call(message, "truncateEndDate"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.truncateEndDate);
            return writer;
        };

        /**
         * Encodes the specified TruncateScheduleRequest message, length delimited. Does not implicitly {@link api.TruncateScheduleRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {api.ITruncateScheduleRequest} message TruncateScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TruncateScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TruncateScheduleRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TruncateScheduleRequest} TruncateScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TruncateScheduleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TruncateScheduleRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.force = reader.bool();
                    break;
                case 2:
                    message.scheduleId = reader.string();
                    break;
                case 3:
                    message.truncateEndDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TruncateScheduleRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TruncateScheduleRequest} TruncateScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TruncateScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TruncateScheduleRequest message.
         * @function verify
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TruncateScheduleRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                if (!$util.isString(message.scheduleId))
                    return "scheduleId: string expected";
            if (message.truncateEndDate != null && message.hasOwnProperty("truncateEndDate"))
                if (!$util.isInteger(message.truncateEndDate) && !(message.truncateEndDate && $util.isInteger(message.truncateEndDate.low) && $util.isInteger(message.truncateEndDate.high)))
                    return "truncateEndDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a TruncateScheduleRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TruncateScheduleRequest} TruncateScheduleRequest
         */
        TruncateScheduleRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TruncateScheduleRequest)
                return object;
            let message = new $root.api.TruncateScheduleRequest();
            if (object.force != null)
                message.force = Boolean(object.force);
            if (object.scheduleId != null)
                message.scheduleId = String(object.scheduleId);
            if (object.truncateEndDate != null)
                if ($util.Long)
                    (message.truncateEndDate = $util.Long.fromValue(object.truncateEndDate)).unsigned = false;
                else if (typeof object.truncateEndDate === "string")
                    message.truncateEndDate = parseInt(object.truncateEndDate, 10);
                else if (typeof object.truncateEndDate === "number")
                    message.truncateEndDate = object.truncateEndDate;
                else if (typeof object.truncateEndDate === "object")
                    message.truncateEndDate = new $util.LongBits(object.truncateEndDate.low >>> 0, object.truncateEndDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TruncateScheduleRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TruncateScheduleRequest
         * @static
         * @param {api.TruncateScheduleRequest} message TruncateScheduleRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TruncateScheduleRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.force = false;
                object.scheduleId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.truncateEndDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.truncateEndDate = options.longs === String ? "0" : 0;
            }
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                object.scheduleId = message.scheduleId;
            if (message.truncateEndDate != null && message.hasOwnProperty("truncateEndDate"))
                if (typeof message.truncateEndDate === "number")
                    object.truncateEndDate = options.longs === String ? String(message.truncateEndDate) : message.truncateEndDate;
                else
                    object.truncateEndDate = options.longs === String ? $util.Long.prototype.toString.call(message.truncateEndDate) : options.longs === Number ? new $util.LongBits(message.truncateEndDate.low >>> 0, message.truncateEndDate.high >>> 0).toNumber() : message.truncateEndDate;
            return object;
        };

        /**
         * Converts this TruncateScheduleRequest to JSON.
         * @function toJSON
         * @memberof api.TruncateScheduleRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TruncateScheduleRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TruncateScheduleRequest;
    })();

    api.UpdateTimeRequest = (function() {

        /**
         * Properties of an UpdateTimeRequest.
         * @memberof api
         * @interface IUpdateTimeRequest
         * @property {api.ITimeUpdate|null} [update] UpdateTimeRequest update
         * @property {boolean|null} [force] UpdateTimeRequest force
         * @property {api.ITime|null} [time] UpdateTimeRequest time
         */

        /**
         * Constructs a new UpdateTimeRequest.
         * @memberof api
         * @classdesc Represents an UpdateTimeRequest.
         * @implements IUpdateTimeRequest
         * @constructor
         * @param {api.IUpdateTimeRequest=} [properties] Properties to set
         */
        function UpdateTimeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateTimeRequest update.
         * @member {api.ITimeUpdate|null|undefined} update
         * @memberof api.UpdateTimeRequest
         * @instance
         */
        UpdateTimeRequest.prototype.update = null;

        /**
         * UpdateTimeRequest force.
         * @member {boolean} force
         * @memberof api.UpdateTimeRequest
         * @instance
         */
        UpdateTimeRequest.prototype.force = false;

        /**
         * UpdateTimeRequest time.
         * @member {api.ITime|null|undefined} time
         * @memberof api.UpdateTimeRequest
         * @instance
         */
        UpdateTimeRequest.prototype.time = null;

        /**
         * Creates a new UpdateTimeRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {api.IUpdateTimeRequest=} [properties] Properties to set
         * @returns {api.UpdateTimeRequest} UpdateTimeRequest instance
         */
        UpdateTimeRequest.create = function create(properties) {
            return new UpdateTimeRequest(properties);
        };

        /**
         * Encodes the specified UpdateTimeRequest message. Does not implicitly {@link api.UpdateTimeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {api.IUpdateTimeRequest} message UpdateTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTimeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.TimeUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.Time.encode(message.time, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateTimeRequest message, length delimited. Does not implicitly {@link api.UpdateTimeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {api.IUpdateTimeRequest} message UpdateTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateTimeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateTimeRequest} UpdateTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTimeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateTimeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.TimeUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.force = reader.bool();
                    break;
                case 3:
                    message.time = $root.api.Time.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateTimeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateTimeRequest} UpdateTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTimeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateTimeRequest message.
         * @function verify
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateTimeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.TimeUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            if (message.time != null && message.hasOwnProperty("time")) {
                let error = $root.api.Time.verify(message.time);
                if (error)
                    return "time." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateTimeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateTimeRequest} UpdateTimeRequest
         */
        UpdateTimeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateTimeRequest)
                return object;
            let message = new $root.api.UpdateTimeRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateTimeRequest.update: object expected");
                message.update = $root.api.TimeUpdate.fromObject(object.update);
            }
            if (object.force != null)
                message.force = Boolean(object.force);
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.UpdateTimeRequest.time: object expected");
                message.time = $root.api.Time.fromObject(object.time);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateTimeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateTimeRequest
         * @static
         * @param {api.UpdateTimeRequest} message UpdateTimeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateTimeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.force = false;
                object.time = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.TimeUpdate.toObject(message.update, options);
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = $root.api.Time.toObject(message.time, options);
            return object;
        };

        /**
         * Converts this UpdateTimeRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateTimeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateTimeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateTimeRequest;
    })();

    api.DeleteTimeRequest = (function() {

        /**
         * Properties of a DeleteTimeRequest.
         * @memberof api
         * @interface IDeleteTimeRequest
         * @property {string|null} [timeId] DeleteTimeRequest timeId
         * @property {boolean|null} [force] DeleteTimeRequest force
         */

        /**
         * Constructs a new DeleteTimeRequest.
         * @memberof api
         * @classdesc Represents a DeleteTimeRequest.
         * @implements IDeleteTimeRequest
         * @constructor
         * @param {api.IDeleteTimeRequest=} [properties] Properties to set
         */
        function DeleteTimeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteTimeRequest timeId.
         * @member {string} timeId
         * @memberof api.DeleteTimeRequest
         * @instance
         */
        DeleteTimeRequest.prototype.timeId = "";

        /**
         * DeleteTimeRequest force.
         * @member {boolean} force
         * @memberof api.DeleteTimeRequest
         * @instance
         */
        DeleteTimeRequest.prototype.force = false;

        /**
         * Creates a new DeleteTimeRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {api.IDeleteTimeRequest=} [properties] Properties to set
         * @returns {api.DeleteTimeRequest} DeleteTimeRequest instance
         */
        DeleteTimeRequest.create = function create(properties) {
            return new DeleteTimeRequest(properties);
        };

        /**
         * Encodes the specified DeleteTimeRequest message. Does not implicitly {@link api.DeleteTimeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {api.IDeleteTimeRequest} message DeleteTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTimeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.timeId);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified DeleteTimeRequest message, length delimited. Does not implicitly {@link api.DeleteTimeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {api.IDeleteTimeRequest} message DeleteTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteTimeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteTimeRequest} DeleteTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTimeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteTimeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timeId = reader.string();
                    break;
                case 2:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteTimeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteTimeRequest} DeleteTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTimeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteTimeRequest message.
         * @function verify
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteTimeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteTimeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteTimeRequest} DeleteTimeRequest
         */
        DeleteTimeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteTimeRequest)
                return object;
            let message = new $root.api.DeleteTimeRequest();
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a DeleteTimeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteTimeRequest
         * @static
         * @param {api.DeleteTimeRequest} message DeleteTimeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteTimeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.timeId = "";
                object.force = false;
            }
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                object.timeId = message.timeId;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this DeleteTimeRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteTimeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteTimeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteTimeRequest;
    })();

    api.AddTimeRequest = (function() {

        /**
         * Properties of an AddTimeRequest.
         * @memberof api
         * @interface IAddTimeRequest
         * @property {api.ITime|null} [time] AddTimeRequest time
         */

        /**
         * Constructs a new AddTimeRequest.
         * @memberof api
         * @classdesc Represents an AddTimeRequest.
         * @implements IAddTimeRequest
         * @constructor
         * @param {api.IAddTimeRequest=} [properties] Properties to set
         */
        function AddTimeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddTimeRequest time.
         * @member {api.ITime|null|undefined} time
         * @memberof api.AddTimeRequest
         * @instance
         */
        AddTimeRequest.prototype.time = null;

        /**
         * Creates a new AddTimeRequest instance using the specified properties.
         * @function create
         * @memberof api.AddTimeRequest
         * @static
         * @param {api.IAddTimeRequest=} [properties] Properties to set
         * @returns {api.AddTimeRequest} AddTimeRequest instance
         */
        AddTimeRequest.create = function create(properties) {
            return new AddTimeRequest(properties);
        };

        /**
         * Encodes the specified AddTimeRequest message. Does not implicitly {@link api.AddTimeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddTimeRequest
         * @static
         * @param {api.IAddTimeRequest} message AddTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTimeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.Time.encode(message.time, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified AddTimeRequest message, length delimited. Does not implicitly {@link api.AddTimeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddTimeRequest
         * @static
         * @param {api.IAddTimeRequest} message AddTimeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTimeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddTimeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddTimeRequest} AddTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTimeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddTimeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.time = $root.api.Time.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddTimeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddTimeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddTimeRequest} AddTimeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTimeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddTimeRequest message.
         * @function verify
         * @memberof api.AddTimeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddTimeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.time != null && message.hasOwnProperty("time")) {
                let error = $root.api.Time.verify(message.time);
                if (error)
                    return "time." + error;
            }
            return null;
        };

        /**
         * Creates an AddTimeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddTimeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddTimeRequest} AddTimeRequest
         */
        AddTimeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddTimeRequest)
                return object;
            let message = new $root.api.AddTimeRequest();
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.AddTimeRequest.time: object expected");
                message.time = $root.api.Time.fromObject(object.time);
            }
            return message;
        };

        /**
         * Creates a plain object from an AddTimeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddTimeRequest
         * @static
         * @param {api.AddTimeRequest} message AddTimeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddTimeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.time = null;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = $root.api.Time.toObject(message.time, options);
            return object;
        };

        /**
         * Converts this AddTimeRequest to JSON.
         * @function toJSON
         * @memberof api.AddTimeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddTimeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTimeRequest;
    })();

    api.DeleteScheduleRequest = (function() {

        /**
         * Properties of a DeleteScheduleRequest.
         * @memberof api
         * @interface IDeleteScheduleRequest
         * @property {string|null} [scheduleId] DeleteScheduleRequest scheduleId
         * @property {boolean|null} [force] DeleteScheduleRequest force
         */

        /**
         * Constructs a new DeleteScheduleRequest.
         * @memberof api
         * @classdesc Represents a DeleteScheduleRequest.
         * @implements IDeleteScheduleRequest
         * @constructor
         * @param {api.IDeleteScheduleRequest=} [properties] Properties to set
         */
        function DeleteScheduleRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteScheduleRequest scheduleId.
         * @member {string} scheduleId
         * @memberof api.DeleteScheduleRequest
         * @instance
         */
        DeleteScheduleRequest.prototype.scheduleId = "";

        /**
         * DeleteScheduleRequest force.
         * @member {boolean} force
         * @memberof api.DeleteScheduleRequest
         * @instance
         */
        DeleteScheduleRequest.prototype.force = false;

        /**
         * Creates a new DeleteScheduleRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {api.IDeleteScheduleRequest=} [properties] Properties to set
         * @returns {api.DeleteScheduleRequest} DeleteScheduleRequest instance
         */
        DeleteScheduleRequest.create = function create(properties) {
            return new DeleteScheduleRequest(properties);
        };

        /**
         * Encodes the specified DeleteScheduleRequest message. Does not implicitly {@link api.DeleteScheduleRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {api.IDeleteScheduleRequest} message DeleteScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.scheduleId);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified DeleteScheduleRequest message, length delimited. Does not implicitly {@link api.DeleteScheduleRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {api.IDeleteScheduleRequest} message DeleteScheduleRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteScheduleRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteScheduleRequest} DeleteScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteScheduleRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.scheduleId = reader.string();
                    break;
                case 3:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteScheduleRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteScheduleRequest} DeleteScheduleRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteScheduleRequest message.
         * @function verify
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteScheduleRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                if (!$util.isString(message.scheduleId))
                    return "scheduleId: string expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteScheduleRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteScheduleRequest} DeleteScheduleRequest
         */
        DeleteScheduleRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteScheduleRequest)
                return object;
            let message = new $root.api.DeleteScheduleRequest();
            if (object.scheduleId != null)
                message.scheduleId = String(object.scheduleId);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a DeleteScheduleRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteScheduleRequest
         * @static
         * @param {api.DeleteScheduleRequest} message DeleteScheduleRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteScheduleRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.scheduleId = "";
                object.force = false;
            }
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                object.scheduleId = message.scheduleId;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this DeleteScheduleRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteScheduleRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteScheduleRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteScheduleRequest;
    })();

    api.GetScheduleSetRequest = (function() {

        /**
         * Properties of a GetScheduleSetRequest.
         * @memberof api
         * @interface IGetScheduleSetRequest
         * @property {string|null} [scheduleSetId] GetScheduleSetRequest scheduleSetId
         * @property {api.IScheduleSetQuery|null} [query] GetScheduleSetRequest query
         */

        /**
         * Constructs a new GetScheduleSetRequest.
         * @memberof api
         * @classdesc Represents a GetScheduleSetRequest.
         * @implements IGetScheduleSetRequest
         * @constructor
         * @param {api.IGetScheduleSetRequest=} [properties] Properties to set
         */
        function GetScheduleSetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetScheduleSetRequest scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.GetScheduleSetRequest
         * @instance
         */
        GetScheduleSetRequest.prototype.scheduleSetId = "";

        /**
         * GetScheduleSetRequest query.
         * @member {api.IScheduleSetQuery|null|undefined} query
         * @memberof api.GetScheduleSetRequest
         * @instance
         */
        GetScheduleSetRequest.prototype.query = null;

        /**
         * Creates a new GetScheduleSetRequest instance using the specified properties.
         * @function create
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {api.IGetScheduleSetRequest=} [properties] Properties to set
         * @returns {api.GetScheduleSetRequest} GetScheduleSetRequest instance
         */
        GetScheduleSetRequest.create = function create(properties) {
            return new GetScheduleSetRequest(properties);
        };

        /**
         * Encodes the specified GetScheduleSetRequest message. Does not implicitly {@link api.GetScheduleSetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {api.IGetScheduleSetRequest} message GetScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleSetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleSetId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.ScheduleSetQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetScheduleSetRequest message, length delimited. Does not implicitly {@link api.GetScheduleSetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {api.IGetScheduleSetRequest} message GetScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetScheduleSetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetScheduleSetRequest} GetScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleSetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetScheduleSetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSetId = reader.string();
                    break;
                case 2:
                    message.query = $root.api.ScheduleSetQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetScheduleSetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetScheduleSetRequest} GetScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleSetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetScheduleSetRequest message.
         * @function verify
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetScheduleSetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.ScheduleSetQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetScheduleSetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetScheduleSetRequest} GetScheduleSetRequest
         */
        GetScheduleSetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetScheduleSetRequest)
                return object;
            let message = new $root.api.GetScheduleSetRequest();
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetScheduleSetRequest.query: object expected");
                message.query = $root.api.ScheduleSetQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetScheduleSetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetScheduleSetRequest
         * @static
         * @param {api.GetScheduleSetRequest} message GetScheduleSetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetScheduleSetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.scheduleSetId = "";
                object.query = null;
            }
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.ScheduleSetQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetScheduleSetRequest to JSON.
         * @function toJSON
         * @memberof api.GetScheduleSetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetScheduleSetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetScheduleSetRequest;
    })();

    api.CreateScheduleSetRequest = (function() {

        /**
         * Properties of a CreateScheduleSetRequest.
         * @memberof api
         * @interface ICreateScheduleSetRequest
         * @property {api.IScheduleSet|null} [scheduleSet] CreateScheduleSetRequest scheduleSet
         */

        /**
         * Constructs a new CreateScheduleSetRequest.
         * @memberof api
         * @classdesc Represents a CreateScheduleSetRequest.
         * @implements ICreateScheduleSetRequest
         * @constructor
         * @param {api.ICreateScheduleSetRequest=} [properties] Properties to set
         */
        function CreateScheduleSetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateScheduleSetRequest scheduleSet.
         * @member {api.IScheduleSet|null|undefined} scheduleSet
         * @memberof api.CreateScheduleSetRequest
         * @instance
         */
        CreateScheduleSetRequest.prototype.scheduleSet = null;

        /**
         * Creates a new CreateScheduleSetRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {api.ICreateScheduleSetRequest=} [properties] Properties to set
         * @returns {api.CreateScheduleSetRequest} CreateScheduleSetRequest instance
         */
        CreateScheduleSetRequest.create = function create(properties) {
            return new CreateScheduleSetRequest(properties);
        };

        /**
         * Encodes the specified CreateScheduleSetRequest message. Does not implicitly {@link api.CreateScheduleSetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {api.ICreateScheduleSetRequest} message CreateScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateScheduleSetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSet.encode(message.scheduleSet, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateScheduleSetRequest message, length delimited. Does not implicitly {@link api.CreateScheduleSetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {api.ICreateScheduleSetRequest} message CreateScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateScheduleSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateScheduleSetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateScheduleSetRequest} CreateScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateScheduleSetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateScheduleSetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSet = $root.api.ScheduleSet.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateScheduleSetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateScheduleSetRequest} CreateScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateScheduleSetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateScheduleSetRequest message.
         * @function verify
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateScheduleSetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSet.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            return null;
        };

        /**
         * Creates a CreateScheduleSetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateScheduleSetRequest} CreateScheduleSetRequest
         */
        CreateScheduleSetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateScheduleSetRequest)
                return object;
            let message = new $root.api.CreateScheduleSetRequest();
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.CreateScheduleSetRequest.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSet.fromObject(object.scheduleSet);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateScheduleSetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateScheduleSetRequest
         * @static
         * @param {api.CreateScheduleSetRequest} message CreateScheduleSetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateScheduleSetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.scheduleSet = null;
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSet.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this CreateScheduleSetRequest to JSON.
         * @function toJSON
         * @memberof api.CreateScheduleSetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateScheduleSetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateScheduleSetRequest;
    })();

    api.ScheduleSetUpdate = (function() {

        /**
         * Properties of a ScheduleSetUpdate.
         * @memberof api
         * @interface IScheduleSetUpdate
         * @property {boolean|null} [name] ScheduleSetUpdate name
         */

        /**
         * Constructs a new ScheduleSetUpdate.
         * @memberof api
         * @classdesc Represents a ScheduleSetUpdate.
         * @implements IScheduleSetUpdate
         * @constructor
         * @param {api.IScheduleSetUpdate=} [properties] Properties to set
         */
        function ScheduleSetUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScheduleSetUpdate name.
         * @member {boolean} name
         * @memberof api.ScheduleSetUpdate
         * @instance
         */
        ScheduleSetUpdate.prototype.name = false;

        /**
         * Creates a new ScheduleSetUpdate instance using the specified properties.
         * @function create
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {api.IScheduleSetUpdate=} [properties] Properties to set
         * @returns {api.ScheduleSetUpdate} ScheduleSetUpdate instance
         */
        ScheduleSetUpdate.create = function create(properties) {
            return new ScheduleSetUpdate(properties);
        };

        /**
         * Encodes the specified ScheduleSetUpdate message. Does not implicitly {@link api.ScheduleSetUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {api.IScheduleSetUpdate} message ScheduleSetUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSetUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.name);
            return writer;
        };

        /**
         * Encodes the specified ScheduleSetUpdate message, length delimited. Does not implicitly {@link api.ScheduleSetUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {api.IScheduleSetUpdate} message ScheduleSetUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSetUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScheduleSetUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ScheduleSetUpdate} ScheduleSetUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSetUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ScheduleSetUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScheduleSetUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ScheduleSetUpdate} ScheduleSetUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSetUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScheduleSetUpdate message.
         * @function verify
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScheduleSetUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (typeof message.name !== "boolean")
                    return "name: boolean expected";
            return null;
        };

        /**
         * Creates a ScheduleSetUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ScheduleSetUpdate} ScheduleSetUpdate
         */
        ScheduleSetUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ScheduleSetUpdate)
                return object;
            let message = new $root.api.ScheduleSetUpdate();
            if (object.name != null)
                message.name = Boolean(object.name);
            return message;
        };

        /**
         * Creates a plain object from a ScheduleSetUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ScheduleSetUpdate
         * @static
         * @param {api.ScheduleSetUpdate} message ScheduleSetUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScheduleSetUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.name = false;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this ScheduleSetUpdate to JSON.
         * @function toJSON
         * @memberof api.ScheduleSetUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScheduleSetUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ScheduleSetUpdate;
    })();

    api.TimeUpdate = (function() {

        /**
         * Properties of a TimeUpdate.
         * @memberof api
         * @interface ITimeUpdate
         * @property {boolean|null} [startTime] TimeUpdate startTime
         * @property {boolean|null} [endTime] TimeUpdate endTime
         */

        /**
         * Constructs a new TimeUpdate.
         * @memberof api
         * @classdesc Represents a TimeUpdate.
         * @implements ITimeUpdate
         * @constructor
         * @param {api.ITimeUpdate=} [properties] Properties to set
         */
        function TimeUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeUpdate startTime.
         * @member {boolean} startTime
         * @memberof api.TimeUpdate
         * @instance
         */
        TimeUpdate.prototype.startTime = false;

        /**
         * TimeUpdate endTime.
         * @member {boolean} endTime
         * @memberof api.TimeUpdate
         * @instance
         */
        TimeUpdate.prototype.endTime = false;

        /**
         * Creates a new TimeUpdate instance using the specified properties.
         * @function create
         * @memberof api.TimeUpdate
         * @static
         * @param {api.ITimeUpdate=} [properties] Properties to set
         * @returns {api.TimeUpdate} TimeUpdate instance
         */
        TimeUpdate.create = function create(properties) {
            return new TimeUpdate(properties);
        };

        /**
         * Encodes the specified TimeUpdate message. Does not implicitly {@link api.TimeUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.TimeUpdate
         * @static
         * @param {api.ITimeUpdate} message TimeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.startTime);
            if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.endTime);
            return writer;
        };

        /**
         * Encodes the specified TimeUpdate message, length delimited. Does not implicitly {@link api.TimeUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TimeUpdate
         * @static
         * @param {api.ITimeUpdate} message TimeUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.TimeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TimeUpdate} TimeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TimeUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.startTime = reader.bool();
                    break;
                case 2:
                    message.endTime = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimeUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TimeUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TimeUpdate} TimeUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeUpdate message.
         * @function verify
         * @memberof api.TimeUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime !== "boolean")
                    return "startTime: boolean expected";
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (typeof message.endTime !== "boolean")
                    return "endTime: boolean expected";
            return null;
        };

        /**
         * Creates a TimeUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TimeUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TimeUpdate} TimeUpdate
         */
        TimeUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TimeUpdate)
                return object;
            let message = new $root.api.TimeUpdate();
            if (object.startTime != null)
                message.startTime = Boolean(object.startTime);
            if (object.endTime != null)
                message.endTime = Boolean(object.endTime);
            return message;
        };

        /**
         * Creates a plain object from a TimeUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TimeUpdate
         * @static
         * @param {api.TimeUpdate} message TimeUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.startTime = false;
                object.endTime = false;
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                object.startTime = message.startTime;
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                object.endTime = message.endTime;
            return object;
        };

        /**
         * Converts this TimeUpdate to JSON.
         * @function toJSON
         * @memberof api.TimeUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TimeUpdate;
    })();

    api.UpdateScheduleSetRequest = (function() {

        /**
         * Properties of an UpdateScheduleSetRequest.
         * @memberof api
         * @interface IUpdateScheduleSetRequest
         * @property {api.IScheduleSetUpdate|null} [update] UpdateScheduleSetRequest update
         * @property {api.IScheduleSet|null} [scheduleSet] UpdateScheduleSetRequest scheduleSet
         */

        /**
         * Constructs a new UpdateScheduleSetRequest.
         * @memberof api
         * @classdesc Represents an UpdateScheduleSetRequest.
         * @implements IUpdateScheduleSetRequest
         * @constructor
         * @param {api.IUpdateScheduleSetRequest=} [properties] Properties to set
         */
        function UpdateScheduleSetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateScheduleSetRequest update.
         * @member {api.IScheduleSetUpdate|null|undefined} update
         * @memberof api.UpdateScheduleSetRequest
         * @instance
         */
        UpdateScheduleSetRequest.prototype.update = null;

        /**
         * UpdateScheduleSetRequest scheduleSet.
         * @member {api.IScheduleSet|null|undefined} scheduleSet
         * @memberof api.UpdateScheduleSetRequest
         * @instance
         */
        UpdateScheduleSetRequest.prototype.scheduleSet = null;

        /**
         * Creates a new UpdateScheduleSetRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {api.IUpdateScheduleSetRequest=} [properties] Properties to set
         * @returns {api.UpdateScheduleSetRequest} UpdateScheduleSetRequest instance
         */
        UpdateScheduleSetRequest.create = function create(properties) {
            return new UpdateScheduleSetRequest(properties);
        };

        /**
         * Encodes the specified UpdateScheduleSetRequest message. Does not implicitly {@link api.UpdateScheduleSetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {api.IUpdateScheduleSetRequest} message UpdateScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleSetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.ScheduleSetUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSet.encode(message.scheduleSet, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateScheduleSetRequest message, length delimited. Does not implicitly {@link api.UpdateScheduleSetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {api.IUpdateScheduleSetRequest} message UpdateScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateScheduleSetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateScheduleSetRequest} UpdateScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleSetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateScheduleSetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.ScheduleSetUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.scheduleSet = $root.api.ScheduleSet.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateScheduleSetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateScheduleSetRequest} UpdateScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleSetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateScheduleSetRequest message.
         * @function verify
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateScheduleSetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.ScheduleSetUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSet.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateScheduleSetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateScheduleSetRequest} UpdateScheduleSetRequest
         */
        UpdateScheduleSetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateScheduleSetRequest)
                return object;
            let message = new $root.api.UpdateScheduleSetRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateScheduleSetRequest.update: object expected");
                message.update = $root.api.ScheduleSetUpdate.fromObject(object.update);
            }
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.UpdateScheduleSetRequest.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSet.fromObject(object.scheduleSet);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateScheduleSetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateScheduleSetRequest
         * @static
         * @param {api.UpdateScheduleSetRequest} message UpdateScheduleSetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateScheduleSetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.scheduleSet = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.ScheduleSetUpdate.toObject(message.update, options);
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSet.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this UpdateScheduleSetRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateScheduleSetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateScheduleSetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateScheduleSetRequest;
    })();

    api.DeleteScheduleSetRequest = (function() {

        /**
         * Properties of a DeleteScheduleSetRequest.
         * @memberof api
         * @interface IDeleteScheduleSetRequest
         * @property {string|null} [scheduleSetId] DeleteScheduleSetRequest scheduleSetId
         * @property {boolean|null} [force] DeleteScheduleSetRequest force
         */

        /**
         * Constructs a new DeleteScheduleSetRequest.
         * @memberof api
         * @classdesc Represents a DeleteScheduleSetRequest.
         * @implements IDeleteScheduleSetRequest
         * @constructor
         * @param {api.IDeleteScheduleSetRequest=} [properties] Properties to set
         */
        function DeleteScheduleSetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteScheduleSetRequest scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.DeleteScheduleSetRequest
         * @instance
         */
        DeleteScheduleSetRequest.prototype.scheduleSetId = "";

        /**
         * DeleteScheduleSetRequest force.
         * @member {boolean} force
         * @memberof api.DeleteScheduleSetRequest
         * @instance
         */
        DeleteScheduleSetRequest.prototype.force = false;

        /**
         * Creates a new DeleteScheduleSetRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {api.IDeleteScheduleSetRequest=} [properties] Properties to set
         * @returns {api.DeleteScheduleSetRequest} DeleteScheduleSetRequest instance
         */
        DeleteScheduleSetRequest.create = function create(properties) {
            return new DeleteScheduleSetRequest(properties);
        };

        /**
         * Encodes the specified DeleteScheduleSetRequest message. Does not implicitly {@link api.DeleteScheduleSetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {api.IDeleteScheduleSetRequest} message DeleteScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleSetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleSetId);
            if (message.force != null && Object.hasOwnProperty.call(message, "force"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.force);
            return writer;
        };

        /**
         * Encodes the specified DeleteScheduleSetRequest message, length delimited. Does not implicitly {@link api.DeleteScheduleSetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {api.IDeleteScheduleSetRequest} message DeleteScheduleSetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleSetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteScheduleSetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteScheduleSetRequest} DeleteScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleSetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteScheduleSetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSetId = reader.string();
                    break;
                case 2:
                    message.force = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteScheduleSetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteScheduleSetRequest} DeleteScheduleSetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleSetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteScheduleSetRequest message.
         * @function verify
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteScheduleSetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            if (message.force != null && message.hasOwnProperty("force"))
                if (typeof message.force !== "boolean")
                    return "force: boolean expected";
            return null;
        };

        /**
         * Creates a DeleteScheduleSetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteScheduleSetRequest} DeleteScheduleSetRequest
         */
        DeleteScheduleSetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteScheduleSetRequest)
                return object;
            let message = new $root.api.DeleteScheduleSetRequest();
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            if (object.force != null)
                message.force = Boolean(object.force);
            return message;
        };

        /**
         * Creates a plain object from a DeleteScheduleSetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteScheduleSetRequest
         * @static
         * @param {api.DeleteScheduleSetRequest} message DeleteScheduleSetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteScheduleSetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.scheduleSetId = "";
                object.force = false;
            }
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            if (message.force != null && message.hasOwnProperty("force"))
                object.force = message.force;
            return object;
        };

        /**
         * Converts this DeleteScheduleSetRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteScheduleSetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteScheduleSetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteScheduleSetRequest;
    })();

    api.CreateUserRequest = (function() {

        /**
         * Properties of a CreateUserRequest.
         * @memberof api
         * @interface ICreateUserRequest
         * @property {string|null} [parentGroupId] CreateUserRequest parentGroupId
         * @property {api.IUser|null} [user] CreateUserRequest user
         */

        /**
         * Constructs a new CreateUserRequest.
         * @memberof api
         * @classdesc Represents a CreateUserRequest.
         * @implements ICreateUserRequest
         * @constructor
         * @param {api.ICreateUserRequest=} [properties] Properties to set
         */
        function CreateUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateUserRequest parentGroupId.
         * @member {string} parentGroupId
         * @memberof api.CreateUserRequest
         * @instance
         */
        CreateUserRequest.prototype.parentGroupId = "";

        /**
         * CreateUserRequest user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.CreateUserRequest
         * @instance
         */
        CreateUserRequest.prototype.user = null;

        /**
         * Creates a new CreateUserRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateUserRequest
         * @static
         * @param {api.ICreateUserRequest=} [properties] Properties to set
         * @returns {api.CreateUserRequest} CreateUserRequest instance
         */
        CreateUserRequest.create = function create(properties) {
            return new CreateUserRequest(properties);
        };

        /**
         * Encodes the specified CreateUserRequest message. Does not implicitly {@link api.CreateUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateUserRequest
         * @static
         * @param {api.ICreateUserRequest} message CreateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentGroupId != null && Object.hasOwnProperty.call(message, "parentGroupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentGroupId);
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateUserRequest message, length delimited. Does not implicitly {@link api.CreateUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateUserRequest
         * @static
         * @param {api.ICreateUserRequest} message CreateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateUserRequest} CreateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parentGroupId = reader.string();
                    break;
                case 2:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateUserRequest} CreateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateUserRequest message.
         * @function verify
         * @memberof api.CreateUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parentGroupId != null && message.hasOwnProperty("parentGroupId"))
                if (!$util.isString(message.parentGroupId))
                    return "parentGroupId: string expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates a CreateUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateUserRequest} CreateUserRequest
         */
        CreateUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateUserRequest)
                return object;
            let message = new $root.api.CreateUserRequest();
            if (object.parentGroupId != null)
                message.parentGroupId = String(object.parentGroupId);
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.CreateUserRequest.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateUserRequest
         * @static
         * @param {api.CreateUserRequest} message CreateUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentGroupId = "";
                object.user = null;
            }
            if (message.parentGroupId != null && message.hasOwnProperty("parentGroupId"))
                object.parentGroupId = message.parentGroupId;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this CreateUserRequest to JSON.
         * @function toJSON
         * @memberof api.CreateUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateUserRequest;
    })();

    api.UserUpdate = (function() {

        /**
         * Properties of a UserUpdate.
         * @memberof api
         * @interface IUserUpdate
         * @property {boolean|null} [password] UserUpdate password
         * @property {boolean|null} [firstName] UserUpdate firstName
         * @property {boolean|null} [lastName] UserUpdate lastName
         * @property {boolean|null} [description] UserUpdate description
         * @property {boolean|null} [vanityName] UserUpdate vanityName
         * @property {boolean|null} [defaultView] UserUpdate defaultView
         * @property {boolean|null} [adChannel] UserUpdate adChannel
         * @property {boolean|null} [timeZone] UserUpdate timeZone
         */

        /**
         * Constructs a new UserUpdate.
         * @memberof api
         * @classdesc Represents a UserUpdate.
         * @implements IUserUpdate
         * @constructor
         * @param {api.IUserUpdate=} [properties] Properties to set
         */
        function UserUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserUpdate password.
         * @member {boolean} password
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.password = false;

        /**
         * UserUpdate firstName.
         * @member {boolean} firstName
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.firstName = false;

        /**
         * UserUpdate lastName.
         * @member {boolean} lastName
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.lastName = false;

        /**
         * UserUpdate description.
         * @member {boolean} description
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.description = false;

        /**
         * UserUpdate vanityName.
         * @member {boolean} vanityName
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.vanityName = false;

        /**
         * UserUpdate defaultView.
         * @member {boolean} defaultView
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.defaultView = false;

        /**
         * UserUpdate adChannel.
         * @member {boolean} adChannel
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.adChannel = false;

        /**
         * UserUpdate timeZone.
         * @member {boolean} timeZone
         * @memberof api.UserUpdate
         * @instance
         */
        UserUpdate.prototype.timeZone = false;

        /**
         * Creates a new UserUpdate instance using the specified properties.
         * @function create
         * @memberof api.UserUpdate
         * @static
         * @param {api.IUserUpdate=} [properties] Properties to set
         * @returns {api.UserUpdate} UserUpdate instance
         */
        UserUpdate.create = function create(properties) {
            return new UserUpdate(properties);
        };

        /**
         * Encodes the specified UserUpdate message. Does not implicitly {@link api.UserUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.UserUpdate
         * @static
         * @param {api.IUserUpdate} message UserUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.password);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.lastName);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.description);
            if (message.vanityName != null && Object.hasOwnProperty.call(message, "vanityName"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.vanityName);
            if (message.defaultView != null && Object.hasOwnProperty.call(message, "defaultView"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.defaultView);
            if (message.adChannel != null && Object.hasOwnProperty.call(message, "adChannel"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.adChannel);
            if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.timeZone);
            return writer;
        };

        /**
         * Encodes the specified UserUpdate message, length delimited. Does not implicitly {@link api.UserUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserUpdate
         * @static
         * @param {api.IUserUpdate} message UserUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserUpdate} UserUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.password = reader.bool();
                    break;
                case 2:
                    message.firstName = reader.bool();
                    break;
                case 3:
                    message.lastName = reader.bool();
                    break;
                case 4:
                    message.description = reader.bool();
                    break;
                case 5:
                    message.vanityName = reader.bool();
                    break;
                case 6:
                    message.defaultView = reader.bool();
                    break;
                case 7:
                    message.adChannel = reader.bool();
                    break;
                case 8:
                    message.timeZone = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserUpdate} UserUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserUpdate message.
         * @function verify
         * @memberof api.UserUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (typeof message.password !== "boolean")
                    return "password: boolean expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (typeof message.firstName !== "boolean")
                    return "firstName: boolean expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (typeof message.lastName !== "boolean")
                    return "lastName: boolean expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (typeof message.description !== "boolean")
                    return "description: boolean expected";
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                if (typeof message.vanityName !== "boolean")
                    return "vanityName: boolean expected";
            if (message.defaultView != null && message.hasOwnProperty("defaultView"))
                if (typeof message.defaultView !== "boolean")
                    return "defaultView: boolean expected";
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                if (typeof message.adChannel !== "boolean")
                    return "adChannel: boolean expected";
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                if (typeof message.timeZone !== "boolean")
                    return "timeZone: boolean expected";
            return null;
        };

        /**
         * Creates a UserUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserUpdate} UserUpdate
         */
        UserUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserUpdate)
                return object;
            let message = new $root.api.UserUpdate();
            if (object.password != null)
                message.password = Boolean(object.password);
            if (object.firstName != null)
                message.firstName = Boolean(object.firstName);
            if (object.lastName != null)
                message.lastName = Boolean(object.lastName);
            if (object.description != null)
                message.description = Boolean(object.description);
            if (object.vanityName != null)
                message.vanityName = Boolean(object.vanityName);
            if (object.defaultView != null)
                message.defaultView = Boolean(object.defaultView);
            if (object.adChannel != null)
                message.adChannel = Boolean(object.adChannel);
            if (object.timeZone != null)
                message.timeZone = Boolean(object.timeZone);
            return message;
        };

        /**
         * Creates a plain object from a UserUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserUpdate
         * @static
         * @param {api.UserUpdate} message UserUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.password = false;
                object.firstName = false;
                object.lastName = false;
                object.description = false;
                object.vanityName = false;
                object.defaultView = false;
                object.adChannel = false;
                object.timeZone = false;
            }
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                object.vanityName = message.vanityName;
            if (message.defaultView != null && message.hasOwnProperty("defaultView"))
                object.defaultView = message.defaultView;
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                object.adChannel = message.adChannel;
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                object.timeZone = message.timeZone;
            return object;
        };

        /**
         * Converts this UserUpdate to JSON.
         * @function toJSON
         * @memberof api.UserUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserUpdate;
    })();

    api.UpdateUserRequest = (function() {

        /**
         * Properties of an UpdateUserRequest.
         * @memberof api
         * @interface IUpdateUserRequest
         * @property {api.IUserUpdate|null} [update] UpdateUserRequest update
         * @property {api.IUser|null} [user] UpdateUserRequest user
         */

        /**
         * Constructs a new UpdateUserRequest.
         * @memberof api
         * @classdesc Represents an UpdateUserRequest.
         * @implements IUpdateUserRequest
         * @constructor
         * @param {api.IUpdateUserRequest=} [properties] Properties to set
         */
        function UpdateUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateUserRequest update.
         * @member {api.IUserUpdate|null|undefined} update
         * @memberof api.UpdateUserRequest
         * @instance
         */
        UpdateUserRequest.prototype.update = null;

        /**
         * UpdateUserRequest user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.UpdateUserRequest
         * @instance
         */
        UpdateUserRequest.prototype.user = null;

        /**
         * Creates a new UpdateUserRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.IUpdateUserRequest=} [properties] Properties to set
         * @returns {api.UpdateUserRequest} UpdateUserRequest instance
         */
        UpdateUserRequest.create = function create(properties) {
            return new UpdateUserRequest(properties);
        };

        /**
         * Encodes the specified UpdateUserRequest message. Does not implicitly {@link api.UpdateUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.IUpdateUserRequest} message UpdateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.UserUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateUserRequest message, length delimited. Does not implicitly {@link api.UpdateUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.IUpdateUserRequest} message UpdateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateUserRequest} UpdateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.UserUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateUserRequest} UpdateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateUserRequest message.
         * @function verify
         * @memberof api.UpdateUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.UserUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateUserRequest} UpdateUserRequest
         */
        UpdateUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateUserRequest)
                return object;
            let message = new $root.api.UpdateUserRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateUserRequest.update: object expected");
                message.update = $root.api.UserUpdate.fromObject(object.update);
            }
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.UpdateUserRequest.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateUserRequest
         * @static
         * @param {api.UpdateUserRequest} message UpdateUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.user = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.UserUpdate.toObject(message.update, options);
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this UpdateUserRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateUserRequest;
    })();

    api.DeleteUserRequest = (function() {

        /**
         * Properties of a DeleteUserRequest.
         * @memberof api
         * @interface IDeleteUserRequest
         * @property {string|null} [userId] DeleteUserRequest userId
         */

        /**
         * Constructs a new DeleteUserRequest.
         * @memberof api
         * @classdesc Represents a DeleteUserRequest.
         * @implements IDeleteUserRequest
         * @constructor
         * @param {api.IDeleteUserRequest=} [properties] Properties to set
         */
        function DeleteUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteUserRequest userId.
         * @member {string} userId
         * @memberof api.DeleteUserRequest
         * @instance
         */
        DeleteUserRequest.prototype.userId = "";

        /**
         * Creates a new DeleteUserRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.IDeleteUserRequest=} [properties] Properties to set
         * @returns {api.DeleteUserRequest} DeleteUserRequest instance
         */
        DeleteUserRequest.create = function create(properties) {
            return new DeleteUserRequest(properties);
        };

        /**
         * Encodes the specified DeleteUserRequest message. Does not implicitly {@link api.DeleteUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.IDeleteUserRequest} message DeleteUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            return writer;
        };

        /**
         * Encodes the specified DeleteUserRequest message, length delimited. Does not implicitly {@link api.DeleteUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.IDeleteUserRequest} message DeleteUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteUserRequest} DeleteUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteUserRequest} DeleteUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUserRequest message.
         * @function verify
         * @memberof api.DeleteUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            return null;
        };

        /**
         * Creates a DeleteUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteUserRequest} DeleteUserRequest
         */
        DeleteUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteUserRequest)
                return object;
            let message = new $root.api.DeleteUserRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteUserRequest
         * @static
         * @param {api.DeleteUserRequest} message DeleteUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = "";
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            return object;
        };

        /**
         * Converts this DeleteUserRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUserRequest;
    })();

    api.CreateGroupRequest = (function() {

        /**
         * Properties of a CreateGroupRequest.
         * @memberof api
         * @interface ICreateGroupRequest
         * @property {string|null} [parentGroupId] CreateGroupRequest parentGroupId
         * @property {api.IGroup|null} [group] CreateGroupRequest group
         */

        /**
         * Constructs a new CreateGroupRequest.
         * @memberof api
         * @classdesc Represents a CreateGroupRequest.
         * @implements ICreateGroupRequest
         * @constructor
         * @param {api.ICreateGroupRequest=} [properties] Properties to set
         */
        function CreateGroupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateGroupRequest parentGroupId.
         * @member {string} parentGroupId
         * @memberof api.CreateGroupRequest
         * @instance
         */
        CreateGroupRequest.prototype.parentGroupId = "";

        /**
         * CreateGroupRequest group.
         * @member {api.IGroup|null|undefined} group
         * @memberof api.CreateGroupRequest
         * @instance
         */
        CreateGroupRequest.prototype.group = null;

        /**
         * Creates a new CreateGroupRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateGroupRequest
         * @static
         * @param {api.ICreateGroupRequest=} [properties] Properties to set
         * @returns {api.CreateGroupRequest} CreateGroupRequest instance
         */
        CreateGroupRequest.create = function create(properties) {
            return new CreateGroupRequest(properties);
        };

        /**
         * Encodes the specified CreateGroupRequest message. Does not implicitly {@link api.CreateGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateGroupRequest
         * @static
         * @param {api.ICreateGroupRequest} message CreateGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.parentGroupId != null && Object.hasOwnProperty.call(message, "parentGroupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.parentGroupId);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.Group.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateGroupRequest message, length delimited. Does not implicitly {@link api.CreateGroupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateGroupRequest
         * @static
         * @param {api.ICreateGroupRequest} message CreateGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateGroupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateGroupRequest} CreateGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateGroupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.parentGroupId = reader.string();
                    break;
                case 2:
                    message.group = $root.api.Group.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateGroupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateGroupRequest} CreateGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateGroupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateGroupRequest message.
         * @function verify
         * @memberof api.CreateGroupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateGroupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.parentGroupId != null && message.hasOwnProperty("parentGroupId"))
                if (!$util.isString(message.parentGroupId))
                    return "parentGroupId: string expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.Group.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a CreateGroupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateGroupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateGroupRequest} CreateGroupRequest
         */
        CreateGroupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateGroupRequest)
                return object;
            let message = new $root.api.CreateGroupRequest();
            if (object.parentGroupId != null)
                message.parentGroupId = String(object.parentGroupId);
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.CreateGroupRequest.group: object expected");
                message.group = $root.api.Group.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateGroupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateGroupRequest
         * @static
         * @param {api.CreateGroupRequest} message CreateGroupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateGroupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.parentGroupId = "";
                object.group = null;
            }
            if (message.parentGroupId != null && message.hasOwnProperty("parentGroupId"))
                object.parentGroupId = message.parentGroupId;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.Group.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this CreateGroupRequest to JSON.
         * @function toJSON
         * @memberof api.CreateGroupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateGroupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateGroupRequest;
    })();

    api.GroupUpdate = (function() {

        /**
         * Properties of a GroupUpdate.
         * @memberof api
         * @interface IGroupUpdate
         * @property {boolean|null} [description] GroupUpdate description
         */

        /**
         * Constructs a new GroupUpdate.
         * @memberof api
         * @classdesc Represents a GroupUpdate.
         * @implements IGroupUpdate
         * @constructor
         * @param {api.IGroupUpdate=} [properties] Properties to set
         */
        function GroupUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GroupUpdate description.
         * @member {boolean} description
         * @memberof api.GroupUpdate
         * @instance
         */
        GroupUpdate.prototype.description = false;

        /**
         * Creates a new GroupUpdate instance using the specified properties.
         * @function create
         * @memberof api.GroupUpdate
         * @static
         * @param {api.IGroupUpdate=} [properties] Properties to set
         * @returns {api.GroupUpdate} GroupUpdate instance
         */
        GroupUpdate.create = function create(properties) {
            return new GroupUpdate(properties);
        };

        /**
         * Encodes the specified GroupUpdate message. Does not implicitly {@link api.GroupUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.GroupUpdate
         * @static
         * @param {api.IGroupUpdate} message GroupUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.description);
            return writer;
        };

        /**
         * Encodes the specified GroupUpdate message, length delimited. Does not implicitly {@link api.GroupUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GroupUpdate
         * @static
         * @param {api.IGroupUpdate} message GroupUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GroupUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.GroupUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GroupUpdate} GroupUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GroupUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.description = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GroupUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GroupUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GroupUpdate} GroupUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GroupUpdate message.
         * @function verify
         * @memberof api.GroupUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GroupUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (typeof message.description !== "boolean")
                    return "description: boolean expected";
            return null;
        };

        /**
         * Creates a GroupUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GroupUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GroupUpdate} GroupUpdate
         */
        GroupUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GroupUpdate)
                return object;
            let message = new $root.api.GroupUpdate();
            if (object.description != null)
                message.description = Boolean(object.description);
            return message;
        };

        /**
         * Creates a plain object from a GroupUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GroupUpdate
         * @static
         * @param {api.GroupUpdate} message GroupUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GroupUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.description = false;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this GroupUpdate to JSON.
         * @function toJSON
         * @memberof api.GroupUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GroupUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GroupUpdate;
    })();

    api.UpdateGroupRequest = (function() {

        /**
         * Properties of an UpdateGroupRequest.
         * @memberof api
         * @interface IUpdateGroupRequest
         * @property {api.IGroupUpdate|null} [update] UpdateGroupRequest update
         * @property {api.IGroup|null} [group] UpdateGroupRequest group
         */

        /**
         * Constructs a new UpdateGroupRequest.
         * @memberof api
         * @classdesc Represents an UpdateGroupRequest.
         * @implements IUpdateGroupRequest
         * @constructor
         * @param {api.IUpdateGroupRequest=} [properties] Properties to set
         */
        function UpdateGroupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateGroupRequest update.
         * @member {api.IGroupUpdate|null|undefined} update
         * @memberof api.UpdateGroupRequest
         * @instance
         */
        UpdateGroupRequest.prototype.update = null;

        /**
         * UpdateGroupRequest group.
         * @member {api.IGroup|null|undefined} group
         * @memberof api.UpdateGroupRequest
         * @instance
         */
        UpdateGroupRequest.prototype.group = null;

        /**
         * Creates a new UpdateGroupRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {api.IUpdateGroupRequest=} [properties] Properties to set
         * @returns {api.UpdateGroupRequest} UpdateGroupRequest instance
         */
        UpdateGroupRequest.create = function create(properties) {
            return new UpdateGroupRequest(properties);
        };

        /**
         * Encodes the specified UpdateGroupRequest message. Does not implicitly {@link api.UpdateGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {api.IUpdateGroupRequest} message UpdateGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.GroupUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.Group.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateGroupRequest message, length delimited. Does not implicitly {@link api.UpdateGroupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {api.IUpdateGroupRequest} message UpdateGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGroupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGroupRequest} UpdateGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGroupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.GroupUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.group = $root.api.Group.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGroupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGroupRequest} UpdateGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGroupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGroupRequest message.
         * @function verify
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGroupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.GroupUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.Group.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateGroupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGroupRequest} UpdateGroupRequest
         */
        UpdateGroupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGroupRequest)
                return object;
            let message = new $root.api.UpdateGroupRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateGroupRequest.update: object expected");
                message.update = $root.api.GroupUpdate.fromObject(object.update);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.UpdateGroupRequest.group: object expected");
                message.group = $root.api.Group.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateGroupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGroupRequest
         * @static
         * @param {api.UpdateGroupRequest} message UpdateGroupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGroupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.group = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.GroupUpdate.toObject(message.update, options);
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.Group.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this UpdateGroupRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateGroupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGroupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGroupRequest;
    })();

    api.DeleteGroupRequest = (function() {

        /**
         * Properties of a DeleteGroupRequest.
         * @memberof api
         * @interface IDeleteGroupRequest
         * @property {string|null} [groupId] DeleteGroupRequest groupId
         */

        /**
         * Constructs a new DeleteGroupRequest.
         * @memberof api
         * @classdesc Represents a DeleteGroupRequest.
         * @implements IDeleteGroupRequest
         * @constructor
         * @param {api.IDeleteGroupRequest=} [properties] Properties to set
         */
        function DeleteGroupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteGroupRequest groupId.
         * @member {string} groupId
         * @memberof api.DeleteGroupRequest
         * @instance
         */
        DeleteGroupRequest.prototype.groupId = "";

        /**
         * Creates a new DeleteGroupRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {api.IDeleteGroupRequest=} [properties] Properties to set
         * @returns {api.DeleteGroupRequest} DeleteGroupRequest instance
         */
        DeleteGroupRequest.create = function create(properties) {
            return new DeleteGroupRequest(properties);
        };

        /**
         * Encodes the specified DeleteGroupRequest message. Does not implicitly {@link api.DeleteGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {api.IDeleteGroupRequest} message DeleteGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            return writer;
        };

        /**
         * Encodes the specified DeleteGroupRequest message, length delimited. Does not implicitly {@link api.DeleteGroupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {api.IDeleteGroupRequest} message DeleteGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGroupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteGroupRequest} DeleteGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteGroupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteGroupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteGroupRequest} DeleteGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGroupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteGroupRequest message.
         * @function verify
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteGroupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            return null;
        };

        /**
         * Creates a DeleteGroupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteGroupRequest} DeleteGroupRequest
         */
        DeleteGroupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteGroupRequest)
                return object;
            let message = new $root.api.DeleteGroupRequest();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteGroupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteGroupRequest
         * @static
         * @param {api.DeleteGroupRequest} message DeleteGroupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteGroupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.groupId = "";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            return object;
        };

        /**
         * Converts this DeleteGroupRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteGroupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteGroupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteGroupRequest;
    })();

    api.SyncData = (function() {

        /**
         * Properties of a SyncData.
         * @memberof api
         * @interface ISyncData
         * @property {string|null} [key] SyncData key
         * @property {string|null} [value] SyncData value
         * @property {Array.<api.ISyncData>|null} [children] SyncData children
         */

        /**
         * Constructs a new SyncData.
         * @memberof api
         * @classdesc Represents a SyncData.
         * @implements ISyncData
         * @constructor
         * @param {api.ISyncData=} [properties] Properties to set
         */
        function SyncData(properties) {
            this.children = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncData key.
         * @member {string} key
         * @memberof api.SyncData
         * @instance
         */
        SyncData.prototype.key = "";

        /**
         * SyncData value.
         * @member {string} value
         * @memberof api.SyncData
         * @instance
         */
        SyncData.prototype.value = "";

        /**
         * SyncData children.
         * @member {Array.<api.ISyncData>} children
         * @memberof api.SyncData
         * @instance
         */
        SyncData.prototype.children = $util.emptyArray;

        /**
         * Creates a new SyncData instance using the specified properties.
         * @function create
         * @memberof api.SyncData
         * @static
         * @param {api.ISyncData=} [properties] Properties to set
         * @returns {api.SyncData} SyncData instance
         */
        SyncData.create = function create(properties) {
            return new SyncData(properties);
        };

        /**
         * Encodes the specified SyncData message. Does not implicitly {@link api.SyncData.verify|verify} messages.
         * @function encode
         * @memberof api.SyncData
         * @static
         * @param {api.ISyncData} message SyncData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncData.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            if (message.children != null && message.children.length)
                for (let i = 0; i < message.children.length; ++i)
                    $root.api.SyncData.encode(message.children[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SyncData message, length delimited. Does not implicitly {@link api.SyncData.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SyncData
         * @static
         * @param {api.ISyncData} message SyncData message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncData.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncData message from the specified reader or buffer.
         * @function decode
         * @memberof api.SyncData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SyncData} SyncData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncData.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SyncData();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.key = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                case 3:
                    if (!(message.children && message.children.length))
                        message.children = [];
                    message.children.push($root.api.SyncData.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncData message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SyncData
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SyncData} SyncData
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncData.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncData message.
         * @function verify
         * @memberof api.SyncData
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncData.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.key != null && message.hasOwnProperty("key"))
                if (!$util.isString(message.key))
                    return "key: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            if (message.children != null && message.hasOwnProperty("children")) {
                if (!Array.isArray(message.children))
                    return "children: array expected";
                for (let i = 0; i < message.children.length; ++i) {
                    let error = $root.api.SyncData.verify(message.children[i]);
                    if (error)
                        return "children." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SyncData message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SyncData
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SyncData} SyncData
         */
        SyncData.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SyncData)
                return object;
            let message = new $root.api.SyncData();
            if (object.key != null)
                message.key = String(object.key);
            if (object.value != null)
                message.value = String(object.value);
            if (object.children) {
                if (!Array.isArray(object.children))
                    throw TypeError(".api.SyncData.children: array expected");
                message.children = [];
                for (let i = 0; i < object.children.length; ++i) {
                    if (typeof object.children[i] !== "object")
                        throw TypeError(".api.SyncData.children: object expected");
                    message.children[i] = $root.api.SyncData.fromObject(object.children[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SyncData message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SyncData
         * @static
         * @param {api.SyncData} message SyncData
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncData.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.children = [];
            if (options.defaults) {
                object.key = "";
                object.value = "";
            }
            if (message.key != null && message.hasOwnProperty("key"))
                object.key = message.key;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            if (message.children && message.children.length) {
                object.children = [];
                for (let j = 0; j < message.children.length; ++j)
                    object.children[j] = $root.api.SyncData.toObject(message.children[j], options);
            }
            return object;
        };

        /**
         * Converts this SyncData to JSON.
         * @function toJSON
         * @memberof api.SyncData
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncData;
    })();

    api.Sync = (function() {

        /**
         * Properties of a Sync.
         * @memberof api
         * @interface ISync
         * @property {string|null} [syncId] Sync syncId
         * @property {string|null} [description] Sync description
         * @property {number|null} [typeId] Sync typeId
         * @property {api.ISyncData|null} [data] Sync data
         * @property {Array.<api.IMember>|null} [members] Sync members
         * @property {Array.<api.IObjectAsset>|null} [assets] Sync assets
         * @property {number|Long|null} [createDate] Sync createDate
         * @property {number|Long|null} [updateDate] Sync updateDate
         */

        /**
         * Constructs a new Sync.
         * @memberof api
         * @classdesc Represents a Sync.
         * @implements ISync
         * @constructor
         * @param {api.ISync=} [properties] Properties to set
         */
        function Sync(properties) {
            this.members = [];
            this.assets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Sync syncId.
         * @member {string} syncId
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.syncId = "";

        /**
         * Sync description.
         * @member {string} description
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.description = "";

        /**
         * Sync typeId.
         * @member {number} typeId
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.typeId = 0;

        /**
         * Sync data.
         * @member {api.ISyncData|null|undefined} data
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.data = null;

        /**
         * Sync members.
         * @member {Array.<api.IMember>} members
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.members = $util.emptyArray;

        /**
         * Sync assets.
         * @member {Array.<api.IObjectAsset>} assets
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.assets = $util.emptyArray;

        /**
         * Sync createDate.
         * @member {number|Long} createDate
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Sync updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Sync
         * @instance
         */
        Sync.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Sync instance using the specified properties.
         * @function create
         * @memberof api.Sync
         * @static
         * @param {api.ISync=} [properties] Properties to set
         * @returns {api.Sync} Sync instance
         */
        Sync.create = function create(properties) {
            return new Sync(properties);
        };

        /**
         * Encodes the specified Sync message. Does not implicitly {@link api.Sync.verify|verify} messages.
         * @function encode
         * @memberof api.Sync
         * @static
         * @param {api.ISync} message Sync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sync.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncId != null && Object.hasOwnProperty.call(message, "syncId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.syncId);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.typeId != null && Object.hasOwnProperty.call(message, "typeId"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.typeId);
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.SyncData.encode(message.data, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.api.Member.encode(message.members[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.assets != null && message.assets.length)
                for (let i = 0; i < message.assets.length; ++i)
                    $root.api.ObjectAsset.encode(message.assets[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.updateDate);
            return writer;
        };

        /**
         * Encodes the specified Sync message, length delimited. Does not implicitly {@link api.Sync.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Sync
         * @static
         * @param {api.ISync} message Sync message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Sync.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Sync message from the specified reader or buffer.
         * @function decode
         * @memberof api.Sync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Sync} Sync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sync.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Sync();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncId = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    message.typeId = reader.int32();
                    break;
                case 5:
                    message.data = $root.api.SyncData.decode(reader, reader.uint32());
                    break;
                case 6:
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.assets && message.assets.length))
                        message.assets = [];
                    message.assets.push($root.api.ObjectAsset.decode(reader, reader.uint32()));
                    break;
                case 8:
                    message.createDate = reader.int64();
                    break;
                case 9:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Sync message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Sync
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Sync} Sync
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Sync.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Sync message.
         * @function verify
         * @memberof api.Sync
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Sync.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                if (!$util.isString(message.syncId))
                    return "syncId: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.typeId != null && message.hasOwnProperty("typeId"))
                if (!$util.isInteger(message.typeId))
                    return "typeId: integer expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.SyncData.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.api.Member.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            if (message.assets != null && message.hasOwnProperty("assets")) {
                if (!Array.isArray(message.assets))
                    return "assets: array expected";
                for (let i = 0; i < message.assets.length; ++i) {
                    let error = $root.api.ObjectAsset.verify(message.assets[i]);
                    if (error)
                        return "assets." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a Sync message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Sync
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Sync} Sync
         */
        Sync.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Sync)
                return object;
            let message = new $root.api.Sync();
            if (object.syncId != null)
                message.syncId = String(object.syncId);
            if (object.description != null)
                message.description = String(object.description);
            if (object.typeId != null)
                message.typeId = object.typeId | 0;
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.Sync.data: object expected");
                message.data = $root.api.SyncData.fromObject(object.data);
            }
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.Sync.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.Sync.members: object expected");
                    message.members[i] = $root.api.Member.fromObject(object.members[i]);
                }
            }
            if (object.assets) {
                if (!Array.isArray(object.assets))
                    throw TypeError(".api.Sync.assets: array expected");
                message.assets = [];
                for (let i = 0; i < object.assets.length; ++i) {
                    if (typeof object.assets[i] !== "object")
                        throw TypeError(".api.Sync.assets: object expected");
                    message.assets[i] = $root.api.ObjectAsset.fromObject(object.assets[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Sync message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Sync
         * @static
         * @param {api.Sync} message Sync
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Sync.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.members = [];
                object.assets = [];
            }
            if (options.defaults) {
                object.syncId = "";
                object.description = "";
                object.typeId = 0;
                object.data = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                object.syncId = message.syncId;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.typeId != null && message.hasOwnProperty("typeId"))
                object.typeId = message.typeId;
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.SyncData.toObject(message.data, options);
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.api.Member.toObject(message.members[j], options);
            }
            if (message.assets && message.assets.length) {
                object.assets = [];
                for (let j = 0; j < message.assets.length; ++j)
                    object.assets[j] = $root.api.ObjectAsset.toObject(message.assets[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            return object;
        };

        /**
         * Converts this Sync to JSON.
         * @function toJSON
         * @memberof api.Sync
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Sync.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Sync;
    })();

    api.SyncDataQuery = (function() {

        /**
         * Properties of a SyncDataQuery.
         * @memberof api
         * @interface ISyncDataQuery
         */

        /**
         * Constructs a new SyncDataQuery.
         * @memberof api
         * @classdesc Represents a SyncDataQuery.
         * @implements ISyncDataQuery
         * @constructor
         * @param {api.ISyncDataQuery=} [properties] Properties to set
         */
        function SyncDataQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SyncDataQuery instance using the specified properties.
         * @function create
         * @memberof api.SyncDataQuery
         * @static
         * @param {api.ISyncDataQuery=} [properties] Properties to set
         * @returns {api.SyncDataQuery} SyncDataQuery instance
         */
        SyncDataQuery.create = function create(properties) {
            return new SyncDataQuery(properties);
        };

        /**
         * Encodes the specified SyncDataQuery message. Does not implicitly {@link api.SyncDataQuery.verify|verify} messages.
         * @function encode
         * @memberof api.SyncDataQuery
         * @static
         * @param {api.ISyncDataQuery} message SyncDataQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncDataQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SyncDataQuery message, length delimited. Does not implicitly {@link api.SyncDataQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SyncDataQuery
         * @static
         * @param {api.ISyncDataQuery} message SyncDataQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncDataQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncDataQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.SyncDataQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SyncDataQuery} SyncDataQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncDataQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SyncDataQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncDataQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SyncDataQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SyncDataQuery} SyncDataQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncDataQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncDataQuery message.
         * @function verify
         * @memberof api.SyncDataQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncDataQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SyncDataQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SyncDataQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SyncDataQuery} SyncDataQuery
         */
        SyncDataQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SyncDataQuery)
                return object;
            return new $root.api.SyncDataQuery();
        };

        /**
         * Creates a plain object from a SyncDataQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SyncDataQuery
         * @static
         * @param {api.SyncDataQuery} message SyncDataQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncDataQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SyncDataQuery to JSON.
         * @function toJSON
         * @memberof api.SyncDataQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncDataQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncDataQuery;
    })();

    api.SyncQuery = (function() {

        /**
         * Properties of a SyncQuery.
         * @memberof api
         * @interface ISyncQuery
         * @property {api.ISyncDataQuery|null} [data] SyncQuery data
         * @property {api.IObjectAssetQuery|null} [assets] SyncQuery assets
         */

        /**
         * Constructs a new SyncQuery.
         * @memberof api
         * @classdesc Represents a SyncQuery.
         * @implements ISyncQuery
         * @constructor
         * @param {api.ISyncQuery=} [properties] Properties to set
         */
        function SyncQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncQuery data.
         * @member {api.ISyncDataQuery|null|undefined} data
         * @memberof api.SyncQuery
         * @instance
         */
        SyncQuery.prototype.data = null;

        /**
         * SyncQuery assets.
         * @member {api.IObjectAssetQuery|null|undefined} assets
         * @memberof api.SyncQuery
         * @instance
         */
        SyncQuery.prototype.assets = null;

        /**
         * Creates a new SyncQuery instance using the specified properties.
         * @function create
         * @memberof api.SyncQuery
         * @static
         * @param {api.ISyncQuery=} [properties] Properties to set
         * @returns {api.SyncQuery} SyncQuery instance
         */
        SyncQuery.create = function create(properties) {
            return new SyncQuery(properties);
        };

        /**
         * Encodes the specified SyncQuery message. Does not implicitly {@link api.SyncQuery.verify|verify} messages.
         * @function encode
         * @memberof api.SyncQuery
         * @static
         * @param {api.ISyncQuery} message SyncQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                $root.api.SyncDataQuery.encode(message.data, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.assets != null && Object.hasOwnProperty.call(message, "assets"))
                $root.api.ObjectAssetQuery.encode(message.assets, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SyncQuery message, length delimited. Does not implicitly {@link api.SyncQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SyncQuery
         * @static
         * @param {api.ISyncQuery} message SyncQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.SyncQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SyncQuery} SyncQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SyncQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = $root.api.SyncDataQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.assets = $root.api.ObjectAssetQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SyncQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SyncQuery} SyncQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncQuery message.
         * @function verify
         * @memberof api.SyncQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data")) {
                let error = $root.api.SyncDataQuery.verify(message.data);
                if (error)
                    return "data." + error;
            }
            if (message.assets != null && message.hasOwnProperty("assets")) {
                let error = $root.api.ObjectAssetQuery.verify(message.assets);
                if (error)
                    return "assets." + error;
            }
            return null;
        };

        /**
         * Creates a SyncQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SyncQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SyncQuery} SyncQuery
         */
        SyncQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SyncQuery)
                return object;
            let message = new $root.api.SyncQuery();
            if (object.data != null) {
                if (typeof object.data !== "object")
                    throw TypeError(".api.SyncQuery.data: object expected");
                message.data = $root.api.SyncDataQuery.fromObject(object.data);
            }
            if (object.assets != null) {
                if (typeof object.assets !== "object")
                    throw TypeError(".api.SyncQuery.assets: object expected");
                message.assets = $root.api.ObjectAssetQuery.fromObject(object.assets);
            }
            return message;
        };

        /**
         * Creates a plain object from a SyncQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SyncQuery
         * @static
         * @param {api.SyncQuery} message SyncQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.data = null;
                object.assets = null;
            }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = $root.api.SyncDataQuery.toObject(message.data, options);
            if (message.assets != null && message.hasOwnProperty("assets"))
                object.assets = $root.api.ObjectAssetQuery.toObject(message.assets, options);
            return object;
        };

        /**
         * Converts this SyncQuery to JSON.
         * @function toJSON
         * @memberof api.SyncQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncQuery;
    })();

    api.CreateSyncRequest = (function() {

        /**
         * Properties of a CreateSyncRequest.
         * @memberof api
         * @interface ICreateSyncRequest
         * @property {api.ISync|null} [sync] CreateSyncRequest sync
         */

        /**
         * Constructs a new CreateSyncRequest.
         * @memberof api
         * @classdesc Represents a CreateSyncRequest.
         * @implements ICreateSyncRequest
         * @constructor
         * @param {api.ICreateSyncRequest=} [properties] Properties to set
         */
        function CreateSyncRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateSyncRequest sync.
         * @member {api.ISync|null|undefined} sync
         * @memberof api.CreateSyncRequest
         * @instance
         */
        CreateSyncRequest.prototype.sync = null;

        /**
         * Creates a new CreateSyncRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateSyncRequest
         * @static
         * @param {api.ICreateSyncRequest=} [properties] Properties to set
         * @returns {api.CreateSyncRequest} CreateSyncRequest instance
         */
        CreateSyncRequest.create = function create(properties) {
            return new CreateSyncRequest(properties);
        };

        /**
         * Encodes the specified CreateSyncRequest message. Does not implicitly {@link api.CreateSyncRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateSyncRequest
         * @static
         * @param {api.ICreateSyncRequest} message CreateSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSyncRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sync != null && Object.hasOwnProperty.call(message, "sync"))
                $root.api.Sync.encode(message.sync, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateSyncRequest message, length delimited. Does not implicitly {@link api.CreateSyncRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateSyncRequest
         * @static
         * @param {api.ICreateSyncRequest} message CreateSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSyncRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateSyncRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateSyncRequest} CreateSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSyncRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateSyncRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sync = $root.api.Sync.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateSyncRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateSyncRequest} CreateSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSyncRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateSyncRequest message.
         * @function verify
         * @memberof api.CreateSyncRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateSyncRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sync != null && message.hasOwnProperty("sync")) {
                let error = $root.api.Sync.verify(message.sync);
                if (error)
                    return "sync." + error;
            }
            return null;
        };

        /**
         * Creates a CreateSyncRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateSyncRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateSyncRequest} CreateSyncRequest
         */
        CreateSyncRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateSyncRequest)
                return object;
            let message = new $root.api.CreateSyncRequest();
            if (object.sync != null) {
                if (typeof object.sync !== "object")
                    throw TypeError(".api.CreateSyncRequest.sync: object expected");
                message.sync = $root.api.Sync.fromObject(object.sync);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateSyncRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateSyncRequest
         * @static
         * @param {api.CreateSyncRequest} message CreateSyncRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateSyncRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sync = null;
            if (message.sync != null && message.hasOwnProperty("sync"))
                object.sync = $root.api.Sync.toObject(message.sync, options);
            return object;
        };

        /**
         * Converts this CreateSyncRequest to JSON.
         * @function toJSON
         * @memberof api.CreateSyncRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateSyncRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateSyncRequest;
    })();

    api.SyncUpdate = (function() {

        /**
         * Properties of a SyncUpdate.
         * @memberof api
         * @interface ISyncUpdate
         * @property {boolean|null} [description] SyncUpdate description
         */

        /**
         * Constructs a new SyncUpdate.
         * @memberof api
         * @classdesc Represents a SyncUpdate.
         * @implements ISyncUpdate
         * @constructor
         * @param {api.ISyncUpdate=} [properties] Properties to set
         */
        function SyncUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncUpdate description.
         * @member {boolean} description
         * @memberof api.SyncUpdate
         * @instance
         */
        SyncUpdate.prototype.description = false;

        /**
         * Creates a new SyncUpdate instance using the specified properties.
         * @function create
         * @memberof api.SyncUpdate
         * @static
         * @param {api.ISyncUpdate=} [properties] Properties to set
         * @returns {api.SyncUpdate} SyncUpdate instance
         */
        SyncUpdate.create = function create(properties) {
            return new SyncUpdate(properties);
        };

        /**
         * Encodes the specified SyncUpdate message. Does not implicitly {@link api.SyncUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.SyncUpdate
         * @static
         * @param {api.ISyncUpdate} message SyncUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.description);
            return writer;
        };

        /**
         * Encodes the specified SyncUpdate message, length delimited. Does not implicitly {@link api.SyncUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SyncUpdate
         * @static
         * @param {api.ISyncUpdate} message SyncUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.SyncUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SyncUpdate} SyncUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SyncUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.description = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SyncUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SyncUpdate} SyncUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncUpdate message.
         * @function verify
         * @memberof api.SyncUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (typeof message.description !== "boolean")
                    return "description: boolean expected";
            return null;
        };

        /**
         * Creates a SyncUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SyncUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SyncUpdate} SyncUpdate
         */
        SyncUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SyncUpdate)
                return object;
            let message = new $root.api.SyncUpdate();
            if (object.description != null)
                message.description = Boolean(object.description);
            return message;
        };

        /**
         * Creates a plain object from a SyncUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SyncUpdate
         * @static
         * @param {api.SyncUpdate} message SyncUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.description = false;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this SyncUpdate to JSON.
         * @function toJSON
         * @memberof api.SyncUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncUpdate;
    })();

    api.UpdateSyncRequest = (function() {

        /**
         * Properties of an UpdateSyncRequest.
         * @memberof api
         * @interface IUpdateSyncRequest
         * @property {api.ISyncUpdate|null} [update] UpdateSyncRequest update
         * @property {api.ISync|null} [sync] UpdateSyncRequest sync
         */

        /**
         * Constructs a new UpdateSyncRequest.
         * @memberof api
         * @classdesc Represents an UpdateSyncRequest.
         * @implements IUpdateSyncRequest
         * @constructor
         * @param {api.IUpdateSyncRequest=} [properties] Properties to set
         */
        function UpdateSyncRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateSyncRequest update.
         * @member {api.ISyncUpdate|null|undefined} update
         * @memberof api.UpdateSyncRequest
         * @instance
         */
        UpdateSyncRequest.prototype.update = null;

        /**
         * UpdateSyncRequest sync.
         * @member {api.ISync|null|undefined} sync
         * @memberof api.UpdateSyncRequest
         * @instance
         */
        UpdateSyncRequest.prototype.sync = null;

        /**
         * Creates a new UpdateSyncRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {api.IUpdateSyncRequest=} [properties] Properties to set
         * @returns {api.UpdateSyncRequest} UpdateSyncRequest instance
         */
        UpdateSyncRequest.create = function create(properties) {
            return new UpdateSyncRequest(properties);
        };

        /**
         * Encodes the specified UpdateSyncRequest message. Does not implicitly {@link api.UpdateSyncRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {api.IUpdateSyncRequest} message UpdateSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSyncRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.SyncUpdate.encode(message.update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.sync != null && Object.hasOwnProperty.call(message, "sync"))
                $root.api.Sync.encode(message.sync, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateSyncRequest message, length delimited. Does not implicitly {@link api.UpdateSyncRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {api.IUpdateSyncRequest} message UpdateSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSyncRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateSyncRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateSyncRequest} UpdateSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSyncRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateSyncRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.update = $root.api.SyncUpdate.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.sync = $root.api.Sync.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateSyncRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateSyncRequest} UpdateSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSyncRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateSyncRequest message.
         * @function verify
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateSyncRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.SyncUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.sync != null && message.hasOwnProperty("sync")) {
                let error = $root.api.Sync.verify(message.sync);
                if (error)
                    return "sync." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateSyncRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateSyncRequest} UpdateSyncRequest
         */
        UpdateSyncRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateSyncRequest)
                return object;
            let message = new $root.api.UpdateSyncRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateSyncRequest.update: object expected");
                message.update = $root.api.SyncUpdate.fromObject(object.update);
            }
            if (object.sync != null) {
                if (typeof object.sync !== "object")
                    throw TypeError(".api.UpdateSyncRequest.sync: object expected");
                message.sync = $root.api.Sync.fromObject(object.sync);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateSyncRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateSyncRequest
         * @static
         * @param {api.UpdateSyncRequest} message UpdateSyncRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSyncRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.sync = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.SyncUpdate.toObject(message.update, options);
            if (message.sync != null && message.hasOwnProperty("sync"))
                object.sync = $root.api.Sync.toObject(message.sync, options);
            return object;
        };

        /**
         * Converts this UpdateSyncRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateSyncRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSyncRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateSyncRequest;
    })();

    api.DeleteSyncRequest = (function() {

        /**
         * Properties of a DeleteSyncRequest.
         * @memberof api
         * @interface IDeleteSyncRequest
         * @property {string|null} [syncId] DeleteSyncRequest syncId
         */

        /**
         * Constructs a new DeleteSyncRequest.
         * @memberof api
         * @classdesc Represents a DeleteSyncRequest.
         * @implements IDeleteSyncRequest
         * @constructor
         * @param {api.IDeleteSyncRequest=} [properties] Properties to set
         */
        function DeleteSyncRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteSyncRequest syncId.
         * @member {string} syncId
         * @memberof api.DeleteSyncRequest
         * @instance
         */
        DeleteSyncRequest.prototype.syncId = "";

        /**
         * Creates a new DeleteSyncRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {api.IDeleteSyncRequest=} [properties] Properties to set
         * @returns {api.DeleteSyncRequest} DeleteSyncRequest instance
         */
        DeleteSyncRequest.create = function create(properties) {
            return new DeleteSyncRequest(properties);
        };

        /**
         * Encodes the specified DeleteSyncRequest message. Does not implicitly {@link api.DeleteSyncRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {api.IDeleteSyncRequest} message DeleteSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteSyncRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncId != null && Object.hasOwnProperty.call(message, "syncId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.syncId);
            return writer;
        };

        /**
         * Encodes the specified DeleteSyncRequest message, length delimited. Does not implicitly {@link api.DeleteSyncRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {api.IDeleteSyncRequest} message DeleteSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteSyncRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteSyncRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteSyncRequest} DeleteSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteSyncRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteSyncRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteSyncRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteSyncRequest} DeleteSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteSyncRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteSyncRequest message.
         * @function verify
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteSyncRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                if (!$util.isString(message.syncId))
                    return "syncId: string expected";
            return null;
        };

        /**
         * Creates a DeleteSyncRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteSyncRequest} DeleteSyncRequest
         */
        DeleteSyncRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteSyncRequest)
                return object;
            let message = new $root.api.DeleteSyncRequest();
            if (object.syncId != null)
                message.syncId = String(object.syncId);
            return message;
        };

        /**
         * Creates a plain object from a DeleteSyncRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteSyncRequest
         * @static
         * @param {api.DeleteSyncRequest} message DeleteSyncRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteSyncRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.syncId = "";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                object.syncId = message.syncId;
            return object;
        };

        /**
         * Converts this DeleteSyncRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteSyncRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteSyncRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteSyncRequest;
    })();

    api.TriggerSyncNameValue = (function() {

        /**
         * Properties of a TriggerSyncNameValue.
         * @memberof api
         * @interface ITriggerSyncNameValue
         * @property {string|null} [name] TriggerSyncNameValue name
         * @property {string|null} [value] TriggerSyncNameValue value
         */

        /**
         * Constructs a new TriggerSyncNameValue.
         * @memberof api
         * @classdesc Represents a TriggerSyncNameValue.
         * @implements ITriggerSyncNameValue
         * @constructor
         * @param {api.ITriggerSyncNameValue=} [properties] Properties to set
         */
        function TriggerSyncNameValue(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TriggerSyncNameValue name.
         * @member {string} name
         * @memberof api.TriggerSyncNameValue
         * @instance
         */
        TriggerSyncNameValue.prototype.name = "";

        /**
         * TriggerSyncNameValue value.
         * @member {string} value
         * @memberof api.TriggerSyncNameValue
         * @instance
         */
        TriggerSyncNameValue.prototype.value = "";

        /**
         * Creates a new TriggerSyncNameValue instance using the specified properties.
         * @function create
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {api.ITriggerSyncNameValue=} [properties] Properties to set
         * @returns {api.TriggerSyncNameValue} TriggerSyncNameValue instance
         */
        TriggerSyncNameValue.create = function create(properties) {
            return new TriggerSyncNameValue(properties);
        };

        /**
         * Encodes the specified TriggerSyncNameValue message. Does not implicitly {@link api.TriggerSyncNameValue.verify|verify} messages.
         * @function encode
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {api.ITriggerSyncNameValue} message TriggerSyncNameValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerSyncNameValue.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };

        /**
         * Encodes the specified TriggerSyncNameValue message, length delimited. Does not implicitly {@link api.TriggerSyncNameValue.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {api.ITriggerSyncNameValue} message TriggerSyncNameValue message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerSyncNameValue.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerSyncNameValue message from the specified reader or buffer.
         * @function decode
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TriggerSyncNameValue} TriggerSyncNameValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerSyncNameValue.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TriggerSyncNameValue();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerSyncNameValue message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TriggerSyncNameValue} TriggerSyncNameValue
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerSyncNameValue.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerSyncNameValue message.
         * @function verify
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerSyncNameValue.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };

        /**
         * Creates a TriggerSyncNameValue message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TriggerSyncNameValue} TriggerSyncNameValue
         */
        TriggerSyncNameValue.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TriggerSyncNameValue)
                return object;
            let message = new $root.api.TriggerSyncNameValue();
            if (object.name != null)
                message.name = String(object.name);
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };

        /**
         * Creates a plain object from a TriggerSyncNameValue message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TriggerSyncNameValue
         * @static
         * @param {api.TriggerSyncNameValue} message TriggerSyncNameValue
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerSyncNameValue.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.value = "";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };

        /**
         * Converts this TriggerSyncNameValue to JSON.
         * @function toJSON
         * @memberof api.TriggerSyncNameValue
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerSyncNameValue.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerSyncNameValue;
    })();

    api.TriggerSyncRequest = (function() {

        /**
         * Properties of a TriggerSyncRequest.
         * @memberof api
         * @interface ITriggerSyncRequest
         * @property {string|null} [syncId] TriggerSyncRequest syncId
         * @property {Array.<api.ITriggerSyncNameValue>|null} [nameValues] TriggerSyncRequest nameValues
         */

        /**
         * Constructs a new TriggerSyncRequest.
         * @memberof api
         * @classdesc Represents a TriggerSyncRequest.
         * @implements ITriggerSyncRequest
         * @constructor
         * @param {api.ITriggerSyncRequest=} [properties] Properties to set
         */
        function TriggerSyncRequest(properties) {
            this.nameValues = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TriggerSyncRequest syncId.
         * @member {string} syncId
         * @memberof api.TriggerSyncRequest
         * @instance
         */
        TriggerSyncRequest.prototype.syncId = "";

        /**
         * TriggerSyncRequest nameValues.
         * @member {Array.<api.ITriggerSyncNameValue>} nameValues
         * @memberof api.TriggerSyncRequest
         * @instance
         */
        TriggerSyncRequest.prototype.nameValues = $util.emptyArray;

        /**
         * Creates a new TriggerSyncRequest instance using the specified properties.
         * @function create
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {api.ITriggerSyncRequest=} [properties] Properties to set
         * @returns {api.TriggerSyncRequest} TriggerSyncRequest instance
         */
        TriggerSyncRequest.create = function create(properties) {
            return new TriggerSyncRequest(properties);
        };

        /**
         * Encodes the specified TriggerSyncRequest message. Does not implicitly {@link api.TriggerSyncRequest.verify|verify} messages.
         * @function encode
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {api.ITriggerSyncRequest} message TriggerSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerSyncRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncId != null && Object.hasOwnProperty.call(message, "syncId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.syncId);
            if (message.nameValues != null && message.nameValues.length)
                for (let i = 0; i < message.nameValues.length; ++i)
                    $root.api.TriggerSyncNameValue.encode(message.nameValues[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TriggerSyncRequest message, length delimited. Does not implicitly {@link api.TriggerSyncRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {api.ITriggerSyncRequest} message TriggerSyncRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerSyncRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerSyncRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TriggerSyncRequest} TriggerSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerSyncRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TriggerSyncRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncId = reader.string();
                    break;
                case 2:
                    if (!(message.nameValues && message.nameValues.length))
                        message.nameValues = [];
                    message.nameValues.push($root.api.TriggerSyncNameValue.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerSyncRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TriggerSyncRequest} TriggerSyncRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerSyncRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerSyncRequest message.
         * @function verify
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerSyncRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                if (!$util.isString(message.syncId))
                    return "syncId: string expected";
            if (message.nameValues != null && message.hasOwnProperty("nameValues")) {
                if (!Array.isArray(message.nameValues))
                    return "nameValues: array expected";
                for (let i = 0; i < message.nameValues.length; ++i) {
                    let error = $root.api.TriggerSyncNameValue.verify(message.nameValues[i]);
                    if (error)
                        return "nameValues." + error;
                }
            }
            return null;
        };

        /**
         * Creates a TriggerSyncRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TriggerSyncRequest} TriggerSyncRequest
         */
        TriggerSyncRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TriggerSyncRequest)
                return object;
            let message = new $root.api.TriggerSyncRequest();
            if (object.syncId != null)
                message.syncId = String(object.syncId);
            if (object.nameValues) {
                if (!Array.isArray(object.nameValues))
                    throw TypeError(".api.TriggerSyncRequest.nameValues: array expected");
                message.nameValues = [];
                for (let i = 0; i < object.nameValues.length; ++i) {
                    if (typeof object.nameValues[i] !== "object")
                        throw TypeError(".api.TriggerSyncRequest.nameValues: object expected");
                    message.nameValues[i] = $root.api.TriggerSyncNameValue.fromObject(object.nameValues[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a TriggerSyncRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TriggerSyncRequest
         * @static
         * @param {api.TriggerSyncRequest} message TriggerSyncRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerSyncRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.nameValues = [];
            if (options.defaults)
                object.syncId = "";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                object.syncId = message.syncId;
            if (message.nameValues && message.nameValues.length) {
                object.nameValues = [];
                for (let j = 0; j < message.nameValues.length; ++j)
                    object.nameValues[j] = $root.api.TriggerSyncNameValue.toObject(message.nameValues[j], options);
            }
            return object;
        };

        /**
         * Converts this TriggerSyncRequest to JSON.
         * @function toJSON
         * @memberof api.TriggerSyncRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerSyncRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerSyncRequest;
    })();

    /**
     * TriggerType enum.
     * @name api.TriggerType
     * @enum {number}
     * @property {number} TriggerAll=0 TriggerAll value
     * @property {number} TriggerGeneral=1 TriggerGeneral value
     * @property {number} TriggerUsers=2 TriggerUsers value
     * @property {number} TriggerTopics=3 TriggerTopics value
     */
    api.TriggerType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "TriggerAll"] = 0;
        values[valuesById[1] = "TriggerGeneral"] = 1;
        values[valuesById[2] = "TriggerUsers"] = 2;
        values[valuesById[3] = "TriggerTopics"] = 3;
        return values;
    })();

    api.TriggerRenderRequest = (function() {

        /**
         * Properties of a TriggerRenderRequest.
         * @memberof api
         * @interface ITriggerRenderRequest
         * @property {api.TriggerType|null} [triggerType] TriggerRenderRequest triggerType
         */

        /**
         * Constructs a new TriggerRenderRequest.
         * @memberof api
         * @classdesc Represents a TriggerRenderRequest.
         * @implements ITriggerRenderRequest
         * @constructor
         * @param {api.ITriggerRenderRequest=} [properties] Properties to set
         */
        function TriggerRenderRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TriggerRenderRequest triggerType.
         * @member {api.TriggerType} triggerType
         * @memberof api.TriggerRenderRequest
         * @instance
         */
        TriggerRenderRequest.prototype.triggerType = 0;

        /**
         * Creates a new TriggerRenderRequest instance using the specified properties.
         * @function create
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {api.ITriggerRenderRequest=} [properties] Properties to set
         * @returns {api.TriggerRenderRequest} TriggerRenderRequest instance
         */
        TriggerRenderRequest.create = function create(properties) {
            return new TriggerRenderRequest(properties);
        };

        /**
         * Encodes the specified TriggerRenderRequest message. Does not implicitly {@link api.TriggerRenderRequest.verify|verify} messages.
         * @function encode
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {api.ITriggerRenderRequest} message TriggerRenderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerRenderRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.triggerType != null && Object.hasOwnProperty.call(message, "triggerType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.triggerType);
            return writer;
        };

        /**
         * Encodes the specified TriggerRenderRequest message, length delimited. Does not implicitly {@link api.TriggerRenderRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {api.ITriggerRenderRequest} message TriggerRenderRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerRenderRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerRenderRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TriggerRenderRequest} TriggerRenderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerRenderRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TriggerRenderRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.triggerType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerRenderRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TriggerRenderRequest} TriggerRenderRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerRenderRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerRenderRequest message.
         * @function verify
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerRenderRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.triggerType != null && message.hasOwnProperty("triggerType"))
                switch (message.triggerType) {
                default:
                    return "triggerType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            return null;
        };

        /**
         * Creates a TriggerRenderRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TriggerRenderRequest} TriggerRenderRequest
         */
        TriggerRenderRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TriggerRenderRequest)
                return object;
            let message = new $root.api.TriggerRenderRequest();
            switch (object.triggerType) {
            case "TriggerAll":
            case 0:
                message.triggerType = 0;
                break;
            case "TriggerGeneral":
            case 1:
                message.triggerType = 1;
                break;
            case "TriggerUsers":
            case 2:
                message.triggerType = 2;
                break;
            case "TriggerTopics":
            case 3:
                message.triggerType = 3;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a TriggerRenderRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TriggerRenderRequest
         * @static
         * @param {api.TriggerRenderRequest} message TriggerRenderRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerRenderRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.triggerType = options.enums === String ? "TriggerAll" : 0;
            if (message.triggerType != null && message.hasOwnProperty("triggerType"))
                object.triggerType = options.enums === String ? $root.api.TriggerType[message.triggerType] : message.triggerType;
            return object;
        };

        /**
         * Converts this TriggerRenderRequest to JSON.
         * @function toJSON
         * @memberof api.TriggerRenderRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerRenderRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerRenderRequest;
    })();

    api.RenderStatusRequest = (function() {

        /**
         * Properties of a RenderStatusRequest.
         * @memberof api
         * @interface IRenderStatusRequest
         * @property {string|null} [renderId] RenderStatusRequest renderId
         */

        /**
         * Constructs a new RenderStatusRequest.
         * @memberof api
         * @classdesc Represents a RenderStatusRequest.
         * @implements IRenderStatusRequest
         * @constructor
         * @param {api.IRenderStatusRequest=} [properties] Properties to set
         */
        function RenderStatusRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RenderStatusRequest renderId.
         * @member {string} renderId
         * @memberof api.RenderStatusRequest
         * @instance
         */
        RenderStatusRequest.prototype.renderId = "";

        /**
         * Creates a new RenderStatusRequest instance using the specified properties.
         * @function create
         * @memberof api.RenderStatusRequest
         * @static
         * @param {api.IRenderStatusRequest=} [properties] Properties to set
         * @returns {api.RenderStatusRequest} RenderStatusRequest instance
         */
        RenderStatusRequest.create = function create(properties) {
            return new RenderStatusRequest(properties);
        };

        /**
         * Encodes the specified RenderStatusRequest message. Does not implicitly {@link api.RenderStatusRequest.verify|verify} messages.
         * @function encode
         * @memberof api.RenderStatusRequest
         * @static
         * @param {api.IRenderStatusRequest} message RenderStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RenderStatusRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.renderId != null && Object.hasOwnProperty.call(message, "renderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.renderId);
            return writer;
        };

        /**
         * Encodes the specified RenderStatusRequest message, length delimited. Does not implicitly {@link api.RenderStatusRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RenderStatusRequest
         * @static
         * @param {api.IRenderStatusRequest} message RenderStatusRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RenderStatusRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RenderStatusRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.RenderStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RenderStatusRequest} RenderStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RenderStatusRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RenderStatusRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.renderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RenderStatusRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RenderStatusRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RenderStatusRequest} RenderStatusRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RenderStatusRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RenderStatusRequest message.
         * @function verify
         * @memberof api.RenderStatusRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RenderStatusRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.renderId != null && message.hasOwnProperty("renderId"))
                if (!$util.isString(message.renderId))
                    return "renderId: string expected";
            return null;
        };

        /**
         * Creates a RenderStatusRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RenderStatusRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RenderStatusRequest} RenderStatusRequest
         */
        RenderStatusRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RenderStatusRequest)
                return object;
            let message = new $root.api.RenderStatusRequest();
            if (object.renderId != null)
                message.renderId = String(object.renderId);
            return message;
        };

        /**
         * Creates a plain object from a RenderStatusRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RenderStatusRequest
         * @static
         * @param {api.RenderStatusRequest} message RenderStatusRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RenderStatusRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.renderId = "";
            if (message.renderId != null && message.hasOwnProperty("renderId"))
                object.renderId = message.renderId;
            return object;
        };

        /**
         * Converts this RenderStatusRequest to JSON.
         * @function toJSON
         * @memberof api.RenderStatusRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RenderStatusRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RenderStatusRequest;
    })();

    /**
     * IntegrationType enum.
     * @name api.IntegrationType
     * @enum {number}
     * @property {number} GoogleCalendarAndMeet=0 GoogleCalendarAndMeet value
     * @property {number} Zoom=1 Zoom value
     */
    api.IntegrationType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "GoogleCalendarAndMeet"] = 0;
        values[valuesById[1] = "Zoom"] = 1;
        return values;
    })();

    api.Integration = (function() {

        /**
         * Properties of an Integration.
         * @memberof api
         * @interface IIntegration
         * @property {api.IntegrationType|null} [integrationType] Integration integrationType
         * @property {string|null} [redirectUri] Integration redirectUri
         * @property {string|null} [authCode] Integration authCode
         * @property {boolean|null} [syncCalendar] Integration syncCalendar
         * @property {boolean|null} [createMeeting] Integration createMeeting
         */

        /**
         * Constructs a new Integration.
         * @memberof api
         * @classdesc Represents an Integration.
         * @implements IIntegration
         * @constructor
         * @param {api.IIntegration=} [properties] Properties to set
         */
        function Integration(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Integration integrationType.
         * @member {api.IntegrationType} integrationType
         * @memberof api.Integration
         * @instance
         */
        Integration.prototype.integrationType = 0;

        /**
         * Integration redirectUri.
         * @member {string} redirectUri
         * @memberof api.Integration
         * @instance
         */
        Integration.prototype.redirectUri = "";

        /**
         * Integration authCode.
         * @member {string} authCode
         * @memberof api.Integration
         * @instance
         */
        Integration.prototype.authCode = "";

        /**
         * Integration syncCalendar.
         * @member {boolean} syncCalendar
         * @memberof api.Integration
         * @instance
         */
        Integration.prototype.syncCalendar = false;

        /**
         * Integration createMeeting.
         * @member {boolean} createMeeting
         * @memberof api.Integration
         * @instance
         */
        Integration.prototype.createMeeting = false;

        /**
         * Creates a new Integration instance using the specified properties.
         * @function create
         * @memberof api.Integration
         * @static
         * @param {api.IIntegration=} [properties] Properties to set
         * @returns {api.Integration} Integration instance
         */
        Integration.create = function create(properties) {
            return new Integration(properties);
        };

        /**
         * Encodes the specified Integration message. Does not implicitly {@link api.Integration.verify|verify} messages.
         * @function encode
         * @memberof api.Integration
         * @static
         * @param {api.IIntegration} message Integration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Integration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.integrationType != null && Object.hasOwnProperty.call(message, "integrationType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.integrationType);
            if (message.authCode != null && Object.hasOwnProperty.call(message, "authCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.authCode);
            if (message.syncCalendar != null && Object.hasOwnProperty.call(message, "syncCalendar"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.syncCalendar);
            if (message.createMeeting != null && Object.hasOwnProperty.call(message, "createMeeting"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.createMeeting);
            if (message.redirectUri != null && Object.hasOwnProperty.call(message, "redirectUri"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.redirectUri);
            return writer;
        };

        /**
         * Encodes the specified Integration message, length delimited. Does not implicitly {@link api.Integration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Integration
         * @static
         * @param {api.IIntegration} message Integration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Integration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Integration message from the specified reader or buffer.
         * @function decode
         * @memberof api.Integration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Integration} Integration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Integration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Integration();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.integrationType = reader.int32();
                    break;
                case 5:
                    message.redirectUri = reader.string();
                    break;
                case 2:
                    message.authCode = reader.string();
                    break;
                case 3:
                    message.syncCalendar = reader.bool();
                    break;
                case 4:
                    message.createMeeting = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Integration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Integration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Integration} Integration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Integration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Integration message.
         * @function verify
         * @memberof api.Integration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Integration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.integrationType != null && message.hasOwnProperty("integrationType"))
                switch (message.integrationType) {
                default:
                    return "integrationType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.redirectUri != null && message.hasOwnProperty("redirectUri"))
                if (!$util.isString(message.redirectUri))
                    return "redirectUri: string expected";
            if (message.authCode != null && message.hasOwnProperty("authCode"))
                if (!$util.isString(message.authCode))
                    return "authCode: string expected";
            if (message.syncCalendar != null && message.hasOwnProperty("syncCalendar"))
                if (typeof message.syncCalendar !== "boolean")
                    return "syncCalendar: boolean expected";
            if (message.createMeeting != null && message.hasOwnProperty("createMeeting"))
                if (typeof message.createMeeting !== "boolean")
                    return "createMeeting: boolean expected";
            return null;
        };

        /**
         * Creates an Integration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Integration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Integration} Integration
         */
        Integration.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Integration)
                return object;
            let message = new $root.api.Integration();
            switch (object.integrationType) {
            case "GoogleCalendarAndMeet":
            case 0:
                message.integrationType = 0;
                break;
            case "Zoom":
            case 1:
                message.integrationType = 1;
                break;
            }
            if (object.redirectUri != null)
                message.redirectUri = String(object.redirectUri);
            if (object.authCode != null)
                message.authCode = String(object.authCode);
            if (object.syncCalendar != null)
                message.syncCalendar = Boolean(object.syncCalendar);
            if (object.createMeeting != null)
                message.createMeeting = Boolean(object.createMeeting);
            return message;
        };

        /**
         * Creates a plain object from an Integration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Integration
         * @static
         * @param {api.Integration} message Integration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Integration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.integrationType = options.enums === String ? "GoogleCalendarAndMeet" : 0;
                object.authCode = "";
                object.syncCalendar = false;
                object.createMeeting = false;
                object.redirectUri = "";
            }
            if (message.integrationType != null && message.hasOwnProperty("integrationType"))
                object.integrationType = options.enums === String ? $root.api.IntegrationType[message.integrationType] : message.integrationType;
            if (message.authCode != null && message.hasOwnProperty("authCode"))
                object.authCode = message.authCode;
            if (message.syncCalendar != null && message.hasOwnProperty("syncCalendar"))
                object.syncCalendar = message.syncCalendar;
            if (message.createMeeting != null && message.hasOwnProperty("createMeeting"))
                object.createMeeting = message.createMeeting;
            if (message.redirectUri != null && message.hasOwnProperty("redirectUri"))
                object.redirectUri = message.redirectUri;
            return object;
        };

        /**
         * Converts this Integration to JSON.
         * @function toJSON
         * @memberof api.Integration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Integration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Integration;
    })();

    api.IntegrationQuery = (function() {

        /**
         * Properties of an IntegrationQuery.
         * @memberof api
         * @interface IIntegrationQuery
         */

        /**
         * Constructs a new IntegrationQuery.
         * @memberof api
         * @classdesc Represents an IntegrationQuery.
         * @implements IIntegrationQuery
         * @constructor
         * @param {api.IIntegrationQuery=} [properties] Properties to set
         */
        function IntegrationQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new IntegrationQuery instance using the specified properties.
         * @function create
         * @memberof api.IntegrationQuery
         * @static
         * @param {api.IIntegrationQuery=} [properties] Properties to set
         * @returns {api.IntegrationQuery} IntegrationQuery instance
         */
        IntegrationQuery.create = function create(properties) {
            return new IntegrationQuery(properties);
        };

        /**
         * Encodes the specified IntegrationQuery message. Does not implicitly {@link api.IntegrationQuery.verify|verify} messages.
         * @function encode
         * @memberof api.IntegrationQuery
         * @static
         * @param {api.IIntegrationQuery} message IntegrationQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrationQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified IntegrationQuery message, length delimited. Does not implicitly {@link api.IntegrationQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.IntegrationQuery
         * @static
         * @param {api.IIntegrationQuery} message IntegrationQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrationQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IntegrationQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.IntegrationQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.IntegrationQuery} IntegrationQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrationQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.IntegrationQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IntegrationQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.IntegrationQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.IntegrationQuery} IntegrationQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrationQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IntegrationQuery message.
         * @function verify
         * @memberof api.IntegrationQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IntegrationQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an IntegrationQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.IntegrationQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.IntegrationQuery} IntegrationQuery
         */
        IntegrationQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.IntegrationQuery)
                return object;
            return new $root.api.IntegrationQuery();
        };

        /**
         * Creates a plain object from an IntegrationQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.IntegrationQuery
         * @static
         * @param {api.IntegrationQuery} message IntegrationQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IntegrationQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this IntegrationQuery to JSON.
         * @function toJSON
         * @memberof api.IntegrationQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IntegrationQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IntegrationQuery;
    })();

    api.GetIntegrationsRequest = (function() {

        /**
         * Properties of a GetIntegrationsRequest.
         * @memberof api
         * @interface IGetIntegrationsRequest
         * @property {api.IIntegrationQuery|null} [query] GetIntegrationsRequest query
         */

        /**
         * Constructs a new GetIntegrationsRequest.
         * @memberof api
         * @classdesc Represents a GetIntegrationsRequest.
         * @implements IGetIntegrationsRequest
         * @constructor
         * @param {api.IGetIntegrationsRequest=} [properties] Properties to set
         */
        function GetIntegrationsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetIntegrationsRequest query.
         * @member {api.IIntegrationQuery|null|undefined} query
         * @memberof api.GetIntegrationsRequest
         * @instance
         */
        GetIntegrationsRequest.prototype.query = null;

        /**
         * Creates a new GetIntegrationsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {api.IGetIntegrationsRequest=} [properties] Properties to set
         * @returns {api.GetIntegrationsRequest} GetIntegrationsRequest instance
         */
        GetIntegrationsRequest.create = function create(properties) {
            return new GetIntegrationsRequest(properties);
        };

        /**
         * Encodes the specified GetIntegrationsRequest message. Does not implicitly {@link api.GetIntegrationsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {api.IGetIntegrationsRequest} message GetIntegrationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetIntegrationsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.IntegrationQuery.encode(message.query, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetIntegrationsRequest message, length delimited. Does not implicitly {@link api.GetIntegrationsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {api.IGetIntegrationsRequest} message GetIntegrationsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetIntegrationsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetIntegrationsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetIntegrationsRequest} GetIntegrationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetIntegrationsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetIntegrationsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.query = $root.api.IntegrationQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetIntegrationsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetIntegrationsRequest} GetIntegrationsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetIntegrationsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetIntegrationsRequest message.
         * @function verify
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetIntegrationsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.IntegrationQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetIntegrationsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetIntegrationsRequest} GetIntegrationsRequest
         */
        GetIntegrationsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetIntegrationsRequest)
                return object;
            let message = new $root.api.GetIntegrationsRequest();
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetIntegrationsRequest.query: object expected");
                message.query = $root.api.IntegrationQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetIntegrationsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetIntegrationsRequest
         * @static
         * @param {api.GetIntegrationsRequest} message GetIntegrationsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetIntegrationsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = null;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.IntegrationQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetIntegrationsRequest to JSON.
         * @function toJSON
         * @memberof api.GetIntegrationsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetIntegrationsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetIntegrationsRequest;
    })();

    api.AddIntegrationRequest = (function() {

        /**
         * Properties of an AddIntegrationRequest.
         * @memberof api
         * @interface IAddIntegrationRequest
         * @property {api.IIntegration|null} [integration] AddIntegrationRequest integration
         * @property {boolean|null} [updateExistingMeetings] AddIntegrationRequest updateExistingMeetings
         */

        /**
         * Constructs a new AddIntegrationRequest.
         * @memberof api
         * @classdesc Represents an AddIntegrationRequest.
         * @implements IAddIntegrationRequest
         * @constructor
         * @param {api.IAddIntegrationRequest=} [properties] Properties to set
         */
        function AddIntegrationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddIntegrationRequest integration.
         * @member {api.IIntegration|null|undefined} integration
         * @memberof api.AddIntegrationRequest
         * @instance
         */
        AddIntegrationRequest.prototype.integration = null;

        /**
         * AddIntegrationRequest updateExistingMeetings.
         * @member {boolean} updateExistingMeetings
         * @memberof api.AddIntegrationRequest
         * @instance
         */
        AddIntegrationRequest.prototype.updateExistingMeetings = false;

        /**
         * Creates a new AddIntegrationRequest instance using the specified properties.
         * @function create
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {api.IAddIntegrationRequest=} [properties] Properties to set
         * @returns {api.AddIntegrationRequest} AddIntegrationRequest instance
         */
        AddIntegrationRequest.create = function create(properties) {
            return new AddIntegrationRequest(properties);
        };

        /**
         * Encodes the specified AddIntegrationRequest message. Does not implicitly {@link api.AddIntegrationRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {api.IAddIntegrationRequest} message AddIntegrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddIntegrationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.integration != null && Object.hasOwnProperty.call(message, "integration"))
                $root.api.Integration.encode(message.integration, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.updateExistingMeetings != null && Object.hasOwnProperty.call(message, "updateExistingMeetings"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.updateExistingMeetings);
            return writer;
        };

        /**
         * Encodes the specified AddIntegrationRequest message, length delimited. Does not implicitly {@link api.AddIntegrationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {api.IAddIntegrationRequest} message AddIntegrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddIntegrationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddIntegrationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddIntegrationRequest} AddIntegrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddIntegrationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddIntegrationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.integration = $root.api.Integration.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.updateExistingMeetings = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddIntegrationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddIntegrationRequest} AddIntegrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddIntegrationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddIntegrationRequest message.
         * @function verify
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddIntegrationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.integration != null && message.hasOwnProperty("integration")) {
                let error = $root.api.Integration.verify(message.integration);
                if (error)
                    return "integration." + error;
            }
            if (message.updateExistingMeetings != null && message.hasOwnProperty("updateExistingMeetings"))
                if (typeof message.updateExistingMeetings !== "boolean")
                    return "updateExistingMeetings: boolean expected";
            return null;
        };

        /**
         * Creates an AddIntegrationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddIntegrationRequest} AddIntegrationRequest
         */
        AddIntegrationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddIntegrationRequest)
                return object;
            let message = new $root.api.AddIntegrationRequest();
            if (object.integration != null) {
                if (typeof object.integration !== "object")
                    throw TypeError(".api.AddIntegrationRequest.integration: object expected");
                message.integration = $root.api.Integration.fromObject(object.integration);
            }
            if (object.updateExistingMeetings != null)
                message.updateExistingMeetings = Boolean(object.updateExistingMeetings);
            return message;
        };

        /**
         * Creates a plain object from an AddIntegrationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddIntegrationRequest
         * @static
         * @param {api.AddIntegrationRequest} message AddIntegrationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddIntegrationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.integration = null;
                object.updateExistingMeetings = false;
            }
            if (message.integration != null && message.hasOwnProperty("integration"))
                object.integration = $root.api.Integration.toObject(message.integration, options);
            if (message.updateExistingMeetings != null && message.hasOwnProperty("updateExistingMeetings"))
                object.updateExistingMeetings = message.updateExistingMeetings;
            return object;
        };

        /**
         * Converts this AddIntegrationRequest to JSON.
         * @function toJSON
         * @memberof api.AddIntegrationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddIntegrationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddIntegrationRequest;
    })();

    api.IntegrationUpdate = (function() {

        /**
         * Properties of an IntegrationUpdate.
         * @memberof api
         * @interface IIntegrationUpdate
         * @property {boolean|null} [syncCalendar] IntegrationUpdate syncCalendar
         * @property {boolean|null} [createMeeting] IntegrationUpdate createMeeting
         */

        /**
         * Constructs a new IntegrationUpdate.
         * @memberof api
         * @classdesc Represents an IntegrationUpdate.
         * @implements IIntegrationUpdate
         * @constructor
         * @param {api.IIntegrationUpdate=} [properties] Properties to set
         */
        function IntegrationUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * IntegrationUpdate syncCalendar.
         * @member {boolean} syncCalendar
         * @memberof api.IntegrationUpdate
         * @instance
         */
        IntegrationUpdate.prototype.syncCalendar = false;

        /**
         * IntegrationUpdate createMeeting.
         * @member {boolean} createMeeting
         * @memberof api.IntegrationUpdate
         * @instance
         */
        IntegrationUpdate.prototype.createMeeting = false;

        /**
         * Creates a new IntegrationUpdate instance using the specified properties.
         * @function create
         * @memberof api.IntegrationUpdate
         * @static
         * @param {api.IIntegrationUpdate=} [properties] Properties to set
         * @returns {api.IntegrationUpdate} IntegrationUpdate instance
         */
        IntegrationUpdate.create = function create(properties) {
            return new IntegrationUpdate(properties);
        };

        /**
         * Encodes the specified IntegrationUpdate message. Does not implicitly {@link api.IntegrationUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.IntegrationUpdate
         * @static
         * @param {api.IIntegrationUpdate} message IntegrationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrationUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncCalendar != null && Object.hasOwnProperty.call(message, "syncCalendar"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.syncCalendar);
            if (message.createMeeting != null && Object.hasOwnProperty.call(message, "createMeeting"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.createMeeting);
            return writer;
        };

        /**
         * Encodes the specified IntegrationUpdate message, length delimited. Does not implicitly {@link api.IntegrationUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.IntegrationUpdate
         * @static
         * @param {api.IIntegrationUpdate} message IntegrationUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        IntegrationUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an IntegrationUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.IntegrationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.IntegrationUpdate} IntegrationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrationUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.IntegrationUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncCalendar = reader.bool();
                    break;
                case 2:
                    message.createMeeting = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an IntegrationUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.IntegrationUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.IntegrationUpdate} IntegrationUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        IntegrationUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an IntegrationUpdate message.
         * @function verify
         * @memberof api.IntegrationUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        IntegrationUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncCalendar != null && message.hasOwnProperty("syncCalendar"))
                if (typeof message.syncCalendar !== "boolean")
                    return "syncCalendar: boolean expected";
            if (message.createMeeting != null && message.hasOwnProperty("createMeeting"))
                if (typeof message.createMeeting !== "boolean")
                    return "createMeeting: boolean expected";
            return null;
        };

        /**
         * Creates an IntegrationUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.IntegrationUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.IntegrationUpdate} IntegrationUpdate
         */
        IntegrationUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.IntegrationUpdate)
                return object;
            let message = new $root.api.IntegrationUpdate();
            if (object.syncCalendar != null)
                message.syncCalendar = Boolean(object.syncCalendar);
            if (object.createMeeting != null)
                message.createMeeting = Boolean(object.createMeeting);
            return message;
        };

        /**
         * Creates a plain object from an IntegrationUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.IntegrationUpdate
         * @static
         * @param {api.IntegrationUpdate} message IntegrationUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        IntegrationUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.syncCalendar = false;
                object.createMeeting = false;
            }
            if (message.syncCalendar != null && message.hasOwnProperty("syncCalendar"))
                object.syncCalendar = message.syncCalendar;
            if (message.createMeeting != null && message.hasOwnProperty("createMeeting"))
                object.createMeeting = message.createMeeting;
            return object;
        };

        /**
         * Converts this IntegrationUpdate to JSON.
         * @function toJSON
         * @memberof api.IntegrationUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        IntegrationUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return IntegrationUpdate;
    })();

    api.UpdateIntegrationRequest = (function() {

        /**
         * Properties of an UpdateIntegrationRequest.
         * @memberof api
         * @interface IUpdateIntegrationRequest
         * @property {api.IIntegrationUpdate|null} [update] UpdateIntegrationRequest update
         * @property {api.IIntegration|null} [integration] UpdateIntegrationRequest integration
         */

        /**
         * Constructs a new UpdateIntegrationRequest.
         * @memberof api
         * @classdesc Represents an UpdateIntegrationRequest.
         * @implements IUpdateIntegrationRequest
         * @constructor
         * @param {api.IUpdateIntegrationRequest=} [properties] Properties to set
         */
        function UpdateIntegrationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateIntegrationRequest update.
         * @member {api.IIntegrationUpdate|null|undefined} update
         * @memberof api.UpdateIntegrationRequest
         * @instance
         */
        UpdateIntegrationRequest.prototype.update = null;

        /**
         * UpdateIntegrationRequest integration.
         * @member {api.IIntegration|null|undefined} integration
         * @memberof api.UpdateIntegrationRequest
         * @instance
         */
        UpdateIntegrationRequest.prototype.integration = null;

        /**
         * Creates a new UpdateIntegrationRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {api.IUpdateIntegrationRequest=} [properties] Properties to set
         * @returns {api.UpdateIntegrationRequest} UpdateIntegrationRequest instance
         */
        UpdateIntegrationRequest.create = function create(properties) {
            return new UpdateIntegrationRequest(properties);
        };

        /**
         * Encodes the specified UpdateIntegrationRequest message. Does not implicitly {@link api.UpdateIntegrationRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {api.IUpdateIntegrationRequest} message UpdateIntegrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateIntegrationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.IntegrationUpdate.encode(message.update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.integration != null && Object.hasOwnProperty.call(message, "integration"))
                $root.api.Integration.encode(message.integration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateIntegrationRequest message, length delimited. Does not implicitly {@link api.UpdateIntegrationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {api.IUpdateIntegrationRequest} message UpdateIntegrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateIntegrationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateIntegrationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateIntegrationRequest} UpdateIntegrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateIntegrationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateIntegrationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.update = $root.api.IntegrationUpdate.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.integration = $root.api.Integration.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateIntegrationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateIntegrationRequest} UpdateIntegrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateIntegrationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateIntegrationRequest message.
         * @function verify
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateIntegrationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.IntegrationUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.integration != null && message.hasOwnProperty("integration")) {
                let error = $root.api.Integration.verify(message.integration);
                if (error)
                    return "integration." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateIntegrationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateIntegrationRequest} UpdateIntegrationRequest
         */
        UpdateIntegrationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateIntegrationRequest)
                return object;
            let message = new $root.api.UpdateIntegrationRequest();
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateIntegrationRequest.update: object expected");
                message.update = $root.api.IntegrationUpdate.fromObject(object.update);
            }
            if (object.integration != null) {
                if (typeof object.integration !== "object")
                    throw TypeError(".api.UpdateIntegrationRequest.integration: object expected");
                message.integration = $root.api.Integration.fromObject(object.integration);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateIntegrationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateIntegrationRequest
         * @static
         * @param {api.UpdateIntegrationRequest} message UpdateIntegrationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateIntegrationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.update = null;
                object.integration = null;
            }
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.IntegrationUpdate.toObject(message.update, options);
            if (message.integration != null && message.hasOwnProperty("integration"))
                object.integration = $root.api.Integration.toObject(message.integration, options);
            return object;
        };

        /**
         * Converts this UpdateIntegrationRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateIntegrationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateIntegrationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateIntegrationRequest;
    })();

    api.ManageCouponRequest = (function() {

        /**
         * Properties of a ManageCouponRequest.
         * @memberof api
         * @interface IManageCouponRequest
         * @property {string|null} [couponCode] ManageCouponRequest couponCode
         * @property {boolean|null} [removeCoupon] ManageCouponRequest removeCoupon
         */

        /**
         * Constructs a new ManageCouponRequest.
         * @memberof api
         * @classdesc Represents a ManageCouponRequest.
         * @implements IManageCouponRequest
         * @constructor
         * @param {api.IManageCouponRequest=} [properties] Properties to set
         */
        function ManageCouponRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ManageCouponRequest couponCode.
         * @member {string} couponCode
         * @memberof api.ManageCouponRequest
         * @instance
         */
        ManageCouponRequest.prototype.couponCode = "";

        /**
         * ManageCouponRequest removeCoupon.
         * @member {boolean} removeCoupon
         * @memberof api.ManageCouponRequest
         * @instance
         */
        ManageCouponRequest.prototype.removeCoupon = false;

        /**
         * Creates a new ManageCouponRequest instance using the specified properties.
         * @function create
         * @memberof api.ManageCouponRequest
         * @static
         * @param {api.IManageCouponRequest=} [properties] Properties to set
         * @returns {api.ManageCouponRequest} ManageCouponRequest instance
         */
        ManageCouponRequest.create = function create(properties) {
            return new ManageCouponRequest(properties);
        };

        /**
         * Encodes the specified ManageCouponRequest message. Does not implicitly {@link api.ManageCouponRequest.verify|verify} messages.
         * @function encode
         * @memberof api.ManageCouponRequest
         * @static
         * @param {api.IManageCouponRequest} message ManageCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManageCouponRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.couponCode != null && Object.hasOwnProperty.call(message, "couponCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.couponCode);
            if (message.removeCoupon != null && Object.hasOwnProperty.call(message, "removeCoupon"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.removeCoupon);
            return writer;
        };

        /**
         * Encodes the specified ManageCouponRequest message, length delimited. Does not implicitly {@link api.ManageCouponRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ManageCouponRequest
         * @static
         * @param {api.IManageCouponRequest} message ManageCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManageCouponRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ManageCouponRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.ManageCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ManageCouponRequest} ManageCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManageCouponRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ManageCouponRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.couponCode = reader.string();
                    break;
                case 2:
                    message.removeCoupon = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ManageCouponRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ManageCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ManageCouponRequest} ManageCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManageCouponRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ManageCouponRequest message.
         * @function verify
         * @memberof api.ManageCouponRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ManageCouponRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                if (!$util.isString(message.couponCode))
                    return "couponCode: string expected";
            if (message.removeCoupon != null && message.hasOwnProperty("removeCoupon"))
                if (typeof message.removeCoupon !== "boolean")
                    return "removeCoupon: boolean expected";
            return null;
        };

        /**
         * Creates a ManageCouponRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ManageCouponRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ManageCouponRequest} ManageCouponRequest
         */
        ManageCouponRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ManageCouponRequest)
                return object;
            let message = new $root.api.ManageCouponRequest();
            if (object.couponCode != null)
                message.couponCode = String(object.couponCode);
            if (object.removeCoupon != null)
                message.removeCoupon = Boolean(object.removeCoupon);
            return message;
        };

        /**
         * Creates a plain object from a ManageCouponRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ManageCouponRequest
         * @static
         * @param {api.ManageCouponRequest} message ManageCouponRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ManageCouponRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.couponCode = "";
                object.removeCoupon = false;
            }
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                object.couponCode = message.couponCode;
            if (message.removeCoupon != null && message.hasOwnProperty("removeCoupon"))
                object.removeCoupon = message.removeCoupon;
            return object;
        };

        /**
         * Converts this ManageCouponRequest to JSON.
         * @function toJSON
         * @memberof api.ManageCouponRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ManageCouponRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ManageCouponRequest;
    })();

    api.Coupon = (function() {

        /**
         * Properties of a Coupon.
         * @memberof api
         * @interface ICoupon
         * @property {string|null} [couponCode] Coupon couponCode
         * @property {number|null} [maxRedemptions] Coupon maxRedemptions
         * @property {number|null} [redemptionCount] Coupon redemptionCount
         * @property {number|null} [maxUses] Coupon maxUses
         * @property {number|null} [amountOff] Coupon amountOff
         * @property {number|null} [percentOff] Coupon percentOff
         * @property {number|null} [expirationDate] Coupon expirationDate
         * @property {string|null} [topicId] Coupon topicId
         * @property {boolean|null} [exclusive] Coupon exclusive
         * @property {number|null} [priority] Coupon priority
         * @property {boolean|null} [disabled] Coupon disabled
         */

        /**
         * Constructs a new Coupon.
         * @memberof api
         * @classdesc Represents a Coupon.
         * @implements ICoupon
         * @constructor
         * @param {api.ICoupon=} [properties] Properties to set
         */
        function Coupon(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Coupon couponCode.
         * @member {string} couponCode
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.couponCode = "";

        /**
         * Coupon maxRedemptions.
         * @member {number} maxRedemptions
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.maxRedemptions = 0;

        /**
         * Coupon redemptionCount.
         * @member {number} redemptionCount
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.redemptionCount = 0;

        /**
         * Coupon maxUses.
         * @member {number} maxUses
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.maxUses = 0;

        /**
         * Coupon amountOff.
         * @member {number} amountOff
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.amountOff = 0;

        /**
         * Coupon percentOff.
         * @member {number} percentOff
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.percentOff = 0;

        /**
         * Coupon expirationDate.
         * @member {number} expirationDate
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.expirationDate = 0;

        /**
         * Coupon topicId.
         * @member {string} topicId
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.topicId = "";

        /**
         * Coupon exclusive.
         * @member {boolean} exclusive
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.exclusive = false;

        /**
         * Coupon priority.
         * @member {number} priority
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.priority = 0;

        /**
         * Coupon disabled.
         * @member {boolean} disabled
         * @memberof api.Coupon
         * @instance
         */
        Coupon.prototype.disabled = false;

        /**
         * Creates a new Coupon instance using the specified properties.
         * @function create
         * @memberof api.Coupon
         * @static
         * @param {api.ICoupon=} [properties] Properties to set
         * @returns {api.Coupon} Coupon instance
         */
        Coupon.create = function create(properties) {
            return new Coupon(properties);
        };

        /**
         * Encodes the specified Coupon message. Does not implicitly {@link api.Coupon.verify|verify} messages.
         * @function encode
         * @memberof api.Coupon
         * @static
         * @param {api.ICoupon} message Coupon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coupon.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.couponCode != null && Object.hasOwnProperty.call(message, "couponCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.couponCode);
            if (message.maxRedemptions != null && Object.hasOwnProperty.call(message, "maxRedemptions"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxRedemptions);
            if (message.redemptionCount != null && Object.hasOwnProperty.call(message, "redemptionCount"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.redemptionCount);
            if (message.maxUses != null && Object.hasOwnProperty.call(message, "maxUses"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.maxUses);
            if (message.amountOff != null && Object.hasOwnProperty.call(message, "amountOff"))
                writer.uint32(/* id 5, wireType 5 =*/45).float(message.amountOff);
            if (message.percentOff != null && Object.hasOwnProperty.call(message, "percentOff"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.percentOff);
            if (message.expirationDate != null && Object.hasOwnProperty.call(message, "expirationDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int32(message.expirationDate);
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.topicId);
            if (message.exclusive != null && Object.hasOwnProperty.call(message, "exclusive"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.exclusive);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.priority);
            if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.disabled);
            return writer;
        };

        /**
         * Encodes the specified Coupon message, length delimited. Does not implicitly {@link api.Coupon.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Coupon
         * @static
         * @param {api.ICoupon} message Coupon message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Coupon.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Coupon message from the specified reader or buffer.
         * @function decode
         * @memberof api.Coupon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Coupon} Coupon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coupon.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Coupon();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.couponCode = reader.string();
                    break;
                case 2:
                    message.maxRedemptions = reader.int32();
                    break;
                case 3:
                    message.redemptionCount = reader.int32();
                    break;
                case 4:
                    message.maxUses = reader.int32();
                    break;
                case 5:
                    message.amountOff = reader.float();
                    break;
                case 6:
                    message.percentOff = reader.float();
                    break;
                case 7:
                    message.expirationDate = reader.int32();
                    break;
                case 8:
                    message.topicId = reader.string();
                    break;
                case 9:
                    message.exclusive = reader.bool();
                    break;
                case 10:
                    message.priority = reader.int32();
                    break;
                case 11:
                    message.disabled = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Coupon message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Coupon
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Coupon} Coupon
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Coupon.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Coupon message.
         * @function verify
         * @memberof api.Coupon
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Coupon.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                if (!$util.isString(message.couponCode))
                    return "couponCode: string expected";
            if (message.maxRedemptions != null && message.hasOwnProperty("maxRedemptions"))
                if (!$util.isInteger(message.maxRedemptions))
                    return "maxRedemptions: integer expected";
            if (message.redemptionCount != null && message.hasOwnProperty("redemptionCount"))
                if (!$util.isInteger(message.redemptionCount))
                    return "redemptionCount: integer expected";
            if (message.maxUses != null && message.hasOwnProperty("maxUses"))
                if (!$util.isInteger(message.maxUses))
                    return "maxUses: integer expected";
            if (message.amountOff != null && message.hasOwnProperty("amountOff"))
                if (typeof message.amountOff !== "number")
                    return "amountOff: number expected";
            if (message.percentOff != null && message.hasOwnProperty("percentOff"))
                if (typeof message.percentOff !== "number")
                    return "percentOff: number expected";
            if (message.expirationDate != null && message.hasOwnProperty("expirationDate"))
                if (!$util.isInteger(message.expirationDate))
                    return "expirationDate: integer expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.exclusive != null && message.hasOwnProperty("exclusive"))
                if (typeof message.exclusive !== "boolean")
                    return "exclusive: boolean expected";
            if (message.priority != null && message.hasOwnProperty("priority"))
                if (!$util.isInteger(message.priority))
                    return "priority: integer expected";
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                if (typeof message.disabled !== "boolean")
                    return "disabled: boolean expected";
            return null;
        };

        /**
         * Creates a Coupon message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Coupon
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Coupon} Coupon
         */
        Coupon.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Coupon)
                return object;
            let message = new $root.api.Coupon();
            if (object.couponCode != null)
                message.couponCode = String(object.couponCode);
            if (object.maxRedemptions != null)
                message.maxRedemptions = object.maxRedemptions | 0;
            if (object.redemptionCount != null)
                message.redemptionCount = object.redemptionCount | 0;
            if (object.maxUses != null)
                message.maxUses = object.maxUses | 0;
            if (object.amountOff != null)
                message.amountOff = Number(object.amountOff);
            if (object.percentOff != null)
                message.percentOff = Number(object.percentOff);
            if (object.expirationDate != null)
                message.expirationDate = object.expirationDate | 0;
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.exclusive != null)
                message.exclusive = Boolean(object.exclusive);
            if (object.priority != null)
                message.priority = object.priority | 0;
            if (object.disabled != null)
                message.disabled = Boolean(object.disabled);
            return message;
        };

        /**
         * Creates a plain object from a Coupon message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Coupon
         * @static
         * @param {api.Coupon} message Coupon
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Coupon.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.couponCode = "";
                object.maxRedemptions = 0;
                object.redemptionCount = 0;
                object.maxUses = 0;
                object.amountOff = 0;
                object.percentOff = 0;
                object.expirationDate = 0;
                object.topicId = "";
                object.exclusive = false;
                object.priority = 0;
                object.disabled = false;
            }
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                object.couponCode = message.couponCode;
            if (message.maxRedemptions != null && message.hasOwnProperty("maxRedemptions"))
                object.maxRedemptions = message.maxRedemptions;
            if (message.redemptionCount != null && message.hasOwnProperty("redemptionCount"))
                object.redemptionCount = message.redemptionCount;
            if (message.maxUses != null && message.hasOwnProperty("maxUses"))
                object.maxUses = message.maxUses;
            if (message.amountOff != null && message.hasOwnProperty("amountOff"))
                object.amountOff = options.json && !isFinite(message.amountOff) ? String(message.amountOff) : message.amountOff;
            if (message.percentOff != null && message.hasOwnProperty("percentOff"))
                object.percentOff = options.json && !isFinite(message.percentOff) ? String(message.percentOff) : message.percentOff;
            if (message.expirationDate != null && message.hasOwnProperty("expirationDate"))
                object.expirationDate = message.expirationDate;
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.exclusive != null && message.hasOwnProperty("exclusive"))
                object.exclusive = message.exclusive;
            if (message.priority != null && message.hasOwnProperty("priority"))
                object.priority = message.priority;
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                object.disabled = message.disabled;
            return object;
        };

        /**
         * Converts this Coupon to JSON.
         * @function toJSON
         * @memberof api.Coupon
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Coupon.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Coupon;
    })();

    api.UpdateServiceSubscriptionRequest = (function() {

        /**
         * Properties of an UpdateServiceSubscriptionRequest.
         * @memberof api
         * @interface IUpdateServiceSubscriptionRequest
         * @property {api.ServiceSubscriptionType|null} [serviceSubscriptionType] UpdateServiceSubscriptionRequest serviceSubscriptionType
         * @property {api.ServiceSubscriptionInterval|null} [serviceSubscriptionInterval] UpdateServiceSubscriptionRequest serviceSubscriptionInterval
         * @property {boolean|null} [preview] UpdateServiceSubscriptionRequest preview
         */

        /**
         * Constructs a new UpdateServiceSubscriptionRequest.
         * @memberof api
         * @classdesc Represents an UpdateServiceSubscriptionRequest.
         * @implements IUpdateServiceSubscriptionRequest
         * @constructor
         * @param {api.IUpdateServiceSubscriptionRequest=} [properties] Properties to set
         */
        function UpdateServiceSubscriptionRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateServiceSubscriptionRequest serviceSubscriptionType.
         * @member {api.ServiceSubscriptionType} serviceSubscriptionType
         * @memberof api.UpdateServiceSubscriptionRequest
         * @instance
         */
        UpdateServiceSubscriptionRequest.prototype.serviceSubscriptionType = 0;

        /**
         * UpdateServiceSubscriptionRequest serviceSubscriptionInterval.
         * @member {api.ServiceSubscriptionInterval} serviceSubscriptionInterval
         * @memberof api.UpdateServiceSubscriptionRequest
         * @instance
         */
        UpdateServiceSubscriptionRequest.prototype.serviceSubscriptionInterval = 0;

        /**
         * UpdateServiceSubscriptionRequest preview.
         * @member {boolean} preview
         * @memberof api.UpdateServiceSubscriptionRequest
         * @instance
         */
        UpdateServiceSubscriptionRequest.prototype.preview = false;

        /**
         * Creates a new UpdateServiceSubscriptionRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {api.IUpdateServiceSubscriptionRequest=} [properties] Properties to set
         * @returns {api.UpdateServiceSubscriptionRequest} UpdateServiceSubscriptionRequest instance
         */
        UpdateServiceSubscriptionRequest.create = function create(properties) {
            return new UpdateServiceSubscriptionRequest(properties);
        };

        /**
         * Encodes the specified UpdateServiceSubscriptionRequest message. Does not implicitly {@link api.UpdateServiceSubscriptionRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {api.IUpdateServiceSubscriptionRequest} message UpdateServiceSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateServiceSubscriptionRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.serviceSubscriptionType != null && Object.hasOwnProperty.call(message, "serviceSubscriptionType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.serviceSubscriptionType);
            if (message.serviceSubscriptionInterval != null && Object.hasOwnProperty.call(message, "serviceSubscriptionInterval"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.serviceSubscriptionInterval);
            if (message.preview != null && Object.hasOwnProperty.call(message, "preview"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.preview);
            return writer;
        };

        /**
         * Encodes the specified UpdateServiceSubscriptionRequest message, length delimited. Does not implicitly {@link api.UpdateServiceSubscriptionRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {api.IUpdateServiceSubscriptionRequest} message UpdateServiceSubscriptionRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateServiceSubscriptionRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateServiceSubscriptionRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateServiceSubscriptionRequest} UpdateServiceSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateServiceSubscriptionRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateServiceSubscriptionRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.serviceSubscriptionType = reader.int32();
                    break;
                case 2:
                    message.serviceSubscriptionInterval = reader.int32();
                    break;
                case 3:
                    message.preview = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateServiceSubscriptionRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateServiceSubscriptionRequest} UpdateServiceSubscriptionRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateServiceSubscriptionRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateServiceSubscriptionRequest message.
         * @function verify
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateServiceSubscriptionRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.serviceSubscriptionType != null && message.hasOwnProperty("serviceSubscriptionType"))
                switch (message.serviceSubscriptionType) {
                default:
                    return "serviceSubscriptionType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.serviceSubscriptionInterval != null && message.hasOwnProperty("serviceSubscriptionInterval"))
                switch (message.serviceSubscriptionInterval) {
                default:
                    return "serviceSubscriptionInterval: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.preview != null && message.hasOwnProperty("preview"))
                if (typeof message.preview !== "boolean")
                    return "preview: boolean expected";
            return null;
        };

        /**
         * Creates an UpdateServiceSubscriptionRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateServiceSubscriptionRequest} UpdateServiceSubscriptionRequest
         */
        UpdateServiceSubscriptionRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateServiceSubscriptionRequest)
                return object;
            let message = new $root.api.UpdateServiceSubscriptionRequest();
            switch (object.serviceSubscriptionType) {
            case "ServiceTypeFree":
            case 0:
                message.serviceSubscriptionType = 0;
                break;
            case "ServiceTypeSilver":
            case 1:
                message.serviceSubscriptionType = 1;
                break;
            case "ServiceTypeGold":
            case 2:
                message.serviceSubscriptionType = 2;
                break;
            }
            switch (object.serviceSubscriptionInterval) {
            case "ServiceIntervalNone":
            case 0:
                message.serviceSubscriptionInterval = 0;
                break;
            case "ServiceIntervalMonthly":
            case 1:
                message.serviceSubscriptionInterval = 1;
                break;
            case "ServiceIntervalYearly":
            case 2:
                message.serviceSubscriptionInterval = 2;
                break;
            }
            if (object.preview != null)
                message.preview = Boolean(object.preview);
            return message;
        };

        /**
         * Creates a plain object from an UpdateServiceSubscriptionRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateServiceSubscriptionRequest
         * @static
         * @param {api.UpdateServiceSubscriptionRequest} message UpdateServiceSubscriptionRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateServiceSubscriptionRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.serviceSubscriptionType = options.enums === String ? "ServiceTypeFree" : 0;
                object.serviceSubscriptionInterval = options.enums === String ? "ServiceIntervalNone" : 0;
                object.preview = false;
            }
            if (message.serviceSubscriptionType != null && message.hasOwnProperty("serviceSubscriptionType"))
                object.serviceSubscriptionType = options.enums === String ? $root.api.ServiceSubscriptionType[message.serviceSubscriptionType] : message.serviceSubscriptionType;
            if (message.serviceSubscriptionInterval != null && message.hasOwnProperty("serviceSubscriptionInterval"))
                object.serviceSubscriptionInterval = options.enums === String ? $root.api.ServiceSubscriptionInterval[message.serviceSubscriptionInterval] : message.serviceSubscriptionInterval;
            if (message.preview != null && message.hasOwnProperty("preview"))
                object.preview = message.preview;
            return object;
        };

        /**
         * Converts this UpdateServiceSubscriptionRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateServiceSubscriptionRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateServiceSubscriptionRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateServiceSubscriptionRequest;
    })();

    api.CreateCouponRequest = (function() {

        /**
         * Properties of a CreateCouponRequest.
         * @memberof api
         * @interface ICreateCouponRequest
         * @property {api.ICoupon|null} [coupon] CreateCouponRequest coupon
         */

        /**
         * Constructs a new CreateCouponRequest.
         * @memberof api
         * @classdesc Represents a CreateCouponRequest.
         * @implements ICreateCouponRequest
         * @constructor
         * @param {api.ICreateCouponRequest=} [properties] Properties to set
         */
        function CreateCouponRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateCouponRequest coupon.
         * @member {api.ICoupon|null|undefined} coupon
         * @memberof api.CreateCouponRequest
         * @instance
         */
        CreateCouponRequest.prototype.coupon = null;

        /**
         * Creates a new CreateCouponRequest instance using the specified properties.
         * @function create
         * @memberof api.CreateCouponRequest
         * @static
         * @param {api.ICreateCouponRequest=} [properties] Properties to set
         * @returns {api.CreateCouponRequest} CreateCouponRequest instance
         */
        CreateCouponRequest.create = function create(properties) {
            return new CreateCouponRequest(properties);
        };

        /**
         * Encodes the specified CreateCouponRequest message. Does not implicitly {@link api.CreateCouponRequest.verify|verify} messages.
         * @function encode
         * @memberof api.CreateCouponRequest
         * @static
         * @param {api.ICreateCouponRequest} message CreateCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCouponRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.coupon != null && Object.hasOwnProperty.call(message, "coupon"))
                $root.api.Coupon.encode(message.coupon, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateCouponRequest message, length delimited. Does not implicitly {@link api.CreateCouponRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateCouponRequest
         * @static
         * @param {api.ICreateCouponRequest} message CreateCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCouponRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateCouponRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateCouponRequest} CreateCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCouponRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateCouponRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.coupon = $root.api.Coupon.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateCouponRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateCouponRequest} CreateCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCouponRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateCouponRequest message.
         * @function verify
         * @memberof api.CreateCouponRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateCouponRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.coupon != null && message.hasOwnProperty("coupon")) {
                let error = $root.api.Coupon.verify(message.coupon);
                if (error)
                    return "coupon." + error;
            }
            return null;
        };

        /**
         * Creates a CreateCouponRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateCouponRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateCouponRequest} CreateCouponRequest
         */
        CreateCouponRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateCouponRequest)
                return object;
            let message = new $root.api.CreateCouponRequest();
            if (object.coupon != null) {
                if (typeof object.coupon !== "object")
                    throw TypeError(".api.CreateCouponRequest.coupon: object expected");
                message.coupon = $root.api.Coupon.fromObject(object.coupon);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateCouponRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateCouponRequest
         * @static
         * @param {api.CreateCouponRequest} message CreateCouponRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateCouponRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.coupon = null;
            if (message.coupon != null && message.hasOwnProperty("coupon"))
                object.coupon = $root.api.Coupon.toObject(message.coupon, options);
            return object;
        };

        /**
         * Converts this CreateCouponRequest to JSON.
         * @function toJSON
         * @memberof api.CreateCouponRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateCouponRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateCouponRequest;
    })();

    api.CouponUpdate = (function() {

        /**
         * Properties of a CouponUpdate.
         * @memberof api
         * @interface ICouponUpdate
         * @property {boolean|null} [couponCode] CouponUpdate couponCode
         * @property {boolean|null} [maxRedemptions] CouponUpdate maxRedemptions
         * @property {boolean|null} [maxUses] CouponUpdate maxUses
         * @property {boolean|null} [amountOff] CouponUpdate amountOff
         * @property {boolean|null} [percentOff] CouponUpdate percentOff
         * @property {boolean|null} [expirationDate] CouponUpdate expirationDate
         * @property {boolean|null} [topicId] CouponUpdate topicId
         * @property {boolean|null} [exclusive] CouponUpdate exclusive
         * @property {boolean|null} [priority] CouponUpdate priority
         * @property {boolean|null} [disabled] CouponUpdate disabled
         */

        /**
         * Constructs a new CouponUpdate.
         * @memberof api
         * @classdesc Represents a CouponUpdate.
         * @implements ICouponUpdate
         * @constructor
         * @param {api.ICouponUpdate=} [properties] Properties to set
         */
        function CouponUpdate(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CouponUpdate couponCode.
         * @member {boolean} couponCode
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.couponCode = false;

        /**
         * CouponUpdate maxRedemptions.
         * @member {boolean} maxRedemptions
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.maxRedemptions = false;

        /**
         * CouponUpdate maxUses.
         * @member {boolean} maxUses
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.maxUses = false;

        /**
         * CouponUpdate amountOff.
         * @member {boolean} amountOff
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.amountOff = false;

        /**
         * CouponUpdate percentOff.
         * @member {boolean} percentOff
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.percentOff = false;

        /**
         * CouponUpdate expirationDate.
         * @member {boolean} expirationDate
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.expirationDate = false;

        /**
         * CouponUpdate topicId.
         * @member {boolean} topicId
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.topicId = false;

        /**
         * CouponUpdate exclusive.
         * @member {boolean} exclusive
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.exclusive = false;

        /**
         * CouponUpdate priority.
         * @member {boolean} priority
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.priority = false;

        /**
         * CouponUpdate disabled.
         * @member {boolean} disabled
         * @memberof api.CouponUpdate
         * @instance
         */
        CouponUpdate.prototype.disabled = false;

        /**
         * Creates a new CouponUpdate instance using the specified properties.
         * @function create
         * @memberof api.CouponUpdate
         * @static
         * @param {api.ICouponUpdate=} [properties] Properties to set
         * @returns {api.CouponUpdate} CouponUpdate instance
         */
        CouponUpdate.create = function create(properties) {
            return new CouponUpdate(properties);
        };

        /**
         * Encodes the specified CouponUpdate message. Does not implicitly {@link api.CouponUpdate.verify|verify} messages.
         * @function encode
         * @memberof api.CouponUpdate
         * @static
         * @param {api.ICouponUpdate} message CouponUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CouponUpdate.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.couponCode != null && Object.hasOwnProperty.call(message, "couponCode"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.couponCode);
            if (message.maxRedemptions != null && Object.hasOwnProperty.call(message, "maxRedemptions"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.maxRedemptions);
            if (message.maxUses != null && Object.hasOwnProperty.call(message, "maxUses"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.maxUses);
            if (message.amountOff != null && Object.hasOwnProperty.call(message, "amountOff"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.amountOff);
            if (message.percentOff != null && Object.hasOwnProperty.call(message, "percentOff"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.percentOff);
            if (message.expirationDate != null && Object.hasOwnProperty.call(message, "expirationDate"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.expirationDate);
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.topicId);
            if (message.exclusive != null && Object.hasOwnProperty.call(message, "exclusive"))
                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.exclusive);
            if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.priority);
            if (message.disabled != null && Object.hasOwnProperty.call(message, "disabled"))
                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.disabled);
            return writer;
        };

        /**
         * Encodes the specified CouponUpdate message, length delimited. Does not implicitly {@link api.CouponUpdate.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CouponUpdate
         * @static
         * @param {api.ICouponUpdate} message CouponUpdate message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CouponUpdate.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CouponUpdate message from the specified reader or buffer.
         * @function decode
         * @memberof api.CouponUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CouponUpdate} CouponUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CouponUpdate.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CouponUpdate();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.couponCode = reader.bool();
                    break;
                case 2:
                    message.maxRedemptions = reader.bool();
                    break;
                case 3:
                    message.maxUses = reader.bool();
                    break;
                case 4:
                    message.amountOff = reader.bool();
                    break;
                case 5:
                    message.percentOff = reader.bool();
                    break;
                case 6:
                    message.expirationDate = reader.bool();
                    break;
                case 7:
                    message.topicId = reader.bool();
                    break;
                case 8:
                    message.exclusive = reader.bool();
                    break;
                case 9:
                    message.priority = reader.bool();
                    break;
                case 10:
                    message.disabled = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CouponUpdate message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CouponUpdate
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CouponUpdate} CouponUpdate
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CouponUpdate.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CouponUpdate message.
         * @function verify
         * @memberof api.CouponUpdate
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CouponUpdate.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                if (typeof message.couponCode !== "boolean")
                    return "couponCode: boolean expected";
            if (message.maxRedemptions != null && message.hasOwnProperty("maxRedemptions"))
                if (typeof message.maxRedemptions !== "boolean")
                    return "maxRedemptions: boolean expected";
            if (message.maxUses != null && message.hasOwnProperty("maxUses"))
                if (typeof message.maxUses !== "boolean")
                    return "maxUses: boolean expected";
            if (message.amountOff != null && message.hasOwnProperty("amountOff"))
                if (typeof message.amountOff !== "boolean")
                    return "amountOff: boolean expected";
            if (message.percentOff != null && message.hasOwnProperty("percentOff"))
                if (typeof message.percentOff !== "boolean")
                    return "percentOff: boolean expected";
            if (message.expirationDate != null && message.hasOwnProperty("expirationDate"))
                if (typeof message.expirationDate !== "boolean")
                    return "expirationDate: boolean expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (typeof message.topicId !== "boolean")
                    return "topicId: boolean expected";
            if (message.exclusive != null && message.hasOwnProperty("exclusive"))
                if (typeof message.exclusive !== "boolean")
                    return "exclusive: boolean expected";
            if (message.priority != null && message.hasOwnProperty("priority"))
                if (typeof message.priority !== "boolean")
                    return "priority: boolean expected";
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                if (typeof message.disabled !== "boolean")
                    return "disabled: boolean expected";
            return null;
        };

        /**
         * Creates a CouponUpdate message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CouponUpdate
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CouponUpdate} CouponUpdate
         */
        CouponUpdate.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CouponUpdate)
                return object;
            let message = new $root.api.CouponUpdate();
            if (object.couponCode != null)
                message.couponCode = Boolean(object.couponCode);
            if (object.maxRedemptions != null)
                message.maxRedemptions = Boolean(object.maxRedemptions);
            if (object.maxUses != null)
                message.maxUses = Boolean(object.maxUses);
            if (object.amountOff != null)
                message.amountOff = Boolean(object.amountOff);
            if (object.percentOff != null)
                message.percentOff = Boolean(object.percentOff);
            if (object.expirationDate != null)
                message.expirationDate = Boolean(object.expirationDate);
            if (object.topicId != null)
                message.topicId = Boolean(object.topicId);
            if (object.exclusive != null)
                message.exclusive = Boolean(object.exclusive);
            if (object.priority != null)
                message.priority = Boolean(object.priority);
            if (object.disabled != null)
                message.disabled = Boolean(object.disabled);
            return message;
        };

        /**
         * Creates a plain object from a CouponUpdate message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CouponUpdate
         * @static
         * @param {api.CouponUpdate} message CouponUpdate
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CouponUpdate.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.couponCode = false;
                object.maxRedemptions = false;
                object.maxUses = false;
                object.amountOff = false;
                object.percentOff = false;
                object.expirationDate = false;
                object.topicId = false;
                object.exclusive = false;
                object.priority = false;
                object.disabled = false;
            }
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                object.couponCode = message.couponCode;
            if (message.maxRedemptions != null && message.hasOwnProperty("maxRedemptions"))
                object.maxRedemptions = message.maxRedemptions;
            if (message.maxUses != null && message.hasOwnProperty("maxUses"))
                object.maxUses = message.maxUses;
            if (message.amountOff != null && message.hasOwnProperty("amountOff"))
                object.amountOff = message.amountOff;
            if (message.percentOff != null && message.hasOwnProperty("percentOff"))
                object.percentOff = message.percentOff;
            if (message.expirationDate != null && message.hasOwnProperty("expirationDate"))
                object.expirationDate = message.expirationDate;
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.exclusive != null && message.hasOwnProperty("exclusive"))
                object.exclusive = message.exclusive;
            if (message.priority != null && message.hasOwnProperty("priority"))
                object.priority = message.priority;
            if (message.disabled != null && message.hasOwnProperty("disabled"))
                object.disabled = message.disabled;
            return object;
        };

        /**
         * Converts this CouponUpdate to JSON.
         * @function toJSON
         * @memberof api.CouponUpdate
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CouponUpdate.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CouponUpdate;
    })();

    api.UpdateCouponRequest = (function() {

        /**
         * Properties of an UpdateCouponRequest.
         * @memberof api
         * @interface IUpdateCouponRequest
         * @property {string|null} [couponCode] UpdateCouponRequest couponCode
         * @property {api.ICouponUpdate|null} [update] UpdateCouponRequest update
         * @property {api.ICoupon|null} [coupon] UpdateCouponRequest coupon
         */

        /**
         * Constructs a new UpdateCouponRequest.
         * @memberof api
         * @classdesc Represents an UpdateCouponRequest.
         * @implements IUpdateCouponRequest
         * @constructor
         * @param {api.IUpdateCouponRequest=} [properties] Properties to set
         */
        function UpdateCouponRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateCouponRequest couponCode.
         * @member {string} couponCode
         * @memberof api.UpdateCouponRequest
         * @instance
         */
        UpdateCouponRequest.prototype.couponCode = "";

        /**
         * UpdateCouponRequest update.
         * @member {api.ICouponUpdate|null|undefined} update
         * @memberof api.UpdateCouponRequest
         * @instance
         */
        UpdateCouponRequest.prototype.update = null;

        /**
         * UpdateCouponRequest coupon.
         * @member {api.ICoupon|null|undefined} coupon
         * @memberof api.UpdateCouponRequest
         * @instance
         */
        UpdateCouponRequest.prototype.coupon = null;

        /**
         * Creates a new UpdateCouponRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {api.IUpdateCouponRequest=} [properties] Properties to set
         * @returns {api.UpdateCouponRequest} UpdateCouponRequest instance
         */
        UpdateCouponRequest.create = function create(properties) {
            return new UpdateCouponRequest(properties);
        };

        /**
         * Encodes the specified UpdateCouponRequest message. Does not implicitly {@link api.UpdateCouponRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {api.IUpdateCouponRequest} message UpdateCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCouponRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.couponCode != null && Object.hasOwnProperty.call(message, "couponCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.couponCode);
            if (message.update != null && Object.hasOwnProperty.call(message, "update"))
                $root.api.CouponUpdate.encode(message.update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.coupon != null && Object.hasOwnProperty.call(message, "coupon"))
                $root.api.Coupon.encode(message.coupon, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateCouponRequest message, length delimited. Does not implicitly {@link api.UpdateCouponRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {api.IUpdateCouponRequest} message UpdateCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCouponRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateCouponRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateCouponRequest} UpdateCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCouponRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateCouponRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.couponCode = reader.string();
                    break;
                case 2:
                    message.update = $root.api.CouponUpdate.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.coupon = $root.api.Coupon.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateCouponRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateCouponRequest} UpdateCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCouponRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateCouponRequest message.
         * @function verify
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateCouponRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                if (!$util.isString(message.couponCode))
                    return "couponCode: string expected";
            if (message.update != null && message.hasOwnProperty("update")) {
                let error = $root.api.CouponUpdate.verify(message.update);
                if (error)
                    return "update." + error;
            }
            if (message.coupon != null && message.hasOwnProperty("coupon")) {
                let error = $root.api.Coupon.verify(message.coupon);
                if (error)
                    return "coupon." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateCouponRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateCouponRequest} UpdateCouponRequest
         */
        UpdateCouponRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateCouponRequest)
                return object;
            let message = new $root.api.UpdateCouponRequest();
            if (object.couponCode != null)
                message.couponCode = String(object.couponCode);
            if (object.update != null) {
                if (typeof object.update !== "object")
                    throw TypeError(".api.UpdateCouponRequest.update: object expected");
                message.update = $root.api.CouponUpdate.fromObject(object.update);
            }
            if (object.coupon != null) {
                if (typeof object.coupon !== "object")
                    throw TypeError(".api.UpdateCouponRequest.coupon: object expected");
                message.coupon = $root.api.Coupon.fromObject(object.coupon);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateCouponRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateCouponRequest
         * @static
         * @param {api.UpdateCouponRequest} message UpdateCouponRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateCouponRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.couponCode = "";
                object.update = null;
                object.coupon = null;
            }
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                object.couponCode = message.couponCode;
            if (message.update != null && message.hasOwnProperty("update"))
                object.update = $root.api.CouponUpdate.toObject(message.update, options);
            if (message.coupon != null && message.hasOwnProperty("coupon"))
                object.coupon = $root.api.Coupon.toObject(message.coupon, options);
            return object;
        };

        /**
         * Converts this UpdateCouponRequest to JSON.
         * @function toJSON
         * @memberof api.UpdateCouponRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateCouponRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateCouponRequest;
    })();

    api.DestroyCouponRequest = (function() {

        /**
         * Properties of a DestroyCouponRequest.
         * @memberof api
         * @interface IDestroyCouponRequest
         * @property {string|null} [couponCode] DestroyCouponRequest couponCode
         */

        /**
         * Constructs a new DestroyCouponRequest.
         * @memberof api
         * @classdesc Represents a DestroyCouponRequest.
         * @implements IDestroyCouponRequest
         * @constructor
         * @param {api.IDestroyCouponRequest=} [properties] Properties to set
         */
        function DestroyCouponRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DestroyCouponRequest couponCode.
         * @member {string} couponCode
         * @memberof api.DestroyCouponRequest
         * @instance
         */
        DestroyCouponRequest.prototype.couponCode = "";

        /**
         * Creates a new DestroyCouponRequest instance using the specified properties.
         * @function create
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {api.IDestroyCouponRequest=} [properties] Properties to set
         * @returns {api.DestroyCouponRequest} DestroyCouponRequest instance
         */
        DestroyCouponRequest.create = function create(properties) {
            return new DestroyCouponRequest(properties);
        };

        /**
         * Encodes the specified DestroyCouponRequest message. Does not implicitly {@link api.DestroyCouponRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {api.IDestroyCouponRequest} message DestroyCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DestroyCouponRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.couponCode != null && Object.hasOwnProperty.call(message, "couponCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.couponCode);
            return writer;
        };

        /**
         * Encodes the specified DestroyCouponRequest message, length delimited. Does not implicitly {@link api.DestroyCouponRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {api.IDestroyCouponRequest} message DestroyCouponRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DestroyCouponRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DestroyCouponRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DestroyCouponRequest} DestroyCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DestroyCouponRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DestroyCouponRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.couponCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DestroyCouponRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DestroyCouponRequest} DestroyCouponRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DestroyCouponRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DestroyCouponRequest message.
         * @function verify
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DestroyCouponRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                if (!$util.isString(message.couponCode))
                    return "couponCode: string expected";
            return null;
        };

        /**
         * Creates a DestroyCouponRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DestroyCouponRequest} DestroyCouponRequest
         */
        DestroyCouponRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DestroyCouponRequest)
                return object;
            let message = new $root.api.DestroyCouponRequest();
            if (object.couponCode != null)
                message.couponCode = String(object.couponCode);
            return message;
        };

        /**
         * Creates a plain object from a DestroyCouponRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DestroyCouponRequest
         * @static
         * @param {api.DestroyCouponRequest} message DestroyCouponRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DestroyCouponRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.couponCode = "";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                object.couponCode = message.couponCode;
            return object;
        };

        /**
         * Converts this DestroyCouponRequest to JSON.
         * @function toJSON
         * @memberof api.DestroyCouponRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DestroyCouponRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DestroyCouponRequest;
    })();

    api.DeleteIntegrationRequest = (function() {

        /**
         * Properties of a DeleteIntegrationRequest.
         * @memberof api
         * @interface IDeleteIntegrationRequest
         * @property {api.IntegrationType|null} [integrationType] DeleteIntegrationRequest integrationType
         */

        /**
         * Constructs a new DeleteIntegrationRequest.
         * @memberof api
         * @classdesc Represents a DeleteIntegrationRequest.
         * @implements IDeleteIntegrationRequest
         * @constructor
         * @param {api.IDeleteIntegrationRequest=} [properties] Properties to set
         */
        function DeleteIntegrationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteIntegrationRequest integrationType.
         * @member {api.IntegrationType} integrationType
         * @memberof api.DeleteIntegrationRequest
         * @instance
         */
        DeleteIntegrationRequest.prototype.integrationType = 0;

        /**
         * Creates a new DeleteIntegrationRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {api.IDeleteIntegrationRequest=} [properties] Properties to set
         * @returns {api.DeleteIntegrationRequest} DeleteIntegrationRequest instance
         */
        DeleteIntegrationRequest.create = function create(properties) {
            return new DeleteIntegrationRequest(properties);
        };

        /**
         * Encodes the specified DeleteIntegrationRequest message. Does not implicitly {@link api.DeleteIntegrationRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {api.IDeleteIntegrationRequest} message DeleteIntegrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteIntegrationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.integrationType != null && Object.hasOwnProperty.call(message, "integrationType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.integrationType);
            return writer;
        };

        /**
         * Encodes the specified DeleteIntegrationRequest message, length delimited. Does not implicitly {@link api.DeleteIntegrationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {api.IDeleteIntegrationRequest} message DeleteIntegrationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteIntegrationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteIntegrationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteIntegrationRequest} DeleteIntegrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteIntegrationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteIntegrationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.integrationType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteIntegrationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteIntegrationRequest} DeleteIntegrationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteIntegrationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteIntegrationRequest message.
         * @function verify
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteIntegrationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.integrationType != null && message.hasOwnProperty("integrationType"))
                switch (message.integrationType) {
                default:
                    return "integrationType: enum value expected";
                case 0:
                case 1:
                    break;
                }
            return null;
        };

        /**
         * Creates a DeleteIntegrationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteIntegrationRequest} DeleteIntegrationRequest
         */
        DeleteIntegrationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteIntegrationRequest)
                return object;
            let message = new $root.api.DeleteIntegrationRequest();
            switch (object.integrationType) {
            case "GoogleCalendarAndMeet":
            case 0:
                message.integrationType = 0;
                break;
            case "Zoom":
            case 1:
                message.integrationType = 1;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a DeleteIntegrationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteIntegrationRequest
         * @static
         * @param {api.DeleteIntegrationRequest} message DeleteIntegrationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteIntegrationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.integrationType = options.enums === String ? "GoogleCalendarAndMeet" : 0;
            if (message.integrationType != null && message.hasOwnProperty("integrationType"))
                object.integrationType = options.enums === String ? $root.api.IntegrationType[message.integrationType] : message.integrationType;
            return object;
        };

        /**
         * Converts this DeleteIntegrationRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteIntegrationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteIntegrationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteIntegrationRequest;
    })();

    api.SendMessageRequest = (function() {

        /**
         * Properties of a SendMessageRequest.
         * @memberof api
         * @interface ISendMessageRequest
         * @property {string|null} [roomId] SendMessageRequest roomId
         * @property {string|null} [timeId] SendMessageRequest timeId
         * @property {string|null} [targetUserId] SendMessageRequest targetUserId
         * @property {boolean|null} [sendAsEmail] SendMessageRequest sendAsEmail
         * @property {string|null} [message] SendMessageRequest message
         */

        /**
         * Constructs a new SendMessageRequest.
         * @memberof api
         * @classdesc Represents a SendMessageRequest.
         * @implements ISendMessageRequest
         * @constructor
         * @param {api.ISendMessageRequest=} [properties] Properties to set
         */
        function SendMessageRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SendMessageRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.SendMessageRequest
         * @instance
         */
        SendMessageRequest.prototype.roomId = null;

        /**
         * SendMessageRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.SendMessageRequest
         * @instance
         */
        SendMessageRequest.prototype.timeId = null;

        /**
         * SendMessageRequest targetUserId.
         * @member {string|null|undefined} targetUserId
         * @memberof api.SendMessageRequest
         * @instance
         */
        SendMessageRequest.prototype.targetUserId = null;

        /**
         * SendMessageRequest sendAsEmail.
         * @member {boolean} sendAsEmail
         * @memberof api.SendMessageRequest
         * @instance
         */
        SendMessageRequest.prototype.sendAsEmail = false;

        /**
         * SendMessageRequest message.
         * @member {string} message
         * @memberof api.SendMessageRequest
         * @instance
         */
        SendMessageRequest.prototype.message = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * SendMessageRequest option.
         * @member {"roomId"|"timeId"|"targetUserId"|undefined} option
         * @memberof api.SendMessageRequest
         * @instance
         */
        Object.defineProperty(SendMessageRequest.prototype, "option", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId", "targetUserId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new SendMessageRequest instance using the specified properties.
         * @function create
         * @memberof api.SendMessageRequest
         * @static
         * @param {api.ISendMessageRequest=} [properties] Properties to set
         * @returns {api.SendMessageRequest} SendMessageRequest instance
         */
        SendMessageRequest.create = function create(properties) {
            return new SendMessageRequest(properties);
        };

        /**
         * Encodes the specified SendMessageRequest message. Does not implicitly {@link api.SendMessageRequest.verify|verify} messages.
         * @function encode
         * @memberof api.SendMessageRequest
         * @static
         * @param {api.ISendMessageRequest} message SendMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendMessageRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.targetUserId != null && Object.hasOwnProperty.call(message, "targetUserId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.targetUserId);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.message);
            if (message.sendAsEmail != null && Object.hasOwnProperty.call(message, "sendAsEmail"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.sendAsEmail);
            return writer;
        };

        /**
         * Encodes the specified SendMessageRequest message, length delimited. Does not implicitly {@link api.SendMessageRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendMessageRequest
         * @static
         * @param {api.ISendMessageRequest} message SendMessageRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendMessageRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendMessageRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendMessageRequest} SendMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendMessageRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendMessageRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.targetUserId = reader.string();
                    break;
                case 5:
                    message.sendAsEmail = reader.bool();
                    break;
                case 4:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendMessageRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendMessageRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendMessageRequest} SendMessageRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendMessageRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendMessageRequest message.
         * @function verify
         * @memberof api.SendMessageRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendMessageRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.option = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.targetUserId != null && message.hasOwnProperty("targetUserId")) {
                if (properties.option === 1)
                    return "option: multiple values";
                properties.option = 1;
                if (!$util.isString(message.targetUserId))
                    return "targetUserId: string expected";
            }
            if (message.sendAsEmail != null && message.hasOwnProperty("sendAsEmail"))
                if (typeof message.sendAsEmail !== "boolean")
                    return "sendAsEmail: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a SendMessageRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendMessageRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendMessageRequest} SendMessageRequest
         */
        SendMessageRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendMessageRequest)
                return object;
            let message = new $root.api.SendMessageRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.targetUserId != null)
                message.targetUserId = String(object.targetUserId);
            if (object.sendAsEmail != null)
                message.sendAsEmail = Boolean(object.sendAsEmail);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a SendMessageRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendMessageRequest
         * @static
         * @param {api.SendMessageRequest} message SendMessageRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendMessageRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.message = "";
                object.sendAsEmail = false;
            }
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.option = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.option = "timeId";
            }
            if (message.targetUserId != null && message.hasOwnProperty("targetUserId")) {
                object.targetUserId = message.targetUserId;
                if (options.oneofs)
                    object.option = "targetUserId";
            }
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.sendAsEmail != null && message.hasOwnProperty("sendAsEmail"))
                object.sendAsEmail = message.sendAsEmail;
            return object;
        };

        /**
         * Converts this SendMessageRequest to JSON.
         * @function toJSON
         * @memberof api.SendMessageRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendMessageRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendMessageRequest;
    })();

    api.GetRoomRequest = (function() {

        /**
         * Properties of a GetRoomRequest.
         * @memberof api
         * @interface IGetRoomRequest
         * @property {string|null} [roomId] GetRoomRequest roomId
         * @property {api.IRoomQuery|null} [query] GetRoomRequest query
         */

        /**
         * Constructs a new GetRoomRequest.
         * @memberof api
         * @classdesc Represents a GetRoomRequest.
         * @implements IGetRoomRequest
         * @constructor
         * @param {api.IGetRoomRequest=} [properties] Properties to set
         */
        function GetRoomRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetRoomRequest roomId.
         * @member {string} roomId
         * @memberof api.GetRoomRequest
         * @instance
         */
        GetRoomRequest.prototype.roomId = "";

        /**
         * GetRoomRequest query.
         * @member {api.IRoomQuery|null|undefined} query
         * @memberof api.GetRoomRequest
         * @instance
         */
        GetRoomRequest.prototype.query = null;

        /**
         * Creates a new GetRoomRequest instance using the specified properties.
         * @function create
         * @memberof api.GetRoomRequest
         * @static
         * @param {api.IGetRoomRequest=} [properties] Properties to set
         * @returns {api.GetRoomRequest} GetRoomRequest instance
         */
        GetRoomRequest.create = function create(properties) {
            return new GetRoomRequest(properties);
        };

        /**
         * Encodes the specified GetRoomRequest message. Does not implicitly {@link api.GetRoomRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetRoomRequest
         * @static
         * @param {api.IGetRoomRequest} message GetRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRoomRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.RoomQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetRoomRequest message, length delimited. Does not implicitly {@link api.GetRoomRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetRoomRequest
         * @static
         * @param {api.IGetRoomRequest} message GetRoomRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRoomRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetRoomRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetRoomRequest} GetRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRoomRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetRoomRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.query = $root.api.RoomQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetRoomRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetRoomRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetRoomRequest} GetRoomRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRoomRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetRoomRequest message.
         * @function verify
         * @memberof api.GetRoomRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetRoomRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.RoomQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetRoomRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetRoomRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetRoomRequest} GetRoomRequest
         */
        GetRoomRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetRoomRequest)
                return object;
            let message = new $root.api.GetRoomRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetRoomRequest.query: object expected");
                message.query = $root.api.RoomQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetRoomRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetRoomRequest
         * @static
         * @param {api.GetRoomRequest} message GetRoomRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetRoomRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.roomId = "";
                object.query = null;
            }
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.RoomQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetRoomRequest to JSON.
         * @function toJSON
         * @memberof api.GetRoomRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetRoomRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetRoomRequest;
    })();

    api.GetConferenceConnectInfoRequest = (function() {

        /**
         * Properties of a GetConferenceConnectInfoRequest.
         * @memberof api
         * @interface IGetConferenceConnectInfoRequest
         * @property {string|null} [roomId] GetConferenceConnectInfoRequest roomId
         * @property {string|null} [timeId] GetConferenceConnectInfoRequest timeId
         * @property {boolean|null} [join] GetConferenceConnectInfoRequest join
         */

        /**
         * Constructs a new GetConferenceConnectInfoRequest.
         * @memberof api
         * @classdesc Represents a GetConferenceConnectInfoRequest.
         * @implements IGetConferenceConnectInfoRequest
         * @constructor
         * @param {api.IGetConferenceConnectInfoRequest=} [properties] Properties to set
         */
        function GetConferenceConnectInfoRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetConferenceConnectInfoRequest roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.GetConferenceConnectInfoRequest
         * @instance
         */
        GetConferenceConnectInfoRequest.prototype.roomId = null;

        /**
         * GetConferenceConnectInfoRequest timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.GetConferenceConnectInfoRequest
         * @instance
         */
        GetConferenceConnectInfoRequest.prototype.timeId = null;

        /**
         * GetConferenceConnectInfoRequest join.
         * @member {boolean} join
         * @memberof api.GetConferenceConnectInfoRequest
         * @instance
         */
        GetConferenceConnectInfoRequest.prototype.join = false;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GetConferenceConnectInfoRequest conferenceOption.
         * @member {"roomId"|"timeId"|undefined} conferenceOption
         * @memberof api.GetConferenceConnectInfoRequest
         * @instance
         */
        Object.defineProperty(GetConferenceConnectInfoRequest.prototype, "conferenceOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetConferenceConnectInfoRequest instance using the specified properties.
         * @function create
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {api.IGetConferenceConnectInfoRequest=} [properties] Properties to set
         * @returns {api.GetConferenceConnectInfoRequest} GetConferenceConnectInfoRequest instance
         */
        GetConferenceConnectInfoRequest.create = function create(properties) {
            return new GetConferenceConnectInfoRequest(properties);
        };

        /**
         * Encodes the specified GetConferenceConnectInfoRequest message. Does not implicitly {@link api.GetConferenceConnectInfoRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {api.IGetConferenceConnectInfoRequest} message GetConferenceConnectInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConferenceConnectInfoRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.join != null && Object.hasOwnProperty.call(message, "join"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.join);
            return writer;
        };

        /**
         * Encodes the specified GetConferenceConnectInfoRequest message, length delimited. Does not implicitly {@link api.GetConferenceConnectInfoRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {api.IGetConferenceConnectInfoRequest} message GetConferenceConnectInfoRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConferenceConnectInfoRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConferenceConnectInfoRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetConferenceConnectInfoRequest} GetConferenceConnectInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConferenceConnectInfoRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetConferenceConnectInfoRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.join = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConferenceConnectInfoRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetConferenceConnectInfoRequest} GetConferenceConnectInfoRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConferenceConnectInfoRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConferenceConnectInfoRequest message.
         * @function verify
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConferenceConnectInfoRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.conferenceOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.conferenceOption === 1)
                    return "conferenceOption: multiple values";
                properties.conferenceOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.join != null && message.hasOwnProperty("join"))
                if (typeof message.join !== "boolean")
                    return "join: boolean expected";
            return null;
        };

        /**
         * Creates a GetConferenceConnectInfoRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetConferenceConnectInfoRequest} GetConferenceConnectInfoRequest
         */
        GetConferenceConnectInfoRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetConferenceConnectInfoRequest)
                return object;
            let message = new $root.api.GetConferenceConnectInfoRequest();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.join != null)
                message.join = Boolean(object.join);
            return message;
        };

        /**
         * Creates a plain object from a GetConferenceConnectInfoRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetConferenceConnectInfoRequest
         * @static
         * @param {api.GetConferenceConnectInfoRequest} message GetConferenceConnectInfoRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConferenceConnectInfoRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.join = false;
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.conferenceOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.conferenceOption = "timeId";
            }
            if (message.join != null && message.hasOwnProperty("join"))
                object.join = message.join;
            return object;
        };

        /**
         * Converts this GetConferenceConnectInfoRequest to JSON.
         * @function toJSON
         * @memberof api.GetConferenceConnectInfoRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConferenceConnectInfoRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConferenceConnectInfoRequest;
    })();

    api.GetTopicRequest = (function() {

        /**
         * Properties of a GetTopicRequest.
         * @memberof api
         * @interface IGetTopicRequest
         * @property {string|null} [topicId] GetTopicRequest topicId
         * @property {api.ITopicQuery|null} [query] GetTopicRequest query
         */

        /**
         * Constructs a new GetTopicRequest.
         * @memberof api
         * @classdesc Represents a GetTopicRequest.
         * @implements IGetTopicRequest
         * @constructor
         * @param {api.IGetTopicRequest=} [properties] Properties to set
         */
        function GetTopicRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTopicRequest topicId.
         * @member {string} topicId
         * @memberof api.GetTopicRequest
         * @instance
         */
        GetTopicRequest.prototype.topicId = "";

        /**
         * GetTopicRequest query.
         * @member {api.ITopicQuery|null|undefined} query
         * @memberof api.GetTopicRequest
         * @instance
         */
        GetTopicRequest.prototype.query = null;

        /**
         * Creates a new GetTopicRequest instance using the specified properties.
         * @function create
         * @memberof api.GetTopicRequest
         * @static
         * @param {api.IGetTopicRequest=} [properties] Properties to set
         * @returns {api.GetTopicRequest} GetTopicRequest instance
         */
        GetTopicRequest.create = function create(properties) {
            return new GetTopicRequest(properties);
        };

        /**
         * Encodes the specified GetTopicRequest message. Does not implicitly {@link api.GetTopicRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetTopicRequest
         * @static
         * @param {api.IGetTopicRequest} message GetTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTopicRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.topicId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.TopicQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetTopicRequest message, length delimited. Does not implicitly {@link api.GetTopicRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetTopicRequest
         * @static
         * @param {api.IGetTopicRequest} message GetTopicRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTopicRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTopicRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetTopicRequest} GetTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTopicRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetTopicRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topicId = reader.string();
                    break;
                case 2:
                    message.query = $root.api.TopicQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTopicRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetTopicRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetTopicRequest} GetTopicRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTopicRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTopicRequest message.
         * @function verify
         * @memberof api.GetTopicRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTopicRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.TopicQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetTopicRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetTopicRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetTopicRequest} GetTopicRequest
         */
        GetTopicRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetTopicRequest)
                return object;
            let message = new $root.api.GetTopicRequest();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetTopicRequest.query: object expected");
                message.query = $root.api.TopicQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTopicRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetTopicRequest
         * @static
         * @param {api.GetTopicRequest} message GetTopicRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTopicRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.topicId = "";
                object.query = null;
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.TopicQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetTopicRequest to JSON.
         * @function toJSON
         * @memberof api.GetTopicRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTopicRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTopicRequest;
    })();

    api.GetUserRequest = (function() {

        /**
         * Properties of a GetUserRequest.
         * @memberof api
         * @interface IGetUserRequest
         * @property {string|null} [userId] GetUserRequest userId
         * @property {string|null} [emailAddress] GetUserRequest emailAddress
         * @property {api.IUserQuery|null} [query] GetUserRequest query
         */

        /**
         * Constructs a new GetUserRequest.
         * @memberof api
         * @classdesc Represents a GetUserRequest.
         * @implements IGetUserRequest
         * @constructor
         * @param {api.IGetUserRequest=} [properties] Properties to set
         */
        function GetUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserRequest userId.
         * @member {string|null|undefined} userId
         * @memberof api.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.userId = null;

        /**
         * GetUserRequest emailAddress.
         * @member {string|null|undefined} emailAddress
         * @memberof api.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.emailAddress = null;

        /**
         * GetUserRequest query.
         * @member {api.IUserQuery|null|undefined} query
         * @memberof api.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.query = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * GetUserRequest lookupChoice.
         * @member {"userId"|"emailAddress"|undefined} lookupChoice
         * @memberof api.GetUserRequest
         * @instance
         */
        Object.defineProperty(GetUserRequest.prototype, "lookupChoice", {
            get: $util.oneOfGetter($oneOfFields = ["userId", "emailAddress"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new GetUserRequest instance using the specified properties.
         * @function create
         * @memberof api.GetUserRequest
         * @static
         * @param {api.IGetUserRequest=} [properties] Properties to set
         * @returns {api.GetUserRequest} GetUserRequest instance
         */
        GetUserRequest.create = function create(properties) {
            return new GetUserRequest(properties);
        };

        /**
         * Encodes the specified GetUserRequest message. Does not implicitly {@link api.GetUserRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetUserRequest
         * @static
         * @param {api.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.UserQuery.encode(message.query, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserRequest message, length delimited. Does not implicitly {@link api.GetUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetUserRequest
         * @static
         * @param {api.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.query = $root.api.UserQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserRequest message.
         * @function verify
         * @memberof api.GetUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.userId != null && message.hasOwnProperty("userId")) {
                properties.lookupChoice = 1;
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
                if (properties.lookupChoice === 1)
                    return "lookupChoice: multiple values";
                properties.lookupChoice = 1;
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            }
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.UserQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetUserRequest} GetUserRequest
         */
        GetUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetUserRequest)
                return object;
            let message = new $root.api.GetUserRequest();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetUserRequest.query: object expected");
                message.query = $root.api.UserQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetUserRequest
         * @static
         * @param {api.GetUserRequest} message GetUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.query = null;
            if (message.userId != null && message.hasOwnProperty("userId")) {
                object.userId = message.userId;
                if (options.oneofs)
                    object.lookupChoice = "userId";
            }
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress")) {
                object.emailAddress = message.emailAddress;
                if (options.oneofs)
                    object.lookupChoice = "emailAddress";
            }
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.UserQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetUserRequest to JSON.
         * @function toJSON
         * @memberof api.GetUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserRequest;
    })();

    api.GetGroupRequest = (function() {

        /**
         * Properties of a GetGroupRequest.
         * @memberof api
         * @interface IGetGroupRequest
         * @property {string|null} [groupId] GetGroupRequest groupId
         * @property {api.IGroupQuery|null} [query] GetGroupRequest query
         */

        /**
         * Constructs a new GetGroupRequest.
         * @memberof api
         * @classdesc Represents a GetGroupRequest.
         * @implements IGetGroupRequest
         * @constructor
         * @param {api.IGetGroupRequest=} [properties] Properties to set
         */
        function GetGroupRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetGroupRequest groupId.
         * @member {string} groupId
         * @memberof api.GetGroupRequest
         * @instance
         */
        GetGroupRequest.prototype.groupId = "";

        /**
         * GetGroupRequest query.
         * @member {api.IGroupQuery|null|undefined} query
         * @memberof api.GetGroupRequest
         * @instance
         */
        GetGroupRequest.prototype.query = null;

        /**
         * Creates a new GetGroupRequest instance using the specified properties.
         * @function create
         * @memberof api.GetGroupRequest
         * @static
         * @param {api.IGetGroupRequest=} [properties] Properties to set
         * @returns {api.GetGroupRequest} GetGroupRequest instance
         */
        GetGroupRequest.create = function create(properties) {
            return new GetGroupRequest(properties);
        };

        /**
         * Encodes the specified GetGroupRequest message. Does not implicitly {@link api.GetGroupRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetGroupRequest
         * @static
         * @param {api.IGetGroupRequest} message GetGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGroupRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.GroupQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetGroupRequest message, length delimited. Does not implicitly {@link api.GetGroupRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetGroupRequest
         * @static
         * @param {api.IGetGroupRequest} message GetGroupRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGroupRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetGroupRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetGroupRequest} GetGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGroupRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetGroupRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                case 2:
                    message.query = $root.api.GroupQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetGroupRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetGroupRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetGroupRequest} GetGroupRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGroupRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetGroupRequest message.
         * @function verify
         * @memberof api.GetGroupRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetGroupRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.GroupQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a GetGroupRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetGroupRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetGroupRequest} GetGroupRequest
         */
        GetGroupRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetGroupRequest)
                return object;
            let message = new $root.api.GetGroupRequest();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.GetGroupRequest.query: object expected");
                message.query = $root.api.GroupQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetGroupRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetGroupRequest
         * @static
         * @param {api.GetGroupRequest} message GetGroupRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetGroupRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.groupId = "";
                object.query = null;
            }
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.GroupQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this GetGroupRequest to JSON.
         * @function toJSON
         * @memberof api.GetGroupRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetGroupRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetGroupRequest;
    })();

    api.AddAssetRequest = (function() {

        /**
         * Properties of an AddAssetRequest.
         * @memberof api
         * @interface IAddAssetRequest
         * @property {string|null} [objectId] AddAssetRequest objectId
         * @property {string|null} [assetType] AddAssetRequest assetType
         * @property {string|null} [name] AddAssetRequest name
         * @property {number|Long|null} [size] AddAssetRequest size
         */

        /**
         * Constructs a new AddAssetRequest.
         * @memberof api
         * @classdesc Represents an AddAssetRequest.
         * @implements IAddAssetRequest
         * @constructor
         * @param {api.IAddAssetRequest=} [properties] Properties to set
         */
        function AddAssetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddAssetRequest objectId.
         * @member {string} objectId
         * @memberof api.AddAssetRequest
         * @instance
         */
        AddAssetRequest.prototype.objectId = "";

        /**
         * AddAssetRequest assetType.
         * @member {string} assetType
         * @memberof api.AddAssetRequest
         * @instance
         */
        AddAssetRequest.prototype.assetType = "";

        /**
         * AddAssetRequest name.
         * @member {string} name
         * @memberof api.AddAssetRequest
         * @instance
         */
        AddAssetRequest.prototype.name = "";

        /**
         * AddAssetRequest size.
         * @member {number|Long} size
         * @memberof api.AddAssetRequest
         * @instance
         */
        AddAssetRequest.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new AddAssetRequest instance using the specified properties.
         * @function create
         * @memberof api.AddAssetRequest
         * @static
         * @param {api.IAddAssetRequest=} [properties] Properties to set
         * @returns {api.AddAssetRequest} AddAssetRequest instance
         */
        AddAssetRequest.create = function create(properties) {
            return new AddAssetRequest(properties);
        };

        /**
         * Encodes the specified AddAssetRequest message. Does not implicitly {@link api.AddAssetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.AddAssetRequest
         * @static
         * @param {api.IAddAssetRequest} message AddAssetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddAssetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.assetType != null && Object.hasOwnProperty.call(message, "assetType"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.assetType);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.size);
            return writer;
        };

        /**
         * Encodes the specified AddAssetRequest message, length delimited. Does not implicitly {@link api.AddAssetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddAssetRequest
         * @static
         * @param {api.IAddAssetRequest} message AddAssetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddAssetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddAssetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddAssetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddAssetRequest} AddAssetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddAssetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddAssetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.assetType = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                case 4:
                    message.size = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddAssetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddAssetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddAssetRequest} AddAssetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddAssetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddAssetRequest message.
         * @function verify
         * @memberof api.AddAssetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddAssetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.assetType != null && message.hasOwnProperty("assetType"))
                if (!$util.isString(message.assetType))
                    return "assetType: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            return null;
        };

        /**
         * Creates an AddAssetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddAssetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddAssetRequest} AddAssetRequest
         */
        AddAssetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddAssetRequest)
                return object;
            let message = new $root.api.AddAssetRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.assetType != null)
                message.assetType = String(object.assetType);
            if (object.name != null)
                message.name = String(object.name);
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an AddAssetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddAssetRequest
         * @static
         * @param {api.AddAssetRequest} message AddAssetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddAssetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.assetType = "";
                object.name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.assetType != null && message.hasOwnProperty("assetType"))
                object.assetType = message.assetType;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            return object;
        };

        /**
         * Converts this AddAssetRequest to JSON.
         * @function toJSON
         * @memberof api.AddAssetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddAssetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddAssetRequest;
    })();

    api.DeleteAssetRequest = (function() {

        /**
         * Properties of a DeleteAssetRequest.
         * @memberof api
         * @interface IDeleteAssetRequest
         * @property {string|null} [objectId] DeleteAssetRequest objectId
         * @property {string|null} [name] DeleteAssetRequest name
         */

        /**
         * Constructs a new DeleteAssetRequest.
         * @memberof api
         * @classdesc Represents a DeleteAssetRequest.
         * @implements IDeleteAssetRequest
         * @constructor
         * @param {api.IDeleteAssetRequest=} [properties] Properties to set
         */
        function DeleteAssetRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DeleteAssetRequest objectId.
         * @member {string} objectId
         * @memberof api.DeleteAssetRequest
         * @instance
         */
        DeleteAssetRequest.prototype.objectId = "";

        /**
         * DeleteAssetRequest name.
         * @member {string} name
         * @memberof api.DeleteAssetRequest
         * @instance
         */
        DeleteAssetRequest.prototype.name = "";

        /**
         * Creates a new DeleteAssetRequest instance using the specified properties.
         * @function create
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {api.IDeleteAssetRequest=} [properties] Properties to set
         * @returns {api.DeleteAssetRequest} DeleteAssetRequest instance
         */
        DeleteAssetRequest.create = function create(properties) {
            return new DeleteAssetRequest(properties);
        };

        /**
         * Encodes the specified DeleteAssetRequest message. Does not implicitly {@link api.DeleteAssetRequest.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {api.IDeleteAssetRequest} message DeleteAssetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssetRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified DeleteAssetRequest message, length delimited. Does not implicitly {@link api.DeleteAssetRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {api.IDeleteAssetRequest} message DeleteAssetRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssetRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteAssetRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteAssetRequest} DeleteAssetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssetRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteAssetRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteAssetRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteAssetRequest} DeleteAssetRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssetRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteAssetRequest message.
         * @function verify
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteAssetRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a DeleteAssetRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteAssetRequest} DeleteAssetRequest
         */
        DeleteAssetRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteAssetRequest)
                return object;
            let message = new $root.api.DeleteAssetRequest();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a DeleteAssetRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteAssetRequest
         * @static
         * @param {api.DeleteAssetRequest} message DeleteAssetRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteAssetRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.name = "";
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this DeleteAssetRequest to JSON.
         * @function toJSON
         * @memberof api.DeleteAssetRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteAssetRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteAssetRequest;
    })();

    api.EventFilter = (function() {

        /**
         * Properties of an EventFilter.
         * @memberof api
         * @interface IEventFilter
         * @property {boolean|null} [chatMessageEvent] EventFilter chatMessageEvent
         * @property {boolean|null} [memberInviteEvent] EventFilter memberInviteEvent
         * @property {boolean|null} [memberAddedEvent] EventFilter memberAddedEvent
         * @property {boolean|null} [memberRemovedEvent] EventFilter memberRemovedEvent
         * @property {boolean|null} [objectRemovedEvent] EventFilter objectRemovedEvent
         * @property {boolean|null} [syncObjectUpdatedEvent] EventFilter syncObjectUpdatedEvent
         */

        /**
         * Constructs a new EventFilter.
         * @memberof api
         * @classdesc Represents an EventFilter.
         * @implements IEventFilter
         * @constructor
         * @param {api.IEventFilter=} [properties] Properties to set
         */
        function EventFilter(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * EventFilter chatMessageEvent.
         * @member {boolean} chatMessageEvent
         * @memberof api.EventFilter
         * @instance
         */
        EventFilter.prototype.chatMessageEvent = false;

        /**
         * EventFilter memberInviteEvent.
         * @member {boolean} memberInviteEvent
         * @memberof api.EventFilter
         * @instance
         */
        EventFilter.prototype.memberInviteEvent = false;

        /**
         * EventFilter memberAddedEvent.
         * @member {boolean} memberAddedEvent
         * @memberof api.EventFilter
         * @instance
         */
        EventFilter.prototype.memberAddedEvent = false;

        /**
         * EventFilter memberRemovedEvent.
         * @member {boolean} memberRemovedEvent
         * @memberof api.EventFilter
         * @instance
         */
        EventFilter.prototype.memberRemovedEvent = false;

        /**
         * EventFilter objectRemovedEvent.
         * @member {boolean} objectRemovedEvent
         * @memberof api.EventFilter
         * @instance
         */
        EventFilter.prototype.objectRemovedEvent = false;

        /**
         * EventFilter syncObjectUpdatedEvent.
         * @member {boolean} syncObjectUpdatedEvent
         * @memberof api.EventFilter
         * @instance
         */
        EventFilter.prototype.syncObjectUpdatedEvent = false;

        /**
         * Creates a new EventFilter instance using the specified properties.
         * @function create
         * @memberof api.EventFilter
         * @static
         * @param {api.IEventFilter=} [properties] Properties to set
         * @returns {api.EventFilter} EventFilter instance
         */
        EventFilter.create = function create(properties) {
            return new EventFilter(properties);
        };

        /**
         * Encodes the specified EventFilter message. Does not implicitly {@link api.EventFilter.verify|verify} messages.
         * @function encode
         * @memberof api.EventFilter
         * @static
         * @param {api.IEventFilter} message EventFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventFilter.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chatMessageEvent != null && Object.hasOwnProperty.call(message, "chatMessageEvent"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.chatMessageEvent);
            if (message.memberInviteEvent != null && Object.hasOwnProperty.call(message, "memberInviteEvent"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.memberInviteEvent);
            if (message.memberAddedEvent != null && Object.hasOwnProperty.call(message, "memberAddedEvent"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.memberAddedEvent);
            if (message.memberRemovedEvent != null && Object.hasOwnProperty.call(message, "memberRemovedEvent"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.memberRemovedEvent);
            if (message.objectRemovedEvent != null && Object.hasOwnProperty.call(message, "objectRemovedEvent"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.objectRemovedEvent);
            if (message.syncObjectUpdatedEvent != null && Object.hasOwnProperty.call(message, "syncObjectUpdatedEvent"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.syncObjectUpdatedEvent);
            return writer;
        };

        /**
         * Encodes the specified EventFilter message, length delimited. Does not implicitly {@link api.EventFilter.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.EventFilter
         * @static
         * @param {api.IEventFilter} message EventFilter message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EventFilter.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EventFilter message from the specified reader or buffer.
         * @function decode
         * @memberof api.EventFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.EventFilter} EventFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventFilter.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.EventFilter();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.chatMessageEvent = reader.bool();
                    break;
                case 2:
                    message.memberInviteEvent = reader.bool();
                    break;
                case 3:
                    message.memberAddedEvent = reader.bool();
                    break;
                case 4:
                    message.memberRemovedEvent = reader.bool();
                    break;
                case 5:
                    message.objectRemovedEvent = reader.bool();
                    break;
                case 6:
                    message.syncObjectUpdatedEvent = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an EventFilter message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.EventFilter
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.EventFilter} EventFilter
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EventFilter.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an EventFilter message.
         * @function verify
         * @memberof api.EventFilter
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        EventFilter.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chatMessageEvent != null && message.hasOwnProperty("chatMessageEvent"))
                if (typeof message.chatMessageEvent !== "boolean")
                    return "chatMessageEvent: boolean expected";
            if (message.memberInviteEvent != null && message.hasOwnProperty("memberInviteEvent"))
                if (typeof message.memberInviteEvent !== "boolean")
                    return "memberInviteEvent: boolean expected";
            if (message.memberAddedEvent != null && message.hasOwnProperty("memberAddedEvent"))
                if (typeof message.memberAddedEvent !== "boolean")
                    return "memberAddedEvent: boolean expected";
            if (message.memberRemovedEvent != null && message.hasOwnProperty("memberRemovedEvent"))
                if (typeof message.memberRemovedEvent !== "boolean")
                    return "memberRemovedEvent: boolean expected";
            if (message.objectRemovedEvent != null && message.hasOwnProperty("objectRemovedEvent"))
                if (typeof message.objectRemovedEvent !== "boolean")
                    return "objectRemovedEvent: boolean expected";
            if (message.syncObjectUpdatedEvent != null && message.hasOwnProperty("syncObjectUpdatedEvent"))
                if (typeof message.syncObjectUpdatedEvent !== "boolean")
                    return "syncObjectUpdatedEvent: boolean expected";
            return null;
        };

        /**
         * Creates an EventFilter message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.EventFilter
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.EventFilter} EventFilter
         */
        EventFilter.fromObject = function fromObject(object) {
            if (object instanceof $root.api.EventFilter)
                return object;
            let message = new $root.api.EventFilter();
            if (object.chatMessageEvent != null)
                message.chatMessageEvent = Boolean(object.chatMessageEvent);
            if (object.memberInviteEvent != null)
                message.memberInviteEvent = Boolean(object.memberInviteEvent);
            if (object.memberAddedEvent != null)
                message.memberAddedEvent = Boolean(object.memberAddedEvent);
            if (object.memberRemovedEvent != null)
                message.memberRemovedEvent = Boolean(object.memberRemovedEvent);
            if (object.objectRemovedEvent != null)
                message.objectRemovedEvent = Boolean(object.objectRemovedEvent);
            if (object.syncObjectUpdatedEvent != null)
                message.syncObjectUpdatedEvent = Boolean(object.syncObjectUpdatedEvent);
            return message;
        };

        /**
         * Creates a plain object from an EventFilter message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.EventFilter
         * @static
         * @param {api.EventFilter} message EventFilter
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        EventFilter.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.chatMessageEvent = false;
                object.memberInviteEvent = false;
                object.memberAddedEvent = false;
                object.memberRemovedEvent = false;
                object.objectRemovedEvent = false;
                object.syncObjectUpdatedEvent = false;
            }
            if (message.chatMessageEvent != null && message.hasOwnProperty("chatMessageEvent"))
                object.chatMessageEvent = message.chatMessageEvent;
            if (message.memberInviteEvent != null && message.hasOwnProperty("memberInviteEvent"))
                object.memberInviteEvent = message.memberInviteEvent;
            if (message.memberAddedEvent != null && message.hasOwnProperty("memberAddedEvent"))
                object.memberAddedEvent = message.memberAddedEvent;
            if (message.memberRemovedEvent != null && message.hasOwnProperty("memberRemovedEvent"))
                object.memberRemovedEvent = message.memberRemovedEvent;
            if (message.objectRemovedEvent != null && message.hasOwnProperty("objectRemovedEvent"))
                object.objectRemovedEvent = message.objectRemovedEvent;
            if (message.syncObjectUpdatedEvent != null && message.hasOwnProperty("syncObjectUpdatedEvent"))
                object.syncObjectUpdatedEvent = message.syncObjectUpdatedEvent;
            return object;
        };

        /**
         * Converts this EventFilter to JSON.
         * @function toJSON
         * @memberof api.EventFilter
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        EventFilter.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EventFilter;
    })();

    api.GetUserEventsRequest = (function() {

        /**
         * Properties of a GetUserEventsRequest.
         * @memberof api
         * @interface IGetUserEventsRequest
         * @property {number|Long|null} [startTime] GetUserEventsRequest startTime
         * @property {number|null} [limit] GetUserEventsRequest limit
         * @property {boolean|null} [reverse] GetUserEventsRequest reverse
         * @property {string|null} [filterObjectId] GetUserEventsRequest filterObjectId
         * @property {api.IEventFilter|null} [eventFilter] GetUserEventsRequest eventFilter
         */

        /**
         * Constructs a new GetUserEventsRequest.
         * @memberof api
         * @classdesc Represents a GetUserEventsRequest.
         * @implements IGetUserEventsRequest
         * @constructor
         * @param {api.IGetUserEventsRequest=} [properties] Properties to set
         */
        function GetUserEventsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserEventsRequest startTime.
         * @member {number|Long} startTime
         * @memberof api.GetUserEventsRequest
         * @instance
         */
        GetUserEventsRequest.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * GetUserEventsRequest limit.
         * @member {number} limit
         * @memberof api.GetUserEventsRequest
         * @instance
         */
        GetUserEventsRequest.prototype.limit = 0;

        /**
         * GetUserEventsRequest reverse.
         * @member {boolean} reverse
         * @memberof api.GetUserEventsRequest
         * @instance
         */
        GetUserEventsRequest.prototype.reverse = false;

        /**
         * GetUserEventsRequest filterObjectId.
         * @member {string} filterObjectId
         * @memberof api.GetUserEventsRequest
         * @instance
         */
        GetUserEventsRequest.prototype.filterObjectId = "";

        /**
         * GetUserEventsRequest eventFilter.
         * @member {api.IEventFilter|null|undefined} eventFilter
         * @memberof api.GetUserEventsRequest
         * @instance
         */
        GetUserEventsRequest.prototype.eventFilter = null;

        /**
         * Creates a new GetUserEventsRequest instance using the specified properties.
         * @function create
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {api.IGetUserEventsRequest=} [properties] Properties to set
         * @returns {api.GetUserEventsRequest} GetUserEventsRequest instance
         */
        GetUserEventsRequest.create = function create(properties) {
            return new GetUserEventsRequest(properties);
        };

        /**
         * Encodes the specified GetUserEventsRequest message. Does not implicitly {@link api.GetUserEventsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {api.IGetUserEventsRequest} message GetUserEventsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserEventsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.startTime);
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.limit);
            if (message.reverse != null && Object.hasOwnProperty.call(message, "reverse"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.reverse);
            if (message.filterObjectId != null && Object.hasOwnProperty.call(message, "filterObjectId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.filterObjectId);
            if (message.eventFilter != null && Object.hasOwnProperty.call(message, "eventFilter"))
                $root.api.EventFilter.encode(message.eventFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserEventsRequest message, length delimited. Does not implicitly {@link api.GetUserEventsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {api.IGetUserEventsRequest} message GetUserEventsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserEventsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserEventsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetUserEventsRequest} GetUserEventsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserEventsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetUserEventsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.startTime = reader.int64();
                    break;
                case 2:
                    message.limit = reader.int32();
                    break;
                case 3:
                    message.reverse = reader.bool();
                    break;
                case 4:
                    message.filterObjectId = reader.string();
                    break;
                case 5:
                    message.eventFilter = $root.api.EventFilter.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserEventsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetUserEventsRequest} GetUserEventsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserEventsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserEventsRequest message.
         * @function verify
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserEventsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.reverse != null && message.hasOwnProperty("reverse"))
                if (typeof message.reverse !== "boolean")
                    return "reverse: boolean expected";
            if (message.filterObjectId != null && message.hasOwnProperty("filterObjectId"))
                if (!$util.isString(message.filterObjectId))
                    return "filterObjectId: string expected";
            if (message.eventFilter != null && message.hasOwnProperty("eventFilter")) {
                let error = $root.api.EventFilter.verify(message.eventFilter);
                if (error)
                    return "eventFilter." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserEventsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetUserEventsRequest} GetUserEventsRequest
         */
        GetUserEventsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetUserEventsRequest)
                return object;
            let message = new $root.api.GetUserEventsRequest();
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = false;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber();
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.reverse != null)
                message.reverse = Boolean(object.reverse);
            if (object.filterObjectId != null)
                message.filterObjectId = String(object.filterObjectId);
            if (object.eventFilter != null) {
                if (typeof object.eventFilter !== "object")
                    throw TypeError(".api.GetUserEventsRequest.eventFilter: object expected");
                message.eventFilter = $root.api.EventFilter.fromObject(object.eventFilter);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserEventsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetUserEventsRequest
         * @static
         * @param {api.GetUserEventsRequest} message GetUserEventsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserEventsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
                object.limit = 0;
                object.reverse = false;
                object.filterObjectId = "";
                object.eventFilter = null;
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber() : message.startTime;
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.reverse != null && message.hasOwnProperty("reverse"))
                object.reverse = message.reverse;
            if (message.filterObjectId != null && message.hasOwnProperty("filterObjectId"))
                object.filterObjectId = message.filterObjectId;
            if (message.eventFilter != null && message.hasOwnProperty("eventFilter"))
                object.eventFilter = $root.api.EventFilter.toObject(message.eventFilter, options);
            return object;
        };

        /**
         * Converts this GetUserEventsRequest to JSON.
         * @function toJSON
         * @memberof api.GetUserEventsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserEventsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserEventsRequest;
    })();

    api.LookupTinyCodeRequest = (function() {

        /**
         * Properties of a LookupTinyCodeRequest.
         * @memberof api
         * @interface ILookupTinyCodeRequest
         * @property {string|null} [tinyCode] LookupTinyCodeRequest tinyCode
         */

        /**
         * Constructs a new LookupTinyCodeRequest.
         * @memberof api
         * @classdesc Represents a LookupTinyCodeRequest.
         * @implements ILookupTinyCodeRequest
         * @constructor
         * @param {api.ILookupTinyCodeRequest=} [properties] Properties to set
         */
        function LookupTinyCodeRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LookupTinyCodeRequest tinyCode.
         * @member {string} tinyCode
         * @memberof api.LookupTinyCodeRequest
         * @instance
         */
        LookupTinyCodeRequest.prototype.tinyCode = "";

        /**
         * Creates a new LookupTinyCodeRequest instance using the specified properties.
         * @function create
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {api.ILookupTinyCodeRequest=} [properties] Properties to set
         * @returns {api.LookupTinyCodeRequest} LookupTinyCodeRequest instance
         */
        LookupTinyCodeRequest.create = function create(properties) {
            return new LookupTinyCodeRequest(properties);
        };

        /**
         * Encodes the specified LookupTinyCodeRequest message. Does not implicitly {@link api.LookupTinyCodeRequest.verify|verify} messages.
         * @function encode
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {api.ILookupTinyCodeRequest} message LookupTinyCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LookupTinyCodeRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.tinyCode != null && Object.hasOwnProperty.call(message, "tinyCode"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.tinyCode);
            return writer;
        };

        /**
         * Encodes the specified LookupTinyCodeRequest message, length delimited. Does not implicitly {@link api.LookupTinyCodeRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {api.ILookupTinyCodeRequest} message LookupTinyCodeRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LookupTinyCodeRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LookupTinyCodeRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LookupTinyCodeRequest} LookupTinyCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LookupTinyCodeRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LookupTinyCodeRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.tinyCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LookupTinyCodeRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LookupTinyCodeRequest} LookupTinyCodeRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LookupTinyCodeRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LookupTinyCodeRequest message.
         * @function verify
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LookupTinyCodeRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.tinyCode != null && message.hasOwnProperty("tinyCode"))
                if (!$util.isString(message.tinyCode))
                    return "tinyCode: string expected";
            return null;
        };

        /**
         * Creates a LookupTinyCodeRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LookupTinyCodeRequest} LookupTinyCodeRequest
         */
        LookupTinyCodeRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LookupTinyCodeRequest)
                return object;
            let message = new $root.api.LookupTinyCodeRequest();
            if (object.tinyCode != null)
                message.tinyCode = String(object.tinyCode);
            return message;
        };

        /**
         * Creates a plain object from a LookupTinyCodeRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LookupTinyCodeRequest
         * @static
         * @param {api.LookupTinyCodeRequest} message LookupTinyCodeRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LookupTinyCodeRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.tinyCode = "";
            if (message.tinyCode != null && message.hasOwnProperty("tinyCode"))
                object.tinyCode = message.tinyCode;
            return object;
        };

        /**
         * Converts this LookupTinyCodeRequest to JSON.
         * @function toJSON
         * @memberof api.LookupTinyCodeRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LookupTinyCodeRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LookupTinyCodeRequest;
    })();

    api.FindTopicsRequest = (function() {

        /**
         * Properties of a FindTopicsRequest.
         * @memberof api
         * @interface IFindTopicsRequest
         * @property {number|null} [limit] FindTopicsRequest limit
         * @property {number|null} [offset] FindTopicsRequest offset
         * @property {number|null} [excess] FindTopicsRequest excess
         * @property {api.ITopicQuery|null} [query] FindTopicsRequest query
         * @property {Array.<string>|null} [tags] FindTopicsRequest tags
         * @property {number|null} [minAge] FindTopicsRequest minAge
         * @property {number|null} [maxAge] FindTopicsRequest maxAge
         * @property {number|Long|null} [minDuration] FindTopicsRequest minDuration
         * @property {number|Long|null} [maxDuration] FindTopicsRequest maxDuration
         * @property {string|null} [searchString] FindTopicsRequest searchString
         * @property {number|null} [minRatings] FindTopicsRequest minRatings
         * @property {number|null} [minRatingsCount] FindTopicsRequest minRatingsCount
         * @property {string|null} [instructorUserId] FindTopicsRequest instructorUserId
         */

        /**
         * Constructs a new FindTopicsRequest.
         * @memberof api
         * @classdesc Represents a FindTopicsRequest.
         * @implements IFindTopicsRequest
         * @constructor
         * @param {api.IFindTopicsRequest=} [properties] Properties to set
         */
        function FindTopicsRequest(properties) {
            this.tags = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindTopicsRequest limit.
         * @member {number} limit
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.limit = 0;

        /**
         * FindTopicsRequest offset.
         * @member {number} offset
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.offset = 0;

        /**
         * FindTopicsRequest excess.
         * @member {number} excess
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.excess = 0;

        /**
         * FindTopicsRequest query.
         * @member {api.ITopicQuery|null|undefined} query
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.query = null;

        /**
         * FindTopicsRequest tags.
         * @member {Array.<string>} tags
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.tags = $util.emptyArray;

        /**
         * FindTopicsRequest minAge.
         * @member {number} minAge
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.minAge = 0;

        /**
         * FindTopicsRequest maxAge.
         * @member {number} maxAge
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.maxAge = 0;

        /**
         * FindTopicsRequest minDuration.
         * @member {number|Long} minDuration
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.minDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FindTopicsRequest maxDuration.
         * @member {number|Long} maxDuration
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.maxDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * FindTopicsRequest searchString.
         * @member {string} searchString
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.searchString = "";

        /**
         * FindTopicsRequest minRatings.
         * @member {number} minRatings
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.minRatings = 0;

        /**
         * FindTopicsRequest minRatingsCount.
         * @member {number} minRatingsCount
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.minRatingsCount = 0;

        /**
         * FindTopicsRequest instructorUserId.
         * @member {string} instructorUserId
         * @memberof api.FindTopicsRequest
         * @instance
         */
        FindTopicsRequest.prototype.instructorUserId = "";

        /**
         * Creates a new FindTopicsRequest instance using the specified properties.
         * @function create
         * @memberof api.FindTopicsRequest
         * @static
         * @param {api.IFindTopicsRequest=} [properties] Properties to set
         * @returns {api.FindTopicsRequest} FindTopicsRequest instance
         */
        FindTopicsRequest.create = function create(properties) {
            return new FindTopicsRequest(properties);
        };

        /**
         * Encodes the specified FindTopicsRequest message. Does not implicitly {@link api.FindTopicsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.FindTopicsRequest
         * @static
         * @param {api.IFindTopicsRequest} message FindTopicsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindTopicsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.TopicQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.tags[i]);
            if (message.minAge != null && Object.hasOwnProperty.call(message, "minAge"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minAge);
            if (message.maxAge != null && Object.hasOwnProperty.call(message, "maxAge"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxAge);
            if (message.minDuration != null && Object.hasOwnProperty.call(message, "minDuration"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.minDuration);
            if (message.maxDuration != null && Object.hasOwnProperty.call(message, "maxDuration"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.maxDuration);
            if (message.searchString != null && Object.hasOwnProperty.call(message, "searchString"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.searchString);
            if (message.minRatings != null && Object.hasOwnProperty.call(message, "minRatings"))
                writer.uint32(/* id 9, wireType 5 =*/77).float(message.minRatings);
            if (message.minRatingsCount != null && Object.hasOwnProperty.call(message, "minRatingsCount"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.minRatingsCount);
            if (message.instructorUserId != null && Object.hasOwnProperty.call(message, "instructorUserId"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.instructorUserId);
            if (message.offset != null && Object.hasOwnProperty.call(message, "offset"))
                writer.uint32(/* id 12, wireType 0 =*/96).int32(message.offset);
            if (message.excess != null && Object.hasOwnProperty.call(message, "excess"))
                writer.uint32(/* id 13, wireType 0 =*/104).int32(message.excess);
            return writer;
        };

        /**
         * Encodes the specified FindTopicsRequest message, length delimited. Does not implicitly {@link api.FindTopicsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.FindTopicsRequest
         * @static
         * @param {api.IFindTopicsRequest} message FindTopicsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindTopicsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FindTopicsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.FindTopicsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.FindTopicsRequest} FindTopicsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindTopicsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.FindTopicsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 12:
                    message.offset = reader.int32();
                    break;
                case 13:
                    message.excess = reader.int32();
                    break;
                case 2:
                    message.query = $root.api.TopicQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                case 4:
                    message.minAge = reader.int32();
                    break;
                case 5:
                    message.maxAge = reader.int32();
                    break;
                case 6:
                    message.minDuration = reader.int64();
                    break;
                case 7:
                    message.maxDuration = reader.int64();
                    break;
                case 8:
                    message.searchString = reader.string();
                    break;
                case 9:
                    message.minRatings = reader.float();
                    break;
                case 10:
                    message.minRatingsCount = reader.int32();
                    break;
                case 11:
                    message.instructorUserId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FindTopicsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.FindTopicsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.FindTopicsRequest} FindTopicsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindTopicsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FindTopicsRequest message.
         * @function verify
         * @memberof api.FindTopicsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FindTopicsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.offset != null && message.hasOwnProperty("offset"))
                if (!$util.isInteger(message.offset))
                    return "offset: integer expected";
            if (message.excess != null && message.hasOwnProperty("excess"))
                if (!$util.isInteger(message.excess))
                    return "excess: integer expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.TopicQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.minAge != null && message.hasOwnProperty("minAge"))
                if (!$util.isInteger(message.minAge))
                    return "minAge: integer expected";
            if (message.maxAge != null && message.hasOwnProperty("maxAge"))
                if (!$util.isInteger(message.maxAge))
                    return "maxAge: integer expected";
            if (message.minDuration != null && message.hasOwnProperty("minDuration"))
                if (!$util.isInteger(message.minDuration) && !(message.minDuration && $util.isInteger(message.minDuration.low) && $util.isInteger(message.minDuration.high)))
                    return "minDuration: integer|Long expected";
            if (message.maxDuration != null && message.hasOwnProperty("maxDuration"))
                if (!$util.isInteger(message.maxDuration) && !(message.maxDuration && $util.isInteger(message.maxDuration.low) && $util.isInteger(message.maxDuration.high)))
                    return "maxDuration: integer|Long expected";
            if (message.searchString != null && message.hasOwnProperty("searchString"))
                if (!$util.isString(message.searchString))
                    return "searchString: string expected";
            if (message.minRatings != null && message.hasOwnProperty("minRatings"))
                if (typeof message.minRatings !== "number")
                    return "minRatings: number expected";
            if (message.minRatingsCount != null && message.hasOwnProperty("minRatingsCount"))
                if (!$util.isInteger(message.minRatingsCount))
                    return "minRatingsCount: integer expected";
            if (message.instructorUserId != null && message.hasOwnProperty("instructorUserId"))
                if (!$util.isString(message.instructorUserId))
                    return "instructorUserId: string expected";
            return null;
        };

        /**
         * Creates a FindTopicsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.FindTopicsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.FindTopicsRequest} FindTopicsRequest
         */
        FindTopicsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.FindTopicsRequest)
                return object;
            let message = new $root.api.FindTopicsRequest();
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.offset != null)
                message.offset = object.offset | 0;
            if (object.excess != null)
                message.excess = object.excess | 0;
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.FindTopicsRequest.query: object expected");
                message.query = $root.api.TopicQuery.fromObject(object.query);
            }
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".api.FindTopicsRequest.tags: array expected");
                message.tags = [];
                for (let i = 0; i < object.tags.length; ++i)
                    message.tags[i] = String(object.tags[i]);
            }
            if (object.minAge != null)
                message.minAge = object.minAge | 0;
            if (object.maxAge != null)
                message.maxAge = object.maxAge | 0;
            if (object.minDuration != null)
                if ($util.Long)
                    (message.minDuration = $util.Long.fromValue(object.minDuration)).unsigned = false;
                else if (typeof object.minDuration === "string")
                    message.minDuration = parseInt(object.minDuration, 10);
                else if (typeof object.minDuration === "number")
                    message.minDuration = object.minDuration;
                else if (typeof object.minDuration === "object")
                    message.minDuration = new $util.LongBits(object.minDuration.low >>> 0, object.minDuration.high >>> 0).toNumber();
            if (object.maxDuration != null)
                if ($util.Long)
                    (message.maxDuration = $util.Long.fromValue(object.maxDuration)).unsigned = false;
                else if (typeof object.maxDuration === "string")
                    message.maxDuration = parseInt(object.maxDuration, 10);
                else if (typeof object.maxDuration === "number")
                    message.maxDuration = object.maxDuration;
                else if (typeof object.maxDuration === "object")
                    message.maxDuration = new $util.LongBits(object.maxDuration.low >>> 0, object.maxDuration.high >>> 0).toNumber();
            if (object.searchString != null)
                message.searchString = String(object.searchString);
            if (object.minRatings != null)
                message.minRatings = Number(object.minRatings);
            if (object.minRatingsCount != null)
                message.minRatingsCount = object.minRatingsCount | 0;
            if (object.instructorUserId != null)
                message.instructorUserId = String(object.instructorUserId);
            return message;
        };

        /**
         * Creates a plain object from a FindTopicsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.FindTopicsRequest
         * @static
         * @param {api.FindTopicsRequest} message FindTopicsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FindTopicsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tags = [];
            if (options.defaults) {
                object.limit = 0;
                object.query = null;
                object.minAge = 0;
                object.maxAge = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minDuration = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.maxDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxDuration = options.longs === String ? "0" : 0;
                object.searchString = "";
                object.minRatings = 0;
                object.minRatingsCount = 0;
                object.instructorUserId = "";
                object.offset = 0;
                object.excess = 0;
            }
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.TopicQuery.toObject(message.query, options);
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (let j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            if (message.minAge != null && message.hasOwnProperty("minAge"))
                object.minAge = message.minAge;
            if (message.maxAge != null && message.hasOwnProperty("maxAge"))
                object.maxAge = message.maxAge;
            if (message.minDuration != null && message.hasOwnProperty("minDuration"))
                if (typeof message.minDuration === "number")
                    object.minDuration = options.longs === String ? String(message.minDuration) : message.minDuration;
                else
                    object.minDuration = options.longs === String ? $util.Long.prototype.toString.call(message.minDuration) : options.longs === Number ? new $util.LongBits(message.minDuration.low >>> 0, message.minDuration.high >>> 0).toNumber() : message.minDuration;
            if (message.maxDuration != null && message.hasOwnProperty("maxDuration"))
                if (typeof message.maxDuration === "number")
                    object.maxDuration = options.longs === String ? String(message.maxDuration) : message.maxDuration;
                else
                    object.maxDuration = options.longs === String ? $util.Long.prototype.toString.call(message.maxDuration) : options.longs === Number ? new $util.LongBits(message.maxDuration.low >>> 0, message.maxDuration.high >>> 0).toNumber() : message.maxDuration;
            if (message.searchString != null && message.hasOwnProperty("searchString"))
                object.searchString = message.searchString;
            if (message.minRatings != null && message.hasOwnProperty("minRatings"))
                object.minRatings = options.json && !isFinite(message.minRatings) ? String(message.minRatings) : message.minRatings;
            if (message.minRatingsCount != null && message.hasOwnProperty("minRatingsCount"))
                object.minRatingsCount = message.minRatingsCount;
            if (message.instructorUserId != null && message.hasOwnProperty("instructorUserId"))
                object.instructorUserId = message.instructorUserId;
            if (message.offset != null && message.hasOwnProperty("offset"))
                object.offset = message.offset;
            if (message.excess != null && message.hasOwnProperty("excess"))
                object.excess = message.excess;
            return object;
        };

        /**
         * Converts this FindTopicsRequest to JSON.
         * @function toJSON
         * @memberof api.FindTopicsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FindTopicsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FindTopicsRequest;
    })();

    api.PendingReviewTopicsRequest = (function() {

        /**
         * Properties of a PendingReviewTopicsRequest.
         * @memberof api
         * @interface IPendingReviewTopicsRequest
         * @property {number|null} [limit] PendingReviewTopicsRequest limit
         * @property {api.ITopicQuery|null} [query] PendingReviewTopicsRequest query
         */

        /**
         * Constructs a new PendingReviewTopicsRequest.
         * @memberof api
         * @classdesc Represents a PendingReviewTopicsRequest.
         * @implements IPendingReviewTopicsRequest
         * @constructor
         * @param {api.IPendingReviewTopicsRequest=} [properties] Properties to set
         */
        function PendingReviewTopicsRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingReviewTopicsRequest limit.
         * @member {number} limit
         * @memberof api.PendingReviewTopicsRequest
         * @instance
         */
        PendingReviewTopicsRequest.prototype.limit = 0;

        /**
         * PendingReviewTopicsRequest query.
         * @member {api.ITopicQuery|null|undefined} query
         * @memberof api.PendingReviewTopicsRequest
         * @instance
         */
        PendingReviewTopicsRequest.prototype.query = null;

        /**
         * Creates a new PendingReviewTopicsRequest instance using the specified properties.
         * @function create
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {api.IPendingReviewTopicsRequest=} [properties] Properties to set
         * @returns {api.PendingReviewTopicsRequest} PendingReviewTopicsRequest instance
         */
        PendingReviewTopicsRequest.create = function create(properties) {
            return new PendingReviewTopicsRequest(properties);
        };

        /**
         * Encodes the specified PendingReviewTopicsRequest message. Does not implicitly {@link api.PendingReviewTopicsRequest.verify|verify} messages.
         * @function encode
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {api.IPendingReviewTopicsRequest} message PendingReviewTopicsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingReviewTopicsRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.limit != null && Object.hasOwnProperty.call(message, "limit"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.limit);
            if (message.query != null && Object.hasOwnProperty.call(message, "query"))
                $root.api.TopicQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PendingReviewTopicsRequest message, length delimited. Does not implicitly {@link api.PendingReviewTopicsRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {api.IPendingReviewTopicsRequest} message PendingReviewTopicsRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingReviewTopicsRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingReviewTopicsRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PendingReviewTopicsRequest} PendingReviewTopicsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingReviewTopicsRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PendingReviewTopicsRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.limit = reader.int32();
                    break;
                case 2:
                    message.query = $root.api.TopicQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingReviewTopicsRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PendingReviewTopicsRequest} PendingReviewTopicsRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingReviewTopicsRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingReviewTopicsRequest message.
         * @function verify
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingReviewTopicsRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.limit != null && message.hasOwnProperty("limit"))
                if (!$util.isInteger(message.limit))
                    return "limit: integer expected";
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.api.TopicQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            return null;
        };

        /**
         * Creates a PendingReviewTopicsRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PendingReviewTopicsRequest} PendingReviewTopicsRequest
         */
        PendingReviewTopicsRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PendingReviewTopicsRequest)
                return object;
            let message = new $root.api.PendingReviewTopicsRequest();
            if (object.limit != null)
                message.limit = object.limit | 0;
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".api.PendingReviewTopicsRequest.query: object expected");
                message.query = $root.api.TopicQuery.fromObject(object.query);
            }
            return message;
        };

        /**
         * Creates a plain object from a PendingReviewTopicsRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PendingReviewTopicsRequest
         * @static
         * @param {api.PendingReviewTopicsRequest} message PendingReviewTopicsRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingReviewTopicsRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.limit = 0;
                object.query = null;
            }
            if (message.limit != null && message.hasOwnProperty("limit"))
                object.limit = message.limit;
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.api.TopicQuery.toObject(message.query, options);
            return object;
        };

        /**
         * Converts this PendingReviewTopicsRequest to JSON.
         * @function toJSON
         * @memberof api.PendingReviewTopicsRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingReviewTopicsRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingReviewTopicsRequest;
    })();

    api.SetupPaymentRequest = (function() {

        /**
         * Properties of a SetupPaymentRequest.
         * @memberof api
         * @interface ISetupPaymentRequest
         */

        /**
         * Constructs a new SetupPaymentRequest.
         * @memberof api
         * @classdesc Represents a SetupPaymentRequest.
         * @implements ISetupPaymentRequest
         * @constructor
         * @param {api.ISetupPaymentRequest=} [properties] Properties to set
         */
        function SetupPaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SetupPaymentRequest instance using the specified properties.
         * @function create
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {api.ISetupPaymentRequest=} [properties] Properties to set
         * @returns {api.SetupPaymentRequest} SetupPaymentRequest instance
         */
        SetupPaymentRequest.create = function create(properties) {
            return new SetupPaymentRequest(properties);
        };

        /**
         * Encodes the specified SetupPaymentRequest message. Does not implicitly {@link api.SetupPaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {api.ISetupPaymentRequest} message SetupPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupPaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SetupPaymentRequest message, length delimited. Does not implicitly {@link api.SetupPaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {api.ISetupPaymentRequest} message SetupPaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupPaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetupPaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SetupPaymentRequest} SetupPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupPaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SetupPaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetupPaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SetupPaymentRequest} SetupPaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupPaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetupPaymentRequest message.
         * @function verify
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetupPaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SetupPaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SetupPaymentRequest} SetupPaymentRequest
         */
        SetupPaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SetupPaymentRequest)
                return object;
            return new $root.api.SetupPaymentRequest();
        };

        /**
         * Creates a plain object from a SetupPaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SetupPaymentRequest
         * @static
         * @param {api.SetupPaymentRequest} message SetupPaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetupPaymentRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SetupPaymentRequest to JSON.
         * @function toJSON
         * @memberof api.SetupPaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetupPaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetupPaymentRequest;
    })();

    api.LoginResponse = (function() {

        /**
         * Properties of a LoginResponse.
         * @memberof api
         * @interface ILoginResponse
         * @property {string|null} [userId] LoginResponse userId
         * @property {string|null} [token] LoginResponse token
         * @property {number|Long|null} [expirationTime] LoginResponse expirationTime
         */

        /**
         * Constructs a new LoginResponse.
         * @memberof api
         * @classdesc Represents a LoginResponse.
         * @implements ILoginResponse
         * @constructor
         * @param {api.ILoginResponse=} [properties] Properties to set
         */
        function LoginResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LoginResponse userId.
         * @member {string} userId
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.userId = "";

        /**
         * LoginResponse token.
         * @member {string} token
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.token = "";

        /**
         * LoginResponse expirationTime.
         * @member {number|Long} expirationTime
         * @memberof api.LoginResponse
         * @instance
         */
        LoginResponse.prototype.expirationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new LoginResponse instance using the specified properties.
         * @function create
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse=} [properties] Properties to set
         * @returns {api.LoginResponse} LoginResponse instance
         */
        LoginResponse.create = function create(properties) {
            return new LoginResponse(properties);
        };

        /**
         * Encodes the specified LoginResponse message. Does not implicitly {@link api.LoginResponse.verify|verify} messages.
         * @function encode
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.expirationTime != null && Object.hasOwnProperty.call(message, "expirationTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expirationTime);
            return writer;
        };

        /**
         * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link api.LoginResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LoginResponse
         * @static
         * @param {api.ILoginResponse} message LoginResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LoginResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LoginResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.expirationTime = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LoginResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LoginResponse} LoginResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LoginResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LoginResponse message.
         * @function verify
         * @memberof api.LoginResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LoginResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (!$util.isInteger(message.expirationTime) && !(message.expirationTime && $util.isInteger(message.expirationTime.low) && $util.isInteger(message.expirationTime.high)))
                    return "expirationTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LoginResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LoginResponse} LoginResponse
         */
        LoginResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LoginResponse)
                return object;
            let message = new $root.api.LoginResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.expirationTime != null)
                if ($util.Long)
                    (message.expirationTime = $util.Long.fromValue(object.expirationTime)).unsigned = false;
                else if (typeof object.expirationTime === "string")
                    message.expirationTime = parseInt(object.expirationTime, 10);
                else if (typeof object.expirationTime === "number")
                    message.expirationTime = object.expirationTime;
                else if (typeof object.expirationTime === "object")
                    message.expirationTime = new $util.LongBits(object.expirationTime.low >>> 0, object.expirationTime.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LoginResponse
         * @static
         * @param {api.LoginResponse} message LoginResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LoginResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expirationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationTime = options.longs === String ? "0" : 0;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (typeof message.expirationTime === "number")
                    object.expirationTime = options.longs === String ? String(message.expirationTime) : message.expirationTime;
                else
                    object.expirationTime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationTime) : options.longs === Number ? new $util.LongBits(message.expirationTime.low >>> 0, message.expirationTime.high >>> 0).toNumber() : message.expirationTime;
            return object;
        };

        /**
         * Converts this LoginResponse to JSON.
         * @function toJSON
         * @memberof api.LoginResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LoginResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoginResponse;
    })();

    api.ResetPasswordResponse = (function() {

        /**
         * Properties of a ResetPasswordResponse.
         * @memberof api
         * @interface IResetPasswordResponse
         */

        /**
         * Constructs a new ResetPasswordResponse.
         * @memberof api
         * @classdesc Represents a ResetPasswordResponse.
         * @implements IResetPasswordResponse
         * @constructor
         * @param {api.IResetPasswordResponse=} [properties] Properties to set
         */
        function ResetPasswordResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResetPasswordResponse instance using the specified properties.
         * @function create
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.IResetPasswordResponse=} [properties] Properties to set
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse instance
         */
        ResetPasswordResponse.create = function create(properties) {
            return new ResetPasswordResponse(properties);
        };

        /**
         * Encodes the specified ResetPasswordResponse message. Does not implicitly {@link api.ResetPasswordResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.IResetPasswordResponse} message ResetPasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetPasswordResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResetPasswordResponse message, length delimited. Does not implicitly {@link api.ResetPasswordResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.IResetPasswordResponse} message ResetPasswordResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResetPasswordResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResetPasswordResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetPasswordResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ResetPasswordResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResetPasswordResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResetPasswordResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResetPasswordResponse message.
         * @function verify
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResetPasswordResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResetPasswordResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ResetPasswordResponse} ResetPasswordResponse
         */
        ResetPasswordResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ResetPasswordResponse)
                return object;
            return new $root.api.ResetPasswordResponse();
        };

        /**
         * Creates a plain object from a ResetPasswordResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ResetPasswordResponse
         * @static
         * @param {api.ResetPasswordResponse} message ResetPasswordResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResetPasswordResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResetPasswordResponse to JSON.
         * @function toJSON
         * @memberof api.ResetPasswordResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResetPasswordResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResetPasswordResponse;
    })();

    api.SendValidationCodeResponse = (function() {

        /**
         * Properties of a SendValidationCodeResponse.
         * @memberof api
         * @interface ISendValidationCodeResponse
         */

        /**
         * Constructs a new SendValidationCodeResponse.
         * @memberof api
         * @classdesc Represents a SendValidationCodeResponse.
         * @implements ISendValidationCodeResponse
         * @constructor
         * @param {api.ISendValidationCodeResponse=} [properties] Properties to set
         */
        function SendValidationCodeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SendValidationCodeResponse instance using the specified properties.
         * @function create
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.ISendValidationCodeResponse=} [properties] Properties to set
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse instance
         */
        SendValidationCodeResponse.create = function create(properties) {
            return new SendValidationCodeResponse(properties);
        };

        /**
         * Encodes the specified SendValidationCodeResponse message. Does not implicitly {@link api.SendValidationCodeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.ISendValidationCodeResponse} message SendValidationCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SendValidationCodeResponse message, length delimited. Does not implicitly {@link api.SendValidationCodeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.ISendValidationCodeResponse} message SendValidationCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendValidationCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendValidationCodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendValidationCodeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendValidationCodeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendValidationCodeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendValidationCodeResponse message.
         * @function verify
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendValidationCodeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SendValidationCodeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendValidationCodeResponse} SendValidationCodeResponse
         */
        SendValidationCodeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendValidationCodeResponse)
                return object;
            return new $root.api.SendValidationCodeResponse();
        };

        /**
         * Creates a plain object from a SendValidationCodeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendValidationCodeResponse
         * @static
         * @param {api.SendValidationCodeResponse} message SendValidationCodeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendValidationCodeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SendValidationCodeResponse to JSON.
         * @function toJSON
         * @memberof api.SendValidationCodeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendValidationCodeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendValidationCodeResponse;
    })();

    api.ValidateResponse = (function() {

        /**
         * Properties of a ValidateResponse.
         * @memberof api
         * @interface IValidateResponse
         * @property {string|null} [userId] ValidateResponse userId
         * @property {string|null} [token] ValidateResponse token
         * @property {number|Long|null} [expirationTime] ValidateResponse expirationTime
         */

        /**
         * Constructs a new ValidateResponse.
         * @memberof api
         * @classdesc Represents a ValidateResponse.
         * @implements IValidateResponse
         * @constructor
         * @param {api.IValidateResponse=} [properties] Properties to set
         */
        function ValidateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidateResponse userId.
         * @member {string} userId
         * @memberof api.ValidateResponse
         * @instance
         */
        ValidateResponse.prototype.userId = "";

        /**
         * ValidateResponse token.
         * @member {string} token
         * @memberof api.ValidateResponse
         * @instance
         */
        ValidateResponse.prototype.token = "";

        /**
         * ValidateResponse expirationTime.
         * @member {number|Long} expirationTime
         * @memberof api.ValidateResponse
         * @instance
         */
        ValidateResponse.prototype.expirationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ValidateResponse instance using the specified properties.
         * @function create
         * @memberof api.ValidateResponse
         * @static
         * @param {api.IValidateResponse=} [properties] Properties to set
         * @returns {api.ValidateResponse} ValidateResponse instance
         */
        ValidateResponse.create = function create(properties) {
            return new ValidateResponse(properties);
        };

        /**
         * Encodes the specified ValidateResponse message. Does not implicitly {@link api.ValidateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ValidateResponse
         * @static
         * @param {api.IValidateResponse} message ValidateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.expirationTime != null && Object.hasOwnProperty.call(message, "expirationTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expirationTime);
            return writer;
        };

        /**
         * Encodes the specified ValidateResponse message, length delimited. Does not implicitly {@link api.ValidateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ValidateResponse
         * @static
         * @param {api.IValidateResponse} message ValidateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ValidateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ValidateResponse} ValidateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ValidateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.expirationTime = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ValidateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ValidateResponse} ValidateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidateResponse message.
         * @function verify
         * @memberof api.ValidateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (!$util.isInteger(message.expirationTime) && !(message.expirationTime && $util.isInteger(message.expirationTime.low) && $util.isInteger(message.expirationTime.high)))
                    return "expirationTime: integer|Long expected";
            return null;
        };

        /**
         * Creates a ValidateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ValidateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ValidateResponse} ValidateResponse
         */
        ValidateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ValidateResponse)
                return object;
            let message = new $root.api.ValidateResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.expirationTime != null)
                if ($util.Long)
                    (message.expirationTime = $util.Long.fromValue(object.expirationTime)).unsigned = false;
                else if (typeof object.expirationTime === "string")
                    message.expirationTime = parseInt(object.expirationTime, 10);
                else if (typeof object.expirationTime === "number")
                    message.expirationTime = object.expirationTime;
                else if (typeof object.expirationTime === "object")
                    message.expirationTime = new $util.LongBits(object.expirationTime.low >>> 0, object.expirationTime.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a ValidateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ValidateResponse
         * @static
         * @param {api.ValidateResponse} message ValidateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expirationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationTime = options.longs === String ? "0" : 0;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (typeof message.expirationTime === "number")
                    object.expirationTime = options.longs === String ? String(message.expirationTime) : message.expirationTime;
                else
                    object.expirationTime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationTime) : options.longs === Number ? new $util.LongBits(message.expirationTime.low >>> 0, message.expirationTime.high >>> 0).toNumber() : message.expirationTime;
            return object;
        };

        /**
         * Converts this ValidateResponse to JSON.
         * @function toJSON
         * @memberof api.ValidateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ValidateResponse;
    })();

    api.AuthenticateResponse = (function() {

        /**
         * Properties of an AuthenticateResponse.
         * @memberof api
         * @interface IAuthenticateResponse
         * @property {string|null} [newToken] AuthenticateResponse newToken
         */

        /**
         * Constructs a new AuthenticateResponse.
         * @memberof api
         * @classdesc Represents an AuthenticateResponse.
         * @implements IAuthenticateResponse
         * @constructor
         * @param {api.IAuthenticateResponse=} [properties] Properties to set
         */
        function AuthenticateResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AuthenticateResponse newToken.
         * @member {string} newToken
         * @memberof api.AuthenticateResponse
         * @instance
         */
        AuthenticateResponse.prototype.newToken = "";

        /**
         * Creates a new AuthenticateResponse instance using the specified properties.
         * @function create
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.IAuthenticateResponse=} [properties] Properties to set
         * @returns {api.AuthenticateResponse} AuthenticateResponse instance
         */
        AuthenticateResponse.create = function create(properties) {
            return new AuthenticateResponse(properties);
        };

        /**
         * Encodes the specified AuthenticateResponse message. Does not implicitly {@link api.AuthenticateResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.IAuthenticateResponse} message AuthenticateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.newToken != null && Object.hasOwnProperty.call(message, "newToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.newToken);
            return writer;
        };

        /**
         * Encodes the specified AuthenticateResponse message, length delimited. Does not implicitly {@link api.AuthenticateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.IAuthenticateResponse} message AuthenticateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AuthenticateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AuthenticateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AuthenticateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AuthenticateResponse} AuthenticateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AuthenticateResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.newToken = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AuthenticateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AuthenticateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AuthenticateResponse} AuthenticateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AuthenticateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AuthenticateResponse message.
         * @function verify
         * @memberof api.AuthenticateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AuthenticateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.newToken != null && message.hasOwnProperty("newToken"))
                if (!$util.isString(message.newToken))
                    return "newToken: string expected";
            return null;
        };

        /**
         * Creates an AuthenticateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AuthenticateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AuthenticateResponse} AuthenticateResponse
         */
        AuthenticateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AuthenticateResponse)
                return object;
            let message = new $root.api.AuthenticateResponse();
            if (object.newToken != null)
                message.newToken = String(object.newToken);
            return message;
        };

        /**
         * Creates a plain object from an AuthenticateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AuthenticateResponse
         * @static
         * @param {api.AuthenticateResponse} message AuthenticateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AuthenticateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.newToken = "";
            if (message.newToken != null && message.hasOwnProperty("newToken"))
                object.newToken = message.newToken;
            return object;
        };

        /**
         * Converts this AuthenticateResponse to JSON.
         * @function toJSON
         * @memberof api.AuthenticateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AuthenticateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuthenticateResponse;
    })();

    api.CreateTopicResponse = (function() {

        /**
         * Properties of a CreateTopicResponse.
         * @memberof api
         * @interface ICreateTopicResponse
         * @property {string|null} [topicId] CreateTopicResponse topicId
         */

        /**
         * Constructs a new CreateTopicResponse.
         * @memberof api
         * @classdesc Represents a CreateTopicResponse.
         * @implements ICreateTopicResponse
         * @constructor
         * @param {api.ICreateTopicResponse=} [properties] Properties to set
         */
        function CreateTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateTopicResponse topicId.
         * @member {string} topicId
         * @memberof api.CreateTopicResponse
         * @instance
         */
        CreateTopicResponse.prototype.topicId = "";

        /**
         * Creates a new CreateTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateTopicResponse
         * @static
         * @param {api.ICreateTopicResponse=} [properties] Properties to set
         * @returns {api.CreateTopicResponse} CreateTopicResponse instance
         */
        CreateTopicResponse.create = function create(properties) {
            return new CreateTopicResponse(properties);
        };

        /**
         * Encodes the specified CreateTopicResponse message. Does not implicitly {@link api.CreateTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateTopicResponse
         * @static
         * @param {api.ICreateTopicResponse} message CreateTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.topicId);
            return writer;
        };

        /**
         * Encodes the specified CreateTopicResponse message, length delimited. Does not implicitly {@link api.CreateTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateTopicResponse
         * @static
         * @param {api.ICreateTopicResponse} message CreateTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateTopicResponse} CreateTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topicId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateTopicResponse} CreateTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateTopicResponse message.
         * @function verify
         * @memberof api.CreateTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            return null;
        };

        /**
         * Creates a CreateTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateTopicResponse} CreateTopicResponse
         */
        CreateTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateTopicResponse)
                return object;
            let message = new $root.api.CreateTopicResponse();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            return message;
        };

        /**
         * Creates a plain object from a CreateTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateTopicResponse
         * @static
         * @param {api.CreateTopicResponse} message CreateTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateTopicResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.topicId = "";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            return object;
        };

        /**
         * Converts this CreateTopicResponse to JSON.
         * @function toJSON
         * @memberof api.CreateTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateTopicResponse;
    })();

    api.UpdateTopicResponse = (function() {

        /**
         * Properties of an UpdateTopicResponse.
         * @memberof api
         * @interface IUpdateTopicResponse
         */

        /**
         * Constructs a new UpdateTopicResponse.
         * @memberof api
         * @classdesc Represents an UpdateTopicResponse.
         * @implements IUpdateTopicResponse
         * @constructor
         * @param {api.IUpdateTopicResponse=} [properties] Properties to set
         */
        function UpdateTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {api.IUpdateTopicResponse=} [properties] Properties to set
         * @returns {api.UpdateTopicResponse} UpdateTopicResponse instance
         */
        UpdateTopicResponse.create = function create(properties) {
            return new UpdateTopicResponse(properties);
        };

        /**
         * Encodes the specified UpdateTopicResponse message. Does not implicitly {@link api.UpdateTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {api.IUpdateTopicResponse} message UpdateTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateTopicResponse message, length delimited. Does not implicitly {@link api.UpdateTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {api.IUpdateTopicResponse} message UpdateTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateTopicResponse} UpdateTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateTopicResponse} UpdateTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateTopicResponse message.
         * @function verify
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateTopicResponse} UpdateTopicResponse
         */
        UpdateTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateTopicResponse)
                return object;
            return new $root.api.UpdateTopicResponse();
        };

        /**
         * Creates a plain object from an UpdateTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateTopicResponse
         * @static
         * @param {api.UpdateTopicResponse} message UpdateTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateTopicResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateTopicResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateTopicResponse;
    })();

    api.DeleteTopicResponse = (function() {

        /**
         * Properties of a DeleteTopicResponse.
         * @memberof api
         * @interface IDeleteTopicResponse
         */

        /**
         * Constructs a new DeleteTopicResponse.
         * @memberof api
         * @classdesc Represents a DeleteTopicResponse.
         * @implements IDeleteTopicResponse
         * @constructor
         * @param {api.IDeleteTopicResponse=} [properties] Properties to set
         */
        function DeleteTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {api.IDeleteTopicResponse=} [properties] Properties to set
         * @returns {api.DeleteTopicResponse} DeleteTopicResponse instance
         */
        DeleteTopicResponse.create = function create(properties) {
            return new DeleteTopicResponse(properties);
        };

        /**
         * Encodes the specified DeleteTopicResponse message. Does not implicitly {@link api.DeleteTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {api.IDeleteTopicResponse} message DeleteTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteTopicResponse message, length delimited. Does not implicitly {@link api.DeleteTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {api.IDeleteTopicResponse} message DeleteTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteTopicResponse} DeleteTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteTopicResponse} DeleteTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteTopicResponse message.
         * @function verify
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteTopicResponse} DeleteTopicResponse
         */
        DeleteTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteTopicResponse)
                return object;
            return new $root.api.DeleteTopicResponse();
        };

        /**
         * Creates a plain object from a DeleteTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteTopicResponse
         * @static
         * @param {api.DeleteTopicResponse} message DeleteTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteTopicResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteTopicResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteTopicResponse;
    })();

    api.DownloadCalendarResponse = (function() {

        /**
         * Properties of a DownloadCalendarResponse.
         * @memberof api
         * @interface IDownloadCalendarResponse
         * @property {Uint8Array|null} [calendarData] DownloadCalendarResponse calendarData
         */

        /**
         * Constructs a new DownloadCalendarResponse.
         * @memberof api
         * @classdesc Represents a DownloadCalendarResponse.
         * @implements IDownloadCalendarResponse
         * @constructor
         * @param {api.IDownloadCalendarResponse=} [properties] Properties to set
         */
        function DownloadCalendarResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DownloadCalendarResponse calendarData.
         * @member {Uint8Array} calendarData
         * @memberof api.DownloadCalendarResponse
         * @instance
         */
        DownloadCalendarResponse.prototype.calendarData = $util.newBuffer([]);

        /**
         * Creates a new DownloadCalendarResponse instance using the specified properties.
         * @function create
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {api.IDownloadCalendarResponse=} [properties] Properties to set
         * @returns {api.DownloadCalendarResponse} DownloadCalendarResponse instance
         */
        DownloadCalendarResponse.create = function create(properties) {
            return new DownloadCalendarResponse(properties);
        };

        /**
         * Encodes the specified DownloadCalendarResponse message. Does not implicitly {@link api.DownloadCalendarResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {api.IDownloadCalendarResponse} message DownloadCalendarResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DownloadCalendarResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.calendarData != null && Object.hasOwnProperty.call(message, "calendarData"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.calendarData);
            return writer;
        };

        /**
         * Encodes the specified DownloadCalendarResponse message, length delimited. Does not implicitly {@link api.DownloadCalendarResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {api.IDownloadCalendarResponse} message DownloadCalendarResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DownloadCalendarResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DownloadCalendarResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DownloadCalendarResponse} DownloadCalendarResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DownloadCalendarResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DownloadCalendarResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.calendarData = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DownloadCalendarResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DownloadCalendarResponse} DownloadCalendarResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DownloadCalendarResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DownloadCalendarResponse message.
         * @function verify
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DownloadCalendarResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.calendarData != null && message.hasOwnProperty("calendarData"))
                if (!(message.calendarData && typeof message.calendarData.length === "number" || $util.isString(message.calendarData)))
                    return "calendarData: buffer expected";
            return null;
        };

        /**
         * Creates a DownloadCalendarResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DownloadCalendarResponse} DownloadCalendarResponse
         */
        DownloadCalendarResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DownloadCalendarResponse)
                return object;
            let message = new $root.api.DownloadCalendarResponse();
            if (object.calendarData != null)
                if (typeof object.calendarData === "string")
                    $util.base64.decode(object.calendarData, message.calendarData = $util.newBuffer($util.base64.length(object.calendarData)), 0);
                else if (object.calendarData.length)
                    message.calendarData = object.calendarData;
            return message;
        };

        /**
         * Creates a plain object from a DownloadCalendarResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DownloadCalendarResponse
         * @static
         * @param {api.DownloadCalendarResponse} message DownloadCalendarResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DownloadCalendarResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.calendarData = "";
                else {
                    object.calendarData = [];
                    if (options.bytes !== Array)
                        object.calendarData = $util.newBuffer(object.calendarData);
                }
            if (message.calendarData != null && message.hasOwnProperty("calendarData"))
                object.calendarData = options.bytes === String ? $util.base64.encode(message.calendarData, 0, message.calendarData.length) : options.bytes === Array ? Array.prototype.slice.call(message.calendarData) : message.calendarData;
            return object;
        };

        /**
         * Converts this DownloadCalendarResponse to JSON.
         * @function toJSON
         * @memberof api.DownloadCalendarResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DownloadCalendarResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DownloadCalendarResponse;
    })();

    api.JoinTopicResponse = (function() {

        /**
         * Properties of a JoinTopicResponse.
         * @memberof api
         * @interface IJoinTopicResponse
         * @property {api.ICostDetails|null} [costDetails] JoinTopicResponse costDetails
         */

        /**
         * Constructs a new JoinTopicResponse.
         * @memberof api
         * @classdesc Represents a JoinTopicResponse.
         * @implements IJoinTopicResponse
         * @constructor
         * @param {api.IJoinTopicResponse=} [properties] Properties to set
         */
        function JoinTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * JoinTopicResponse costDetails.
         * @member {api.ICostDetails|null|undefined} costDetails
         * @memberof api.JoinTopicResponse
         * @instance
         */
        JoinTopicResponse.prototype.costDetails = null;

        /**
         * Creates a new JoinTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.JoinTopicResponse
         * @static
         * @param {api.IJoinTopicResponse=} [properties] Properties to set
         * @returns {api.JoinTopicResponse} JoinTopicResponse instance
         */
        JoinTopicResponse.create = function create(properties) {
            return new JoinTopicResponse(properties);
        };

        /**
         * Encodes the specified JoinTopicResponse message. Does not implicitly {@link api.JoinTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.JoinTopicResponse
         * @static
         * @param {api.IJoinTopicResponse} message JoinTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JoinTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.costDetails != null && Object.hasOwnProperty.call(message, "costDetails"))
                $root.api.CostDetails.encode(message.costDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified JoinTopicResponse message, length delimited. Does not implicitly {@link api.JoinTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.JoinTopicResponse
         * @static
         * @param {api.IJoinTopicResponse} message JoinTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        JoinTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a JoinTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.JoinTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.JoinTopicResponse} JoinTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JoinTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.JoinTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.costDetails = $root.api.CostDetails.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a JoinTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.JoinTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.JoinTopicResponse} JoinTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        JoinTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a JoinTopicResponse message.
         * @function verify
         * @memberof api.JoinTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        JoinTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.costDetails != null && message.hasOwnProperty("costDetails")) {
                let error = $root.api.CostDetails.verify(message.costDetails);
                if (error)
                    return "costDetails." + error;
            }
            return null;
        };

        /**
         * Creates a JoinTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.JoinTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.JoinTopicResponse} JoinTopicResponse
         */
        JoinTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.JoinTopicResponse)
                return object;
            let message = new $root.api.JoinTopicResponse();
            if (object.costDetails != null) {
                if (typeof object.costDetails !== "object")
                    throw TypeError(".api.JoinTopicResponse.costDetails: object expected");
                message.costDetails = $root.api.CostDetails.fromObject(object.costDetails);
            }
            return message;
        };

        /**
         * Creates a plain object from a JoinTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.JoinTopicResponse
         * @static
         * @param {api.JoinTopicResponse} message JoinTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        JoinTopicResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.costDetails = null;
            if (message.costDetails != null && message.hasOwnProperty("costDetails"))
                object.costDetails = $root.api.CostDetails.toObject(message.costDetails, options);
            return object;
        };

        /**
         * Converts this JoinTopicResponse to JSON.
         * @function toJSON
         * @memberof api.JoinTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        JoinTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return JoinTopicResponse;
    })();

    api.UpdateServiceSubscriptionResponse = (function() {

        /**
         * Properties of an UpdateServiceSubscriptionResponse.
         * @memberof api
         * @interface IUpdateServiceSubscriptionResponse
         * @property {api.ICostDetails|null} [costDetails] UpdateServiceSubscriptionResponse costDetails
         */

        /**
         * Constructs a new UpdateServiceSubscriptionResponse.
         * @memberof api
         * @classdesc Represents an UpdateServiceSubscriptionResponse.
         * @implements IUpdateServiceSubscriptionResponse
         * @constructor
         * @param {api.IUpdateServiceSubscriptionResponse=} [properties] Properties to set
         */
        function UpdateServiceSubscriptionResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdateServiceSubscriptionResponse costDetails.
         * @member {api.ICostDetails|null|undefined} costDetails
         * @memberof api.UpdateServiceSubscriptionResponse
         * @instance
         */
        UpdateServiceSubscriptionResponse.prototype.costDetails = null;

        /**
         * Creates a new UpdateServiceSubscriptionResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {api.IUpdateServiceSubscriptionResponse=} [properties] Properties to set
         * @returns {api.UpdateServiceSubscriptionResponse} UpdateServiceSubscriptionResponse instance
         */
        UpdateServiceSubscriptionResponse.create = function create(properties) {
            return new UpdateServiceSubscriptionResponse(properties);
        };

        /**
         * Encodes the specified UpdateServiceSubscriptionResponse message. Does not implicitly {@link api.UpdateServiceSubscriptionResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {api.IUpdateServiceSubscriptionResponse} message UpdateServiceSubscriptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateServiceSubscriptionResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.costDetails != null && Object.hasOwnProperty.call(message, "costDetails"))
                $root.api.CostDetails.encode(message.costDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UpdateServiceSubscriptionResponse message, length delimited. Does not implicitly {@link api.UpdateServiceSubscriptionResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {api.IUpdateServiceSubscriptionResponse} message UpdateServiceSubscriptionResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateServiceSubscriptionResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateServiceSubscriptionResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateServiceSubscriptionResponse} UpdateServiceSubscriptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateServiceSubscriptionResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateServiceSubscriptionResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.costDetails = $root.api.CostDetails.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateServiceSubscriptionResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateServiceSubscriptionResponse} UpdateServiceSubscriptionResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateServiceSubscriptionResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateServiceSubscriptionResponse message.
         * @function verify
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateServiceSubscriptionResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.costDetails != null && message.hasOwnProperty("costDetails")) {
                let error = $root.api.CostDetails.verify(message.costDetails);
                if (error)
                    return "costDetails." + error;
            }
            return null;
        };

        /**
         * Creates an UpdateServiceSubscriptionResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateServiceSubscriptionResponse} UpdateServiceSubscriptionResponse
         */
        UpdateServiceSubscriptionResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateServiceSubscriptionResponse)
                return object;
            let message = new $root.api.UpdateServiceSubscriptionResponse();
            if (object.costDetails != null) {
                if (typeof object.costDetails !== "object")
                    throw TypeError(".api.UpdateServiceSubscriptionResponse.costDetails: object expected");
                message.costDetails = $root.api.CostDetails.fromObject(object.costDetails);
            }
            return message;
        };

        /**
         * Creates a plain object from an UpdateServiceSubscriptionResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateServiceSubscriptionResponse
         * @static
         * @param {api.UpdateServiceSubscriptionResponse} message UpdateServiceSubscriptionResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateServiceSubscriptionResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.costDetails = null;
            if (message.costDetails != null && message.hasOwnProperty("costDetails"))
                object.costDetails = $root.api.CostDetails.toObject(message.costDetails, options);
            return object;
        };

        /**
         * Converts this UpdateServiceSubscriptionResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateServiceSubscriptionResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateServiceSubscriptionResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateServiceSubscriptionResponse;
    })();

    api.UpdatePaymentResponse = (function() {

        /**
         * Properties of an UpdatePaymentResponse.
         * @memberof api
         * @interface IUpdatePaymentResponse
         * @property {string|null} [paymentClientSecret] UpdatePaymentResponse paymentClientSecret
         * @property {string|null} [publishableKey] UpdatePaymentResponse publishableKey
         * @property {boolean|null} [hasExistingPayment] UpdatePaymentResponse hasExistingPayment
         * @property {string|null} [existingPaymentDescription] UpdatePaymentResponse existingPaymentDescription
         */

        /**
         * Constructs a new UpdatePaymentResponse.
         * @memberof api
         * @classdesc Represents an UpdatePaymentResponse.
         * @implements IUpdatePaymentResponse
         * @constructor
         * @param {api.IUpdatePaymentResponse=} [properties] Properties to set
         */
        function UpdatePaymentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UpdatePaymentResponse paymentClientSecret.
         * @member {string} paymentClientSecret
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.paymentClientSecret = "";

        /**
         * UpdatePaymentResponse publishableKey.
         * @member {string} publishableKey
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.publishableKey = "";

        /**
         * UpdatePaymentResponse hasExistingPayment.
         * @member {boolean} hasExistingPayment
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.hasExistingPayment = false;

        /**
         * UpdatePaymentResponse existingPaymentDescription.
         * @member {string} existingPaymentDescription
         * @memberof api.UpdatePaymentResponse
         * @instance
         */
        UpdatePaymentResponse.prototype.existingPaymentDescription = "";

        /**
         * Creates a new UpdatePaymentResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.IUpdatePaymentResponse=} [properties] Properties to set
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse instance
         */
        UpdatePaymentResponse.create = function create(properties) {
            return new UpdatePaymentResponse(properties);
        };

        /**
         * Encodes the specified UpdatePaymentResponse message. Does not implicitly {@link api.UpdatePaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.IUpdatePaymentResponse} message UpdatePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.paymentClientSecret != null && Object.hasOwnProperty.call(message, "paymentClientSecret"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.paymentClientSecret);
            if (message.publishableKey != null && Object.hasOwnProperty.call(message, "publishableKey"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.publishableKey);
            if (message.hasExistingPayment != null && Object.hasOwnProperty.call(message, "hasExistingPayment"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.hasExistingPayment);
            if (message.existingPaymentDescription != null && Object.hasOwnProperty.call(message, "existingPaymentDescription"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.existingPaymentDescription);
            return writer;
        };

        /**
         * Encodes the specified UpdatePaymentResponse message, length delimited. Does not implicitly {@link api.UpdatePaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.IUpdatePaymentResponse} message UpdatePaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdatePaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdatePaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.paymentClientSecret = reader.string();
                    break;
                case 3:
                    message.publishableKey = reader.string();
                    break;
                case 4:
                    message.hasExistingPayment = reader.bool();
                    break;
                case 5:
                    message.existingPaymentDescription = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdatePaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdatePaymentResponse message.
         * @function verify
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdatePaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.paymentClientSecret != null && message.hasOwnProperty("paymentClientSecret"))
                if (!$util.isString(message.paymentClientSecret))
                    return "paymentClientSecret: string expected";
            if (message.publishableKey != null && message.hasOwnProperty("publishableKey"))
                if (!$util.isString(message.publishableKey))
                    return "publishableKey: string expected";
            if (message.hasExistingPayment != null && message.hasOwnProperty("hasExistingPayment"))
                if (typeof message.hasExistingPayment !== "boolean")
                    return "hasExistingPayment: boolean expected";
            if (message.existingPaymentDescription != null && message.hasOwnProperty("existingPaymentDescription"))
                if (!$util.isString(message.existingPaymentDescription))
                    return "existingPaymentDescription: string expected";
            return null;
        };

        /**
         * Creates an UpdatePaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdatePaymentResponse} UpdatePaymentResponse
         */
        UpdatePaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdatePaymentResponse)
                return object;
            let message = new $root.api.UpdatePaymentResponse();
            if (object.paymentClientSecret != null)
                message.paymentClientSecret = String(object.paymentClientSecret);
            if (object.publishableKey != null)
                message.publishableKey = String(object.publishableKey);
            if (object.hasExistingPayment != null)
                message.hasExistingPayment = Boolean(object.hasExistingPayment);
            if (object.existingPaymentDescription != null)
                message.existingPaymentDescription = String(object.existingPaymentDescription);
            return message;
        };

        /**
         * Creates a plain object from an UpdatePaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdatePaymentResponse
         * @static
         * @param {api.UpdatePaymentResponse} message UpdatePaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdatePaymentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.paymentClientSecret = "";
                object.publishableKey = "";
                object.hasExistingPayment = false;
                object.existingPaymentDescription = "";
            }
            if (message.paymentClientSecret != null && message.hasOwnProperty("paymentClientSecret"))
                object.paymentClientSecret = message.paymentClientSecret;
            if (message.publishableKey != null && message.hasOwnProperty("publishableKey"))
                object.publishableKey = message.publishableKey;
            if (message.hasExistingPayment != null && message.hasOwnProperty("hasExistingPayment"))
                object.hasExistingPayment = message.hasExistingPayment;
            if (message.existingPaymentDescription != null && message.hasOwnProperty("existingPaymentDescription"))
                object.existingPaymentDescription = message.existingPaymentDescription;
            return object;
        };

        /**
         * Converts this UpdatePaymentResponse to JSON.
         * @function toJSON
         * @memberof api.UpdatePaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdatePaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdatePaymentResponse;
    })();

    api.LeaveTopicResponse = (function() {

        /**
         * Properties of a LeaveTopicResponse.
         * @memberof api
         * @interface ILeaveTopicResponse
         */

        /**
         * Constructs a new LeaveTopicResponse.
         * @memberof api
         * @classdesc Represents a LeaveTopicResponse.
         * @implements ILeaveTopicResponse
         * @constructor
         * @param {api.ILeaveTopicResponse=} [properties] Properties to set
         */
        function LeaveTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new LeaveTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {api.ILeaveTopicResponse=} [properties] Properties to set
         * @returns {api.LeaveTopicResponse} LeaveTopicResponse instance
         */
        LeaveTopicResponse.create = function create(properties) {
            return new LeaveTopicResponse(properties);
        };

        /**
         * Encodes the specified LeaveTopicResponse message. Does not implicitly {@link api.LeaveTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {api.ILeaveTopicResponse} message LeaveTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaveTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified LeaveTopicResponse message, length delimited. Does not implicitly {@link api.LeaveTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {api.ILeaveTopicResponse} message LeaveTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LeaveTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LeaveTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LeaveTopicResponse} LeaveTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaveTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LeaveTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LeaveTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LeaveTopicResponse} LeaveTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LeaveTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LeaveTopicResponse message.
         * @function verify
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LeaveTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a LeaveTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LeaveTopicResponse} LeaveTopicResponse
         */
        LeaveTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LeaveTopicResponse)
                return object;
            return new $root.api.LeaveTopicResponse();
        };

        /**
         * Creates a plain object from a LeaveTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LeaveTopicResponse
         * @static
         * @param {api.LeaveTopicResponse} message LeaveTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LeaveTopicResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this LeaveTopicResponse to JSON.
         * @function toJSON
         * @memberof api.LeaveTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LeaveTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LeaveTopicResponse;
    })();

    api.WaitlistTopicResponse = (function() {

        /**
         * Properties of a WaitlistTopicResponse.
         * @memberof api
         * @interface IWaitlistTopicResponse
         */

        /**
         * Constructs a new WaitlistTopicResponse.
         * @memberof api
         * @classdesc Represents a WaitlistTopicResponse.
         * @implements IWaitlistTopicResponse
         * @constructor
         * @param {api.IWaitlistTopicResponse=} [properties] Properties to set
         */
        function WaitlistTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new WaitlistTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {api.IWaitlistTopicResponse=} [properties] Properties to set
         * @returns {api.WaitlistTopicResponse} WaitlistTopicResponse instance
         */
        WaitlistTopicResponse.create = function create(properties) {
            return new WaitlistTopicResponse(properties);
        };

        /**
         * Encodes the specified WaitlistTopicResponse message. Does not implicitly {@link api.WaitlistTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {api.IWaitlistTopicResponse} message WaitlistTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WaitlistTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified WaitlistTopicResponse message, length delimited. Does not implicitly {@link api.WaitlistTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {api.IWaitlistTopicResponse} message WaitlistTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WaitlistTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WaitlistTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WaitlistTopicResponse} WaitlistTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WaitlistTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WaitlistTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WaitlistTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WaitlistTopicResponse} WaitlistTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WaitlistTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WaitlistTopicResponse message.
         * @function verify
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WaitlistTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a WaitlistTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WaitlistTopicResponse} WaitlistTopicResponse
         */
        WaitlistTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WaitlistTopicResponse)
                return object;
            return new $root.api.WaitlistTopicResponse();
        };

        /**
         * Creates a plain object from a WaitlistTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WaitlistTopicResponse
         * @static
         * @param {api.WaitlistTopicResponse} message WaitlistTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WaitlistTopicResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this WaitlistTopicResponse to JSON.
         * @function toJSON
         * @memberof api.WaitlistTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WaitlistTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WaitlistTopicResponse;
    })();

    api.CreateRoomResponse = (function() {

        /**
         * Properties of a CreateRoomResponse.
         * @memberof api
         * @interface ICreateRoomResponse
         * @property {string|null} [roomId] CreateRoomResponse roomId
         */

        /**
         * Constructs a new CreateRoomResponse.
         * @memberof api
         * @classdesc Represents a CreateRoomResponse.
         * @implements ICreateRoomResponse
         * @constructor
         * @param {api.ICreateRoomResponse=} [properties] Properties to set
         */
        function CreateRoomResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateRoomResponse roomId.
         * @member {string} roomId
         * @memberof api.CreateRoomResponse
         * @instance
         */
        CreateRoomResponse.prototype.roomId = "";

        /**
         * Creates a new CreateRoomResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateRoomResponse
         * @static
         * @param {api.ICreateRoomResponse=} [properties] Properties to set
         * @returns {api.CreateRoomResponse} CreateRoomResponse instance
         */
        CreateRoomResponse.create = function create(properties) {
            return new CreateRoomResponse(properties);
        };

        /**
         * Encodes the specified CreateRoomResponse message. Does not implicitly {@link api.CreateRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateRoomResponse
         * @static
         * @param {api.ICreateRoomResponse} message CreateRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            return writer;
        };

        /**
         * Encodes the specified CreateRoomResponse message, length delimited. Does not implicitly {@link api.CreateRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateRoomResponse
         * @static
         * @param {api.ICreateRoomResponse} message CreateRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateRoomResponse} CreateRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateRoomResponse} CreateRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateRoomResponse message.
         * @function verify
         * @memberof api.CreateRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            return null;
        };

        /**
         * Creates a CreateRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateRoomResponse} CreateRoomResponse
         */
        CreateRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateRoomResponse)
                return object;
            let message = new $root.api.CreateRoomResponse();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            return message;
        };

        /**
         * Creates a plain object from a CreateRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateRoomResponse
         * @static
         * @param {api.CreateRoomResponse} message CreateRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateRoomResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.roomId = "";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            return object;
        };

        /**
         * Converts this CreateRoomResponse to JSON.
         * @function toJSON
         * @memberof api.CreateRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateRoomResponse;
    })();

    api.DeleteRoomResponse = (function() {

        /**
         * Properties of a DeleteRoomResponse.
         * @memberof api
         * @interface IDeleteRoomResponse
         */

        /**
         * Constructs a new DeleteRoomResponse.
         * @memberof api
         * @classdesc Represents a DeleteRoomResponse.
         * @implements IDeleteRoomResponse
         * @constructor
         * @param {api.IDeleteRoomResponse=} [properties] Properties to set
         */
        function DeleteRoomResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteRoomResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {api.IDeleteRoomResponse=} [properties] Properties to set
         * @returns {api.DeleteRoomResponse} DeleteRoomResponse instance
         */
        DeleteRoomResponse.create = function create(properties) {
            return new DeleteRoomResponse(properties);
        };

        /**
         * Encodes the specified DeleteRoomResponse message. Does not implicitly {@link api.DeleteRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {api.IDeleteRoomResponse} message DeleteRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteRoomResponse message, length delimited. Does not implicitly {@link api.DeleteRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {api.IDeleteRoomResponse} message DeleteRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteRoomResponse} DeleteRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteRoomResponse} DeleteRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteRoomResponse message.
         * @function verify
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteRoomResponse} DeleteRoomResponse
         */
        DeleteRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteRoomResponse)
                return object;
            return new $root.api.DeleteRoomResponse();
        };

        /**
         * Creates a plain object from a DeleteRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteRoomResponse
         * @static
         * @param {api.DeleteRoomResponse} message DeleteRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteRoomResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteRoomResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteRoomResponse;
    })();

    api.UpdateRoomResponse = (function() {

        /**
         * Properties of an UpdateRoomResponse.
         * @memberof api
         * @interface IUpdateRoomResponse
         */

        /**
         * Constructs a new UpdateRoomResponse.
         * @memberof api
         * @classdesc Represents an UpdateRoomResponse.
         * @implements IUpdateRoomResponse
         * @constructor
         * @param {api.IUpdateRoomResponse=} [properties] Properties to set
         */
        function UpdateRoomResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateRoomResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {api.IUpdateRoomResponse=} [properties] Properties to set
         * @returns {api.UpdateRoomResponse} UpdateRoomResponse instance
         */
        UpdateRoomResponse.create = function create(properties) {
            return new UpdateRoomResponse(properties);
        };

        /**
         * Encodes the specified UpdateRoomResponse message. Does not implicitly {@link api.UpdateRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {api.IUpdateRoomResponse} message UpdateRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateRoomResponse message, length delimited. Does not implicitly {@link api.UpdateRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {api.IUpdateRoomResponse} message UpdateRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateRoomResponse} UpdateRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateRoomResponse} UpdateRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateRoomResponse message.
         * @function verify
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateRoomResponse} UpdateRoomResponse
         */
        UpdateRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateRoomResponse)
                return object;
            return new $root.api.UpdateRoomResponse();
        };

        /**
         * Creates a plain object from an UpdateRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateRoomResponse
         * @static
         * @param {api.UpdateRoomResponse} message UpdateRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateRoomResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateRoomResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateRoomResponse;
    })();

    api.AddTopicReviewResponse = (function() {

        /**
         * Properties of an AddTopicReviewResponse.
         * @memberof api
         * @interface IAddTopicReviewResponse
         */

        /**
         * Constructs a new AddTopicReviewResponse.
         * @memberof api
         * @classdesc Represents an AddTopicReviewResponse.
         * @implements IAddTopicReviewResponse
         * @constructor
         * @param {api.IAddTopicReviewResponse=} [properties] Properties to set
         */
        function AddTopicReviewResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AddTopicReviewResponse instance using the specified properties.
         * @function create
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {api.IAddTopicReviewResponse=} [properties] Properties to set
         * @returns {api.AddTopicReviewResponse} AddTopicReviewResponse instance
         */
        AddTopicReviewResponse.create = function create(properties) {
            return new AddTopicReviewResponse(properties);
        };

        /**
         * Encodes the specified AddTopicReviewResponse message. Does not implicitly {@link api.AddTopicReviewResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {api.IAddTopicReviewResponse} message AddTopicReviewResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTopicReviewResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AddTopicReviewResponse message, length delimited. Does not implicitly {@link api.AddTopicReviewResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {api.IAddTopicReviewResponse} message AddTopicReviewResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTopicReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddTopicReviewResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddTopicReviewResponse} AddTopicReviewResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTopicReviewResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddTopicReviewResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddTopicReviewResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddTopicReviewResponse} AddTopicReviewResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTopicReviewResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddTopicReviewResponse message.
         * @function verify
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddTopicReviewResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AddTopicReviewResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddTopicReviewResponse} AddTopicReviewResponse
         */
        AddTopicReviewResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddTopicReviewResponse)
                return object;
            return new $root.api.AddTopicReviewResponse();
        };

        /**
         * Creates a plain object from an AddTopicReviewResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddTopicReviewResponse
         * @static
         * @param {api.AddTopicReviewResponse} message AddTopicReviewResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddTopicReviewResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AddTopicReviewResponse to JSON.
         * @function toJSON
         * @memberof api.AddTopicReviewResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddTopicReviewResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTopicReviewResponse;
    })();

    api.UpdateTopicReviewResponse = (function() {

        /**
         * Properties of an UpdateTopicReviewResponse.
         * @memberof api
         * @interface IUpdateTopicReviewResponse
         */

        /**
         * Constructs a new UpdateTopicReviewResponse.
         * @memberof api
         * @classdesc Represents an UpdateTopicReviewResponse.
         * @implements IUpdateTopicReviewResponse
         * @constructor
         * @param {api.IUpdateTopicReviewResponse=} [properties] Properties to set
         */
        function UpdateTopicReviewResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateTopicReviewResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {api.IUpdateTopicReviewResponse=} [properties] Properties to set
         * @returns {api.UpdateTopicReviewResponse} UpdateTopicReviewResponse instance
         */
        UpdateTopicReviewResponse.create = function create(properties) {
            return new UpdateTopicReviewResponse(properties);
        };

        /**
         * Encodes the specified UpdateTopicReviewResponse message. Does not implicitly {@link api.UpdateTopicReviewResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {api.IUpdateTopicReviewResponse} message UpdateTopicReviewResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicReviewResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateTopicReviewResponse message, length delimited. Does not implicitly {@link api.UpdateTopicReviewResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {api.IUpdateTopicReviewResponse} message UpdateTopicReviewResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTopicReviewResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateTopicReviewResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateTopicReviewResponse} UpdateTopicReviewResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicReviewResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateTopicReviewResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateTopicReviewResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateTopicReviewResponse} UpdateTopicReviewResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTopicReviewResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateTopicReviewResponse message.
         * @function verify
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateTopicReviewResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateTopicReviewResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateTopicReviewResponse} UpdateTopicReviewResponse
         */
        UpdateTopicReviewResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateTopicReviewResponse)
                return object;
            return new $root.api.UpdateTopicReviewResponse();
        };

        /**
         * Creates a plain object from an UpdateTopicReviewResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateTopicReviewResponse
         * @static
         * @param {api.UpdateTopicReviewResponse} message UpdateTopicReviewResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateTopicReviewResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateTopicReviewResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateTopicReviewResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateTopicReviewResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateTopicReviewResponse;
    })();

    api.AddMemberResponse = (function() {

        /**
         * Properties of an AddMemberResponse.
         * @memberof api
         * @interface IAddMemberResponse
         */

        /**
         * Constructs a new AddMemberResponse.
         * @memberof api
         * @classdesc Represents an AddMemberResponse.
         * @implements IAddMemberResponse
         * @constructor
         * @param {api.IAddMemberResponse=} [properties] Properties to set
         */
        function AddMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AddMemberResponse instance using the specified properties.
         * @function create
         * @memberof api.AddMemberResponse
         * @static
         * @param {api.IAddMemberResponse=} [properties] Properties to set
         * @returns {api.AddMemberResponse} AddMemberResponse instance
         */
        AddMemberResponse.create = function create(properties) {
            return new AddMemberResponse(properties);
        };

        /**
         * Encodes the specified AddMemberResponse message. Does not implicitly {@link api.AddMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddMemberResponse
         * @static
         * @param {api.IAddMemberResponse} message AddMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AddMemberResponse message, length delimited. Does not implicitly {@link api.AddMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddMemberResponse
         * @static
         * @param {api.IAddMemberResponse} message AddMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddMemberResponse} AddMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddMemberResponse} AddMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddMemberResponse message.
         * @function verify
         * @memberof api.AddMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AddMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddMemberResponse} AddMemberResponse
         */
        AddMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddMemberResponse)
                return object;
            return new $root.api.AddMemberResponse();
        };

        /**
         * Creates a plain object from an AddMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddMemberResponse
         * @static
         * @param {api.AddMemberResponse} message AddMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddMemberResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AddMemberResponse to JSON.
         * @function toJSON
         * @memberof api.AddMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddMemberResponse;
    })();

    api.UpdateMemberResponse = (function() {

        /**
         * Properties of an UpdateMemberResponse.
         * @memberof api
         * @interface IUpdateMemberResponse
         */

        /**
         * Constructs a new UpdateMemberResponse.
         * @memberof api
         * @classdesc Represents an UpdateMemberResponse.
         * @implements IUpdateMemberResponse
         * @constructor
         * @param {api.IUpdateMemberResponse=} [properties] Properties to set
         */
        function UpdateMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateMemberResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {api.IUpdateMemberResponse=} [properties] Properties to set
         * @returns {api.UpdateMemberResponse} UpdateMemberResponse instance
         */
        UpdateMemberResponse.create = function create(properties) {
            return new UpdateMemberResponse(properties);
        };

        /**
         * Encodes the specified UpdateMemberResponse message. Does not implicitly {@link api.UpdateMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {api.IUpdateMemberResponse} message UpdateMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateMemberResponse message, length delimited. Does not implicitly {@link api.UpdateMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {api.IUpdateMemberResponse} message UpdateMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateMemberResponse} UpdateMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateMemberResponse} UpdateMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateMemberResponse message.
         * @function verify
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateMemberResponse} UpdateMemberResponse
         */
        UpdateMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateMemberResponse)
                return object;
            return new $root.api.UpdateMemberResponse();
        };

        /**
         * Creates a plain object from an UpdateMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateMemberResponse
         * @static
         * @param {api.UpdateMemberResponse} message UpdateMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateMemberResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateMemberResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateMemberResponse;
    })();

    api.DeleteMemberResponse = (function() {

        /**
         * Properties of a DeleteMemberResponse.
         * @memberof api
         * @interface IDeleteMemberResponse
         */

        /**
         * Constructs a new DeleteMemberResponse.
         * @memberof api
         * @classdesc Represents a DeleteMemberResponse.
         * @implements IDeleteMemberResponse
         * @constructor
         * @param {api.IDeleteMemberResponse=} [properties] Properties to set
         */
        function DeleteMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteMemberResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {api.IDeleteMemberResponse=} [properties] Properties to set
         * @returns {api.DeleteMemberResponse} DeleteMemberResponse instance
         */
        DeleteMemberResponse.create = function create(properties) {
            return new DeleteMemberResponse(properties);
        };

        /**
         * Encodes the specified DeleteMemberResponse message. Does not implicitly {@link api.DeleteMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {api.IDeleteMemberResponse} message DeleteMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteMemberResponse message, length delimited. Does not implicitly {@link api.DeleteMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {api.IDeleteMemberResponse} message DeleteMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteMemberResponse} DeleteMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteMemberResponse} DeleteMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteMemberResponse message.
         * @function verify
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteMemberResponse} DeleteMemberResponse
         */
        DeleteMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteMemberResponse)
                return object;
            return new $root.api.DeleteMemberResponse();
        };

        /**
         * Creates a plain object from a DeleteMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteMemberResponse
         * @static
         * @param {api.DeleteMemberResponse} message DeleteMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMemberResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteMemberResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteMemberResponse;
    })();

    api.DeleteMembersResponse = (function() {

        /**
         * Properties of a DeleteMembersResponse.
         * @memberof api
         * @interface IDeleteMembersResponse
         */

        /**
         * Constructs a new DeleteMembersResponse.
         * @memberof api
         * @classdesc Represents a DeleteMembersResponse.
         * @implements IDeleteMembersResponse
         * @constructor
         * @param {api.IDeleteMembersResponse=} [properties] Properties to set
         */
        function DeleteMembersResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteMembersResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {api.IDeleteMembersResponse=} [properties] Properties to set
         * @returns {api.DeleteMembersResponse} DeleteMembersResponse instance
         */
        DeleteMembersResponse.create = function create(properties) {
            return new DeleteMembersResponse(properties);
        };

        /**
         * Encodes the specified DeleteMembersResponse message. Does not implicitly {@link api.DeleteMembersResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {api.IDeleteMembersResponse} message DeleteMembersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMembersResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteMembersResponse message, length delimited. Does not implicitly {@link api.DeleteMembersResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {api.IDeleteMembersResponse} message DeleteMembersResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteMembersResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteMembersResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteMembersResponse} DeleteMembersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMembersResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteMembersResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteMembersResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteMembersResponse} DeleteMembersResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteMembersResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteMembersResponse message.
         * @function verify
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteMembersResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteMembersResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteMembersResponse} DeleteMembersResponse
         */
        DeleteMembersResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteMembersResponse)
                return object;
            return new $root.api.DeleteMembersResponse();
        };

        /**
         * Creates a plain object from a DeleteMembersResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteMembersResponse
         * @static
         * @param {api.DeleteMembersResponse} message DeleteMembersResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteMembersResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteMembersResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteMembersResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteMembersResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteMembersResponse;
    })();

    api.AnswerMemberResponse = (function() {

        /**
         * Properties of an AnswerMemberResponse.
         * @memberof api
         * @interface IAnswerMemberResponse
         */

        /**
         * Constructs a new AnswerMemberResponse.
         * @memberof api
         * @classdesc Represents an AnswerMemberResponse.
         * @implements IAnswerMemberResponse
         * @constructor
         * @param {api.IAnswerMemberResponse=} [properties] Properties to set
         */
        function AnswerMemberResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AnswerMemberResponse instance using the specified properties.
         * @function create
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {api.IAnswerMemberResponse=} [properties] Properties to set
         * @returns {api.AnswerMemberResponse} AnswerMemberResponse instance
         */
        AnswerMemberResponse.create = function create(properties) {
            return new AnswerMemberResponse(properties);
        };

        /**
         * Encodes the specified AnswerMemberResponse message. Does not implicitly {@link api.AnswerMemberResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {api.IAnswerMemberResponse} message AnswerMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnswerMemberResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AnswerMemberResponse message, length delimited. Does not implicitly {@link api.AnswerMemberResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {api.IAnswerMemberResponse} message AnswerMemberResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AnswerMemberResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AnswerMemberResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AnswerMemberResponse} AnswerMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnswerMemberResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AnswerMemberResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AnswerMemberResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AnswerMemberResponse} AnswerMemberResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AnswerMemberResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AnswerMemberResponse message.
         * @function verify
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AnswerMemberResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AnswerMemberResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AnswerMemberResponse} AnswerMemberResponse
         */
        AnswerMemberResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AnswerMemberResponse)
                return object;
            return new $root.api.AnswerMemberResponse();
        };

        /**
         * Creates a plain object from an AnswerMemberResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AnswerMemberResponse
         * @static
         * @param {api.AnswerMemberResponse} message AnswerMemberResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AnswerMemberResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AnswerMemberResponse to JSON.
         * @function toJSON
         * @memberof api.AnswerMemberResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AnswerMemberResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AnswerMemberResponse;
    })();

    api.GetScheduleResponse = (function() {

        /**
         * Properties of a GetScheduleResponse.
         * @memberof api
         * @interface IGetScheduleResponse
         * @property {api.ISchedule|null} [schedule] GetScheduleResponse schedule
         */

        /**
         * Constructs a new GetScheduleResponse.
         * @memberof api
         * @classdesc Represents a GetScheduleResponse.
         * @implements IGetScheduleResponse
         * @constructor
         * @param {api.IGetScheduleResponse=} [properties] Properties to set
         */
        function GetScheduleResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetScheduleResponse schedule.
         * @member {api.ISchedule|null|undefined} schedule
         * @memberof api.GetScheduleResponse
         * @instance
         */
        GetScheduleResponse.prototype.schedule = null;

        /**
         * Creates a new GetScheduleResponse instance using the specified properties.
         * @function create
         * @memberof api.GetScheduleResponse
         * @static
         * @param {api.IGetScheduleResponse=} [properties] Properties to set
         * @returns {api.GetScheduleResponse} GetScheduleResponse instance
         */
        GetScheduleResponse.create = function create(properties) {
            return new GetScheduleResponse(properties);
        };

        /**
         * Encodes the specified GetScheduleResponse message. Does not implicitly {@link api.GetScheduleResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetScheduleResponse
         * @static
         * @param {api.IGetScheduleResponse} message GetScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                $root.api.Schedule.encode(message.schedule, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetScheduleResponse message, length delimited. Does not implicitly {@link api.GetScheduleResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetScheduleResponse
         * @static
         * @param {api.IGetScheduleResponse} message GetScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetScheduleResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetScheduleResponse} GetScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetScheduleResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.schedule = $root.api.Schedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetScheduleResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetScheduleResponse} GetScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetScheduleResponse message.
         * @function verify
         * @memberof api.GetScheduleResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetScheduleResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.schedule != null && message.hasOwnProperty("schedule")) {
                let error = $root.api.Schedule.verify(message.schedule);
                if (error)
                    return "schedule." + error;
            }
            return null;
        };

        /**
         * Creates a GetScheduleResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetScheduleResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetScheduleResponse} GetScheduleResponse
         */
        GetScheduleResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetScheduleResponse)
                return object;
            let message = new $root.api.GetScheduleResponse();
            if (object.schedule != null) {
                if (typeof object.schedule !== "object")
                    throw TypeError(".api.GetScheduleResponse.schedule: object expected");
                message.schedule = $root.api.Schedule.fromObject(object.schedule);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetScheduleResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetScheduleResponse
         * @static
         * @param {api.GetScheduleResponse} message GetScheduleResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetScheduleResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.schedule = null;
            if (message.schedule != null && message.hasOwnProperty("schedule"))
                object.schedule = $root.api.Schedule.toObject(message.schedule, options);
            return object;
        };

        /**
         * Converts this GetScheduleResponse to JSON.
         * @function toJSON
         * @memberof api.GetScheduleResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetScheduleResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetScheduleResponse;
    })();

    api.AddScheduleResponse = (function() {

        /**
         * Properties of an AddScheduleResponse.
         * @memberof api
         * @interface IAddScheduleResponse
         * @property {string|null} [scheduleId] AddScheduleResponse scheduleId
         */

        /**
         * Constructs a new AddScheduleResponse.
         * @memberof api
         * @classdesc Represents an AddScheduleResponse.
         * @implements IAddScheduleResponse
         * @constructor
         * @param {api.IAddScheduleResponse=} [properties] Properties to set
         */
        function AddScheduleResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddScheduleResponse scheduleId.
         * @member {string} scheduleId
         * @memberof api.AddScheduleResponse
         * @instance
         */
        AddScheduleResponse.prototype.scheduleId = "";

        /**
         * Creates a new AddScheduleResponse instance using the specified properties.
         * @function create
         * @memberof api.AddScheduleResponse
         * @static
         * @param {api.IAddScheduleResponse=} [properties] Properties to set
         * @returns {api.AddScheduleResponse} AddScheduleResponse instance
         */
        AddScheduleResponse.create = function create(properties) {
            return new AddScheduleResponse(properties);
        };

        /**
         * Encodes the specified AddScheduleResponse message. Does not implicitly {@link api.AddScheduleResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddScheduleResponse
         * @static
         * @param {api.IAddScheduleResponse} message AddScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddScheduleResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleId);
            return writer;
        };

        /**
         * Encodes the specified AddScheduleResponse message, length delimited. Does not implicitly {@link api.AddScheduleResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddScheduleResponse
         * @static
         * @param {api.IAddScheduleResponse} message AddScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddScheduleResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddScheduleResponse} AddScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddScheduleResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddScheduleResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddScheduleResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddScheduleResponse} AddScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddScheduleResponse message.
         * @function verify
         * @memberof api.AddScheduleResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddScheduleResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                if (!$util.isString(message.scheduleId))
                    return "scheduleId: string expected";
            return null;
        };

        /**
         * Creates an AddScheduleResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddScheduleResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddScheduleResponse} AddScheduleResponse
         */
        AddScheduleResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddScheduleResponse)
                return object;
            let message = new $root.api.AddScheduleResponse();
            if (object.scheduleId != null)
                message.scheduleId = String(object.scheduleId);
            return message;
        };

        /**
         * Creates a plain object from an AddScheduleResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddScheduleResponse
         * @static
         * @param {api.AddScheduleResponse} message AddScheduleResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddScheduleResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.scheduleId = "";
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                object.scheduleId = message.scheduleId;
            return object;
        };

        /**
         * Converts this AddScheduleResponse to JSON.
         * @function toJSON
         * @memberof api.AddScheduleResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddScheduleResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddScheduleResponse;
    })();

    api.UpdateScheduleResponse = (function() {

        /**
         * Properties of an UpdateScheduleResponse.
         * @memberof api
         * @interface IUpdateScheduleResponse
         */

        /**
         * Constructs a new UpdateScheduleResponse.
         * @memberof api
         * @classdesc Represents an UpdateScheduleResponse.
         * @implements IUpdateScheduleResponse
         * @constructor
         * @param {api.IUpdateScheduleResponse=} [properties] Properties to set
         */
        function UpdateScheduleResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateScheduleResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {api.IUpdateScheduleResponse=} [properties] Properties to set
         * @returns {api.UpdateScheduleResponse} UpdateScheduleResponse instance
         */
        UpdateScheduleResponse.create = function create(properties) {
            return new UpdateScheduleResponse(properties);
        };

        /**
         * Encodes the specified UpdateScheduleResponse message. Does not implicitly {@link api.UpdateScheduleResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {api.IUpdateScheduleResponse} message UpdateScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateScheduleResponse message, length delimited. Does not implicitly {@link api.UpdateScheduleResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {api.IUpdateScheduleResponse} message UpdateScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateScheduleResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateScheduleResponse} UpdateScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateScheduleResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateScheduleResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateScheduleResponse} UpdateScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateScheduleResponse message.
         * @function verify
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateScheduleResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateScheduleResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateScheduleResponse} UpdateScheduleResponse
         */
        UpdateScheduleResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateScheduleResponse)
                return object;
            return new $root.api.UpdateScheduleResponse();
        };

        /**
         * Creates a plain object from an UpdateScheduleResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateScheduleResponse
         * @static
         * @param {api.UpdateScheduleResponse} message UpdateScheduleResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateScheduleResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateScheduleResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateScheduleResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateScheduleResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateScheduleResponse;
    })();

    api.TruncateScheduleResponse = (function() {

        /**
         * Properties of a TruncateScheduleResponse.
         * @memberof api
         * @interface ITruncateScheduleResponse
         */

        /**
         * Constructs a new TruncateScheduleResponse.
         * @memberof api
         * @classdesc Represents a TruncateScheduleResponse.
         * @implements ITruncateScheduleResponse
         * @constructor
         * @param {api.ITruncateScheduleResponse=} [properties] Properties to set
         */
        function TruncateScheduleResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TruncateScheduleResponse instance using the specified properties.
         * @function create
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {api.ITruncateScheduleResponse=} [properties] Properties to set
         * @returns {api.TruncateScheduleResponse} TruncateScheduleResponse instance
         */
        TruncateScheduleResponse.create = function create(properties) {
            return new TruncateScheduleResponse(properties);
        };

        /**
         * Encodes the specified TruncateScheduleResponse message. Does not implicitly {@link api.TruncateScheduleResponse.verify|verify} messages.
         * @function encode
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {api.ITruncateScheduleResponse} message TruncateScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TruncateScheduleResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TruncateScheduleResponse message, length delimited. Does not implicitly {@link api.TruncateScheduleResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {api.ITruncateScheduleResponse} message TruncateScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TruncateScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TruncateScheduleResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TruncateScheduleResponse} TruncateScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TruncateScheduleResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TruncateScheduleResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TruncateScheduleResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TruncateScheduleResponse} TruncateScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TruncateScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TruncateScheduleResponse message.
         * @function verify
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TruncateScheduleResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TruncateScheduleResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TruncateScheduleResponse} TruncateScheduleResponse
         */
        TruncateScheduleResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TruncateScheduleResponse)
                return object;
            return new $root.api.TruncateScheduleResponse();
        };

        /**
         * Creates a plain object from a TruncateScheduleResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TruncateScheduleResponse
         * @static
         * @param {api.TruncateScheduleResponse} message TruncateScheduleResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TruncateScheduleResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TruncateScheduleResponse to JSON.
         * @function toJSON
         * @memberof api.TruncateScheduleResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TruncateScheduleResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TruncateScheduleResponse;
    })();

    api.GetTimeResponse = (function() {

        /**
         * Properties of a GetTimeResponse.
         * @memberof api
         * @interface IGetTimeResponse
         * @property {api.ITime|null} [time] GetTimeResponse time
         */

        /**
         * Constructs a new GetTimeResponse.
         * @memberof api
         * @classdesc Represents a GetTimeResponse.
         * @implements IGetTimeResponse
         * @constructor
         * @param {api.IGetTimeResponse=} [properties] Properties to set
         */
        function GetTimeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTimeResponse time.
         * @member {api.ITime|null|undefined} time
         * @memberof api.GetTimeResponse
         * @instance
         */
        GetTimeResponse.prototype.time = null;

        /**
         * Creates a new GetTimeResponse instance using the specified properties.
         * @function create
         * @memberof api.GetTimeResponse
         * @static
         * @param {api.IGetTimeResponse=} [properties] Properties to set
         * @returns {api.GetTimeResponse} GetTimeResponse instance
         */
        GetTimeResponse.create = function create(properties) {
            return new GetTimeResponse(properties);
        };

        /**
         * Encodes the specified GetTimeResponse message. Does not implicitly {@link api.GetTimeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetTimeResponse
         * @static
         * @param {api.IGetTimeResponse} message GetTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTimeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.Time.encode(message.time, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetTimeResponse message, length delimited. Does not implicitly {@link api.GetTimeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetTimeResponse
         * @static
         * @param {api.IGetTimeResponse} message GetTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTimeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetTimeResponse} GetTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTimeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetTimeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.time = $root.api.Time.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTimeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetTimeResponse} GetTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTimeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTimeResponse message.
         * @function verify
         * @memberof api.GetTimeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTimeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.time != null && message.hasOwnProperty("time")) {
                let error = $root.api.Time.verify(message.time);
                if (error)
                    return "time." + error;
            }
            return null;
        };

        /**
         * Creates a GetTimeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetTimeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetTimeResponse} GetTimeResponse
         */
        GetTimeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetTimeResponse)
                return object;
            let message = new $root.api.GetTimeResponse();
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.GetTimeResponse.time: object expected");
                message.time = $root.api.Time.fromObject(object.time);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTimeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetTimeResponse
         * @static
         * @param {api.GetTimeResponse} message GetTimeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTimeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.time = null;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = $root.api.Time.toObject(message.time, options);
            return object;
        };

        /**
         * Converts this GetTimeResponse to JSON.
         * @function toJSON
         * @memberof api.GetTimeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTimeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTimeResponse;
    })();

    api.UpdateTimeResponse = (function() {

        /**
         * Properties of an UpdateTimeResponse.
         * @memberof api
         * @interface IUpdateTimeResponse
         */

        /**
         * Constructs a new UpdateTimeResponse.
         * @memberof api
         * @classdesc Represents an UpdateTimeResponse.
         * @implements IUpdateTimeResponse
         * @constructor
         * @param {api.IUpdateTimeResponse=} [properties] Properties to set
         */
        function UpdateTimeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateTimeResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {api.IUpdateTimeResponse=} [properties] Properties to set
         * @returns {api.UpdateTimeResponse} UpdateTimeResponse instance
         */
        UpdateTimeResponse.create = function create(properties) {
            return new UpdateTimeResponse(properties);
        };

        /**
         * Encodes the specified UpdateTimeResponse message. Does not implicitly {@link api.UpdateTimeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {api.IUpdateTimeResponse} message UpdateTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTimeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateTimeResponse message, length delimited. Does not implicitly {@link api.UpdateTimeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {api.IUpdateTimeResponse} message UpdateTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateTimeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateTimeResponse} UpdateTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTimeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateTimeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateTimeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateTimeResponse} UpdateTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateTimeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateTimeResponse message.
         * @function verify
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateTimeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateTimeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateTimeResponse} UpdateTimeResponse
         */
        UpdateTimeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateTimeResponse)
                return object;
            return new $root.api.UpdateTimeResponse();
        };

        /**
         * Creates a plain object from an UpdateTimeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateTimeResponse
         * @static
         * @param {api.UpdateTimeResponse} message UpdateTimeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateTimeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateTimeResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateTimeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateTimeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateTimeResponse;
    })();

    api.DeleteTimeResponse = (function() {

        /**
         * Properties of a DeleteTimeResponse.
         * @memberof api
         * @interface IDeleteTimeResponse
         */

        /**
         * Constructs a new DeleteTimeResponse.
         * @memberof api
         * @classdesc Represents a DeleteTimeResponse.
         * @implements IDeleteTimeResponse
         * @constructor
         * @param {api.IDeleteTimeResponse=} [properties] Properties to set
         */
        function DeleteTimeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteTimeResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {api.IDeleteTimeResponse=} [properties] Properties to set
         * @returns {api.DeleteTimeResponse} DeleteTimeResponse instance
         */
        DeleteTimeResponse.create = function create(properties) {
            return new DeleteTimeResponse(properties);
        };

        /**
         * Encodes the specified DeleteTimeResponse message. Does not implicitly {@link api.DeleteTimeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {api.IDeleteTimeResponse} message DeleteTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTimeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteTimeResponse message, length delimited. Does not implicitly {@link api.DeleteTimeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {api.IDeleteTimeResponse} message DeleteTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteTimeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteTimeResponse} DeleteTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTimeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteTimeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteTimeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteTimeResponse} DeleteTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteTimeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteTimeResponse message.
         * @function verify
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteTimeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteTimeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteTimeResponse} DeleteTimeResponse
         */
        DeleteTimeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteTimeResponse)
                return object;
            return new $root.api.DeleteTimeResponse();
        };

        /**
         * Creates a plain object from a DeleteTimeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteTimeResponse
         * @static
         * @param {api.DeleteTimeResponse} message DeleteTimeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteTimeResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteTimeResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteTimeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteTimeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteTimeResponse;
    })();

    api.AddTimeResponse = (function() {

        /**
         * Properties of an AddTimeResponse.
         * @memberof api
         * @interface IAddTimeResponse
         * @property {string|null} [timeId] AddTimeResponse timeId
         */

        /**
         * Constructs a new AddTimeResponse.
         * @memberof api
         * @classdesc Represents an AddTimeResponse.
         * @implements IAddTimeResponse
         * @constructor
         * @param {api.IAddTimeResponse=} [properties] Properties to set
         */
        function AddTimeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddTimeResponse timeId.
         * @member {string} timeId
         * @memberof api.AddTimeResponse
         * @instance
         */
        AddTimeResponse.prototype.timeId = "";

        /**
         * Creates a new AddTimeResponse instance using the specified properties.
         * @function create
         * @memberof api.AddTimeResponse
         * @static
         * @param {api.IAddTimeResponse=} [properties] Properties to set
         * @returns {api.AddTimeResponse} AddTimeResponse instance
         */
        AddTimeResponse.create = function create(properties) {
            return new AddTimeResponse(properties);
        };

        /**
         * Encodes the specified AddTimeResponse message. Does not implicitly {@link api.AddTimeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddTimeResponse
         * @static
         * @param {api.IAddTimeResponse} message AddTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTimeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.timeId);
            return writer;
        };

        /**
         * Encodes the specified AddTimeResponse message, length delimited. Does not implicitly {@link api.AddTimeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddTimeResponse
         * @static
         * @param {api.IAddTimeResponse} message AddTimeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddTimeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddTimeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddTimeResponse} AddTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTimeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddTimeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timeId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddTimeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddTimeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddTimeResponse} AddTimeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddTimeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddTimeResponse message.
         * @function verify
         * @memberof api.AddTimeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddTimeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            return null;
        };

        /**
         * Creates an AddTimeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddTimeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddTimeResponse} AddTimeResponse
         */
        AddTimeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddTimeResponse)
                return object;
            let message = new $root.api.AddTimeResponse();
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            return message;
        };

        /**
         * Creates a plain object from an AddTimeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddTimeResponse
         * @static
         * @param {api.AddTimeResponse} message AddTimeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddTimeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.timeId = "";
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                object.timeId = message.timeId;
            return object;
        };

        /**
         * Converts this AddTimeResponse to JSON.
         * @function toJSON
         * @memberof api.AddTimeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddTimeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTimeResponse;
    })();

    api.DeleteScheduleResponse = (function() {

        /**
         * Properties of a DeleteScheduleResponse.
         * @memberof api
         * @interface IDeleteScheduleResponse
         */

        /**
         * Constructs a new DeleteScheduleResponse.
         * @memberof api
         * @classdesc Represents a DeleteScheduleResponse.
         * @implements IDeleteScheduleResponse
         * @constructor
         * @param {api.IDeleteScheduleResponse=} [properties] Properties to set
         */
        function DeleteScheduleResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteScheduleResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {api.IDeleteScheduleResponse=} [properties] Properties to set
         * @returns {api.DeleteScheduleResponse} DeleteScheduleResponse instance
         */
        DeleteScheduleResponse.create = function create(properties) {
            return new DeleteScheduleResponse(properties);
        };

        /**
         * Encodes the specified DeleteScheduleResponse message. Does not implicitly {@link api.DeleteScheduleResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {api.IDeleteScheduleResponse} message DeleteScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteScheduleResponse message, length delimited. Does not implicitly {@link api.DeleteScheduleResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {api.IDeleteScheduleResponse} message DeleteScheduleResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteScheduleResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteScheduleResponse} DeleteScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteScheduleResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteScheduleResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteScheduleResponse} DeleteScheduleResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteScheduleResponse message.
         * @function verify
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteScheduleResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteScheduleResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteScheduleResponse} DeleteScheduleResponse
         */
        DeleteScheduleResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteScheduleResponse)
                return object;
            return new $root.api.DeleteScheduleResponse();
        };

        /**
         * Creates a plain object from a DeleteScheduleResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteScheduleResponse
         * @static
         * @param {api.DeleteScheduleResponse} message DeleteScheduleResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteScheduleResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteScheduleResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteScheduleResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteScheduleResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteScheduleResponse;
    })();

    api.GetScheduleSetResponse = (function() {

        /**
         * Properties of a GetScheduleSetResponse.
         * @memberof api
         * @interface IGetScheduleSetResponse
         * @property {api.IScheduleSet|null} [scheduleSet] GetScheduleSetResponse scheduleSet
         */

        /**
         * Constructs a new GetScheduleSetResponse.
         * @memberof api
         * @classdesc Represents a GetScheduleSetResponse.
         * @implements IGetScheduleSetResponse
         * @constructor
         * @param {api.IGetScheduleSetResponse=} [properties] Properties to set
         */
        function GetScheduleSetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetScheduleSetResponse scheduleSet.
         * @member {api.IScheduleSet|null|undefined} scheduleSet
         * @memberof api.GetScheduleSetResponse
         * @instance
         */
        GetScheduleSetResponse.prototype.scheduleSet = null;

        /**
         * Creates a new GetScheduleSetResponse instance using the specified properties.
         * @function create
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {api.IGetScheduleSetResponse=} [properties] Properties to set
         * @returns {api.GetScheduleSetResponse} GetScheduleSetResponse instance
         */
        GetScheduleSetResponse.create = function create(properties) {
            return new GetScheduleSetResponse(properties);
        };

        /**
         * Encodes the specified GetScheduleSetResponse message. Does not implicitly {@link api.GetScheduleSetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {api.IGetScheduleSetResponse} message GetScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleSetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSet.encode(message.scheduleSet, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetScheduleSetResponse message, length delimited. Does not implicitly {@link api.GetScheduleSetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {api.IGetScheduleSetResponse} message GetScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetScheduleSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetScheduleSetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetScheduleSetResponse} GetScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleSetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetScheduleSetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSet = $root.api.ScheduleSet.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetScheduleSetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetScheduleSetResponse} GetScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetScheduleSetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetScheduleSetResponse message.
         * @function verify
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetScheduleSetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSet.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            return null;
        };

        /**
         * Creates a GetScheduleSetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetScheduleSetResponse} GetScheduleSetResponse
         */
        GetScheduleSetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetScheduleSetResponse)
                return object;
            let message = new $root.api.GetScheduleSetResponse();
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.GetScheduleSetResponse.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSet.fromObject(object.scheduleSet);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetScheduleSetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetScheduleSetResponse
         * @static
         * @param {api.GetScheduleSetResponse} message GetScheduleSetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetScheduleSetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.scheduleSet = null;
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSet.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this GetScheduleSetResponse to JSON.
         * @function toJSON
         * @memberof api.GetScheduleSetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetScheduleSetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetScheduleSetResponse;
    })();

    api.CreateScheduleSetResponse = (function() {

        /**
         * Properties of a CreateScheduleSetResponse.
         * @memberof api
         * @interface ICreateScheduleSetResponse
         * @property {string|null} [scheduleSetId] CreateScheduleSetResponse scheduleSetId
         */

        /**
         * Constructs a new CreateScheduleSetResponse.
         * @memberof api
         * @classdesc Represents a CreateScheduleSetResponse.
         * @implements ICreateScheduleSetResponse
         * @constructor
         * @param {api.ICreateScheduleSetResponse=} [properties] Properties to set
         */
        function CreateScheduleSetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateScheduleSetResponse scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.CreateScheduleSetResponse
         * @instance
         */
        CreateScheduleSetResponse.prototype.scheduleSetId = "";

        /**
         * Creates a new CreateScheduleSetResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {api.ICreateScheduleSetResponse=} [properties] Properties to set
         * @returns {api.CreateScheduleSetResponse} CreateScheduleSetResponse instance
         */
        CreateScheduleSetResponse.create = function create(properties) {
            return new CreateScheduleSetResponse(properties);
        };

        /**
         * Encodes the specified CreateScheduleSetResponse message. Does not implicitly {@link api.CreateScheduleSetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {api.ICreateScheduleSetResponse} message CreateScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateScheduleSetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleSetId);
            return writer;
        };

        /**
         * Encodes the specified CreateScheduleSetResponse message, length delimited. Does not implicitly {@link api.CreateScheduleSetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {api.ICreateScheduleSetResponse} message CreateScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateScheduleSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateScheduleSetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateScheduleSetResponse} CreateScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateScheduleSetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateScheduleSetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSetId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateScheduleSetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateScheduleSetResponse} CreateScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateScheduleSetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateScheduleSetResponse message.
         * @function verify
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateScheduleSetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            return null;
        };

        /**
         * Creates a CreateScheduleSetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateScheduleSetResponse} CreateScheduleSetResponse
         */
        CreateScheduleSetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateScheduleSetResponse)
                return object;
            let message = new $root.api.CreateScheduleSetResponse();
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            return message;
        };

        /**
         * Creates a plain object from a CreateScheduleSetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateScheduleSetResponse
         * @static
         * @param {api.CreateScheduleSetResponse} message CreateScheduleSetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateScheduleSetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.scheduleSetId = "";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            return object;
        };

        /**
         * Converts this CreateScheduleSetResponse to JSON.
         * @function toJSON
         * @memberof api.CreateScheduleSetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateScheduleSetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateScheduleSetResponse;
    })();

    api.UpdateScheduleSetResponse = (function() {

        /**
         * Properties of an UpdateScheduleSetResponse.
         * @memberof api
         * @interface IUpdateScheduleSetResponse
         */

        /**
         * Constructs a new UpdateScheduleSetResponse.
         * @memberof api
         * @classdesc Represents an UpdateScheduleSetResponse.
         * @implements IUpdateScheduleSetResponse
         * @constructor
         * @param {api.IUpdateScheduleSetResponse=} [properties] Properties to set
         */
        function UpdateScheduleSetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateScheduleSetResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {api.IUpdateScheduleSetResponse=} [properties] Properties to set
         * @returns {api.UpdateScheduleSetResponse} UpdateScheduleSetResponse instance
         */
        UpdateScheduleSetResponse.create = function create(properties) {
            return new UpdateScheduleSetResponse(properties);
        };

        /**
         * Encodes the specified UpdateScheduleSetResponse message. Does not implicitly {@link api.UpdateScheduleSetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {api.IUpdateScheduleSetResponse} message UpdateScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleSetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateScheduleSetResponse message, length delimited. Does not implicitly {@link api.UpdateScheduleSetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {api.IUpdateScheduleSetResponse} message UpdateScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateScheduleSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateScheduleSetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateScheduleSetResponse} UpdateScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleSetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateScheduleSetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateScheduleSetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateScheduleSetResponse} UpdateScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateScheduleSetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateScheduleSetResponse message.
         * @function verify
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateScheduleSetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateScheduleSetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateScheduleSetResponse} UpdateScheduleSetResponse
         */
        UpdateScheduleSetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateScheduleSetResponse)
                return object;
            return new $root.api.UpdateScheduleSetResponse();
        };

        /**
         * Creates a plain object from an UpdateScheduleSetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateScheduleSetResponse
         * @static
         * @param {api.UpdateScheduleSetResponse} message UpdateScheduleSetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateScheduleSetResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateScheduleSetResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateScheduleSetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateScheduleSetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateScheduleSetResponse;
    })();

    api.DeleteScheduleSetResponse = (function() {

        /**
         * Properties of a DeleteScheduleSetResponse.
         * @memberof api
         * @interface IDeleteScheduleSetResponse
         */

        /**
         * Constructs a new DeleteScheduleSetResponse.
         * @memberof api
         * @classdesc Represents a DeleteScheduleSetResponse.
         * @implements IDeleteScheduleSetResponse
         * @constructor
         * @param {api.IDeleteScheduleSetResponse=} [properties] Properties to set
         */
        function DeleteScheduleSetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteScheduleSetResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {api.IDeleteScheduleSetResponse=} [properties] Properties to set
         * @returns {api.DeleteScheduleSetResponse} DeleteScheduleSetResponse instance
         */
        DeleteScheduleSetResponse.create = function create(properties) {
            return new DeleteScheduleSetResponse(properties);
        };

        /**
         * Encodes the specified DeleteScheduleSetResponse message. Does not implicitly {@link api.DeleteScheduleSetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {api.IDeleteScheduleSetResponse} message DeleteScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleSetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteScheduleSetResponse message, length delimited. Does not implicitly {@link api.DeleteScheduleSetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {api.IDeleteScheduleSetResponse} message DeleteScheduleSetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteScheduleSetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteScheduleSetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteScheduleSetResponse} DeleteScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleSetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteScheduleSetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteScheduleSetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteScheduleSetResponse} DeleteScheduleSetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteScheduleSetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteScheduleSetResponse message.
         * @function verify
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteScheduleSetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteScheduleSetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteScheduleSetResponse} DeleteScheduleSetResponse
         */
        DeleteScheduleSetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteScheduleSetResponse)
                return object;
            return new $root.api.DeleteScheduleSetResponse();
        };

        /**
         * Creates a plain object from a DeleteScheduleSetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteScheduleSetResponse
         * @static
         * @param {api.DeleteScheduleSetResponse} message DeleteScheduleSetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteScheduleSetResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteScheduleSetResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteScheduleSetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteScheduleSetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteScheduleSetResponse;
    })();

    api.CreateUserResponse = (function() {

        /**
         * Properties of a CreateUserResponse.
         * @memberof api
         * @interface ICreateUserResponse
         * @property {string|null} [userId] CreateUserResponse userId
         * @property {string|null} [token] CreateUserResponse token
         * @property {number|Long|null} [expirationTime] CreateUserResponse expirationTime
         * @property {boolean|null} [emailValidationRequired] CreateUserResponse emailValidationRequired
         */

        /**
         * Constructs a new CreateUserResponse.
         * @memberof api
         * @classdesc Represents a CreateUserResponse.
         * @implements ICreateUserResponse
         * @constructor
         * @param {api.ICreateUserResponse=} [properties] Properties to set
         */
        function CreateUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateUserResponse userId.
         * @member {string} userId
         * @memberof api.CreateUserResponse
         * @instance
         */
        CreateUserResponse.prototype.userId = "";

        /**
         * CreateUserResponse token.
         * @member {string} token
         * @memberof api.CreateUserResponse
         * @instance
         */
        CreateUserResponse.prototype.token = "";

        /**
         * CreateUserResponse expirationTime.
         * @member {number|Long} expirationTime
         * @memberof api.CreateUserResponse
         * @instance
         */
        CreateUserResponse.prototype.expirationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * CreateUserResponse emailValidationRequired.
         * @member {boolean} emailValidationRequired
         * @memberof api.CreateUserResponse
         * @instance
         */
        CreateUserResponse.prototype.emailValidationRequired = false;

        /**
         * Creates a new CreateUserResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateUserResponse
         * @static
         * @param {api.ICreateUserResponse=} [properties] Properties to set
         * @returns {api.CreateUserResponse} CreateUserResponse instance
         */
        CreateUserResponse.create = function create(properties) {
            return new CreateUserResponse(properties);
        };

        /**
         * Encodes the specified CreateUserResponse message. Does not implicitly {@link api.CreateUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateUserResponse
         * @static
         * @param {api.ICreateUserResponse} message CreateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.token != null && Object.hasOwnProperty.call(message, "token"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.token);
            if (message.expirationTime != null && Object.hasOwnProperty.call(message, "expirationTime"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.expirationTime);
            if (message.emailValidationRequired != null && Object.hasOwnProperty.call(message, "emailValidationRequired"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.emailValidationRequired);
            return writer;
        };

        /**
         * Encodes the specified CreateUserResponse message, length delimited. Does not implicitly {@link api.CreateUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateUserResponse
         * @static
         * @param {api.ICreateUserResponse} message CreateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateUserResponse} CreateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.token = reader.string();
                    break;
                case 3:
                    message.expirationTime = reader.int64();
                    break;
                case 4:
                    message.emailValidationRequired = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateUserResponse} CreateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateUserResponse message.
         * @function verify
         * @memberof api.CreateUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.token != null && message.hasOwnProperty("token"))
                if (!$util.isString(message.token))
                    return "token: string expected";
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (!$util.isInteger(message.expirationTime) && !(message.expirationTime && $util.isInteger(message.expirationTime.low) && $util.isInteger(message.expirationTime.high)))
                    return "expirationTime: integer|Long expected";
            if (message.emailValidationRequired != null && message.hasOwnProperty("emailValidationRequired"))
                if (typeof message.emailValidationRequired !== "boolean")
                    return "emailValidationRequired: boolean expected";
            return null;
        };

        /**
         * Creates a CreateUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateUserResponse} CreateUserResponse
         */
        CreateUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateUserResponse)
                return object;
            let message = new $root.api.CreateUserResponse();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.token != null)
                message.token = String(object.token);
            if (object.expirationTime != null)
                if ($util.Long)
                    (message.expirationTime = $util.Long.fromValue(object.expirationTime)).unsigned = false;
                else if (typeof object.expirationTime === "string")
                    message.expirationTime = parseInt(object.expirationTime, 10);
                else if (typeof object.expirationTime === "number")
                    message.expirationTime = object.expirationTime;
                else if (typeof object.expirationTime === "object")
                    message.expirationTime = new $util.LongBits(object.expirationTime.low >>> 0, object.expirationTime.high >>> 0).toNumber();
            if (object.emailValidationRequired != null)
                message.emailValidationRequired = Boolean(object.emailValidationRequired);
            return message;
        };

        /**
         * Creates a plain object from a CreateUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateUserResponse
         * @static
         * @param {api.CreateUserResponse} message CreateUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.token = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.expirationTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.expirationTime = options.longs === String ? "0" : 0;
                object.emailValidationRequired = false;
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.token != null && message.hasOwnProperty("token"))
                object.token = message.token;
            if (message.expirationTime != null && message.hasOwnProperty("expirationTime"))
                if (typeof message.expirationTime === "number")
                    object.expirationTime = options.longs === String ? String(message.expirationTime) : message.expirationTime;
                else
                    object.expirationTime = options.longs === String ? $util.Long.prototype.toString.call(message.expirationTime) : options.longs === Number ? new $util.LongBits(message.expirationTime.low >>> 0, message.expirationTime.high >>> 0).toNumber() : message.expirationTime;
            if (message.emailValidationRequired != null && message.hasOwnProperty("emailValidationRequired"))
                object.emailValidationRequired = message.emailValidationRequired;
            return object;
        };

        /**
         * Converts this CreateUserResponse to JSON.
         * @function toJSON
         * @memberof api.CreateUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateUserResponse;
    })();

    api.UpdateUserResponse = (function() {

        /**
         * Properties of an UpdateUserResponse.
         * @memberof api
         * @interface IUpdateUserResponse
         */

        /**
         * Constructs a new UpdateUserResponse.
         * @memberof api
         * @classdesc Represents an UpdateUserResponse.
         * @implements IUpdateUserResponse
         * @constructor
         * @param {api.IUpdateUserResponse=} [properties] Properties to set
         */
        function UpdateUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateUserResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.IUpdateUserResponse=} [properties] Properties to set
         * @returns {api.UpdateUserResponse} UpdateUserResponse instance
         */
        UpdateUserResponse.create = function create(properties) {
            return new UpdateUserResponse(properties);
        };

        /**
         * Encodes the specified UpdateUserResponse message. Does not implicitly {@link api.UpdateUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.IUpdateUserResponse} message UpdateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateUserResponse message, length delimited. Does not implicitly {@link api.UpdateUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.IUpdateUserResponse} message UpdateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateUserResponse} UpdateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateUserResponse} UpdateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateUserResponse message.
         * @function verify
         * @memberof api.UpdateUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateUserResponse} UpdateUserResponse
         */
        UpdateUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateUserResponse)
                return object;
            return new $root.api.UpdateUserResponse();
        };

        /**
         * Creates a plain object from an UpdateUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateUserResponse
         * @static
         * @param {api.UpdateUserResponse} message UpdateUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateUserResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateUserResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateUserResponse;
    })();

    api.DeleteUserResponse = (function() {

        /**
         * Properties of a DeleteUserResponse.
         * @memberof api
         * @interface IDeleteUserResponse
         */

        /**
         * Constructs a new DeleteUserResponse.
         * @memberof api
         * @classdesc Represents a DeleteUserResponse.
         * @implements IDeleteUserResponse
         * @constructor
         * @param {api.IDeleteUserResponse=} [properties] Properties to set
         */
        function DeleteUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteUserResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.IDeleteUserResponse=} [properties] Properties to set
         * @returns {api.DeleteUserResponse} DeleteUserResponse instance
         */
        DeleteUserResponse.create = function create(properties) {
            return new DeleteUserResponse(properties);
        };

        /**
         * Encodes the specified DeleteUserResponse message. Does not implicitly {@link api.DeleteUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.IDeleteUserResponse} message DeleteUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteUserResponse message, length delimited. Does not implicitly {@link api.DeleteUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.IDeleteUserResponse} message DeleteUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteUserResponse} DeleteUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteUserResponse} DeleteUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteUserResponse message.
         * @function verify
         * @memberof api.DeleteUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteUserResponse} DeleteUserResponse
         */
        DeleteUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteUserResponse)
                return object;
            return new $root.api.DeleteUserResponse();
        };

        /**
         * Creates a plain object from a DeleteUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteUserResponse
         * @static
         * @param {api.DeleteUserResponse} message DeleteUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteUserResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteUserResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteUserResponse;
    })();

    api.CreateGroupResponse = (function() {

        /**
         * Properties of a CreateGroupResponse.
         * @memberof api
         * @interface ICreateGroupResponse
         * @property {string|null} [groupId] CreateGroupResponse groupId
         */

        /**
         * Constructs a new CreateGroupResponse.
         * @memberof api
         * @classdesc Represents a CreateGroupResponse.
         * @implements ICreateGroupResponse
         * @constructor
         * @param {api.ICreateGroupResponse=} [properties] Properties to set
         */
        function CreateGroupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateGroupResponse groupId.
         * @member {string} groupId
         * @memberof api.CreateGroupResponse
         * @instance
         */
        CreateGroupResponse.prototype.groupId = "";

        /**
         * Creates a new CreateGroupResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateGroupResponse
         * @static
         * @param {api.ICreateGroupResponse=} [properties] Properties to set
         * @returns {api.CreateGroupResponse} CreateGroupResponse instance
         */
        CreateGroupResponse.create = function create(properties) {
            return new CreateGroupResponse(properties);
        };

        /**
         * Encodes the specified CreateGroupResponse message. Does not implicitly {@link api.CreateGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateGroupResponse
         * @static
         * @param {api.ICreateGroupResponse} message CreateGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            return writer;
        };

        /**
         * Encodes the specified CreateGroupResponse message, length delimited. Does not implicitly {@link api.CreateGroupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateGroupResponse
         * @static
         * @param {api.ICreateGroupResponse} message CreateGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateGroupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateGroupResponse} CreateGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateGroupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateGroupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateGroupResponse} CreateGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateGroupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateGroupResponse message.
         * @function verify
         * @memberof api.CreateGroupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateGroupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            return null;
        };

        /**
         * Creates a CreateGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateGroupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateGroupResponse} CreateGroupResponse
         */
        CreateGroupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateGroupResponse)
                return object;
            let message = new $root.api.CreateGroupResponse();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            return message;
        };

        /**
         * Creates a plain object from a CreateGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateGroupResponse
         * @static
         * @param {api.CreateGroupResponse} message CreateGroupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateGroupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.groupId = "";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            return object;
        };

        /**
         * Converts this CreateGroupResponse to JSON.
         * @function toJSON
         * @memberof api.CreateGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateGroupResponse;
    })();

    api.UpdateGroupResponse = (function() {

        /**
         * Properties of an UpdateGroupResponse.
         * @memberof api
         * @interface IUpdateGroupResponse
         */

        /**
         * Constructs a new UpdateGroupResponse.
         * @memberof api
         * @classdesc Represents an UpdateGroupResponse.
         * @implements IUpdateGroupResponse
         * @constructor
         * @param {api.IUpdateGroupResponse=} [properties] Properties to set
         */
        function UpdateGroupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateGroupResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {api.IUpdateGroupResponse=} [properties] Properties to set
         * @returns {api.UpdateGroupResponse} UpdateGroupResponse instance
         */
        UpdateGroupResponse.create = function create(properties) {
            return new UpdateGroupResponse(properties);
        };

        /**
         * Encodes the specified UpdateGroupResponse message. Does not implicitly {@link api.UpdateGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {api.IUpdateGroupResponse} message UpdateGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateGroupResponse message, length delimited. Does not implicitly {@link api.UpdateGroupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {api.IUpdateGroupResponse} message UpdateGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateGroupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateGroupResponse} UpdateGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateGroupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateGroupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateGroupResponse} UpdateGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateGroupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateGroupResponse message.
         * @function verify
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateGroupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateGroupResponse} UpdateGroupResponse
         */
        UpdateGroupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateGroupResponse)
                return object;
            return new $root.api.UpdateGroupResponse();
        };

        /**
         * Creates a plain object from an UpdateGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateGroupResponse
         * @static
         * @param {api.UpdateGroupResponse} message UpdateGroupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateGroupResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateGroupResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateGroupResponse;
    })();

    api.DeleteGroupResponse = (function() {

        /**
         * Properties of a DeleteGroupResponse.
         * @memberof api
         * @interface IDeleteGroupResponse
         */

        /**
         * Constructs a new DeleteGroupResponse.
         * @memberof api
         * @classdesc Represents a DeleteGroupResponse.
         * @implements IDeleteGroupResponse
         * @constructor
         * @param {api.IDeleteGroupResponse=} [properties] Properties to set
         */
        function DeleteGroupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteGroupResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {api.IDeleteGroupResponse=} [properties] Properties to set
         * @returns {api.DeleteGroupResponse} DeleteGroupResponse instance
         */
        DeleteGroupResponse.create = function create(properties) {
            return new DeleteGroupResponse(properties);
        };

        /**
         * Encodes the specified DeleteGroupResponse message. Does not implicitly {@link api.DeleteGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {api.IDeleteGroupResponse} message DeleteGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteGroupResponse message, length delimited. Does not implicitly {@link api.DeleteGroupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {api.IDeleteGroupResponse} message DeleteGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteGroupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteGroupResponse} DeleteGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteGroupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteGroupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteGroupResponse} DeleteGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteGroupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteGroupResponse message.
         * @function verify
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteGroupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteGroupResponse} DeleteGroupResponse
         */
        DeleteGroupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteGroupResponse)
                return object;
            return new $root.api.DeleteGroupResponse();
        };

        /**
         * Creates a plain object from a DeleteGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteGroupResponse
         * @static
         * @param {api.DeleteGroupResponse} message DeleteGroupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteGroupResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteGroupResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteGroupResponse;
    })();

    api.CreateSyncResponse = (function() {

        /**
         * Properties of a CreateSyncResponse.
         * @memberof api
         * @interface ICreateSyncResponse
         * @property {string|null} [syncId] CreateSyncResponse syncId
         */

        /**
         * Constructs a new CreateSyncResponse.
         * @memberof api
         * @classdesc Represents a CreateSyncResponse.
         * @implements ICreateSyncResponse
         * @constructor
         * @param {api.ICreateSyncResponse=} [properties] Properties to set
         */
        function CreateSyncResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateSyncResponse syncId.
         * @member {string} syncId
         * @memberof api.CreateSyncResponse
         * @instance
         */
        CreateSyncResponse.prototype.syncId = "";

        /**
         * Creates a new CreateSyncResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateSyncResponse
         * @static
         * @param {api.ICreateSyncResponse=} [properties] Properties to set
         * @returns {api.CreateSyncResponse} CreateSyncResponse instance
         */
        CreateSyncResponse.create = function create(properties) {
            return new CreateSyncResponse(properties);
        };

        /**
         * Encodes the specified CreateSyncResponse message. Does not implicitly {@link api.CreateSyncResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateSyncResponse
         * @static
         * @param {api.ICreateSyncResponse} message CreateSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSyncResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncId != null && Object.hasOwnProperty.call(message, "syncId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.syncId);
            return writer;
        };

        /**
         * Encodes the specified CreateSyncResponse message, length delimited. Does not implicitly {@link api.CreateSyncResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateSyncResponse
         * @static
         * @param {api.ICreateSyncResponse} message CreateSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateSyncResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateSyncResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateSyncResponse} CreateSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSyncResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateSyncResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateSyncResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateSyncResponse} CreateSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateSyncResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateSyncResponse message.
         * @function verify
         * @memberof api.CreateSyncResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateSyncResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                if (!$util.isString(message.syncId))
                    return "syncId: string expected";
            return null;
        };

        /**
         * Creates a CreateSyncResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateSyncResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateSyncResponse} CreateSyncResponse
         */
        CreateSyncResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateSyncResponse)
                return object;
            let message = new $root.api.CreateSyncResponse();
            if (object.syncId != null)
                message.syncId = String(object.syncId);
            return message;
        };

        /**
         * Creates a plain object from a CreateSyncResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateSyncResponse
         * @static
         * @param {api.CreateSyncResponse} message CreateSyncResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateSyncResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.syncId = "";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                object.syncId = message.syncId;
            return object;
        };

        /**
         * Converts this CreateSyncResponse to JSON.
         * @function toJSON
         * @memberof api.CreateSyncResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateSyncResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateSyncResponse;
    })();

    api.UpdateSyncResponse = (function() {

        /**
         * Properties of an UpdateSyncResponse.
         * @memberof api
         * @interface IUpdateSyncResponse
         */

        /**
         * Constructs a new UpdateSyncResponse.
         * @memberof api
         * @classdesc Represents an UpdateSyncResponse.
         * @implements IUpdateSyncResponse
         * @constructor
         * @param {api.IUpdateSyncResponse=} [properties] Properties to set
         */
        function UpdateSyncResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateSyncResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {api.IUpdateSyncResponse=} [properties] Properties to set
         * @returns {api.UpdateSyncResponse} UpdateSyncResponse instance
         */
        UpdateSyncResponse.create = function create(properties) {
            return new UpdateSyncResponse(properties);
        };

        /**
         * Encodes the specified UpdateSyncResponse message. Does not implicitly {@link api.UpdateSyncResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {api.IUpdateSyncResponse} message UpdateSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSyncResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateSyncResponse message, length delimited. Does not implicitly {@link api.UpdateSyncResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {api.IUpdateSyncResponse} message UpdateSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateSyncResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateSyncResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateSyncResponse} UpdateSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSyncResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateSyncResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateSyncResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateSyncResponse} UpdateSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateSyncResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateSyncResponse message.
         * @function verify
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateSyncResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateSyncResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateSyncResponse} UpdateSyncResponse
         */
        UpdateSyncResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateSyncResponse)
                return object;
            return new $root.api.UpdateSyncResponse();
        };

        /**
         * Creates a plain object from an UpdateSyncResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateSyncResponse
         * @static
         * @param {api.UpdateSyncResponse} message UpdateSyncResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateSyncResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateSyncResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateSyncResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateSyncResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateSyncResponse;
    })();

    api.DeleteSyncResponse = (function() {

        /**
         * Properties of a DeleteSyncResponse.
         * @memberof api
         * @interface IDeleteSyncResponse
         */

        /**
         * Constructs a new DeleteSyncResponse.
         * @memberof api
         * @classdesc Represents a DeleteSyncResponse.
         * @implements IDeleteSyncResponse
         * @constructor
         * @param {api.IDeleteSyncResponse=} [properties] Properties to set
         */
        function DeleteSyncResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteSyncResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {api.IDeleteSyncResponse=} [properties] Properties to set
         * @returns {api.DeleteSyncResponse} DeleteSyncResponse instance
         */
        DeleteSyncResponse.create = function create(properties) {
            return new DeleteSyncResponse(properties);
        };

        /**
         * Encodes the specified DeleteSyncResponse message. Does not implicitly {@link api.DeleteSyncResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {api.IDeleteSyncResponse} message DeleteSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteSyncResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteSyncResponse message, length delimited. Does not implicitly {@link api.DeleteSyncResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {api.IDeleteSyncResponse} message DeleteSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteSyncResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteSyncResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteSyncResponse} DeleteSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteSyncResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteSyncResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteSyncResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteSyncResponse} DeleteSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteSyncResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteSyncResponse message.
         * @function verify
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteSyncResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteSyncResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteSyncResponse} DeleteSyncResponse
         */
        DeleteSyncResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteSyncResponse)
                return object;
            return new $root.api.DeleteSyncResponse();
        };

        /**
         * Creates a plain object from a DeleteSyncResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteSyncResponse
         * @static
         * @param {api.DeleteSyncResponse} message DeleteSyncResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteSyncResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteSyncResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteSyncResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteSyncResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteSyncResponse;
    })();

    api.TriggerSyncResponse = (function() {

        /**
         * Properties of a TriggerSyncResponse.
         * @memberof api
         * @interface ITriggerSyncResponse
         */

        /**
         * Constructs a new TriggerSyncResponse.
         * @memberof api
         * @classdesc Represents a TriggerSyncResponse.
         * @implements ITriggerSyncResponse
         * @constructor
         * @param {api.ITriggerSyncResponse=} [properties] Properties to set
         */
        function TriggerSyncResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TriggerSyncResponse instance using the specified properties.
         * @function create
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {api.ITriggerSyncResponse=} [properties] Properties to set
         * @returns {api.TriggerSyncResponse} TriggerSyncResponse instance
         */
        TriggerSyncResponse.create = function create(properties) {
            return new TriggerSyncResponse(properties);
        };

        /**
         * Encodes the specified TriggerSyncResponse message. Does not implicitly {@link api.TriggerSyncResponse.verify|verify} messages.
         * @function encode
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {api.ITriggerSyncResponse} message TriggerSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerSyncResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TriggerSyncResponse message, length delimited. Does not implicitly {@link api.TriggerSyncResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {api.ITriggerSyncResponse} message TriggerSyncResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerSyncResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerSyncResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TriggerSyncResponse} TriggerSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerSyncResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TriggerSyncResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerSyncResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TriggerSyncResponse} TriggerSyncResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerSyncResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerSyncResponse message.
         * @function verify
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerSyncResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TriggerSyncResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TriggerSyncResponse} TriggerSyncResponse
         */
        TriggerSyncResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TriggerSyncResponse)
                return object;
            return new $root.api.TriggerSyncResponse();
        };

        /**
         * Creates a plain object from a TriggerSyncResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TriggerSyncResponse
         * @static
         * @param {api.TriggerSyncResponse} message TriggerSyncResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerSyncResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TriggerSyncResponse to JSON.
         * @function toJSON
         * @memberof api.TriggerSyncResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerSyncResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerSyncResponse;
    })();

    api.TriggerRenderResponse = (function() {

        /**
         * Properties of a TriggerRenderResponse.
         * @memberof api
         * @interface ITriggerRenderResponse
         * @property {string|null} [renderId] TriggerRenderResponse renderId
         */

        /**
         * Constructs a new TriggerRenderResponse.
         * @memberof api
         * @classdesc Represents a TriggerRenderResponse.
         * @implements ITriggerRenderResponse
         * @constructor
         * @param {api.ITriggerRenderResponse=} [properties] Properties to set
         */
        function TriggerRenderResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TriggerRenderResponse renderId.
         * @member {string} renderId
         * @memberof api.TriggerRenderResponse
         * @instance
         */
        TriggerRenderResponse.prototype.renderId = "";

        /**
         * Creates a new TriggerRenderResponse instance using the specified properties.
         * @function create
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {api.ITriggerRenderResponse=} [properties] Properties to set
         * @returns {api.TriggerRenderResponse} TriggerRenderResponse instance
         */
        TriggerRenderResponse.create = function create(properties) {
            return new TriggerRenderResponse(properties);
        };

        /**
         * Encodes the specified TriggerRenderResponse message. Does not implicitly {@link api.TriggerRenderResponse.verify|verify} messages.
         * @function encode
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {api.ITriggerRenderResponse} message TriggerRenderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerRenderResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.renderId != null && Object.hasOwnProperty.call(message, "renderId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.renderId);
            return writer;
        };

        /**
         * Encodes the specified TriggerRenderResponse message, length delimited. Does not implicitly {@link api.TriggerRenderResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {api.ITriggerRenderResponse} message TriggerRenderResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TriggerRenderResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TriggerRenderResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TriggerRenderResponse} TriggerRenderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerRenderResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TriggerRenderResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.renderId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TriggerRenderResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TriggerRenderResponse} TriggerRenderResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TriggerRenderResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TriggerRenderResponse message.
         * @function verify
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TriggerRenderResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.renderId != null && message.hasOwnProperty("renderId"))
                if (!$util.isString(message.renderId))
                    return "renderId: string expected";
            return null;
        };

        /**
         * Creates a TriggerRenderResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TriggerRenderResponse} TriggerRenderResponse
         */
        TriggerRenderResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TriggerRenderResponse)
                return object;
            let message = new $root.api.TriggerRenderResponse();
            if (object.renderId != null)
                message.renderId = String(object.renderId);
            return message;
        };

        /**
         * Creates a plain object from a TriggerRenderResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TriggerRenderResponse
         * @static
         * @param {api.TriggerRenderResponse} message TriggerRenderResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TriggerRenderResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.renderId = "";
            if (message.renderId != null && message.hasOwnProperty("renderId"))
                object.renderId = message.renderId;
            return object;
        };

        /**
         * Converts this TriggerRenderResponse to JSON.
         * @function toJSON
         * @memberof api.TriggerRenderResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TriggerRenderResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TriggerRenderResponse;
    })();

    /**
     * RenderStatus enum.
     * @name api.RenderStatus
     * @enum {number}
     * @property {number} RenderStarted=0 RenderStarted value
     * @property {number} RenderCompleted=1 RenderCompleted value
     * @property {number} RenderRunning=2 RenderRunning value
     */
    api.RenderStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "RenderStarted"] = 0;
        values[valuesById[1] = "RenderCompleted"] = 1;
        values[valuesById[2] = "RenderRunning"] = 2;
        return values;
    })();

    api.RenderStatusResponse = (function() {

        /**
         * Properties of a RenderStatusResponse.
         * @memberof api
         * @interface IRenderStatusResponse
         * @property {api.RenderStatus|null} [renderStatus] RenderStatusResponse renderStatus
         * @property {number|null} [urlsProcessed] RenderStatusResponse urlsProcessed
         * @property {number|null} [errorCount] RenderStatusResponse errorCount
         * @property {string|null} [currentUrl] RenderStatusResponse currentUrl
         * @property {Array.<string>|null} [errorMessages] RenderStatusResponse errorMessages
         */

        /**
         * Constructs a new RenderStatusResponse.
         * @memberof api
         * @classdesc Represents a RenderStatusResponse.
         * @implements IRenderStatusResponse
         * @constructor
         * @param {api.IRenderStatusResponse=} [properties] Properties to set
         */
        function RenderStatusResponse(properties) {
            this.errorMessages = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RenderStatusResponse renderStatus.
         * @member {api.RenderStatus} renderStatus
         * @memberof api.RenderStatusResponse
         * @instance
         */
        RenderStatusResponse.prototype.renderStatus = 0;

        /**
         * RenderStatusResponse urlsProcessed.
         * @member {number} urlsProcessed
         * @memberof api.RenderStatusResponse
         * @instance
         */
        RenderStatusResponse.prototype.urlsProcessed = 0;

        /**
         * RenderStatusResponse errorCount.
         * @member {number} errorCount
         * @memberof api.RenderStatusResponse
         * @instance
         */
        RenderStatusResponse.prototype.errorCount = 0;

        /**
         * RenderStatusResponse currentUrl.
         * @member {string} currentUrl
         * @memberof api.RenderStatusResponse
         * @instance
         */
        RenderStatusResponse.prototype.currentUrl = "";

        /**
         * RenderStatusResponse errorMessages.
         * @member {Array.<string>} errorMessages
         * @memberof api.RenderStatusResponse
         * @instance
         */
        RenderStatusResponse.prototype.errorMessages = $util.emptyArray;

        /**
         * Creates a new RenderStatusResponse instance using the specified properties.
         * @function create
         * @memberof api.RenderStatusResponse
         * @static
         * @param {api.IRenderStatusResponse=} [properties] Properties to set
         * @returns {api.RenderStatusResponse} RenderStatusResponse instance
         */
        RenderStatusResponse.create = function create(properties) {
            return new RenderStatusResponse(properties);
        };

        /**
         * Encodes the specified RenderStatusResponse message. Does not implicitly {@link api.RenderStatusResponse.verify|verify} messages.
         * @function encode
         * @memberof api.RenderStatusResponse
         * @static
         * @param {api.IRenderStatusResponse} message RenderStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RenderStatusResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.renderStatus != null && Object.hasOwnProperty.call(message, "renderStatus"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.renderStatus);
            if (message.errorMessages != null && message.errorMessages.length)
                for (let i = 0; i < message.errorMessages.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.errorMessages[i]);
            if (message.urlsProcessed != null && Object.hasOwnProperty.call(message, "urlsProcessed"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.urlsProcessed);
            if (message.currentUrl != null && Object.hasOwnProperty.call(message, "currentUrl"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentUrl);
            if (message.errorCount != null && Object.hasOwnProperty.call(message, "errorCount"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.errorCount);
            return writer;
        };

        /**
         * Encodes the specified RenderStatusResponse message, length delimited. Does not implicitly {@link api.RenderStatusResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RenderStatusResponse
         * @static
         * @param {api.IRenderStatusResponse} message RenderStatusResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RenderStatusResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RenderStatusResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.RenderStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RenderStatusResponse} RenderStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RenderStatusResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RenderStatusResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.renderStatus = reader.int32();
                    break;
                case 3:
                    message.urlsProcessed = reader.int32();
                    break;
                case 5:
                    message.errorCount = reader.int32();
                    break;
                case 4:
                    message.currentUrl = reader.string();
                    break;
                case 2:
                    if (!(message.errorMessages && message.errorMessages.length))
                        message.errorMessages = [];
                    message.errorMessages.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RenderStatusResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RenderStatusResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RenderStatusResponse} RenderStatusResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RenderStatusResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RenderStatusResponse message.
         * @function verify
         * @memberof api.RenderStatusResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RenderStatusResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.renderStatus != null && message.hasOwnProperty("renderStatus"))
                switch (message.renderStatus) {
                default:
                    return "renderStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.urlsProcessed != null && message.hasOwnProperty("urlsProcessed"))
                if (!$util.isInteger(message.urlsProcessed))
                    return "urlsProcessed: integer expected";
            if (message.errorCount != null && message.hasOwnProperty("errorCount"))
                if (!$util.isInteger(message.errorCount))
                    return "errorCount: integer expected";
            if (message.currentUrl != null && message.hasOwnProperty("currentUrl"))
                if (!$util.isString(message.currentUrl))
                    return "currentUrl: string expected";
            if (message.errorMessages != null && message.hasOwnProperty("errorMessages")) {
                if (!Array.isArray(message.errorMessages))
                    return "errorMessages: array expected";
                for (let i = 0; i < message.errorMessages.length; ++i)
                    if (!$util.isString(message.errorMessages[i]))
                        return "errorMessages: string[] expected";
            }
            return null;
        };

        /**
         * Creates a RenderStatusResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RenderStatusResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RenderStatusResponse} RenderStatusResponse
         */
        RenderStatusResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RenderStatusResponse)
                return object;
            let message = new $root.api.RenderStatusResponse();
            switch (object.renderStatus) {
            case "RenderStarted":
            case 0:
                message.renderStatus = 0;
                break;
            case "RenderCompleted":
            case 1:
                message.renderStatus = 1;
                break;
            case "RenderRunning":
            case 2:
                message.renderStatus = 2;
                break;
            }
            if (object.urlsProcessed != null)
                message.urlsProcessed = object.urlsProcessed | 0;
            if (object.errorCount != null)
                message.errorCount = object.errorCount | 0;
            if (object.currentUrl != null)
                message.currentUrl = String(object.currentUrl);
            if (object.errorMessages) {
                if (!Array.isArray(object.errorMessages))
                    throw TypeError(".api.RenderStatusResponse.errorMessages: array expected");
                message.errorMessages = [];
                for (let i = 0; i < object.errorMessages.length; ++i)
                    message.errorMessages[i] = String(object.errorMessages[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a RenderStatusResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RenderStatusResponse
         * @static
         * @param {api.RenderStatusResponse} message RenderStatusResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RenderStatusResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.errorMessages = [];
            if (options.defaults) {
                object.renderStatus = options.enums === String ? "RenderStarted" : 0;
                object.urlsProcessed = 0;
                object.currentUrl = "";
                object.errorCount = 0;
            }
            if (message.renderStatus != null && message.hasOwnProperty("renderStatus"))
                object.renderStatus = options.enums === String ? $root.api.RenderStatus[message.renderStatus] : message.renderStatus;
            if (message.errorMessages && message.errorMessages.length) {
                object.errorMessages = [];
                for (let j = 0; j < message.errorMessages.length; ++j)
                    object.errorMessages[j] = message.errorMessages[j];
            }
            if (message.urlsProcessed != null && message.hasOwnProperty("urlsProcessed"))
                object.urlsProcessed = message.urlsProcessed;
            if (message.currentUrl != null && message.hasOwnProperty("currentUrl"))
                object.currentUrl = message.currentUrl;
            if (message.errorCount != null && message.hasOwnProperty("errorCount"))
                object.errorCount = message.errorCount;
            return object;
        };

        /**
         * Converts this RenderStatusResponse to JSON.
         * @function toJSON
         * @memberof api.RenderStatusResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RenderStatusResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RenderStatusResponse;
    })();

    api.GetIntegrationsResponse = (function() {

        /**
         * Properties of a GetIntegrationsResponse.
         * @memberof api
         * @interface IGetIntegrationsResponse
         * @property {Array.<api.IIntegration>|null} [integrations] GetIntegrationsResponse integrations
         */

        /**
         * Constructs a new GetIntegrationsResponse.
         * @memberof api
         * @classdesc Represents a GetIntegrationsResponse.
         * @implements IGetIntegrationsResponse
         * @constructor
         * @param {api.IGetIntegrationsResponse=} [properties] Properties to set
         */
        function GetIntegrationsResponse(properties) {
            this.integrations = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetIntegrationsResponse integrations.
         * @member {Array.<api.IIntegration>} integrations
         * @memberof api.GetIntegrationsResponse
         * @instance
         */
        GetIntegrationsResponse.prototype.integrations = $util.emptyArray;

        /**
         * Creates a new GetIntegrationsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {api.IGetIntegrationsResponse=} [properties] Properties to set
         * @returns {api.GetIntegrationsResponse} GetIntegrationsResponse instance
         */
        GetIntegrationsResponse.create = function create(properties) {
            return new GetIntegrationsResponse(properties);
        };

        /**
         * Encodes the specified GetIntegrationsResponse message. Does not implicitly {@link api.GetIntegrationsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {api.IGetIntegrationsResponse} message GetIntegrationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetIntegrationsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.integrations != null && message.integrations.length)
                for (let i = 0; i < message.integrations.length; ++i)
                    $root.api.Integration.encode(message.integrations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetIntegrationsResponse message, length delimited. Does not implicitly {@link api.GetIntegrationsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {api.IGetIntegrationsResponse} message GetIntegrationsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetIntegrationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetIntegrationsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetIntegrationsResponse} GetIntegrationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetIntegrationsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetIntegrationsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.integrations && message.integrations.length))
                        message.integrations = [];
                    message.integrations.push($root.api.Integration.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetIntegrationsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetIntegrationsResponse} GetIntegrationsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetIntegrationsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetIntegrationsResponse message.
         * @function verify
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetIntegrationsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.integrations != null && message.hasOwnProperty("integrations")) {
                if (!Array.isArray(message.integrations))
                    return "integrations: array expected";
                for (let i = 0; i < message.integrations.length; ++i) {
                    let error = $root.api.Integration.verify(message.integrations[i]);
                    if (error)
                        return "integrations." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetIntegrationsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetIntegrationsResponse} GetIntegrationsResponse
         */
        GetIntegrationsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetIntegrationsResponse)
                return object;
            let message = new $root.api.GetIntegrationsResponse();
            if (object.integrations) {
                if (!Array.isArray(object.integrations))
                    throw TypeError(".api.GetIntegrationsResponse.integrations: array expected");
                message.integrations = [];
                for (let i = 0; i < object.integrations.length; ++i) {
                    if (typeof object.integrations[i] !== "object")
                        throw TypeError(".api.GetIntegrationsResponse.integrations: object expected");
                    message.integrations[i] = $root.api.Integration.fromObject(object.integrations[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetIntegrationsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetIntegrationsResponse
         * @static
         * @param {api.GetIntegrationsResponse} message GetIntegrationsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetIntegrationsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.integrations = [];
            if (message.integrations && message.integrations.length) {
                object.integrations = [];
                for (let j = 0; j < message.integrations.length; ++j)
                    object.integrations[j] = $root.api.Integration.toObject(message.integrations[j], options);
            }
            return object;
        };

        /**
         * Converts this GetIntegrationsResponse to JSON.
         * @function toJSON
         * @memberof api.GetIntegrationsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetIntegrationsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetIntegrationsResponse;
    })();

    api.AddIntegrationResponse = (function() {

        /**
         * Properties of an AddIntegrationResponse.
         * @memberof api
         * @interface IAddIntegrationResponse
         */

        /**
         * Constructs a new AddIntegrationResponse.
         * @memberof api
         * @classdesc Represents an AddIntegrationResponse.
         * @implements IAddIntegrationResponse
         * @constructor
         * @param {api.IAddIntegrationResponse=} [properties] Properties to set
         */
        function AddIntegrationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new AddIntegrationResponse instance using the specified properties.
         * @function create
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {api.IAddIntegrationResponse=} [properties] Properties to set
         * @returns {api.AddIntegrationResponse} AddIntegrationResponse instance
         */
        AddIntegrationResponse.create = function create(properties) {
            return new AddIntegrationResponse(properties);
        };

        /**
         * Encodes the specified AddIntegrationResponse message. Does not implicitly {@link api.AddIntegrationResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {api.IAddIntegrationResponse} message AddIntegrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddIntegrationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified AddIntegrationResponse message, length delimited. Does not implicitly {@link api.AddIntegrationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {api.IAddIntegrationResponse} message AddIntegrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddIntegrationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddIntegrationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddIntegrationResponse} AddIntegrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddIntegrationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddIntegrationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddIntegrationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddIntegrationResponse} AddIntegrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddIntegrationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddIntegrationResponse message.
         * @function verify
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddIntegrationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an AddIntegrationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddIntegrationResponse} AddIntegrationResponse
         */
        AddIntegrationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddIntegrationResponse)
                return object;
            return new $root.api.AddIntegrationResponse();
        };

        /**
         * Creates a plain object from an AddIntegrationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddIntegrationResponse
         * @static
         * @param {api.AddIntegrationResponse} message AddIntegrationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddIntegrationResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this AddIntegrationResponse to JSON.
         * @function toJSON
         * @memberof api.AddIntegrationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddIntegrationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddIntegrationResponse;
    })();

    api.UpdateIntegrationResponse = (function() {

        /**
         * Properties of an UpdateIntegrationResponse.
         * @memberof api
         * @interface IUpdateIntegrationResponse
         */

        /**
         * Constructs a new UpdateIntegrationResponse.
         * @memberof api
         * @classdesc Represents an UpdateIntegrationResponse.
         * @implements IUpdateIntegrationResponse
         * @constructor
         * @param {api.IUpdateIntegrationResponse=} [properties] Properties to set
         */
        function UpdateIntegrationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateIntegrationResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {api.IUpdateIntegrationResponse=} [properties] Properties to set
         * @returns {api.UpdateIntegrationResponse} UpdateIntegrationResponse instance
         */
        UpdateIntegrationResponse.create = function create(properties) {
            return new UpdateIntegrationResponse(properties);
        };

        /**
         * Encodes the specified UpdateIntegrationResponse message. Does not implicitly {@link api.UpdateIntegrationResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {api.IUpdateIntegrationResponse} message UpdateIntegrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateIntegrationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateIntegrationResponse message, length delimited. Does not implicitly {@link api.UpdateIntegrationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {api.IUpdateIntegrationResponse} message UpdateIntegrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateIntegrationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateIntegrationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateIntegrationResponse} UpdateIntegrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateIntegrationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateIntegrationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateIntegrationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateIntegrationResponse} UpdateIntegrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateIntegrationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateIntegrationResponse message.
         * @function verify
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateIntegrationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateIntegrationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateIntegrationResponse} UpdateIntegrationResponse
         */
        UpdateIntegrationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateIntegrationResponse)
                return object;
            return new $root.api.UpdateIntegrationResponse();
        };

        /**
         * Creates a plain object from an UpdateIntegrationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateIntegrationResponse
         * @static
         * @param {api.UpdateIntegrationResponse} message UpdateIntegrationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateIntegrationResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateIntegrationResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateIntegrationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateIntegrationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateIntegrationResponse;
    })();

    api.ManageCouponResponse = (function() {

        /**
         * Properties of a ManageCouponResponse.
         * @memberof api
         * @interface IManageCouponResponse
         */

        /**
         * Constructs a new ManageCouponResponse.
         * @memberof api
         * @classdesc Represents a ManageCouponResponse.
         * @implements IManageCouponResponse
         * @constructor
         * @param {api.IManageCouponResponse=} [properties] Properties to set
         */
        function ManageCouponResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ManageCouponResponse instance using the specified properties.
         * @function create
         * @memberof api.ManageCouponResponse
         * @static
         * @param {api.IManageCouponResponse=} [properties] Properties to set
         * @returns {api.ManageCouponResponse} ManageCouponResponse instance
         */
        ManageCouponResponse.create = function create(properties) {
            return new ManageCouponResponse(properties);
        };

        /**
         * Encodes the specified ManageCouponResponse message. Does not implicitly {@link api.ManageCouponResponse.verify|verify} messages.
         * @function encode
         * @memberof api.ManageCouponResponse
         * @static
         * @param {api.IManageCouponResponse} message ManageCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManageCouponResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ManageCouponResponse message, length delimited. Does not implicitly {@link api.ManageCouponResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ManageCouponResponse
         * @static
         * @param {api.IManageCouponResponse} message ManageCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ManageCouponResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ManageCouponResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.ManageCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ManageCouponResponse} ManageCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManageCouponResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ManageCouponResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ManageCouponResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ManageCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ManageCouponResponse} ManageCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ManageCouponResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ManageCouponResponse message.
         * @function verify
         * @memberof api.ManageCouponResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ManageCouponResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ManageCouponResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ManageCouponResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ManageCouponResponse} ManageCouponResponse
         */
        ManageCouponResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ManageCouponResponse)
                return object;
            return new $root.api.ManageCouponResponse();
        };

        /**
         * Creates a plain object from a ManageCouponResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ManageCouponResponse
         * @static
         * @param {api.ManageCouponResponse} message ManageCouponResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ManageCouponResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ManageCouponResponse to JSON.
         * @function toJSON
         * @memberof api.ManageCouponResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ManageCouponResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ManageCouponResponse;
    })();

    api.CreateCouponResponse = (function() {

        /**
         * Properties of a CreateCouponResponse.
         * @memberof api
         * @interface ICreateCouponResponse
         */

        /**
         * Constructs a new CreateCouponResponse.
         * @memberof api
         * @classdesc Represents a CreateCouponResponse.
         * @implements ICreateCouponResponse
         * @constructor
         * @param {api.ICreateCouponResponse=} [properties] Properties to set
         */
        function CreateCouponResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateCouponResponse instance using the specified properties.
         * @function create
         * @memberof api.CreateCouponResponse
         * @static
         * @param {api.ICreateCouponResponse=} [properties] Properties to set
         * @returns {api.CreateCouponResponse} CreateCouponResponse instance
         */
        CreateCouponResponse.create = function create(properties) {
            return new CreateCouponResponse(properties);
        };

        /**
         * Encodes the specified CreateCouponResponse message. Does not implicitly {@link api.CreateCouponResponse.verify|verify} messages.
         * @function encode
         * @memberof api.CreateCouponResponse
         * @static
         * @param {api.ICreateCouponResponse} message CreateCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCouponResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CreateCouponResponse message, length delimited. Does not implicitly {@link api.CreateCouponResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CreateCouponResponse
         * @static
         * @param {api.ICreateCouponResponse} message CreateCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateCouponResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateCouponResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.CreateCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CreateCouponResponse} CreateCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCouponResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CreateCouponResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateCouponResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CreateCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CreateCouponResponse} CreateCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateCouponResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateCouponResponse message.
         * @function verify
         * @memberof api.CreateCouponResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateCouponResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CreateCouponResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CreateCouponResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CreateCouponResponse} CreateCouponResponse
         */
        CreateCouponResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CreateCouponResponse)
                return object;
            return new $root.api.CreateCouponResponse();
        };

        /**
         * Creates a plain object from a CreateCouponResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CreateCouponResponse
         * @static
         * @param {api.CreateCouponResponse} message CreateCouponResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateCouponResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateCouponResponse to JSON.
         * @function toJSON
         * @memberof api.CreateCouponResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateCouponResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateCouponResponse;
    })();

    api.UpdateCouponResponse = (function() {

        /**
         * Properties of an UpdateCouponResponse.
         * @memberof api
         * @interface IUpdateCouponResponse
         */

        /**
         * Constructs a new UpdateCouponResponse.
         * @memberof api
         * @classdesc Represents an UpdateCouponResponse.
         * @implements IUpdateCouponResponse
         * @constructor
         * @param {api.IUpdateCouponResponse=} [properties] Properties to set
         */
        function UpdateCouponResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdateCouponResponse instance using the specified properties.
         * @function create
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {api.IUpdateCouponResponse=} [properties] Properties to set
         * @returns {api.UpdateCouponResponse} UpdateCouponResponse instance
         */
        UpdateCouponResponse.create = function create(properties) {
            return new UpdateCouponResponse(properties);
        };

        /**
         * Encodes the specified UpdateCouponResponse message. Does not implicitly {@link api.UpdateCouponResponse.verify|verify} messages.
         * @function encode
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {api.IUpdateCouponResponse} message UpdateCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCouponResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdateCouponResponse message, length delimited. Does not implicitly {@link api.UpdateCouponResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {api.IUpdateCouponResponse} message UpdateCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdateCouponResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdateCouponResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdateCouponResponse} UpdateCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCouponResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdateCouponResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdateCouponResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdateCouponResponse} UpdateCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdateCouponResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdateCouponResponse message.
         * @function verify
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdateCouponResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdateCouponResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdateCouponResponse} UpdateCouponResponse
         */
        UpdateCouponResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdateCouponResponse)
                return object;
            return new $root.api.UpdateCouponResponse();
        };

        /**
         * Creates a plain object from an UpdateCouponResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdateCouponResponse
         * @static
         * @param {api.UpdateCouponResponse} message UpdateCouponResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdateCouponResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdateCouponResponse to JSON.
         * @function toJSON
         * @memberof api.UpdateCouponResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdateCouponResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdateCouponResponse;
    })();

    api.DestroyCouponResponse = (function() {

        /**
         * Properties of a DestroyCouponResponse.
         * @memberof api
         * @interface IDestroyCouponResponse
         */

        /**
         * Constructs a new DestroyCouponResponse.
         * @memberof api
         * @classdesc Represents a DestroyCouponResponse.
         * @implements IDestroyCouponResponse
         * @constructor
         * @param {api.IDestroyCouponResponse=} [properties] Properties to set
         */
        function DestroyCouponResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DestroyCouponResponse instance using the specified properties.
         * @function create
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {api.IDestroyCouponResponse=} [properties] Properties to set
         * @returns {api.DestroyCouponResponse} DestroyCouponResponse instance
         */
        DestroyCouponResponse.create = function create(properties) {
            return new DestroyCouponResponse(properties);
        };

        /**
         * Encodes the specified DestroyCouponResponse message. Does not implicitly {@link api.DestroyCouponResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {api.IDestroyCouponResponse} message DestroyCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DestroyCouponResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DestroyCouponResponse message, length delimited. Does not implicitly {@link api.DestroyCouponResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {api.IDestroyCouponResponse} message DestroyCouponResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DestroyCouponResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DestroyCouponResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DestroyCouponResponse} DestroyCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DestroyCouponResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DestroyCouponResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DestroyCouponResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DestroyCouponResponse} DestroyCouponResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DestroyCouponResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DestroyCouponResponse message.
         * @function verify
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DestroyCouponResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DestroyCouponResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DestroyCouponResponse} DestroyCouponResponse
         */
        DestroyCouponResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DestroyCouponResponse)
                return object;
            return new $root.api.DestroyCouponResponse();
        };

        /**
         * Creates a plain object from a DestroyCouponResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DestroyCouponResponse
         * @static
         * @param {api.DestroyCouponResponse} message DestroyCouponResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DestroyCouponResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DestroyCouponResponse to JSON.
         * @function toJSON
         * @memberof api.DestroyCouponResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DestroyCouponResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DestroyCouponResponse;
    })();

    api.DeleteIntegrationResponse = (function() {

        /**
         * Properties of a DeleteIntegrationResponse.
         * @memberof api
         * @interface IDeleteIntegrationResponse
         */

        /**
         * Constructs a new DeleteIntegrationResponse.
         * @memberof api
         * @classdesc Represents a DeleteIntegrationResponse.
         * @implements IDeleteIntegrationResponse
         * @constructor
         * @param {api.IDeleteIntegrationResponse=} [properties] Properties to set
         */
        function DeleteIntegrationResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteIntegrationResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {api.IDeleteIntegrationResponse=} [properties] Properties to set
         * @returns {api.DeleteIntegrationResponse} DeleteIntegrationResponse instance
         */
        DeleteIntegrationResponse.create = function create(properties) {
            return new DeleteIntegrationResponse(properties);
        };

        /**
         * Encodes the specified DeleteIntegrationResponse message. Does not implicitly {@link api.DeleteIntegrationResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {api.IDeleteIntegrationResponse} message DeleteIntegrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteIntegrationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteIntegrationResponse message, length delimited. Does not implicitly {@link api.DeleteIntegrationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {api.IDeleteIntegrationResponse} message DeleteIntegrationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteIntegrationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteIntegrationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteIntegrationResponse} DeleteIntegrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteIntegrationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteIntegrationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteIntegrationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteIntegrationResponse} DeleteIntegrationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteIntegrationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteIntegrationResponse message.
         * @function verify
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteIntegrationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteIntegrationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteIntegrationResponse} DeleteIntegrationResponse
         */
        DeleteIntegrationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteIntegrationResponse)
                return object;
            return new $root.api.DeleteIntegrationResponse();
        };

        /**
         * Creates a plain object from a DeleteIntegrationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteIntegrationResponse
         * @static
         * @param {api.DeleteIntegrationResponse} message DeleteIntegrationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteIntegrationResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteIntegrationResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteIntegrationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteIntegrationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteIntegrationResponse;
    })();

    api.SendMessageResponse = (function() {

        /**
         * Properties of a SendMessageResponse.
         * @memberof api
         * @interface ISendMessageResponse
         */

        /**
         * Constructs a new SendMessageResponse.
         * @memberof api
         * @classdesc Represents a SendMessageResponse.
         * @implements ISendMessageResponse
         * @constructor
         * @param {api.ISendMessageResponse=} [properties] Properties to set
         */
        function SendMessageResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SendMessageResponse instance using the specified properties.
         * @function create
         * @memberof api.SendMessageResponse
         * @static
         * @param {api.ISendMessageResponse=} [properties] Properties to set
         * @returns {api.SendMessageResponse} SendMessageResponse instance
         */
        SendMessageResponse.create = function create(properties) {
            return new SendMessageResponse(properties);
        };

        /**
         * Encodes the specified SendMessageResponse message. Does not implicitly {@link api.SendMessageResponse.verify|verify} messages.
         * @function encode
         * @memberof api.SendMessageResponse
         * @static
         * @param {api.ISendMessageResponse} message SendMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendMessageResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SendMessageResponse message, length delimited. Does not implicitly {@link api.SendMessageResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SendMessageResponse
         * @static
         * @param {api.ISendMessageResponse} message SendMessageResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SendMessageResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SendMessageResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.SendMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SendMessageResponse} SendMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendMessageResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SendMessageResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SendMessageResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SendMessageResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SendMessageResponse} SendMessageResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SendMessageResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SendMessageResponse message.
         * @function verify
         * @memberof api.SendMessageResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SendMessageResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SendMessageResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SendMessageResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SendMessageResponse} SendMessageResponse
         */
        SendMessageResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SendMessageResponse)
                return object;
            return new $root.api.SendMessageResponse();
        };

        /**
         * Creates a plain object from a SendMessageResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SendMessageResponse
         * @static
         * @param {api.SendMessageResponse} message SendMessageResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SendMessageResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SendMessageResponse to JSON.
         * @function toJSON
         * @memberof api.SendMessageResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SendMessageResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SendMessageResponse;
    })();

    api.GetRoomResponse = (function() {

        /**
         * Properties of a GetRoomResponse.
         * @memberof api
         * @interface IGetRoomResponse
         * @property {api.IRoom|null} [room] GetRoomResponse room
         */

        /**
         * Constructs a new GetRoomResponse.
         * @memberof api
         * @classdesc Represents a GetRoomResponse.
         * @implements IGetRoomResponse
         * @constructor
         * @param {api.IGetRoomResponse=} [properties] Properties to set
         */
        function GetRoomResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetRoomResponse room.
         * @member {api.IRoom|null|undefined} room
         * @memberof api.GetRoomResponse
         * @instance
         */
        GetRoomResponse.prototype.room = null;

        /**
         * Creates a new GetRoomResponse instance using the specified properties.
         * @function create
         * @memberof api.GetRoomResponse
         * @static
         * @param {api.IGetRoomResponse=} [properties] Properties to set
         * @returns {api.GetRoomResponse} GetRoomResponse instance
         */
        GetRoomResponse.create = function create(properties) {
            return new GetRoomResponse(properties);
        };

        /**
         * Encodes the specified GetRoomResponse message. Does not implicitly {@link api.GetRoomResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetRoomResponse
         * @static
         * @param {api.IGetRoomResponse} message GetRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRoomResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.Room.encode(message.room, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetRoomResponse message, length delimited. Does not implicitly {@link api.GetRoomResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetRoomResponse
         * @static
         * @param {api.IGetRoomResponse} message GetRoomResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetRoomResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetRoomResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetRoomResponse} GetRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRoomResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetRoomResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.room = $root.api.Room.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetRoomResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetRoomResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetRoomResponse} GetRoomResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetRoomResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetRoomResponse message.
         * @function verify
         * @memberof api.GetRoomResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetRoomResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.Room.verify(message.room);
                if (error)
                    return "room." + error;
            }
            return null;
        };

        /**
         * Creates a GetRoomResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetRoomResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetRoomResponse} GetRoomResponse
         */
        GetRoomResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetRoomResponse)
                return object;
            let message = new $root.api.GetRoomResponse();
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.GetRoomResponse.room: object expected");
                message.room = $root.api.Room.fromObject(object.room);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetRoomResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetRoomResponse
         * @static
         * @param {api.GetRoomResponse} message GetRoomResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetRoomResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.room = null;
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.Room.toObject(message.room, options);
            return object;
        };

        /**
         * Converts this GetRoomResponse to JSON.
         * @function toJSON
         * @memberof api.GetRoomResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetRoomResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetRoomResponse;
    })();

    api.GetConferenceConnectInfoResponse = (function() {

        /**
         * Properties of a GetConferenceConnectInfoResponse.
         * @memberof api
         * @interface IGetConferenceConnectInfoResponse
         * @property {string|null} [conferenceToken] GetConferenceConnectInfoResponse conferenceToken
         * @property {string|null} [conferenceUrl] GetConferenceConnectInfoResponse conferenceUrl
         * @property {string|null} [googleMeetId] GetConferenceConnectInfoResponse googleMeetId
         * @property {string|null} [zoomMeetingId] GetConferenceConnectInfoResponse zoomMeetingId
         * @property {string|null} [meetingPassword] GetConferenceConnectInfoResponse meetingPassword
         */

        /**
         * Constructs a new GetConferenceConnectInfoResponse.
         * @memberof api
         * @classdesc Represents a GetConferenceConnectInfoResponse.
         * @implements IGetConferenceConnectInfoResponse
         * @constructor
         * @param {api.IGetConferenceConnectInfoResponse=} [properties] Properties to set
         */
        function GetConferenceConnectInfoResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetConferenceConnectInfoResponse conferenceToken.
         * @member {string} conferenceToken
         * @memberof api.GetConferenceConnectInfoResponse
         * @instance
         */
        GetConferenceConnectInfoResponse.prototype.conferenceToken = "";

        /**
         * GetConferenceConnectInfoResponse conferenceUrl.
         * @member {string} conferenceUrl
         * @memberof api.GetConferenceConnectInfoResponse
         * @instance
         */
        GetConferenceConnectInfoResponse.prototype.conferenceUrl = "";

        /**
         * GetConferenceConnectInfoResponse googleMeetId.
         * @member {string} googleMeetId
         * @memberof api.GetConferenceConnectInfoResponse
         * @instance
         */
        GetConferenceConnectInfoResponse.prototype.googleMeetId = "";

        /**
         * GetConferenceConnectInfoResponse zoomMeetingId.
         * @member {string} zoomMeetingId
         * @memberof api.GetConferenceConnectInfoResponse
         * @instance
         */
        GetConferenceConnectInfoResponse.prototype.zoomMeetingId = "";

        /**
         * GetConferenceConnectInfoResponse meetingPassword.
         * @member {string} meetingPassword
         * @memberof api.GetConferenceConnectInfoResponse
         * @instance
         */
        GetConferenceConnectInfoResponse.prototype.meetingPassword = "";

        /**
         * Creates a new GetConferenceConnectInfoResponse instance using the specified properties.
         * @function create
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {api.IGetConferenceConnectInfoResponse=} [properties] Properties to set
         * @returns {api.GetConferenceConnectInfoResponse} GetConferenceConnectInfoResponse instance
         */
        GetConferenceConnectInfoResponse.create = function create(properties) {
            return new GetConferenceConnectInfoResponse(properties);
        };

        /**
         * Encodes the specified GetConferenceConnectInfoResponse message. Does not implicitly {@link api.GetConferenceConnectInfoResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {api.IGetConferenceConnectInfoResponse} message GetConferenceConnectInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConferenceConnectInfoResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.conferenceToken != null && Object.hasOwnProperty.call(message, "conferenceToken"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.conferenceToken);
            if (message.conferenceUrl != null && Object.hasOwnProperty.call(message, "conferenceUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.conferenceUrl);
            if (message.googleMeetId != null && Object.hasOwnProperty.call(message, "googleMeetId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.googleMeetId);
            if (message.zoomMeetingId != null && Object.hasOwnProperty.call(message, "zoomMeetingId"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.zoomMeetingId);
            if (message.meetingPassword != null && Object.hasOwnProperty.call(message, "meetingPassword"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.meetingPassword);
            return writer;
        };

        /**
         * Encodes the specified GetConferenceConnectInfoResponse message, length delimited. Does not implicitly {@link api.GetConferenceConnectInfoResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {api.IGetConferenceConnectInfoResponse} message GetConferenceConnectInfoResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetConferenceConnectInfoResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetConferenceConnectInfoResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetConferenceConnectInfoResponse} GetConferenceConnectInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConferenceConnectInfoResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetConferenceConnectInfoResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.conferenceToken = reader.string();
                    break;
                case 2:
                    message.conferenceUrl = reader.string();
                    break;
                case 3:
                    message.googleMeetId = reader.string();
                    break;
                case 4:
                    message.zoomMeetingId = reader.string();
                    break;
                case 5:
                    message.meetingPassword = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetConferenceConnectInfoResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetConferenceConnectInfoResponse} GetConferenceConnectInfoResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetConferenceConnectInfoResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetConferenceConnectInfoResponse message.
         * @function verify
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetConferenceConnectInfoResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.conferenceToken != null && message.hasOwnProperty("conferenceToken"))
                if (!$util.isString(message.conferenceToken))
                    return "conferenceToken: string expected";
            if (message.conferenceUrl != null && message.hasOwnProperty("conferenceUrl"))
                if (!$util.isString(message.conferenceUrl))
                    return "conferenceUrl: string expected";
            if (message.googleMeetId != null && message.hasOwnProperty("googleMeetId"))
                if (!$util.isString(message.googleMeetId))
                    return "googleMeetId: string expected";
            if (message.zoomMeetingId != null && message.hasOwnProperty("zoomMeetingId"))
                if (!$util.isString(message.zoomMeetingId))
                    return "zoomMeetingId: string expected";
            if (message.meetingPassword != null && message.hasOwnProperty("meetingPassword"))
                if (!$util.isString(message.meetingPassword))
                    return "meetingPassword: string expected";
            return null;
        };

        /**
         * Creates a GetConferenceConnectInfoResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetConferenceConnectInfoResponse} GetConferenceConnectInfoResponse
         */
        GetConferenceConnectInfoResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetConferenceConnectInfoResponse)
                return object;
            let message = new $root.api.GetConferenceConnectInfoResponse();
            if (object.conferenceToken != null)
                message.conferenceToken = String(object.conferenceToken);
            if (object.conferenceUrl != null)
                message.conferenceUrl = String(object.conferenceUrl);
            if (object.googleMeetId != null)
                message.googleMeetId = String(object.googleMeetId);
            if (object.zoomMeetingId != null)
                message.zoomMeetingId = String(object.zoomMeetingId);
            if (object.meetingPassword != null)
                message.meetingPassword = String(object.meetingPassword);
            return message;
        };

        /**
         * Creates a plain object from a GetConferenceConnectInfoResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetConferenceConnectInfoResponse
         * @static
         * @param {api.GetConferenceConnectInfoResponse} message GetConferenceConnectInfoResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetConferenceConnectInfoResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.conferenceToken = "";
                object.conferenceUrl = "";
                object.googleMeetId = "";
                object.zoomMeetingId = "";
                object.meetingPassword = "";
            }
            if (message.conferenceToken != null && message.hasOwnProperty("conferenceToken"))
                object.conferenceToken = message.conferenceToken;
            if (message.conferenceUrl != null && message.hasOwnProperty("conferenceUrl"))
                object.conferenceUrl = message.conferenceUrl;
            if (message.googleMeetId != null && message.hasOwnProperty("googleMeetId"))
                object.googleMeetId = message.googleMeetId;
            if (message.zoomMeetingId != null && message.hasOwnProperty("zoomMeetingId"))
                object.zoomMeetingId = message.zoomMeetingId;
            if (message.meetingPassword != null && message.hasOwnProperty("meetingPassword"))
                object.meetingPassword = message.meetingPassword;
            return object;
        };

        /**
         * Converts this GetConferenceConnectInfoResponse to JSON.
         * @function toJSON
         * @memberof api.GetConferenceConnectInfoResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetConferenceConnectInfoResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetConferenceConnectInfoResponse;
    })();

    api.GetTopicResponse = (function() {

        /**
         * Properties of a GetTopicResponse.
         * @memberof api
         * @interface IGetTopicResponse
         * @property {api.ITopic|null} [topic] GetTopicResponse topic
         */

        /**
         * Constructs a new GetTopicResponse.
         * @memberof api
         * @classdesc Represents a GetTopicResponse.
         * @implements IGetTopicResponse
         * @constructor
         * @param {api.IGetTopicResponse=} [properties] Properties to set
         */
        function GetTopicResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetTopicResponse topic.
         * @member {api.ITopic|null|undefined} topic
         * @memberof api.GetTopicResponse
         * @instance
         */
        GetTopicResponse.prototype.topic = null;

        /**
         * Creates a new GetTopicResponse instance using the specified properties.
         * @function create
         * @memberof api.GetTopicResponse
         * @static
         * @param {api.IGetTopicResponse=} [properties] Properties to set
         * @returns {api.GetTopicResponse} GetTopicResponse instance
         */
        GetTopicResponse.create = function create(properties) {
            return new GetTopicResponse(properties);
        };

        /**
         * Encodes the specified GetTopicResponse message. Does not implicitly {@link api.GetTopicResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetTopicResponse
         * @static
         * @param {api.IGetTopicResponse} message GetTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTopicResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.Topic.encode(message.topic, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetTopicResponse message, length delimited. Does not implicitly {@link api.GetTopicResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetTopicResponse
         * @static
         * @param {api.IGetTopicResponse} message GetTopicResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetTopicResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetTopicResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetTopicResponse} GetTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTopicResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetTopicResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topic = $root.api.Topic.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetTopicResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetTopicResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetTopicResponse} GetTopicResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetTopicResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetTopicResponse message.
         * @function verify
         * @memberof api.GetTopicResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetTopicResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.Topic.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            return null;
        };

        /**
         * Creates a GetTopicResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetTopicResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetTopicResponse} GetTopicResponse
         */
        GetTopicResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetTopicResponse)
                return object;
            let message = new $root.api.GetTopicResponse();
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.GetTopicResponse.topic: object expected");
                message.topic = $root.api.Topic.fromObject(object.topic);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetTopicResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetTopicResponse
         * @static
         * @param {api.GetTopicResponse} message GetTopicResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetTopicResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.topic = null;
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.Topic.toObject(message.topic, options);
            return object;
        };

        /**
         * Converts this GetTopicResponse to JSON.
         * @function toJSON
         * @memberof api.GetTopicResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetTopicResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetTopicResponse;
    })();

    api.CouponQuery = (function() {

        /**
         * Properties of a CouponQuery.
         * @memberof api
         * @interface ICouponQuery
         */

        /**
         * Constructs a new CouponQuery.
         * @memberof api
         * @classdesc Represents a CouponQuery.
         * @implements ICouponQuery
         * @constructor
         * @param {api.ICouponQuery=} [properties] Properties to set
         */
        function CouponQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CouponQuery instance using the specified properties.
         * @function create
         * @memberof api.CouponQuery
         * @static
         * @param {api.ICouponQuery=} [properties] Properties to set
         * @returns {api.CouponQuery} CouponQuery instance
         */
        CouponQuery.create = function create(properties) {
            return new CouponQuery(properties);
        };

        /**
         * Encodes the specified CouponQuery message. Does not implicitly {@link api.CouponQuery.verify|verify} messages.
         * @function encode
         * @memberof api.CouponQuery
         * @static
         * @param {api.ICouponQuery} message CouponQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CouponQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CouponQuery message, length delimited. Does not implicitly {@link api.CouponQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CouponQuery
         * @static
         * @param {api.ICouponQuery} message CouponQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CouponQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CouponQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.CouponQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CouponQuery} CouponQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CouponQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CouponQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CouponQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CouponQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CouponQuery} CouponQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CouponQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CouponQuery message.
         * @function verify
         * @memberof api.CouponQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CouponQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CouponQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CouponQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CouponQuery} CouponQuery
         */
        CouponQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CouponQuery)
                return object;
            return new $root.api.CouponQuery();
        };

        /**
         * Creates a plain object from a CouponQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CouponQuery
         * @static
         * @param {api.CouponQuery} message CouponQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CouponQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CouponQuery to JSON.
         * @function toJSON
         * @memberof api.CouponQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CouponQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CouponQuery;
    })();

    api.UserQuery = (function() {

        /**
         * Properties of a UserQuery.
         * @memberof api
         * @interface IUserQuery
         * @property {api.IMemberQuery|null} [membersOf] UserQuery membersOf
         * @property {api.IObjectAssetQuery|null} [assets] UserQuery assets
         * @property {api.ITopicReviewQuery|null} [reviewsByUser] UserQuery reviewsByUser
         * @property {api.ITopicReviewQuery|null} [reviewsOfUser] UserQuery reviewsOfUser
         * @property {boolean|null} [includeDescription] UserQuery includeDescription
         * @property {api.IIntegrationQuery|null} [integrations] UserQuery integrations
         * @property {api.IServiceSubscriptionQuery|null} [serviceSubscription] UserQuery serviceSubscription
         * @property {api.ICouponQuery|null} [coupons] UserQuery coupons
         */

        /**
         * Constructs a new UserQuery.
         * @memberof api
         * @classdesc Represents a UserQuery.
         * @implements IUserQuery
         * @constructor
         * @param {api.IUserQuery=} [properties] Properties to set
         */
        function UserQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserQuery membersOf.
         * @member {api.IMemberQuery|null|undefined} membersOf
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.membersOf = null;

        /**
         * UserQuery assets.
         * @member {api.IObjectAssetQuery|null|undefined} assets
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.assets = null;

        /**
         * UserQuery reviewsByUser.
         * @member {api.ITopicReviewQuery|null|undefined} reviewsByUser
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.reviewsByUser = null;

        /**
         * UserQuery reviewsOfUser.
         * @member {api.ITopicReviewQuery|null|undefined} reviewsOfUser
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.reviewsOfUser = null;

        /**
         * UserQuery includeDescription.
         * @member {boolean} includeDescription
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.includeDescription = false;

        /**
         * UserQuery integrations.
         * @member {api.IIntegrationQuery|null|undefined} integrations
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.integrations = null;

        /**
         * UserQuery serviceSubscription.
         * @member {api.IServiceSubscriptionQuery|null|undefined} serviceSubscription
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.serviceSubscription = null;

        /**
         * UserQuery coupons.
         * @member {api.ICouponQuery|null|undefined} coupons
         * @memberof api.UserQuery
         * @instance
         */
        UserQuery.prototype.coupons = null;

        /**
         * Creates a new UserQuery instance using the specified properties.
         * @function create
         * @memberof api.UserQuery
         * @static
         * @param {api.IUserQuery=} [properties] Properties to set
         * @returns {api.UserQuery} UserQuery instance
         */
        UserQuery.create = function create(properties) {
            return new UserQuery(properties);
        };

        /**
         * Encodes the specified UserQuery message. Does not implicitly {@link api.UserQuery.verify|verify} messages.
         * @function encode
         * @memberof api.UserQuery
         * @static
         * @param {api.IUserQuery} message UserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeDescription != null && Object.hasOwnProperty.call(message, "includeDescription"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeDescription);
            if (message.membersOf != null && Object.hasOwnProperty.call(message, "membersOf"))
                $root.api.MemberQuery.encode(message.membersOf, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.assets != null && Object.hasOwnProperty.call(message, "assets"))
                $root.api.ObjectAssetQuery.encode(message.assets, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.reviewsByUser != null && Object.hasOwnProperty.call(message, "reviewsByUser"))
                $root.api.TopicReviewQuery.encode(message.reviewsByUser, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.reviewsOfUser != null && Object.hasOwnProperty.call(message, "reviewsOfUser"))
                $root.api.TopicReviewQuery.encode(message.reviewsOfUser, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.integrations != null && Object.hasOwnProperty.call(message, "integrations"))
                $root.api.IntegrationQuery.encode(message.integrations, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.serviceSubscription != null && Object.hasOwnProperty.call(message, "serviceSubscription"))
                $root.api.ServiceSubscriptionQuery.encode(message.serviceSubscription, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.coupons != null && Object.hasOwnProperty.call(message, "coupons"))
                $root.api.CouponQuery.encode(message.coupons, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified UserQuery message, length delimited. Does not implicitly {@link api.UserQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserQuery
         * @static
         * @param {api.IUserQuery} message UserQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserQuery} UserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.membersOf = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.assets = $root.api.ObjectAssetQuery.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.reviewsByUser = $root.api.TopicReviewQuery.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.reviewsOfUser = $root.api.TopicReviewQuery.decode(reader, reader.uint32());
                    break;
                case 1:
                    message.includeDescription = reader.bool();
                    break;
                case 6:
                    message.integrations = $root.api.IntegrationQuery.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.serviceSubscription = $root.api.ServiceSubscriptionQuery.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.coupons = $root.api.CouponQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserQuery} UserQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserQuery message.
         * @function verify
         * @memberof api.UserQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                let error = $root.api.MemberQuery.verify(message.membersOf);
                if (error)
                    return "membersOf." + error;
            }
            if (message.assets != null && message.hasOwnProperty("assets")) {
                let error = $root.api.ObjectAssetQuery.verify(message.assets);
                if (error)
                    return "assets." + error;
            }
            if (message.reviewsByUser != null && message.hasOwnProperty("reviewsByUser")) {
                let error = $root.api.TopicReviewQuery.verify(message.reviewsByUser);
                if (error)
                    return "reviewsByUser." + error;
            }
            if (message.reviewsOfUser != null && message.hasOwnProperty("reviewsOfUser")) {
                let error = $root.api.TopicReviewQuery.verify(message.reviewsOfUser);
                if (error)
                    return "reviewsOfUser." + error;
            }
            if (message.includeDescription != null && message.hasOwnProperty("includeDescription"))
                if (typeof message.includeDescription !== "boolean")
                    return "includeDescription: boolean expected";
            if (message.integrations != null && message.hasOwnProperty("integrations")) {
                let error = $root.api.IntegrationQuery.verify(message.integrations);
                if (error)
                    return "integrations." + error;
            }
            if (message.serviceSubscription != null && message.hasOwnProperty("serviceSubscription")) {
                let error = $root.api.ServiceSubscriptionQuery.verify(message.serviceSubscription);
                if (error)
                    return "serviceSubscription." + error;
            }
            if (message.coupons != null && message.hasOwnProperty("coupons")) {
                let error = $root.api.CouponQuery.verify(message.coupons);
                if (error)
                    return "coupons." + error;
            }
            return null;
        };

        /**
         * Creates a UserQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserQuery} UserQuery
         */
        UserQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserQuery)
                return object;
            let message = new $root.api.UserQuery();
            if (object.membersOf != null) {
                if (typeof object.membersOf !== "object")
                    throw TypeError(".api.UserQuery.membersOf: object expected");
                message.membersOf = $root.api.MemberQuery.fromObject(object.membersOf);
            }
            if (object.assets != null) {
                if (typeof object.assets !== "object")
                    throw TypeError(".api.UserQuery.assets: object expected");
                message.assets = $root.api.ObjectAssetQuery.fromObject(object.assets);
            }
            if (object.reviewsByUser != null) {
                if (typeof object.reviewsByUser !== "object")
                    throw TypeError(".api.UserQuery.reviewsByUser: object expected");
                message.reviewsByUser = $root.api.TopicReviewQuery.fromObject(object.reviewsByUser);
            }
            if (object.reviewsOfUser != null) {
                if (typeof object.reviewsOfUser !== "object")
                    throw TypeError(".api.UserQuery.reviewsOfUser: object expected");
                message.reviewsOfUser = $root.api.TopicReviewQuery.fromObject(object.reviewsOfUser);
            }
            if (object.includeDescription != null)
                message.includeDescription = Boolean(object.includeDescription);
            if (object.integrations != null) {
                if (typeof object.integrations !== "object")
                    throw TypeError(".api.UserQuery.integrations: object expected");
                message.integrations = $root.api.IntegrationQuery.fromObject(object.integrations);
            }
            if (object.serviceSubscription != null) {
                if (typeof object.serviceSubscription !== "object")
                    throw TypeError(".api.UserQuery.serviceSubscription: object expected");
                message.serviceSubscription = $root.api.ServiceSubscriptionQuery.fromObject(object.serviceSubscription);
            }
            if (object.coupons != null) {
                if (typeof object.coupons !== "object")
                    throw TypeError(".api.UserQuery.coupons: object expected");
                message.coupons = $root.api.CouponQuery.fromObject(object.coupons);
            }
            return message;
        };

        /**
         * Creates a plain object from a UserQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserQuery
         * @static
         * @param {api.UserQuery} message UserQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.includeDescription = false;
                object.membersOf = null;
                object.assets = null;
                object.reviewsByUser = null;
                object.reviewsOfUser = null;
                object.integrations = null;
                object.serviceSubscription = null;
                object.coupons = null;
            }
            if (message.includeDescription != null && message.hasOwnProperty("includeDescription"))
                object.includeDescription = message.includeDescription;
            if (message.membersOf != null && message.hasOwnProperty("membersOf"))
                object.membersOf = $root.api.MemberQuery.toObject(message.membersOf, options);
            if (message.assets != null && message.hasOwnProperty("assets"))
                object.assets = $root.api.ObjectAssetQuery.toObject(message.assets, options);
            if (message.reviewsByUser != null && message.hasOwnProperty("reviewsByUser"))
                object.reviewsByUser = $root.api.TopicReviewQuery.toObject(message.reviewsByUser, options);
            if (message.reviewsOfUser != null && message.hasOwnProperty("reviewsOfUser"))
                object.reviewsOfUser = $root.api.TopicReviewQuery.toObject(message.reviewsOfUser, options);
            if (message.integrations != null && message.hasOwnProperty("integrations"))
                object.integrations = $root.api.IntegrationQuery.toObject(message.integrations, options);
            if (message.serviceSubscription != null && message.hasOwnProperty("serviceSubscription"))
                object.serviceSubscription = $root.api.ServiceSubscriptionQuery.toObject(message.serviceSubscription, options);
            if (message.coupons != null && message.hasOwnProperty("coupons"))
                object.coupons = $root.api.CouponQuery.toObject(message.coupons, options);
            return object;
        };

        /**
         * Converts this UserQuery to JSON.
         * @function toJSON
         * @memberof api.UserQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserQuery;
    })();

    /**
     * ViewType enum.
     * @name api.ViewType
     * @enum {number}
     * @property {number} ConsumerView=0 ConsumerView value
     * @property {number} ProviderView=1 ProviderView value
     */
    api.ViewType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ConsumerView"] = 0;
        values[valuesById[1] = "ProviderView"] = 1;
        return values;
    })();

    /**
     * ServiceSubscriptionType enum.
     * @name api.ServiceSubscriptionType
     * @enum {number}
     * @property {number} ServiceTypeFree=0 ServiceTypeFree value
     * @property {number} ServiceTypeSilver=1 ServiceTypeSilver value
     * @property {number} ServiceTypeGold=2 ServiceTypeGold value
     */
    api.ServiceSubscriptionType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ServiceTypeFree"] = 0;
        values[valuesById[1] = "ServiceTypeSilver"] = 1;
        values[valuesById[2] = "ServiceTypeGold"] = 2;
        return values;
    })();

    /**
     * ServiceSubscriptionInterval enum.
     * @name api.ServiceSubscriptionInterval
     * @enum {number}
     * @property {number} ServiceIntervalNone=0 ServiceIntervalNone value
     * @property {number} ServiceIntervalMonthly=1 ServiceIntervalMonthly value
     * @property {number} ServiceIntervalYearly=2 ServiceIntervalYearly value
     */
    api.ServiceSubscriptionInterval = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "ServiceIntervalNone"] = 0;
        values[valuesById[1] = "ServiceIntervalMonthly"] = 1;
        values[valuesById[2] = "ServiceIntervalYearly"] = 2;
        return values;
    })();

    api.ServiceSubscriptionQuery = (function() {

        /**
         * Properties of a ServiceSubscriptionQuery.
         * @memberof api
         * @interface IServiceSubscriptionQuery
         */

        /**
         * Constructs a new ServiceSubscriptionQuery.
         * @memberof api
         * @classdesc Represents a ServiceSubscriptionQuery.
         * @implements IServiceSubscriptionQuery
         * @constructor
         * @param {api.IServiceSubscriptionQuery=} [properties] Properties to set
         */
        function ServiceSubscriptionQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ServiceSubscriptionQuery instance using the specified properties.
         * @function create
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {api.IServiceSubscriptionQuery=} [properties] Properties to set
         * @returns {api.ServiceSubscriptionQuery} ServiceSubscriptionQuery instance
         */
        ServiceSubscriptionQuery.create = function create(properties) {
            return new ServiceSubscriptionQuery(properties);
        };

        /**
         * Encodes the specified ServiceSubscriptionQuery message. Does not implicitly {@link api.ServiceSubscriptionQuery.verify|verify} messages.
         * @function encode
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {api.IServiceSubscriptionQuery} message ServiceSubscriptionQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceSubscriptionQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ServiceSubscriptionQuery message, length delimited. Does not implicitly {@link api.ServiceSubscriptionQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {api.IServiceSubscriptionQuery} message ServiceSubscriptionQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceSubscriptionQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServiceSubscriptionQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ServiceSubscriptionQuery} ServiceSubscriptionQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceSubscriptionQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ServiceSubscriptionQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServiceSubscriptionQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ServiceSubscriptionQuery} ServiceSubscriptionQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceSubscriptionQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServiceSubscriptionQuery message.
         * @function verify
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServiceSubscriptionQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ServiceSubscriptionQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ServiceSubscriptionQuery} ServiceSubscriptionQuery
         */
        ServiceSubscriptionQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ServiceSubscriptionQuery)
                return object;
            return new $root.api.ServiceSubscriptionQuery();
        };

        /**
         * Creates a plain object from a ServiceSubscriptionQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ServiceSubscriptionQuery
         * @static
         * @param {api.ServiceSubscriptionQuery} message ServiceSubscriptionQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceSubscriptionQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ServiceSubscriptionQuery to JSON.
         * @function toJSON
         * @memberof api.ServiceSubscriptionQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceSubscriptionQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServiceSubscriptionQuery;
    })();

    api.ServiceSubscription = (function() {

        /**
         * Properties of a ServiceSubscription.
         * @memberof api
         * @interface IServiceSubscription
         * @property {string|null} [serviceSubscriptionId] ServiceSubscription serviceSubscriptionId
         * @property {api.ServiceSubscriptionType|null} [serviceSubscriptionType] ServiceSubscription serviceSubscriptionType
         * @property {api.ServiceSubscriptionInterval|null} [serviceSubscriptionInterval] ServiceSubscription serviceSubscriptionInterval
         * @property {number|Long|null} [startDate] ServiceSubscription startDate
         * @property {boolean|null} [autoRenew] ServiceSubscription autoRenew
         * @property {number|Long|null} [cancelDate] ServiceSubscription cancelDate
         */

        /**
         * Constructs a new ServiceSubscription.
         * @memberof api
         * @classdesc Represents a ServiceSubscription.
         * @implements IServiceSubscription
         * @constructor
         * @param {api.IServiceSubscription=} [properties] Properties to set
         */
        function ServiceSubscription(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ServiceSubscription serviceSubscriptionId.
         * @member {string} serviceSubscriptionId
         * @memberof api.ServiceSubscription
         * @instance
         */
        ServiceSubscription.prototype.serviceSubscriptionId = "";

        /**
         * ServiceSubscription serviceSubscriptionType.
         * @member {api.ServiceSubscriptionType} serviceSubscriptionType
         * @memberof api.ServiceSubscription
         * @instance
         */
        ServiceSubscription.prototype.serviceSubscriptionType = 0;

        /**
         * ServiceSubscription serviceSubscriptionInterval.
         * @member {api.ServiceSubscriptionInterval} serviceSubscriptionInterval
         * @memberof api.ServiceSubscription
         * @instance
         */
        ServiceSubscription.prototype.serviceSubscriptionInterval = 0;

        /**
         * ServiceSubscription startDate.
         * @member {number|Long} startDate
         * @memberof api.ServiceSubscription
         * @instance
         */
        ServiceSubscription.prototype.startDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ServiceSubscription autoRenew.
         * @member {boolean} autoRenew
         * @memberof api.ServiceSubscription
         * @instance
         */
        ServiceSubscription.prototype.autoRenew = false;

        /**
         * ServiceSubscription cancelDate.
         * @member {number|Long} cancelDate
         * @memberof api.ServiceSubscription
         * @instance
         */
        ServiceSubscription.prototype.cancelDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ServiceSubscription instance using the specified properties.
         * @function create
         * @memberof api.ServiceSubscription
         * @static
         * @param {api.IServiceSubscription=} [properties] Properties to set
         * @returns {api.ServiceSubscription} ServiceSubscription instance
         */
        ServiceSubscription.create = function create(properties) {
            return new ServiceSubscription(properties);
        };

        /**
         * Encodes the specified ServiceSubscription message. Does not implicitly {@link api.ServiceSubscription.verify|verify} messages.
         * @function encode
         * @memberof api.ServiceSubscription
         * @static
         * @param {api.IServiceSubscription} message ServiceSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceSubscription.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.serviceSubscriptionId != null && Object.hasOwnProperty.call(message, "serviceSubscriptionId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.serviceSubscriptionId);
            if (message.serviceSubscriptionType != null && Object.hasOwnProperty.call(message, "serviceSubscriptionType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.serviceSubscriptionType);
            if (message.serviceSubscriptionInterval != null && Object.hasOwnProperty.call(message, "serviceSubscriptionInterval"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.serviceSubscriptionInterval);
            if (message.startDate != null && Object.hasOwnProperty.call(message, "startDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.startDate);
            if (message.autoRenew != null && Object.hasOwnProperty.call(message, "autoRenew"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.autoRenew);
            if (message.cancelDate != null && Object.hasOwnProperty.call(message, "cancelDate"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.cancelDate);
            return writer;
        };

        /**
         * Encodes the specified ServiceSubscription message, length delimited. Does not implicitly {@link api.ServiceSubscription.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ServiceSubscription
         * @static
         * @param {api.IServiceSubscription} message ServiceSubscription message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ServiceSubscription.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ServiceSubscription message from the specified reader or buffer.
         * @function decode
         * @memberof api.ServiceSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ServiceSubscription} ServiceSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceSubscription.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ServiceSubscription();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.serviceSubscriptionId = reader.string();
                    break;
                case 2:
                    message.serviceSubscriptionType = reader.int32();
                    break;
                case 3:
                    message.serviceSubscriptionInterval = reader.int32();
                    break;
                case 4:
                    message.startDate = reader.int64();
                    break;
                case 5:
                    message.autoRenew = reader.bool();
                    break;
                case 6:
                    message.cancelDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ServiceSubscription message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ServiceSubscription
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ServiceSubscription} ServiceSubscription
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ServiceSubscription.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ServiceSubscription message.
         * @function verify
         * @memberof api.ServiceSubscription
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ServiceSubscription.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.serviceSubscriptionId != null && message.hasOwnProperty("serviceSubscriptionId"))
                if (!$util.isString(message.serviceSubscriptionId))
                    return "serviceSubscriptionId: string expected";
            if (message.serviceSubscriptionType != null && message.hasOwnProperty("serviceSubscriptionType"))
                switch (message.serviceSubscriptionType) {
                default:
                    return "serviceSubscriptionType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.serviceSubscriptionInterval != null && message.hasOwnProperty("serviceSubscriptionInterval"))
                switch (message.serviceSubscriptionInterval) {
                default:
                    return "serviceSubscriptionInterval: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.startDate != null && message.hasOwnProperty("startDate"))
                if (!$util.isInteger(message.startDate) && !(message.startDate && $util.isInteger(message.startDate.low) && $util.isInteger(message.startDate.high)))
                    return "startDate: integer|Long expected";
            if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                if (typeof message.autoRenew !== "boolean")
                    return "autoRenew: boolean expected";
            if (message.cancelDate != null && message.hasOwnProperty("cancelDate"))
                if (!$util.isInteger(message.cancelDate) && !(message.cancelDate && $util.isInteger(message.cancelDate.low) && $util.isInteger(message.cancelDate.high)))
                    return "cancelDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a ServiceSubscription message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ServiceSubscription
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ServiceSubscription} ServiceSubscription
         */
        ServiceSubscription.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ServiceSubscription)
                return object;
            let message = new $root.api.ServiceSubscription();
            if (object.serviceSubscriptionId != null)
                message.serviceSubscriptionId = String(object.serviceSubscriptionId);
            switch (object.serviceSubscriptionType) {
            case "ServiceTypeFree":
            case 0:
                message.serviceSubscriptionType = 0;
                break;
            case "ServiceTypeSilver":
            case 1:
                message.serviceSubscriptionType = 1;
                break;
            case "ServiceTypeGold":
            case 2:
                message.serviceSubscriptionType = 2;
                break;
            }
            switch (object.serviceSubscriptionInterval) {
            case "ServiceIntervalNone":
            case 0:
                message.serviceSubscriptionInterval = 0;
                break;
            case "ServiceIntervalMonthly":
            case 1:
                message.serviceSubscriptionInterval = 1;
                break;
            case "ServiceIntervalYearly":
            case 2:
                message.serviceSubscriptionInterval = 2;
                break;
            }
            if (object.startDate != null)
                if ($util.Long)
                    (message.startDate = $util.Long.fromValue(object.startDate)).unsigned = false;
                else if (typeof object.startDate === "string")
                    message.startDate = parseInt(object.startDate, 10);
                else if (typeof object.startDate === "number")
                    message.startDate = object.startDate;
                else if (typeof object.startDate === "object")
                    message.startDate = new $util.LongBits(object.startDate.low >>> 0, object.startDate.high >>> 0).toNumber();
            if (object.autoRenew != null)
                message.autoRenew = Boolean(object.autoRenew);
            if (object.cancelDate != null)
                if ($util.Long)
                    (message.cancelDate = $util.Long.fromValue(object.cancelDate)).unsigned = false;
                else if (typeof object.cancelDate === "string")
                    message.cancelDate = parseInt(object.cancelDate, 10);
                else if (typeof object.cancelDate === "number")
                    message.cancelDate = object.cancelDate;
                else if (typeof object.cancelDate === "object")
                    message.cancelDate = new $util.LongBits(object.cancelDate.low >>> 0, object.cancelDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a ServiceSubscription message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ServiceSubscription
         * @static
         * @param {api.ServiceSubscription} message ServiceSubscription
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ServiceSubscription.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.serviceSubscriptionId = "";
                object.serviceSubscriptionType = options.enums === String ? "ServiceTypeFree" : 0;
                object.serviceSubscriptionInterval = options.enums === String ? "ServiceIntervalNone" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startDate = options.longs === String ? "0" : 0;
                object.autoRenew = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.cancelDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.cancelDate = options.longs === String ? "0" : 0;
            }
            if (message.serviceSubscriptionId != null && message.hasOwnProperty("serviceSubscriptionId"))
                object.serviceSubscriptionId = message.serviceSubscriptionId;
            if (message.serviceSubscriptionType != null && message.hasOwnProperty("serviceSubscriptionType"))
                object.serviceSubscriptionType = options.enums === String ? $root.api.ServiceSubscriptionType[message.serviceSubscriptionType] : message.serviceSubscriptionType;
            if (message.serviceSubscriptionInterval != null && message.hasOwnProperty("serviceSubscriptionInterval"))
                object.serviceSubscriptionInterval = options.enums === String ? $root.api.ServiceSubscriptionInterval[message.serviceSubscriptionInterval] : message.serviceSubscriptionInterval;
            if (message.startDate != null && message.hasOwnProperty("startDate"))
                if (typeof message.startDate === "number")
                    object.startDate = options.longs === String ? String(message.startDate) : message.startDate;
                else
                    object.startDate = options.longs === String ? $util.Long.prototype.toString.call(message.startDate) : options.longs === Number ? new $util.LongBits(message.startDate.low >>> 0, message.startDate.high >>> 0).toNumber() : message.startDate;
            if (message.autoRenew != null && message.hasOwnProperty("autoRenew"))
                object.autoRenew = message.autoRenew;
            if (message.cancelDate != null && message.hasOwnProperty("cancelDate"))
                if (typeof message.cancelDate === "number")
                    object.cancelDate = options.longs === String ? String(message.cancelDate) : message.cancelDate;
                else
                    object.cancelDate = options.longs === String ? $util.Long.prototype.toString.call(message.cancelDate) : options.longs === Number ? new $util.LongBits(message.cancelDate.low >>> 0, message.cancelDate.high >>> 0).toNumber() : message.cancelDate;
            return object;
        };

        /**
         * Converts this ServiceSubscription to JSON.
         * @function toJSON
         * @memberof api.ServiceSubscription
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ServiceSubscription.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ServiceSubscription;
    })();

    api.CostLineItem = (function() {

        /**
         * Properties of a CostLineItem.
         * @memberof api
         * @interface ICostLineItem
         * @property {string|null} [description] CostLineItem description
         * @property {number|null} [quantity] CostLineItem quantity
         * @property {string|null} [couponCode] CostLineItem couponCode
         * @property {number|null} [cost] CostLineItem cost
         */

        /**
         * Constructs a new CostLineItem.
         * @memberof api
         * @classdesc Represents a CostLineItem.
         * @implements ICostLineItem
         * @constructor
         * @param {api.ICostLineItem=} [properties] Properties to set
         */
        function CostLineItem(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CostLineItem description.
         * @member {string} description
         * @memberof api.CostLineItem
         * @instance
         */
        CostLineItem.prototype.description = "";

        /**
         * CostLineItem quantity.
         * @member {number} quantity
         * @memberof api.CostLineItem
         * @instance
         */
        CostLineItem.prototype.quantity = 0;

        /**
         * CostLineItem couponCode.
         * @member {string} couponCode
         * @memberof api.CostLineItem
         * @instance
         */
        CostLineItem.prototype.couponCode = "";

        /**
         * CostLineItem cost.
         * @member {number} cost
         * @memberof api.CostLineItem
         * @instance
         */
        CostLineItem.prototype.cost = 0;

        /**
         * Creates a new CostLineItem instance using the specified properties.
         * @function create
         * @memberof api.CostLineItem
         * @static
         * @param {api.ICostLineItem=} [properties] Properties to set
         * @returns {api.CostLineItem} CostLineItem instance
         */
        CostLineItem.create = function create(properties) {
            return new CostLineItem(properties);
        };

        /**
         * Encodes the specified CostLineItem message. Does not implicitly {@link api.CostLineItem.verify|verify} messages.
         * @function encode
         * @memberof api.CostLineItem
         * @static
         * @param {api.ICostLineItem} message CostLineItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CostLineItem.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.description);
            if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.quantity);
            if (message.couponCode != null && Object.hasOwnProperty.call(message, "couponCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.couponCode);
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                writer.uint32(/* id 4, wireType 1 =*/33).double(message.cost);
            return writer;
        };

        /**
         * Encodes the specified CostLineItem message, length delimited. Does not implicitly {@link api.CostLineItem.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CostLineItem
         * @static
         * @param {api.ICostLineItem} message CostLineItem message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CostLineItem.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CostLineItem message from the specified reader or buffer.
         * @function decode
         * @memberof api.CostLineItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CostLineItem} CostLineItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CostLineItem.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CostLineItem();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.description = reader.string();
                    break;
                case 2:
                    message.quantity = reader.int32();
                    break;
                case 3:
                    message.couponCode = reader.string();
                    break;
                case 4:
                    message.cost = reader.double();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CostLineItem message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CostLineItem
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CostLineItem} CostLineItem
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CostLineItem.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CostLineItem message.
         * @function verify
         * @memberof api.CostLineItem
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CostLineItem.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.quantity != null && message.hasOwnProperty("quantity"))
                if (!$util.isInteger(message.quantity))
                    return "quantity: integer expected";
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                if (!$util.isString(message.couponCode))
                    return "couponCode: string expected";
            if (message.cost != null && message.hasOwnProperty("cost"))
                if (typeof message.cost !== "number")
                    return "cost: number expected";
            return null;
        };

        /**
         * Creates a CostLineItem message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CostLineItem
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CostLineItem} CostLineItem
         */
        CostLineItem.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CostLineItem)
                return object;
            let message = new $root.api.CostLineItem();
            if (object.description != null)
                message.description = String(object.description);
            if (object.quantity != null)
                message.quantity = object.quantity | 0;
            if (object.couponCode != null)
                message.couponCode = String(object.couponCode);
            if (object.cost != null)
                message.cost = Number(object.cost);
            return message;
        };

        /**
         * Creates a plain object from a CostLineItem message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CostLineItem
         * @static
         * @param {api.CostLineItem} message CostLineItem
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CostLineItem.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.description = "";
                object.quantity = 0;
                object.couponCode = "";
                object.cost = 0;
            }
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.quantity != null && message.hasOwnProperty("quantity"))
                object.quantity = message.quantity;
            if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                object.couponCode = message.couponCode;
            if (message.cost != null && message.hasOwnProperty("cost"))
                object.cost = options.json && !isFinite(message.cost) ? String(message.cost) : message.cost;
            return object;
        };

        /**
         * Converts this CostLineItem to JSON.
         * @function toJSON
         * @memberof api.CostLineItem
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CostLineItem.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CostLineItem;
    })();

    api.CostDetails = (function() {

        /**
         * Properties of a CostDetails.
         * @memberof api
         * @interface ICostDetails
         * @property {number|null} [finalCost] CostDetails finalCost
         * @property {Array.<api.ICostLineItem>|null} [lines] CostDetails lines
         */

        /**
         * Constructs a new CostDetails.
         * @memberof api
         * @classdesc Represents a CostDetails.
         * @implements ICostDetails
         * @constructor
         * @param {api.ICostDetails=} [properties] Properties to set
         */
        function CostDetails(properties) {
            this.lines = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CostDetails finalCost.
         * @member {number} finalCost
         * @memberof api.CostDetails
         * @instance
         */
        CostDetails.prototype.finalCost = 0;

        /**
         * CostDetails lines.
         * @member {Array.<api.ICostLineItem>} lines
         * @memberof api.CostDetails
         * @instance
         */
        CostDetails.prototype.lines = $util.emptyArray;

        /**
         * Creates a new CostDetails instance using the specified properties.
         * @function create
         * @memberof api.CostDetails
         * @static
         * @param {api.ICostDetails=} [properties] Properties to set
         * @returns {api.CostDetails} CostDetails instance
         */
        CostDetails.create = function create(properties) {
            return new CostDetails(properties);
        };

        /**
         * Encodes the specified CostDetails message. Does not implicitly {@link api.CostDetails.verify|verify} messages.
         * @function encode
         * @memberof api.CostDetails
         * @static
         * @param {api.ICostDetails} message CostDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CostDetails.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.finalCost != null && Object.hasOwnProperty.call(message, "finalCost"))
                writer.uint32(/* id 1, wireType 1 =*/9).double(message.finalCost);
            if (message.lines != null && message.lines.length)
                for (let i = 0; i < message.lines.length; ++i)
                    $root.api.CostLineItem.encode(message.lines[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CostDetails message, length delimited. Does not implicitly {@link api.CostDetails.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.CostDetails
         * @static
         * @param {api.ICostDetails} message CostDetails message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CostDetails.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CostDetails message from the specified reader or buffer.
         * @function decode
         * @memberof api.CostDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.CostDetails} CostDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CostDetails.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.CostDetails();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.finalCost = reader.double();
                    break;
                case 2:
                    if (!(message.lines && message.lines.length))
                        message.lines = [];
                    message.lines.push($root.api.CostLineItem.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CostDetails message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.CostDetails
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.CostDetails} CostDetails
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CostDetails.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CostDetails message.
         * @function verify
         * @memberof api.CostDetails
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CostDetails.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.finalCost != null && message.hasOwnProperty("finalCost"))
                if (typeof message.finalCost !== "number")
                    return "finalCost: number expected";
            if (message.lines != null && message.hasOwnProperty("lines")) {
                if (!Array.isArray(message.lines))
                    return "lines: array expected";
                for (let i = 0; i < message.lines.length; ++i) {
                    let error = $root.api.CostLineItem.verify(message.lines[i]);
                    if (error)
                        return "lines." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CostDetails message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.CostDetails
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.CostDetails} CostDetails
         */
        CostDetails.fromObject = function fromObject(object) {
            if (object instanceof $root.api.CostDetails)
                return object;
            let message = new $root.api.CostDetails();
            if (object.finalCost != null)
                message.finalCost = Number(object.finalCost);
            if (object.lines) {
                if (!Array.isArray(object.lines))
                    throw TypeError(".api.CostDetails.lines: array expected");
                message.lines = [];
                for (let i = 0; i < object.lines.length; ++i) {
                    if (typeof object.lines[i] !== "object")
                        throw TypeError(".api.CostDetails.lines: object expected");
                    message.lines[i] = $root.api.CostLineItem.fromObject(object.lines[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CostDetails message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.CostDetails
         * @static
         * @param {api.CostDetails} message CostDetails
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CostDetails.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.lines = [];
            if (options.defaults)
                object.finalCost = 0;
            if (message.finalCost != null && message.hasOwnProperty("finalCost"))
                object.finalCost = options.json && !isFinite(message.finalCost) ? String(message.finalCost) : message.finalCost;
            if (message.lines && message.lines.length) {
                object.lines = [];
                for (let j = 0; j < message.lines.length; ++j)
                    object.lines[j] = $root.api.CostLineItem.toObject(message.lines[j], options);
            }
            return object;
        };

        /**
         * Converts this CostDetails to JSON.
         * @function toJSON
         * @memberof api.CostDetails
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CostDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CostDetails;
    })();

    api.User = (function() {

        /**
         * Properties of a User.
         * @memberof api
         * @interface IUser
         * @property {string|null} [userId] User userId
         * @property {string|null} [emailAddress] User emailAddress
         * @property {string|null} [firstName] User firstName
         * @property {string|null} [lastName] User lastName
         * @property {string|null} [description] User description
         * @property {string|null} [password] User password
         * @property {Array.<api.IMember>|null} [membersOf] User membersOf
         * @property {number|Long|null} [createDate] User createDate
         * @property {number|Long|null} [updateDate] User updateDate
         * @property {Array.<api.IObjectAsset>|null} [assets] User assets
         * @property {Array.<api.ITopicReview>|null} [reviewsByUser] User reviewsByUser
         * @property {Array.<api.ITopicReview>|null} [reviewsOfUser] User reviewsOfUser
         * @property {string|null} [tinyCode] User tinyCode
         * @property {string|null} [vanityName] User vanityName
         * @property {boolean|null} [validated] User validated
         * @property {api.ViewType|null} [defaultView] User defaultView
         * @property {string|null} [adChannel] User adChannel
         * @property {boolean|null} [paymentAccountActive] User paymentAccountActive
         * @property {Array.<api.IIntegration>|null} [integrations] User integrations
         * @property {api.ServiceSubscriptionType|null} [serviceSubscriptionType] User serviceSubscriptionType
         * @property {api.ServiceSubscriptionInterval|null} [serviceSubscriptionInterval] User serviceSubscriptionInterval
         * @property {number|Long|null} [serviceSubscriptionExpirationDate] User serviceSubscriptionExpirationDate
         * @property {Array.<api.IServiceSubscription>|null} [serviceSubscriptions] User serviceSubscriptions
         * @property {Array.<string>|null} [couponCodes] User couponCodes
         * @property {string|null} [timeZone] User timeZone
         */

        /**
         * Constructs a new User.
         * @memberof api
         * @classdesc Represents a User.
         * @implements IUser
         * @constructor
         * @param {api.IUser=} [properties] Properties to set
         */
        function User(properties) {
            this.membersOf = [];
            this.assets = [];
            this.reviewsByUser = [];
            this.reviewsOfUser = [];
            this.integrations = [];
            this.serviceSubscriptions = [];
            this.couponCodes = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * User userId.
         * @member {string} userId
         * @memberof api.User
         * @instance
         */
        User.prototype.userId = "";

        /**
         * User emailAddress.
         * @member {string} emailAddress
         * @memberof api.User
         * @instance
         */
        User.prototype.emailAddress = "";

        /**
         * User firstName.
         * @member {string} firstName
         * @memberof api.User
         * @instance
         */
        User.prototype.firstName = "";

        /**
         * User lastName.
         * @member {string} lastName
         * @memberof api.User
         * @instance
         */
        User.prototype.lastName = "";

        /**
         * User description.
         * @member {string} description
         * @memberof api.User
         * @instance
         */
        User.prototype.description = "";

        /**
         * User password.
         * @member {string} password
         * @memberof api.User
         * @instance
         */
        User.prototype.password = "";

        /**
         * User membersOf.
         * @member {Array.<api.IMember>} membersOf
         * @memberof api.User
         * @instance
         */
        User.prototype.membersOf = $util.emptyArray;

        /**
         * User createDate.
         * @member {number|Long} createDate
         * @memberof api.User
         * @instance
         */
        User.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User updateDate.
         * @member {number|Long} updateDate
         * @memberof api.User
         * @instance
         */
        User.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User assets.
         * @member {Array.<api.IObjectAsset>} assets
         * @memberof api.User
         * @instance
         */
        User.prototype.assets = $util.emptyArray;

        /**
         * User reviewsByUser.
         * @member {Array.<api.ITopicReview>} reviewsByUser
         * @memberof api.User
         * @instance
         */
        User.prototype.reviewsByUser = $util.emptyArray;

        /**
         * User reviewsOfUser.
         * @member {Array.<api.ITopicReview>} reviewsOfUser
         * @memberof api.User
         * @instance
         */
        User.prototype.reviewsOfUser = $util.emptyArray;

        /**
         * User tinyCode.
         * @member {string} tinyCode
         * @memberof api.User
         * @instance
         */
        User.prototype.tinyCode = "";

        /**
         * User vanityName.
         * @member {string} vanityName
         * @memberof api.User
         * @instance
         */
        User.prototype.vanityName = "";

        /**
         * User validated.
         * @member {boolean} validated
         * @memberof api.User
         * @instance
         */
        User.prototype.validated = false;

        /**
         * User defaultView.
         * @member {api.ViewType} defaultView
         * @memberof api.User
         * @instance
         */
        User.prototype.defaultView = 0;

        /**
         * User adChannel.
         * @member {string} adChannel
         * @memberof api.User
         * @instance
         */
        User.prototype.adChannel = "";

        /**
         * User paymentAccountActive.
         * @member {boolean} paymentAccountActive
         * @memberof api.User
         * @instance
         */
        User.prototype.paymentAccountActive = false;

        /**
         * User integrations.
         * @member {Array.<api.IIntegration>} integrations
         * @memberof api.User
         * @instance
         */
        User.prototype.integrations = $util.emptyArray;

        /**
         * User serviceSubscriptionType.
         * @member {api.ServiceSubscriptionType} serviceSubscriptionType
         * @memberof api.User
         * @instance
         */
        User.prototype.serviceSubscriptionType = 0;

        /**
         * User serviceSubscriptionInterval.
         * @member {api.ServiceSubscriptionInterval} serviceSubscriptionInterval
         * @memberof api.User
         * @instance
         */
        User.prototype.serviceSubscriptionInterval = 0;

        /**
         * User serviceSubscriptionExpirationDate.
         * @member {number|Long} serviceSubscriptionExpirationDate
         * @memberof api.User
         * @instance
         */
        User.prototype.serviceSubscriptionExpirationDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * User serviceSubscriptions.
         * @member {Array.<api.IServiceSubscription>} serviceSubscriptions
         * @memberof api.User
         * @instance
         */
        User.prototype.serviceSubscriptions = $util.emptyArray;

        /**
         * User couponCodes.
         * @member {Array.<string>} couponCodes
         * @memberof api.User
         * @instance
         */
        User.prototype.couponCodes = $util.emptyArray;

        /**
         * User timeZone.
         * @member {string} timeZone
         * @memberof api.User
         * @instance
         */
        User.prototype.timeZone = "";

        /**
         * Creates a new User instance using the specified properties.
         * @function create
         * @memberof api.User
         * @static
         * @param {api.IUser=} [properties] Properties to set
         * @returns {api.User} User instance
         */
        User.create = function create(properties) {
            return new User(properties);
        };

        /**
         * Encodes the specified User message. Does not implicitly {@link api.User.verify|verify} messages.
         * @function encode
         * @memberof api.User
         * @static
         * @param {api.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.password);
            if (message.membersOf != null && message.membersOf.length)
                for (let i = 0; i < message.membersOf.length; ++i)
                    $root.api.Member.encode(message.membersOf[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.updateDate);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.description);
            if (message.assets != null && message.assets.length)
                for (let i = 0; i < message.assets.length; ++i)
                    $root.api.ObjectAsset.encode(message.assets[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.reviewsByUser != null && message.reviewsByUser.length)
                for (let i = 0; i < message.reviewsByUser.length; ++i)
                    $root.api.TopicReview.encode(message.reviewsByUser[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.reviewsOfUser != null && message.reviewsOfUser.length)
                for (let i = 0; i < message.reviewsOfUser.length; ++i)
                    $root.api.TopicReview.encode(message.reviewsOfUser[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.tinyCode != null && Object.hasOwnProperty.call(message, "tinyCode"))
                writer.uint32(/* id 13, wireType 2 =*/106).string(message.tinyCode);
            if (message.vanityName != null && Object.hasOwnProperty.call(message, "vanityName"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.vanityName);
            if (message.validated != null && Object.hasOwnProperty.call(message, "validated"))
                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.validated);
            if (message.defaultView != null && Object.hasOwnProperty.call(message, "defaultView"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.defaultView);
            if (message.adChannel != null && Object.hasOwnProperty.call(message, "adChannel"))
                writer.uint32(/* id 17, wireType 2 =*/138).string(message.adChannel);
            if (message.paymentAccountActive != null && Object.hasOwnProperty.call(message, "paymentAccountActive"))
                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.paymentAccountActive);
            if (message.integrations != null && message.integrations.length)
                for (let i = 0; i < message.integrations.length; ++i)
                    $root.api.Integration.encode(message.integrations[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.serviceSubscriptionType != null && Object.hasOwnProperty.call(message, "serviceSubscriptionType"))
                writer.uint32(/* id 20, wireType 0 =*/160).int32(message.serviceSubscriptionType);
            if (message.serviceSubscriptionInterval != null && Object.hasOwnProperty.call(message, "serviceSubscriptionInterval"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.serviceSubscriptionInterval);
            if (message.serviceSubscriptionExpirationDate != null && Object.hasOwnProperty.call(message, "serviceSubscriptionExpirationDate"))
                writer.uint32(/* id 22, wireType 0 =*/176).int64(message.serviceSubscriptionExpirationDate);
            if (message.serviceSubscriptions != null && message.serviceSubscriptions.length)
                for (let i = 0; i < message.serviceSubscriptions.length; ++i)
                    $root.api.ServiceSubscription.encode(message.serviceSubscriptions[i], writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.couponCodes != null && message.couponCodes.length)
                for (let i = 0; i < message.couponCodes.length; ++i)
                    writer.uint32(/* id 24, wireType 2 =*/194).string(message.couponCodes[i]);
            if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                writer.uint32(/* id 25, wireType 2 =*/202).string(message.timeZone);
            return writer;
        };

        /**
         * Encodes the specified User message, length delimited. Does not implicitly {@link api.User.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.User
         * @static
         * @param {api.IUser} message User message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        User.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a User message from the specified reader or buffer.
         * @function decode
         * @memberof api.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.User();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.emailAddress = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 9:
                    message.description = reader.string();
                    break;
                case 5:
                    message.password = reader.string();
                    break;
                case 6:
                    if (!(message.membersOf && message.membersOf.length))
                        message.membersOf = [];
                    message.membersOf.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 7:
                    message.createDate = reader.int64();
                    break;
                case 8:
                    message.updateDate = reader.int64();
                    break;
                case 10:
                    if (!(message.assets && message.assets.length))
                        message.assets = [];
                    message.assets.push($root.api.ObjectAsset.decode(reader, reader.uint32()));
                    break;
                case 11:
                    if (!(message.reviewsByUser && message.reviewsByUser.length))
                        message.reviewsByUser = [];
                    message.reviewsByUser.push($root.api.TopicReview.decode(reader, reader.uint32()));
                    break;
                case 12:
                    if (!(message.reviewsOfUser && message.reviewsOfUser.length))
                        message.reviewsOfUser = [];
                    message.reviewsOfUser.push($root.api.TopicReview.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.tinyCode = reader.string();
                    break;
                case 14:
                    message.vanityName = reader.string();
                    break;
                case 15:
                    message.validated = reader.bool();
                    break;
                case 16:
                    message.defaultView = reader.int32();
                    break;
                case 17:
                    message.adChannel = reader.string();
                    break;
                case 18:
                    message.paymentAccountActive = reader.bool();
                    break;
                case 19:
                    if (!(message.integrations && message.integrations.length))
                        message.integrations = [];
                    message.integrations.push($root.api.Integration.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.serviceSubscriptionType = reader.int32();
                    break;
                case 21:
                    message.serviceSubscriptionInterval = reader.int32();
                    break;
                case 22:
                    message.serviceSubscriptionExpirationDate = reader.int64();
                    break;
                case 23:
                    if (!(message.serviceSubscriptions && message.serviceSubscriptions.length))
                        message.serviceSubscriptions = [];
                    message.serviceSubscriptions.push($root.api.ServiceSubscription.decode(reader, reader.uint32()));
                    break;
                case 24:
                    if (!(message.couponCodes && message.couponCodes.length))
                        message.couponCodes = [];
                    message.couponCodes.push(reader.string());
                    break;
                case 25:
                    message.timeZone = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a User message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.User
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.User} User
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        User.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a User message.
         * @function verify
         * @memberof api.User
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        User.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                if (!$util.isString(message.emailAddress))
                    return "emailAddress: string expected";
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.password != null && message.hasOwnProperty("password"))
                if (!$util.isString(message.password))
                    return "password: string expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                if (!Array.isArray(message.membersOf))
                    return "membersOf: array expected";
                for (let i = 0; i < message.membersOf.length; ++i) {
                    let error = $root.api.Member.verify(message.membersOf[i]);
                    if (error)
                        return "membersOf." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            if (message.assets != null && message.hasOwnProperty("assets")) {
                if (!Array.isArray(message.assets))
                    return "assets: array expected";
                for (let i = 0; i < message.assets.length; ++i) {
                    let error = $root.api.ObjectAsset.verify(message.assets[i]);
                    if (error)
                        return "assets." + error;
                }
            }
            if (message.reviewsByUser != null && message.hasOwnProperty("reviewsByUser")) {
                if (!Array.isArray(message.reviewsByUser))
                    return "reviewsByUser: array expected";
                for (let i = 0; i < message.reviewsByUser.length; ++i) {
                    let error = $root.api.TopicReview.verify(message.reviewsByUser[i]);
                    if (error)
                        return "reviewsByUser." + error;
                }
            }
            if (message.reviewsOfUser != null && message.hasOwnProperty("reviewsOfUser")) {
                if (!Array.isArray(message.reviewsOfUser))
                    return "reviewsOfUser: array expected";
                for (let i = 0; i < message.reviewsOfUser.length; ++i) {
                    let error = $root.api.TopicReview.verify(message.reviewsOfUser[i]);
                    if (error)
                        return "reviewsOfUser." + error;
                }
            }
            if (message.tinyCode != null && message.hasOwnProperty("tinyCode"))
                if (!$util.isString(message.tinyCode))
                    return "tinyCode: string expected";
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                if (!$util.isString(message.vanityName))
                    return "vanityName: string expected";
            if (message.validated != null && message.hasOwnProperty("validated"))
                if (typeof message.validated !== "boolean")
                    return "validated: boolean expected";
            if (message.defaultView != null && message.hasOwnProperty("defaultView"))
                switch (message.defaultView) {
                default:
                    return "defaultView: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                if (!$util.isString(message.adChannel))
                    return "adChannel: string expected";
            if (message.paymentAccountActive != null && message.hasOwnProperty("paymentAccountActive"))
                if (typeof message.paymentAccountActive !== "boolean")
                    return "paymentAccountActive: boolean expected";
            if (message.integrations != null && message.hasOwnProperty("integrations")) {
                if (!Array.isArray(message.integrations))
                    return "integrations: array expected";
                for (let i = 0; i < message.integrations.length; ++i) {
                    let error = $root.api.Integration.verify(message.integrations[i]);
                    if (error)
                        return "integrations." + error;
                }
            }
            if (message.serviceSubscriptionType != null && message.hasOwnProperty("serviceSubscriptionType"))
                switch (message.serviceSubscriptionType) {
                default:
                    return "serviceSubscriptionType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.serviceSubscriptionInterval != null && message.hasOwnProperty("serviceSubscriptionInterval"))
                switch (message.serviceSubscriptionInterval) {
                default:
                    return "serviceSubscriptionInterval: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.serviceSubscriptionExpirationDate != null && message.hasOwnProperty("serviceSubscriptionExpirationDate"))
                if (!$util.isInteger(message.serviceSubscriptionExpirationDate) && !(message.serviceSubscriptionExpirationDate && $util.isInteger(message.serviceSubscriptionExpirationDate.low) && $util.isInteger(message.serviceSubscriptionExpirationDate.high)))
                    return "serviceSubscriptionExpirationDate: integer|Long expected";
            if (message.serviceSubscriptions != null && message.hasOwnProperty("serviceSubscriptions")) {
                if (!Array.isArray(message.serviceSubscriptions))
                    return "serviceSubscriptions: array expected";
                for (let i = 0; i < message.serviceSubscriptions.length; ++i) {
                    let error = $root.api.ServiceSubscription.verify(message.serviceSubscriptions[i]);
                    if (error)
                        return "serviceSubscriptions." + error;
                }
            }
            if (message.couponCodes != null && message.hasOwnProperty("couponCodes")) {
                if (!Array.isArray(message.couponCodes))
                    return "couponCodes: array expected";
                for (let i = 0; i < message.couponCodes.length; ++i)
                    if (!$util.isString(message.couponCodes[i]))
                        return "couponCodes: string[] expected";
            }
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                if (!$util.isString(message.timeZone))
                    return "timeZone: string expected";
            return null;
        };

        /**
         * Creates a User message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.User
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.User} User
         */
        User.fromObject = function fromObject(object) {
            if (object instanceof $root.api.User)
                return object;
            let message = new $root.api.User();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.emailAddress != null)
                message.emailAddress = String(object.emailAddress);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.description != null)
                message.description = String(object.description);
            if (object.password != null)
                message.password = String(object.password);
            if (object.membersOf) {
                if (!Array.isArray(object.membersOf))
                    throw TypeError(".api.User.membersOf: array expected");
                message.membersOf = [];
                for (let i = 0; i < object.membersOf.length; ++i) {
                    if (typeof object.membersOf[i] !== "object")
                        throw TypeError(".api.User.membersOf: object expected");
                    message.membersOf[i] = $root.api.Member.fromObject(object.membersOf[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            if (object.assets) {
                if (!Array.isArray(object.assets))
                    throw TypeError(".api.User.assets: array expected");
                message.assets = [];
                for (let i = 0; i < object.assets.length; ++i) {
                    if (typeof object.assets[i] !== "object")
                        throw TypeError(".api.User.assets: object expected");
                    message.assets[i] = $root.api.ObjectAsset.fromObject(object.assets[i]);
                }
            }
            if (object.reviewsByUser) {
                if (!Array.isArray(object.reviewsByUser))
                    throw TypeError(".api.User.reviewsByUser: array expected");
                message.reviewsByUser = [];
                for (let i = 0; i < object.reviewsByUser.length; ++i) {
                    if (typeof object.reviewsByUser[i] !== "object")
                        throw TypeError(".api.User.reviewsByUser: object expected");
                    message.reviewsByUser[i] = $root.api.TopicReview.fromObject(object.reviewsByUser[i]);
                }
            }
            if (object.reviewsOfUser) {
                if (!Array.isArray(object.reviewsOfUser))
                    throw TypeError(".api.User.reviewsOfUser: array expected");
                message.reviewsOfUser = [];
                for (let i = 0; i < object.reviewsOfUser.length; ++i) {
                    if (typeof object.reviewsOfUser[i] !== "object")
                        throw TypeError(".api.User.reviewsOfUser: object expected");
                    message.reviewsOfUser[i] = $root.api.TopicReview.fromObject(object.reviewsOfUser[i]);
                }
            }
            if (object.tinyCode != null)
                message.tinyCode = String(object.tinyCode);
            if (object.vanityName != null)
                message.vanityName = String(object.vanityName);
            if (object.validated != null)
                message.validated = Boolean(object.validated);
            switch (object.defaultView) {
            case "ConsumerView":
            case 0:
                message.defaultView = 0;
                break;
            case "ProviderView":
            case 1:
                message.defaultView = 1;
                break;
            }
            if (object.adChannel != null)
                message.adChannel = String(object.adChannel);
            if (object.paymentAccountActive != null)
                message.paymentAccountActive = Boolean(object.paymentAccountActive);
            if (object.integrations) {
                if (!Array.isArray(object.integrations))
                    throw TypeError(".api.User.integrations: array expected");
                message.integrations = [];
                for (let i = 0; i < object.integrations.length; ++i) {
                    if (typeof object.integrations[i] !== "object")
                        throw TypeError(".api.User.integrations: object expected");
                    message.integrations[i] = $root.api.Integration.fromObject(object.integrations[i]);
                }
            }
            switch (object.serviceSubscriptionType) {
            case "ServiceTypeFree":
            case 0:
                message.serviceSubscriptionType = 0;
                break;
            case "ServiceTypeSilver":
            case 1:
                message.serviceSubscriptionType = 1;
                break;
            case "ServiceTypeGold":
            case 2:
                message.serviceSubscriptionType = 2;
                break;
            }
            switch (object.serviceSubscriptionInterval) {
            case "ServiceIntervalNone":
            case 0:
                message.serviceSubscriptionInterval = 0;
                break;
            case "ServiceIntervalMonthly":
            case 1:
                message.serviceSubscriptionInterval = 1;
                break;
            case "ServiceIntervalYearly":
            case 2:
                message.serviceSubscriptionInterval = 2;
                break;
            }
            if (object.serviceSubscriptionExpirationDate != null)
                if ($util.Long)
                    (message.serviceSubscriptionExpirationDate = $util.Long.fromValue(object.serviceSubscriptionExpirationDate)).unsigned = false;
                else if (typeof object.serviceSubscriptionExpirationDate === "string")
                    message.serviceSubscriptionExpirationDate = parseInt(object.serviceSubscriptionExpirationDate, 10);
                else if (typeof object.serviceSubscriptionExpirationDate === "number")
                    message.serviceSubscriptionExpirationDate = object.serviceSubscriptionExpirationDate;
                else if (typeof object.serviceSubscriptionExpirationDate === "object")
                    message.serviceSubscriptionExpirationDate = new $util.LongBits(object.serviceSubscriptionExpirationDate.low >>> 0, object.serviceSubscriptionExpirationDate.high >>> 0).toNumber();
            if (object.serviceSubscriptions) {
                if (!Array.isArray(object.serviceSubscriptions))
                    throw TypeError(".api.User.serviceSubscriptions: array expected");
                message.serviceSubscriptions = [];
                for (let i = 0; i < object.serviceSubscriptions.length; ++i) {
                    if (typeof object.serviceSubscriptions[i] !== "object")
                        throw TypeError(".api.User.serviceSubscriptions: object expected");
                    message.serviceSubscriptions[i] = $root.api.ServiceSubscription.fromObject(object.serviceSubscriptions[i]);
                }
            }
            if (object.couponCodes) {
                if (!Array.isArray(object.couponCodes))
                    throw TypeError(".api.User.couponCodes: array expected");
                message.couponCodes = [];
                for (let i = 0; i < object.couponCodes.length; ++i)
                    message.couponCodes[i] = String(object.couponCodes[i]);
            }
            if (object.timeZone != null)
                message.timeZone = String(object.timeZone);
            return message;
        };

        /**
         * Creates a plain object from a User message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.User
         * @static
         * @param {api.User} message User
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        User.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.membersOf = [];
                object.assets = [];
                object.reviewsByUser = [];
                object.reviewsOfUser = [];
                object.integrations = [];
                object.serviceSubscriptions = [];
                object.couponCodes = [];
            }
            if (options.defaults) {
                object.userId = "";
                object.emailAddress = "";
                object.firstName = "";
                object.lastName = "";
                object.password = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.description = "";
                object.tinyCode = "";
                object.vanityName = "";
                object.validated = false;
                object.defaultView = options.enums === String ? "ConsumerView" : 0;
                object.adChannel = "";
                object.paymentAccountActive = false;
                object.serviceSubscriptionType = options.enums === String ? "ServiceTypeFree" : 0;
                object.serviceSubscriptionInterval = options.enums === String ? "ServiceIntervalNone" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.serviceSubscriptionExpirationDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.serviceSubscriptionExpirationDate = options.longs === String ? "0" : 0;
                object.timeZone = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                object.emailAddress = message.emailAddress;
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.password != null && message.hasOwnProperty("password"))
                object.password = message.password;
            if (message.membersOf && message.membersOf.length) {
                object.membersOf = [];
                for (let j = 0; j < message.membersOf.length; ++j)
                    object.membersOf[j] = $root.api.Member.toObject(message.membersOf[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.assets && message.assets.length) {
                object.assets = [];
                for (let j = 0; j < message.assets.length; ++j)
                    object.assets[j] = $root.api.ObjectAsset.toObject(message.assets[j], options);
            }
            if (message.reviewsByUser && message.reviewsByUser.length) {
                object.reviewsByUser = [];
                for (let j = 0; j < message.reviewsByUser.length; ++j)
                    object.reviewsByUser[j] = $root.api.TopicReview.toObject(message.reviewsByUser[j], options);
            }
            if (message.reviewsOfUser && message.reviewsOfUser.length) {
                object.reviewsOfUser = [];
                for (let j = 0; j < message.reviewsOfUser.length; ++j)
                    object.reviewsOfUser[j] = $root.api.TopicReview.toObject(message.reviewsOfUser[j], options);
            }
            if (message.tinyCode != null && message.hasOwnProperty("tinyCode"))
                object.tinyCode = message.tinyCode;
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                object.vanityName = message.vanityName;
            if (message.validated != null && message.hasOwnProperty("validated"))
                object.validated = message.validated;
            if (message.defaultView != null && message.hasOwnProperty("defaultView"))
                object.defaultView = options.enums === String ? $root.api.ViewType[message.defaultView] : message.defaultView;
            if (message.adChannel != null && message.hasOwnProperty("adChannel"))
                object.adChannel = message.adChannel;
            if (message.paymentAccountActive != null && message.hasOwnProperty("paymentAccountActive"))
                object.paymentAccountActive = message.paymentAccountActive;
            if (message.integrations && message.integrations.length) {
                object.integrations = [];
                for (let j = 0; j < message.integrations.length; ++j)
                    object.integrations[j] = $root.api.Integration.toObject(message.integrations[j], options);
            }
            if (message.serviceSubscriptionType != null && message.hasOwnProperty("serviceSubscriptionType"))
                object.serviceSubscriptionType = options.enums === String ? $root.api.ServiceSubscriptionType[message.serviceSubscriptionType] : message.serviceSubscriptionType;
            if (message.serviceSubscriptionInterval != null && message.hasOwnProperty("serviceSubscriptionInterval"))
                object.serviceSubscriptionInterval = options.enums === String ? $root.api.ServiceSubscriptionInterval[message.serviceSubscriptionInterval] : message.serviceSubscriptionInterval;
            if (message.serviceSubscriptionExpirationDate != null && message.hasOwnProperty("serviceSubscriptionExpirationDate"))
                if (typeof message.serviceSubscriptionExpirationDate === "number")
                    object.serviceSubscriptionExpirationDate = options.longs === String ? String(message.serviceSubscriptionExpirationDate) : message.serviceSubscriptionExpirationDate;
                else
                    object.serviceSubscriptionExpirationDate = options.longs === String ? $util.Long.prototype.toString.call(message.serviceSubscriptionExpirationDate) : options.longs === Number ? new $util.LongBits(message.serviceSubscriptionExpirationDate.low >>> 0, message.serviceSubscriptionExpirationDate.high >>> 0).toNumber() : message.serviceSubscriptionExpirationDate;
            if (message.serviceSubscriptions && message.serviceSubscriptions.length) {
                object.serviceSubscriptions = [];
                for (let j = 0; j < message.serviceSubscriptions.length; ++j)
                    object.serviceSubscriptions[j] = $root.api.ServiceSubscription.toObject(message.serviceSubscriptions[j], options);
            }
            if (message.couponCodes && message.couponCodes.length) {
                object.couponCodes = [];
                for (let j = 0; j < message.couponCodes.length; ++j)
                    object.couponCodes[j] = message.couponCodes[j];
            }
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                object.timeZone = message.timeZone;
            return object;
        };

        /**
         * Converts this User to JSON.
         * @function toJSON
         * @memberof api.User
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return User;
    })();

    api.GroupQuery = (function() {

        /**
         * Properties of a GroupQuery.
         * @memberof api
         * @interface IGroupQuery
         * @property {api.IMemberQuery|null} [membersOf] GroupQuery membersOf
         * @property {api.IMemberQuery|null} [members] GroupQuery members
         */

        /**
         * Constructs a new GroupQuery.
         * @memberof api
         * @classdesc Represents a GroupQuery.
         * @implements IGroupQuery
         * @constructor
         * @param {api.IGroupQuery=} [properties] Properties to set
         */
        function GroupQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GroupQuery membersOf.
         * @member {api.IMemberQuery|null|undefined} membersOf
         * @memberof api.GroupQuery
         * @instance
         */
        GroupQuery.prototype.membersOf = null;

        /**
         * GroupQuery members.
         * @member {api.IMemberQuery|null|undefined} members
         * @memberof api.GroupQuery
         * @instance
         */
        GroupQuery.prototype.members = null;

        /**
         * Creates a new GroupQuery instance using the specified properties.
         * @function create
         * @memberof api.GroupQuery
         * @static
         * @param {api.IGroupQuery=} [properties] Properties to set
         * @returns {api.GroupQuery} GroupQuery instance
         */
        GroupQuery.create = function create(properties) {
            return new GroupQuery(properties);
        };

        /**
         * Encodes the specified GroupQuery message. Does not implicitly {@link api.GroupQuery.verify|verify} messages.
         * @function encode
         * @memberof api.GroupQuery
         * @static
         * @param {api.IGroupQuery} message GroupQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.membersOf != null && Object.hasOwnProperty.call(message, "membersOf"))
                $root.api.MemberQuery.encode(message.membersOf, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.MemberQuery.encode(message.members, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GroupQuery message, length delimited. Does not implicitly {@link api.GroupQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GroupQuery
         * @static
         * @param {api.IGroupQuery} message GroupQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GroupQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.GroupQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GroupQuery} GroupQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GroupQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.membersOf = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.members = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GroupQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GroupQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GroupQuery} GroupQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GroupQuery message.
         * @function verify
         * @memberof api.GroupQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GroupQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                let error = $root.api.MemberQuery.verify(message.membersOf);
                if (error)
                    return "membersOf." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.MemberQuery.verify(message.members);
                if (error)
                    return "members." + error;
            }
            return null;
        };

        /**
         * Creates a GroupQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GroupQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GroupQuery} GroupQuery
         */
        GroupQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GroupQuery)
                return object;
            let message = new $root.api.GroupQuery();
            if (object.membersOf != null) {
                if (typeof object.membersOf !== "object")
                    throw TypeError(".api.GroupQuery.membersOf: object expected");
                message.membersOf = $root.api.MemberQuery.fromObject(object.membersOf);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.GroupQuery.members: object expected");
                message.members = $root.api.MemberQuery.fromObject(object.members);
            }
            return message;
        };

        /**
         * Creates a plain object from a GroupQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GroupQuery
         * @static
         * @param {api.GroupQuery} message GroupQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GroupQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.membersOf = null;
                object.members = null;
            }
            if (message.membersOf != null && message.hasOwnProperty("membersOf"))
                object.membersOf = $root.api.MemberQuery.toObject(message.membersOf, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.MemberQuery.toObject(message.members, options);
            return object;
        };

        /**
         * Converts this GroupQuery to JSON.
         * @function toJSON
         * @memberof api.GroupQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GroupQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GroupQuery;
    })();

    api.Group = (function() {

        /**
         * Properties of a Group.
         * @memberof api
         * @interface IGroup
         * @property {string|null} [groupId] Group groupId
         * @property {string|null} [groupName] Group groupName
         * @property {string|null} [description] Group description
         * @property {Array.<api.IMember>|null} [membersOf] Group membersOf
         * @property {Array.<api.IMember>|null} [members] Group members
         * @property {number|Long|null} [createDate] Group createDate
         * @property {number|Long|null} [updateDate] Group updateDate
         */

        /**
         * Constructs a new Group.
         * @memberof api
         * @classdesc Represents a Group.
         * @implements IGroup
         * @constructor
         * @param {api.IGroup=} [properties] Properties to set
         */
        function Group(properties) {
            this.membersOf = [];
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Group groupId.
         * @member {string} groupId
         * @memberof api.Group
         * @instance
         */
        Group.prototype.groupId = "";

        /**
         * Group groupName.
         * @member {string} groupName
         * @memberof api.Group
         * @instance
         */
        Group.prototype.groupName = "";

        /**
         * Group description.
         * @member {string} description
         * @memberof api.Group
         * @instance
         */
        Group.prototype.description = "";

        /**
         * Group membersOf.
         * @member {Array.<api.IMember>} membersOf
         * @memberof api.Group
         * @instance
         */
        Group.prototype.membersOf = $util.emptyArray;

        /**
         * Group members.
         * @member {Array.<api.IMember>} members
         * @memberof api.Group
         * @instance
         */
        Group.prototype.members = $util.emptyArray;

        /**
         * Group createDate.
         * @member {number|Long} createDate
         * @memberof api.Group
         * @instance
         */
        Group.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Group updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Group
         * @instance
         */
        Group.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Group instance using the specified properties.
         * @function create
         * @memberof api.Group
         * @static
         * @param {api.IGroup=} [properties] Properties to set
         * @returns {api.Group} Group instance
         */
        Group.create = function create(properties) {
            return new Group(properties);
        };

        /**
         * Encodes the specified Group message. Does not implicitly {@link api.Group.verify|verify} messages.
         * @function encode
         * @memberof api.Group
         * @static
         * @param {api.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            if (message.groupName != null && Object.hasOwnProperty.call(message, "groupName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.groupName);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.membersOf != null && message.membersOf.length)
                for (let i = 0; i < message.membersOf.length; ++i)
                    $root.api.Member.encode(message.membersOf[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.api.Member.encode(message.members[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.updateDate);
            return writer;
        };

        /**
         * Encodes the specified Group message, length delimited. Does not implicitly {@link api.Group.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Group
         * @static
         * @param {api.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Group message from the specified reader or buffer.
         * @function decode
         * @memberof api.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Group();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                case 2:
                    message.groupName = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 4:
                    if (!(message.membersOf && message.membersOf.length))
                        message.membersOf = [];
                    message.membersOf.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 5:
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.createDate = reader.int64();
                    break;
                case 7:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Group message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Group message.
         * @function verify
         * @memberof api.Group
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Group.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            if (message.groupName != null && message.hasOwnProperty("groupName"))
                if (!$util.isString(message.groupName))
                    return "groupName: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.membersOf != null && message.hasOwnProperty("membersOf")) {
                if (!Array.isArray(message.membersOf))
                    return "membersOf: array expected";
                for (let i = 0; i < message.membersOf.length; ++i) {
                    let error = $root.api.Member.verify(message.membersOf[i]);
                    if (error)
                        return "membersOf." + error;
                }
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.api.Member.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a Group message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Group
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Group} Group
         */
        Group.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Group)
                return object;
            let message = new $root.api.Group();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            if (object.groupName != null)
                message.groupName = String(object.groupName);
            if (object.description != null)
                message.description = String(object.description);
            if (object.membersOf) {
                if (!Array.isArray(object.membersOf))
                    throw TypeError(".api.Group.membersOf: array expected");
                message.membersOf = [];
                for (let i = 0; i < object.membersOf.length; ++i) {
                    if (typeof object.membersOf[i] !== "object")
                        throw TypeError(".api.Group.membersOf: object expected");
                    message.membersOf[i] = $root.api.Member.fromObject(object.membersOf[i]);
                }
            }
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.Group.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.Group.members: object expected");
                    message.members[i] = $root.api.Member.fromObject(object.members[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Group message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Group
         * @static
         * @param {api.Group} message Group
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Group.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.membersOf = [];
                object.members = [];
            }
            if (options.defaults) {
                object.groupId = "";
                object.groupName = "";
                object.description = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            if (message.groupName != null && message.hasOwnProperty("groupName"))
                object.groupName = message.groupName;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.membersOf && message.membersOf.length) {
                object.membersOf = [];
                for (let j = 0; j < message.membersOf.length; ++j)
                    object.membersOf[j] = $root.api.Member.toObject(message.membersOf[j], options);
            }
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.api.Member.toObject(message.members[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            return object;
        };

        /**
         * Converts this Group to JSON.
         * @function toJSON
         * @memberof api.Group
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Group.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Group;
    })();

    api.GetUserResponse = (function() {

        /**
         * Properties of a GetUserResponse.
         * @memberof api
         * @interface IGetUserResponse
         * @property {api.IUser|null} [user] GetUserResponse user
         */

        /**
         * Constructs a new GetUserResponse.
         * @memberof api
         * @classdesc Represents a GetUserResponse.
         * @implements IGetUserResponse
         * @constructor
         * @param {api.IGetUserResponse=} [properties] Properties to set
         */
        function GetUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserResponse user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.GetUserResponse
         * @instance
         */
        GetUserResponse.prototype.user = null;

        /**
         * Creates a new GetUserResponse instance using the specified properties.
         * @function create
         * @memberof api.GetUserResponse
         * @static
         * @param {api.IGetUserResponse=} [properties] Properties to set
         * @returns {api.GetUserResponse} GetUserResponse instance
         */
        GetUserResponse.create = function create(properties) {
            return new GetUserResponse(properties);
        };

        /**
         * Encodes the specified GetUserResponse message. Does not implicitly {@link api.GetUserResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetUserResponse
         * @static
         * @param {api.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserResponse message, length delimited. Does not implicitly {@link api.GetUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetUserResponse
         * @static
         * @param {api.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserResponse message.
         * @function verify
         * @memberof api.GetUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetUserResponse} GetUserResponse
         */
        GetUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetUserResponse)
                return object;
            let message = new $root.api.GetUserResponse();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.GetUserResponse.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetUserResponse
         * @static
         * @param {api.GetUserResponse} message GetUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this GetUserResponse to JSON.
         * @function toJSON
         * @memberof api.GetUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserResponse;
    })();

    api.GetGroupResponse = (function() {

        /**
         * Properties of a GetGroupResponse.
         * @memberof api
         * @interface IGetGroupResponse
         * @property {api.IGroup|null} [group] GetGroupResponse group
         */

        /**
         * Constructs a new GetGroupResponse.
         * @memberof api
         * @classdesc Represents a GetGroupResponse.
         * @implements IGetGroupResponse
         * @constructor
         * @param {api.IGetGroupResponse=} [properties] Properties to set
         */
        function GetGroupResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetGroupResponse group.
         * @member {api.IGroup|null|undefined} group
         * @memberof api.GetGroupResponse
         * @instance
         */
        GetGroupResponse.prototype.group = null;

        /**
         * Creates a new GetGroupResponse instance using the specified properties.
         * @function create
         * @memberof api.GetGroupResponse
         * @static
         * @param {api.IGetGroupResponse=} [properties] Properties to set
         * @returns {api.GetGroupResponse} GetGroupResponse instance
         */
        GetGroupResponse.create = function create(properties) {
            return new GetGroupResponse(properties);
        };

        /**
         * Encodes the specified GetGroupResponse message. Does not implicitly {@link api.GetGroupResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetGroupResponse
         * @static
         * @param {api.IGetGroupResponse} message GetGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGroupResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.Group.encode(message.group, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetGroupResponse message, length delimited. Does not implicitly {@link api.GetGroupResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetGroupResponse
         * @static
         * @param {api.IGetGroupResponse} message GetGroupResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetGroupResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetGroupResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetGroupResponse} GetGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGroupResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetGroupResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.group = $root.api.Group.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetGroupResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetGroupResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetGroupResponse} GetGroupResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetGroupResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetGroupResponse message.
         * @function verify
         * @memberof api.GetGroupResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetGroupResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.Group.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a GetGroupResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetGroupResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetGroupResponse} GetGroupResponse
         */
        GetGroupResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetGroupResponse)
                return object;
            let message = new $root.api.GetGroupResponse();
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.GetGroupResponse.group: object expected");
                message.group = $root.api.Group.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetGroupResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetGroupResponse
         * @static
         * @param {api.GetGroupResponse} message GetGroupResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetGroupResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.group = null;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.Group.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this GetGroupResponse to JSON.
         * @function toJSON
         * @memberof api.GetGroupResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetGroupResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetGroupResponse;
    })();

    api.AddAssetResponse = (function() {

        /**
         * Properties of an AddAssetResponse.
         * @memberof api
         * @interface IAddAssetResponse
         * @property {string|null} [uploadUrl] AddAssetResponse uploadUrl
         */

        /**
         * Constructs a new AddAssetResponse.
         * @memberof api
         * @classdesc Represents an AddAssetResponse.
         * @implements IAddAssetResponse
         * @constructor
         * @param {api.IAddAssetResponse=} [properties] Properties to set
         */
        function AddAssetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * AddAssetResponse uploadUrl.
         * @member {string} uploadUrl
         * @memberof api.AddAssetResponse
         * @instance
         */
        AddAssetResponse.prototype.uploadUrl = "";

        /**
         * Creates a new AddAssetResponse instance using the specified properties.
         * @function create
         * @memberof api.AddAssetResponse
         * @static
         * @param {api.IAddAssetResponse=} [properties] Properties to set
         * @returns {api.AddAssetResponse} AddAssetResponse instance
         */
        AddAssetResponse.create = function create(properties) {
            return new AddAssetResponse(properties);
        };

        /**
         * Encodes the specified AddAssetResponse message. Does not implicitly {@link api.AddAssetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.AddAssetResponse
         * @static
         * @param {api.IAddAssetResponse} message AddAssetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddAssetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uploadUrl != null && Object.hasOwnProperty.call(message, "uploadUrl"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.uploadUrl);
            return writer;
        };

        /**
         * Encodes the specified AddAssetResponse message, length delimited. Does not implicitly {@link api.AddAssetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.AddAssetResponse
         * @static
         * @param {api.IAddAssetResponse} message AddAssetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddAssetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an AddAssetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.AddAssetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.AddAssetResponse} AddAssetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddAssetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.AddAssetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.uploadUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an AddAssetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.AddAssetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.AddAssetResponse} AddAssetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddAssetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an AddAssetResponse message.
         * @function verify
         * @memberof api.AddAssetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddAssetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uploadUrl != null && message.hasOwnProperty("uploadUrl"))
                if (!$util.isString(message.uploadUrl))
                    return "uploadUrl: string expected";
            return null;
        };

        /**
         * Creates an AddAssetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.AddAssetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.AddAssetResponse} AddAssetResponse
         */
        AddAssetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.AddAssetResponse)
                return object;
            let message = new $root.api.AddAssetResponse();
            if (object.uploadUrl != null)
                message.uploadUrl = String(object.uploadUrl);
            return message;
        };

        /**
         * Creates a plain object from an AddAssetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.AddAssetResponse
         * @static
         * @param {api.AddAssetResponse} message AddAssetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddAssetResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.uploadUrl = "";
            if (message.uploadUrl != null && message.hasOwnProperty("uploadUrl"))
                object.uploadUrl = message.uploadUrl;
            return object;
        };

        /**
         * Converts this AddAssetResponse to JSON.
         * @function toJSON
         * @memberof api.AddAssetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddAssetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddAssetResponse;
    })();

    api.DeleteAssetResponse = (function() {

        /**
         * Properties of a DeleteAssetResponse.
         * @memberof api
         * @interface IDeleteAssetResponse
         */

        /**
         * Constructs a new DeleteAssetResponse.
         * @memberof api
         * @classdesc Represents a DeleteAssetResponse.
         * @implements IDeleteAssetResponse
         * @constructor
         * @param {api.IDeleteAssetResponse=} [properties] Properties to set
         */
        function DeleteAssetResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new DeleteAssetResponse instance using the specified properties.
         * @function create
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {api.IDeleteAssetResponse=} [properties] Properties to set
         * @returns {api.DeleteAssetResponse} DeleteAssetResponse instance
         */
        DeleteAssetResponse.create = function create(properties) {
            return new DeleteAssetResponse(properties);
        };

        /**
         * Encodes the specified DeleteAssetResponse message. Does not implicitly {@link api.DeleteAssetResponse.verify|verify} messages.
         * @function encode
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {api.IDeleteAssetResponse} message DeleteAssetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssetResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified DeleteAssetResponse message, length delimited. Does not implicitly {@link api.DeleteAssetResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {api.IDeleteAssetResponse} message DeleteAssetResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DeleteAssetResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DeleteAssetResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DeleteAssetResponse} DeleteAssetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssetResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DeleteAssetResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DeleteAssetResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DeleteAssetResponse} DeleteAssetResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DeleteAssetResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DeleteAssetResponse message.
         * @function verify
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DeleteAssetResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a DeleteAssetResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DeleteAssetResponse} DeleteAssetResponse
         */
        DeleteAssetResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DeleteAssetResponse)
                return object;
            return new $root.api.DeleteAssetResponse();
        };

        /**
         * Creates a plain object from a DeleteAssetResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DeleteAssetResponse
         * @static
         * @param {api.DeleteAssetResponse} message DeleteAssetResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DeleteAssetResponse.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this DeleteAssetResponse to JSON.
         * @function toJSON
         * @memberof api.DeleteAssetResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DeleteAssetResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DeleteAssetResponse;
    })();

    api.GetUserEventsResponse = (function() {

        /**
         * Properties of a GetUserEventsResponse.
         * @memberof api
         * @interface IGetUserEventsResponse
         * @property {Array.<api.IEvent>|null} [events] GetUserEventsResponse events
         */

        /**
         * Constructs a new GetUserEventsResponse.
         * @memberof api
         * @classdesc Represents a GetUserEventsResponse.
         * @implements IGetUserEventsResponse
         * @constructor
         * @param {api.IGetUserEventsResponse=} [properties] Properties to set
         */
        function GetUserEventsResponse(properties) {
            this.events = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserEventsResponse events.
         * @member {Array.<api.IEvent>} events
         * @memberof api.GetUserEventsResponse
         * @instance
         */
        GetUserEventsResponse.prototype.events = $util.emptyArray;

        /**
         * Creates a new GetUserEventsResponse instance using the specified properties.
         * @function create
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {api.IGetUserEventsResponse=} [properties] Properties to set
         * @returns {api.GetUserEventsResponse} GetUserEventsResponse instance
         */
        GetUserEventsResponse.create = function create(properties) {
            return new GetUserEventsResponse(properties);
        };

        /**
         * Encodes the specified GetUserEventsResponse message. Does not implicitly {@link api.GetUserEventsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {api.IGetUserEventsResponse} message GetUserEventsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserEventsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.events != null && message.events.length)
                for (let i = 0; i < message.events.length; ++i)
                    $root.api.Event.encode(message.events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserEventsResponse message, length delimited. Does not implicitly {@link api.GetUserEventsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {api.IGetUserEventsResponse} message GetUserEventsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserEventsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserEventsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GetUserEventsResponse} GetUserEventsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserEventsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GetUserEventsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.events && message.events.length))
                        message.events = [];
                    message.events.push($root.api.Event.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserEventsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GetUserEventsResponse} GetUserEventsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserEventsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserEventsResponse message.
         * @function verify
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserEventsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.events != null && message.hasOwnProperty("events")) {
                if (!Array.isArray(message.events))
                    return "events: array expected";
                for (let i = 0; i < message.events.length; ++i) {
                    let error = $root.api.Event.verify(message.events[i]);
                    if (error)
                        return "events." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetUserEventsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GetUserEventsResponse} GetUserEventsResponse
         */
        GetUserEventsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GetUserEventsResponse)
                return object;
            let message = new $root.api.GetUserEventsResponse();
            if (object.events) {
                if (!Array.isArray(object.events))
                    throw TypeError(".api.GetUserEventsResponse.events: array expected");
                message.events = [];
                for (let i = 0; i < object.events.length; ++i) {
                    if (typeof object.events[i] !== "object")
                        throw TypeError(".api.GetUserEventsResponse.events: object expected");
                    message.events[i] = $root.api.Event.fromObject(object.events[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserEventsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GetUserEventsResponse
         * @static
         * @param {api.GetUserEventsResponse} message GetUserEventsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserEventsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.events = [];
            if (message.events && message.events.length) {
                object.events = [];
                for (let j = 0; j < message.events.length; ++j)
                    object.events[j] = $root.api.Event.toObject(message.events[j], options);
            }
            return object;
        };

        /**
         * Converts this GetUserEventsResponse to JSON.
         * @function toJSON
         * @memberof api.GetUserEventsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserEventsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserEventsResponse;
    })();

    api.TimeQuery = (function() {

        /**
         * Properties of a TimeQuery.
         * @memberof api
         * @interface ITimeQuery
         * @property {api.IScheduleQuery|null} [schedule] TimeQuery schedule
         * @property {api.IRoomQuery|null} [room] TimeQuery room
         * @property {api.IMemberQuery|null} [members] TimeQuery members
         */

        /**
         * Constructs a new TimeQuery.
         * @memberof api
         * @classdesc Represents a TimeQuery.
         * @implements ITimeQuery
         * @constructor
         * @param {api.ITimeQuery=} [properties] Properties to set
         */
        function TimeQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeQuery schedule.
         * @member {api.IScheduleQuery|null|undefined} schedule
         * @memberof api.TimeQuery
         * @instance
         */
        TimeQuery.prototype.schedule = null;

        /**
         * TimeQuery room.
         * @member {api.IRoomQuery|null|undefined} room
         * @memberof api.TimeQuery
         * @instance
         */
        TimeQuery.prototype.room = null;

        /**
         * TimeQuery members.
         * @member {api.IMemberQuery|null|undefined} members
         * @memberof api.TimeQuery
         * @instance
         */
        TimeQuery.prototype.members = null;

        /**
         * Creates a new TimeQuery instance using the specified properties.
         * @function create
         * @memberof api.TimeQuery
         * @static
         * @param {api.ITimeQuery=} [properties] Properties to set
         * @returns {api.TimeQuery} TimeQuery instance
         */
        TimeQuery.create = function create(properties) {
            return new TimeQuery(properties);
        };

        /**
         * Encodes the specified TimeQuery message. Does not implicitly {@link api.TimeQuery.verify|verify} messages.
         * @function encode
         * @memberof api.TimeQuery
         * @static
         * @param {api.ITimeQuery} message TimeQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                $root.api.ScheduleQuery.encode(message.schedule, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.MemberQuery.encode(message.members, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.RoomQuery.encode(message.room, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TimeQuery message, length delimited. Does not implicitly {@link api.TimeQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TimeQuery
         * @static
         * @param {api.ITimeQuery} message TimeQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.TimeQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TimeQuery} TimeQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TimeQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.schedule = $root.api.ScheduleQuery.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.room = $root.api.RoomQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.members = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimeQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TimeQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TimeQuery} TimeQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeQuery message.
         * @function verify
         * @memberof api.TimeQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.schedule != null && message.hasOwnProperty("schedule")) {
                let error = $root.api.ScheduleQuery.verify(message.schedule);
                if (error)
                    return "schedule." + error;
            }
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.RoomQuery.verify(message.room);
                if (error)
                    return "room." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.MemberQuery.verify(message.members);
                if (error)
                    return "members." + error;
            }
            return null;
        };

        /**
         * Creates a TimeQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TimeQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TimeQuery} TimeQuery
         */
        TimeQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TimeQuery)
                return object;
            let message = new $root.api.TimeQuery();
            if (object.schedule != null) {
                if (typeof object.schedule !== "object")
                    throw TypeError(".api.TimeQuery.schedule: object expected");
                message.schedule = $root.api.ScheduleQuery.fromObject(object.schedule);
            }
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.TimeQuery.room: object expected");
                message.room = $root.api.RoomQuery.fromObject(object.room);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.TimeQuery.members: object expected");
                message.members = $root.api.MemberQuery.fromObject(object.members);
            }
            return message;
        };

        /**
         * Creates a plain object from a TimeQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TimeQuery
         * @static
         * @param {api.TimeQuery} message TimeQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.schedule = null;
                object.members = null;
                object.room = null;
            }
            if (message.schedule != null && message.hasOwnProperty("schedule"))
                object.schedule = $root.api.ScheduleQuery.toObject(message.schedule, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.MemberQuery.toObject(message.members, options);
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.RoomQuery.toObject(message.room, options);
            return object;
        };

        /**
         * Converts this TimeQuery to JSON.
         * @function toJSON
         * @memberof api.TimeQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TimeQuery;
    })();

    api.ScheduleSetQuery = (function() {

        /**
         * Properties of a ScheduleSetQuery.
         * @memberof api
         * @interface IScheduleSetQuery
         * @property {api.IScheduleQuery|null} [schedules] ScheduleSetQuery schedules
         * @property {api.IRoomQuery|null} [rooms] ScheduleSetQuery rooms
         */

        /**
         * Constructs a new ScheduleSetQuery.
         * @memberof api
         * @classdesc Represents a ScheduleSetQuery.
         * @implements IScheduleSetQuery
         * @constructor
         * @param {api.IScheduleSetQuery=} [properties] Properties to set
         */
        function ScheduleSetQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScheduleSetQuery schedules.
         * @member {api.IScheduleQuery|null|undefined} schedules
         * @memberof api.ScheduleSetQuery
         * @instance
         */
        ScheduleSetQuery.prototype.schedules = null;

        /**
         * ScheduleSetQuery rooms.
         * @member {api.IRoomQuery|null|undefined} rooms
         * @memberof api.ScheduleSetQuery
         * @instance
         */
        ScheduleSetQuery.prototype.rooms = null;

        /**
         * Creates a new ScheduleSetQuery instance using the specified properties.
         * @function create
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {api.IScheduleSetQuery=} [properties] Properties to set
         * @returns {api.ScheduleSetQuery} ScheduleSetQuery instance
         */
        ScheduleSetQuery.create = function create(properties) {
            return new ScheduleSetQuery(properties);
        };

        /**
         * Encodes the specified ScheduleSetQuery message. Does not implicitly {@link api.ScheduleSetQuery.verify|verify} messages.
         * @function encode
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {api.IScheduleSetQuery} message ScheduleSetQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSetQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.schedules != null && Object.hasOwnProperty.call(message, "schedules"))
                $root.api.ScheduleQuery.encode(message.schedules, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.rooms != null && Object.hasOwnProperty.call(message, "rooms"))
                $root.api.RoomQuery.encode(message.rooms, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ScheduleSetQuery message, length delimited. Does not implicitly {@link api.ScheduleSetQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {api.IScheduleSetQuery} message ScheduleSetQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSetQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScheduleSetQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ScheduleSetQuery} ScheduleSetQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSetQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ScheduleSetQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.schedules = $root.api.ScheduleQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.rooms = $root.api.RoomQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScheduleSetQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ScheduleSetQuery} ScheduleSetQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSetQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScheduleSetQuery message.
         * @function verify
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScheduleSetQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.schedules != null && message.hasOwnProperty("schedules")) {
                let error = $root.api.ScheduleQuery.verify(message.schedules);
                if (error)
                    return "schedules." + error;
            }
            if (message.rooms != null && message.hasOwnProperty("rooms")) {
                let error = $root.api.RoomQuery.verify(message.rooms);
                if (error)
                    return "rooms." + error;
            }
            return null;
        };

        /**
         * Creates a ScheduleSetQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ScheduleSetQuery} ScheduleSetQuery
         */
        ScheduleSetQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ScheduleSetQuery)
                return object;
            let message = new $root.api.ScheduleSetQuery();
            if (object.schedules != null) {
                if (typeof object.schedules !== "object")
                    throw TypeError(".api.ScheduleSetQuery.schedules: object expected");
                message.schedules = $root.api.ScheduleQuery.fromObject(object.schedules);
            }
            if (object.rooms != null) {
                if (typeof object.rooms !== "object")
                    throw TypeError(".api.ScheduleSetQuery.rooms: object expected");
                message.rooms = $root.api.RoomQuery.fromObject(object.rooms);
            }
            return message;
        };

        /**
         * Creates a plain object from a ScheduleSetQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ScheduleSetQuery
         * @static
         * @param {api.ScheduleSetQuery} message ScheduleSetQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScheduleSetQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.schedules = null;
                object.rooms = null;
            }
            if (message.schedules != null && message.hasOwnProperty("schedules"))
                object.schedules = $root.api.ScheduleQuery.toObject(message.schedules, options);
            if (message.rooms != null && message.hasOwnProperty("rooms"))
                object.rooms = $root.api.RoomQuery.toObject(message.rooms, options);
            return object;
        };

        /**
         * Converts this ScheduleSetQuery to JSON.
         * @function toJSON
         * @memberof api.ScheduleSetQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScheduleSetQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ScheduleSetQuery;
    })();

    api.ScheduleQuery = (function() {

        /**
         * Properties of a ScheduleQuery.
         * @memberof api
         * @interface IScheduleQuery
         * @property {api.IScheduleSetQuery|null} [scheduleSet] ScheduleQuery scheduleSet
         * @property {api.ITimeQuery|null} [times] ScheduleQuery times
         */

        /**
         * Constructs a new ScheduleQuery.
         * @memberof api
         * @classdesc Represents a ScheduleQuery.
         * @implements IScheduleQuery
         * @constructor
         * @param {api.IScheduleQuery=} [properties] Properties to set
         */
        function ScheduleQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScheduleQuery scheduleSet.
         * @member {api.IScheduleSetQuery|null|undefined} scheduleSet
         * @memberof api.ScheduleQuery
         * @instance
         */
        ScheduleQuery.prototype.scheduleSet = null;

        /**
         * ScheduleQuery times.
         * @member {api.ITimeQuery|null|undefined} times
         * @memberof api.ScheduleQuery
         * @instance
         */
        ScheduleQuery.prototype.times = null;

        /**
         * Creates a new ScheduleQuery instance using the specified properties.
         * @function create
         * @memberof api.ScheduleQuery
         * @static
         * @param {api.IScheduleQuery=} [properties] Properties to set
         * @returns {api.ScheduleQuery} ScheduleQuery instance
         */
        ScheduleQuery.create = function create(properties) {
            return new ScheduleQuery(properties);
        };

        /**
         * Encodes the specified ScheduleQuery message. Does not implicitly {@link api.ScheduleQuery.verify|verify} messages.
         * @function encode
         * @memberof api.ScheduleQuery
         * @static
         * @param {api.IScheduleQuery} message ScheduleQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.times != null && Object.hasOwnProperty.call(message, "times"))
                $root.api.TimeQuery.encode(message.times, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSetQuery.encode(message.scheduleSet, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ScheduleQuery message, length delimited. Does not implicitly {@link api.ScheduleQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ScheduleQuery
         * @static
         * @param {api.IScheduleQuery} message ScheduleQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScheduleQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.ScheduleQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ScheduleQuery} ScheduleQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ScheduleQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.scheduleSet = $root.api.ScheduleSetQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.times = $root.api.TimeQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScheduleQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ScheduleQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ScheduleQuery} ScheduleQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScheduleQuery message.
         * @function verify
         * @memberof api.ScheduleQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScheduleQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSetQuery.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            if (message.times != null && message.hasOwnProperty("times")) {
                let error = $root.api.TimeQuery.verify(message.times);
                if (error)
                    return "times." + error;
            }
            return null;
        };

        /**
         * Creates a ScheduleQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ScheduleQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ScheduleQuery} ScheduleQuery
         */
        ScheduleQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ScheduleQuery)
                return object;
            let message = new $root.api.ScheduleQuery();
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.ScheduleQuery.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSetQuery.fromObject(object.scheduleSet);
            }
            if (object.times != null) {
                if (typeof object.times !== "object")
                    throw TypeError(".api.ScheduleQuery.times: object expected");
                message.times = $root.api.TimeQuery.fromObject(object.times);
            }
            return message;
        };

        /**
         * Creates a plain object from a ScheduleQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ScheduleQuery
         * @static
         * @param {api.ScheduleQuery} message ScheduleQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScheduleQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.times = null;
                object.scheduleSet = null;
            }
            if (message.times != null && message.hasOwnProperty("times"))
                object.times = $root.api.TimeQuery.toObject(message.times, options);
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSetQuery.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this ScheduleQuery to JSON.
         * @function toJSON
         * @memberof api.ScheduleQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScheduleQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ScheduleQuery;
    })();

    api.TopicReviewQuery = (function() {

        /**
         * Properties of a TopicReviewQuery.
         * @memberof api
         * @interface ITopicReviewQuery
         */

        /**
         * Constructs a new TopicReviewQuery.
         * @memberof api
         * @classdesc Represents a TopicReviewQuery.
         * @implements ITopicReviewQuery
         * @constructor
         * @param {api.ITopicReviewQuery=} [properties] Properties to set
         */
        function TopicReviewQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TopicReviewQuery instance using the specified properties.
         * @function create
         * @memberof api.TopicReviewQuery
         * @static
         * @param {api.ITopicReviewQuery=} [properties] Properties to set
         * @returns {api.TopicReviewQuery} TopicReviewQuery instance
         */
        TopicReviewQuery.create = function create(properties) {
            return new TopicReviewQuery(properties);
        };

        /**
         * Encodes the specified TopicReviewQuery message. Does not implicitly {@link api.TopicReviewQuery.verify|verify} messages.
         * @function encode
         * @memberof api.TopicReviewQuery
         * @static
         * @param {api.ITopicReviewQuery} message TopicReviewQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicReviewQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TopicReviewQuery message, length delimited. Does not implicitly {@link api.TopicReviewQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TopicReviewQuery
         * @static
         * @param {api.ITopicReviewQuery} message TopicReviewQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicReviewQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TopicReviewQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.TopicReviewQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TopicReviewQuery} TopicReviewQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicReviewQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TopicReviewQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TopicReviewQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TopicReviewQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TopicReviewQuery} TopicReviewQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicReviewQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TopicReviewQuery message.
         * @function verify
         * @memberof api.TopicReviewQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TopicReviewQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TopicReviewQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TopicReviewQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TopicReviewQuery} TopicReviewQuery
         */
        TopicReviewQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TopicReviewQuery)
                return object;
            return new $root.api.TopicReviewQuery();
        };

        /**
         * Creates a plain object from a TopicReviewQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TopicReviewQuery
         * @static
         * @param {api.TopicReviewQuery} message TopicReviewQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicReviewQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TopicReviewQuery to JSON.
         * @function toJSON
         * @memberof api.TopicReviewQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicReviewQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicReviewQuery;
    })();

    api.DailySchedule = (function() {

        /**
         * Properties of a DailySchedule.
         * @memberof api
         * @interface IDailySchedule
         * @property {number|null} [interval] DailySchedule interval
         */

        /**
         * Constructs a new DailySchedule.
         * @memberof api
         * @classdesc Represents a DailySchedule.
         * @implements IDailySchedule
         * @constructor
         * @param {api.IDailySchedule=} [properties] Properties to set
         */
        function DailySchedule(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DailySchedule interval.
         * @member {number} interval
         * @memberof api.DailySchedule
         * @instance
         */
        DailySchedule.prototype.interval = 0;

        /**
         * Creates a new DailySchedule instance using the specified properties.
         * @function create
         * @memberof api.DailySchedule
         * @static
         * @param {api.IDailySchedule=} [properties] Properties to set
         * @returns {api.DailySchedule} DailySchedule instance
         */
        DailySchedule.create = function create(properties) {
            return new DailySchedule(properties);
        };

        /**
         * Encodes the specified DailySchedule message. Does not implicitly {@link api.DailySchedule.verify|verify} messages.
         * @function encode
         * @memberof api.DailySchedule
         * @static
         * @param {api.IDailySchedule} message DailySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DailySchedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.interval);
            return writer;
        };

        /**
         * Encodes the specified DailySchedule message, length delimited. Does not implicitly {@link api.DailySchedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.DailySchedule
         * @static
         * @param {api.IDailySchedule} message DailySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DailySchedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DailySchedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.DailySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.DailySchedule} DailySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DailySchedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.DailySchedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.interval = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DailySchedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.DailySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.DailySchedule} DailySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DailySchedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DailySchedule message.
         * @function verify
         * @memberof api.DailySchedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DailySchedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.interval != null && message.hasOwnProperty("interval"))
                if (!$util.isInteger(message.interval))
                    return "interval: integer expected";
            return null;
        };

        /**
         * Creates a DailySchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.DailySchedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.DailySchedule} DailySchedule
         */
        DailySchedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.DailySchedule)
                return object;
            let message = new $root.api.DailySchedule();
            if (object.interval != null)
                message.interval = object.interval | 0;
            return message;
        };

        /**
         * Creates a plain object from a DailySchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.DailySchedule
         * @static
         * @param {api.DailySchedule} message DailySchedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DailySchedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.interval = 0;
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = message.interval;
            return object;
        };

        /**
         * Converts this DailySchedule to JSON.
         * @function toJSON
         * @memberof api.DailySchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DailySchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DailySchedule;
    })();

    api.WeeklySchedule = (function() {

        /**
         * Properties of a WeeklySchedule.
         * @memberof api
         * @interface IWeeklySchedule
         * @property {number|null} [interval] WeeklySchedule interval
         * @property {Array.<number>|null} [weekDays] WeeklySchedule weekDays
         */

        /**
         * Constructs a new WeeklySchedule.
         * @memberof api
         * @classdesc Represents a WeeklySchedule.
         * @implements IWeeklySchedule
         * @constructor
         * @param {api.IWeeklySchedule=} [properties] Properties to set
         */
        function WeeklySchedule(properties) {
            this.weekDays = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WeeklySchedule interval.
         * @member {number} interval
         * @memberof api.WeeklySchedule
         * @instance
         */
        WeeklySchedule.prototype.interval = 0;

        /**
         * WeeklySchedule weekDays.
         * @member {Array.<number>} weekDays
         * @memberof api.WeeklySchedule
         * @instance
         */
        WeeklySchedule.prototype.weekDays = $util.emptyArray;

        /**
         * Creates a new WeeklySchedule instance using the specified properties.
         * @function create
         * @memberof api.WeeklySchedule
         * @static
         * @param {api.IWeeklySchedule=} [properties] Properties to set
         * @returns {api.WeeklySchedule} WeeklySchedule instance
         */
        WeeklySchedule.create = function create(properties) {
            return new WeeklySchedule(properties);
        };

        /**
         * Encodes the specified WeeklySchedule message. Does not implicitly {@link api.WeeklySchedule.verify|verify} messages.
         * @function encode
         * @memberof api.WeeklySchedule
         * @static
         * @param {api.IWeeklySchedule} message WeeklySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WeeklySchedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.interval);
            if (message.weekDays != null && message.weekDays.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.weekDays.length; ++i)
                    writer.int32(message.weekDays[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified WeeklySchedule message, length delimited. Does not implicitly {@link api.WeeklySchedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.WeeklySchedule
         * @static
         * @param {api.IWeeklySchedule} message WeeklySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WeeklySchedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WeeklySchedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.WeeklySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.WeeklySchedule} WeeklySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WeeklySchedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.WeeklySchedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.interval = reader.int32();
                    break;
                case 2:
                    if (!(message.weekDays && message.weekDays.length))
                        message.weekDays = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.weekDays.push(reader.int32());
                    } else
                        message.weekDays.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WeeklySchedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.WeeklySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.WeeklySchedule} WeeklySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WeeklySchedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WeeklySchedule message.
         * @function verify
         * @memberof api.WeeklySchedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WeeklySchedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.interval != null && message.hasOwnProperty("interval"))
                if (!$util.isInteger(message.interval))
                    return "interval: integer expected";
            if (message.weekDays != null && message.hasOwnProperty("weekDays")) {
                if (!Array.isArray(message.weekDays))
                    return "weekDays: array expected";
                for (let i = 0; i < message.weekDays.length; ++i)
                    if (!$util.isInteger(message.weekDays[i]))
                        return "weekDays: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a WeeklySchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.WeeklySchedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.WeeklySchedule} WeeklySchedule
         */
        WeeklySchedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.WeeklySchedule)
                return object;
            let message = new $root.api.WeeklySchedule();
            if (object.interval != null)
                message.interval = object.interval | 0;
            if (object.weekDays) {
                if (!Array.isArray(object.weekDays))
                    throw TypeError(".api.WeeklySchedule.weekDays: array expected");
                message.weekDays = [];
                for (let i = 0; i < object.weekDays.length; ++i)
                    message.weekDays[i] = object.weekDays[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a WeeklySchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.WeeklySchedule
         * @static
         * @param {api.WeeklySchedule} message WeeklySchedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WeeklySchedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.weekDays = [];
            if (options.defaults)
                object.interval = 0;
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = message.interval;
            if (message.weekDays && message.weekDays.length) {
                object.weekDays = [];
                for (let j = 0; j < message.weekDays.length; ++j)
                    object.weekDays[j] = message.weekDays[j];
            }
            return object;
        };

        /**
         * Converts this WeeklySchedule to JSON.
         * @function toJSON
         * @memberof api.WeeklySchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WeeklySchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WeeklySchedule;
    })();

    api.MonthlyDaySchedule = (function() {

        /**
         * Properties of a MonthlyDaySchedule.
         * @memberof api
         * @interface IMonthlyDaySchedule
         * @property {Array.<number>|null} [monthlyDay] MonthlyDaySchedule monthlyDay
         */

        /**
         * Constructs a new MonthlyDaySchedule.
         * @memberof api
         * @classdesc Represents a MonthlyDaySchedule.
         * @implements IMonthlyDaySchedule
         * @constructor
         * @param {api.IMonthlyDaySchedule=} [properties] Properties to set
         */
        function MonthlyDaySchedule(properties) {
            this.monthlyDay = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonthlyDaySchedule monthlyDay.
         * @member {Array.<number>} monthlyDay
         * @memberof api.MonthlyDaySchedule
         * @instance
         */
        MonthlyDaySchedule.prototype.monthlyDay = $util.emptyArray;

        /**
         * Creates a new MonthlyDaySchedule instance using the specified properties.
         * @function create
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {api.IMonthlyDaySchedule=} [properties] Properties to set
         * @returns {api.MonthlyDaySchedule} MonthlyDaySchedule instance
         */
        MonthlyDaySchedule.create = function create(properties) {
            return new MonthlyDaySchedule(properties);
        };

        /**
         * Encodes the specified MonthlyDaySchedule message. Does not implicitly {@link api.MonthlyDaySchedule.verify|verify} messages.
         * @function encode
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {api.IMonthlyDaySchedule} message MonthlyDaySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonthlyDaySchedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.monthlyDay != null && message.monthlyDay.length) {
                writer.uint32(/* id 1, wireType 2 =*/10).fork();
                for (let i = 0; i < message.monthlyDay.length; ++i)
                    writer.int32(message.monthlyDay[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified MonthlyDaySchedule message, length delimited. Does not implicitly {@link api.MonthlyDaySchedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {api.IMonthlyDaySchedule} message MonthlyDaySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonthlyDaySchedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MonthlyDaySchedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MonthlyDaySchedule} MonthlyDaySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonthlyDaySchedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MonthlyDaySchedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.monthlyDay && message.monthlyDay.length))
                        message.monthlyDay = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.monthlyDay.push(reader.int32());
                    } else
                        message.monthlyDay.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MonthlyDaySchedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MonthlyDaySchedule} MonthlyDaySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonthlyDaySchedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MonthlyDaySchedule message.
         * @function verify
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonthlyDaySchedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.monthlyDay != null && message.hasOwnProperty("monthlyDay")) {
                if (!Array.isArray(message.monthlyDay))
                    return "monthlyDay: array expected";
                for (let i = 0; i < message.monthlyDay.length; ++i)
                    if (!$util.isInteger(message.monthlyDay[i]))
                        return "monthlyDay: integer[] expected";
            }
            return null;
        };

        /**
         * Creates a MonthlyDaySchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MonthlyDaySchedule} MonthlyDaySchedule
         */
        MonthlyDaySchedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MonthlyDaySchedule)
                return object;
            let message = new $root.api.MonthlyDaySchedule();
            if (object.monthlyDay) {
                if (!Array.isArray(object.monthlyDay))
                    throw TypeError(".api.MonthlyDaySchedule.monthlyDay: array expected");
                message.monthlyDay = [];
                for (let i = 0; i < object.monthlyDay.length; ++i)
                    message.monthlyDay[i] = object.monthlyDay[i] | 0;
            }
            return message;
        };

        /**
         * Creates a plain object from a MonthlyDaySchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MonthlyDaySchedule
         * @static
         * @param {api.MonthlyDaySchedule} message MonthlyDaySchedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonthlyDaySchedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.monthlyDay = [];
            if (message.monthlyDay && message.monthlyDay.length) {
                object.monthlyDay = [];
                for (let j = 0; j < message.monthlyDay.length; ++j)
                    object.monthlyDay[j] = message.monthlyDay[j];
            }
            return object;
        };

        /**
         * Converts this MonthlyDaySchedule to JSON.
         * @function toJSON
         * @memberof api.MonthlyDaySchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonthlyDaySchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MonthlyDaySchedule;
    })();

    api.SelectiveSchedule = (function() {

        /**
         * Properties of a SelectiveSchedule.
         * @memberof api
         * @interface ISelectiveSchedule
         * @property {number|null} [selector] SelectiveSchedule selector
         * @property {number|null} [choice] SelectiveSchedule choice
         */

        /**
         * Constructs a new SelectiveSchedule.
         * @memberof api
         * @classdesc Represents a SelectiveSchedule.
         * @implements ISelectiveSchedule
         * @constructor
         * @param {api.ISelectiveSchedule=} [properties] Properties to set
         */
        function SelectiveSchedule(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SelectiveSchedule selector.
         * @member {number} selector
         * @memberof api.SelectiveSchedule
         * @instance
         */
        SelectiveSchedule.prototype.selector = 0;

        /**
         * SelectiveSchedule choice.
         * @member {number} choice
         * @memberof api.SelectiveSchedule
         * @instance
         */
        SelectiveSchedule.prototype.choice = 0;

        /**
         * Creates a new SelectiveSchedule instance using the specified properties.
         * @function create
         * @memberof api.SelectiveSchedule
         * @static
         * @param {api.ISelectiveSchedule=} [properties] Properties to set
         * @returns {api.SelectiveSchedule} SelectiveSchedule instance
         */
        SelectiveSchedule.create = function create(properties) {
            return new SelectiveSchedule(properties);
        };

        /**
         * Encodes the specified SelectiveSchedule message. Does not implicitly {@link api.SelectiveSchedule.verify|verify} messages.
         * @function encode
         * @memberof api.SelectiveSchedule
         * @static
         * @param {api.ISelectiveSchedule} message SelectiveSchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectiveSchedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.selector != null && Object.hasOwnProperty.call(message, "selector"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.selector);
            if (message.choice != null && Object.hasOwnProperty.call(message, "choice"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.choice);
            return writer;
        };

        /**
         * Encodes the specified SelectiveSchedule message, length delimited. Does not implicitly {@link api.SelectiveSchedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SelectiveSchedule
         * @static
         * @param {api.ISelectiveSchedule} message SelectiveSchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectiveSchedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SelectiveSchedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.SelectiveSchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SelectiveSchedule} SelectiveSchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectiveSchedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SelectiveSchedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.selector = reader.int32();
                    break;
                case 2:
                    message.choice = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SelectiveSchedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SelectiveSchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SelectiveSchedule} SelectiveSchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectiveSchedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SelectiveSchedule message.
         * @function verify
         * @memberof api.SelectiveSchedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SelectiveSchedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.selector != null && message.hasOwnProperty("selector"))
                if (!$util.isInteger(message.selector))
                    return "selector: integer expected";
            if (message.choice != null && message.hasOwnProperty("choice"))
                if (!$util.isInteger(message.choice))
                    return "choice: integer expected";
            return null;
        };

        /**
         * Creates a SelectiveSchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SelectiveSchedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SelectiveSchedule} SelectiveSchedule
         */
        SelectiveSchedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SelectiveSchedule)
                return object;
            let message = new $root.api.SelectiveSchedule();
            if (object.selector != null)
                message.selector = object.selector | 0;
            if (object.choice != null)
                message.choice = object.choice | 0;
            return message;
        };

        /**
         * Creates a plain object from a SelectiveSchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SelectiveSchedule
         * @static
         * @param {api.SelectiveSchedule} message SelectiveSchedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SelectiveSchedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.selector = 0;
                object.choice = 0;
            }
            if (message.selector != null && message.hasOwnProperty("selector"))
                object.selector = message.selector;
            if (message.choice != null && message.hasOwnProperty("choice"))
                object.choice = message.choice;
            return object;
        };

        /**
         * Converts this SelectiveSchedule to JSON.
         * @function toJSON
         * @memberof api.SelectiveSchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SelectiveSchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SelectiveSchedule;
    })();

    api.MonthlySchedule = (function() {

        /**
         * Properties of a MonthlySchedule.
         * @memberof api
         * @interface IMonthlySchedule
         * @property {number|null} [interval] MonthlySchedule interval
         * @property {api.IMonthlyDaySchedule|null} [monthlyDays] MonthlySchedule monthlyDays
         * @property {api.ISelectiveSchedule|null} [selectiveInterval] MonthlySchedule selectiveInterval
         */

        /**
         * Constructs a new MonthlySchedule.
         * @memberof api
         * @classdesc Represents a MonthlySchedule.
         * @implements IMonthlySchedule
         * @constructor
         * @param {api.IMonthlySchedule=} [properties] Properties to set
         */
        function MonthlySchedule(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MonthlySchedule interval.
         * @member {number} interval
         * @memberof api.MonthlySchedule
         * @instance
         */
        MonthlySchedule.prototype.interval = 0;

        /**
         * MonthlySchedule monthlyDays.
         * @member {api.IMonthlyDaySchedule|null|undefined} monthlyDays
         * @memberof api.MonthlySchedule
         * @instance
         */
        MonthlySchedule.prototype.monthlyDays = null;

        /**
         * MonthlySchedule selectiveInterval.
         * @member {api.ISelectiveSchedule|null|undefined} selectiveInterval
         * @memberof api.MonthlySchedule
         * @instance
         */
        MonthlySchedule.prototype.selectiveInterval = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * MonthlySchedule monthlyFrequency.
         * @member {"monthlyDays"|"selectiveInterval"|undefined} monthlyFrequency
         * @memberof api.MonthlySchedule
         * @instance
         */
        Object.defineProperty(MonthlySchedule.prototype, "monthlyFrequency", {
            get: $util.oneOfGetter($oneOfFields = ["monthlyDays", "selectiveInterval"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new MonthlySchedule instance using the specified properties.
         * @function create
         * @memberof api.MonthlySchedule
         * @static
         * @param {api.IMonthlySchedule=} [properties] Properties to set
         * @returns {api.MonthlySchedule} MonthlySchedule instance
         */
        MonthlySchedule.create = function create(properties) {
            return new MonthlySchedule(properties);
        };

        /**
         * Encodes the specified MonthlySchedule message. Does not implicitly {@link api.MonthlySchedule.verify|verify} messages.
         * @function encode
         * @memberof api.MonthlySchedule
         * @static
         * @param {api.IMonthlySchedule} message MonthlySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonthlySchedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.interval);
            if (message.monthlyDays != null && Object.hasOwnProperty.call(message, "monthlyDays"))
                $root.api.MonthlyDaySchedule.encode(message.monthlyDays, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.selectiveInterval != null && Object.hasOwnProperty.call(message, "selectiveInterval"))
                $root.api.SelectiveSchedule.encode(message.selectiveInterval, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MonthlySchedule message, length delimited. Does not implicitly {@link api.MonthlySchedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MonthlySchedule
         * @static
         * @param {api.IMonthlySchedule} message MonthlySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MonthlySchedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MonthlySchedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.MonthlySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MonthlySchedule} MonthlySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonthlySchedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MonthlySchedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.interval = reader.int32();
                    break;
                case 2:
                    message.monthlyDays = $root.api.MonthlyDaySchedule.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.selectiveInterval = $root.api.SelectiveSchedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MonthlySchedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MonthlySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MonthlySchedule} MonthlySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MonthlySchedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MonthlySchedule message.
         * @function verify
         * @memberof api.MonthlySchedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MonthlySchedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.interval != null && message.hasOwnProperty("interval"))
                if (!$util.isInteger(message.interval))
                    return "interval: integer expected";
            if (message.monthlyDays != null && message.hasOwnProperty("monthlyDays")) {
                properties.monthlyFrequency = 1;
                {
                    let error = $root.api.MonthlyDaySchedule.verify(message.monthlyDays);
                    if (error)
                        return "monthlyDays." + error;
                }
            }
            if (message.selectiveInterval != null && message.hasOwnProperty("selectiveInterval")) {
                if (properties.monthlyFrequency === 1)
                    return "monthlyFrequency: multiple values";
                properties.monthlyFrequency = 1;
                {
                    let error = $root.api.SelectiveSchedule.verify(message.selectiveInterval);
                    if (error)
                        return "selectiveInterval." + error;
                }
            }
            return null;
        };

        /**
         * Creates a MonthlySchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MonthlySchedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MonthlySchedule} MonthlySchedule
         */
        MonthlySchedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MonthlySchedule)
                return object;
            let message = new $root.api.MonthlySchedule();
            if (object.interval != null)
                message.interval = object.interval | 0;
            if (object.monthlyDays != null) {
                if (typeof object.monthlyDays !== "object")
                    throw TypeError(".api.MonthlySchedule.monthlyDays: object expected");
                message.monthlyDays = $root.api.MonthlyDaySchedule.fromObject(object.monthlyDays);
            }
            if (object.selectiveInterval != null) {
                if (typeof object.selectiveInterval !== "object")
                    throw TypeError(".api.MonthlySchedule.selectiveInterval: object expected");
                message.selectiveInterval = $root.api.SelectiveSchedule.fromObject(object.selectiveInterval);
            }
            return message;
        };

        /**
         * Creates a plain object from a MonthlySchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MonthlySchedule
         * @static
         * @param {api.MonthlySchedule} message MonthlySchedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MonthlySchedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.interval = 0;
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = message.interval;
            if (message.monthlyDays != null && message.hasOwnProperty("monthlyDays")) {
                object.monthlyDays = $root.api.MonthlyDaySchedule.toObject(message.monthlyDays, options);
                if (options.oneofs)
                    object.monthlyFrequency = "monthlyDays";
            }
            if (message.selectiveInterval != null && message.hasOwnProperty("selectiveInterval")) {
                object.selectiveInterval = $root.api.SelectiveSchedule.toObject(message.selectiveInterval, options);
                if (options.oneofs)
                    object.monthlyFrequency = "selectiveInterval";
            }
            return object;
        };

        /**
         * Converts this MonthlySchedule to JSON.
         * @function toJSON
         * @memberof api.MonthlySchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MonthlySchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MonthlySchedule;
    })();

    api.YearlySchedule = (function() {

        /**
         * Properties of a YearlySchedule.
         * @memberof api
         * @interface IYearlySchedule
         * @property {number|null} [interval] YearlySchedule interval
         * @property {Array.<number>|null} [months] YearlySchedule months
         * @property {api.ISelectiveSchedule|null} [selectiveInterval] YearlySchedule selectiveInterval
         */

        /**
         * Constructs a new YearlySchedule.
         * @memberof api
         * @classdesc Represents a YearlySchedule.
         * @implements IYearlySchedule
         * @constructor
         * @param {api.IYearlySchedule=} [properties] Properties to set
         */
        function YearlySchedule(properties) {
            this.months = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * YearlySchedule interval.
         * @member {number} interval
         * @memberof api.YearlySchedule
         * @instance
         */
        YearlySchedule.prototype.interval = 0;

        /**
         * YearlySchedule months.
         * @member {Array.<number>} months
         * @memberof api.YearlySchedule
         * @instance
         */
        YearlySchedule.prototype.months = $util.emptyArray;

        /**
         * YearlySchedule selectiveInterval.
         * @member {api.ISelectiveSchedule|null|undefined} selectiveInterval
         * @memberof api.YearlySchedule
         * @instance
         */
        YearlySchedule.prototype.selectiveInterval = null;

        /**
         * Creates a new YearlySchedule instance using the specified properties.
         * @function create
         * @memberof api.YearlySchedule
         * @static
         * @param {api.IYearlySchedule=} [properties] Properties to set
         * @returns {api.YearlySchedule} YearlySchedule instance
         */
        YearlySchedule.create = function create(properties) {
            return new YearlySchedule(properties);
        };

        /**
         * Encodes the specified YearlySchedule message. Does not implicitly {@link api.YearlySchedule.verify|verify} messages.
         * @function encode
         * @memberof api.YearlySchedule
         * @static
         * @param {api.IYearlySchedule} message YearlySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        YearlySchedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.interval != null && Object.hasOwnProperty.call(message, "interval"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.interval);
            if (message.months != null && message.months.length) {
                writer.uint32(/* id 2, wireType 2 =*/18).fork();
                for (let i = 0; i < message.months.length; ++i)
                    writer.int32(message.months[i]);
                writer.ldelim();
            }
            if (message.selectiveInterval != null && Object.hasOwnProperty.call(message, "selectiveInterval"))
                $root.api.SelectiveSchedule.encode(message.selectiveInterval, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified YearlySchedule message, length delimited. Does not implicitly {@link api.YearlySchedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.YearlySchedule
         * @static
         * @param {api.IYearlySchedule} message YearlySchedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        YearlySchedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a YearlySchedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.YearlySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.YearlySchedule} YearlySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        YearlySchedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.YearlySchedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.interval = reader.int32();
                    break;
                case 2:
                    if (!(message.months && message.months.length))
                        message.months = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.months.push(reader.int32());
                    } else
                        message.months.push(reader.int32());
                    break;
                case 3:
                    message.selectiveInterval = $root.api.SelectiveSchedule.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a YearlySchedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.YearlySchedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.YearlySchedule} YearlySchedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        YearlySchedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a YearlySchedule message.
         * @function verify
         * @memberof api.YearlySchedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        YearlySchedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.interval != null && message.hasOwnProperty("interval"))
                if (!$util.isInteger(message.interval))
                    return "interval: integer expected";
            if (message.months != null && message.hasOwnProperty("months")) {
                if (!Array.isArray(message.months))
                    return "months: array expected";
                for (let i = 0; i < message.months.length; ++i)
                    if (!$util.isInteger(message.months[i]))
                        return "months: integer[] expected";
            }
            if (message.selectiveInterval != null && message.hasOwnProperty("selectiveInterval")) {
                let error = $root.api.SelectiveSchedule.verify(message.selectiveInterval);
                if (error)
                    return "selectiveInterval." + error;
            }
            return null;
        };

        /**
         * Creates a YearlySchedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.YearlySchedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.YearlySchedule} YearlySchedule
         */
        YearlySchedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.YearlySchedule)
                return object;
            let message = new $root.api.YearlySchedule();
            if (object.interval != null)
                message.interval = object.interval | 0;
            if (object.months) {
                if (!Array.isArray(object.months))
                    throw TypeError(".api.YearlySchedule.months: array expected");
                message.months = [];
                for (let i = 0; i < object.months.length; ++i)
                    message.months[i] = object.months[i] | 0;
            }
            if (object.selectiveInterval != null) {
                if (typeof object.selectiveInterval !== "object")
                    throw TypeError(".api.YearlySchedule.selectiveInterval: object expected");
                message.selectiveInterval = $root.api.SelectiveSchedule.fromObject(object.selectiveInterval);
            }
            return message;
        };

        /**
         * Creates a plain object from a YearlySchedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.YearlySchedule
         * @static
         * @param {api.YearlySchedule} message YearlySchedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        YearlySchedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.months = [];
            if (options.defaults) {
                object.interval = 0;
                object.selectiveInterval = null;
            }
            if (message.interval != null && message.hasOwnProperty("interval"))
                object.interval = message.interval;
            if (message.months && message.months.length) {
                object.months = [];
                for (let j = 0; j < message.months.length; ++j)
                    object.months[j] = message.months[j];
            }
            if (message.selectiveInterval != null && message.hasOwnProperty("selectiveInterval"))
                object.selectiveInterval = $root.api.SelectiveSchedule.toObject(message.selectiveInterval, options);
            return object;
        };

        /**
         * Converts this YearlySchedule to JSON.
         * @function toJSON
         * @memberof api.YearlySchedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        YearlySchedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return YearlySchedule;
    })();

    api.Time = (function() {

        /**
         * Properties of a Time.
         * @memberof api
         * @interface ITime
         * @property {string|null} [timeId] Time timeId
         * @property {string|null} [scheduleId] Time scheduleId
         * @property {api.ISchedule|null} [schedule] Time schedule
         * @property {string|null} [roomId] Time roomId
         * @property {api.IRoom|null} [room] Time room
         * @property {number|Long|null} [startTime] Time startTime
         * @property {number|Long|null} [endTime] Time endTime
         * @property {number|null} [memberCount] Time memberCount
         * @property {Array.<api.IMember>|null} [members] Time members
         * @property {number|Long|null} [createDate] Time createDate
         * @property {number|Long|null} [updateDate] Time updateDate
         */

        /**
         * Constructs a new Time.
         * @memberof api
         * @classdesc Represents a Time.
         * @implements ITime
         * @constructor
         * @param {api.ITime=} [properties] Properties to set
         */
        function Time(properties) {
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Time timeId.
         * @member {string} timeId
         * @memberof api.Time
         * @instance
         */
        Time.prototype.timeId = "";

        /**
         * Time scheduleId.
         * @member {string} scheduleId
         * @memberof api.Time
         * @instance
         */
        Time.prototype.scheduleId = "";

        /**
         * Time schedule.
         * @member {api.ISchedule|null|undefined} schedule
         * @memberof api.Time
         * @instance
         */
        Time.prototype.schedule = null;

        /**
         * Time roomId.
         * @member {string} roomId
         * @memberof api.Time
         * @instance
         */
        Time.prototype.roomId = "";

        /**
         * Time room.
         * @member {api.IRoom|null|undefined} room
         * @memberof api.Time
         * @instance
         */
        Time.prototype.room = null;

        /**
         * Time startTime.
         * @member {number|Long} startTime
         * @memberof api.Time
         * @instance
         */
        Time.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Time endTime.
         * @member {number|Long} endTime
         * @memberof api.Time
         * @instance
         */
        Time.prototype.endTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Time memberCount.
         * @member {number} memberCount
         * @memberof api.Time
         * @instance
         */
        Time.prototype.memberCount = 0;

        /**
         * Time members.
         * @member {Array.<api.IMember>} members
         * @memberof api.Time
         * @instance
         */
        Time.prototype.members = $util.emptyArray;

        /**
         * Time createDate.
         * @member {number|Long} createDate
         * @memberof api.Time
         * @instance
         */
        Time.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Time updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Time
         * @instance
         */
        Time.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Time instance using the specified properties.
         * @function create
         * @memberof api.Time
         * @static
         * @param {api.ITime=} [properties] Properties to set
         * @returns {api.Time} Time instance
         */
        Time.create = function create(properties) {
            return new Time(properties);
        };

        /**
         * Encodes the specified Time message. Does not implicitly {@link api.Time.verify|verify} messages.
         * @function encode
         * @memberof api.Time
         * @static
         * @param {api.ITime} message Time message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Time.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.startTime);
            if (message.endTime != null && Object.hasOwnProperty.call(message, "endTime"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.endTime);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.timeId);
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.api.Member.encode(message.members[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.scheduleId);
            if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                $root.api.Schedule.encode(message.schedule, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.memberCount != null && Object.hasOwnProperty.call(message, "memberCount"))
                writer.uint32(/* id 8, wireType 0 =*/64).int32(message.memberCount);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.roomId);
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.Room.encode(message.room, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.updateDate);
            return writer;
        };

        /**
         * Encodes the specified Time message, length delimited. Does not implicitly {@link api.Time.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Time
         * @static
         * @param {api.ITime} message Time message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Time.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Time message from the specified reader or buffer.
         * @function decode
         * @memberof api.Time
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Time} Time
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Time.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Time();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 3:
                    message.timeId = reader.string();
                    break;
                case 6:
                    message.scheduleId = reader.string();
                    break;
                case 7:
                    message.schedule = $root.api.Schedule.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.roomId = reader.string();
                    break;
                case 10:
                    message.room = $root.api.Room.decode(reader, reader.uint32());
                    break;
                case 1:
                    message.startTime = reader.int64();
                    break;
                case 2:
                    message.endTime = reader.int64();
                    break;
                case 8:
                    message.memberCount = reader.int32();
                    break;
                case 4:
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.createDate = reader.int64();
                    break;
                case 12:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Time message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Time
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Time} Time
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Time.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Time message.
         * @function verify
         * @memberof api.Time
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Time.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                if (!$util.isString(message.scheduleId))
                    return "scheduleId: string expected";
            if (message.schedule != null && message.hasOwnProperty("schedule")) {
                let error = $root.api.Schedule.verify(message.schedule);
                if (error)
                    return "schedule." + error;
            }
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.Room.verify(message.room);
                if (error)
                    return "room." + error;
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (!$util.isInteger(message.endTime) && !(message.endTime && $util.isInteger(message.endTime.low) && $util.isInteger(message.endTime.high)))
                    return "endTime: integer|Long expected";
            if (message.memberCount != null && message.hasOwnProperty("memberCount"))
                if (!$util.isInteger(message.memberCount))
                    return "memberCount: integer expected";
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.api.Member.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a Time message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Time
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Time} Time
         */
        Time.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Time)
                return object;
            let message = new $root.api.Time();
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.scheduleId != null)
                message.scheduleId = String(object.scheduleId);
            if (object.schedule != null) {
                if (typeof object.schedule !== "object")
                    throw TypeError(".api.Time.schedule: object expected");
                message.schedule = $root.api.Schedule.fromObject(object.schedule);
            }
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.Time.room: object expected");
                message.room = $root.api.Room.fromObject(object.room);
            }
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = false;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber();
            if (object.endTime != null)
                if ($util.Long)
                    (message.endTime = $util.Long.fromValue(object.endTime)).unsigned = false;
                else if (typeof object.endTime === "string")
                    message.endTime = parseInt(object.endTime, 10);
                else if (typeof object.endTime === "number")
                    message.endTime = object.endTime;
                else if (typeof object.endTime === "object")
                    message.endTime = new $util.LongBits(object.endTime.low >>> 0, object.endTime.high >>> 0).toNumber();
            if (object.memberCount != null)
                message.memberCount = object.memberCount | 0;
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.Time.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.Time.members: object expected");
                    message.members[i] = $root.api.Member.fromObject(object.members[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Time message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Time
         * @static
         * @param {api.Time} message Time
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Time.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.members = [];
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.endTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.endTime = options.longs === String ? "0" : 0;
                object.timeId = "";
                object.scheduleId = "";
                object.schedule = null;
                object.memberCount = 0;
                object.roomId = "";
                object.room = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber() : message.startTime;
            if (message.endTime != null && message.hasOwnProperty("endTime"))
                if (typeof message.endTime === "number")
                    object.endTime = options.longs === String ? String(message.endTime) : message.endTime;
                else
                    object.endTime = options.longs === String ? $util.Long.prototype.toString.call(message.endTime) : options.longs === Number ? new $util.LongBits(message.endTime.low >>> 0, message.endTime.high >>> 0).toNumber() : message.endTime;
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                object.timeId = message.timeId;
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.api.Member.toObject(message.members[j], options);
            }
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                object.scheduleId = message.scheduleId;
            if (message.schedule != null && message.hasOwnProperty("schedule"))
                object.schedule = $root.api.Schedule.toObject(message.schedule, options);
            if (message.memberCount != null && message.hasOwnProperty("memberCount"))
                object.memberCount = message.memberCount;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.Room.toObject(message.room, options);
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            return object;
        };

        /**
         * Converts this Time to JSON.
         * @function toJSON
         * @memberof api.Time
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Time.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Time;
    })();

    api.ScheduleSet = (function() {

        /**
         * Properties of a ScheduleSet.
         * @memberof api
         * @interface IScheduleSet
         * @property {string|null} [scheduleSetId] ScheduleSet scheduleSetId
         * @property {string|null} [name] ScheduleSet name
         * @property {Array.<api.ISchedule>|null} [schedules] ScheduleSet schedules
         * @property {Array.<api.IRoom>|null} [rooms] ScheduleSet rooms
         * @property {number|Long|null} [createDate] ScheduleSet createDate
         * @property {number|Long|null} [updateDate] ScheduleSet updateDate
         */

        /**
         * Constructs a new ScheduleSet.
         * @memberof api
         * @classdesc Represents a ScheduleSet.
         * @implements IScheduleSet
         * @constructor
         * @param {api.IScheduleSet=} [properties] Properties to set
         */
        function ScheduleSet(properties) {
            this.schedules = [];
            this.rooms = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScheduleSet scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.ScheduleSet
         * @instance
         */
        ScheduleSet.prototype.scheduleSetId = "";

        /**
         * ScheduleSet name.
         * @member {string} name
         * @memberof api.ScheduleSet
         * @instance
         */
        ScheduleSet.prototype.name = "";

        /**
         * ScheduleSet schedules.
         * @member {Array.<api.ISchedule>} schedules
         * @memberof api.ScheduleSet
         * @instance
         */
        ScheduleSet.prototype.schedules = $util.emptyArray;

        /**
         * ScheduleSet rooms.
         * @member {Array.<api.IRoom>} rooms
         * @memberof api.ScheduleSet
         * @instance
         */
        ScheduleSet.prototype.rooms = $util.emptyArray;

        /**
         * ScheduleSet createDate.
         * @member {number|Long} createDate
         * @memberof api.ScheduleSet
         * @instance
         */
        ScheduleSet.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ScheduleSet updateDate.
         * @member {number|Long} updateDate
         * @memberof api.ScheduleSet
         * @instance
         */
        ScheduleSet.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ScheduleSet instance using the specified properties.
         * @function create
         * @memberof api.ScheduleSet
         * @static
         * @param {api.IScheduleSet=} [properties] Properties to set
         * @returns {api.ScheduleSet} ScheduleSet instance
         */
        ScheduleSet.create = function create(properties) {
            return new ScheduleSet(properties);
        };

        /**
         * Encodes the specified ScheduleSet message. Does not implicitly {@link api.ScheduleSet.verify|verify} messages.
         * @function encode
         * @memberof api.ScheduleSet
         * @static
         * @param {api.IScheduleSet} message ScheduleSet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSet.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleSetId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.schedules != null && message.schedules.length)
                for (let i = 0; i < message.schedules.length; ++i)
                    $root.api.Schedule.encode(message.schedules[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updateDate);
            if (message.rooms != null && message.rooms.length)
                for (let i = 0; i < message.rooms.length; ++i)
                    $root.api.Room.encode(message.rooms[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ScheduleSet message, length delimited. Does not implicitly {@link api.ScheduleSet.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ScheduleSet
         * @static
         * @param {api.IScheduleSet} message ScheduleSet message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSet.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScheduleSet message from the specified reader or buffer.
         * @function decode
         * @memberof api.ScheduleSet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ScheduleSet} ScheduleSet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSet.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ScheduleSet();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSetId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.schedules && message.schedules.length))
                        message.schedules = [];
                    message.schedules.push($root.api.Schedule.decode(reader, reader.uint32()));
                    break;
                case 6:
                    if (!(message.rooms && message.rooms.length))
                        message.rooms = [];
                    message.rooms.push($root.api.Room.decode(reader, reader.uint32()));
                    break;
                case 4:
                    message.createDate = reader.int64();
                    break;
                case 5:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScheduleSet message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ScheduleSet
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ScheduleSet} ScheduleSet
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSet.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScheduleSet message.
         * @function verify
         * @memberof api.ScheduleSet
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScheduleSet.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.schedules != null && message.hasOwnProperty("schedules")) {
                if (!Array.isArray(message.schedules))
                    return "schedules: array expected";
                for (let i = 0; i < message.schedules.length; ++i) {
                    let error = $root.api.Schedule.verify(message.schedules[i]);
                    if (error)
                        return "schedules." + error;
                }
            }
            if (message.rooms != null && message.hasOwnProperty("rooms")) {
                if (!Array.isArray(message.rooms))
                    return "rooms: array expected";
                for (let i = 0; i < message.rooms.length; ++i) {
                    let error = $root.api.Room.verify(message.rooms[i]);
                    if (error)
                        return "rooms." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a ScheduleSet message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ScheduleSet
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ScheduleSet} ScheduleSet
         */
        ScheduleSet.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ScheduleSet)
                return object;
            let message = new $root.api.ScheduleSet();
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.schedules) {
                if (!Array.isArray(object.schedules))
                    throw TypeError(".api.ScheduleSet.schedules: array expected");
                message.schedules = [];
                for (let i = 0; i < object.schedules.length; ++i) {
                    if (typeof object.schedules[i] !== "object")
                        throw TypeError(".api.ScheduleSet.schedules: object expected");
                    message.schedules[i] = $root.api.Schedule.fromObject(object.schedules[i]);
                }
            }
            if (object.rooms) {
                if (!Array.isArray(object.rooms))
                    throw TypeError(".api.ScheduleSet.rooms: array expected");
                message.rooms = [];
                for (let i = 0; i < object.rooms.length; ++i) {
                    if (typeof object.rooms[i] !== "object")
                        throw TypeError(".api.ScheduleSet.rooms: object expected");
                    message.rooms[i] = $root.api.Room.fromObject(object.rooms[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a ScheduleSet message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ScheduleSet
         * @static
         * @param {api.ScheduleSet} message ScheduleSet
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScheduleSet.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.schedules = [];
                object.rooms = [];
            }
            if (options.defaults) {
                object.scheduleSetId = "";
                object.name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
            }
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.schedules && message.schedules.length) {
                object.schedules = [];
                for (let j = 0; j < message.schedules.length; ++j)
                    object.schedules[j] = $root.api.Schedule.toObject(message.schedules[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.rooms && message.rooms.length) {
                object.rooms = [];
                for (let j = 0; j < message.rooms.length; ++j)
                    object.rooms[j] = $root.api.Room.toObject(message.rooms[j], options);
            }
            return object;
        };

        /**
         * Converts this ScheduleSet to JSON.
         * @function toJSON
         * @memberof api.ScheduleSet
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScheduleSet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ScheduleSet;
    })();

    api.Schedule = (function() {

        /**
         * Properties of a Schedule.
         * @memberof api
         * @interface ISchedule
         * @property {string|null} [scheduleId] Schedule scheduleId
         * @property {string|null} [scheduleSetId] Schedule scheduleSetId
         * @property {api.IScheduleSet|null} [scheduleSet] Schedule scheduleSet
         * @property {number|Long|null} [startTime] Schedule startTime
         * @property {number|Long|null} [duration] Schedule duration
         * @property {number|null} [repeatCount] Schedule repeatCount
         * @property {number|Long|null} [repeatEndDate] Schedule repeatEndDate
         * @property {api.IDailySchedule|null} [daily] Schedule daily
         * @property {api.IWeeklySchedule|null} [weekly] Schedule weekly
         * @property {api.IMonthlySchedule|null} [monthly] Schedule monthly
         * @property {api.IYearlySchedule|null} [yearly] Schedule yearly
         * @property {Array.<api.ITime>|null} [times] Schedule times
         * @property {string|null} [timeZone] Schedule timeZone
         * @property {number|Long|null} [createDate] Schedule createDate
         * @property {number|Long|null} [updateDate] Schedule updateDate
         */

        /**
         * Constructs a new Schedule.
         * @memberof api
         * @classdesc Represents a Schedule.
         * @implements ISchedule
         * @constructor
         * @param {api.ISchedule=} [properties] Properties to set
         */
        function Schedule(properties) {
            this.times = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Schedule scheduleId.
         * @member {string} scheduleId
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.scheduleId = "";

        /**
         * Schedule scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.scheduleSetId = "";

        /**
         * Schedule scheduleSet.
         * @member {api.IScheduleSet|null|undefined} scheduleSet
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.scheduleSet = null;

        /**
         * Schedule startTime.
         * @member {number|Long} startTime
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.startTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Schedule duration.
         * @member {number|Long} duration
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.duration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Schedule repeatCount.
         * @member {number} repeatCount
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.repeatCount = 0;

        /**
         * Schedule repeatEndDate.
         * @member {number|Long} repeatEndDate
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.repeatEndDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Schedule daily.
         * @member {api.IDailySchedule|null|undefined} daily
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.daily = null;

        /**
         * Schedule weekly.
         * @member {api.IWeeklySchedule|null|undefined} weekly
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.weekly = null;

        /**
         * Schedule monthly.
         * @member {api.IMonthlySchedule|null|undefined} monthly
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.monthly = null;

        /**
         * Schedule yearly.
         * @member {api.IYearlySchedule|null|undefined} yearly
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.yearly = null;

        /**
         * Schedule times.
         * @member {Array.<api.ITime>} times
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.times = $util.emptyArray;

        /**
         * Schedule timeZone.
         * @member {string} timeZone
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.timeZone = "";

        /**
         * Schedule createDate.
         * @member {number|Long} createDate
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Schedule updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Schedule
         * @instance
         */
        Schedule.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Schedule repeatFrequency.
         * @member {"daily"|"weekly"|"monthly"|"yearly"|undefined} repeatFrequency
         * @memberof api.Schedule
         * @instance
         */
        Object.defineProperty(Schedule.prototype, "repeatFrequency", {
            get: $util.oneOfGetter($oneOfFields = ["daily", "weekly", "monthly", "yearly"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Schedule instance using the specified properties.
         * @function create
         * @memberof api.Schedule
         * @static
         * @param {api.ISchedule=} [properties] Properties to set
         * @returns {api.Schedule} Schedule instance
         */
        Schedule.create = function create(properties) {
            return new Schedule(properties);
        };

        /**
         * Encodes the specified Schedule message. Does not implicitly {@link api.Schedule.verify|verify} messages.
         * @function encode
         * @memberof api.Schedule
         * @static
         * @param {api.ISchedule} message Schedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Schedule.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleId != null && Object.hasOwnProperty.call(message, "scheduleId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleId);
            if (message.startTime != null && Object.hasOwnProperty.call(message, "startTime"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTime);
            if (message.duration != null && Object.hasOwnProperty.call(message, "duration"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.duration);
            if (message.repeatCount != null && Object.hasOwnProperty.call(message, "repeatCount"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.repeatCount);
            if (message.repeatEndDate != null && Object.hasOwnProperty.call(message, "repeatEndDate"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.repeatEndDate);
            if (message.daily != null && Object.hasOwnProperty.call(message, "daily"))
                $root.api.DailySchedule.encode(message.daily, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.weekly != null && Object.hasOwnProperty.call(message, "weekly"))
                $root.api.WeeklySchedule.encode(message.weekly, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.monthly != null && Object.hasOwnProperty.call(message, "monthly"))
                $root.api.MonthlySchedule.encode(message.monthly, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.yearly != null && Object.hasOwnProperty.call(message, "yearly"))
                $root.api.YearlySchedule.encode(message.yearly, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.times != null && message.times.length)
                for (let i = 0; i < message.times.length; ++i)
                    $root.api.Time.encode(message.times[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.timeZone);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.updateDate);
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.scheduleSetId);
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSet.encode(message.scheduleSet, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Schedule message, length delimited. Does not implicitly {@link api.Schedule.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Schedule
         * @static
         * @param {api.ISchedule} message Schedule message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Schedule.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Schedule message from the specified reader or buffer.
         * @function decode
         * @memberof api.Schedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Schedule} Schedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Schedule.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Schedule();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleId = reader.string();
                    break;
                case 14:
                    message.scheduleSetId = reader.string();
                    break;
                case 15:
                    message.scheduleSet = $root.api.ScheduleSet.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.startTime = reader.int64();
                    break;
                case 3:
                    message.duration = reader.int64();
                    break;
                case 4:
                    message.repeatCount = reader.int32();
                    break;
                case 5:
                    message.repeatEndDate = reader.int64();
                    break;
                case 6:
                    message.daily = $root.api.DailySchedule.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.weekly = $root.api.WeeklySchedule.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.monthly = $root.api.MonthlySchedule.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.yearly = $root.api.YearlySchedule.decode(reader, reader.uint32());
                    break;
                case 10:
                    if (!(message.times && message.times.length))
                        message.times = [];
                    message.times.push($root.api.Time.decode(reader, reader.uint32()));
                    break;
                case 11:
                    message.timeZone = reader.string();
                    break;
                case 12:
                    message.createDate = reader.int64();
                    break;
                case 13:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Schedule message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Schedule
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Schedule} Schedule
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Schedule.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Schedule message.
         * @function verify
         * @memberof api.Schedule
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Schedule.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                if (!$util.isString(message.scheduleId))
                    return "scheduleId: string expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSet.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (!$util.isInteger(message.startTime) && !(message.startTime && $util.isInteger(message.startTime.low) && $util.isInteger(message.startTime.high)))
                    return "startTime: integer|Long expected";
            if (message.duration != null && message.hasOwnProperty("duration"))
                if (!$util.isInteger(message.duration) && !(message.duration && $util.isInteger(message.duration.low) && $util.isInteger(message.duration.high)))
                    return "duration: integer|Long expected";
            if (message.repeatCount != null && message.hasOwnProperty("repeatCount"))
                if (!$util.isInteger(message.repeatCount))
                    return "repeatCount: integer expected";
            if (message.repeatEndDate != null && message.hasOwnProperty("repeatEndDate"))
                if (!$util.isInteger(message.repeatEndDate) && !(message.repeatEndDate && $util.isInteger(message.repeatEndDate.low) && $util.isInteger(message.repeatEndDate.high)))
                    return "repeatEndDate: integer|Long expected";
            if (message.daily != null && message.hasOwnProperty("daily")) {
                properties.repeatFrequency = 1;
                {
                    let error = $root.api.DailySchedule.verify(message.daily);
                    if (error)
                        return "daily." + error;
                }
            }
            if (message.weekly != null && message.hasOwnProperty("weekly")) {
                if (properties.repeatFrequency === 1)
                    return "repeatFrequency: multiple values";
                properties.repeatFrequency = 1;
                {
                    let error = $root.api.WeeklySchedule.verify(message.weekly);
                    if (error)
                        return "weekly." + error;
                }
            }
            if (message.monthly != null && message.hasOwnProperty("monthly")) {
                if (properties.repeatFrequency === 1)
                    return "repeatFrequency: multiple values";
                properties.repeatFrequency = 1;
                {
                    let error = $root.api.MonthlySchedule.verify(message.monthly);
                    if (error)
                        return "monthly." + error;
                }
            }
            if (message.yearly != null && message.hasOwnProperty("yearly")) {
                if (properties.repeatFrequency === 1)
                    return "repeatFrequency: multiple values";
                properties.repeatFrequency = 1;
                {
                    let error = $root.api.YearlySchedule.verify(message.yearly);
                    if (error)
                        return "yearly." + error;
                }
            }
            if (message.times != null && message.hasOwnProperty("times")) {
                if (!Array.isArray(message.times))
                    return "times: array expected";
                for (let i = 0; i < message.times.length; ++i) {
                    let error = $root.api.Time.verify(message.times[i]);
                    if (error)
                        return "times." + error;
                }
            }
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                if (!$util.isString(message.timeZone))
                    return "timeZone: string expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a Schedule message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Schedule
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Schedule} Schedule
         */
        Schedule.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Schedule)
                return object;
            let message = new $root.api.Schedule();
            if (object.scheduleId != null)
                message.scheduleId = String(object.scheduleId);
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.Schedule.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSet.fromObject(object.scheduleSet);
            }
            if (object.startTime != null)
                if ($util.Long)
                    (message.startTime = $util.Long.fromValue(object.startTime)).unsigned = false;
                else if (typeof object.startTime === "string")
                    message.startTime = parseInt(object.startTime, 10);
                else if (typeof object.startTime === "number")
                    message.startTime = object.startTime;
                else if (typeof object.startTime === "object")
                    message.startTime = new $util.LongBits(object.startTime.low >>> 0, object.startTime.high >>> 0).toNumber();
            if (object.duration != null)
                if ($util.Long)
                    (message.duration = $util.Long.fromValue(object.duration)).unsigned = false;
                else if (typeof object.duration === "string")
                    message.duration = parseInt(object.duration, 10);
                else if (typeof object.duration === "number")
                    message.duration = object.duration;
                else if (typeof object.duration === "object")
                    message.duration = new $util.LongBits(object.duration.low >>> 0, object.duration.high >>> 0).toNumber();
            if (object.repeatCount != null)
                message.repeatCount = object.repeatCount | 0;
            if (object.repeatEndDate != null)
                if ($util.Long)
                    (message.repeatEndDate = $util.Long.fromValue(object.repeatEndDate)).unsigned = false;
                else if (typeof object.repeatEndDate === "string")
                    message.repeatEndDate = parseInt(object.repeatEndDate, 10);
                else if (typeof object.repeatEndDate === "number")
                    message.repeatEndDate = object.repeatEndDate;
                else if (typeof object.repeatEndDate === "object")
                    message.repeatEndDate = new $util.LongBits(object.repeatEndDate.low >>> 0, object.repeatEndDate.high >>> 0).toNumber();
            if (object.daily != null) {
                if (typeof object.daily !== "object")
                    throw TypeError(".api.Schedule.daily: object expected");
                message.daily = $root.api.DailySchedule.fromObject(object.daily);
            }
            if (object.weekly != null) {
                if (typeof object.weekly !== "object")
                    throw TypeError(".api.Schedule.weekly: object expected");
                message.weekly = $root.api.WeeklySchedule.fromObject(object.weekly);
            }
            if (object.monthly != null) {
                if (typeof object.monthly !== "object")
                    throw TypeError(".api.Schedule.monthly: object expected");
                message.monthly = $root.api.MonthlySchedule.fromObject(object.monthly);
            }
            if (object.yearly != null) {
                if (typeof object.yearly !== "object")
                    throw TypeError(".api.Schedule.yearly: object expected");
                message.yearly = $root.api.YearlySchedule.fromObject(object.yearly);
            }
            if (object.times) {
                if (!Array.isArray(object.times))
                    throw TypeError(".api.Schedule.times: array expected");
                message.times = [];
                for (let i = 0; i < object.times.length; ++i) {
                    if (typeof object.times[i] !== "object")
                        throw TypeError(".api.Schedule.times: object expected");
                    message.times[i] = $root.api.Time.fromObject(object.times[i]);
                }
            }
            if (object.timeZone != null)
                message.timeZone = String(object.timeZone);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Schedule message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Schedule
         * @static
         * @param {api.Schedule} message Schedule
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Schedule.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.times = [];
            if (options.defaults) {
                object.scheduleId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTime = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.duration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.duration = options.longs === String ? "0" : 0;
                object.repeatCount = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.repeatEndDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.repeatEndDate = options.longs === String ? "0" : 0;
                object.timeZone = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.scheduleSetId = "";
                object.scheduleSet = null;
            }
            if (message.scheduleId != null && message.hasOwnProperty("scheduleId"))
                object.scheduleId = message.scheduleId;
            if (message.startTime != null && message.hasOwnProperty("startTime"))
                if (typeof message.startTime === "number")
                    object.startTime = options.longs === String ? String(message.startTime) : message.startTime;
                else
                    object.startTime = options.longs === String ? $util.Long.prototype.toString.call(message.startTime) : options.longs === Number ? new $util.LongBits(message.startTime.low >>> 0, message.startTime.high >>> 0).toNumber() : message.startTime;
            if (message.duration != null && message.hasOwnProperty("duration"))
                if (typeof message.duration === "number")
                    object.duration = options.longs === String ? String(message.duration) : message.duration;
                else
                    object.duration = options.longs === String ? $util.Long.prototype.toString.call(message.duration) : options.longs === Number ? new $util.LongBits(message.duration.low >>> 0, message.duration.high >>> 0).toNumber() : message.duration;
            if (message.repeatCount != null && message.hasOwnProperty("repeatCount"))
                object.repeatCount = message.repeatCount;
            if (message.repeatEndDate != null && message.hasOwnProperty("repeatEndDate"))
                if (typeof message.repeatEndDate === "number")
                    object.repeatEndDate = options.longs === String ? String(message.repeatEndDate) : message.repeatEndDate;
                else
                    object.repeatEndDate = options.longs === String ? $util.Long.prototype.toString.call(message.repeatEndDate) : options.longs === Number ? new $util.LongBits(message.repeatEndDate.low >>> 0, message.repeatEndDate.high >>> 0).toNumber() : message.repeatEndDate;
            if (message.daily != null && message.hasOwnProperty("daily")) {
                object.daily = $root.api.DailySchedule.toObject(message.daily, options);
                if (options.oneofs)
                    object.repeatFrequency = "daily";
            }
            if (message.weekly != null && message.hasOwnProperty("weekly")) {
                object.weekly = $root.api.WeeklySchedule.toObject(message.weekly, options);
                if (options.oneofs)
                    object.repeatFrequency = "weekly";
            }
            if (message.monthly != null && message.hasOwnProperty("monthly")) {
                object.monthly = $root.api.MonthlySchedule.toObject(message.monthly, options);
                if (options.oneofs)
                    object.repeatFrequency = "monthly";
            }
            if (message.yearly != null && message.hasOwnProperty("yearly")) {
                object.yearly = $root.api.YearlySchedule.toObject(message.yearly, options);
                if (options.oneofs)
                    object.repeatFrequency = "yearly";
            }
            if (message.times && message.times.length) {
                object.times = [];
                for (let j = 0; j < message.times.length; ++j)
                    object.times[j] = $root.api.Time.toObject(message.times[j], options);
            }
            if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                object.timeZone = message.timeZone;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSet.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this Schedule to JSON.
         * @function toJSON
         * @memberof api.Schedule
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Schedule.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Schedule;
    })();

    api.MemberObjectQuery = (function() {

        /**
         * Properties of a MemberObjectQuery.
         * @memberof api
         * @interface IMemberObjectQuery
         * @property {api.ITopicQuery|null} [topic] MemberObjectQuery topic
         * @property {api.IRoomQuery|null} [room] MemberObjectQuery room
         * @property {api.IScheduleSetQuery|null} [scheduleSet] MemberObjectQuery scheduleSet
         * @property {api.IGroupQuery|null} [group] MemberObjectQuery group
         * @property {api.ITimeQuery|null} [time] MemberObjectQuery time
         */

        /**
         * Constructs a new MemberObjectQuery.
         * @memberof api
         * @classdesc Represents a MemberObjectQuery.
         * @implements IMemberObjectQuery
         * @constructor
         * @param {api.IMemberObjectQuery=} [properties] Properties to set
         */
        function MemberObjectQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberObjectQuery topic.
         * @member {api.ITopicQuery|null|undefined} topic
         * @memberof api.MemberObjectQuery
         * @instance
         */
        MemberObjectQuery.prototype.topic = null;

        /**
         * MemberObjectQuery room.
         * @member {api.IRoomQuery|null|undefined} room
         * @memberof api.MemberObjectQuery
         * @instance
         */
        MemberObjectQuery.prototype.room = null;

        /**
         * MemberObjectQuery scheduleSet.
         * @member {api.IScheduleSetQuery|null|undefined} scheduleSet
         * @memberof api.MemberObjectQuery
         * @instance
         */
        MemberObjectQuery.prototype.scheduleSet = null;

        /**
         * MemberObjectQuery group.
         * @member {api.IGroupQuery|null|undefined} group
         * @memberof api.MemberObjectQuery
         * @instance
         */
        MemberObjectQuery.prototype.group = null;

        /**
         * MemberObjectQuery time.
         * @member {api.ITimeQuery|null|undefined} time
         * @memberof api.MemberObjectQuery
         * @instance
         */
        MemberObjectQuery.prototype.time = null;

        /**
         * Creates a new MemberObjectQuery instance using the specified properties.
         * @function create
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.IMemberObjectQuery=} [properties] Properties to set
         * @returns {api.MemberObjectQuery} MemberObjectQuery instance
         */
        MemberObjectQuery.create = function create(properties) {
            return new MemberObjectQuery(properties);
        };

        /**
         * Encodes the specified MemberObjectQuery message. Does not implicitly {@link api.MemberObjectQuery.verify|verify} messages.
         * @function encode
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.IMemberObjectQuery} message MemberObjectQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObjectQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.TopicQuery.encode(message.topic, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.RoomQuery.encode(message.room, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupQuery.encode(message.group, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.TimeQuery.encode(message.time, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSetQuery.encode(message.scheduleSet, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberObjectQuery message, length delimited. Does not implicitly {@link api.MemberObjectQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.IMemberObjectQuery} message MemberObjectQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberObjectQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberObjectQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberObjectQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberObjectQuery} MemberObjectQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObjectQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberObjectQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topic = $root.api.TopicQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.room = $root.api.RoomQuery.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.scheduleSet = $root.api.ScheduleSetQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.group = $root.api.GroupQuery.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.time = $root.api.TimeQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberObjectQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberObjectQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberObjectQuery} MemberObjectQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberObjectQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberObjectQuery message.
         * @function verify
         * @memberof api.MemberObjectQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberObjectQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.TopicQuery.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.RoomQuery.verify(message.room);
                if (error)
                    return "room." + error;
            }
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSetQuery.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.GroupQuery.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.time != null && message.hasOwnProperty("time")) {
                let error = $root.api.TimeQuery.verify(message.time);
                if (error)
                    return "time." + error;
            }
            return null;
        };

        /**
         * Creates a MemberObjectQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberObjectQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberObjectQuery} MemberObjectQuery
         */
        MemberObjectQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberObjectQuery)
                return object;
            let message = new $root.api.MemberObjectQuery();
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.MemberObjectQuery.topic: object expected");
                message.topic = $root.api.TopicQuery.fromObject(object.topic);
            }
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.MemberObjectQuery.room: object expected");
                message.room = $root.api.RoomQuery.fromObject(object.room);
            }
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.MemberObjectQuery.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSetQuery.fromObject(object.scheduleSet);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberObjectQuery.group: object expected");
                message.group = $root.api.GroupQuery.fromObject(object.group);
            }
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.MemberObjectQuery.time: object expected");
                message.time = $root.api.TimeQuery.fromObject(object.time);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberObjectQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberObjectQuery
         * @static
         * @param {api.MemberObjectQuery} message MemberObjectQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberObjectQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.topic = null;
                object.room = null;
                object.group = null;
                object.time = null;
                object.scheduleSet = null;
            }
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.TopicQuery.toObject(message.topic, options);
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.RoomQuery.toObject(message.room, options);
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.GroupQuery.toObject(message.group, options);
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = $root.api.TimeQuery.toObject(message.time, options);
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSetQuery.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this MemberObjectQuery to JSON.
         * @function toJSON
         * @memberof api.MemberObjectQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberObjectQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberObjectQuery;
    })();

    api.MemberEntityQuery = (function() {

        /**
         * Properties of a MemberEntityQuery.
         * @memberof api
         * @interface IMemberEntityQuery
         * @property {api.IUserQuery|null} [user] MemberEntityQuery user
         * @property {api.IGroupQuery|null} [group] MemberEntityQuery group
         */

        /**
         * Constructs a new MemberEntityQuery.
         * @memberof api
         * @classdesc Represents a MemberEntityQuery.
         * @implements IMemberEntityQuery
         * @constructor
         * @param {api.IMemberEntityQuery=} [properties] Properties to set
         */
        function MemberEntityQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberEntityQuery user.
         * @member {api.IUserQuery|null|undefined} user
         * @memberof api.MemberEntityQuery
         * @instance
         */
        MemberEntityQuery.prototype.user = null;

        /**
         * MemberEntityQuery group.
         * @member {api.IGroupQuery|null|undefined} group
         * @memberof api.MemberEntityQuery
         * @instance
         */
        MemberEntityQuery.prototype.group = null;

        /**
         * Creates a new MemberEntityQuery instance using the specified properties.
         * @function create
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.IMemberEntityQuery=} [properties] Properties to set
         * @returns {api.MemberEntityQuery} MemberEntityQuery instance
         */
        MemberEntityQuery.create = function create(properties) {
            return new MemberEntityQuery(properties);
        };

        /**
         * Encodes the specified MemberEntityQuery message. Does not implicitly {@link api.MemberEntityQuery.verify|verify} messages.
         * @function encode
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.IMemberEntityQuery} message MemberEntityQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntityQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.UserQuery.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.GroupQuery.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberEntityQuery message, length delimited. Does not implicitly {@link api.MemberEntityQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.IMemberEntityQuery} message MemberEntityQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberEntityQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberEntityQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberEntityQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberEntityQuery} MemberEntityQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntityQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberEntityQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = $root.api.UserQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.group = $root.api.GroupQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberEntityQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberEntityQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberEntityQuery} MemberEntityQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberEntityQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberEntityQuery message.
         * @function verify
         * @memberof api.MemberEntityQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberEntityQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.UserQuery.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.GroupQuery.verify(message.group);
                if (error)
                    return "group." + error;
            }
            return null;
        };

        /**
         * Creates a MemberEntityQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberEntityQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberEntityQuery} MemberEntityQuery
         */
        MemberEntityQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberEntityQuery)
                return object;
            let message = new $root.api.MemberEntityQuery();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.MemberEntityQuery.user: object expected");
                message.user = $root.api.UserQuery.fromObject(object.user);
            }
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.MemberEntityQuery.group: object expected");
                message.group = $root.api.GroupQuery.fromObject(object.group);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberEntityQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberEntityQuery
         * @static
         * @param {api.MemberEntityQuery} message MemberEntityQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberEntityQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.user = null;
                object.group = null;
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.UserQuery.toObject(message.user, options);
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.GroupQuery.toObject(message.group, options);
            return object;
        };

        /**
         * Converts this MemberEntityQuery to JSON.
         * @function toJSON
         * @memberof api.MemberEntityQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberEntityQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberEntityQuery;
    })();

    api.LiveMemberQuery = (function() {

        /**
         * Properties of a LiveMemberQuery.
         * @memberof api
         * @interface ILiveMemberQuery
         * @property {api.IUserQuery|null} [user] LiveMemberQuery user
         */

        /**
         * Constructs a new LiveMemberQuery.
         * @memberof api
         * @classdesc Represents a LiveMemberQuery.
         * @implements ILiveMemberQuery
         * @constructor
         * @param {api.ILiveMemberQuery=} [properties] Properties to set
         */
        function LiveMemberQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LiveMemberQuery user.
         * @member {api.IUserQuery|null|undefined} user
         * @memberof api.LiveMemberQuery
         * @instance
         */
        LiveMemberQuery.prototype.user = null;

        /**
         * Creates a new LiveMemberQuery instance using the specified properties.
         * @function create
         * @memberof api.LiveMemberQuery
         * @static
         * @param {api.ILiveMemberQuery=} [properties] Properties to set
         * @returns {api.LiveMemberQuery} LiveMemberQuery instance
         */
        LiveMemberQuery.create = function create(properties) {
            return new LiveMemberQuery(properties);
        };

        /**
         * Encodes the specified LiveMemberQuery message. Does not implicitly {@link api.LiveMemberQuery.verify|verify} messages.
         * @function encode
         * @memberof api.LiveMemberQuery
         * @static
         * @param {api.ILiveMemberQuery} message LiveMemberQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LiveMemberQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.UserQuery.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified LiveMemberQuery message, length delimited. Does not implicitly {@link api.LiveMemberQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LiveMemberQuery
         * @static
         * @param {api.ILiveMemberQuery} message LiveMemberQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LiveMemberQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LiveMemberQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.LiveMemberQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LiveMemberQuery} LiveMemberQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LiveMemberQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LiveMemberQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.user = $root.api.UserQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LiveMemberQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LiveMemberQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LiveMemberQuery} LiveMemberQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LiveMemberQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LiveMemberQuery message.
         * @function verify
         * @memberof api.LiveMemberQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LiveMemberQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.UserQuery.verify(message.user);
                if (error)
                    return "user." + error;
            }
            return null;
        };

        /**
         * Creates a LiveMemberQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LiveMemberQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LiveMemberQuery} LiveMemberQuery
         */
        LiveMemberQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LiveMemberQuery)
                return object;
            let message = new $root.api.LiveMemberQuery();
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.LiveMemberQuery.user: object expected");
                message.user = $root.api.UserQuery.fromObject(object.user);
            }
            return message;
        };

        /**
         * Creates a plain object from a LiveMemberQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LiveMemberQuery
         * @static
         * @param {api.LiveMemberQuery} message LiveMemberQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LiveMemberQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.user = null;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.UserQuery.toObject(message.user, options);
            return object;
        };

        /**
         * Converts this LiveMemberQuery to JSON.
         * @function toJSON
         * @memberof api.LiveMemberQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LiveMemberQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LiveMemberQuery;
    })();

    api.MemberQuery = (function() {

        /**
         * Properties of a MemberQuery.
         * @memberof api
         * @interface IMemberQuery
         * @property {api.IMemberObjectQuery|null} [obj] MemberQuery obj
         * @property {api.IBoolFilter|null} [ownerFilter] MemberQuery ownerFilter
         * @property {api.IBoolFilter|null} [instructorFilter] MemberQuery instructorFilter
         * @property {api.IStringFilter|null} [entityFilter] MemberQuery entityFilter
         * @property {api.IMemberEntityQuery|null} [entity] MemberQuery entity
         */

        /**
         * Constructs a new MemberQuery.
         * @memberof api
         * @classdesc Represents a MemberQuery.
         * @implements IMemberQuery
         * @constructor
         * @param {api.IMemberQuery=} [properties] Properties to set
         */
        function MemberQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MemberQuery obj.
         * @member {api.IMemberObjectQuery|null|undefined} obj
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.obj = null;

        /**
         * MemberQuery ownerFilter.
         * @member {api.IBoolFilter|null|undefined} ownerFilter
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.ownerFilter = null;

        /**
         * MemberQuery instructorFilter.
         * @member {api.IBoolFilter|null|undefined} instructorFilter
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.instructorFilter = null;

        /**
         * MemberQuery entityFilter.
         * @member {api.IStringFilter|null|undefined} entityFilter
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.entityFilter = null;

        /**
         * MemberQuery entity.
         * @member {api.IMemberEntityQuery|null|undefined} entity
         * @memberof api.MemberQuery
         * @instance
         */
        MemberQuery.prototype.entity = null;

        /**
         * Creates a new MemberQuery instance using the specified properties.
         * @function create
         * @memberof api.MemberQuery
         * @static
         * @param {api.IMemberQuery=} [properties] Properties to set
         * @returns {api.MemberQuery} MemberQuery instance
         */
        MemberQuery.create = function create(properties) {
            return new MemberQuery(properties);
        };

        /**
         * Encodes the specified MemberQuery message. Does not implicitly {@link api.MemberQuery.verify|verify} messages.
         * @function encode
         * @memberof api.MemberQuery
         * @static
         * @param {api.IMemberQuery} message MemberQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObjectQuery.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.ownerFilter != null && Object.hasOwnProperty.call(message, "ownerFilter"))
                $root.api.BoolFilter.encode(message.ownerFilter, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntityQuery.encode(message.entity, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.instructorFilter != null && Object.hasOwnProperty.call(message, "instructorFilter"))
                $root.api.BoolFilter.encode(message.instructorFilter, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.entityFilter != null && Object.hasOwnProperty.call(message, "entityFilter"))
                $root.api.StringFilter.encode(message.entityFilter, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified MemberQuery message, length delimited. Does not implicitly {@link api.MemberQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MemberQuery
         * @static
         * @param {api.IMemberQuery} message MemberQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MemberQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MemberQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.MemberQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MemberQuery} MemberQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MemberQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObjectQuery.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.ownerFilter = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.instructorFilter = $root.api.BoolFilter.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.entityFilter = $root.api.StringFilter.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.entity = $root.api.MemberEntityQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MemberQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MemberQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MemberQuery} MemberQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MemberQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MemberQuery message.
         * @function verify
         * @memberof api.MemberQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MemberQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObjectQuery.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.ownerFilter != null && message.hasOwnProperty("ownerFilter")) {
                let error = $root.api.BoolFilter.verify(message.ownerFilter);
                if (error)
                    return "ownerFilter." + error;
            }
            if (message.instructorFilter != null && message.hasOwnProperty("instructorFilter")) {
                let error = $root.api.BoolFilter.verify(message.instructorFilter);
                if (error)
                    return "instructorFilter." + error;
            }
            if (message.entityFilter != null && message.hasOwnProperty("entityFilter")) {
                let error = $root.api.StringFilter.verify(message.entityFilter);
                if (error)
                    return "entityFilter." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntityQuery.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            return null;
        };

        /**
         * Creates a MemberQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MemberQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MemberQuery} MemberQuery
         */
        MemberQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MemberQuery)
                return object;
            let message = new $root.api.MemberQuery();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.MemberQuery.obj: object expected");
                message.obj = $root.api.MemberObjectQuery.fromObject(object.obj);
            }
            if (object.ownerFilter != null) {
                if (typeof object.ownerFilter !== "object")
                    throw TypeError(".api.MemberQuery.ownerFilter: object expected");
                message.ownerFilter = $root.api.BoolFilter.fromObject(object.ownerFilter);
            }
            if (object.instructorFilter != null) {
                if (typeof object.instructorFilter !== "object")
                    throw TypeError(".api.MemberQuery.instructorFilter: object expected");
                message.instructorFilter = $root.api.BoolFilter.fromObject(object.instructorFilter);
            }
            if (object.entityFilter != null) {
                if (typeof object.entityFilter !== "object")
                    throw TypeError(".api.MemberQuery.entityFilter: object expected");
                message.entityFilter = $root.api.StringFilter.fromObject(object.entityFilter);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.MemberQuery.entity: object expected");
                message.entity = $root.api.MemberEntityQuery.fromObject(object.entity);
            }
            return message;
        };

        /**
         * Creates a plain object from a MemberQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MemberQuery
         * @static
         * @param {api.MemberQuery} message MemberQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MemberQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.ownerFilter = null;
                object.entity = null;
                object.instructorFilter = null;
                object.entityFilter = null;
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObjectQuery.toObject(message.obj, options);
            if (message.ownerFilter != null && message.hasOwnProperty("ownerFilter"))
                object.ownerFilter = $root.api.BoolFilter.toObject(message.ownerFilter, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntityQuery.toObject(message.entity, options);
            if (message.instructorFilter != null && message.hasOwnProperty("instructorFilter"))
                object.instructorFilter = $root.api.BoolFilter.toObject(message.instructorFilter, options);
            if (message.entityFilter != null && message.hasOwnProperty("entityFilter"))
                object.entityFilter = $root.api.StringFilter.toObject(message.entityFilter, options);
            return object;
        };

        /**
         * Converts this MemberQuery to JSON.
         * @function toJSON
         * @memberof api.MemberQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MemberQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MemberQuery;
    })();

    api.UserOption = (function() {

        /**
         * Properties of a UserOption.
         * @memberof api
         * @interface IUserOption
         * @property {string|null} [userId] UserOption userId
         * @property {api.IUser|null} [user] UserOption user
         * @property {string|null} [firstName] UserOption firstName
         * @property {string|null} [lastName] UserOption lastName
         */

        /**
         * Constructs a new UserOption.
         * @memberof api
         * @classdesc Represents a UserOption.
         * @implements IUserOption
         * @constructor
         * @param {api.IUserOption=} [properties] Properties to set
         */
        function UserOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserOption userId.
         * @member {string} userId
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.userId = "";

        /**
         * UserOption user.
         * @member {api.IUser|null|undefined} user
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.user = null;

        /**
         * UserOption firstName.
         * @member {string} firstName
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.firstName = "";

        /**
         * UserOption lastName.
         * @member {string} lastName
         * @memberof api.UserOption
         * @instance
         */
        UserOption.prototype.lastName = "";

        /**
         * Creates a new UserOption instance using the specified properties.
         * @function create
         * @memberof api.UserOption
         * @static
         * @param {api.IUserOption=} [properties] Properties to set
         * @returns {api.UserOption} UserOption instance
         */
        UserOption.create = function create(properties) {
            return new UserOption(properties);
        };

        /**
         * Encodes the specified UserOption message. Does not implicitly {@link api.UserOption.verify|verify} messages.
         * @function encode
         * @memberof api.UserOption
         * @static
         * @param {api.IUserOption} message UserOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
            if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                $root.api.User.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            return writer;
        };

        /**
         * Encodes the specified UserOption message, length delimited. Does not implicitly {@link api.UserOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UserOption
         * @static
         * @param {api.IUserOption} message UserOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.UserOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UserOption} UserOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UserOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = reader.string();
                    break;
                case 2:
                    message.user = $root.api.User.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UserOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UserOption} UserOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserOption message.
         * @function verify
         * @memberof api.UserOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId"))
                if (!$util.isString(message.userId))
                    return "userId: string expected";
            if (message.user != null && message.hasOwnProperty("user")) {
                let error = $root.api.User.verify(message.user);
                if (error)
                    return "user." + error;
            }
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            return null;
        };

        /**
         * Creates a UserOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UserOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UserOption} UserOption
         */
        UserOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UserOption)
                return object;
            let message = new $root.api.UserOption();
            if (object.userId != null)
                message.userId = String(object.userId);
            if (object.user != null) {
                if (typeof object.user !== "object")
                    throw TypeError(".api.UserOption.user: object expected");
                message.user = $root.api.User.fromObject(object.user);
            }
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            return message;
        };

        /**
         * Creates a plain object from a UserOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UserOption
         * @static
         * @param {api.UserOption} message UserOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = "";
                object.user = null;
                object.firstName = "";
                object.lastName = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = message.userId;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = $root.api.User.toObject(message.user, options);
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            return object;
        };

        /**
         * Converts this UserOption to JSON.
         * @function toJSON
         * @memberof api.UserOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserOption;
    })();

    api.GroupOption = (function() {

        /**
         * Properties of a GroupOption.
         * @memberof api
         * @interface IGroupOption
         * @property {string|null} [groupId] GroupOption groupId
         * @property {api.IGroup|null} [group] GroupOption group
         * @property {string|null} [description] GroupOption description
         */

        /**
         * Constructs a new GroupOption.
         * @memberof api
         * @classdesc Represents a GroupOption.
         * @implements IGroupOption
         * @constructor
         * @param {api.IGroupOption=} [properties] Properties to set
         */
        function GroupOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GroupOption groupId.
         * @member {string} groupId
         * @memberof api.GroupOption
         * @instance
         */
        GroupOption.prototype.groupId = "";

        /**
         * GroupOption group.
         * @member {api.IGroup|null|undefined} group
         * @memberof api.GroupOption
         * @instance
         */
        GroupOption.prototype.group = null;

        /**
         * GroupOption description.
         * @member {string} description
         * @memberof api.GroupOption
         * @instance
         */
        GroupOption.prototype.description = "";

        /**
         * Creates a new GroupOption instance using the specified properties.
         * @function create
         * @memberof api.GroupOption
         * @static
         * @param {api.IGroupOption=} [properties] Properties to set
         * @returns {api.GroupOption} GroupOption instance
         */
        GroupOption.create = function create(properties) {
            return new GroupOption(properties);
        };

        /**
         * Encodes the specified GroupOption message. Does not implicitly {@link api.GroupOption.verify|verify} messages.
         * @function encode
         * @memberof api.GroupOption
         * @static
         * @param {api.IGroupOption} message GroupOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.groupId != null && Object.hasOwnProperty.call(message, "groupId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.groupId);
            if (message.group != null && Object.hasOwnProperty.call(message, "group"))
                $root.api.Group.encode(message.group, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified GroupOption message, length delimited. Does not implicitly {@link api.GroupOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.GroupOption
         * @static
         * @param {api.IGroupOption} message GroupOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GroupOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GroupOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.GroupOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.GroupOption} GroupOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.GroupOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.groupId = reader.string();
                    break;
                case 2:
                    message.group = $root.api.Group.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GroupOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.GroupOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.GroupOption} GroupOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GroupOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GroupOption message.
         * @function verify
         * @memberof api.GroupOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GroupOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            if (message.group != null && message.hasOwnProperty("group")) {
                let error = $root.api.Group.verify(message.group);
                if (error)
                    return "group." + error;
            }
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates a GroupOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.GroupOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.GroupOption} GroupOption
         */
        GroupOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.GroupOption)
                return object;
            let message = new $root.api.GroupOption();
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            if (object.group != null) {
                if (typeof object.group !== "object")
                    throw TypeError(".api.GroupOption.group: object expected");
                message.group = $root.api.Group.fromObject(object.group);
            }
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from a GroupOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.GroupOption
         * @static
         * @param {api.GroupOption} message GroupOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GroupOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.groupId = "";
                object.group = null;
                object.description = "";
            }
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            if (message.group != null && message.hasOwnProperty("group"))
                object.group = $root.api.Group.toObject(message.group, options);
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this GroupOption to JSON.
         * @function toJSON
         * @memberof api.GroupOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GroupOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GroupOption;
    })();

    api.TopicOption = (function() {

        /**
         * Properties of a TopicOption.
         * @memberof api
         * @interface ITopicOption
         * @property {string|null} [topicId] TopicOption topicId
         * @property {api.ITopic|null} [topic] TopicOption topic
         */

        /**
         * Constructs a new TopicOption.
         * @memberof api
         * @classdesc Represents a TopicOption.
         * @implements ITopicOption
         * @constructor
         * @param {api.ITopicOption=} [properties] Properties to set
         */
        function TopicOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TopicOption topicId.
         * @member {string} topicId
         * @memberof api.TopicOption
         * @instance
         */
        TopicOption.prototype.topicId = "";

        /**
         * TopicOption topic.
         * @member {api.ITopic|null|undefined} topic
         * @memberof api.TopicOption
         * @instance
         */
        TopicOption.prototype.topic = null;

        /**
         * Creates a new TopicOption instance using the specified properties.
         * @function create
         * @memberof api.TopicOption
         * @static
         * @param {api.ITopicOption=} [properties] Properties to set
         * @returns {api.TopicOption} TopicOption instance
         */
        TopicOption.create = function create(properties) {
            return new TopicOption(properties);
        };

        /**
         * Encodes the specified TopicOption message. Does not implicitly {@link api.TopicOption.verify|verify} messages.
         * @function encode
         * @memberof api.TopicOption
         * @static
         * @param {api.ITopicOption} message TopicOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.topicId);
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.Topic.encode(message.topic, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TopicOption message, length delimited. Does not implicitly {@link api.TopicOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TopicOption
         * @static
         * @param {api.ITopicOption} message TopicOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TopicOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.TopicOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TopicOption} TopicOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TopicOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topicId = reader.string();
                    break;
                case 2:
                    message.topic = $root.api.Topic.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TopicOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TopicOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TopicOption} TopicOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TopicOption message.
         * @function verify
         * @memberof api.TopicOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TopicOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.Topic.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            return null;
        };

        /**
         * Creates a TopicOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TopicOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TopicOption} TopicOption
         */
        TopicOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TopicOption)
                return object;
            let message = new $root.api.TopicOption();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.TopicOption.topic: object expected");
                message.topic = $root.api.Topic.fromObject(object.topic);
            }
            return message;
        };

        /**
         * Creates a plain object from a TopicOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TopicOption
         * @static
         * @param {api.TopicOption} message TopicOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.topicId = "";
                object.topic = null;
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.Topic.toObject(message.topic, options);
            return object;
        };

        /**
         * Converts this TopicOption to JSON.
         * @function toJSON
         * @memberof api.TopicOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicOption;
    })();

    api.RoomOption = (function() {

        /**
         * Properties of a RoomOption.
         * @memberof api
         * @interface IRoomOption
         * @property {string|null} [roomId] RoomOption roomId
         * @property {api.IRoom|null} [room] RoomOption room
         */

        /**
         * Constructs a new RoomOption.
         * @memberof api
         * @classdesc Represents a RoomOption.
         * @implements IRoomOption
         * @constructor
         * @param {api.IRoomOption=} [properties] Properties to set
         */
        function RoomOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoomOption roomId.
         * @member {string} roomId
         * @memberof api.RoomOption
         * @instance
         */
        RoomOption.prototype.roomId = "";

        /**
         * RoomOption room.
         * @member {api.IRoom|null|undefined} room
         * @memberof api.RoomOption
         * @instance
         */
        RoomOption.prototype.room = null;

        /**
         * Creates a new RoomOption instance using the specified properties.
         * @function create
         * @memberof api.RoomOption
         * @static
         * @param {api.IRoomOption=} [properties] Properties to set
         * @returns {api.RoomOption} RoomOption instance
         */
        RoomOption.create = function create(properties) {
            return new RoomOption(properties);
        };

        /**
         * Encodes the specified RoomOption message. Does not implicitly {@link api.RoomOption.verify|verify} messages.
         * @function encode
         * @memberof api.RoomOption
         * @static
         * @param {api.IRoomOption} message RoomOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                $root.api.Room.encode(message.room, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RoomOption message, length delimited. Does not implicitly {@link api.RoomOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RoomOption
         * @static
         * @param {api.IRoomOption} message RoomOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoomOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.RoomOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RoomOption} RoomOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RoomOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.room = $root.api.Room.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoomOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RoomOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RoomOption} RoomOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoomOption message.
         * @function verify
         * @memberof api.RoomOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoomOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            if (message.room != null && message.hasOwnProperty("room")) {
                let error = $root.api.Room.verify(message.room);
                if (error)
                    return "room." + error;
            }
            return null;
        };

        /**
         * Creates a RoomOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RoomOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RoomOption} RoomOption
         */
        RoomOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RoomOption)
                return object;
            let message = new $root.api.RoomOption();
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.room != null) {
                if (typeof object.room !== "object")
                    throw TypeError(".api.RoomOption.room: object expected");
                message.room = $root.api.Room.fromObject(object.room);
            }
            return message;
        };

        /**
         * Creates a plain object from a RoomOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RoomOption
         * @static
         * @param {api.RoomOption} message RoomOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoomOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.roomId = "";
                object.room = null;
            }
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = $root.api.Room.toObject(message.room, options);
            return object;
        };

        /**
         * Converts this RoomOption to JSON.
         * @function toJSON
         * @memberof api.RoomOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoomOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoomOption;
    })();

    api.TimeOption = (function() {

        /**
         * Properties of a TimeOption.
         * @memberof api
         * @interface ITimeOption
         * @property {string|null} [timeId] TimeOption timeId
         * @property {api.ITime|null} [time] TimeOption time
         */

        /**
         * Constructs a new TimeOption.
         * @memberof api
         * @classdesc Represents a TimeOption.
         * @implements ITimeOption
         * @constructor
         * @param {api.ITimeOption=} [properties] Properties to set
         */
        function TimeOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TimeOption timeId.
         * @member {string} timeId
         * @memberof api.TimeOption
         * @instance
         */
        TimeOption.prototype.timeId = "";

        /**
         * TimeOption time.
         * @member {api.ITime|null|undefined} time
         * @memberof api.TimeOption
         * @instance
         */
        TimeOption.prototype.time = null;

        /**
         * Creates a new TimeOption instance using the specified properties.
         * @function create
         * @memberof api.TimeOption
         * @static
         * @param {api.ITimeOption=} [properties] Properties to set
         * @returns {api.TimeOption} TimeOption instance
         */
        TimeOption.create = function create(properties) {
            return new TimeOption(properties);
        };

        /**
         * Encodes the specified TimeOption message. Does not implicitly {@link api.TimeOption.verify|verify} messages.
         * @function encode
         * @memberof api.TimeOption
         * @static
         * @param {api.ITimeOption} message TimeOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.timeId);
            if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                $root.api.Time.encode(message.time, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified TimeOption message, length delimited. Does not implicitly {@link api.TimeOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TimeOption
         * @static
         * @param {api.ITimeOption} message TimeOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TimeOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TimeOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.TimeOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TimeOption} TimeOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TimeOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.timeId = reader.string();
                    break;
                case 2:
                    message.time = $root.api.Time.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TimeOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TimeOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TimeOption} TimeOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TimeOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TimeOption message.
         * @function verify
         * @memberof api.TimeOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TimeOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            if (message.time != null && message.hasOwnProperty("time")) {
                let error = $root.api.Time.verify(message.time);
                if (error)
                    return "time." + error;
            }
            return null;
        };

        /**
         * Creates a TimeOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TimeOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TimeOption} TimeOption
         */
        TimeOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TimeOption)
                return object;
            let message = new $root.api.TimeOption();
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.time != null) {
                if (typeof object.time !== "object")
                    throw TypeError(".api.TimeOption.time: object expected");
                message.time = $root.api.Time.fromObject(object.time);
            }
            return message;
        };

        /**
         * Creates a plain object from a TimeOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TimeOption
         * @static
         * @param {api.TimeOption} message TimeOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TimeOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.timeId = "";
                object.time = null;
            }
            if (message.timeId != null && message.hasOwnProperty("timeId"))
                object.timeId = message.timeId;
            if (message.time != null && message.hasOwnProperty("time"))
                object.time = $root.api.Time.toObject(message.time, options);
            return object;
        };

        /**
         * Converts this TimeOption to JSON.
         * @function toJSON
         * @memberof api.TimeOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TimeOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TimeOption;
    })();

    api.ScheduleSetOption = (function() {

        /**
         * Properties of a ScheduleSetOption.
         * @memberof api
         * @interface IScheduleSetOption
         * @property {string|null} [scheduleSetId] ScheduleSetOption scheduleSetId
         * @property {api.IScheduleSet|null} [scheduleSet] ScheduleSetOption scheduleSet
         */

        /**
         * Constructs a new ScheduleSetOption.
         * @memberof api
         * @classdesc Represents a ScheduleSetOption.
         * @implements IScheduleSetOption
         * @constructor
         * @param {api.IScheduleSetOption=} [properties] Properties to set
         */
        function ScheduleSetOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ScheduleSetOption scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.ScheduleSetOption
         * @instance
         */
        ScheduleSetOption.prototype.scheduleSetId = "";

        /**
         * ScheduleSetOption scheduleSet.
         * @member {api.IScheduleSet|null|undefined} scheduleSet
         * @memberof api.ScheduleSetOption
         * @instance
         */
        ScheduleSetOption.prototype.scheduleSet = null;

        /**
         * Creates a new ScheduleSetOption instance using the specified properties.
         * @function create
         * @memberof api.ScheduleSetOption
         * @static
         * @param {api.IScheduleSetOption=} [properties] Properties to set
         * @returns {api.ScheduleSetOption} ScheduleSetOption instance
         */
        ScheduleSetOption.create = function create(properties) {
            return new ScheduleSetOption(properties);
        };

        /**
         * Encodes the specified ScheduleSetOption message. Does not implicitly {@link api.ScheduleSetOption.verify|verify} messages.
         * @function encode
         * @memberof api.ScheduleSetOption
         * @static
         * @param {api.IScheduleSetOption} message ScheduleSetOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSetOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.scheduleSetId);
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSet.encode(message.scheduleSet, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ScheduleSetOption message, length delimited. Does not implicitly {@link api.ScheduleSetOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ScheduleSetOption
         * @static
         * @param {api.IScheduleSetOption} message ScheduleSetOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ScheduleSetOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ScheduleSetOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.ScheduleSetOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ScheduleSetOption} ScheduleSetOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSetOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ScheduleSetOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.scheduleSetId = reader.string();
                    break;
                case 2:
                    message.scheduleSet = $root.api.ScheduleSet.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ScheduleSetOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ScheduleSetOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ScheduleSetOption} ScheduleSetOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ScheduleSetOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ScheduleSetOption message.
         * @function verify
         * @memberof api.ScheduleSetOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ScheduleSetOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSet.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            return null;
        };

        /**
         * Creates a ScheduleSetOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ScheduleSetOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ScheduleSetOption} ScheduleSetOption
         */
        ScheduleSetOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ScheduleSetOption)
                return object;
            let message = new $root.api.ScheduleSetOption();
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.ScheduleSetOption.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSet.fromObject(object.scheduleSet);
            }
            return message;
        };

        /**
         * Creates a plain object from a ScheduleSetOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ScheduleSetOption
         * @static
         * @param {api.ScheduleSetOption} message ScheduleSetOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ScheduleSetOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.scheduleSetId = "";
                object.scheduleSet = null;
            }
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSet.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this ScheduleSetOption to JSON.
         * @function toJSON
         * @memberof api.ScheduleSetOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ScheduleSetOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ScheduleSetOption;
    })();

    api.SyncOption = (function() {

        /**
         * Properties of a SyncOption.
         * @memberof api
         * @interface ISyncOption
         * @property {string|null} [syncId] SyncOption syncId
         * @property {api.ISync|null} [sync] SyncOption sync
         */

        /**
         * Constructs a new SyncOption.
         * @memberof api
         * @classdesc Represents a SyncOption.
         * @implements ISyncOption
         * @constructor
         * @param {api.ISyncOption=} [properties] Properties to set
         */
        function SyncOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SyncOption syncId.
         * @member {string} syncId
         * @memberof api.SyncOption
         * @instance
         */
        SyncOption.prototype.syncId = "";

        /**
         * SyncOption sync.
         * @member {api.ISync|null|undefined} sync
         * @memberof api.SyncOption
         * @instance
         */
        SyncOption.prototype.sync = null;

        /**
         * Creates a new SyncOption instance using the specified properties.
         * @function create
         * @memberof api.SyncOption
         * @static
         * @param {api.ISyncOption=} [properties] Properties to set
         * @returns {api.SyncOption} SyncOption instance
         */
        SyncOption.create = function create(properties) {
            return new SyncOption(properties);
        };

        /**
         * Encodes the specified SyncOption message. Does not implicitly {@link api.SyncOption.verify|verify} messages.
         * @function encode
         * @memberof api.SyncOption
         * @static
         * @param {api.ISyncOption} message SyncOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.syncId != null && Object.hasOwnProperty.call(message, "syncId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.syncId);
            if (message.sync != null && Object.hasOwnProperty.call(message, "sync"))
                $root.api.Sync.encode(message.sync, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SyncOption message, length delimited. Does not implicitly {@link api.SyncOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SyncOption
         * @static
         * @param {api.ISyncOption} message SyncOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SyncOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SyncOption message from the specified reader or buffer.
         * @function decode
         * @memberof api.SyncOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SyncOption} SyncOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SyncOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.syncId = reader.string();
                    break;
                case 2:
                    message.sync = $root.api.Sync.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SyncOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SyncOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SyncOption} SyncOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SyncOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SyncOption message.
         * @function verify
         * @memberof api.SyncOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SyncOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                if (!$util.isString(message.syncId))
                    return "syncId: string expected";
            if (message.sync != null && message.hasOwnProperty("sync")) {
                let error = $root.api.Sync.verify(message.sync);
                if (error)
                    return "sync." + error;
            }
            return null;
        };

        /**
         * Creates a SyncOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SyncOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SyncOption} SyncOption
         */
        SyncOption.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SyncOption)
                return object;
            let message = new $root.api.SyncOption();
            if (object.syncId != null)
                message.syncId = String(object.syncId);
            if (object.sync != null) {
                if (typeof object.sync !== "object")
                    throw TypeError(".api.SyncOption.sync: object expected");
                message.sync = $root.api.Sync.fromObject(object.sync);
            }
            return message;
        };

        /**
         * Creates a plain object from a SyncOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SyncOption
         * @static
         * @param {api.SyncOption} message SyncOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SyncOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.syncId = "";
                object.sync = null;
            }
            if (message.syncId != null && message.hasOwnProperty("syncId"))
                object.syncId = message.syncId;
            if (message.sync != null && message.hasOwnProperty("sync"))
                object.sync = $root.api.Sync.toObject(message.sync, options);
            return object;
        };

        /**
         * Converts this SyncOption to JSON.
         * @function toJSON
         * @memberof api.SyncOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SyncOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SyncOption;
    })();

    api.Member = (function() {

        /**
         * Properties of a Member.
         * @memberof api
         * @interface IMember
         * @property {api.IMemberObject|null} [obj] Member obj
         * @property {api.IMemberEntity|null} [entity] Member entity
         * @property {boolean|null} [instructor] Member instructor
         * @property {boolean|null} [owner] Member owner
         * @property {boolean|null} [modify] Member modify
         * @property {boolean|null} [accepted] Member accepted
         * @property {number|Long|null} [createDate] Member createDate
         * @property {number|Long|null} [updateDate] Member updateDate
         * @property {string|null} [membershipId] Member membershipId
         */

        /**
         * Constructs a new Member.
         * @memberof api
         * @classdesc Represents a Member.
         * @implements IMember
         * @constructor
         * @param {api.IMember=} [properties] Properties to set
         */
        function Member(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Member obj.
         * @member {api.IMemberObject|null|undefined} obj
         * @memberof api.Member
         * @instance
         */
        Member.prototype.obj = null;

        /**
         * Member entity.
         * @member {api.IMemberEntity|null|undefined} entity
         * @memberof api.Member
         * @instance
         */
        Member.prototype.entity = null;

        /**
         * Member instructor.
         * @member {boolean} instructor
         * @memberof api.Member
         * @instance
         */
        Member.prototype.instructor = false;

        /**
         * Member owner.
         * @member {boolean} owner
         * @memberof api.Member
         * @instance
         */
        Member.prototype.owner = false;

        /**
         * Member modify.
         * @member {boolean} modify
         * @memberof api.Member
         * @instance
         */
        Member.prototype.modify = false;

        /**
         * Member accepted.
         * @member {boolean} accepted
         * @memberof api.Member
         * @instance
         */
        Member.prototype.accepted = false;

        /**
         * Member createDate.
         * @member {number|Long} createDate
         * @memberof api.Member
         * @instance
         */
        Member.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Member updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Member
         * @instance
         */
        Member.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Member membershipId.
         * @member {string} membershipId
         * @memberof api.Member
         * @instance
         */
        Member.prototype.membershipId = "";

        /**
         * Creates a new Member instance using the specified properties.
         * @function create
         * @memberof api.Member
         * @static
         * @param {api.IMember=} [properties] Properties to set
         * @returns {api.Member} Member instance
         */
        Member.create = function create(properties) {
            return new Member(properties);
        };

        /**
         * Encodes the specified Member message. Does not implicitly {@link api.Member.verify|verify} messages.
         * @function encode
         * @memberof api.Member
         * @static
         * @param {api.IMember} message Member message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Member.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.obj != null && Object.hasOwnProperty.call(message, "obj"))
                $root.api.MemberObject.encode(message.obj, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.entity != null && Object.hasOwnProperty.call(message, "entity"))
                $root.api.MemberEntity.encode(message.entity, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.instructor != null && Object.hasOwnProperty.call(message, "instructor"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.instructor);
            if (message.owner != null && Object.hasOwnProperty.call(message, "owner"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.owner);
            if (message.modify != null && Object.hasOwnProperty.call(message, "modify"))
                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.modify);
            if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.accepted);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.updateDate);
            if (message.membershipId != null && Object.hasOwnProperty.call(message, "membershipId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.membershipId);
            return writer;
        };

        /**
         * Encodes the specified Member message, length delimited. Does not implicitly {@link api.Member.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Member
         * @static
         * @param {api.IMember} message Member message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Member.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Member message from the specified reader or buffer.
         * @function decode
         * @memberof api.Member
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Member} Member
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Member.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Member();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.obj = $root.api.MemberObject.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.entity = $root.api.MemberEntity.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.instructor = reader.bool();
                    break;
                case 4:
                    message.owner = reader.bool();
                    break;
                case 5:
                    message.modify = reader.bool();
                    break;
                case 6:
                    message.accepted = reader.bool();
                    break;
                case 7:
                    message.createDate = reader.int64();
                    break;
                case 8:
                    message.updateDate = reader.int64();
                    break;
                case 9:
                    message.membershipId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Member message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Member
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Member} Member
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Member.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Member message.
         * @function verify
         * @memberof api.Member
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Member.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.obj != null && message.hasOwnProperty("obj")) {
                let error = $root.api.MemberObject.verify(message.obj);
                if (error)
                    return "obj." + error;
            }
            if (message.entity != null && message.hasOwnProperty("entity")) {
                let error = $root.api.MemberEntity.verify(message.entity);
                if (error)
                    return "entity." + error;
            }
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                if (typeof message.instructor !== "boolean")
                    return "instructor: boolean expected";
            if (message.owner != null && message.hasOwnProperty("owner"))
                if (typeof message.owner !== "boolean")
                    return "owner: boolean expected";
            if (message.modify != null && message.hasOwnProperty("modify"))
                if (typeof message.modify !== "boolean")
                    return "modify: boolean expected";
            if (message.accepted != null && message.hasOwnProperty("accepted"))
                if (typeof message.accepted !== "boolean")
                    return "accepted: boolean expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            if (message.membershipId != null && message.hasOwnProperty("membershipId"))
                if (!$util.isString(message.membershipId))
                    return "membershipId: string expected";
            return null;
        };

        /**
         * Creates a Member message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Member
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Member} Member
         */
        Member.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Member)
                return object;
            let message = new $root.api.Member();
            if (object.obj != null) {
                if (typeof object.obj !== "object")
                    throw TypeError(".api.Member.obj: object expected");
                message.obj = $root.api.MemberObject.fromObject(object.obj);
            }
            if (object.entity != null) {
                if (typeof object.entity !== "object")
                    throw TypeError(".api.Member.entity: object expected");
                message.entity = $root.api.MemberEntity.fromObject(object.entity);
            }
            if (object.instructor != null)
                message.instructor = Boolean(object.instructor);
            if (object.owner != null)
                message.owner = Boolean(object.owner);
            if (object.modify != null)
                message.modify = Boolean(object.modify);
            if (object.accepted != null)
                message.accepted = Boolean(object.accepted);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            if (object.membershipId != null)
                message.membershipId = String(object.membershipId);
            return message;
        };

        /**
         * Creates a plain object from a Member message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Member
         * @static
         * @param {api.Member} message Member
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Member.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.obj = null;
                object.entity = null;
                object.instructor = false;
                object.owner = false;
                object.modify = false;
                object.accepted = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.membershipId = "";
            }
            if (message.obj != null && message.hasOwnProperty("obj"))
                object.obj = $root.api.MemberObject.toObject(message.obj, options);
            if (message.entity != null && message.hasOwnProperty("entity"))
                object.entity = $root.api.MemberEntity.toObject(message.entity, options);
            if (message.instructor != null && message.hasOwnProperty("instructor"))
                object.instructor = message.instructor;
            if (message.owner != null && message.hasOwnProperty("owner"))
                object.owner = message.owner;
            if (message.modify != null && message.hasOwnProperty("modify"))
                object.modify = message.modify;
            if (message.accepted != null && message.hasOwnProperty("accepted"))
                object.accepted = message.accepted;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.membershipId != null && message.hasOwnProperty("membershipId"))
                object.membershipId = message.membershipId;
            return object;
        };

        /**
         * Converts this Member to JSON.
         * @function toJSON
         * @memberof api.Member
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Member.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Member;
    })();

    api.RoomQuery = (function() {

        /**
         * Properties of a RoomQuery.
         * @memberof api
         * @interface IRoomQuery
         * @property {api.ITopicQuery|null} [topic] RoomQuery topic
         * @property {api.IScheduleSetQuery|null} [scheduleSet] RoomQuery scheduleSet
         * @property {api.IMemberQuery|null} [members] RoomQuery members
         */

        /**
         * Constructs a new RoomQuery.
         * @memberof api
         * @classdesc Represents a RoomQuery.
         * @implements IRoomQuery
         * @constructor
         * @param {api.IRoomQuery=} [properties] Properties to set
         */
        function RoomQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RoomQuery topic.
         * @member {api.ITopicQuery|null|undefined} topic
         * @memberof api.RoomQuery
         * @instance
         */
        RoomQuery.prototype.topic = null;

        /**
         * RoomQuery scheduleSet.
         * @member {api.IScheduleSetQuery|null|undefined} scheduleSet
         * @memberof api.RoomQuery
         * @instance
         */
        RoomQuery.prototype.scheduleSet = null;

        /**
         * RoomQuery members.
         * @member {api.IMemberQuery|null|undefined} members
         * @memberof api.RoomQuery
         * @instance
         */
        RoomQuery.prototype.members = null;

        /**
         * Creates a new RoomQuery instance using the specified properties.
         * @function create
         * @memberof api.RoomQuery
         * @static
         * @param {api.IRoomQuery=} [properties] Properties to set
         * @returns {api.RoomQuery} RoomQuery instance
         */
        RoomQuery.create = function create(properties) {
            return new RoomQuery(properties);
        };

        /**
         * Encodes the specified RoomQuery message. Does not implicitly {@link api.RoomQuery.verify|verify} messages.
         * @function encode
         * @memberof api.RoomQuery
         * @static
         * @param {api.IRoomQuery} message RoomQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.TopicQuery.encode(message.topic, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSetQuery.encode(message.scheduleSet, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.MemberQuery.encode(message.members, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RoomQuery message, length delimited. Does not implicitly {@link api.RoomQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.RoomQuery
         * @static
         * @param {api.IRoomQuery} message RoomQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RoomQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RoomQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.RoomQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.RoomQuery} RoomQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.RoomQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.topic = $root.api.TopicQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.scheduleSet = $root.api.ScheduleSetQuery.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.members = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RoomQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.RoomQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.RoomQuery} RoomQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RoomQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RoomQuery message.
         * @function verify
         * @memberof api.RoomQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RoomQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.TopicQuery.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSetQuery.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.MemberQuery.verify(message.members);
                if (error)
                    return "members." + error;
            }
            return null;
        };

        /**
         * Creates a RoomQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.RoomQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.RoomQuery} RoomQuery
         */
        RoomQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.RoomQuery)
                return object;
            let message = new $root.api.RoomQuery();
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.RoomQuery.topic: object expected");
                message.topic = $root.api.TopicQuery.fromObject(object.topic);
            }
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.RoomQuery.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSetQuery.fromObject(object.scheduleSet);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.RoomQuery.members: object expected");
                message.members = $root.api.MemberQuery.fromObject(object.members);
            }
            return message;
        };

        /**
         * Creates a plain object from a RoomQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.RoomQuery
         * @static
         * @param {api.RoomQuery} message RoomQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RoomQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.topic = null;
                object.scheduleSet = null;
                object.members = null;
            }
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.TopicQuery.toObject(message.topic, options);
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSetQuery.toObject(message.scheduleSet, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.MemberQuery.toObject(message.members, options);
            return object;
        };

        /**
         * Converts this RoomQuery to JSON.
         * @function toJSON
         * @memberof api.RoomQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RoomQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RoomQuery;
    })();

    api.TopicReview = (function() {

        /**
         * Properties of a TopicReview.
         * @memberof api
         * @interface ITopicReview
         * @property {string|null} [topicId] TopicReview topicId
         * @property {string|null} [roomId] TopicReview roomId
         * @property {string|null} [timeId] TopicReview timeId
         * @property {string|null} [firstName] TopicReview firstName
         * @property {string|null} [lastName] TopicReview lastName
         * @property {number|null} [rating] TopicReview rating
         * @property {string|null} [review] TopicReview review
         * @property {number|Long|null} [createDate] TopicReview createDate
         * @property {number|Long|null} [updateDate] TopicReview updateDate
         */

        /**
         * Constructs a new TopicReview.
         * @memberof api
         * @classdesc Represents a TopicReview.
         * @implements ITopicReview
         * @constructor
         * @param {api.ITopicReview=} [properties] Properties to set
         */
        function TopicReview(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TopicReview topicId.
         * @member {string} topicId
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.topicId = "";

        /**
         * TopicReview roomId.
         * @member {string|null|undefined} roomId
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.roomId = null;

        /**
         * TopicReview timeId.
         * @member {string|null|undefined} timeId
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.timeId = null;

        /**
         * TopicReview firstName.
         * @member {string} firstName
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.firstName = "";

        /**
         * TopicReview lastName.
         * @member {string} lastName
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.lastName = "";

        /**
         * TopicReview rating.
         * @member {number} rating
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.rating = 0;

        /**
         * TopicReview review.
         * @member {string} review
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.review = "";

        /**
         * TopicReview createDate.
         * @member {number|Long} createDate
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TopicReview updateDate.
         * @member {number|Long} updateDate
         * @memberof api.TopicReview
         * @instance
         */
        TopicReview.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * TopicReview reviewOption.
         * @member {"roomId"|"timeId"|undefined} reviewOption
         * @memberof api.TopicReview
         * @instance
         */
        Object.defineProperty(TopicReview.prototype, "reviewOption", {
            get: $util.oneOfGetter($oneOfFields = ["roomId", "timeId"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new TopicReview instance using the specified properties.
         * @function create
         * @memberof api.TopicReview
         * @static
         * @param {api.ITopicReview=} [properties] Properties to set
         * @returns {api.TopicReview} TopicReview instance
         */
        TopicReview.create = function create(properties) {
            return new TopicReview(properties);
        };

        /**
         * Encodes the specified TopicReview message. Does not implicitly {@link api.TopicReview.verify|verify} messages.
         * @function encode
         * @memberof api.TopicReview
         * @static
         * @param {api.ITopicReview} message TopicReview message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicReview.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.roomId);
            if (message.timeId != null && Object.hasOwnProperty.call(message, "timeId"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeId);
            if (message.firstName != null && Object.hasOwnProperty.call(message, "firstName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.firstName);
            if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
            if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.rating);
            if (message.review != null && Object.hasOwnProperty.call(message, "review"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.review);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.updateDate);
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.topicId);
            return writer;
        };

        /**
         * Encodes the specified TopicReview message, length delimited. Does not implicitly {@link api.TopicReview.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TopicReview
         * @static
         * @param {api.ITopicReview} message TopicReview message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicReview.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TopicReview message from the specified reader or buffer.
         * @function decode
         * @memberof api.TopicReview
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TopicReview} TopicReview
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicReview.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TopicReview();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 9:
                    message.topicId = reader.string();
                    break;
                case 1:
                    message.roomId = reader.string();
                    break;
                case 2:
                    message.timeId = reader.string();
                    break;
                case 3:
                    message.firstName = reader.string();
                    break;
                case 4:
                    message.lastName = reader.string();
                    break;
                case 5:
                    message.rating = reader.int32();
                    break;
                case 6:
                    message.review = reader.string();
                    break;
                case 7:
                    message.createDate = reader.int64();
                    break;
                case 8:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TopicReview message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TopicReview
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TopicReview} TopicReview
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicReview.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TopicReview message.
         * @function verify
         * @memberof api.TopicReview
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TopicReview.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                properties.reviewOption = 1;
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                if (properties.reviewOption === 1)
                    return "reviewOption: multiple values";
                properties.reviewOption = 1;
                if (!$util.isString(message.timeId))
                    return "timeId: string expected";
            }
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                if (!$util.isString(message.firstName))
                    return "firstName: string expected";
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                if (!$util.isString(message.lastName))
                    return "lastName: string expected";
            if (message.rating != null && message.hasOwnProperty("rating"))
                if (!$util.isInteger(message.rating))
                    return "rating: integer expected";
            if (message.review != null && message.hasOwnProperty("review"))
                if (!$util.isString(message.review))
                    return "review: string expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a TopicReview message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TopicReview
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TopicReview} TopicReview
         */
        TopicReview.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TopicReview)
                return object;
            let message = new $root.api.TopicReview();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.timeId != null)
                message.timeId = String(object.timeId);
            if (object.firstName != null)
                message.firstName = String(object.firstName);
            if (object.lastName != null)
                message.lastName = String(object.lastName);
            if (object.rating != null)
                message.rating = object.rating | 0;
            if (object.review != null)
                message.review = String(object.review);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TopicReview message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TopicReview
         * @static
         * @param {api.TopicReview} message TopicReview
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicReview.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.firstName = "";
                object.lastName = "";
                object.rating = 0;
                object.review = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.topicId = "";
            }
            if (message.roomId != null && message.hasOwnProperty("roomId")) {
                object.roomId = message.roomId;
                if (options.oneofs)
                    object.reviewOption = "roomId";
            }
            if (message.timeId != null && message.hasOwnProperty("timeId")) {
                object.timeId = message.timeId;
                if (options.oneofs)
                    object.reviewOption = "timeId";
            }
            if (message.firstName != null && message.hasOwnProperty("firstName"))
                object.firstName = message.firstName;
            if (message.lastName != null && message.hasOwnProperty("lastName"))
                object.lastName = message.lastName;
            if (message.rating != null && message.hasOwnProperty("rating"))
                object.rating = message.rating;
            if (message.review != null && message.hasOwnProperty("review"))
                object.review = message.review;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            return object;
        };

        /**
         * Converts this TopicReview to JSON.
         * @function toJSON
         * @memberof api.TopicReview
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicReview.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicReview;
    })();

    api.Room = (function() {

        /**
         * Properties of a Room.
         * @memberof api
         * @interface IRoom
         * @property {string|null} [topicId] Room topicId
         * @property {string|null} [topicDescription] Room topicDescription
         * @property {string|null} [roomId] Room roomId
         * @property {string|null} [section] Room section
         * @property {string|null} [room] Room room
         * @property {api.ITopic|null} [topic] Room topic
         * @property {string|null} [scheduleSetId] Room scheduleSetId
         * @property {api.IScheduleSet|null} [scheduleSet] Room scheduleSet
         * @property {number|null} [memberCount] Room memberCount
         * @property {Array.<api.IMember>|null} [members] Room members
         * @property {number|Long|null} [createDate] Room createDate
         * @property {number|Long|null} [updateDate] Room updateDate
         */

        /**
         * Constructs a new Room.
         * @memberof api
         * @classdesc Represents a Room.
         * @implements IRoom
         * @constructor
         * @param {api.IRoom=} [properties] Properties to set
         */
        function Room(properties) {
            this.members = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Room topicId.
         * @member {string} topicId
         * @memberof api.Room
         * @instance
         */
        Room.prototype.topicId = "";

        /**
         * Room topicDescription.
         * @member {string} topicDescription
         * @memberof api.Room
         * @instance
         */
        Room.prototype.topicDescription = "";

        /**
         * Room roomId.
         * @member {string} roomId
         * @memberof api.Room
         * @instance
         */
        Room.prototype.roomId = "";

        /**
         * Room section.
         * @member {string} section
         * @memberof api.Room
         * @instance
         */
        Room.prototype.section = "";

        /**
         * Room room.
         * @member {string} room
         * @memberof api.Room
         * @instance
         */
        Room.prototype.room = "";

        /**
         * Room topic.
         * @member {api.ITopic|null|undefined} topic
         * @memberof api.Room
         * @instance
         */
        Room.prototype.topic = null;

        /**
         * Room scheduleSetId.
         * @member {string} scheduleSetId
         * @memberof api.Room
         * @instance
         */
        Room.prototype.scheduleSetId = "";

        /**
         * Room scheduleSet.
         * @member {api.IScheduleSet|null|undefined} scheduleSet
         * @memberof api.Room
         * @instance
         */
        Room.prototype.scheduleSet = null;

        /**
         * Room memberCount.
         * @member {number} memberCount
         * @memberof api.Room
         * @instance
         */
        Room.prototype.memberCount = 0;

        /**
         * Room members.
         * @member {Array.<api.IMember>} members
         * @memberof api.Room
         * @instance
         */
        Room.prototype.members = $util.emptyArray;

        /**
         * Room createDate.
         * @member {number|Long} createDate
         * @memberof api.Room
         * @instance
         */
        Room.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Room updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Room
         * @instance
         */
        Room.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Room instance using the specified properties.
         * @function create
         * @memberof api.Room
         * @static
         * @param {api.IRoom=} [properties] Properties to set
         * @returns {api.Room} Room instance
         */
        Room.create = function create(properties) {
            return new Room(properties);
        };

        /**
         * Encodes the specified Room message. Does not implicitly {@link api.Room.verify|verify} messages.
         * @function encode
         * @memberof api.Room
         * @static
         * @param {api.IRoom} message Room message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Room.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.topicId);
            if (message.topicDescription != null && Object.hasOwnProperty.call(message, "topicDescription"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.topicDescription);
            if (message.roomId != null && Object.hasOwnProperty.call(message, "roomId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.roomId);
            if (message.section != null && Object.hasOwnProperty.call(message, "section"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.section);
            if (message.room != null && Object.hasOwnProperty.call(message, "room"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.room);
            if (message.topic != null && Object.hasOwnProperty.call(message, "topic"))
                $root.api.Topic.encode(message.topic, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.scheduleSetId != null && Object.hasOwnProperty.call(message, "scheduleSetId"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.scheduleSetId);
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.api.Member.encode(message.members[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 19, wireType 0 =*/152).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 20, wireType 0 =*/160).int64(message.updateDate);
            if (message.memberCount != null && Object.hasOwnProperty.call(message, "memberCount"))
                writer.uint32(/* id 21, wireType 0 =*/168).int32(message.memberCount);
            if (message.scheduleSet != null && Object.hasOwnProperty.call(message, "scheduleSet"))
                $root.api.ScheduleSet.encode(message.scheduleSet, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Room message, length delimited. Does not implicitly {@link api.Room.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Room
         * @static
         * @param {api.IRoom} message Room message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Room.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Room message from the specified reader or buffer.
         * @function decode
         * @memberof api.Room
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Room} Room
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Room.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Room();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topicId = reader.string();
                    break;
                case 2:
                    message.topicDescription = reader.string();
                    break;
                case 3:
                    message.roomId = reader.string();
                    break;
                case 4:
                    message.section = reader.string();
                    break;
                case 5:
                    message.room = reader.string();
                    break;
                case 15:
                    message.topic = $root.api.Topic.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.scheduleSetId = reader.string();
                    break;
                case 22:
                    message.scheduleSet = $root.api.ScheduleSet.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.memberCount = reader.int32();
                    break;
                case 17:
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 19:
                    message.createDate = reader.int64();
                    break;
                case 20:
                    message.updateDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Room message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Room
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Room} Room
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Room.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Room message.
         * @function verify
         * @memberof api.Room
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Room.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.topicDescription != null && message.hasOwnProperty("topicDescription"))
                if (!$util.isString(message.topicDescription))
                    return "topicDescription: string expected";
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                if (!$util.isString(message.roomId))
                    return "roomId: string expected";
            if (message.section != null && message.hasOwnProperty("section"))
                if (!$util.isString(message.section))
                    return "section: string expected";
            if (message.room != null && message.hasOwnProperty("room"))
                if (!$util.isString(message.room))
                    return "room: string expected";
            if (message.topic != null && message.hasOwnProperty("topic")) {
                let error = $root.api.Topic.verify(message.topic);
                if (error)
                    return "topic." + error;
            }
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                if (!$util.isString(message.scheduleSetId))
                    return "scheduleSetId: string expected";
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet")) {
                let error = $root.api.ScheduleSet.verify(message.scheduleSet);
                if (error)
                    return "scheduleSet." + error;
            }
            if (message.memberCount != null && message.hasOwnProperty("memberCount"))
                if (!$util.isInteger(message.memberCount))
                    return "memberCount: integer expected";
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.api.Member.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            return null;
        };

        /**
         * Creates a Room message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Room
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Room} Room
         */
        Room.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Room)
                return object;
            let message = new $root.api.Room();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.topicDescription != null)
                message.topicDescription = String(object.topicDescription);
            if (object.roomId != null)
                message.roomId = String(object.roomId);
            if (object.section != null)
                message.section = String(object.section);
            if (object.room != null)
                message.room = String(object.room);
            if (object.topic != null) {
                if (typeof object.topic !== "object")
                    throw TypeError(".api.Room.topic: object expected");
                message.topic = $root.api.Topic.fromObject(object.topic);
            }
            if (object.scheduleSetId != null)
                message.scheduleSetId = String(object.scheduleSetId);
            if (object.scheduleSet != null) {
                if (typeof object.scheduleSet !== "object")
                    throw TypeError(".api.Room.scheduleSet: object expected");
                message.scheduleSet = $root.api.ScheduleSet.fromObject(object.scheduleSet);
            }
            if (object.memberCount != null)
                message.memberCount = object.memberCount | 0;
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.Room.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.Room.members: object expected");
                    message.members[i] = $root.api.Member.fromObject(object.members[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a Room message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Room
         * @static
         * @param {api.Room} message Room
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Room.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.members = [];
            if (options.defaults) {
                object.topicId = "";
                object.topicDescription = "";
                object.roomId = "";
                object.section = "";
                object.room = "";
                object.topic = null;
                object.scheduleSetId = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                object.memberCount = 0;
                object.scheduleSet = null;
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.topicDescription != null && message.hasOwnProperty("topicDescription"))
                object.topicDescription = message.topicDescription;
            if (message.roomId != null && message.hasOwnProperty("roomId"))
                object.roomId = message.roomId;
            if (message.section != null && message.hasOwnProperty("section"))
                object.section = message.section;
            if (message.room != null && message.hasOwnProperty("room"))
                object.room = message.room;
            if (message.topic != null && message.hasOwnProperty("topic"))
                object.topic = $root.api.Topic.toObject(message.topic, options);
            if (message.scheduleSetId != null && message.hasOwnProperty("scheduleSetId"))
                object.scheduleSetId = message.scheduleSetId;
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.api.Member.toObject(message.members[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.memberCount != null && message.hasOwnProperty("memberCount"))
                object.memberCount = message.memberCount;
            if (message.scheduleSet != null && message.hasOwnProperty("scheduleSet"))
                object.scheduleSet = $root.api.ScheduleSet.toObject(message.scheduleSet, options);
            return object;
        };

        /**
         * Converts this Room to JSON.
         * @function toJSON
         * @memberof api.Room
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Room.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Room;
    })();

    api.TagQuery = (function() {

        /**
         * Properties of a TagQuery.
         * @memberof api
         * @interface ITagQuery
         */

        /**
         * Constructs a new TagQuery.
         * @memberof api
         * @classdesc Represents a TagQuery.
         * @implements ITagQuery
         * @constructor
         * @param {api.ITagQuery=} [properties] Properties to set
         */
        function TagQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new TagQuery instance using the specified properties.
         * @function create
         * @memberof api.TagQuery
         * @static
         * @param {api.ITagQuery=} [properties] Properties to set
         * @returns {api.TagQuery} TagQuery instance
         */
        TagQuery.create = function create(properties) {
            return new TagQuery(properties);
        };

        /**
         * Encodes the specified TagQuery message. Does not implicitly {@link api.TagQuery.verify|verify} messages.
         * @function encode
         * @memberof api.TagQuery
         * @static
         * @param {api.ITagQuery} message TagQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified TagQuery message, length delimited. Does not implicitly {@link api.TagQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TagQuery
         * @static
         * @param {api.ITagQuery} message TagQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TagQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TagQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.TagQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TagQuery} TagQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TagQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TagQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TagQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TagQuery} TagQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TagQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TagQuery message.
         * @function verify
         * @memberof api.TagQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TagQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a TagQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TagQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TagQuery} TagQuery
         */
        TagQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TagQuery)
                return object;
            return new $root.api.TagQuery();
        };

        /**
         * Creates a plain object from a TagQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TagQuery
         * @static
         * @param {api.TagQuery} message TagQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TagQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this TagQuery to JSON.
         * @function toJSON
         * @memberof api.TagQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TagQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TagQuery;
    })();

    api.TopicQuery = (function() {

        /**
         * Properties of a TopicQuery.
         * @memberof api
         * @interface ITopicQuery
         * @property {boolean|null} [includeDescription] TopicQuery includeDescription
         * @property {boolean|null} [includeReviewMessage] TopicQuery includeReviewMessage
         * @property {api.IRoomQuery|null} [rooms] TopicQuery rooms
         * @property {api.IMemberQuery|null} [members] TopicQuery members
         * @property {api.ITagQuery|null} [tags] TopicQuery tags
         * @property {api.IObjectAssetQuery|null} [assets] TopicQuery assets
         * @property {api.ITopicReviewQuery|null} [reviews] TopicQuery reviews
         */

        /**
         * Constructs a new TopicQuery.
         * @memberof api
         * @classdesc Represents a TopicQuery.
         * @implements ITopicQuery
         * @constructor
         * @param {api.ITopicQuery=} [properties] Properties to set
         */
        function TopicQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TopicQuery includeDescription.
         * @member {boolean} includeDescription
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.includeDescription = false;

        /**
         * TopicQuery includeReviewMessage.
         * @member {boolean} includeReviewMessage
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.includeReviewMessage = false;

        /**
         * TopicQuery rooms.
         * @member {api.IRoomQuery|null|undefined} rooms
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.rooms = null;

        /**
         * TopicQuery members.
         * @member {api.IMemberQuery|null|undefined} members
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.members = null;

        /**
         * TopicQuery tags.
         * @member {api.ITagQuery|null|undefined} tags
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.tags = null;

        /**
         * TopicQuery assets.
         * @member {api.IObjectAssetQuery|null|undefined} assets
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.assets = null;

        /**
         * TopicQuery reviews.
         * @member {api.ITopicReviewQuery|null|undefined} reviews
         * @memberof api.TopicQuery
         * @instance
         */
        TopicQuery.prototype.reviews = null;

        /**
         * Creates a new TopicQuery instance using the specified properties.
         * @function create
         * @memberof api.TopicQuery
         * @static
         * @param {api.ITopicQuery=} [properties] Properties to set
         * @returns {api.TopicQuery} TopicQuery instance
         */
        TopicQuery.create = function create(properties) {
            return new TopicQuery(properties);
        };

        /**
         * Encodes the specified TopicQuery message. Does not implicitly {@link api.TopicQuery.verify|verify} messages.
         * @function encode
         * @memberof api.TopicQuery
         * @static
         * @param {api.ITopicQuery} message TopicQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.includeDescription != null && Object.hasOwnProperty.call(message, "includeDescription"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.includeDescription);
            if (message.rooms != null && Object.hasOwnProperty.call(message, "rooms"))
                $root.api.RoomQuery.encode(message.rooms, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.members != null && Object.hasOwnProperty.call(message, "members"))
                $root.api.MemberQuery.encode(message.members, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.tags != null && Object.hasOwnProperty.call(message, "tags"))
                $root.api.TagQuery.encode(message.tags, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.assets != null && Object.hasOwnProperty.call(message, "assets"))
                $root.api.ObjectAssetQuery.encode(message.assets, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.reviews != null && Object.hasOwnProperty.call(message, "reviews"))
                $root.api.TopicReviewQuery.encode(message.reviews, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.includeReviewMessage != null && Object.hasOwnProperty.call(message, "includeReviewMessage"))
                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.includeReviewMessage);
            return writer;
        };

        /**
         * Encodes the specified TopicQuery message, length delimited. Does not implicitly {@link api.TopicQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.TopicQuery
         * @static
         * @param {api.ITopicQuery} message TopicQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TopicQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TopicQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.TopicQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.TopicQuery} TopicQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.TopicQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.includeDescription = reader.bool();
                    break;
                case 7:
                    message.includeReviewMessage = reader.bool();
                    break;
                case 2:
                    message.rooms = $root.api.RoomQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.members = $root.api.MemberQuery.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.tags = $root.api.TagQuery.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.assets = $root.api.ObjectAssetQuery.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.reviews = $root.api.TopicReviewQuery.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TopicQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.TopicQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.TopicQuery} TopicQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TopicQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TopicQuery message.
         * @function verify
         * @memberof api.TopicQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TopicQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.includeDescription != null && message.hasOwnProperty("includeDescription"))
                if (typeof message.includeDescription !== "boolean")
                    return "includeDescription: boolean expected";
            if (message.includeReviewMessage != null && message.hasOwnProperty("includeReviewMessage"))
                if (typeof message.includeReviewMessage !== "boolean")
                    return "includeReviewMessage: boolean expected";
            if (message.rooms != null && message.hasOwnProperty("rooms")) {
                let error = $root.api.RoomQuery.verify(message.rooms);
                if (error)
                    return "rooms." + error;
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                let error = $root.api.MemberQuery.verify(message.members);
                if (error)
                    return "members." + error;
            }
            if (message.tags != null && message.hasOwnProperty("tags")) {
                let error = $root.api.TagQuery.verify(message.tags);
                if (error)
                    return "tags." + error;
            }
            if (message.assets != null && message.hasOwnProperty("assets")) {
                let error = $root.api.ObjectAssetQuery.verify(message.assets);
                if (error)
                    return "assets." + error;
            }
            if (message.reviews != null && message.hasOwnProperty("reviews")) {
                let error = $root.api.TopicReviewQuery.verify(message.reviews);
                if (error)
                    return "reviews." + error;
            }
            return null;
        };

        /**
         * Creates a TopicQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.TopicQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.TopicQuery} TopicQuery
         */
        TopicQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.TopicQuery)
                return object;
            let message = new $root.api.TopicQuery();
            if (object.includeDescription != null)
                message.includeDescription = Boolean(object.includeDescription);
            if (object.includeReviewMessage != null)
                message.includeReviewMessage = Boolean(object.includeReviewMessage);
            if (object.rooms != null) {
                if (typeof object.rooms !== "object")
                    throw TypeError(".api.TopicQuery.rooms: object expected");
                message.rooms = $root.api.RoomQuery.fromObject(object.rooms);
            }
            if (object.members != null) {
                if (typeof object.members !== "object")
                    throw TypeError(".api.TopicQuery.members: object expected");
                message.members = $root.api.MemberQuery.fromObject(object.members);
            }
            if (object.tags != null) {
                if (typeof object.tags !== "object")
                    throw TypeError(".api.TopicQuery.tags: object expected");
                message.tags = $root.api.TagQuery.fromObject(object.tags);
            }
            if (object.assets != null) {
                if (typeof object.assets !== "object")
                    throw TypeError(".api.TopicQuery.assets: object expected");
                message.assets = $root.api.ObjectAssetQuery.fromObject(object.assets);
            }
            if (object.reviews != null) {
                if (typeof object.reviews !== "object")
                    throw TypeError(".api.TopicQuery.reviews: object expected");
                message.reviews = $root.api.TopicReviewQuery.fromObject(object.reviews);
            }
            return message;
        };

        /**
         * Creates a plain object from a TopicQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.TopicQuery
         * @static
         * @param {api.TopicQuery} message TopicQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TopicQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.includeDescription = false;
                object.rooms = null;
                object.members = null;
                object.tags = null;
                object.assets = null;
                object.reviews = null;
                object.includeReviewMessage = false;
            }
            if (message.includeDescription != null && message.hasOwnProperty("includeDescription"))
                object.includeDescription = message.includeDescription;
            if (message.rooms != null && message.hasOwnProperty("rooms"))
                object.rooms = $root.api.RoomQuery.toObject(message.rooms, options);
            if (message.members != null && message.hasOwnProperty("members"))
                object.members = $root.api.MemberQuery.toObject(message.members, options);
            if (message.tags != null && message.hasOwnProperty("tags"))
                object.tags = $root.api.TagQuery.toObject(message.tags, options);
            if (message.assets != null && message.hasOwnProperty("assets"))
                object.assets = $root.api.ObjectAssetQuery.toObject(message.assets, options);
            if (message.reviews != null && message.hasOwnProperty("reviews"))
                object.reviews = $root.api.TopicReviewQuery.toObject(message.reviews, options);
            if (message.includeReviewMessage != null && message.hasOwnProperty("includeReviewMessage"))
                object.includeReviewMessage = message.includeReviewMessage;
            return object;
        };

        /**
         * Converts this TopicQuery to JSON.
         * @function toJSON
         * @memberof api.TopicQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TopicQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TopicQuery;
    })();

    api.SingleSession = (function() {

        /**
         * Properties of a SingleSession.
         * @memberof api
         * @interface ISingleSession
         */

        /**
         * Constructs a new SingleSession.
         * @memberof api
         * @classdesc Represents a SingleSession.
         * @implements ISingleSession
         * @constructor
         * @param {api.ISingleSession=} [properties] Properties to set
         */
        function SingleSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new SingleSession instance using the specified properties.
         * @function create
         * @memberof api.SingleSession
         * @static
         * @param {api.ISingleSession=} [properties] Properties to set
         * @returns {api.SingleSession} SingleSession instance
         */
        SingleSession.create = function create(properties) {
            return new SingleSession(properties);
        };

        /**
         * Encodes the specified SingleSession message. Does not implicitly {@link api.SingleSession.verify|verify} messages.
         * @function encode
         * @memberof api.SingleSession
         * @static
         * @param {api.ISingleSession} message SingleSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified SingleSession message, length delimited. Does not implicitly {@link api.SingleSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SingleSession
         * @static
         * @param {api.ISingleSession} message SingleSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SingleSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SingleSession message from the specified reader or buffer.
         * @function decode
         * @memberof api.SingleSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SingleSession} SingleSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SingleSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SingleSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SingleSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SingleSession} SingleSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SingleSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SingleSession message.
         * @function verify
         * @memberof api.SingleSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SingleSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a SingleSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SingleSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SingleSession} SingleSession
         */
        SingleSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SingleSession)
                return object;
            return new $root.api.SingleSession();
        };

        /**
         * Creates a plain object from a SingleSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SingleSession
         * @static
         * @param {api.SingleSession} message SingleSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SingleSession.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this SingleSession to JSON.
         * @function toJSON
         * @memberof api.SingleSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SingleSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SingleSession;
    })();

    api.MultiSession = (function() {

        /**
         * Properties of a MultiSession.
         * @memberof api
         * @interface IMultiSession
         * @property {boolean|null} [mustBeMemberBeforeFirstSession] MultiSession mustBeMemberBeforeFirstSession
         */

        /**
         * Constructs a new MultiSession.
         * @memberof api
         * @classdesc Represents a MultiSession.
         * @implements IMultiSession
         * @constructor
         * @param {api.IMultiSession=} [properties] Properties to set
         */
        function MultiSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * MultiSession mustBeMemberBeforeFirstSession.
         * @member {boolean} mustBeMemberBeforeFirstSession
         * @memberof api.MultiSession
         * @instance
         */
        MultiSession.prototype.mustBeMemberBeforeFirstSession = false;

        /**
         * Creates a new MultiSession instance using the specified properties.
         * @function create
         * @memberof api.MultiSession
         * @static
         * @param {api.IMultiSession=} [properties] Properties to set
         * @returns {api.MultiSession} MultiSession instance
         */
        MultiSession.create = function create(properties) {
            return new MultiSession(properties);
        };

        /**
         * Encodes the specified MultiSession message. Does not implicitly {@link api.MultiSession.verify|verify} messages.
         * @function encode
         * @memberof api.MultiSession
         * @static
         * @param {api.IMultiSession} message MultiSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MultiSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mustBeMemberBeforeFirstSession != null && Object.hasOwnProperty.call(message, "mustBeMemberBeforeFirstSession"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.mustBeMemberBeforeFirstSession);
            return writer;
        };

        /**
         * Encodes the specified MultiSession message, length delimited. Does not implicitly {@link api.MultiSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.MultiSession
         * @static
         * @param {api.IMultiSession} message MultiSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MultiSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a MultiSession message from the specified reader or buffer.
         * @function decode
         * @memberof api.MultiSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.MultiSession} MultiSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MultiSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.MultiSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.mustBeMemberBeforeFirstSession = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a MultiSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.MultiSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.MultiSession} MultiSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MultiSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a MultiSession message.
         * @function verify
         * @memberof api.MultiSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MultiSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mustBeMemberBeforeFirstSession != null && message.hasOwnProperty("mustBeMemberBeforeFirstSession"))
                if (typeof message.mustBeMemberBeforeFirstSession !== "boolean")
                    return "mustBeMemberBeforeFirstSession: boolean expected";
            return null;
        };

        /**
         * Creates a MultiSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.MultiSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.MultiSession} MultiSession
         */
        MultiSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api.MultiSession)
                return object;
            let message = new $root.api.MultiSession();
            if (object.mustBeMemberBeforeFirstSession != null)
                message.mustBeMemberBeforeFirstSession = Boolean(object.mustBeMemberBeforeFirstSession);
            return message;
        };

        /**
         * Creates a plain object from a MultiSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.MultiSession
         * @static
         * @param {api.MultiSession} message MultiSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MultiSession.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.mustBeMemberBeforeFirstSession = false;
            if (message.mustBeMemberBeforeFirstSession != null && message.hasOwnProperty("mustBeMemberBeforeFirstSession"))
                object.mustBeMemberBeforeFirstSession = message.mustBeMemberBeforeFirstSession;
            return object;
        };

        /**
         * Converts this MultiSession to JSON.
         * @function toJSON
         * @memberof api.MultiSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MultiSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return MultiSession;
    })();

    api.OngoingSession = (function() {

        /**
         * Properties of an OngoingSession.
         * @memberof api
         * @interface IOngoingSession
         */

        /**
         * Constructs a new OngoingSession.
         * @memberof api
         * @classdesc Represents an OngoingSession.
         * @implements IOngoingSession
         * @constructor
         * @param {api.IOngoingSession=} [properties] Properties to set
         */
        function OngoingSession(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new OngoingSession instance using the specified properties.
         * @function create
         * @memberof api.OngoingSession
         * @static
         * @param {api.IOngoingSession=} [properties] Properties to set
         * @returns {api.OngoingSession} OngoingSession instance
         */
        OngoingSession.create = function create(properties) {
            return new OngoingSession(properties);
        };

        /**
         * Encodes the specified OngoingSession message. Does not implicitly {@link api.OngoingSession.verify|verify} messages.
         * @function encode
         * @memberof api.OngoingSession
         * @static
         * @param {api.IOngoingSession} message OngoingSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OngoingSession.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified OngoingSession message, length delimited. Does not implicitly {@link api.OngoingSession.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.OngoingSession
         * @static
         * @param {api.IOngoingSession} message OngoingSession message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OngoingSession.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OngoingSession message from the specified reader or buffer.
         * @function decode
         * @memberof api.OngoingSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.OngoingSession} OngoingSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OngoingSession.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.OngoingSession();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OngoingSession message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.OngoingSession
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.OngoingSession} OngoingSession
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OngoingSession.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OngoingSession message.
         * @function verify
         * @memberof api.OngoingSession
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OngoingSession.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an OngoingSession message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.OngoingSession
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.OngoingSession} OngoingSession
         */
        OngoingSession.fromObject = function fromObject(object) {
            if (object instanceof $root.api.OngoingSession)
                return object;
            return new $root.api.OngoingSession();
        };

        /**
         * Creates a plain object from an OngoingSession message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.OngoingSession
         * @static
         * @param {api.OngoingSession} message OngoingSession
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OngoingSession.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this OngoingSession to JSON.
         * @function toJSON
         * @memberof api.OngoingSession
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OngoingSession.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OngoingSession;
    })();

    api.ObjectAssetQuery = (function() {

        /**
         * Properties of an ObjectAssetQuery.
         * @memberof api
         * @interface IObjectAssetQuery
         */

        /**
         * Constructs a new ObjectAssetQuery.
         * @memberof api
         * @classdesc Represents an ObjectAssetQuery.
         * @implements IObjectAssetQuery
         * @constructor
         * @param {api.IObjectAssetQuery=} [properties] Properties to set
         */
        function ObjectAssetQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ObjectAssetQuery instance using the specified properties.
         * @function create
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {api.IObjectAssetQuery=} [properties] Properties to set
         * @returns {api.ObjectAssetQuery} ObjectAssetQuery instance
         */
        ObjectAssetQuery.create = function create(properties) {
            return new ObjectAssetQuery(properties);
        };

        /**
         * Encodes the specified ObjectAssetQuery message. Does not implicitly {@link api.ObjectAssetQuery.verify|verify} messages.
         * @function encode
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {api.IObjectAssetQuery} message ObjectAssetQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ObjectAssetQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ObjectAssetQuery message, length delimited. Does not implicitly {@link api.ObjectAssetQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {api.IObjectAssetQuery} message ObjectAssetQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ObjectAssetQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ObjectAssetQuery message from the specified reader or buffer.
         * @function decode
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ObjectAssetQuery} ObjectAssetQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ObjectAssetQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ObjectAssetQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ObjectAssetQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ObjectAssetQuery} ObjectAssetQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ObjectAssetQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ObjectAssetQuery message.
         * @function verify
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ObjectAssetQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an ObjectAssetQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ObjectAssetQuery} ObjectAssetQuery
         */
        ObjectAssetQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ObjectAssetQuery)
                return object;
            return new $root.api.ObjectAssetQuery();
        };

        /**
         * Creates a plain object from an ObjectAssetQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ObjectAssetQuery
         * @static
         * @param {api.ObjectAssetQuery} message ObjectAssetQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ObjectAssetQuery.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ObjectAssetQuery to JSON.
         * @function toJSON
         * @memberof api.ObjectAssetQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ObjectAssetQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ObjectAssetQuery;
    })();

    api.ObjectAsset = (function() {

        /**
         * Properties of an ObjectAsset.
         * @memberof api
         * @interface IObjectAsset
         * @property {string|null} [name] ObjectAsset name
         * @property {string|null} [url] ObjectAsset url
         * @property {number|Long|null} [size] ObjectAsset size
         * @property {string|null} [assetType] ObjectAsset assetType
         * @property {number|Long|null} [createDate] ObjectAsset createDate
         */

        /**
         * Constructs a new ObjectAsset.
         * @memberof api
         * @classdesc Represents an ObjectAsset.
         * @implements IObjectAsset
         * @constructor
         * @param {api.IObjectAsset=} [properties] Properties to set
         */
        function ObjectAsset(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ObjectAsset name.
         * @member {string} name
         * @memberof api.ObjectAsset
         * @instance
         */
        ObjectAsset.prototype.name = "";

        /**
         * ObjectAsset url.
         * @member {string} url
         * @memberof api.ObjectAsset
         * @instance
         */
        ObjectAsset.prototype.url = "";

        /**
         * ObjectAsset size.
         * @member {number|Long} size
         * @memberof api.ObjectAsset
         * @instance
         */
        ObjectAsset.prototype.size = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * ObjectAsset assetType.
         * @member {string} assetType
         * @memberof api.ObjectAsset
         * @instance
         */
        ObjectAsset.prototype.assetType = "";

        /**
         * ObjectAsset createDate.
         * @member {number|Long} createDate
         * @memberof api.ObjectAsset
         * @instance
         */
        ObjectAsset.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new ObjectAsset instance using the specified properties.
         * @function create
         * @memberof api.ObjectAsset
         * @static
         * @param {api.IObjectAsset=} [properties] Properties to set
         * @returns {api.ObjectAsset} ObjectAsset instance
         */
        ObjectAsset.create = function create(properties) {
            return new ObjectAsset(properties);
        };

        /**
         * Encodes the specified ObjectAsset message. Does not implicitly {@link api.ObjectAsset.verify|verify} messages.
         * @function encode
         * @memberof api.ObjectAsset
         * @static
         * @param {api.IObjectAsset} message ObjectAsset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ObjectAsset.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
            if (message.size != null && Object.hasOwnProperty.call(message, "size"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.size);
            if (message.assetType != null && Object.hasOwnProperty.call(message, "assetType"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.assetType);
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.createDate);
            return writer;
        };

        /**
         * Encodes the specified ObjectAsset message, length delimited. Does not implicitly {@link api.ObjectAsset.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.ObjectAsset
         * @static
         * @param {api.IObjectAsset} message ObjectAsset message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ObjectAsset.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ObjectAsset message from the specified reader or buffer.
         * @function decode
         * @memberof api.ObjectAsset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.ObjectAsset} ObjectAsset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ObjectAsset.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.ObjectAsset();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.url = reader.string();
                    break;
                case 3:
                    message.size = reader.int64();
                    break;
                case 4:
                    message.assetType = reader.string();
                    break;
                case 5:
                    message.createDate = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ObjectAsset message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.ObjectAsset
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.ObjectAsset} ObjectAsset
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ObjectAsset.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ObjectAsset message.
         * @function verify
         * @memberof api.ObjectAsset
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ObjectAsset.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.size != null && message.hasOwnProperty("size"))
                if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                    return "size: integer|Long expected";
            if (message.assetType != null && message.hasOwnProperty("assetType"))
                if (!$util.isString(message.assetType))
                    return "assetType: string expected";
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            return null;
        };

        /**
         * Creates an ObjectAsset message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.ObjectAsset
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.ObjectAsset} ObjectAsset
         */
        ObjectAsset.fromObject = function fromObject(object) {
            if (object instanceof $root.api.ObjectAsset)
                return object;
            let message = new $root.api.ObjectAsset();
            if (object.name != null)
                message.name = String(object.name);
            if (object.url != null)
                message.url = String(object.url);
            if (object.size != null)
                if ($util.Long)
                    (message.size = $util.Long.fromValue(object.size)).unsigned = false;
                else if (typeof object.size === "string")
                    message.size = parseInt(object.size, 10);
                else if (typeof object.size === "number")
                    message.size = object.size;
                else if (typeof object.size === "object")
                    message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber();
            if (object.assetType != null)
                message.assetType = String(object.assetType);
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an ObjectAsset message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.ObjectAsset
         * @static
         * @param {api.ObjectAsset} message ObjectAsset
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ObjectAsset.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.name = "";
                object.url = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.size = options.longs === String ? "0" : 0;
                object.assetType = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
            }
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.size != null && message.hasOwnProperty("size"))
                if (typeof message.size === "number")
                    object.size = options.longs === String ? String(message.size) : message.size;
                else
                    object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber() : message.size;
            if (message.assetType != null && message.hasOwnProperty("assetType"))
                object.assetType = message.assetType;
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            return object;
        };

        /**
         * Converts this ObjectAsset to JSON.
         * @function toJSON
         * @memberof api.ObjectAsset
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ObjectAsset.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ObjectAsset;
    })();

    /**
     * CostType enum.
     * @name api.CostType
     * @enum {number}
     * @property {number} free=0 free value
     * @property {number} cost_all_upfront=1 cost_all_upfront value
     * @property {number} cost_per_time_charged_as_you_go=2 cost_per_time_charged_as_you_go value
     * @property {number} cost_per_month=3 cost_per_month value
     */
    api.CostType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "free"] = 0;
        values[valuesById[1] = "cost_all_upfront"] = 1;
        values[valuesById[2] = "cost_per_time_charged_as_you_go"] = 2;
        values[valuesById[3] = "cost_per_month"] = 3;
        return values;
    })();

    api.Topic = (function() {

        /**
         * Properties of a Topic.
         * @memberof api
         * @interface ITopic
         * @property {string|null} [topicId] Topic topicId
         * @property {string|null} [name] Topic name
         * @property {string|null} [description] Topic description
         * @property {api.ISingleSession|null} [singleSession] Topic singleSession
         * @property {api.IMultiSession|null} [multiSession] Topic multiSession
         * @property {api.IOngoingSession|null} [ongoingSession] Topic ongoingSession
         * @property {string|null} [currency] Topic currency
         * @property {number|null} [cost] Topic cost
         * @property {api.CostType|null} [costType] Topic costType
         * @property {number|null} [minAge] Topic minAge
         * @property {number|null} [maxAge] Topic maxAge
         * @property {number|null} [maxParticipants] Topic maxParticipants
         * @property {Array.<api.IRoom>|null} [rooms] Topic rooms
         * @property {Array.<api.IMember>|null} [members] Topic members
         * @property {Array.<api.IObjectAsset>|null} [assets] Topic assets
         * @property {number|Long|null} [createDate] Topic createDate
         * @property {number|Long|null} [updateDate] Topic updateDate
         * @property {number|Long|null} [minDuration] Topic minDuration
         * @property {number|Long|null} [maxDuration] Topic maxDuration
         * @property {number|Long|null} [minTotalDuration] Topic minTotalDuration
         * @property {number|Long|null} [maxTotalDuration] Topic maxTotalDuration
         * @property {Array.<string>|null} [tags] Topic tags
         * @property {Array.<number>|null} [ratingsCount] Topic ratingsCount
         * @property {number|null} [ratingsAverage] Topic ratingsAverage
         * @property {Array.<api.ITopicReview>|null} [reviews] Topic reviews
         * @property {boolean|null} [searchable] Topic searchable
         * @property {boolean|null} [allowSearchable] Topic allowSearchable
         * @property {boolean|null} [pendingReview] Topic pendingReview
         * @property {number|Long|null} [reviewDate] Topic reviewDate
         * @property {string|null} [reviewMessage] Topic reviewMessage
         * @property {string|null} [tinyCode] Topic tinyCode
         * @property {string|null} [vanityName] Topic vanityName
         */

        /**
         * Constructs a new Topic.
         * @memberof api
         * @classdesc Represents a Topic.
         * @implements ITopic
         * @constructor
         * @param {api.ITopic=} [properties] Properties to set
         */
        function Topic(properties) {
            this.rooms = [];
            this.members = [];
            this.assets = [];
            this.tags = [];
            this.ratingsCount = [];
            this.reviews = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Topic topicId.
         * @member {string} topicId
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.topicId = "";

        /**
         * Topic name.
         * @member {string} name
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.name = "";

        /**
         * Topic description.
         * @member {string} description
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.description = "";

        /**
         * Topic singleSession.
         * @member {api.ISingleSession|null|undefined} singleSession
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.singleSession = null;

        /**
         * Topic multiSession.
         * @member {api.IMultiSession|null|undefined} multiSession
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.multiSession = null;

        /**
         * Topic ongoingSession.
         * @member {api.IOngoingSession|null|undefined} ongoingSession
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.ongoingSession = null;

        /**
         * Topic currency.
         * @member {string} currency
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.currency = "";

        /**
         * Topic cost.
         * @member {number} cost
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.cost = 0;

        /**
         * Topic costType.
         * @member {api.CostType} costType
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.costType = 0;

        /**
         * Topic minAge.
         * @member {number} minAge
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.minAge = 0;

        /**
         * Topic maxAge.
         * @member {number} maxAge
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.maxAge = 0;

        /**
         * Topic maxParticipants.
         * @member {number} maxParticipants
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.maxParticipants = 0;

        /**
         * Topic rooms.
         * @member {Array.<api.IRoom>} rooms
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.rooms = $util.emptyArray;

        /**
         * Topic members.
         * @member {Array.<api.IMember>} members
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.members = $util.emptyArray;

        /**
         * Topic assets.
         * @member {Array.<api.IObjectAsset>} assets
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.assets = $util.emptyArray;

        /**
         * Topic createDate.
         * @member {number|Long} createDate
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.createDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic updateDate.
         * @member {number|Long} updateDate
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.updateDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic minDuration.
         * @member {number|Long} minDuration
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.minDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic maxDuration.
         * @member {number|Long} maxDuration
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.maxDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic minTotalDuration.
         * @member {number|Long} minTotalDuration
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.minTotalDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic maxTotalDuration.
         * @member {number|Long} maxTotalDuration
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.maxTotalDuration = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic tags.
         * @member {Array.<string>} tags
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.tags = $util.emptyArray;

        /**
         * Topic ratingsCount.
         * @member {Array.<number>} ratingsCount
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.ratingsCount = $util.emptyArray;

        /**
         * Topic ratingsAverage.
         * @member {number} ratingsAverage
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.ratingsAverage = 0;

        /**
         * Topic reviews.
         * @member {Array.<api.ITopicReview>} reviews
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.reviews = $util.emptyArray;

        /**
         * Topic searchable.
         * @member {boolean} searchable
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.searchable = false;

        /**
         * Topic allowSearchable.
         * @member {boolean} allowSearchable
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.allowSearchable = false;

        /**
         * Topic pendingReview.
         * @member {boolean} pendingReview
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.pendingReview = false;

        /**
         * Topic reviewDate.
         * @member {number|Long} reviewDate
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.reviewDate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Topic reviewMessage.
         * @member {string} reviewMessage
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.reviewMessage = "";

        /**
         * Topic tinyCode.
         * @member {string} tinyCode
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.tinyCode = "";

        /**
         * Topic vanityName.
         * @member {string} vanityName
         * @memberof api.Topic
         * @instance
         */
        Topic.prototype.vanityName = "";

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * Topic topicType.
         * @member {"singleSession"|"multiSession"|"ongoingSession"|undefined} topicType
         * @memberof api.Topic
         * @instance
         */
        Object.defineProperty(Topic.prototype, "topicType", {
            get: $util.oneOfGetter($oneOfFields = ["singleSession", "multiSession", "ongoingSession"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new Topic instance using the specified properties.
         * @function create
         * @memberof api.Topic
         * @static
         * @param {api.ITopic=} [properties] Properties to set
         * @returns {api.Topic} Topic instance
         */
        Topic.create = function create(properties) {
            return new Topic(properties);
        };

        /**
         * Encodes the specified Topic message. Does not implicitly {@link api.Topic.verify|verify} messages.
         * @function encode
         * @memberof api.Topic
         * @static
         * @param {api.ITopic} message Topic message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Topic.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topicId != null && Object.hasOwnProperty.call(message, "topicId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.topicId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            if (message.minAge != null && Object.hasOwnProperty.call(message, "minAge"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minAge);
            if (message.maxAge != null && Object.hasOwnProperty.call(message, "maxAge"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxAge);
            if (message.maxParticipants != null && Object.hasOwnProperty.call(message, "maxParticipants"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxParticipants);
            if (message.rooms != null && message.rooms.length)
                for (let i = 0; i < message.rooms.length; ++i)
                    $root.api.Room.encode(message.rooms[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.members != null && message.members.length)
                for (let i = 0; i < message.members.length; ++i)
                    $root.api.Member.encode(message.members[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createDate != null && Object.hasOwnProperty.call(message, "createDate"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.createDate);
            if (message.updateDate != null && Object.hasOwnProperty.call(message, "updateDate"))
                writer.uint32(/* id 10, wireType 0 =*/80).int64(message.updateDate);
            if (message.minDuration != null && Object.hasOwnProperty.call(message, "minDuration"))
                writer.uint32(/* id 11, wireType 0 =*/88).int64(message.minDuration);
            if (message.maxDuration != null && Object.hasOwnProperty.call(message, "maxDuration"))
                writer.uint32(/* id 12, wireType 0 =*/96).int64(message.maxDuration);
            if (message.minTotalDuration != null && Object.hasOwnProperty.call(message, "minTotalDuration"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.minTotalDuration);
            if (message.maxTotalDuration != null && Object.hasOwnProperty.call(message, "maxTotalDuration"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.maxTotalDuration);
            if (message.tags != null && message.tags.length)
                for (let i = 0; i < message.tags.length; ++i)
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.tags[i]);
            if (message.singleSession != null && Object.hasOwnProperty.call(message, "singleSession"))
                $root.api.SingleSession.encode(message.singleSession, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.multiSession != null && Object.hasOwnProperty.call(message, "multiSession"))
                $root.api.MultiSession.encode(message.multiSession, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.ongoingSession != null && Object.hasOwnProperty.call(message, "ongoingSession"))
                $root.api.OngoingSession.encode(message.ongoingSession, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.assets != null && message.assets.length)
                for (let i = 0; i < message.assets.length; ++i)
                    $root.api.ObjectAsset.encode(message.assets[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.reviews != null && message.reviews.length)
                for (let i = 0; i < message.reviews.length; ++i)
                    $root.api.TopicReview.encode(message.reviews[i], writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.ratingsCount != null && message.ratingsCount.length) {
                writer.uint32(/* id 22, wireType 2 =*/178).fork();
                for (let i = 0; i < message.ratingsCount.length; ++i)
                    writer.int32(message.ratingsCount[i]);
                writer.ldelim();
            }
            if (message.ratingsAverage != null && Object.hasOwnProperty.call(message, "ratingsAverage"))
                writer.uint32(/* id 28, wireType 1 =*/225).double(message.ratingsAverage);
            if (message.currency != null && Object.hasOwnProperty.call(message, "currency"))
                writer.uint32(/* id 29, wireType 2 =*/234).string(message.currency);
            if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                writer.uint32(/* id 30, wireType 1 =*/241).double(message.cost);
            if (message.costType != null && Object.hasOwnProperty.call(message, "costType"))
                writer.uint32(/* id 31, wireType 0 =*/248).int32(message.costType);
            if (message.searchable != null && Object.hasOwnProperty.call(message, "searchable"))
                writer.uint32(/* id 32, wireType 0 =*/256).bool(message.searchable);
            if (message.allowSearchable != null && Object.hasOwnProperty.call(message, "allowSearchable"))
                writer.uint32(/* id 33, wireType 0 =*/264).bool(message.allowSearchable);
            if (message.pendingReview != null && Object.hasOwnProperty.call(message, "pendingReview"))
                writer.uint32(/* id 34, wireType 0 =*/272).bool(message.pendingReview);
            if (message.reviewDate != null && Object.hasOwnProperty.call(message, "reviewDate"))
                writer.uint32(/* id 35, wireType 0 =*/280).int64(message.reviewDate);
            if (message.reviewMessage != null && Object.hasOwnProperty.call(message, "reviewMessage"))
                writer.uint32(/* id 36, wireType 2 =*/290).string(message.reviewMessage);
            if (message.tinyCode != null && Object.hasOwnProperty.call(message, "tinyCode"))
                writer.uint32(/* id 37, wireType 2 =*/298).string(message.tinyCode);
            if (message.vanityName != null && Object.hasOwnProperty.call(message, "vanityName"))
                writer.uint32(/* id 38, wireType 2 =*/306).string(message.vanityName);
            return writer;
        };

        /**
         * Encodes the specified Topic message, length delimited. Does not implicitly {@link api.Topic.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.Topic
         * @static
         * @param {api.ITopic} message Topic message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Topic.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Topic message from the specified reader or buffer.
         * @function decode
         * @memberof api.Topic
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.Topic} Topic
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Topic.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.Topic();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.topicId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                case 16:
                    message.singleSession = $root.api.SingleSession.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.multiSession = $root.api.MultiSession.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.ongoingSession = $root.api.OngoingSession.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.currency = reader.string();
                    break;
                case 30:
                    message.cost = reader.double();
                    break;
                case 31:
                    message.costType = reader.int32();
                    break;
                case 4:
                    message.minAge = reader.int32();
                    break;
                case 5:
                    message.maxAge = reader.int32();
                    break;
                case 6:
                    message.maxParticipants = reader.int32();
                    break;
                case 7:
                    if (!(message.rooms && message.rooms.length))
                        message.rooms = [];
                    message.rooms.push($root.api.Room.decode(reader, reader.uint32()));
                    break;
                case 8:
                    if (!(message.members && message.members.length))
                        message.members = [];
                    message.members.push($root.api.Member.decode(reader, reader.uint32()));
                    break;
                case 19:
                    if (!(message.assets && message.assets.length))
                        message.assets = [];
                    message.assets.push($root.api.ObjectAsset.decode(reader, reader.uint32()));
                    break;
                case 9:
                    message.createDate = reader.int64();
                    break;
                case 10:
                    message.updateDate = reader.int64();
                    break;
                case 11:
                    message.minDuration = reader.int64();
                    break;
                case 12:
                    message.maxDuration = reader.int64();
                    break;
                case 13:
                    message.minTotalDuration = reader.int64();
                    break;
                case 14:
                    message.maxTotalDuration = reader.int64();
                    break;
                case 15:
                    if (!(message.tags && message.tags.length))
                        message.tags = [];
                    message.tags.push(reader.string());
                    break;
                case 22:
                    if (!(message.ratingsCount && message.ratingsCount.length))
                        message.ratingsCount = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ratingsCount.push(reader.int32());
                    } else
                        message.ratingsCount.push(reader.int32());
                    break;
                case 28:
                    message.ratingsAverage = reader.double();
                    break;
                case 21:
                    if (!(message.reviews && message.reviews.length))
                        message.reviews = [];
                    message.reviews.push($root.api.TopicReview.decode(reader, reader.uint32()));
                    break;
                case 32:
                    message.searchable = reader.bool();
                    break;
                case 33:
                    message.allowSearchable = reader.bool();
                    break;
                case 34:
                    message.pendingReview = reader.bool();
                    break;
                case 35:
                    message.reviewDate = reader.int64();
                    break;
                case 36:
                    message.reviewMessage = reader.string();
                    break;
                case 37:
                    message.tinyCode = reader.string();
                    break;
                case 38:
                    message.vanityName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Topic message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.Topic
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.Topic} Topic
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Topic.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Topic message.
         * @function verify
         * @memberof api.Topic
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Topic.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                if (!$util.isString(message.topicId))
                    return "topicId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.singleSession != null && message.hasOwnProperty("singleSession")) {
                properties.topicType = 1;
                {
                    let error = $root.api.SingleSession.verify(message.singleSession);
                    if (error)
                        return "singleSession." + error;
                }
            }
            if (message.multiSession != null && message.hasOwnProperty("multiSession")) {
                if (properties.topicType === 1)
                    return "topicType: multiple values";
                properties.topicType = 1;
                {
                    let error = $root.api.MultiSession.verify(message.multiSession);
                    if (error)
                        return "multiSession." + error;
                }
            }
            if (message.ongoingSession != null && message.hasOwnProperty("ongoingSession")) {
                if (properties.topicType === 1)
                    return "topicType: multiple values";
                properties.topicType = 1;
                {
                    let error = $root.api.OngoingSession.verify(message.ongoingSession);
                    if (error)
                        return "ongoingSession." + error;
                }
            }
            if (message.currency != null && message.hasOwnProperty("currency"))
                if (!$util.isString(message.currency))
                    return "currency: string expected";
            if (message.cost != null && message.hasOwnProperty("cost"))
                if (typeof message.cost !== "number")
                    return "cost: number expected";
            if (message.costType != null && message.hasOwnProperty("costType"))
                switch (message.costType) {
                default:
                    return "costType: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                    break;
                }
            if (message.minAge != null && message.hasOwnProperty("minAge"))
                if (!$util.isInteger(message.minAge))
                    return "minAge: integer expected";
            if (message.maxAge != null && message.hasOwnProperty("maxAge"))
                if (!$util.isInteger(message.maxAge))
                    return "maxAge: integer expected";
            if (message.maxParticipants != null && message.hasOwnProperty("maxParticipants"))
                if (!$util.isInteger(message.maxParticipants))
                    return "maxParticipants: integer expected";
            if (message.rooms != null && message.hasOwnProperty("rooms")) {
                if (!Array.isArray(message.rooms))
                    return "rooms: array expected";
                for (let i = 0; i < message.rooms.length; ++i) {
                    let error = $root.api.Room.verify(message.rooms[i]);
                    if (error)
                        return "rooms." + error;
                }
            }
            if (message.members != null && message.hasOwnProperty("members")) {
                if (!Array.isArray(message.members))
                    return "members: array expected";
                for (let i = 0; i < message.members.length; ++i) {
                    let error = $root.api.Member.verify(message.members[i]);
                    if (error)
                        return "members." + error;
                }
            }
            if (message.assets != null && message.hasOwnProperty("assets")) {
                if (!Array.isArray(message.assets))
                    return "assets: array expected";
                for (let i = 0; i < message.assets.length; ++i) {
                    let error = $root.api.ObjectAsset.verify(message.assets[i]);
                    if (error)
                        return "assets." + error;
                }
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (!$util.isInteger(message.createDate) && !(message.createDate && $util.isInteger(message.createDate.low) && $util.isInteger(message.createDate.high)))
                    return "createDate: integer|Long expected";
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (!$util.isInteger(message.updateDate) && !(message.updateDate && $util.isInteger(message.updateDate.low) && $util.isInteger(message.updateDate.high)))
                    return "updateDate: integer|Long expected";
            if (message.minDuration != null && message.hasOwnProperty("minDuration"))
                if (!$util.isInteger(message.minDuration) && !(message.minDuration && $util.isInteger(message.minDuration.low) && $util.isInteger(message.minDuration.high)))
                    return "minDuration: integer|Long expected";
            if (message.maxDuration != null && message.hasOwnProperty("maxDuration"))
                if (!$util.isInteger(message.maxDuration) && !(message.maxDuration && $util.isInteger(message.maxDuration.low) && $util.isInteger(message.maxDuration.high)))
                    return "maxDuration: integer|Long expected";
            if (message.minTotalDuration != null && message.hasOwnProperty("minTotalDuration"))
                if (!$util.isInteger(message.minTotalDuration) && !(message.minTotalDuration && $util.isInteger(message.minTotalDuration.low) && $util.isInteger(message.minTotalDuration.high)))
                    return "minTotalDuration: integer|Long expected";
            if (message.maxTotalDuration != null && message.hasOwnProperty("maxTotalDuration"))
                if (!$util.isInteger(message.maxTotalDuration) && !(message.maxTotalDuration && $util.isInteger(message.maxTotalDuration.low) && $util.isInteger(message.maxTotalDuration.high)))
                    return "maxTotalDuration: integer|Long expected";
            if (message.tags != null && message.hasOwnProperty("tags")) {
                if (!Array.isArray(message.tags))
                    return "tags: array expected";
                for (let i = 0; i < message.tags.length; ++i)
                    if (!$util.isString(message.tags[i]))
                        return "tags: string[] expected";
            }
            if (message.ratingsCount != null && message.hasOwnProperty("ratingsCount")) {
                if (!Array.isArray(message.ratingsCount))
                    return "ratingsCount: array expected";
                for (let i = 0; i < message.ratingsCount.length; ++i)
                    if (!$util.isInteger(message.ratingsCount[i]))
                        return "ratingsCount: integer[] expected";
            }
            if (message.ratingsAverage != null && message.hasOwnProperty("ratingsAverage"))
                if (typeof message.ratingsAverage !== "number")
                    return "ratingsAverage: number expected";
            if (message.reviews != null && message.hasOwnProperty("reviews")) {
                if (!Array.isArray(message.reviews))
                    return "reviews: array expected";
                for (let i = 0; i < message.reviews.length; ++i) {
                    let error = $root.api.TopicReview.verify(message.reviews[i]);
                    if (error)
                        return "reviews." + error;
                }
            }
            if (message.searchable != null && message.hasOwnProperty("searchable"))
                if (typeof message.searchable !== "boolean")
                    return "searchable: boolean expected";
            if (message.allowSearchable != null && message.hasOwnProperty("allowSearchable"))
                if (typeof message.allowSearchable !== "boolean")
                    return "allowSearchable: boolean expected";
            if (message.pendingReview != null && message.hasOwnProperty("pendingReview"))
                if (typeof message.pendingReview !== "boolean")
                    return "pendingReview: boolean expected";
            if (message.reviewDate != null && message.hasOwnProperty("reviewDate"))
                if (!$util.isInteger(message.reviewDate) && !(message.reviewDate && $util.isInteger(message.reviewDate.low) && $util.isInteger(message.reviewDate.high)))
                    return "reviewDate: integer|Long expected";
            if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
                if (!$util.isString(message.reviewMessage))
                    return "reviewMessage: string expected";
            if (message.tinyCode != null && message.hasOwnProperty("tinyCode"))
                if (!$util.isString(message.tinyCode))
                    return "tinyCode: string expected";
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                if (!$util.isString(message.vanityName))
                    return "vanityName: string expected";
            return null;
        };

        /**
         * Creates a Topic message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.Topic
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.Topic} Topic
         */
        Topic.fromObject = function fromObject(object) {
            if (object instanceof $root.api.Topic)
                return object;
            let message = new $root.api.Topic();
            if (object.topicId != null)
                message.topicId = String(object.topicId);
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            if (object.singleSession != null) {
                if (typeof object.singleSession !== "object")
                    throw TypeError(".api.Topic.singleSession: object expected");
                message.singleSession = $root.api.SingleSession.fromObject(object.singleSession);
            }
            if (object.multiSession != null) {
                if (typeof object.multiSession !== "object")
                    throw TypeError(".api.Topic.multiSession: object expected");
                message.multiSession = $root.api.MultiSession.fromObject(object.multiSession);
            }
            if (object.ongoingSession != null) {
                if (typeof object.ongoingSession !== "object")
                    throw TypeError(".api.Topic.ongoingSession: object expected");
                message.ongoingSession = $root.api.OngoingSession.fromObject(object.ongoingSession);
            }
            if (object.currency != null)
                message.currency = String(object.currency);
            if (object.cost != null)
                message.cost = Number(object.cost);
            switch (object.costType) {
            case "free":
            case 0:
                message.costType = 0;
                break;
            case "cost_all_upfront":
            case 1:
                message.costType = 1;
                break;
            case "cost_per_time_charged_as_you_go":
            case 2:
                message.costType = 2;
                break;
            case "cost_per_month":
            case 3:
                message.costType = 3;
                break;
            }
            if (object.minAge != null)
                message.minAge = object.minAge | 0;
            if (object.maxAge != null)
                message.maxAge = object.maxAge | 0;
            if (object.maxParticipants != null)
                message.maxParticipants = object.maxParticipants | 0;
            if (object.rooms) {
                if (!Array.isArray(object.rooms))
                    throw TypeError(".api.Topic.rooms: array expected");
                message.rooms = [];
                for (let i = 0; i < object.rooms.length; ++i) {
                    if (typeof object.rooms[i] !== "object")
                        throw TypeError(".api.Topic.rooms: object expected");
                    message.rooms[i] = $root.api.Room.fromObject(object.rooms[i]);
                }
            }
            if (object.members) {
                if (!Array.isArray(object.members))
                    throw TypeError(".api.Topic.members: array expected");
                message.members = [];
                for (let i = 0; i < object.members.length; ++i) {
                    if (typeof object.members[i] !== "object")
                        throw TypeError(".api.Topic.members: object expected");
                    message.members[i] = $root.api.Member.fromObject(object.members[i]);
                }
            }
            if (object.assets) {
                if (!Array.isArray(object.assets))
                    throw TypeError(".api.Topic.assets: array expected");
                message.assets = [];
                for (let i = 0; i < object.assets.length; ++i) {
                    if (typeof object.assets[i] !== "object")
                        throw TypeError(".api.Topic.assets: object expected");
                    message.assets[i] = $root.api.ObjectAsset.fromObject(object.assets[i]);
                }
            }
            if (object.createDate != null)
                if ($util.Long)
                    (message.createDate = $util.Long.fromValue(object.createDate)).unsigned = false;
                else if (typeof object.createDate === "string")
                    message.createDate = parseInt(object.createDate, 10);
                else if (typeof object.createDate === "number")
                    message.createDate = object.createDate;
                else if (typeof object.createDate === "object")
                    message.createDate = new $util.LongBits(object.createDate.low >>> 0, object.createDate.high >>> 0).toNumber();
            if (object.updateDate != null)
                if ($util.Long)
                    (message.updateDate = $util.Long.fromValue(object.updateDate)).unsigned = false;
                else if (typeof object.updateDate === "string")
                    message.updateDate = parseInt(object.updateDate, 10);
                else if (typeof object.updateDate === "number")
                    message.updateDate = object.updateDate;
                else if (typeof object.updateDate === "object")
                    message.updateDate = new $util.LongBits(object.updateDate.low >>> 0, object.updateDate.high >>> 0).toNumber();
            if (object.minDuration != null)
                if ($util.Long)
                    (message.minDuration = $util.Long.fromValue(object.minDuration)).unsigned = false;
                else if (typeof object.minDuration === "string")
                    message.minDuration = parseInt(object.minDuration, 10);
                else if (typeof object.minDuration === "number")
                    message.minDuration = object.minDuration;
                else if (typeof object.minDuration === "object")
                    message.minDuration = new $util.LongBits(object.minDuration.low >>> 0, object.minDuration.high >>> 0).toNumber();
            if (object.maxDuration != null)
                if ($util.Long)
                    (message.maxDuration = $util.Long.fromValue(object.maxDuration)).unsigned = false;
                else if (typeof object.maxDuration === "string")
                    message.maxDuration = parseInt(object.maxDuration, 10);
                else if (typeof object.maxDuration === "number")
                    message.maxDuration = object.maxDuration;
                else if (typeof object.maxDuration === "object")
                    message.maxDuration = new $util.LongBits(object.maxDuration.low >>> 0, object.maxDuration.high >>> 0).toNumber();
            if (object.minTotalDuration != null)
                if ($util.Long)
                    (message.minTotalDuration = $util.Long.fromValue(object.minTotalDuration)).unsigned = false;
                else if (typeof object.minTotalDuration === "string")
                    message.minTotalDuration = parseInt(object.minTotalDuration, 10);
                else if (typeof object.minTotalDuration === "number")
                    message.minTotalDuration = object.minTotalDuration;
                else if (typeof object.minTotalDuration === "object")
                    message.minTotalDuration = new $util.LongBits(object.minTotalDuration.low >>> 0, object.minTotalDuration.high >>> 0).toNumber();
            if (object.maxTotalDuration != null)
                if ($util.Long)
                    (message.maxTotalDuration = $util.Long.fromValue(object.maxTotalDuration)).unsigned = false;
                else if (typeof object.maxTotalDuration === "string")
                    message.maxTotalDuration = parseInt(object.maxTotalDuration, 10);
                else if (typeof object.maxTotalDuration === "number")
                    message.maxTotalDuration = object.maxTotalDuration;
                else if (typeof object.maxTotalDuration === "object")
                    message.maxTotalDuration = new $util.LongBits(object.maxTotalDuration.low >>> 0, object.maxTotalDuration.high >>> 0).toNumber();
            if (object.tags) {
                if (!Array.isArray(object.tags))
                    throw TypeError(".api.Topic.tags: array expected");
                message.tags = [];
                for (let i = 0; i < object.tags.length; ++i)
                    message.tags[i] = String(object.tags[i]);
            }
            if (object.ratingsCount) {
                if (!Array.isArray(object.ratingsCount))
                    throw TypeError(".api.Topic.ratingsCount: array expected");
                message.ratingsCount = [];
                for (let i = 0; i < object.ratingsCount.length; ++i)
                    message.ratingsCount[i] = object.ratingsCount[i] | 0;
            }
            if (object.ratingsAverage != null)
                message.ratingsAverage = Number(object.ratingsAverage);
            if (object.reviews) {
                if (!Array.isArray(object.reviews))
                    throw TypeError(".api.Topic.reviews: array expected");
                message.reviews = [];
                for (let i = 0; i < object.reviews.length; ++i) {
                    if (typeof object.reviews[i] !== "object")
                        throw TypeError(".api.Topic.reviews: object expected");
                    message.reviews[i] = $root.api.TopicReview.fromObject(object.reviews[i]);
                }
            }
            if (object.searchable != null)
                message.searchable = Boolean(object.searchable);
            if (object.allowSearchable != null)
                message.allowSearchable = Boolean(object.allowSearchable);
            if (object.pendingReview != null)
                message.pendingReview = Boolean(object.pendingReview);
            if (object.reviewDate != null)
                if ($util.Long)
                    (message.reviewDate = $util.Long.fromValue(object.reviewDate)).unsigned = false;
                else if (typeof object.reviewDate === "string")
                    message.reviewDate = parseInt(object.reviewDate, 10);
                else if (typeof object.reviewDate === "number")
                    message.reviewDate = object.reviewDate;
                else if (typeof object.reviewDate === "object")
                    message.reviewDate = new $util.LongBits(object.reviewDate.low >>> 0, object.reviewDate.high >>> 0).toNumber();
            if (object.reviewMessage != null)
                message.reviewMessage = String(object.reviewMessage);
            if (object.tinyCode != null)
                message.tinyCode = String(object.tinyCode);
            if (object.vanityName != null)
                message.vanityName = String(object.vanityName);
            return message;
        };

        /**
         * Creates a plain object from a Topic message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.Topic
         * @static
         * @param {api.Topic} message Topic
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Topic.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.rooms = [];
                object.members = [];
                object.tags = [];
                object.assets = [];
                object.reviews = [];
                object.ratingsCount = [];
            }
            if (options.defaults) {
                object.topicId = "";
                object.name = "";
                object.description = "";
                object.minAge = 0;
                object.maxAge = 0;
                object.maxParticipants = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updateDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updateDate = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minDuration = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.maxDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxDuration = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.minTotalDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.minTotalDuration = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.maxTotalDuration = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.maxTotalDuration = options.longs === String ? "0" : 0;
                object.ratingsAverage = 0;
                object.currency = "";
                object.cost = 0;
                object.costType = options.enums === String ? "free" : 0;
                object.searchable = false;
                object.allowSearchable = false;
                object.pendingReview = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.reviewDate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.reviewDate = options.longs === String ? "0" : 0;
                object.reviewMessage = "";
                object.tinyCode = "";
                object.vanityName = "";
            }
            if (message.topicId != null && message.hasOwnProperty("topicId"))
                object.topicId = message.topicId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.minAge != null && message.hasOwnProperty("minAge"))
                object.minAge = message.minAge;
            if (message.maxAge != null && message.hasOwnProperty("maxAge"))
                object.maxAge = message.maxAge;
            if (message.maxParticipants != null && message.hasOwnProperty("maxParticipants"))
                object.maxParticipants = message.maxParticipants;
            if (message.rooms && message.rooms.length) {
                object.rooms = [];
                for (let j = 0; j < message.rooms.length; ++j)
                    object.rooms[j] = $root.api.Room.toObject(message.rooms[j], options);
            }
            if (message.members && message.members.length) {
                object.members = [];
                for (let j = 0; j < message.members.length; ++j)
                    object.members[j] = $root.api.Member.toObject(message.members[j], options);
            }
            if (message.createDate != null && message.hasOwnProperty("createDate"))
                if (typeof message.createDate === "number")
                    object.createDate = options.longs === String ? String(message.createDate) : message.createDate;
                else
                    object.createDate = options.longs === String ? $util.Long.prototype.toString.call(message.createDate) : options.longs === Number ? new $util.LongBits(message.createDate.low >>> 0, message.createDate.high >>> 0).toNumber() : message.createDate;
            if (message.updateDate != null && message.hasOwnProperty("updateDate"))
                if (typeof message.updateDate === "number")
                    object.updateDate = options.longs === String ? String(message.updateDate) : message.updateDate;
                else
                    object.updateDate = options.longs === String ? $util.Long.prototype.toString.call(message.updateDate) : options.longs === Number ? new $util.LongBits(message.updateDate.low >>> 0, message.updateDate.high >>> 0).toNumber() : message.updateDate;
            if (message.minDuration != null && message.hasOwnProperty("minDuration"))
                if (typeof message.minDuration === "number")
                    object.minDuration = options.longs === String ? String(message.minDuration) : message.minDuration;
                else
                    object.minDuration = options.longs === String ? $util.Long.prototype.toString.call(message.minDuration) : options.longs === Number ? new $util.LongBits(message.minDuration.low >>> 0, message.minDuration.high >>> 0).toNumber() : message.minDuration;
            if (message.maxDuration != null && message.hasOwnProperty("maxDuration"))
                if (typeof message.maxDuration === "number")
                    object.maxDuration = options.longs === String ? String(message.maxDuration) : message.maxDuration;
                else
                    object.maxDuration = options.longs === String ? $util.Long.prototype.toString.call(message.maxDuration) : options.longs === Number ? new $util.LongBits(message.maxDuration.low >>> 0, message.maxDuration.high >>> 0).toNumber() : message.maxDuration;
            if (message.minTotalDuration != null && message.hasOwnProperty("minTotalDuration"))
                if (typeof message.minTotalDuration === "number")
                    object.minTotalDuration = options.longs === String ? String(message.minTotalDuration) : message.minTotalDuration;
                else
                    object.minTotalDuration = options.longs === String ? $util.Long.prototype.toString.call(message.minTotalDuration) : options.longs === Number ? new $util.LongBits(message.minTotalDuration.low >>> 0, message.minTotalDuration.high >>> 0).toNumber() : message.minTotalDuration;
            if (message.maxTotalDuration != null && message.hasOwnProperty("maxTotalDuration"))
                if (typeof message.maxTotalDuration === "number")
                    object.maxTotalDuration = options.longs === String ? String(message.maxTotalDuration) : message.maxTotalDuration;
                else
                    object.maxTotalDuration = options.longs === String ? $util.Long.prototype.toString.call(message.maxTotalDuration) : options.longs === Number ? new $util.LongBits(message.maxTotalDuration.low >>> 0, message.maxTotalDuration.high >>> 0).toNumber() : message.maxTotalDuration;
            if (message.tags && message.tags.length) {
                object.tags = [];
                for (let j = 0; j < message.tags.length; ++j)
                    object.tags[j] = message.tags[j];
            }
            if (message.singleSession != null && message.hasOwnProperty("singleSession")) {
                object.singleSession = $root.api.SingleSession.toObject(message.singleSession, options);
                if (options.oneofs)
                    object.topicType = "singleSession";
            }
            if (message.multiSession != null && message.hasOwnProperty("multiSession")) {
                object.multiSession = $root.api.MultiSession.toObject(message.multiSession, options);
                if (options.oneofs)
                    object.topicType = "multiSession";
            }
            if (message.ongoingSession != null && message.hasOwnProperty("ongoingSession")) {
                object.ongoingSession = $root.api.OngoingSession.toObject(message.ongoingSession, options);
                if (options.oneofs)
                    object.topicType = "ongoingSession";
            }
            if (message.assets && message.assets.length) {
                object.assets = [];
                for (let j = 0; j < message.assets.length; ++j)
                    object.assets[j] = $root.api.ObjectAsset.toObject(message.assets[j], options);
            }
            if (message.reviews && message.reviews.length) {
                object.reviews = [];
                for (let j = 0; j < message.reviews.length; ++j)
                    object.reviews[j] = $root.api.TopicReview.toObject(message.reviews[j], options);
            }
            if (message.ratingsCount && message.ratingsCount.length) {
                object.ratingsCount = [];
                for (let j = 0; j < message.ratingsCount.length; ++j)
                    object.ratingsCount[j] = message.ratingsCount[j];
            }
            if (message.ratingsAverage != null && message.hasOwnProperty("ratingsAverage"))
                object.ratingsAverage = options.json && !isFinite(message.ratingsAverage) ? String(message.ratingsAverage) : message.ratingsAverage;
            if (message.currency != null && message.hasOwnProperty("currency"))
                object.currency = message.currency;
            if (message.cost != null && message.hasOwnProperty("cost"))
                object.cost = options.json && !isFinite(message.cost) ? String(message.cost) : message.cost;
            if (message.costType != null && message.hasOwnProperty("costType"))
                object.costType = options.enums === String ? $root.api.CostType[message.costType] : message.costType;
            if (message.searchable != null && message.hasOwnProperty("searchable"))
                object.searchable = message.searchable;
            if (message.allowSearchable != null && message.hasOwnProperty("allowSearchable"))
                object.allowSearchable = message.allowSearchable;
            if (message.pendingReview != null && message.hasOwnProperty("pendingReview"))
                object.pendingReview = message.pendingReview;
            if (message.reviewDate != null && message.hasOwnProperty("reviewDate"))
                if (typeof message.reviewDate === "number")
                    object.reviewDate = options.longs === String ? String(message.reviewDate) : message.reviewDate;
                else
                    object.reviewDate = options.longs === String ? $util.Long.prototype.toString.call(message.reviewDate) : options.longs === Number ? new $util.LongBits(message.reviewDate.low >>> 0, message.reviewDate.high >>> 0).toNumber() : message.reviewDate;
            if (message.reviewMessage != null && message.hasOwnProperty("reviewMessage"))
                object.reviewMessage = message.reviewMessage;
            if (message.tinyCode != null && message.hasOwnProperty("tinyCode"))
                object.tinyCode = message.tinyCode;
            if (message.vanityName != null && message.hasOwnProperty("vanityName"))
                object.vanityName = message.vanityName;
            return object;
        };

        /**
         * Converts this Topic to JSON.
         * @function toJSON
         * @memberof api.Topic
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Topic.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Topic;
    })();

    api.LookupTinyCodeResponse = (function() {

        /**
         * Properties of a LookupTinyCodeResponse.
         * @memberof api
         * @interface ILookupTinyCodeResponse
         * @property {string|null} [objectId] LookupTinyCodeResponse objectId
         * @property {string|null} [name] LookupTinyCodeResponse name
         */

        /**
         * Constructs a new LookupTinyCodeResponse.
         * @memberof api
         * @classdesc Represents a LookupTinyCodeResponse.
         * @implements ILookupTinyCodeResponse
         * @constructor
         * @param {api.ILookupTinyCodeResponse=} [properties] Properties to set
         */
        function LookupTinyCodeResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LookupTinyCodeResponse objectId.
         * @member {string} objectId
         * @memberof api.LookupTinyCodeResponse
         * @instance
         */
        LookupTinyCodeResponse.prototype.objectId = "";

        /**
         * LookupTinyCodeResponse name.
         * @member {string} name
         * @memberof api.LookupTinyCodeResponse
         * @instance
         */
        LookupTinyCodeResponse.prototype.name = "";

        /**
         * Creates a new LookupTinyCodeResponse instance using the specified properties.
         * @function create
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {api.ILookupTinyCodeResponse=} [properties] Properties to set
         * @returns {api.LookupTinyCodeResponse} LookupTinyCodeResponse instance
         */
        LookupTinyCodeResponse.create = function create(properties) {
            return new LookupTinyCodeResponse(properties);
        };

        /**
         * Encodes the specified LookupTinyCodeResponse message. Does not implicitly {@link api.LookupTinyCodeResponse.verify|verify} messages.
         * @function encode
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {api.ILookupTinyCodeResponse} message LookupTinyCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LookupTinyCodeResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.objectId != null && Object.hasOwnProperty.call(message, "objectId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.objectId);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };

        /**
         * Encodes the specified LookupTinyCodeResponse message, length delimited. Does not implicitly {@link api.LookupTinyCodeResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {api.ILookupTinyCodeResponse} message LookupTinyCodeResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LookupTinyCodeResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a LookupTinyCodeResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.LookupTinyCodeResponse} LookupTinyCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LookupTinyCodeResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.LookupTinyCodeResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.objectId = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a LookupTinyCodeResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.LookupTinyCodeResponse} LookupTinyCodeResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LookupTinyCodeResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a LookupTinyCodeResponse message.
         * @function verify
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LookupTinyCodeResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                if (!$util.isString(message.objectId))
                    return "objectId: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };

        /**
         * Creates a LookupTinyCodeResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.LookupTinyCodeResponse} LookupTinyCodeResponse
         */
        LookupTinyCodeResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.LookupTinyCodeResponse)
                return object;
            let message = new $root.api.LookupTinyCodeResponse();
            if (object.objectId != null)
                message.objectId = String(object.objectId);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };

        /**
         * Creates a plain object from a LookupTinyCodeResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.LookupTinyCodeResponse
         * @static
         * @param {api.LookupTinyCodeResponse} message LookupTinyCodeResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LookupTinyCodeResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.objectId = "";
                object.name = "";
            }
            if (message.objectId != null && message.hasOwnProperty("objectId"))
                object.objectId = message.objectId;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };

        /**
         * Converts this LookupTinyCodeResponse to JSON.
         * @function toJSON
         * @memberof api.LookupTinyCodeResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LookupTinyCodeResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LookupTinyCodeResponse;
    })();

    api.FindTopicsResponse = (function() {

        /**
         * Properties of a FindTopicsResponse.
         * @memberof api
         * @interface IFindTopicsResponse
         * @property {Array.<api.ITopic>|null} [topics] FindTopicsResponse topics
         * @property {number|null} [extra] FindTopicsResponse extra
         */

        /**
         * Constructs a new FindTopicsResponse.
         * @memberof api
         * @classdesc Represents a FindTopicsResponse.
         * @implements IFindTopicsResponse
         * @constructor
         * @param {api.IFindTopicsResponse=} [properties] Properties to set
         */
        function FindTopicsResponse(properties) {
            this.topics = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * FindTopicsResponse topics.
         * @member {Array.<api.ITopic>} topics
         * @memberof api.FindTopicsResponse
         * @instance
         */
        FindTopicsResponse.prototype.topics = $util.emptyArray;

        /**
         * FindTopicsResponse extra.
         * @member {number} extra
         * @memberof api.FindTopicsResponse
         * @instance
         */
        FindTopicsResponse.prototype.extra = 0;

        /**
         * Creates a new FindTopicsResponse instance using the specified properties.
         * @function create
         * @memberof api.FindTopicsResponse
         * @static
         * @param {api.IFindTopicsResponse=} [properties] Properties to set
         * @returns {api.FindTopicsResponse} FindTopicsResponse instance
         */
        FindTopicsResponse.create = function create(properties) {
            return new FindTopicsResponse(properties);
        };

        /**
         * Encodes the specified FindTopicsResponse message. Does not implicitly {@link api.FindTopicsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.FindTopicsResponse
         * @static
         * @param {api.IFindTopicsResponse} message FindTopicsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindTopicsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topics != null && message.topics.length)
                for (let i = 0; i < message.topics.length; ++i)
                    $root.api.Topic.encode(message.topics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.extra != null && Object.hasOwnProperty.call(message, "extra"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.extra);
            return writer;
        };

        /**
         * Encodes the specified FindTopicsResponse message, length delimited. Does not implicitly {@link api.FindTopicsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.FindTopicsResponse
         * @static
         * @param {api.IFindTopicsResponse} message FindTopicsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        FindTopicsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a FindTopicsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.FindTopicsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.FindTopicsResponse} FindTopicsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindTopicsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.FindTopicsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.topics && message.topics.length))
                        message.topics = [];
                    message.topics.push($root.api.Topic.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.extra = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a FindTopicsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.FindTopicsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.FindTopicsResponse} FindTopicsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        FindTopicsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a FindTopicsResponse message.
         * @function verify
         * @memberof api.FindTopicsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        FindTopicsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topics != null && message.hasOwnProperty("topics")) {
                if (!Array.isArray(message.topics))
                    return "topics: array expected";
                for (let i = 0; i < message.topics.length; ++i) {
                    let error = $root.api.Topic.verify(message.topics[i]);
                    if (error)
                        return "topics." + error;
                }
            }
            if (message.extra != null && message.hasOwnProperty("extra"))
                if (!$util.isInteger(message.extra))
                    return "extra: integer expected";
            return null;
        };

        /**
         * Creates a FindTopicsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.FindTopicsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.FindTopicsResponse} FindTopicsResponse
         */
        FindTopicsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.FindTopicsResponse)
                return object;
            let message = new $root.api.FindTopicsResponse();
            if (object.topics) {
                if (!Array.isArray(object.topics))
                    throw TypeError(".api.FindTopicsResponse.topics: array expected");
                message.topics = [];
                for (let i = 0; i < object.topics.length; ++i) {
                    if (typeof object.topics[i] !== "object")
                        throw TypeError(".api.FindTopicsResponse.topics: object expected");
                    message.topics[i] = $root.api.Topic.fromObject(object.topics[i]);
                }
            }
            if (object.extra != null)
                message.extra = object.extra | 0;
            return message;
        };

        /**
         * Creates a plain object from a FindTopicsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.FindTopicsResponse
         * @static
         * @param {api.FindTopicsResponse} message FindTopicsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        FindTopicsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.topics = [];
            if (options.defaults)
                object.extra = 0;
            if (message.topics && message.topics.length) {
                object.topics = [];
                for (let j = 0; j < message.topics.length; ++j)
                    object.topics[j] = $root.api.Topic.toObject(message.topics[j], options);
            }
            if (message.extra != null && message.hasOwnProperty("extra"))
                object.extra = message.extra;
            return object;
        };

        /**
         * Converts this FindTopicsResponse to JSON.
         * @function toJSON
         * @memberof api.FindTopicsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        FindTopicsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FindTopicsResponse;
    })();

    api.PendingReviewTopicsResponse = (function() {

        /**
         * Properties of a PendingReviewTopicsResponse.
         * @memberof api
         * @interface IPendingReviewTopicsResponse
         * @property {Array.<api.ITopic>|null} [topics] PendingReviewTopicsResponse topics
         */

        /**
         * Constructs a new PendingReviewTopicsResponse.
         * @memberof api
         * @classdesc Represents a PendingReviewTopicsResponse.
         * @implements IPendingReviewTopicsResponse
         * @constructor
         * @param {api.IPendingReviewTopicsResponse=} [properties] Properties to set
         */
        function PendingReviewTopicsResponse(properties) {
            this.topics = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * PendingReviewTopicsResponse topics.
         * @member {Array.<api.ITopic>} topics
         * @memberof api.PendingReviewTopicsResponse
         * @instance
         */
        PendingReviewTopicsResponse.prototype.topics = $util.emptyArray;

        /**
         * Creates a new PendingReviewTopicsResponse instance using the specified properties.
         * @function create
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {api.IPendingReviewTopicsResponse=} [properties] Properties to set
         * @returns {api.PendingReviewTopicsResponse} PendingReviewTopicsResponse instance
         */
        PendingReviewTopicsResponse.create = function create(properties) {
            return new PendingReviewTopicsResponse(properties);
        };

        /**
         * Encodes the specified PendingReviewTopicsResponse message. Does not implicitly {@link api.PendingReviewTopicsResponse.verify|verify} messages.
         * @function encode
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {api.IPendingReviewTopicsResponse} message PendingReviewTopicsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingReviewTopicsResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.topics != null && message.topics.length)
                for (let i = 0; i < message.topics.length; ++i)
                    $root.api.Topic.encode(message.topics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified PendingReviewTopicsResponse message, length delimited. Does not implicitly {@link api.PendingReviewTopicsResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {api.IPendingReviewTopicsResponse} message PendingReviewTopicsResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PendingReviewTopicsResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PendingReviewTopicsResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.PendingReviewTopicsResponse} PendingReviewTopicsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingReviewTopicsResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.PendingReviewTopicsResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.topics && message.topics.length))
                        message.topics = [];
                    message.topics.push($root.api.Topic.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PendingReviewTopicsResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.PendingReviewTopicsResponse} PendingReviewTopicsResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PendingReviewTopicsResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PendingReviewTopicsResponse message.
         * @function verify
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PendingReviewTopicsResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.topics != null && message.hasOwnProperty("topics")) {
                if (!Array.isArray(message.topics))
                    return "topics: array expected";
                for (let i = 0; i < message.topics.length; ++i) {
                    let error = $root.api.Topic.verify(message.topics[i]);
                    if (error)
                        return "topics." + error;
                }
            }
            return null;
        };

        /**
         * Creates a PendingReviewTopicsResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.PendingReviewTopicsResponse} PendingReviewTopicsResponse
         */
        PendingReviewTopicsResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.PendingReviewTopicsResponse)
                return object;
            let message = new $root.api.PendingReviewTopicsResponse();
            if (object.topics) {
                if (!Array.isArray(object.topics))
                    throw TypeError(".api.PendingReviewTopicsResponse.topics: array expected");
                message.topics = [];
                for (let i = 0; i < object.topics.length; ++i) {
                    if (typeof object.topics[i] !== "object")
                        throw TypeError(".api.PendingReviewTopicsResponse.topics: object expected");
                    message.topics[i] = $root.api.Topic.fromObject(object.topics[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a PendingReviewTopicsResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.PendingReviewTopicsResponse
         * @static
         * @param {api.PendingReviewTopicsResponse} message PendingReviewTopicsResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PendingReviewTopicsResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.topics = [];
            if (message.topics && message.topics.length) {
                object.topics = [];
                for (let j = 0; j < message.topics.length; ++j)
                    object.topics[j] = $root.api.Topic.toObject(message.topics[j], options);
            }
            return object;
        };

        /**
         * Converts this PendingReviewTopicsResponse to JSON.
         * @function toJSON
         * @memberof api.PendingReviewTopicsResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PendingReviewTopicsResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PendingReviewTopicsResponse;
    })();

    api.UpdatePaymentRequest = (function() {

        /**
         * Properties of an UpdatePaymentRequest.
         * @memberof api
         * @interface IUpdatePaymentRequest
         */

        /**
         * Constructs a new UpdatePaymentRequest.
         * @memberof api
         * @classdesc Represents an UpdatePaymentRequest.
         * @implements IUpdatePaymentRequest
         * @constructor
         * @param {api.IUpdatePaymentRequest=} [properties] Properties to set
         */
        function UpdatePaymentRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new UpdatePaymentRequest instance using the specified properties.
         * @function create
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.IUpdatePaymentRequest=} [properties] Properties to set
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest instance
         */
        UpdatePaymentRequest.create = function create(properties) {
            return new UpdatePaymentRequest(properties);
        };

        /**
         * Encodes the specified UpdatePaymentRequest message. Does not implicitly {@link api.UpdatePaymentRequest.verify|verify} messages.
         * @function encode
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.IUpdatePaymentRequest} message UpdatePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified UpdatePaymentRequest message, length delimited. Does not implicitly {@link api.UpdatePaymentRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.IUpdatePaymentRequest} message UpdatePaymentRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UpdatePaymentRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UpdatePaymentRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.UpdatePaymentRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UpdatePaymentRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UpdatePaymentRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UpdatePaymentRequest message.
         * @function verify
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UpdatePaymentRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates an UpdatePaymentRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.UpdatePaymentRequest} UpdatePaymentRequest
         */
        UpdatePaymentRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.UpdatePaymentRequest)
                return object;
            return new $root.api.UpdatePaymentRequest();
        };

        /**
         * Creates a plain object from an UpdatePaymentRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.UpdatePaymentRequest
         * @static
         * @param {api.UpdatePaymentRequest} message UpdatePaymentRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UpdatePaymentRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this UpdatePaymentRequest to JSON.
         * @function toJSON
         * @memberof api.UpdatePaymentRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UpdatePaymentRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UpdatePaymentRequest;
    })();

    api.SetupPaymentResponse = (function() {

        /**
         * Properties of a SetupPaymentResponse.
         * @memberof api
         * @interface ISetupPaymentResponse
         * @property {string|null} [url] SetupPaymentResponse url
         * @property {string|null} [message] SetupPaymentResponse message
         */

        /**
         * Constructs a new SetupPaymentResponse.
         * @memberof api
         * @classdesc Represents a SetupPaymentResponse.
         * @implements ISetupPaymentResponse
         * @constructor
         * @param {api.ISetupPaymentResponse=} [properties] Properties to set
         */
        function SetupPaymentResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SetupPaymentResponse url.
         * @member {string} url
         * @memberof api.SetupPaymentResponse
         * @instance
         */
        SetupPaymentResponse.prototype.url = "";

        /**
         * SetupPaymentResponse message.
         * @member {string} message
         * @memberof api.SetupPaymentResponse
         * @instance
         */
        SetupPaymentResponse.prototype.message = "";

        /**
         * Creates a new SetupPaymentResponse instance using the specified properties.
         * @function create
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {api.ISetupPaymentResponse=} [properties] Properties to set
         * @returns {api.SetupPaymentResponse} SetupPaymentResponse instance
         */
        SetupPaymentResponse.create = function create(properties) {
            return new SetupPaymentResponse(properties);
        };

        /**
         * Encodes the specified SetupPaymentResponse message. Does not implicitly {@link api.SetupPaymentResponse.verify|verify} messages.
         * @function encode
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {api.ISetupPaymentResponse} message SetupPaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupPaymentResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.url != null && Object.hasOwnProperty.call(message, "url"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            return writer;
        };

        /**
         * Encodes the specified SetupPaymentResponse message, length delimited. Does not implicitly {@link api.SetupPaymentResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {api.ISetupPaymentResponse} message SetupPaymentResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SetupPaymentResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SetupPaymentResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.SetupPaymentResponse} SetupPaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupPaymentResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.SetupPaymentResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.url = reader.string();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SetupPaymentResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.SetupPaymentResponse} SetupPaymentResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SetupPaymentResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SetupPaymentResponse message.
         * @function verify
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SetupPaymentResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.url != null && message.hasOwnProperty("url"))
                if (!$util.isString(message.url))
                    return "url: string expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            return null;
        };

        /**
         * Creates a SetupPaymentResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.SetupPaymentResponse} SetupPaymentResponse
         */
        SetupPaymentResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.SetupPaymentResponse)
                return object;
            let message = new $root.api.SetupPaymentResponse();
            if (object.url != null)
                message.url = String(object.url);
            if (object.message != null)
                message.message = String(object.message);
            return message;
        };

        /**
         * Creates a plain object from a SetupPaymentResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.SetupPaymentResponse
         * @static
         * @param {api.SetupPaymentResponse} message SetupPaymentResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SetupPaymentResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.url = "";
                object.message = "";
            }
            if (message.url != null && message.hasOwnProperty("url"))
                object.url = message.url;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            return object;
        };

        /**
         * Converts this SetupPaymentResponse to JSON.
         * @function toJSON
         * @memberof api.SetupPaymentResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SetupPaymentResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SetupPaymentResponse;
    })();

    api.APIResponse = (function() {

        /**
         * Properties of a APIResponse.
         * @memberof api
         * @interface IAPIResponse
         * @property {number|null} [requestId] APIResponse requestId
         * @property {string|null} [error] APIResponse error
         * @property {string|null} [errorCode] APIResponse errorCode
         * @property {api.ILoginResponse|null} [loginResponse] APIResponse loginResponse
         * @property {api.IResetPasswordResponse|null} [resetPasswordResponse] APIResponse resetPasswordResponse
         * @property {api.ISendValidationCodeResponse|null} [sendValidationCodeResponse] APIResponse sendValidationCodeResponse
         * @property {api.IValidateResponse|null} [validateResponse] APIResponse validateResponse
         * @property {api.IAuthenticateResponse|null} [authenticateResponse] APIResponse authenticateResponse
         * @property {api.ICreateTopicResponse|null} [createTopicResponse] APIResponse createTopicResponse
         * @property {api.IUpdateTopicResponse|null} [updateTopicResponse] APIResponse updateTopicResponse
         * @property {api.IDeleteTopicResponse|null} [deleteTopicResponse] APIResponse deleteTopicResponse
         * @property {api.IDownloadCalendarResponse|null} [downloadCalendarResponse] APIResponse downloadCalendarResponse
         * @property {api.IJoinTopicResponse|null} [joinTopicResponse] APIResponse joinTopicResponse
         * @property {api.ILeaveTopicResponse|null} [leaveTopicResponse] APIResponse leaveTopicResponse
         * @property {api.IWaitlistTopicResponse|null} [waitlistTopicResponse] APIResponse waitlistTopicResponse
         * @property {api.ICreateRoomResponse|null} [createRoomResponse] APIResponse createRoomResponse
         * @property {api.IDeleteRoomResponse|null} [deleteRoomResponse] APIResponse deleteRoomResponse
         * @property {api.IUpdateRoomResponse|null} [updateRoomResponse] APIResponse updateRoomResponse
         * @property {api.IAddTopicReviewResponse|null} [addTopicReviewResponse] APIResponse addTopicReviewResponse
         * @property {api.IUpdateTopicReviewResponse|null} [updateTopicReviewResponse] APIResponse updateTopicReviewResponse
         * @property {api.ISetupPaymentResponse|null} [setupPaymentResponse] APIResponse setupPaymentResponse
         * @property {api.IUpdatePaymentResponse|null} [updatePaymentResponse] APIResponse updatePaymentResponse
         * @property {api.IAddMemberResponse|null} [addMemberResponse] APIResponse addMemberResponse
         * @property {api.IUpdateMemberResponse|null} [updateMemberResponse] APIResponse updateMemberResponse
         * @property {api.IDeleteMemberResponse|null} [deleteMemberResponse] APIResponse deleteMemberResponse
         * @property {api.IDeleteMembersResponse|null} [deleteMembersResponse] APIResponse deleteMembersResponse
         * @property {api.IAnswerMemberResponse|null} [answerMemberResponse] APIResponse answerMemberResponse
         * @property {api.IGetScheduleSetResponse|null} [getScheduleSetResponse] APIResponse getScheduleSetResponse
         * @property {api.ICreateScheduleSetResponse|null} [createScheduleSetResponse] APIResponse createScheduleSetResponse
         * @property {api.IUpdateScheduleSetResponse|null} [updateScheduleSetResponse] APIResponse updateScheduleSetResponse
         * @property {api.IDeleteScheduleSetResponse|null} [deleteScheduleSetResponse] APIResponse deleteScheduleSetResponse
         * @property {api.IGetScheduleResponse|null} [getScheduleResponse] APIResponse getScheduleResponse
         * @property {api.IAddScheduleResponse|null} [addScheduleResponse] APIResponse addScheduleResponse
         * @property {api.IUpdateScheduleResponse|null} [updateScheduleResponse] APIResponse updateScheduleResponse
         * @property {api.ITruncateScheduleResponse|null} [truncateScheduleResponse] APIResponse truncateScheduleResponse
         * @property {api.IDeleteScheduleResponse|null} [deleteScheduleResponse] APIResponse deleteScheduleResponse
         * @property {api.IGetTimeResponse|null} [getTimeResponse] APIResponse getTimeResponse
         * @property {api.IUpdateTimeResponse|null} [updateTimeResponse] APIResponse updateTimeResponse
         * @property {api.IDeleteTimeResponse|null} [deleteTimeResponse] APIResponse deleteTimeResponse
         * @property {api.IAddTimeResponse|null} [addTimeResponse] APIResponse addTimeResponse
         * @property {api.ICreateUserResponse|null} [createUserResponse] APIResponse createUserResponse
         * @property {api.IUpdateUserResponse|null} [updateUserResponse] APIResponse updateUserResponse
         * @property {api.IDeleteUserResponse|null} [deleteUserResponse] APIResponse deleteUserResponse
         * @property {api.ICreateGroupResponse|null} [createGroupResponse] APIResponse createGroupResponse
         * @property {api.IUpdateGroupResponse|null} [updateGroupResponse] APIResponse updateGroupResponse
         * @property {api.IDeleteGroupResponse|null} [deleteGroupResponse] APIResponse deleteGroupResponse
         * @property {api.ISendMessageResponse|null} [sendMessageResponse] APIResponse sendMessageResponse
         * @property {api.IGetRoomResponse|null} [getRoomResponse] APIResponse getRoomResponse
         * @property {api.IGetConferenceConnectInfoResponse|null} [getConferenceConnectInfoResponse] APIResponse getConferenceConnectInfoResponse
         * @property {api.IGetTopicResponse|null} [getTopicResponse] APIResponse getTopicResponse
         * @property {api.IGetUserResponse|null} [getUserResponse] APIResponse getUserResponse
         * @property {api.IGetGroupResponse|null} [getGroupResponse] APIResponse getGroupResponse
         * @property {api.IAddAssetResponse|null} [addAssetResponse] APIResponse addAssetResponse
         * @property {api.IDeleteAssetResponse|null} [deleteAssetResponse] APIResponse deleteAssetResponse
         * @property {api.ICreateSyncResponse|null} [createSyncResponse] APIResponse createSyncResponse
         * @property {api.IUpdateSyncResponse|null} [updateSyncResponse] APIResponse updateSyncResponse
         * @property {api.IDeleteSyncResponse|null} [deleteSyncResponse] APIResponse deleteSyncResponse
         * @property {api.ITriggerSyncResponse|null} [triggerSyncResponse] APIResponse triggerSyncResponse
         * @property {api.IGetUserEventsResponse|null} [getUserEventsResponse] APIResponse getUserEventsResponse
         * @property {api.ILookupTinyCodeResponse|null} [lookupTinyCodeResponse] APIResponse lookupTinyCodeResponse
         * @property {api.IFindTopicsResponse|null} [findTopicsResponse] APIResponse findTopicsResponse
         * @property {api.IPendingReviewTopicsResponse|null} [pendingReviewTopicsResponse] APIResponse pendingReviewTopicsResponse
         * @property {api.ITriggerRenderResponse|null} [triggerRenderResponse] APIResponse triggerRenderResponse
         * @property {api.IRenderStatusResponse|null} [renderStatusResponse] APIResponse renderStatusResponse
         * @property {api.IAddIntegrationResponse|null} [addIntegrationResponse] APIResponse addIntegrationResponse
         * @property {api.IDeleteIntegrationResponse|null} [deleteIntegrationResponse] APIResponse deleteIntegrationResponse
         * @property {api.IUpdateIntegrationResponse|null} [updateIntegrationResponse] APIResponse updateIntegrationResponse
         * @property {api.IManageCouponResponse|null} [manageCouponResponse] APIResponse manageCouponResponse
         * @property {api.ICreateCouponResponse|null} [createCouponResponse] APIResponse createCouponResponse
         * @property {api.IUpdateCouponResponse|null} [updateCouponResponse] APIResponse updateCouponResponse
         * @property {api.IDestroyCouponResponse|null} [destroyCouponResponse] APIResponse destroyCouponResponse
         * @property {api.IUpdateServiceSubscriptionResponse|null} [updateServiceSubscriptionResponse] APIResponse updateServiceSubscriptionResponse
         */

        /**
         * Constructs a new APIResponse.
         * @memberof api
         * @classdesc Represents a APIResponse.
         * @implements IAPIResponse
         * @constructor
         * @param {api.IAPIResponse=} [properties] Properties to set
         */
        function APIResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIResponse requestId.
         * @member {number} requestId
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.requestId = 0;

        /**
         * APIResponse error.
         * @member {string} error
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.error = "";

        /**
         * APIResponse errorCode.
         * @member {string} errorCode
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.errorCode = "";

        /**
         * APIResponse loginResponse.
         * @member {api.ILoginResponse|null|undefined} loginResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.loginResponse = null;

        /**
         * APIResponse resetPasswordResponse.
         * @member {api.IResetPasswordResponse|null|undefined} resetPasswordResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.resetPasswordResponse = null;

        /**
         * APIResponse sendValidationCodeResponse.
         * @member {api.ISendValidationCodeResponse|null|undefined} sendValidationCodeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.sendValidationCodeResponse = null;

        /**
         * APIResponse validateResponse.
         * @member {api.IValidateResponse|null|undefined} validateResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.validateResponse = null;

        /**
         * APIResponse authenticateResponse.
         * @member {api.IAuthenticateResponse|null|undefined} authenticateResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.authenticateResponse = null;

        /**
         * APIResponse createTopicResponse.
         * @member {api.ICreateTopicResponse|null|undefined} createTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createTopicResponse = null;

        /**
         * APIResponse updateTopicResponse.
         * @member {api.IUpdateTopicResponse|null|undefined} updateTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateTopicResponse = null;

        /**
         * APIResponse deleteTopicResponse.
         * @member {api.IDeleteTopicResponse|null|undefined} deleteTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteTopicResponse = null;

        /**
         * APIResponse downloadCalendarResponse.
         * @member {api.IDownloadCalendarResponse|null|undefined} downloadCalendarResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.downloadCalendarResponse = null;

        /**
         * APIResponse joinTopicResponse.
         * @member {api.IJoinTopicResponse|null|undefined} joinTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.joinTopicResponse = null;

        /**
         * APIResponse leaveTopicResponse.
         * @member {api.ILeaveTopicResponse|null|undefined} leaveTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.leaveTopicResponse = null;

        /**
         * APIResponse waitlistTopicResponse.
         * @member {api.IWaitlistTopicResponse|null|undefined} waitlistTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.waitlistTopicResponse = null;

        /**
         * APIResponse createRoomResponse.
         * @member {api.ICreateRoomResponse|null|undefined} createRoomResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createRoomResponse = null;

        /**
         * APIResponse deleteRoomResponse.
         * @member {api.IDeleteRoomResponse|null|undefined} deleteRoomResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteRoomResponse = null;

        /**
         * APIResponse updateRoomResponse.
         * @member {api.IUpdateRoomResponse|null|undefined} updateRoomResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateRoomResponse = null;

        /**
         * APIResponse addTopicReviewResponse.
         * @member {api.IAddTopicReviewResponse|null|undefined} addTopicReviewResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addTopicReviewResponse = null;

        /**
         * APIResponse updateTopicReviewResponse.
         * @member {api.IUpdateTopicReviewResponse|null|undefined} updateTopicReviewResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateTopicReviewResponse = null;

        /**
         * APIResponse setupPaymentResponse.
         * @member {api.ISetupPaymentResponse|null|undefined} setupPaymentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.setupPaymentResponse = null;

        /**
         * APIResponse updatePaymentResponse.
         * @member {api.IUpdatePaymentResponse|null|undefined} updatePaymentResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updatePaymentResponse = null;

        /**
         * APIResponse addMemberResponse.
         * @member {api.IAddMemberResponse|null|undefined} addMemberResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addMemberResponse = null;

        /**
         * APIResponse updateMemberResponse.
         * @member {api.IUpdateMemberResponse|null|undefined} updateMemberResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateMemberResponse = null;

        /**
         * APIResponse deleteMemberResponse.
         * @member {api.IDeleteMemberResponse|null|undefined} deleteMemberResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteMemberResponse = null;

        /**
         * APIResponse deleteMembersResponse.
         * @member {api.IDeleteMembersResponse|null|undefined} deleteMembersResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteMembersResponse = null;

        /**
         * APIResponse answerMemberResponse.
         * @member {api.IAnswerMemberResponse|null|undefined} answerMemberResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.answerMemberResponse = null;

        /**
         * APIResponse getScheduleSetResponse.
         * @member {api.IGetScheduleSetResponse|null|undefined} getScheduleSetResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getScheduleSetResponse = null;

        /**
         * APIResponse createScheduleSetResponse.
         * @member {api.ICreateScheduleSetResponse|null|undefined} createScheduleSetResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createScheduleSetResponse = null;

        /**
         * APIResponse updateScheduleSetResponse.
         * @member {api.IUpdateScheduleSetResponse|null|undefined} updateScheduleSetResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateScheduleSetResponse = null;

        /**
         * APIResponse deleteScheduleSetResponse.
         * @member {api.IDeleteScheduleSetResponse|null|undefined} deleteScheduleSetResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteScheduleSetResponse = null;

        /**
         * APIResponse getScheduleResponse.
         * @member {api.IGetScheduleResponse|null|undefined} getScheduleResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getScheduleResponse = null;

        /**
         * APIResponse addScheduleResponse.
         * @member {api.IAddScheduleResponse|null|undefined} addScheduleResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addScheduleResponse = null;

        /**
         * APIResponse updateScheduleResponse.
         * @member {api.IUpdateScheduleResponse|null|undefined} updateScheduleResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateScheduleResponse = null;

        /**
         * APIResponse truncateScheduleResponse.
         * @member {api.ITruncateScheduleResponse|null|undefined} truncateScheduleResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.truncateScheduleResponse = null;

        /**
         * APIResponse deleteScheduleResponse.
         * @member {api.IDeleteScheduleResponse|null|undefined} deleteScheduleResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteScheduleResponse = null;

        /**
         * APIResponse getTimeResponse.
         * @member {api.IGetTimeResponse|null|undefined} getTimeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getTimeResponse = null;

        /**
         * APIResponse updateTimeResponse.
         * @member {api.IUpdateTimeResponse|null|undefined} updateTimeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateTimeResponse = null;

        /**
         * APIResponse deleteTimeResponse.
         * @member {api.IDeleteTimeResponse|null|undefined} deleteTimeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteTimeResponse = null;

        /**
         * APIResponse addTimeResponse.
         * @member {api.IAddTimeResponse|null|undefined} addTimeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addTimeResponse = null;

        /**
         * APIResponse createUserResponse.
         * @member {api.ICreateUserResponse|null|undefined} createUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createUserResponse = null;

        /**
         * APIResponse updateUserResponse.
         * @member {api.IUpdateUserResponse|null|undefined} updateUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateUserResponse = null;

        /**
         * APIResponse deleteUserResponse.
         * @member {api.IDeleteUserResponse|null|undefined} deleteUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteUserResponse = null;

        /**
         * APIResponse createGroupResponse.
         * @member {api.ICreateGroupResponse|null|undefined} createGroupResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createGroupResponse = null;

        /**
         * APIResponse updateGroupResponse.
         * @member {api.IUpdateGroupResponse|null|undefined} updateGroupResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateGroupResponse = null;

        /**
         * APIResponse deleteGroupResponse.
         * @member {api.IDeleteGroupResponse|null|undefined} deleteGroupResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteGroupResponse = null;

        /**
         * APIResponse sendMessageResponse.
         * @member {api.ISendMessageResponse|null|undefined} sendMessageResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.sendMessageResponse = null;

        /**
         * APIResponse getRoomResponse.
         * @member {api.IGetRoomResponse|null|undefined} getRoomResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getRoomResponse = null;

        /**
         * APIResponse getConferenceConnectInfoResponse.
         * @member {api.IGetConferenceConnectInfoResponse|null|undefined} getConferenceConnectInfoResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getConferenceConnectInfoResponse = null;

        /**
         * APIResponse getTopicResponse.
         * @member {api.IGetTopicResponse|null|undefined} getTopicResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getTopicResponse = null;

        /**
         * APIResponse getUserResponse.
         * @member {api.IGetUserResponse|null|undefined} getUserResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getUserResponse = null;

        /**
         * APIResponse getGroupResponse.
         * @member {api.IGetGroupResponse|null|undefined} getGroupResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getGroupResponse = null;

        /**
         * APIResponse addAssetResponse.
         * @member {api.IAddAssetResponse|null|undefined} addAssetResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addAssetResponse = null;

        /**
         * APIResponse deleteAssetResponse.
         * @member {api.IDeleteAssetResponse|null|undefined} deleteAssetResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteAssetResponse = null;

        /**
         * APIResponse createSyncResponse.
         * @member {api.ICreateSyncResponse|null|undefined} createSyncResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createSyncResponse = null;

        /**
         * APIResponse updateSyncResponse.
         * @member {api.IUpdateSyncResponse|null|undefined} updateSyncResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateSyncResponse = null;

        /**
         * APIResponse deleteSyncResponse.
         * @member {api.IDeleteSyncResponse|null|undefined} deleteSyncResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteSyncResponse = null;

        /**
         * APIResponse triggerSyncResponse.
         * @member {api.ITriggerSyncResponse|null|undefined} triggerSyncResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.triggerSyncResponse = null;

        /**
         * APIResponse getUserEventsResponse.
         * @member {api.IGetUserEventsResponse|null|undefined} getUserEventsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.getUserEventsResponse = null;

        /**
         * APIResponse lookupTinyCodeResponse.
         * @member {api.ILookupTinyCodeResponse|null|undefined} lookupTinyCodeResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.lookupTinyCodeResponse = null;

        /**
         * APIResponse findTopicsResponse.
         * @member {api.IFindTopicsResponse|null|undefined} findTopicsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.findTopicsResponse = null;

        /**
         * APIResponse pendingReviewTopicsResponse.
         * @member {api.IPendingReviewTopicsResponse|null|undefined} pendingReviewTopicsResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.pendingReviewTopicsResponse = null;

        /**
         * APIResponse triggerRenderResponse.
         * @member {api.ITriggerRenderResponse|null|undefined} triggerRenderResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.triggerRenderResponse = null;

        /**
         * APIResponse renderStatusResponse.
         * @member {api.IRenderStatusResponse|null|undefined} renderStatusResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.renderStatusResponse = null;

        /**
         * APIResponse addIntegrationResponse.
         * @member {api.IAddIntegrationResponse|null|undefined} addIntegrationResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.addIntegrationResponse = null;

        /**
         * APIResponse deleteIntegrationResponse.
         * @member {api.IDeleteIntegrationResponse|null|undefined} deleteIntegrationResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.deleteIntegrationResponse = null;

        /**
         * APIResponse updateIntegrationResponse.
         * @member {api.IUpdateIntegrationResponse|null|undefined} updateIntegrationResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateIntegrationResponse = null;

        /**
         * APIResponse manageCouponResponse.
         * @member {api.IManageCouponResponse|null|undefined} manageCouponResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.manageCouponResponse = null;

        /**
         * APIResponse createCouponResponse.
         * @member {api.ICreateCouponResponse|null|undefined} createCouponResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.createCouponResponse = null;

        /**
         * APIResponse updateCouponResponse.
         * @member {api.IUpdateCouponResponse|null|undefined} updateCouponResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateCouponResponse = null;

        /**
         * APIResponse destroyCouponResponse.
         * @member {api.IDestroyCouponResponse|null|undefined} destroyCouponResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.destroyCouponResponse = null;

        /**
         * APIResponse updateServiceSubscriptionResponse.
         * @member {api.IUpdateServiceSubscriptionResponse|null|undefined} updateServiceSubscriptionResponse
         * @memberof api.APIResponse
         * @instance
         */
        APIResponse.prototype.updateServiceSubscriptionResponse = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * APIResponse apiResponseInfo.
         * @member {"loginResponse"|"resetPasswordResponse"|"sendValidationCodeResponse"|"validateResponse"|"authenticateResponse"|"createTopicResponse"|"updateTopicResponse"|"deleteTopicResponse"|"downloadCalendarResponse"|"joinTopicResponse"|"leaveTopicResponse"|"waitlistTopicResponse"|"createRoomResponse"|"deleteRoomResponse"|"updateRoomResponse"|"addTopicReviewResponse"|"updateTopicReviewResponse"|"setupPaymentResponse"|"updatePaymentResponse"|"addMemberResponse"|"updateMemberResponse"|"deleteMemberResponse"|"deleteMembersResponse"|"answerMemberResponse"|"getScheduleSetResponse"|"createScheduleSetResponse"|"updateScheduleSetResponse"|"deleteScheduleSetResponse"|"getScheduleResponse"|"addScheduleResponse"|"updateScheduleResponse"|"truncateScheduleResponse"|"deleteScheduleResponse"|"getTimeResponse"|"updateTimeResponse"|"deleteTimeResponse"|"addTimeResponse"|"createUserResponse"|"updateUserResponse"|"deleteUserResponse"|"createGroupResponse"|"updateGroupResponse"|"deleteGroupResponse"|"sendMessageResponse"|"getRoomResponse"|"getConferenceConnectInfoResponse"|"getTopicResponse"|"getUserResponse"|"getGroupResponse"|"addAssetResponse"|"deleteAssetResponse"|"createSyncResponse"|"updateSyncResponse"|"deleteSyncResponse"|"triggerSyncResponse"|"getUserEventsResponse"|"lookupTinyCodeResponse"|"findTopicsResponse"|"pendingReviewTopicsResponse"|"triggerRenderResponse"|"renderStatusResponse"|"addIntegrationResponse"|"deleteIntegrationResponse"|"updateIntegrationResponse"|"manageCouponResponse"|"createCouponResponse"|"updateCouponResponse"|"destroyCouponResponse"|"updateServiceSubscriptionResponse"|undefined} apiResponseInfo
         * @memberof api.APIResponse
         * @instance
         */
        Object.defineProperty(APIResponse.prototype, "apiResponseInfo", {
            get: $util.oneOfGetter($oneOfFields = ["loginResponse", "resetPasswordResponse", "sendValidationCodeResponse", "validateResponse", "authenticateResponse", "createTopicResponse", "updateTopicResponse", "deleteTopicResponse", "downloadCalendarResponse", "joinTopicResponse", "leaveTopicResponse", "waitlistTopicResponse", "createRoomResponse", "deleteRoomResponse", "updateRoomResponse", "addTopicReviewResponse", "updateTopicReviewResponse", "setupPaymentResponse", "updatePaymentResponse", "addMemberResponse", "updateMemberResponse", "deleteMemberResponse", "deleteMembersResponse", "answerMemberResponse", "getScheduleSetResponse", "createScheduleSetResponse", "updateScheduleSetResponse", "deleteScheduleSetResponse", "getScheduleResponse", "addScheduleResponse", "updateScheduleResponse", "truncateScheduleResponse", "deleteScheduleResponse", "getTimeResponse", "updateTimeResponse", "deleteTimeResponse", "addTimeResponse", "createUserResponse", "updateUserResponse", "deleteUserResponse", "createGroupResponse", "updateGroupResponse", "deleteGroupResponse", "sendMessageResponse", "getRoomResponse", "getConferenceConnectInfoResponse", "getTopicResponse", "getUserResponse", "getGroupResponse", "addAssetResponse", "deleteAssetResponse", "createSyncResponse", "updateSyncResponse", "deleteSyncResponse", "triggerSyncResponse", "getUserEventsResponse", "lookupTinyCodeResponse", "findTopicsResponse", "pendingReviewTopicsResponse", "triggerRenderResponse", "renderStatusResponse", "addIntegrationResponse", "deleteIntegrationResponse", "updateIntegrationResponse", "manageCouponResponse", "createCouponResponse", "updateCouponResponse", "destroyCouponResponse", "updateServiceSubscriptionResponse"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APIResponse instance using the specified properties.
         * @function create
         * @memberof api.APIResponse
         * @static
         * @param {api.IAPIResponse=} [properties] Properties to set
         * @returns {api.APIResponse} APIResponse instance
         */
        APIResponse.create = function create(properties) {
            return new APIResponse(properties);
        };

        /**
         * Encodes the specified APIResponse message. Does not implicitly {@link api.APIResponse.verify|verify} messages.
         * @function encode
         * @memberof api.APIResponse
         * @static
         * @param {api.IAPIResponse} message APIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requestId);
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
            if (message.loginResponse != null && Object.hasOwnProperty.call(message, "loginResponse"))
                $root.api.LoginResponse.encode(message.loginResponse, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.authenticateResponse != null && Object.hasOwnProperty.call(message, "authenticateResponse"))
                $root.api.AuthenticateResponse.encode(message.authenticateResponse, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.createTopicResponse != null && Object.hasOwnProperty.call(message, "createTopicResponse"))
                $root.api.CreateTopicResponse.encode(message.createTopicResponse, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.updateTopicResponse != null && Object.hasOwnProperty.call(message, "updateTopicResponse"))
                $root.api.UpdateTopicResponse.encode(message.updateTopicResponse, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.deleteTopicResponse != null && Object.hasOwnProperty.call(message, "deleteTopicResponse"))
                $root.api.DeleteTopicResponse.encode(message.deleteTopicResponse, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.createRoomResponse != null && Object.hasOwnProperty.call(message, "createRoomResponse"))
                $root.api.CreateRoomResponse.encode(message.createRoomResponse, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.deleteRoomResponse != null && Object.hasOwnProperty.call(message, "deleteRoomResponse"))
                $root.api.DeleteRoomResponse.encode(message.deleteRoomResponse, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.updateRoomResponse != null && Object.hasOwnProperty.call(message, "updateRoomResponse"))
                $root.api.UpdateRoomResponse.encode(message.updateRoomResponse, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.addMemberResponse != null && Object.hasOwnProperty.call(message, "addMemberResponse"))
                $root.api.AddMemberResponse.encode(message.addMemberResponse, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.updateMemberResponse != null && Object.hasOwnProperty.call(message, "updateMemberResponse"))
                $root.api.UpdateMemberResponse.encode(message.updateMemberResponse, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.deleteMemberResponse != null && Object.hasOwnProperty.call(message, "deleteMemberResponse"))
                $root.api.DeleteMemberResponse.encode(message.deleteMemberResponse, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.answerMemberResponse != null && Object.hasOwnProperty.call(message, "answerMemberResponse"))
                $root.api.AnswerMemberResponse.encode(message.answerMemberResponse, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.createScheduleSetResponse != null && Object.hasOwnProperty.call(message, "createScheduleSetResponse"))
                $root.api.CreateScheduleSetResponse.encode(message.createScheduleSetResponse, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.updateScheduleSetResponse != null && Object.hasOwnProperty.call(message, "updateScheduleSetResponse"))
                $root.api.UpdateScheduleSetResponse.encode(message.updateScheduleSetResponse, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.deleteScheduleSetResponse != null && Object.hasOwnProperty.call(message, "deleteScheduleSetResponse"))
                $root.api.DeleteScheduleSetResponse.encode(message.deleteScheduleSetResponse, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.createUserResponse != null && Object.hasOwnProperty.call(message, "createUserResponse"))
                $root.api.CreateUserResponse.encode(message.createUserResponse, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.updateUserResponse != null && Object.hasOwnProperty.call(message, "updateUserResponse"))
                $root.api.UpdateUserResponse.encode(message.updateUserResponse, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.deleteUserResponse != null && Object.hasOwnProperty.call(message, "deleteUserResponse"))
                $root.api.DeleteUserResponse.encode(message.deleteUserResponse, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.createGroupResponse != null && Object.hasOwnProperty.call(message, "createGroupResponse"))
                $root.api.CreateGroupResponse.encode(message.createGroupResponse, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.updateGroupResponse != null && Object.hasOwnProperty.call(message, "updateGroupResponse"))
                $root.api.UpdateGroupResponse.encode(message.updateGroupResponse, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.deleteGroupResponse != null && Object.hasOwnProperty.call(message, "deleteGroupResponse"))
                $root.api.DeleteGroupResponse.encode(message.deleteGroupResponse, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.sendMessageResponse != null && Object.hasOwnProperty.call(message, "sendMessageResponse"))
                $root.api.SendMessageResponse.encode(message.sendMessageResponse, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.getRoomResponse != null && Object.hasOwnProperty.call(message, "getRoomResponse"))
                $root.api.GetRoomResponse.encode(message.getRoomResponse, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.getConferenceConnectInfoResponse != null && Object.hasOwnProperty.call(message, "getConferenceConnectInfoResponse"))
                $root.api.GetConferenceConnectInfoResponse.encode(message.getConferenceConnectInfoResponse, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.getTopicResponse != null && Object.hasOwnProperty.call(message, "getTopicResponse"))
                $root.api.GetTopicResponse.encode(message.getTopicResponse, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
            if (message.getUserResponse != null && Object.hasOwnProperty.call(message, "getUserResponse"))
                $root.api.GetUserResponse.encode(message.getUserResponse, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.getGroupResponse != null && Object.hasOwnProperty.call(message, "getGroupResponse"))
                $root.api.GetGroupResponse.encode(message.getGroupResponse, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.getUserEventsResponse != null && Object.hasOwnProperty.call(message, "getUserEventsResponse"))
                $root.api.GetUserEventsResponse.encode(message.getUserEventsResponse, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.findTopicsResponse != null && Object.hasOwnProperty.call(message, "findTopicsResponse"))
                $root.api.FindTopicsResponse.encode(message.findTopicsResponse, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.addTopicReviewResponse != null && Object.hasOwnProperty.call(message, "addTopicReviewResponse"))
                $root.api.AddTopicReviewResponse.encode(message.addTopicReviewResponse, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.joinTopicResponse != null && Object.hasOwnProperty.call(message, "joinTopicResponse"))
                $root.api.JoinTopicResponse.encode(message.joinTopicResponse, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.addAssetResponse != null && Object.hasOwnProperty.call(message, "addAssetResponse"))
                $root.api.AddAssetResponse.encode(message.addAssetResponse, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.deleteAssetResponse != null && Object.hasOwnProperty.call(message, "deleteAssetResponse"))
                $root.api.DeleteAssetResponse.encode(message.deleteAssetResponse, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.createSyncResponse != null && Object.hasOwnProperty.call(message, "createSyncResponse"))
                $root.api.CreateSyncResponse.encode(message.createSyncResponse, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.updateSyncResponse != null && Object.hasOwnProperty.call(message, "updateSyncResponse"))
                $root.api.UpdateSyncResponse.encode(message.updateSyncResponse, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.deleteSyncResponse != null && Object.hasOwnProperty.call(message, "deleteSyncResponse"))
                $root.api.DeleteSyncResponse.encode(message.deleteSyncResponse, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.triggerSyncResponse != null && Object.hasOwnProperty.call(message, "triggerSyncResponse"))
                $root.api.TriggerSyncResponse.encode(message.triggerSyncResponse, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.waitlistTopicResponse != null && Object.hasOwnProperty.call(message, "waitlistTopicResponse"))
                $root.api.WaitlistTopicResponse.encode(message.waitlistTopicResponse, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.updateTopicReviewResponse != null && Object.hasOwnProperty.call(message, "updateTopicReviewResponse"))
                $root.api.UpdateTopicReviewResponse.encode(message.updateTopicReviewResponse, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.addScheduleResponse != null && Object.hasOwnProperty.call(message, "addScheduleResponse"))
                $root.api.AddScheduleResponse.encode(message.addScheduleResponse, writer.uint32(/* id 47, wireType 2 =*/378).fork()).ldelim();
            if (message.updateScheduleResponse != null && Object.hasOwnProperty.call(message, "updateScheduleResponse"))
                $root.api.UpdateScheduleResponse.encode(message.updateScheduleResponse, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
            if (message.deleteScheduleResponse != null && Object.hasOwnProperty.call(message, "deleteScheduleResponse"))
                $root.api.DeleteScheduleResponse.encode(message.deleteScheduleResponse, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
            if (message.getScheduleSetResponse != null && Object.hasOwnProperty.call(message, "getScheduleSetResponse"))
                $root.api.GetScheduleSetResponse.encode(message.getScheduleSetResponse, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.getScheduleResponse != null && Object.hasOwnProperty.call(message, "getScheduleResponse"))
                $root.api.GetScheduleResponse.encode(message.getScheduleResponse, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.updateTimeResponse != null && Object.hasOwnProperty.call(message, "updateTimeResponse"))
                $root.api.UpdateTimeResponse.encode(message.updateTimeResponse, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            if (message.getTimeResponse != null && Object.hasOwnProperty.call(message, "getTimeResponse"))
                $root.api.GetTimeResponse.encode(message.getTimeResponse, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
            if (message.validateResponse != null && Object.hasOwnProperty.call(message, "validateResponse"))
                $root.api.ValidateResponse.encode(message.validateResponse, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
            if (message.pendingReviewTopicsResponse != null && Object.hasOwnProperty.call(message, "pendingReviewTopicsResponse"))
                $root.api.PendingReviewTopicsResponse.encode(message.pendingReviewTopicsResponse, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
            if (message.deleteMembersResponse != null && Object.hasOwnProperty.call(message, "deleteMembersResponse"))
                $root.api.DeleteMembersResponse.encode(message.deleteMembersResponse, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
            if (message.lookupTinyCodeResponse != null && Object.hasOwnProperty.call(message, "lookupTinyCodeResponse"))
                $root.api.LookupTinyCodeResponse.encode(message.lookupTinyCodeResponse, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
            if (message.setupPaymentResponse != null && Object.hasOwnProperty.call(message, "setupPaymentResponse"))
                $root.api.SetupPaymentResponse.encode(message.setupPaymentResponse, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
            if (message.deleteTimeResponse != null && Object.hasOwnProperty.call(message, "deleteTimeResponse"))
                $root.api.DeleteTimeResponse.encode(message.deleteTimeResponse, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
            if (message.truncateScheduleResponse != null && Object.hasOwnProperty.call(message, "truncateScheduleResponse"))
                $root.api.TruncateScheduleResponse.encode(message.truncateScheduleResponse, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
            if (message.resetPasswordResponse != null && Object.hasOwnProperty.call(message, "resetPasswordResponse"))
                $root.api.ResetPasswordResponse.encode(message.resetPasswordResponse, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
            if (message.addTimeResponse != null && Object.hasOwnProperty.call(message, "addTimeResponse"))
                $root.api.AddTimeResponse.encode(message.addTimeResponse, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
            if (message.sendValidationCodeResponse != null && Object.hasOwnProperty.call(message, "sendValidationCodeResponse"))
                $root.api.SendValidationCodeResponse.encode(message.sendValidationCodeResponse, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
            if (message.leaveTopicResponse != null && Object.hasOwnProperty.call(message, "leaveTopicResponse"))
                $root.api.LeaveTopicResponse.encode(message.leaveTopicResponse, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
            if (message.updatePaymentResponse != null && Object.hasOwnProperty.call(message, "updatePaymentResponse"))
                $root.api.UpdatePaymentResponse.encode(message.updatePaymentResponse, writer.uint32(/* id 65, wireType 2 =*/522).fork()).ldelim();
            if (message.downloadCalendarResponse != null && Object.hasOwnProperty.call(message, "downloadCalendarResponse"))
                $root.api.DownloadCalendarResponse.encode(message.downloadCalendarResponse, writer.uint32(/* id 66, wireType 2 =*/530).fork()).ldelim();
            if (message.triggerRenderResponse != null && Object.hasOwnProperty.call(message, "triggerRenderResponse"))
                $root.api.TriggerRenderResponse.encode(message.triggerRenderResponse, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
            if (message.renderStatusResponse != null && Object.hasOwnProperty.call(message, "renderStatusResponse"))
                $root.api.RenderStatusResponse.encode(message.renderStatusResponse, writer.uint32(/* id 68, wireType 2 =*/546).fork()).ldelim();
            if (message.addIntegrationResponse != null && Object.hasOwnProperty.call(message, "addIntegrationResponse"))
                $root.api.AddIntegrationResponse.encode(message.addIntegrationResponse, writer.uint32(/* id 69, wireType 2 =*/554).fork()).ldelim();
            if (message.deleteIntegrationResponse != null && Object.hasOwnProperty.call(message, "deleteIntegrationResponse"))
                $root.api.DeleteIntegrationResponse.encode(message.deleteIntegrationResponse, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
            if (message.updateIntegrationResponse != null && Object.hasOwnProperty.call(message, "updateIntegrationResponse"))
                $root.api.UpdateIntegrationResponse.encode(message.updateIntegrationResponse, writer.uint32(/* id 72, wireType 2 =*/578).fork()).ldelim();
            if (message.manageCouponResponse != null && Object.hasOwnProperty.call(message, "manageCouponResponse"))
                $root.api.ManageCouponResponse.encode(message.manageCouponResponse, writer.uint32(/* id 73, wireType 2 =*/586).fork()).ldelim();
            if (message.createCouponResponse != null && Object.hasOwnProperty.call(message, "createCouponResponse"))
                $root.api.CreateCouponResponse.encode(message.createCouponResponse, writer.uint32(/* id 74, wireType 2 =*/594).fork()).ldelim();
            if (message.updateCouponResponse != null && Object.hasOwnProperty.call(message, "updateCouponResponse"))
                $root.api.UpdateCouponResponse.encode(message.updateCouponResponse, writer.uint32(/* id 75, wireType 2 =*/602).fork()).ldelim();
            if (message.destroyCouponResponse != null && Object.hasOwnProperty.call(message, "destroyCouponResponse"))
                $root.api.DestroyCouponResponse.encode(message.destroyCouponResponse, writer.uint32(/* id 76, wireType 2 =*/610).fork()).ldelim();
            if (message.updateServiceSubscriptionResponse != null && Object.hasOwnProperty.call(message, "updateServiceSubscriptionResponse"))
                $root.api.UpdateServiceSubscriptionResponse.encode(message.updateServiceSubscriptionResponse, writer.uint32(/* id 77, wireType 2 =*/618).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified APIResponse message, length delimited. Does not implicitly {@link api.APIResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIResponse
         * @static
         * @param {api.IAPIResponse} message APIResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIResponse message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIResponse} APIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = reader.int32();
                    break;
                case 2:
                    message.error = reader.string();
                    break;
                case 3:
                    message.errorCode = reader.string();
                    break;
                case 4:
                    message.loginResponse = $root.api.LoginResponse.decode(reader, reader.uint32());
                    break;
                case 61:
                    message.resetPasswordResponse = $root.api.ResetPasswordResponse.decode(reader, reader.uint32());
                    break;
                case 63:
                    message.sendValidationCodeResponse = $root.api.SendValidationCodeResponse.decode(reader, reader.uint32());
                    break;
                case 54:
                    message.validateResponse = $root.api.ValidateResponse.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.authenticateResponse = $root.api.AuthenticateResponse.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.createTopicResponse = $root.api.CreateTopicResponse.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.updateTopicResponse = $root.api.UpdateTopicResponse.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.deleteTopicResponse = $root.api.DeleteTopicResponse.decode(reader, reader.uint32());
                    break;
                case 66:
                    message.downloadCalendarResponse = $root.api.DownloadCalendarResponse.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.joinTopicResponse = $root.api.JoinTopicResponse.decode(reader, reader.uint32());
                    break;
                case 64:
                    message.leaveTopicResponse = $root.api.LeaveTopicResponse.decode(reader, reader.uint32());
                    break;
                case 45:
                    message.waitlistTopicResponse = $root.api.WaitlistTopicResponse.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.createRoomResponse = $root.api.CreateRoomResponse.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.deleteRoomResponse = $root.api.DeleteRoomResponse.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.updateRoomResponse = $root.api.UpdateRoomResponse.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.addTopicReviewResponse = $root.api.AddTopicReviewResponse.decode(reader, reader.uint32());
                    break;
                case 46:
                    message.updateTopicReviewResponse = $root.api.UpdateTopicReviewResponse.decode(reader, reader.uint32());
                    break;
                case 58:
                    message.setupPaymentResponse = $root.api.SetupPaymentResponse.decode(reader, reader.uint32());
                    break;
                case 65:
                    message.updatePaymentResponse = $root.api.UpdatePaymentResponse.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.addMemberResponse = $root.api.AddMemberResponse.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.updateMemberResponse = $root.api.UpdateMemberResponse.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.deleteMemberResponse = $root.api.DeleteMemberResponse.decode(reader, reader.uint32());
                    break;
                case 56:
                    message.deleteMembersResponse = $root.api.DeleteMembersResponse.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.answerMemberResponse = $root.api.AnswerMemberResponse.decode(reader, reader.uint32());
                    break;
                case 50:
                    message.getScheduleSetResponse = $root.api.GetScheduleSetResponse.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.createScheduleSetResponse = $root.api.CreateScheduleSetResponse.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.updateScheduleSetResponse = $root.api.UpdateScheduleSetResponse.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.deleteScheduleSetResponse = $root.api.DeleteScheduleSetResponse.decode(reader, reader.uint32());
                    break;
                case 51:
                    message.getScheduleResponse = $root.api.GetScheduleResponse.decode(reader, reader.uint32());
                    break;
                case 47:
                    message.addScheduleResponse = $root.api.AddScheduleResponse.decode(reader, reader.uint32());
                    break;
                case 48:
                    message.updateScheduleResponse = $root.api.UpdateScheduleResponse.decode(reader, reader.uint32());
                    break;
                case 60:
                    message.truncateScheduleResponse = $root.api.TruncateScheduleResponse.decode(reader, reader.uint32());
                    break;
                case 49:
                    message.deleteScheduleResponse = $root.api.DeleteScheduleResponse.decode(reader, reader.uint32());
                    break;
                case 53:
                    message.getTimeResponse = $root.api.GetTimeResponse.decode(reader, reader.uint32());
                    break;
                case 52:
                    message.updateTimeResponse = $root.api.UpdateTimeResponse.decode(reader, reader.uint32());
                    break;
                case 59:
                    message.deleteTimeResponse = $root.api.DeleteTimeResponse.decode(reader, reader.uint32());
                    break;
                case 62:
                    message.addTimeResponse = $root.api.AddTimeResponse.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.createUserResponse = $root.api.CreateUserResponse.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.updateUserResponse = $root.api.UpdateUserResponse.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.deleteUserResponse = $root.api.DeleteUserResponse.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.createGroupResponse = $root.api.CreateGroupResponse.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.updateGroupResponse = $root.api.UpdateGroupResponse.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.deleteGroupResponse = $root.api.DeleteGroupResponse.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.sendMessageResponse = $root.api.SendMessageResponse.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.getRoomResponse = $root.api.GetRoomResponse.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.getConferenceConnectInfoResponse = $root.api.GetConferenceConnectInfoResponse.decode(reader, reader.uint32());
                    break;
                case 31:
                    message.getTopicResponse = $root.api.GetTopicResponse.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.getUserResponse = $root.api.GetUserResponse.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.getGroupResponse = $root.api.GetGroupResponse.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.addAssetResponse = $root.api.AddAssetResponse.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.deleteAssetResponse = $root.api.DeleteAssetResponse.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.createSyncResponse = $root.api.CreateSyncResponse.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.updateSyncResponse = $root.api.UpdateSyncResponse.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.deleteSyncResponse = $root.api.DeleteSyncResponse.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.triggerSyncResponse = $root.api.TriggerSyncResponse.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.getUserEventsResponse = $root.api.GetUserEventsResponse.decode(reader, reader.uint32());
                    break;
                case 57:
                    message.lookupTinyCodeResponse = $root.api.LookupTinyCodeResponse.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.findTopicsResponse = $root.api.FindTopicsResponse.decode(reader, reader.uint32());
                    break;
                case 55:
                    message.pendingReviewTopicsResponse = $root.api.PendingReviewTopicsResponse.decode(reader, reader.uint32());
                    break;
                case 67:
                    message.triggerRenderResponse = $root.api.TriggerRenderResponse.decode(reader, reader.uint32());
                    break;
                case 68:
                    message.renderStatusResponse = $root.api.RenderStatusResponse.decode(reader, reader.uint32());
                    break;
                case 69:
                    message.addIntegrationResponse = $root.api.AddIntegrationResponse.decode(reader, reader.uint32());
                    break;
                case 70:
                    message.deleteIntegrationResponse = $root.api.DeleteIntegrationResponse.decode(reader, reader.uint32());
                    break;
                case 72:
                    message.updateIntegrationResponse = $root.api.UpdateIntegrationResponse.decode(reader, reader.uint32());
                    break;
                case 73:
                    message.manageCouponResponse = $root.api.ManageCouponResponse.decode(reader, reader.uint32());
                    break;
                case 74:
                    message.createCouponResponse = $root.api.CreateCouponResponse.decode(reader, reader.uint32());
                    break;
                case 75:
                    message.updateCouponResponse = $root.api.UpdateCouponResponse.decode(reader, reader.uint32());
                    break;
                case 76:
                    message.destroyCouponResponse = $root.api.DestroyCouponResponse.decode(reader, reader.uint32());
                    break;
                case 77:
                    message.updateServiceSubscriptionResponse = $root.api.UpdateServiceSubscriptionResponse.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIResponse} APIResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIResponse message.
         * @function verify
         * @memberof api.APIResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId))
                    return "requestId: integer expected";
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            if (message.loginResponse != null && message.hasOwnProperty("loginResponse")) {
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.LoginResponse.verify(message.loginResponse);
                    if (error)
                        return "loginResponse." + error;
                }
            }
            if (message.resetPasswordResponse != null && message.hasOwnProperty("resetPasswordResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.ResetPasswordResponse.verify(message.resetPasswordResponse);
                    if (error)
                        return "resetPasswordResponse." + error;
                }
            }
            if (message.sendValidationCodeResponse != null && message.hasOwnProperty("sendValidationCodeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.SendValidationCodeResponse.verify(message.sendValidationCodeResponse);
                    if (error)
                        return "sendValidationCodeResponse." + error;
                }
            }
            if (message.validateResponse != null && message.hasOwnProperty("validateResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.ValidateResponse.verify(message.validateResponse);
                    if (error)
                        return "validateResponse." + error;
                }
            }
            if (message.authenticateResponse != null && message.hasOwnProperty("authenticateResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AuthenticateResponse.verify(message.authenticateResponse);
                    if (error)
                        return "authenticateResponse." + error;
                }
            }
            if (message.createTopicResponse != null && message.hasOwnProperty("createTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateTopicResponse.verify(message.createTopicResponse);
                    if (error)
                        return "createTopicResponse." + error;
                }
            }
            if (message.updateTopicResponse != null && message.hasOwnProperty("updateTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateTopicResponse.verify(message.updateTopicResponse);
                    if (error)
                        return "updateTopicResponse." + error;
                }
            }
            if (message.deleteTopicResponse != null && message.hasOwnProperty("deleteTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteTopicResponse.verify(message.deleteTopicResponse);
                    if (error)
                        return "deleteTopicResponse." + error;
                }
            }
            if (message.downloadCalendarResponse != null && message.hasOwnProperty("downloadCalendarResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DownloadCalendarResponse.verify(message.downloadCalendarResponse);
                    if (error)
                        return "downloadCalendarResponse." + error;
                }
            }
            if (message.joinTopicResponse != null && message.hasOwnProperty("joinTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.JoinTopicResponse.verify(message.joinTopicResponse);
                    if (error)
                        return "joinTopicResponse." + error;
                }
            }
            if (message.leaveTopicResponse != null && message.hasOwnProperty("leaveTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.LeaveTopicResponse.verify(message.leaveTopicResponse);
                    if (error)
                        return "leaveTopicResponse." + error;
                }
            }
            if (message.waitlistTopicResponse != null && message.hasOwnProperty("waitlistTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.WaitlistTopicResponse.verify(message.waitlistTopicResponse);
                    if (error)
                        return "waitlistTopicResponse." + error;
                }
            }
            if (message.createRoomResponse != null && message.hasOwnProperty("createRoomResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateRoomResponse.verify(message.createRoomResponse);
                    if (error)
                        return "createRoomResponse." + error;
                }
            }
            if (message.deleteRoomResponse != null && message.hasOwnProperty("deleteRoomResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteRoomResponse.verify(message.deleteRoomResponse);
                    if (error)
                        return "deleteRoomResponse." + error;
                }
            }
            if (message.updateRoomResponse != null && message.hasOwnProperty("updateRoomResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateRoomResponse.verify(message.updateRoomResponse);
                    if (error)
                        return "updateRoomResponse." + error;
                }
            }
            if (message.addTopicReviewResponse != null && message.hasOwnProperty("addTopicReviewResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddTopicReviewResponse.verify(message.addTopicReviewResponse);
                    if (error)
                        return "addTopicReviewResponse." + error;
                }
            }
            if (message.updateTopicReviewResponse != null && message.hasOwnProperty("updateTopicReviewResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateTopicReviewResponse.verify(message.updateTopicReviewResponse);
                    if (error)
                        return "updateTopicReviewResponse." + error;
                }
            }
            if (message.setupPaymentResponse != null && message.hasOwnProperty("setupPaymentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.SetupPaymentResponse.verify(message.setupPaymentResponse);
                    if (error)
                        return "setupPaymentResponse." + error;
                }
            }
            if (message.updatePaymentResponse != null && message.hasOwnProperty("updatePaymentResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdatePaymentResponse.verify(message.updatePaymentResponse);
                    if (error)
                        return "updatePaymentResponse." + error;
                }
            }
            if (message.addMemberResponse != null && message.hasOwnProperty("addMemberResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddMemberResponse.verify(message.addMemberResponse);
                    if (error)
                        return "addMemberResponse." + error;
                }
            }
            if (message.updateMemberResponse != null && message.hasOwnProperty("updateMemberResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateMemberResponse.verify(message.updateMemberResponse);
                    if (error)
                        return "updateMemberResponse." + error;
                }
            }
            if (message.deleteMemberResponse != null && message.hasOwnProperty("deleteMemberResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteMemberResponse.verify(message.deleteMemberResponse);
                    if (error)
                        return "deleteMemberResponse." + error;
                }
            }
            if (message.deleteMembersResponse != null && message.hasOwnProperty("deleteMembersResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteMembersResponse.verify(message.deleteMembersResponse);
                    if (error)
                        return "deleteMembersResponse." + error;
                }
            }
            if (message.answerMemberResponse != null && message.hasOwnProperty("answerMemberResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AnswerMemberResponse.verify(message.answerMemberResponse);
                    if (error)
                        return "answerMemberResponse." + error;
                }
            }
            if (message.getScheduleSetResponse != null && message.hasOwnProperty("getScheduleSetResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetScheduleSetResponse.verify(message.getScheduleSetResponse);
                    if (error)
                        return "getScheduleSetResponse." + error;
                }
            }
            if (message.createScheduleSetResponse != null && message.hasOwnProperty("createScheduleSetResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateScheduleSetResponse.verify(message.createScheduleSetResponse);
                    if (error)
                        return "createScheduleSetResponse." + error;
                }
            }
            if (message.updateScheduleSetResponse != null && message.hasOwnProperty("updateScheduleSetResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateScheduleSetResponse.verify(message.updateScheduleSetResponse);
                    if (error)
                        return "updateScheduleSetResponse." + error;
                }
            }
            if (message.deleteScheduleSetResponse != null && message.hasOwnProperty("deleteScheduleSetResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteScheduleSetResponse.verify(message.deleteScheduleSetResponse);
                    if (error)
                        return "deleteScheduleSetResponse." + error;
                }
            }
            if (message.getScheduleResponse != null && message.hasOwnProperty("getScheduleResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetScheduleResponse.verify(message.getScheduleResponse);
                    if (error)
                        return "getScheduleResponse." + error;
                }
            }
            if (message.addScheduleResponse != null && message.hasOwnProperty("addScheduleResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddScheduleResponse.verify(message.addScheduleResponse);
                    if (error)
                        return "addScheduleResponse." + error;
                }
            }
            if (message.updateScheduleResponse != null && message.hasOwnProperty("updateScheduleResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateScheduleResponse.verify(message.updateScheduleResponse);
                    if (error)
                        return "updateScheduleResponse." + error;
                }
            }
            if (message.truncateScheduleResponse != null && message.hasOwnProperty("truncateScheduleResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.TruncateScheduleResponse.verify(message.truncateScheduleResponse);
                    if (error)
                        return "truncateScheduleResponse." + error;
                }
            }
            if (message.deleteScheduleResponse != null && message.hasOwnProperty("deleteScheduleResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteScheduleResponse.verify(message.deleteScheduleResponse);
                    if (error)
                        return "deleteScheduleResponse." + error;
                }
            }
            if (message.getTimeResponse != null && message.hasOwnProperty("getTimeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetTimeResponse.verify(message.getTimeResponse);
                    if (error)
                        return "getTimeResponse." + error;
                }
            }
            if (message.updateTimeResponse != null && message.hasOwnProperty("updateTimeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateTimeResponse.verify(message.updateTimeResponse);
                    if (error)
                        return "updateTimeResponse." + error;
                }
            }
            if (message.deleteTimeResponse != null && message.hasOwnProperty("deleteTimeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteTimeResponse.verify(message.deleteTimeResponse);
                    if (error)
                        return "deleteTimeResponse." + error;
                }
            }
            if (message.addTimeResponse != null && message.hasOwnProperty("addTimeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddTimeResponse.verify(message.addTimeResponse);
                    if (error)
                        return "addTimeResponse." + error;
                }
            }
            if (message.createUserResponse != null && message.hasOwnProperty("createUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateUserResponse.verify(message.createUserResponse);
                    if (error)
                        return "createUserResponse." + error;
                }
            }
            if (message.updateUserResponse != null && message.hasOwnProperty("updateUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateUserResponse.verify(message.updateUserResponse);
                    if (error)
                        return "updateUserResponse." + error;
                }
            }
            if (message.deleteUserResponse != null && message.hasOwnProperty("deleteUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteUserResponse.verify(message.deleteUserResponse);
                    if (error)
                        return "deleteUserResponse." + error;
                }
            }
            if (message.createGroupResponse != null && message.hasOwnProperty("createGroupResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateGroupResponse.verify(message.createGroupResponse);
                    if (error)
                        return "createGroupResponse." + error;
                }
            }
            if (message.updateGroupResponse != null && message.hasOwnProperty("updateGroupResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateGroupResponse.verify(message.updateGroupResponse);
                    if (error)
                        return "updateGroupResponse." + error;
                }
            }
            if (message.deleteGroupResponse != null && message.hasOwnProperty("deleteGroupResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteGroupResponse.verify(message.deleteGroupResponse);
                    if (error)
                        return "deleteGroupResponse." + error;
                }
            }
            if (message.sendMessageResponse != null && message.hasOwnProperty("sendMessageResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.SendMessageResponse.verify(message.sendMessageResponse);
                    if (error)
                        return "sendMessageResponse." + error;
                }
            }
            if (message.getRoomResponse != null && message.hasOwnProperty("getRoomResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetRoomResponse.verify(message.getRoomResponse);
                    if (error)
                        return "getRoomResponse." + error;
                }
            }
            if (message.getConferenceConnectInfoResponse != null && message.hasOwnProperty("getConferenceConnectInfoResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetConferenceConnectInfoResponse.verify(message.getConferenceConnectInfoResponse);
                    if (error)
                        return "getConferenceConnectInfoResponse." + error;
                }
            }
            if (message.getTopicResponse != null && message.hasOwnProperty("getTopicResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetTopicResponse.verify(message.getTopicResponse);
                    if (error)
                        return "getTopicResponse." + error;
                }
            }
            if (message.getUserResponse != null && message.hasOwnProperty("getUserResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetUserResponse.verify(message.getUserResponse);
                    if (error)
                        return "getUserResponse." + error;
                }
            }
            if (message.getGroupResponse != null && message.hasOwnProperty("getGroupResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetGroupResponse.verify(message.getGroupResponse);
                    if (error)
                        return "getGroupResponse." + error;
                }
            }
            if (message.addAssetResponse != null && message.hasOwnProperty("addAssetResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddAssetResponse.verify(message.addAssetResponse);
                    if (error)
                        return "addAssetResponse." + error;
                }
            }
            if (message.deleteAssetResponse != null && message.hasOwnProperty("deleteAssetResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteAssetResponse.verify(message.deleteAssetResponse);
                    if (error)
                        return "deleteAssetResponse." + error;
                }
            }
            if (message.createSyncResponse != null && message.hasOwnProperty("createSyncResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateSyncResponse.verify(message.createSyncResponse);
                    if (error)
                        return "createSyncResponse." + error;
                }
            }
            if (message.updateSyncResponse != null && message.hasOwnProperty("updateSyncResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateSyncResponse.verify(message.updateSyncResponse);
                    if (error)
                        return "updateSyncResponse." + error;
                }
            }
            if (message.deleteSyncResponse != null && message.hasOwnProperty("deleteSyncResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteSyncResponse.verify(message.deleteSyncResponse);
                    if (error)
                        return "deleteSyncResponse." + error;
                }
            }
            if (message.triggerSyncResponse != null && message.hasOwnProperty("triggerSyncResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.TriggerSyncResponse.verify(message.triggerSyncResponse);
                    if (error)
                        return "triggerSyncResponse." + error;
                }
            }
            if (message.getUserEventsResponse != null && message.hasOwnProperty("getUserEventsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.GetUserEventsResponse.verify(message.getUserEventsResponse);
                    if (error)
                        return "getUserEventsResponse." + error;
                }
            }
            if (message.lookupTinyCodeResponse != null && message.hasOwnProperty("lookupTinyCodeResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.LookupTinyCodeResponse.verify(message.lookupTinyCodeResponse);
                    if (error)
                        return "lookupTinyCodeResponse." + error;
                }
            }
            if (message.findTopicsResponse != null && message.hasOwnProperty("findTopicsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.FindTopicsResponse.verify(message.findTopicsResponse);
                    if (error)
                        return "findTopicsResponse." + error;
                }
            }
            if (message.pendingReviewTopicsResponse != null && message.hasOwnProperty("pendingReviewTopicsResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.PendingReviewTopicsResponse.verify(message.pendingReviewTopicsResponse);
                    if (error)
                        return "pendingReviewTopicsResponse." + error;
                }
            }
            if (message.triggerRenderResponse != null && message.hasOwnProperty("triggerRenderResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.TriggerRenderResponse.verify(message.triggerRenderResponse);
                    if (error)
                        return "triggerRenderResponse." + error;
                }
            }
            if (message.renderStatusResponse != null && message.hasOwnProperty("renderStatusResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.RenderStatusResponse.verify(message.renderStatusResponse);
                    if (error)
                        return "renderStatusResponse." + error;
                }
            }
            if (message.addIntegrationResponse != null && message.hasOwnProperty("addIntegrationResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.AddIntegrationResponse.verify(message.addIntegrationResponse);
                    if (error)
                        return "addIntegrationResponse." + error;
                }
            }
            if (message.deleteIntegrationResponse != null && message.hasOwnProperty("deleteIntegrationResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DeleteIntegrationResponse.verify(message.deleteIntegrationResponse);
                    if (error)
                        return "deleteIntegrationResponse." + error;
                }
            }
            if (message.updateIntegrationResponse != null && message.hasOwnProperty("updateIntegrationResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateIntegrationResponse.verify(message.updateIntegrationResponse);
                    if (error)
                        return "updateIntegrationResponse." + error;
                }
            }
            if (message.manageCouponResponse != null && message.hasOwnProperty("manageCouponResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.ManageCouponResponse.verify(message.manageCouponResponse);
                    if (error)
                        return "manageCouponResponse." + error;
                }
            }
            if (message.createCouponResponse != null && message.hasOwnProperty("createCouponResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.CreateCouponResponse.verify(message.createCouponResponse);
                    if (error)
                        return "createCouponResponse." + error;
                }
            }
            if (message.updateCouponResponse != null && message.hasOwnProperty("updateCouponResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateCouponResponse.verify(message.updateCouponResponse);
                    if (error)
                        return "updateCouponResponse." + error;
                }
            }
            if (message.destroyCouponResponse != null && message.hasOwnProperty("destroyCouponResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.DestroyCouponResponse.verify(message.destroyCouponResponse);
                    if (error)
                        return "destroyCouponResponse." + error;
                }
            }
            if (message.updateServiceSubscriptionResponse != null && message.hasOwnProperty("updateServiceSubscriptionResponse")) {
                if (properties.apiResponseInfo === 1)
                    return "apiResponseInfo: multiple values";
                properties.apiResponseInfo = 1;
                {
                    let error = $root.api.UpdateServiceSubscriptionResponse.verify(message.updateServiceSubscriptionResponse);
                    if (error)
                        return "updateServiceSubscriptionResponse." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIResponse} APIResponse
         */
        APIResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIResponse)
                return object;
            let message = new $root.api.APIResponse();
            if (object.requestId != null)
                message.requestId = object.requestId | 0;
            if (object.error != null)
                message.error = String(object.error);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            if (object.loginResponse != null) {
                if (typeof object.loginResponse !== "object")
                    throw TypeError(".api.APIResponse.loginResponse: object expected");
                message.loginResponse = $root.api.LoginResponse.fromObject(object.loginResponse);
            }
            if (object.resetPasswordResponse != null) {
                if (typeof object.resetPasswordResponse !== "object")
                    throw TypeError(".api.APIResponse.resetPasswordResponse: object expected");
                message.resetPasswordResponse = $root.api.ResetPasswordResponse.fromObject(object.resetPasswordResponse);
            }
            if (object.sendValidationCodeResponse != null) {
                if (typeof object.sendValidationCodeResponse !== "object")
                    throw TypeError(".api.APIResponse.sendValidationCodeResponse: object expected");
                message.sendValidationCodeResponse = $root.api.SendValidationCodeResponse.fromObject(object.sendValidationCodeResponse);
            }
            if (object.validateResponse != null) {
                if (typeof object.validateResponse !== "object")
                    throw TypeError(".api.APIResponse.validateResponse: object expected");
                message.validateResponse = $root.api.ValidateResponse.fromObject(object.validateResponse);
            }
            if (object.authenticateResponse != null) {
                if (typeof object.authenticateResponse !== "object")
                    throw TypeError(".api.APIResponse.authenticateResponse: object expected");
                message.authenticateResponse = $root.api.AuthenticateResponse.fromObject(object.authenticateResponse);
            }
            if (object.createTopicResponse != null) {
                if (typeof object.createTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.createTopicResponse: object expected");
                message.createTopicResponse = $root.api.CreateTopicResponse.fromObject(object.createTopicResponse);
            }
            if (object.updateTopicResponse != null) {
                if (typeof object.updateTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.updateTopicResponse: object expected");
                message.updateTopicResponse = $root.api.UpdateTopicResponse.fromObject(object.updateTopicResponse);
            }
            if (object.deleteTopicResponse != null) {
                if (typeof object.deleteTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteTopicResponse: object expected");
                message.deleteTopicResponse = $root.api.DeleteTopicResponse.fromObject(object.deleteTopicResponse);
            }
            if (object.downloadCalendarResponse != null) {
                if (typeof object.downloadCalendarResponse !== "object")
                    throw TypeError(".api.APIResponse.downloadCalendarResponse: object expected");
                message.downloadCalendarResponse = $root.api.DownloadCalendarResponse.fromObject(object.downloadCalendarResponse);
            }
            if (object.joinTopicResponse != null) {
                if (typeof object.joinTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.joinTopicResponse: object expected");
                message.joinTopicResponse = $root.api.JoinTopicResponse.fromObject(object.joinTopicResponse);
            }
            if (object.leaveTopicResponse != null) {
                if (typeof object.leaveTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.leaveTopicResponse: object expected");
                message.leaveTopicResponse = $root.api.LeaveTopicResponse.fromObject(object.leaveTopicResponse);
            }
            if (object.waitlistTopicResponse != null) {
                if (typeof object.waitlistTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.waitlistTopicResponse: object expected");
                message.waitlistTopicResponse = $root.api.WaitlistTopicResponse.fromObject(object.waitlistTopicResponse);
            }
            if (object.createRoomResponse != null) {
                if (typeof object.createRoomResponse !== "object")
                    throw TypeError(".api.APIResponse.createRoomResponse: object expected");
                message.createRoomResponse = $root.api.CreateRoomResponse.fromObject(object.createRoomResponse);
            }
            if (object.deleteRoomResponse != null) {
                if (typeof object.deleteRoomResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteRoomResponse: object expected");
                message.deleteRoomResponse = $root.api.DeleteRoomResponse.fromObject(object.deleteRoomResponse);
            }
            if (object.updateRoomResponse != null) {
                if (typeof object.updateRoomResponse !== "object")
                    throw TypeError(".api.APIResponse.updateRoomResponse: object expected");
                message.updateRoomResponse = $root.api.UpdateRoomResponse.fromObject(object.updateRoomResponse);
            }
            if (object.addTopicReviewResponse != null) {
                if (typeof object.addTopicReviewResponse !== "object")
                    throw TypeError(".api.APIResponse.addTopicReviewResponse: object expected");
                message.addTopicReviewResponse = $root.api.AddTopicReviewResponse.fromObject(object.addTopicReviewResponse);
            }
            if (object.updateTopicReviewResponse != null) {
                if (typeof object.updateTopicReviewResponse !== "object")
                    throw TypeError(".api.APIResponse.updateTopicReviewResponse: object expected");
                message.updateTopicReviewResponse = $root.api.UpdateTopicReviewResponse.fromObject(object.updateTopicReviewResponse);
            }
            if (object.setupPaymentResponse != null) {
                if (typeof object.setupPaymentResponse !== "object")
                    throw TypeError(".api.APIResponse.setupPaymentResponse: object expected");
                message.setupPaymentResponse = $root.api.SetupPaymentResponse.fromObject(object.setupPaymentResponse);
            }
            if (object.updatePaymentResponse != null) {
                if (typeof object.updatePaymentResponse !== "object")
                    throw TypeError(".api.APIResponse.updatePaymentResponse: object expected");
                message.updatePaymentResponse = $root.api.UpdatePaymentResponse.fromObject(object.updatePaymentResponse);
            }
            if (object.addMemberResponse != null) {
                if (typeof object.addMemberResponse !== "object")
                    throw TypeError(".api.APIResponse.addMemberResponse: object expected");
                message.addMemberResponse = $root.api.AddMemberResponse.fromObject(object.addMemberResponse);
            }
            if (object.updateMemberResponse != null) {
                if (typeof object.updateMemberResponse !== "object")
                    throw TypeError(".api.APIResponse.updateMemberResponse: object expected");
                message.updateMemberResponse = $root.api.UpdateMemberResponse.fromObject(object.updateMemberResponse);
            }
            if (object.deleteMemberResponse != null) {
                if (typeof object.deleteMemberResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteMemberResponse: object expected");
                message.deleteMemberResponse = $root.api.DeleteMemberResponse.fromObject(object.deleteMemberResponse);
            }
            if (object.deleteMembersResponse != null) {
                if (typeof object.deleteMembersResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteMembersResponse: object expected");
                message.deleteMembersResponse = $root.api.DeleteMembersResponse.fromObject(object.deleteMembersResponse);
            }
            if (object.answerMemberResponse != null) {
                if (typeof object.answerMemberResponse !== "object")
                    throw TypeError(".api.APIResponse.answerMemberResponse: object expected");
                message.answerMemberResponse = $root.api.AnswerMemberResponse.fromObject(object.answerMemberResponse);
            }
            if (object.getScheduleSetResponse != null) {
                if (typeof object.getScheduleSetResponse !== "object")
                    throw TypeError(".api.APIResponse.getScheduleSetResponse: object expected");
                message.getScheduleSetResponse = $root.api.GetScheduleSetResponse.fromObject(object.getScheduleSetResponse);
            }
            if (object.createScheduleSetResponse != null) {
                if (typeof object.createScheduleSetResponse !== "object")
                    throw TypeError(".api.APIResponse.createScheduleSetResponse: object expected");
                message.createScheduleSetResponse = $root.api.CreateScheduleSetResponse.fromObject(object.createScheduleSetResponse);
            }
            if (object.updateScheduleSetResponse != null) {
                if (typeof object.updateScheduleSetResponse !== "object")
                    throw TypeError(".api.APIResponse.updateScheduleSetResponse: object expected");
                message.updateScheduleSetResponse = $root.api.UpdateScheduleSetResponse.fromObject(object.updateScheduleSetResponse);
            }
            if (object.deleteScheduleSetResponse != null) {
                if (typeof object.deleteScheduleSetResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteScheduleSetResponse: object expected");
                message.deleteScheduleSetResponse = $root.api.DeleteScheduleSetResponse.fromObject(object.deleteScheduleSetResponse);
            }
            if (object.getScheduleResponse != null) {
                if (typeof object.getScheduleResponse !== "object")
                    throw TypeError(".api.APIResponse.getScheduleResponse: object expected");
                message.getScheduleResponse = $root.api.GetScheduleResponse.fromObject(object.getScheduleResponse);
            }
            if (object.addScheduleResponse != null) {
                if (typeof object.addScheduleResponse !== "object")
                    throw TypeError(".api.APIResponse.addScheduleResponse: object expected");
                message.addScheduleResponse = $root.api.AddScheduleResponse.fromObject(object.addScheduleResponse);
            }
            if (object.updateScheduleResponse != null) {
                if (typeof object.updateScheduleResponse !== "object")
                    throw TypeError(".api.APIResponse.updateScheduleResponse: object expected");
                message.updateScheduleResponse = $root.api.UpdateScheduleResponse.fromObject(object.updateScheduleResponse);
            }
            if (object.truncateScheduleResponse != null) {
                if (typeof object.truncateScheduleResponse !== "object")
                    throw TypeError(".api.APIResponse.truncateScheduleResponse: object expected");
                message.truncateScheduleResponse = $root.api.TruncateScheduleResponse.fromObject(object.truncateScheduleResponse);
            }
            if (object.deleteScheduleResponse != null) {
                if (typeof object.deleteScheduleResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteScheduleResponse: object expected");
                message.deleteScheduleResponse = $root.api.DeleteScheduleResponse.fromObject(object.deleteScheduleResponse);
            }
            if (object.getTimeResponse != null) {
                if (typeof object.getTimeResponse !== "object")
                    throw TypeError(".api.APIResponse.getTimeResponse: object expected");
                message.getTimeResponse = $root.api.GetTimeResponse.fromObject(object.getTimeResponse);
            }
            if (object.updateTimeResponse != null) {
                if (typeof object.updateTimeResponse !== "object")
                    throw TypeError(".api.APIResponse.updateTimeResponse: object expected");
                message.updateTimeResponse = $root.api.UpdateTimeResponse.fromObject(object.updateTimeResponse);
            }
            if (object.deleteTimeResponse != null) {
                if (typeof object.deleteTimeResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteTimeResponse: object expected");
                message.deleteTimeResponse = $root.api.DeleteTimeResponse.fromObject(object.deleteTimeResponse);
            }
            if (object.addTimeResponse != null) {
                if (typeof object.addTimeResponse !== "object")
                    throw TypeError(".api.APIResponse.addTimeResponse: object expected");
                message.addTimeResponse = $root.api.AddTimeResponse.fromObject(object.addTimeResponse);
            }
            if (object.createUserResponse != null) {
                if (typeof object.createUserResponse !== "object")
                    throw TypeError(".api.APIResponse.createUserResponse: object expected");
                message.createUserResponse = $root.api.CreateUserResponse.fromObject(object.createUserResponse);
            }
            if (object.updateUserResponse != null) {
                if (typeof object.updateUserResponse !== "object")
                    throw TypeError(".api.APIResponse.updateUserResponse: object expected");
                message.updateUserResponse = $root.api.UpdateUserResponse.fromObject(object.updateUserResponse);
            }
            if (object.deleteUserResponse != null) {
                if (typeof object.deleteUserResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteUserResponse: object expected");
                message.deleteUserResponse = $root.api.DeleteUserResponse.fromObject(object.deleteUserResponse);
            }
            if (object.createGroupResponse != null) {
                if (typeof object.createGroupResponse !== "object")
                    throw TypeError(".api.APIResponse.createGroupResponse: object expected");
                message.createGroupResponse = $root.api.CreateGroupResponse.fromObject(object.createGroupResponse);
            }
            if (object.updateGroupResponse != null) {
                if (typeof object.updateGroupResponse !== "object")
                    throw TypeError(".api.APIResponse.updateGroupResponse: object expected");
                message.updateGroupResponse = $root.api.UpdateGroupResponse.fromObject(object.updateGroupResponse);
            }
            if (object.deleteGroupResponse != null) {
                if (typeof object.deleteGroupResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteGroupResponse: object expected");
                message.deleteGroupResponse = $root.api.DeleteGroupResponse.fromObject(object.deleteGroupResponse);
            }
            if (object.sendMessageResponse != null) {
                if (typeof object.sendMessageResponse !== "object")
                    throw TypeError(".api.APIResponse.sendMessageResponse: object expected");
                message.sendMessageResponse = $root.api.SendMessageResponse.fromObject(object.sendMessageResponse);
            }
            if (object.getRoomResponse != null) {
                if (typeof object.getRoomResponse !== "object")
                    throw TypeError(".api.APIResponse.getRoomResponse: object expected");
                message.getRoomResponse = $root.api.GetRoomResponse.fromObject(object.getRoomResponse);
            }
            if (object.getConferenceConnectInfoResponse != null) {
                if (typeof object.getConferenceConnectInfoResponse !== "object")
                    throw TypeError(".api.APIResponse.getConferenceConnectInfoResponse: object expected");
                message.getConferenceConnectInfoResponse = $root.api.GetConferenceConnectInfoResponse.fromObject(object.getConferenceConnectInfoResponse);
            }
            if (object.getTopicResponse != null) {
                if (typeof object.getTopicResponse !== "object")
                    throw TypeError(".api.APIResponse.getTopicResponse: object expected");
                message.getTopicResponse = $root.api.GetTopicResponse.fromObject(object.getTopicResponse);
            }
            if (object.getUserResponse != null) {
                if (typeof object.getUserResponse !== "object")
                    throw TypeError(".api.APIResponse.getUserResponse: object expected");
                message.getUserResponse = $root.api.GetUserResponse.fromObject(object.getUserResponse);
            }
            if (object.getGroupResponse != null) {
                if (typeof object.getGroupResponse !== "object")
                    throw TypeError(".api.APIResponse.getGroupResponse: object expected");
                message.getGroupResponse = $root.api.GetGroupResponse.fromObject(object.getGroupResponse);
            }
            if (object.addAssetResponse != null) {
                if (typeof object.addAssetResponse !== "object")
                    throw TypeError(".api.APIResponse.addAssetResponse: object expected");
                message.addAssetResponse = $root.api.AddAssetResponse.fromObject(object.addAssetResponse);
            }
            if (object.deleteAssetResponse != null) {
                if (typeof object.deleteAssetResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteAssetResponse: object expected");
                message.deleteAssetResponse = $root.api.DeleteAssetResponse.fromObject(object.deleteAssetResponse);
            }
            if (object.createSyncResponse != null) {
                if (typeof object.createSyncResponse !== "object")
                    throw TypeError(".api.APIResponse.createSyncResponse: object expected");
                message.createSyncResponse = $root.api.CreateSyncResponse.fromObject(object.createSyncResponse);
            }
            if (object.updateSyncResponse != null) {
                if (typeof object.updateSyncResponse !== "object")
                    throw TypeError(".api.APIResponse.updateSyncResponse: object expected");
                message.updateSyncResponse = $root.api.UpdateSyncResponse.fromObject(object.updateSyncResponse);
            }
            if (object.deleteSyncResponse != null) {
                if (typeof object.deleteSyncResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteSyncResponse: object expected");
                message.deleteSyncResponse = $root.api.DeleteSyncResponse.fromObject(object.deleteSyncResponse);
            }
            if (object.triggerSyncResponse != null) {
                if (typeof object.triggerSyncResponse !== "object")
                    throw TypeError(".api.APIResponse.triggerSyncResponse: object expected");
                message.triggerSyncResponse = $root.api.TriggerSyncResponse.fromObject(object.triggerSyncResponse);
            }
            if (object.getUserEventsResponse != null) {
                if (typeof object.getUserEventsResponse !== "object")
                    throw TypeError(".api.APIResponse.getUserEventsResponse: object expected");
                message.getUserEventsResponse = $root.api.GetUserEventsResponse.fromObject(object.getUserEventsResponse);
            }
            if (object.lookupTinyCodeResponse != null) {
                if (typeof object.lookupTinyCodeResponse !== "object")
                    throw TypeError(".api.APIResponse.lookupTinyCodeResponse: object expected");
                message.lookupTinyCodeResponse = $root.api.LookupTinyCodeResponse.fromObject(object.lookupTinyCodeResponse);
            }
            if (object.findTopicsResponse != null) {
                if (typeof object.findTopicsResponse !== "object")
                    throw TypeError(".api.APIResponse.findTopicsResponse: object expected");
                message.findTopicsResponse = $root.api.FindTopicsResponse.fromObject(object.findTopicsResponse);
            }
            if (object.pendingReviewTopicsResponse != null) {
                if (typeof object.pendingReviewTopicsResponse !== "object")
                    throw TypeError(".api.APIResponse.pendingReviewTopicsResponse: object expected");
                message.pendingReviewTopicsResponse = $root.api.PendingReviewTopicsResponse.fromObject(object.pendingReviewTopicsResponse);
            }
            if (object.triggerRenderResponse != null) {
                if (typeof object.triggerRenderResponse !== "object")
                    throw TypeError(".api.APIResponse.triggerRenderResponse: object expected");
                message.triggerRenderResponse = $root.api.TriggerRenderResponse.fromObject(object.triggerRenderResponse);
            }
            if (object.renderStatusResponse != null) {
                if (typeof object.renderStatusResponse !== "object")
                    throw TypeError(".api.APIResponse.renderStatusResponse: object expected");
                message.renderStatusResponse = $root.api.RenderStatusResponse.fromObject(object.renderStatusResponse);
            }
            if (object.addIntegrationResponse != null) {
                if (typeof object.addIntegrationResponse !== "object")
                    throw TypeError(".api.APIResponse.addIntegrationResponse: object expected");
                message.addIntegrationResponse = $root.api.AddIntegrationResponse.fromObject(object.addIntegrationResponse);
            }
            if (object.deleteIntegrationResponse != null) {
                if (typeof object.deleteIntegrationResponse !== "object")
                    throw TypeError(".api.APIResponse.deleteIntegrationResponse: object expected");
                message.deleteIntegrationResponse = $root.api.DeleteIntegrationResponse.fromObject(object.deleteIntegrationResponse);
            }
            if (object.updateIntegrationResponse != null) {
                if (typeof object.updateIntegrationResponse !== "object")
                    throw TypeError(".api.APIResponse.updateIntegrationResponse: object expected");
                message.updateIntegrationResponse = $root.api.UpdateIntegrationResponse.fromObject(object.updateIntegrationResponse);
            }
            if (object.manageCouponResponse != null) {
                if (typeof object.manageCouponResponse !== "object")
                    throw TypeError(".api.APIResponse.manageCouponResponse: object expected");
                message.manageCouponResponse = $root.api.ManageCouponResponse.fromObject(object.manageCouponResponse);
            }
            if (object.createCouponResponse != null) {
                if (typeof object.createCouponResponse !== "object")
                    throw TypeError(".api.APIResponse.createCouponResponse: object expected");
                message.createCouponResponse = $root.api.CreateCouponResponse.fromObject(object.createCouponResponse);
            }
            if (object.updateCouponResponse != null) {
                if (typeof object.updateCouponResponse !== "object")
                    throw TypeError(".api.APIResponse.updateCouponResponse: object expected");
                message.updateCouponResponse = $root.api.UpdateCouponResponse.fromObject(object.updateCouponResponse);
            }
            if (object.destroyCouponResponse != null) {
                if (typeof object.destroyCouponResponse !== "object")
                    throw TypeError(".api.APIResponse.destroyCouponResponse: object expected");
                message.destroyCouponResponse = $root.api.DestroyCouponResponse.fromObject(object.destroyCouponResponse);
            }
            if (object.updateServiceSubscriptionResponse != null) {
                if (typeof object.updateServiceSubscriptionResponse !== "object")
                    throw TypeError(".api.APIResponse.updateServiceSubscriptionResponse: object expected");
                message.updateServiceSubscriptionResponse = $root.api.UpdateServiceSubscriptionResponse.fromObject(object.updateServiceSubscriptionResponse);
            }
            return message;
        };

        /**
         * Creates a plain object from a APIResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIResponse
         * @static
         * @param {api.APIResponse} message APIResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestId = 0;
                object.error = "";
                object.errorCode = "";
            }
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                object.requestId = message.requestId;
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            if (message.loginResponse != null && message.hasOwnProperty("loginResponse")) {
                object.loginResponse = $root.api.LoginResponse.toObject(message.loginResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "loginResponse";
            }
            if (message.authenticateResponse != null && message.hasOwnProperty("authenticateResponse")) {
                object.authenticateResponse = $root.api.AuthenticateResponse.toObject(message.authenticateResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "authenticateResponse";
            }
            if (message.createTopicResponse != null && message.hasOwnProperty("createTopicResponse")) {
                object.createTopicResponse = $root.api.CreateTopicResponse.toObject(message.createTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createTopicResponse";
            }
            if (message.updateTopicResponse != null && message.hasOwnProperty("updateTopicResponse")) {
                object.updateTopicResponse = $root.api.UpdateTopicResponse.toObject(message.updateTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateTopicResponse";
            }
            if (message.deleteTopicResponse != null && message.hasOwnProperty("deleteTopicResponse")) {
                object.deleteTopicResponse = $root.api.DeleteTopicResponse.toObject(message.deleteTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteTopicResponse";
            }
            if (message.createRoomResponse != null && message.hasOwnProperty("createRoomResponse")) {
                object.createRoomResponse = $root.api.CreateRoomResponse.toObject(message.createRoomResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createRoomResponse";
            }
            if (message.deleteRoomResponse != null && message.hasOwnProperty("deleteRoomResponse")) {
                object.deleteRoomResponse = $root.api.DeleteRoomResponse.toObject(message.deleteRoomResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteRoomResponse";
            }
            if (message.updateRoomResponse != null && message.hasOwnProperty("updateRoomResponse")) {
                object.updateRoomResponse = $root.api.UpdateRoomResponse.toObject(message.updateRoomResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateRoomResponse";
            }
            if (message.addMemberResponse != null && message.hasOwnProperty("addMemberResponse")) {
                object.addMemberResponse = $root.api.AddMemberResponse.toObject(message.addMemberResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addMemberResponse";
            }
            if (message.updateMemberResponse != null && message.hasOwnProperty("updateMemberResponse")) {
                object.updateMemberResponse = $root.api.UpdateMemberResponse.toObject(message.updateMemberResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateMemberResponse";
            }
            if (message.deleteMemberResponse != null && message.hasOwnProperty("deleteMemberResponse")) {
                object.deleteMemberResponse = $root.api.DeleteMemberResponse.toObject(message.deleteMemberResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteMemberResponse";
            }
            if (message.answerMemberResponse != null && message.hasOwnProperty("answerMemberResponse")) {
                object.answerMemberResponse = $root.api.AnswerMemberResponse.toObject(message.answerMemberResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "answerMemberResponse";
            }
            if (message.createScheduleSetResponse != null && message.hasOwnProperty("createScheduleSetResponse")) {
                object.createScheduleSetResponse = $root.api.CreateScheduleSetResponse.toObject(message.createScheduleSetResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createScheduleSetResponse";
            }
            if (message.updateScheduleSetResponse != null && message.hasOwnProperty("updateScheduleSetResponse")) {
                object.updateScheduleSetResponse = $root.api.UpdateScheduleSetResponse.toObject(message.updateScheduleSetResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateScheduleSetResponse";
            }
            if (message.deleteScheduleSetResponse != null && message.hasOwnProperty("deleteScheduleSetResponse")) {
                object.deleteScheduleSetResponse = $root.api.DeleteScheduleSetResponse.toObject(message.deleteScheduleSetResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteScheduleSetResponse";
            }
            if (message.createUserResponse != null && message.hasOwnProperty("createUserResponse")) {
                object.createUserResponse = $root.api.CreateUserResponse.toObject(message.createUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createUserResponse";
            }
            if (message.updateUserResponse != null && message.hasOwnProperty("updateUserResponse")) {
                object.updateUserResponse = $root.api.UpdateUserResponse.toObject(message.updateUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateUserResponse";
            }
            if (message.deleteUserResponse != null && message.hasOwnProperty("deleteUserResponse")) {
                object.deleteUserResponse = $root.api.DeleteUserResponse.toObject(message.deleteUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteUserResponse";
            }
            if (message.createGroupResponse != null && message.hasOwnProperty("createGroupResponse")) {
                object.createGroupResponse = $root.api.CreateGroupResponse.toObject(message.createGroupResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createGroupResponse";
            }
            if (message.updateGroupResponse != null && message.hasOwnProperty("updateGroupResponse")) {
                object.updateGroupResponse = $root.api.UpdateGroupResponse.toObject(message.updateGroupResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateGroupResponse";
            }
            if (message.deleteGroupResponse != null && message.hasOwnProperty("deleteGroupResponse")) {
                object.deleteGroupResponse = $root.api.DeleteGroupResponse.toObject(message.deleteGroupResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteGroupResponse";
            }
            if (message.sendMessageResponse != null && message.hasOwnProperty("sendMessageResponse")) {
                object.sendMessageResponse = $root.api.SendMessageResponse.toObject(message.sendMessageResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "sendMessageResponse";
            }
            if (message.getRoomResponse != null && message.hasOwnProperty("getRoomResponse")) {
                object.getRoomResponse = $root.api.GetRoomResponse.toObject(message.getRoomResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getRoomResponse";
            }
            if (message.getConferenceConnectInfoResponse != null && message.hasOwnProperty("getConferenceConnectInfoResponse")) {
                object.getConferenceConnectInfoResponse = $root.api.GetConferenceConnectInfoResponse.toObject(message.getConferenceConnectInfoResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getConferenceConnectInfoResponse";
            }
            if (message.getTopicResponse != null && message.hasOwnProperty("getTopicResponse")) {
                object.getTopicResponse = $root.api.GetTopicResponse.toObject(message.getTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getTopicResponse";
            }
            if (message.getUserResponse != null && message.hasOwnProperty("getUserResponse")) {
                object.getUserResponse = $root.api.GetUserResponse.toObject(message.getUserResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getUserResponse";
            }
            if (message.getGroupResponse != null && message.hasOwnProperty("getGroupResponse")) {
                object.getGroupResponse = $root.api.GetGroupResponse.toObject(message.getGroupResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getGroupResponse";
            }
            if (message.getUserEventsResponse != null && message.hasOwnProperty("getUserEventsResponse")) {
                object.getUserEventsResponse = $root.api.GetUserEventsResponse.toObject(message.getUserEventsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getUserEventsResponse";
            }
            if (message.findTopicsResponse != null && message.hasOwnProperty("findTopicsResponse")) {
                object.findTopicsResponse = $root.api.FindTopicsResponse.toObject(message.findTopicsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "findTopicsResponse";
            }
            if (message.addTopicReviewResponse != null && message.hasOwnProperty("addTopicReviewResponse")) {
                object.addTopicReviewResponse = $root.api.AddTopicReviewResponse.toObject(message.addTopicReviewResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addTopicReviewResponse";
            }
            if (message.joinTopicResponse != null && message.hasOwnProperty("joinTopicResponse")) {
                object.joinTopicResponse = $root.api.JoinTopicResponse.toObject(message.joinTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "joinTopicResponse";
            }
            if (message.addAssetResponse != null && message.hasOwnProperty("addAssetResponse")) {
                object.addAssetResponse = $root.api.AddAssetResponse.toObject(message.addAssetResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addAssetResponse";
            }
            if (message.deleteAssetResponse != null && message.hasOwnProperty("deleteAssetResponse")) {
                object.deleteAssetResponse = $root.api.DeleteAssetResponse.toObject(message.deleteAssetResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteAssetResponse";
            }
            if (message.createSyncResponse != null && message.hasOwnProperty("createSyncResponse")) {
                object.createSyncResponse = $root.api.CreateSyncResponse.toObject(message.createSyncResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createSyncResponse";
            }
            if (message.updateSyncResponse != null && message.hasOwnProperty("updateSyncResponse")) {
                object.updateSyncResponse = $root.api.UpdateSyncResponse.toObject(message.updateSyncResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateSyncResponse";
            }
            if (message.deleteSyncResponse != null && message.hasOwnProperty("deleteSyncResponse")) {
                object.deleteSyncResponse = $root.api.DeleteSyncResponse.toObject(message.deleteSyncResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteSyncResponse";
            }
            if (message.triggerSyncResponse != null && message.hasOwnProperty("triggerSyncResponse")) {
                object.triggerSyncResponse = $root.api.TriggerSyncResponse.toObject(message.triggerSyncResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "triggerSyncResponse";
            }
            if (message.waitlistTopicResponse != null && message.hasOwnProperty("waitlistTopicResponse")) {
                object.waitlistTopicResponse = $root.api.WaitlistTopicResponse.toObject(message.waitlistTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "waitlistTopicResponse";
            }
            if (message.updateTopicReviewResponse != null && message.hasOwnProperty("updateTopicReviewResponse")) {
                object.updateTopicReviewResponse = $root.api.UpdateTopicReviewResponse.toObject(message.updateTopicReviewResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateTopicReviewResponse";
            }
            if (message.addScheduleResponse != null && message.hasOwnProperty("addScheduleResponse")) {
                object.addScheduleResponse = $root.api.AddScheduleResponse.toObject(message.addScheduleResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addScheduleResponse";
            }
            if (message.updateScheduleResponse != null && message.hasOwnProperty("updateScheduleResponse")) {
                object.updateScheduleResponse = $root.api.UpdateScheduleResponse.toObject(message.updateScheduleResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateScheduleResponse";
            }
            if (message.deleteScheduleResponse != null && message.hasOwnProperty("deleteScheduleResponse")) {
                object.deleteScheduleResponse = $root.api.DeleteScheduleResponse.toObject(message.deleteScheduleResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteScheduleResponse";
            }
            if (message.getScheduleSetResponse != null && message.hasOwnProperty("getScheduleSetResponse")) {
                object.getScheduleSetResponse = $root.api.GetScheduleSetResponse.toObject(message.getScheduleSetResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getScheduleSetResponse";
            }
            if (message.getScheduleResponse != null && message.hasOwnProperty("getScheduleResponse")) {
                object.getScheduleResponse = $root.api.GetScheduleResponse.toObject(message.getScheduleResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getScheduleResponse";
            }
            if (message.updateTimeResponse != null && message.hasOwnProperty("updateTimeResponse")) {
                object.updateTimeResponse = $root.api.UpdateTimeResponse.toObject(message.updateTimeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateTimeResponse";
            }
            if (message.getTimeResponse != null && message.hasOwnProperty("getTimeResponse")) {
                object.getTimeResponse = $root.api.GetTimeResponse.toObject(message.getTimeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "getTimeResponse";
            }
            if (message.validateResponse != null && message.hasOwnProperty("validateResponse")) {
                object.validateResponse = $root.api.ValidateResponse.toObject(message.validateResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "validateResponse";
            }
            if (message.pendingReviewTopicsResponse != null && message.hasOwnProperty("pendingReviewTopicsResponse")) {
                object.pendingReviewTopicsResponse = $root.api.PendingReviewTopicsResponse.toObject(message.pendingReviewTopicsResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "pendingReviewTopicsResponse";
            }
            if (message.deleteMembersResponse != null && message.hasOwnProperty("deleteMembersResponse")) {
                object.deleteMembersResponse = $root.api.DeleteMembersResponse.toObject(message.deleteMembersResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteMembersResponse";
            }
            if (message.lookupTinyCodeResponse != null && message.hasOwnProperty("lookupTinyCodeResponse")) {
                object.lookupTinyCodeResponse = $root.api.LookupTinyCodeResponse.toObject(message.lookupTinyCodeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "lookupTinyCodeResponse";
            }
            if (message.setupPaymentResponse != null && message.hasOwnProperty("setupPaymentResponse")) {
                object.setupPaymentResponse = $root.api.SetupPaymentResponse.toObject(message.setupPaymentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "setupPaymentResponse";
            }
            if (message.deleteTimeResponse != null && message.hasOwnProperty("deleteTimeResponse")) {
                object.deleteTimeResponse = $root.api.DeleteTimeResponse.toObject(message.deleteTimeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteTimeResponse";
            }
            if (message.truncateScheduleResponse != null && message.hasOwnProperty("truncateScheduleResponse")) {
                object.truncateScheduleResponse = $root.api.TruncateScheduleResponse.toObject(message.truncateScheduleResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "truncateScheduleResponse";
            }
            if (message.resetPasswordResponse != null && message.hasOwnProperty("resetPasswordResponse")) {
                object.resetPasswordResponse = $root.api.ResetPasswordResponse.toObject(message.resetPasswordResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "resetPasswordResponse";
            }
            if (message.addTimeResponse != null && message.hasOwnProperty("addTimeResponse")) {
                object.addTimeResponse = $root.api.AddTimeResponse.toObject(message.addTimeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addTimeResponse";
            }
            if (message.sendValidationCodeResponse != null && message.hasOwnProperty("sendValidationCodeResponse")) {
                object.sendValidationCodeResponse = $root.api.SendValidationCodeResponse.toObject(message.sendValidationCodeResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "sendValidationCodeResponse";
            }
            if (message.leaveTopicResponse != null && message.hasOwnProperty("leaveTopicResponse")) {
                object.leaveTopicResponse = $root.api.LeaveTopicResponse.toObject(message.leaveTopicResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "leaveTopicResponse";
            }
            if (message.updatePaymentResponse != null && message.hasOwnProperty("updatePaymentResponse")) {
                object.updatePaymentResponse = $root.api.UpdatePaymentResponse.toObject(message.updatePaymentResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updatePaymentResponse";
            }
            if (message.downloadCalendarResponse != null && message.hasOwnProperty("downloadCalendarResponse")) {
                object.downloadCalendarResponse = $root.api.DownloadCalendarResponse.toObject(message.downloadCalendarResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "downloadCalendarResponse";
            }
            if (message.triggerRenderResponse != null && message.hasOwnProperty("triggerRenderResponse")) {
                object.triggerRenderResponse = $root.api.TriggerRenderResponse.toObject(message.triggerRenderResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "triggerRenderResponse";
            }
            if (message.renderStatusResponse != null && message.hasOwnProperty("renderStatusResponse")) {
                object.renderStatusResponse = $root.api.RenderStatusResponse.toObject(message.renderStatusResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "renderStatusResponse";
            }
            if (message.addIntegrationResponse != null && message.hasOwnProperty("addIntegrationResponse")) {
                object.addIntegrationResponse = $root.api.AddIntegrationResponse.toObject(message.addIntegrationResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "addIntegrationResponse";
            }
            if (message.deleteIntegrationResponse != null && message.hasOwnProperty("deleteIntegrationResponse")) {
                object.deleteIntegrationResponse = $root.api.DeleteIntegrationResponse.toObject(message.deleteIntegrationResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "deleteIntegrationResponse";
            }
            if (message.updateIntegrationResponse != null && message.hasOwnProperty("updateIntegrationResponse")) {
                object.updateIntegrationResponse = $root.api.UpdateIntegrationResponse.toObject(message.updateIntegrationResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateIntegrationResponse";
            }
            if (message.manageCouponResponse != null && message.hasOwnProperty("manageCouponResponse")) {
                object.manageCouponResponse = $root.api.ManageCouponResponse.toObject(message.manageCouponResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "manageCouponResponse";
            }
            if (message.createCouponResponse != null && message.hasOwnProperty("createCouponResponse")) {
                object.createCouponResponse = $root.api.CreateCouponResponse.toObject(message.createCouponResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "createCouponResponse";
            }
            if (message.updateCouponResponse != null && message.hasOwnProperty("updateCouponResponse")) {
                object.updateCouponResponse = $root.api.UpdateCouponResponse.toObject(message.updateCouponResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateCouponResponse";
            }
            if (message.destroyCouponResponse != null && message.hasOwnProperty("destroyCouponResponse")) {
                object.destroyCouponResponse = $root.api.DestroyCouponResponse.toObject(message.destroyCouponResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "destroyCouponResponse";
            }
            if (message.updateServiceSubscriptionResponse != null && message.hasOwnProperty("updateServiceSubscriptionResponse")) {
                object.updateServiceSubscriptionResponse = $root.api.UpdateServiceSubscriptionResponse.toObject(message.updateServiceSubscriptionResponse, options);
                if (options.oneofs)
                    object.apiResponseInfo = "updateServiceSubscriptionResponse";
            }
            return object;
        };

        /**
         * Converts this APIResponse to JSON.
         * @function toJSON
         * @memberof api.APIResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIResponse;
    })();

    api.APIRequest = (function() {

        /**
         * Properties of a APIRequest.
         * @memberof api
         * @interface IAPIRequest
         * @property {number|null} [requestId] APIRequest requestId
         * @property {api.ILoginRequest|null} [loginRequest] APIRequest loginRequest
         * @property {api.ISendValidationCodeRequest|null} [sendValidationCodeRequest] APIRequest sendValidationCodeRequest
         * @property {api.IValidateRequest|null} [validateRequest] APIRequest validateRequest
         * @property {api.IAuthenticateRequest|null} [authenticateRequest] APIRequest authenticateRequest
         * @property {api.ILookupTinyCodeRequest|null} [lookupTinyCodeRequest] APIRequest lookupTinyCodeRequest
         * @property {api.IFindTopicsRequest|null} [findTopicsRequest] APIRequest findTopicsRequest
         * @property {api.IPendingReviewTopicsRequest|null} [pendingReviewTopicsRequest] APIRequest pendingReviewTopicsRequest
         * @property {api.IGetTopicRequest|null} [getTopicRequest] APIRequest getTopicRequest
         * @property {api.ICreateTopicRequest|null} [createTopicRequest] APIRequest createTopicRequest
         * @property {api.IUpdateTopicRequest|null} [updateTopicRequest] APIRequest updateTopicRequest
         * @property {api.IDeleteTopicRequest|null} [deleteTopicRequest] APIRequest deleteTopicRequest
         * @property {api.IDownloadCalendarRequest|null} [downloadCalendarRequest] APIRequest downloadCalendarRequest
         * @property {api.IJoinTopicRequest|null} [joinTopicRequest] APIRequest joinTopicRequest
         * @property {api.ILeaveTopicRequest|null} [leaveTopicRequest] APIRequest leaveTopicRequest
         * @property {api.IWaitlistTopicRequest|null} [waitlistTopicRequest] APIRequest waitlistTopicRequest
         * @property {api.IAddTopicReviewRequest|null} [addTopicReviewRequest] APIRequest addTopicReviewRequest
         * @property {api.IUpdateTopicReviewRequest|null} [updateTopicReviewRequest] APIRequest updateTopicReviewRequest
         * @property {api.ISetupPaymentRequest|null} [setupPaymentRequest] APIRequest setupPaymentRequest
         * @property {api.IUpdatePaymentRequest|null} [updatePaymentRequest] APIRequest updatePaymentRequest
         * @property {api.IGetRoomRequest|null} [getRoomRequest] APIRequest getRoomRequest
         * @property {api.ICreateRoomRequest|null} [createRoomRequest] APIRequest createRoomRequest
         * @property {api.IUpdateRoomRequest|null} [updateRoomRequest] APIRequest updateRoomRequest
         * @property {api.IDeleteRoomRequest|null} [deleteRoomRequest] APIRequest deleteRoomRequest
         * @property {api.IAddMemberRequest|null} [addMemberRequest] APIRequest addMemberRequest
         * @property {api.IUpdateMemberRequest|null} [updateMemberRequest] APIRequest updateMemberRequest
         * @property {api.IDeleteMemberRequest|null} [deleteMemberRequest] APIRequest deleteMemberRequest
         * @property {api.IDeleteMembersRequest|null} [deleteMembersRequest] APIRequest deleteMembersRequest
         * @property {api.IAnswerMemberRequest|null} [answerMemberRequest] APIRequest answerMemberRequest
         * @property {api.IGetScheduleSetRequest|null} [getScheduleSetRequest] APIRequest getScheduleSetRequest
         * @property {api.ICreateScheduleSetRequest|null} [createScheduleSetRequest] APIRequest createScheduleSetRequest
         * @property {api.IUpdateScheduleSetRequest|null} [updateScheduleSetRequest] APIRequest updateScheduleSetRequest
         * @property {api.IDeleteScheduleSetRequest|null} [deleteScheduleSetRequest] APIRequest deleteScheduleSetRequest
         * @property {api.IGetScheduleRequest|null} [getScheduleRequest] APIRequest getScheduleRequest
         * @property {api.IAddScheduleRequest|null} [addScheduleRequest] APIRequest addScheduleRequest
         * @property {api.IUpdateScheduleRequest|null} [updateScheduleRequest] APIRequest updateScheduleRequest
         * @property {api.ITruncateScheduleRequest|null} [truncateScheduleRequest] APIRequest truncateScheduleRequest
         * @property {api.IDeleteScheduleRequest|null} [deleteScheduleRequest] APIRequest deleteScheduleRequest
         * @property {api.IGetTimeRequest|null} [getTimeRequest] APIRequest getTimeRequest
         * @property {api.IUpdateTimeRequest|null} [updateTimeRequest] APIRequest updateTimeRequest
         * @property {api.IDeleteTimeRequest|null} [deleteTimeRequest] APIRequest deleteTimeRequest
         * @property {api.IAddTimeRequest|null} [addTimeRequest] APIRequest addTimeRequest
         * @property {api.IGetUserRequest|null} [getUserRequest] APIRequest getUserRequest
         * @property {api.ICreateUserRequest|null} [createUserRequest] APIRequest createUserRequest
         * @property {api.IUpdateUserRequest|null} [updateUserRequest] APIRequest updateUserRequest
         * @property {api.IDeleteUserRequest|null} [deleteUserRequest] APIRequest deleteUserRequest
         * @property {api.IGetUserEventsRequest|null} [getUserEventsRequest] APIRequest getUserEventsRequest
         * @property {api.IGetGroupRequest|null} [getGroupRequest] APIRequest getGroupRequest
         * @property {api.ICreateGroupRequest|null} [createGroupRequest] APIRequest createGroupRequest
         * @property {api.IUpdateGroupRequest|null} [updateGroupRequest] APIRequest updateGroupRequest
         * @property {api.IDeleteGroupRequest|null} [deleteGroupRequest] APIRequest deleteGroupRequest
         * @property {api.ISendMessageRequest|null} [sendMessageRequest] APIRequest sendMessageRequest
         * @property {api.IGetConferenceConnectInfoRequest|null} [getConferenceConnectInfoRequest] APIRequest getConferenceConnectInfoRequest
         * @property {api.IAddAssetRequest|null} [addAssetRequest] APIRequest addAssetRequest
         * @property {api.IDeleteAssetRequest|null} [deleteAssetRequest] APIRequest deleteAssetRequest
         * @property {api.ICreateSyncRequest|null} [createSyncRequest] APIRequest createSyncRequest
         * @property {api.IUpdateSyncRequest|null} [updateSyncRequest] APIRequest updateSyncRequest
         * @property {api.IDeleteSyncRequest|null} [deleteSyncRequest] APIRequest deleteSyncRequest
         * @property {api.ITriggerSyncRequest|null} [triggerSyncRequest] APIRequest triggerSyncRequest
         * @property {api.ITriggerRenderRequest|null} [triggerRenderRequest] APIRequest triggerRenderRequest
         * @property {api.IRenderStatusRequest|null} [renderStatusRequest] APIRequest renderStatusRequest
         * @property {api.IAddIntegrationRequest|null} [addIntegrationRequest] APIRequest addIntegrationRequest
         * @property {api.IDeleteIntegrationRequest|null} [deleteIntegrationRequest] APIRequest deleteIntegrationRequest
         * @property {api.IUpdateIntegrationRequest|null} [updateIntegrationRequest] APIRequest updateIntegrationRequest
         * @property {api.IManageCouponRequest|null} [manageCouponRequest] APIRequest manageCouponRequest
         * @property {api.ICreateCouponRequest|null} [createCouponRequest] APIRequest createCouponRequest
         * @property {api.IUpdateCouponRequest|null} [updateCouponRequest] APIRequest updateCouponRequest
         * @property {api.IDestroyCouponRequest|null} [destroyCouponRequest] APIRequest destroyCouponRequest
         * @property {api.IUpdateServiceSubscriptionRequest|null} [updateServiceSubscriptionRequest] APIRequest updateServiceSubscriptionRequest
         */

        /**
         * Constructs a new APIRequest.
         * @memberof api
         * @classdesc Represents a APIRequest.
         * @implements IAPIRequest
         * @constructor
         * @param {api.IAPIRequest=} [properties] Properties to set
         */
        function APIRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIRequest requestId.
         * @member {number} requestId
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.requestId = 0;

        /**
         * APIRequest loginRequest.
         * @member {api.ILoginRequest|null|undefined} loginRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.loginRequest = null;

        /**
         * APIRequest sendValidationCodeRequest.
         * @member {api.ISendValidationCodeRequest|null|undefined} sendValidationCodeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.sendValidationCodeRequest = null;

        /**
         * APIRequest validateRequest.
         * @member {api.IValidateRequest|null|undefined} validateRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.validateRequest = null;

        /**
         * APIRequest authenticateRequest.
         * @member {api.IAuthenticateRequest|null|undefined} authenticateRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.authenticateRequest = null;

        /**
         * APIRequest lookupTinyCodeRequest.
         * @member {api.ILookupTinyCodeRequest|null|undefined} lookupTinyCodeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.lookupTinyCodeRequest = null;

        /**
         * APIRequest findTopicsRequest.
         * @member {api.IFindTopicsRequest|null|undefined} findTopicsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.findTopicsRequest = null;

        /**
         * APIRequest pendingReviewTopicsRequest.
         * @member {api.IPendingReviewTopicsRequest|null|undefined} pendingReviewTopicsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.pendingReviewTopicsRequest = null;

        /**
         * APIRequest getTopicRequest.
         * @member {api.IGetTopicRequest|null|undefined} getTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getTopicRequest = null;

        /**
         * APIRequest createTopicRequest.
         * @member {api.ICreateTopicRequest|null|undefined} createTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createTopicRequest = null;

        /**
         * APIRequest updateTopicRequest.
         * @member {api.IUpdateTopicRequest|null|undefined} updateTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateTopicRequest = null;

        /**
         * APIRequest deleteTopicRequest.
         * @member {api.IDeleteTopicRequest|null|undefined} deleteTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteTopicRequest = null;

        /**
         * APIRequest downloadCalendarRequest.
         * @member {api.IDownloadCalendarRequest|null|undefined} downloadCalendarRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.downloadCalendarRequest = null;

        /**
         * APIRequest joinTopicRequest.
         * @member {api.IJoinTopicRequest|null|undefined} joinTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.joinTopicRequest = null;

        /**
         * APIRequest leaveTopicRequest.
         * @member {api.ILeaveTopicRequest|null|undefined} leaveTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.leaveTopicRequest = null;

        /**
         * APIRequest waitlistTopicRequest.
         * @member {api.IWaitlistTopicRequest|null|undefined} waitlistTopicRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.waitlistTopicRequest = null;

        /**
         * APIRequest addTopicReviewRequest.
         * @member {api.IAddTopicReviewRequest|null|undefined} addTopicReviewRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addTopicReviewRequest = null;

        /**
         * APIRequest updateTopicReviewRequest.
         * @member {api.IUpdateTopicReviewRequest|null|undefined} updateTopicReviewRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateTopicReviewRequest = null;

        /**
         * APIRequest setupPaymentRequest.
         * @member {api.ISetupPaymentRequest|null|undefined} setupPaymentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.setupPaymentRequest = null;

        /**
         * APIRequest updatePaymentRequest.
         * @member {api.IUpdatePaymentRequest|null|undefined} updatePaymentRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updatePaymentRequest = null;

        /**
         * APIRequest getRoomRequest.
         * @member {api.IGetRoomRequest|null|undefined} getRoomRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getRoomRequest = null;

        /**
         * APIRequest createRoomRequest.
         * @member {api.ICreateRoomRequest|null|undefined} createRoomRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createRoomRequest = null;

        /**
         * APIRequest updateRoomRequest.
         * @member {api.IUpdateRoomRequest|null|undefined} updateRoomRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateRoomRequest = null;

        /**
         * APIRequest deleteRoomRequest.
         * @member {api.IDeleteRoomRequest|null|undefined} deleteRoomRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteRoomRequest = null;

        /**
         * APIRequest addMemberRequest.
         * @member {api.IAddMemberRequest|null|undefined} addMemberRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addMemberRequest = null;

        /**
         * APIRequest updateMemberRequest.
         * @member {api.IUpdateMemberRequest|null|undefined} updateMemberRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateMemberRequest = null;

        /**
         * APIRequest deleteMemberRequest.
         * @member {api.IDeleteMemberRequest|null|undefined} deleteMemberRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteMemberRequest = null;

        /**
         * APIRequest deleteMembersRequest.
         * @member {api.IDeleteMembersRequest|null|undefined} deleteMembersRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteMembersRequest = null;

        /**
         * APIRequest answerMemberRequest.
         * @member {api.IAnswerMemberRequest|null|undefined} answerMemberRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.answerMemberRequest = null;

        /**
         * APIRequest getScheduleSetRequest.
         * @member {api.IGetScheduleSetRequest|null|undefined} getScheduleSetRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getScheduleSetRequest = null;

        /**
         * APIRequest createScheduleSetRequest.
         * @member {api.ICreateScheduleSetRequest|null|undefined} createScheduleSetRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createScheduleSetRequest = null;

        /**
         * APIRequest updateScheduleSetRequest.
         * @member {api.IUpdateScheduleSetRequest|null|undefined} updateScheduleSetRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateScheduleSetRequest = null;

        /**
         * APIRequest deleteScheduleSetRequest.
         * @member {api.IDeleteScheduleSetRequest|null|undefined} deleteScheduleSetRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteScheduleSetRequest = null;

        /**
         * APIRequest getScheduleRequest.
         * @member {api.IGetScheduleRequest|null|undefined} getScheduleRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getScheduleRequest = null;

        /**
         * APIRequest addScheduleRequest.
         * @member {api.IAddScheduleRequest|null|undefined} addScheduleRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addScheduleRequest = null;

        /**
         * APIRequest updateScheduleRequest.
         * @member {api.IUpdateScheduleRequest|null|undefined} updateScheduleRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateScheduleRequest = null;

        /**
         * APIRequest truncateScheduleRequest.
         * @member {api.ITruncateScheduleRequest|null|undefined} truncateScheduleRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.truncateScheduleRequest = null;

        /**
         * APIRequest deleteScheduleRequest.
         * @member {api.IDeleteScheduleRequest|null|undefined} deleteScheduleRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteScheduleRequest = null;

        /**
         * APIRequest getTimeRequest.
         * @member {api.IGetTimeRequest|null|undefined} getTimeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getTimeRequest = null;

        /**
         * APIRequest updateTimeRequest.
         * @member {api.IUpdateTimeRequest|null|undefined} updateTimeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateTimeRequest = null;

        /**
         * APIRequest deleteTimeRequest.
         * @member {api.IDeleteTimeRequest|null|undefined} deleteTimeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteTimeRequest = null;

        /**
         * APIRequest addTimeRequest.
         * @member {api.IAddTimeRequest|null|undefined} addTimeRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addTimeRequest = null;

        /**
         * APIRequest getUserRequest.
         * @member {api.IGetUserRequest|null|undefined} getUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getUserRequest = null;

        /**
         * APIRequest createUserRequest.
         * @member {api.ICreateUserRequest|null|undefined} createUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createUserRequest = null;

        /**
         * APIRequest updateUserRequest.
         * @member {api.IUpdateUserRequest|null|undefined} updateUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateUserRequest = null;

        /**
         * APIRequest deleteUserRequest.
         * @member {api.IDeleteUserRequest|null|undefined} deleteUserRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteUserRequest = null;

        /**
         * APIRequest getUserEventsRequest.
         * @member {api.IGetUserEventsRequest|null|undefined} getUserEventsRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getUserEventsRequest = null;

        /**
         * APIRequest getGroupRequest.
         * @member {api.IGetGroupRequest|null|undefined} getGroupRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getGroupRequest = null;

        /**
         * APIRequest createGroupRequest.
         * @member {api.ICreateGroupRequest|null|undefined} createGroupRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createGroupRequest = null;

        /**
         * APIRequest updateGroupRequest.
         * @member {api.IUpdateGroupRequest|null|undefined} updateGroupRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateGroupRequest = null;

        /**
         * APIRequest deleteGroupRequest.
         * @member {api.IDeleteGroupRequest|null|undefined} deleteGroupRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteGroupRequest = null;

        /**
         * APIRequest sendMessageRequest.
         * @member {api.ISendMessageRequest|null|undefined} sendMessageRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.sendMessageRequest = null;

        /**
         * APIRequest getConferenceConnectInfoRequest.
         * @member {api.IGetConferenceConnectInfoRequest|null|undefined} getConferenceConnectInfoRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.getConferenceConnectInfoRequest = null;

        /**
         * APIRequest addAssetRequest.
         * @member {api.IAddAssetRequest|null|undefined} addAssetRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addAssetRequest = null;

        /**
         * APIRequest deleteAssetRequest.
         * @member {api.IDeleteAssetRequest|null|undefined} deleteAssetRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteAssetRequest = null;

        /**
         * APIRequest createSyncRequest.
         * @member {api.ICreateSyncRequest|null|undefined} createSyncRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createSyncRequest = null;

        /**
         * APIRequest updateSyncRequest.
         * @member {api.IUpdateSyncRequest|null|undefined} updateSyncRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateSyncRequest = null;

        /**
         * APIRequest deleteSyncRequest.
         * @member {api.IDeleteSyncRequest|null|undefined} deleteSyncRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteSyncRequest = null;

        /**
         * APIRequest triggerSyncRequest.
         * @member {api.ITriggerSyncRequest|null|undefined} triggerSyncRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.triggerSyncRequest = null;

        /**
         * APIRequest triggerRenderRequest.
         * @member {api.ITriggerRenderRequest|null|undefined} triggerRenderRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.triggerRenderRequest = null;

        /**
         * APIRequest renderStatusRequest.
         * @member {api.IRenderStatusRequest|null|undefined} renderStatusRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.renderStatusRequest = null;

        /**
         * APIRequest addIntegrationRequest.
         * @member {api.IAddIntegrationRequest|null|undefined} addIntegrationRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.addIntegrationRequest = null;

        /**
         * APIRequest deleteIntegrationRequest.
         * @member {api.IDeleteIntegrationRequest|null|undefined} deleteIntegrationRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.deleteIntegrationRequest = null;

        /**
         * APIRequest updateIntegrationRequest.
         * @member {api.IUpdateIntegrationRequest|null|undefined} updateIntegrationRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateIntegrationRequest = null;

        /**
         * APIRequest manageCouponRequest.
         * @member {api.IManageCouponRequest|null|undefined} manageCouponRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.manageCouponRequest = null;

        /**
         * APIRequest createCouponRequest.
         * @member {api.ICreateCouponRequest|null|undefined} createCouponRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.createCouponRequest = null;

        /**
         * APIRequest updateCouponRequest.
         * @member {api.IUpdateCouponRequest|null|undefined} updateCouponRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateCouponRequest = null;

        /**
         * APIRequest destroyCouponRequest.
         * @member {api.IDestroyCouponRequest|null|undefined} destroyCouponRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.destroyCouponRequest = null;

        /**
         * APIRequest updateServiceSubscriptionRequest.
         * @member {api.IUpdateServiceSubscriptionRequest|null|undefined} updateServiceSubscriptionRequest
         * @memberof api.APIRequest
         * @instance
         */
        APIRequest.prototype.updateServiceSubscriptionRequest = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * APIRequest apiRequestInfo.
         * @member {"loginRequest"|"sendValidationCodeRequest"|"validateRequest"|"authenticateRequest"|"lookupTinyCodeRequest"|"findTopicsRequest"|"pendingReviewTopicsRequest"|"getTopicRequest"|"createTopicRequest"|"updateTopicRequest"|"deleteTopicRequest"|"downloadCalendarRequest"|"joinTopicRequest"|"leaveTopicRequest"|"waitlistTopicRequest"|"addTopicReviewRequest"|"updateTopicReviewRequest"|"setupPaymentRequest"|"updatePaymentRequest"|"getRoomRequest"|"createRoomRequest"|"updateRoomRequest"|"deleteRoomRequest"|"addMemberRequest"|"updateMemberRequest"|"deleteMemberRequest"|"deleteMembersRequest"|"answerMemberRequest"|"getScheduleSetRequest"|"createScheduleSetRequest"|"updateScheduleSetRequest"|"deleteScheduleSetRequest"|"getScheduleRequest"|"addScheduleRequest"|"updateScheduleRequest"|"truncateScheduleRequest"|"deleteScheduleRequest"|"getTimeRequest"|"updateTimeRequest"|"deleteTimeRequest"|"addTimeRequest"|"getUserRequest"|"createUserRequest"|"updateUserRequest"|"deleteUserRequest"|"getUserEventsRequest"|"getGroupRequest"|"createGroupRequest"|"updateGroupRequest"|"deleteGroupRequest"|"sendMessageRequest"|"getConferenceConnectInfoRequest"|"addAssetRequest"|"deleteAssetRequest"|"createSyncRequest"|"updateSyncRequest"|"deleteSyncRequest"|"triggerSyncRequest"|"triggerRenderRequest"|"renderStatusRequest"|"addIntegrationRequest"|"deleteIntegrationRequest"|"updateIntegrationRequest"|"manageCouponRequest"|"createCouponRequest"|"updateCouponRequest"|"destroyCouponRequest"|"updateServiceSubscriptionRequest"|undefined} apiRequestInfo
         * @memberof api.APIRequest
         * @instance
         */
        Object.defineProperty(APIRequest.prototype, "apiRequestInfo", {
            get: $util.oneOfGetter($oneOfFields = ["loginRequest", "sendValidationCodeRequest", "validateRequest", "authenticateRequest", "lookupTinyCodeRequest", "findTopicsRequest", "pendingReviewTopicsRequest", "getTopicRequest", "createTopicRequest", "updateTopicRequest", "deleteTopicRequest", "downloadCalendarRequest", "joinTopicRequest", "leaveTopicRequest", "waitlistTopicRequest", "addTopicReviewRequest", "updateTopicReviewRequest", "setupPaymentRequest", "updatePaymentRequest", "getRoomRequest", "createRoomRequest", "updateRoomRequest", "deleteRoomRequest", "addMemberRequest", "updateMemberRequest", "deleteMemberRequest", "deleteMembersRequest", "answerMemberRequest", "getScheduleSetRequest", "createScheduleSetRequest", "updateScheduleSetRequest", "deleteScheduleSetRequest", "getScheduleRequest", "addScheduleRequest", "updateScheduleRequest", "truncateScheduleRequest", "deleteScheduleRequest", "getTimeRequest", "updateTimeRequest", "deleteTimeRequest", "addTimeRequest", "getUserRequest", "createUserRequest", "updateUserRequest", "deleteUserRequest", "getUserEventsRequest", "getGroupRequest", "createGroupRequest", "updateGroupRequest", "deleteGroupRequest", "sendMessageRequest", "getConferenceConnectInfoRequest", "addAssetRequest", "deleteAssetRequest", "createSyncRequest", "updateSyncRequest", "deleteSyncRequest", "triggerSyncRequest", "triggerRenderRequest", "renderStatusRequest", "addIntegrationRequest", "deleteIntegrationRequest", "updateIntegrationRequest", "manageCouponRequest", "createCouponRequest", "updateCouponRequest", "destroyCouponRequest", "updateServiceSubscriptionRequest"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APIRequest instance using the specified properties.
         * @function create
         * @memberof api.APIRequest
         * @static
         * @param {api.IAPIRequest=} [properties] Properties to set
         * @returns {api.APIRequest} APIRequest instance
         */
        APIRequest.create = function create(properties) {
            return new APIRequest(properties);
        };

        /**
         * Encodes the specified APIRequest message. Does not implicitly {@link api.APIRequest.verify|verify} messages.
         * @function encode
         * @memberof api.APIRequest
         * @static
         * @param {api.IAPIRequest} message APIRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestId != null && Object.hasOwnProperty.call(message, "requestId"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.requestId);
            if (message.loginRequest != null && Object.hasOwnProperty.call(message, "loginRequest"))
                $root.api.LoginRequest.encode(message.loginRequest, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.authenticateRequest != null && Object.hasOwnProperty.call(message, "authenticateRequest"))
                $root.api.AuthenticateRequest.encode(message.authenticateRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.createTopicRequest != null && Object.hasOwnProperty.call(message, "createTopicRequest"))
                $root.api.CreateTopicRequest.encode(message.createTopicRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.updateTopicRequest != null && Object.hasOwnProperty.call(message, "updateTopicRequest"))
                $root.api.UpdateTopicRequest.encode(message.updateTopicRequest, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.deleteTopicRequest != null && Object.hasOwnProperty.call(message, "deleteTopicRequest"))
                $root.api.DeleteTopicRequest.encode(message.deleteTopicRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.createRoomRequest != null && Object.hasOwnProperty.call(message, "createRoomRequest"))
                $root.api.CreateRoomRequest.encode(message.createRoomRequest, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.updateRoomRequest != null && Object.hasOwnProperty.call(message, "updateRoomRequest"))
                $root.api.UpdateRoomRequest.encode(message.updateRoomRequest, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.deleteRoomRequest != null && Object.hasOwnProperty.call(message, "deleteRoomRequest"))
                $root.api.DeleteRoomRequest.encode(message.deleteRoomRequest, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.addMemberRequest != null && Object.hasOwnProperty.call(message, "addMemberRequest"))
                $root.api.AddMemberRequest.encode(message.addMemberRequest, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.updateMemberRequest != null && Object.hasOwnProperty.call(message, "updateMemberRequest"))
                $root.api.UpdateMemberRequest.encode(message.updateMemberRequest, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.deleteMemberRequest != null && Object.hasOwnProperty.call(message, "deleteMemberRequest"))
                $root.api.DeleteMemberRequest.encode(message.deleteMemberRequest, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.answerMemberRequest != null && Object.hasOwnProperty.call(message, "answerMemberRequest"))
                $root.api.AnswerMemberRequest.encode(message.answerMemberRequest, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.createScheduleSetRequest != null && Object.hasOwnProperty.call(message, "createScheduleSetRequest"))
                $root.api.CreateScheduleSetRequest.encode(message.createScheduleSetRequest, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.updateScheduleSetRequest != null && Object.hasOwnProperty.call(message, "updateScheduleSetRequest"))
                $root.api.UpdateScheduleSetRequest.encode(message.updateScheduleSetRequest, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.deleteScheduleSetRequest != null && Object.hasOwnProperty.call(message, "deleteScheduleSetRequest"))
                $root.api.DeleteScheduleSetRequest.encode(message.deleteScheduleSetRequest, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.createUserRequest != null && Object.hasOwnProperty.call(message, "createUserRequest"))
                $root.api.CreateUserRequest.encode(message.createUserRequest, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.updateUserRequest != null && Object.hasOwnProperty.call(message, "updateUserRequest"))
                $root.api.UpdateUserRequest.encode(message.updateUserRequest, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.deleteUserRequest != null && Object.hasOwnProperty.call(message, "deleteUserRequest"))
                $root.api.DeleteUserRequest.encode(message.deleteUserRequest, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.createGroupRequest != null && Object.hasOwnProperty.call(message, "createGroupRequest"))
                $root.api.CreateGroupRequest.encode(message.createGroupRequest, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.updateGroupRequest != null && Object.hasOwnProperty.call(message, "updateGroupRequest"))
                $root.api.UpdateGroupRequest.encode(message.updateGroupRequest, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.deleteGroupRequest != null && Object.hasOwnProperty.call(message, "deleteGroupRequest"))
                $root.api.DeleteGroupRequest.encode(message.deleteGroupRequest, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.sendMessageRequest != null && Object.hasOwnProperty.call(message, "sendMessageRequest"))
                $root.api.SendMessageRequest.encode(message.sendMessageRequest, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.getRoomRequest != null && Object.hasOwnProperty.call(message, "getRoomRequest"))
                $root.api.GetRoomRequest.encode(message.getRoomRequest, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.getConferenceConnectInfoRequest != null && Object.hasOwnProperty.call(message, "getConferenceConnectInfoRequest"))
                $root.api.GetConferenceConnectInfoRequest.encode(message.getConferenceConnectInfoRequest, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.getTopicRequest != null && Object.hasOwnProperty.call(message, "getTopicRequest"))
                $root.api.GetTopicRequest.encode(message.getTopicRequest, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.getUserRequest != null && Object.hasOwnProperty.call(message, "getUserRequest"))
                $root.api.GetUserRequest.encode(message.getUserRequest, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            if (message.getGroupRequest != null && Object.hasOwnProperty.call(message, "getGroupRequest"))
                $root.api.GetGroupRequest.encode(message.getGroupRequest, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
            if (message.getUserEventsRequest != null && Object.hasOwnProperty.call(message, "getUserEventsRequest"))
                $root.api.GetUserEventsRequest.encode(message.getUserEventsRequest, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
            if (message.findTopicsRequest != null && Object.hasOwnProperty.call(message, "findTopicsRequest"))
                $root.api.FindTopicsRequest.encode(message.findTopicsRequest, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
            if (message.addTopicReviewRequest != null && Object.hasOwnProperty.call(message, "addTopicReviewRequest"))
                $root.api.AddTopicReviewRequest.encode(message.addTopicReviewRequest, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
            if (message.joinTopicRequest != null && Object.hasOwnProperty.call(message, "joinTopicRequest"))
                $root.api.JoinTopicRequest.encode(message.joinTopicRequest, writer.uint32(/* id 33, wireType 2 =*/266).fork()).ldelim();
            if (message.addAssetRequest != null && Object.hasOwnProperty.call(message, "addAssetRequest"))
                $root.api.AddAssetRequest.encode(message.addAssetRequest, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
            if (message.deleteAssetRequest != null && Object.hasOwnProperty.call(message, "deleteAssetRequest"))
                $root.api.DeleteAssetRequest.encode(message.deleteAssetRequest, writer.uint32(/* id 35, wireType 2 =*/282).fork()).ldelim();
            if (message.createSyncRequest != null && Object.hasOwnProperty.call(message, "createSyncRequest"))
                $root.api.CreateSyncRequest.encode(message.createSyncRequest, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
            if (message.updateSyncRequest != null && Object.hasOwnProperty.call(message, "updateSyncRequest"))
                $root.api.UpdateSyncRequest.encode(message.updateSyncRequest, writer.uint32(/* id 37, wireType 2 =*/298).fork()).ldelim();
            if (message.deleteSyncRequest != null && Object.hasOwnProperty.call(message, "deleteSyncRequest"))
                $root.api.DeleteSyncRequest.encode(message.deleteSyncRequest, writer.uint32(/* id 38, wireType 2 =*/306).fork()).ldelim();
            if (message.triggerSyncRequest != null && Object.hasOwnProperty.call(message, "triggerSyncRequest"))
                $root.api.TriggerSyncRequest.encode(message.triggerSyncRequest, writer.uint32(/* id 39, wireType 2 =*/314).fork()).ldelim();
            if (message.waitlistTopicRequest != null && Object.hasOwnProperty.call(message, "waitlistTopicRequest"))
                $root.api.WaitlistTopicRequest.encode(message.waitlistTopicRequest, writer.uint32(/* id 40, wireType 2 =*/322).fork()).ldelim();
            if (message.updateTopicReviewRequest != null && Object.hasOwnProperty.call(message, "updateTopicReviewRequest"))
                $root.api.UpdateTopicReviewRequest.encode(message.updateTopicReviewRequest, writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
            if (message.getScheduleSetRequest != null && Object.hasOwnProperty.call(message, "getScheduleSetRequest"))
                $root.api.GetScheduleSetRequest.encode(message.getScheduleSetRequest, writer.uint32(/* id 42, wireType 2 =*/338).fork()).ldelim();
            if (message.addScheduleRequest != null && Object.hasOwnProperty.call(message, "addScheduleRequest"))
                $root.api.AddScheduleRequest.encode(message.addScheduleRequest, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
            if (message.updateScheduleRequest != null && Object.hasOwnProperty.call(message, "updateScheduleRequest"))
                $root.api.UpdateScheduleRequest.encode(message.updateScheduleRequest, writer.uint32(/* id 44, wireType 2 =*/354).fork()).ldelim();
            if (message.deleteScheduleRequest != null && Object.hasOwnProperty.call(message, "deleteScheduleRequest"))
                $root.api.DeleteScheduleRequest.encode(message.deleteScheduleRequest, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
            if (message.getScheduleRequest != null && Object.hasOwnProperty.call(message, "getScheduleRequest"))
                $root.api.GetScheduleRequest.encode(message.getScheduleRequest, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
            if (message.getTimeRequest != null && Object.hasOwnProperty.call(message, "getTimeRequest"))
                $root.api.GetTimeRequest.encode(message.getTimeRequest, writer.uint32(/* id 48, wireType 2 =*/386).fork()).ldelim();
            if (message.updateTimeRequest != null && Object.hasOwnProperty.call(message, "updateTimeRequest"))
                $root.api.UpdateTimeRequest.encode(message.updateTimeRequest, writer.uint32(/* id 49, wireType 2 =*/394).fork()).ldelim();
            if (message.validateRequest != null && Object.hasOwnProperty.call(message, "validateRequest"))
                $root.api.ValidateRequest.encode(message.validateRequest, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
            if (message.pendingReviewTopicsRequest != null && Object.hasOwnProperty.call(message, "pendingReviewTopicsRequest"))
                $root.api.PendingReviewTopicsRequest.encode(message.pendingReviewTopicsRequest, writer.uint32(/* id 51, wireType 2 =*/410).fork()).ldelim();
            if (message.deleteMembersRequest != null && Object.hasOwnProperty.call(message, "deleteMembersRequest"))
                $root.api.DeleteMembersRequest.encode(message.deleteMembersRequest, writer.uint32(/* id 52, wireType 2 =*/418).fork()).ldelim();
            if (message.lookupTinyCodeRequest != null && Object.hasOwnProperty.call(message, "lookupTinyCodeRequest"))
                $root.api.LookupTinyCodeRequest.encode(message.lookupTinyCodeRequest, writer.uint32(/* id 53, wireType 2 =*/426).fork()).ldelim();
            if (message.setupPaymentRequest != null && Object.hasOwnProperty.call(message, "setupPaymentRequest"))
                $root.api.SetupPaymentRequest.encode(message.setupPaymentRequest, writer.uint32(/* id 54, wireType 2 =*/434).fork()).ldelim();
            if (message.deleteTimeRequest != null && Object.hasOwnProperty.call(message, "deleteTimeRequest"))
                $root.api.DeleteTimeRequest.encode(message.deleteTimeRequest, writer.uint32(/* id 55, wireType 2 =*/442).fork()).ldelim();
            if (message.truncateScheduleRequest != null && Object.hasOwnProperty.call(message, "truncateScheduleRequest"))
                $root.api.TruncateScheduleRequest.encode(message.truncateScheduleRequest, writer.uint32(/* id 56, wireType 2 =*/450).fork()).ldelim();
            if (message.sendValidationCodeRequest != null && Object.hasOwnProperty.call(message, "sendValidationCodeRequest"))
                $root.api.SendValidationCodeRequest.encode(message.sendValidationCodeRequest, writer.uint32(/* id 57, wireType 2 =*/458).fork()).ldelim();
            if (message.addTimeRequest != null && Object.hasOwnProperty.call(message, "addTimeRequest"))
                $root.api.AddTimeRequest.encode(message.addTimeRequest, writer.uint32(/* id 58, wireType 2 =*/466).fork()).ldelim();
            if (message.leaveTopicRequest != null && Object.hasOwnProperty.call(message, "leaveTopicRequest"))
                $root.api.LeaveTopicRequest.encode(message.leaveTopicRequest, writer.uint32(/* id 59, wireType 2 =*/474).fork()).ldelim();
            if (message.updatePaymentRequest != null && Object.hasOwnProperty.call(message, "updatePaymentRequest"))
                $root.api.UpdatePaymentRequest.encode(message.updatePaymentRequest, writer.uint32(/* id 60, wireType 2 =*/482).fork()).ldelim();
            if (message.downloadCalendarRequest != null && Object.hasOwnProperty.call(message, "downloadCalendarRequest"))
                $root.api.DownloadCalendarRequest.encode(message.downloadCalendarRequest, writer.uint32(/* id 61, wireType 2 =*/490).fork()).ldelim();
            if (message.triggerRenderRequest != null && Object.hasOwnProperty.call(message, "triggerRenderRequest"))
                $root.api.TriggerRenderRequest.encode(message.triggerRenderRequest, writer.uint32(/* id 62, wireType 2 =*/498).fork()).ldelim();
            if (message.renderStatusRequest != null && Object.hasOwnProperty.call(message, "renderStatusRequest"))
                $root.api.RenderStatusRequest.encode(message.renderStatusRequest, writer.uint32(/* id 63, wireType 2 =*/506).fork()).ldelim();
            if (message.addIntegrationRequest != null && Object.hasOwnProperty.call(message, "addIntegrationRequest"))
                $root.api.AddIntegrationRequest.encode(message.addIntegrationRequest, writer.uint32(/* id 64, wireType 2 =*/514).fork()).ldelim();
            if (message.deleteIntegrationRequest != null && Object.hasOwnProperty.call(message, "deleteIntegrationRequest"))
                $root.api.DeleteIntegrationRequest.encode(message.deleteIntegrationRequest, writer.uint32(/* id 65, wireType 2 =*/522).fork()).ldelim();
            if (message.updateIntegrationRequest != null && Object.hasOwnProperty.call(message, "updateIntegrationRequest"))
                $root.api.UpdateIntegrationRequest.encode(message.updateIntegrationRequest, writer.uint32(/* id 67, wireType 2 =*/538).fork()).ldelim();
            if (message.manageCouponRequest != null && Object.hasOwnProperty.call(message, "manageCouponRequest"))
                $root.api.ManageCouponRequest.encode(message.manageCouponRequest, writer.uint32(/* id 68, wireType 2 =*/546).fork()).ldelim();
            if (message.createCouponRequest != null && Object.hasOwnProperty.call(message, "createCouponRequest"))
                $root.api.CreateCouponRequest.encode(message.createCouponRequest, writer.uint32(/* id 69, wireType 2 =*/554).fork()).ldelim();
            if (message.updateCouponRequest != null && Object.hasOwnProperty.call(message, "updateCouponRequest"))
                $root.api.UpdateCouponRequest.encode(message.updateCouponRequest, writer.uint32(/* id 70, wireType 2 =*/562).fork()).ldelim();
            if (message.destroyCouponRequest != null && Object.hasOwnProperty.call(message, "destroyCouponRequest"))
                $root.api.DestroyCouponRequest.encode(message.destroyCouponRequest, writer.uint32(/* id 71, wireType 2 =*/570).fork()).ldelim();
            if (message.updateServiceSubscriptionRequest != null && Object.hasOwnProperty.call(message, "updateServiceSubscriptionRequest"))
                $root.api.UpdateServiceSubscriptionRequest.encode(message.updateServiceSubscriptionRequest, writer.uint32(/* id 72, wireType 2 =*/578).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified APIRequest message, length delimited. Does not implicitly {@link api.APIRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIRequest
         * @static
         * @param {api.IAPIRequest} message APIRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIRequest message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIRequest} APIRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestId = reader.int32();
                    break;
                case 2:
                    message.loginRequest = $root.api.LoginRequest.decode(reader, reader.uint32());
                    break;
                case 57:
                    message.sendValidationCodeRequest = $root.api.SendValidationCodeRequest.decode(reader, reader.uint32());
                    break;
                case 50:
                    message.validateRequest = $root.api.ValidateRequest.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.authenticateRequest = $root.api.AuthenticateRequest.decode(reader, reader.uint32());
                    break;
                case 53:
                    message.lookupTinyCodeRequest = $root.api.LookupTinyCodeRequest.decode(reader, reader.uint32());
                    break;
                case 30:
                    message.findTopicsRequest = $root.api.FindTopicsRequest.decode(reader, reader.uint32());
                    break;
                case 51:
                    message.pendingReviewTopicsRequest = $root.api.PendingReviewTopicsRequest.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.getTopicRequest = $root.api.GetTopicRequest.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.createTopicRequest = $root.api.CreateTopicRequest.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.updateTopicRequest = $root.api.UpdateTopicRequest.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.deleteTopicRequest = $root.api.DeleteTopicRequest.decode(reader, reader.uint32());
                    break;
                case 61:
                    message.downloadCalendarRequest = $root.api.DownloadCalendarRequest.decode(reader, reader.uint32());
                    break;
                case 33:
                    message.joinTopicRequest = $root.api.JoinTopicRequest.decode(reader, reader.uint32());
                    break;
                case 59:
                    message.leaveTopicRequest = $root.api.LeaveTopicRequest.decode(reader, reader.uint32());
                    break;
                case 40:
                    message.waitlistTopicRequest = $root.api.WaitlistTopicRequest.decode(reader, reader.uint32());
                    break;
                case 32:
                    message.addTopicReviewRequest = $root.api.AddTopicReviewRequest.decode(reader, reader.uint32());
                    break;
                case 41:
                    message.updateTopicReviewRequest = $root.api.UpdateTopicReviewRequest.decode(reader, reader.uint32());
                    break;
                case 54:
                    message.setupPaymentRequest = $root.api.SetupPaymentRequest.decode(reader, reader.uint32());
                    break;
                case 60:
                    message.updatePaymentRequest = $root.api.UpdatePaymentRequest.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.getRoomRequest = $root.api.GetRoomRequest.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.createRoomRequest = $root.api.CreateRoomRequest.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.updateRoomRequest = $root.api.UpdateRoomRequest.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.deleteRoomRequest = $root.api.DeleteRoomRequest.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.addMemberRequest = $root.api.AddMemberRequest.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.updateMemberRequest = $root.api.UpdateMemberRequest.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.deleteMemberRequest = $root.api.DeleteMemberRequest.decode(reader, reader.uint32());
                    break;
                case 52:
                    message.deleteMembersRequest = $root.api.DeleteMembersRequest.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.answerMemberRequest = $root.api.AnswerMemberRequest.decode(reader, reader.uint32());
                    break;
                case 42:
                    message.getScheduleSetRequest = $root.api.GetScheduleSetRequest.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.createScheduleSetRequest = $root.api.CreateScheduleSetRequest.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.updateScheduleSetRequest = $root.api.UpdateScheduleSetRequest.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.deleteScheduleSetRequest = $root.api.DeleteScheduleSetRequest.decode(reader, reader.uint32());
                    break;
                case 46:
                    message.getScheduleRequest = $root.api.GetScheduleRequest.decode(reader, reader.uint32());
                    break;
                case 43:
                    message.addScheduleRequest = $root.api.AddScheduleRequest.decode(reader, reader.uint32());
                    break;
                case 44:
                    message.updateScheduleRequest = $root.api.UpdateScheduleRequest.decode(reader, reader.uint32());
                    break;
                case 56:
                    message.truncateScheduleRequest = $root.api.TruncateScheduleRequest.decode(reader, reader.uint32());
                    break;
                case 45:
                    message.deleteScheduleRequest = $root.api.DeleteScheduleRequest.decode(reader, reader.uint32());
                    break;
                case 48:
                    message.getTimeRequest = $root.api.GetTimeRequest.decode(reader, reader.uint32());
                    break;
                case 49:
                    message.updateTimeRequest = $root.api.UpdateTimeRequest.decode(reader, reader.uint32());
                    break;
                case 55:
                    message.deleteTimeRequest = $root.api.DeleteTimeRequest.decode(reader, reader.uint32());
                    break;
                case 58:
                    message.addTimeRequest = $root.api.AddTimeRequest.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.getUserRequest = $root.api.GetUserRequest.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.createUserRequest = $root.api.CreateUserRequest.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.updateUserRequest = $root.api.UpdateUserRequest.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.deleteUserRequest = $root.api.DeleteUserRequest.decode(reader, reader.uint32());
                    break;
                case 29:
                    message.getUserEventsRequest = $root.api.GetUserEventsRequest.decode(reader, reader.uint32());
                    break;
                case 28:
                    message.getGroupRequest = $root.api.GetGroupRequest.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.createGroupRequest = $root.api.CreateGroupRequest.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.updateGroupRequest = $root.api.UpdateGroupRequest.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.deleteGroupRequest = $root.api.DeleteGroupRequest.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.sendMessageRequest = $root.api.SendMessageRequest.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.getConferenceConnectInfoRequest = $root.api.GetConferenceConnectInfoRequest.decode(reader, reader.uint32());
                    break;
                case 34:
                    message.addAssetRequest = $root.api.AddAssetRequest.decode(reader, reader.uint32());
                    break;
                case 35:
                    message.deleteAssetRequest = $root.api.DeleteAssetRequest.decode(reader, reader.uint32());
                    break;
                case 36:
                    message.createSyncRequest = $root.api.CreateSyncRequest.decode(reader, reader.uint32());
                    break;
                case 37:
                    message.updateSyncRequest = $root.api.UpdateSyncRequest.decode(reader, reader.uint32());
                    break;
                case 38:
                    message.deleteSyncRequest = $root.api.DeleteSyncRequest.decode(reader, reader.uint32());
                    break;
                case 39:
                    message.triggerSyncRequest = $root.api.TriggerSyncRequest.decode(reader, reader.uint32());
                    break;
                case 62:
                    message.triggerRenderRequest = $root.api.TriggerRenderRequest.decode(reader, reader.uint32());
                    break;
                case 63:
                    message.renderStatusRequest = $root.api.RenderStatusRequest.decode(reader, reader.uint32());
                    break;
                case 64:
                    message.addIntegrationRequest = $root.api.AddIntegrationRequest.decode(reader, reader.uint32());
                    break;
                case 65:
                    message.deleteIntegrationRequest = $root.api.DeleteIntegrationRequest.decode(reader, reader.uint32());
                    break;
                case 67:
                    message.updateIntegrationRequest = $root.api.UpdateIntegrationRequest.decode(reader, reader.uint32());
                    break;
                case 68:
                    message.manageCouponRequest = $root.api.ManageCouponRequest.decode(reader, reader.uint32());
                    break;
                case 69:
                    message.createCouponRequest = $root.api.CreateCouponRequest.decode(reader, reader.uint32());
                    break;
                case 70:
                    message.updateCouponRequest = $root.api.UpdateCouponRequest.decode(reader, reader.uint32());
                    break;
                case 71:
                    message.destroyCouponRequest = $root.api.DestroyCouponRequest.decode(reader, reader.uint32());
                    break;
                case 72:
                    message.updateServiceSubscriptionRequest = $root.api.UpdateServiceSubscriptionRequest.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIRequest} APIRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIRequest message.
         * @function verify
         * @memberof api.APIRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                if (!$util.isInteger(message.requestId))
                    return "requestId: integer expected";
            if (message.loginRequest != null && message.hasOwnProperty("loginRequest")) {
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.LoginRequest.verify(message.loginRequest);
                    if (error)
                        return "loginRequest." + error;
                }
            }
            if (message.sendValidationCodeRequest != null && message.hasOwnProperty("sendValidationCodeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.SendValidationCodeRequest.verify(message.sendValidationCodeRequest);
                    if (error)
                        return "sendValidationCodeRequest." + error;
                }
            }
            if (message.validateRequest != null && message.hasOwnProperty("validateRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.ValidateRequest.verify(message.validateRequest);
                    if (error)
                        return "validateRequest." + error;
                }
            }
            if (message.authenticateRequest != null && message.hasOwnProperty("authenticateRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AuthenticateRequest.verify(message.authenticateRequest);
                    if (error)
                        return "authenticateRequest." + error;
                }
            }
            if (message.lookupTinyCodeRequest != null && message.hasOwnProperty("lookupTinyCodeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.LookupTinyCodeRequest.verify(message.lookupTinyCodeRequest);
                    if (error)
                        return "lookupTinyCodeRequest." + error;
                }
            }
            if (message.findTopicsRequest != null && message.hasOwnProperty("findTopicsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.FindTopicsRequest.verify(message.findTopicsRequest);
                    if (error)
                        return "findTopicsRequest." + error;
                }
            }
            if (message.pendingReviewTopicsRequest != null && message.hasOwnProperty("pendingReviewTopicsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.PendingReviewTopicsRequest.verify(message.pendingReviewTopicsRequest);
                    if (error)
                        return "pendingReviewTopicsRequest." + error;
                }
            }
            if (message.getTopicRequest != null && message.hasOwnProperty("getTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetTopicRequest.verify(message.getTopicRequest);
                    if (error)
                        return "getTopicRequest." + error;
                }
            }
            if (message.createTopicRequest != null && message.hasOwnProperty("createTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateTopicRequest.verify(message.createTopicRequest);
                    if (error)
                        return "createTopicRequest." + error;
                }
            }
            if (message.updateTopicRequest != null && message.hasOwnProperty("updateTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateTopicRequest.verify(message.updateTopicRequest);
                    if (error)
                        return "updateTopicRequest." + error;
                }
            }
            if (message.deleteTopicRequest != null && message.hasOwnProperty("deleteTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteTopicRequest.verify(message.deleteTopicRequest);
                    if (error)
                        return "deleteTopicRequest." + error;
                }
            }
            if (message.downloadCalendarRequest != null && message.hasOwnProperty("downloadCalendarRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DownloadCalendarRequest.verify(message.downloadCalendarRequest);
                    if (error)
                        return "downloadCalendarRequest." + error;
                }
            }
            if (message.joinTopicRequest != null && message.hasOwnProperty("joinTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.JoinTopicRequest.verify(message.joinTopicRequest);
                    if (error)
                        return "joinTopicRequest." + error;
                }
            }
            if (message.leaveTopicRequest != null && message.hasOwnProperty("leaveTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.LeaveTopicRequest.verify(message.leaveTopicRequest);
                    if (error)
                        return "leaveTopicRequest." + error;
                }
            }
            if (message.waitlistTopicRequest != null && message.hasOwnProperty("waitlistTopicRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.WaitlistTopicRequest.verify(message.waitlistTopicRequest);
                    if (error)
                        return "waitlistTopicRequest." + error;
                }
            }
            if (message.addTopicReviewRequest != null && message.hasOwnProperty("addTopicReviewRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddTopicReviewRequest.verify(message.addTopicReviewRequest);
                    if (error)
                        return "addTopicReviewRequest." + error;
                }
            }
            if (message.updateTopicReviewRequest != null && message.hasOwnProperty("updateTopicReviewRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateTopicReviewRequest.verify(message.updateTopicReviewRequest);
                    if (error)
                        return "updateTopicReviewRequest." + error;
                }
            }
            if (message.setupPaymentRequest != null && message.hasOwnProperty("setupPaymentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.SetupPaymentRequest.verify(message.setupPaymentRequest);
                    if (error)
                        return "setupPaymentRequest." + error;
                }
            }
            if (message.updatePaymentRequest != null && message.hasOwnProperty("updatePaymentRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdatePaymentRequest.verify(message.updatePaymentRequest);
                    if (error)
                        return "updatePaymentRequest." + error;
                }
            }
            if (message.getRoomRequest != null && message.hasOwnProperty("getRoomRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetRoomRequest.verify(message.getRoomRequest);
                    if (error)
                        return "getRoomRequest." + error;
                }
            }
            if (message.createRoomRequest != null && message.hasOwnProperty("createRoomRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateRoomRequest.verify(message.createRoomRequest);
                    if (error)
                        return "createRoomRequest." + error;
                }
            }
            if (message.updateRoomRequest != null && message.hasOwnProperty("updateRoomRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateRoomRequest.verify(message.updateRoomRequest);
                    if (error)
                        return "updateRoomRequest." + error;
                }
            }
            if (message.deleteRoomRequest != null && message.hasOwnProperty("deleteRoomRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteRoomRequest.verify(message.deleteRoomRequest);
                    if (error)
                        return "deleteRoomRequest." + error;
                }
            }
            if (message.addMemberRequest != null && message.hasOwnProperty("addMemberRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddMemberRequest.verify(message.addMemberRequest);
                    if (error)
                        return "addMemberRequest." + error;
                }
            }
            if (message.updateMemberRequest != null && message.hasOwnProperty("updateMemberRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateMemberRequest.verify(message.updateMemberRequest);
                    if (error)
                        return "updateMemberRequest." + error;
                }
            }
            if (message.deleteMemberRequest != null && message.hasOwnProperty("deleteMemberRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteMemberRequest.verify(message.deleteMemberRequest);
                    if (error)
                        return "deleteMemberRequest." + error;
                }
            }
            if (message.deleteMembersRequest != null && message.hasOwnProperty("deleteMembersRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteMembersRequest.verify(message.deleteMembersRequest);
                    if (error)
                        return "deleteMembersRequest." + error;
                }
            }
            if (message.answerMemberRequest != null && message.hasOwnProperty("answerMemberRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AnswerMemberRequest.verify(message.answerMemberRequest);
                    if (error)
                        return "answerMemberRequest." + error;
                }
            }
            if (message.getScheduleSetRequest != null && message.hasOwnProperty("getScheduleSetRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetScheduleSetRequest.verify(message.getScheduleSetRequest);
                    if (error)
                        return "getScheduleSetRequest." + error;
                }
            }
            if (message.createScheduleSetRequest != null && message.hasOwnProperty("createScheduleSetRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateScheduleSetRequest.verify(message.createScheduleSetRequest);
                    if (error)
                        return "createScheduleSetRequest." + error;
                }
            }
            if (message.updateScheduleSetRequest != null && message.hasOwnProperty("updateScheduleSetRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateScheduleSetRequest.verify(message.updateScheduleSetRequest);
                    if (error)
                        return "updateScheduleSetRequest." + error;
                }
            }
            if (message.deleteScheduleSetRequest != null && message.hasOwnProperty("deleteScheduleSetRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteScheduleSetRequest.verify(message.deleteScheduleSetRequest);
                    if (error)
                        return "deleteScheduleSetRequest." + error;
                }
            }
            if (message.getScheduleRequest != null && message.hasOwnProperty("getScheduleRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetScheduleRequest.verify(message.getScheduleRequest);
                    if (error)
                        return "getScheduleRequest." + error;
                }
            }
            if (message.addScheduleRequest != null && message.hasOwnProperty("addScheduleRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddScheduleRequest.verify(message.addScheduleRequest);
                    if (error)
                        return "addScheduleRequest." + error;
                }
            }
            if (message.updateScheduleRequest != null && message.hasOwnProperty("updateScheduleRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateScheduleRequest.verify(message.updateScheduleRequest);
                    if (error)
                        return "updateScheduleRequest." + error;
                }
            }
            if (message.truncateScheduleRequest != null && message.hasOwnProperty("truncateScheduleRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.TruncateScheduleRequest.verify(message.truncateScheduleRequest);
                    if (error)
                        return "truncateScheduleRequest." + error;
                }
            }
            if (message.deleteScheduleRequest != null && message.hasOwnProperty("deleteScheduleRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteScheduleRequest.verify(message.deleteScheduleRequest);
                    if (error)
                        return "deleteScheduleRequest." + error;
                }
            }
            if (message.getTimeRequest != null && message.hasOwnProperty("getTimeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetTimeRequest.verify(message.getTimeRequest);
                    if (error)
                        return "getTimeRequest." + error;
                }
            }
            if (message.updateTimeRequest != null && message.hasOwnProperty("updateTimeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateTimeRequest.verify(message.updateTimeRequest);
                    if (error)
                        return "updateTimeRequest." + error;
                }
            }
            if (message.deleteTimeRequest != null && message.hasOwnProperty("deleteTimeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteTimeRequest.verify(message.deleteTimeRequest);
                    if (error)
                        return "deleteTimeRequest." + error;
                }
            }
            if (message.addTimeRequest != null && message.hasOwnProperty("addTimeRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddTimeRequest.verify(message.addTimeRequest);
                    if (error)
                        return "addTimeRequest." + error;
                }
            }
            if (message.getUserRequest != null && message.hasOwnProperty("getUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetUserRequest.verify(message.getUserRequest);
                    if (error)
                        return "getUserRequest." + error;
                }
            }
            if (message.createUserRequest != null && message.hasOwnProperty("createUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateUserRequest.verify(message.createUserRequest);
                    if (error)
                        return "createUserRequest." + error;
                }
            }
            if (message.updateUserRequest != null && message.hasOwnProperty("updateUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateUserRequest.verify(message.updateUserRequest);
                    if (error)
                        return "updateUserRequest." + error;
                }
            }
            if (message.deleteUserRequest != null && message.hasOwnProperty("deleteUserRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteUserRequest.verify(message.deleteUserRequest);
                    if (error)
                        return "deleteUserRequest." + error;
                }
            }
            if (message.getUserEventsRequest != null && message.hasOwnProperty("getUserEventsRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetUserEventsRequest.verify(message.getUserEventsRequest);
                    if (error)
                        return "getUserEventsRequest." + error;
                }
            }
            if (message.getGroupRequest != null && message.hasOwnProperty("getGroupRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetGroupRequest.verify(message.getGroupRequest);
                    if (error)
                        return "getGroupRequest." + error;
                }
            }
            if (message.createGroupRequest != null && message.hasOwnProperty("createGroupRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateGroupRequest.verify(message.createGroupRequest);
                    if (error)
                        return "createGroupRequest." + error;
                }
            }
            if (message.updateGroupRequest != null && message.hasOwnProperty("updateGroupRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateGroupRequest.verify(message.updateGroupRequest);
                    if (error)
                        return "updateGroupRequest." + error;
                }
            }
            if (message.deleteGroupRequest != null && message.hasOwnProperty("deleteGroupRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteGroupRequest.verify(message.deleteGroupRequest);
                    if (error)
                        return "deleteGroupRequest." + error;
                }
            }
            if (message.sendMessageRequest != null && message.hasOwnProperty("sendMessageRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.SendMessageRequest.verify(message.sendMessageRequest);
                    if (error)
                        return "sendMessageRequest." + error;
                }
            }
            if (message.getConferenceConnectInfoRequest != null && message.hasOwnProperty("getConferenceConnectInfoRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.GetConferenceConnectInfoRequest.verify(message.getConferenceConnectInfoRequest);
                    if (error)
                        return "getConferenceConnectInfoRequest." + error;
                }
            }
            if (message.addAssetRequest != null && message.hasOwnProperty("addAssetRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddAssetRequest.verify(message.addAssetRequest);
                    if (error)
                        return "addAssetRequest." + error;
                }
            }
            if (message.deleteAssetRequest != null && message.hasOwnProperty("deleteAssetRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteAssetRequest.verify(message.deleteAssetRequest);
                    if (error)
                        return "deleteAssetRequest." + error;
                }
            }
            if (message.createSyncRequest != null && message.hasOwnProperty("createSyncRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateSyncRequest.verify(message.createSyncRequest);
                    if (error)
                        return "createSyncRequest." + error;
                }
            }
            if (message.updateSyncRequest != null && message.hasOwnProperty("updateSyncRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateSyncRequest.verify(message.updateSyncRequest);
                    if (error)
                        return "updateSyncRequest." + error;
                }
            }
            if (message.deleteSyncRequest != null && message.hasOwnProperty("deleteSyncRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteSyncRequest.verify(message.deleteSyncRequest);
                    if (error)
                        return "deleteSyncRequest." + error;
                }
            }
            if (message.triggerSyncRequest != null && message.hasOwnProperty("triggerSyncRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.TriggerSyncRequest.verify(message.triggerSyncRequest);
                    if (error)
                        return "triggerSyncRequest." + error;
                }
            }
            if (message.triggerRenderRequest != null && message.hasOwnProperty("triggerRenderRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.TriggerRenderRequest.verify(message.triggerRenderRequest);
                    if (error)
                        return "triggerRenderRequest." + error;
                }
            }
            if (message.renderStatusRequest != null && message.hasOwnProperty("renderStatusRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.RenderStatusRequest.verify(message.renderStatusRequest);
                    if (error)
                        return "renderStatusRequest." + error;
                }
            }
            if (message.addIntegrationRequest != null && message.hasOwnProperty("addIntegrationRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.AddIntegrationRequest.verify(message.addIntegrationRequest);
                    if (error)
                        return "addIntegrationRequest." + error;
                }
            }
            if (message.deleteIntegrationRequest != null && message.hasOwnProperty("deleteIntegrationRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DeleteIntegrationRequest.verify(message.deleteIntegrationRequest);
                    if (error)
                        return "deleteIntegrationRequest." + error;
                }
            }
            if (message.updateIntegrationRequest != null && message.hasOwnProperty("updateIntegrationRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateIntegrationRequest.verify(message.updateIntegrationRequest);
                    if (error)
                        return "updateIntegrationRequest." + error;
                }
            }
            if (message.manageCouponRequest != null && message.hasOwnProperty("manageCouponRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.ManageCouponRequest.verify(message.manageCouponRequest);
                    if (error)
                        return "manageCouponRequest." + error;
                }
            }
            if (message.createCouponRequest != null && message.hasOwnProperty("createCouponRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.CreateCouponRequest.verify(message.createCouponRequest);
                    if (error)
                        return "createCouponRequest." + error;
                }
            }
            if (message.updateCouponRequest != null && message.hasOwnProperty("updateCouponRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateCouponRequest.verify(message.updateCouponRequest);
                    if (error)
                        return "updateCouponRequest." + error;
                }
            }
            if (message.destroyCouponRequest != null && message.hasOwnProperty("destroyCouponRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.DestroyCouponRequest.verify(message.destroyCouponRequest);
                    if (error)
                        return "destroyCouponRequest." + error;
                }
            }
            if (message.updateServiceSubscriptionRequest != null && message.hasOwnProperty("updateServiceSubscriptionRequest")) {
                if (properties.apiRequestInfo === 1)
                    return "apiRequestInfo: multiple values";
                properties.apiRequestInfo = 1;
                {
                    let error = $root.api.UpdateServiceSubscriptionRequest.verify(message.updateServiceSubscriptionRequest);
                    if (error)
                        return "updateServiceSubscriptionRequest." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIRequest} APIRequest
         */
        APIRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIRequest)
                return object;
            let message = new $root.api.APIRequest();
            if (object.requestId != null)
                message.requestId = object.requestId | 0;
            if (object.loginRequest != null) {
                if (typeof object.loginRequest !== "object")
                    throw TypeError(".api.APIRequest.loginRequest: object expected");
                message.loginRequest = $root.api.LoginRequest.fromObject(object.loginRequest);
            }
            if (object.sendValidationCodeRequest != null) {
                if (typeof object.sendValidationCodeRequest !== "object")
                    throw TypeError(".api.APIRequest.sendValidationCodeRequest: object expected");
                message.sendValidationCodeRequest = $root.api.SendValidationCodeRequest.fromObject(object.sendValidationCodeRequest);
            }
            if (object.validateRequest != null) {
                if (typeof object.validateRequest !== "object")
                    throw TypeError(".api.APIRequest.validateRequest: object expected");
                message.validateRequest = $root.api.ValidateRequest.fromObject(object.validateRequest);
            }
            if (object.authenticateRequest != null) {
                if (typeof object.authenticateRequest !== "object")
                    throw TypeError(".api.APIRequest.authenticateRequest: object expected");
                message.authenticateRequest = $root.api.AuthenticateRequest.fromObject(object.authenticateRequest);
            }
            if (object.lookupTinyCodeRequest != null) {
                if (typeof object.lookupTinyCodeRequest !== "object")
                    throw TypeError(".api.APIRequest.lookupTinyCodeRequest: object expected");
                message.lookupTinyCodeRequest = $root.api.LookupTinyCodeRequest.fromObject(object.lookupTinyCodeRequest);
            }
            if (object.findTopicsRequest != null) {
                if (typeof object.findTopicsRequest !== "object")
                    throw TypeError(".api.APIRequest.findTopicsRequest: object expected");
                message.findTopicsRequest = $root.api.FindTopicsRequest.fromObject(object.findTopicsRequest);
            }
            if (object.pendingReviewTopicsRequest != null) {
                if (typeof object.pendingReviewTopicsRequest !== "object")
                    throw TypeError(".api.APIRequest.pendingReviewTopicsRequest: object expected");
                message.pendingReviewTopicsRequest = $root.api.PendingReviewTopicsRequest.fromObject(object.pendingReviewTopicsRequest);
            }
            if (object.getTopicRequest != null) {
                if (typeof object.getTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.getTopicRequest: object expected");
                message.getTopicRequest = $root.api.GetTopicRequest.fromObject(object.getTopicRequest);
            }
            if (object.createTopicRequest != null) {
                if (typeof object.createTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.createTopicRequest: object expected");
                message.createTopicRequest = $root.api.CreateTopicRequest.fromObject(object.createTopicRequest);
            }
            if (object.updateTopicRequest != null) {
                if (typeof object.updateTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.updateTopicRequest: object expected");
                message.updateTopicRequest = $root.api.UpdateTopicRequest.fromObject(object.updateTopicRequest);
            }
            if (object.deleteTopicRequest != null) {
                if (typeof object.deleteTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteTopicRequest: object expected");
                message.deleteTopicRequest = $root.api.DeleteTopicRequest.fromObject(object.deleteTopicRequest);
            }
            if (object.downloadCalendarRequest != null) {
                if (typeof object.downloadCalendarRequest !== "object")
                    throw TypeError(".api.APIRequest.downloadCalendarRequest: object expected");
                message.downloadCalendarRequest = $root.api.DownloadCalendarRequest.fromObject(object.downloadCalendarRequest);
            }
            if (object.joinTopicRequest != null) {
                if (typeof object.joinTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.joinTopicRequest: object expected");
                message.joinTopicRequest = $root.api.JoinTopicRequest.fromObject(object.joinTopicRequest);
            }
            if (object.leaveTopicRequest != null) {
                if (typeof object.leaveTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.leaveTopicRequest: object expected");
                message.leaveTopicRequest = $root.api.LeaveTopicRequest.fromObject(object.leaveTopicRequest);
            }
            if (object.waitlistTopicRequest != null) {
                if (typeof object.waitlistTopicRequest !== "object")
                    throw TypeError(".api.APIRequest.waitlistTopicRequest: object expected");
                message.waitlistTopicRequest = $root.api.WaitlistTopicRequest.fromObject(object.waitlistTopicRequest);
            }
            if (object.addTopicReviewRequest != null) {
                if (typeof object.addTopicReviewRequest !== "object")
                    throw TypeError(".api.APIRequest.addTopicReviewRequest: object expected");
                message.addTopicReviewRequest = $root.api.AddTopicReviewRequest.fromObject(object.addTopicReviewRequest);
            }
            if (object.updateTopicReviewRequest != null) {
                if (typeof object.updateTopicReviewRequest !== "object")
                    throw TypeError(".api.APIRequest.updateTopicReviewRequest: object expected");
                message.updateTopicReviewRequest = $root.api.UpdateTopicReviewRequest.fromObject(object.updateTopicReviewRequest);
            }
            if (object.setupPaymentRequest != null) {
                if (typeof object.setupPaymentRequest !== "object")
                    throw TypeError(".api.APIRequest.setupPaymentRequest: object expected");
                message.setupPaymentRequest = $root.api.SetupPaymentRequest.fromObject(object.setupPaymentRequest);
            }
            if (object.updatePaymentRequest != null) {
                if (typeof object.updatePaymentRequest !== "object")
                    throw TypeError(".api.APIRequest.updatePaymentRequest: object expected");
                message.updatePaymentRequest = $root.api.UpdatePaymentRequest.fromObject(object.updatePaymentRequest);
            }
            if (object.getRoomRequest != null) {
                if (typeof object.getRoomRequest !== "object")
                    throw TypeError(".api.APIRequest.getRoomRequest: object expected");
                message.getRoomRequest = $root.api.GetRoomRequest.fromObject(object.getRoomRequest);
            }
            if (object.createRoomRequest != null) {
                if (typeof object.createRoomRequest !== "object")
                    throw TypeError(".api.APIRequest.createRoomRequest: object expected");
                message.createRoomRequest = $root.api.CreateRoomRequest.fromObject(object.createRoomRequest);
            }
            if (object.updateRoomRequest != null) {
                if (typeof object.updateRoomRequest !== "object")
                    throw TypeError(".api.APIRequest.updateRoomRequest: object expected");
                message.updateRoomRequest = $root.api.UpdateRoomRequest.fromObject(object.updateRoomRequest);
            }
            if (object.deleteRoomRequest != null) {
                if (typeof object.deleteRoomRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteRoomRequest: object expected");
                message.deleteRoomRequest = $root.api.DeleteRoomRequest.fromObject(object.deleteRoomRequest);
            }
            if (object.addMemberRequest != null) {
                if (typeof object.addMemberRequest !== "object")
                    throw TypeError(".api.APIRequest.addMemberRequest: object expected");
                message.addMemberRequest = $root.api.AddMemberRequest.fromObject(object.addMemberRequest);
            }
            if (object.updateMemberRequest != null) {
                if (typeof object.updateMemberRequest !== "object")
                    throw TypeError(".api.APIRequest.updateMemberRequest: object expected");
                message.updateMemberRequest = $root.api.UpdateMemberRequest.fromObject(object.updateMemberRequest);
            }
            if (object.deleteMemberRequest != null) {
                if (typeof object.deleteMemberRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteMemberRequest: object expected");
                message.deleteMemberRequest = $root.api.DeleteMemberRequest.fromObject(object.deleteMemberRequest);
            }
            if (object.deleteMembersRequest != null) {
                if (typeof object.deleteMembersRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteMembersRequest: object expected");
                message.deleteMembersRequest = $root.api.DeleteMembersRequest.fromObject(object.deleteMembersRequest);
            }
            if (object.answerMemberRequest != null) {
                if (typeof object.answerMemberRequest !== "object")
                    throw TypeError(".api.APIRequest.answerMemberRequest: object expected");
                message.answerMemberRequest = $root.api.AnswerMemberRequest.fromObject(object.answerMemberRequest);
            }
            if (object.getScheduleSetRequest != null) {
                if (typeof object.getScheduleSetRequest !== "object")
                    throw TypeError(".api.APIRequest.getScheduleSetRequest: object expected");
                message.getScheduleSetRequest = $root.api.GetScheduleSetRequest.fromObject(object.getScheduleSetRequest);
            }
            if (object.createScheduleSetRequest != null) {
                if (typeof object.createScheduleSetRequest !== "object")
                    throw TypeError(".api.APIRequest.createScheduleSetRequest: object expected");
                message.createScheduleSetRequest = $root.api.CreateScheduleSetRequest.fromObject(object.createScheduleSetRequest);
            }
            if (object.updateScheduleSetRequest != null) {
                if (typeof object.updateScheduleSetRequest !== "object")
                    throw TypeError(".api.APIRequest.updateScheduleSetRequest: object expected");
                message.updateScheduleSetRequest = $root.api.UpdateScheduleSetRequest.fromObject(object.updateScheduleSetRequest);
            }
            if (object.deleteScheduleSetRequest != null) {
                if (typeof object.deleteScheduleSetRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteScheduleSetRequest: object expected");
                message.deleteScheduleSetRequest = $root.api.DeleteScheduleSetRequest.fromObject(object.deleteScheduleSetRequest);
            }
            if (object.getScheduleRequest != null) {
                if (typeof object.getScheduleRequest !== "object")
                    throw TypeError(".api.APIRequest.getScheduleRequest: object expected");
                message.getScheduleRequest = $root.api.GetScheduleRequest.fromObject(object.getScheduleRequest);
            }
            if (object.addScheduleRequest != null) {
                if (typeof object.addScheduleRequest !== "object")
                    throw TypeError(".api.APIRequest.addScheduleRequest: object expected");
                message.addScheduleRequest = $root.api.AddScheduleRequest.fromObject(object.addScheduleRequest);
            }
            if (object.updateScheduleRequest != null) {
                if (typeof object.updateScheduleRequest !== "object")
                    throw TypeError(".api.APIRequest.updateScheduleRequest: object expected");
                message.updateScheduleRequest = $root.api.UpdateScheduleRequest.fromObject(object.updateScheduleRequest);
            }
            if (object.truncateScheduleRequest != null) {
                if (typeof object.truncateScheduleRequest !== "object")
                    throw TypeError(".api.APIRequest.truncateScheduleRequest: object expected");
                message.truncateScheduleRequest = $root.api.TruncateScheduleRequest.fromObject(object.truncateScheduleRequest);
            }
            if (object.deleteScheduleRequest != null) {
                if (typeof object.deleteScheduleRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteScheduleRequest: object expected");
                message.deleteScheduleRequest = $root.api.DeleteScheduleRequest.fromObject(object.deleteScheduleRequest);
            }
            if (object.getTimeRequest != null) {
                if (typeof object.getTimeRequest !== "object")
                    throw TypeError(".api.APIRequest.getTimeRequest: object expected");
                message.getTimeRequest = $root.api.GetTimeRequest.fromObject(object.getTimeRequest);
            }
            if (object.updateTimeRequest != null) {
                if (typeof object.updateTimeRequest !== "object")
                    throw TypeError(".api.APIRequest.updateTimeRequest: object expected");
                message.updateTimeRequest = $root.api.UpdateTimeRequest.fromObject(object.updateTimeRequest);
            }
            if (object.deleteTimeRequest != null) {
                if (typeof object.deleteTimeRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteTimeRequest: object expected");
                message.deleteTimeRequest = $root.api.DeleteTimeRequest.fromObject(object.deleteTimeRequest);
            }
            if (object.addTimeRequest != null) {
                if (typeof object.addTimeRequest !== "object")
                    throw TypeError(".api.APIRequest.addTimeRequest: object expected");
                message.addTimeRequest = $root.api.AddTimeRequest.fromObject(object.addTimeRequest);
            }
            if (object.getUserRequest != null) {
                if (typeof object.getUserRequest !== "object")
                    throw TypeError(".api.APIRequest.getUserRequest: object expected");
                message.getUserRequest = $root.api.GetUserRequest.fromObject(object.getUserRequest);
            }
            if (object.createUserRequest != null) {
                if (typeof object.createUserRequest !== "object")
                    throw TypeError(".api.APIRequest.createUserRequest: object expected");
                message.createUserRequest = $root.api.CreateUserRequest.fromObject(object.createUserRequest);
            }
            if (object.updateUserRequest != null) {
                if (typeof object.updateUserRequest !== "object")
                    throw TypeError(".api.APIRequest.updateUserRequest: object expected");
                message.updateUserRequest = $root.api.UpdateUserRequest.fromObject(object.updateUserRequest);
            }
            if (object.deleteUserRequest != null) {
                if (typeof object.deleteUserRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteUserRequest: object expected");
                message.deleteUserRequest = $root.api.DeleteUserRequest.fromObject(object.deleteUserRequest);
            }
            if (object.getUserEventsRequest != null) {
                if (typeof object.getUserEventsRequest !== "object")
                    throw TypeError(".api.APIRequest.getUserEventsRequest: object expected");
                message.getUserEventsRequest = $root.api.GetUserEventsRequest.fromObject(object.getUserEventsRequest);
            }
            if (object.getGroupRequest != null) {
                if (typeof object.getGroupRequest !== "object")
                    throw TypeError(".api.APIRequest.getGroupRequest: object expected");
                message.getGroupRequest = $root.api.GetGroupRequest.fromObject(object.getGroupRequest);
            }
            if (object.createGroupRequest != null) {
                if (typeof object.createGroupRequest !== "object")
                    throw TypeError(".api.APIRequest.createGroupRequest: object expected");
                message.createGroupRequest = $root.api.CreateGroupRequest.fromObject(object.createGroupRequest);
            }
            if (object.updateGroupRequest != null) {
                if (typeof object.updateGroupRequest !== "object")
                    throw TypeError(".api.APIRequest.updateGroupRequest: object expected");
                message.updateGroupRequest = $root.api.UpdateGroupRequest.fromObject(object.updateGroupRequest);
            }
            if (object.deleteGroupRequest != null) {
                if (typeof object.deleteGroupRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteGroupRequest: object expected");
                message.deleteGroupRequest = $root.api.DeleteGroupRequest.fromObject(object.deleteGroupRequest);
            }
            if (object.sendMessageRequest != null) {
                if (typeof object.sendMessageRequest !== "object")
                    throw TypeError(".api.APIRequest.sendMessageRequest: object expected");
                message.sendMessageRequest = $root.api.SendMessageRequest.fromObject(object.sendMessageRequest);
            }
            if (object.getConferenceConnectInfoRequest != null) {
                if (typeof object.getConferenceConnectInfoRequest !== "object")
                    throw TypeError(".api.APIRequest.getConferenceConnectInfoRequest: object expected");
                message.getConferenceConnectInfoRequest = $root.api.GetConferenceConnectInfoRequest.fromObject(object.getConferenceConnectInfoRequest);
            }
            if (object.addAssetRequest != null) {
                if (typeof object.addAssetRequest !== "object")
                    throw TypeError(".api.APIRequest.addAssetRequest: object expected");
                message.addAssetRequest = $root.api.AddAssetRequest.fromObject(object.addAssetRequest);
            }
            if (object.deleteAssetRequest != null) {
                if (typeof object.deleteAssetRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteAssetRequest: object expected");
                message.deleteAssetRequest = $root.api.DeleteAssetRequest.fromObject(object.deleteAssetRequest);
            }
            if (object.createSyncRequest != null) {
                if (typeof object.createSyncRequest !== "object")
                    throw TypeError(".api.APIRequest.createSyncRequest: object expected");
                message.createSyncRequest = $root.api.CreateSyncRequest.fromObject(object.createSyncRequest);
            }
            if (object.updateSyncRequest != null) {
                if (typeof object.updateSyncRequest !== "object")
                    throw TypeError(".api.APIRequest.updateSyncRequest: object expected");
                message.updateSyncRequest = $root.api.UpdateSyncRequest.fromObject(object.updateSyncRequest);
            }
            if (object.deleteSyncRequest != null) {
                if (typeof object.deleteSyncRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteSyncRequest: object expected");
                message.deleteSyncRequest = $root.api.DeleteSyncRequest.fromObject(object.deleteSyncRequest);
            }
            if (object.triggerSyncRequest != null) {
                if (typeof object.triggerSyncRequest !== "object")
                    throw TypeError(".api.APIRequest.triggerSyncRequest: object expected");
                message.triggerSyncRequest = $root.api.TriggerSyncRequest.fromObject(object.triggerSyncRequest);
            }
            if (object.triggerRenderRequest != null) {
                if (typeof object.triggerRenderRequest !== "object")
                    throw TypeError(".api.APIRequest.triggerRenderRequest: object expected");
                message.triggerRenderRequest = $root.api.TriggerRenderRequest.fromObject(object.triggerRenderRequest);
            }
            if (object.renderStatusRequest != null) {
                if (typeof object.renderStatusRequest !== "object")
                    throw TypeError(".api.APIRequest.renderStatusRequest: object expected");
                message.renderStatusRequest = $root.api.RenderStatusRequest.fromObject(object.renderStatusRequest);
            }
            if (object.addIntegrationRequest != null) {
                if (typeof object.addIntegrationRequest !== "object")
                    throw TypeError(".api.APIRequest.addIntegrationRequest: object expected");
                message.addIntegrationRequest = $root.api.AddIntegrationRequest.fromObject(object.addIntegrationRequest);
            }
            if (object.deleteIntegrationRequest != null) {
                if (typeof object.deleteIntegrationRequest !== "object")
                    throw TypeError(".api.APIRequest.deleteIntegrationRequest: object expected");
                message.deleteIntegrationRequest = $root.api.DeleteIntegrationRequest.fromObject(object.deleteIntegrationRequest);
            }
            if (object.updateIntegrationRequest != null) {
                if (typeof object.updateIntegrationRequest !== "object")
                    throw TypeError(".api.APIRequest.updateIntegrationRequest: object expected");
                message.updateIntegrationRequest = $root.api.UpdateIntegrationRequest.fromObject(object.updateIntegrationRequest);
            }
            if (object.manageCouponRequest != null) {
                if (typeof object.manageCouponRequest !== "object")
                    throw TypeError(".api.APIRequest.manageCouponRequest: object expected");
                message.manageCouponRequest = $root.api.ManageCouponRequest.fromObject(object.manageCouponRequest);
            }
            if (object.createCouponRequest != null) {
                if (typeof object.createCouponRequest !== "object")
                    throw TypeError(".api.APIRequest.createCouponRequest: object expected");
                message.createCouponRequest = $root.api.CreateCouponRequest.fromObject(object.createCouponRequest);
            }
            if (object.updateCouponRequest != null) {
                if (typeof object.updateCouponRequest !== "object")
                    throw TypeError(".api.APIRequest.updateCouponRequest: object expected");
                message.updateCouponRequest = $root.api.UpdateCouponRequest.fromObject(object.updateCouponRequest);
            }
            if (object.destroyCouponRequest != null) {
                if (typeof object.destroyCouponRequest !== "object")
                    throw TypeError(".api.APIRequest.destroyCouponRequest: object expected");
                message.destroyCouponRequest = $root.api.DestroyCouponRequest.fromObject(object.destroyCouponRequest);
            }
            if (object.updateServiceSubscriptionRequest != null) {
                if (typeof object.updateServiceSubscriptionRequest !== "object")
                    throw TypeError(".api.APIRequest.updateServiceSubscriptionRequest: object expected");
                message.updateServiceSubscriptionRequest = $root.api.UpdateServiceSubscriptionRequest.fromObject(object.updateServiceSubscriptionRequest);
            }
            return message;
        };

        /**
         * Creates a plain object from a APIRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIRequest
         * @static
         * @param {api.APIRequest} message APIRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.requestId = 0;
            if (message.requestId != null && message.hasOwnProperty("requestId"))
                object.requestId = message.requestId;
            if (message.loginRequest != null && message.hasOwnProperty("loginRequest")) {
                object.loginRequest = $root.api.LoginRequest.toObject(message.loginRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "loginRequest";
            }
            if (message.authenticateRequest != null && message.hasOwnProperty("authenticateRequest")) {
                object.authenticateRequest = $root.api.AuthenticateRequest.toObject(message.authenticateRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "authenticateRequest";
            }
            if (message.createTopicRequest != null && message.hasOwnProperty("createTopicRequest")) {
                object.createTopicRequest = $root.api.CreateTopicRequest.toObject(message.createTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createTopicRequest";
            }
            if (message.updateTopicRequest != null && message.hasOwnProperty("updateTopicRequest")) {
                object.updateTopicRequest = $root.api.UpdateTopicRequest.toObject(message.updateTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateTopicRequest";
            }
            if (message.deleteTopicRequest != null && message.hasOwnProperty("deleteTopicRequest")) {
                object.deleteTopicRequest = $root.api.DeleteTopicRequest.toObject(message.deleteTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteTopicRequest";
            }
            if (message.createRoomRequest != null && message.hasOwnProperty("createRoomRequest")) {
                object.createRoomRequest = $root.api.CreateRoomRequest.toObject(message.createRoomRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createRoomRequest";
            }
            if (message.updateRoomRequest != null && message.hasOwnProperty("updateRoomRequest")) {
                object.updateRoomRequest = $root.api.UpdateRoomRequest.toObject(message.updateRoomRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateRoomRequest";
            }
            if (message.deleteRoomRequest != null && message.hasOwnProperty("deleteRoomRequest")) {
                object.deleteRoomRequest = $root.api.DeleteRoomRequest.toObject(message.deleteRoomRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteRoomRequest";
            }
            if (message.addMemberRequest != null && message.hasOwnProperty("addMemberRequest")) {
                object.addMemberRequest = $root.api.AddMemberRequest.toObject(message.addMemberRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addMemberRequest";
            }
            if (message.updateMemberRequest != null && message.hasOwnProperty("updateMemberRequest")) {
                object.updateMemberRequest = $root.api.UpdateMemberRequest.toObject(message.updateMemberRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateMemberRequest";
            }
            if (message.deleteMemberRequest != null && message.hasOwnProperty("deleteMemberRequest")) {
                object.deleteMemberRequest = $root.api.DeleteMemberRequest.toObject(message.deleteMemberRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteMemberRequest";
            }
            if (message.answerMemberRequest != null && message.hasOwnProperty("answerMemberRequest")) {
                object.answerMemberRequest = $root.api.AnswerMemberRequest.toObject(message.answerMemberRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "answerMemberRequest";
            }
            if (message.createScheduleSetRequest != null && message.hasOwnProperty("createScheduleSetRequest")) {
                object.createScheduleSetRequest = $root.api.CreateScheduleSetRequest.toObject(message.createScheduleSetRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createScheduleSetRequest";
            }
            if (message.updateScheduleSetRequest != null && message.hasOwnProperty("updateScheduleSetRequest")) {
                object.updateScheduleSetRequest = $root.api.UpdateScheduleSetRequest.toObject(message.updateScheduleSetRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateScheduleSetRequest";
            }
            if (message.deleteScheduleSetRequest != null && message.hasOwnProperty("deleteScheduleSetRequest")) {
                object.deleteScheduleSetRequest = $root.api.DeleteScheduleSetRequest.toObject(message.deleteScheduleSetRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteScheduleSetRequest";
            }
            if (message.createUserRequest != null && message.hasOwnProperty("createUserRequest")) {
                object.createUserRequest = $root.api.CreateUserRequest.toObject(message.createUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createUserRequest";
            }
            if (message.updateUserRequest != null && message.hasOwnProperty("updateUserRequest")) {
                object.updateUserRequest = $root.api.UpdateUserRequest.toObject(message.updateUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateUserRequest";
            }
            if (message.deleteUserRequest != null && message.hasOwnProperty("deleteUserRequest")) {
                object.deleteUserRequest = $root.api.DeleteUserRequest.toObject(message.deleteUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteUserRequest";
            }
            if (message.createGroupRequest != null && message.hasOwnProperty("createGroupRequest")) {
                object.createGroupRequest = $root.api.CreateGroupRequest.toObject(message.createGroupRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createGroupRequest";
            }
            if (message.updateGroupRequest != null && message.hasOwnProperty("updateGroupRequest")) {
                object.updateGroupRequest = $root.api.UpdateGroupRequest.toObject(message.updateGroupRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateGroupRequest";
            }
            if (message.deleteGroupRequest != null && message.hasOwnProperty("deleteGroupRequest")) {
                object.deleteGroupRequest = $root.api.DeleteGroupRequest.toObject(message.deleteGroupRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteGroupRequest";
            }
            if (message.sendMessageRequest != null && message.hasOwnProperty("sendMessageRequest")) {
                object.sendMessageRequest = $root.api.SendMessageRequest.toObject(message.sendMessageRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "sendMessageRequest";
            }
            if (message.getRoomRequest != null && message.hasOwnProperty("getRoomRequest")) {
                object.getRoomRequest = $root.api.GetRoomRequest.toObject(message.getRoomRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getRoomRequest";
            }
            if (message.getConferenceConnectInfoRequest != null && message.hasOwnProperty("getConferenceConnectInfoRequest")) {
                object.getConferenceConnectInfoRequest = $root.api.GetConferenceConnectInfoRequest.toObject(message.getConferenceConnectInfoRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getConferenceConnectInfoRequest";
            }
            if (message.getTopicRequest != null && message.hasOwnProperty("getTopicRequest")) {
                object.getTopicRequest = $root.api.GetTopicRequest.toObject(message.getTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getTopicRequest";
            }
            if (message.getUserRequest != null && message.hasOwnProperty("getUserRequest")) {
                object.getUserRequest = $root.api.GetUserRequest.toObject(message.getUserRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getUserRequest";
            }
            if (message.getGroupRequest != null && message.hasOwnProperty("getGroupRequest")) {
                object.getGroupRequest = $root.api.GetGroupRequest.toObject(message.getGroupRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getGroupRequest";
            }
            if (message.getUserEventsRequest != null && message.hasOwnProperty("getUserEventsRequest")) {
                object.getUserEventsRequest = $root.api.GetUserEventsRequest.toObject(message.getUserEventsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getUserEventsRequest";
            }
            if (message.findTopicsRequest != null && message.hasOwnProperty("findTopicsRequest")) {
                object.findTopicsRequest = $root.api.FindTopicsRequest.toObject(message.findTopicsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "findTopicsRequest";
            }
            if (message.addTopicReviewRequest != null && message.hasOwnProperty("addTopicReviewRequest")) {
                object.addTopicReviewRequest = $root.api.AddTopicReviewRequest.toObject(message.addTopicReviewRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addTopicReviewRequest";
            }
            if (message.joinTopicRequest != null && message.hasOwnProperty("joinTopicRequest")) {
                object.joinTopicRequest = $root.api.JoinTopicRequest.toObject(message.joinTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "joinTopicRequest";
            }
            if (message.addAssetRequest != null && message.hasOwnProperty("addAssetRequest")) {
                object.addAssetRequest = $root.api.AddAssetRequest.toObject(message.addAssetRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addAssetRequest";
            }
            if (message.deleteAssetRequest != null && message.hasOwnProperty("deleteAssetRequest")) {
                object.deleteAssetRequest = $root.api.DeleteAssetRequest.toObject(message.deleteAssetRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteAssetRequest";
            }
            if (message.createSyncRequest != null && message.hasOwnProperty("createSyncRequest")) {
                object.createSyncRequest = $root.api.CreateSyncRequest.toObject(message.createSyncRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createSyncRequest";
            }
            if (message.updateSyncRequest != null && message.hasOwnProperty("updateSyncRequest")) {
                object.updateSyncRequest = $root.api.UpdateSyncRequest.toObject(message.updateSyncRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateSyncRequest";
            }
            if (message.deleteSyncRequest != null && message.hasOwnProperty("deleteSyncRequest")) {
                object.deleteSyncRequest = $root.api.DeleteSyncRequest.toObject(message.deleteSyncRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteSyncRequest";
            }
            if (message.triggerSyncRequest != null && message.hasOwnProperty("triggerSyncRequest")) {
                object.triggerSyncRequest = $root.api.TriggerSyncRequest.toObject(message.triggerSyncRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "triggerSyncRequest";
            }
            if (message.waitlistTopicRequest != null && message.hasOwnProperty("waitlistTopicRequest")) {
                object.waitlistTopicRequest = $root.api.WaitlistTopicRequest.toObject(message.waitlistTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "waitlistTopicRequest";
            }
            if (message.updateTopicReviewRequest != null && message.hasOwnProperty("updateTopicReviewRequest")) {
                object.updateTopicReviewRequest = $root.api.UpdateTopicReviewRequest.toObject(message.updateTopicReviewRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateTopicReviewRequest";
            }
            if (message.getScheduleSetRequest != null && message.hasOwnProperty("getScheduleSetRequest")) {
                object.getScheduleSetRequest = $root.api.GetScheduleSetRequest.toObject(message.getScheduleSetRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getScheduleSetRequest";
            }
            if (message.addScheduleRequest != null && message.hasOwnProperty("addScheduleRequest")) {
                object.addScheduleRequest = $root.api.AddScheduleRequest.toObject(message.addScheduleRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addScheduleRequest";
            }
            if (message.updateScheduleRequest != null && message.hasOwnProperty("updateScheduleRequest")) {
                object.updateScheduleRequest = $root.api.UpdateScheduleRequest.toObject(message.updateScheduleRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateScheduleRequest";
            }
            if (message.deleteScheduleRequest != null && message.hasOwnProperty("deleteScheduleRequest")) {
                object.deleteScheduleRequest = $root.api.DeleteScheduleRequest.toObject(message.deleteScheduleRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteScheduleRequest";
            }
            if (message.getScheduleRequest != null && message.hasOwnProperty("getScheduleRequest")) {
                object.getScheduleRequest = $root.api.GetScheduleRequest.toObject(message.getScheduleRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getScheduleRequest";
            }
            if (message.getTimeRequest != null && message.hasOwnProperty("getTimeRequest")) {
                object.getTimeRequest = $root.api.GetTimeRequest.toObject(message.getTimeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "getTimeRequest";
            }
            if (message.updateTimeRequest != null && message.hasOwnProperty("updateTimeRequest")) {
                object.updateTimeRequest = $root.api.UpdateTimeRequest.toObject(message.updateTimeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateTimeRequest";
            }
            if (message.validateRequest != null && message.hasOwnProperty("validateRequest")) {
                object.validateRequest = $root.api.ValidateRequest.toObject(message.validateRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "validateRequest";
            }
            if (message.pendingReviewTopicsRequest != null && message.hasOwnProperty("pendingReviewTopicsRequest")) {
                object.pendingReviewTopicsRequest = $root.api.PendingReviewTopicsRequest.toObject(message.pendingReviewTopicsRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "pendingReviewTopicsRequest";
            }
            if (message.deleteMembersRequest != null && message.hasOwnProperty("deleteMembersRequest")) {
                object.deleteMembersRequest = $root.api.DeleteMembersRequest.toObject(message.deleteMembersRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteMembersRequest";
            }
            if (message.lookupTinyCodeRequest != null && message.hasOwnProperty("lookupTinyCodeRequest")) {
                object.lookupTinyCodeRequest = $root.api.LookupTinyCodeRequest.toObject(message.lookupTinyCodeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "lookupTinyCodeRequest";
            }
            if (message.setupPaymentRequest != null && message.hasOwnProperty("setupPaymentRequest")) {
                object.setupPaymentRequest = $root.api.SetupPaymentRequest.toObject(message.setupPaymentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "setupPaymentRequest";
            }
            if (message.deleteTimeRequest != null && message.hasOwnProperty("deleteTimeRequest")) {
                object.deleteTimeRequest = $root.api.DeleteTimeRequest.toObject(message.deleteTimeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteTimeRequest";
            }
            if (message.truncateScheduleRequest != null && message.hasOwnProperty("truncateScheduleRequest")) {
                object.truncateScheduleRequest = $root.api.TruncateScheduleRequest.toObject(message.truncateScheduleRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "truncateScheduleRequest";
            }
            if (message.sendValidationCodeRequest != null && message.hasOwnProperty("sendValidationCodeRequest")) {
                object.sendValidationCodeRequest = $root.api.SendValidationCodeRequest.toObject(message.sendValidationCodeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "sendValidationCodeRequest";
            }
            if (message.addTimeRequest != null && message.hasOwnProperty("addTimeRequest")) {
                object.addTimeRequest = $root.api.AddTimeRequest.toObject(message.addTimeRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addTimeRequest";
            }
            if (message.leaveTopicRequest != null && message.hasOwnProperty("leaveTopicRequest")) {
                object.leaveTopicRequest = $root.api.LeaveTopicRequest.toObject(message.leaveTopicRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "leaveTopicRequest";
            }
            if (message.updatePaymentRequest != null && message.hasOwnProperty("updatePaymentRequest")) {
                object.updatePaymentRequest = $root.api.UpdatePaymentRequest.toObject(message.updatePaymentRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updatePaymentRequest";
            }
            if (message.downloadCalendarRequest != null && message.hasOwnProperty("downloadCalendarRequest")) {
                object.downloadCalendarRequest = $root.api.DownloadCalendarRequest.toObject(message.downloadCalendarRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "downloadCalendarRequest";
            }
            if (message.triggerRenderRequest != null && message.hasOwnProperty("triggerRenderRequest")) {
                object.triggerRenderRequest = $root.api.TriggerRenderRequest.toObject(message.triggerRenderRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "triggerRenderRequest";
            }
            if (message.renderStatusRequest != null && message.hasOwnProperty("renderStatusRequest")) {
                object.renderStatusRequest = $root.api.RenderStatusRequest.toObject(message.renderStatusRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "renderStatusRequest";
            }
            if (message.addIntegrationRequest != null && message.hasOwnProperty("addIntegrationRequest")) {
                object.addIntegrationRequest = $root.api.AddIntegrationRequest.toObject(message.addIntegrationRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "addIntegrationRequest";
            }
            if (message.deleteIntegrationRequest != null && message.hasOwnProperty("deleteIntegrationRequest")) {
                object.deleteIntegrationRequest = $root.api.DeleteIntegrationRequest.toObject(message.deleteIntegrationRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "deleteIntegrationRequest";
            }
            if (message.updateIntegrationRequest != null && message.hasOwnProperty("updateIntegrationRequest")) {
                object.updateIntegrationRequest = $root.api.UpdateIntegrationRequest.toObject(message.updateIntegrationRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateIntegrationRequest";
            }
            if (message.manageCouponRequest != null && message.hasOwnProperty("manageCouponRequest")) {
                object.manageCouponRequest = $root.api.ManageCouponRequest.toObject(message.manageCouponRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "manageCouponRequest";
            }
            if (message.createCouponRequest != null && message.hasOwnProperty("createCouponRequest")) {
                object.createCouponRequest = $root.api.CreateCouponRequest.toObject(message.createCouponRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "createCouponRequest";
            }
            if (message.updateCouponRequest != null && message.hasOwnProperty("updateCouponRequest")) {
                object.updateCouponRequest = $root.api.UpdateCouponRequest.toObject(message.updateCouponRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateCouponRequest";
            }
            if (message.destroyCouponRequest != null && message.hasOwnProperty("destroyCouponRequest")) {
                object.destroyCouponRequest = $root.api.DestroyCouponRequest.toObject(message.destroyCouponRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "destroyCouponRequest";
            }
            if (message.updateServiceSubscriptionRequest != null && message.hasOwnProperty("updateServiceSubscriptionRequest")) {
                object.updateServiceSubscriptionRequest = $root.api.UpdateServiceSubscriptionRequest.toObject(message.updateServiceSubscriptionRequest, options);
                if (options.oneofs)
                    object.apiRequestInfo = "updateServiceSubscriptionRequest";
            }
            return object;
        };

        /**
         * Converts this APIRequest to JSON.
         * @function toJSON
         * @memberof api.APIRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIRequest;
    })();

    api.APIResponses = (function() {

        /**
         * Properties of a APIResponses.
         * @memberof api
         * @interface IAPIResponses
         * @property {Array.<api.IAPIResponse>|null} [responses] APIResponses responses
         * @property {string|null} [error] APIResponses error
         * @property {string|null} [errorCode] APIResponses errorCode
         */

        /**
         * Constructs a new APIResponses.
         * @memberof api
         * @classdesc Represents a APIResponses.
         * @implements IAPIResponses
         * @constructor
         * @param {api.IAPIResponses=} [properties] Properties to set
         */
        function APIResponses(properties) {
            this.responses = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIResponses responses.
         * @member {Array.<api.IAPIResponse>} responses
         * @memberof api.APIResponses
         * @instance
         */
        APIResponses.prototype.responses = $util.emptyArray;

        /**
         * APIResponses error.
         * @member {string} error
         * @memberof api.APIResponses
         * @instance
         */
        APIResponses.prototype.error = "";

        /**
         * APIResponses errorCode.
         * @member {string} errorCode
         * @memberof api.APIResponses
         * @instance
         */
        APIResponses.prototype.errorCode = "";

        /**
         * Creates a new APIResponses instance using the specified properties.
         * @function create
         * @memberof api.APIResponses
         * @static
         * @param {api.IAPIResponses=} [properties] Properties to set
         * @returns {api.APIResponses} APIResponses instance
         */
        APIResponses.create = function create(properties) {
            return new APIResponses(properties);
        };

        /**
         * Encodes the specified APIResponses message. Does not implicitly {@link api.APIResponses.verify|verify} messages.
         * @function encode
         * @memberof api.APIResponses
         * @static
         * @param {api.IAPIResponses} message APIResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponses.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responses != null && message.responses.length)
                for (let i = 0; i < message.responses.length; ++i)
                    $root.api.APIResponse.encode(message.responses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
            if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
            return writer;
        };

        /**
         * Encodes the specified APIResponses message, length delimited. Does not implicitly {@link api.APIResponses.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIResponses
         * @static
         * @param {api.IAPIResponses} message APIResponses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIResponses.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIResponses message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIResponses} APIResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponses.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIResponses();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.responses && message.responses.length))
                        message.responses = [];
                    message.responses.push($root.api.APIResponse.decode(reader, reader.uint32()));
                    break;
                case 2:
                    message.error = reader.string();
                    break;
                case 3:
                    message.errorCode = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIResponses message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIResponses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIResponses} APIResponses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIResponses.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIResponses message.
         * @function verify
         * @memberof api.APIResponses
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIResponses.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.responses != null && message.hasOwnProperty("responses")) {
                if (!Array.isArray(message.responses))
                    return "responses: array expected";
                for (let i = 0; i < message.responses.length; ++i) {
                    let error = $root.api.APIResponse.verify(message.responses[i]);
                    if (error)
                        return "responses." + error;
                }
            }
            if (message.error != null && message.hasOwnProperty("error"))
                if (!$util.isString(message.error))
                    return "error: string expected";
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                if (!$util.isString(message.errorCode))
                    return "errorCode: string expected";
            return null;
        };

        /**
         * Creates a APIResponses message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIResponses
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIResponses} APIResponses
         */
        APIResponses.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIResponses)
                return object;
            let message = new $root.api.APIResponses();
            if (object.responses) {
                if (!Array.isArray(object.responses))
                    throw TypeError(".api.APIResponses.responses: array expected");
                message.responses = [];
                for (let i = 0; i < object.responses.length; ++i) {
                    if (typeof object.responses[i] !== "object")
                        throw TypeError(".api.APIResponses.responses: object expected");
                    message.responses[i] = $root.api.APIResponse.fromObject(object.responses[i]);
                }
            }
            if (object.error != null)
                message.error = String(object.error);
            if (object.errorCode != null)
                message.errorCode = String(object.errorCode);
            return message;
        };

        /**
         * Creates a plain object from a APIResponses message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIResponses
         * @static
         * @param {api.APIResponses} message APIResponses
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIResponses.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.responses = [];
            if (options.defaults) {
                object.error = "";
                object.errorCode = "";
            }
            if (message.responses && message.responses.length) {
                object.responses = [];
                for (let j = 0; j < message.responses.length; ++j)
                    object.responses[j] = $root.api.APIResponse.toObject(message.responses[j], options);
            }
            if (message.error != null && message.hasOwnProperty("error"))
                object.error = message.error;
            if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                object.errorCode = message.errorCode;
            return object;
        };

        /**
         * Converts this APIResponses to JSON.
         * @function toJSON
         * @memberof api.APIResponses
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIResponses.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIResponses;
    })();

    api.APIRequests = (function() {

        /**
         * Properties of a APIRequests.
         * @memberof api
         * @interface IAPIRequests
         * @property {boolean|null} [singleTransaction] APIRequests singleTransaction
         * @property {Array.<api.IAPIRequest>|null} [requests] APIRequests requests
         */

        /**
         * Constructs a new APIRequests.
         * @memberof api
         * @classdesc Represents a APIRequests.
         * @implements IAPIRequests
         * @constructor
         * @param {api.IAPIRequests=} [properties] Properties to set
         */
        function APIRequests(properties) {
            this.requests = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIRequests singleTransaction.
         * @member {boolean} singleTransaction
         * @memberof api.APIRequests
         * @instance
         */
        APIRequests.prototype.singleTransaction = false;

        /**
         * APIRequests requests.
         * @member {Array.<api.IAPIRequest>} requests
         * @memberof api.APIRequests
         * @instance
         */
        APIRequests.prototype.requests = $util.emptyArray;

        /**
         * Creates a new APIRequests instance using the specified properties.
         * @function create
         * @memberof api.APIRequests
         * @static
         * @param {api.IAPIRequests=} [properties] Properties to set
         * @returns {api.APIRequests} APIRequests instance
         */
        APIRequests.create = function create(properties) {
            return new APIRequests(properties);
        };

        /**
         * Encodes the specified APIRequests message. Does not implicitly {@link api.APIRequests.verify|verify} messages.
         * @function encode
         * @memberof api.APIRequests
         * @static
         * @param {api.IAPIRequests} message APIRequests message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequests.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requests != null && message.requests.length)
                for (let i = 0; i < message.requests.length; ++i)
                    $root.api.APIRequest.encode(message.requests[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.singleTransaction != null && Object.hasOwnProperty.call(message, "singleTransaction"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.singleTransaction);
            return writer;
        };

        /**
         * Encodes the specified APIRequests message, length delimited. Does not implicitly {@link api.APIRequests.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIRequests
         * @static
         * @param {api.IAPIRequests} message APIRequests message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIRequests.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIRequests message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIRequests
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIRequests} APIRequests
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequests.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIRequests();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.singleTransaction = reader.bool();
                    break;
                case 1:
                    if (!(message.requests && message.requests.length))
                        message.requests = [];
                    message.requests.push($root.api.APIRequest.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIRequests message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIRequests
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIRequests} APIRequests
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIRequests.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIRequests message.
         * @function verify
         * @memberof api.APIRequests
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIRequests.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.singleTransaction != null && message.hasOwnProperty("singleTransaction"))
                if (typeof message.singleTransaction !== "boolean")
                    return "singleTransaction: boolean expected";
            if (message.requests != null && message.hasOwnProperty("requests")) {
                if (!Array.isArray(message.requests))
                    return "requests: array expected";
                for (let i = 0; i < message.requests.length; ++i) {
                    let error = $root.api.APIRequest.verify(message.requests[i]);
                    if (error)
                        return "requests." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIRequests message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIRequests
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIRequests} APIRequests
         */
        APIRequests.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIRequests)
                return object;
            let message = new $root.api.APIRequests();
            if (object.singleTransaction != null)
                message.singleTransaction = Boolean(object.singleTransaction);
            if (object.requests) {
                if (!Array.isArray(object.requests))
                    throw TypeError(".api.APIRequests.requests: array expected");
                message.requests = [];
                for (let i = 0; i < object.requests.length; ++i) {
                    if (typeof object.requests[i] !== "object")
                        throw TypeError(".api.APIRequests.requests: object expected");
                    message.requests[i] = $root.api.APIRequest.fromObject(object.requests[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a APIRequests message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIRequests
         * @static
         * @param {api.APIRequests} message APIRequests
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIRequests.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.requests = [];
            if (options.defaults)
                object.singleTransaction = false;
            if (message.requests && message.requests.length) {
                object.requests = [];
                for (let j = 0; j < message.requests.length; ++j)
                    object.requests[j] = $root.api.APIRequest.toObject(message.requests[j], options);
            }
            if (message.singleTransaction != null && message.hasOwnProperty("singleTransaction"))
                object.singleTransaction = message.singleTransaction;
            return object;
        };

        /**
         * Converts this APIRequests to JSON.
         * @function toJSON
         * @memberof api.APIRequests
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIRequests.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIRequests;
    })();

    api.APIMessage = (function() {

        /**
         * Properties of a APIMessage.
         * @memberof api
         * @interface IAPIMessage
         * @property {api.IAPIResponses|null} [apiResponses] APIMessage apiResponses
         * @property {api.IAPIRequests|null} [apiRequests] APIMessage apiRequests
         * @property {api.IEvent|null} [event] APIMessage event
         */

        /**
         * Constructs a new APIMessage.
         * @memberof api
         * @classdesc Represents a APIMessage.
         * @implements IAPIMessage
         * @constructor
         * @param {api.IAPIMessage=} [properties] Properties to set
         */
        function APIMessage(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * APIMessage apiResponses.
         * @member {api.IAPIResponses|null|undefined} apiResponses
         * @memberof api.APIMessage
         * @instance
         */
        APIMessage.prototype.apiResponses = null;

        /**
         * APIMessage apiRequests.
         * @member {api.IAPIRequests|null|undefined} apiRequests
         * @memberof api.APIMessage
         * @instance
         */
        APIMessage.prototype.apiRequests = null;

        /**
         * APIMessage event.
         * @member {api.IEvent|null|undefined} event
         * @memberof api.APIMessage
         * @instance
         */
        APIMessage.prototype.event = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * APIMessage messageInfo.
         * @member {"apiResponses"|"apiRequests"|"event"|undefined} messageInfo
         * @memberof api.APIMessage
         * @instance
         */
        Object.defineProperty(APIMessage.prototype, "messageInfo", {
            get: $util.oneOfGetter($oneOfFields = ["apiResponses", "apiRequests", "event"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new APIMessage instance using the specified properties.
         * @function create
         * @memberof api.APIMessage
         * @static
         * @param {api.IAPIMessage=} [properties] Properties to set
         * @returns {api.APIMessage} APIMessage instance
         */
        APIMessage.create = function create(properties) {
            return new APIMessage(properties);
        };

        /**
         * Encodes the specified APIMessage message. Does not implicitly {@link api.APIMessage.verify|verify} messages.
         * @function encode
         * @memberof api.APIMessage
         * @static
         * @param {api.IAPIMessage} message APIMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIMessage.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.apiResponses != null && Object.hasOwnProperty.call(message, "apiResponses"))
                $root.api.APIResponses.encode(message.apiResponses, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.apiRequests != null && Object.hasOwnProperty.call(message, "apiRequests"))
                $root.api.APIRequests.encode(message.apiRequests, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.event != null && Object.hasOwnProperty.call(message, "event"))
                $root.api.Event.encode(message.event, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified APIMessage message, length delimited. Does not implicitly {@link api.APIMessage.verify|verify} messages.
         * @function encodeDelimited
         * @memberof api.APIMessage
         * @static
         * @param {api.IAPIMessage} message APIMessage message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        APIMessage.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a APIMessage message from the specified reader or buffer.
         * @function decode
         * @memberof api.APIMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {api.APIMessage} APIMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIMessage.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.api.APIMessage();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 2:
                    message.apiResponses = $root.api.APIResponses.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.apiRequests = $root.api.APIRequests.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.event = $root.api.Event.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a APIMessage message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof api.APIMessage
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {api.APIMessage} APIMessage
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        APIMessage.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a APIMessage message.
         * @function verify
         * @memberof api.APIMessage
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        APIMessage.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.apiResponses != null && message.hasOwnProperty("apiResponses")) {
                properties.messageInfo = 1;
                {
                    let error = $root.api.APIResponses.verify(message.apiResponses);
                    if (error)
                        return "apiResponses." + error;
                }
            }
            if (message.apiRequests != null && message.hasOwnProperty("apiRequests")) {
                if (properties.messageInfo === 1)
                    return "messageInfo: multiple values";
                properties.messageInfo = 1;
                {
                    let error = $root.api.APIRequests.verify(message.apiRequests);
                    if (error)
                        return "apiRequests." + error;
                }
            }
            if (message.event != null && message.hasOwnProperty("event")) {
                if (properties.messageInfo === 1)
                    return "messageInfo: multiple values";
                properties.messageInfo = 1;
                {
                    let error = $root.api.Event.verify(message.event);
                    if (error)
                        return "event." + error;
                }
            }
            return null;
        };

        /**
         * Creates a APIMessage message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof api.APIMessage
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {api.APIMessage} APIMessage
         */
        APIMessage.fromObject = function fromObject(object) {
            if (object instanceof $root.api.APIMessage)
                return object;
            let message = new $root.api.APIMessage();
            if (object.apiResponses != null) {
                if (typeof object.apiResponses !== "object")
                    throw TypeError(".api.APIMessage.apiResponses: object expected");
                message.apiResponses = $root.api.APIResponses.fromObject(object.apiResponses);
            }
            if (object.apiRequests != null) {
                if (typeof object.apiRequests !== "object")
                    throw TypeError(".api.APIMessage.apiRequests: object expected");
                message.apiRequests = $root.api.APIRequests.fromObject(object.apiRequests);
            }
            if (object.event != null) {
                if (typeof object.event !== "object")
                    throw TypeError(".api.APIMessage.event: object expected");
                message.event = $root.api.Event.fromObject(object.event);
            }
            return message;
        };

        /**
         * Creates a plain object from a APIMessage message. Also converts values to other types if specified.
         * @function toObject
         * @memberof api.APIMessage
         * @static
         * @param {api.APIMessage} message APIMessage
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        APIMessage.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.apiResponses != null && message.hasOwnProperty("apiResponses")) {
                object.apiResponses = $root.api.APIResponses.toObject(message.apiResponses, options);
                if (options.oneofs)
                    object.messageInfo = "apiResponses";
            }
            if (message.apiRequests != null && message.hasOwnProperty("apiRequests")) {
                object.apiRequests = $root.api.APIRequests.toObject(message.apiRequests, options);
                if (options.oneofs)
                    object.messageInfo = "apiRequests";
            }
            if (message.event != null && message.hasOwnProperty("event")) {
                object.event = $root.api.Event.toObject(message.event, options);
                if (options.oneofs)
                    object.messageInfo = "event";
            }
            return object;
        };

        /**
         * Converts this APIMessage to JSON.
         * @function toJSON
         * @memberof api.APIMessage
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        APIMessage.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return APIMessage;
    })();

    return api;
})();

export { $root as default };
