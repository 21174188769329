import { Fragment, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { api as apiproto } from "../../apiproto";
import TimeTable from "../../components/Course Details/TimeTable";
import { ExtendedSchedule, ExtendedTime } from "../../utils/eduutils";
import { tdrAlert } from "../../utils/utils";

type ChildProps = {
    topic: apiproto.ITopic | null,
    roomid: string | null,
    schedule: ExtendedSchedule | null,
    visible: boolean,
    listingType: boolean,
    onCancel?: () => void | null,
    onConfirm?: (value: any) => void | null,
    onCallback?: (value: ExtendedTime) => void | null,

}

const TimeTablePopover: React.FC<ChildProps> = ({ topic = null, roomid = null, schedule = null, visible = false, listingType = false, onCancel = () => { }, onConfirm = () => { } }, onCallback = null) => {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(visible);
    }, [visible])

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={onCancel}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <TimeTable topic={topic} schedule={schedule} roomid={roomid} bookingCallback={onCallback} listType={listingType} />
                </DialogContent>
            </Dialog>
        </Fragment>)
}

export default TimeTablePopover;