import * as apiproto from '../apiproto';
import { APIObject } from './apiobject';
import protobuf from 'protobufjs';
import React from 'react';
import { ActionType, AuthContext } from '../context/authcontext';
import { decodeJWT } from './utils';

export { API }

class Push extends APIObject {
    ws: WebSocket | undefined;
    url: string;
    token: string;

    constructor(token: string) {
        super();
        this.url = process.env["REACT_APP_APIURL"]!.replace('https://', 'wss://');
        this.token = token;
    }

    disconnect() {
        if (this.ws) {
            this.removeAllListeners();
            this.ws.onclose = null;
            this.ws.onerror = null;
            this.ws.close();
        }
    }

    connect() {

        const newWS = new WebSocket(this.url + '/ws');

        const restart = async () => {
            if (newWS) {
                newWS.close();

                await this.delay(1000);
                this.connect();
            }
        }


        newWS.binaryType = 'arraybuffer';
        newWS.onopen = async (ev) => {
            if (this.ws) {
                // connected
                this.log('connected');
                try {
                    const writer = protobuf.Writer.create();
                    const message = new apiproto.api.APIMessage({
                        apiRequests: {
                            requests: [
                                {
                                    authenticateRequest:
                                    {
                                        token: this.token
                                    }
                                }
                            ]
                        }
                    });
                    apiproto.api.APIMessage.encode(message, writer);
                    newWS.send(writer.finish());
                    // everything initialized and ready to go
                    this.emit('authenticated');
                } catch (err) {
                    newWS.close();
                    this.emit('error', err);
                }
            }
        };

        newWS.onclose = (ev) => {
            // connection closed
            restart();
        }

        newWS.onerror = (ev) => {
            // error with connection
            restart();
        }

        newWS.onmessage = async (ev) => {
            if (!ev.data || !(ev.data instanceof ArrayBuffer)) {
                // no message
                return;
            }
            try {
                this.log('got message');
                const buffer = new Uint8Array(ev.data);
                const msg = apiproto.api.APIMessage.decode(buffer) as apiproto.api.APIMessage;
                if (msg.event) {
                    // new event
                    this.emit('event', msg.event);
                } else if (msg.apiResponses && msg.apiResponses.responses && msg.apiResponses.responses.length === 1 && msg.apiResponses.responses[0].authenticateResponse) {
                    // valid response, ignore
                } else {
                    // unexpected
                    throw this.newError('UNEXPECTED_MESSAGE', 'Unexpected message');
                }
            } catch (err) {
                // error processing msg
                this.error('Error processing message', err);
            }
        }
        this.ws = newWS
    }

}

interface FindTopicSearchParams {
    minAge?: number;
    maxAge?: number;
    minDuration?: number;
    maxDuration?: number;
    searchString?: string;
    tags?: string[];
    minRatings?: number;
    minRatingsCount?: number;
    instructorUserId?: string;
}

class API extends APIObject {
    url: string;
    token: string | null = null;
    userId: string | null = null;
    dispatch: React.Dispatch<ActionType>;

    constructor(dispatch: React.Dispatch<ActionType>) {
        super();
        this.dispatch = dispatch;
        this.url = process.env["REACT_APP_APIURL"]!;
    }

    setToken(newToken: string) {
        this.token = newToken;
    }

    async executeHttpRequests(singleTransaction: boolean, apiRequests: apiproto.api.APIRequest[], checkForErrors: boolean = false): Promise<apiproto.api.IAPIResponse[]> {
        try {
            if (apiRequests.length === 0) {
                throw this.newError('Cannot send empty request', 'INVALID_PARAMETER');
            }

            const requestMessage = new apiproto.api.APIMessage({ apiRequests: { singleTransaction: singleTransaction, requests: apiRequests } });
            const writer = protobuf.Writer.create();
            apiproto.api.APIMessage.encode(requestMessage, writer);
            const requestData = writer.finish();
            if (requestData.length === 0) {
                throw this.newError('Invalid request size', 'Invalid request size');
            }
            const headers: HeadersInit = {};
            if (this.token) {
                headers['Authorization'] = 'Basic ' + this.token;
            }
            headers['Content-Type'] = 'application/octet-stream';
            const response = await fetch(this.url + '/api', { method: 'POST', body: requestData, cache: 'no-store', headers: headers, credentials: 'omit' });
            if (response.status !== 200) {
                throw this.newError('Bad status code', 'Invalid response');
            }
            if (!response.body) {
                throw this.newError('No body', 'Invalid response');
            }

            const newToken = response.headers.get('token');
            if (newToken) {
                // has new token
                const { userId, exp } = decodeJWT(newToken);
                this.dispatch({ type: 'login', loginPayload: { userId: userId, token: newToken, expirationTime: exp * 1000 } });
            }
            if (response.headers.get('Content-Type') !== 'application/octet-stream') {
                throw this.newError('Bad respond content type', 'Invalid response');
            }
            const contentLengthString = response.headers.get('Content-Length');
            if (!contentLengthString) {
                throw this.newError('Missing response content length', 'Invalid content length');
            }

            const contentLength = parseInt(contentLengthString, 10);

            if (isNaN(contentLength)) {
                throw this.newError('Invalid content length', 'Invalid content length');
            }

            if (contentLength <= 0 || contentLength > 65536 * 3) {
                throw this.newError('Too large or too small content length', 'Invalid content length');
            }

            const responseData = await response.arrayBuffer();
            const msg = apiproto.api.APIMessage.decode(new Uint8Array(responseData)) as apiproto.api.APIMessage;
            if (!msg.apiResponses || !msg.apiResponses.responses) {
                throw this.newError('Invalid response', 'Invalid response from API');
            }

            if (msg.apiResponses.error) {
                throw this.newError(msg.apiResponses.error, msg.apiResponses.errorCode ? msg.apiResponses.errorCode : 'API_ERROR');
            }

            if (msg.apiResponses.responses.length !== apiRequests.length) {
                throw this.newError('Invalid response', 'Invalid response from API');
            }
            for (let a = 0; a < apiRequests.length; a++) {
                const apiResponse = msg.apiResponses.responses[a]
                if (apiRequests[a].requestId !== apiResponse.requestId) {
                    // mis match request id
                    throw this.newError('Invalid response', 'mismatch request id from API');
                }
                if (checkForErrors) {
                    if (apiResponse.error) {
                        throw this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR');
                    }
                }
            }
            return msg.apiResponses.responses;
        } catch (err) {
            // error
            this.log('Error executing api - ' + err);
            throw err
        }
    }

    async executeHttpRequest(apiRequest: apiproto.api.APIRequest): Promise<apiproto.api.IAPIResponse> {
        const responses = await this.executeHttpRequests(true, [apiRequest])
        if (responses.length !== 1) {
            throw this.newError('Invalid response', 'Invalid response from API');
        }
        const apiResponse = responses[0]
        if (apiResponse.error) {
            throw this.newError(apiResponse.error, apiResponse.errorCode ? apiResponse.errorCode : 'API_ERROR');
        }
        return apiResponse
    }

    async login(emailAddress: string, password: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { emailPassword: { emailAddress: emailAddress, password: password } } }));
        if (!result.loginResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.loginResponse;
    }

    async googleLogin(googleJWTToken: string): Promise<apiproto.api.ILoginResponse> {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ loginRequest: { googleCredential: { jwtToken: googleJWTToken } } }));
        if (!result.loginResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.loginResponse;
    }

    async createTopic(topic: apiproto.api.ITopic) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createTopicRequest: { topic: topic } }));
        if (!result.createTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.createTopicResponse;
    }

    async updateTopic(update: apiproto.api.ITopicUpdate, topic: apiproto.api.ITopic, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateTopicRequest: { update: update, topic: topic, force: force } }));
        if (!result.updateTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateTopicResponse;
    }

    async deleteTopic(topicId: string, force: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteTopicRequest: { topicId: topicId, force: force } }));
        if (!result.deleteTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteTopicResponse;
    }

    async createRoom(room: apiproto.api.IRoom) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createRoomRequest: { room: room } }));
        if (!result.createRoomResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.createRoomResponse;
    }

    async updateRoom(update: apiproto.api.IRoomUpdate, room: apiproto.api.IRoom, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateRoomRequest: { update: update, room: room, force: force } }));
        if (!result.updateRoomResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateRoomResponse;
    }

    async deleteRoom(roomId: string, force: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteRoomRequest: { roomId: roomId, force: force } }));
        if (!result.deleteRoomResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteRoomResponse;
    }

    async updateTime(update: apiproto.api.ITimeUpdate, time: apiproto.api.ITime, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateTimeRequest: { update: update, time: time, force: force } }));
        if (!result.updateTimeResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateTimeResponse;
    }

    async deleteTime(timeId: string, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteTimeRequest: { timeId: timeId, force: force } }));
        if (!result.deleteTimeResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteTimeResponse;
    }

    async addTime(theTime: apiproto.api.ITime) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addTimeRequest: { time: theTime } }));
        if (!result.addTimeResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addTimeResponse;
    }

    async addSchedule(schedule: apiproto.api.ISchedule) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addScheduleRequest: { schedule: schedule } }));
        if (!result.addScheduleResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addScheduleResponse;
    }

    async updateSchedule(schedule: apiproto.api.ISchedule, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateScheduleRequest: { schedule: schedule, force: force } }));
        if (!result.updateScheduleResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateScheduleResponse;
    }

    async deleteSchedule(scheduleId: string, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteScheduleRequest: { scheduleId: scheduleId, force: force } }));
        if (!result.deleteScheduleResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteScheduleResponse;
    }

    async truncateSchedule(scheduleId: string, truncateEndDate: number, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ truncateScheduleRequest: { scheduleId: scheduleId, truncateEndDate: truncateEndDate, force: force } }));
        if (!result.truncateScheduleResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.truncateScheduleResponse;
    }

    async createScheduleSet(scheduleSet: apiproto.api.IScheduleSet) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createScheduleSetRequest: { scheduleSet: scheduleSet } }));
        if (!result.createScheduleSetResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.createScheduleSetResponse;
    }

    async updateScheduleSet(update: apiproto.api.IScheduleSetUpdate, scheduleSet: apiproto.api.IScheduleSet) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateScheduleSetRequest: { update: update, scheduleSet: scheduleSet } }));
        if (!result.updateScheduleSetResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateScheduleSetResponse;
    }

    async deleteScheduleSet(scheduleSetId: string, force: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteScheduleSetRequest: { scheduleSetId: scheduleSetId, force: force } }));
        if (!result.deleteScheduleSetResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteScheduleSetResponse;
    }

    async addMember(objectId: string, member: apiproto.api.IMember) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addMemberRequest: { objectId: objectId, member: member } }));
        if (!result.addMemberResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addMemberResponse;
    }

    async addMembers(singleTransaction: boolean, objectId: string, members: apiproto.api.IMember[]) {
        const apiRequests: apiproto.api.APIRequest[] = [];
        for (const member of members) {
            apiRequests.push(new apiproto.api.APIRequest({ addMemberRequest: { objectId: objectId, member: member } }))
        }
        const result = await this.executeHttpRequests(singleTransaction, apiRequests, true);
        return result;
    }

    async updateMember(objectId: string, update: apiproto.api.IMemberUpdate, member: apiproto.api.IMember) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateMemberRequest: { objectId: objectId, update: update, member: member } }));
        if (!result.updateMemberResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateMemberResponse;
    }

    async deleteMember(objectId: string, entityId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteMemberRequest: { objectId: objectId, entityId: entityId } }));
        if (!result.deleteMemberResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteMemberResponse;
    }

    async deleteMembers(objectId: string, instructorFilter: apiproto.api.IBoolFilter | null | undefined, modifyFilter: apiproto.api.IBoolFilter | null | undefined) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteMembersRequest: { objectId: objectId, instructorFilter: instructorFilter, modifyFilter: modifyFilter } }));
        if (!result.deleteMembersResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteMembersResponse;
    }

    async answerMember(objectId: string, entityId: string, accept: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ answerMemberRequest: { accept: accept, entityId: entityId, objectId: objectId } }));
        if (!result.answerMemberResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.answerMemberResponse;
    }

    async createUser(parentGroupId: string, userInfo: apiproto.api.IUser) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createUserRequest: { parentGroupId: parentGroupId, user: userInfo } }));
        if (!result.createUserResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.createUserResponse;
    }

    async updateUser(update: apiproto.api.IUserUpdate, userInfo: apiproto.api.IUser) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateUserRequest: { update: update, user: userInfo } }));
        if (!result.updateUserResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateUserResponse;
    }

    async deleteUser(userId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteUserRequest: { userId: userId } }));
        if (!result.deleteUserResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteUserResponse;
    }

    async createGroup(parentGroupId: string, groupInfo: apiproto.api.IGroup) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createGroupRequest: { parentGroupId: parentGroupId, group: groupInfo } }));
        if (!result.createGroupResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.createGroupResponse;
    }

    async updateGroup(update: apiproto.api.IGroupUpdate, groupInfo: apiproto.api.IGroup) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateGroupRequest: { update: update, group: groupInfo } }));
        if (!result.updateGroupResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateGroupResponse;
    }

    async deleteGroup(groupId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteGroupRequest: { groupId: groupId } }));
        if (!result.deleteGroupResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteGroupResponse;
    }

    async sendMessageToUser(targetUserId: string, message: string, sendAsEmail: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ sendMessageRequest: { targetUserId: targetUserId, message: message, sendAsEmail: sendAsEmail } }));
        if (!result.sendMessageResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.sendMessageResponse;
    }

    async sendMessageToRoom(roomId: string, message: string, sendAsEmail: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ sendMessageRequest: { roomId: roomId, message: message, sendAsEmail: sendAsEmail } }));
        if (!result.sendMessageResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.sendMessageResponse;
    }

    async sendMessageToTime(timeId: string, message: string, sendAsEmail: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ sendMessageRequest: { timeId: timeId, message: message, sendAsEmail: sendAsEmail } }));
        if (!result.sendMessageResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.sendMessageResponse;
    }

    async setupPayment() {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ setupPaymentRequest: {} }));
        if (!result.setupPaymentResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.setupPaymentResponse;
    }

    async getUser(query: apiproto.api.IUserQuery, options: { userId?: string | null | undefined, emailAddress?: string | null | undefined }) {
        if ((options.userId && options.emailAddress) || (!options.userId && !options.emailAddress)) {
            throw this.newError('INVALID_PARAMETER', 'Must pass either userName or userId');
        }
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getUserRequest: { query: query, userId: options.userId, emailAddress: options.emailAddress } }));
        if (!result.getUserResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        this.dispatch({ type: 'updateViewType', viewTypePayload: { currentView: result.getUserResponse.user?.defaultView ? result.getUserResponse.user?.defaultView : apiproto.api.ViewType.ConsumerView } });

        return result.getUserResponse;
    }

    async getGroup(query: apiproto.api.IUserQuery, groupId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getGroupRequest: { query: query, groupId: groupId } }));
        if (!result.getGroupResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.getGroupResponse;
    }

    async getRoom(query: apiproto.api.IRoomQuery, roomId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getRoomRequest: { query: query, roomId: roomId } }));
        if (!result.getRoomResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.getRoomResponse;
    }

    async getConferenceConnectInfo(roomOrTimeId: string, join: boolean) {
        let result: apiproto.api.IAPIResponse
        if (roomOrTimeId.startsWith("r_")) {
            result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getConferenceConnectInfoRequest: { roomId: roomOrTimeId, join: join } }));
        } else if (roomOrTimeId.startsWith("t_")) {
            result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getConferenceConnectInfoRequest: { timeId: roomOrTimeId, join: join } }));
        } else {
            throw this.newError("Invalid roomTimeOrId", "INVALID_PARAMETER")
        }
        if (!result.getConferenceConnectInfoResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.getConferenceConnectInfoResponse;
    }

    async getTopic(query: apiproto.api.ITopicQuery, topicId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getTopicRequest: { query: query, topicId: topicId } }));
        if (!result.getTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.getTopicResponse;
    }

    async findTopics(query: apiproto.api.ITopicQuery, { minAge, maxAge, minDuration, maxDuration, searchString, tags, minRatings, minRatingsCount, instructorUserId }: FindTopicSearchParams, limit: number = 100, offset: number = 0, excess: number = 0) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ findTopicsRequest: { query: query, minAge: minAge, maxAge: maxAge, minDuration: minDuration, maxDuration: maxDuration, searchString: searchString, tags: tags, minRatings: minRatings, minRatingsCount: minRatingsCount, instructorUserId: instructorUserId, limit: limit, offset: offset, excess: excess } }));
        if (!result.findTopicsResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.findTopicsResponse;
    }

    async lookupTinyCode(tinyCode: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ lookupTinyCodeRequest: { tinyCode: tinyCode } }));
        if (!result.lookupTinyCodeResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.lookupTinyCodeResponse;
    }

    async pendingReviewTopics(query: apiproto.api.ITopicQuery, limit: number = 100) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ pendingReviewTopicsRequest: { query: query, limit: limit } }));
        if (!result.pendingReviewTopicsResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.pendingReviewTopicsResponse;
    }

    async downloadCalendarForTime(topicId: string, timeId: string, format: string = "ics") {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ downloadCalendarRequest: { topicId: topicId, timeId: timeId, format: format } }));
        if (!result.downloadCalendarResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.downloadCalendarResponse;
    }

    async downloadCalendarForRoom(topicId: string, roomId: string, format: string = "ics") {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ downloadCalendarRequest: { topicId: topicId, roomId: roomId, format: format } }));
        if (!result.downloadCalendarResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.downloadCalendarResponse;
    }

    async joinRoom(roomId: string, topicId: string, emailAddress: string = "", preview: boolean = false, expectedCost: number = -1) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ joinTopicRequest: { roomId: roomId, topicId: topicId, emailAddress: emailAddress, preview: preview, expectedCost: expectedCost, checkCost: expectedCost >= 0 } }));
        if (!result.joinTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.joinTopicResponse;
    }

    async leaveRoom(roomId: string, userId: string = "") {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ leaveTopicRequest: { roomId: roomId, userId: userId } }));
        if (!result.leaveTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.leaveTopicResponse;
    }

    async joinTime(timeId: string, topicId: string, emailAddress: string = "", preview: boolean = false, expectedCost: number = -1) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ joinTopicRequest: { timeId: timeId, topicId: topicId, emailAddress: emailAddress, preview: preview, expectedCost: expectedCost, checkCost: expectedCost >= 0 } }));
        if (!result.joinTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.joinTopicResponse;
    }

    async updatePayment() {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updatePaymentRequest: {} }));
        if (!result.updatePaymentResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updatePaymentResponse;
    }

    async leaveTime(timeId: string, userId: string = "") {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ leaveTopicRequest: { timeId: timeId, userId: userId } }));
        if (!result.leaveTopicResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.leaveTopicResponse;
    }

    async addRoomReview(roomId: string, rating: number, review: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addTopicReviewRequest: { roomId: roomId, rating: rating, review: review } }));
        if (!result.addTopicReviewResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addTopicReviewResponse;
    }

    async updateRoomReview(roomId: string, rating: number, review: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateTopicReviewRequest: { roomId: roomId, rating: rating, review: review } }));
        if (!result.updateTopicReviewResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateTopicReviewResponse;
    }

    async addTimeReview(timeId: string, rating: number, review: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addTopicReviewRequest: { timeId: timeId, rating: rating, review: review } }));
        if (!result.addTopicReviewResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addTopicReviewResponse;
    }

    async updateTimeReview(timeId: string, rating: number, review: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateTopicReviewRequest: { timeId: timeId, rating: rating, review: review } }));
        if (!result.updateTopicReviewResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateTopicReviewResponse;
    }

    async getUserEvents(startTime: number, reverse: boolean, filterObjectId: string, eventFilter: apiproto.api.IEventFilter, limit: number) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ getUserEventsRequest: { startTime: startTime, reverse: reverse, filterObjectId: filterObjectId, eventFilter: eventFilter, limit: limit } }));
        if (!result.getUserEventsResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.getUserEventsResponse;
    }

    async addAsset(objectId: string, name: string, assetType: string, size: number) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addAssetRequest: { assetType: assetType, name: name, objectId: objectId, size: size } }));
        if (!result.addAssetResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addAssetResponse;
    }

    async deleteAsset(objectId: string, name: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteAssetRequest: { name: name, objectId: objectId } }));
        if (!result.deleteAssetResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteAssetResponse;
    }

    async sendValidationCode(emailAddress: string, newPassword: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ sendValidationCodeRequest: { emailAddress: emailAddress, newPassword: newPassword } }));
        if (!result.sendValidationCodeResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.sendValidationCodeResponse;
    }

    async validate(emailAddress: string, validationCode: string, newPassword: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ validateRequest: { emailAddress: emailAddress, validationCode: validationCode, newPassword: newPassword } }));
        if (!result.validateResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.validateResponse;
    }

    async triggerRender(triggerType: apiproto.api.TriggerType) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ triggerRenderRequest: { triggerType: triggerType } }));
        if (!result.triggerRenderResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.triggerRenderResponse;
    }

    async renderStatus(renderId: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ renderStatusRequest: { renderId: renderId } }));
        if (!result.renderStatusResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.renderStatusResponse;
    }

    async addGoogleCalendarAndMeetIntegration(authCode: string, syncCalendar: boolean, createMeeting: boolean, updateTimedoraMeetings: boolean) {
        const redirectUri = window.location.protocol + "//" + window.location.host
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addIntegrationRequest: { integration: { integrationType: apiproto.api.IntegrationType.GoogleCalendarAndMeet, authCode: authCode, redirectUri: redirectUri, syncCalendar: syncCalendar, createMeeting: createMeeting }, updateExistingMeetings: updateTimedoraMeetings } }));
        if (!result.addIntegrationResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addIntegrationResponse;
    }

    async addZoomIntegration(authCode: string, redirectUrl: string, updateTimedoraMeetings: boolean) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ addIntegrationRequest: { integration: { integrationType: apiproto.api.IntegrationType.Zoom, authCode: authCode, redirectUri: redirectUrl }, updateExistingMeetings: updateTimedoraMeetings } }));
        if (!result.addIntegrationResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.addIntegrationResponse;
    }

    async deleteIntegration(integrationType: apiproto.api.IntegrationType) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ deleteIntegrationRequest: { integrationType: integrationType } }));
        if (!result.deleteIntegrationResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.deleteIntegrationResponse;
    }

    async updateIntegration(update: apiproto.api.IntegrationUpdate, integration: apiproto.api.Integration) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateIntegrationRequest: { update: update, integration: integration } }));
        if (!result.updateIntegrationResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateIntegrationResponse;
    }

    async createCoupon(coupon: apiproto.api.Coupon) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ createCouponRequest: { coupon: coupon } }));
        if (!result.createCouponResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.createCouponResponse;
    }

    async destroyCoupon(couponCode: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ destroyCouponRequest: { couponCode: couponCode } }));
        if (!result.destroyCouponResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.destroyCouponResponse;
    }

    async updateCoupon(couponCode: string, update: apiproto.api.CouponUpdate, coupon: apiproto.api.Coupon) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateCouponRequest: { couponCode: couponCode, update: update, coupon: coupon } }));
        if (!result.updateCouponResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateCouponResponse;
    }

    async addCoupon(couponCode: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ manageCouponRequest: { couponCode: couponCode, removeCoupon: false } }));
        if (!result.updateCouponResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateCouponResponse;
    }

    async removeCoupon(couponCode: string) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ manageCouponRequest: { couponCode: couponCode, removeCoupon: true } }));
        if (!result.updateCouponResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateCouponResponse;
    }

    async updateServiceSubscription(serviceSubscriptionType: apiproto.api.ServiceSubscriptionType, serviceSubscriptionInterval: apiproto.api.ServiceSubscriptionInterval, preview: boolean = false) {
        const result = await this.executeHttpRequest(new apiproto.api.APIRequest({ updateServiceSubscriptionRequest: { serviceSubscriptionType: serviceSubscriptionType, serviceSubscriptionInterval: serviceSubscriptionInterval, preview: preview } }));
        if (!result.updateServiceSubscriptionResponse) {
            throw this.newError('INVALID_RESPONSE_FROM_SERVER', 'Unexpected response from server');
        }
        return result.updateServiceSubscriptionResponse;
    }
}

export const useAPI = () => {
    const { state, dispatch } = React.useContext(AuthContext);
    return React.useMemo(() => {
        const api = new API(dispatch);
        if (state.token && state.userId && state.expirationTime && state.expirationTime > (new Date()).getTime()) {
            api.setToken(state.token);
        }
        return api;
    }, [state])
}

export const usePush = () => {
    const { state } = React.useContext(AuthContext);
    return React.useMemo(() => {
        if (state.token) {
            const push = new Push(state.token);
            return push;
        } else {
            return null;
        }
    }, [state])
}
