import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const MainPricing2 = () => {
    const navigate = useNavigate();

    const pricingClicked = () => {
        let path = '/pricing';
        navigate(path);
    }

    return (
        <section
            id="pricing-2"
            className="bg-03 wide-60 pricing-section division"
        >
            <div className="container">
                <div className="row d-flex align-items-center">
                    {/* PRICING TEXT */}
                    <div className="col-lg-6">
                        <div className="pricing-txt pc-25 white-color mb-40">
                            {/* Title */}
                            <h3 className="h3-lg">
                                Free to start for knowledge creators. Easy sign up. No credit cards needed.
                            </h3>
                            {/* List */}
                            <ul className="txt-list mt-25">
                                <li>
                                    It's completely free to get started.
                                </li>
                                <li>
                                    We can help market your service. 30% of earnings if we refer clients. No commission if it is your own client.
                                </li>
                                <li>
                                    We help process payments for experts at industry standard rates. (typically 2.9% - 3.0%)
                                </li>
                                <li>
                                    Monthly plan available for premium tier features.
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* END PRICING TEXT */}
                    {/* PRICE PLAN PREMIUM */}
                    <div className="col-lg-6">
                        <div className="pricing-table">
                            {/* Plan Price  */}
                            <div className="pricing-plan text-center">
                                <img
                                    className="img-75"
                                    src="images/crown.png"
                                    alt="price-icon"
                                />
                                <h5 className="h5-md">Free Tier</h5>
                                <p className="grey-color">
                                    Always free
                                </p>
                            </div>
                            {/* Pricing Plan Features */}
                            <ul className="features">
                                <li>
                                    Create unlimited one-off Meetings
                                    <span>
                                        <i className="fas fa-check" />
                                    </span>
                                </li>
                                <li>
                                    Customize booking links
                                    <span>
                                        <i className="fas fa-check" />
                                    </span>
                                </li>
                                <li>
                                    Limit to 10 monthly one-on-one video conferencing.
                                    <span>
                                        <i className="fas fa-check" />
                                    </span>
                                </li>
                                <li>
                                    Add Timedora to your website
                                    <span>
                                        <i className="fas fa-check" />
                                    </span>
                                </li>
                                <li>
                                    Email Customer Support
                                    <span>
                                        <i className="fas fa-check" />
                                    </span>
                                </li>
                            </ul>
                            {/* Pricing Table Button */}
                            <div className="pricing-plan-btn text-center">
                                <Button size="large" variant="contained" onClick={pricingClicked} sx={{
                                    ':hover': {
                                        bgcolor: '#ff1f59', // theme.palette.primary.main
                                        color: 'white',
                                    }, textAlign: 'center', width: 300, height: 60, fontSize: '23px', backgroundColor: 'primary.main', textTransform: 'none'
                                }}>
                                    See Premium Tiers
                                </Button>

                            </div>
                        </div>
                    </div>
                    {/* END PRICE PLAN PREMIUM */}
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section >
    );
};

export default MainPricing2;
