import React from "react";
import { VideoSource } from "./ConferenceTypes";
import { ControlButton, MenuItem } from "./ControlButton";

export interface SourceSelectButtonProps {
    source: VideoSource;
    onSourceSelected: (source: VideoSource) => void;
    className?: string;
    popoverContainerClassName?: string;
    popoverTriggerBtnClassName?: string;
    popoverTriggerBtnSeparatorClassName?: string;
}

export const SourceSelectButton = ({
    source,
    onSourceSelected,
    className,
    popoverContainerClassName,
    popoverTriggerBtnClassName,
    popoverTriggerBtnSeparatorClassName,
}: SourceSelectButtonProps) => {

    const sources: MenuItem<VideoSource>[] = [{ label: 'Auto', obj: VideoSource.Auto }, { label: 'Camera', obj: VideoSource.Camera }, { label: 'Screen', obj: VideoSource.Screen } /*, { label: 'Face', obj: VideoSource.Face }*/];

    const handleMenuItem = (item: MenuItem<VideoSource>) => {
        const source = sources.find((d) => d.label === item.label);
        if (source && onSourceSelected) {
            onSourceSelected(source.obj as VideoSource);
        }
    };

    const onClick = () => {
        // toggle layout
        let selectedIndex = 0;
        for (let a = 0; a < sources.length; a++) {
            if (sources[a].obj === source) {
                selectedIndex = a;
                break;
            }
        }
        selectedIndex = (++selectedIndex) % (sources.length);
        onSourceSelected(sources[selectedIndex].obj as VideoSource);
    }

    let sourceLabel = sources[0].label;
    for (let a = 0; a < sources.length; a++) {
        if (sources[a].obj === source) {
            sourceLabel = sources[a].label;
            break;
        }
    }

    return (
        <ControlButton
            label={sourceLabel}
            onClick={onClick}
            menuItems={sources}
            onMenuItemClick={handleMenuItem}
            className={className}
            popoverContainerClassName={popoverContainerClassName}
            popoverTriggerBtnClassName={popoverTriggerBtnClassName}
            popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
        />
    );
};