import React, { Fragment } from "react";
import { api as apiproto } from "../../apiproto";
import TopicCard from "./TopicCard";

type ChildProps = {
  courses: apiproto.ITopic[] | null,
  user: apiproto.IUser | null
}

const CourseSimilar: React.FC<ChildProps> = ({ courses: topics = [], user = null }) => {

  if (!topics || topics.length === 0) return (<Fragment></Fragment>)
  return (
    <Fragment>


      <div>
        {/* SECTION TITLE */}
        <div className="row">
          <div className="col-md-12">
            {user &&
              <div className="section-title mb-60 divpaddinglr">
                {/* Title 	*/}
                <h4 className="h4-xl">{topics.length} {topics.length === 1 ? "topic" : "topics"} by {user?.firstName} {user?.lastName}</h4>
                <p className="p-md">
                  Explore topics offered by {user.firstName} {user.lastName}
                </p>
              </div>
            }
          </div>
        </div>
        {topics?.map((topic: apiproto.ITopic, index) => (
          <div className="row" key={'simrow' + topic.topicId}>
            <div className="col-md-12">
              <TopicCard topic={topic} type="" key={'simcard' + topic.topicId} />
            </div >
          </div>
        ))}

      </div>
    </Fragment >
  );
};

export default CourseSimilar;
