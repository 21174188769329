import React, { ChangeEvent, useEffect, useState, FormEvent, Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { api as apiproto } from "../../apiproto";
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { ActiveSchedule, AppIntegrations, checkIfTopicProfile, computeDuration, computeDurationToHrMin, computeEpochtoTimeNoAMPMStr, computeEpochtoTimeStr, convertDateToEpoch, customDaysStr, daysOfWeek, encodeJSONObject, ExtendedRoom, ExtendedSchedule, ExtendedScheduleSet, ExtendedTopic, generateProfileTopicURL, generateTopicURL, getAppIntegrationStatus, getTimeZoneAbbrev, getTopicPhotoURL, getWeekOfMonthStr, KeyCodes, resizeFile, SESSIONFREQUENCY, SessionType, SESSION_PHOTO_ASSET_NAME } from '../../utils/eduutils'
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import { Alert, Autocomplete, Box, Button, Grid, IconButton, Menu, MenuItem, Paper, Snackbar, Tab, Tabs, TextField, Typography } from "@mui/material";
import DialogMessageBox from "../../components/DialogMessageBox";
import { WithContext as ReactTags } from 'react-tag-input';
import { LinearProgressWithLabel } from "../../components/LinearProgressWithLabel";
import { PhotoCamera } from "@mui/icons-material";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import ScheduleInfoPopover from "./scheduleinfoinputdialog";
import ScheduleNameChangePopover from "./schedulesetnamechangedialog";
import moment from "moment";
import { tdrAlert, tdrLog } from "../../utils/utils";
import DateRangeIcon from '@mui/icons-material/DateRange';
import TimeTablePopover from "./timetabledialog";
import PageTitle from "../../layout/PageTitle";

type TopicParams = {
    id: string;
}

type Tag = {
    id: string,
    text: string
}

//const filter = createFilterOptions<LocationType>();

export interface SnackbarMessage {
    snackMessage: string;
    key: number;
    information?: string;
}

export interface State {
    open: boolean;
    snackPack: readonly SnackbarMessage[];
    snackMessageInfo?: SnackbarMessage;
}

export const TopicCreate = () => {
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const [tabValue, setTabValue] = React.useState(0);

    // Schedule Set Menu selection handling
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }


    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();
    const topicparams = useParams<TopicParams>();
    const [imageUrl, setImageUrl] = useState("/images/image-04.png");
    // This is for the anchor element for select schedule set button
    const [activeEditedSchedule, setActiveEditedSchedule] = useState<ActiveSchedule | null>({ room: null, schedule: null });
    const [scheduleInfoOpen, setScheduleInfoOpen] = React.useState(false);
    const [timeTableOpen, setTimeTableOpen] = React.useState(false);
    const [editTimeMode, setEditTimeMode] = React.useState(false);
    const [scheduleInfoNameChangeOpen, setScheduleNameChangeOpen] = useState(false);
    const [totalEnrolledMembers, setTotalEnrolledMembers] = useState(0); // This keeps track if there are already members enrolled and if so certain things like classformat cannot be changed anymore. If one time single session, also cannot change to other class foramt whether there are enrollees or not because too computationally intensive to try to figure out all the time members.
    const [price, setPrice] = useState("0");
    const [isProfilePage, setIsProfilePage] = useState(false);
    const [userInfo, setUserInfo] = useState<apiproto.IUser | null>(null);

    // Snack Bar to show committed changes
    const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessageInfo, setSnackMessageInfo] = useState<SnackbarMessage | undefined>(undefined,);

    // App Integrations
    const [appIntegrationStatus, setAppIntegrationStatus] = useState<AppIntegrations>({ googleCalendar: false, googleMeet: false, zoom: false, stripe: false })

    useEffect(() => {
        if (snackPack.length && !snackMessageInfo) {
            // Set a new snack when we don't have an active one
            setSnackMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setSnackOpen(true);
        } else if (snackPack.length && snackMessageInfo && snackOpen) {
            // Close an active snack when a new one is added
            setSnackOpen(false);
        }
    }, [snackPack, snackMessageInfo, snackOpen]);

    const handleSnackClick = (snackMessage: string, information?: string) => {
        setSnackPack((prev) => [...prev, { snackMessage, key: new Date().getTime(), information: information }]);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleSnackExited = () => {
        setSnackMessageInfo(undefined);
    };

    const handleScheduleInfoClose = () => {
        setScheduleInfoOpen(false);
    };

    const handleScheduleNameChangeClose = () => {
        setScheduleNameChangeOpen(false);
    };

    const roomSchedule: ExtendedSchedule[] = [
        {
            // scheduleId: "",
            scheduleId: "1",
            scheduleSetId: null,
            startTime: 0,
            duration: 0,
            repeatCount: -1, // For now we don't do repeat count until we add custom schedule
            repeatEndDate: 0,
            dateRange: new Date(),
            fromDate: new Date(),
            toDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
            fromTime: '09:00',
            toTime: '10:00',
            frequency: SESSIONFREQUENCY.WEEKLY.toString(),
            // Customize error:
            toDateErr: "",
            toTimeErr: "",
            durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
            weekDays: [false, false, false, false, false, false, false],
            generalErr: "",
        }
    ]

    const roomSchedule1: ExtendedSchedule[] = [
        {
            // scheduleId: "",
            scheduleId: "1",
            scheduleSetId: null,
            startTime: 0,
            duration: 0,
            repeatCount: -1, // For now we don't do repeat count until we add custom schedule
            repeatEndDate: 0,
            dateRange: new Date(),
            fromDate: new Date(),
            toDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
            fromTime: '10:00',
            toTime: '11:00',
            frequency: SESSIONFREQUENCY.MONTHLY.toString(),
            // Customize error:
            toDateErr: "",
            toTimeErr: "",
            durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
            generalErr: "",
        },
        {
            // scheduleId: "",
            scheduleId: "2",
            scheduleSetId: null,
            startTime: 0,
            duration: 0,
            repeatCount: -1, // For now we don't do repeat count until we add custom schedule
            repeatEndDate: 0,
            dateRange: new Date(),
            fromDate: new Date(),
            toDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
            fromTime: '11:00',
            toTime: '12:00',
            frequency: SESSIONFREQUENCY.MONTHLY.toString(),
            // Customize error:
            toDateErr: "",
            toTimeErr: "",
            durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
            generalErr: "",
        }
    ]


    /*
        const initialRoom: ExtendedRoom[] = [
            {
                roomKey: "0",
                roomId: "0",
                topicId: null,
                section: '',
                room: '',
                scheduleSet: scheduleSet,
                scheduleSelection: "0",
                scheduleSetId: null,
                existingRoom: false,
                scheduleSetOpen: null
            }
        ]
    */
    const initialState: ExtendedTopic = {
        topicId: null,
        name: "",
        description: "",
        minAge: 9,
        maxAge: 12,
        maxParticipants: 1,
        topicrooms: [],
        topicformat: SessionType.single,
        category: 0,
        cost: 0,
        currency: "USD",
        costType: apiproto.CostType.free
    };

    const [currentScheduleSet, setCurrentScheduleSet] = useState<ExtendedScheduleSet[]>([{ name: "schedule1", scheduleSetId: "schedid1", schedules: roomSchedule }, { name: "schedule2", scheduleSetId: "schedid2", schedules: roomSchedule1 }]);


    const submitInitialStatus = {
        isSubmitting: false,
        errorMessage: "",
    }
    const [submitStatus, setSubmitStatus] = useState(submitInitialStatus);

    // Topics->Rooms->ScheduleSet->Schedule
    const [fullDescription, setFullDescription] = useState({ summary: "", description: "", prerequisites: "", supply_list: "", assignments: "", time_commitment: "" })

    const placeholderDescriptionText = ["Please enter a summary. (required)", "Please enter a description for the topic. (required)", "Please enter any prerequisites (optional)", "Enter any supply list (optional)"];
    const [createSuccess, setCreateSuccess] = useState(false);
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true, action: 0 });
    const [topicInfoState, setTopicInfoState] = useState<ExtendedTopic>(initialState);
    const [roomScheduleState, setRoomScheduleState] = useState(roomSchedule);
    const [roomCount, setRoomCount] = useState(100);
    const [deletedScheduleID, setDeletedScheduleID] = useState<any[]>([]);
    const [deletedRoomID, setDeletedRoomID] = useState<string[]>([]);
    const [tags, setTags] = React.useState<Tag[]>([]);
    const handleDelete = (i: number) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag: any) => {
        setTags([...tags, tag]);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'tags') {
        }
        else {
            setTopicInfoState({
                ...topicInfoState,
                [event.target.name]: event.target.value
            });
        }
    };

    const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        setFullDescription({ ...fullDescription, [event.target.name]: event.target.value })
        setTopicInfoState({
            ...topicInfoState,
            "description": JSON.stringify(fullDescription)
        });
    };


    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // That means class format has changed, so we just reset billing type to Free since depending on what is selected, its unclear what is the best default- so default to free since it is common to all types
        if (event.target.name === 'topicformat') {
            setTopicInfoState({
                ...topicInfoState,
                topicformat: parseInt(event.target.value),
                costType: apiproto.CostType.free
            });

        }
        else {
            setTopicInfoState({
                ...topicInfoState,
                [event.target.name]: event.target.value
            });
        }

    };


    const handleScheduleSetChange = (schedulesetid: string | null | undefined, roomid: string | null | undefined) => {
        if (!roomid) return;
        // Get the room that is affected
        const cindex = topicInfoState.topicrooms.findIndex((f: ExtendedRoom) => f.roomId === roomid);
        if (cindex === -1) return;
        const theroom: ExtendedRoom = { ...topicInfoState.topicrooms[cindex] };

        // If choice is new schedule, then create a new schedule
        if (schedulesetid === null) {
            theroom.scheduleSetId = null;
            theroom.scheduleSet = { scheduleSetId: null, name: "", schedules: [] };
        }
        else {
            // Get the schedule set from the list
            const sindex = currentScheduleSet.findIndex((f: apiproto.IScheduleSet) => f.scheduleSetId === schedulesetid);
            if (sindex === -1) {
                // TODO - can't find the schedule setwhich is impossible
                return;
            }

            const existingScheduleSet: ExtendedScheduleSet = { ...currentScheduleSet[sindex] };
            theroom.scheduleSet = existingScheduleSet;
            theroom.scheduleSetId = schedulesetid;
        }
        // Create a new rooms[] object with updated schedule
        const topicrooms: ExtendedRoom[] = [...topicInfoState.topicrooms.slice(0, cindex), theroom, ...topicInfoState.topicrooms.slice(cindex + 1)]
        setTopicInfoState({
            ...topicInfoState,
            topicrooms: topicrooms
        });
    };


    const onClose = () => {
        if (isProfilePage) {
            navigate("/profile");
        }
        else {
            if (topicInfoState.topicId) {
                onPreview();
            }
            else {
                navigate("/sessions");
            }
        }
    }

    const onPreview = () => {
        if (topicInfoState.topicId) {
            if (isProfilePage) {
                if (userInfo !== null) {
                    navigate(generateProfileTopicURL(userInfo.firstName!, userInfo.lastName!, userInfo.userId!))
                }
            }
            else {
                navigate(generateTopicURL(topicInfoState));
            }
        }
        else {
            onClose();
        }
    }

    const increment = () => setRoomCount(c => c + 1)

    const addRoom = () => {
        increment(); // Need to figure this one out due to its asyncrounous- may need a call
        const assignedRoomID = roomCount.toString();
        const newroom: ExtendedRoom = {
            roomKey: roomCount.toString(),
            roomId: assignedRoomID,
            topicId: topicInfoState.topicId,
            section: '',
            room: 'v:td', // This defaults to Timedora video
            scheduleSet: { name: "", schedules: [] },
            scheduleSelection: "0",
            scheduleSetOpen: null, //this keeps track of the button
            scheduleSetId: null,
            existingRoom: false
        }
        const newrooms: ExtendedRoom[] = [...topicInfoState.topicrooms, newroom];
        setTopicInfoState({ ...topicInfoState, topicrooms: newrooms })
        addSchedule(newroom);
    }

    const changeScheduleSetName = (room: ExtendedRoom) => {
        setActiveEditedSchedule({ room: room, schedule: null });
        setScheduleNameChangeOpen(true);
    }

    const addSchedule = (room: ExtendedRoom) => {
        increment();
        const newschedule: ExtendedSchedule = {
            scheduleId: roomCount.toString(),
            scheduleSetId: room.scheduleSetId,
            startTime: 0,
            duration: 60 * 60 * 1000,
            repeatCount: -1, // For now we don't do repeat count until we add custom schedule
            repeatEndDate: 0,
            // This is the original one for UI
            dateRange: new Date(),
            fromDate: new Date(),
            toDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
            fromTime: '09:00',
            toTime: '10:00',
            frequency: SESSIONFREQUENCY.WEEKLY.toString(),

            // Customize error:
            toDateErr: "",
            toTimeErr: "",
            durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
            generalErr: "",

        }
        setActiveEditedSchedule({ room: room, schedule: newschedule });
        setScheduleInfoOpen(true);
        /*
                const cindex = topicInfoState.topicrooms.findIndex((f: ExtendedRoom) => f.roomId === roomId);
        if (cindex === -1) return;
        const roomToAddScheduleTo = { ...topicInfoState.topicrooms[cindex] }

        if (roomToAddScheduleTo.scheduleSet === null || roomToAddScheduleTo.scheduleSet.schedules === null) return;
        roomToAddScheduleTo.scheduleSet.schedules = [...roomToAddScheduleTo.scheduleSet.schedules, newschedule]*/
    }

    /******************************************************* 
     * 
     * Dialog box section for handling actions confirmation
     * 
     * *****************************************************/
    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true, action: 0 });
    }

    const dialogNoOp = (meta: string) => {
        setCreateSuccess(false);
    }

    const handleCancel = () => {
        setTimeTableOpen(false);
        setScheduleInfoOpen(false);
        clearDialog();
    }

    const handleActionConfirm = () => {

        if (dialogMessage.action === 1) {
            // Confirm delete schedule or time
            handleDeleteConfirm(dialogMessage.meta);
        }
        else if (dialogMessage.action === 2) {
            // Force update
            createTopic(true);
        }
        else if (dialogMessage.action === 3) {
            // Force update
            const meta: any = dialogMessage.meta;
            deleteAsyncSchedule(meta, true);
            deleteScheduleFromRoom(meta.scheduleid, meta.roomid, meta.schedulesetid);
            // createTopic(true);
        }
        else if (dialogMessage.action === 4) {
            // Force update
            const meta: any = dialogMessage.meta;
            updateAsyncSchedule(meta, true);
        }
        else if (dialogMessage.action === 20) {
            // Google Meet integration.
            window.open("/integration");
        }

        clearDialog();
    }

    const updateScheduleInUI = (meta: ActiveSchedule) => {
        //        meta.schedule!.startTime = convertDateToEpoch(meta.schedule!.fromDate, meta.schedule!.fromTime);
        //      meta.schedule!.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (meta.room !== null && meta.schedule !== null) {
            // Schedule set id is null and schedule is null, so we create a new schedule with a schedule set name
            const cindex = topicInfoState.topicrooms.findIndex((f: ExtendedRoom) => f.roomId === meta.room?.roomId);
            if (cindex === -1) return;
            const room = { ...topicInfoState.topicrooms[cindex] }
            if (room.scheduleSet === null || room.scheduleSet.schedules === null) return;
            const sindex = room.scheduleSet.schedules!.findIndex((s: ExtendedSchedule) => s.scheduleId === meta.schedule?.scheduleId);
            if (sindex === -1) {
                // At this point cannot find the scheduleid, so this is a new schedule. Add to end of the room.
                room.scheduleSet.schedules = [...room.scheduleSet.schedules, meta.schedule]
                setTopicInfoState({
                    ...topicInfoState
                });
            }
            else {
                // Its an existing schedule so we change it
                const newschedule = [...room.scheduleSet.schedules!.slice(0, sindex), meta.schedule, ...room.scheduleSet.schedules!.slice(sindex + 1)];
                room.scheduleSet.schedules = newschedule;
                // Create a new rooms[] object with updated schedule
                const topicrooms: ExtendedRoom[] = [...topicInfoState.topicrooms.slice(0, cindex), room, ...topicInfoState.topicrooms.slice(cindex + 1)]
                setTopicInfoState({
                    ...topicInfoState,
                    topicrooms: topicrooms
                });
            }
        }
    }

    const handleScheduleNameChangeDone = (meta: any) => {
        setScheduleNameChangeOpen(false);
        // Update the schedule set 
        if (meta.reload) {
            refreshScheduleSet();
        }
    }


    const handleScheduleInfoDone = (meta: ActiveSchedule) => {
        if (meta.schedule?.scheduleSetId !== null && !meta.schedule?.scheduleId?.includes("_")) {
            // Create a new schedule in the schedule set.
            // This is an existing schedule and so we just update it and we are done.
            /*
            const cindex = topicInfoState.topicrooms.findIndex((f: ExtendedRoom) => f.roomId === meta.room?.roomId);
            if (cindex === -1) return;
            const room = { ...topicInfoState.topicrooms[cindex] }
            if (room.scheduleSet !== null && room.scheduleSet.schedules !== null) {
                room.scheduleSet.schedules = [...room.scheduleSet.schedules!, meta.schedule!]
                setTopicInfoState({
                    ...topicInfoState
                });
            }*/
            addAsyncSchedule(meta.schedule!);
        }
        else if (meta.schedule?.scheduleSetId !== null && meta.schedule?.scheduleId?.includes("_")) {
            // Updating an existing schedule in an existing schedule set.
            updateAsyncSchedule(meta.schedule!, false);
        }
        else {
            // TODO What to do here???
        }
        updateScheduleInUI(meta);
        handleScheduleInfoClose();

    }

    /******************************************************* 
     * 
     * Delete schedule section - UI functions
     * 
     * *****************************************************/
    const handleDeleteScheduleConfirm = (toDeleteRoomID: string, toDeleteScheduleID: string, toDeleteScheduleSetID: string | null) => {
        clearDialog();
        if (toDeleteRoomID.includes("_")) {
            const deleteID = { roomid: toDeleteRoomID, scheduleid: toDeleteScheduleID, schedulesetid: toDeleteScheduleSetID };
            setDeletedScheduleID([...deletedScheduleID, deleteID]);
        }
        deleteScheduleFromRoom(toDeleteScheduleID, toDeleteRoomID, toDeleteScheduleSetID);
    }

    const editSchedule = (room: ExtendedRoom, schedule: ExtendedSchedule) => {
        // first if this uses an existing scheduleset id , we will ask if they truly want to delete
        setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
        setScheduleInfoOpen(true);
        setEditTimeMode(false);
    }

    const editTime = (room: ExtendedRoom, schedule: ExtendedSchedule) => {
        // first if this uses an existing scheduleset id , we will ask if they truly want to delete
        setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
        setTimeTableOpen(true);
        setEditTimeMode(true);
    }

    const deleteSchedule = (scheduleid: string, roomid: string, schedulesetid: string | null | undefined) => {
        // first if this uses an existing scheduleset id , we will ask if they truly want to delete
        if (schedulesetid && schedulesetid.includes("_")) {
            // this is in an existing set id
            // we want to delete one at a time in the DB because very hard to do it in bulk without being transactional.
            if (scheduleid.includes("_")) {
                // This is an existing saved schedule
                const metadata = { scheduleid: scheduleid, roomid: roomid, schedulesetid: schedulesetid }
                setDialogMessage({ title: 'Delete Schedule', message: 'Are you sure you want to delete an existing schedule from the schedule set? This will be permanently deleted from your schedule set and may affect any topic pages and rooms sharing this schedule and affect participants enrolled.', meta: metadata, confirm: true, action: 3 });
                return;
            }
            else {
                // This is a manually added schedule that is not saved yet
                deleteScheduleFromRoom(scheduleid, roomid, schedulesetid);
            }
        }
        else if (topicparams.id) {
            // this is in edit mode
            if (scheduleid.includes("_")) {
                const metadata = { scheduleid: scheduleid, roomid: roomid, schedulesetid: schedulesetid }
                setDialogMessage({ title: 'Delete Schedule', message: 'Are you sure you want to delete an existing schedule from the room? This will be permanently deleted when you update.', meta: metadata, confirm: true, action: 1 });
                return;
            }
        }
        // This is a topic creation with no schedule set and so ok to just delete since nothing in DB yet.
        deleteScheduleFromRoom(scheduleid, roomid, schedulesetid);
    }

    const deleteScheduleFromRoom = (scheduleid: string, roomid: string, schedulesetid: string | null | undefined) => {
        const cindex = topicInfoState.topicrooms.findIndex((f: ExtendedRoom) => f.roomId === roomid);
        if (cindex === -1) return;

        const topicRoom: ExtendedRoom = { ...topicInfoState.topicrooms[cindex] };
        if (topicRoom.scheduleSet === null || topicRoom.scheduleSet.schedules === null) return;
        const sindex = topicRoom.scheduleSet.schedules.findIndex((s: ExtendedSchedule) => s.scheduleId === scheduleid);
        // At this point cannot find the scheduleid, roomid pair so return.
        if (sindex === -1) return;
        // Now we return a new array wit deleted schedule id
        const newschedule = [...topicRoom.scheduleSet.schedules.slice(0, sindex), ...topicRoom.scheduleSet.schedules.slice(sindex + 1)]
        topicRoom.scheduleSet.schedules = newschedule;

        // Create a new rooms[] object with updated schedule
        const newrooms: ExtendedRoom[] = [...topicInfoState.topicrooms.slice(0, cindex), topicRoom, ...topicInfoState.topicrooms.slice(cindex + 1)]
        setTopicInfoState({
            ...topicInfoState,
            topicrooms: newrooms
        });
    }
    // This is where schedules are actually effected through API on DB
    /*
    const deleteSchedules = async (deleteIDs: any[], force: boolean) => {
        tdrLog(JSON.stringify(deleteIDs));
        try {
            const unresolvedPromises = deleteIDs.map((deleteID: any) => deleteAsyncSchedule(deleteID, force));
            if (unresolvedPromises) {
                await Promise.all(unresolvedPromises);
            }
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
            tdrLog(err.message);

        }

    }*/


    const deleteAsyncSchedule = async (deleteID: any, force: boolean) => {
        if (deleteID.scheduleid) {
            try {
                const deleteResult = await api.deleteSchedule(deleteID.scheduleid, force);
                handleSnackClick('Schedule deleted...', "success")
                // Refresh the schedule set
                refreshScheduleSet();
                return deleteResult;
            }
            catch (err: any) {
                setSubmitStatus({ isSubmitting: false, errorMessage: err.message })
                setDialogMessage({ title: 'Delete Schedule', message: 'You are deleting a schedule ' + deleteID.scheduleid + ' that may affect  other topic pages sharing this schedule', meta: deleteID, confirm: true, action: 3 });
            }
        }
    }



    /******************************************************* 
     * 
     * Delete room section - UI functions
     * 
     * *****************************************************/

    const deleteRoom = (room: apiproto.IRoom) => {
        if (topicparams.id) {
            // If edit, any delete, we just store the schedule ids and only actually do the update when an actual update topic occurs.
            // We ask for confirmation
            if (room.roomId?.includes("_")) {
                setDialogMessage({ title: 'Delete Room', message: 'Are you sure you want to delete an existing room? This will be permanently deleted when you update.', meta: { roomid: room.roomId! }, confirm: true, action: 1 });
                return;
            }
        }
        deleteRoomFromTopic(room.roomId!);
    }

    const handleDeleteConfirm = (toDeleteMeta: any) => {
        const toDeleteRoomID = toDeleteMeta.roomid;
        const toDeleteScheduleID = toDeleteMeta.scheduleid;
        const toDeleteScheduleSetID = toDeleteMeta.schedulesetid;
        if (toDeleteScheduleID && toDeleteRoomID) {
            handleDeleteScheduleConfirm(toDeleteRoomID, toDeleteScheduleID, toDeleteScheduleSetID);
        }
        else if (toDeleteRoomID) {
            clearDialog();
            if (toDeleteRoomID.includes("_")) {
                // To store the existing roomids for actual API call delete when save is pressed
                setDeletedRoomID([...deletedRoomID, toDeleteRoomID]);
            }
            // UI delete but not yet actual delete using API until save is clicked
            deleteRoomFromTopic(toDeleteRoomID);
        }
    }

    const deleteRoomFromTopic = (roomId: string) => {
        const topicrooms: ExtendedRoom[] = topicInfoState.topicrooms.filter(room => roomId !== room.roomId);
        setTopicInfoState({
            ...topicInfoState,
            topicrooms: topicrooms
        });

    }

    // This is where rooms actions such as deletes are actually effected through API on DB

    const deleteRooms = async (deleteIDs: string[]) => {
        try {
            const unresolvedPromises = deleteIDs.map((deleteID: string) => deleteAsyncRoom(deleteID));
            if (unresolvedPromises) {
                await Promise.all(unresolvedPromises);
            }
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
            tdrAlert('DeleteRooms' + err.name + ' ' + err.message);
            tdrLog(err.message);
        }

    }

    const deleteAsyncRoom = async (roomId: string) => {
        try {
            const deleteResult = await api.deleteRoom(roomId);
            return deleteResult;
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
        }

    }




    const editTopic = async (topicObj: apiproto.ITopic, force: boolean) => {
        // We first topic the course object came from a course id, so is an edit 
        // We first topic courses
        // Update the main topic object
        try {
            // First we update the existing topics with
            await api.updateTopic({ name: true, description: true, minAge: true, maxAge: true, maxParticipants: true, tag: true, cost: true, costType: true, currency: true }, topicObj, force);
            // Delete schedules
            if (deletedScheduleID.length > 0) {
                // We don't need to do deleteSchedules anymore since we delete as sonon as deleteicon is clicked.
                // due to schedule set changes.
                // await deleteSchedules(deletedScheduleID, force);
            }

            if (deletedRoomID.length > 0) {
                await deleteRooms(deletedRoomID);
            }

            // Add Schedules
            if (topicObj.rooms && topicObj.rooms.length > 0) {
                // Updating rooms
                await createUpdateRooms(topicObj.rooms, force);
            }
            //setCreateSuccess(true);
            handleSnackClick('Changes successfully saved...', "success")
            setSubmitStatus({ isSubmitting: false, errorMessage: "" })
        }
        catch (err: any) {
            // TODO - change to FORCE_REQUIRED only when server updates
            if (err.name === "SYSTEM_ERROR" || err.name === "FORCE_REQUIRED") {
                setDialogMessage({ title: 'Confirm update', message: 'You are updating your page that may affect participants signed up for your session. This may cause cancellations from enrolled users. Are you sure you want to continue?', meta: '', confirm: true, action: 2 });
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })
            }
            else if (err.name === "COSTCHANGE_ERROR") {
                setDialogMessage({ title: 'Cost change error', message: 'You have enrollees in your topic. You cannot change your cost or billing type once you have enrollees in your topic.', meta: '', confirm: true, action: 2 });

            }
            else {
                setDialogMessage({ title: 'Update Error', message: 'There was an error updating your topic page.', meta: '', confirm: true, action: 0 });
                setSubmitStatus({ isSubmitting: false, errorMessage: err.message })
            }
            return null;
        }

    }


    const createUpdateRooms = async (trooms: apiproto.IRoom[], force: boolean) => {
        try {
            const unresolvedPromises = trooms.map((room: apiproto.IRoom) => (

                createUpdateAsyncRoom(room, force)
            ));
            if (unresolvedPromises) {
                await Promise.all(unresolvedPromises);
            }
        } catch (err: any) {
            tdrAlert('createUpdateRooms' + err.name + ' ' + err.message);
            throw (err);
        }

    }

    const createUpdateAsyncRoom = async (room: apiproto.IRoom, force: boolean) => {
        try {
            if (room.roomId && room.roomId.includes("_")) {
                var scheduleSetChange = (topicInfoState.singleSession !== null) ? true : false;
                await api.updateRoom({ room: true, section: true, scheduleSetId: scheduleSetChange }, room, force);
                if (room.scheduleSetId !== null) {
                    // If already has schedule ID, you are either updating the schedule or adding to the schedule
                    room.scheduleSet?.schedules?.forEach((schedule: apiproto.ISchedule) => {
                        if (schedule.scheduleId?.includes("_") && room.roomId?.includes("_")) {
                            // Best effort update for schedules
                            updateAsyncSchedule(schedule, force);
                        }
                        else if (room.roomId?.includes("_")) {
                            // Best effort update for schedules
                            addAsyncSchedule(schedule);
                        }
                    }
                    )
                }
                else {
                    // The existing room changed to a completely newly to be create schedule set.
                    createScheduleSet(room.scheduleSet!, true).then((result) => {
                        if (result !== null) {
                            room.scheduleSetId = result;
                            room.scheduleSet!.schedules = null;
                            const addResult = api.updateRoom({ room: true, section: true, scheduleSetId: true }, room, force);
                            return addResult;
                        }
                    })
                }
            }
            else {
                const addResult = await api.createRoom(room);
                return addResult;
            }

        } catch (err: any) {
            tdrAlert('createUpdateAsyncRoom' + err.name + ' ' + err.message);
            throw (err);
        }

    }

    const createScheduleSet = async (scheduleset: apiproto.IScheduleSet, force: boolean) => {
        try {
            const result = await api.createScheduleSet(scheduleset);
            if (result && result.scheduleSetId) {
                return Promise.resolve(result.scheduleSetId);
            }
        }
        catch (err: any) {
            tdrAlert('createScheduleSet' + err.name + ' ' + err.message);
            throw (err);
        }
        return null;


    }

    const updateAsyncSchedule = async (scheduleupdate: apiproto.ISchedule, force: boolean) => {
        try {
            const updateResult = await api.updateSchedule(scheduleupdate, force);
            handleSnackClick('Schedule changes saved...', "success")
            refreshScheduleSet();
            return updateResult;
        }

        catch (err: any) {
            tdrAlert('updateAsyncSchedule' + err.name + ' ' + err.message);
            if (err.name === 'FORCE_REQUIRED') {
                setDialogMessage({ title: 'Confirm update schedule', message: 'You are updating a schedule that may affect participants signed up. This may cause cancellations from enrolled users. Are you sure you want to continue?', meta: scheduleupdate, confirm: true, action: 4 });
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })
                return;
            }
            else {
                setDialogMessage({ title: err.name, message: err.message, meta: scheduleupdate, confirm: true, action: 0 });
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })
                return;
            }
        }

    }

    const addAsyncSchedule = async (scheduleupdate: apiproto.ISchedule) => {
        try {
            const updateResult = await api.addSchedule(scheduleupdate);
            if (updateResult && updateResult.scheduleId) {
                scheduleupdate.scheduleId = updateResult.scheduleId;
            }
            // Need to refresh the dropdown for schedulesets;
            handleSnackClick('Schedule added...', "success")
            refreshScheduleSet();

            return updateResult;
        }

        catch (err: any) {
            tdrAlert('addAsyncSchedule' + err.name + ' ' + err.message);
            throw (err);
        }

    }

    const createTopicSubmitted = async (event: FormEvent) => {
        if (event) {
            event.preventDefault();
        }
        // Check
        let errMsg = "";
        const cost = Number(price);
        /* We do not test for this now to reduce friction. Anyone who signs up will be using our sub account first.
        if (cost > 0 && topicInfoState.costType !== apiproto.CostType.free) {
            if (!userInfo?.paymentAccountActive) {
                errMsg = "You need to set up your payment information in order to charge users for your session. Go to Account menu->Billing to set it up so you can accept credit card for your clients and also deposit your earnings.";
                setSubmitStatus({ isSubmitting: false, errorMessage: errMsg })
                return;
            }
        }
        */
        for (const roomSingle of topicInfoState.topicrooms) {
            if (roomSingle.scheduleSet?.schedules?.length === 0) {
                errMsg = "A room must have schedules filled in. If there are no schedules, you may want to delete the room before proceeding."
                if (isProfilePage) {
                    errMsg = "Please add a schedule before proceeding."
                }
                handleSnackClick(errMsg, "error");
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })
                // A room should not have 0 schedules in the schedule set
                return;
            }
            if (roomSingle.room === "v:meet" && !appIntegrationStatus.googleMeet) {
                errMsg = "You selected Google Meet as your session location. You have not integrated with Google Meet. Please make sure you integrate with Google Meet in Profile->App Integrations"
                handleSnackClick(errMsg, "error");
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })
                return;
            }
            if (roomSingle.room === "v:zoom" && !appIntegrationStatus.zoom) {
                errMsg = "You selected Zoom as your session location. You have not integrated with Zoom. Please make sure you integrate with Zoom in Profile->App Integrations"
                handleSnackClick(errMsg, "error");
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })

                //                setSubmitStatus({ isSubmitting: false, errorMessage: errMsg })
                return;
            }

        }

        if (cost === 0 && topicInfoState.costType !== apiproto.CostType.free) {
            const errorMsg = "Your cost cannot be $0 if your billing type is not free.";
            handleSnackClick(errorMsg, "error");
            setSubmitStatus({ isSubmitting: false, errorMessage: "" })

            //            setSubmitStatus({ isSubmitting: false, errorMessage: "Your cost cannot be $0 if your billing type is not free." })

            return;
        }

        createTopic(false);
    }
    const createTopic = async (force: boolean) => {
        // We set the price at final part so it takes a string to convert to number
        // else doing it on value change can cause some input issue and not able to input decimal
        setTopicInfoState({
            ...topicInfoState, cost: Number(price)
        });
        setSubmitStatus({ isSubmitting: true, errorMessage: "" })
        topicInfoState.topicrooms.forEach((roomSingle) => {
            if (roomSingle.scheduleSet?.schedules?.length === 0) {
                var errMsg = "A room must have schedules filled in. If there are no schedules, you may want to delete the room before proceeding."
                if (isProfilePage) {
                    errMsg = "Please add a schedule before proceeding."
                }
                handleSnackClick(errMsg, "error");
                setSubmitStatus({ isSubmitting: false, errorMessage: "" })

                //                setSubmitStatus({ isSubmitting: false, errorMessage: errMsg })
                // A room should not have 0 schedules in the schedule set
                return;
            }
            roomSingle.scheduleSet?.schedules!.forEach((scheduleSingle) => {

                /* I leave this here in case need to use back range picker which has a different way of representing the dates
                const dateinput = scheduleSingle.dateRange;
                if (dateinput instanceof Object) {
                    const dates = Object.values(dateinput)
                    if (dates.length == 1) {
                        scheduleSingle.fromDate = dates[0];
                        scheduleSingle.toDate = dates[0];
     
                    }
                    else {
                        scheduleSingle.fromDate = dates[0];
                        scheduleSingle.toDate = dates[1];
                    }
                }
                */
                // Reset error strings
                scheduleSingle.scheduleSetId = roomSingle.scheduleSetId;
                scheduleSingle.toTimeErr = "";
                scheduleSingle.toDateErr = "";
                if (scheduleSingle.fromDate.getTime() > scheduleSingle.toDate.getTime()) {
                    scheduleSingle.toDateErr = "End date must be later than start date";
                }
                scheduleSingle.startTime = convertDateToEpoch(scheduleSingle.fromDate, scheduleSingle.fromTime);
                scheduleSingle.repeatEndDate = convertDateToEpoch(scheduleSingle.toDate, "23:59");
                // TODO - confirm we dont need this now scheduleSingle.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                scheduleSingle.duration = computeDuration(scheduleSingle.fromTime, scheduleSingle.toTime);
                // Probably should reset all of it.
                //                scheduleSingle.daily = { interval: 0 };
                //               scheduleSingle.weekly = { weekDays: [0] };
                //              scheduleSingle.monthly = { monthlyDays: { monthlyDay: [0] } };
                delete scheduleSingle.daily;
                delete scheduleSingle.monthly;
                delete scheduleSingle.weekly;
                scheduleSingle.repeatCount = -1;
                if (scheduleSingle.frequency === SESSIONFREQUENCY.DAILY.valueOf()) {
                    scheduleSingle["daily"] = { interval: 1 };
                }
                else if (scheduleSingle.frequency === SESSIONFREQUENCY.WEEKLY.valueOf()) {

                    scheduleSingle["weekly"] = { interval: 1, weekDays: [scheduleSingle.fromDate.getDay()] };
                }
                else if (scheduleSingle.frequency === SESSIONFREQUENCY.WEEKLYCUSTOM.valueOf()) {
                    let selectedWeekDays: number[] = [];
                    for (let i = 0; i < 7; i++) {
                        if (scheduleSingle.weekDays && scheduleSingle.weekDays[i]) {
                            selectedWeekDays.push(i);
                        }
                    }
                    scheduleSingle["weekly"] = { interval: 1, weekDays: selectedWeekDays };
                }
                else if (scheduleSingle.frequency === SESSIONFREQUENCY.MONTHLY.valueOf()) {

                    scheduleSingle["monthly"] = { interval: 1, monthlyDays: { monthlyDay: [scheduleSingle.fromDate.getDate()] } };
                }
                else {
                    scheduleSingle.repeatCount = 0;
                    scheduleSingle.repeatEndDate = 0;
                }


            })
        });
        try {
            var topicObj = createTopicObj();
            const tmpDescription = encodeJSONObject({ ...fullDescription });
            const topicObjToWrite = { ...topicObj, description: JSON.stringify(tmpDescription) };
            tdrLog('TopicObjToWrite:' + JSON.stringify(topicObjToWrite));
            var topicid = topicInfoState.topicId;
            if (topicid) {
                await editTopic(topicObjToWrite, force);
            }
            else {
                const result = await api.createTopic(topicObjToWrite);
                if (result.topicId) {
                    topicid = result.topicId;
                    setTopicInfoState({
                        ...topicInfoState,
                        topicId: topicid
                    });
                }
            }
            if (selectedImage && topicid) {
                // We can only upload after we know what the topic id is.
                uploadBlob(topicid, selectedImage);
            }

        } catch (err: any) {
            tdrAlert('createTopicSubmitted' + err.name + ' ' + err.message);
            setTopicInfoState({
                ...topicInfoState,
            });
            setSubmitStatus({ isSubmitting: false, errorMessage: err.message })
            return;
        }
        if (!topicparams.id) {
            //setCreateSuccess(true);
            setSubmitStatus({ isSubmitting: false, errorMessage: "" })
            handleSnackClick('Topic saved...', "success")

        }

    }

    const createTopicObj = () => {
        const newtags: string[] = [];
        tags.forEach(tag =>
            newtags.push(tag.text)
        )
        // This section deals with the pricing
        var tmpcost = topicInfoState.cost;
        var tmpcurrency = topicInfoState.currency;
        var tmpcosttype = topicInfoState.costType
        try {
            tmpcost = Number(price);
        }
        catch (err: any) {
            // somehow user in UI still did not put price so we set to free
            tmpcurrency = "USD";
            tmpcost = 0;
            tmpcosttype = apiproto.CostType.free;
            // Nothing we can do here- just let price be whatever initial is then.
        }
        if (topicInfoState.costType === apiproto.CostType.free) {
            // Free requires this condition but we don't want to zero out the UI because users may switch back from free and don't want to retype price
            tmpcurrency = "";
            tmpcost = 0;

        }
        else if (topicInfoState.currency === null || topicInfoState.currency?.length === 0) {
            // Default to USD
            tmpcurrency = "USD";
        }
        // We check the scheduleset, if schedulesetID exists, then we don't put in the schedule
        // We also need to create copies so it does not reference into the original room and wipe out the schedule set
        const newrooms: apiproto.IRoom[] = [];
        const newroom = [...topicInfoState.topicrooms];
        newroom.forEach((room) => {
            const cloneroom = { ...room };
            if (cloneroom.scheduleSetId && cloneroom.scheduleSetId.includes("_")) {
                cloneroom.scheduleSet = null;
            }
            newrooms.push(cloneroom);
        }
        )
        var topicObj: apiproto.ITopic = { topicId: topicInfoState.topicId, name: topicInfoState.name, description: topicInfoState.description, minAge: topicInfoState.minAge, maxAge: topicInfoState.maxAge, maxParticipants: topicInfoState.maxParticipants, rooms: newrooms, members: [], tags: newtags, cost: tmpcost, costType: tmpcosttype, currency: tmpcurrency }
        if (topicInfoState.topicformat === SessionType.single) {
            // One time
            topicObj = { ...topicObj, singleSession: {} };
        }
        else if (topicInfoState.topicformat === SessionType.ongoing) {
            // Ongoing
            topicObj = { ...topicObj, ongoingSession: {} };
        }
        else if (topicInfoState.topicformat === SessionType.multimemberonday1) {
            // Multi session - join only on 1st sessopm
            topicObj = { ...topicObj, multiSession: { mustBeMemberBeforeFirstSession: true } };
        }
        else if (topicInfoState.topicformat === SessionType.multi) {
            // Multi session - join anytime
            topicObj = { ...topicObj, multiSession: { mustBeMemberBeforeFirstSession: false } };
        }
        return topicObj;

    }
    /******************************************************* 
     * 
     * Upload Topic Photo
     * 
     * *****************************************************/
    const [progress, setProgress] = useState(0);
    const [selectedImage, setSelectedImage] = useState<Blob>();
    const imagePhotoSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target!.files) {
            setSubmitStatus({ isSubmitting: true, errorMessage: "" })
            const file = e.target!.files[0];
            setSelectedImage(file);
            setImageUrl(URL.createObjectURL(file)!)
            if (topicparams.id) {
                handleSubmitPhoto(topicparams.id, e);
            }
        }
        else {
        }
        setSubmitStatus({ isSubmitting: false, errorMessage: "" })

    }

    const handleSubmitPhoto = async (objid: string, e: React.ChangeEvent<HTMLInputElement> | null) => {
        // There is no update asset so we just delete and reupload.
        /*
        var file = null;
        if (e && e.target && e.target.files) {
            file = e.target.files[0];
        }
        else if (selectedImage) {
            file = selectedImage;
        }*/
        if (e && e.target && e.target.files) {
            uploadBlob(objid, e.target.files[0]);
        }

    };

    const uploadBlob = async (objid: string, originalFile: Blob) => {
        setSubmitStatus({ isSubmitting: true, errorMessage: "" })
        setProgress(1);
        const file = await resizeFile(originalFile) as Blob;
        setImageUrl(URL.createObjectURL(file!))
        // This is separate because we want to deleteAsset anyway to clear the old asset and if nothing it will error out but it is ok
        try {
            await api.deleteAsset(topicInfoState.topicId!, SESSION_PHOTO_ASSET_NAME)
        }
        catch (err: any) {
            // TODO = what to do here besides pop up error message?
            tdrAlert('uploadBlob' + err.name + ' ' + err.message);
        }
        try {
            const assetInfo = await api.addAsset(objid, SESSION_PHOTO_ASSET_NAME, file.type, file.size);
            if (assetInfo.uploadUrl && file) {
                tdrLog(assetInfo.uploadUrl);
                // Using POST
                const formData = new FormData();
                formData.append("Content-Type", file.type);
                formData.append("file", file);
                axios.put(assetInfo.uploadUrl, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                    onUploadProgress: data => {
                        //Set the progress value to show the progress bar
                        const percent = Math.round((100 * data.loaded) / data.total);
                        setProgress(percent);
                    },
                })
                    .then((res) => {
                        if (topicparams.id) {
                            // Only show if it is an edit so not too many pop up will appear
                            setDialogMessage({ title: 'Upload Success', message: 'Your picture was uploaded successfully.', meta: {}, confirm: false, action: 0 });
                        }
                        setImageUrl(URL.createObjectURL(file)!)
                    })
                    .catch((err) => {
                        tdrAlert('uploadBlog 1' + err.name + ' ' + err.message);
                        setDialogMessage({ title: 'Upload Error', message: 'Your picture was unable to be uploaded successfully.', meta: {}, confirm: false, action: 0 });
                    });
            }
        }

        catch (err: any) {
            tdrAlert('uploadBlob2' + err.name + ' ' + err.message);
            setDialogMessage({ title: 'Upload Error', message: 'Your picture was unable to be uploaded successfully.', meta: {}, confirm: false, action: 0 });
        }
        setSubmitStatus({ isSubmitting: false, errorMessage: "" })
        setProgress(0);

    }

    const refreshScheduleSet = () => {
        tdrLog('refreshscheduleset===')
        if (user.userId && user.authenticated) {
            (async () => {
                const scheduleSets: ExtendedScheduleSet[] = [];
                const userInfoResult = await api.getUser({ integrations: {}, membersOf: { obj: { scheduleSet: { schedules: { times: {} } } } } }, { userId: user.userId });
                const userInfo = userInfoResult.user;
                if (userInfo) {
                    setAppIntegrationStatus(getAppIntegrationStatus(userInfo));
                    if (userInfo.membersOf) {
                        setUserInfo(userInfo);
                        userInfo.membersOf.forEach((m) => {
                            const r = m.obj?.scheduleSet;

                            // room?.forEach((r) => {
                            if (r) {
                                var newschedule = convertSchedulesToExtendedSchedules(r);
                                const extendedScheduleSet: ExtendedScheduleSet = { scheduleSetId: r.scheduleSetId, schedules: newschedule, name: r.scheduleSet?.name };
                                scheduleSets.push(extendedScheduleSet);
                            }
                            //})
                        })
                        setCurrentScheduleSet(scheduleSets);
                    }
                }
            })()
        }
    }

    const convertSchedulesToExtendedSchedules = (roomItem: apiproto.IRoom) => {
        var newschedule: ExtendedSchedule[] = [];

        roomItem.scheduleSet?.schedules!.forEach((schedule, index1) => {
            const fromDate: Date = new Date(+schedule.startTime!.toString());
            const endDate: number = +schedule.startTime!.toString() + +schedule.duration!;
            let frequencyStr: string = SESSIONFREQUENCY.NOREPEAT.toString();
            let weekdays: boolean[] = [false, false, false, false, false, false, false];
            let weekdaysStr: string = "";
            if (schedule.weekly) {
                frequencyStr = SESSIONFREQUENCY.WEEKLY.toString();
                if (schedule.weekly.weekDays) {
                    // If it is weekly, we check if there are multiple days selected
                    for (let i = 0; i < schedule.weekly.weekDays.length; i++) {
                        weekdays[schedule.weekly.weekDays[i]] = true;
                    }
                    if (schedule.weekly.weekDays.length > 1) {
                        frequencyStr = SESSIONFREQUENCY.WEEKLYCUSTOM.toString();
                        weekdaysStr = customDaysStr(weekdays);
                    }
                }
            }
            else if (schedule.monthly) {
                frequencyStr = SESSIONFREQUENCY.MONTHLY.toString();
            }
            else if (schedule.daily) {
                frequencyStr = SESSIONFREQUENCY.DAILY.toString();
            }
            const scheduleinitial: ExtendedSchedule = {
                // scheduleId: "",
                scheduleId: schedule.scheduleId,
                scheduleSetId: schedule.scheduleSetId,
                startTime: schedule.startTime,
                duration: schedule.duration,
                timeZone: schedule.timeZone,
                repeatCount: schedule.repeatCount, // For now we don't do repeat count until we add custom schedule
                repeatEndDate: schedule.repeatEndDate,
                // This is the original one for UI
                dateRange: new Date(),
                fromDate: fromDate,
                toDate: schedule.repeatCount === 0 ? fromDate : new Date(+schedule.repeatEndDate!.toString()),
                fromTime: computeEpochtoTimeNoAMPMStr(schedule.startTime!.toString(), schedule.timeZone),
                toTime: computeEpochtoTimeNoAMPMStr(endDate.toString(), schedule.timeZone),
                frequency: frequencyStr,
                // Customize error:
                toDateErr: "",
                toTimeErr: "",
                durationStr: 'End time',
                weekDays: weekdays,
                weekDaysStr: weekdaysStr,
                generalErr: "",
            }
            newschedule = [...newschedule, scheduleinitial];
        })
        return newschedule;
    }

    useEffect(() => {
        // load course
        (async () => {
            try {
                var totalRoomMembers = 0;
                refreshScheduleSet();
                if (topicparams.id) {
                    if (checkIfTopicProfile(topicparams.id)) {
                        setIsProfilePage(true);
                    }
                    const result = await api.getTopic({ includeDescription: true, assets: {}, tags: {}, rooms: { members: {}, scheduleSet: { schedules: { times: { members: {} } } } } }, topicparams.id);
                    if (result) {
                        tdrLog("getTopic:" + JSON.stringify(result))

                        const info = result.topic;
                        if (info) {
                            tdrLog(JSON.stringify('getTopic ' + JSON.stringify(info)))

                            if (info.cost) {
                                setPrice(info.cost.toString());
                            }
                            if (info?.assets) {
                                setImageUrl(getTopicPhotoURL(info!))
                            }
                            if (info?.rooms) {
                                // We now need to convert this to more UI friendly way.
                                var topicRooms: ExtendedRoom[] = [];
                                info?.rooms.forEach((roomItem, index) => {
                                    var newschedule = convertSchedulesToExtendedSchedules(roomItem);
                                    topicRooms = [...topicRooms,
                                    {
                                        ...roomItem,
                                        /*
                                        topicId: roomItem.topicId!,
                                        scheduleSetId: roomItem.scheduleSetId,
                                        roomId: roomItem.roomId!,
                                        section: roomItem.section,
                                        room: roomItem.room,*/
                                        room: roomItem.room ? roomItem.room : "v:td",
                                        roomKey: roomItem.roomId!,
                                        scheduleSet: { name: roomItem.scheduleSet?.name, schedules: newschedule, scheduleSetId: roomItem.scheduleSetId },
                                        existingRoom: true,
                                        scheduleSelection: "0", // This is for UI ,
                                        scheduleSetOpen: null

                                    }
                                    ];
                                    if (roomItem.memberCount && roomItem.memberCount! > 0) {
                                        totalRoomMembers++;
                                    }
                                }
                                )
                                setTotalEnrolledMembers(totalRoomMembers);
                                if (info) {
                                    const newtags: Tag[] = [];
                                    info.tags?.forEach((tag: string) => {
                                        newtags.push({ id: tag, text: tag });
                                    })
                                    setTags(newtags);
                                    var topicFormat = SessionType.multi;
                                    if (info.singleSession) {
                                        topicFormat = SessionType.single;
                                    }
                                    else if (info.ongoingSession) {
                                        topicFormat = SessionType.ongoing;
                                    }
                                    else if (info.multiSession?.mustBeMemberBeforeFirstSession) {
                                        topicFormat = SessionType.multimemberonday1;
                                    }
                                    const copiedTopicState: ExtendedTopic = {
                                        topicId: info.topicId,
                                        name: info.name,
                                        description: info.description,
                                        minAge: info.minAge,
                                        maxAge: info.maxAge,
                                        maxParticipants: info.maxParticipants,
                                        topicrooms: topicRooms,
                                        category: 0,
                                        topicformat: topicFormat,
                                        tags: info.tags,
                                        cost: info.cost,
                                        currency: (info.costType === apiproto.CostType.free) ? "" : (info.currency ? info.currency : "USD"),
                                        costType: info.costType
                                    };
                                    setTopicInfoState(copiedTopicState)
                                    if (info === null || info.description?.length === 0) {
                                        setFullDescription({ summary: "", description: "", prerequisites: "", supply_list: "", assignments: "", time_commitment: "" }
                                        );
                                    }
                                    else {
                                        var obj = JSON.parse(info.description!);
                                        // Since we stick about_me as a JSON meta to support profile, but we don't use it in topic , we take care of it here.
                                        delete obj.about_me;
                                        setFullDescription(obj)
                                    }
                                }
                            }
                        }
                    }

                }
            } catch (err: any) {
                tdrAlert('use effect' + err.name + ' ' + err.message);
                tdrLog('error loading topic:' + err);
            }
        })();
    }, [topicparams.id]);

    const priceChange = (value: string | undefined) => {
        if (value) {
            setPrice(value);
        }
        else {
            setPrice("0");
        }
    }

    const changeRoom = async (room: ExtendedRoom | null | undefined, newValue: string | undefined, refreshUser?: boolean) => {
        if (!room || room === null || !newValue) return true;
        let integrationStatus = appIntegrationStatus;
        if (refreshUser) {
            // We don't want to fetch everything just user info.
            const userInfoResult = await api.getUser({ integrations: {} }, { userId: user.userId });
            const tmpUserInfo = userInfoResult.user;
            if (tmpUserInfo && tmpUserInfo !== null && tmpUserInfo.integrations) {
                integrationStatus = getAppIntegrationStatus(tmpUserInfo);
                setAppIntegrationStatus(integrationStatus);
                setUserInfo({ ...userInfo, integrations: tmpUserInfo.integrations })
            }
        }
        // timeout to avoid instant validation of the dialog's form.
        if (newValue === "Google Meet") {

            if (integrationStatus.googleMeet) {
                room.room = "v:meet"
            }
            else {
                if (!refreshUser) {
                    changeRoom(room, newValue, true);
                    return true;
                }
                else {
                    room.room = ""; // this line is needed for some weird MUI Autocomplete reason - do not delete. We want any non integrated app to jump back to default v:td, but will not work if the original choice was v:td. Don't ask me why- just some dumb state in mui
                    setDialogMessage({ title: 'Google Meet Integration', message: 'You selected Google Meet as your session location. You have not integrated with Google Meet. Do you want to go to app integrations page to connect to Google Meet?', meta: {}, confirm: true, action: 20 });
                    room.room = "v:td";
                    return true;
                }
            }
        }
        else if (newValue === "Zoom Video") {
            if (integrationStatus.zoom) {
                room.room = "v:zoom"
            }
            else {
                if (!refreshUser) {
                    changeRoom(room, newValue, true);
                    return true;
                }
                else {
                    room.room = ""; // this line is needed for some weird MUI Autocomplete reason - do not delete. We want any non integrated app to jump back to default v:td, but will not work if the original choice was v:td. Don't ask me why- just some dumb state in mui
                    setDialogMessage({ title: 'Zoom Integration', message: 'You selected Zoom as your session location. You have not integrated with Zoom. Do you want to go to app integrations page to connect to Zoom?', meta: {}, confirm: true, action: 20 });
                    room.room = "v:td";
                    return true;
                }
            }
        }
        else if (newValue === "Timedora Video") {
            room.room = "v:td";
        }
        else {
            room.room = newValue;
        }
        setRoomScheduleState([...roomScheduleState]);
        return false;
    }

    const ScheduleLineInput = ({ room, schedule }: { room: ExtendedRoom | null, schedule: ExtendedSchedule | null }) => {
        if (room === null || schedule === null) return (<div></div>);
        return (
            <Fragment>

                {(room && schedule) &&
                    <Row>
                        <Col sm="6" md="6">
                            <Row>
                                <Col xl="6">
                                    <b>
                                        {(moment(schedule.fromDate)).format('ddd, MMM Do')}
                                        {schedule.fromDate !== schedule.toDate && <span>
                                            -
                                            {(moment(schedule.toDate)).format('ddd, MMM Do')}</span>
                                        }                            </b>
                                </Col>
                                <Col xl="6">
                                    {schedule.frequency === SESSIONFREQUENCY.NOREPEAT.valueOf() ? 'Does not repeat' :
                                        (schedule.frequency === SESSIONFREQUENCY.DAILY.valueOf() ? 'Daily' :
                                            (schedule.frequency === SESSIONFREQUENCY.WEEKLY.valueOf() ? 'Weekly on ' + daysOfWeek[schedule.fromDate.getDay()] :
                                                (schedule.frequency === SESSIONFREQUENCY.MONTHLY.valueOf() ? 'Monthly on the ' + getWeekOfMonthStr(schedule.fromDate) + ' ' + daysOfWeek[schedule.fromDate.getDay()] : (schedule.frequency === SESSIONFREQUENCY.WEEKLYCUSTOM ? 'Weekly ' + schedule.weekDaysStr : ""))))
                                    }
                                </Col>

                            </Row>
                        </Col>
                        <Col sm="6" md="4">
                            <Row>
                                <Col xl="6">
                                    {computeEpochtoTimeStr(schedule.startTime!.toString(), schedule.timeZone)} - {computeEpochtoTimeStr((+schedule.startTime!.toString() + +schedule.duration!).toString(), schedule.timeZone)} ({computeDurationToHrMin(computeDuration(schedule!.fromTime, schedule!.toTime
                                    ))}
                                    )
                                </Col>
                                <Col xl="6">
                                    {getTimeZoneAbbrev(schedule.timeZone)}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm="12" md="2">
                            <IconButton sx={{ float: 'right', padding: '0px 0px 12px 8px' }} size="large" color="primary" aria-label="Delete Schedule" component="span" hidden={(room!.scheduleSet && room.scheduleSet.schedules) ? (room.scheduleSet.schedules?.length <= 1) : false} onClick={() => {
                                deleteSchedule(schedule!.scheduleId!, room!.roomId!, room!.scheduleSetId)
                            }}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton sx={{ float: 'right', padding: '0px 0px 12px 8px' }} size="large" color="primary" aria-label="Delete Schedule" component="span" onClick={() => {
                                editSchedule(room, schedule);
                            }}>
                                <EditIcon />
                            </IconButton>
                            <IconButton sx={{ float: 'right', padding: '0px 0px 12px 8px' }} size="large" color="primary" aria-label="Edit Time" hidden={!schedule.scheduleId?.startsWith("s_")}
                                component="span" onClick={() => {
                                    editTime(room, schedule);
                                }}>
                                <DateRangeIcon />
                            </IconButton>

                        </Col>
                        <hr />
                    </Row>
                }
            </Fragment>)
    }

    const isScheduleSetUsed = (schedulesetid: string | null | undefined) => {
        if (schedulesetid && schedulesetid !== null) {
            return topicInfoState.topicrooms?.some((singleRoom) =>
                singleRoom.scheduleSet?.scheduleSetId === schedulesetid
            )
        }
        return false;
    }

    return (
        <Layout headerBtn footer={3}>
            <PageTitle pageTitle="Create Topic - Timedora" />
            {/*-- Dialog Boxes and Pop overs to support workflow--*/}
            <ScheduleNameChangePopover room={activeEditedSchedule?.room} meta={""} onCancel={handleScheduleNameChangeClose} visible={scheduleInfoNameChangeOpen} onConfirm={handleScheduleNameChangeDone} />
            <ScheduleInfoPopover topic={topicInfoState} room={activeEditedSchedule?.room} schedule={activeEditedSchedule?.schedule} time={null} visible={scheduleInfoOpen} meta={{ room: null, schedule: null }} onCancel={handleScheduleInfoClose} onConfirm={handleScheduleInfoDone} editMode={topicparams.id ? true : false} editTime={editTimeMode} />
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={handleCancel} onConfirm={handleActionConfirm} visible={dialogMessage.title.length > 0} />
            <DialogMessageBox title={topicparams.id ? (isProfilePage ? "Schedule updated" : "Topic Page Updated") : (isProfilePage ? "Schedule created" : "Topic Page Created")} message={topicparams.id ? (isProfilePage ? "Your schedule has been updated" : "Your topic page was updated successfully.") : (isProfilePage ? "Your schedule is created." : "Your topic page was created successfully. You will need to publish your topic when ready so users can view your page.")} meta={""} confirm={""} cancel={"Dismiss"} onConfirm={dialogNoOp} onCancel={onPreview} visible={createSuccess} />
            <TimeTablePopover topic={topicInfoState!} roomid={null} schedule={activeEditedSchedule ? activeEditedSchedule?.schedule : null} visible={timeTableOpen} onCancel={handleCancel} onConfirm={handleCancel} listingType={false} />

            <Snackbar key={snackMessageInfo ? snackMessageInfo.key : undefined}
                open={snackOpen}
                sx={{ width: '80%' }}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                TransitionProps={{ onExited: handleSnackExited }}

            >
                <Alert action={
                    <React.Fragment>
                        {(snackMessageInfo && snackMessageInfo?.information && snackMessageInfo?.information === "success") &&
                            <Button color={'info'} size="small" onClick={onClose}>
                                Preview
                            </Button>
                        }
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5, marginLeft: '20px' }}
                            onClick={handleSnackClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </React.Fragment>
                } sx={{ width: "100%" }} severity={(snackMessageInfo && snackMessageInfo?.information && snackMessageInfo?.information === "success") ? "success" : ((snackMessageInfo && snackMessageInfo?.information && snackMessageInfo?.information === "error") ? "error" : "info")}>
                    {snackMessageInfo ? snackMessageInfo.snackMessage : undefined}
                </Alert>

            </Snackbar>


            {/*-- Signup Screen 
            // Original
            <div className="course-wrapper">
                <div className="course-inner course-innerpadding">
            --*/}
            <RequireAuth>

                <div className="course-wrapper course-top">
                    <div className="course-innerpadding">
                        <ValidatorForm onSubmit={createTopicSubmitted}>
                            <h3>{isProfilePage ? "Personal Availability" : (topicparams.id ? 'Update' : 'Create')}</h3>
                            <hr />
                            {/*===================== Summary Description Pricing etc ==================*/}
                            <div>
                                <Row>
                                    <Col lg={7}>
                                        {!isProfilePage &&
                                            <Fragment>
                                                <h4>Topic Details</h4>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <div className="form-group form-input">
                                                            <TextValidator
                                                                label="Enter title"
                                                                type="text"
                                                                value={topicInfoState.name}
                                                                onChange={handleInputChange}
                                                                name="name"
                                                                id="name"
                                                                className="form-control"
                                                                placeholder="Enter topic title"
                                                                validators={['required']}
                                                                errorMessages={['Topic title is required']}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="form-group form-input">
                                                            <ReactTags
                                                                tags={tags}
                                                                delimiters={[KeyCodes.comma, KeyCodes.enter]}
                                                                handleDelete={handleDelete}
                                                                handleAddition={handleAddition}
                                                                inputFieldPosition="inline"
                                                                placeholder="Enter tags"
                                                                autocomplete
                                                                autofocus={false}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-group form-input">
                                                            <SelectValidator
                                                                id="topicformat"
                                                                name="topicformat"
                                                                value={topicInfoState.topicformat}
                                                                className="form-control"
                                                                label="Choose Topic Format"
                                                                disabled={topicparams.id ? ((totalEnrolledMembers > 0 || (topicInfoState.singleSession !== null)) ? true : false) : false}
                                                                onChange={handleSelectChange}>
                                                                <MenuItem value={SessionType.single}>One Time</MenuItem>
                                                                <MenuItem value={SessionType.ongoing}>Ongoing</MenuItem>
                                                                <MenuItem value={SessionType.multi}>Multi Sessions (Join anytime)</MenuItem>
                                                                <MenuItem value={SessionType.multimemberonday1}>Multi Sessions (Must join first session)</MenuItem>
                                                            </SelectValidator>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Fragment>
                                        }
                                        <Row>
                                            {/*
                                                <Col>
                                                    <div className="form-group form-input">
                                                        <SelectValidator
                                                            id="category"
                                                            name="category"
                                                            value={topicInfoState.category}
                                                            className="form-control"
                                                            label="Choose Category"
                                                            onChange={handleSelectChange}
                                                        >
                                                            <MenuItem value={0}>Choose the category</MenuItem>
                                                            <MenuItem value={10}>Accounting</MenuItem>
                                                            <MenuItem value={20}>Coaching</MenuItem>
                                                            <MenuItem value={30}>Financial Advisor</MenuItem>
                                                            <MenuItem value={40}>Investor</MenuItem>
                                                            <MenuItem value={50}>Lawyer</MenuItem>
                                                            <MenuItem value={60}>Startup Advisor</MenuItem>
                                                            <MenuItem value={70}>Software Consultant</MenuItem>
                                                            <MenuItem value={80}>Teacher</MenuItem>
                                                            <MenuItem value={90}>Tutor</MenuItem>
                                                            <MenuItem value={100}>Others</MenuItem>
                                                        </SelectValidator>
                                                    </div>

                                                </Col>
                            */}
                                            <Col xs="12" sm="6">
                                                <div className="form-group form-input">
                                                    <SelectValidator
                                                        id="costtype"
                                                        name="costType"
                                                        value={topicInfoState.costType}
                                                        className="form-control"
                                                        label="Choose billing type"
                                                        onChange={handleSelectChange}
                                                    >
                                                        <MenuItem value={apiproto.CostType.free}>Free</MenuItem>
                                                        {/* user pays for all cost upfront. This applies to multi-session or single-session only */}
                                                        <MenuItem hidden={topicInfoState.topicformat === SessionType.ongoing ? true : false} value={apiproto.CostType.cost_all_upfront}>Upfront</MenuItem>
                                                        {/* user pays cost of the room time session, charged monthly at the start of the month. user is charged the first month's sessions immediately on sign up (Even if user signs up ahead of time in an earlier month)
                                                this applies to multi-session or on-going sessions */}
                                                        <MenuItem hidden={(topicInfoState.topicformat === SessionType.single ? true : false)} value={apiproto.CostType.cost_per_time_charged_as_you_go}>Per session completed</MenuItem>
                                                        {/* user pays fixed cost per month, charged at the start of the month regardless of sessions. On signup, cost will be pro-rated based on number of days left in the month divided by number of days in the month and charged immediately. this applies to ongoing sessions only */}
                                                        <MenuItem hidden={(topicInfoState.topicformat === SessionType.ongoing ? false : true)} value={apiproto.CostType.cost_per_month}>Per month</MenuItem>
                                                    </SelectValidator>
                                                </div>

                                            </Col>

                                            <Col xs="auto" hidden={topicInfoState.costType === apiproto.CostType.free}>
                                                <div className="form-group form-input">
                                                    <SelectValidator
                                                        id="currency"
                                                        name="currency"
                                                        value={topicInfoState.currency ? topicInfoState.currency : "USD"}
                                                        className="form-control"
                                                        label="Currency"
                                                        onChange={handleSelectChange}
                                                        sx={{ width: 100 }}
                                                    >
                                                        <MenuItem value={"USD"}>USD</MenuItem>
                                                        <MenuItem value={"CAD"}>CAD</MenuItem>
                                                        <MenuItem value={"GBP"}>GBP</MenuItem>
                                                    </SelectValidator>
                                                </div>
                                            </Col>
                                            <Col xs="auto" hidden={topicInfoState.costType === apiproto.CostType.free}>
                                                <div className="form-group form-input">
                                                    {/* TO DO - need to pull locale from user profile */}
                                                    <CurrencyInput className="currencyInput"
                                                        intlConfig={{ locale: 'en-US', currency: topicInfoState.currency! }}
                                                        id="cost"
                                                        name="cost"
                                                        placeholder="Enter Price"
                                                        value={price}
                                                        defaultValue={0}
                                                        decimalsLimit={2}
                                                        allowDecimals={true}
                                                        onValueChange={(value, name) => { priceChange(value) }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {!isProfilePage &&
                                        <Col lg={5} className="text-center">
                                            {/*===================== Topic Photo Upload ==================*/}
                                            <input accept="image/*" id="contained-image-file"
                                                type="file" style={{ display: 'none' }} onChange={e => { imagePhotoSelected(e) }} />
                                            {
                                                (imageUrl) ? (
                                                    < img alt="topic image" src={imageUrl} width="300px" />
                                                ) : <img alt="No image" src="/images/image-04.png" width="300px" />}
                                            <LinearProgressWithLabel sx={{ align: "center" }} value={progress} width={300} />

                                            <br />
                                            <label htmlFor="contained-image-file">
                                                <Button sx={{ width: 300 }} variant="text" size="large" color="primary" component="span" startIcon={<PhotoCamera />} disabled={submitStatus.isSubmitting}>
                                                    {submitStatus.isSubmitting ? "Uploading..." : "Upload Photo"}
                                                </Button>
                                            </label>
                                        </Col>
                                    }
                                </Row>
                                {!isProfilePage &&
                                    <Fragment>
                                        <hr />
                                        <div className="form-group form-input">
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                                                    {Object.keys(fullDescription).map((descriptionKey: string, index) => (
                                                        < Tab key={'descTab' + index} label={descriptionKey.replace("_", " ")} {...a11yProps(index)} />
                                                    ))}
                                                </Tabs>
                                            </Box>
                                            {Object.keys(fullDescription).map((descriptionKey: string, index) => {
                                                return (
                                                    <div key={'descriptiontext' + index}
                                                        role="tabpanel"
                                                        hidden={tabValue !== index}
                                                        id={`simple-tabpanel-${index}`}
                                                        aria-labelledby={`simple-tab-${index}`}
                                                    >
                                                        {tabValue === index && (

                                                            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                                                                <Typography> <textarea
                                                                    key={"metatextarea" + index}
                                                                    minLength={6}
                                                                    maxLength={4096}
                                                                    rows={5}
                                                                    onChange={handleTextAreaChange}
                                                                    name={descriptionKey}
                                                                    id={descriptionKey}
                                                                    className="form-control" placeholder={placeholderDescriptionText[index]}
                                                                    value={(Object.values(fullDescription)[index])} /></Typography>
                                                            </Box>
                                                        )}
                                                    </div>
                                                )

                                            })}
                                        </div>
                                        <Row>
                                            <Col xs="auto">
                                                <div className="form-group form-input">
                                                    <TextValidator minLength={1}
                                                        maxLength={4}
                                                        label="Enter minimum age"
                                                        className="form-control"
                                                        placeholder="8-100 (Optional)" name="minAge" value={topicInfoState.minAge} onChange={handleInputChange}
                                                        validators={['required', 'minNumber:8', 'maxNumber:100']}
                                                        style={{ width: 180 }}
                                                        errorMessages={['Enter a number between 8-100']}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="auto">
                                                <div className="form-group form-input">
                                                    <TextValidator label="Enter maximum age"
                                                        minLength={1}
                                                        maxLength={4}
                                                        className="form-control"
                                                        placeholder="8-100 (Optional)" name="maxAge" value={topicInfoState.maxAge} onChange={handleInputChange}
                                                        style={{ width: 180 }}
                                                        validators={['minNumber:8', 'maxNumber:100']}
                                                        errorMessages={['Enter a number between 8-100']}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs="auto">
                                                <div className="form-group form-input">
                                                    <TextValidator placeholder="1-1000 (Optional)"
                                                        label="Enter maximum participants"
                                                        minLength={1}
                                                        maxLength={4}
                                                        style={{ width: 180 }}
                                                        className="form-control"
                                                        name="maxParticipants" value={topicInfoState.maxParticipants} onChange={handleInputChange}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>}
                            </div>

                            {/*===================== TOPIC SCHEDULE ==================*/}
                            {topicInfoState.topicrooms!.map((room, index1) =>
                                <div className="form-group" key={room.roomId}>
                                    <br />
                                    <Paper style={{ padding: 20, }} elevation={6}>
                                        {isProfilePage ? <h4>Set schedule</h4> :
                                            <Row>
                                                <Col>
                                                    <h4>Session {index1 + 1} </h4>
                                                </Col>

                                                <Col xs="auto">
                                                    <Button
                                                        startIcon={<DeleteIcon />}
                                                        hidden={topicInfoState.topicrooms.length <= 0}
                                                        sx={{ mr: 2, float: 'right' }} size="medium"
                                                        color="primary" aria-label="upload picture" component="span" onClick={() => {
                                                            deleteRoom(room);
                                                        }}>
                                                        Delete Room
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        <br />
                                        <Row>
                                            <Col sm="6">
                                                <div className="form-group form-input">
                                                    <Autocomplete
                                                        value={room.room === "v:meet" ? "Google Meet" : (room.room === "v:zoom" ? "Zoom Video" : ((room.room === "v:td") ? "Timedora Video" : (room.room ? room.room : '')))}
                                                        onChange={(event, newValue) => {
                                                            event.preventDefault();
                                                            if (typeof newValue === 'string') {
                                                                changeRoom(room, newValue);
                                                                setTimeout(() => {
                                                                    //toggleOpen(true);
                                                                });
                                                            } else if (newValue && newValue.inputValue) {
                                                                room.room = newValue?.inputValue;
                                                                //toggleOpen(true);
                                                            } else {
                                                                // This is when option is selected
                                                                changeRoom(room, newValue?.title)
                                                                setRoomScheduleState([...roomScheduleState]);

                                                            }
                                                        }}
                                                        id="locationType"
                                                        options={conferenceType}
                                                        getOptionLabel={(option) => {
                                                            // e.g value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            if (option.inputValue) {

                                                                return option.inputValue;
                                                            }

                                                            return option.title;
                                                        }}
                                                        selectOnFocus
                                                        handleHomeEndKeys
                                                        renderOption={(props, option) => <li {...props}><img style={{ marginRight: '20px' }} alt={option.title} src={"/images/apps/" + option.image} width="32" height="32" />{option.title}</li>}
                                                        freeSolo
                                                        renderInput={(params) => < TextField {...params} label="Enter Location (required)"
                                                        />}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="6">
                                                <div className="form-group form-input">
                                                    <TextValidator label="Enter Section (Optional)"
                                                        minLength={1}
                                                        maxLength={32}
                                                        className="form-control"
                                                        placeholder="(Optional)" name="section" value={room.section}
                                                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                            room.section = event.target.value;
                                                            setRoomScheduleState([...roomScheduleState]);

                                                        }}

                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Paper style={{ padding: 10, }} elevation={4}>
                                            {!isProfilePage &&
                                                <Row className="align-items-center">
                                                    <Col xs="auto">

                                                        <div>
                                                            {room.scheduleSetId === null ?
                                                                <TextValidator label="Name schedule set (required)"
                                                                    key="schedulesetnew"
                                                                    minLength={1}
                                                                    maxLength={32}
                                                                    sx={{ width: 260 }}
                                                                    validators={['required']}
                                                                    className="form-control"
                                                                    placeholder="(Required)" name="scheduleSetName"
                                                                    value={room.scheduleSet?.name}
                                                                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                        room.scheduleSet!.name = event.target.value;
                                                                        setRoomScheduleState([...roomScheduleState]);
                                                                    }}
                                                                    errorMessages={['Naming your schedule set allows you to share the schedule with your other topic pages.']}
                                                                /> : <div className="scheduleSetName">{room.scheduleSet?.name}</div>}
                                                        </div>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <IconButton sx={{ float: 'right' }} size="large" color="primary" aria-label="Delete Schedule" component="span" hidden={room.scheduleSetId === null || isProfilePage} onClick={() => {
                                                            changeScheduleSetName(room);
                                                        }}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Col>
                                                    <Col>
                                                        {(topicInfoState && topicInfoState.topicformat === SessionType.single) &&
                                                            <Button
                                                                id="basic-button"
                                                                variant="text"
                                                                size="medium"
                                                                aria-controls={Boolean(room.scheduleSetOpen) ? 'basic-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={Boolean(room.scheduleSetOpen) ? 'true' : undefined}
                                                                onClick={(e) => {
                                                                    room.scheduleSetOpen = e.currentTarget;
                                                                    setRoomScheduleState([...roomScheduleState]);
                                                                }}
                                                                hidden={currentScheduleSet.length === 0 || isProfilePage}
                                                            >
                                                                Schedule Set
                                                            </Button>
                                                        }
                                                        <Menu
                                                            id={'basic-menu' + room.roomId}
                                                            anchorEl={room.scheduleSetOpen}
                                                            open={Boolean(room.scheduleSetOpen)}
                                                            onClose={() => {
                                                                room.scheduleSetOpen = null;
                                                                setRoomScheduleState([...roomScheduleState]);
                                                            }}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}
                                                        >
                                                            {currentScheduleSet.map((scheduleSetSingle, index) =>
                                                                <MenuItem disabled={isScheduleSetUsed(scheduleSetSingle.scheduleSetId)} sx={{ minWidth: 400 }} key={scheduleSetSingle.scheduleSetId} value={room.scheduleSelection}
                                                                    onClick={(event) => {
                                                                        if (isScheduleSetUsed(scheduleSetSingle.scheduleSetId) === true) {
                                                                            setDialogMessage({ title: 'Schedule Set', message: 'You cannot share the same schedule set within the same topic.', meta: '', confirm: false, action: 0 });
                                                                            return;
                                                                        }
                                                                        room.scheduleSelection = scheduleSetSingle.scheduleSetId!;
                                                                        room.scheduleSetOpen = null;
                                                                        handleScheduleSetChange(room.scheduleSelection, room.roomId);
                                                                        setRoomScheduleState([...roomScheduleState]);
                                                                    }}>
                                                                    Schedule #{index} - {scheduleSetSingle?.name}
                                                                </MenuItem>)}
                                                            <MenuItem sx={{ minWidth: 400 }} onClick={(event) => {
                                                                room.scheduleSetOpen = null;
                                                                room.scheduleSet = { scheduleSetId: null, name: "", schedules: [] };
                                                                room.scheduleSetId = null;
                                                                handleScheduleSetChange(null, room.roomId);
                                                                setRoomScheduleState([...roomScheduleState]);
                                                                addSchedule(room);

                                                            }}>
                                                                Create New Schedule Set
                                                            </MenuItem>
                                                        </Menu>
                                                    </Col>
                                                </Row>
                                            }
                                            {room.scheduleSet?.schedules!.map((schedule, index) =>
                                                <div key={schedule.scheduleId} className="mt-15">
                                                    <ScheduleLineInput room={room} schedule={schedule} />
                                                </div>
                                            )}
                                            {room.scheduleSet?.schedules?.length === 0 ? <div className="divCenter"><br /><br /><b>Start by adding schedules and setting your availability. Tap on "Add Schedule" button</b><br /><br /></div> : ""}
                                            <Row>
                                                <Col>
                                                    <Button hidden={room.scheduleSet ? room.scheduleSet?.schedules!.length > 9 : true} sx={{ mr: 2, float: 'right' }} size="medium" variant="text" startIcon={<ScheduleIcon />} onClick={(e) => { e.preventDefault(); addSchedule(room) }}>
                                                        Add Schedule
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Paper>

                                    </Paper>

                                </div>

                            )}
                            <div className="text-center">
                                {submitStatus.errorMessage && (
                                    <span className="form-error"><br />{submitStatus.errorMessage}</span>
                                )}
                                <br />
                                <br />
                                <Grid container direction="row-reverse" spacing={1} alignContent="flex-end">                                        {/*topicInfoState.topicrooms.length > 0 &&*/}
                                    <Grid item xs="auto">
                                        <LoadingButton
                                            sx={{ marginBottom: '60px' }}
                                            size="large"
                                            variant="contained"
                                            loading={submitStatus.isSubmitting}
                                            loadingPosition="start" type="submit"
                                            disabled={((!isProfilePage && (topicInfoState.name?.length === 0 || fullDescription.summary.length === 0)) || (isProfilePage && topicInfoState.topicrooms && topicInfoState.topicrooms.length > 0 && topicInfoState.topicrooms[0].scheduleSet?.schedules?.length === 0))}
                                            startIcon={<AddIcon />}>
                                            {topicInfoState.topicId ? 'Save Changes' : 'Create Topic'}
                                        </LoadingButton>
                                    </Grid>
                                    {/* End of commenting out checking room length*/}
                                    {!isProfilePage &&
                                        <Grid item xs="auto">
                                            <Button hidden={topicInfoState.topicrooms.length > 9 || (topicInfoState.topicrooms.length === 1 && topicInfoState.topicrooms[0].scheduleSet?.schedules?.length === 0)} size="large" variant="contained" startIcon={<AddIcon />} disabled={(topicInfoState.name?.length === 0 || fullDescription.summary.length === 0)}
                                                onClick={addRoom}>
                                                {topicInfoState.topicrooms.length === 0 ? "Set Schedule" : "Add Room"}
                                            </Button>
                                        </Grid>
                                    }
                                    <Grid item xs="auto">
                                        <Button sx={{ backgroundColor: "gray" }} size="large" variant="contained" onClick={onClose}>
                                            {isProfilePage ? 'Back to Profile' : (topicInfoState.topicId ? 'Preview' : 'Cancel')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </ValidatorForm>
                    </div >
                </div >
            </RequireAuth>
        </Layout >
    );

};


interface LocationType {
    inputValue?: string;
    title: string;
    image?: string;
}

const conferenceType: readonly LocationType[] = [
    { title: 'Timedora Video', image: "app_timedora_icon.png" },
    { title: 'Google Meet', image: "app_google_meet_icon.png" },
    { title: 'Zoom Video', image: "app_zoom_icon.png" },
];


export default TopicCreate;

