import { Participant } from "livekit-client";
import React, { useContext, useEffect, useState } from "react";
import { ConferenceProps } from "./ConferenceView"
import { UserView } from "./UserView";
import "./desktop.css";
import { DisplayContext } from "./DisplayContext";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

export const GridView = ({
    roomState,
    joinLeaveClicked,
    chatClicked,
    onLeave,
}: ConferenceProps) => {
    const { isConnecting, error, participants, room } = roomState;
    const [visibleParticipants, setVisibleParticipants] = useState<Participant[]>(
        []
    );
    const [gridClass, setGridClass] = React.useState("conf-desktop-grid1x1");
    const context = useContext(DisplayContext)
    const hasWindow = typeof window !== 'undefined';
    const [aspectRatio, setAspectRatio] = useState(1.33);
    const [windowSize, setWindowSize] = useState({
        x: 320,
        y: 240
    });
    const controlBarHeight = 100;
    const handleResize = () => {

        if (hasWindow) {
            setWindowSize({
                x: window.innerWidth,
                y: window.innerHeight
            });
        }

    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [hasWindow]);

    // compute visible participants and sort.
    useEffect(() => {
        let x = 320;
        let y = 240;
        if (hasWindow) {
            x = window.innerWidth;
            y = window.innerHeight;
        }
        const windowAspect = x / y;
        // determine grid size
        let numVisible = participants.length;
        let ratio = 1.33;
        if (participants.length >= 16) {
            setGridClass("conf-desktop-grid5x5");
            ratio = (windowSize.x / 5) / ((windowSize.y - controlBarHeight) / 5)
            //numVisible = 9;
        } else if (participants.length >= 9) {
            setGridClass("conf-desktop-grid4x4");
            ratio = (windowSize.x / 4) / ((windowSize.y - controlBarHeight) / 4)
            //numVisible = 9;
        } else if (participants.length >= 7) {
            setGridClass("conf-desktop-grid3x3");
            ratio = (windowSize.x / 3) / ((windowSize.y - controlBarHeight) / 3)
            //numVisible = 6;
            // one empty row
        } else if (participants.length >= 5) {
            if (windowAspect < 1.1) {
                setGridClass("conf-desktop-grid2x3");
                ratio = (windowSize.x / 2) / ((windowSize.y - controlBarHeight) / 3)
            }
            else {
                setGridClass("conf-desktop-grid3x2");
                ratio = (windowSize.x / 3) / ((windowSize.y - controlBarHeight) / 2)
            }
            numVisible = 5;
        } else if (participants.length === 4) {
            setGridClass("conf-desktop-grid2x2");
            numVisible = 4;
            ratio = (windowSize.x / 2) / ((windowSize.y - controlBarHeight) / 2)
        } else if (participants.length === 3) {
            setGridClass("conf-desktop-grid2x2");
            numVisible = 3;
            ratio = (windowSize.x / 2) / ((windowSize.y - controlBarHeight) / 2)
            // one empty spot
        } else if (participants.length === 2) {
            if (windowAspect < 1.1) {
                setGridClass("conf-desktop-grid1x2");
                ratio = windowSize.x / ((windowSize.y - controlBarHeight) / 2)
            }
            else {
                setGridClass("conf-desktop-grid2x1");
                ratio = (windowSize.x / 2) / (windowSize.y - (controlBarHeight));

            }
            numVisible = 2;
        } else if (participants.length === 1) {
            setGridClass("conf-desktop-grid1x1");
            ratio = (windowSize.x) / (windowSize.y - (controlBarHeight));

        }
        setAspectRatio(ratio);

        // remove any participants that are no longer connected
        const newParticipants: Participant[] = [];
        visibleParticipants.forEach((p) => {
            if (
                room?.participants.has(p.sid) ||
                room?.localParticipant.sid === p.sid
            ) {
                newParticipants.push(p);
            }
        });

        // ensure active speakers are all visible
        room?.activeSpeakers?.forEach((speaker) => {
            if (
                newParticipants.includes(speaker) ||
                (speaker !== room?.localParticipant &&
                    !room?.participants.has(speaker.sid))
            ) {
                return;
            }
            // find a non-active speaker and switch
            const idx = newParticipants.findIndex((p) => !p.isSpeaking);
            if (idx >= 0) {
                newParticipants[idx] = speaker;
            } else {
                newParticipants.push(speaker);
            }
        });

        // add other non speakers
        for (const p of participants) {
            if (newParticipants.length >= numVisible) {
                break;
            }
            if (newParticipants.includes(p) || p.isSpeaking) {
                continue;
            }
            newParticipants.push(p);
        }

        if (newParticipants.length > numVisible) {
            newParticipants.splice(numVisible, newParticipants.length - numVisible);
        }
        setVisibleParticipants(newParticipants);
    }, [participants, windowSize.x, windowSize.y]);

    if (error) {
        return <Box
            display="flex"
            flexWrap={'wrap'}
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"

        ><div>error {error.message}</div></Box>;
    }

    if (isConnecting) {
        return <Box
            display="flex"
            flexWrap={'wrap'}
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            fontSize={'20px'}

        ><CircularProgress />
            <p style={{ paddingTop: '15px' }}>&nbsp;&nbsp;&nbsp;Connecting...</p>
        </Box>;
    }
    if (!room) {
        return <Box
            display="flex"
            flexWrap={'wrap'}
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"

        ><div style={{ fontSize: 16 }}>Room closed...</div></Box>;
    }

    if (participants.length === 0) {
        return <Box
            display="flex"
            flexWrap={'wrap'}
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"

        ><div style={{ fontSize: 16 }}>No one is in the room...</div></Box>;
    }

    return (
        // global container
        <div className="conf-desktop-container">
            <div className={`conf-desktop-grid-stage ${gridClass}`}>
                {visibleParticipants.map((participant) => {
                    if (participant.isCameraEnabled) {
                        return (
                            <UserView
                                key={participant.identity}
                                source={context.displayOptions.selectedVideoSource}
                                participant={participant}
                                orientation="landscape"
                                width="100%"
                                height="100%"
                                aspectWidth={aspectRatio}
                                aspectHeight={1}
                                showOverlay={true}
                                showSpeaking={participant.isSpeaking}
                            />
                        );
                    }
                    else {
                        return (
                            <UserView
                                key={participant.identity}
                                source={context.displayOptions.selectedVideoSource}
                                participant={participant}
                                orientation="landscape"
                                width="100%"
                                height="100%"
                                aspectWidth={aspectRatio}
                                aspectHeight={1}
                                showOverlay={true}
                                showSpeaking={participant.isSpeaking}
                            />

                        );
                    }
                }
                )}
            </div>

        </div>
    );
};