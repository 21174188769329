import React, { useState } from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../layout/PageTitle";

const Faqs1 = () => {
  const [active, setActive] = useState(1);
  const activeLi = (value: number) => (active === value ? "current" : "");
  return (
    <section id="faqs-1" className="bg-fixed wide-70 faqs-section division">
      <PageTitle pageTitle="Frequently Asked Questions" />
      <div className="container">
        <div className="row">
          {/* TABS NAVIGATION */}
          <div className="col-lg-5">
            <div className="tabs-nav clearfix">
              <ul className="tabs-1">
                {/* TAB-1 LINK */}
                <li
                  className={`tab-link ${activeLi(1)}`}
                  onClick={() => setActive(1)}
                  data-tab="tab-1"
                >
                  <img
                    className="img-55"
                    src="images/icons/lesson.png"
                    alt="tab-icon"
                  />
                  <div className="tab-link-txt">
                    <h5 className="h5-xs">General Questions</h5>
                    <p>
                      Learn about our platform, services and pricing
                    </p>
                  </div>
                </li>
                {/* TAB-4 LINK */}
                <li
                  className={`tab-link ${activeLi(4)}`}
                  onClick={() => setActive(4)}
                  data-tab="tab-4"
                >
                  <img
                    className="img-55"
                    src="images/icons/trophy.png"
                    alt="tab-icon-icon"
                  />
                  <div className="tab-link-txt">
                    <h5 className="h5-xs">For Experts</h5>
                    <p>
                      How to get started as an expert
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* END TABS NAVIGATION */}
          {/* TABS CONTENT */}
          <div className="col-lg-7">
            <div className="tabs-content">
              {/* TAB-1 CONTENT */}
              <div id="tab-1" className={`tab-content ${activeLi(1)}`}>
                {/* CATEGORY TITLE */}
                <div className="category-title">
                  {/* Title */}
                  <h4 className="h4-md">General Questions</h4>
                  {/* Text */}
                  <p className="p-md">

                  </p>
                </div>
                {/* QUESTION #1 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-md">
                    What is a Timedora session?
                  </h5>
                  {/* Answer */}
                  <ul className="txt-list mb-15">
                    <li>
                      A Timedora session is a live video conference session between an expert and you.
                    </li>
                    <li>
                      A session typically lasts anywhere from 30min to 1 hour.
                    </li>
                    <li>
                      During the session, you can learn, get consultation and advice from an expert for the topic that you book for. For the benefit of our experts and you, the questions and knowledge seek should be in the context for which you book the session for.
                    </li>
                    <li>
                      Just search for the topic that you are interested in and the expert you want to talk to. Then, find a time that works and book the time with your credit card. Once the time of your session is near, you and  expert will be reminded to meet online.
                    </li>
                  </ul>
                </div>
                {/* END QUESTION #1 */}
                {/* QUESTION #2 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How much does it cost?
                  </h5>
                  {/* Answer */}
                  <p>
                    Our expert set their own price depending on their expertise, experience and topic matter. Some experts may also provide their services for free. Most sessions are single session meetings where you meet one time with our expert and are billed on a one time basis per session. Some are ongoing sessions that span over a certain date range and they can be billed monthly or per session at least 3 days before they start such as classes.
                  </p>
                </div>
                {/* END QUESTION #2 */}
                {/* QUESTION #3 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    Can I cancel or reschedule my session?
                  </h5>
                  {/* Text */}
                  <p>
                    If there are changes and you would like to cancel the session you booked for, you can easily cancel it from our website easily. Just go to the topic page where you book the time slot and select "Cancel Session". If you want to reschedule your session, simply book a new time slot and then cancel the previously booked session.
                  </p>
                  {/* Answer */}
                </div>
                {/* END QUESTION #3 */}
                {/* QUESTION #4 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    What is your session size?
                  </h5>
                  {/* Answer */}
                  <p>
                    The session size can vary from 1 to tens to hundreds depending on the topic. Typical sessions are 1 on 1. However, small group classes or even large size sessions can be hosted on Timedora.
                  </p>
                </div>
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    Do you support Google Meet and Zoom integrations?
                  </h5>
                  {/* Answer */}
                  <p>
                    Timedora uses its own video conferencing system. However, you can also easily integrate with Google Meet and Zoom. Learn how to integrate with Google Meet and <Link to="/zoomintegration">Zoom</Link> .
                  </p>
                </div>

                {/* END QUESTION #4 */}
                {/* END TAB-1 CONTENT */}

                {/* QUESTION #4 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    Do you support international customers?
                  </h5>
                  {/* Answer */}
                  <p>
                    Yes. The fundamental idea of Timedora is to connect experts with users who seek their knowledge and time independent of geography and time zone. We believe that distance and geography should not impede the ability to seek the best expert or teacher for their advice and knowledge.
                  </p>
                  <p>
                    The times displayed are set to the user time zone. For example, an expert may be hosting a session at 6pm Pacific Standard Time. A user in Japan will see the time available as 9am in Japan Standard Time.
                  </p>
                </div>
                {/* END QUESTION #4 */}
              </div>
              {/* END TAB-1 CONTENT */}
              {/* TAB-2 CONTENT */}
              <div id="tab-2" className={`tab-content ${activeLi(2)}`}>
                {/* CATEGORY TITLE */}
                <div className="category-title">
                  {/* Title */}
                  <h4 className="h4-md">Video Conferencing</h4>
                  {/* Text */}
                  <p className="p-md">
                    Learn more about our unique and powerful video conferencing solution designed for teachers and the education industry.
                  </p>
                </div>
                {/* QUESTION #5 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">What is Smart Attendance?</h5>
                  {/* Answer */}
                  <p>
                    When there are many video faces in a conference, it is hard to tell who is not present. As a teacher, when you are expecting 40 students and only see 35 students, you want to know who is absent so you can quickly text absentees reminders to attend their course on time.
                  </p>
                  <p>
                    After observing numerous online sessions, what we found is that in certain classes, teachers can spend up to 10 minutes doing a roll call and getting students one by one to say their name out loud and perhaps manually checking their names against a list. In that process, some teachers also help figure out if a student microphone or speaker is working properly.
                  </p>
                  <p>
                    We think there is a better way to do it by automating the process. When you as a teacher presses the roll call button, you will be asked to say a greeting to everyone. We will be able to test your speaker and detect if your microphone is working. Then, press "Start Roll Call" and your greeting will be transmitted to everyone else online and the students will have the option to acknowledge if they heard your greeting and students will also respond by saying things in the microphone. Not only do we know who is present, but also be able to diagnose the various microphone or speaker issues to provide an attendance list report. In addition, our Smart Attendance can notify absentees on record that they are missing class. Students can get feedback on their attendance rate, percentage of time when they are on time or late for classes.
                  </p>
                </div>
                {/* END QUESTION #5 */}
                {/* QUESTION #6 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">What is Smart Attention?</h5>
                  {/* Answer */}
                  <p>
                    Being in front of a computer can be very distracting. There is always an urge to swtich to another window or another tab to view other content instead of focusing in the class. Our Smart Attention algorithm can detect when the class is not in focus or when a face is not in video view. When the attention is not focused on the online session, our system can play a gentle audio reminder to remind the attendee to focus their attention back to the classroom. Our system can also generate reports on percentage of attention focused in the class. Students can also get feedback on their attention span.
                  </p>
                  <p>
                    Smart Attention can be configured to be on and off depending on the teacher and the classes. On screen presence can also be turned off since the camera may be pointing at the homework or desk in certain instruction type.
                  </p>

                </div>
                {/* END QUESTION #6 */}
                {/* QUESTION #7 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    What is Smart Share?
                  </h5>
                  {/* Answer */}
                  <p>
                    Smart Share is a new way of sharing your screen. Instead of having one presenter share to everyone else in a one to many screen sharing, our technology enables many to many sharing. Why many to many screen sharing?
                  </p>
                  <p>
                    In a typical classroom, the teacher may move from student to student to observe their progress working on a homework or study material. Smart Share allows EVERY student to share their screen or window where they are working on an online course material. The teacher is able to choose to view which screens they want to observe akin to the teacher looking over the shoulders of a student to monitor progress and help if necessary.
                  </p>
                  <p>
                    Many to many sharing can be configured by the teacher to enable only teacher's view where only the teacher can view all the presentation. It can also be configured so that everyone can see everyone screen to enable a new form of collaboration. Frankly, we don't know what type of online collaboration it can enable, but we are excited to hear from you about your use case.
                  </p>
                </div>
                {/* END QUESTION #7 */}
                {/* QUESTION #8 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">What is Smart Pointer?</h5>
                  {/* Answer */}
                  <p>
                    When a projector is used in classroom settings, teachers use laser pointers to draw attention to specific areas on the projected screen. Smart Pointer works like that. Smart Pointer allows the presenter to control a pointer to draw attendees attention to a specifc area of a presentation online. Frequently, we observe how presenters have to point attendees' attention to specific area by using words like "top left corner" or "bottom portion of the slides", etc. With Smart Pointer, you can remotely control a pointer that shows up on everyone's screen to highlight various areas.
                  </p>
                </div>
                {/* END QUESTION #8 */}
                {/* QUESTION #8 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">What is Smart Pointer?</h5>
                  {/* Answer */}
                  <p>
                    When a projector is used in classroom settings, teachers use laser pointers to draw attention to specific areas on the projected screen. Smart Pointer works like that. Smart Pointer allows the presenter to control a pointer to draw attendees attention to a specifc area of a presentation online. Frequently, we observe how presenters have to point attendees' attention to specific area by using words like "top left corner" or "bottom portion of the slides", etc. With Smart Pointer, you can remotely control a pointer that shows up on everyone's screen to highlight various areas.
                  </p>
                </div>
                {/* END QUESTION #8 */}

              </div>
              {/* END TAB-2 CONTENT */}
              {/* TAB-3 CONTENT */}
              <div id="tab-3" className={`tab-content ${activeLi(3)}`}>
                {/* CATEGORY TITLE */}
                <div className="category-title">
                  {/* Title */}
                  <h4 className="h4-md">Mobile Applications</h4>
                  {/* Text */}
                  <p className="p-md">
                    Integer congue magna at pretium purus pretium ligula rutrum
                    at risus luctus eros dolor auctor ipsum blandit purus
                    vehicula magna and luctus tempor quisque
                  </p>
                </div>
                {/* QUESTION #9 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How can I download the treeks Android App?
                  </h5>
                  {/* Answer */}
                  <ul className="txt-list mb-15">
                    <li>Vitae auctor integer congue magna at pretium</li>
                    <li>
                      Integer congue magna at pretium purus pretium ligula
                      rutrum and luctus risus eros dolor auctor ipsum blandit
                      purus vehicula magna and luctus tempor
                    </li>
                    <li>
                      Sagittis congue augue egestas volutpat egestas magna donec
                      ociis
                    </li>
                  </ul>
                </div>
                {/* END QUESTION #9 */}
                {/* QUESTION #10 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How do I start a course using the IOS App?
                  </h5>
                  {/* Answer */}
                  <ul className="txt-list mb-15">
                    <li>
                      An augue egestas an ipsum vitae emo ligula volute ante
                      ipsum primis faucibus sit ametn in odio. Integer congue
                      metus, eu mollis lorem viverra suscipit lectus
                    </li>
                    <li>
                      Integer congue magna at pretium purus pretium ligula
                      rutrum and luctus risus eros dolor auctor ipsum blandit
                      purus vehicula magna and luctus tempor
                    </li>
                  </ul>
                </div>
                {/* END QUESTION #10 */}
                {/* QUESTION #11 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How do I make a payment using the Android App?
                  </h5>
                  {/* Answer */}
                  <p>
                    Aliqum mullam blandit tempor sapien gravida donec ipsum, at
                    porta justo. Velna vitae and congue auctor magna nihil
                    impedit ligula risus. Mauris donec ociis magnis sapien etiam
                    sapien sagittis congue tempor gravida donec enim ipsum porta
                    justo
                  </p>
                  {/* List */}
                  <ul className="txt-list mb-15">
                    <li>
                      Integer congue magna at pretium purus pretium ligula
                      rutrum at risus luctus eros dolor auctor ipsum blandit
                      purus vehicula magna and luctus tempor quisque
                    </li>
                    <li>
                      Risus at congue etiam and aliquam sapien egestas posuere a
                      cubilia
                    </li>
                  </ul>
                </div>
                {/* END QUESTION #11 */}
              </div>

              {/* END TAB-3 CONTENT */}
              {/* TAB-4 CONTENT */}
              <div id="tab-4" className={`tab-content ${activeLi(4)}`}>
                {/* CATEGORY TITLE */}
                <div className="category-title">
                  {/* Title */}
                  <h4 className="h4-md">FAQ for Expert</h4>
                  {/* Text */}
                  <p className="p-md">
                    This is a starting guide for users who want to join our service as an expert and earn money by sharing their knowledge and expertise.
                  </p>
                </div>
                {/* QUESTION #11 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">How do I get started?</h5>
                  {/* Answer */}
                  <ul className="txt-list mb-15">
                    <li>
                      You can sign up by tapping on the button "Become and Expert" anywhere on our website. By tapping that, you will be registered as an expert rather than a user.
                    </li>
                    <li>
                      An expert will be able to create new topics, set up payment information, host a video conference session and many other features.
                    </li>
                  </ul>
                </div>
                {/* END QUESTION #11 */}
                {/* QUESTION #12 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">What is a topic?</h5>
                  {/* Answer */}
                  <p>
                    An expert is someone who has a deep knowledge about something. However, an expert can have many expertise in which they want to share as a topic. A topic is the subject matter in which an expert or teacher may want to provide advice for or teach. A topic can be an expertise description or a course title. For example, if you are a lawyer,  you may want to provide your time specifically to talk about startup financing. You can create a topic such as "Term Sheet Negotiation for Startup".
                  </p>
                  <p>
                    If you are signed up as an expert, you will be able to create topics under your account from the menu Sessions-&gt;Create Topic
                  </p>
                </div>
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">How much does it cost?</h5>
                  {/* Answer */}
                  <p>
                    It costs nothing to join as an expert and set up your first topic. We don't get paid until you make your first earnings. Below is our fee structure.
                  </p>
                  {/* List */}
                  <ul className="txt-list mb-15">
                    <li>
                      For referrals generated through our marketing, lead generation and the marketplace on our website, we take a 30% referral fee.
                    </li>
                    <li>
                      For customers that you as an expert brought on board yourself, there is no commission. If the customer comes from an invitation link or a public short link that you may have advertise in various channels, we do not charge commission on earnings.
                    </li>
                  </ul>
                  <p>
                    The fees above already includes credit card processing fees which is usually about 2.9% plus a minimum charge depending on payment types, currency and region.
                  </p>
                </div>
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">How do you differentiate between a user I brought in and a referral coming from Timedora?</h5>
                  {/* Answer */}
                  <p>
                    Your profile and your topic pages all have a unique short link. When your users sign up and book a session with you through this short link, we know that the user is coming from you. Otherwise, if they signed up directly from our website or through our marketing, then we consider it as a referral.
                  </p>
                  <p>
                    You can easily access this short link from the profile and topic pages and then use it to invite your users or put the link from your website so your users are ready to book a time with you.
                  </p>
                </div>
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    What if I have a change of plans and need to reschedule my session?
                  </h5>
                  {/* Answer */}
                  <p>
                    You can cancel your session and the user will have the chance to find a new time slot to consult with you. Similarly, a user may also have a change of plans and will also be
                  </p>
                </div>
                {/* END QUESTION #13 */}
              </div>
              {/* END TAB-4 CONTENT */}
            </div>
          </div>
          {/* END TABS CONTENT */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </section >
  );
};

export default Faqs1;
