import { Link, NavLink, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { api as apiproto } from "../../apiproto";
import ReviewRatingsDisplay from "../Reviews/ReviewRatingsDisplay";
import { getFormat, getTopicPhotoURL, getOwners, getUserPhotoURL, secondsToDhms, isUserOwner, getTopicShareStatus, generateProfileURL, generateTopicURL, generateProfileTopicURL, ExtendedTime, checkIfTopicProfile } from "../../utils/eduutils";
import { Avatar, Button, Chip, Paper, Skeleton, Tooltip } from "@mui/material";
import { getPriceStr } from "../../utils/eduutils";
import GroupIcon from '@mui/icons-material/Group';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TimeTable from "./TimeTable";
import EditIcon from '@mui/icons-material/Edit';
import { useUser } from "../../utils/useUser";
import { tdrLog } from "../../utils/utils";
import { ReadMore } from "../ReadMore";

type ChildProps = {
  topic: apiproto.ITopic | null,
  type: string
}

const TopicCard: React.FC<ChildProps> = ({ topic = null, type = "" }) => {
  const [topicPhoto, setTopicPhoto] = useState("");
  const [userPhotoUrl, setUserPhotoUrl] = useState("");
  const [titleUrl, setTitleUrl] = useState("");
  const [priceStr, setPriceStr] = useState("");
  const [topicMeta, setTopicMeta] = useState({ summary: "", about_me: "", description: "", prerequisites: "", supply_list: "", assignments: "", time_commitment: "" });
  const [isFetchData, setFetchData] = useState(true);
  const [owners, setOwners] = useState<apiproto.IMember[]>([]);
  const [isOwner, setIsOwner] = useState(false);
  const [isPublish, setPublish] = useState("");
  const [isTopicProfile, setIsTopicProfile] = useState(false);
  const user = useUser();
  const navigate = useNavigate();

  const editTopic = (topicid: string) => {
    if (checkIfTopicProfile(topicid)) {
      //This is a profile topic which we use to allow topic pages to display profiles.
      navigate("/profile");
    }
    else {
      navigate("/createtopic/" + window.encodeURIComponent(topicid));
    }
  }

  const timeslotClicked = (time: ExtendedTime) => {
    if (topic !== null) {
      if (checkIfTopicProfile(topic.topicId!)) {
        const userinfo = owners[0].entity?.user?.user!;
        navigate(generateProfileTopicURL(userinfo.firstName!, userinfo.lastName!, userinfo.userId!))
      }
      else {
        navigate(generateTopicURL(topic));
      }
    }
  }

  useEffect(() => {
    if (topic) {
      var owners: apiproto.IMember[] = [];
      if (checkIfTopicProfile(topic.topicId!)) {
        setIsTopicProfile(true);
      }
      if (topic.members) owners = getOwners(topic.members!);
      setTopicPhoto(getTopicPhotoURL(topic!));
      if (owners && owners.length > 0) {
        const userinfo = owners[0].entity?.user?.user!;
        setUserPhotoUrl(getUserPhotoURL(userinfo));
        const checkid = checkIfTopicProfile(topic.topicId);
        if (checkid) {
          setTitleUrl(generateProfileTopicURL(userinfo.firstName!, userinfo.lastName!, userinfo.userId!))
        }
        else {
          setTitleUrl(generateTopicURL(topic));
        }
      }
      var price = getPriceStr(topic?.cost, topic?.costType, topic?.currency);
      setPriceStr(price);
      try {
        if (topic && topic !== null) {
          tdrLog("Topic results....");
          tdrLog(JSON.stringify(topic!));
        }
        setTopicMeta(JSON.parse(topic?.description!));
      }
      catch (err: any) {
      }
      setFetchData(false);
      if (topic.members) {
        setOwners(owners);
        setIsOwner(isUserOwner(owners, user.userId));
      }
      const status = getTopicShareStatus(topic);
      setPublish(status);

    }
  }, [topic]);

  return (
    <Fragment>
      {topic &&
        <div className="divpadding sessionCard">
          <div className="row">
            <div className="col-md-8  col-lg-9 mb-10">
              <Link style={{ textDecoration: 'none', color: "black" }} to={titleUrl}>

                <div className="course-header">
                  <NavLink className="link18" to={titleUrl}>
                    {topic.name}
                  </NavLink>
                </div>
                <div className="row">
                  {/* Course Description */}
                  <div className="col-md-3 text-center">
                    {isTopicProfile ? <img alt="course photo" className="img-fluid-small mb-15" src={userPhotoUrl} />
                      : ((topicPhoto && topicPhoto.length) > 0 ?
                        <img alt="course photo" className="img-fluid-small mb-15" src={topicPhoto} /> : <Skeleton variant="rectangular" sx={{ width: '100%' }} />)}
                    <br />
                    {owners.map((owner: apiproto.IMember, index) => (
                      <span key={'owner' + index} className="legend-label">
                        <Link to={generateProfileURL(owner.entity?.user?.firstName!, owner.entity?.user?.lastName!, owner.entity?.user?.userId!)}>
                          {topic.topicId && !checkIfTopicProfile(topic.topicId!) ?
                            < Avatar sx={{ mr: 2, float: 'left' }} alt={owner ? owner.entity?.user?.firstName! + ' ' + owner.entity?.user?.lastName! : ""} src={userPhotoUrl} />
                            : ""}
                          {owner ? owner.entity?.user?.firstName + ' ' + owner.entity?.user?.lastName! : ""}
                        </Link>
                      </span>))}
                    <div className="course-price">{priceStr}</div>
                  </div>
                  <div className="col-md-9 cbox-5-txt">
                    {/* Rating */}
                    <div className="row">
                      <div className="col-md-12">
                        {(topic.topicId && checkIfTopicProfile(topic.topicId!)) ? <ReadMore min={300} ideal={300} max={350} hideShowMore={true}>{topicMeta.about_me}</ReadMore>
                          : topicMeta.summary}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <p className="course-tags mt-20">
                          {topic.tags?.map((tag, index) => (
                            <span className="tagStyle" key={tag + topic.topicId}>{tag}</span>
                          ))}
                        </p>
                      </div>
                    </div>
                    <div className="row mt-10">
                      <div className="col-md-12">
                        <ReviewRatingsDisplay ratingsAverage={topic.ratingsAverage} ratingsCount={topic.ratingsCount} reviews={[]} bar={false} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-auto">
                        <span className="legend-label">
                          <DateRangeIcon />&nbsp;{getFormat(topic)}
                        </span>
                      </div>
                      {topic.minDuration && topic.minDuration > 0 &&
                        <div className="col-sm-auto">
                          <span className="legend-label">
                            <AccessTimeIcon />&nbsp;{secondsToDhms(+topic.minDuration! / 1000, false)} per session
                          </span>
                        </div>
                      }
                      <div className="col-sm-auto">
                        <span className="legend-label">
                          <GroupIcon />&nbsp;{topic.maxParticipants} {topic.maxParticipants! > 1 ? ' participants' : ' participant'}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {/* Course Data */}
            <div className="col-md-4 col-lg-3 mb-20">
              {isFetchData ?
                <div>
                  <br /><Skeleton variant="rectangular" width={150} />
                  <br /><Skeleton variant="rectangular" width={150} />
                  <br /><Skeleton variant="rectangular" width={150} />
                  <br /><Skeleton variant="rectangular" width={150} />
                </div>
                :
                <Paper style={{ padding: 8, }} elevation={3}>
                  <div className="timeslotBoxShort">
                    <TimeTable topic={topic!} schedule={null} roomid={null} type={type} bookingCallback={timeslotClicked} showVideoButton={(type === 'book' || type === 'created') ? true : false} />
                  </div>
                </Paper>
              }
            </div>
          </div>
          {
            user.authenticated && isOwner &&
            <div className="divHide">
              <div className="row divHide">
                <div className="col">
                  <b className="mt-50">
                    {isPublish === "private" ? <Tooltip title="Only you can view it."><Chip label="Private" color="error" /></Tooltip>
                      :
                      (isPublish === "inviteonly" ?
                        <Tooltip title="Only invitees can view it.">
                          <Chip label="Invite Only" color="primary" />
                        </Tooltip>
                        : (isPublish === "restricted" ?
                          <Tooltip title="Anyone with the link can view it.">
                            <Chip label="Restricted" color="warning" />
                          </Tooltip>
                          : (topic?.allowSearchable ?
                            <Tooltip title="Anyone can view it. Approved for marketplace and search.">
                              <Chip label="Public - Anyone can view it. Approved for marketplace and search." color="success" />
                            </Tooltip> :
                            <Tooltip title="Anyone can view it. Pending approval for marketplace and search.">
                              <Chip label="Public (pending)" color="success" />
                            </Tooltip>)))}</b>
                </div>
                <div className="col">
                  {type === 'created' &&
                    <div>
                      <Button sx={{ mr: 2, float: 'right' }} size="small" variant="outlined" onClick={() => editTopic(topic.topicId!)} startIcon={<EditIcon />}>
                        Edit
                      </Button>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div >

      }
    </Fragment >
  );
};

export default TopicCard;


