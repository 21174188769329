import { Fragment, useEffect, useState } from "react";
import { checkIfTopicProfile, computeEpochtoPrettyDateStr, computeEpochtoTimeStr, frequencyString, generateTopicURL, getPriceStr, getProfileTimeZoneStr, getUserPhotoURL, IScheduleCard, IUserRating, secondsToDhms } from "../../utils/eduutils";
import { Col, Row } from "react-bootstrap";
import { Avatar, Box, Dialog, DialogContent, DialogTitle, IconButton, Paper, Typography } from "@mui/material";
import { useAPI } from "../../utils/useAPI";
import { api as apiproto } from "../../apiproto";
import ReviewRatingsDisplay from "../../components/Reviews/ReviewRatingsDisplay";
import { RequireAuth, useUser } from "../../utils/useUser";
import CloseIcon from '@mui/icons-material/Close';
import DialogMessageBox from "../../components/DialogMessageBox";
import TimeTable from "../../components/Course Details/TimeTable";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BookCheckout from "../account/bookCheckout";
import { tdrAlert } from "../../utils/utils";
import { ReadMore } from "../../components/ReadMore";
import { useNavigate } from "react-router-dom";

type ChildProps = {
    topic: apiproto.ITopic | null | undefined,
    topicOwners: apiproto.IUser[] | null | undefined,
    scheduleCard: IScheduleCard | null,
    userRating: IUserRating,
    visible: boolean,
    onCancel?: () => void | null,
    onConfirm?: (value: any) => void | null,
    userInfo?: apiproto.User | null
}

type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};
const ScheduleBookDialog: React.FC<ChildProps> = ({ topic = null, topicOwners = [], scheduleCard = null, userRating = { averageScore: 0, userRatingsCount: [0, 0, 0, 0, 0] }, visible = false, onCancel = () => { }, onConfirm = () => { }, userInfo = null }) => {
    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();
    const [parentLoading, setParentLoading] = useState(false);
    const [topicMeta, setTopicMeta] = useState({ summary: "", description: "", prerequisites: "", supply_list: "", assignments: "", time_commitment: "", photo: "" });
    const [scheduleBookOpen, setScheduleBookOpen] = useState(false);
    const [priceStr, setPriceStr] = useState("");
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [displayTimeZone, setDisplayTimeZone] = useState("");
    const [errorMessage, setErrorMessage] = useState<string | undefined | null>(null);
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [stripeKeys, setStripeKeys] = useState<StripeKeys>({ clientSecret: "", publishableKey: "" });
    const [existingPayment, setExistingPayment] = useState("");
    const [isProfilePage, setIsProfilePage] = useState(false);
    const [goBack, setGoBack] = useState(true);
    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: {}, confirm: true });

    }

    const isReserverTheOwner = () => {
        if (topicOwners !== null) {
            for (var owner of topicOwners) {
                if (owner.userId === user.userId) {
                    return true;
                }
            }
        }
        return false
    }


    const paymentSuccess = () => {
        (async () => {
            setParentLoading(true);
            if (topic?.cost === 0 || topic?.costType === apiproto.CostType.free) {
                // if it is the owner, don't join the owner to it and do nothing
                // but join the guests invited
                if (isReserverTheOwner()) {
                    setDialogMessage({ title: 'Guests invited', message: "You have successfully invited your guests.", meta: {}, confirm: false });
                }
                else {
                    // Join the user if not the owner since no point joining owner to own session.             
                    joinFreeSession();
                }
            }
            else {
                await joinSession(false);
            }
            setParentLoading(false);
        })();
    }

    const paymentCancelled = () => {
        closeWindow();
    }

    const closeWindow = () => {
        clearDialog();
        if (goBack) {
            if (topic !== null) {
                navigate(generateTopicURL(topic));
            }
            onCancel();
        }
        setGoBack(true);
    }

    const confirmWindow = (meta: any) => {
        if (meta.reload !== null) {
            // Some error where we force a reload
            window.location.reload();
        }
        else {
            clearDialog();
            onCancel();
        }
    }

    const joinFreeSession = () => {
        joinSession(true);
    }

    const setupPayment = async (free: boolean) => {
        try {
            if (user !== null && user.authenticated) {
                // Only need to set up if user is authenticated
                const result = await api.updatePayment()
                if (result !== null && result.publishableKey && result.publishableKey?.length > 0) {
                    const stripeKeysResult = { clientSecret: result.paymentClientSecret!, publishableKey: result.publishableKey! };
                    setStripeKeys(stripeKeysResult)
                    if (result.paymentClientSecret && result.paymentClientSecret.length > 0 && stripeKeysResult.publishableKey.length > 0) {
                        setStripePromise(loadStripe
                            (stripeKeysResult.publishableKey!, {
                            }));
                    }
                    if (result !== null && result.existingPaymentDescription && result.existingPaymentDescription.length > 0) {
                        setExistingPayment(result.existingPaymentDescription);
                    }
                    else {
                        setExistingPayment("");
                    }
                }
            }
        }
        catch (err: any) {
            // Not sure what to do but probably reload 
            setDialogMessage({ title: 'Schedule setup', message: "Error setting up scheduling information. Do you want to retry?", meta: { reload: true }, confirm: true });
        }
    }

    const joinSession = async (free: boolean) => {

        try {
            let result = null;
            if (scheduleCard !== null && topic !== null) {
                if (topic.singleSession) {
                    if (scheduleCard.timeId !== null && scheduleCard.timeId.length > 0) {
                        tdrAlert("Joining " + scheduleCard.timeId);
                        result = await api.joinTime(scheduleCard.timeId, topic.topicId!);
                    }
                    else {
                        // TODO: Should never happen
                        tdrAlert("Error - Joining Single Session but no time. ");
                    }
                }
                else {
                    result = await api.joinRoom(scheduleCard.roomId, topic.topicId!);
                }
                if (result !== null) {
                    if (free) {
                        setDialogMessage({ title: 'Session booked', message: "Your free session was successfully booked.", meta: {}, confirm: false });

                    }
                    else {
                        setDialogMessage({ title: 'Session booked', message: "Your payment was successfully processed.  Your session is now booked.", meta: {}, confirm: false });
                    }
                }
            }
        } catch (err) {
            const errName = (err as Error).name;
            if (errName === "TOPIC_FULL" || errName === 'MAX_CAPACITY') {
                setDialogMessage({ title: 'Booking error', message: "The session is already booked or in the process of being booked. This may happen when someone booked the session before you did or creator reduced the number of participants in the session. Please find another available session to book or check back later.", meta: {}, confirm: false });
                setGoBack(true);
                //    setErrorMessage("The session is already booked. Please find another session to book");;
            }
            else if (errName === 'ALREADY_JOINED') {
                setDialogMessage({ title: 'Already Booked', message: "You have already join this session. You cannot double book the same session.", meta: {}, confirm: false });
                setGoBack(true);
            }
            else if (errName === 'MISSING_CUSTOMER_PAYMENT_INFO ') {
                setDialogMessage({ title: 'Missing payment information', message: "Your payment information is missing some information. Please try a new payment method.", meta: {}, confirm: false });
                setGoBack(false);
            }
            else if (errName === 'ERROR_CONFIRMING_PAYMENT') {
                setDialogMessage({ title: 'Error Confirming Payment', message: "There was a failure processing your payment. Please try a new payment method.", meta: {}, confirm: false });
                setGoBack(false);
            }
            else if (errName === 'PAYMENT_AUTHORIZATION_FAILED') {
                setDialogMessage({ title: 'Payment Authorization Failed', message: "We are unable to authorize your payment. Please try a new payment method.", meta: {}, confirm: false });
                setGoBack(false);
            }
            else if (errName === 'TIME_NOT_AVAILABLE') {
                setDialogMessage({ title: 'Time no longer available', message: "The time you booked may have been reserved or the time has passed and is no longer available.", meta: { goback: true }, confirm: false });
            }
            else if (errName === 'PROVIDER_PAYMENT_NOT_SETUP') {
                setDialogMessage({ title: 'Provider payment', message: "The proivder has not configure their payment.", meta: {}, confirm: false });
                setGoBack(true);
            }
            else {
                setDialogMessage({ title: 'Booking error', message: 'There was an error making a booking for the session. Please use a different card or try again.' + errName, meta: { goback: false }, confirm: false });
                setGoBack(false);
            }
        }
    }

    useEffect(() => {
        if (user.authenticated) {
            setScheduleBookOpen(visible);
            // Reinitalize defaults since user can tap cancel and book and don't want old errors to be seen
            setErrorMessage("");
            clearDialog();
            // Only do a join to get key if it is not free. If session is free, do not join. Only join later on confirm
            // We need useEffect on user.authenticated because if user was not logged in and then logged in, we want to setup payment again
            // On parent component load, we need to set up payment here. If you have conditions, then it can cause useStripe and useElement to be null on reload whic is very strange.
            // We also 
            setupPayment(false);
            setDisplayTimeZone(getProfileTimeZoneStr(false));
        }
    }, [visible, user.authenticated]);

    useEffect(() => {
        if (topic === null && scheduleCard?.roomId !== null) {
            return;
        }
        if (checkIfTopicProfile(topic?.topicId)) {
            setIsProfilePage(true);
        }
        try {
            if (topic) {
                var price = getPriceStr(topic.cost, topic.costType, topic.currency);
                setPriceStr(price);
                setTopicMeta(JSON.parse(topic.description!));
            }
        }
        catch (err: any) {
            // TODO: Not sure what we really can do here 
        }
    }, [topic?.topicId, scheduleCard?.roomId]);

    return (
        <Fragment>
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={closeWindow} onConfirm={confirmWindow} visible={dialogMessage.title.length > 0} />
            <Dialog
                fullScreen
                open={scheduleBookOpen}
                onClose={onCancel}
            >
                {false &&
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={paymentCancelled}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                }
                <DialogContent>
                    <div>
                        <RequireAuth>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                minHeight="800"
                            >
                                <div className="form-group">
                                    <br />
                                    <Paper elevation={6} sx={{ maxWidth: 1280 }}>

                                        <Row style={{ padding: 20 }}>
                                            <Col style={{ padding: 15 }}>
                                                <Row>
                                                    <Col xs="5" lg="5" className="text-center">
                                                        <Box display="flex" alignItems="center" justifyContent="center" padding={2}>
                                                            {topicOwners && topicOwners.length > 0 ?
                                                                <Avatar sx={{ width: 100, height: 120, alignItems: "center", flexDirection: "column", justifyContent: "center", display: "flex" }} variant="square" alt={topicOwners[0].firstName!} src={isProfilePage ? topicMeta.photo : getUserPhotoURL(topicOwners[0])} /> : ""}
                                                        </Box>
                                                        {(topicOwners && topicOwners[0]) ? topicOwners[0].firstName! + ' ' + topicOwners[0].lastName! : ""}
                                                        <br />
                                                        <ReviewRatingsDisplay ratingsAverage={userRating.averageScore} ratingsCount={userRating.userRatingsCount} reviews={[]} bar={false} />

                                                    </Col>
                                                    <Col xs="7">
                                                        {topic?.singleSession !== null ? <div>
                                                            <h3> {scheduleCard ? computeEpochtoPrettyDateStr(scheduleCard.starttime!.toString(), 'ddd, MMM Do') : ""}
                                                            </h3>
                                                            <h5>
                                                                {scheduleCard && computeEpochtoTimeStr(scheduleCard.starttime.toString())} -
                                                                {scheduleCard && computeEpochtoTimeStr(scheduleCard.endtime!.toString())}
                                                            </h5>
                                                            {displayTimeZone.length > 0 ? <span>({displayTimeZone})<br /><br /></span> : ""}
                                                            <b>Cost: </b> {priceStr}
                                                            <br /><br />

                                                        </div> :
                                                            <div>
                                                                <b>Cost: </b> {priceStr}
                                                                {(topic?.cost && topic?.costType === apiproto.CostType.cost_per_time_charged_as_you_go && topic.cost > 0) ? <div className="course-price-detail">1st session charged immediately. Subsequent sessions are charged 3 days in advance before they start.</div> : ""}
                                                                <br /><br />
                                                            </div>
                                                        }
                                                        <b>Type: </b>
                                                        {topic?.singleSession ? "Single Session" : (topic?.ongoingSession ? (scheduleCard && scheduleCard.schedules.map((schedule: apiproto.ISchedule) => (
                                                            <Typography variant="subtitle1" color="text.secondary">
                                                                {frequencyString(schedule)}
                                                                <br />
                                                                {computeEpochtoTimeStr(schedule.startTime!.toString())} -
                                                                {computeEpochtoTimeStr((+schedule.startTime! + +schedule.duration!).toString())}
                                                                <br />
                                                                {secondsToDhms((+schedule.duration! / 1000) as number, false)} per session
                                                                <br />
                                                                {scheduleCard.schedules.length > 1 ?
                                                                    schedule.times?.length + " sessions" : ""}
                                                                <br /><br />
                                                            </Typography>))) : (topic?.multiSession ? "Multi Sessions" : ""))}

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <hr />
                                                        <h4>{topic?.name}</h4>
                                                        <div className="newline">
                                                            <ReadMore min={200} ideal={300} max={350}>
                                                                {isProfilePage ? topicMeta?.description : topicMeta?.summary}
                                                            </ReadMore>
                                                        </div>
                                                        <br />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="timeslotBox">
                                                        {scheduleCard?.roomId && topic?.singleSession === null &&
                                                            <TimeTable topic={topic!} roomid={scheduleCard?.roomId!} schedule={null} userInfo={userInfo} />
                                                        }
                                                    </div>
                                                </Row>

                                            </Col>
                                            < Col style={{ border: '1px solid gray', padding: 15 }} lg={6}>
                                                <Row>
                                                    <Col>

                                                        <div className="form-group form-input">
                                                            {errorMessage && (
                                                                <p className="form-error">
                                                                    Error:{errorMessage}
                                                                </p>)
                                                            }
                                                            <Elements options={{
                                                                clientSecret: stripeKeys.clientSecret, appearance: {
                                                                    theme: 'stripe', variables: {
                                                                        colorPrimary: '#0570de',
                                                                        colorBackground: '#ffffff',
                                                                        colorText: '#30313d',
                                                                        colorDanger: '#df1b41',
                                                                        fontFamily: 'Ideal Sans, system-ui, sans-serif',
                                                                        fontSizeBase: '18px',
                                                                        spacingUnit: '4px',
                                                                        borderRadius: '4px',
                                                                        // See all possible variables below
                                                                    }
                                                                }
                                                            }} stripe={stripePromise} key={stripeKeys.clientSecret}>
                                                                <BookCheckout stripeKeys={stripeKeys} existingPayment={existingPayment} topic={topic} topicOwners={topicOwners} scheduleCard={scheduleCard} paymentCancel={paymentCancelled} paymentSuccess={paymentSuccess} parentLoading={parentLoading} />
                                                            </Elements>
                                                        </div>

                                                    </Col>
                                                </Row>
                                            </Col>


                                        </Row>

                                    </Paper>
                                </div>
                                <br /><br /><br />
                            </Box>
                        </RequireAuth>
                    </div >
                </DialogContent >
            </Dialog >
        </Fragment >)
}

export default ScheduleBookDialog;

