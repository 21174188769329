import { useState } from "react";
import { trimText } from "../utils/eduutils";

export const ReadMore = ({ children, min, ideal, max, hideShowMore }: { children: string, min: number, ideal: number, max: number, hideShowMore?: boolean }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    var trimTxt = trimText(text, min, ideal, max);
    //    const [prevScrollY, setPrevScrollY] = useState(0);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
        /* Give up on show less going back to anchor
        if (isReadMore) {
            setIsReadMore(false);
            setPrevScrollY(window.scrollY);
        }
        else {
            setTimeout(() => window.scrollTo(0, prevScrollY), 1);
            setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 1);
            setIsReadMore(true);
        }
*/
    };

    return (
        <div>
            {isReadMore ? trimTxt : text}
            {
                (text && text !== null) ? (
                    text.length > trimTxt[0].length &&
                    <div onClick={toggleReadMore} className="read-more-link" >
                        {hideShowMore ? "" : (isReadMore ? "...read more" : " show less")}
                    </div>) : ""
            }
        </div>

    );
};
