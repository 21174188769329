import { RoomConnectOptions, Participant, Room } from "livekit-client";
import { useContext, useEffect } from "react";
import { ConferenceView } from "./ConferenceView";
import { DisplayContext } from "./DisplayContext";
import { useRoom } from "./useRoom";

export interface RoomProps {
    url: string;
    token: string;
    connectOptions?: RoomConnectOptions;
    // override default participant sort
    sortParticipants?: (participants: Participant[]) => void;
    // when first connected to room
    onConnected?: (room: Room) => void;
    // when user leaves the room
    onLeave?: (room: Room) => void;
    joinLeaveClicked?: () => void;
}

export const ConferenceRoom = ({
    url,
    token,
    connectOptions,
    sortParticipants,
    onConnected,
    onLeave,
    joinLeaveClicked
}: RoomProps) => {
    const roomState = useRoom({ sortParticipants });
    if (!connectOptions) {
        connectOptions = {};
    }
    const context = useContext(DisplayContext);

    useEffect(() => {
        var theRoom: Room | null = null;
        roomState.connect(url, token, connectOptions).then(async (room) => {
            if (!room) {
                return;
            }
            theRoom = room;
            if (onConnected) {
                onConnected(room);
            }
        });
        return () => {
            if (theRoom) {
                theRoom.disconnect();
            }
        };
    }, [context.displayOptions.connectState]);

    return ConferenceView({
        roomState,
        //face,
        onLeave,
        joinLeaveClicked
    });
};