import { LoadingButton } from "@mui/lab";
import React, { ChangeEvent, useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useLocation, useNavigate } from "react-router-dom";
import DialogMessageBox from "../components/DialogMessageBox";
import Layout from "../layout/Layout";
import { emailValidation } from "../utils/eduutils";
import { useAPI } from "../utils/useAPI";
import { useUser } from "../utils/useUser";

// This is not me button

export const ValidateEmail = () => {

    const search = useLocation().search;
    const email = new URLSearchParams(search).get('e');
    const code = new URLSearchParams(search).get('vc');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [resendSubmitting, setResendSubmitting] = useState<boolean>(false);
    const [validateMessage, setValidateMessage] = useState<string>('');
    const [validationCode, setValidationCode] = useState<string>('');
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true, action: 0 });

    const api = useAPI();
    var user = useUser();
    const navigate = useNavigate();
    const redirect = new URLSearchParams(search).get('redirect');

    useEffect(() => {
        if (email !== null && code !== null) {
            setValidationCode(code);
            // This is click through from email to validate instead of showing UI
        }
        // check message
        setValidateMessage('');
    }, [email, code])

    useEffect(() => {
        if (resendSubmitting) {
            let interval = setTimeout(() => {
                setResendSubmitting(false);
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [resendSubmitting])

    const notMe = () => {
        setDialogMessage({ title: 'Not me?', message: 'Your email will not be verified', meta: '', confirm: false, action: 0 });
        if (redirect && redirect !== null) {
            navigate(redirect);
        }
    }

    const handleCancel = () => {
        if (redirect && redirect !== null) {
            navigate(redirect);
        }
        setDialogMessage({ title: '', message: '', meta: '', confirm: true, action: 0 });
        navigate('/profile')
    }

    const codeSubmitted = () => {
        (async () => {
            try {
                setIsSubmitting(true);
                // If user is not authenticated, we need to pass email in too
                if (user.authenticated) {
                    await api.validate("", validationCode, "");
                }
                else if (email !== null) {
                    await api.validate(email, validationCode, "");
                }
                setValidateMessage('Congratulations, your email is now verified.');
                setDialogMessage({ title: 'Success!', message: 'Your email is now verified.', meta: '', confirm: false, action: 0 });
                if (redirect && redirect !== null) {
                    navigate(redirect);
                }
            } catch (e) {
                let errorcode = (e as Error).name;
                if (errorcode === "EXPIRED_CODE") {
                    setValidateMessage('Your code has expired. You can resend a new validation code.');
                }
                else {
                    setValidateMessage('Your code is wrong or you have already validated your account.');
                }
                setIsSubmitting(false);
            }
        })();
    }

    const requestValidation = () => {
        (async () => {
            try {
                setResendSubmitting(true);
                if (email !== null && emailValidation(email)) {
                    await api.sendValidationCode(email, false);
                }
                else {
                    await api.sendValidationCode("", false);
                }
                setValidateMessage('Validation code sent. Please enter the validation code in the box below.');
            } catch (e) {
                if (user.authenticated) setValidateMessage('Problem sending validation code - ' + (e as Error).message);
                else setValidateMessage('You need to be logged in to resend verification code')
            }
        })();
    }


    return (
        <Layout headerBtn={false} footer={3}>
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={handleCancel} visible={dialogMessage.title.length > 0} />
            <div className="auth-inner">
                <ValidatorForm onSubmit={codeSubmitted}>
                    <p className="form-error">{validateMessage}</p>

                    <TextValidator
                        label="Enter verification code sent to your email"
                        type="text"
                        value={validationCode}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setValidationCode(event.target.value)
                        }}
                        style={{ width: '100%' }}
                        name="vcode"
                        id="vcode"
                        autoComplete="false"
                        className="form-group"
                        placeholder="Enter code"
                        validators={['required']}
                        errorMessages={['Verification code required']}
                    />

                    <LoadingButton type="submit" style={{ width: '100%', marginTop: '10px' }} loading={isSubmitting} variant="contained" size="large" onClick={() => {
                    }}>
                        Verify email
                    </LoadingButton>

                    <LoadingButton style={{ width: '100%', marginTop: '20px' }} loading={isSubmitting} size="small" onClick={notMe}>
                        This is not me
                    </LoadingButton>

                    <LoadingButton style={{ width: '100%', marginTop: '5px' }} disabled={resendSubmitting} size="small" onClick={requestValidation}>
                        {resendSubmitting ? 'You can resend again in 60 seconds if you have not received' : 'Did not receive code? Resend.'}
                    </LoadingButton>
                </ValidatorForm>
            </div>
            <br /><br />
        </Layout >
    );
};



export default ValidateEmail;