import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { useAPI } from "../../utils/useAPI";
import LoadingButton from "@mui/lab/LoadingButton";
import { Navigate, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import PageTitle from "../../layout/PageTitle";
import { useUser } from "../../utils/useUser";
import { Col, Row } from "react-bootstrap";

export const SetupPayment = () => {

    const api = useAPI();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [setupPaymentUrl, setSetupPaymentUrl] = useState<string | undefined | null>(null);
    const [validateEmail, setValidateEmail] = useState<boolean>(false);
    const [ready, setReady] = useState<boolean>(false);
    const navigate = useNavigate();
    const user = useUser();
    const goValidateEmail = () => {
        (async () => {
            try {
                await api.sendValidationCode("", false);
            } catch (e) {
                if (user.authenticated) {
                    setMessage('Problem sending validation code - ' + (e as Error).message);
                }
                else {
                    setMessage('You need to be logged in to resend verification code')
                }
                return;
            }
        })();
        navigate('/validateEmail?redirect=' + window.encodeURIComponent(window.location.pathname + window.location.search))
    }

    const goStripe = () => {
        window.location.replace(setupPaymentUrl!)
    }

    const goStripeEdit = () => {
        window.open('https://dashboard.stripe.com/settings', '_stripe');
    }
    useEffect(() => {
        (async () => {

            try {
                const result = await api.setupPayment();
                setSetupPaymentUrl(result.url);
                setMessage(result.message ? result.message : '');
            } catch (err) {
                if ((err as Error).name === "NOT_VALIDATED") {
                    setValidateEmail(true);
                }
                else {
                    setErrorMessage((err as Error).message);
                }
            }
            setReady(true);
        })();
    }, []);
    if (!user.authenticated) {
        return (<Navigate to={"/login?type=login&redirect=" + window.encodeURIComponent(window.location.pathname + window.location.search)} />);
    }




    return (
        <Layout headerBtn={false} footer={3}>
            <PageTitle pageTitle="Payment Setup | Timedora" />
            <div className="payment-inner payment-wrapper">
                <Row>
                    <Col>
                        <b>Accept credit cards from customers:</b><br /><br />
                        <ul className="paymentdesc">
                            <li>Create paid topics.</li>
                            <li>Accept payments from customers using credit or debit cards.</li>
                            <li>Get paid for your time and expertise.</li>
                            <li>Reduce no show appointments.</li>
                            <li>Charge up front, monthly or per session.</li>
                            <li>Deposit earnings directly into your bank.</li>
                        </ul>
                        {ready ? <div>
                            {errorMessage && (
                                <div>
                                    {errorMessage}
                                </div>)
                            }
                            {setupPaymentUrl && (
                                <div>
                                    <br />
                                    <b>Connect with Stripe</b><br /><br />
                                    <p>
                                        Your email address is verified. Please set up your payment information to start accepting payments from customers and have your earnings be paid into your bank account.
                                        <br /><br />
                                        We have partnered with Stripe to handle credit card processing and payments to your bank account. You will be directed to Stripe's website to complete the necessary information.</p>
                                    {message.length > 0 &&
                                        <p className="divRedBold">{message} If you believe that you have filled in all necessary information, please check back again since your account may be pending verification.</p>}
                                    <LoadingButton disabled={errorMessage.length > 0} type="submit" style={{ width: '260px', marginTop: '20px' }} variant="contained" size="large" onClick={goStripe}>
                                        {message.length > 0 ? "Continue Stripe Setup" : "Connect to Stripe"}
                                    </LoadingButton>
                                    <br />
                                    <br />

                                </div>)
                            }
                            {!setupPaymentUrl && !errorMessage && !validateEmail && (
                                <div>
                                    <br />
                                    <b>Payment Connected</b><br /><br />
                                    <p>
                                        Congratulations! Your payment and bank account information is already set up!
                                    </p>
                                    <br />
                                    <LoadingButton disabled={errorMessage.length > 0} type="submit" style={{ width: '200px', marginTop: '20px' }} variant="contained" size="large" onClick={goStripeEdit}>
                                        Edit Payment
                                    </LoadingButton>
                                    <br />
                                    <br />
                                </div>)
                            }
                            {validateEmail &&
                                <div>
                                    <b>Getting Started</b><br />
                                    <p>
                                        You need to validate your email before being able to set up your payment profile. A payment profile will  allow you to accept credit card payments from your customer  and earnings to be deposited into your bank.
                                    </p>
                                    <br />
                                    <LoadingButton type="submit" style={{ width: '200px', marginTop: '20px' }} variant="contained" size="large" onClick={goValidateEmail}>
                                        Verify email
                                    </LoadingButton>
                                    <br />
                                    <br />

                                </div>
                            }



                        </div> :
                            <div>
                                <div className="courseCardPhoto"><CircularProgress sx={{ alignItems: 'center', margin: '50px' }} /></div>
                                <br />
                                <div className="divCenter">Getting payment setup information...</div>
                            </div>}
                    </Col>
                    <Col>
                        <div style={{ backgroundColor: '#F0F8FF', justifyContent: 'center', height: '100%', alignItems: 'center', display: 'flex', padding: '40px' }}>
                            <img alt="Timedora" width="68" height="68" src="images/timedoralogotransparent.png" />
                            <img alt="plus icon" width="68" height="68" src="images/icons/plus-icon-128.png" />
                            <img alt="Stripe" width="68" height="68" src="images/apps/app_stripe_icon.png" />
                        </div>
                    </Col>
                </Row>
            </div>
            <br /><br />

        </Layout >
    );
};



export default SetupPayment;