

import { faMicrophone, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Property } from "csstype";
import {
    LocalVideoTrack,
} from "livekit-client";
import React, {
    CSSProperties,
    ReactElement,
    useLayoutEffect,
    useState,
} from "react";
import { VideoRenderer } from "./VideoRenderer";
import "./conference.css";
import Box from "@mui/material/Box";
export interface LobbyViewProps {
    videoDevices: MediaDeviceInfo[], // Added this to see how many video sources there are. 0 means no camera
    isAudioMuted: boolean;
    track: LocalVideoTrack | null;
    displayName: string;
    // width in CSS
    width?: Property.Width;
    // height in CSS
    height?: Property.Height;
    orientation?: "landscape" | "portrait";
    className?: string;
    showOverlay?: boolean;
}

export const LobbyView = ({
    videoDevices,
    isAudioMuted,
    track,
    width,
    height,
    className,
    orientation,
    displayName,
}: LobbyViewProps) => {

    let mainElement: ReactElement;

    const [maxVideoHeight, setMaxVideoHeight] = useState('480px');
    const [maxVideoTopPadding, setMaxVideoTopPadding] = useState('40px');
    useLayoutEffect(() => {
        function updateSize() {
            var padding = 100;
            if (window.innerHeight < 700) {
                setMaxVideoHeight((window.innerHeight - 280) + 'px');
            }
            else {
                if (maxVideoHeight !== '468px') {
                    setMaxVideoHeight('468px');
                }
                padding = (window.innerHeight - 600) / 2;
                if (padding < 0) padding = 100;
            }
            setMaxVideoTopPadding(padding + 'px');

        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const containerStyles: CSSProperties = {
        width: width,
        height: height,
    };



    // when aspect matches, cover instead
    let objectFit: Property.ObjectFit = "contain";
    let videoOrientation: "landscape" | "portrait" | undefined;

    if (videoOrientation === orientation) {
        objectFit = "cover";
    }

    if (track) {
        mainElement = (
            <VideoRenderer
                track={track}
                isLocal={true}
                objectFit={objectFit}
                width="100%"
                height="100%"
            />
        );
    } else {
        mainElement = (
            <div className="conf-placeholder">
                <Box
                    display="flex"
                    flexWrap={'wrap'}
                    justifyContent="center"
                    alignItems="center"
                    textAlign={"center"}
                    padding={'40px'}
                    width={'100%'}
                    height={'100%'}
                >

                    {/* Turns out that video id is not a reliable way to see if camera is even plug in. A null video id can simply mean the default camera */}
                    {videoDevices.length === 0 ? 'Camera is not detected. Make sure you have a camera plugged in.' : 'Camera is not on. Tap Video button to turn on'}
                </Box>
            </div>
        );
    }

    const classes = ["conf-participant"];
    if (className) {
        classes.push(className);
    }

    return (
        <div style={containerStyles}>
            <div
                className={classes.join(" ")}
                style={{ paddingTop: maxVideoTopPadding }} >
                <div style={{ height: maxVideoHeight }}>
                    {mainElement}
                </div>
                <div className="conf-name1">
                    <FontAwesomeIcon
                        icon={isAudioMuted ? faMicrophoneSlash : faMicrophone}
                        height={32}
                        style={{ marginRight: 10 }}
                    />
                    {displayName} (Click join when ready)
                </div>
                <div className="conf-participant-bar">
                    <div className="conf-end">
                    </div>
                </div>
            </div>
        </div >
    );
};