import { faVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { Room } from "livekit-client";
import React, { useCallback, useEffect, useState } from "react";
import { tdrLog } from "../../utils/utils";
import { ControlButton, MenuItem } from "./ControlButton";

export interface VideoSelectButtonProps {
    isEnabled: boolean;
    onClick?: () => void;
    onSourceSelected?: (device: MediaDeviceInfo) => void;
    disableText?: string;
    enableText?: string;
    className?: string;
    popoverContainerClassName?: string;
    popoverTriggerBtnClassName?: string;
    popoverTriggerBtnSeparatorClassName?: string;
}

export const VideoSelectButton = ({
    isEnabled,
    onClick,
    onSourceSelected,
    disableText = "", // Take away video work
    enableText = "", // Take away video word to make icon button smaller used to be "Video"
    className,
    popoverContainerClassName,
    popoverTriggerBtnClassName,
    popoverTriggerBtnSeparatorClassName,
}: VideoSelectButtonProps) => {
    const [sources, setSources] = useState<MediaDeviceInfo[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItem<string>[]>([]);

    const listVideoDevices = useCallback(async () => {

        try {
            const devices = await Room.getLocalDevices("videoinput");
            if (devices.length === 0) {
                isEnabled = false;
            }
            setSources(devices);
            setMenuItems(
                devices.map((item) => {
                    return { label: item.label, obj: item.label };
                })
            );
            tdrLog("Video devices in VideoSelectButton = " + devices.length);

        }
        catch (err) {

            tdrLog("Video devices in VideoSelectButton = 0")
            isEnabled = false;
            setSources([]);
            setMenuItems([]);
        }

    }, []);

    useEffect(() => {
        listVideoDevices();
        navigator.mediaDevices.addEventListener("devicechange", listVideoDevices);
        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                listVideoDevices
            );
        };
    }, []);

    const handleMenuItem = (item: MenuItem<string>) => {
        const device = sources.find((d) => d.label === item.label);
        if (device && onSourceSelected) {
            onSourceSelected(device);
        }
    };

    return (
        <ControlButton
            label={isEnabled ? disableText : enableText}
            tooltip={isEnabled ? 'Turn off video' : 'Turn on video'}
            icon={isEnabled ? faVideo : faVideoSlash}
            onClick={onClick}
            menuItems={menuItems}
            onMenuItemClick={handleMenuItem}
            className={className}
            popoverContainerClassName={popoverContainerClassName}
            popoverTriggerBtnClassName={popoverTriggerBtnClassName}
            popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
        />
    );
};