import { Property } from "csstype";
import {
    LocalTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack,
    TrackPublication,
} from "livekit-client";
import React, {
    CSSProperties,
    MouseEventHandler,
    ReactElement,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { AspectRatio } from "react-aspect-ratio";
import { DisplayContext } from "./DisplayContext";
import { useParticipant } from "./useParticipant";
import { VideoRenderer } from "./VideoRenderer";
import "./conference.css";
import { VideoSource } from "./ConferenceTypes";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { tdrLog } from "../../utils/utils";
export interface ParticipantProps {
    participant: Participant;
    source: VideoSource;
    displayName?: string;
    // width in CSS
    width?: Property.Width;
    // height in CSS
    height?: Property.Height;
    className?: string;
    // aspect ratio width, if set, maintains aspect ratio
    aspectWidth?: number;
    // aspect ratio height
    aspectHeight?: number;
    // determine whether to contain or cover video.
    // cover mode is used when layout orientation matches video orientation
    orientation?: "landscape" | "portrait";
    // true if overlay with participant info should be shown
    showOverlay?: boolean;
    // show speaking
    showSpeaking?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export const UserView = ({
    participant,
    source,
    width,
    height,
    className,
    aspectWidth,
    aspectHeight,
    orientation,
    displayName,
    showOverlay,
    showSpeaking,
    onMouseEnter,
    onMouseLeave,
}: ParticipantProps) => {
    const { cameraPublication, isLocal, screenSharePublication, facePublication } = useParticipant(participant);
    const [videoSize, setVideoSize] = useState<string>();
    const [incomingVideoSize, setIncomingVideoSize] = useState({ x: 320, y: 240, ratio: 1.33 });

    const [currentBitrate, setCurrentBitrate] = useState<number>();
    const context = useContext(DisplayContext);
    const [profileURL, setProfileURL] = useState<string | null>(null)
    const [speakMode, setSpeakMode] = useState(false);
    const handleResize = useCallback((width: number, height: number) => {
        setVideoSize(`${width}x${height}`);
        let ratio = 1.33;
        let displayRatio = 1.33;
        /* This helps size the video properly and make it fill aspect wise to the container
        not sure why it works but seems to . */
        if (height > 0 && width > height) {
            ratio = width / height;
            if (aspectWidth && aspectHeight && aspectWidth !== 0)
                displayRatio = aspectWidth / aspectHeight;
        }
        else if (height > 0 && width < height) {
            if (aspectWidth && aspectHeight && aspectHeight !== 0)
                displayRatio = height / width;
            if (displayRatio < ratio) ratio = displayRatio;

        }
        ratio = ratio - 0.75;
        setIncomingVideoSize({ x: width, y: height, ratio: ratio });
        tdrLog("The video size is " + width + "," + height);
    }, []);

    const stringToColor = (string: string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    const stringAvatar = (name: string) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 80,
                height: 80
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    useEffect(() => {
        if (!participant.isSpeaking) {
            let interval = setTimeout(() => {
                setSpeakMode(false);
            }, 2000);
            return () => {
                clearInterval(interval);
            };
        }
        else {
            setSpeakMode(true);
        }
    }, [participant.isSpeaking]);

    useEffect(() => {
        const interval = setInterval(() => {
            let total = 0;
            participant.tracks.forEach((pub) => {
                if (
                    pub.track instanceof LocalTrack ||
                    pub.track instanceof RemoteVideoTrack ||
                    pub.track instanceof RemoteAudioTrack
                ) {
                    total += pub.track.currentBitrate;
                }
            });
            setCurrentBitrate(total);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (participant.metadata && participant.metadata !== null) {
            try {
                const metaJSON = JSON.parse(participant.metadata);
                if (metaJSON.profile !== null) {
                    setProfileURL(metaJSON.profile);
                }
            }
            catch (err) {

            }
        }
    }, [participant.metadata]);

    if (!displayName) {
        if (participant.identity) {
            displayName = participant.name;
        }
        if (isLocal) {
            displayName += " (You)";
        }
    }

    let onClickVideo: MouseEventHandler<HTMLAnchorElement> = (e) => { };

    const hasCameraPublication = cameraPublication?.isSubscribed && cameraPublication?.track && !cameraPublication?.isMuted;
    const hasScreenSharePublication = screenSharePublication?.isSubscribed && screenSharePublication?.track && !screenSharePublication?.isMuted;
    const hasFacePublication = facePublication?.isSubscribed && facePublication?.track && !facePublication?.isMuted;
    let mainElement: ReactElement;
    let selectedTrackPublication: TrackPublication | null = null;

    if (hasCameraPublication && (
        source === VideoSource.Auto || (source === VideoSource.Camera || (source === VideoSource.Screen && !hasScreenSharePublication) || (source === VideoSource.Face && !hasFacePublication))
    )) {
        selectedTrackPublication = cameraPublication;
    } else if (hasScreenSharePublication && (source === VideoSource.Screen || source === VideoSource.Auto || (source === VideoSource.Face && !hasFacePublication))) {
        selectedTrackPublication = screenSharePublication;
    } else if (hasFacePublication && (source === VideoSource.Face || source === VideoSource.Auto || (source === VideoSource.Screen && !hasScreenSharePublication))) {
        selectedTrackPublication = facePublication;
    }
    const containerStyles: CSSProperties = {
        width: width,
        height: height,
        border: '5px solid black',
        borderRadius: 8,
    };
    const containerStylesSpeak: CSSProperties = {
        width: width,
        height: height,
        border: '5px solid #2a6fde',
        borderRadius: 8,
    };

    // when aspect matches, cover instead
    let objectFit: Property.ObjectFit = "cover";
    let videoOrientation: "landscape" | "portrait" | undefined;
    if (!orientation && aspectWidth && aspectHeight) {
        orientation = aspectWidth > aspectHeight ? "landscape" : "portrait";
    }
    if (selectedTrackPublication?.dimensions) {
        videoOrientation =
            selectedTrackPublication.dimensions.width > selectedTrackPublication.dimensions.height
                ? "landscape"
                : "portrait";
    }

    if (videoOrientation === orientation) {
        objectFit = "contain";
    }
    let showScreen = true;
    if (isLocal && context.displayOptions.videoDevices === 0) {
        // Camera is off for local participant
        // Ideally we should be able to infer from cameraEnabled but for some reason not working so use number
        // of video devices to determine.
        showScreen = false;
    }
    if (selectedTrackPublication && showScreen) {
        mainElement = (
            <div style={{ height: '100%' }}>
                <VideoRenderer
                    track={selectedTrackPublication.track!}
                    isLocal={selectedTrackPublication === screenSharePublication ? false : isLocal}
                    objectFit={objectFit}
                    width="100%"
                    height="100%"
                    onSizeChanged={handleResize}
                />
            </div>
        );
        onClickVideo = (e) => {
            e.preventDefault();
            context.displayOptionsDispatch({ type: 'setSelectedParticipant', payload: { newParticipant: participant.identity } })
        }
    } else {
        mainElement = <div className="conf-placeholder" >
            <Box
                color="white"
                display="flex"
                flexWrap={'wrap'}
                justifyContent="center"
                alignItems="center"
                width={'100%'}
                height={'80%'}
            >
                {profileURL === null ?
                    <div>{displayName ? <Avatar {...stringAvatar(displayName)} /> : <Avatar src="/images/no-profile-picture.png" />}</div> :

                    <Avatar
                        alt={displayName}
                        src={profileURL}
                        sx={{ width: 80, height: 80 }}
                    />
                }
            </Box></div>;
    }

    const classes = ["conf-participant"];
    if (className) {
        classes.push(className);
    }
    const isAudioMuted = !participant.isMicrophoneEnabled;

    // gather stats
    let statsContent: ReactElement | undefined;
    if (context.displayOptions.showStats) {
        statsContent = (
            <div className="conf-stats">
                <div>{videoSize}</div>
                {currentBitrate !== undefined && currentBitrate > 0 && (
                    <div>{Math.round(currentBitrate / 1024)} kbps</div>
                )}
            </div>
        );
    }

    return (
        <div style={speakMode ? containerStylesSpeak : containerStyles}>
            <a href="#" onClick={onClickVideo}>
                <div
                    className={classes.join(" ")}
                    style={containerStyles}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}>
                    {(aspectWidth && aspectHeight) && (
                        <AspectRatio ratio={aspectWidth / aspectHeight}>
                            {mainElement}
                        </AspectRatio>
                    )}
                    {(!aspectWidth || !aspectHeight) && mainElement}

                    {(showOverlay || context.displayOptions.showStats) && (
                        <div className="conf-participant-bar">
                            <div>
                                {isAudioMuted ? <MicOffIcon sx={{ color: 'red' }} /> : <MicIcon sx={{ color: 'white' }} />}
                                {speakMode ? <RecordVoiceOverIcon sx={{ color: 'white' }} /> : ''}
                            </div>
                            <div className="conf-name">{displayName}</div>
                            <div className="conf-center">{statsContent}</div>
                            <div className="conf-end">
                            </div>

                        </div>


                    )}
                </div></a>
        </div>
    );
};