import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopicDetails from "../../components/Course Details/TopicDetails";
import { api as apiproto } from "../../apiproto";
import Layout from "../../layout/Layout";
import PageTitle from "../../layout/PageTitle";
import { checkIfTopicID, checkIfTopicProfile, computeEpochtoTimeNoAMPMStr, generateSEOTitle, getUserPhotoURL } from "../../utils/eduutils";
import { useAPI } from "../../utils/useAPI";
import Paper from "@mui/material/Paper";
import { tdrAlert } from "../../utils/utils";

const TopicInfoDetails = () => {

  type TopicParams = {
    seotitle: string,
    id: string
  }

  const api = useAPI();
  const topicParams = useParams<TopicParams>();
  const [isFetched, setIsFetched] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [detailRefresh, setDetailRefresh] = useState(0);

  enum Frequency {
    NOREPEAT = '0',
    DAILY = '10',
    WEEKLY = '20',
    MONTHLY = '30',
  }

  const initialState: apiproto.ITopic = {
    topicId: null,
    name: "",
    description: JSON.stringify({ summary: "", description: "", prerequisites: "" }),
    minAge: 9,
    maxAge: 12,
    maxParticipants: 1,
  };


  interface ExtendedSchedule extends apiproto.ISchedule {
    dateRange: Date,
    fromDate: Date,
    toDate: Date,
    fromTime: string,
    toTime: string,
    frequency: string,
    toDateErr: string,
    toTimeErr: string,
    durationStr: string
  }

  interface ExtendedRoom extends apiproto.IRoom {
    classroomKey: string,
    schedules: ExtendedSchedule[],
    existingClass: boolean,
  }



  const [topicInfoState, setTopicInfoState] = useState<apiproto.ITopic>(initialState);

  const refreshTopic = () => {
    // Force a refresh on useEffect
    // But this tends to slow things down and cause some delay reaction in button presses
    // TODO= need to figure out when to refresh and when not to.
    setRefresh(refresh + 1);
  }


  useEffect(() => {
    (async () => {
      try {
        let topicid = topicParams.id;
        if (topicid && !checkIfTopicID(topicid)) {
          // This could be a bitly link
          const result = await api.lookupTinyCode(topicid);
          if (result && result.objectId) {
            /*
            const redirect = '/topic/' + generateSEOTitle(result.name ? result.name : 't') + '/' + window.encodeURIComponent(result.objectId!) + window.location.search;
            tdrAlert(redirect)
            window.location.href = redirect;*/
            topicid = result.objectId;
          }
          else {
            return;
          }
        }
        if (topicid) {
          const topicInfo = await api.getTopic({ includeDescription: true, reviews: {}, assets: {}, members: { entity: { user: { includeDescription: false } } }, rooms: { members: {}, scheduleSet: { schedules: { times: { members: {} } } } } }, topicid);
          if (topicInfo && topicInfo.topic) {
            if (topicInfo.topic.topicId && checkIfTopicProfile(topicInfo.topic.topicId)) {
              const userId = topicInfo.topic.topicId.replace("c_", "");
              const userResult = await api.getUser({ includeDescription: true, assets: {} }, { userId: userId });
              if (userResult && userResult.user) {
                const userInfo = userResult.user;
                if (userInfo && userInfo.description) {
                  var thedescription = JSON.parse(userInfo.description!);
                  thedescription = { ...thedescription, about_me: decodeURIComponent(thedescription.about_me) }
                  topicInfo.topic.name = userInfo.firstName + ' ' + userInfo.lastName;
                  const newdescription = {
                    summary:
                      "", description: decodeURIComponent(thedescription.about_me), prerequisites: "", supply_list: "", assignments: "", time_commitment: "", photo: getUserPhotoURL(userInfo)
                  };
                  topicInfo.topic.description = JSON.stringify(newdescription);
                }
              }
            }
            setTopicInfoState(topicInfo.topic)
            // WE check if SEO string is the same and if not we redirect again
            const seotitle = generateSEOTitle(topicInfo.topic.name!);
            if (topicParams.seotitle !== seotitle) {
              window.location.href = '/topic/' + seotitle + '/' + window.encodeURIComponent(topicInfo.topic.topicId!) + window.location.search;
              return;
            }
          }
          if (topicInfo.topic?.rooms) {
            // We now need to convert this to more UI friendly way.
            var rooms: ExtendedRoom[] = [];
            topicInfo.topic?.rooms.forEach((classItem, index) => {
              var newschedule: ExtendedSchedule[] = [];

              classItem.scheduleSet?.schedules!.forEach((schedule, index1) => {
                const fromDate: Date = new Date(+schedule.startTime!.toString());
                const endDate: number = +schedule.startTime!.toString() + +schedule.duration!;
                const scheduleinitial: ExtendedSchedule = {
                  // scheduleId: "",
                  scheduleId: schedule.scheduleId,
                  startTime: schedule.startTime,
                  duration: schedule.duration,
                  repeatCount: schedule.repeatCount, // For now we don't do repeat count until we add custom schedule
                  repeatEndDate: schedule.repeatEndDate,
                  // classroomTimes: [],
                  // This is the original one for UI
                  dateRange: new Date(),
                  fromDate: fromDate,
                  timeZone: schedule.timeZone,
                  toDate: schedule.repeatCount === 0 ? fromDate : new Date(+schedule.repeatEndDate!.toString()),
                  fromTime: computeEpochtoTimeNoAMPMStr(schedule.startTime!.toString()),
                  toTime: computeEpochtoTimeNoAMPMStr(endDate.toString()),
                  frequency: (schedule.daily ? Frequency.DAILY.toString() : (schedule.weekly ? Frequency.WEEKLY.toString() : (schedule.monthly ? Frequency.MONTHLY.toString() : Frequency.NOREPEAT.toString()))),
                  // Customize error:
                  toDateErr: "",
                  toTimeErr: "",
                  durationStr: 'End time',
                }
                newschedule = [...newschedule, scheduleinitial];
              }
              )
              rooms = [...rooms,
              {
                topicId: classItem.topicId!,
                roomId: classItem.roomId!,
                classroomKey: classItem.roomId!,
                section: classItem.section,
                room: classItem.room,
                schedules: newschedule,
                existingClass: true,
              }
              ];

            }
            )

          }
          setDetailRefresh(detailRefresh + 1);
        }
      } catch (err) {
        tdrAlert('Error loading class:' + err);
        setFetchError(true);
      }
      setIsFetched(true);
    })();
  }, [topicParams.id, refresh]);
  return (
    <Layout headerBtn={false} footer={3}>
      {fetchError &&
        <div className="inner-page-wrapper divpadding">
          <PageTitle motherTitle="Timedora" pageTitle="Page is private" />
          <Paper style={{ padding: 100 }} elevation={6}>
            <br /><br /><br /><br /><br />
            <h4 className="text-center">The page is not available or is private.</h4>
            <br /><br /><br /><br /><br />
          </Paper>
        </div>
      }
      {isFetched && !fetchError &&
        <div className="inner-page-wrapper">
          <PageTitle motherTitle="Topic" pageTitle={(topicInfoState && topicInfoState.name) ? topicInfoState.name : "Timedora"} />
          <TopicDetails topicInfo={topicInfoState} topicrooms={[]} isFetched={isFetched} refreshCallback={refreshTopic} refresh={detailRefresh} />
        </div>
      }
    </Layout>
  );
};

export default TopicInfoDetails;
