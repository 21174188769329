import { useNavigate } from "react-router-dom";
import React from "react";
import Button from "@mui/material/Button";

const MainBanner5 = () => {
    const navigate = useNavigate();

    const loginClicked = () => {
        let path = '/login?type=signup&cat=expert';
        navigate(path);
    }
    const sessionClicked = () => {
        navigate('/sessions');
    }

    return (
        <section
            id="banner-5"
            className="bg-whitesmoke wide-60 division sectionpadding"
        >
            <div className="container">
                <div className="row">
                    {/* BANNER TEXT */}
                    <div className="col-md-12">
                        <div className="banner-5-txt">
                            {/* Icon */}
                            <img src="/images/image-04.png" alt="banner-icon" />
                            {/* Text */}
                            <div className="b5-txt">
                                {/* Title */}
                                <h4 className="h4-xs">Become an Expert</h4>
                                {/* Text */}
                                <p>
                                    Earn by sharing your knowledge and domain expertise to people who needs it.
                                </p>
                                <Button size="medium" variant="contained" onClick={loginClicked} sx={{
                                    ':hover': {
                                        bgcolor: '#ff1f59', // theme.palette.primary.main
                                        color: 'white',
                                    }, textAlign: 'center', width: 250, height: 50, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none'
                                }}>
                                    Get Started
                                </Button>

                            </div>
                        </div>
                    </div>
                    {/* END BANNER TEXT */}
                    {/* BANNER TEXT 
                    <div className="col-md-6">
                        <div className="banner-5-txt">
                            <img src="/images/image-05.png" alt="banner-icon" />
                            <div className="b5-txt">
                                <h4 className="h4-xs">Find Expert</h4>
                                <p>
                                    Book a time and connect live with an expert and learn from a domain expert and professional.
                                </p>
                                <Button size="medium" variant="contained" onClick={sessionClicked} sx={{
                                    ':hover': {
                                        bgcolor: '#ff1f59', // theme.palette.primary.main
                                        color: 'white',
                                    }, textAlign: 'center', width: 250, height: 50, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none'
                                }}>
                                    Find Expert
                                </Button>
                            </div>
                        </div>
                    </div>
                     */}
                </div>
                {/* End row */}
            </div >
            {/* End container */}
        </section >
    );
};

export default MainBanner5;
