import React, { Fragment, useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import LoadingButton from "@mui/lab/LoadingButton";
import { Col, Row } from "react-bootstrap";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import { useNavigate } from "react-router-dom";
import { tdrAlert, tdrLog } from "../../utils/utils";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};

type Plan = {
    name: string;
    monthly: number;
    yearly: number; // This is in monthly and needs to X 12
    total: number;
    monthlyStr: string;
    yearlyInMonthStr: string;
    yearlyStr: string;
    totalStr: string;
}

type ChildProps =
    {
        planSelected: Plan,
        recur: string,
        license: number,
        stripeKeys: StripeKeys,
        existingPayment: string,
        paymentCancel?: () => void | null,
        paymentSuccess?: () => void | null,
    }

const ProductCheckout: React.FC<ChildProps> = ({ planSelected = { name: "Free", monthly: 0, yearly: 0, total: 0, monthlyStr: "$0.00", yearlyInMonthStr: "$0.00", yearlyStr: "$0.00", totalStr: "0" }, recur = "monthly", license = 1, stripeKeys = { clientSecret: "", publishableKey: "" }, existingPayment = "", paymentCancel = () => { }, paymentSuccess = () => { } }) => {
    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string | null | undefined>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPaymentSuccess, setConfirmPaymentSuccess] = useState(false);
    const [setupIntentID, setSetupIntentID] = useState<string | null>(null);
    const [paymentInfo, setPaymentInfo] = useState({ lastName: "", firstName: "", email: "" });
    const [closeButton, setCloseButton] = useState(false);
    const [paymentRadioValue, setPaymentRadioValue] = React.useState('new');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentRadioValue((event.target as HTMLInputElement).value);
    };

    const getUserProfile = async (userId: string) => {
        try {
            const userResult = await api.getUser({ assets: {} }, { userId: userId });
            if (userResult && userResult.user) {
                const userinfo = userResult.user;
                if (userinfo.firstName && userinfo.lastName && userinfo.emailAddress) {
                    setPaymentInfo({ firstName: userinfo.firstName, lastName: userinfo.lastName, email: userinfo.emailAddress })
                }
                return userResult.user;
            }
            /*
            if (topicOwners && topicOwners.length > 0) {
                setPaymentInfo({ firstName: topicOwners[0].firstName!, lastName: topicOwners[0].lastName!, email: topicOwners[0].emailAddress! })
            }*/
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
        }
        return null;
    }

    useEffect(() => {

        if (user.userId !== null && stripeKeys.publishableKey.length > 0) {
            getUserProfile(user.userId);
        }
    }, [stripeKeys.publishableKey]);

    useEffect(() => {
        if (existingPayment.length > 0) {
            setPaymentRadioValue('existing');
        }
    }, [existingPayment.length]);


    const handleSubmit = async () => {
        if (closeButton) {
            // Here is means something failed with Stripe 
            // we refresh entire page to get a refreshed Stripe object.
            // Here is why, user A and user B both checkout and then both hit confirm
            // the first one will succeed. User B will fail with require_payment_method which really should not be the error name
            // regardless this failure, we need to refresh.
            setCloseButton(false);
            window.location.reload();
            return;
        }
        if (!user.authenticated) {
            navigate('/login');
        }

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        if (!stripe) {
            return;
        }
        if (stripeKeys.clientSecret.length === 0) {
            return;
        }
        let returnurl = "https://" + window.location.hostname + "/pricing";
        if (window.location.hostname === 'localhost') {
            returnurl = "http://localhost:3000/pricing"
        }
        if (paymentRadioValue === 'existing') {
            setMessage("Processing payment on file...please wait...");
            setSetupIntentID(null);
            setIsLoading(true);
            paymentSuccess();
            return;
        }
        else {

        }

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: returnurl
            },
            redirect: "if_required"
        })
        if (error) {
            tdrAlert('The error is ' + error.type)
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            }
            else if (error.type === "invalid_request_error") {
                // This happens when the same user press book on 2 different tabs or browser
                // Tries to enter the credit card in the 1st one - but possibly the 2nd one made the 1st invalid
                // so it needs to be closed.
                setMessage("There was an invalid request. Close this screen and try again.");
                setCloseButton(true);
            }
            else {
                setMessage("An unexpected error occurred.");
            }
        }
        else if (setupIntent) {
            setMessage("Processing payment...Please do not close your browser.");
            setIsLoading(true);
            setSetupIntentID(setupIntent.id);
            return;
        }
        setIsLoading(false);
    };

    const refreshPaymentIntent = () => {
        if (setupIntentID != null && stripe) {
            stripe.retrieveSetupIntent(stripeKeys.clientSecret).then(({ setupIntent }) => {
                switch (setupIntent!.status) {
                    case "succeeded":
                        setMessage("Payment authorized...please wait...");
                        //setConfirmPaymentSuccess(true);
                        setSetupIntentID(null);
                        setIsLoading(true);
                        paymentSuccess();
                        break;
                    case "processing":
                        setMessage("Your payment setup is processing.");
                        setIsLoading(true);
                        break;
                    case "requires_payment_method":
                        // Do not trap this error because it can come here if you retrieve too quickly and
                        // stripe has still not process payment info.
                        // Not sure Stripe returns this error instead of something else when payment is actually submitted
                        setMessage("Your payment was not successful, please try again.");
                        setIsLoading(false);
                        //setPaymentIntentID(null);
                        //setCloseButton(true);
                        break;
                    case "canceled":
                        setMessage("Payment setup was cancelled. This can be due to a card error or someone booked the session just ahead of you. Please try again.");
                        setSetupIntentID(null);
                        setIsLoading(false);
                        setCloseButton(true);
                        break;
                    case "requires_confirmation":
                        setMessage("Payment requires confirmation.");
                        setIsLoading(false);
                        break;
                    default:
                        setMessage("Something went wrong.");
                        setSetupIntentID(null);
                        setIsLoading(false);
                        break;
                }
            }).catch((err) => {
                setMessage("An error occurred processing payment setup. Please try again");
                tdrLog('error occurred - ' + err.name);
                setSetupIntentID(null);
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        if (setupIntentID !== null) {
            const interval = setInterval(() => {
                refreshPaymentIntent()
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [setupIntentID])

    return (
        <div>
            <Fragment>
                <Row>
                    <Col>
                        <div className="form-group form-input">
                            <div className="mb-10">
                                <h3>Order Summary</h3>
                                <br />
                                <Row>
                                    <Col md="8">
                                        <b>{planSelected.name} Subscription</b>
                                        <br />
                                        {recur === "monthly" ? planSelected.monthlyStr : planSelected.yearlyInMonthStr} {recur === "yearly" && ' X 12 months'} {license > 1 ? " X " + license + " licenses" : ""}
                                    </Col>
                                    <Col md="4" style={{ textAlign: 'right' }}>
                                        <b>{planSelected.totalStr}</b>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col>
                                        <b>Total</b>
                                    </Col>
                                    <Col style={{ textAlign: 'right' }}>
                                        <b>{recur === "monthly" ? planSelected.totalStr + "/month" : planSelected.totalStr + "/year"}</b>
                                    </Col>
                                </Row>
                                <hr />
                            </div>

                        </div>
                    </Col>
                </Row>
            </Fragment>
            {existingPayment.length > 0 &&
                <Row>
                    <Col>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={paymentRadioValue}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="existing" control={<Radio />} label={existingPayment} />
                                <FormControlLabel value="new" control={<Radio />} label="Use new payment method" />
                            </RadioGroup>
                        </FormControl>
                        <br />
                    </Col>
                </Row>
            }
            {confirmPaymentSuccess ? <div></div>
                :
                <Fragment>
                    <div>

                        <Row>
                            <Col>
                                {paymentRadioValue === 'new' ?
                                    <PaymentElement id="payment-element" /> : ""}
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col>
                            <br />
                            <p className="form-error">{message}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                sx={{ float: 'right', mr: 2 }}
                                size="large"
                                variant="text"
                                onClick={paymentCancel}
                                hidden={false}
                                disabled={isLoading}>
                                Cancel
                            </Button>
                            <LoadingButton
                                sx={{ float: 'right', mr: 2 }}
                                size="large"
                                variant="contained"
                                loading={isLoading}
                                onClick={handleSubmit}
                                hidden={false}>
                                Purchase
                            </LoadingButton>
                        </Col>
                    </Row>
                </Fragment>
            }

        </div>
    );
}

export default ProductCheckout;