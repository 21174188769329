import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import { api as apiproto } from "../../apiproto";
import Typography from '@mui/material/Typography';
import { Button, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useSearchParams } from "react-router-dom";
import DialogMessageBox from "../../components/DialogMessageBox";
import TopicCard from "../../components/Course Details/TopicCard";
import SearchBar from "./searchbar";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "react-bootstrap";
import PageTitle from "../../layout/PageTitle";
import { tdrLog } from "../../utils/utils";

export const Sessions = () => {

    const navigate = useNavigate();
    const api = useAPI();
    const user = useUser();

    let [topics, setTopics] = useState<(apiproto.ITopic | undefined | null)[]>();

    const [searchParams] = useSearchParams();
    const [sessionType, setSessionType] = React.useState(""); // created = topics I created, book = topics I booked, invite = topics I am invited

    useEffect(() => {
        let stype = searchParams.get('type');
        if (stype === null || stype.length === 0) {
            stype = "created"
        }
        setSessionType(stype);
    }, [api, searchParams])

    useEffect(() => {
        getEnrolledTopics();
    }, [])

    const [errDialogopen, setErrDialogOpen] = React.useState({ title: "", message: "" });

    const handleErrDialogClose = () => {
        setErrDialogOpen({ title: "", message: "" });
    };

    const getEnrolledTopics = () => {
        var topics: { [key: string]: apiproto.ITopic } = {};
        if (user.userId && user.authenticated) {
            (async () => {
                const userInfoResult = await api.getUser({ membersOf: { obj: { time: { room: { topic: { includeDescription: true, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {} } } }, room: { topic: { includeDescription: true, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {}, rooms: { scheduleSet: { schedules: { times: {} } } } } } } } }, { userId: user.userId });
                //const userInfoResult = await api.getUser({ assets: {}, membersOf: { obj: { time: { room: { topic: { includeDescription: true, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {} } } }, room: { topic: { includeDescription: false, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {}, rooms: { scheduleSet: { schedules: { times: {} } } } } } } } }, { userId: user.userId });
                //const userInfoResult = await api.getUser({ assets: {}, membersOf: { obj: { room: { topic: { includeDescription: true, members: {}, assets: {}, tags: {}, rooms: { scheduleSet: { schedules: { times: {} } } } } } } } }, { userId: user.userId });
                const filteredResults: apiproto.ITopic[] = [];
                tdrLog('UserInfo Result ' + JSON.stringify(userInfoResult));
                userInfoResult.user?.membersOf?.forEach((m) => {
                    tdrLog("===============")
                    tdrLog('Members ' + JSON.stringify(m))
                    if (m.obj?.room?.room?.topic) {
                        tdrLog("******************")
                        tdrLog("Enrolled " + user.userId + " getEnrolled " + JSON.stringify(m))
                        filteredResults.push(m.obj?.room?.room?.topic);
                    }
                    else if (m.obj?.time?.time?.room?.topic) {
                        tdrLog("$$$$$$$$$$$$")
                        tdrLog("Enrolled " + user.userId + " getEnrolled " + JSON.stringify(m))
                        var currentTopic = topics[m.obj?.time?.time?.room?.topic.topicId!]
                        if (!currentTopic) {
                            var newtopic = m.obj?.time?.time?.room?.topic!;
                            // We add an artificial room here to hold the enrolled time ID
                            const newschedule: apiproto.ISchedule = {
                                times: [{ startTime: m.obj.time.time.startTime, endTime: m.obj.time.time.endTime, timeId: m.obj.time.timeId }]
                            }
                            const newroom: apiproto.IRoom[] = [{
                                roomId: "r-" + newtopic.topicId,
                                topicId: newtopic.topicId,
                                section: m.obj.time.time.room.section,
                                room: m.obj.time.time.room.roomId,
                                scheduleSet: { name: "", schedules: [newschedule] },
                                scheduleSetId: null
                            }];
                            newtopic = { ...newtopic, rooms: newroom };
                            filteredResults.push(newtopic);
                            topics[newtopic.topicId!] = newtopic;
                        }
                        else {
                            if (currentTopic && currentTopic.rooms && currentTopic.rooms.length > 0 && currentTopic.rooms[0].scheduleSet) {
                                const newschedule: apiproto.ISchedule = {
                                    times: [{ startTime: m.obj.time.time.startTime, endTime: m.obj.time.time.endTime, timeId: m.obj.time.timeId }]
                                }
                                currentTopic.rooms[0].scheduleSet.schedules?.push(newschedule);
                            }
                        }
                    }
                })
                if (filteredResults) {
                    setTopics(filteredResults);
                    tdrLog("Filtered Results===========")
                    tdrLog(JSON.stringify(filteredResults));
                }

                /*
                                setCourses(userInfoResult.userInfo?.membersOf?.filter((m) => {
                                    return m.obj?.course?.courseInfo?.courseId;
                                }).map((m) => { return m.obj?.course?.courseInfo; }))*/
            })()

        }
        else {
            navigate('/login');
            //            getPublicTopics();
        }

    }
    const NoEnrolledTopics = () => {
        return (
            <Box textAlign='center' sx={{ mt: 8, mr: 7, ml: 7, mb: 8 }}>
                <Typography align="center" variant="h6" gutterBottom component="div">
                    You are not signed up for any sessions yet.
                </Typography>
                <br />
            </Box>
        )
    };

    const handleSubmit = () => {
    }

    return (
        <Layout headerBtn footer={3}>
            <PageTitle pageTitle="Enrolled Sessions | Timedora" />
            <div className="course-wrapper">
                <div className="course-inner divpadding">
                    <h3>Enrolled Sessions</h3>

                    <DialogMessageBox title={errDialogopen.title} message={errDialogopen.message} meta="" confirm={""} cancel={"Dismiss"} onCancel={handleErrDialogClose} visible={errDialogopen.title.length > 0} />
                    {topics?.length === 0 ? <NoEnrolledTopics />
                        : <div>
                            {
                                (topics ? (topics.map((topic, index) => (topic && <TopicCard topic={topic} type={sessionType} key={'cocard' + topic.topicId} />))) : "")}
                        </div >
                    }
                </div>
            </div >

        </Layout >);

}
export default Sessions

