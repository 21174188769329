
import { Routes, Route, useLocation } from "react-router-dom";
import React from 'react';
import { useEffect, useState } from "react";
import "swiper/css";
import VideoState from "../src/context/video";
import PreLoader from "../src/layout/PreLoader";
import "./styles/globals.css";
import About from './pages/about';
import Expert from './pages/expert';
import Contacts from './pages/contacts';
import Faqs from './pages/faqs';
import FaqIntegration from './pages/faqintegration';
import Pricing from './pages/pricing';
import { ActionType, AuthContext, AuthContextType } from "./context/authcontext";
import Login from "./pages/login";
import Conference from "./components/conference/conference";
import MainPage from "./pages/main"
import TopicCreate from "./pages/course/topiccreate";
import TopicInfoDetails from "./pages/course/topic";
import { MemberProfile } from "./pages/account/profile";
import Sessions from "./pages/course/sessions";
import PersonProfile from "./pages/person-profile";
import ValidateEmail from "./pages/validateEmail";
import SetupPayment from "./pages/account/setuppayment";
import ChangePassword from "./pages/changepassword";
import { api } from "./apiproto";
import TriggerRender from "./pages/triggerRender";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import ReactGA from 'react-ga4';
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import PageNotFound from "./pages/pagenotfound";
import Integration from "./pages/account/integration";
import CloseIcon from '@mui/icons-material/Close';
import SessionsEnrolled from "./pages/course/sessions_enrolled";
import ProductPurchase from "./pages/course/productpurchase";

/*
import BlogListing from './pages/blog-listing';
import CategoriesList from './pages/categories-list';
import CategoryDetails from './pages/category-details';
import CoursesList from './pages/courses-list';
import Reviews from './pages/reviews';
import SinglePost from './pages/single-post';
import TeacherProfile from './pages/teacher-profile';
import TeachersList from './pages/teachers-list';
import TestPayment from "./pages/account/testpayment";
import SurveyInput from "./pages/survey/survey-input";
import Test from "./pages/account/test";
import TalusTest from "./pages/survey/talustest";
*/

const reducer = (state: AuthContextType, action: ActionType) => {
    switch (action.type) {
        case "login":
            if (action.loginPayload) {
                localStorage.setItem("userId", action.loginPayload.userId);
                localStorage.setItem("token", action.loginPayload.token);
                localStorage.setItem("expirationTime", action.loginPayload.expirationTime.toString());
                return {
                    ...state,
                    userId: action.loginPayload.userId,
                    token: action.loginPayload.token,
                    expirationTime: action.loginPayload.expirationTime
                };
            } else {
                return state;
            }
        case "logout":
            localStorage.clear();
            return {
                ...state,
                userId: null,
                token: null
            };
        case "updateViewType":
            if (action.viewTypePayload) {
                localStorage.setItem("currentView", action.viewTypePayload.currentView === api.ViewType.ProviderView ? 'provider' : 'consumer');
                return {
                    ...state,
                    currentView: action.viewTypePayload.currentView
                }
            }
            else {
                return state;
            }
        default:
            return state;
    }
};
try {
    ReactGA.initialize([
        {
            trackingId: "G-KRJ7ETN4LS",
        },
    ])
}
catch (err) {
    // Ad blockers might cause an error.
}

export function App() {
    const location = useLocation();

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.send({ hitType: "pageview", page: window.location.pathname });
        }
    }, [location]);

    const [showRefreshPage, setShowRefreshPage] = useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(async () => {
            // check if current version matches what's on the web server
            const meta = document.querySelector('meta[name="build-version"]') as any
            if (meta && meta.content) {
                try {
                    const currentVersion = meta.content as string
                    // fetch version.txt
                    const response = await fetch("/version.txt", { cache: 'no-cache' })
                    if (response.status !== 200) {
                        // error
                        throw new Error("error fetching version")
                    }
                    const newVersion = await response.text()

                    console.log('refresh check - new version:' + newVersion + ', current version:' + currentVersion);

                    if (newVersion === "") {
                        return
                    }
                    if (newVersion !== currentVersion) {
                        setShowRefreshPage(true);
                        clearInterval(interval);
                    }
                } catch (err) {
                    // error checking
                    console.error('error checking for new version')
                }
            }
        }, 15 * 1000);
        return () => clearInterval(interval);
    }, []);
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const expirationTimeString = localStorage.getItem('expirationTime');
    const currentViewString = localStorage.getItem('currentView');
    let currentView: api.ViewType = api.ViewType.ConsumerView
    if (currentViewString === 'provider') {
        currentView = api.ViewType.ProviderView
    }
    const expirationTime = parseInt(expirationTimeString ? expirationTimeString : '0');
    const [state, dispatch] = React.useReducer(reducer, { userId: userId, token: token, expirationTime: expirationTime, currentView: currentView });
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, 300);
        return () => clearTimeout(timeout);
    }, []);

    const handleSnackClose = () => {
        setShowRefreshPage(false);
    }
    const handleSnackRefresh = () => {
        window.location.reload();
        handleSnackClose();
    }

    return (

        <AuthContext.Provider
            value={{
                state,
                dispatch
            }}>
            <VideoState>
                {loader && <PreLoader />}
                <div className="wsmenucontainer">
                    <Routes>
                        {/* Service pages */}
                        <Route path="/" element={<MainPage />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/faqs" element={<Faqs />} />
                        <Route path="/zoomintegration" element={<FaqIntegration />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/expert" element={<Expert />} />
                        <Route path="/terms" element={<Terms />} />
                        <Route path="/privacy" element={<Privacy />} />
                        {/* Conference pages */}
                        <Route path="/conferences/:classroomOrTimeId" element={<Conference />} />
                        {/* Account pages*/}
                        <Route path="/profile" element={<MemberProfile />} />
                        <Route path="/validateEmail" element={<ValidateEmail />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/resetPassword" element={<ChangePassword />} />
                        <Route path="/setuppayment" element={<SetupPayment />} />
                        <Route path="/integration" element={<Integration />} />
                        <Route path="/upgradePlan" element={<ProductPurchase />} />
                        {/* Session Pages */}
                        <Route path="/createtopic" element={<TopicCreate />} />
                        <Route path="/createtopic/:id" element={<TopicCreate />} />
                        <Route path="/sessions" element={<Sessions />} />
                        <Route path="/sessionsenrolled" element={<SessionsEnrolled />} />
                        <Route path="/topic/:seotitle/:id" element={<TopicInfoDetails />} />
                        <Route path="/t/:id" element={<TopicInfoDetails />} />
                        <Route path="/publicprofile/:seotitle/:id" element={<PersonProfile />} />
                        <Route path="/u/:id" element={<PersonProfile />} />


                        {/* Admin Pages */}
                        <Route path="/TriggerRender" element={<TriggerRender />} />
                        <Route path="*" element={<PageNotFound />} />

                        {/* I keep this links so I can reference them to the pages for the design later
                            <Route path="/test" element={<Test />} />
                            <Route path="/testpayment" element={<TestPayment />} />
                            <Route path="/survey" element={<SurveyInput />} />
                            <Route path="/talustest" element={<TalusTest />} />
                            <Route path="/blog-listing" element={<BlogListing />} />
                            <Route path="/categories-list" element={<CategoriesList />} />
                            <Route path="/category-details" element={<CategoryDetails />} />
                            <Route path="/courses-list" element={<CoursesList />} />
                            <Route path="/reviews" element={<Reviews />} />
                            <Route path="/single-post" element={<SinglePost />} />
                            <Route path="/teacher-profile" element={<TeacherProfile />} />
                            <Route path="/teachers-list" element={<TeachersList />} />
            */}
                    </Routes >
                    <Snackbar
                        open={showRefreshPage}
                        autoHideDuration={10000}
                        message={"The web site has been updated. Tap refresh on your browser to refresh this page to get the latest version."}

                    >
                        <Alert severity="info" sx={{ width: '100%' }} action={
                            <React.Fragment>
                                <Button color={'warning'} size="small" onClick={handleSnackRefresh}>
                                    Refresh
                                </Button>
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    sx={{ p: 0.5 }}
                                    onClick={handleSnackClose}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </React.Fragment>
                        }>
                            The web site has been updated. Tap refresh on your browser to refresh this page to get the latest version.  </Alert>
                    </Snackbar>
                </div >
            </VideoState >
        </AuthContext.Provider >
    );
}

