import { computeEpochtoPrettyDateStr, computeEpochtoTimeStr, IScheduleCard, isSessionReady } from "../../utils/eduutils";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { Button } from "@mui/material";
import { useUser } from "../../utils/useUser";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../apiproto";
import DialogMessageBox from "../DialogMessageBox";

export interface VideoStartButtonProps {
    scheduleCard: IScheduleCard | null,
    topicInfo: api.ITopic | null,
    width?: number | string,
    showDate?: boolean
}
export const VideoStartButton: React.FC<VideoStartButtonProps> = ({ scheduleCard = null, topicInfo = null, width = 180, showDate = false }: VideoStartButtonProps) => {

    const user = useUser();
    const navigate = useNavigate();
    /******************************************************* 
    * 
    * Dialog box section for handling actions confirmation
    * 
    * *****************************************************/
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });

    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true });

    }
    const [btnColor, setBtnColor] = useState<string>("gray");
    const [height, setHeight] = useState(50);
    const [dateStr, setDateStr] = useState({ day: "", date: "", month: "", year: "", time: "" });
    useEffect(() => {
        if (scheduleCard === null || topicInfo === null) return;
        if (scheduleCard.enrolled && (+scheduleCard.starttime - 15 * 60 * 1000) < Date.now() && Date.now() < (+scheduleCard.endtime + 15 * 60 * 1000)) {
            setBtnColor("");
        }
        else if (isSessionReady(scheduleCard) === 0) {
            setBtnColor("");
        }
        else {
            setBtnColor("gray");
        }
        let minTime = scheduleCard.starttime;
        setDateStr({ day: computeEpochtoPrettyDateStr(minTime.toString(), 'ddd'), date: computeEpochtoPrettyDateStr(minTime.toString(), 'D'), month: computeEpochtoPrettyDateStr(minTime.toString(), 'MMM'), year: computeEpochtoPrettyDateStr(minTime.toString(), 'yy'), time: computeEpochtoTimeStr(minTime.toString()) })
        if (showDate) {
            setHeight(90);
        }
    }, [topicInfo?.topicId, scheduleCard?.roomId, scheduleCard?.timeId, showDate])

    const startSession = (scheduleCard: IScheduleCard | null) => {
        if (user !== null && !user.authenticated) {
            navigate('/login');
        }
        if (scheduleCard === null) return;
        const sessionReadiness = isSessionReady(scheduleCard);
        if (sessionReadiness > 0) {
            const starttime = moment.unix((scheduleCard.starttime! as number) / 1000);
            setDialogMessage({ title: 'Session time', message: 'The session only starts in ' + moment().to(starttime, true) + '. Please join the session later.', meta: { scheduleCard: scheduleCard }, confirm: false });
        }
        else if (sessionReadiness < 0) {
            const endtime = moment.unix((scheduleCard.endtime! as number) / 1000);
            setDialogMessage({ title: 'Session time', message: 'The session ended ' + endtime.fromNow() + '. You can still click confirm to join but there may be no one there.', meta: { scheduleCard: scheduleCard }, confirm: true });
        }
        else {
            startSessionConfirm(scheduleCard);
        }
    }

    const startSessionConfirm = (scheduleCard: IScheduleCard) => {
        var sessionid = null;
        if (topicInfo?.singleSession && scheduleCard.timeId) {
            sessionid = scheduleCard.timeId;
        }
        else {
            sessionid = scheduleCard.roomId;
        }
        if (sessionid) {
            navigate("/conferences/" + window.encodeURIComponent(sessionid));
        }
        else {
            //TODO
            setDialogMessage({ title: 'Session Error', message: 'There was an error starting your session.', meta: { scheduleCard: scheduleCard }, confirm: true });
        }
    }

    const handleConfirm = (meta: any) => {
        if (meta.scheduleCard) {
            startSessionConfirm(meta.scheduleCard);
        }
    }

    const handleCancel = () => {
        clearDialog();
    }


    return (
        <Fragment>
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Dismiss" : "Dismiss"} onCancel={handleCancel} onConfirm={handleConfirm} visible={dialogMessage.title.length > 0} />
            <Button size="large" sx={{
                align: "center", padding: 0, height: { height }, width: { width }, backgroundColor: btnColor
            }} variant="contained" onClick={() => { startSession(scheduleCard) }} startIcon={<VideoCallIcon />}>

                Start {dateStr.time}
                <br />
                {showDate ? dateStr.day + ', ' + dateStr.month + ' ' + dateStr.date : ""}
            </Button>
        </Fragment >
    );
};