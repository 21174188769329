import React from "react";

const MainAbout2 = () => {
    return (
        <section id="about-2" className="wide-60 about-section division ">
            <div className="container">
                <div className="row d-flex align-items-center">
                    {/* ABOUT IMAGE */}
                    <div className="col-md-5 col-lg-6">
                        <div className="img-block pc-25 mb-40">
                            <img
                                className="img-fluid"
                                src="images/image-01.png"
                                alt="about-image"
                            />
                        </div>
                    </div>
                    {/* ABOUT TEXT */}
                    <div className="col-md-7 col-lg-6">
                        <div className="txt-block pc-25 mb-40">
                            {/* Title */}
                            <h2 className="h3-sm">
                                What is Timedora?
                            </h2>
                            {/* Text */}
                            <br />
                            <p className="p-md">
                                Timedora is a service that allows service provider and experts to easily set up a marketing page, schedule and accept payment online. We connect experts, consultants and teachers to knowledge seekers and learners. It is also a platform that allows professionals to schedule time to be easily booked.
                            </p>
                            <br /><br />
                            <h2 className="h3-sm">
                                For Experts
                            </h2>
                            <p className="p-md">
                                If you are a teacher, a coach, a consultant, a CFO, a technologist or any professionals with deep expertise, you can offer your knowledge and get paid. Someone out there can benefit from your time and expertise.
                            </p>
                            <br /><br />
                            <h2 className="h3-sm">
                                For knowledge seekers
                            </h2>
                            <p className="p-md">
                                Need an advice, a question answered or help in learning? Get connected to an expert easily by booking a time with them. In our daily and professional lives, we have questions that sometimes we wish we can find good advice from experts. Whether it is a math question, a tax consultation, a legal advice, someone out there can help. We help you connect easily with experts.
                            </p>
                        </div>
                    </div>
                    {/* END ABOUT TEXT */}
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section >
    );
};

export default MainAbout2;
