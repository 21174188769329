import { Track, VideoTrack, Participant } from "livekit-client";
import React, { ReactElement, useContext } from "react";
import { ConferenceProps } from "./ConferenceView"
import { UserView } from "./UserView";
import { ScreenShareView } from "./ScreenShareView";
import "./desktop.css";
import { DisplayContext } from "./DisplayContext";
import { VideoSource } from "./ConferenceTypes";

export const SpeakerView = ({
    roomState,
    joinLeaveClicked,
    onLeave,
    chatClicked,
    windowWidth,
    windowHeight
}: ConferenceProps) => {
    const { isConnecting, error, participants, room } = roomState;
    const context = useContext(DisplayContext)

    if (error) {
        return <div>error {error.message}</div>;
    }

    if (isConnecting) {
        return <div>connecting</div>;
    }
    if (!room) {
        return <div>room closed</div>;
    }

    if (participants.length === 0) {
        return <div>no one is in the room</div>;
    }

    // find first participant with screen shared
    let mainTrack: VideoTrack | undefined;
    let participant: Participant | undefined;
    let screenMainView: boolean = false;
    participants.forEach((p) => {
        if (participant) {
            return
        }
        if (p.identity === context.displayOptions.selectedParticipant) {
            // okay there is a specific selected participant
            participant = p;
        }
    })

    if (!participant) {
        participant = participants[0];
    }

    const track = participant.getTrack(Track.Source.ScreenShare)
    if (track?.isSubscribed && track.videoTrack) {
        mainTrack = track?.videoTrack;
        screenMainView = true;
    }

    const otherParticipants = participants;
    let mainView: ReactElement;
    if (screenMainView && mainTrack) {
        mainView = (
            <ScreenShareView track={mainTrack} height="100%" width="100%" shareOnTop={false} />
        );
    } else {
        mainView = (
            <UserView
                key={participant.identity}
                source={VideoSource.Auto}
                participant={participant}
                width="100%"
                height="100%"
                aspectWidth={4}
                aspectHeight={3}
                orientation="landscape"
                showOverlay={true}
                showSpeaking={participant.isSpeaking}
            />
        );
    }

    return (
        // global container
        <div className="conf-desktop-stage">
            <div className="conf-desktop-stage-center">{mainView}</div>
            <div className="conf-desktop-sidebar">
                {otherParticipants.map((participant) => {
                    return (
                        <UserView
                            key={participant.identity}
                            source={context.displayOptions.selectedVideoSource}
                            participant={participant}
                            width="100%"
                            height="100%"
                            aspectWidth={16}
                            aspectHeight={9}
                            showOverlay={true}
                            showSpeaking={participant.isSpeaking}
                        />
                    );
                })}
            </div>
        </div>
    );
};