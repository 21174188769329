import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faMicrophoneSlash, faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useState } from "react";
import { Popover } from "react-tiny-popover";
import "./conference.css";
import CallEndIcon from '@mui/icons-material/CallEnd';
import Tooltip from "@mui/material/Tooltip";

interface ButtonProps<T> {
    label: string;
    tooltip?: string;
    disabled?: boolean;
    onClick?: () => void;
    icon?: IconDefinition;
    className?: string;
    popoverContainerClassName?: string;
    popoverTriggerBtnClassName?: string;
    popoverTriggerBtnSeparatorClassName?: string;
    menuItems?: MenuItem<T>[];
    onMenuItemClick?: (item: MenuItem<T>) => void;
}

export interface MenuItem<T> {
    label: string;
    obj: T;
}

export function ControlButton<T>({
    label,
    tooltip,
    disabled,
    onClick,
    icon,
    className,
    menuItems,
    popoverContainerClassName,
    popoverTriggerBtnClassName,
    popoverTriggerBtnSeparatorClassName,
    onMenuItemClick,
}: ButtonProps<T>) {
    const [menuVisible, setMenuVisible] = useState(false);

    let classes = "conf-button";
    if (!icon || icon === null) {
        classes = "conf-button-textonly"
    }
    if (className) {
        classes += ` ${className}`;
    }

    // Some icon we want to color it red like when it is mute or video if off
    if (icon === faMicrophoneSlash || icon === faVideoSlash) {
        classes += ` ${"conf-red-button"}`;
    }
    const handleMenuClick = (item: MenuItem<T>) => {
        setMenuVisible(false);
        if (onMenuItemClick) {
            onMenuItemClick(item);
        }
    };
    let confclasses = `${"conf-button"} ${popoverTriggerBtnClassName}  ${"conf-dropdown"}`;
    if (icon === faMicrophoneSlash || icon === faVideoSlash) {
        confclasses += ` ${"conf-red-button"}`;
    }

    let menuTrigger: ReactElement | undefined;
    let menu: ReactElement = <div />;
    if (menuItems && menuItems.length > 0) {
        classes += ` ${"conf-has-dropdown"}`;
        menuTrigger = (
            <button
                disabled={disabled}
                className={confclasses}
                onClick={() => setMenuVisible(!menuVisible)}
            >
                <div
                    className={`${"conf-separator"} ${popoverTriggerBtnSeparatorClassName}`}
                />
                <FontAwesomeIcon height={32} icon={faChevronDown} />
            </button>
        );

        menu = (
            <div className={`${"conf-popover-menu"} ${popoverContainerClassName}`}>
                <ul className={"conf-list"}>
                    {menuItems?.map((item, i) => {
                        return (
                            <li key={i} onClick={() => handleMenuClick(item)}>
                                {item.label}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    return (

        <Popover isOpen={menuVisible} positions={["top"]} content={menu}>
            {label === 'Leave' ?
                < div className={"conf-button-wrapper"}>
                    <Tooltip title={tooltip ? tooltip : ""}>
                        <button
                            disabled={disabled}
                            className="conf-button-textonly conf-red-button"
                            onClick={() => {
                                setMenuVisible(false);
                                if (onClick) onClick();
                            }}
                        >
                            <CallEndIcon />
                        </button>
                    </Tooltip>
                    {menuTrigger}
                </div>
                :
                <div className={"conf-button-wrapper"}>
                    <Tooltip title={tooltip ? tooltip : ""}>
                        <button
                            disabled={disabled}
                            className={classes}
                            onClick={() => {
                                setMenuVisible(false);
                                if (onClick) onClick();
                            }}
                        >
                            {icon && (
                                <div>
                                    <FontAwesomeIcon className={"conf-icon"} height={32} icon={icon} /></div>
                            )}
                            {label}
                        </button>
                    </Tooltip>
                    {menuTrigger}
                </div>
            }
        </Popover >
    );
};