import { ChangeEventHandler, KeyboardEventHandler, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAPI, usePush } from "../../utils/useAPI";
import * as apiproto from '../../apiproto';
import "./ChatBox.css";
import { useUser } from "../../utils/useUser";
import { DateTime } from "luxon";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from "@mui/lab/LoadingButton";
import SyncIcon from '@mui/icons-material/Sync';

type ClassroomParams = {
    recipientId?: string | null; // Could be a userID, a class ID or a time ID
}

type Message = {
    timeStamp: number;
    senderUserId: string;
    senderName: string;
    message: string;
}

const nameCache = new Map<string, string>();

export const ChatBox: React.FC<ClassroomParams> = ({ recipientId = null }) => {
    const refChatBoxBottom = useRef<HTMLDivElement>(null);
    const push = usePush();
    const api = useAPI();
    const [messages] = useState<Message[]>([]);
    const [, setTrigger] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [newMessageText, setNewMessageText] = useState<string>('');
    const [currentStartTime, setCurrentStartTime] = useState<number>((new Date()).getTime());
    const [oldTopMessage, setOldTopMessage] = useState<Message | null>(null);
    const [elementScrollOffset, setElementScrollOffset] = useState<number>(0);
    const oldTopElement = useRef<HTMLDivElement>(null);
    const currentTopElement = useRef<HTMLDivElement | null>(null);
    const user = useUser();
    const [isLoading, setIsLoading] = useState(false);

    const getOlderMessages = async (preserveScrollPosition: boolean) => {


        if (!recipientId) {
            return;
        }
        if (!hasMore) {
            return;
        }

        setIsLoading(true);
        let scrollOffset: number = 0;
        if (currentTopElement.current) {
            scrollOffset = currentTopElement.current.scrollTop;
        }
        const oldMessageTop = messages[0];

        const getUserEventsResponse = await api.getUserEvents(currentStartTime, true, recipientId!, { chatMessageEvent: true }, 5);
        let oldestStartTime: number = -1;
        if (getUserEventsResponse.events && getUserEventsResponse.events.length > 0) {
            for (const event of getUserEventsResponse.events) {
                if (event.chatMessageEvent && event.chatMessageEvent.userId && event.timeStamp && event.chatMessageEvent.message) {
                    // relevant event
                    let senderName: string | undefined = '';
                    if (event.chatMessageEvent.userId !== user.userId) {
                        // other, we need to lookup the name if we don't have it
                        senderName = nameCache.get(event.chatMessageEvent.userId);
                        if (!senderName) {
                            // okay, we need to lookup
                            const senderUserInfo = await api.getUser({}, { userId: event.chatMessageEvent.userId })
                            if (senderUserInfo.user?.firstName) {
                                senderName = senderUserInfo.user?.firstName;
                            } else {
                                senderName = 'Unknown';
                            }
                        }
                    }
                    const timeStamp = parseInt(event.timeStamp.toString());
                    messages.unshift({
                        senderUserId: event.chatMessageEvent.userId,
                        senderName: senderName,
                        timeStamp: timeStamp,
                        message: event.chatMessageEvent.message
                    });
                    oldestStartTime = timeStamp;
                }
            }
            // done getting messages
            if (oldestStartTime !== -1) {
                setCurrentStartTime(oldestStartTime - 1);
                setTrigger(Math.random());
                setOldTopMessage(oldMessageTop);
                setElementScrollOffset(scrollOffset);
            } else {
                setHasMore(false);
            }
        }
        else {
            setHasMore(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (push && recipientId) {
            // trigger one get older messages
            getOlderMessages(false);
            push.connect();
            return () => {
                push.disconnect();
            }
        }

    }, []);

    useEffect(() => {
        if (push) {
            const eventHandler = async (event: apiproto.api.IEvent) => {
                // got new event message
                if (event.chatMessageEvent && event.timeStamp && event.chatMessageEvent.message && event.chatMessageEvent.userId) {
                    let senderName: string | undefined = '';
                    if (event.chatMessageEvent.userId !== user.userId) {
                        // other, we need to lookup the name if we don't have it
                        senderName = nameCache.get(event.chatMessageEvent.userId);
                        if (!senderName) {
                            // okay, we need to lookup
                            const senderUserInfo = await api.getUser({}, { userId: event.chatMessageEvent.userId })
                            if (senderUserInfo.user?.firstName) {
                                senderName = senderUserInfo.user?.firstName;
                            } else {
                                senderName = 'Unknown';
                            }
                        }
                    }
                    const newMessage: Message = {
                        senderUserId: event.chatMessageEvent.userId,
                        senderName: senderName,
                        timeStamp: parseInt(event.timeStamp.toString()),
                        message: event.chatMessageEvent.message
                    }
                    messages.push(newMessage);
                    setTrigger(Math.random());

                    if (refChatBoxBottom.current) {
                        refChatBoxBottom.current.scrollIntoView();
                    }
                }
            };
            push.on('event', eventHandler);
            return () => { push.off('event', eventHandler) };
        }
    }, []);

    useLayoutEffect(() => {
        if (oldTopElement.current) {
            // we have a top element, we need to scroll to that element
            oldTopElement.current.scrollIntoView({ block: 'start' });
            // TODO - not sure if below line is necessary give above line? 
            oldTopElement.current.scrollTo(0, elementScrollOffset);
            // now we need to reset that
            setOldTopMessage(null);
        }
    });

    let currentDateString: string = '';
    const messageList: JSX.Element[] = [];
    let firstElement: boolean = true;
    for (const msg of messages) {
        let msgContent: JSX.Element;
        // time difference too much, we will need to show time bar
        let dateString: string;
        const dateTime = DateTime.fromMillis(msg.timeStamp);
        const endOfToday = DateTime.now().endOf("day");
        const daysDiff = dateTime.endOf("day").diff(endOfToday, "days");

        if (daysDiff.days === 0) {
            dateString = 'Today ' + dateTime.toFormat('t');
        } else if (daysDiff.days === -1) {
            dateString = 'Yesterday' + dateTime.toFormat('t');
        } else if (daysDiff.days >= -6 && daysDiff.days <= -1) {
            dateString = dateTime.toFormat('cccc t');
        } else {
            dateString = dateTime.toFormat('f');
        }
        if (msg.senderUserId === user.userId) {
            msgContent = <div className="chat-content-self-message"><div ref={firstElement ? currentTopElement : (oldTopMessage === msg && (dateString === currentDateString) ? oldTopElement : null)} className="chat-bubble-self tri-right round btm-right-in" key={'m' + msg.timeStamp} >{msg.message}</div></div>;
        } else {
            msgContent = <div ref={firstElement ? currentTopElement : (oldTopMessage === msg && (dateString === currentDateString) ? oldTopElement : null)} className="chat-content-other" key={'u' + msg.timeStamp}><div className="chat-content-other-name">{msg.senderName}</div><div className="chat-bubble-other tri-right round btm-left-in">{msg.message}</div></div>;
        }
        firstElement = false;
        if (dateString !== currentDateString) {
            messageList.push(<div key={'timebar-' + msg.timeStamp}><div className="chat-content-timebar">{dateString}</div></div>);
        }
        messageList.push(msgContent);
        currentDateString = dateString
    };

    const keyPressed: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.code === "Enter") {
            sendMessage();
        }
    }

    const textChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        setNewMessageText(e.target.value);
    }
    const sendMessage = async () => {
        if (recipientId) {
            if (recipientId.startsWith("r_")) {
                await api.sendMessageToRoom(recipientId, newMessageText);
            } else if (recipientId.startsWith("t_")) {
                await api.sendMessageToTime(recipientId, newMessageText);
            }
            else if (recipientId.startsWith("u_")) {
                await api.sendMessageToUser(recipientId, newMessageText);
            }
            setNewMessageText('');
        }
    }

    const handleScroll = (e: React.UIEvent<HTMLElement>) => {
        if (e.currentTarget.scrollTop === 0) {
            // load more if there is
            if (messages.length > 0) {
                getOlderMessages(true);
            }
        }
    }

    return (
        <div className="chat">
            {hasMore ?
                <div>
                    <LoadingButton onClick={() => { getOlderMessages(true) }} style={{ width: '100%', textAlign: 'center', marginTop: '10px', fontSize: '12px' }} size="large" variant="text" loading={isLoading} loadingPosition="start" type="submit" startIcon={<SyncIcon />}>
                        Tap to load older messages...
                    </LoadingButton>
                </div>
                : ""
            }
            <div className="chat-content" onScroll={handleScroll}>
                {messageList}
                <div className="chat-content-message-bottom" ref={refChatBoxBottom}></div>
            </div>
            <div className="chat-new-message">
                <TextField
                    variant="filled"
                    hiddenLabel
                    maxRows={5}
                    onKeyPress={keyPressed}
                    sx={{
                        "& .MuiFilledInput-root": {
                            backgroundColor: "#fff",
                            borderRadius: "12px",
                            border: "2px solid #ccc",
                            padding: "10px",
                        },

                        "& .MuiFilledInput-root:hover": {
                            backgroundColor: "#fff",
                            borderRadius: "12px",
                            borderColor: "#ddd",
                            // Reset on touch devices, it doesn't add specificity
                            "@media (hover: none)": {
                                backgroundColor: "#fff"
                            }
                        },
                        "& .MuiFilledInput-root.Mui-focused": {
                            backgroundColor: "#fff"
                        },
                        marginBottom: '10px',
                        color: 'black',
                        width: '100%',
                    }}
                    id="outlined-textarea"
                    placeholder="Type text message here..."
                    value={newMessageText}
                    onChange={textChanged}
                    multiline
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <IconButton size="small" sx={{

                                '& svg': {
                                    fontSize: 18
                                },
                                "&:hover": {
                                    color: '#ffffff',
                                    backgroundColor: '#2b43ce',
                                }, color: '#dddddd', backgroundColor: '#2c5bd3', borderRadius: 4, display: 'flex', justifyContent: 'end', alignItems: 'self-end'
                            }} onClick={sendMessage}>
                                <SendIcon />
                            </IconButton>
                        )
                    }}
                />
            </div>
        </div >)

}