import { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { api as apiproto } from "../../apiproto";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LinkedinShareButton, LinkedinIcon, FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon, RedditShareButton, RedditIcon } from 'react-share';
import { Col, Row } from "react-bootstrap";
import { checkIfTopicProfile } from "../../utils/eduutils";
type ChildProps = {
    topic: apiproto.ITopic | null | undefined,
    owner: apiproto.IUser | null,
    visible: boolean,
    callback?: (value: any) => void | null,
}


const TopicSocial: React.FC<ChildProps> = ({ topic = null, owner = null, visible = false, callback = () => { } }) => {

    const [linkCopied, setLinkCopied] = useState(false);
    const [isProfilePage, setIsProfilePage] = useState(false);
    const [open, setOpen] = useState(false);
    const [vanityURL, setVanityURL] = useState(topic?.vanityName ? topic.vanityName : "");

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (linkCopied) {
            timer = setTimeout(() => {
                setLinkCopied(false);
            }, 2000);
        }
        return () => {
            if (!timer) {
                clearTimeout(timer);
            }
        };
    }, [linkCopied]);

    const onCancel = () => {
        // Here we do nothing because we don't want user to accidentally tap outside dialog box and close.
    }

    const onClickCloseButton = () => {
        callback("none");
    }

    const generateVanityURL = () => {
        if (topic) {
            setVanityURL('https://' + window.location.hostname + (window.location.port ? ':' + window.location.port : "") + '/t/' + ((topic.vanityName && topic.vanityName.length > 0) ? topic.vanityName : topic?.tinyCode));
        }
    }

    useEffect(() => {
        setOpen(visible);
        generateVanityURL();
        if (checkIfTopicProfile(topic?.topicId)) {
            setIsProfilePage(true);
        }
    }, [visible, topic?.topicId, owner?.userId]);


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={onCancel}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickCloseButton}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <br />
                <h2>{isProfilePage ? "Share your personal session times" : "Share this topic with friends and family"}</h2>
                {isProfilePage ? "" : <h4>{topic ? topic?.name : ""}</h4>}
                {vanityURL}<Button variant="text"
                    startIcon={<ContentCopyIcon />}
                    size="large"
                    sx={{ marginLeft: '20px' }}
                    disabled={linkCopied ? true : false}
                    color="primary" aria-label="upload picture" component="span" onClick={() => {
                        navigator.clipboard.writeText(vanityURL);
                        setLinkCopied(true);
                    }}>
                    {linkCopied ? "Link Copied" : "Copy Link"}
                </Button>
                <hr />
                <Row>
                    <Col>
                        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '12px' }}>
                            <LinkedinShareButton
                                url={vanityURL}
                                title={topic ? topic.name! : ""}>
                                <LinkedinIcon size={48} round />
                                <span className="btn_share">LinkedIn</span>
                            </LinkedinShareButton>
                        </Box>
                    </Col>
                    <Col>
                        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '12px' }}>
                            <FacebookShareButton
                                url={vanityURL}
                                title={topic ? topic.name! : ""}>
                                <FacebookIcon size={48} round />
                                <span className="btn_share">Facebook</span>
                            </FacebookShareButton>
                        </Box>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '12px' }}>
                            <TwitterShareButton
                                url={vanityURL}
                                title={topic ? topic.name! : ""}>
                                <TwitterIcon size={48} round />
                                <span className="btn_share">Twitter</span>
                            </TwitterShareButton>
                        </Box>
                    </Col>
                    <Col>
                        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '12px' }}>
                            <WhatsappShareButton
                                url={vanityURL}
                                title={topic ? topic.name! : ""}>
                                <WhatsappIcon size={48} round />
                                <span className="btn_share">WhatsApp</span>
                            </WhatsappShareButton>
                        </Box>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '12px' }}>
                            <EmailShareButton
                                subject={topic ? topic.name! : ""}
                                body={'You may be interested in this topic '}
                                url={vanityURL}>
                                <EmailIcon size={48} round />
                                <span className="btn_share">Email</span>
                            </EmailShareButton>
                        </Box>
                    </Col>
                    <Col>
                        <Box sx={{ border: '1px solid lightgray', borderRadius: '10px', padding: '12px' }}>
                            <RedditShareButton
                                url={vanityURL}
                                title={topic ? topic.name! : ""}>
                                <RedditIcon size={48} round />
                                <span className="btn_share">Reddit</span>
                            </RedditShareButton>
                        </Box>
                    </Col>
                </Row>
            </DialogContent >
        </Dialog >)
}

export default TopicSocial;