import { useEffect } from "react";

const PageTitle = ({ pageTitle, motherTitle, url }: { pageTitle?: string | undefined, motherTitle?: string | undefined, url?: string | undefined }) => {

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
  }, [pageTitle]);
  return (
    <div id="breadcrumb" className="division">
      {/*
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                {motherTitle && (
                  <li className="breadcrumb-item">
                    <Link to={`/${url}`}>{motherTitle}</Link>
                  </li>
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  {pageTitle}
                </li>
              </ol>
            </nav>
          </div>
        </div>{" "}
                </div>{" "} */}
      {/* End container */}
    </div>
  );
};

export default PageTitle;
