import React, { useState } from "react";
import PageTitle from "../../layout/PageTitle";

const FaqIntegration1 = () => {
  const [active, setActive] = useState(1);
  const activeLi = (value: number) => (active === value ? "current" : "");
  return (
    <section id="faqs-1" className="bg-fixed wide-70 faqs-section division">
      <PageTitle pageTitle="Integration with Zoom" />
      <div className="container">
        <div className="row">
          {/* TABS NAVIGATION */}
          <div className="col-lg-5">
            <div className="tabs-nav clearfix">
              <ul className="tabs-1">
                {/* TAB-1 LINK */}
                <li
                  className={`tab-link ${activeLi(1)}`}
                  onClick={() => setActive(1)}
                  data-tab="tab-1"
                >
                  <img
                    className="img-55"
                    src="images/apps/app_zoom_icon.png"
                    alt="tab-icon"
                  />
                  <div className="tab-link-txt">
                    <h5 className="h5-xs">Zoom Integration</h5>
                    <p>
                      Learn how to integrate with Zoom
                    </p>
                  </div>
                </li>
                {/* TAB-4 LINK */}
                {/*
                <li
                  className={`tab-link ${activeLi(2)}`}
                  onClick={() => setActive(2)}
                  data-tab="tab-2"
                >
                  <img
                    className="img-55"
                    src="images/apps/app_google_meet_icon.png"
                    alt="tab-icon-icon"
                  />
                  <div className="tab-link-txt">
                    <h5 className="h5-xs">Google Meet</h5>
                    <p>
                      Learn how to integrate with Google Meet
                    </p>
                  </div>
  </li>*/}
              </ul>
            </div>
          </div>
          {/* END TABS NAVIGATION */}
          {/* TABS CONTENT */}
          <div className="col-lg-7">
            <div className="tabs-content">
              {/* TAB-1 CONTENT */}
              <div id="tab-1" className={`tab-content ${activeLi(1)}`}>
                {/* CATEGORY TITLE */}
                <div className="category-title">
                  {/* Title */}
                  <h4 className="h4-md">Meetings with Zoom Video Conference</h4>
                  {/* Text */}
                  <p className="p-md">

                  </p>
                </div>
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-md">
                    Zoom
                  </h5>
                  <p>
                    Zoom is a cloud-based video conferencing platform that allows users to connect with others virtually from anywhere in the world. It is commonly used for online meetings, webinars, and video chats with coworkers, friends, and family. Some of the key features of Zoom include the ability to share screens, record meetings, and use virtual backgrounds to customize your video appearance. Zoom is available on a variety of devices, including desktop and mobile devices, and offers both free and paid plans. It has become particularly popular in recent years due to the increased need for remote work and communication during the COVID-19 pandemic.
                  </p>
                </div>
                <div className="question">
                  <h5 className="h5-md">
                    Zoom Features
                  </h5>
                  <p>
                    Some of the key features of Zoom include the ability to share screens, record meetings, and use virtual backgrounds to customize your video appearance. Additionally, Zoom offers the following features:

                    HD video and audio: Zoom offers high-quality video and audio, allowing you to see and hear other participants clearly.
                    Meeting scheduling and calendar integrations: You can schedule meetings and invite participants directly from the Zoom platform, and you can also integrate Zoom with your calendar for easy scheduling.
                    Real-time collaboration: Zoom allows multiple participants to share their screens and collaborate in real-time on documents, presentations, and other files.
                    Breakout rooms: You can use breakout rooms to divide participants into smaller groups for more focused discussions.
                    Polling and Q&A: You can use Zoom's polling and Q&A features to engage participants and gather feedback.
                    Security: Zoom takes security seriously and offers robust security features to keep your meetings and data safe.
                    These are just some of the many features offered by Zoom. For a complete list of features and to learn more about how Zoom can help you stay connected and productive, you can visit their website.
                  </p>
                </div>

                {/* QUESTION #1 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-md">
                    Zoom + Timedora
                  </h5>
                  {/* Answer */}
                  <p>
                    Timedora offers users a choice of it's own video conferencing system or with other 3rd party popular providers. By connecting Zoom with Timedora, you can generate Zoom meeting links and directly host your meetings on Zoom.
                  </p>
                </div>
                {/* END QUESTION #1 */}
                {/* QUESTION #2 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How to connect to Zoom on Timedora?
                  </h5>
                  {/* Answer */}
                  <p>
                    In profile and under "App Integrations", you will see many apps ready for integration. Select the Zoom panel and tap "Connect". If you are not already logged in to Zoom, you will be asked to login on Zoom's website to grant appropriate permissions for Timedora to connect to Zoom. Once you have successfully connected, Timedora will be able to automatically create Zoom links for you to use.
                  </p>
                </div>
                {/* END QUESTION #2 */}
                {/* QUESTION #3 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How to assign Zoom to my room?
                  </h5>
                  {/* Text */}
                  <p>
                    When you create a topic, you can choose the video conferencing provider in Location for your meetings. If you are successfully integrated with Zoom, you should be able to choose it. If not, Timedora will prompt you to integrate.
                  </p>
                  {/* Answer */}
                </div>
                {/* END QUESTION #3 */}
                {/* QUESTION #4 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How do I disconnect from Zoom?
                  </h5>
                  {/* Answer */}
                  <p>
                    Simply go to Profile -&lt; App Integration and tap on Disconnect. You will then be immediately disconnected from Zoom. Bear in mind that if you have your meetings already scheduled for Zoom, you may need to then change your meeting location by editing your topics and setting a new meeting location for your schedules. Otherwise, you will need to re-integrate with Zoom to make sure the meetings with your users do not get disrupted.
                  </p>
                </div>
                {/* END QUESTION #4 */}
                {/* END TAB-1 CONTENT */}

              </div>
              {/* END TAB-1 CONTENT */}
              {/* TAB-2 CONTENT */}
              <div id="tab-2" className={`tab-content ${activeLi(2)}`}>
                {/* CATEGORY TITLE */}
                {/* QUESTION #1 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-md">
                    Meetings with Google Meet
                  </h5>
                  <p>
                    Google Meet is a video conferencing platform offered by Google. Users can connect with others virtually from anywhere in the world. Some of the key features of Google Meet include the ability to share screens, record meetings, and use virtual backgrounds. Additionally, Google Meet offers the following features:<br />
                    <br />
                    <b>Integration with Google apps and services:</b> Google Meet is integrated with other Google apps and services, such as Google Calendar and Gmail, making it easy to schedule and join meetings.
                    <br /><br />
                    <b>Security:</b> Google Meet uses the same security protocols as Google's other products, so you can be confident that your meetings and data are secure.
                    <br /><br />
                    <b>Real-time collaboration:</b> Multiple participants can share their screens and collaborate in real-time on documents, presentations, and other files.
                    <br /><br />
                    <b>High-quality audio and video:</b> Google Meet offers high-definition audio and video, allowing you to see and hear other participants clearly.
                    <br /><br />
                    <b>Meeting scheduling and calendar integrations:</b> You can schedule meetings and invite participants directly from the Google Meet platform, and you can also integrate Google Meet with your calendar for easy scheduling.
                    These are just some of the features offered by Google Meet. For a complete list of features and to learn more about how Google Meet can help you stay connected and productive, you can visit their website.
                    <br />
                  </p>
                </div>

                <div className="question">
                  <h5 className="h5-md">
                    Google Meet + Timedora
                  </h5>
                  {/* Answer */}
                  <p>
                    Timedora offers users a choice of it's own video conferencing system or with other 3rd party popular providers. By connecting Google Meet with Timedora, you can generate Google Meet links and directly host your meetings on Google Meet.
                  </p>
                </div>
                {/* END QUESTION #1 */}
                {/* QUESTION #2 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How to connect to Google Meet?
                  </h5>
                  {/* Answer */}
                  <p>
                    In profile and under "App Integrations", you will see many apps ready for integration. Select the Google Meet panel and tap "Connect". If you are not already logged in to Google Meet, you will be asked to login on Google Meet's website to grant appropriate permissions for Timedora to connect to Google Meet. Once you have successfully connected, Timedora will be able to automatically create Google Meet links for you to use.
                  </p>
                </div>
                {/* END QUESTION #2 */}
                {/* QUESTION #3 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How to assign Google Meet to my room?
                  </h5>
                  {/* Text */}
                  <p>
                    When you create a topic, you can choose the video conferencing provider in Location for your meetings. If you are successfully integrated with Google Meet, you should be able to choose it. If not, Timedora will prompt you to integrate.
                  </p>
                  {/* Answer */}
                </div>
                {/* END QUESTION #3 */}
                {/* QUESTION #4 */}
                <div className="question">
                  {/* Question */}
                  <h5 className="h5-sm">
                    How do I disconnect from Google Meet?
                  </h5>
                  {/* Answer */}
                  <p>
                    Simply go to Profile -&lt; App Integration and tap on Disconnect. You will then be immediately disconnected from Google Meet. Bear in mind that if you have your meetings already scheduled for Google Meet, you may then need to change your meeting location by editing your topics and setting a new meeting location for your schedules. Otherwise, you will need to re-integrate with Google Meet to make sure the meetings with your users do not get disrupted.
                  </p>
                </div>
                {/* END QUESTION #4 */}
                {/* END TAB-2 CONTENT */}
                {/* TAB-3 CONTENT */}
                <div id="tab-3" className={`tab-content ${activeLi(3)}`}>
                  {/* CATEGORY TITLE */}

                  <div className="category-title">
                    {/* Title */}
                    <h4 className="h4-md">Video Conferencing</h4>
                    {/* Text */}
                    <p className="p-md">
                      Learn more about our unique and powerful video conferencing solution designed for teachers and the education industry.
                    </p>
                  </div>
                  {/* QUESTION #5 */}
                  <div className="question">
                    {/* Question */}
                    <h5 className="h5-sm">What is Smart Attendance?</h5>
                    {/* Answer */}
                    <p>
                      When there are many video faces in a conference, it is hard to tell who is not present. As a teacher, when you are expecting 40 students and only see 35 students, you want to know who is absent so you can quickly text absentees reminders to attend their course on time.
                    </p>
                    <p>
                      After observing numerous online sessions, what we found is that in certain classes, teachers can spend up to 10 minutes doing a roll call and getting students one by one to say their name out loud and perhaps manually checking their names against a list. In that process, some teachers also help figure out if a student microphone or speaker is working properly.
                    </p>
                    <p>
                      We think there is a better way to do it by automating the process. When you as a teacher presses the roll call button, you will be asked to say a greeting to everyone. We will be able to test your speaker and detect if your microphone is working. Then, press "Start Roll Call" and your greeting will be transmitted to everyone else online and the students will have the option to acknowledge if they heard your greeting and students will also respond by saying things in the microphone. Not only do we know who is present, but also be able to diagnose the various microphone or speaker issues to provide an attendance list report. In addition, our Smart Attendance can notify absentees on record that they are missing class. Students can get feedback on their attendance rate, percentage of time when they are on time or late for classes.
                    </p>
                  </div>
                  {/* END QUESTION #5 */}
                  {/* QUESTION #6 */}
                  <div className="question">
                    {/* Question */}
                    <h5 className="h5-sm">What is Smart Attention?</h5>
                    {/* Answer */}
                    <p>
                      Being in front of a computer can be very distracting. There is always an urge to swtich to another window or another tab to view other content instead of focusing in the class. Our Smart Attention algorithm can detect when the class is not in focus or when a face is not in video view. When the attention is not focused on the online session, our system can play a gentle audio reminder to remind the attendee to focus their attention back to the classroom. Our system can also generate reports on percentage of attention focused in the class. Students can also get feedback on their attention span.
                    </p>
                    <p>
                      Smart Attention can be configured to be on and off depending on the teacher and the classes. On screen presence can also be turned off since the camera may be pointing at the homework or desk in certain instruction type.
                    </p>

                  </div>
                  {/* END QUESTION #6 */}
                  {/* QUESTION #7 */}
                  <div className="question">
                    {/* Question */}
                    <h5 className="h5-sm">
                      What is Smart Share?
                    </h5>
                    {/* Answer */}
                    <p>
                      Smart Share is a new way of sharing your screen. Instead of having one presenter share to everyone else in a one to many screen sharing, our technology enables many to many sharing. Why many to many screen sharing?
                    </p>
                    <p>
                      In a typical classroom, the teacher may move from student to student to observe their progress working on a homework or study material. Smart Share allows EVERY student to share their screen or window where they are working on an online course material. The teacher is able to choose to view which screens they want to observe akin to the teacher looking over the shoulders of a student to monitor progress and help if necessary.
                    </p>
                    <p>
                      Many to many sharing can be configured by the teacher to enable only teacher's view where only the teacher can view all the presentation. It can also be configured so that everyone can see everyone screen to enable a new form of collaboration. Frankly, we don't know what type of online collaboration it can enable, but we are excited to hear from you about your use case.
                    </p>
                  </div>
                  {/* END QUESTION #7 */}
                  {/* QUESTION #8 */}
                  <div className="question">
                    {/* Question */}
                    <h5 className="h5-sm">What is Smart Pointer?</h5>
                    {/* Answer */}
                    <p>
                      When a projector is used in classroom settings, teachers use laser pointers to draw attention to specific areas on the projected screen. Smart Pointer works like that. Smart Pointer allows the presenter to control a pointer to draw attendees attention to a specifc area of a presentation online. Frequently, we observe how presenters have to point attendees' attention to specific area by using words like "top left corner" or "bottom portion of the slides", etc. With Smart Pointer, you can remotely control a pointer that shows up on everyone's screen to highlight various areas.
                    </p>
                  </div>
                  {/* END QUESTION #8 */}
                  {/* QUESTION #8 */}
                  <div className="question">
                    {/* Question */}
                    <h5 className="h5-sm">What is Smart Pointer?</h5>
                    {/* Answer */}
                    <p>
                      When a projector is used in classroom settings, teachers use laser pointers to draw attention to specific areas on the projected screen. Smart Pointer works like that. Smart Pointer allows the presenter to control a pointer to draw attendees attention to a specifc area of a presentation online. Frequently, we observe how presenters have to point attendees' attention to specific area by using words like "top left corner" or "bottom portion of the slides", etc. With Smart Pointer, you can remotely control a pointer that shows up on everyone's screen to highlight various areas.
                    </p>
                  </div>
                  {/* END QUESTION #8 */}

                </div>


              </div>

              {/* END TAB-3 CONTENT */}
              {/* TAB-4 CONTENT */}
              <div id="tab-4" className={`tab-content ${activeLi(4)}`}>
                {/* CATEGORY TITLE */}

                {/* END QUESTION #13 */}
              </div>
              {/* END TAB-4 CONTENT */}
            </div>
          </div>
          {/* END TABS CONTENT */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </section >
  );
};

export default FaqIntegration1;
