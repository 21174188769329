import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { IScheduleCard } from "../../utils/eduutils";
import { Navigate } from "react-router-dom";
import DialogMessageBox from "../DialogMessageBox";
import { api as apiproto } from "../../apiproto";



type ChildProps = {
    scheduleCard: IScheduleCard
    reviewsByUser: apiproto.ITopicReview[] | null | undefined
    courseId: string | null | undefined
}

const ReviewWrite: React.FC<ChildProps> = ({ scheduleCard = null, reviewsByUser = null, courseId: topicId = null }) => {
    // Review
    const api = useAPI();
    const user = useUser();
    const labels: { [index: string]: string } = {
        0.5: 'Horrible',
        1: 'Bad',
        1.5: 'Very Poor',
        2: 'Poor',
        2.5: 'Ok',
        3: 'Average',
        3.5: 'Good',
        4: 'Very Good',
        4.5: 'Excellent',
        5: 'Perfect',
    };
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [reviewData, setReviewData] = useState({ ratingValue: 0, ratingHover: -1, reviewText: "", reviewOpen: false });
    const handleReviewClickOpen = () => {
        setReviewData({ ...reviewData, reviewOpen: true });
    };
    const handleReviewClose = () => {
        setReviewData({ ...reviewData, reviewOpen: false });
    };
    const now = Date.now();
    const [previousReview, setPreviousReview] = useState<apiproto.ITopicReview | null>(null);

    const getUserReview = (reviews: apiproto.ITopicReview[] | null) => {
        if (reviews) {
            const review = reviews.filter((m) => m.topicId === topicId)
            if (review && review.length > 0) {
                setPreviousReview(review[0]);
                setReviewData({ ...reviewData, reviewText: review[0].review!, ratingValue: review[0].rating! });
            }
        }
    }
    useEffect(() => {
        getUserReview(reviewsByUser);
    }, [reviewsByUser, topicId]);

    const handleReviewSubmit = () => {
        if (user.userId && user.authenticated) {
            try {
                (async () => {

                    if (scheduleCard?.enrolled) {
                        // Not enrolled, cannot write review
                        //return;
                    }
                    if (now < scheduleCard?.endtime!) {
                        // Cannot write review because not yet attended session
                    }
                    var reviewResult = null;
                    if ((scheduleCard?.timeId && scheduleCard.timeId.length > 0)
                        || (scheduleCard?.roomId && scheduleCard.roomId.length > 0)) {
                        try {

                            if (previousReview) {
                                if (previousReview.timeId) {
                                    reviewResult = await api.updateTimeReview(previousReview.timeId!, reviewData.ratingValue ? reviewData.ratingValue : 0, reviewData.reviewText);
                                }
                                else {
                                    reviewResult = await api.updateRoomReview(previousReview.roomId!, reviewData.ratingValue ? reviewData.ratingValue : 0, reviewData.reviewText);
                                }
                            }
                            else {
                                if (scheduleCard?.timeId && scheduleCard.timeId.length > 0) {
                                    reviewResult = await api.addTimeReview(scheduleCard.timeId, reviewData.ratingValue ? reviewData.ratingValue : 0, reviewData.reviewText);
                                }
                                else {
                                    reviewResult = await api.addRoomReview(scheduleCard.roomId, reviewData.ratingValue ? reviewData.ratingValue : 0, reviewData.reviewText);
                                }
                            }

                            handleReviewClose();
                            const title = previousReview ? "Feedback Updated" : "Feedback Submitted";
                            const message = previousReview ? 'Thank you! Your feedback has been updated.' : 'Thank you! Your feedback has been submitted.'
                            setDialogMessage({ title: title, message: message, meta: '', confirm: false });
                        } catch (err: any) {
                            handleReviewClose();
                            setDialogMessage({ title: 'Feedback error', message: 'An error occurred submitting your feedback.', meta: '', confirm: true });
                        }
                    }
                    if (reviewResult) {
                        handleReviewClose();
                    }

                })()
            }
            catch (err: any) {
                setDialogMessage({ title: 'Feedback error', message: 'An error occurred submitting your feedback.', meta: '', confirm: true });
            }
        }
    };

    const handleReviewTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        setReviewData({ ...reviewData, reviewText: event.target.value })
    };
    /******************************************************* 
         * 
         * Dialog box section for handling actions confirmation
         * 
         * *****************************************************/
    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true });
    }

    const handleDeleteCancel = () => {
        clearDialog();
    }
    if (!user.authenticated) {
        return (<Navigate to="/login" />);
    }

    return (

        <Fragment>
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Confirm"} onCancel={handleDeleteCancel} visible={dialogMessage.title.length > 0} />
            <Button size="small" sx={{ align: "center", height: 40, width: 200 }} variant="contained" onClick={handleReviewClickOpen} startIcon={<StarIcon />}>
                Write Review
            </Button>
            <Dialog open={reviewData.reviewOpen} onClose={handleReviewClose}>
                <DialogTitle>Provide us Feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please provide feedback. Rate and write about your experience
                    </DialogContentText>
                    <Box
                        sx={{
                            width: 800,
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: 1,
                            paddingBottom: 1

                        }}
                    >

                        <Rating
                            name="hoverfeedback"
                            size="large"
                            value={reviewData.ratingValue}
                            precision={1.0}
                            onChange={(event, newValue) => {
                                setReviewData({ ...reviewData, ratingValue: newValue ? newValue : 0 });
                            }}
                            onChangeActive={(event, newHover) => {
                                setReviewData({ ...reviewData, ratingHover: newHover });
                            }}
                            icon={<StarIcon fontSize="inherit" />}
                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                        />
                        {reviewData.ratingValue !== null && (
                            <Box sx={{ ml: 2 }}>{labels[reviewData.ratingHover !== -1 ? reviewData.ratingHover : reviewData.ratingValue]}</Box>
                        )}
                    </Box>
                    <textarea
                        key={"reviewform"}
                        minLength={6}
                        maxLength={4096}
                        rows={5}
                        onChange={handleReviewTextAreaChange}
                        name="reviewform"
                        id="reviewform"
                        className="form-control" placeholder="Write a review..."
                        value={reviewData.reviewText} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReviewClose}>Cancel</Button>
                    <Button onClick={handleReviewSubmit}>{previousReview ? "Update Review" : "Submit Review"}</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default ReviewWrite;
