import React, { Fragment, useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import LoginComponent from "./LoginComponent";


type ChildProps = {
    popopen: boolean,
    onClose: () => void;
    logintype: string
}

export const LoginDialog: React.FC<ChildProps> = ({ popopen = false, onClose, logintype = 'login' }) => {

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        onClose();
    };
    useEffect(() => {
        setOpen(popopen);
    }, [popopen]);

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className="auth-dialog">
                        <LoginComponent type={logintype} redirect={null} onCallback={handleClose} category={null} />
                    </div>
                </DialogContent>
            </Dialog>
        </Fragment >
    );

};



export default LoginDialog;