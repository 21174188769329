import React from "react";
import Layout from "../layout/Layout";
import LoginComponent from "../components/Account/LoginComponent";
import { useLocation } from "react-router-dom";
import PageTitle from "../layout/PageTitle";
import { useNavigate } from "react-router-dom";

export const Login = () => {

    const search = useLocation().search;
    const navigate = useNavigate();
    const name = new URLSearchParams(search).get('type');
    const redirect = new URLSearchParams(search).get('redirect');
    const category = new URLSearchParams(search).get('cat'); // Expert or regular user when signing up
    const loginDone = () => {
        navigate('/sessions');
    }
    return (
        <Layout headerBtn={false} footer={3}>
            <PageTitle pageTitle="Login or Sign up on Timedora" />
            <div className="auth-inner">
                <LoginComponent type={name} redirect={redirect} onCallback={loginDone} category={category} />
            </div>
            <br /><br /><br />
        </Layout >
    );
};



export default Login;