import React, { createContext, useReducer } from "react";

const type = {
  VIDEO: "VIDEO",
};

const VideoContext = createContext<TheState>({});

type TheState = {
  showVideo?: (id?: string) => void
  hideVideo?: () => void
  video?: any
}

type ActionType = {
  type: string,
  payload: any
}

const videoReducer: (previousState: TheState, action: ActionType) => TheState = (previousState: TheState, action: ActionType) => {
  switch (action.type) {
    case type.VIDEO:
      return {
        ...previousState,
        video: action.payload,
      };

    default:
      return previousState;
  }
};

const VideoState = ({ children }: { children: React.ReactNode }) => {
  const initialState = {
    video: { show: false, videoId: null },
  };

  const [state, dispatch] = useReducer(videoReducer, initialState);

  const showVideo = (id?: string) => {
    dispatch({
      type: type.VIDEO,
      payload: { show: true, id },
    });
  };

  const hideVideo = () => {
    dispatch({
      type: type.VIDEO,
      payload: { show: false, videoId: null },
    });
  };

  return (
    <VideoContext.Provider
      value={{
        showVideo: showVideo,
        hideVideo: hideVideo,
        video: state.video,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export default VideoState;

export { VideoContext };
