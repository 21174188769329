import React, { ChangeEvent, Fragment, useEffect } from "react";
import { AuthContext } from "../../context/authcontext";
import { useAPI } from "../../utils/useAPI";
import { FormEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { useUser } from "../../utils/useUser";
import { api as apiproto } from "../../../src/apiproto";
import { decodeJWT, tdrLog } from "../../utils/utils";
import { LoadingButton } from "@mui/lab";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

type ChildProps = {
    type: string | null,
    redirect: string | null,
    category: string | null,
    onCallback: () => void;
}
export const LoginComponent: React.FC<ChildProps> = ({ type = null, redirect = null, onCallback, category = null }) => {

    const { dispatch } = React.useContext(AuthContext);
    const api = useAPI();
    var user = useUser();
    const [searchParams] = useSearchParams();
    const [data, setData] = React.useState({
        userName: "",
        password: "",
        cpassword: "",
        firstName: "",
        lastName: "",
        isSubmitting: false,
        errorMessage: ""
    });
    const [pressTimeout, setPressTimeout] = React.useState(false);

    // For switching
    const [accountScreen, setAccountScreen] = React.useState('login');

    const emailValidators = ['required', 'isEmail', 'minStringLength:8', 'maxStringLength:128'];
    const emailValidatorMessage = ['Email is required', 'Email is not valid', 'Enter 8 characters or more', 'Enter 128 characters or less'];
    const passwordValidators = ['required', 'minStringLength:8', 'maxStringLength:32'];
    const passwordValidatorMessages = ['Password is required', 'Enter 8 characters or more', 'Enter 32 characters or less'];
    const nameValidators = ['required', 'minStringLength:2', 'maxStringLength:32'];
    const nameValidatorMessages = ['Name is required', 'Enter 2 characters or more', 'Enter 32 characters or less'];
    const navigate = useNavigate();

    useEffect(() => {
        if (user.authenticated) {
            if (type && type === 'signup' && category === 'expert') {
                // If it is a sign up, we just direct them to profile page
                navigate("/profile");
                return;
            }
            let returnLocation = searchParams.get('redirect');
            if (returnLocation !== null) {
                if (returnLocation === "/") {
                    returnLocation = "/sessions";
                }
                navigate(returnLocation);
                return;
            }
            else if (redirect !== null) {
                navigate(redirect);
                return;
            }
            onCallback();
        }
    }, [user.authenticated]);


    useEffect(() => {
        searchParams.get("redirect");
        if (type && type === 'signup') {
            setAccountScreen('signup');
        }
    }, [searchParams]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (pressTimeout) {
                setPressTimeout(false);
                setData({ ...data, isSubmitting: false });
            }
        }, 60000);
        return () => clearTimeout(timer);
    }, [pressTimeout]);
    /*
        if (user.authenticated) {
            if (searchParams.get("redirect") === null && type === null) {
                return (<Navigate to="/sessions" />);
            }
        }
    */
    ValidatorForm.removeValidationRule('isPasswordMatch');
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== data.password) {
            tdrLog(value + '---' + data.password + "---" + JSON.stringify(data));
            return false;
        }
        return true;
    });


    const toggleScreen = (screenType: string) => {
        setData({
            ...data,
            isSubmitting: false,
            errorMessage: ""
        });
        setAccountScreen(screenType);
    };


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        });
    };


    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (accountScreen === 'login') {
            login();
            return
        }
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: ""
        });
        if (accountScreen === 'signup') {
            signup();
        }
        else if (accountScreen === 'forgot') {
            forgot();
        }
    };

    const forgot = async () => {
        setData({ ...data, isSubmitting: false });
        setPressTimeout(true);
        try {
            await api.sendValidationCode(data.userName, true);
            setData({ ...data, isSubmitting: true, errorMessage: "Please check your email for a link to reset your password. If you did not receive it, check your spam folder." });

        } catch (err: any) {
            if (err.name === "VERIFICATION_SENT_LIMIT_EXCEEDED") {
                setData({ ...data, isSubmitting: true, errorMessage: 'You exceeded the number of times in sending a verification code. Please check your email or spam folder. If you did not receive the code, please wait a minute or so to send another code. Thanks!' });
            }
            else {
                setData({ ...data, isSubmitting: true, errorMessage: err.message });
            }
        }
    }

    const googleSuccess = async (response: CredentialResponse) => {
        tdrLog(JSON.stringify(response));
        if (response.credential) {
            try {
                const result = await api.googleLogin(response.credential!);
                if (!result.token) {
                    throw Error('Unexpected response from server');
                }
                const { userId, exp } = decodeJWT(result.token);
                dispatch({ type: 'login', loginPayload: { userId: userId, token: result.token, expirationTime: exp * 1000 } });
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: ""
                });

            } catch (err: any) {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: err.message
                });
            }
        }
    }

    const googleFailed = () => {
        tdrLog('error google login');
    }
    const signup = async () => {
        try {
            var viewType = apiproto.ViewType.ConsumerView;
            if (category === 'expert') {
                viewType = apiproto.ViewType.ProviderView;
            }
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const userInfo = apiproto.User.create({ emailAddress: data.userName, password: data.password, firstName: data.firstName, lastName: data.lastName, description: "", defaultView: viewType, timeZone: timezone });
            const createUserResult = await api.createUser("g_public", userInfo)
            if (createUserResult.userId !== null) {
                login();
            }
            else {
                // TODO- need to wait for lawrence to fix server bug to see what behavior
            }

        } catch (err: any) {
            if (err.message === "SYSTEM_ERROR:unable to send verification email") {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "We were unable to send an email for your verification. Make sure your email is valid."
                });
            }
            else if (err.message.startsWith("INVALID_PARAMETERS")) {
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Error. Make sure your input are valid."
                });
            }
            else if (err.message.startsWith("UNABLE_TO_CREATE_USER")) {
                //tdrAlert(err.message + ' ' + err.description);
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "The email address is already taken."
                });
            }
            else {
                //tdrAlert(err.message + "-" + err.description);
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "An error occur when you try to sign up."
                });
            }
        }
    }

    const login = async () => {
        if (user.authenticated) {
            navigate("/sessions");
            return;
        }
        setData({
            ...data,
            isSubmitting: true,
            errorMessage: ""
        });
        try {
            const result = await api.login(data.userName, data.password);
            if (!result.token) {
                //tdrAlert('Unexpected response with no result token')
                throw Error('Unexpected response from server');
            }
            const { userId, exp } = decodeJWT(result.token);
            dispatch({ type: 'login', loginPayload: { userId: userId, token: result.token, expirationTime: exp * 1000 } });
            setData({
                ...data,
                isSubmitting: false,
                errorMessage: ""
            });

        } catch (err: any) {
            if (err.name === 'ALREADY_AUTHENTICATED') {
                //tdrAlert(err.name + ' : ' + err.message)
                if (user.authenticated) {
                    navigate("/sessions");
                }
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "User with email may already exists."
                });

            }
            else {
                //tdrAlert(err.name + ' : ' + err.message)
                // TODO - somehow when you leave your login screen and it has logout and you try to login, it will give this error.
                setData({
                    ...data,
                    isSubmitting: false,
                    errorMessage: "Unable to login. Email or password may be incorrect."
                });
            }
        }
    }



    return (
        <Fragment>
            <GoogleOAuthProvider clientId="860747522814-a50s3geksanrc7ptngic24gjulgfn8q4.apps.googleusercontent.com">
                {/*-- Signup Screen --*/}
                <div className={accountScreen === 'signup' ? "auth-wrapper accountShow" : "auth-wrapper accountHide"}>

                    <ValidatorForm onSubmit={handleFormSubmit}>
                        <h3>Create Account</h3>
                        <div className="form-group form-input">
                            <TextValidator
                                type="text"
                                value={data.firstName}
                                onChange={handleInputChange}
                                name="firstName"
                                id="firstName"
                                className="form-control"
                                label='First Name'
                                placeholder="Enter First Name"
                                validators={nameValidators}
                                errorMessages={nameValidatorMessages}
                            />
                        </div>
                        <div className="form-group form-input">
                            <TextValidator
                                type="text"
                                value={data.lastName}
                                onChange={handleInputChange}
                                name="lastName"
                                id="lastName"
                                className="form-control"
                                placeholder="Enter Last Name"
                                label="Last Name"
                                validators={nameValidators}
                                errorMessages={nameValidatorMessages}

                            />
                        </div>
                        <div className="form-group form-input">
                            <TextValidator
                                type="text"
                                value={data.userName}
                                onChange={handleInputChange}
                                name="userName"
                                id="userName"
                                className="form-control"
                                label="Email Address"
                                placeholder="Enter email"
                                validators={emailValidators}
                                errorMessages={emailValidatorMessage}

                            />
                        </div>


                        <div className="form-group form-input">
                            <TextValidator type={"password"}
                                className="form-control"
                                placeholder="Enter password"
                                name="password"
                                label="Password"
                                validators={passwordValidators}
                                errorMessages={passwordValidatorMessages}
                                value={data.password}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group form-input">
                            <TextValidator
                                type={"password"}
                                className="form-control"
                                label="Confirm Password"
                                placeholder="Confirm password"
                                name="cpassword"
                                value={data.cpassword}
                                onChange={handleInputChange}
                                validators={[...passwordValidators, 'isPasswordMatch']}
                                errorMessages={[...passwordValidatorMessages, 'Passwords must match']}

                            />
                        </div>

                        <div className="form-group">
                            <div className="custom-control custom-checkbox text-center">
                                <label className="custom-control-label" htmlFor="customCheck1">By signing up, you agree to the Terms of Use and Privacy Policy and that you are 13 years of age or older</label>
                            </div>
                        </div>
                        {data.errorMessage.length > 0 && (
                            <div className="form-error text-center">{data.errorMessage}</div>
                        )}
                        <br />
                        <div className="text-center">
                            <LoadingButton style={{ width: '100%', height: '50px' }} size="medium" variant="contained" loading={data.isSubmitting} type="submit">
                                Create Account
                            </LoadingButton>
                            <br />
                            <p className="create-account">
                                Have an account? <a href="#" onClick={() => toggleScreen('login')}>Login</a>
                            </p>
                            <p className="forgot-password text-right">
                                <a href="#" onClick={() => toggleScreen('forgot')}> Forgot password?</a>
                            </p>
                        </div>
                        <br /><hr />
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                            <GoogleLogin
                                width="260px"
                                theme="filled_blue"
                                size="large"
                                text={"signup_with"}
                                logo_alignment="left"
                                shape="rectangular"
                                onSuccess={googleSuccess}
                                onError={googleFailed}
                            />
                        </div>


                    </ValidatorForm>

                </div >
                {/*-- Login Screen --*/}
                <div className={accountScreen === 'login' ? "auth-wrapper accountShow" : "auth-wrapper accountHide"}>

                    <ValidatorForm onSubmit={handleFormSubmit}>
                        <h3>Sign In</h3>

                        <div className="form-group form-input">
                            <TextValidator
                                minLength={6}
                                maxLength={128}
                                type="text"
                                value={data.userName}
                                onChange={handleInputChange}
                                name="userName"
                                id="userNameJoin"
                                className="form-control"
                                label="Email Address"
                                placeholder="Enter email"
                                validators={emailValidators}
                                errorMessages={emailValidatorMessage}

                            />
                        </div>

                        <div className="form-group form-input">
                            <div className="inputWithButton">
                                <TextValidator
                                    minLength={6}
                                    maxLength={32}
                                    type={"password"}
                                    className="form-control"
                                    placeholder="Enter password"
                                    label="Password"
                                    name="password"
                                    value={data.password}
                                    onChange={handleInputChange}
                                    validators={passwordValidators}
                                    errorMessages={passwordValidatorMessages}

                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />&nbsp;
                                <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                            </div>
                        </div>
                        {data.errorMessage.length > 0 && (
                            <div className="form-error text-center">{data.errorMessage}</div>
                        )}
                        <br />
                        <div className="text-center">
                            <LoadingButton style={{ width: '100%', height: '50px' }} size="large" variant="contained" loading={data.isSubmitting} type="submit">
                                Login
                            </LoadingButton>
                            <br />
                            <br />
                            <p className="create-account">
                                Need an account? <a href="#" onClick={() => toggleScreen('signup')}>Join Now</a>
                            </p>
                            <p className="forgot-password text-right">
                                <a href="#" onClick={() => toggleScreen('forgot')}>Forgot password?</a>
                            </p>
                        </div>
                        <br /><hr />
                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%', alignItems: 'stretch' }}>
                            <GoogleLogin
                                width="260px"
                                theme="filled_blue"
                                size="large"
                                text={"signin_with"}
                                logo_alignment="left"
                                shape="rectangular"
                                onSuccess={googleSuccess}
                                onError={googleFailed}
                            />
                        </div>

                    </ValidatorForm>
                </div >
                {/*-- Forgot Screen --*/}
                <div className={accountScreen === 'forgot' ? "auth-wrapper accountShow" : "auth-wrapper accountHide"}>
                    <ValidatorForm onSubmit={handleFormSubmit}>
                        <h3>Forgot Password</h3>
                        <div className="form-group form-input">
                            <TextValidator
                                type="text"
                                value={data.userName}
                                onChange={handleInputChange}
                                name="userName"
                                id="userNameForgot"
                                className="form-control"
                                label="Email address"
                                placeholder="Enter email"
                                validators={emailValidators}
                                errorMessages={emailValidatorMessage}

                            />
                        </div>
                        <div className="form-group">
                            <label className="custom-control-label" htmlFor="customCheck1">Enter the email you signed up with and we will help set up a new password.</label>
                        </div>
                        {data.errorMessage.length > 0 && (
                            <div className="form-error text-center">{data.errorMessage}</div>
                        )}
                        <div className="text-center">
                            <LoadingButton style={{ width: '100%' }} size="large" variant="contained" disabled={pressTimeout} type="submit">
                                {pressTimeout ? "Resend will be reenable after 60 seconds." : "Continue"}
                            </LoadingButton>
                            <p className="create-account">
                                Existing member? <a href="#" onClick={() => toggleScreen('login')}>Login</a>
                            </p>
                        </div>

                    </ValidatorForm>

                </div >
            </GoogleOAuthProvider>
        </Fragment >
    );

};



export default LoginComponent;