import React, { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import { Autocomplete, AutocompleteChangeDetails, AutocompleteInputChangeReason, Button, FormControl, InputLabel, MenuItem, Popover, Select, SelectChangeEvent, Slider, TextField, TextFieldProps } from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import { computeDurationToHrMin, computeEpochtoPrettyDateStr, computeEpochtoTimeStr, secondsToDhms } from "../../utils/eduutils";
import { DesktopDateRangePicker, LocalizationProvider, StaticDateRangePicker } from "@mui/lab";
import { AutocompleteChangeReason } from "@mui/lab/node_modules/@mui/base";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { RangeInput } from "@mui/lab/DateRangePicker/RangeTypes";
import { useMediaQuery } from "react-responsive";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";

export const SearchBar = () => {

    const allDayTimeRange = [(new Date()).setHours(0, 0, 0, 0), (new Date()).setHours(23, 59, 0, 0)];
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const strAnyDay = "Any Day";
    const strAnyTime = "Any Time";

    const initialDateValue: RangeInput<Date> = [null, null];
    const defaultFilterValue = { days: [false, false, false, false, false, false, false], weekdays: false, weekends: false, timeOfDay: 0, dayFilterText: strAnyDay, timeFilterText: strAnyTime, dateFilterText: 'Any Date', dateChoice: "0", filterOn: false, ageRange: [6, 100], duration: [15 * 60 * 1000, 8 * 60 * 60 * 1000], participants: [1, 101], sessionFormat: "0", timeRange: allDayTimeRange, dateValue: initialDateValue };

    const [searchTerm, setSearchTerm] = useState("");
    const [tempFilter, setTempFilter] = useState(defaultFilterValue);
    const [searchFilter, setSearchFilter] = useState({ ...tempFilter });

    //Search Time Popovers
    const [timeAnchorEl, setTimeAnchorEl] = useState<HTMLElement | null>(null);
    const timepopover = Boolean(timeAnchorEl);
    const timepopoverid = timepopover ? 'time-popover' : undefined;

    //Search Date Popovers
    const [dateAnchorEl, setDateAnchorEl] = useState<HTMLElement | null>(null);
    const datepopover = Boolean(dateAnchorEl);
    const datepopoverid = datepopover ? 'date-popover' : undefined;

    // More Popovers
    const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLElement | null>(null);
    const morepopover = Boolean(moreAnchorEl);
    const morepopoverid = morepopover ? 'more-popover' : undefined;

    useEffect(() => {
        // We copy the applied search filter to the temporary one.
        setTempFilter({ ...searchFilter });
    }, [searchFilter]);

    const handleMorePopOverClick = (event: MouseEvent<HTMLElement>) => {
        //        setTempFilter({ ...searchFilter });
        setMoreAnchorEl(event.currentTarget);
    };

    const handleDatePopOverClick = (event: MouseEvent<HTMLElement>) => {
        setTempFilter({ ...searchFilter });
        setDateAnchorEl(event.currentTarget);
    };

    const handleMorePopOverClose = () => {
        // This copies back the final value depending on whether it was applied or canceled
        setTempFilter({ ...searchFilter });
        setMoreAnchorEl(null);
    };

    const handleTimePopOverClick = (event: MouseEvent<HTMLElement>) => {
        //        setTempFilter({ ...searchFilter });
        setTimeAnchorEl(event.currentTarget);
    };
    const handleTimePopOverClose = () => {
        // This copies back the final value depending on whether it was applied or canceled
        setTempFilter({ ...searchFilter });
        setTimeAnchorEl(null);
    };


    const handleDatePopOverClose = () => {
        // This copies back the final value depending on whether it was applied or canceled
        setTempFilter({ ...searchFilter });
        setDateAnchorEl(null);
    };

    const handleTimePopOverApply = () => {
        setSearchFilter({ ...tempFilter });
        handleTimePopOverClose();
    };

    const handleDatePopOverApply = () => {
        setSearchFilter({ ...tempFilter });
        //dateFilterDescription();
        handleDatePopOverClose();
    };

    const handleMorePopOverApply = () => {
        setSearchFilter({ ...tempFilter });
        //dateFilterDescription();
        handleMorePopOverClose();
    };

    const timeOfDayClick = (index: number) => {
        var anyDay: boolean = false;
        const newdayfilter = { ...tempFilter };
        newdayfilter.timeOfDay = index;
        if (index === 1) {
            newdayfilter.timeRange = [(new Date()).setHours(6, 0, 0, 0), (new Date()).setHours(18, 0, 0, 0)];
        }
        else if (index === 2) {
            newdayfilter.timeRange = [(new Date()).setHours(9, 0, 0, 0), (new Date()).setHours(12, 0, 0, 0)];
        }
        else if (index === 3) {
            newdayfilter.timeRange = [(new Date()).setHours(12, 0, 0, 0), (new Date()).setHours(18, 0, 0, 0)];
        }
        else {
            newdayfilter.timeRange = allDayTimeRange;
            anyDay = true;
        }
        // IF weekends and weekdays and time range is all, then filter is off or if nothing is checked.
        if (!tempFilter.weekdays && !tempFilter.weekends && !tempFilter.days[0] && !tempFilter.days[1] && !tempFilter.days[2] && !tempFilter.days[3] && !tempFilter.days[4] && !tempFilter.days[5] && !tempFilter.days[6] && anyDay) {
            anyDay = true;
        }
        else if (tempFilter.weekdays && tempFilter.weekends && anyDay) {
            anyDay = true;
        }
        newdayfilter.filterOn = !anyDay;
        setTempFilter(newdayfilter);
    }

    const dateFilterDescription = () => {
        var output = "Any Date";
        var filter = { ...tempFilter };
        if (filter.dateChoice === "0") {
            output = "Any Date";
        }
        if (filter.dateChoice === "1") {
            output = "Today";
        }
        else if (filter.dateChoice === "2") {
            output = "Next 14 days";
        }
        else if (filter.dateChoice === "3") {
            output = "Next 30 days";
        }
        else if (filter.dateChoice === "4") {
            output = "Next 90 days";
        }
        else if (filter.dateChoice === "10") {
            if (filter.dateValue[0] && filter.dateValue[1]) {
                output = computeEpochtoPrettyDateStr(filter.dateValue[0].valueOf().toString(), 'll') + ' - ' + computeEpochtoPrettyDateStr(filter.dateValue[1].valueOf().toString(), 'll')
            }
        }
        // setSearchFilter({ ...searchFilter, dateFilterText: output });
        return output;
    }
    const dayFilterDescription = () => {
        // Calculate Day
        var output = "Any day or time";
        var count = 0;
        if (tempFilter.weekdays && tempFilter.weekends) {
            output = "Any day";
        }
        else if (tempFilter.weekdays && !tempFilter.weekends && !tempFilter.days[5] && !tempFilter.days[6]) {
            output = "Weekdays";
        }
        else if (!tempFilter.weekdays && tempFilter.weekends && !tempFilter.days[0] && !tempFilter.days[1] && !tempFilter.days[2] && !tempFilter.days[3] && !tempFilter.days[4]) {
            output = "Weekends";
        }
        else {
            output = '';
            for (var index in tempFilter.days) {
                if (tempFilter.days[index]) {
                    output = output + (output.length > 0 ? ',' : '') + daysOfWeek[index];
                    count++;
                }
            }
            if (count > 3) {
                output = "Some days";
            }
        }
        if (output.length === 0) {
            output = strAnyDay;
        }
        // Calculate Time
        var timeoutput = '';
        if (tempFilter.timeOfDay === 0) {
            timeoutput = strAnyTime;
        }
        else {
            timeoutput = computeEpochtoTimeStr(tempFilter.timeRange[0].toString()) + ' - ' + computeEpochtoTimeStr(tempFilter.timeRange[1].toString());
        }

        return output + ' ' + timeoutput;
    }

    const dayOfWeekCheckClick = (index: number) => {
        let thedays = [...tempFilter.days];
        thedays[index] = !thedays[index];
        const newweekdays: boolean = thedays[0] && thedays[1] && thedays[2] && thedays[3] && thedays[4];
        const newweekends: boolean = thedays[5] && thedays[6];

        setTempFilter({ ...tempFilter, dayFilterText: dayFilterDescription(), days: thedays, weekdays: newweekdays, weekends: newweekends })

    }

    const dayWeekDayClick = () => {
        const theweekdays: boolean[] = [...tempFilter.days];
        if (tempFilter.weekdays) {
            let thedays: boolean[] = [false, false, false, false, false, theweekdays[5], theweekdays[6]];
            setTempFilter({ ...tempFilter, dayFilterText: dayFilterDescription(), days: thedays, weekdays: false });
        }
        else {
            let thedays: boolean[] = [true, true, true, true, true, theweekdays[5], theweekdays[6]];
            setTempFilter({ ...tempFilter, dayFilterText: dayFilterDescription(), days: thedays, weekdays: true });
        }
    }

    const dayWeekEndClick = () => {
        const theweekends: boolean[] = [...tempFilter.days];
        if (tempFilter.weekends) {
            theweekends[5] = false;
            theweekends[6] = false;
            setTempFilter({ ...tempFilter, dayFilterText: dayFilterDescription(), days: theweekends, weekends: false });
        }
        else {
            theweekends[5] = true;
            theweekends[6] = true;
            setTempFilter({ ...tempFilter, dayFilterText: dayFilterDescription(), days: theweekends, weekends: true });
        }
    }

    const handleTimeRangeChange = (event: Event, newValue: number[] | number, activeThumb: number) => {
        if (newValue && Array.isArray(newValue))
            setTempFilter({ ...tempFilter, dayFilterText: dayFilterDescription(), timeOfDay: 5, timeRange: newValue });

    };
    const handleSessionFormatChange = (event: SelectChangeEvent<string>) => {
        setTempFilter({ ...tempFilter, sessionFormat: event.target.value });
    };
    const handleDurationRangeChange = (event: Event, newValue: number[] | number, activeThumb: number) => {
        if (newValue && Array.isArray(newValue))
            setTempFilter({ ...tempFilter, duration: newValue });
    };
    const handleAgeRangeChange = (event: Event, newValue: number[] | number, activeThumb: number) => {
        if (newValue && Array.isArray(newValue))
            setTempFilter({ ...tempFilter, ageRange: newValue });
    };
    const handleParticipantRangeChange = (event: Event, newValue: number[] | number, activeThumb: number) => {
        if (newValue && Array.isArray(newValue))
            setTempFilter({ ...tempFilter, participants: newValue });
    };

    const handleDateSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        const today: Date = new Date();
        if (value === "0") {
            setTempFilter({ ...tempFilter, dateChoice: "0", dateValue: [today, new Date(today.getTime() + 1000 * 60 * 60 * 24 * 365)] });
        }
        else if (value === "1") {
            setTempFilter({ ...tempFilter, dateChoice: "1", dateValue: [today, today] });
        }
        else if (value === "2") {
            setTempFilter({ ...tempFilter, dateChoice: "2", dateValue: [today, new Date(today.getTime() + 1000 * 60 * 60 * 24 * 14)] });
        }
        else if (value === "3") {
            setTempFilter({ ...tempFilter, dateChoice: "3", dateValue: [today, new Date(today.getTime() + 1000 * 60 * 60 * 24 * 30)] });
        }
        else if (value === "4") {
            setTempFilter({ ...tempFilter, dateChoice: "4", dateValue: [today, new Date(today.getTime() + 1000 * 60 * 60 * 24 * 90)] });
        }
        else {
            setTempFilter({ ...tempFilter, dateChoice: value });
        }
    };

    const sixam = (new Date()).setHours(6, 0, 0, 0);
    const sixpm = (new Date()).setHours(22, 0, 0, 0);

    const timeRangeText = (value: number) => {
        const str = computeEpochtoTimeStr(value.toString());
        return str;
    };

    const durationRangeText = (value: number) => {
        const str = computeDurationToHrMin(value);
        return str;
    };

    const sliderRangeText = (value: number) => {
        return value.toString();
    };


    const handleChange = (event: React.SyntheticEvent<Element, Event>, value: string | null, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails) => {
        if (value) {
            setSearchTerm(value);
            return value;
        }
    }

    const handleSearchInputChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        return value;

    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
    }

    const autosuggestterms = [{ title: 'Math', id: 1 }];



    return (
        <div className="container divpadding">
            {/*==========================Search bar=======================*/}
            <div className="row">
                <div className="col-md-8">
                    <form onSubmit={handleSubmit}>
                        <Autocomplete
                            key="autocompletebox"
                            id="search-box"
                            freeSolo
                            autoSelect
                            value={searchTerm}
                            onInputChange={handleSearchInputChange}
                            onChange={handleChange}
                            options={autosuggestterms.map((option) => option.title)}
                            renderInput={(params) => <TextField {...params} autoFocus InputProps={{
                                ...params.InputProps,
                                startAdornment: (<SearchIcon />
                                )
                            }} label="Search" />}
                        />
                    </form>
                </div>
            </div>
            <div className="row rowpadding">
                {/*==========================Day and Time Ranger Filter Button and Popover=======================*/}
                <div className="col-md-12">
                    <Button size="medium" aria-describedby={timepopoverid} variant={searchFilter.filterOn ? "contained" : "outlined"} onClick={handleTimePopOverClick}>
                        {dayFilterDescription()}
                    </Button>
                    <Popover
                        id={timepopoverid}
                        open={timepopover}
                        anchorEl={timeAnchorEl}
                        onClose={handleTimePopOverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                    >
                        <div className="divpadding">
                            <Typography variant="h6">Day of week.</Typography>
                            <div className="row">
                                <div className="col"><br />
                                    {daysOfWeek.map((m, index) => (
                                        <Button key={'daysofweek' + index} size="small" aria-describedby={datepopoverid} variant={tempFilter.days[index] ? "contained" : "outlined"} onClick={() => dayOfWeekCheckClick(index)}>
                                            {daysOfWeek[index]}
                                        </Button>
                                    ))}
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <br />
                                    <Button size="small" aria-describedby={datepopoverid} variant={tempFilter.weekdays ? "contained" : "outlined"} onClick={dayWeekDayClick}>
                                        Weekdays
                                    </Button>
                                    <Button size="small" aria-describedby={datepopoverid} variant={tempFilter.weekends ? "contained" : "outlined"} onClick={dayWeekEndClick}>
                                        Weekends
                                    </Button>

                                </div>
                            </div>
                            <hr />
                            <Typography variant="h6">Time Of Day</Typography>
                            <br />
                            <div className="row">
                                <div className="col">
                                    <Button sx={{ width: 240 }} size="large" variant={tempFilter.timeOfDay === 0 ? "contained" : "outlined"} onClick={() => timeOfDayClick(0)}>
                                        Any time
                                    </Button>
                                    <Button sx={{ width: 240 }} size="large" variant={tempFilter.timeOfDay === 1 ? "contained" : "outlined"} onClick={() => timeOfDayClick(1)}>
                                        All Day (6am-6pm)
                                    </Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Button sx={{ width: 240 }} size="large" variant={tempFilter.timeOfDay === 2 ? "contained" : "outlined"} onClick={() => timeOfDayClick(2)}>
                                        Morning (9am-12pm)
                                    </Button>
                                    <Button sx={{ width: 240 }} size="large" variant={tempFilter.timeOfDay === 3 ? "contained" : "outlined"} onClick={() => timeOfDayClick(3)}>
                                        Afternoon (12pm-6pm)
                                    </Button>
                                </div>
                            </div>
                            <br />
                            <div className="row text-center">
                                <div className="col">
                                    <Slider sx={{ width: 0.9 }}
                                        getAriaLabel={() => 'Time of Day'}
                                        valueLabelFormat={timeRangeText}
                                        value={tempFilter.timeRange}
                                        onChange={handleTimeRangeChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={timeRangeText}
                                        min={sixam}
                                        max={sixpm}
                                        step={900000}
                                        disableSwap
                                    />
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-2">
                                    {tempFilter.timeRange[0] ? computeEpochtoTimeStr(tempFilter.timeRange[0].toString()) : ""}
                                </div>
                                <div className="col-8">
                                </div>
                                <div className="col-2">

                                    {tempFilter.timeRange[1] ? computeEpochtoTimeStr(tempFilter.timeRange[1].toString()) : ""}
                                </div>
                            </div>

                            <br />

                        </div>
                        <hr />
                        <div className="divpadding">
                            <div className="row">
                                <div className="col-4">
                                    <Button sx={{ color: 'gray' }} size="large" variant="text" onClick={handleTimePopOverClose}>
                                        Cancel
                                    </Button>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4" style={{ paddingRight: 10 }}>
                                    <Button sx={{ backgroundColor: 'green', float: 'right' }} size="large" variant="contained" onClick={handleTimePopOverApply}>
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </Popover>
                    &nbsp;&nbsp;&nbsp;
                    {/*==========================Date Range Filter Button and Popover=======================*/}
                    <Button size="medium" aria-describedby={datepopoverid} variant={searchFilter.dateChoice !== "0" ? "contained" : "outlined"} onClick={handleDatePopOverClick}>
                        {dateFilterDescription()}
                    </Button>
                    <Popover
                        id={datepopoverid}
                        open={datepopover}
                        anchorEl={dateAnchorEl}
                        onClose={handleDatePopOverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                    >
                        <div className="divpadding">
                            <Typography variant="h6">Sessions Starting</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className="rowpadding">
                                    <ValidatorForm onSubmit={handleSubmit}>
                                        <SelectValidator
                                            id="dateChoice"
                                            name="dateChoice"
                                            value={tempFilter.dateChoice}
                                            className="form-control"
                                            label="Choose Date Range"
                                            onChange={handleDateSelectChange}>
                                            <MenuItem value={"0"}>All</MenuItem>
                                            <MenuItem value={"1"}>Today</MenuItem>
                                            <MenuItem value={"2"}>Next 14 Days</MenuItem>
                                            <MenuItem value={"3"}>Next 30 Days</MenuItem>
                                            <MenuItem value={"4"}>Next 90 Days</MenuItem>
                                            <MenuItem value={"10"}>Custom</MenuItem>

                                        </SelectValidator>
                                    </ValidatorForm>
                                </div>
                                <div className="rowpadding">

                                    {useMediaQuery({ query: '(max-width: 768px)' }) ? "" : <DesktopDateRangePicker
                                        startText="Start"
                                        value={tempFilter.dateValue}
                                        onChange={(newValue) => {
                                            setTempFilter({ ...tempFilter, dateChoice: "10", dateValue: newValue });
                                        }}
                                        disableOpenPicker
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps} />
                                                <Box sx={{ mx: 2 }}> to </Box>
                                                <TextField {...endProps} />
                                            </React.Fragment>
                                        )}
                                    />}
                                    <StaticDateRangePicker
                                        displayStaticWrapperAs={useMediaQuery({ query: '(max-width: 768px)' }) ? "mobile" : "desktop"}
                                        value={tempFilter.dateValue}
                                        onChange={(newValue) => {
                                            setTempFilter({ ...tempFilter, dateChoice: "10", dateValue: newValue });
                                        }}
                                        renderInput={(startProps: JSX.IntrinsicAttributes & TextFieldProps, endProps: JSX.IntrinsicAttributes & TextFieldProps) => (
                                            <React.Fragment>
                                                <TextField {...startProps} />
                                                <Box sx={{ mx: 2 }}> to </Box>
                                                <TextField {...endProps} />
                                            </React.Fragment>
                                        )}
                                    />
                                </div>
                                <hr />
                                <div className="divpadding">
                                    <div className="row">
                                        <div className="col-4">
                                            <Button sx={{ color: 'gray' }} size="large" variant="text" onClick={handleDatePopOverClose}>
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-4" style={{ paddingRight: 10 }}>
                                            <Button sx={{ backgroundColor: 'green', float: 'right' }} size="large" variant="contained" onClick={handleDatePopOverApply}>
                                                Apply
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </LocalizationProvider>
                        </div>
                    </Popover>
                    &nbsp;&nbsp;&nbsp;
                    {/*==========================More  Button and Popover=======================*/}
                    <Button size="medium" aria-describedby={datepopoverid} variant={searchFilter.dateChoice !== "0" ? "contained" : "outlined"} onClick={handleMorePopOverClick}>
                        Advanced
                    </Button>
                    <Popover
                        id={morepopoverid}
                        open={morepopover}
                        anchorEl={moreAnchorEl}
                        onClose={handleMorePopOverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}

                    >
                        <div className="divpadding divwidth500">
                            <Typography variant="h6">Advanced</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <div className="row">
                                    <div className="col">
                                        <hr />
                                        <FormControl fullWidth>
                                            <InputLabel id="sessionformat-label">Session Format</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={tempFilter.sessionFormat}
                                                label="Session Format"
                                                onChange={handleSessionFormatChange}
                                            >
                                                <MenuItem value={0}>Choose Class Format</MenuItem>
                                                <MenuItem value={10}>One Time</MenuItem>
                                                <MenuItem value={20}>Ongoing</MenuItem>
                                                <MenuItem value={30}>Multi Sessions (Join anytime)</MenuItem>
                                                <MenuItem value={40}>Multi Sessions (Must join first session)</MenuItem>
                                            </Select>
                                        </FormControl>                                                </div>
                                </div>

                                <div className="row text-center">
                                    <div className="col">
                                        <hr />
                                        <b>Duration:</b> {secondsToDhms(tempFilter.duration[0] / 1000, true)}-{secondsToDhms(tempFilter.duration[1] / 1000, true)}
                                        <Slider sx={{ width: 0.9 }}
                                            getAriaLabel={() => 'Duration'}
                                            valueLabelFormat={durationRangeText}
                                            value={tempFilter.duration}
                                            onChange={handleDurationRangeChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={durationRangeText}
                                            min={defaultFilterValue.duration[0]}
                                            max={defaultFilterValue.duration[1]}
                                            step={15 * 60 * 1000}
                                            disableSwap
                                        />
                                    </div>
                                </div>

                                <div className="row text-center">
                                    <div className="col">
                                        <hr />
                                        <b>Age Group:</b> {tempFilter.ageRange[0]}-{tempFilter.ageRange[1]}
                                        <Slider sx={{ width: 0.9 }}
                                            getAriaLabel={() => 'Age'}
                                            valueLabelFormat={sliderRangeText}
                                            value={tempFilter.ageRange}
                                            onChange={handleAgeRangeChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={sliderRangeText}
                                            min={defaultFilterValue.ageRange[0]}
                                            max={defaultFilterValue.ageRange[1]}
                                            step={1}
                                            disableSwap
                                        />
                                    </div>
                                </div>
                                <div className="row text-center">
                                    <div className="col">
                                        <hr />
                                        <b>Participants:</b> {tempFilter.participants[0]}-{tempFilter.participants[1]}
                                        <Slider sx={{ width: 0.9 }}
                                            getAriaLabel={() => 'Participants'}
                                            valueLabelFormat={sliderRangeText}
                                            value={tempFilter.participants}
                                            onChange={handleParticipantRangeChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={sliderRangeText}
                                            min={defaultFilterValue.participants[0]}
                                            max={defaultFilterValue.participants[1]}
                                            step={1}
                                            disableSwap
                                        />
                                    </div>
                                </div>
                                <div className="divpadding">
                                    <div className="row">
                                        <div className="col-4">
                                            <Button sx={{ color: 'gray' }} size="large" variant="text" onClick={handleMorePopOverClose}>
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className="col-4"></div>
                                        <div className="col-4" style={{ paddingRight: 10 }}>
                                            <Button sx={{ backgroundColor: 'green', float: 'right' }} size="large" variant="contained" onClick={handleMorePopOverApply}>
                                                Apply
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </LocalizationProvider>
                        </div>
                    </Popover>
                </div>

            </div></div >
    );

}
export default SearchBar

