import React from "react";

const BecomeATeacherServices4 = () => {
    return (
        <div className="container divpadding">
            {/* SECTION TITLE */}
            <div className="row">
                <div className="col-md-12">
                    <div className="section-title title-centered mb-70">
                        {/* Title 	*/}
                        <h3 className="h3-sm">
                            Share your Knowledge and Earn
                        </h3>
                        {/* Text */}
                        <p className="p-md">
                            Sign up to teach, mentor, coach, counsel or consult. You choose how you want to share your knowledge and have someone connect live with you via video conferencing. Get paid for your knowledge.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* SERVICE BOX #1 */}
                <div className="col-md-4">
                    <div className="sbox-4 text-center">
                        {/* Icon */}
                        <img
                            className="img-95"
                            src="images/icons/creative.png"
                            alt="service-icon"
                        />
                        {/* Text */}
                        <div className="sbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-lg">Create a Session</h5>
                            {/* Text */}
                            <p className="grey-color">
                                Create a session on the knowledge you want to share. Set up time slots when you would be available for someone to connect live with you. You can also create multiple sessions and earn by sharing different knowledges.
                            </p>
                        </div>
                    </div>
                </div>
                {/* END SERVICE BOX #1 */}
                {/* SERVICE BOX #2 */}
                <div className="col-md-4">
                    <div className="sbox-4 text-center">
                        {/* Icon */}
                        <img
                            className="img-95"
                            src="images/icons/film.png"
                            alt="service-icon"
                        />
                        {/* Text */}
                        <div className="sbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-lg">Live Video Conferencing</h5>
                            {/* Text */}
                            <p className="grey-color">
                                Connect with your student or client via live video conferencing during the reserved time slot. Then get paid.
                            </p>
                        </div>
                    </div>
                </div>
                {/* END SERVICE BOX #2 */}
                {/* SERVICE BOX #3 */}
                <div className="col-md-4">
                    <div className="sbox-4 text-center">
                        {/* Icon */}
                        <img
                            className="img-95"
                            src="images/icons/classroom.png"
                            alt="service-icon"
                        />
                        {/* Text */}
                        <div className="sbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-lg">Build Your Community</h5>
                            {/* Text */}
                            <p className="grey-color">
                                Use it as a platform for your scheduling and billing needs with existing clients or generate new leads.
                            </p>
                        </div>
                    </div>
                </div>
                {/* END SERVICE BOX #3 */}
            </div>
            {/* End row */}
        </div>
    );
};

export default BecomeATeacherServices4;
