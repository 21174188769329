import React, { Fragment, useEffect, useState } from 'react';
import '../../css/classroom.css';
// CSS should be explicitly imported if using the default UI
// used by the default ParticipantView to maintain video aspect ratio.
// this CSS must be imported globally
// if you are using a custom Participant renderer, this import isn't necessary.
import "react-aspect-ratio/aspect-ratio.css";
import { useAPI } from '../../utils/useAPI';
import { RequireAuth, useUser } from '../../utils/useUser';
import { useParams } from 'react-router-dom';
import { ConferenceRoom } from './ConferenceRoom';
import { DisplayContext, DisplayContextActionType, DisplayOptions, LayoutType } from './DisplayContext';
import { VideoSource } from './ConferenceTypes';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom"
import { Button } from '@mui/material';
import PageTitle from '../../layout/PageTitle';

export type ConferenceParams = {
  classroomOrTimeId: string;
}


const reducer: (state: DisplayOptions, action: DisplayContextActionType) => DisplayOptions = (state: DisplayOptions, action: DisplayContextActionType) => {
  switch (action.type) {
    case "setLayout":
      if (action.payload && action.payload.newLayout) {
        return {
          ...state,
          stageLayout: action.payload.newLayout,
        };
      } else {
        return state;
      }
    case "setStat":
      if (action.payload && action.payload.newStat) {
        return {
          ...state,
          showStats: action.payload.newStat,
        };
      } else {
        return state;
      }
    case "setSelectedParticipant":
      if (action.payload && action.payload.newParticipant) {
        return {
          ...state,
          selectedParticipant: action.payload.newParticipant,
        };
      } else {
        return state;
      }
    case "setSource":
      if (action.payload && action.payload.newSource) {
        return {
          ...state,
          selectedVideoSource: action.payload.newSource,
        };
      } else {
        return state;
      }
    case "setConnectState":
      if (action.payload) {
        return {
          ...state,
          connectState: action.payload.newConnectState ? true : false
        }
      } else {
        return state;
      }
    case "setCameraEnabled":
      if (action.payload) {
        return {
          ...state,
          cameraEnabled: action.payload.newCameraEnabled ? true : false
        }
      } else {
        return state;
      }
    case "setVideoDeviceId":
      if (action.payload) {
        return {
          ...state,
          videoDeviceId: action.payload.newVideoDeviceId ? action.payload.newVideoDeviceId : null
        }
      } else {
        return state;
      }
    case "setMicrophoneEnabled":
      if (action.payload) {
        return {
          ...state,
          microphoneEnabled: action.payload.newMicrophoneEnabled ? true : false
        }
      } else {
        return state;
      }
    case "setAudioDeviceId":
      if (action.payload) {
        return {
          ...state,
          audioDeviceId: action.payload.newAudioDeviceId ? action.payload.newAudioDeviceId : null
        }
      } else {
        return state;
      }
    case "setVideoDevices":
      if (action.payload) {
        return {
          ...state,
          videoDevices: action.payload.newVideoDevices ? action.payload.newVideoDevices : 0
        }
      } else {
        return state;
      }
    default:
      return state;
  }
};

const Conference = () => {
  const [conferenceError, setConferenceError] = useState("");
  const [conferenceUrl, setConferenceUrl] = React.useState<string | null>(null);
  const [conferenceToken, setConferenceToken] = React.useState<string | null>(null);
  const [googleMeetId, setGoogleMeetId] = React.useState<string | null>(null);
  const [zoomMeetingId, setZoomMeetingId] = React.useState<string | null>(null);
  const [zoomMeetingPassword, setZoomMeetingPassword] = React.useState<string | null>(null);
  const params = useParams<ConferenceParams>();
  const api = useAPI();
  const user = useUser();
  const navigate = useNavigate();

  const [displayOptions, displayOptionsDispatch] = React.useReducer(reducer, {
    stageLayout: LayoutType.Auto,
    showStats: false,
    selectedParticipant: null,
    selectedVideoSource: VideoSource.Auto,
    connectState: false,
    cameraEnabled: true,
    videoDeviceId: null,
    microphoneEnabled: true,
    audioDeviceId: null,
    videoDevices: 0
  })

  useEffect(() => {

    // load class
    (async () => {
      try {
        if (user.authenticated && params.classroomOrTimeId && !conferenceToken) {
          const conferenceConnectInfo = await api.getConferenceConnectInfo(params.classroomOrTimeId, displayOptions.connectState);
          if (conferenceConnectInfo.conferenceToken && conferenceConnectInfo.conferenceUrl) {
            //conferenceConnectInfo.conferenceUrl = 'wss://livekit.timepiper.com:443'
            setConferenceUrl(conferenceConnectInfo.conferenceUrl);
            setConferenceToken(conferenceConnectInfo.conferenceToken);
          } else if (conferenceConnectInfo.googleMeetId && conferenceConnectInfo.conferenceUrl) {
            // window.location.href = conferenceConnectInfo.conferenceUrl;
            setConferenceUrl(conferenceConnectInfo.conferenceUrl);
            setGoogleMeetId(conferenceConnectInfo.googleMeetId)
          } else if (conferenceConnectInfo.zoomMeetingId && conferenceConnectInfo.meetingPassword && conferenceConnectInfo.conferenceUrl) {
            setConferenceUrl(conferenceConnectInfo.conferenceUrl);
            setZoomMeetingId(conferenceConnectInfo.zoomMeetingId)
            setZoomMeetingPassword(conferenceConnectInfo.meetingPassword)
          }
        }
      } catch (err: any) {
        if (err.name === 'CONF_NORESERVATIONYET') {
          setConferenceError('Session has no one enrolled yet. Conference is not yet ready to start.');
        }
        else if (err.name === 'CONF_TIMEEXPIRED') {
          setConferenceError('Session has already expired as the end time of the session has passed for too long.');
        }
        else if (err.name === 'CONF_TIMENOTREADY') {
          setConferenceError('Session is not ready to start as the starting time of the session is still some time away');
        }
        else if (err.name === 'UNAUTHORIZED') {
          setConferenceError('Your session cannot start. Make sure you are login as the person who is authorized to participate in this session.');
        }
        else if (err.name === 'ZOOM_NOT_CONNECTED') {
          setConferenceError('The host originally scheduled this session on Zoom. However, there are technical problems with using Zoom at this time. If you are the host, please check your Zoom integration. If you are an attendee, please contact the host for this session to re-configure their Zoom integration again or select Timedora Video as the location.')
        }
        else if (err.name === 'MEET_NOT_CONNECTED') {
          setConferenceError('This session is originally scheduled on Google Meet. However, there are technical problems with using Google Meet at this time. If you are the host, please check your Google Meet integration. If you are an attendee, please contact the host for this session to re-configure their Google Meet integration again or select Timedora Video as the location.')
        }
        else if (err.name === 'SYSTEM_ERROR') {
          setConferenceError('This session is originally scheduled on Google Meet or Zoom. However, there are technical problems. If you are the host, please re-check your integration with these video providers. If you are an attendee, please contact the host for this session to re-configure their video conference integration again or select Timedora Video as the location.');
        }
        else {
          setConferenceError('The session is not ready to start yet. This can be because the session has expired, or the session start time is in the future, there are no participants who book the session yet or an error.');
        }
      }
    })();
    return () => {
    }
  }, [user.userId, displayOptions.connectState]);

  if (conferenceToken && params.classroomOrTimeId) {

    const joinLeaveClicked = async () => {
      displayOptionsDispatch({
        type: 'setConnectState', payload: { newConnectState: !displayOptions.connectState }
      })
      setConferenceToken(null);
    }

    return (
      <div className="classroom">
        <PageTitle pageTitle="Timedora Video Conference" />
        <div className='classroom-container'>
          <RequireAuth>
            <DisplayContext.Provider value={{ displayOptions, displayOptionsDispatch }}>
              <div className='classroom-room-container'>
                {conferenceToken &&
                  <ConferenceRoom key={conferenceToken} joinLeaveClicked={joinLeaveClicked} url={conferenceUrl ? conferenceUrl : ''} connectOptions={
                    {}} token={conferenceToken} />

                }</div></DisplayContext.Provider></RequireAuth>
        </div> :
        <div>This end time set for this conference passed a while ago. This session is expired.</div>
      </div >
    );
  } else if (googleMeetId && conferenceUrl) {
    return (<div>
      <RequireAuth>
        <Box
          display="flex"
          flexWrap={'wrap'}
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"

        >
          {
            conferenceError.length > 0 ? <div style={{ textAlign: 'center', padding: 180 }}>{conferenceError}<br />
              <Button size="medium" sx={{ marginTop: 5, align: "center", height: 50, width: 180 }} variant="contained" onClick={() => { navigate(-1) }}>
                Dismiss
              </Button>
            </div>
              : <div style={{ textAlign: 'center', padding: 180 }}>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-12">
                    <img src="/images/apps/app_google_meet_icon.png" alt=" google meet" width="92" />
                  </div>
                </div>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-12">
                    <b>Google Meet</b><br /><br />Your session is hosted on Google Meet. Your Google Meet ID is <b>{googleMeetId}</b> <br />
                    <Button size="large" sx={{ margin: 5, align: "center", height: 50, width: 150 }} variant="contained" onClick={(e) => { e.preventDefault(); window.location.href = conferenceUrl }}>
                      Join
                    </Button>
                    <Button size="large" sx={{ margin: 5, align: "center", height: 50, width: 150, backgroundColor: 'gray' }} variant="contained" onClick={(e) => { e.preventDefault(); navigate(-1) }}>
                      Back
                    </Button>
                  </div>
                </div>
              </div>}
        </Box></RequireAuth>
    </div>);
  } else if (zoomMeetingId && zoomMeetingPassword && conferenceUrl) {
    return (<div>
      <RequireAuth>
        <Box
          display="flex"
          flexWrap={'wrap'}
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"

        >
          {
            conferenceError.length > 0 ? <div style={{ textAlign: 'center', padding: 180 }}>{conferenceError}<br />
              <Button size="medium" sx={{ marginTop: 5, align: "center", height: 50, width: 180 }} variant="contained" onClick={() => { navigate(-1) }}>
                Dismiss
              </Button>
            </div>
              : <div style={{ textAlign: 'center', padding: 180 }}>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-12">
                    <img src="/images/apps/app_zoom_icon.png" alt=" google meet" width="92" />
                  </div>
                </div>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-12">
                    <b>Zoom Video</b><br /><br />
                    Your session is hosted on Zoom Video. <br />Your Zoom meeting ID is <b>{zoomMeetingId}</b> and  meeting password is <b>{zoomMeetingPassword} </b><br />
                    <Button size="medium" sx={{ margin: 5, align: "center", height: 50, width: 150 }} variant="contained" onClick={(e) => { e.preventDefault(); window.location.href = conferenceUrl; }}>
                      Join
                    </Button>
                    <Button size="large" sx={{ margin: 5, align: "center", height: 50, width: 150, backgroundColor: 'gray' }} variant="contained" onClick={(e) => { e.preventDefault(); navigate(-1) }}>
                      Back
                    </Button>

                  </div>
                </div>
              </div>}
        </Box></RequireAuth>
    </div>);
  } else {
    return (<div>
      <RequireAuth>
        <Box
          display="flex"
          flexWrap={'wrap'}
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"

        >
          {
            conferenceError.length > 0 ? <div style={{ textAlign: 'center', padding: 180 }}>{conferenceError}<br />
              <Button size="medium" sx={{ marginTop: 5, align: "center", height: 50, width: 180 }} variant="contained" onClick={() => { navigate(-1) }}>
                Dismiss
              </Button>
            </div>
              : <Fragment>
                <div><CircularProgress /></div>
                <p>&nbsp;&nbsp;&nbsp;Loading conference room...</p>
              </Fragment>}
        </Box></RequireAuth>
    </div>);
  }
}

export default Conference;
