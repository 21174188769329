import { Track, VideoTrack } from "livekit-client";
import React, { ReactElement } from "react";
import { ConferenceProps } from "./ConferenceView"
import { UserView } from "./UserView";
import { ScreenShareView } from "./ScreenShareView";
import "./mobile.css";
import { VideoSource } from "./ConferenceTypes";

export const MobileView = ({
    roomState,
    onLeave,
    joinLeaveClicked,
    chatClicked
}: ConferenceProps) => {
    const { isConnecting, error, participants, room } = roomState;

    if (error) {
        return <div>error {error.message}</div>;
    }

    if (isConnecting) {
        return <div>connecting</div>;
    }
    if (!room) {
        return <div>room closed</div>;
    }

    if (participants.length === 0) {
        return <div>no one is in the room</div>;
    }


    // find first participant with screen shared
    let screenTrack: VideoTrack | undefined;
    participants.forEach((p) => {
        if (screenTrack) {
            return;
        }
        const track = p.getTrack(Track.Source.ScreenShare);
        if (track?.isSubscribed && track.videoTrack) {
            screenTrack = track.videoTrack;
        }
    });

    const otherParticipants = participants;
    let mainView: ReactElement;
    if (screenTrack) {
        mainView = (
            <ScreenShareView track={screenTrack} height="100%" width="100%" shareOnTop={true} />
        );
    } else {
        mainView = (
            <UserView
                key={participants[0].identity}
                source={VideoSource.Auto}
                participant={participants[0]}
                showOverlay={true}
                showSpeaking={participants[0].isSpeaking}
                width="100%"
                height="100%"
                aspectWidth={3}
                aspectHeight={3}
                orientation="portrait"
            />
        );
    }

    return (
        // global container
        <div className="conf-mobile-container">
            <div className="conf-mobile-stage">{mainView}</div>
            <div className="conf-mobile-participants-area">
                {otherParticipants.map((participant) => {
                    return (
                        <UserView
                            key={participant.identity}
                            source={VideoSource.Auto}
                            participant={participant}
                            className="conf-mobile-participant"
                            aspectWidth={4}
                            aspectHeight={3}
                            showOverlay={true}
                            showSpeaking={participant.isSpeaking}
                        />
                    );
                })}
            </div>
        </div>
    );
};