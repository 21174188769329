import React from "react";

const PricingAbout4 = () => {
    return (
        <section id="about-4">

            <div className="container">
                {/* ABOUT TEXT */}
                <div className="row">
                    <div className="col-xl-10 offset-xl-1">
                        <div className="a4-txt">
                            {/* Title */}
                            <h5 className="h5-xl text-center">
                                No Long Term Commitment, No Cost to Start
                            </h5>
                            {/* Text */}
                            <p>
                                Our goal is to enable teachers, coaches, mentors and professionals to share their domain expertise and knowledge to benefit a community of users who are seeking advice, counsel and knowledge.
                            </p>
                        </div>
                    </div>
                </div>
                {/* END ABOUT TEXT */}
                <br /><br />
                {/* ABOUT BOXES */}
                <div className="a4-boxes">
                    <div className="row d-flex align-items-center">
                        {/* BOX #1 */}
                        <div className="col-md-4">
                            <div className="abox-4 icon-sm">
                                {/* Icon */}
                                <span className="flaticon-004-computer" />
                                {/* Text */}
                                <div className="abox-4-txt">
                                    <h5 className="h5-md">No Hassle Billing</h5>
                                    <p>
                                        We handle billing for you. Users pay immediately for your time and you get paid after the session is delivered.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* END BOX #1 */}
                        {/* BOX #2 */}
                        <div className="col-md-4">
                            <div className="abox-4 icon-sm">
                                {/* Icon */}
                                <span className="flaticon-028-learning-1" />
                                {/* Text */}
                                <div className="abox-4-txt">
                                    <h5 className="h5-md">
                                        Low Commissions
                                    </h5>
                                    <p>
                                        We take a small commission so we can power the service for you and reinvest in lead generation and marketing for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* END BOX #2 */}
                        {/* BOX #3 */}
                        <div className="col-md-4">
                            <div className="abox-4 icon-sm">
                                {/* Icon */}
                                <span className="flaticon-032-tablet" />
                                {/* Text */}
                                <div className="abox-4-txt">
                                    <h5 className="h5-md">Different plans</h5>
                                    <p>
                                        Choose from different plans that suit your needs. The Basic plan gets you started easily at no cost and let you try marketing your knowledge online.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* END BOX #3 */}
                    </div>
                    {/* End row */}
                </div>
                {/* END ABOUT BOXES */}
            </div>
            {/* End container */}
        </section>
    );
};

export default PricingAbout4;
