import React, { useEffect, useState } from "react";
import PricingDetail from "../components/Pricing Page/PricingDetail";
import PricingAbout4 from "../components/Pricing Page/PricingAbout4";
//import PricingBrands1 from "../components/Pricing Page/PricingBrands1";
//import PricingContacts1 from "../components/Pricing Page/PricingContacts1";
import Layout from "../layout/Layout";
import PageTitle from "../layout/PageTitle";
import { useUser } from "../utils/useUser";



const Pricing = () => {
  const user = useUser();
  const [footerType, setFooterType] = useState(2)
  useEffect(() => {
    if (user.authenticated) {
      setFooterType(3);
    }
  }, [user.authenticated]);

  return (
    <Layout headerBtn footer={footerType}>
      <div className="inner-page-wrapper">
        <PageTitle pageTitle="Pricing Plans" />

        {/* END BREADCRUMB */}
        {/* PRICING-1
				============================================= */}
        <PricingDetail />
        {/* END PRICING-1 */}
        {/* ABOUT-4
				============================================= */}
        {!user.authenticated &&
          <div>
            <hr />
            <PricingAbout4 />
          </div>}

        {/* End ABOUT-4 */}
        {/* BRANDS-1
				============================================= */}
        {/* <PricingBrands1 /> */}
        {/* END BRANDS-1 */}
        {/* CONTACTS-1
				============================================= */}
        {/*<PricingContacts1 />*/}
        {/* END CONTACTS-1 */}
        {/* FOOTER-2
				============================================= */}
      </div>
    </Layout>
  );
};

export default Pricing;
