import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React, { Fragment, useEffect, useState, MouseEvent } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { calculateUserRatings, capitalizeFirstLetterOfEveryWord, computeEpochtoPrettyDateStr, computeEpochtoTimeStr, frequencyString, getFormat, getTopicPhotoURL, getOwners, getPriceStr, getRegularTopicMembers, getTopicShareStatus, getUserPhotoURL, IScheduleCard, IUserRating, secondsToDhms, isUserOwnerOfTopic, ExtendedTime, generateProfileURL, checkIfUserEnrolledInRoom, checkIfTopicProfile, getShortTimeZone, getTimeZone, getProfileTimeZoneStr, isUserMemberOfGroupId } from "../../utils/eduutils";
import { alpha, Avatar, Box, Button, Card, CardActionArea, CardActions, CardContent, Divider, Menu, MenuItem, MenuProps, Skeleton, styled, Table, TableBody, TableCell, TableContainer, TableRow, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CakeIcon from '@mui/icons-material/Cake';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PublishIcon from '@mui/icons-material/Publish';
import { useUser } from "../../utils/useUser";
import IosShareIcon from '@mui/icons-material/IosShare';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import EditIcon from '@mui/icons-material/Edit';
import ReviewWrite from "../Reviews/ReviewWrite";
import { useAPI } from "../../utils/useAPI";
import ReviewRatingsDisplay from "../Reviews/ReviewRatingsDisplay";
import DialogMessageBox from "../DialogMessageBox";
import TopicAttendees from "../../pages/course/course-attendees";
import TopicTimer from "./TopicTimer";
import CourseSimilar from "./CourseSimilar";
import LoginDialog from "../Account/LoginDialog";
import TimeTable from "./TimeTable";
import { api as apiproto } from "../../apiproto";
import ScheduleBookDialog from "../../pages/course/schedulebook";
import TopicShare from "../../pages/course/topicshare";
import { tdrAlert, tdrLog } from "../../utils/utils";
import TimeTablePopover from "../../pages/course/timetabledialog";
import DeleteIcon from '@mui/icons-material/Delete';
import TopicSocial from "../../pages/course/topicsocial";
import ChatDialog from "../../pages/course/chatdialog";
import EmailIcon from '@mui/icons-material/Email';
import { VideoStartButton } from "../conference/VideoStartButton";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TopicDetails = ({ topicInfo, topicrooms, isFetched, refreshCallback, refresh }: { topicInfo: apiproto.ITopic | null | undefined, topicrooms: React.ReactNode, isFetched: boolean, refreshCallback: () => void | null, refresh: number }) => {

  const user = useUser();
  const api = useAPI();
  const navigate = useNavigate();
  //  const prevlocation = useLocation();
  //const videoContext = useContext(VideoContext);
  // const { showVideo } = videoContext;
  const [scheduleCards, setScheduleCards] = useState<IScheduleCard[] | null>(null);
  const [scheduleEnrolledCards, setScheduleEnrolledCards] = useState<IScheduleCard[]>([]);
  const [topicOwners, setTopicOwners] = useState<apiproto.IUser[]>([]);
  const [userOwner, setUserOwner] = useState(false);
  const [nextSchedule, setNextSchedule] = useState<IScheduleCard | null>(null);
  const [userInfo, setUserInfo] = useState<apiproto.IUser | null>(null);
  const [timer, setTimer] = useState(0);
  var myTimer: ReturnType<typeof setTimeout> | null = null;
  const [isPublish, setPublish] = useState("private");
  //Member Popovers
  const [topicPhoto, setTopicPhoto] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const [attendeeOpen, setAttendeeOpen] = useState(false);
  const [similarTopics, setSimilarTopics] = useState<apiproto.ITopic[] | null>(null);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [topicPublishOpen, setTopicPublishOpen] = useState(false);
  const [topicSocialOpen, setTopicSocialOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [timeTableOpen, setTimeTableOpen] = useState(false);
  const [activeScheduleforTimeTable, setActiveScheduleforTimeTable] = useState<IScheduleCard | null>(null);
  const [scheduleBookOpen, setScheduleBookOpen] = useState(false);
  const [scheduleCardBooked, setScheduleCardBooked] = useState<IScheduleCard | null>(null);
  const [priceStr, setPriceStr] = useState("");
  const [topicMeta, setCourseMeta] = useState({ summary: "", description: "", prerequisites: "", supply_list: "", assignments: "", time_commitment: "" });
  const [userRating, setUserRating] = useState<IUserRating>({ averageScore: 0, userRatingsCount: [0, 0, 0, 0, 0] });
  const [attendeesObjectId, setAttendeesObjectId] = useState<string | null>(null);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  //const [refresh, setRefresh] = useState(1);
  const [searchParams] = useSearchParams();
  const search = useLocation().search;
  const [activeInvitedScheduleCard, setActiveInvitedScheduleCard] = useState<IScheduleCard | null>(null);
  const [shortTimeZone, setShortTimeZone] = useState(getShortTimeZone());
  const [scheduleCardRange, setScheduleCardRange] = useState({ first: 0, last: 0, pagenum: 0 });
  const maxCardPerScroll = 25;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreActionMenuClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 260,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

  useEffect(() => {
    // If there is a query perimeter for invite, we try to find the schedule card that match
    // and open up booking screen.
    const inviteparam = searchParams.get('invite');
    if (inviteparam && inviteparam !== null) {
      let invite = window.decodeURIComponent(inviteparam);
      if (invite !== null && scheduleCards && scheduleCards?.length > 0) {
        let inviteScheduleCard = [];
        if (topicInfo?.singleSession !== null) {
          inviteScheduleCard = scheduleCards.filter((m) => m.timeId === invite)
        }
        else {
          inviteScheduleCard = scheduleCards.filter((m) => m.roomId === invite)
        }
        if (inviteScheduleCard && inviteScheduleCard.length > 0) {
          subscribeSession(inviteScheduleCard[0]);
        }
      }
    }
    let id = searchParams.get('id');
    if (id !== null) {
      id = window.decodeURIComponent(id);
      if (id !== null && scheduleCards && scheduleCards?.length > 0) {
        // We try to find the card that was the invited schedule
        let invitedScheduleCard = null;
        if (topicInfo?.singleSession !== null) {
          invitedScheduleCard = scheduleCards.filter((m) => m.timeId === id)
        }
        else {
          invitedScheduleCard = scheduleCards.filter((m) => m.roomId === id)
        }
        if (invitedScheduleCard !== null && invitedScheduleCard.length > 0) {
          setScheduleCards(invitedScheduleCard);
          setActiveInvitedScheduleCard(invitedScheduleCard[0]);
        }
      }
    }
    else {
      setActiveInvitedScheduleCard(null);
    }

    // Set to calendar view by default if it is single session
    // We do not default if there is an invite or id because we want to display one single card
    if (topicInfo?.singleSession !== null && id === null && inviteparam === null) {
      setTimeTableTypeView("calendar");
    }
    setShortTimeZone(getProfileTimeZoneStr(false));
    var moment = require('moment-timezone');
    tdrLog("Time zone" + getTimeZone() + " " + moment().tz(getTimeZone()).zoneAbbr())
    // Set the card to render from 0 to some fixed default
    setScheduleCardRange({ first: 0, last: Math.min(maxCardPerScroll, (scheduleCards ? scheduleCards?.length : 0)), pagenum: 0 })
  }, [searchParams, scheduleCards?.length])
  const updateInfoTimeout = () => {
    // First figure out how often need to timeout without needing to keep updating
    if (!user.authenticated) return;
    var newInterval = 60 * 60 * 1000;
    var scheduleCard: IScheduleCard | null | undefined = null;
    if (userOwner) {
      if (scheduleCards) {
        scheduleCard = computeNextSessionToStart(scheduleCards);
      }
    }
    else {
      if (scheduleEnrolledCards) {
        scheduleCard = computeNextSessionToStart(scheduleEnrolledCards);
      }
    }
    if (scheduleCard) {
      // Sometimes there is no schedule card because there is no next session to start!
      const now = Date.now();
      var timedifference = (scheduleCard.starttime as number) - now;

      if (timedifference < 0) {
        timedifference = now - (scheduleCard.starttime as number);

      }
      setCountdownValues(timedifference);
      if (timedifference > 0) {
        if (timedifference < 15 * 60 * 1000) {
          newInterval = 1000; // if < 15min update every second
        }
        else if (timedifference < 2 * 60 * 60 * 1000) {
          newInterval = 60 * 1000; // if < 2 hr update every min
        }
        else {
          newInterval = 60 * 60 * 1000; // update every hour
        }
      }
      else {
        // Session has started
        newInterval = 60 * 1000 // update every min
      }

    }
    if (myTimer) {
      clearTimeout(myTimer);
    }
    myTimer = setTimeout(() => {
      tdrLog("setting timer..." + timedifference);
      setTimer((prevState) => (
        prevState + 1))
    }, newInterval);

    return newInterval; // This tells the client how often to count and not unnecessarily just update every minute.

  }


  /******************************************************* 
  * 
  * Dialog box section for handling actions confirmation
  * 
  * *****************************************************/
  const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });

  const clearDialog = () => {
    setDialogMessage({ title: '', message: '', meta: '', confirm: true });

  }


  const handleCancel = () => {
    clearDialog();
    refreshTopicDetails();
    if (dialogMessage.meta) {
      const postAction: any = dialogMessage.meta;
      if (postAction.action === 1) {
        navigate("/sessions");
      }
    }
  }

  const updateSchedule = () => {
    if (isProfilePage) {
      navigate('/createtopic/' + window.encodeURIComponent("c_" + userInfo?.userId));
    }
    else {
      navigate("/createtopic/" + topicInfo?.topicId);

    }
  }

  const editTopic = (topicid: string) => {
    handleMoreActionMenuClose();
    if (checkIfTopicProfile(topicid)) {
      navigate("/profile");
    }
    else {
      navigate("/createtopic/" + window.encodeURIComponent(topicid));
    }
  }

  const publishTopic = async (courseid: string) => {
    handleMoreActionMenuClose();
    setTopicPublishOpen(true);
  }

  const shareTopicSocial = async (courseid: string) => {
    setTopicSocialOpen(true);
  }

  const handleSocialTopicClose = (status: string) => {
    setTopicSocialOpen(false);
  }

  const openChatBox = () => {
    setChatOpen(true);
  }

  const handleChatDialogClose = (status: string) => {
    setChatOpen(false);
  }


  const handlePublishTopicClose = (status: string) => {
    // Refresh State
    refreshTopicDetails();
    setTopicPublishOpen(false);
    if (status === "private") {
      setDialogMessage({ title: 'Topic Page is Private', message: 'Your topic page is now private. No one can access your page.', meta: {}, confirm: false });
    }
    else if (status === "restricted") {
      setDialogMessage({ title: 'Topic Page Published', message: 'Your topic page is now published. You can share the link to your customers and they can start reserving a time with you.', meta: {}, confirm: false });
    }
    else if (status === "inviteonly") {
      setDialogMessage({ title: 'Topic Page Share', message: 'Your topic page is now available to the users you have invited.', meta: {}, confirm: false });
    }
    else if (status === "public") {
      setDialogMessage({ title: 'Topic Page Published', message: 'Your topic page is now published. Your link is available to anyone you shared with and it will also be searchable on search engines and on our marketplace.', meta: {}, confirm: false });
    }
    else if (status === "error") {
      setDialogMessage({ title: 'Topic Page Error', message: 'An error occurred changing the settings of your topic page.', meta: {}, confirm: false });
    }
    else if (status === "paymentnotsetup") {
      setDialogMessage({ title: 'Payment setup', message: 'Before you can publish a paid topic, you need to configure your payment information. This allows your topic page to accept credit card payment from your client and deposit earnings into your account. Go to Account->Profile->Payments to configure.', meta: {}, confirm: false });
    }

  }


  const approveTopic = async (topicparam: apiproto.ITopic) => {
    const topic = { ...topicparam };
    try {
      if (topic.allowSearchable) {
        topic.allowSearchable = false;
        topic.pendingReview = false;
      }
      else {
        // If searchable is required, we set allowSearchable = true if approve
        topic.pendingReview = false;
        topic.allowSearchable = true;
      }
      await api.updateTopic({ allowSearchable: true, pendingReview: true }, topic, true);
      refreshTopicDetails();

    }
    catch (err: any) {
      tdrAlert(err.message);
      //      tdrLog(err);
    }
  }

  const subscribeSession = async (scheduleCard: IScheduleCard) => {
    setScheduleCardBooked(scheduleCard);
    // If time is in the past and not owner, we don't allow booking.
    const currTime = (new Date()).getTime();
    if (!userOwner && scheduleCard.endtime && scheduleCard.endtime < currTime) {
      setDialogMessage({ title: 'Unavailable to book', message: 'You cannot book a session that had already ended.', meta: {}, confirm: false });
      return;
    }

    if (!user.authenticated) {
      setLoginDialogOpen(true);
      return;
    }
    // We give more privilege to a user who may want to invite users beyond the max participants.
    if (!userOwner && topicInfo && topicInfo.maxParticipants) {
      if (scheduleCard.enrolled) {
        setDialogMessage({ title: 'Enrolled', message: 'You are enrolled for the session. Do you want to join the video session?', meta: { scheduleCard: scheduleCard }, confirm: true });
        return;
      }
      else if (scheduleCard.membersize >= topicInfo?.maxParticipants) {
        setDialogMessage({ title: 'Fully booked', message: 'The time for this session is fully booked. Please check other times.', meta: scheduleCard, confirm: false });
        return;
      }

    }
    setScheduleBookOpen(true);
  }

  const subscribeSessionFromTimeSlot = (time: ExtendedTime) => {
    if (scheduleCards != null && scheduleCards.length > 0) {
      if (topicInfo?.singleSession !== null) {
        const scheduleCard = scheduleCards.filter((m) => m.timeId === time.timeId);
        if (scheduleCard === null || scheduleCard.length === 0) return;
        subscribeSession(scheduleCard[0]);
      }
      else {
        const scheduleCard = scheduleCards.filter((m) => m.roomId === time.roomid);
        if (scheduleCard === null || scheduleCard.length === 0) return;
        subscribeSession(scheduleCard[0]);
      }
    }
  }

  const showTimeTable = (scheduleCard: IScheduleCard) => {
    setTimeTableOpen(true);
    setActiveScheduleforTimeTable(scheduleCard);
  }

  const closeTimeTable = () => {
    setTimeTableOpen(false);
    refreshTopicDetails();
  }
  /******************************************************* 
  * 
  * Start Video Conferencing Part and checking
  * 
  * *****************************************************/

  /*
  const downloadCalendarFile = async (topicId: string, roomIdOrTimeId: string) => {
    var result: apiproto.IDownloadCalendarResponse
    if (roomIdOrTimeId.startsWith('r_')) {
      result = await api.downloadCalendarForRoom(topicId, roomIdOrTimeId)
    } else {
      result = await api.downloadCalendarForTime(topicId, roomIdOrTimeId)
    }
    const blob = new Blob([result.calendarData!])
    const newUrl = URL.createObjectURL(blob)
    const element = document.createElement("a");
    element.href = newUrl;
    element.download = "newCalendar.ics";
    document.body.appendChild(element);
    element.click();
  }
*/
  /* Example of how to use the calendar download function
if (topicInfo?.topicId && scheduleCard.roomId) {
  downloadCalendarFile(topicInfo?.topicId, scheduleCard.roomId)
} else if (topicInfo?.topicId && scheduleCard.timeId) {
  downloadCalendarFile(topicInfo?.topicId, scheduleCard.timeId)
}*/


  const cancelSession = (scheduleCard: IScheduleCard) => {
    // Check if session has already started
    const currTime = (new Date()).getTime();
    if (topicInfo?.singleSession && (scheduleCard.starttime < currTime)) {
      // This is single session that started in the past
      setDialogMessage({ title: 'Cancel Session', message: 'You cannot cancel a session that has already started in the past.', meta: { cancelSchedule: scheduleCard }, confirm: false });
    }
    else if (scheduleCard.endtime < scheduleCard.starttime) {
      setDialogMessage({ title: 'Cancel Session', message: 'You cannot cancel sessions that have already started in the past.', meta: { cancelSchedule: scheduleCard }, confirm: false });
    }
    else if (topicInfo?.costType === apiproto.CostType.free) {
      setDialogMessage({ title: 'Cancel Free Session', message: 'Are you sure you want to cancel this free session? You are not guaranteed to be able to rejoin the same session again.', meta: { cancelSchedule: scheduleCard }, confirm: true });
    }
    else if (topicInfo?.singleSession) {
      setDialogMessage({ title: 'Cancel Session', message: 'Are you sure you want to cancel this session? If the session has not started, your credit card will be credited with a refund. You are not guaranteed to be able to rejoin the same session again.', meta: { cancelSchedule: scheduleCard }, confirm: true });
    }
    else if (topicInfo?.costType === apiproto.CostType.cost_per_month) {
      setDialogMessage({ title: 'Cancel Session', message: 'Are you sure you want to cancel? If you cancel, your next month subscription will not be charged.', meta: { cancelSchedule: scheduleCard }, confirm: true });
    }
    else if (topicInfo?.costType === apiproto.CostType.cost_all_upfront) {
      setDialogMessage({ title: 'Cancel Session', message: 'Are you sure you want to cancel? If you cancel, you will be refunded for the sessions you have not started.', meta: { cancelSchedule: scheduleCard }, confirm: true });
    }
    else {
      setDialogMessage({ title: 'Cancel Session', message: 'Are you sure you want to cancel this session? If you cancel, you will not be charged for any sessions that have not started.', meta: { cancelSchedule: scheduleCard }, confirm: true });
    }
  }

  const cancelSessionConfirm = async (scheduleCard: IScheduleCard) => {
    clearDialog();
    try {
      if (topicInfo) {
        let cancelResult = null;
        if (topicInfo?.singleSession) {
          cancelResult = await api.leaveTime(scheduleCard.timeId);
        }
        else {
          cancelResult = await api.leaveRoom(scheduleCard.roomId);
        }
        if (cancelResult !== null) {
          //tdrAlert("Canceling " + scheduleCard.timeId + ' ' + JSON.stringify(cancelResult))
          setDialogMessage({ title: 'Session cancelled', message: 'Your session is now cancelled.', meta: {}, confirm: false });
          refreshCallback();
        }
      }
    }
    catch (err: any) {
      // We don't throw this error back since the IDs may have been deleted for some reason
      // and this not necessarily a fatal error.
      if (err.name === "TIME_NOT_AVAILABLE") {
        setDialogMessage({ title: 'Cancel Session Failed', message: 'You are not booked for this session. You cannot cancel a session you are not booked for.', meta: {}, confirm: false });
      }
      else if (err.name === "CANCEL_TIME_ALREADY_STARTED") {
        setDialogMessage({ title: 'Cancel Session Failed', message: 'You cannot cancel a session that has already started.', meta: {}, confirm: false });
      }
      else {
        tdrAlert(err.name + ' ' + err.message);
        tdrLog(err.message);
      }
    }
  }

  const handleConfirm = (meta: any) => {
    if (meta.cancelSchedule) {
      cancelSessionConfirm(meta.cancelSchedule);
    }
    else if (meta.scheduleCard) {
      startSessionConfirm(meta.scheduleCard);
    }
    else if (meta.topicId) {
      deleteItemConfirm(meta.topicId);
    }
  }

  const startSessionConfirm = (scheduleCard: IScheduleCard) => {
    var sessionid = null;
    if (topicInfo?.singleSession && scheduleCard.timeId) {
      sessionid = scheduleCard.timeId;
    }
    else {
      sessionid = scheduleCard.roomId;
    }
    if (sessionid) {
      navigate("/conferences/" + window.encodeURIComponent(sessionid));
    }
    else {
      //TODO
      setDialogMessage({ title: 'Session Error', message: 'There was an error starting your session.', meta: { scheduleCard: scheduleCard }, confirm: true });
    }
  }

  const isTopicPublic = () => {
    if (topicInfo) {
      const status = getTopicShareStatus(topicInfo);
      setPublish(status);
    }
  }

  const getSessionsByUser = async (userId: string) => {
    const allResults = await api.findTopics({ includeDescription: true, reviews: {}, members: { entity: { user: { includeDescription: false, assets: {} } } }, rooms: { scheduleSet: { schedules: { times: {} } } }, tags: {}, assets: {} }, { instructorUserId: userId }, 5);
    if (allResults.topics) {
      setSimilarTopics(allResults.topics);
      setUserRating(calculateUserRatings(allResults.topics));
    }
  }

  const checkIfUserOwner = (userInfo: apiproto.IUser) => {
    if (user && user.authenticated && userInfo && userInfo.membersOf && topicInfo) {
      if (isUserOwnerOfTopic(userInfo, topicInfo)) {
        setUserOwner(true);
        return true
      }
      else {
        setUserOwner(false);
        return false;
      }
    }
    setUserOwner(false);
    return false;
  }


  const checkIfUserMemberOfRoom = (roomMembers: apiproto.IMember[], userInfo: apiproto.IUser) => {
    if (roomMembers.length > 0 && user.authenticated) {
      //tdrLog("Members of " + JSON.stringify(userInfo.membersOf) + " for " + objid);
      const enrolled = roomMembers.filter((m) => (m.accepted && (!m.owner && !m.instructor) && (m.entity?.user?.userId === userInfo.userId)));
      // tdrLog("Enrolled" + JSON.stringify(enrolled));
      tdrLog("Check if usermemberofroom");
      if (enrolled && enrolled.length > 0) {
        return true;
      }
    }
    return false;
  }

  const getCurrentUser = async () => {
    if (user && user.userId) {
      try {
        const userResult = await api.getUser({ reviewsByUser: {}, reviewsOfUser: {}, membersOf: { obj: { room: {}, time: {}, group: {} } } }, { userId: user.userId });
        //tdrLog("Users " + JSON.stringify(userResult))
        //tdrLog("===========")
        //tdrLog(JSON.stringify(userResult))
        //tdrLog("===========")
        if (userResult && userResult.user) {
          const usertmpInfo = userResult.user;
          if (usertmpInfo) {
            setUserInfo(usertmpInfo);
          }
          const admin = isUserMemberOfGroupId(usertmpInfo, "g_admin")
          setIsUserAdmin(admin);
          return usertmpInfo;
        }
      }
      catch (err: any) {
        // We don't throw this error back since the IDs may have been deleted for some reason
        // and this not necessarily a fatal error.
        tdrLog(err.message);
      }
    }
    return null;

  }

  const getOwnerProfile = async (userId: string) => {
    try {
      const userResult = await api.getUser({ assets: {} }, { userId: userId }
      );
      if (userResult && userResult.user) {
        const userInfo = userResult.user;
        setTopicOwners([userInfo!]);
        const userphoto = getUserPhotoURL(userInfo);
        setUserPhoto(userphoto);
      }
    }
    catch (err: any) {
      // We don't throw this error back since the IDs may have been deleted for some reason
      // and this not necessarily a fatal error.
      tdrAlert(err.name + ' ' + err.message);
      tdrLog(err.message);
    }
  }
  const setUpOwnerPage = () => {
    if (topicInfo && topicInfo.members && topicInfo.members.length > 0) {
      const owners = getOwners(topicInfo?.members!);
      const userId = owners[0].entity?.user?.userId!
      // Get the owner
      if (owners && owners.length > 0) {
        getOwnerProfile(userId);
      }
      // Get the sessions
      getSessionsByUser(userId);
    }
  }
  /*  const getInstructors = () => {
      if (topicInfo && topicInfo.members) {
        const owners = getOwners(topicInfo?.members!);
        if (owners && owners.length > 0) {
          getUserProfile(owners[0].entity?.user?.userId!);
        }
      }
    }
  */
  const scheduleTotalSessions = (scheduleCards: apiproto.ISchedule[]) => {
    var total = 0;
    scheduleCards.map((schedule: apiproto.ISchedule) => {
      total += schedule.times!.length;
      return total;

    });
    return total;
  }


  const computeNextSessionToStart = (schedules: IScheduleCard[]) => {
    const now = Date.now();
    for (let schedule of schedules) {
      if (schedule.roomId !== 'unavailable' && (schedule.starttime > now || now < schedule.endtime)) {
        const cloneschedule = { ...schedule };
        setNextSchedule(cloneschedule);
        return schedule;
      }
    }
  }

  const scheduleOneTimeComputation = (userinfo: apiproto.IUser | null) => {
    // This is for one time sessions
    // We show up to 6 hours ago
    tdrLog('One Time Computation MembersOf:' + JSON.stringify(userInfo?.membersOf));
    const currentTime = Date.now() - 24 * 60 * 60 * 1000;
    var finalschedules: IScheduleCard[] = [];
    topicInfo?.rooms?.forEach((troom: apiproto.IRoom) => {
      troom?.scheduleSet?.schedules?.forEach((schedule: apiproto.ISchedule) => {
        schedule?.times?.forEach((time: apiproto.ITime) => {
          var timeroomid = ''
          if (time.roomId && time.roomId !== troom.roomId && time.roomId?.length > 0) {
            // TODO - why does this even happen????
            timeroomid = 'unavailable'
            tdrLog("&&&&&&&&" + computeEpochtoPrettyDateStr(time.startTime!.toString(), 'ddd MMM Do yy') + time.roomId + "&" + time.timeId + " , " + troom.roomId)
          }
          if (time.endTime! > currentTime) {
            // One Time - we just combine all the schedules
            var enrolled = false;
            if (userinfo && user.authenticated && time.members && checkIfUserEnrolledInRoom(time.timeId!, troom.roomId!, userinfo)) {
              enrolled = true;
            }
            const scheduleCard: IScheduleCard = {
              roomId: timeroomid,
              timeId: time.timeId!,
              starttime: time.startTime!,
              endtime: time.endTime!,
              schedules: [], // Not needed,so we don't waste space here 
              sessions: finalschedules.length,
              duration: schedule.duration!,
              membersize: (time) ? time.memberCount! : 0,
              enrolled: enrolled
            }
            finalschedules.push(scheduleCard);
          }
        }
        )
      })
      finalschedules = finalschedules.sort((a, b) => a.starttime! > b.starttime! ? 1 : -1)
    })
    setScheduleCards(finalschedules);
    const enrolledSchedule = finalschedules.filter((m) => m.enrolled === true)
    if (enrolledSchedule && enrolledSchedule.length > 0) {
      setScheduleEnrolledCards(enrolledSchedule);
    }
    let isUserOwner = false;
    if (userinfo !== null) {
      isUserOwner = checkIfUserOwner(userinfo);
    }
    if (isUserOwner) {
      computeNextSessionToStart(finalschedules);
    }
    else {
      computeNextSessionToStart(enrolledSchedule);
    }
  }

  const scheduleOngoingComputation = (userinfo: apiproto.IUser | null) => {
    // This is for one time sessions
    var finalschedules: IScheduleCard[] = [];
    let scheduleCard: IScheduleCard | null = null;
    // We give a lee way of 6 hours for started in case users still need to be able to see the session to start the video if the session runs over 
    const currTime = (new Date()).getTime() - 6 * 60 * 60 * 1000;
    topicInfo?.rooms?.forEach((troom: apiproto.IRoom) => {
      var enrolled = false;
      var ongoingMinRoom = { roomid: troom.roomId, minStartTime: Number.MAX_SAFE_INTEGER, minEndTime: Number.MAX_SAFE_INTEGER };
      const members = ((troom && troom.members) ? getRegularTopicMembers(troom?.members) : []);
      if (userinfo && members && checkIfUserEnrolledInRoom(null, troom?.roomId!, userinfo)) {
        enrolled = true;
      }
      troom?.scheduleSet?.schedules?.forEach((schedule: apiproto.ISchedule) => {
        schedule?.times?.forEach((time: apiproto.ITime) => {
          // Find the first start time for the room
          if (time.startTime && time.startTime < ongoingMinRoom.minStartTime && time.endTime && time.endTime > currTime) {
            ongoingMinRoom.minStartTime = Number(time.startTime);
            ongoingMinRoom.minEndTime = Number(time.endTime);
            ongoingMinRoom.roomid = troom.roomId;
          }
        })
        scheduleCard = {
          roomId: ongoingMinRoom.roomid!,
          timeId: "",
          starttime: Number(ongoingMinRoom.minStartTime!),
          endtime: Number(ongoingMinRoom.minEndTime!),
          schedules: troom.scheduleSet?.schedules!, // Not needed,so we don't waste space here 
          sessions: finalschedules.length,
          duration: schedule.duration!,
          membersize: members.length,
          enrolled: enrolled
        }

      })
      if (scheduleCard !== null) {
        finalschedules.push(scheduleCard);
      }

    })
    finalschedules = finalschedules.sort((a, b) => a.starttime! > b.starttime! ? 1 : -1)
    setScheduleCards(finalschedules);
    const enrolledSchedule = finalschedules.filter((m) => m.enrolled === true)
    if (enrolledSchedule && enrolledSchedule.length > 0) {
      setScheduleEnrolledCards(enrolledSchedule);
    }
    if (userOwner) {
      computeNextSessionToStart(finalschedules);
    }
    else {
      computeNextSessionToStart(enrolledSchedule);
    }

  }


  const schedulePerRoomComputation = (userinfo: apiproto.IUser | null) => {
    var finalschedules: apiproto.ITime[] = [];
    var tmpscheduleCards: IScheduleCard[] = [];
    var enrolled = false;

    topicInfo?.rooms?.forEach((troom: apiproto.IRoom) => {
      finalschedules = [];
      enrolled = false;
      tdrLog("SchedulePerRoomComputation");
      //      tdrLog("Room members " + JSON.stringify(troom.members));
      const members = ((troom && troom.members) ? getRegularTopicMembers(troom?.members) : []);
      if (userinfo && members && checkIfUserMemberOfRoom(members, userinfo)) {
        enrolled = true;
      }
      troom?.scheduleSet?.schedules?.forEach((schedule: apiproto.ISchedule) => {
        schedule?.times?.forEach((classtime: apiproto.ITime) => {
          finalschedules = [...finalschedules, classtime];

        })
      })
      finalschedules = finalschedules.sort((a, b) => a.startTime! > b.startTime! ? 1 : -1)

      if (finalschedules && finalschedules.length > 0) {
        const scheduleCard: IScheduleCard = {
          roomId: troom.roomId!,
          timeId: finalschedules[0].timeId!,
          starttime: finalschedules[0].startTime!,
          endtime: finalschedules[finalschedules.length - 1].endTime!,
          schedules: troom.scheduleSet?.schedules!,
          sessions: finalschedules.length,
          duration: 0,
          membersize: members.length,
          enrolled: enrolled
        }
        tmpscheduleCards.push(scheduleCard);
      }
    })
    setScheduleCards(tmpscheduleCards);
    const enrolledSchedule = tmpscheduleCards.filter((m) => m.enrolled === true)
    if (enrolledSchedule && enrolledSchedule.length > 0) {
      setScheduleEnrolledCards(enrolledSchedule);
    }
    if (userOwner) {
      computeNextSessionToStart(tmpscheduleCards);
    }
    else {
      computeNextSessionToStart(enrolledSchedule);
    }

  }



  const computeScheduleCards = (tmpuserinfo: (apiproto.IUser | null)) => {
    if (topicInfo?.singleSession) {
      scheduleOneTimeComputation(tmpuserinfo);
    }
    else if (topicInfo?.ongoingSession) {
      scheduleOngoingComputation(tmpuserinfo);
    }
    else {
      schedulePerRoomComputation(tmpuserinfo)
    }

  }

  const setCountdownValues = (countDown: number) => {
    // calculate time left
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    return [days, hours, minutes, seconds];
  };

  const viewAttendees = (event: MouseEvent<HTMLElement>) => {
    //        setTempFilter({ ...searchFilter });
    handleMoreActionMenuClose();
    setAttendeesObjectId(null);
    if (!user.authenticated) {
      setLoginDialogOpen(true);
      return;
    }
    setAttendeeOpen(true);
  };

  const viewAttendeesDetail = (scheduleCard: IScheduleCard) => {
    if (topicInfo && topicInfo.singleSession !== null) {
      setAttendeesObjectId(scheduleCard.timeId!);
    }
    else {
      setAttendeesObjectId(scheduleCard.roomId!);
    }
    setAttendeeOpen(true);
  };
  const [timetableTypeView, setTimeTableTypeView] = React.useState('card');

  const handleTimeTableViewChange = (
    event: React.MouseEvent<HTMLElement>,
    viewType: string,
  ) => {
    if (viewType !== null) {
      setTimeTableTypeView(viewType);
    }
  };

  const handleCloseAttendee = () => {
    setAttendeeOpen(false);
    refreshTopicDetails();
  };

  const handleCloseLoginDialog = () => {
    // First close the login dialog
    setLoginDialogOpen(false);
    if (scheduleCardBooked !== null) {
      if (user.authenticated) {
        subscribeSession(scheduleCardBooked);
        return;
      }
      setScheduleCardBooked(null);
    }
  };
  const handleScheduleBookClose = () => {
    refreshTopicDetails();
    setScheduleBookOpen(false);
  };

  const handleScheduleBookDone = () => {
  };

  const refreshTopicDetails = () => {
    refreshCallback();
    //setRefresh(refresh + 1);
  }

  const deleteItemConfirm = async (topicId: string) => {
    setDialogMessage({ title: '', message: '', meta: '', confirm: true });
    try {
      const deleteResult = await api.deleteTopic(topicId);
      if (deleteResult !== null) {
        setDialogMessage({ title: 'Topic deleted', message: 'Your topic is now deleted. All bookings have been deleted and payments cancelled or refunded.', meta: { action: 1 }, confirm: false });
      }
    }
    catch (err: any) {
      setDialogMessage({ title: 'Delete Error', message: 'An error occurred when deleting the topic', meta: '', confirm: false });
    }
  }

  const deleteItem = async (topicId: string) => {
    handleMoreActionMenuClose();
    setDialogMessage({ title: 'Delete Topic', message: 'Are you sure you want to delete this topic? Everything will be permanently deleted and payments and sessions will be cancelled and deleted.', meta: { topicId: topicId }, confirm: true });
  }


  const CardStatusInfo = ({ topicInfo, scheduleCard }: { topicInfo: apiproto.ITopic, scheduleCard: IScheduleCard }) => {
    return (
      <Fragment>
        {userOwner ?
          <div>
            {scheduleCard.roomId === 'unavailable' ? 'Time reserved for other rooms' : <div>
              <div>
                {scheduleCard.membersize > 0 ? <Link to="#" onClick={() => { viewAttendeesDetail(scheduleCard) }}>(View Attendees)</Link> : <br />}
              </div><br />
              {scheduleCard.membersize >= topicInfo.maxParticipants! ? <div className="divRedBold">Fully booked</div> : <br />}
              <br />
              <VideoStartButton scheduleCard={scheduleCard} topicInfo={topicInfo} />
              <br />
              {(topicInfo.cost === 0 || topicInfo.costType === apiproto.CostType.free) ?
                <Button disabled={false} size="small" sx={{ align: "center", marginTop: 1, width: 180, height: 40 }} variant="contained" onClick={() => { subscribeSession(scheduleCard) }}>Invite
                </Button> : ""}
            </div>
            }
          </div> :
          <div>
            {/*Enrolled */}
            {scheduleCard.enrolled ?
              <div>
                <VideoStartButton scheduleCard={scheduleCard} topicInfo={topicInfo} />
              </div> :
              <Fragment>
                {activeInvitedScheduleCard === null ?
                  <Button disabled={(scheduleCard.membersize >= topicInfo.maxParticipants! || scheduleCard.enrolled) || scheduleCard.roomId === 'unavailable'} size="small" sx={{ align: "center", width: 180, height: 40 }} variant="contained" onClick={() => { subscribeSession(scheduleCard) }}>
                    {scheduleCard.roomId === 'unavailable' ? 'Unavailable' : (scheduleCard.enrolled ? "Enrolled" : (scheduleCard.membersize >= topicInfo.maxParticipants! ? "Fully booked" : (topicInfo.costType === apiproto.CostType.cost_per_month || topicInfo.costType === apiproto.CostType.cost_per_time_charged_as_you_go) ? "Subscribe" : "Book"))}</Button>
                  : <div><VideoStartButton scheduleCard={scheduleCard} topicInfo={topicInfo} /></div>}
              </Fragment>
            }

            {/* Cancel option if enrolled */}
            {scheduleCard.enrolled ?
              <Button hidden={!scheduleCard.enrolled} size="small" sx={{ align: "center", height: 50, width: 180, color: 'gray' }} variant="text" onClick={() => { cancelSession(scheduleCard) }}>
                Cancel session
              </Button> :
              <Box sx={{ height: 50 }}></Box>
            }

          </div>
        }
      </Fragment>

    );
  }

  const cardRangeNext = () => {
    cardRangeMove(true);
  }
  const cardRangePrev = () => {
    cardRangeMove(false);
  }

  const cardRangeMove = (next: boolean) => {
    if (!scheduleCards) {
      return;
    }
    if (scheduleCardRange.last > scheduleCards?.length) {
      return;
    }
    let pagenum = scheduleCardRange.pagenum;
    if (next) {
      pagenum++;
    }
    else {
      pagenum--;
    }
    if (pagenum < 0) pagenum = 0;
    if (pagenum > (scheduleCards.length / maxCardPerScroll)) {
      pagenum = scheduleCards.length / maxCardPerScroll;
    }
    let startIndex = pagenum * maxCardPerScroll;
    let endIndex = startIndex + maxCardPerScroll;
    //  if (startIndex < 0) {
    startIndex = 0;
    //}
    if (endIndex >= scheduleCards.length) {
      endIndex = scheduleCards.length;
    }
    setScheduleCardRange({ first: startIndex, last: endIndex, pagenum: pagenum });

  }

  useEffect(() => {
    updateInfoTimeout();
  }, [user.authenticated]);

  useEffect(() => {
    if (topicInfo && topicInfo.members && topicInfo.members.length > 0) {
      setUpOwnerPage();
    }
  }, [topicInfo?.members?.length]);

  useEffect(() => {
    if (topicInfo?.topicId === null) {
      return;
    }
    if (checkIfTopicProfile(topicInfo?.topicId!)) {
      setIsProfilePage(true);
    }
    const name = new URLSearchParams(search).get('payment_intent');
    if (name !== null) {
      setScheduleBookOpen(true);
    }
    setTopicPhoto(getTopicPhotoURL(topicInfo!));
    var price = getPriceStr(topicInfo?.cost, topicInfo?.costType, topicInfo?.currency);
    setPriceStr(price);
    isTopicPublic();
    try {
      var info = JSON.parse(topicInfo?.description!);
      if (checkIfTopicProfile(topicInfo?.topicId!)) {
        // This is actually the topic for a profile page since we are reusing topic for profiles for times.
        setTopicPhoto(info.photo);
      }
      setCourseMeta(info);
    }
    catch (err: any) {
      // TODO
      // Not much you can do - just don't display information
      //      tdrAlert(err.message);
    }
    getCurrentUser().then(tmpuserinfo => {
      if (tmpuserinfo) {
        if (topicInfo && topicInfo.members && tmpuserinfo) {
          checkIfUserOwner(tmpuserinfo);
        }
      }
      // If no login user, then we will not know if person if enrolled
      computeScheduleCards(tmpuserinfo);
    });
  }, [topicInfo?.topicId, refresh]);

  return (
    <Fragment>
      <LoginDialog popopen={loginDialogOpen} onClose={handleCloseLoginDialog} logintype={'signup'} />
      <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Dismiss" : "Dismiss"} onCancel={handleCancel} onConfirm={handleConfirm} visible={dialogMessage.title.length > 0} />
      {(user.authenticated && scheduleBookOpen) ?
        <ScheduleBookDialog topic={topicInfo} topicOwners={topicOwners} scheduleCard={scheduleCardBooked} userRating={userRating} visible={scheduleBookOpen} onCancel={handleScheduleBookClose} onConfirm={handleScheduleBookDone} /> : ""}
      <TopicShare topic={topicInfo} owner={userInfo} visible={topicPublishOpen} callback={handlePublishTopicClose} />
      <TopicSocial topic={topicInfo} owner={userInfo} visible={topicSocialOpen} callback={handleSocialTopicClose} />
      <TimeTablePopover topic={topicInfo!} roomid={activeScheduleforTimeTable?.roomId ? activeScheduleforTimeTable?.roomId : null} schedule={null} visible={timeTableOpen} onCancel={closeTimeTable} onConfirm={handleCancel} onCallback={subscribeSessionFromTimeSlot} listingType={true} />
      {chatOpen ? <ChatDialog recipientId={topicOwners[0].userId ? topicOwners[0].userId : null} visible={chatOpen} callback={handleChatDialogClose} /> : ""}

      <section id="course-details" >
        {isPublish === "private" &&
          <div style={{ textAlign: 'center', width: '100%', padding: '30px', minHeight: '50px', backgroundColor: '#e1f1fd' }}>This page is set to private. When you finish setting up, you need to publish it to make it accessible to others. <Button sx={{ marginLeft: '20px' }} variant="outlined" onClick={() => (publishTopic(topicInfo?.topicId!))}>Publish</Button> </div>
        }
        <div className="container">

          <TopicAttendees courseid={topicInfo?.topicId!} popopen={attendeeOpen} onClose={handleCloseAttendee} objectid={attendeesObjectId} />
          <div className="row">
            {/* COURSE DESCRIPTION */}
            <div className="col-lg-8">
              <div className="course-txt pe-30">
                {/* Course Title */}
                <h3 className="h3-sm">
                  {isFetched ?
                    (topicInfo ? topicInfo.name : "Course not found") : <Skeleton variant="rectangular" sx={{ width: '80%', height: 30 }} />}
                </h3>
                {/* Person owner*/}
                <div>
                  {topicOwners && topicOwners.length > 0 &&
                    (
                      <div className="course-short-data">
                        <Row>
                          <Col>
                            <Row>
                              <Col style={{ border: 3, borderColor: "black" }}>
                                <Avatar sx={{ mr: 2, float: 'left' }} alt={topicOwners[0] ? topicOwners[0].firstName! : ""} src={userPhoto} />
                                Created by:                         <Link to=
                                  {generateProfileURL(topicOwners[0].firstName!, topicOwners[0].lastName!, topicOwners[0].userId!)}>{topicOwners[0] ? topicOwners[0].firstName! + ' ' + topicOwners[0].lastName! : ""}</Link>
                                {/* Course Rating */}
                                <br /> {topicInfo?.reviews ?
                                  <ReviewRatingsDisplay ratingsAverage={topicInfo?.ratingsAverage} ratingsCount={topicInfo?.ratingsCount} reviews={null} bar={false} /> : <Skeleton variant="rectangular" sx={{ width: 250, height: 30 }} />}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button
                              hidden={true}
                              startIcon={<BookmarkAddIcon />}
                              sx={{ mr: 1, float: 'right' }} size="small" variant="outlined"
                              color="primary" aria-label="save topic" component="span" onClick={() => {

                              }}>
                              Save
                            </Button>
                            <Button
                              hidden={(userOwner || !user.authenticated) ? true : false}
                              startIcon={<EmailIcon />}
                              variant="outlined"
                              sx={{ mr: 1, float: 'right' }} size="small"
                              color="primary" aria-label="save topic" component="span" onClick={openChatBox}>
                              Message
                            </Button>
                            {isPublish !== "private" &&
                              <Button
                                startIcon={<IosShareIcon />}
                                sx={{ mr: 1, float: 'right' }} size="small" variant="outlined"
                                color="primary" aria-label="share topic" component="span" onClick={() => (shareTopicSocial(topicInfo?.topicId!))}>
                                Share
                              </Button>
                            }
                            {userOwner &&
                              <Fragment>
                                <Button
                                  size="small"
                                  sx={{ float: 'right', mr: 1 }}
                                  id="customized-delete-button"
                                  aria-controls={open ? 'delete-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  variant="outlined"
                                  disableElevation
                                  onClick={handleMenuClick}
                                  endIcon={<ExpandMoreIcon />}
                                >
                                  Manage
                                </Button>
                                <StyledMenu
                                  id="action-menu"
                                  MenuListProps={{
                                    'aria-labelledby': 'customized-delete-button',
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleMoreActionMenuClose}
                                >
                                  <Fragment>
                                    <MenuItem onClick={() => (editTopic(topicInfo?.topicId!))} disableRipple>
                                      <EditIcon />
                                      Edit
                                    </MenuItem>

                                    <MenuItem onClick={viewAttendees} disableRipple>
                                      <GroupIcon />
                                      View Attendees
                                    </MenuItem>
                                    <MenuItem onClick={() => (publishTopic(topicInfo?.topicId!))}>
                                      <PublishIcon />
                                      Publish (Status: {isPublish === "private" ? "Private" : (isPublish === "inviteonly") ? "Invite Only" : (isPublish === "restricted" ? "Restricted" : "Public")})
                                    </MenuItem>
                                    {!isProfilePage &&
                                      <Fragment>
                                        <Divider sx={{ my: 0.5 }} />
                                        <MenuItem onClick={() => (deleteItem(topicInfo?.topicId!))} disableRipple>
                                          <DeleteIcon />
                                          Delete
                                        </MenuItem>
                                      </Fragment>
                                    }
                                  </Fragment>
                                  <Divider sx={{ my: 0.5 }} />
                                  <MenuItem onClick={handleMoreActionMenuClose} disableRipple>
                                    Cancel
                                  </MenuItem>
                                </StyledMenu>
                              </Fragment>
                            }
                          </Col>
                        </Row>
                        <br />
                      </div>
                    )}
                  {/* Course Short Description */}
                  <p className="p-md newline">
                    {isFetched ? topicMeta.summary : <Skeleton variant="rectangular" sx={{ width: '100%', height: 150 }} />}
                  </p>

                </div>
                {/* Show the Schedule */}
                {(scheduleCards && scheduleCards?.length > 0) &&
                  <div className="cs-requirements cd-block">
                    {/* Small Title */}
                    {/* Do not show this is this was an invited ID from an email since we only show one single schedule */}
                    {(activeInvitedScheduleCard === null || (scheduleCards && scheduleCards.length > 0)) ?
                      <Row>
                        <Col xs="auto" lg="auto" md="auto">
                          <h5 className="h5-xl"><b>Available Times</b></h5>
                          {shortTimeZone.length > 0 ? <b>({shortTimeZone})</b> : ""}
                        </Col>
                        <Col>
                          {topicInfo?.singleSession && scheduleCards && scheduleCards!.length > 0 &&
                            <div>
                              <ToggleButtonGroup
                                color="primary"
                                style={{ height: 30, float: 'left', paddingBottom: 5 }}
                                value={timetableTypeView}
                                exclusive
                                onChange={handleTimeTableViewChange}
                                aria-label="Platform"
                              >
                                <ToggleButton value="calendar">Calendar</ToggleButton>
                                <ToggleButton value="card">Card</ToggleButton>
                              </ToggleButtonGroup>
                            </div>
                          }
                        </Col>
                      </Row> : <Row><Col><h5 className="h5-xl"><b>Your Session Information</b></h5></Col></Row>
                    }
                    {timetableTypeView === 'calendar' && topicInfo?.singleSession && scheduleCards && scheduleCards!.length > 0 &&
                      <div className="timeslotBox">
                        {(isFetched) ?
                          <TimeTable topic={topicInfo!} roomid={null} schedule={null} bookingCallback={subscribeSessionFromTimeSlot} calendar={true} forceRefresh={refresh} userInfo={userInfo} refreshCallback={refreshTopicDetails} />
                          : <Skeleton variant="rectangular" sx={{ width: '100%', height: 200 }} />}
                      </div>
                    }
                    {(scheduleCards ? (scheduleCards && scheduleCards!.length === 0) ?
                      <div className="card">
                        <div className="card-header divCenter" role="tab" id="headingFour">
                          <Typography variant="h6" sx={{ textAlign: "center", padding: 3 }}>No available sessions at the moment.</Typography>
                          {userOwner &&
                            <Button size="small" sx={{ marginBottom: 2, height: 40, width: 200 }} variant="contained" onClick={updateSchedule} startIcon={<DateRangeIcon />}>
                              Set Schedule
                            </Button>
                          }
                        </div>
                      </div>
                      :
                      ""
                      : <Skeleton variant="rectangular" sx={{ width: '100%', height: 300 }} />


                    )

                    }
                    <TableContainer
                      sx={{
                        width: 0.95
                      }}
                    >
                      {topicInfo?.singleSession && timetableTypeView === 'card' &&
                        <Table
                          sx={{
                            width: "100%"
                          }}

                        >
                          <TableBody>
                            <TableRow>
                              {(scheduleCards && scheduleCardRange.first > 0) ? <div><Link to="#" onClick={cardRangePrev}>Prev</Link> </div> : ""
                              }

                              {scheduleCards && scheduleCards.map((scheduleCard: IScheduleCard, index) => (
                                (index >= scheduleCardRange.first && index < scheduleCardRange.last) ?
                                  <TableCell align="center" key={'scheduleonetimecard' + index}>
                                    <Card sx={activeInvitedScheduleCard === null ? { borderColor: "black", backgroundColor: "#e5f3f8", width: 245, textAlign: "center" } : { borderColor: "black", backgroundColor: "#e5f3f8", width: '100%', textAlign: "center" }}>
                                      <CardActionArea>
                                        <CardContent>
                                          <Typography gutterBottom variant="h5" component="div">
                                            {computeEpochtoPrettyDateStr(scheduleCard.starttime!.toString(), 'ddd, MMM Do')}
                                          </Typography>
                                          <Typography variant="subtitle1" color="text.primary">
                                            One Time
                                            <br /><br />{computeEpochtoTimeStr(scheduleCard.starttime.toString())} -
                                            {computeEpochtoTimeStr(scheduleCard.endtime!.toString())}
                                            <br />
                                            {secondsToDhms((+scheduleCard.duration! / 1000) as number, false)}  per session
                                          </Typography>
                                          <Typography variant="subtitle1" color={(topicInfo.maxParticipants! > 1 && (topicInfo.maxParticipants! - scheduleCard.membersize) < 4) ? "red" : ""}>
                                            <br />
                                            {scheduleCard.roomId === 'unavailable' ? <div>Unavailable</div> :
                                              <div>{scheduleCard.membersize} / {topicInfo.maxParticipants}<br />
                                                {(topicInfo.maxParticipants! > 1 && (topicInfo.maxParticipants! - scheduleCard.membersize) < 5) ? (topicInfo.maxParticipants! - scheduleCard.membersize) + ((topicInfo.maxParticipants! - scheduleCard.membersize) > 1 ? " spots left" : " spot left") : ""}
                                              </div>}
                                          </Typography>
                                        </CardContent>
                                      </CardActionArea>
                                      <CardActions sx={{ justifyContent: 'center', minWidth: 220, minHeight: 100 }}>
                                        <CardStatusInfo topicInfo={topicInfo} scheduleCard={scheduleCard} />
                                      </CardActions>
                                    </Card>
                                  </TableCell>
                                  : ""
                              ))
                              }
                              {scheduleCards && scheduleCardRange.last < scheduleCards.length &&
                                <TableCell align="center" key={'moreschedule'}>
                                  <Button size="small" sx={{
                                    ':hover': {
                                      bgcolor: '#e5f3f8', // theme.palette.primary.main
                                      color: 'black',
                                    }, align: "center", height: 100, width: 40, marginRight: 2, backgroundColor: "gray"
                                  }} variant="contained" onClick={cardRangeNext} startIcon={<ArrowForwardIosIcon />} />
                                </TableCell>
                              }
                            </TableRow>
                          </TableBody>
                        </Table>
                      }
                      {topicInfo?.ongoingSession &&
                        <Table>
                          <TableBody>
                            <TableRow>
                              {scheduleCards && scheduleCards.map((scheduleCard: IScheduleCard) => (
                                <TableCell align="center" key={'t' + scheduleCard.roomId}>
                                  <Card sx={{ borderColor: "black", backgroundColor: "#e5f3f8", maxWidth: 345, textAlign: "center" }}>
                                    <CardActionArea>
                                      <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                          Next on {computeEpochtoPrettyDateStr(scheduleCard.starttime!.toString(), 'ddd, MMM Do')}
                                        </Typography>
                                        <br />
                                        <Typography variant="subtitle1" color="text.primary">{getFormat(topicInfo!)}</Typography>
                                        <br />

                                        {scheduleCard.schedules.map((schedule: apiproto.ISchedule) => (
                                          <Typography variant="subtitle1" color="text.secondary" key={'ch' + schedule.scheduleId}>
                                            {frequencyString(schedule)}
                                            <br />
                                            {computeEpochtoTimeStr(schedule.startTime!.toString())} -
                                            {computeEpochtoTimeStr((+schedule.startTime! + +schedule.duration!).toString())}
                                            <br />
                                            {secondsToDhms((+schedule.duration! / 1000) as number, false)} per session
                                            <br />
                                            {scheduleCard.schedules.length > 1 ?
                                              schedule.times?.length + " sessions" : ""}
                                            <br /><br />
                                          </Typography>))}
                                        Total: {scheduleTotalSessions(scheduleCard.schedules)} sessions
                                        <br />
                                        {scheduleTotalSessions(scheduleCard.schedules) > 1 ? (<Link to="#" onClick={() => { showTimeTable(scheduleCard) }}>Show All Sessions</Link>) : ""}
                                      </CardContent>
                                    </CardActionArea>
                                    <CardActions sx={{ justifyContent: 'center' }}>
                                      <CardStatusInfo topicInfo={topicInfo} scheduleCard={scheduleCard} />
                                    </CardActions>
                                  </Card>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      }
                      {topicInfo?.multiSession &&
                        <Table>
                          <TableBody>
                            <TableRow>
                              {scheduleCards && scheduleCards.map((scheduleCard: IScheduleCard) => (
                                <TableCell align="center" key={'t' + scheduleCard.roomId}>
                                  <Card sx={{ borderColor: "black", backgroundColor: "#e5f3f8", maxWidth: 345, textAlign: "center" }}>
                                    <CardActionArea>
                                      <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">
                                          {computeEpochtoPrettyDateStr(scheduleCard.starttime!.toString(), 'ddd, MMM Do')}
                                          <br /> to <br />
                                          {computeEpochtoPrettyDateStr(scheduleCard.endtime!.toString(), 'ddd, MMM Do')}
                                        </Typography>
                                        <br />
                                        <Typography variant="subtitle1" color="text.primary">{getFormat(topicInfo!)}</Typography>
                                        <br />

                                        {scheduleCard.schedules.map((schedule: apiproto.ISchedule) => (
                                          <Typography variant="subtitle1" color="text.secondary" key={'sc' + schedule.scheduleId}>
                                            {frequencyString(schedule)}
                                            <br />
                                            {computeEpochtoTimeStr(schedule.startTime!.toString())} -
                                            {computeEpochtoTimeStr(scheduleCard.endtime!.toString())}
                                            <br />
                                            {secondsToDhms((+schedule.duration! / 1000) as number, false)} per session
                                            <br />
                                            {scheduleCard.schedules.length > 1 ?
                                              schedule.times?.length + " sessions" : ""}
                                            <br /><br />
                                          </Typography>))}
                                        Total: {scheduleTotalSessions(scheduleCard.schedules)} sessions
                                        <br />
                                        {scheduleTotalSessions(scheduleCard.schedules) > 1 ? (<Link to="#" onClick={() => { showTimeTable(scheduleCard) }}>Show All Sessions</Link>) : ""}
                                      </CardContent>
                                    </CardActionArea>
                                    <CardActions sx={{ justifyContent: 'center' }}>
                                      <CardStatusInfo topicInfo={topicInfo} scheduleCard={scheduleCard} />
                                    </CardActions>
                                  </Card>
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableBody>
                        </Table>
                      }
                    </TableContainer>
                  </div>
                }

                {/* COURSE DESCRIPTION */}
                {topicMeta.description && topicMeta.description.length > 0 &&
                  <div className="cs-requirements cd-block">
                    {/* Small Title */}
                    <h5 className="h5-xl"><b>Description</b></h5>
                    {/* Text */}
                    <p className="newline">
                      {isFetched ? topicMeta.description : <Skeleton variant="rectangular" sx={{ width: '100%', height: 200 }} />}
                    </p>
                  </div>
                }
                {/* END COURSE DESCRIPTION */}

                {/* COURSE CONTENT */}
                <div className="cs-content cd-block">
                  {/* Small Title */}
                  {/* ACCORDION */}
                  {isFetched ?
                    <Accordion
                      defaultActiveKey="collapse0"
                      id="accordion"
                      role="tablist"
                    >
                      {/* CARD #1 */}

                      {Object.keys(topicMeta).map((descriptionKey: string, index) => {
                        if (descriptionKey === 'photo' || descriptionKey === 'summary' || descriptionKey === 'description' || Object.values(topicMeta)[index].length === 0) return ("")
                        return (
                          <div className="card" key={'metad' + index}>
                            {/* Card Header */}
                            <div className="card-header" role="tab" id={"heading" + index}>
                              {/* Header Title */}
                              <h5 className="h5-xs">
                                <Accordion.Toggle
                                  data-toggle="collapse"
                                  as="a"
                                  href={"#collapse" + index}
                                  eventKey={"collapse" + index}
                                >
                                  {capitalizeFirstLetterOfEveryWord(descriptionKey.replace("_", " "))}
                                </Accordion.Toggle>
                              </h5>
                            </div>
                            {/* Card Body */}
                            <Accordion.Collapse eventKey={"collapse" + index}>
                              <div className="card-body newline">
                                {/* List */}
                                {Object.values(topicMeta)[index]}
                              </div>
                            </Accordion.Collapse>
                          </div>
                        )
                      })}
                      {/* CARD #4 */}
                      <div className="card">
                        {/* Card Header */}
                        {/* This has links for showing video -may need in future

                      <div className="card-header" role="tab" id="headingFour">
                        <h5 className="h5-xs">
                          <Accordion.Toggle
                            as="a"
                            data-toggle="collapse"
                            href="#collapseFour"
                            eventKey="collapseFour"
                          >
                            Course Assessment
                          </Accordion.Toggle>
                        </h5>
                      <div className="hdr-data">
                        <p>1 lecture, 26:39 min</p>
                      </div>
                    </div>
                    <Accordion.Collapse eventKey="collapseFour">
                      <div className="card-body">
                        <p>
                          Video
                        </p>
                        <p className="download-file">
                          <i className="fas fa-file-archive" />
                          <Link to="sources/your-file.zip"
                            download="your-file"
                          >
                            Download File
                          </Link>
                        </p>
                        <p className="cb-video">
                          <Link to="#"
                            className="video-popup1"
                            onClick={() => { if (showVideo) { showVideo("SZEflIVnhH8"); } }}
                          >
                            <i className="fas fa-play-circle" />
                            Show video
                          </Link>
                        </p>
                      </div>
                    </Accordion.Collapse > */}</div>

                      {/* END CARD #4 */}
                    </Accordion > : <Skeleton variant="rectangular" sx={{ width: '100%', height: 200 }} />}
                  {/* END ACCORDION */}
                </div >
                {/* END COURSE CONTENT */}
                {/* COURSE RATING */}
                {(topicInfo?.reviews && topicInfo.reviews.length > 0) &&
                  <div className="cs-rating cd-block">
                    {/* Small Title */}
                    <h5 className="h5-xl"><b>Session Reviews</b></h5>
                    <br />
                    {/* Rating */}
                    {topicInfo?.reviews ?
                      <ReviewRatingsDisplay ratingsAverage={topicInfo?.ratingsAverage} ratingsCount={topicInfo?.ratingsCount} reviews={topicInfo?.reviews!} bar={true} /> :
                      <Skeleton variant="rectangular" sx={{ width: '100%', height: 150 }} />
                    }
                  </div>
                }
                {/* END COURSE RATING */}
              </div >
            </div >
            {/* END COURSE DESCRIPTION */}
            {/* COURSE DATA */}
            <div className="col-lg-4">
              <div className="course-data">
                {/* Image */}
                <div className="text-center">
                  {isProfilePage ? ((userPhoto && userPhoto.length) === 0 ? <Skeleton variant="rectangular" sx={{ width: '100%', height: 300 }} /> :
                    <img
                      className="img-fluid-portrait  text-center"
                      src={userPhoto}
                      alt="user photo"
                    />) :
                    ((topicPhoto && topicPhoto.length) === 0 ? <Skeleton variant="rectangular" sx={{ width: '100%', height: 300 }} /> :
                      <img
                        className="img-fluid-medium  text-center"
                        src={topicPhoto}
                        alt="topic photo"
                      />
                    )}
                </div>
                {/* Course Price */}
                <div className="text-center mt-10">
                  <h4><b>{priceStr}</b></h4>
                  {(topicInfo?.cost && topicInfo?.costType === apiproto.CostType.cost_per_time_charged_as_you_go && topicInfo.cost > 0) ? <div className="course-price-detail">1st session charged immediately. Subsequent sessions are charged 3 days in advance before they start.</div> : ""}
                  {/*<span className="old-price">$124.99</span>*/}
                </div>
                {nextSchedule && nextSchedule.starttime && nextSchedule.endtime &&
                  <TopicTimer nextSchedule={nextSchedule} timer={timer} refreshCallback={refreshCallback} />
                }
                {/* Links */}
                {nextSchedule &&
                  <div className="text-center divButton">
                    <VideoStartButton scheduleCard={nextSchedule} topicInfo={topicInfo ? topicInfo : null} width={200} />
                  </div>
                }
                {(scheduleEnrolledCards && scheduleEnrolledCards.length > 0) ?

                  <div className="text-center divButton">
                    <ReviewWrite scheduleCard={scheduleEnrolledCards[0]} reviewsByUser={userInfo?.reviewsByUser} courseId={topicInfo?.topicId} />
                  </div> : ""}
                {
                  ((userInfo?.emailAddress === 'fai@timedora.com' || userInfo?.emailAddress === 'lawrence@timedora.com' || userInfo?.emailAddress === 'fai+30@timedora.com') || isUserAdmin) &&
                  (
                    <div>
                      <div className="text-center">
                        <b>
                          {isPublish === "private" ? "Private. Only you can view it." : (isPublish === "inviteonly" ? "Invite Only - Only invitees can view it." : (isPublish === "restricted" ? "Restricted - Anyone with the link can view it." : "Public - Anyone can view it."))}</b>
                        <br />Searchable: {topicInfo?.searchable ? "On" : "Off"}
                        <br />
                        AllowSearch: {topicInfo?.allowSearchable ? "On" : "Off"}
                        <br />
                        Pending Review: {topicInfo?.pendingReview ? "On" : "Off"}
                        <br />
                        Last Reviewed: {(topicInfo && topicInfo.reviewDate) ? computeEpochtoPrettyDateStr(topicInfo.reviewDate!.toString(), 'ddd, MMM Do') : "None"}
                      </div>
                      <div className="text-center divButton">
                        <Button size="small" sx={{ align: "center", height: 40, width: 200 }} variant="contained" onClick={() => (approveTopic(topicInfo!))} startIcon={<PublishIcon />}>
                          {topicInfo?.allowSearchable ? "Remove from search" : "Approve for search"}
                        </Button>
                      </div>
                    </div>
                  )
                }
                <hr />
                <div>
                  <b>Quick Info</b>
                  {/* List */}

                  {(isFetched) ?
                    <div className="row divpadding">
                      {!isProfilePage ?
                        <Fragment>
                          <div className="col-sm-2">
                            <CakeIcon fontSize="medium" />
                          </div>
                          <div className="col-sm-10">
                            {topicInfo?.minAge}-{topicInfo?.maxAge} years old
                          </div>
                        </Fragment> : ''}
                      <div className="col-sm-2">
                        <GroupIcon fontSize="medium" />
                      </div>
                      <div className="col-sm-10">
                        {topicInfo?.maxParticipants} {topicInfo ? (topicInfo.maxParticipants! > 1 ? "participants" : "participant") : "participant"} per session
                      </div>
                      {(topicInfo && topicInfo.minDuration && topicInfo?.minDuration > 0) &&
                        <Fragment>
                          <div className="col-sm-2">
                            <AccessTimeIcon fontSize="medium" />
                          </div>
                          <div className="col-sm-10">
                            {secondsToDhms(+topicInfo?.minDuration! / 1000, false)} {topicInfo?.minDuration !== topicInfo?.maxDuration ? " - " + secondsToDhms(+topicInfo?.maxTotalDuration! / 1000, false) : ""} per session
                          </div>
                        </Fragment>}
                      <div className="col-sm-2">
                        <DateRangeIcon fontSize="medium" />
                      </div>
                      <div className="col-sm-10">
                        {getFormat(topicInfo!)}
                      </div>
                    </div>
                    : <Skeleton variant="rectangular" sx={{ width: '100%', height: 150 }} />}
                </div>
              </div >

            </div >
            {/* END COURSE DATA */}
            {
              (similarTopics && similarTopics.length > 0) &&
              <div className="container">
                <hr />
                <CourseSimilar courses={similarTopics} user={topicOwners[0]} />
              </div>
            }
          </div >
          {/* End row */}
        </div >

        {/* End container */}

      </section >

    </Fragment >
  );
};

export default TopicDetails;
