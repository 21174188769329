

export const log = (msg: string) => {
  console.log(msg);
}

export const error = (msg: string, err: any) => {
  console.error(msg + ':' + err.message);

}

export const newError = (msg: string, errorCode: string) => {
  const err = new Error(msg);
  err.name = errorCode;
  return err;
}

export const decodeJWT = (jwtString: string) => {
  const stringParts = jwtString.split('.');
  if (stringParts.length !== 3) {
    // invalid jwt
    throw newError('invalid jwt', 'INVALID_JWT');
  }
  const payload = atob(stringParts[1]);
  //const payload = Buffer.from(stringParts[1], "base64").toString();
  const payloadObject = JSON.parse(payload);
  if (!payloadObject.exp) {
    throw newError('missing jwt exp', 'INVALID_JWT');
  }
  const expString = payloadObject.exp.toString();
  const expNumber = parseInt(expString);
  if (isNaN(expNumber)) {
    throw newError('invalid jwt exp', 'INVALID_JWT');
  }

  if (!payloadObject.sub) {
    throw newError('missing jwt sub', 'INVALID_JWT');
  }
  const subString = payloadObject.sub.toString() as string;
  if (!subString) {
    throw newError('invalid jwt sub', 'INVALID_JWT');
  }
  const subStringParts = subString.split('@');
  if (subStringParts.length !== 2) {
  }
  const userId = subStringParts[0];
  const sessionId = subStringParts[1];

  return {
    userId: userId,
    sessionId: sessionId,
    exp: expNumber
  }
}


export const delay = async (delayInMilliseconds: number): Promise<void> => {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve();
    }, delayInMilliseconds);
  });
}

export const stickNav = () => {
  let offset = window.scrollY;
  const sticky = document.querySelector("#wsmainfull");
  if (sticky) {
    if (offset > 80) {
      sticky.classList.add("scroll");
    } else {
      sticky.classList.remove("scroll");
    }
  }
};

export const aTagClick = () => {
  const aTag = document.querySelectorAll("[href='#']");
  for (let i = 0; i < aTag.length; i++) {
    const a = aTag[i];
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }
};

export const isotopLayout = (container?: string | undefined, item?: string | undefined) => {
  setTimeout(() => {
    if (typeof window !== "undefined") {
      const Isotope = require("isotope-layout");
      new Isotope(container ? container : ".masonry-wrap", {
        itemSelector: item ? item : ".masonry-item",
        percentPosition: true,
        masonry: {
          columnWidth: item ? item : ".masonry-item",
        },
      });
    }
  }, 1000);
};

export const pagination = (listClass: string, sort: number, active: number) => {
  let list = document.querySelectorAll(listClass);
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (active === 1) {
      if (i < sort) {
        element.classList.remove("d-none");
      } else {
        element.classList.add("d-none");
      }
    } else {
      if (i >= (active - 1) * sort && i < active * sort) {
        element.classList.remove("d-none");
      } else {
        element.classList.add("d-none");
      }
    }
  }
};

export const getPagination = (totalNumber: number, sort: number) => {
  let arr = new Array(Math.ceil(totalNumber / sort))
    .fill(undefined)
    .map((_, idx) => idx + 1);
  return arr;
};

export const tdrLog = (msg: string) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(msg)
  }
}

export const tdrAlert = (msg: string) => {
  if (process.env.NODE_ENV !== 'production') {
    alert(msg)
  }
}
