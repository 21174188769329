import { Property } from "csstype";
import { Track } from "livekit-client";
import React from "react";
import { VideoRenderer } from "./VideoRenderer";
import "./conference.css";

interface ScreenShareProps {
    track: Track;
    width?: Property.Width;
    height?: Property.Height;
    shareOnTop?: boolean;
}

export const ScreenShareView = ({ track, width, height, shareOnTop }: ScreenShareProps) => {
    if (shareOnTop) {
        return (

            <div className="conf-screen-share-top">
                <VideoRenderer
                    track={track}
                    isLocal={false}
                    width={width}
                    height={height}
                />
            </div>
        );
    }
    else {
        return (
            <div className="conf-screen-share">
                <VideoRenderer
                    track={track}
                    isLocal={false}
                    width={width}
                    height={height}
                />
            </div>)

    }
};