import { Link, Navigate, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useUser } from "../../utils/useUser";
import { useAPI } from "../../utils/useAPI";
import * as apiproto from "../../apiproto";
import DialogMessageBox from "../DialogMessageBox";
import { tdrAlert } from "../../utils/utils";
import { computeEpochtoPrettyDateStr } from "../../utils/eduutils";

const PricingDetail = () => {

    const navigate = useNavigate();
    const user = useUser();
    const api = useAPI();
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [renewaldate, setRenewalDate] = useState("");
    const features = [
        { feature: "One on one meetings", free: "true", pro: "true", team: "true" },
        { feature: "Group meetings", free: "", pro: "true", team: "true" },
        { feature: "Create multiple topics", free: "1", pro: "5", team: "100" },
        { feature: "Create multiple rooms", free: "1", pro: "5", team: "10" },
        { feature: "Create ongoing & multi-sessions topics", free: "", pro: "true", team: "true" },
        { feature: "Create paid topics", free: "", pro: "true", team: "true" },
        { feature: "Automatic booking and cancellation notifications", free: "true", pro: "true", team: "true" },
        { feature: "Accept credit card and payments for bookings", free: "", pro: "true", team: "true" },
        { feature: "Timedora video conference", free: "true", pro: "true", team: "true" },
        { feature: "Integrate with Zoom and Google Meet", free: "", pro: "true", team: "true" },
        { feature: "Personalized links", free: "", pro: "true", team: "true" },
        { feature: "Commission for your own customers **", free: "0%", pro: "0%", team: "0%" },
        { feature: "Commission for customers referred by us **", free: "30%", pro: "28%", team: "25%" },
    ]
    const getStarted = () => {
        let path = '/login?type=signup&cat=expert';
        navigate(path);
    }
    const [plan, setPlan] = useState({ planType: "Free", billing: "yearly" });
    const [currentPlan, setCurrentPlan] = useState({ planType: "Free", billing: "", autoRenew: false });
    const [refresh, setRefresh] = useState(0);
    const selectPlan = (planid: string) => {
        if (process.env.NODE_ENV === 'production') {
            getStarted();
            return;
        }
        // Not ready to release to production yet- still in beta
        if (planid === 'free') {
            // If switcb back to free plan 
            if (currentPlan.planType !== "Free") {
                // That means user wants to cancel
                cancelSubscription();
            }
            else {
                // Already on the free plan
                setDialogMessage({ title: 'Already on Free Plan', message: "You are already on the Free Plan. Upgrade to premium plans for more powerful features.", meta: {}, confirm: false });
            }
            return;
        }
        navigate("/upgradePlan?id=" + planid + "&type=" + plan.billing)
    }
    const handleChange = (event: React.MouseEvent<HTMLElement>, frequency: string) => {
        setPlan({ ...plan, billing: frequency });
    };

    const cancelSubscription = () => {
        setDialogMessage({ title: 'Cancel Premium Plan', message: "You are on the " + currentPlan.billing + " " + currentPlan.planType + " Plan. Are you sure you want to cancel your premium plan and switch back to the Free Plan? When you cancel the premium plan, your premium benefits will continue to be available until the expiration date of your plan. The premium plan will also no longer renew so you will not be charged again.", meta: {}, confirm: true });
    }

    const cancelSubscriptionConfirmed = () => {
        (async () => {
            const serviceSubscriptionType: apiproto.api.ServiceSubscriptionType = apiproto.api.ServiceSubscriptionType.ServiceTypeFree;
            const serviceSubscriptionInterval: apiproto.api.ServiceSubscriptionInterval = apiproto.api.ServiceSubscriptionInterval.ServiceIntervalNone;
            try {
                const result = await api.updateServiceSubscription(serviceSubscriptionType, serviceSubscriptionInterval)
                // Success here if no error caught.
                if (result !== null) {
                    setDialogMessage({ title: 'Plan Cancelled', message: "Your plan is successfully cancelled. You will not be charged again and your plan will not be renewed. Your current plan benefits will be available until its expiration date.", meta: {}, confirm: false });
                }
                else {
                    setDialogMessage({ title: 'Cancellation error', message: "An error may have occurred during the cancellation of your premium plan.", meta: {}, confirm: false });
                }
            } catch (e: any) {
                setDialogMessage({ title: 'Cancellation error', message: "An error occurred during the cancellation of your selected plan.", meta: {}, confirm: false });
            }
            setRefresh(refresh + 1);
        })();
    }

    const onCancel = () => {
        setDialogMessage({ title: '', message: "", meta: {}, confirm: true });
    }

    useEffect(() => {
        if (user.authenticated) {
            (async () => {
                const userInfo = await api.getUser({ serviceSubscription: {} }, { userId: user.userId });
                // tdrAlert('current user subscription type:' + userInfo.user?.serviceSubscriptionType + ', subscription interval:' + userInfo.user?.serviceSubscriptionInterval + ',expiration:' + userInfo.user?.serviceSubscriptionExpirationDate);
                if (userInfo.user?.serviceSubscriptionExpirationDate) {
                    let expirationDate = computeEpochtoPrettyDateStr(userInfo.user?.serviceSubscriptionExpirationDate.toString(), 'ddd, MMM Do YYYY')
                    setRenewalDate(expirationDate);
                }
                let plantype = "Free";
                let billtype = "";
                let autorenew = false;
                if (userInfo.user?.serviceSubscriptionType === apiproto.api.ServiceSubscriptionType.ServiceTypeGold) {
                    plantype = "Pro";
                }
                else if (userInfo.user?.serviceSubscriptionType === apiproto.api.ServiceSubscriptionType.ServiceTypeSilver) {
                    plantype = "Individual"
                }
                if (userInfo.user?.serviceSubscriptionInterval === apiproto.api.ServiceSubscriptionInterval.ServiceIntervalMonthly) {
                    billtype = "monthly";
                }
                else if (userInfo.user?.serviceSubscriptionInterval === apiproto.api.ServiceSubscriptionInterval.ServiceIntervalYearly) {
                    billtype = "yearly";
                }
                if (userInfo.user?.serviceSubscriptions) {
                    for (const ss of userInfo.user?.serviceSubscriptions) {
                        //   tdrAlert('subscription level:' + ss.serviceSubscriptionType + ',interval:' + ss.serviceSubscriptionInterval + ',start:' + ss.startDate + ',cancelDate:' + ss.cancelDate + ' , autoRenew:' + (ss.autoRenew ? 'true' : 'false'));
                        // As long as there is one auto renew, we considered some plan is being auto-renewed and the subscription is not cancelled yet
                        if (ss.autoRenew) autorenew = true;
                    }
                }
                setCurrentPlan({ ...currentPlan, billing: billtype, planType: plantype, autoRenew: autorenew })

            })();
        }

    }, [user.authenticated, refresh]);

    return (
        <section id="pricing-1">
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Cancel Plan" : ""} cancel={dialogMessage.confirm ? "Dismiss" : "Dismiss"} onCancel={onCancel} onConfirm={cancelSubscriptionConfirmed} visible={dialogMessage.title.length > 0} />

            <div className="divpadding">
                {/* SECTION TITLE */}
                {user.authenticated && process.env.NODE_ENV !== 'production' &&
                    <div className="row pricing-row">
                        {/* PRICE PLAN MONTHLY */}
                        <div className="col-sm-12">
                            <div className="currentplan-table">
                                You are currently on the <b> {currentPlan.billing} {currentPlan.planType} plan</b>.
                                {renewaldate.length > 0 && (currentPlan.autoRenew ?
                                    <span> Your next renewal date is on <b>{renewaldate}</b>.</span>
                                    :
                                    <span> Your plan will expire on <b>{renewaldate}</b>.</span>)
                                }
                                <br /><br />
                                {currentPlan.planType === "Pro" && currentPlan.billing === "monthly" && <span> You can upgrade to a <Link to="/upgradePlan?id=pro&type-yearly">Pro Yearly plan and get a 20% discount</Link></span>}
                                {currentPlan.planType === "Individual" && <span> You can upgrade to our most powerful <Link to="/upgradePlan?id=pro&type-yearly">Pro Yearly plan.</Link></span>}
                                {(currentPlan.planType !== "Free" && currentPlan.autoRenew) && <span> You can <Link to="#" onClick={cancelSubscription}>cancel your subscription</Link> anytime.</span>}

                            </div>
                        </div>
                    </div>
                }
                {!user.authenticated &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title title-centered mb-30">
                                {/* Title 	*/}
                                <h3 className="h3-lg">
                                    Free to Start
                                </h3>
                                {/* Text */}
                                <p className="p-md">
                                    It's free to get started (no credit cards required). Choose from our different plans. No long term commitment- just pay monthly and cancel anytime you want. If you are not 100% satisfied, let us know and we will refund your purchase.
                                </p>
                            </div>
                        </div>
                    </div>}
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-title title-centered mb-30">
                            <ToggleButtonGroup
                                color="primary"
                                value={plan.billing}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="yearly"><b>Billed Yearly (Save 20%)</b></ToggleButton>
                                <ToggleButton value="monthly"><b>Billed Monthly</b></ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </div>

                {/* PRICING TABLES HOLDER */}

                <div className="row pricing-row">
                    {/* PRICE PLAN MONTHLY */}
                    <div className="col-md-4">
                        <div className="pricing-table">
                            {/* Plan Price  */}
                            <div className="pricing-plan">
                                <h5 className="h5-md">Free</h5>
                                <sup>$</sup>
                                <span className="price">0</span>
                                <sup className="coins">00</sup>
                                <p className="p-sm">per month</p>
                                <div style={{ minHeight: '40px' }}>
                                    <p className="p-sm">{plan.billing === "monthly" ? <br /> : <br />}</p>
                                </div>
                            </div>
                            {/* Pricing Plan Features */}
                            <ul className="features divHide">
                                <li className="disabled-option">Lead Generation</li>
                                <li className="disabled-option">App Integrations</li>
                                <li className="disabled-option">Accept Payments</li>
                                <li>Create free sessions</li>
                                <li>Access to Scheduling and Session Tools</li>
                                <li>Video Conferencing Tool<br /><br /></li>
                                <li>0% on earnings from users you bring (includes payment processing fees ~ 2.9%)**</li>
                                <li>30% on earnings from users referred by us **</li>
                            </ul>
                            {/* Pricing Table Button */}
                            {currentPlan.planType === "Free" ?
                                <div className="currentPlan">Current Plan</div>
                                : <Button size="large" variant="contained" onClick={() => { selectPlan("free") }} sx={{
                                    ':hover': {
                                        bgcolor: '#ff1f59', // theme.palette.primary.main
                                        color: 'white'
                                    }, width: 180, height: 50, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none', marginTop: '30px'
                                }}>
                                    Free
                                </Button>}
                        </div>
                    </div>
                    {/* END PRICE PLAN STARTER */}
                    {/* PRICE PLAN ANNUAL */}
                    <div className="col-md-4">
                        <div className="pricing-table">
                            {/* Plan Price  */}
                            <div className="pricing-plan">
                                <h5 className="h5-md">Indvidual</h5>
                                <sup>$</sup>
                                <span className="price">{plan.billing === "monthly" ? 5 : 4}</span>
                                <sup className="coins">00</sup>
                                <p className="p-sm">per month</p>
                                <div style={{ minHeight: '40px' }}>

                                    <p className="p-sm">{plan.billing === "monthly" ? "" : "($48/year billed yearly)"}</p>
                                </div>
                            </div>
                            {/* Pricing Plan Features */}
                            <ul className="features divHide">
                                <li className="disabled-option">Lead Generation</li>
                                <li className="disabled-option">App Integrations </li>
                                <li>Accept payments</li>
                                <li>Create free and paid sessions</li>
                                <li>Access to Scheduling and Session Tools</li>
                                <li>Video Conferencing Tool 2 hours limit per session</li>
                                <li>0% on earnings from users you bring *<br /><br /></li>
                                <li>29% on earnings from users referred by us **</li>
                            </ul>
                            {currentPlan.planType === "Individual" && currentPlan.autoRenew && currentPlan.billing === plan.billing ?
                                <div className="currentPlan">Current Plan</div>
                                :
                                <Button size="large" variant="contained" onClick={() => { selectPlan("individual") }} sx={{
                                    ':hover': {
                                        bgcolor: '#ff1f59', // theme.palette.primary.main
                                        color: 'white',
                                    }, width: 180, height: 50, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none', marginTop: '30px'
                                }}>
                                    Individual
                                </Button>}
                        </div>
                    </div>
                    {/* END PRICE PLAN ANNUAL */}
                    {/* PRICE PLAN BUSINESS */}
                    <div className="col-md-4">
                        <div className="pricing-table">
                            {/* Plan Price  */}
                            <div className="pricing-plan">
                                <h5 className="h5-md">Pro</h5>
                                <sup>$</sup>
                                <span className="price">                                <span className="price">{plan.billing === "monthly" ? 10 : 8}</span>
                                </span>
                                <sup className="coins">00</sup>
                                <p className="p-sm">per month</p>
                                <div style={{ minHeight: '40px' }}>
                                    <p className="p-sm">{plan.billing === "monthly" ? "" : "$96/year (billed yearly)"}</p>
                                </div>
                            </div>
                            {/* Pricing Plan Features */}
                            <ul className="features divHide">
                                <li>Lead Generation</li>
                                <li>App Integrations</li>
                                <li>Accept payments</li>
                                <li>Create free and paid sessions</li>
                                <li>Access to Scheduling and Session Tools</li>
                                <li>Video Conferencing Tool 4 hour limit per session</li>
                                <li>0% on earnings from users you bring (includes payment processing fees ~ 2.9%)**</li>
                                <li>27% on earnings from users referred by us **</li>
                            </ul>
                            {currentPlan.planType === "Pro" && currentPlan.autoRenew && currentPlan.billing === plan.billing ?
                                <div className="currentPlan">Current Plan</div>
                                :
                                <Button size="medium" variant="contained" onClick={() => { selectPlan("pro") }} sx={{
                                    ':hover': {
                                        bgcolor: '#ff1f59', // theme.palette.primary.main
                                        color: 'white',
                                    }, width: 180, height: 50, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none', marginTop: '30px'
                                }}>
                                    Pro
                                </Button>}
                        </div>
                    </div>
                    {/* END PRICE PLAN BUSINESS */}
                </div >
                {/* END PRICING TABLES HOLDER */}
                <h3 className="h3-lg">
                    Compare Plans
                </h3><br />
                <Paper style={{ padding: 0, }} elevation={6}>
                    <TableContainer sx={{ height: 'calc (100vh-600px)' }}>
                        <Table stickyHeader sx={{ width: '98%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><h4>Features</h4></TableCell>
                                    <TableCell style={{ width: '15%' }} align="center">
                                        <h5>Free</h5>
                                        <Button size="small" variant="text" onClick={() => { selectPlan("pro") }} sx={{
                                            ':hover': {
                                                bgcolor: '#ff1f59', // theme.palette.primary.main
                                                color: 'white',
                                            }, height: 50, fontSize: '14px', textDecoration: 'underline'
                                        }}>
                                            Select Free
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ width: '15%' }} align="center">
                                        <h5>Individual</h5>
                                        <Button size="small" variant="text" onClick={() => { selectPlan("pro") }} sx={{
                                            ':hover': {
                                                bgcolor: '#ff1f59', // theme.palette.primary.main
                                                color: 'white',
                                            }, height: 50, fontSize: '14px', textDecoration: 'underline'
                                        }}>
                                            Select Individual
                                        </Button>
                                    </TableCell>
                                    <TableCell style={{ width: '15%' }} align="center">
                                        <h5>Pro</h5>
                                        <Button size="small" variant="text" onClick={() => { selectPlan("pro") }} sx={{
                                            ':hover': {
                                                bgcolor: '#ff1f59', // theme.palette.primary.main
                                                color: 'white',
                                            }, height: 50, fontSize: '14px', textDecoration: 'underline'
                                        }}>
                                            Select Pro
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {features.map((row) => (
                                    <TableRow
                                        key={row.feature}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.feature}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.free === "true" ? <img src="/images/icons/checkmark.png" alt="check" width="28" /> : row.free}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.pro === "true" ? <img src="/images/icons/checkmark.png" alt="check" width="28" /> : row.pro}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.team === "true" ? <img src="/images/icons/checkmark.png" alt="check" width="28" /> : row.team}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {/* PRICING NOTICE TEXT */}
                <div className="row">
                    <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <div className="pricing-notice text-center mb-40">
                            <p className="p-md">
                                <span>Note!</span>
                                <br />
                                * Lower commission rate are by invite only for now for expert who has a higher number of hours of sessions and a good reputation.<br /><br />

                                ** Commission already includes the payment processing fees that vary from location to location depending on country and currency. It can range from 2.9%-3.4% with a minimum charge of $0.50.
                            </p>
                        </div>
                    </div>
                </div>
            </div >
            {/* End container */}
        </section >
    );
};

export default PricingDetail;
