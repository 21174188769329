import React, { Dispatch } from "react";
import { api } from "../apiproto";

export interface AuthContextType {
    token: string | null;
    userId: string | null;
    expirationTime: number | null;
    currentView: api.ViewType | null;
}

export interface ActionType {
    type: "login" | "logout" | "updateViewType";
    loginPayload?: {
        userId: string;
        token: string;
        expirationTime: number;
    }
    viewTypePayload?: {
        currentView: api.ViewType;
    }
}

export const AuthContext = React.createContext<{ state: AuthContextType, dispatch: Dispatch<ActionType> }>({ state: { userId: null, token: null, expirationTime: null, currentView: api.ViewType.ConsumerView }, dispatch: () => { } });
