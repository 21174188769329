import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

import AddIcon from '@mui/icons-material/Add';

import { Box, Button, Chip, Tab, Tabs, Typography } from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import { api as apiproto } from "../../apiproto";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { LinearProgressWithLabel } from "../../components/LinearProgressWithLabel";
import DialogMessageBox from "../../components/DialogMessageBox";
import { encodeJSONObject, generateProfileURL, getUserPhotoURL, isAlphaNumeric, PROFILE_PHOTO_ASSET_NAME, resizeFile, setTimeZone } from "../../utils/eduutils";
import TimezoneSelect, { ITimezoneOption } from "react-timezone-select";
import type { ITimezone } from "react-timezone-select";
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PageTitle from "../../layout/PageTitle";
import DateRangeIcon from '@mui/icons-material/DateRange';
import { tdrAlert, tdrLog } from "../../utils/utils";


library.add(faCaretDown);

export const MemberProfile = () => {


    const [photoUploading, setPhotoUploading] = useState(false);
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: "" });


    const [tz, setTz] = useState<ITimezone>(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    const [vanityURL, setVanityURL] = useState("");
    const [vanityError, setVanityError] = useState("");
    const [linkCopied, setLinkCopied] = useState(false);


    const DropdownIndicator = (props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => {
        return (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon="caret-down" />
            </components.DropdownIndicator>
        );
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProfileState({
            ...profileState,
            [event.target.name]: event.target.value
        });
    };
    const handleVanityInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (vanityError.length > 0) {
            setVanityError("");
        }
        setVanityURL(event.target.value);
    };

    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();

    const submitInitialStatus = {
        isSubmitting: false,
        errorMessage: "",
    }
    const placeholderDescriptionText = ["Introduce yourself to others. (required)"];
    const [submitStatus, setSubmitStatus] = useState(submitInitialStatus);
    const [fullDescription, setFullDescription] = useState({ about_me: "" })
    const [profileState, setProfileState] = useState<apiproto.IUser>({ lastName: "", firstName: "", description: "{\"about_me\": \"\"}", vanityName: "" });
    const [selectedImage, setSelectedImage] = useState<Blob>();
    const [imageUrl, setImageUrl] = useState("/images/no-profile-picture.png");
    useEffect(() => {
        setSubmitStatus({ ...submitStatus, isSubmitting: true });
        getUserProfile();
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage)!);
        }
        try {
            if (profileState) {
                setFullDescription(JSON.parse(profileState.description!))
            }
        }

        catch (err: any) {
            // TODO: Not much we can do here.
            setSubmitStatus({ isSubmitting: false, errorMessage: "Error getting profile" });
            return;
        }
        setSubmitStatus({ isSubmitting: false, errorMessage: "" });
    }, [user.userId]);


    useEffect(() => {
        const update = async () => {
            let timer: ReturnType<typeof setTimeout>;

            if (linkCopied) {
                setVanityError("");
                if (vanityURL !== profileState?.vanityName) {
                    // We want to update the vanity name
                    const result = await updateVanityName();
                    if (!result) {
                        setLinkCopied(false);
                        return;
                    }
                }
                timer = setTimeout(() => {
                    setLinkCopied(false);
                }, 1000);
            }
            return () => {
                if (!timer) {
                    clearTimeout(timer);
                }
            };

        }
        update();

    }, [linkCopied]);

    const verifyEmail = async () => {
        (async () => {
            try {
                await api.sendValidationCode("", false);
                navigate("/validateEmail");
            } catch (e) {
                setDialogMessage({ title: 'Verify Email', message: 'Your verification code cannot be sent right now as you may have just sent it recently. Try again later. Check your spam folder if you do not see the email for your verification code.', meta: {}, confirm: "" });

            }
        })();
    }

    const updateVanityName = async () => {
        if (vanityURL.length > 0 && vanityURL.length < 8) {
            setVanityError("Vanity name must be 8 characters or more.");
            return false;
        }
        if (!profileState.vanityName && vanityURL.length === 0) {
            // It's ok- we are just not updating vanity name
            return true;
        }
        if (profileState && vanityURL !== profileState.vanityName) {
            const oldVanityName = profileState.vanityName
            const tmpProfileState = { ...profileState };
            tmpProfileState.vanityName = vanityURL;
            try {
                await api.updateUser({ vanityName: true }, tmpProfileState);
                setProfileState({ ...tmpProfileState, vanityName: vanityURL });
            }
            catch (err: any) {
                if (!isAlphaNumeric(vanityURL)) {
                    setVanityError("Vanity name can only contain alphanumeric characters.");
                }
                else {
                    setVanityError("Vanity name is taken or you have non-alphabet characters.");
                }
                tmpProfileState.vanityName = oldVanityName;
                setProfileState(tmpProfileState);
                return false;
            }
            return true;
        }
        return false;
    }

    const generateVanityURL = () => {
        return window.location.hostname + (window.location.port ? ':' + window.location.port : "") + '/u/' + ((vanityURL.length > 0) ? vanityURL : profileState?.tinyCode);
    }
    /*
        const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
            // That means class format has changed, so we just reset billing type to Free since depending on what is selected, its unclear what is the best default- so default to free since it is common to all types
    
            //        setCourseInfoState({
                        ...courseInfoState,
                        [event.target.name]: event.target.value,
                        costType: event.target.name === 'classformat' ? edu.CostType.free : parseInt(event.target.value)
                    });
            
        };
    */
    const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        setFullDescription({ ...fullDescription, [event.target.name]: event.target.value })
        setProfileState({ ...profileState, description: JSON.stringify(fullDescription) })
    };

    const [progress, setProgress] = useState(0);

    const imagePhotoSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhotoUploading(true);
        if (e.target && e.target!.files) {
            setSelectedImage(e.target!.files![0]);
            handleSubmitPhoto(e);
        }
        else {
            setPhotoUploading(false);
        }
    }

    const handleSubmitPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
        // There is no update asset so we just delete and reupload.
        if (e.target && e.target!.files) {
            const originalFile = e.target!.files[0];
            const file = await resizeFile(originalFile) as Blob;
            if (!file) {
                setPhotoUploading(false);
                return;
            }
            setImageUrl(URL.createObjectURL(file)!)
            setProgress(1);
            if (profileState.assets && profileState.assets.length > 0) {
                try {
                    await api.deleteAsset(profileState.userId!, PROFILE_PHOTO_ASSET_NAME)
                }
                catch (err: any) {
                    // TODO: Not much we can do here
                    tdrLog(err);
                }
            }
            try {
                const assetInfo = await api.addAsset(profileState.userId!, PROFILE_PHOTO_ASSET_NAME, file.type, file.size);
                if (assetInfo.uploadUrl && file) {
                    // Using POST
                    const formData = new FormData();
                    formData.append("Content-Type", file.type);
                    formData.append("file", e.target!.files[0]);
                    axios.put(assetInfo.uploadUrl, file, {
                        headers: {
                            "Content-Type": file.type,
                        },
                        onUploadProgress: data => {
                            //Set the progress value to show the progress bar
                            const percent = Math.round((100 * data.loaded) / data.total);
                            setProgress(percent);

                        },
                    })
                        .then((res) => {
                            setDialogMessage({ title: 'Upload Success', message: 'Your picture was uploaded successfully.', meta: {}, confirm: "" });
                            setPhotoUploading(false);
                            setProgress(0);
                            setImageUrl(URL.createObjectURL(file)!)
                        })
                        .catch((err) => {
                            tdrAlert(JSON.stringify(err))
                            setPhotoUploading(false);
                            setImageUrl("/images/no-profile-picture.png");
                            setProgress(0);
                            setDialogMessage({ title: 'Upload Error', message: 'Your picture was unable to be uploaded successfully.', meta: {}, confirm: "" });
                        });
                    // Using PUT
                    /*
                    axios.put(assetInfo.uploadUrl, e.target!.files[0], {
                        headers: { "Content-Type": file.type, },
                        onUploadProgress: data => {
                            //Set the progress value to show the progress bar
                            //   setProgress(Math.round((100 * data.loaded) / data.total))
                            setProgress(data.total)
                        },
                    })
                        .then((res) => {
                            setDialogMessage({ title: 'Upload Success', message: 'Your picture was uploaded successfully.', meta: {} });
                        })
                        .catch((err) => {
                            setDialogMessage({ title: 'Upload Error', message: 'Your picture was unable to be uploaded successfully.', meta: {} });
                        });*/

                }
            }

            catch (err: any) {
                setDialogMessage({ title: 'Upload Error', message: 'Your picture was unable to be uploaded successfully.', meta: {}, confirm: "" });
                setPhotoUploading(false);
                setImageUrl("/images/no-profile-picture.png");
                setProgress(0);
            }
        }
    };


    const updateProfile = async () => {
        setSubmitStatus({ ...submitStatus, isSubmitting: true });
        setVanityError("");
        try {
            if (profileState) {
                let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                if (profileState.timeZone && profileState.timeZone.length > 0) {
                    timezone = profileState.timeZone;
                }
                if ((tz as ITimezoneOption).value) {
                    timezone = (tz as ITimezoneOption).value;
                }
                setProfileState({ ...profileState, description: JSON.stringify(fullDescription), timeZone: timezone })
                const thedescription = encodeJSONObject({ ...fullDescription })
                const profileStateToWrite = { ...profileState, description: JSON.stringify(thedescription), timeZone: timezone };
                // We update vanity name to make sure that it is available
                var result = true;
                if (vanityURL !== profileState.vanityName) {
                    result = await updateVanityName();
                    if (!result) {
                        window.scrollTo(0, 0);
                        setSubmitStatus({ ...submitStatus, isSubmitting: false });
                        return;
                    }
                }
                if (result) {
                    await api.updateUser({ firstName: true, lastName: true, description: true, timeZone: true }, profileStateToWrite)
                        .then((res) => {
                            if (res) {
                                setTimeZone(profileStateToWrite.timeZone);
                                setDialogMessage({ title: 'Profile Updated', message: 'Your profile was updated successfully. Do you want to view your profile?', meta: {}, confirm: "View Profile" });
                            }
                        });
                }
            }
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
            setDialogMessage({ title: 'Profile Update Error', message: 'An error occurred while updating your profile.', meta: {}, confirm: "" });
        }
        setSubmitStatus({ ...submitStatus, isSubmitting: false });

    }

    const getUserProfile = async () => {
        try {
            const userResult = await api.getUser({ includeDescription: true, assets: {}, integrations: {} }, { userId: user.userId });
            //tdrAlert(JSON.stringify(userResult) + ' ' + user.currentView)
            if (userResult && userResult.user) {
                const userInfo = userResult.user;
                setProfileState(userInfo);
                if (userInfo.vanityName && userInfo.vanityName.length > 0) {
                    setVanityURL(userInfo.vanityName);
                }
                var thedescription = JSON.parse(userResult.user.description!);
                thedescription = { ...thedescription, about_me: decodeURIComponent(thedescription.about_me) }
                setFullDescription(thedescription);
                setImageUrl(getUserPhotoURL(userResult.user));
                if (userResult.user.timeZone && userResult.user.timeZone.length > 0) {
                    setTz(userResult.user.timeZone);
                    setTimeZone(userResult.user.timeZone);
                }
            }
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
        }
        setSubmitStatus({ ...submitStatus, isSubmitting: false });
    }
    const updateSchedule = () => {
        updateProfile();
        navigate('/createtopic/' + window.encodeURIComponent("c_" + profileState.userId));
    }

    const onClose = () => {
        navigate(generateProfileURL(profileState.firstName!, profileState.lastName!, profileState.userId!));
    }
    const handleCancel = () => {
        clearDialog();
        setProgress(0);
    }

    const handleConfirm = () => {
        navigate(generateProfileURL(profileState.firstName!, profileState.lastName!, profileState.userId!));
    }

    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: "" });
    }

    return (
        <RequireAuth>
            <Layout headerBtn footer={3}>
                <PageTitle pageTitle="My Profile | Timedora" />
                < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm} cancel={"Dismiss"} onCancel={handleCancel} onConfirm={handleConfirm} visible={dialogMessage.title.length > 0} />

                {/*-- Profile Screen --*/}
                <div className="course-wrapper">
                    <div className="course-inner course-innerpadding">
                        <ValidatorForm onSubmit={updateProfile}>
                            <h3>Profile</h3>
                            <hr />
                            <div className="form-group form-input">
                                <Row>
                                    <Col md="4" className="text-center">
                                        <input accept="image/*" id="contained-image-file"
                                            type="file" style={{ display: 'none' }} onChange={e => { imagePhotoSelected(e) }} />
                                        {
                                            (imageUrl) ? (
                                                < img alt="profile image" src={imageUrl} height="200px" />
                                            ) : <img alt="no profile" src="/images/no-profile-picture.png" height="200px" />}
                                        <Row>
                                            <Col>
                                                <LinearProgressWithLabel value={progress} width={200} />
                                                <br />
                                                <label htmlFor="contained-image-file">
                                                    <Button sx={{ width: 180, height: 40 }} variant="text" size="small" color="primary" component="span" startIcon={<PhotoCamera />} disabled={photoUploading}>
                                                        Upload Photo
                                                    </Button>
                                                </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="form-group form-input">
                                                    <TextValidator
                                                        label="Enter first name"
                                                        type="text"
                                                        value={profileState?.firstName}
                                                        onChange={handleInputChange}
                                                        name="firstName"
                                                        id="firstname"
                                                        className="form-control"
                                                        placeholder="Enter first name"
                                                        validators={['required']}
                                                        errorMessages={['First name is required']}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="form-group form-input">
                                                    <TextValidator
                                                        label="Enter last name"
                                                        type="text"
                                                        value={profileState?.lastName}
                                                        onChange={handleInputChange}
                                                        name="lastName"
                                                        id="lastname"
                                                        className="form-control"
                                                        validators={['required']}
                                                        errorMessages={['Last name is required']}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>
                                                <div className="form-group form-input">

                                                    <TextValidator
                                                        label={window.location.hostname + (window.location.port ? ':' + window.location.port : "") + '/u/' + (vanityURL.length > 0 ? vanityURL : profileState.tinyCode)}
                                                        type="text"
                                                        value={vanityURL}
                                                        onChange={handleVanityInputChange}
                                                        name="vanity"
                                                        id="vanity"
                                                        className="form-control"
                                                        placeholder="Enter profile vanity name"
                                                        validators={[]}
                                                        errorMessages={[]}
                                                    />
                                                    {vanityError ? <div className="form-error-msg">{vanityError}</div> : ""}
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="form-group form-input">

                                                    <Button variant="text"
                                                        startIcon={<ContentCopyIcon />}
                                                        size="small"
                                                        sx={{ marginTop: 1 }}
                                                        hidden={vanityURL.length === 0 && profileState.tinyCode?.length === 0}
                                                        disabled={linkCopied ? true : false}
                                                        color="primary" aria-label="Copy Link" component="span" onClick={() => {
                                                            navigator.clipboard.writeText(generateVanityURL());
                                                            setLinkCopied(true);
                                                        }}>
                                                        {linkCopied ? "Link Copied" : "Copy Link"}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>
                                                <div className="form-group form-input">

                                                    <TextValidator
                                                        label="Email"
                                                        type="text"
                                                        disabled={true}
                                                        value={profileState.emailAddress || ''}
                                                        onChange={handleInputChange}
                                                        name="email"
                                                        id="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        validators={['required']}
                                                        errorMessages={['Email is required']}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                {profileState.validated ? <Chip label={'Email verified'} color="success" />
                                                    :
                                                    <div><Button variant="text"
                                                        sx={{ marginTop: 1 }}
                                                        size="small"
                                                        color="primary" aria-label="verify email" component="span" onClick={() => {
                                                            verifyEmail();
                                                        }}>
                                                        Verify Email
                                                    </Button>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>
                                <hr />
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        {Object.keys(fullDescription).map((descriptionKey: string, index) => {
                                            return (< Tab key={'descTab' + index} label={descriptionKey.replace("_", " ")} {...a11yProps(index)} />)
                                        })}
                                    </Tabs>
                                </Box>
                                {Object.keys(fullDescription).map((descriptionKey: string, index) => {
                                    return (
                                        <div key={'descriptiontext' + index}
                                            role="tabpanel"
                                            hidden={value !== index}
                                            id={`simple-tabpanel-${index}`}
                                            aria-labelledby={`simple-tab-${index}`}
                                        >
                                            {value === index && (

                                                <Box sx={{ paddingTop: 2 }}>
                                                    <Typography> <textarea
                                                        key={"metatextarea" + index}
                                                        minLength={6}
                                                        maxLength={4096}
                                                        rows={8}
                                                        onChange={handleTextAreaChange}
                                                        name={descriptionKey}
                                                        id={descriptionKey}
                                                        className="form-control" placeholder={placeholderDescriptionText[index]}
                                                        value={(Object.values(fullDescription)[index])} /></Typography>
                                                </Box>
                                            )}
                                        </div>
                                    )

                                })}
                            </div>
                            <hr />
                            <h4 className="mb-20">Timezone &amp; Currency</h4>
                            <Row>
                                <Col lg={4}>
                                    <div className="form-group form-input">
                                        <TimezoneSelect
                                            classNamePrefix="timeZoneSelect"
                                            value={tz}
                                            onChange={setTz}
                                            components={{ DropdownIndicator }}
                                        />
                                    </div>
                                </Col>
                                {/* hide for now since we don't really support yet
                                <Col lg={4}>
                                    <div className="form-group form-input">
                                        <SelectValidator
                                            id="currency"
                                            name="currency"
                                            value="USD"
                                            label="Choose Currency"
                                            className="form-control"
                                            onChange={handleSelectChange}>
                                            <MenuItem value="USD">USD - US Dollars</MenuItem>
                                            <MenuItem value="CAD">CAD - Canadian Dollars</MenuItem>
                                            <MenuItem value="EUR">EUR - Euro Dollars</MenuItem>
                                        </SelectValidator>
                                    </div>

                                </Col>
                            */}
                            </Row>


                            <div className="text-center">
                                <br /><br /><br />
                                {submitStatus.errorMessage && (
                                    <span className="form-error">{submitStatus.errorMessage}</span>
                                )}
                                <br /><br />
                                <Button sx={{ backgroundColor: "gray", float: 'right', marginLeft: 1 }} size="large" variant="contained" onClick={onClose}>
                                    Cancel
                                </Button>
                                <LoadingButton style={{ float: 'right', marginLeft: 10 }} size="large" variant="contained" loading={submitStatus.isSubmitting} loadingPosition="start" type="submit" disabled={(fullDescription.about_me.length === 0 || photoUploading)} startIcon={<AddIcon />}>
                                    Save Profile
                                </LoadingButton>
                                <LoadingButton style={{ float: 'right', marginLeft: 10 }} variant="contained" size="large" loading={submitStatus.isSubmitting} loadingPosition="start" color="primary" component="span" startIcon={<DateRangeIcon />} disabled={photoUploading} onClick={updateSchedule}>
                                    Set Schedule
                                </LoadingButton>

                            </div>

                        </ValidatorForm>
                    </div >



                </div >


            </Layout >
        </RequireAuth>
    );

};

