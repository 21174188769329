import { useNavigate } from "react-router-dom";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { home1HeroProps } from "../sliderProps";
import Button from "@mui/material/Button";

const MainHero1 = () => {
  const navigate = useNavigate();
  const mentorClicked = () => {
    let path = '/expert';
    navigate(path);
  }

  return (
    <section id="hero-1" className="hero-section division">
      {/* SLIDER */}
      <Swiper {...home1HeroProps}>
        <ul className="swiper-wrapper slides">

          {/* SLIDE #3 */}
          <SwiperSlide>
            <div className="li">
              {/* Background Image */}
              <img
                style={{ backgroundImage: 'url("images/slider/slide-3.jpg")' }}
                src="data:image/gif;base64,R0lGODlhAQABAIABAP///wAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                alt="slide-background"
              />
              {/* Image Caption */}
              <div className="caption d-flex align-items-center right-align">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 col-lg-7">
                      <div className="caption-txt">
                        {/* Title */}
                        <h2 className="h2-sm">
                          Powering the service economy
                        </h2>
                        {/* Text */}
                        <p className="p-lg">
                          Are you a coach, accountant, consultant, teacher or service provider? Timedora is a flexible service that lets you market your expertise and easily build your own marketing page with integrated scheduling and payments page. Clients can easily reserve your available time slot and make payments online. They can then meet with you through an integrated video conferencing system.
                        </p>
                        <Button size="large" variant="contained" onClick={mentorClicked} sx={{
                          ':hover': {
                            bgcolor: '#ff1f59', // theme.palette.primary.main
                            color: 'white',
                          }, width: 200, height: 50, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none'
                        }}>
                          Try for Free
                        </Button>                      </div>
                    </div>
                  </div>
                  {/* End row */}
                </div>
                {/* End container */}
              </div>
              {/* End Image Caption */}
            </div>
          </SwiperSlide>
          {/* END SLIDE #3 */}
        </ul>
        {/* <ul className="indicators"></ul> */}
      </Swiper>
      {/* END SLIDER */}
    </section >
  );
};

export default MainHero1;
