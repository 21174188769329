import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { ChatBox } from "../../components/conference/ChatBox";


type ChildProps = {
    recipientId: string | null,
    visible: boolean,
    callback?: (value: any) => void | null,
}


const ChatDialog: React.FC<ChildProps> = ({ recipientId = null, visible = false, callback = () => { } }) => {


    const [open, setOpen] = useState(false);
    const handleChatClose = () => {
        setOpen(false);
        callback("");
    }
    useEffect(() => {
        setOpen(visible);
    }, [visible]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={handleChatClose}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleChatClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: "hidden" }}>
                <div className="chat-container-light">
                    <ChatBox recipientId={recipientId} />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ChatDialog;