import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { AuthContext } from "../context/authcontext";
import { useUser } from "../utils/useUser";
import Button from "@mui/material/Button";

const Header = ({ headerBtn }: { headerBtn: boolean }) => {
  const [openSubMen, setOpenSubMen] = useState<string | null>(null);
  const onClick = () => {
    const body = document.querySelector("body");
    body?.classList.toggle("wsactive");
    setOpenSubMen("");
  };
  const activeFun = (name: string | null) => setOpenSubMen(name === openSubMen ? "" : name);
  const activeLi = (name: string | null) =>
    name === openSubMen ? "d-block" : "d-md-block d-sm-none";
  const iconChange = (name: string | null) => (name === openSubMen ? "ws-activearrow" : "");
  const navigate = useNavigate();
  const user = useUser();
  const { dispatch } = React.useContext(AuthContext);
  const logoutPressed: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    dispatch({ type: 'logout' });
    navigate("/");
  }

  const mentorClicked = () => {
    let path = '/login?type=signup&cat=expert';
    navigate(path);
  }
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);


  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  return (
    <header id="header" className="white-menu navbar-dark">
      <div className="header-wrapper">
        {/* MOBILE HEADER */}
        <div className="wsmobileheader clearfix">
          <a href="#"
            onClick={() => onClick()}
            id="wsnavtoggle"
            className="wsanimated-arrow"
          >
            <span />
          </a>
          <Link to={user.authenticated ? "/sessions" : "/"} className="logo-black">
            <span className="smllogo smllogo-black">
              <img
                src="/images/timedoramediumlogo.png"
                height={50}
                alt="mobile-logo"
              />
            </span>
          </Link>
          <span className="smllogo smllogo-white">
            <img
              src="/images/timedoramediumlogo.png"
              height={50}
              alt="mobile-logo"
            />
          </span>
        </div>
        {/* NAVIGATION MENU */}
        <div className="wsmainfull menu clearfix" id="wsmainfull">
          <div className="wsmainwp clearfix">
            {/* LOGO IMAGE */}
            {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 344 x 80 pixels) */}
            <div className="desktoplogo">
              <Link to={user.authenticated ? "/sessions" : "/"} className="logo-black">
                <img
                  width={230}
                  src="/images/timedoramediumlogo.png"
                  alt="header-logo"
                />
              </Link>
            </div>
            <div className="desktoplogo">
              <a href={user.authenticated ? "/sessions" : "/"} className="logo-white">
                <img
                  src="/images/timedoramediumlogo.png"
                  width={230}
                  alt="header-logo"
                />
              </a>
            </div>
            {/* MAIN MENU */}
            {(headerBtn && !user.authenticated) ?
              <Button size="small" variant="contained" onClick={mentorClicked} sx={{
                ':hover': {
                  bgcolor: '#ff1f59', // theme.palette.primary.main
                  color: 'white',
                }, width: 200, float: 'right', marginLeft: 5, marginTop: 2, height: 40, fontSize: '18px', backgroundColor: 'primary.main', textTransform: 'none'
              }}>
                Sign Up
              </Button>
              : ""}
            <nav className="wsmenu clearfix" style={{ maxHeight: "100%" }}>
              <div className="overlapblackbg" onClick={() => onClick()} />
              <ul className="wsmenu-list">

                {/* DROPDOWN MENU */}
                {
                  (!user.authenticated) &&
                  <li onClick={() => activeFun("About")}>
                    <span className={`wsmenu-click ${iconChange("About")}`}>
                      <i className="wsmenu-arrow" />
                    </span>
                    <a href="#">
                      About <span className="wsarrow" />
                    </a>
                    <ul className={`sub-menu ${activeLi("About")}`}>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/faqs">
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/pricing">
                          Pricing
                        </Link>
                      </li>
                    </ul>
                  </li>
                }
                {/* SESSION MANAGER */}
                {
                  (user.authenticated) ?
                    (<li onClick={() => activeFun("Sessions")}>
                      <span className={`wsmenu-click ${iconChange("Sessions")}`}>
                        <i className="wsmenu-arrow" />
                      </span>
                      <a href="#">
                        Sessions <span className="wsarrow" />
                      </a>
                      <ul className={`sub-menu ${activeLi("Sessions")}`}>
                        {(user.userId === 'u_r1y52Aql0OiAV4bVZw8W' || user.userId === 'u_tkUWofQTRtW7pWYiiWFcT' || user.userId === 'u_9tTHpFvHwXOx3miOvav9') &&
                          <li>
                            <Link to="/sessions?type=adminpending">
                              Pending Approval
                            </Link>
                          </li>
                        }
                        <li>
                          <Link to="/sessions?type=book">
                            Enrolled Sessions
                          </Link>
                        </li>
                        <li>
                          <Link to="/sessions?type=shared">
                            Shared Sessions
                          </Link>
                        </li>
                      </ul>
                    </li>)
                    :
                    <li className="nl-simple">
                      <Link to={'/login?type=login&redirect=' + window.encodeURIComponent(currentLocation.pathname + window.location.search)}>Login</Link></li>
                }
                {/* TOPICS HEADER MENU */}
                {
                  (user.authenticated) ?
                    (<li onClick={() => activeFun("Topics")}>
                      <span className={`wsmenu-click ${iconChange("Topics")}`}>
                        <i className="wsmenu-arrow" />
                      </span>
                      <a href="#">
                        Topics <span className="wsarrow" />
                      </a>
                      <ul className={`sub-menu ${activeLi("Topics")}`}>
                        <li>
                          <Link to="/sessions">
                            My Topics
                          </Link>
                        </li>
                        <li>
                          <Link to="/createtopic">
                            Create New Topic
                          </Link>
                        </li>
                      </ul>
                    </li>)
                    :
                    ""}
                {/* MY ACCOUNT */}
                {
                  (user.authenticated) ?
                    (<li onClick={() => activeFun("Account")}>
                      <span className={`wsmenu-click ${iconChange("Account")}`}>
                        <i className="wsmenu-arrow" />
                      </span>
                      <a href="#">
                        Account <span className="wsarrow" />
                      </a>
                      <ul className={`sub-menu ${activeLi("Account")}`}>
                        {/* user.currentView === apiproto.ViewType.ProviderView &&
                          <li>
                            <Link to="/payments">
                              App Integrations
                            </Link>
                          </li>
                */}
                        <li>
                          <Link to="/profile">Profile</Link>
                        </li>
                        <li>
                          <Link to="/setuppayment">
                            Payments
                          </Link>
                        </li>
                        {process.env.NODE_ENV !== 'production' &&
                          <li>
                            <Link to="/pricing">
                              Billing
                            </Link>
                          </li>

                        }

                        <li>
                          <Link to="/integration">
                            App Integrations
                          </Link>
                        </li>

                        <li>
                          <Link to="/resetPassword">
                            Change Password
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={logoutPressed}>Logout</Link>
                        </li>
                      </ul>
                    </li>)
                    :
                    ""}
                {/* DROPDOWN MENU 
                <li onClick={() => activeFun("En")}>
                  <span className={`wsmenu-click ${iconChange("En")}`}>
                    <i className="wsmenu-arrow" />
                  </span>
                  <a href="#" className="lang-select">
                    <img src="/images/icons/flags/uk.png" alt="flag-icon" /> En{" "}
                    <span className="wsarrow" />
                  </a>
                  <ul className={`sub-menu last-sub-menu ${activeLi("En")}`}>
                    <li>
                      <a href="#">
                        <img
                          src="/images/icons/flags/germany.png"
                          alt="flag-icon"
                        />{" "}
                        Deutch
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="/images/icons/flags/spain.png"
                          alt="flag-icon"
                        />{" "}
                        Español
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="/images/icons/flags/france.png"
                          alt="flag-icon"
                        />{" "}
                        Français
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="/images/icons/flags/italy.png"
                          alt="flag-icon"
                        />{" "}
                        Italiano
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="/images/icons/flags/russia.png"
                          alt="flag-icon"
                        />{" "}
                        Русский
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src="/images/icons/flags/china.png"
                          alt="flag-icon"
                        />{" "}
                        简体中文
                      </a>
                    </li>
                  </ul>
              </li>*/}
                {" "}
                {/* END DROPDOWN MENU */}
                {/* HEADER BUTTON 
								    <li class="nl-simple" >
								    	<a href="#" class="btn btn-rose tra-black-hover last-link">Get Started</a>
								    </li> */}
              </ul>
            </nav>

            {/* END MAIN MENU */}
          </div>
        </div>
        {/* END NAVIGATION MENU */}
      </div>
      {/* End header-wrapper */}

    </header >
  );
};

export default Header;
