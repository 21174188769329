import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import PageTitle from "../layout/PageTitle";
import * as apiproto from '../apiproto';
import { useAPI } from "../utils/useAPI";
import { RequireAuth } from "../utils/useUser";

const TriggerRender = () => {

    const api = useAPI();
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [renderId, setRenderId] = useState<string>("");
    const [status, setStatus] = useState<string>('Ready');
    const [currentUrl, setCurrentUrl] = useState<string>('');
    const [urlsProcessed, setUrlsProcessed] = useState<number>(0);
    const [errorCount, setErrorCount] = useState<number>(0);

    const triggerRender = async (triggerType: apiproto.api.TriggerType) => {
        try {
            const results = await api.triggerRender(triggerType)
            setRenderId(results.renderId!)
            setCurrentUrl("")
            setErrorCount(0);
            setUrlsProcessed(0);
            setErrorMessages([]);
        } catch (err) {
            if (err instanceof Error) {
                setErrorMessages([err.message])
            }
        }
    }

    useEffect(() => {
        const timer = setInterval(async () => {
            if (renderId !== "") {
                const results = await api.renderStatus(renderId)
                if (results.renderStatus === apiproto.api.RenderStatus.RenderStarted) {
                    setStatus("Running");
                } else if (results.renderStatus === apiproto.api.RenderStatus.RenderRunning) {
                    setCurrentUrl(results.currentUrl!)
                    setErrorCount(results.errorCount!)
                    setUrlsProcessed(results.urlsProcessed!)
                } else {
                    setStatus("Ready");
                    setErrorMessages(results.errorMessages!);
                    setRenderId("");
                    setCurrentUrl("")
                    setErrorCount(0)
                    setUrlsProcessed(0)
                }
            }
        }, 2000);
        return () => clearInterval(timer);
    });


    var messages: React.ReactElement;

    if (errorMessages.length === 0) {
        messages = <div>No Errors</div>
    } else {
        messages = <div>{
            errorMessages.map((msg, index) => {
                return <p key={index}>{msg}</p>
            })
        }</div>

    }

    return (
        <RequireAuth>
            <Layout footer={0} headerBtn={true}>
                <div className="inner-page-wrapper">
                    <PageTitle pageTitle="Render Trigger" />
                    <p>
                        Status: {status}
                    </p>
                    <p>
                        Current Url: {currentUrl}
                    </p>
                    <p>
                        Urls Processed: {urlsProcessed}
                    </p>
                    <p>
                        Error count: {errorCount}
                    </p>
                    <p>
                        <a href="#" onClick={() => triggerRender(apiproto.api.TriggerType.TriggerAll)}>Render All</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" onClick={() => triggerRender(apiproto.api.TriggerType.TriggerGeneral)}>Render general</a>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="#" onClick={() => triggerRender(apiproto.api.TriggerType.TriggerTopics)}>Render topics</a>
                    </p>

                    {messages}
                </div>
            </Layout>
        </RequireAuth>
    );
};

export default TriggerRender;
