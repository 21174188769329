import { useContext, useEffect } from "react";
import VideoPopup from "../components/VideoPopup";
import { VideoContext } from "../context/video";
import { aTagClick, stickNav } from "../utils/utils";
import Footer2 from "./Footer2";
import Footer3 from "./Footer3";
import Header from "./Header";
import React from 'react'

const Layout = ({ children, footer, headerBtn }: { children: React.ReactNode | undefined, footer?: number | undefined, headerBtn: boolean }) => {
  useEffect(() => {
    aTagClick();
    window.addEventListener("scroll", stickNav);
  });
  const videoContext = useContext(VideoContext);
  const { video } = videoContext;
  return (
    <div className="page" id="page">
      {video.show && <VideoPopup />}
      <Header headerBtn={headerBtn} />
      {children}
      {footer === 3 ? <Footer3 /> : <Footer2 />}
    </div>
  );
};

export default Layout;
