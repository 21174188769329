
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { ExtendedRoom } from "../../utils/eduutils";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAPI } from "../../utils/useAPI";
import DialogMessageBox from "../../components/DialogMessageBox";

type ChildProps = {
    room: ExtendedRoom | null | undefined,
    visible: boolean,
    meta: string | null,
    onCancel?: () => void | null,
    onConfirm?: (value: any) => void | null,
}

const ScheduleNameChangePopover: React.FC<ChildProps> = ({ room = null, visible = false, meta = "", onCancel = () => { }, onConfirm = () => { } }) => {

    const [scheduleInfoOpen, setScheduleInfoOpen] = useState(false);
    const api = useAPI();
    const submitInitialStatus = {
        isSubmitting: false,
        errorMessage: "",
    }
    const [submitStatus, setSubmitStatus] = useState(submitInitialStatus);


    const handleDone = async () => {
        setSubmitStatus({ isSubmitting: true, errorMessage: "" });
        try {
            if (scheduleSetName === room?.scheduleSet?.name) {
                setSubmitStatus({ isSubmitting: false, errorMessage: "" });
                onConfirm({ name: scheduleSetName, reload: false })
                return;
            }
            const scheduleset = room?.scheduleSet;
            if (scheduleset) {
                scheduleset.name = scheduleSetName;
                const result = await api.updateScheduleSet({ name: true }, scheduleset);
                //  onConfirm(scheduleSetName);
                setSubmitStatus({ isSubmitting: false, errorMessage: "" });
                onConfirm({ name: scheduleSetName, reload: true });
                return result;
            }
        }
        catch (err: any) {
            setSubmitStatus({ isSubmitting: false, errorMessage: "An error occurred changing your schedule set name." });
        }
    }

    const handleDismissError = () => {
        clearDialog();
        onConfirm({ name: room?.scheduleSet?.name, reload: false });
    }

    const [scheduleSetName, setScheduleSetName] = useState("");
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true, action: 0 });

    useEffect(() => {
        setScheduleInfoOpen(visible);
        setScheduleSetName(room?.scheduleSet?.name!);
    }, [visible, room])

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event) {
            event.preventDefault();
        }
        setScheduleSetName(event.target.value)
    };
    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: false, action: 0 });
    }


    return (
        <Fragment>
            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={handleDismissError} visible={dialogMessage.title.length > 0} />
            <Dialog open={scheduleInfoOpen} onClose={onCancel}>
                <DialogTitle> <IconButton
                    aria-label="close"
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change the name of your schedule set. Your schedule set helps you group your schedules so you can use the same schedules for other topics you create.
                    </DialogContentText>
                    <ValidatorForm onSubmit={handleDone}>

                        <TextValidator
                            autoFocus
                            minlength="3"
                            maxlength="32"
                            margin="dense"
                            label="Schedule Set name"
                            type="text"
                            fullWidth
                            name="schedulesetname"
                            id="schedulesetname"
                            variant="standard"
                            value={scheduleSetName}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter schedule set name"
                            validators={['required']}
                            errorMessages={['Schedule set name is required']}

                        />
                        <br /><br />
                        <LoadingButton
                            style={{ float: 'right' }}
                            size="medium"
                            variant="contained"
                            loading={submitStatus.isSubmitting}
                            type="submit"
                            disabled={scheduleSetName ? (scheduleSetName.length < 3 ? true : false) : false}>
                            Submit
                        </LoadingButton>
                    </ValidatorForm>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>

        </Fragment >)
}

export default ScheduleNameChangePopover;
