import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const [pressed, setPressed] = useState(false);

    useLayoutEffect(() => {
        if (!pressed) {
            //document.documentElement.style.scrollBehavior = 'auto';
            //setTimeout(() => window.scrollTo(0, 0), 1);
            //setTimeout(() => document.documentElement.style.scrollBehavior = 'smooth', 1);
            // TODO - somehow does not work when screen is smaller and in mobile mode. not sure why
            document.documentElement.style.scrollBehavior = 'auto';
            window.scrollTo(0, 0);
        }
    }, [pressed, pathname])

    useEffect(() => {
        setPressed(false);
        window.onpopstate = e => {
            // Back and forward button pressed detection
            setPressed(true);
            return;
        };
    }, [pathname])

    useEffect(() => {
        const body = document.querySelector("body");
        if (body?.classList.contains("wsactive")) {
            // This closes the menu in responsive mobile
            body?.classList.remove("wsactive");
        }
    }, [pathname]);

    return null;
}