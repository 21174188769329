import React from "react";
import { ControlButton, MenuItem } from "./ControlButton";
import { LayoutType } from "./DisplayContext";
import { faClone, faChalkboardTeacher, faGripHorizontal } from "@fortawesome/free-solid-svg-icons";

export interface LayoutSelectButtonProps {
    layoutType: LayoutType;
    onLayoutSelected: (layoutType: LayoutType) => void;
    className?: string;
    popoverContainerClassName?: string;
    popoverTriggerBtnClassName?: string;
    popoverTriggerBtnSeparatorClassName?: string;
}

export const LayoutSelectButton = ({
    layoutType,
    onLayoutSelected,
    className,
    popoverContainerClassName,
    popoverTriggerBtnClassName,
    popoverTriggerBtnSeparatorClassName,
}: LayoutSelectButtonProps) => {

    const layoutTypes: MenuItem<LayoutType>[] = [{ label: 'Auto', obj: LayoutType.Auto }, { label: 'Grid', obj: LayoutType.Grid }, { label: 'Presenter', obj: LayoutType.Presenter }];

    const handleMenuItem = (item: MenuItem<LayoutType>) => {
        const layoutType = layoutTypes.find((d) => d.label === item.label);
        if (layoutType && onLayoutSelected) {
            onLayoutSelected(layoutType.obj);
        }
    };

    const onClick = () => {
        // toggle layout
        let selectedIndex = 0;
        for (let a = 0; a < layoutTypes.length; a++) {
            if (layoutTypes[a].obj === layoutType) {
                selectedIndex = a;
                break;
            }
        }
        selectedIndex = (++selectedIndex) % (layoutTypes.length)
        onLayoutSelected(layoutTypes[selectedIndex].obj)
    }
    /* Not really needed now since we use tooltip and icons
        let layoutTypeLabel = "Auto";
        for (let a = 0; a < layoutTypes.length; a++) {
            if (layoutTypes[a].obj === layoutType) {
                layoutTypeLabel = layoutTypes[a].label;
                break;
            }
        }
    */
    return (
        <ControlButton
            label={""}
            tooltip={layoutType === LayoutType.Auto ? 'Auto Mode - Switch to Grid' : (layoutType === LayoutType.Grid ? 'Grid mode - Switch to Presenter' : 'Presenter Mode - Switch to Auto')}
            icon={layoutType === LayoutType.Auto ? faClone : (layoutType === LayoutType.Grid ? faGripHorizontal : faChalkboardTeacher)}
            onClick={onClick}
            menuItems={layoutTypes}
            onMenuItemClick={handleMenuItem}
            className={className}
            popoverContainerClassName={popoverContainerClassName}
            popoverTriggerBtnClassName={popoverTriggerBtnClassName}
            popoverTriggerBtnSeparatorClassName={popoverTriggerBtnSeparatorClassName}
        />
    );
};