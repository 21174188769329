import React from "react";
import Layout from "../layout/Layout";
import PageTitle from "../layout/PageTitle";

const PageNotFound = () => {
    return (
        <Layout headerBtn footer={3}>
            <div className="auth-inner">
                <div style={{ backgroundColor: 'white', alignItems: 'center', textAlign: 'center' }}>
                    <PageTitle pageTitle="404 Error - Page not Found" />
                    <h1>Oops</h1>
                    <br />
                    <h3>The page you are looking for seem to be lost in space and time.</h3>
                    <br /><br /><br />
                    <img alt="404 error" width="320" src="/images/404rocket.png" />
                </div>
            </div>
        </Layout >
    );
};

export default PageNotFound;
