import React, { Fragment, useEffect, useState } from "react";
import { api as apiproto } from "../../apiproto";
import { computeEpochtoPrettyDateStr } from "../../utils/eduutils";
import { ReadMore } from "../ReadMore";



type ChildProps = {
    ratingsCount: number[] | null | undefined,
    ratingsAverage: number | null | undefined,
    reviews: apiproto.ITopicReview[] | null,
    bar: boolean
}
// ratingsCount: the 5 different values, ratingsAverage: the average of the values, review: to show the actual text, bar: true to show distribution of ratings
const ReviewRatingsDisplay: React.FC<ChildProps> = ({ ratingsCount = [], ratingsAverage = 0, reviews = [], bar = true }) => {
    const [totalRatings, setTotalRatings] = useState<number>(0);

    useEffect(() => {
        var total = 0;
        total = 0;
        if (ratingsCount && ratingsCount.length) {
            ratingsCount.forEach((rating, index) => {
                total += rating;
            })
        }
        setTotalRatings(total);
        //setClasstimesMap(tmpclasstimesMap);
    }, [ratingsCount]);


    return (

        <Fragment>
            {(bar && totalRatings > 0) &&
                <div className="row d-flex align-items-center">
                    <div className="col-md-10 col-xl-10">
                        <div className="cs-rating-data">
                            <ul>
                                {ratingsCount && ratingsCount.slice(0).reverse().map((ratings: number, index: number) => (
                                    <li className="barWrapper clearfix" key={'rate' + index} >
                                        <div className="ratingtext-right" >
                                            <p className="p-sm">{5 - index} stars </p>
                                        </div>
                                        <div className="progress-wrapper">
                                            <div
                                                className="progress-bar"
                                                role="progressbar"
                                                style={{
                                                    width: Math.round(ratings * 100 / (totalRatings)) + '%',
                                                }}
                                                aria-valuenow={Math.round(ratings * 100 / (totalRatings))}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            />
                                        </div>
                                        {/* Percentage */}
                                        <div className="ratingtext-left">
                                            <span className="p-sm">{Math.round(ratings * 100 / totalRatings)}% ({ratings} {ratings === 1 ? "rating" : "ratings"})</span>
                                        </div>
                                    </li>))}
                            </ul>
                        </div>
                    </div>
                    <br />

                </div>

            }
            {(ratingsCount && ratingsAverage && totalRatings > 0) ?
                <div className="course-rating clearfix">
                    {ratingsAverage >= 1 ?
                        <i className="fas fa-star" /> : ""}
                    {ratingsAverage >= 2 ?
                        <i className="fas fa-star" /> : ""}
                    {ratingsAverage >= 3 ?
                        <i className="fas fa-star" /> : ""}
                    {ratingsAverage >= 4 ?
                        <i className="fas fa-star" /> : ""}
                    {ratingsAverage >= 5 ?
                        <i className="fas fa-star" /> : ""}
                    {(ratingsAverage - Math.floor(ratingsAverage)) > 0 ? <i className="fas fa-star-half-alt" /> : ""}
                    ({ratingsAverage.toFixed(1)}/5) with {totalRatings} {totalRatings === 1 ? "Rating" : "Ratings"}
                </div> : "No Reviews yet"}
            <br /><br />
            {/* TESTIMONIAL #1 */}
            {reviews && reviews!.map((review: apiproto.ITopicReview, index) => (
                <div className="review-4" key={'rvw' + index}>
                    <div className="review-4-txt">
                        {/* Author Data */}
                        <div className="review-4-author d-flex align-items-center mb-10">
                            {/* Testimonial Author */}
                            <div className="review-author">
                                {/* Rating */}
                                <div className="course-rating clearfix mb-10">
                                    {review.rating! >= 1 ?
                                        <i className="fas fa-star" /> : ""}
                                    {review.rating! >= 2 ?
                                        <i className="fas fa-star" /> : ""}
                                    {review.rating! >= 3 ?
                                        <i className="fas fa-star" /> : ""}
                                    {review.rating! >= 4 ?
                                        <i className="fas fa-star" /> : ""}
                                    {review.rating! >= 5 ?
                                        <i className="fas fa-star" /> : ""}
                                    {(review.rating! - Math.floor(review.rating!)) > 0 ? <i className="fas fa-star-half-alt" /> : ""}

                                </div>
                                <br />
                                <b className="mb-10">{review.lastName} {review.firstName}</b>
                                - {computeEpochtoPrettyDateStr(review.createDate!.toString(), 'MMM Do YYYY')}

                            </div>
                        </div>
                        {/* Text */}
                        <ReadMore min={600} ideal={650} max={700}>
                            {review.review ? review.review : ""}
                        </ReadMore>
                    </div>


                </div>

            ))}

        </Fragment>
    );
};

export default ReviewRatingsDisplay;
