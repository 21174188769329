import { LoadingButton } from "@mui/lab";
import React, { ChangeEvent, useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useLocation, useNavigate } from "react-router-dom";
import DialogMessageBox from "../components/DialogMessageBox";
import Layout from "../layout/Layout";
import { useAPI } from "../utils/useAPI";
import { useUser } from "../utils/useUser";
import { AuthContext } from "../context/authcontext";
import PageTitle from "../layout/PageTitle";


// This is not me button

export const ChangePassword = () => {

    const search = useLocation().search;
    const email = new URLSearchParams(search).get('e');
    const code = new URLSearchParams(search).get('vc');

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [validateMessage, setValidateMessage] = useState<string>('');
    const [validationCode, setValidationCode] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true, action: 0 });
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const passwordValidators = ['required', 'minStringLength:8', 'maxStringLength:32'];
    const passwordValidatorMessages = ['Password is required', 'Enter 8 characters or more', 'Enter 32 characters or less'];
    const [resendSubmitting, setResendSubmitting] = useState<boolean>(false);

    const api = useAPI();
    var user = useUser();
    const navigate = useNavigate();
    const { dispatch } = React.useContext(AuthContext);

    useEffect(() => {
        if (code === null && !user.authenticated) {
            setDialogMessage({ title: 'Please login', message: 'You will be sent to the login page', meta: '', confirm: false, action: 0 });
            return;
        }
        /*
        if (user.authenticated) {
            setValidateMessage('We\'ve sent a verification code to your email!');
            requestValidation();
        }*/
        console.log(code);
        if (code !== null) {
            setValidationCode(code);
            // This is click through from email to validate instead of showing UI
        }
        // check message
        setValidateMessage('');
    }, [email, code])

    useEffect(() => {
        if (resendSubmitting) {
            let interval = setTimeout(() => {
                setResendSubmitting(false);
            }, 60000);
            return () => clearInterval(interval);
        }
    }, [resendSubmitting])


    useEffect(() => {
        if (confirmPassword === newPassword && newPassword !== '') {
            setSubmitDisabled(false);
            // This is click through from email to validate instead of showing UI
        }
        else setSubmitDisabled(true);
        // check message
    }, [newPassword, confirmPassword])

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (confirmPassword !== newPassword) {
            return false;
        }
        return true;
    });

    const notMe = () => {
        setDialogMessage({ title: 'Not me?', message: 'Someone may be resetting your password. Your password will not be reset. You will now be directed back to the login page.', meta: '', confirm: false, action: 0 });
    }

    const handleCancel = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true, action: 0 });
        if (user.authenticated) {
            dispatch({ type: 'logout' });
        }
        navigate("/login");
    }

    const requestValidation = () => {
        (async () => {
            try {
                await api.sendValidationCode("", true);
                setCodeSent(true);
                setResendSubmitting(true);
                setValidateMessage('Validation code sent. Please enter the validation code to set new password.');
            } catch (e: any) {
                if (user.authenticated) {
                    setValidateMessage('');
                    //setValidateMessage('Problem sending validation code - ' + (e as Error).message);
                }
                else setValidateMessage('You need to be logged in to resend verification code')
                if (e.name === "VERIFICATION_SENT_LIMIT_EXCEEDED") {
                    setValidateMessage('You just sent a verification code. Please check your email or spam folder. Otherwise, wait a minute or so to send another code. Thanks!')
                }
                else {
                    setValidateMessage('There is an error sending your verification code. If you already requested a verification code, please check your email or spam folder. Otherwise, wait a minute or so to send another code. Thanks!')
                }
            }
        })();
    }

    const passwordChanged = () => {
        (async () => {
            try {
                setIsSubmitting(true);
                if (email !== null) {
                    await api.validate(email, validationCode, newPassword);
                    setValidateMessage('Congratulations, your password has been successfully changed.');
                    setDialogMessage({ title: 'Success!', message: 'Your password has been successfully changed. You will be logout. Please login again with new password', meta: '', confirm: false, action: 0 });
                }
                else {
                    if (user.authenticated) {
                        await api.validate("", validationCode, newPassword);
                        setValidateMessage('Congratulations, your password has been successfully changed.');
                        setDialogMessage({ title: 'Success!', message: 'Your password has been successfully changed. You will be logout. Please login again with new password', meta: '', confirm: false, action: 0 });
                    }
                    else {
                        setValidateMessage('You need an email to reset your password.');
                    }
                }
            } catch (e) {
                let message = ((e as Error).name);
                if (message === "EXPIRED_CODE") {
                    setValidateMessage('Your code has expired. Please click Send Verification Code to get a new code.')
                }
                else {
                    setValidateMessage('Your code may be wrong.');
                }
                setIsSubmitting(false);
            }
        })();
    }

    return (
        <Layout headerBtn={false} footer={3}>
            <PageTitle pageTitle="Change Password | Timedora" />
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={handleCancel} visible={dialogMessage.title.length > 0} />
            <div className="auth-inner">
                <ValidatorForm onSubmit={passwordChanged}>
                    <p className="form-error">{validateMessage}</p>
                    <br />
                    {(code || codeSent) &&
                        <div>
                            <TextValidator
                                label="Verification code"
                                type="text"
                                value={validationCode}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setValidationCode(event.target.value)
                                }}

                                autoComplete="none"
                                style={{ width: '100%' }}
                                name="passwordvcode"
                                id="passwordvcode"
                                className="form-group"
                                placeholder="Enter code"
                                validators={['required']}
                                errorMessages={['Verification code required']}
                            />

                            <TextValidator
                                label="New password"
                                type="password"
                                value={newPassword}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setNewPassword(event.target.value)
                                }}
                                style={{ width: '100%', marginTop: '10px' }}
                                name="password"
                                id="password"
                                className="form-group"
                                placeholder="Enter new password"
                                validators={passwordValidators}
                                errorMessages={passwordValidatorMessages}
                            />

                            <TextValidator
                                label="Confirm password"
                                type="password"
                                value={confirmPassword}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    setConfirmPassword(event.target.value)
                                }}
                                style={{ width: '100%', marginTop: '10px' }}
                                name="newpassword"
                                id="newpassword"
                                className="form-group"
                                placeholder="Confirm new password"
                                validators={[...passwordValidators, 'isPasswordMatch']}
                                errorMessages={[...passwordValidatorMessages, 'Passwords must match']}
                            />
                            <LoadingButton type="submit" style={{ width: '100%', marginTop: '10px' }} disabled={submitDisabled} variant="contained" size="large" onClick={() => {
                            }}>
                                CHANGE PASSWORD
                            </LoadingButton>
                            {!user.authenticated &&
                                <LoadingButton style={{ width: '100%', marginTop: '20px' }} loading={isSubmitting} size="small" onClick={notMe}>
                                    This is not me
                                </LoadingButton>}

                        </div>}

                    <LoadingButton style={{ width: '100%', marginTop: '10px' }} disabled={resendSubmitting} variant={(code || codeSent) ? "text" : "contained"} size="large" onClick={requestValidation}>
                        {resendSubmitting ? 'You can resend code again in 60 seconds' : 'Send Verification Code'}
                    </LoadingButton>

                </ValidatorForm>
            </div>
            <br /><br /><br /><br />
        </Layout >
    );
};



export default ChangePassword;