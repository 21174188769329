import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { api } from "../apiproto";
import { AuthContext } from "../context/authcontext";

interface UserState {
    authenticated: boolean;
    userId: string | null;
    currentView: api.ViewType;
}

export const useUser = () => {
    const { state } = React.useContext(AuthContext);

    const userState: UserState = {
        authenticated: state.token != null && state.userId != null && state.expirationTime != null && state.expirationTime > (new Date()).getTime(),
        userId: state.userId,
        currentView: state.currentView ? state.currentView : api.ViewType.ConsumerView
    }
    return userState;
}

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const user = useUser();
    const location = useLocation();
    if (user.authenticated) {
        return <div>{children}</div>;
    } else {
        return <Navigate to={"/login?type=login&redirect=" + window.encodeURIComponent(location.pathname + location.search)} state={{ from: location }
        } />
    }
}
