import React from "react";
import MainHero1 from "../components/Main/MainHero1";
import MainAbout3 from "../components/Main/MainAbout3";
//import MainCourses1 from "../components/Main/MainCourses1";
import MainPricing2 from "../components/Main/MainPricing2";
//import MainAbout2 from "../components/Main/MainAbout2";
//import MainBanner3 from "../components/Main/MainBanner3";
//import MainBanner5 from "../components/Main/MainBanner5";
//import MainCategories4 from "../components/Main/MainCategories4";
//import MainCourses3 from "../components/Main/MainCourses3";
//import MainCourses5 from "../components/Main/MainCourses5";
//import MainHero2 from "../components/Main/MainHero2";
//import MainNews2 from "../components/Main/MainNews2";
//import MainNewsLetter1 from "../components/Main/MainNewsLetter1";
//import MainReviews1 from "../components/Main/MainReviews1";
import Layout from "../layout/Layout";
import PageTitle from "../layout/PageTitle";
import { useUser } from "../utils/useUser";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
  const user = useUser();
  const navigate = useNavigate();
  if (user.authenticated) {
    navigate("/sessions");
  }
  return (
    <Layout footer={2} headerBtn={true}>
      <PageTitle pageTitle="Free Online Appointment Scheduling & Booking Software - Timedora" />
      <MainHero1 />
      {/*}      <MainHero2 />*/}
      {/* END HERO-2 */}
      {/* ABOUT-2
                  ============================================= */}
      {/*      <MainAbout2 />*/}
      {/* End ABOUT-2 */}
      {/* ABOUT-3
                  ============================================= */}
      <MainAbout3 />
      {/* End ABOUT-3 */}

      {/* COURSES-1
                  ============================================= */}
      {/* take off for now since we don't have anyone <MainCourses1 /> */}
      {/* END COURSES-1 */}


      {/* CATEGORIES-4
                  ============================================= */}
      {/* Hide this for now  <MainCategories4 /> */}
      {/* END CATEGORIES-4 */}
      {/* BANNER-3
                  ============================================= */}
      {/*<MainBanner3 />*/}
      {/* END BANNER-3 */}
      {/* COURSES-3
                  ============================================= */}
      {/* <MainCourses3 /> */}
      {/* END COURSES-3 */}
      {/* PAGE PAGINATION
                  ============================================= */}

      {/* <MainCourses5 /> */}
      {/* END COURSES-5 */}
      {/* PRICING-2
                  ============================================= */}
      <MainPricing2 />
      {/* END PRICING-2 */}
      {/* TESTIMONIALS-1
                  ============================================= */}
      {/*<MainReviews1 />*/}
      {/* END TESTIMONIALS-1 */}
      {/* BANNER-5
                  ============================================= */}
      {/*<MainBanner5 />*/}
      {/* END BANNER-5 */}
      {/* NEWS-2
                  ============================================= */}
      {/*<MainNews2 />*/}
      {/* END NEWS-2 */}
      {/* NEWSLETTER-1
                  ============================================= */}
      {/*<MainNewsLetter1 />*/}
    </Layout>
  );
};

export default MainPage;
