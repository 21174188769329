import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { api as apiproto } from "../../apiproto";
import { computeEpochtoPrettyDateStr, computeEpochtoTimeStr, ExtendedSchedule, ExtendedTime, generateTopicURL, getTimeZone, IScheduleCard, isUserOwnerOfTopic, scheduleOneTimeComputation, TimeAction } from "../../utils/eduutils";
import DialogMessageBox from "../DialogMessageBox";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import ScheduleInfoPopover from "../../pages/course/scheduleinfoinputdialog";
import { tdrLog } from "../../utils/utils";
import AddIcon from '@mui/icons-material/Add';
import { Button, Skeleton } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import Calendar from 'react-calendar'
import { VideoStartButton } from "../conference/VideoStartButton";

type ChildProps = {
    topic: apiproto.ITopic | null,
    roomid: string | null,
    schedule: ExtendedSchedule | null, // This is passed if timetable needs to be shown for a specific schedule or room ID
    bookingCallback?: (value: ExtendedTime) => void | null,
    listType?: boolean,
    calendar?: boolean,
    type?: string, // the type of sessions timetable is shown in such as enrolled
    forceRefresh?: number,
    userInfo?: apiproto.IUser | null,
    showVideoButton?: boolean,
    refreshCallback?: () => void | null
}


const TimeTable: React.FC<ChildProps> = ({ topic = null, roomid = null, schedule = null, bookingCallback = null, listType = false, calendar = false, type = "", forceRefresh = 0, userInfo = null, showVideoButton = false, refreshCallback = null }) => {

    const [availableDates, setAvailableDates] = useState<Set<string> | null>(null);
    const [nextTimeSlot, setNextTimeSlot] = useState(Number.MAX_SAFE_INTEGER);
    const [nextTimeSlotStr, setNextTimeSlotStr] = useState("None");
    const [activeScheduleCard, setActiveScheduleCard] = useState<IScheduleCard | null>(null);
    const [timeSlots, setTimeSlots] = useState<ExtendedTime[][]>([]);
    const [slotEndIndex, setSlotEndIndex] = useState(0);
    const latestTime = new Date().getTime();
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true, action: 0 });
    const api = useAPI();
    const user = useUser();
    const [open, setOpen] = useState(false);
    const [activeEditedTime, setActiveEditedTime] = useState<apiproto.ITime | null>(null);
    const [isUserOwner, setUserOwner] = useState(false);
    const [activeSchedule, setActiveSchedule] = useState<ExtendedSchedule | null>(null);
    const [calValue, setCalValue] = useState("");
    const dateStrRep = 'ddd MMM Do yy';
    const [firstAvailableDate, setFirstAvailableDate] = useState<Date | null>(null);
    const [topicURL, setTopicURL] = useState("");

    const handleScheduleInfoClose = () => {
        setOpen(false);
        setActiveEditedTime(null);
    };

    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true, action: 0 });

    }

    const getCurrentUser = async () => {
        if (user && user.userId) {
            try {
                const userResult = await api.getUser({ reviewsByUser: {}, reviewsOfUser: {}, membersOf: { ownerFilter: { filterValue: true }, instructorFilter: { filterValue: true }, obj: { room: {}, time: {} } } }, { userId: user.userId });
                if (userResult && userResult.user) {
                    const usertmpInfo = userResult.user;
                    if (topic) {
                        setUserOwner(isUserOwnerOfTopic(usertmpInfo, topic));
                    }
                    return usertmpInfo;
                }
            }
            catch (err: any) {
                // We don't throw this error back since the IDs may have been deleted for some reason
                // and this not necessarily a fatal error.
                tdrLog(err.message);
            }
        }
        return null;

    }


    const timeSlotClick = async (time: ExtendedTime) => {

        if (time.schedule === null) {
            time.schedule = schedule;
        }
        if (showVideoButton) {
            // We change the active schedule so the clicking will cause active schedule to change and the conference to start that date
            const scheduleCard = {
                roomId: (roomid ? roomid : ""),
                timeId: time.timeId!,
                starttime: time.startTime!,
                endtime: time.endTime!,
                schedules: [],
                sessions: 0,
                duration: (+time.endTime! - +time.startTime!),
                membersize: 0,
                enrolled: true
            }
            setActiveScheduleCard(scheduleCard);
        }
        else if (isUserOwner) {
            // Only owners are allow to edit
            setActiveEditedTime(time);
            setOpen(true);
        }
        else {
            if (bookingCallback !== null) {
                bookingCallback(time)
            }
            else {
                return;
            }
        }
    }
    /* Old code when we supported disabling
    const timeDisable = async (time: apiproto.ITime) => {
        try {
            if (time.disabled) {
                // We renable it
                time.disabled = false;
                await api.updateTime({ disabled: true }, time, false);
                setDialogMessage({ title: 'Time slot enabled', message: 'Timeslot for ' + day + ' at ' + timeday + ' is now enabled. The slot is available for booking.', meta: '', confirm: false });

            }
            else {
                // We disable it
                time.disabled = true;
                await api.updateTime({ disabled: true }, time, false);
                setDialogMessage({ title: 'Time slot disabled', message: 'Timeslot for ' + day + ' at ' + timeday + ' is now disabled and not available for booking', meta: '', confirm: false });
            }
        }
        catch (err: any) {
            setDialogMessage({ title: 'Error', message: "An error occured changing status of the time slot.", meta: '', confirm: false });

        }
    }
*/
    const computeTimeTable = (topic: apiproto.ITopic) => {
        var availableDate: Set<string> = new Set([]);
        //tdrLog('One time compute:' + JSON.stringify(topic));
        var sortedTimes: ExtendedTime[] = scheduleOneTimeComputation(topic, roomid, schedule, userInfo);
        if (sortedTimes.length === 0) {
            setTimeSlots([]);
            setSlotEndIndex(0);
            return [];
        }
        var timetable: ExtendedTime[][] = [];
        var timeForTheDay: ExtendedTime[] = [];
        var minTime = Number.MAX_SAFE_INTEGER;
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var currentTime = today.getTime() - 24 * 60 * 60 * 1000;
        var scheduleCard: IScheduleCard;
        /*** Dont think we need this. Causes old dates to be inserted as first date.
        const firstTime = sortedTimes[0].startTime! as number;
        var lastDay = new Date(firstTime);        
        const thedate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
        availableDate.add(thedate.toString());
        */
        let lastDay: Date | null = null;
        for (let index = 0; index < sortedTimes.length; index++) {
            let time = sortedTimes[index];
            var timeDate = new Date(new Date(+time.startTime!).toLocaleString('en', { timeZone: getTimeZone() }));
            //tdrLog("Time " + time + "TimeDate " + timeDate.getDate().toString() + "-- - " + lastDay.getDate().toString());
            if (time.startTime! > currentTime) {
                if (lastDay !== null && timeDate.getFullYear() === lastDay.getFullYear() && timeDate.getMonth() === lastDay.getMonth() && timeDate.getDate() === lastDay.getDate()) {
                    timeForTheDay.push(time);
                }
                else {
                    lastDay = timeDate;
                    //const thedate = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
                    //                    const thedate = new Date(new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate()).toLocaleString('en-US', { timeZone: getTimeZone() }));
                    const convertedDate = timeDate.getMonth() + "/" + timeDate.getDate() + "/" + timeDate.getFullYear();
                    //                    availableDate.add(thedate.toString());
                    availableDate.add(convertedDate);

                    timetable.push(timeForTheDay);
                    timeForTheDay = [time];
                    //tdrLog("==========")
                }
                // Need this because the last date will not get pushed without below so missing the times for all the last dates
                if ((index + 1) === sortedTimes.length) {
                    // timetable.push(timeForTheDay);
                }
            }
            if ((time.startTime! > latestTime || time.endTime! > latestTime) && time.startTime! < minTime) {
                minTime = time.startTime! as number;
                scheduleCard = {
                    roomId: time.roomId!,
                    timeId: time.timeId!,
                    starttime: time.startTime!,
                    endtime: time.endTime!,
                    schedules: [],
                    sessions: 0,
                    duration: (+time.endTime! - +time.startTime!),
                    membersize: 0,
                    enrolled: true
                }
                setActiveScheduleCard(scheduleCard);
            }

        }

        setNextTimeSlot(minTime);
        if (minTime !== Number.MAX_SAFE_INTEGER) {
            setNextTimeSlotStr(computeEpochtoPrettyDateStr(minTime.toString(), 'ddd, MMM Do') + ' ' + computeEpochtoTimeStr(minTime.toString()));
        }

        // Happens only 1 day
        if (timetable[0] != null && timetable[0].length === 0) timetable.push(timeForTheDay);
        if (timetable.length > 0 && minTime === Number.MAX_SAFE_INTEGER && timeForTheDay.length > 0) {
            // If no future sessions ready to start, we still allow for the most recent one

            const firstTime = timeForTheDay[0];
            if (firstTime && firstTime.startTime) {
                minTime = +firstTime.startTime;
                scheduleCard = {
                    roomId: firstTime.roomId!,
                    timeId: firstTime.timeId!,
                    starttime: firstTime.startTime!,
                    endtime: firstTime.endTime!,
                    schedules: [],
                    sessions: 0,
                    duration: (+firstTime.endTime! - +firstTime.startTime!),
                    membersize: 0,
                    enrolled: true
                }
                setActiveScheduleCard(scheduleCard);
            }
        }

        setTimeSlots(timetable);
        setSlotEndIndex(10);
        setAvailableDates(availableDate);
        if (minTime !== Number.MAX_SAFE_INTEGER && firstAvailableDate === null) {
            // If we haven't set before,we set to show the first time available. 
            // However, if we set it before, then we don't want to change where the user
            // already clicked.
            const firstDate = new Date(minTime);
            calendarDayClicked(firstDate);
            setFirstAvailableDate(firstDate);
        }
    }

    const isDateAvailable = (thedate: Date) => {
        const convertedDate = thedate.getMonth() + "/" + thedate.getDate() + "/" + thedate.getFullYear();
        if (availableDates === null) return true;
        if (availableDates.has(convertedDate)) {
            return false;
        }
        return true;
    }

    const refreshParent = () => {
        // Need to refresh schedule cards and other topi related stuff due to changes in time
        if (refreshCallback !== null) {
            refreshCallback();
        }
    }
    const modifyTime = (time: apiproto.ITime, action: TimeAction) => {
        if (action === TimeAction.update) {
            modifyAsyncTime(time, false);
        }
        else if (action === TimeAction.add) {
            addAsyncTime(time, false);
        }
        else if (action === TimeAction.deletesingle) {
            deleteAsyncTime(time, false);
        }
        else if (action === TimeAction.deletefuture) {
            deleteAsyncFutureTime(time, false);
        }
        refreshParent();
    }


    const addTime = () => {
        // Open the popover for inputting
        setOpen(true);
    }

    const addAsyncTime = async (time: apiproto.ITime, force: boolean) => {
        try {
            time.scheduleId = schedule?.scheduleId;
            await api.addTime(time);
            setOpen(false);
            setActiveEditedTime(null);
            getTopic();
        }
        catch (err: any) {
            if (err.name === "FORCE_REQUIRED") {
                // TODO: This probably should not happen since this is a add time but if you add more time to a room, would that cause such a thing?
                setDialogMessage({ title: 'Add Time', message: 'Are you sure you want to add this time? This may affect existing participants schedule.', meta: time, confirm: true, action: TimeAction.add });
            }
            else {
                setDialogMessage({ title: 'Error', message: 'An error occurred while trying to add the time', meta: "", confirm: false, action: TimeAction.noop });
            }
        }
    }

    const modifyAsyncTime = async (time: apiproto.ITime, force: boolean) => {
        try {
            await api.updateTime({ startTime: true, endTime: true }, time, force);
            setOpen(false);
            setActiveEditedTime(null);
            getTopic();

        }
        catch (err: any) {
            if (err.name === "FORCE_REQUIRED") {
                setDialogMessage({ title: 'Modify Time', message: 'Are you sure you want to modify this time? This will affect participants enrolled for this time and may cause their bookings to be canceled.', meta: time, confirm: true, action: TimeAction.update });
            }
            else {
                setDialogMessage({ title: 'Error', message: 'An error occurred', meta: "", confirm: false, action: TimeAction.noop });

            }
        }
    }

    const deleteAsyncTime = async (time: apiproto.ITime, force: boolean) => {
        try {
            await api.deleteTime(time.timeId!, force);
            setOpen(false);
            setActiveEditedTime(null);
            getTopic();

        }
        catch (err: any) {
            if (err.name === "FORCE_REQUIRED") {
                setDialogMessage({ title: 'Delete Time', message: 'Are you sure you want to delete this time? This will affect participants enrolled for this time and will cause their bookings to be canceled.', meta: time, confirm: true, action: TimeAction.deletesingle });
            }
            else {
                setDialogMessage({ title: 'Error', message: 'An error occurred', meta: "", confirm: false, action: 1 });
            }
        }
    }

    const deleteAsyncFutureTime = async (time: apiproto.ITime, force: boolean) => {
        try {
            if (schedule?.scheduleId !== null) {
                await api.truncateSchedule(schedule?.scheduleId!, +time.startTime!, force);
                getTopic();
            }
            setOpen(false);
            setActiveEditedTime(null);

        }
        catch (err: any) {
            if (err.name === "FORCE_REQUIRED") {
                setDialogMessage({ title: 'Delete Time', message: 'Are you sure you want all future time slots from this time? This will affect participants enrolled for this time and all future times. It will also cause their bookings to be canceled.', meta: time, confirm: true, action: TimeAction.deletefuture });
            }
            else {
                setDialogMessage({ title: 'Error', message: 'An error occurred', meta: "", confirm: false, action: 1 });
            }
        }
    }

    const handleActionConfirm = () => {
        if (dialogMessage.action === TimeAction.update) {
            // Confirm delete schedule or time
            modifyAsyncTime(dialogMessage.meta, true);
        }
        else if (dialogMessage.action === TimeAction.deletesingle) {
            deleteAsyncTime(dialogMessage.meta, true);
        }
        else if (dialogMessage.action === TimeAction.deletefuture) {
            deleteAsyncFutureTime(dialogMessage.meta, true);
        }

        clearDialog();
    }

    // TOOD, server should have API to allow fetch on times for a roomid, 
    const getTopic = async () => {
        const topicInfo = await api.getTopic({ includeDescription: true, members: { entity: { user: { includeDescription: false } } }, rooms: { members: {}, scheduleSet: { schedules: { times: { members: {} } } } } }, topic?.topicId!);
        if (topicInfo.topic) {
            tdrLog("Timetable - gettopic")
            //            tdrLog(JSON.stringify(topicInfo.topic));
            computeTimeTable(topicInfo.topic);
        }
    }

    const calendarDayClicked = (value: Date) => {
        // We do not use computeEpoch because it will change the time zone
        // Since this calendar widget is not time zone aware we need it to just be the date as is
        var moment = require('moment-timezone');
        const dayStr = moment(value).format(dateStrRep);
        //        const dayStr = computeEpochtoPrettyDateStr(value.getTime().toString(), dateStrRep);
        setCalValue(dayStr)
    }

    useEffect(() => {
        setActiveSchedule(schedule);
        if (topic?.topicId) {
            setTopicURL(generateTopicURL(topic));
            if (topic.rooms) {
                computeTimeTable(topic)
            }
            else {
                // This comes from creation where we use extended room so we refetch. We do this special case because we
                // don't want to refetch for every topic id when it is already passed because listings use this component and if there are 
                // 10 listings, then you cause 10 refetches from this component which is bad.
                getTopic();
            }
            getCurrentUser();
        }
    }, [topic?.topicId, forceRefresh, userInfo?.userId]);

    //    }, [topic, roomid, schedule, bookingCallback]);

    return (
        <Fragment>
            <ScheduleInfoPopover topic={topic} room={null} roomid={roomid} schedule={activeSchedule} time={activeEditedTime} visible={open} meta={{ room: null, schedule: activeSchedule }} onCancel={handleScheduleInfoClose} onConfirm={handleScheduleInfoClose} onModifyTime={modifyTime} editMode={true} editTime={true} />

            < DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Cancel" : "Dismiss"} onCancel={clearDialog} onConfirm={handleActionConfirm} visible={dialogMessage.title.length > 0} />


            {timeSlots.length === 0 ? <div className="divpadding">{type !== "book" ? ((isUserOwner && calendar) ? "" : "There are no available sessions.") : "No future sessions."}</div> :
                <Fragment>

                    {!listType ?
                        <Fragment>
                            {isUserOwner && schedule?.scheduleId &&
                                <Row>
                                    <Col>
                                        <Button sx={{ mb: 1, mr: 2, mt: 1 }} size="small" variant="text" startIcon={<AddIcon />} onClick={addTime}>
                                            Add Time
                                        </Button>
                                    </Col>
                                    <Col>
                                        <div style={{ float: 'right', marginTop: '8px', marginLeft: '12px', color: 'black', fontSize: '12px' }}>Time Zone: {schedule.timeZone}</div>
                                    </Col>
                                </Row>

                            }
                            <Row>
                                {(availableDates !== null && availableDates?.size > 0 && calendar) &&
                                    <Col style={{ minWidth: 320 }} md={calendar ? 8 : 12} xs="12">

                                        <Calendar
                                            tileClassName={({ date }) => {
                                                if (date) {
                                                    return 'selected';
                                                }
                                                return null;
                                            }}
                                            tileDisabled={({ date }) => isDateAvailable(date)}
                                            onClickDay={(value, event) => calendarDayClicked(value)}
                                        />
                                    </Col>
                                }
                                <Col className="align-top">
                                    <Row>
                                        <Col>
                                        </Col>
                                        <Col>
                                            { }
                                        </Col>
                                    </Row>

                                    <div className="timeslot-parent">
                                        <div style={{ position: 'relative', top: 0, left: 0 }}>
                                            {showVideoButton ?
                                                <VideoStartButton scheduleCard={activeScheduleCard} topicInfo={topic} width={'100%'} showDate={true} /> :
                                                (!calendar &&
                                                    <div className="timeslot-next row">
                                                        <b>Next Session<br />{nextTimeSlotStr}</b>
                                                    </div>
                                                )}
                                        </div>
                                        {timeSlots != null ?
                                            <div className={calendar ? "timeslotBoxesWithCal" : "timeslotBoxes"}>
                                                {
                                                    timeSlots.map((timearray: ExtendedTime[], index) => (
                                                        <div key={'ta' + index}>
                                                            {(calendar || index < slotEndIndex) && ((calendar && timearray && timearray.length > 0 && timearray[0].startDateStr === calValue) || !calendar) &&
                                                                < div key={topic?.topicId! + index}>
                                                                    {timearray && timearray.length > 0 &&
                                                                        <div className="row">
                                                                            <div className="col timeslotHeader">
                                                                                <b>{timearray[0].startDateStr}</b>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="row">
                                                                        {timearray.map((time: ExtendedTime, index) => (
                                                                            <div className={time.enrolled ? "col-auto timeslot-enrolled" : (time.startTime! > latestTime && /*!time.disabled && */ !time.fullyBooked) ? ((activeScheduleCard?.timeId === time.timeId) ? "col-auto timeslot-active" : "col-auto timeslot") : "col-auto timeslot-disabled"} key={time.timeId}>
                                                                                {
                                                                                    <Link className={time.enrolled ? "timeslotLinkEnrolled" : "timeslotLink"} to="#" onClick={(e) => { e.preventDefault(); timeSlotClick(time) }}>
                                                                                        {time.fromTime}
                                                                                    </Link>
                                                                                }
                                                                            </div>

                                                                        ))}
                                                                    </div>
                                                                </div>

                                                            }
                                                            {(!calendar && slotEndIndex === index) ? <Link style={{ float: 'right', marginTop: '5px' }} to={topicURL}>more...</Link> : ""}

                                                        </div>))
                                                }

                                            </div> :
                                            <Skeleton variant="rectangular" sx={{ width: '100%', height: 500 }} />}
                                    </div>
                                </Col>
                            </Row>
                        </Fragment>
                        :
                        <div>
                            <br />
                            {timeSlots.map((timearray: ExtendedTime[], index) => (
                                timearray.map((time: ExtendedTime, index1) => (
                                    <div className={(time.startTime! > latestTime /*&& !time.disabled*/) ? ((time.startTime === nextTimeSlot || activeScheduleCard?.timeId === time.timeId) ? "timeslotBox-lg-active" : "timeslotBox-lg") : "timeslotBox-lg-disabled"} key={time.timeId}>
                                        {
                                            time.startTime! > latestTime ?
                                                <Link className="timeslotLink" to="#" onClick={() => timeSlotClick(time)}>
                                                    <b><Row><Col>{time.startDateStr}<br />{time.fromTime} - {time.toTime}</Col></Row></b>
                                                </Link> :
                                                <Row><Col>{time.startDateStr}<br />{time.fromTime} - {time.toTime}</Col></Row>
                                        }
                                    </div>

                                ))


                            ))}
                        </div>

                    }
                </Fragment>
            }
        </Fragment >
    );
};

export default TimeTable;



