import { faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Track, VideoTrack, Room, RoomEvent, LocalVideoTrack } from "livekit-client";
import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MobileView } from "./MobileView";
import { GridView } from "./GridView";
import { SpeakerView } from "./SpeakerView";
import { ConferenceControlView } from "./ConferenceControl";
import { DisplayContext, LayoutType } from "./DisplayContext";
import { RoomState } from "./useRoom";
import { AudioRenderer } from "./AudioRenderer";
import "./conference.css";
import { LobbyView } from "./LobbyView";
import { ChatBox } from "./ChatBox";
import Layout from "../../layout/Layout";
import { tdrAlert, tdrLog } from "../../utils/utils";
import { ConferenceParams } from "./conference";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
export interface ConferenceProps {
    roomState: RoomState;
    onLeave?: (room: Room) => void;
    joinLeaveClicked?: () => void;
    chatClicked?: () => void;
    windowWidth?: number;
    windowHeight?: number;
}

export const ConferenceView = (confProps: ConferenceProps) => {
    const params = useParams<ConferenceParams>();
    const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
    const { room, participants } = confProps.roomState;
    const context = useContext(DisplayContext);
    const [participantList, setParticipantList] = useState("");
    const [cameraEnabled, setCameraEnabled] = useState<boolean>(false);
    const [videoDeviceId, setVideoDeviceId] = useState<string | null>(null);
    const [microphoneEnabled, setMicrophoneEnabled] = useState<boolean>(false);
    const [audioDeviceId, setAudioDeviceId] = useState<string | null>(null);
    const [localVideoTrack, setLocalVideoTrack] = useState<LocalVideoTrack | null>(null);
    const [chatboxEnabled, setChatboxEnabled] = useState<boolean>(false)
    const [videoDevices, setVideoDevices] = React.useState<MediaDeviceInfo[]>([]);
    const recipientId = params.classroomOrTimeId;

    let { displayOptions, displayOptionsDispatch } = useContext(DisplayContext)

    const hasWindow = typeof window !== 'undefined';
    const [windowSize, setWindowSize] = useState({
        x: 320,
        y: 240
    });
    const handleResize = () => {
        if (hasWindow) {
            setWindowSize({
                x: window.innerWidth,
                y: window.innerHeight
            });
            confProps.windowWidth = window.innerWidth;
            confProps.windowHeight = window.innerHeight;
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [hasWindow]);

    const listVideoDevices = useCallback(async () => {
        let devices = [];
        try {
            devices = await Room.getLocalDevices("videoinput");
            if (devices !== null) {
                setVideoDevices(devices);
            }
        }
        catch (err) {
        }
        if (devices.length === 0) {
            setCameraEnabled(false);
            if (displayOptions.connectState) {
                if (room) {
                    await room.localParticipant.setCameraEnabled(false);
                }
            } else {
                displayOptionsDispatch({ type: 'setCameraEnabled', payload: { newCameraEnabled: false } })
            }
        }
        displayOptionsDispatch({ type: 'setVideoDevices', payload: { newVideoDevices: devices.length } });
    }, []);

    useEffect(() => {
        listVideoDevices();
        navigator.mediaDevices.addEventListener("devicechange", listVideoDevices);
        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                listVideoDevices
            );
        };
    }, []);

    const chatClicked = () => {
        setChatboxEnabled(!chatboxEnabled)
    }

    const updateJoinedParticipants = () => {
        var tmpParticipantList = "";
        if (room && room.participants) {
            const participantNames: string[] = [];
            for (const participant of room.participants.values()) {
                try {
                    if (participant.metadata && JSON.parse(participant.metadata).joined) {
                        // okay
                    } else {
                        continue;
                    }
                } catch {
                    continue;
                }
                const thename = participant.name ?? 'Anomymous User';
                participantNames.push(thename)
                tmpParticipantList = tmpParticipantList + "," + thename;
            }
            tmpParticipantList = tmpParticipantList.replace(",", "");
            setParticipantList(tmpParticipantList)
        }
    }
    useEffect(() => {
        updateJoinedParticipants();
        if (room) {
            if (context.displayOptions.connectState) {
                // we are joined
                if (context.displayOptions.cameraEnabled) {
                    // okay, we already enabled the camera
                    if (context.displayOptions.videoDeviceId) {
                        room.options.videoCaptureDefaults!.deviceId = context.displayOptions.videoDeviceId;
                    }
                    room.localParticipant.setCameraEnabled(true);
                }
                if (context.displayOptions.microphoneEnabled) {
                    // okay, we already enabled the microphone
                    if (context.displayOptions.audioDeviceId) {
                        room.options.audioCaptureDefaults!.deviceId = context.displayOptions.audioDeviceId;
                    }
                    room.localParticipant.setMicrophoneEnabled(true);
                }
            }
            room.on(RoomEvent.ParticipantConnected, () => {
                updateJoinedParticipants();
            })
            room.on(RoomEvent.ParticipantDisconnected, () => {
                updateJoinedParticipants();
            })
        }
    }, [room])
    useEffect(() => {
        if (!context.displayOptions.connectState && room?.localParticipant) {
            if (videoDeviceId !== context.displayOptions.videoDeviceId) {
                // video changed
                room.options.videoCaptureDefaults!.deviceId = context.displayOptions.videoDeviceId ? context.displayOptions.videoDeviceId : undefined;
                if (cameraEnabled) {
                    (async () => {
                        const localTracks = await room.localParticipant.createTracks({ video: true });
                        if (localTracks.length > 0) {
                            setLocalVideoTrack(localTracks[0] as LocalVideoTrack);
                        }
                    })();
                }
                setVideoDeviceId(context.displayOptions.videoDeviceId);
            }

            if (audioDeviceId !== context.displayOptions.audioDeviceId) {
                // audio changed
                room.options.audioCaptureDefaults!.deviceId = context.displayOptions.audioDeviceId ? context.displayOptions.audioDeviceId : undefined;
                setAudioDeviceId(context.displayOptions.audioDeviceId);
            }

            if (microphoneEnabled !== context.displayOptions.microphoneEnabled) {
                // audio state changed
                if (context.displayOptions.microphoneEnabled) {
                    // audio turned on
                    (async () => {
                        await room.localParticipant.createTracks({ audio: true });
                    })();
                } else {
                    // microphone turned off
                }
                setMicrophoneEnabled(context.displayOptions.microphoneEnabled);
            }

            if (cameraEnabled !== context.displayOptions.cameraEnabled) {
                // camera state changed
                if (context.displayOptions.cameraEnabled) {
                    // camera turned on
                    (async () => {
                        const localTracks = await room.localParticipant.createTracks({ video: true });
                        if (localTracks.length > 0) {
                            setLocalVideoTrack(localTracks[0] as LocalVideoTrack);
                        }
                    })();
                } else {
                    // camera turned off
                    setLocalVideoTrack(null);
                }
                setCameraEnabled(context.displayOptions.cameraEnabled);
            }
        }
    }, [room?.localParticipant, context.displayOptions.cameraEnabled, context.displayOptions.videoDeviceId])

    let mainElement: ReactElement;
    if (context.displayOptions.connectState) {
        confProps.chatClicked = chatClicked
        /*if (isMobile) {
            mainElement = <div className="conf-layout-center"><MobileView {...confProps} /></div>;
        } else*/ {
            if (context.displayOptions.stageLayout === LayoutType.Auto) {
                // find first participant with screen shared
                let screenTrack: VideoTrack | undefined;
                participants.forEach((p) => {
                    if (screenTrack) {
                        return;
                    }
                    const track = p.getTrack(Track.Source.ScreenShare);
                    if (track?.isSubscribed && track.videoTrack) {
                        screenTrack = track.videoTrack;
                    }
                });

                if (screenTrack === undefined) {
                    mainElement = <div className="conf-layout-center"><GridView {...confProps} /></div>
                        ;
                } else {
                    const windowAspect = windowSize.x / windowSize.y;
                    if (windowAspect < 1.3) {

                        mainElement = <div className="conf-layout-center"><MobileView {...confProps} /></div>;
                    }
                    else {
                        mainElement = <div className="conf-layout-center"><SpeakerView {...confProps} /></div>;
                    }
                }
            } else if (context.displayOptions.stageLayout === LayoutType.Grid) {
                mainElement = <div className="conf-layout-center"><GridView  {...confProps} /></div>;
            } else if (context.displayOptions.stageLayout === LayoutType.Presenter) {
                const windowAspect = windowSize.x / windowSize.y;
                if (windowAspect < 1.3) {
                    mainElement = <div className="conf-layout-center"><MobileView {...confProps} /></div>;
                }
                else {
                    mainElement = <div className="conf-layout-center"><SpeakerView {...confProps} /></div>;
                }
            } else {
                throw Error("Unexpected case");
            }
        }
        if (chatboxEnabled) {
            mainElement =
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>{mainElement}</div>
                    <div className="chat-container">
                        <ChatBox recipientId={recipientId} />
                    </div>
                </div>

        }
        mainElement =
            <div>
                {mainElement}
                {
                    room && room.localParticipant && <div className="conf-desktop-controls-area">
                        <ConferenceControlView joinLeaveClicked={confProps.joinLeaveClicked} chatClicked={chatClicked} room={room} onLeave={confProps.onLeave} />
                    </div>
                }
            </div>
    } else {
        // not joined yet
        mainElement = (
            <Layout headerBtn={false} footer={3}>
                <div>
                    {room && room.localParticipant &&
                        <LobbyView
                            videoDevices={videoDevices}
                            key={room.localParticipant.identity}
                            track={localVideoTrack}
                            width="100%"
                            height="100%"
                            orientation="landscape" isAudioMuted={!context.displayOptions.microphoneEnabled}
                            displayName={room.localParticipant.name!}
                        />
                    }
                    {participantList.length === 0 ? <div className="conf-joined">No one is here yet.</div> :
                        <div className="conf-joined">Who's already on? {participantList}</div>}
                    {room && <div className="conf-desktop-controls-area">
                        <ConferenceControlView joinLeaveClicked={confProps.joinLeaveClicked} enableScreenShare={false} room={room} onLeave={confProps.onLeave} />
                    </div>}
                </div>
            </Layout>)
    }

    return (
        <div className="conf-container">
            {mainElement}
            {confProps.roomState.audioTracks.map((track) => (
                <AudioRenderer key={track.sid} track={track} isLocal={false} />
            ))}

            {room?.canPlaybackAudio === false && (
                <div className="conf-overlay">
                    <button
                        className="conf-unmute-button"
                        onClick={() => {
                            room.startAudio();
                        }}
                    >
                        <FontAwesomeIcon
                            className="conf-icon"
                            size="1x"
                            icon={faVolumeMute}
                        />
                        Click to Unmute
                    </button>
                </div>
            )}
        </div>
    );
};
/*
function displayOptionsDispatch(arg0: { type: string; payload: { newCameraEnabled: boolean; }; }) {
    throw new Error("Function not implemented.");
}
*/