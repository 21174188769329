import { ChangeEvent, useEffect, useState } from "react";
import { ActiveSchedule, computeDuration, computeDurationToHrMin, computeEpochtoTimeNoAMPMStr, computeTimeStrtoEpoch, convertDateToEpoch, customDaysStr, daysOfWeek, daysOfWeekShort, ExtendedRoom, ExtendedSchedule, getTimeZone, getWeekOfMonthStr, SESSIONFREQUENCY } from "../../utils/eduutils";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "react-bootstrap";
import { DatePicker, TimePicker } from "react-rainbow-components";
import { Button, ButtonGroup, MenuItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from '@mui/icons-material/Add';
import { api as apiproto } from "../../apiproto";
import { tdrLog } from "../../utils/utils";
import TimezoneSelect, { ITimezone, ITimezoneOption } from "react-timezone-select";
import { components, DropdownIndicatorProps, GroupBase } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

type ChildProps = {
    topic: apiproto.ITopic | null | undefined,
    room: ExtendedRoom | null | undefined,
    schedulein: ExtendedSchedule | null | undefined,
    callback?: (value: any) => void | null,
    instantSave: boolean // Specify whether the update is going to be instantaneously call as in edit mode

}

const ScheduleSingleInput: React.FC<ChildProps> = ({ room = null, schedulein = null, callback = () => { }, instantSave = false }) => {

    const DropdownIndicator = (props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>) => {
        return (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon icon="caret-down" />
            </components.DropdownIndicator>
        );
    }
    const [activeEditedSchedule, setActiveEditedSchedule] = useState<ActiveSchedule | null>({ room: null, schedule: null });
    const [timeZoneSelector, setTimeZoneSelector] = useState(false);
    const [schedule, setSchedule] = useState<ExtendedSchedule | null>(schedulein);
    const dateStyles = {
        minWidth: 250,
        maxWidth: 280,
        padding: 10,
    };
    const timeStyles = {
        width: 250,
        padding: 10,
    };
    const [tz, setTz] = useState<ITimezone | string>(schedule?.timeZone ? schedule.timeZone : getTimeZone());
    const chooseDay = (event: React.MouseEvent<HTMLButtonElement>, room: ExtendedRoom, day: number) => {
        event.preventDefault();
        var tmpSchedule: boolean[] = [false, false, false, false, false, false, false];
        if (schedule?.weekDays) {
            schedule.weekDays[day] = !schedule.weekDays[day];
        }
        else {
            if (schedule) {
                tmpSchedule[day] = true;
                schedule.weekDays = tmpSchedule;
            }
        }
        setSchedule(schedule);
        if (room && schedule) {
            setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
        }
        if (schedule && schedule.weekDays) {
            schedule.weekDaysStr = customDaysStr(schedule.weekDays);
        }
        if (schedule !== null) {
            isInvalidSchedule(schedule);
        }
    }

    const handleDone = () => {
        const meta = { ...activeEditedSchedule };
        //tdrAlert(JSON.stringify(meta.schedule))
        const scheduleSingle = meta.schedule!;
        if ((tz as ITimezoneOption).value) {
            scheduleSingle.timeZone = (tz as ITimezoneOption).value;
        }
        scheduleSingle.toTimeErr = "";
        scheduleSingle.toDateErr = "";
        scheduleSingle.startTime = convertDateToEpoch(scheduleSingle.fromDate, scheduleSingle.fromTime, schedule?.timeZone);
        scheduleSingle.repeatEndDate = convertDateToEpoch(scheduleSingle.toDate, "23:59", scheduleSingle.timeZone);
        scheduleSingle.duration = computeDuration(scheduleSingle.fromTime, scheduleSingle.toTime);
        delete scheduleSingle.daily;
        delete scheduleSingle.monthly;
        delete scheduleSingle.weekly;
        scheduleSingle.repeatCount = -1;
        if (scheduleSingle.frequency === SESSIONFREQUENCY.DAILY.valueOf()) {
            scheduleSingle["daily"] = { interval: 1 };
        }
        else if (scheduleSingle.frequency === SESSIONFREQUENCY.WEEKLY.valueOf()) {

            scheduleSingle["weekly"] = { interval: 1, weekDays: [scheduleSingle.fromDate.getDay()] };
        }
        else if (scheduleSingle.frequency === SESSIONFREQUENCY.WEEKLYCUSTOM.valueOf()) {
            let selectedWeekDays: number[] = [];
            for (let i = 0; i < 7; i++) {
                if (scheduleSingle.weekDays && scheduleSingle.weekDays[i]) {
                    selectedWeekDays.push(i);
                }
            }
            if (selectedWeekDays.length === 0) {
                // Should never happen but if it does, we pick the day that is based on fromDate
                scheduleSingle["weekly"] = { interval: 1, weekDays: [scheduleSingle.fromDate.getDay()] };
            }
            else {
                scheduleSingle["weekly"] = {
                    interval: 1, weekDays:
                        selectedWeekDays
                };
                if (scheduleSingle.weekDays) {
                    scheduleSingle.weekDaysStr = customDaysStr(scheduleSingle.weekDays);
                }
            }
        }
        else if (scheduleSingle.frequency === SESSIONFREQUENCY.MONTHLY.valueOf()) {

            scheduleSingle["monthly"] = { interval: 1, monthlyDays: { monthlyDay: [scheduleSingle.fromDate.getDate()] } };
        }
        else {
            scheduleSingle.repeatCount = 0;
            scheduleSingle.repeatEndDate = 0;
        }
        tdrLog(JSON.stringify(scheduleSingle))
        if (isInvalidSchedule(scheduleSingle)) {
            return;
        }
        callback({ room: { ...meta.room }, schedule: { ...scheduleSingle } });
    }



    const isInvalidSchedule = (scheduleSingle: ExtendedSchedule) => {
        var invalid: boolean = false;
        if (scheduleSingle === null) return false;
        if (scheduleSingle.frequency === SESSIONFREQUENCY.WEEKLYCUSTOM) {
            if (scheduleSingle.weekDays) {
                if (!scheduleSingle.weekDays[scheduleSingle.fromDate.getDay()]) {
                    // Check that the start date day is also checked at least
                    // otherwise server will error out.
                    scheduleSingle.generalErr = daysOfWeekShort[scheduleSingle.fromDate.getDay()] + " must be selected since the start date falls on " + daysOfWeek[scheduleSingle.fromDate.getDay()];
                    invalid = true;
                }
                else if (!scheduleSingle.weekDays[0] && !scheduleSingle.weekDays[1] && !scheduleSingle.weekDays[2] && !scheduleSingle.weekDays[3] && !scheduleSingle.weekDays[4] && !scheduleSingle.weekDays[5] && !scheduleSingle.weekDays[6]) {
                    scheduleSingle.generalErr = "Select the days available.";
                    invalid = true;
                }
            }
            else {
                // No weeks days custom - should never happen.
                // We don't want to show any error since this means this selection was never done before, but we want to gray out "Done" button which is based on error string. so 2 spaces to trigger grey out of done.
                scheduleSingle.generalErr = " ";
                invalid = true;
            }
        }
        if (scheduleSingle.frequency !== SESSIONFREQUENCY.NOREPEAT.valueOf() && scheduleSingle.fromDate.getTime() > scheduleSingle.toDate.getTime()) {
            scheduleSingle.toDateErr = "End date must be later than start date";
            invalid = true;
        }
        else {
            scheduleSingle.toDateErr = "";
        }
        if (scheduleSingle.duration! > 10 * 60 * 60 * 1000) {
            scheduleSingle.toTimeErr = "The duration must be less than 10 hours.";
            invalid = true;
        }
        if (!invalid) {
            scheduleSingle.toDateErr = "";
            scheduleSingle.toTimeErr = "";
            scheduleSingle.generalErr = "";
        }
        setSchedule(scheduleSingle);
        setActiveEditedSchedule({ room: room, schedule: scheduleSingle });

        return invalid;

    }
    useEffect(() => {
        if (schedulein && schedulein !== null) {
            if (!schedulein.timeZone || schedulein.timeZone === null || schedulein?.timeZone?.length === 0) {
                schedulein.timeZone = getTimeZone();
            }
        }
        setActiveEditedSchedule({ room: room, schedule: schedulein });
        setSchedule(schedulein);
        if (schedulein && schedulein !== null) {
            isInvalidSchedule(schedulein);
        }
    }, [room, schedulein])


    const chooseTimeZone = () => {
        setTimeZoneSelector(true);
    }

    return (
        <div>
            <ValidatorForm onSubmit={handleDone}>

                {(room && schedule) &&
                    <div>
                        <Row>
                            <Col>
                                <div style={dateStyles}>
                                    <DatePicker
                                        required
                                        id="fromDatePicker"
                                        value={schedule!.fromDate}
                                        onChange={(value) => {
                                            schedule.duration = computeDuration(schedule.fromTime, schedule.toTime)
                                            schedule.fromDate = value;
                                            schedule.startTime = convertDateToEpoch(schedule.fromDate, schedule.fromTime, schedule.timeZone);
                                            setSchedule(schedule);
                                            setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
                                            isInvalidSchedule(schedule);
                                            //                                            handleScheduleDateChange(schedule!.scheduleId, room!.roomId, 'fromDate', value);
                                        }}
                                        label="Start Date"
                                        formatStyle="large" />
                                </div>
                            </Col>
                            <Col>
                                <div style={dateStyles}>
                                    {schedule!.frequency !== SESSIONFREQUENCY.NOREPEAT &&

                                        <DatePicker
                                            required
                                            id="toDatePicker"
                                            value={schedule!.toDate}
                                            error={schedule!.toDateErr}
                                            onChange={(value) => {
                                                schedule.toDate = value;
                                                schedule.toDateErr = "";
                                                setSchedule(schedule);
                                                setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
                                                isInvalidSchedule(schedule);

                                            }}
                                            label="End Date"
                                            formatStyle="large" />
                                    }
                                </div>
                            </Col>

                            <Col>
                                <div style={timeStyles}>
                                    <TimePicker
                                        value={schedule!.fromTime}
                                        label="Start Time"
                                        style={{ width: 180 }}
                                        onChange={(value) => {
                                            //tdrAlert(value)
                                            const newfromtime = computeTimeStrtoEpoch(value);
                                            const totime = computeTimeStrtoEpoch(schedule.toTime);
                                            var oldduration = (totime - computeTimeStrtoEpoch(schedule!.fromTime));
                                            // Calculate new starttime
                                            schedule!.fromTime = value;
                                            schedule.startTime = convertDateToEpoch(schedule.fromDate, schedule.fromTime, schedule.timeZone);
                                            if (oldduration <= 0) oldduration = 60 * 60 * 1000;
                                            if (newfromtime >= totime) {
                                                const endDate: number = +schedule.startTime!.toString() + +oldduration;
                                                schedule!.toTime = computeEpochtoTimeNoAMPMStr(endDate.toString(), schedule.timeZone);
                                            }
                                            schedule!.duration = computeDuration(schedule!.fromTime, schedule!.toTime
                                            );
                                            schedule!.durationStr = 'End Time (' + computeDurationToHrMin(schedule!.duration) + ')';
                                            setSchedule(schedule);
                                            setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
                                            isInvalidSchedule(schedule);

                                        }}
                                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto" />

                                </div>
                            </Col>
                            <Col>
                                <div style={timeStyles}>
                                    <TimePicker
                                        value={schedule!.toTime}
                                        label={schedule!.durationStr}
                                        error={schedule!.toTimeErr}
                                        style={{ width: 180 }}
                                        onChange={(value) => {
                                            schedule!.toTimeErr = "";
                                            schedule!.toTime = value;
                                            schedule!.duration = computeDuration(schedule!.fromTime, schedule!.toTime
                                            );
                                            schedule!.durationStr = 'End Time (' + computeDurationToHrMin(schedule!.duration) + ')';
                                            if (schedule.duration > 10 * 60 * 60 * 1000) {
                                                schedule.toTimeErr = "The duration must be less than 10 hours.";
                                            }
                                            setSchedule(schedule);
                                            setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
                                            isInvalidSchedule(schedule);

                                        }}
                                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto" />

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group form-input form-padding">
                                    <SelectValidator
                                        key={'frequency' + schedule.scheduleId}
                                        sx={{ width: 270 }}
                                        id="category"
                                        name="category"
                                        value={schedule.frequency}
                                        className="form-control"
                                        label="Select Frequency"
                                        onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                            schedule.frequency = event.target.value;
                                            isInvalidSchedule(schedule);
                                            if (schedule.frequency === SESSIONFREQUENCY.NOREPEAT) {
                                                schedule.toDate = schedule.fromDate;
                                            }
                                            setSchedule(schedule);
                                            setActiveEditedSchedule({ room: { ...room }, schedule: { ...schedule } });
                                            //setRoomScheduleState([...roomScheduleState]);
                                        }}

                                    >
                                        <MenuItem value={SESSIONFREQUENCY.NOREPEAT.valueOf()}>Does not repeat</MenuItem>
                                        <MenuItem value={SESSIONFREQUENCY.DAILY.valueOf()}>Daily</MenuItem>
                                        <MenuItem value={SESSIONFREQUENCY.WEEKLY.valueOf()}>Weekly on {daysOfWeek[schedule.fromDate.getDay()]}</MenuItem>
                                        <MenuItem value={SESSIONFREQUENCY.WEEKLYCUSTOM.valueOf()}>{(schedule.frequency === SESSIONFREQUENCY.WEEKLYCUSTOM && schedule.weekDays && schedule.weekDays?.length > 0) ? 'Weekly ' + schedule.weekDaysStr : 'Weekly Custom'}</MenuItem>
                                        <MenuItem value={SESSIONFREQUENCY.MONTHLY.valueOf()}>Monthly on the {getWeekOfMonthStr(schedule.fromDate)} {daysOfWeek[schedule.fromDate.getDay()]}</MenuItem>
                                    </SelectValidator>
                                </div>
                            </Col>
                            {schedule.frequency === SESSIONFREQUENCY.WEEKLYCUSTOM &&
                                <Row>
                                    <Col>
                                        <ButtonGroup style={{ width: '260px', marginTop: '5px' }} size="small" variant="outlined" aria-label="outlined primary button group">
                                            <Button variant={(schedule.weekDays && schedule.weekDays[0]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 0); }}>Sun</Button>
                                            <Button variant={(schedule.weekDays && schedule.weekDays[1]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 1); }}>Mon</Button>
                                            <Button variant={(schedule.weekDays && schedule.weekDays[2]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 2); }}>Tue</Button>
                                            <Button variant={(schedule.weekDays && schedule.weekDays[3]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 3); }}>Wed</Button>
                                            <Button variant={(schedule.weekDays && schedule.weekDays[4]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 4); }}>Thu</Button>
                                            <Button variant={(schedule.weekDays && schedule.weekDays[5]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 5); }}>Fri</Button>
                                            <Button variant={(schedule.weekDays && schedule.weekDays[6]) ? "contained" : "outlined"} onClick={(e) => { chooseDay(e, room, 6); }}>Sat</Button>
                                        </ButtonGroup>

                                    </Col>
                                </Row>
                            }
                        </Row>
                        <Row>
                            <Col>
                                {timeZoneSelector ?
                                    <div className="form-group form-input form-padding">
                                        <TimezoneSelect
                                            classNamePrefix="timeZoneSelect"
                                            value={tz}
                                            onChange={setTz}
                                            components={{ DropdownIndicator }}
                                            labelStyle="abbrev"
                                        />
                                        <br /><br /><br /><br /><br /><br /><br />
                                    </div>
                                    :
                                    <div style={{ marginTop: '10px' }}>
                                        <Link to="#" onClick={chooseTimeZone} style={{ marginLeft: '5px', color: 'black', fontSize: '12px' }}>Time Zone: {schedule.timeZone}</Link>
                                    </div>
                                }
                            </Col>
                        </Row>

                        <hr />
                        <span className="form-error">{schedule.generalErr}</span>
                        <Row>
                            <Col>
                                <LoadingButton disabled={schedule.toDateErr.length > 0 || schedule.toTimeErr.length > 0 || schedule.generalErr.length > 0} style={{ float: 'right' }} size="medium" variant="contained" loading={false} loadingPosition="start" type="submit" startIcon={<AddIcon />}>
                                    {instantSave ? "Confirm & Save" : "Done"}
                                </LoadingButton>
                            </Col>
                        </Row>

                    </div>
                }
            </ValidatorForm >
        </div >)
}

export default ScheduleSingleInput;