import React from "react";
//import Time from "../../utils/time";

const Aboutbanner2 = () => {
  return (
    <section id="banner-2" className="bg-01 wide-60 banner-section">
      <div className="container">
        <div className="row d-flex align-items-center">
          {/* BANNER TEXT */}
          <div className="col-md-7">
            <div className="banner-2-txt white-color">
              <h3 className="h3-lg">
                <span>If you are a teacher, a coach, an accountant, a lawyer, a CFO, a mentor or any professional</span>, you can share your knowledge. Set your schedule, set your price and start earning.
              </h3>
              {/* COUNTDOWN ELEMENT */}
              {/*
              <div className="countdown">
                <span className="countdown-txt">Ends in</span>
                <div id="clock">
                  <div className="cbox clearfix">
                    <span className="cbox-digit">
                      {Time("2022-09-27 00:00:00").days}
                    </span>{" "}
                    <span className="cbox-txt">d</span>
                  </div>
                  <div className="cbox clearfix">
                    <span className="cbox-digit">
                      {Time("2022-09-27 00:00:00").hours}
                    </span>{" "}
                    <span className="cbox-txt">h</span>
                  </div>
                  <div className="cbox clearfix">
                    <span className="cbox-digit">
                      {Time("2022-09-27 00:00:00").minutes}
                    </span>{" "}
                    <span className="cbox-txt">m</span>
                  </div>
                  <div className="cbox clearfix">
                    <span className="cbox-digit">
                      {Time("2022-09-27 00:00:00").seconds}
                    </span>{" "}
                    <span className="cbox-txt">s</span>
                  </div>
                </div>
              </div>
              */}
            </div>
          </div>
          {/* REGISTER FORM */}
          <div className="col-md-5">

          </div>
          {/* END REGISTER FORM */}
        </div>
        {/* End row */}
      </div>
      {/* End container */}
    </section>
  );
};

export default Aboutbanner2;
