import React from "react";

const BecomeATeacherAbout4 = () => {
    return (
        <section
            id="about-4"
            className="bg-lightgrey wide-70 about-section"
        >
            <div className="container">
                {/* ABOUT TEXT */}
                <div className="row">
                    <div className="col-xl-10 offset-xl-1">
                        <div className="a4-txt">
                            {/* Title */}
                            <h5 className="h5-xl text-center">
                                Connect with people who needs your knowledge.
                            </h5>
                            {/* Text */}
                            <p className="text-center">
                                <b>Be a teacher - </b>Teach academic and enrichment courses to students<br /><br />
                                <b>Be a consultant - </b>Share your knowledge. Consult and share your expertise.<br /><br />
                                <b>Be a coach - </b> Coach someone and help them maximize their potential<br /><br />
                                <b>Be a mentor - </b>Give advice and help someone achieve their goals.<br /><br />
                                <b>Endless possibilities when we connect knowledge together. </b><br /><br />
                            </p>
                        </div>
                    </div>
                </div>
                {/* END ABOUT TEXT */}
                {/* ABOUT BOXES */}
                <br /><br /><br />

                <div className="a4-boxes">
                    <div className="row d-flex align-items-center">
                        {/* BOX #1 */}
                        <div className="col-md-4">
                            <div className="abox-4 icon-sm">
                                {/* Icon */}
                                <span className="flaticon-028-learning-1" />
                                {/* Text */}
                                <div className="abox-4-txt">
                                    <h5 className="h5-md">
                                        Get Discovered
                                    </h5>
                                    <p>
                                        Get your knowledge discovered by a community seeking it.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* END BOX #1 */}
                        {/* BOX #2 */}
                        <div className="col-md-4">
                            <div className="abox-4 icon-sm">
                                {/* Icon */}
                                <span className="flaticon-004-computer" />
                                {/* Text */}
                                <div className="abox-4-txt">
                                    <h5 className="h5-md">Scheduling</h5>
                                    <p>
                                        Easily set up your schedule for others to book your time.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* END BOX #2 */}
                        {/* BOX #3 */}
                        <div className="col-md-4">
                            <div className="abox-4 icon-sm">
                                {/* Icon */}
                                <span className="flaticon-032-tablet" />
                                {/* Text */}
                                <div className="abox-4-txt">
                                    <h5 className="h5-md">Billing</h5>
                                    <p>
                                        Accept payments and get paid after you complete your live video conferencing session with your client.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* END BOX #3 */}
                    </div>
                    {/* End row */}
                </div>
                {/* END ABOUT BOXES */}
            </div>
            {/* End container */}
        </section >
    );
};

export default BecomeATeacherAbout4;
