import { Fragment, useEffect, useState } from "react";
import { ActiveSchedule, ExtendedRoom, ExtendedSchedule, TimeAction } from "../../utils/eduutils";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ScheduleSingleInput from "./scheduleinfoinput";
import { api as apiproto } from "../../apiproto";
import ScheduleSingleTimeInput from "./scheduletimesingleinput";

type ChildProps = {
    topic: apiproto.ITopic | null | undefined,
    room: ExtendedRoom | null | undefined,
    roomid?: string | null,// string if only roomid in
    schedule: ExtendedSchedule | null | undefined,
    time: apiproto.ITime | null,
    visible: boolean,
    meta: ActiveSchedule | null,
    onCancel?: () => void | null,
    onConfirm?: (value: any) => void | null,
    onModifyTime?: (value: apiproto.ITime, action: TimeAction) => void | null,
    editMode: boolean,
    editTime: boolean // If true, then it is edit time slot else edit schedule
}

const ScheduleInfoPopover: React.FC<ChildProps> = ({ topic = null, room = null, roomid = null, schedule = null, time = null, visible = false, meta = { room: null, schedule: null }, onCancel = () => { }, onConfirm = () => { }, onModifyTime = () => { }, editMode = false, editTime = false }) => {

    const [activeEditedSchedule, setActiveEditedSchedule] = useState<ActiveSchedule | null>({ room: null, schedule: null });
    const [scheduleInfoOpen, setScheduleInfoOpen] = useState(false);
    const [activeEditedTime, setActiveEditedTime] = useState<apiproto.ITime | null>(null);
    const handleDone = (meta: ActiveSchedule) => {
        onConfirm(meta);
    }


    useEffect(() => {
        setScheduleInfoOpen(visible);
        setActiveEditedSchedule({ room: room, schedule: schedule });
        setActiveEditedTime(time);
    }, [visible, time, schedule])

    return (
        <Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={scheduleInfoOpen}
                onClose={onCancel}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* This is for editing schedule */}
                    {!editTime && <ScheduleSingleInput topic={topic} room={activeEditedSchedule?.room} schedulein={activeEditedSchedule?.schedule} callback={handleDone} instantSave={editMode} />}
                    {/* This is for editing timeslot */}
                    {editTime && <ScheduleSingleTimeInput topic={topic} time={activeEditedTime} roomid={roomid} scheduleCurrent={activeEditedSchedule?.schedule!} callback={onCancel} onModifyTime={onModifyTime} />}
                </DialogContent>
            </Dialog>
        </Fragment>)
}

export default ScheduleInfoPopover;