import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { useAPI } from "../../utils/useAPI";
import { RequireAuth, useUser } from "../../utils/useUser";
import { api as apiproto } from "../../apiproto";
import Typography from '@mui/material/Typography';
import { Button, MenuItem } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useSearchParams } from "react-router-dom";
import DialogMessageBox from "../../components/DialogMessageBox";
import TopicCard from "../../components/Course Details/TopicCard";
import SearchBar from "./searchbar";
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "react-bootstrap";
import PageTitle from "../../layout/PageTitle";
import { tdrLog } from "../../utils/utils";
import { getMinTimeOfEnrolledTopic } from "../../utils/eduutils";


export const Sessions = () => {

    type MinTimeTopic = {
        minTime: number,
        topic: apiproto.ITopic
    }

    const navigate = useNavigate();
    const api = useAPI();
    const user = useUser();

    let [topics, setTopics] = useState<(apiproto.ITopic | undefined | null)[]>();

    const [searchParams] = useSearchParams();
    const [sessionType, setSessionType] = React.useState(""); // created = topics I created, book = topics I booked, invite = topics I am invited

    useEffect(() => {
        let stype = searchParams.get('type');
        if (stype === null || stype.length === 0) {
            stype = "created"
        }
        setSessionType(stype);
    }, [api, searchParams])

    useEffect(() => {
        if (sessionType === 'book') {
            getEnrolledTopics();
        }
        else if (sessionType === 'created') {
            getUserTopics(true);
        }
        else if (sessionType === 'shared') {
            getUserTopics(false);
        }
        else if (sessionType === 'adminpending') {
            getPendingTopics();
        }
        else if (sessionType === 'search') {
            getPublicTopics();
        }
    }, [sessionType])

    const [errDialogopen, setErrDialogOpen] = React.useState({ title: "", message: "" });

    const handleErrDialogClose = () => {
        setErrDialogOpen({ title: "", message: "" });
    };

    const createTopic = () => {
        navigate("/createtopic");
    }

    const findTopic = () => {
        navigate("/sessions?type=search");
    }

    const getPublicTopics = async () => {
        const allResults = await api.findTopics({ includeDescription: true, assets: {}, members: { entity: { user: { assets: {} } } }, rooms: { scheduleSet: { schedules: { times: {} } } }, tags: {} }, { searchString: "" }, 50);
        if (allResults.topics) {
            setTopics(allResults.topics!);
        }
        else {
            setTopics([]);
        }
    }
    /*
        const getCoursesNew = async () => {
            const allResults = await api.findCourses({ members: { entity: { user: { assets: {} } } }, classrooms: { schedules: {} }, tags: {}, assets: {} }, { instructorUserId: user.userId! }, 100);
            if (allResults.courses) {
                console.log(JSON.stringify(allResults.courses));
                setCourses(allResults.courses!);
            }
            else {
                setCourses([]);
            }
            setFetchCourse(false);
    
        }
        */

    const getPendingTopics = () => {
        if (user.authenticated && (user.userId === 'u_r1y52Aql0OiAV4bVZw8W' || user.userId === 'u_tkUWofQTRtW7pWYiiWFcT' || user.userId === 'u_9tTHpFvHwXOx3miOvav9')) {
            (async () => {
                // A bit of a hack right now to just know if admin user- should use g_public owner?
                const allResults = await api.pendingReviewTopics({ includeDescription: true, assets: {}, members: { entity: { user: { assets: {} } } }, rooms: { scheduleSet: { schedules: { times: {} } } }, tags: {} });
                if (allResults.topics) {
                    setTopics(allResults.topics!);
                }
                else {
                    setTopics([]);
                }
            })()
        }
    }

    const getUserTopics = (owner: boolean) => {
        if (user.userId && user.authenticated) {
            (async () => {
                //    const userInfoResult = await api.getUser({ assets: {}, membersOf: { ownerFilter: { filterValue: true }, instructorFilter: { filterValue: true }, obj: { room: { topic: { includeDescription: true, assets: {}, tags: {}, } } } } }, { userId: user.userId });
                const userInfoResult = await api.getUser({ assets: {}, membersOf: { ownerFilter: { filterValue: owner }, instructorFilter: { filterValue: owner }, obj: { topic: { includeDescription: true, assets: {}, tags: {}, members: { entity: { user: { assets: {} } } }, rooms: { scheduleSet: { schedules: { times: {} } } } } } } }, { userId: user.userId });
                const filteredResults: MinTimeTopic[] = [];
                userInfoResult.user?.membersOf?.forEach((m) => {
                    if (m.obj?.topic?.topic) {
                        const minTime = getMinTimeOfEnrolledTopic(m.obj.topic.topic);
                        filteredResults.push({ minTime, topic: m.obj.topic.topic });
                    }
                })
                if (filteredResults) {
                    const filtered = filteredResults.sort((topic1, topic2) => {
                        return (topic1.minTime - topic2.minTime)
                    }).map((json) => json.topic);
                    setTopics(filtered);
                }
            })()

        }
        else {
            getPublicTopics();
        }

    }

    const getEnrolledTopics = () => {
        var topics: { [key: string]: apiproto.ITopic } = {};
        const today = new Date();
        const currentTime = today.getTime();

        if (user.userId && user.authenticated) {
            (async () => {
                const userInfoResult = await api.getUser({ membersOf: { obj: { time: { room: { topic: { includeDescription: true, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {} } } }, room: { topic: { includeDescription: true, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {}, rooms: { scheduleSet: { schedules: { times: {} } } } } } } } }, { userId: user.userId });
                tdrLog("User Result:" + JSON.stringify(userInfoResult))
                //const userInfoResult = await api.getUser({ assets: {}, membersOf: { obj: { time: { room: { topic: { includeDescription: true, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {} } } }, room: { topic: { includeDescription: false, members: { entity: { user: { assets: {} } } }, assets: {}, tags: {}, rooms: { scheduleSet: { schedules: { times: {} } } } } } } } }, { userId: user.userId });
                //const userInfoResult = await api.getUser({ assets: {}, membersOf: { obj: { room: { topic: { includeDescription: true, members: {}, assets: {}, tags: {}, rooms: { scheduleSet: { schedules: { times: {} } } } } } } } }, { userId: user.userId });
                const filteredResults: MinTimeTopic[] = [];
                //      tdrLog('UserInfo Result ' + JSON.stringify(userInfoResult));
                userInfoResult.user?.membersOf?.forEach((m) => {
                    // This is a room enrollment
                    tdrLog("===============")
                    tdrLog('Members ' + JSON.stringify(m))
                    if (m.obj?.room?.room?.topic) {
                        //                        tdrLog("******************")
                        //                      tdrLog("Enrolled " + user.userId + " getEnrolled " + JSON.stringify(m))
                        const minTime = getMinTimeOfEnrolledTopic(m.obj?.room?.room?.topic);
                        filteredResults.push({ minTime, topic: m.obj?.room?.room?.topic! });
                    }
                    else if (m.obj?.time?.time?.room?.topic && m.obj.time.time.startTime && m.obj.time.time.startTime > currentTime) {
                        //                    tdrLog("$$$$$$$$$$$$")
                        //                  tdrLog("Enrolled " + user.userId + " getEnrolled " + JSON.stringify(m))
                        // This is a time enrollment
                        var existingTopic = topics[m.obj?.time?.time?.room?.topic.topicId!]
                        if (!existingTopic) {
                            // That means this is still not a topic with a time enrolled. ITs the first time so far
                            var newtopic = m.obj?.time?.time?.room?.topic!;
                            // We add an artificial room here to hold the enrolled time ID
                            const newschedule: apiproto.ISchedule = {
                                times: [{ startTime: m.obj.time.time.startTime, endTime: m.obj.time.time.endTime, timeId: m.obj.time.timeId }]
                            }
                            const newroom: apiproto.IRoom[] = [{
                                roomId: "r-" + newtopic.topicId,
                                topicId: newtopic.topicId,
                                section: m.obj.time.time.room.section,
                                room: m.obj.time.time.room.roomId,
                                scheduleSet: { name: "", schedules: [newschedule] },
                                scheduleSetId: null
                            }];
                            newtopic = { ...newtopic, rooms: newroom };
                            const minTime = getMinTimeOfEnrolledTopic(newtopic);
                            filteredResults.push({ minTime, topic: newtopic });
                            topics[newtopic.topicId!] = newtopic;
                        }
                        else {
                            if (existingTopic && existingTopic.rooms && existingTopic.rooms.length > 0 && existingTopic.rooms[0].scheduleSet) {
                                const newschedule: apiproto.ISchedule = {
                                    times: [{ startTime: m.obj.time.time.startTime, endTime: m.obj.time.time.endTime, timeId: m.obj.time.timeId }]
                                }
                                existingTopic.rooms[0].scheduleSet.schedules?.push(newschedule);
                            }
                        }
                    }
                })
                //tdrLog("FilteredResults:" + JSON.stringify(filteredResults))
                if (filteredResults) {
                    const sortedResults = filteredResults.sort((topic1, topic2) => {
                        return (topic1.minTime - topic2.minTime)
                    }).map((json) => json.topic);

                    setTopics(sortedResults);
                }

                /*
                                setCourses(userInfoResult.userInfo?.membersOf?.filter((m) => {
                                    return m.obj?.course?.courseInfo?.courseId;
                                }).map((m) => { return m.obj?.course?.courseInfo; }))*/
            })()

        }
        else {
            navigate('/login');
            //            getPublicTopics();
        }

    }

    const handleChangeSessionType = () => {


    }

    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // That means class format has changed, so we just reset billing type to Free since depending on what is selected, its unclear what is the best default- so default to free since it is common to all types
        navigate("/sessions?type=" + event.target.value);
        //        setSessionType(event.target.value);
    };
    /*
        const deleteItem = async (topicId: string) => {
    
            const deleteResult = await api.deleteTopic(topicId);
            return deleteResult;
        }
    */

    // Need bulk deletes at some point passing an array of courseids
    /*
    const deleteItems = async () => {
        setIsSubmitting(true);
        const unresolvedPromises = selectedItems.map((courseid) => deleteItem(courseid));
        await Promise.all(unresolvedPromises);
        setSelectedItems([]);
        setIsSubmitting(false);
        setRefresh(new Date().getTime())
    }
*/
    const NoCreatedTopics = () => {
        return (
            <Box textAlign='center' sx={{ mt: 8, mr: 7, ml: 7, mb: 8 }}>
                <Typography align="center" variant="h6" gutterBottom component="div">
                    You have not created a topic. <br /><br />Create a topic and schedule your availability. <br /><br />Earn when others book a session with you. <br /><br />Try creating a topic page now.
                </Typography>
                <br />
                <Button size="large" variant="contained" onClick={createTopic} startIcon={<AddIcon />}>
                    Create Topic
                </Button>
            </Box >
        )
    };

    const NoEnrolledTopics = () => {
        return (
            <Box textAlign='center' sx={{ mt: 8, mr: 7, ml: 7, mb: 8 }}>
                <Typography align="center" variant="h6" gutterBottom component="div">
                    You are not signed up for any sessions yet.
                </Typography>
                <br />
                <Button size="large" hidden={true} variant="contained" onClick={findTopic} startIcon={<AddIcon />}>
                    Find Sessions
                </Button>
            </Box>
        )
    };

    const NoSharedTopics = () => {
        return (
            <Box textAlign='center' sx={{ mt: 8, mr: 7, ml: 7, mb: 8 }}>
                <Typography align="center" variant="h6" gutterBottom component="div">
                    There are no topics shared to you yet.
                </Typography>
                <br />
                <Button size="large" hidden={true} variant="contained" onClick={findTopic} startIcon={<AddIcon />}>
                    Find Sessions
                </Button>
            </Box>
        )
    };

    return (
        <Layout headerBtn footer={3}>
            <PageTitle pageTitle="Topics & Sessions | Timedora" />
            <RequireAuth>
                <div className="course-wrapper">
                    <div className="course-top">
                        <h3>{sessionType === "book" ? "Enrolled Sessions" : (sessionType === "created" ? "My Topics" : "")}</h3>

                        {(sessionType === 'search' || !user.authenticated) ?
                            <SearchBar /> :
                            <div className="divpadding divHide">
                                <ValidatorForm onSubmit={handleChangeSessionType}>
                                    <Row>
                                        <Col>
                                            <div className="form-group form-input">
                                                <SelectValidator
                                                    sx={{ width: 300, height: 45, float: 'right' }}
                                                    id="sessiontype"
                                                    name="sessiontype"
                                                    value={sessionType}
                                                    className="form-control"
                                                    label="Select view"
                                                    onChange={handleSelectChange}>
                                                    {(user.userId === 'u_r1y52Aql0OiAV4bVZw8W' || user.userId === 'u_tkUWofQTRtW7pWYiiWFcT' || user.userId === 'u_9tTHpFvHwXOx3miOvav9') ? <MenuItem value={"adminpending"}>Pending Review</MenuItem> : ""}
                                                    <MenuItem value={"created"}>My Sessions</MenuItem>
                                                    <MenuItem value={"book"}>Enrolled Sessions</MenuItem>
                                                    <MenuItem value={"shared"}>Shared Sessions</MenuItem>
                                                </SelectValidator>
                                            </div>
                                        </Col>
                                    </Row>
                                </ValidatorForm>
                            </div>
                        }
                        <DialogMessageBox title={errDialogopen.title} message={errDialogopen.message} meta="" confirm={""} cancel={"Dismiss"} onCancel={handleErrDialogClose} visible={errDialogopen.title.length > 0} />
                        {topics?.length === 0 ? (sessionType === 'book' ? <NoEnrolledTopics /> : (sessionType === "shared" ? <NoSharedTopics /> : <NoCreatedTopics />))
                            : <div>
                                {
                                    (topics ? (topics.map((topic, index) => (topic && <TopicCard topic={topic} type={sessionType} key={'cocard' + topic.topicId} />))) : "")}
                            </div >
                        }
                    </div>
                </div >
            </RequireAuth>
        </Layout >);

}
export default Sessions

