import { faDesktop, faStop, faComment } from "@fortawesome/free-solid-svg-icons";
import { Room } from "livekit-client";
import React, { ReactElement, useContext, useEffect } from "react";
import { LayoutSelectButton } from "./LayoutSelectButton";
import { DisplayContext, LayoutType } from "./DisplayContext";
import { useParticipant } from "./useParticipant";
import { AudioSelectButton } from "./AudioSelectButton";
import { ControlButton } from "./ControlButton";
import { VideoSelectButton } from "./VideoSelectButton";
import "./conference.css";
import { SourceSelectButton } from "./SourceSelectButton";
import { VideoSource } from "./ConferenceTypes";

export interface ControlsProps {
    room: Room;
    enableScreenShare?: boolean;
    enableAudio?: boolean;
    enableVideo?: boolean;
    onLeave?: (room: Room) => void;
    joinLeaveClicked?: () => void;
    chatClicked?: () => void;
}

export const ConferenceControlView = ({
    //face,
    room,
    enableScreenShare,
    enableAudio,
    enableVideo,
    onLeave,
    joinLeaveClicked,
    chatClicked
}: ControlsProps) => {
    const { cameraPublication: camPub } = useParticipant(room.localParticipant);

    useEffect(() => {
        // only run once
        if (displayOptions.connectState) {
            const enabled = !(camPub?.isMuted ?? true);
            if (enabled) {
                // okay, camera enabled, we need to start face streaming
                //face.activateFaceStreaming();
            }
        }
    }, [])
    let { displayOptions, displayOptionsDispatch } = useContext(DisplayContext)
    if (enableScreenShare === undefined) {
        enableScreenShare = true;
    }
    if (enableVideo === undefined) {
        enableVideo = true;
    }
    if (enableAudio === undefined) {
        enableAudio = true;
    }

    let muteButton: ReactElement | undefined;
    if (enableAudio) {
        let enabled: boolean;
        if (displayOptions.connectState) {
            enabled = room.localParticipant.isMicrophoneEnabled;
        } else {
            enabled = displayOptions.microphoneEnabled;
        }
        muteButton = (
            <AudioSelectButton
                isMuted={!enabled}
                onClick={() => {
                    if (displayOptions.connectState) {
                        room.localParticipant.setMicrophoneEnabled(!enabled)
                    } else {
                        displayOptionsDispatch({ type: 'setMicrophoneEnabled', payload: { newMicrophoneEnabled: !enabled } })
                    }
                }}
                onSourceSelected={async (device) => {
                    if (displayOptions.connectState) {
                        await room.switchActiveDevice("audioinput", device.deviceId);
                    } else {
                        displayOptionsDispatch({ type: 'setAudioDeviceId', payload: { newAudioDeviceId: device.deviceId } })
                    }
                }}
            />
        );
    }

    let videoButton: ReactElement | undefined;
    if (enableVideo) {
        let enabled: boolean = false;
        if (displayOptions.videoDevices === 0) {
            enabled = false;
            // displayOptionsDispatch({ type: 'setCameraEnabled', payload: { newCameraEnabled: false } })
        }
        else if (displayOptions.connectState) {
            enabled = !(camPub?.isMuted ?? true);
        } else {
            enabled = displayOptions.cameraEnabled;
        }
        videoButton = (
            <VideoSelectButton
                isEnabled={enabled}
                onClick={async () => {
                    if (displayOptions.videoDevices === 0) {
                        // There is no camera plugged in
                        displayOptionsDispatch({ type: 'setCameraEnabled', payload: { newCameraEnabled: false } })
                    }
                    else {
                        if (displayOptions.connectState) {
                            await room.localParticipant.setCameraEnabled(!enabled);
                        } else {
                            displayOptionsDispatch({ type: 'setCameraEnabled', payload: { newCameraEnabled: !enabled } })
                        }
                    }
                }}
                onSourceSelected={async (device) => {
                    if (displayOptions.connectState) {
                        await room.switchActiveDevice("videoinput", device.deviceId);
                    } else {
                        displayOptionsDispatch({ type: 'setVideoDeviceId', payload: { newVideoDeviceId: device.deviceId } })
                    }
                }}
            />
        );
    }

    let screenButton: ReactElement | undefined;
    if (enableScreenShare) {
        const enabled = room.localParticipant.isScreenShareEnabled;
        screenButton = (

            /* we make icons smaller                 label={enabled ? "Stop sharing" : "Share"}
*/
            <ControlButton
                label={enabled ? "" : ""}
                tooltip={enabled ? "Stop screen sharing" : "Start screen sharing"}
                icon={enabled ? faStop : faDesktop}
                onClick={() => {
                    if (enabled) {
                        room.localParticipant.setScreenShareEnabled(false);
                    } else {
                        room.localParticipant.setScreenShareEnabled(true);
                    }
                }}
            />
        );
    }

    const onLayoutSelected = (newLayoutType: LayoutType) => {
        displayOptionsDispatch({
            type: 'setLayout', payload: { newLayout: newLayoutType }
        })
    }

    const layoutSelectButton = (
        <LayoutSelectButton
            layoutType={displayOptions.stageLayout ? displayOptions.stageLayout : LayoutType.Grid}
            onLayoutSelected={onLayoutSelected}
        />
    );

    const onSourceSelected = (newSource: VideoSource) => {
        displayOptionsDispatch({
            type: 'setSource', payload: { newSource: newSource }
        })
    }

    const sourceSelectButton = (
        <SourceSelectButton
            source={displayOptions.selectedVideoSource ? displayOptions.selectedVideoSource : VideoSource.Auto}
            onSourceSelected={onSourceSelected}
        />
    );

    const chatButton = (
        <ControlButton
            tooltip="Chat"
            icon={faComment}
            label={""}
            onClick={chatClicked}
        />
    );

    const joinButton = (
        <ControlButton
            className={displayOptions.connectState ? "leave-button" : "join-button"}
            label={displayOptions.connectState ? "Leave" : "Join Now"}
            tooltip={displayOptions.connectState ? "End session" : "Join session"}
            onClick={joinLeaveClicked}
        />
    );

    return (
        <div>
            {muteButton}
            {videoButton}
            {displayOptions.connectState && screenButton}
            {displayOptions.connectState && layoutSelectButton}
            {displayOptions.connectState && sourceSelectButton}
            {displayOptions.connectState && chatButton}
            {joinButton}
        </div>
    );
};