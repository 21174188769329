import React, { Fragment, useEffect, useState } from "react";
import { computeEpochtoPrettyDateStr, computeEpochtoTimeStr, IScheduleCard, secondsToDhms } from "../../utils/eduutils";
import { useUser } from "../../utils/useUser";
import { tdrAlert, tdrLog } from "../../utils/utils";

type ChildProps = {
    nextSchedule: IScheduleCard | null
    timer: number
    refreshCallback: () => void | null,
}

const TopicTimer: React.FC<ChildProps> = ({ nextSchedule = null, timer = -1, refreshCallback = () => { } }) => {
    const user = useUser();
    const [countdownDisplay, setCountdownDisplay] = useState("");
    const [startDate, setStartDate] = useState("");
    const [duration, setDuration] = useState("");
    var timer1: ReturnType<typeof setTimeout>;

    //var timer: ReturnType<typeof setTimeout> = 
    const displayCountdownValues = (countDownValues: number[]) => {
        if (countDownValues[0] > 0) { return countDownValues[0] + (countDownValues[0] === 1 ? ' day' : ' days') + ' ' + (countDownValues[1] > 0 ? (countDownValues[1] + (countDownValues[1] === 1 ? ' hr' : ' hrs')) : ""); }
        if (countDownValues[1] > 0) { return countDownValues[1] + (countDownValues[1] === 1 ? ' hr' : ' hrs'); }
        if (countDownValues[2] === 0) { return countDownValues[3] + 's'; }
        if (countDownValues[2] > 15) {
            return countDownValues[2] + 'min ';
        }
        return countDownValues[2] + 'min ' + countDownValues[3] + 's';


    }
    const setCountdownValues = (countDown: number) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
        return [days, hours, minutes, seconds];
    };

    const updateTimer = () => {
        if (nextSchedule && user.authenticated) {
            var newInterval = 60 * 1000;
            var curTime = (new Date()).getTime();
            tdrLog("updating my timer 5 ")
            // The current schedule already ended so we find the next session
            if (curTime > nextSchedule.endtime) {
                // This means we need to find the next schedule. - best to do a refreshCallback
                refreshCallback();
                return;
            }
            var timedifference = (nextSchedule.starttime as number) - Date.now();
            if (timedifference < 0) {
                // That means started already so we find out how long it has been started for
                timedifference = -timedifference;
            }
            setCountdownDisplay(displayCountdownValues(setCountdownValues(timedifference)));
            if (timedifference > 0) {
                if (timedifference < 15 * 60 * 1000) {
                    newInterval = 1000; // if < 15min update every second
                }
                else if (timedifference < 2 * 60 * 60 * 1000) {
                    newInterval = 60 * 1000; // if < 2 hr update every min
                }
                else {
                    newInterval = 60 * 1000; // update every hour
                }
            }
            else {
                // Session has started
                newInterval = 60 * 1000 // update every min
            }
            timer1 = setTimeout(() => {
                updateTimer();
            }, newInterval);
        }
    }


    useEffect(() => {
        if (nextSchedule !== null && nextSchedule.starttime !== null && nextSchedule.endtime !== null) {
            updateTimer();
            setStartDate(computeEpochtoPrettyDateStr(nextSchedule.starttime.toString(), 'ddd, MMM Do'));
            // We cannot use nextSchedule.duration because the duration on the topic maybe different from the 
            // actual generated time of the session because the creator may change the timing of the sessions
            // which only affect future sessions but not sessions that may already just started.
            const actualDuration = +nextSchedule.endtime - +nextSchedule.starttime;
            setDuration(secondsToDhms((actualDuration / 1000) as number, false));
        }
        return () => {
            // This part is important so the timer gets cleared and you don't have multiple timers running during a refresh
            if (timer1 !== null) {
                clearTimeout(timer1);
            }
        };
    }, [nextSchedule?.starttime, nextSchedule?.endtime]);

    return (
        <Fragment>
            <div style={{ textAlign: "center", fontWeight: 600, color: '#555555', fontSize: '0.7em' }}>
                {nextSchedule && nextSchedule.starttime && nextSchedule.endtime &&
                    <p className="p-sm">{(nextSchedule.starttime < Date.now()) ? "Session started for " : "Next Session In "} {countdownDisplay}<br />
                        {startDate} {computeEpochtoTimeStr(nextSchedule.starttime.toString())} <br />
                        Duration: {duration}
                    </p>
                }
            </div>
        </Fragment >
    );
};



export default TopicTimer;
