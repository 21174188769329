import { CircularProgress, LinearProgress } from "@mui/material";


export const LinearProgressWithLabel = (props: any) => {
    return (
        <div>
            <div className={(props.value > 1 && props.value < 100) ? "text-center rowpadding" : "divHide"}>
                <LinearProgress sx={{ alignContent: "center" }} variant="determinate" {...props} />
            </div>
            <div className={(props.value === 1) ? "text-center rowpadding" : "divHide"}>
                <CircularProgress />
            </div>
        </div>
    );
}
