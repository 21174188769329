import React from "react";
import FaqIntegration1 from "../components/Faqs/FaqIntegration1";
import Layout from "../layout/Layout";
import PageTitle from "../layout/PageTitle";

const FaqIntegration = () => {
  return (
    <Layout headerBtn footer={2}>
      <div>
        <PageTitle pageTitle="Timedora - FAQs Frequently Asked Questions" />
        <FaqIntegration1 />
      </div>
    </Layout>
  );
};

export default FaqIntegration;
