import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import LoadingButton from "@mui/lab/LoadingButton";
import { Col, Row } from "react-bootstrap";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { api as apiproto } from "../../apiproto";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import { emailValidation, generateTopicURL, IScheduleCard } from "../../utils/eduutils";
import { useNavigate } from "react-router-dom";
import { tdrAlert, tdrLog } from "../../utils/utils";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import FormControl from "@mui/material/FormControl/FormControl";


type StripeKeys = {
    clientSecret: string;
    publishableKey: string;
};

type ChildProps = {
    stripeKeys: StripeKeys,
    existingPayment: string,
    topic: apiproto.ITopic | null | undefined,
    topicOwners: apiproto.IUser[] | null | undefined,
    scheduleCard: IScheduleCard | null
    paymentCancel?: () => void | null,
    paymentSuccess?: () => void | null,
    parentLoading?: boolean,
}

const BookCheckout: React.FC<ChildProps> = ({ stripeKeys = { clientSecret: "", publishableKey: "" }, existingPayment = "", topic = null, topicOwners = [], scheduleCard = null, paymentCancel = () => { }, paymentSuccess = () => { }, parentLoading = false }) => {
    const api = useAPI();
    const user = useUser();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string | null | undefined>(null);
    const [note, setNote] = useState("");
    const [emailAddresses, setEmailAddresses] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPaymentSuccess, setConfirmPaymentSuccess] = useState(false);
    const [setupIntentID, setSetupIntentID] = useState<string | null>(null);
    const [paymentInfo, setPaymentInfo] = useState({ lastName: "", firstName: "", email: "" });
    const [closeButton, setCloseButton] = useState(false);
    const [paymentRadioValue, setPaymentRadioValue] = React.useState('new');
    const [isBookerOwner, setIsBookerOwner] = useState(false);
    const [canInviteGuest, setCanInviteGuest] = useState(false);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentRadioValue((event.target as HTMLInputElement).value);
    };

    const isReserverTheOwner = () => {
        if (topicOwners !== null) {
            for (var owner of topicOwners) {
                if (owner.userId === user.userId) {
                    return true;
                }
            }
        }
        return false
    }


    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPaymentInfo({
            ...paymentInfo,
            [event.target.name]: event.target.value
        });
    };

    const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        setNote(event.target.value);
    };

    const handleEmailAddressesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        setEmailAddresses(event.target.value);
    };

    const convertTextToEmailList = () => {
        var str: string = emailAddresses.replace(/,\s*$/, "");
        var newemails: string[] = str.split(",");
        if (newemails && newemails.length > 0) {
            for (const email of newemails) {
                if (!emailValidation(email)) {
                    setEmailError(email.length === 0 ? 'Email is not valid.' : email + ' is not valid.');
                    return null;
                }
            };
            return newemails;
        }
        return null;
    }

    const getUserProfile = async (userId: string) => {
        try {
            const userResult = await api.getUser({ assets: {} }, { userId: userId });
            if (userResult && userResult.user) {
                const userinfo = userResult.user;
                if (userinfo.firstName && userinfo.lastName && userinfo.emailAddress) {
                    setPaymentInfo({ firstName: userinfo.firstName, lastName: userinfo.lastName, email: userinfo.emailAddress })
                }
                return userResult.user;
            }
            /*
            if (topicOwners && topicOwners.length > 0) {
                setPaymentInfo({ firstName: topicOwners[0].firstName!, lastName: topicOwners[0].lastName!, email: topicOwners[0].emailAddress! })
            }*/
        }
        catch (err: any) {
            // We don't throw this error back since the IDs may have been deleted for some reason
            // and this not necessarily a fatal error.
        }
        return null;
    }

    useEffect(() => {

        if (user.userId !== null && stripeKeys.publishableKey.length > 0 && topic !== null) {
            getUserProfile(user.userId);
            // See if this reservation is made by the owner
            setIsBookerOwner(isReserverTheOwner());
            setCanInviteGuest(isInvitingAllowed());
        }
    }, [stripeKeys.publishableKey]);

    useEffect(() => {
        if (existingPayment.length > 0) {
            setPaymentRadioValue('existing');
        }
    }, [existingPayment.length]);

    useEffect(() => {
        // If the parent component is still processing like joining a sessiosn
        // we want confirm booking to be still grayed out and loading...
        // The isLoading does not need to be true at this point since processing has passed on to parent        
        if (parentLoading) {
            setIsLoading(false);
        }
        else {
            setMessage("");
        }
    }, [parentLoading]);
    /*
        const isInvitingAllowed = () => {
            // Inviting is only allowed if price is free and the person is the owner
            const isOwner = isReserverTheOwner();
            if (isOwner && ((topic?.cost && topic?.cost === 0) || (topic?.costType === apiproto.CostType.free))) {
                return true;
            }
            return false;
        }
    */
    const isInvitingAllowed = () => {
        // Inviting is only allowed if price is free
        if ((topic?.cost && topic?.cost === 0) || topic?.costType === apiproto.CostType.free) {
            return true;
        }
        return false;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if (closeButton) {
            // Here is means something failed with Stripe 
            // we refresh entire page to get a refreshed Stripe object.
            // Here is why, user A and user B both checkout and then both hit confirm
            // the first one will succeed. User B will fail with require_payment_method which really should not be the error name
            // regardless this failure, we need to refresh.
            setCloseButton(false);
            window.location.reload();
            return;
        }
        if (!user.authenticated) {
            navigate('/login');
        }
        if (topic !== null && canInviteGuest) {
            // This is free... no need to do any stripe payment- just proceed to paymentSuccess and do a post joinSession
            if (isBookerOwner && canInviteGuest) {
                // If you are the owner , then you need to have guest list to invite
                if (emailAddresses.length === 0) {
                    // you must at least type something in the guest list
                    setEmailError('You must enter emails for your guests.');
                    setIsLoading(false);
                    return;
                }
            }
            if (emailAddresses !== null) {
                if (emailAddresses.length > 0) {
                    const emailList = convertTextToEmailList();
                    if (emailList !== null) {
                        // TODO - need to call some API with the list of emails or string depending on how it is structured using scheduleCard.timeid or scheduleCard.roomid 
                        let currentEmail = "";
                        for (const emailAddress of emailList) {
                            currentEmail = emailAddress;
                            try {

                                if (scheduleCard?.timeId) {
                                    await api.joinTime(scheduleCard?.timeId, topic.topicId!, emailAddress)
                                } else if (scheduleCard?.roomId) {
                                    await api.joinRoom(scheduleCard?.roomId, topic.topicId!, emailAddress)
                                }
                            }
                            catch (err: any) {
                                if (err.name === 'TIME_NOT_AVAILABLE') {
                                    setMessage("The time you booked may have been reserved or the time has passed and is no longer available.");
                                    setIsLoading(false);
                                    return;
                                }
                                else if (err.name === 'USER_ALREADY_ADDED') {
                                    setMessage(currentEmail + " is already added");
                                    // Don't return -silent process the rest of emails and let it error silently
                                }
                                else {
                                    setMessage(err.name + ' ' + err.description + " An error occurred during booking");
                                    setIsLoading(false);
                                    return;
                                }
                            }

                        }
                    }
                    else {
                        // Suppose to have email, but some emails are not valid.
                        setIsLoading(false);
                        return;
                    }
                }
            }
            paymentSuccess();
            setConfirmPaymentSuccess(true);
            setIsLoading(false);
            return;
        }
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setIsLoading(false);
            return;
        }
        if (!stripe) {
            setIsLoading(false);
            return;
        }
        if (stripeKeys.clientSecret.length === 0) {
            setIsLoading(false);
            return;
        }
        if (topic === null) {
            setIsLoading(false);
            return;
        }

        let returnurl = "https://" + window.location.hostname + generateTopicURL(topic);
        if (window.location.hostname === 'localhost') {
            returnurl = "http://localhost:3000" + generateTopicURL(topic)
        }
        if (paymentRadioValue === 'existing') {
            setMessage("Processing payment on file...please wait...");
            setSetupIntentID(null);
            setIsLoading(true);
            paymentSuccess();
            return;
        }
        else {

        }

        const { setupIntent, error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: returnurl
            },
            redirect: "if_required"
        })
        if (error) {
            tdrAlert('The error is ' + error.type)
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            tdrAlert(error.type)
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            }
            else if (error.type === "invalid_request_error") {
                // This happens when the same user press book on 2 different tabs or browser
                // Tries to enter the credit card in the 1st one - but possibly the 2nd one made the 1st invalid
                // so it needs to be closed.
                setMessage("There was an invalid request. Close this screen and try again.");
                setCloseButton(true);
            }
            else {
                setMessage("An unexpected error occurred.");
            }
        }
        else if (setupIntent) {
            setMessage("Processing payment...Please do not close your browser.");
            setIsLoading(true);
            setSetupIntentID(setupIntent.id);
            return;
        }
        setIsLoading(false);
    };

    const refreshPaymentIntent = () => {
        if (setupIntentID != null && stripe) {
            stripe.retrieveSetupIntent(stripeKeys.clientSecret).then(({ setupIntent }) => {
                switch (setupIntent!.status) {
                    case "succeeded":
                        setMessage("Payment authorized...please wait...");
                        //setConfirmPaymentSuccess(true);
                        setSetupIntentID(null);
                        setIsLoading(true);
                        paymentSuccess();
                        break;
                    case "processing":
                        setMessage("Your payment setup is processing.");
                        setIsLoading(true);
                        break;
                    case "requires_payment_method":
                        // Do not trap this error because it can come here if you retrieve too quickly and
                        // stripe has still not process payment info.
                        // Not sure Stripe returns this error instead of something else when payment is actually submitted
                        setMessage("Your payment was not successful, please try again.");
                        setIsLoading(false);
                        //setPaymentIntentID(null);
                        //setCloseButton(true);
                        break;
                    case "canceled":
                        setMessage("Payment setup was cancelled. This can be due to a card error or someone booked the session just ahead of you. Please try again.");
                        setSetupIntentID(null);
                        setIsLoading(false);
                        setCloseButton(true);
                        break;
                    case "requires_confirmation":
                        setMessage("Payment requires confirmation.");
                        setIsLoading(false);
                        break;
                    default:
                        setMessage("Something went wrong.");
                        setSetupIntentID(null);
                        setIsLoading(false);
                        break;
                }
            }).catch((err) => {
                setMessage("An error occurred processing payment setup. Please try again");
                tdrLog('error occurred - ' + err.name);
                setSetupIntentID(null);
                setIsLoading(false);
            });
        }
    }

    useEffect(() => {
        if (setupIntentID !== null) {
            const interval = setInterval(() => {
                refreshPaymentIntent()
            }, 5000)
            return () => clearInterval(interval)
        }
    }, [setupIntentID])

    return (
        <Fragment>

            <ValidatorForm id="payment-form" onSubmit={handleSubmit}>
                {isBookerOwner ? "" :
                    <Fragment>
                        <Row>
                            <Col>
                                <div className="form-group form-input">
                                    <div className="mb-10"><b>Booking information</b></div>
                                    <TextValidator
                                        label="Email address"
                                        type="text"
                                        value={paymentInfo.email}
                                        onChange={handleInputChange}
                                        name="email"
                                        id="email"
                                        disabled={true}
                                        //disabled={confirmPaymentSuccess}
                                        className="form-control"
                                        placeholder="Enter email address"
                                        validators={['required', 'isEmail']}
                                        errorMessages={['First name is required']}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="form-group form-input">
                                    <TextValidator
                                        label="First name"
                                        type="text"
                                        value={paymentInfo.firstName}
                                        onChange={handleInputChange}
                                        name="firstName"
                                        id="firstname"
                                        //disabled={confirmPaymentSuccess}
                                        disabled={true}
                                        className="form-control"
                                        placeholder="Enter first name"
                                        validators={['required']}
                                        errorMessages={['First name is required']}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="form-group form-input">
                                    <TextValidator
                                        label="Last name"
                                        type="text"
                                        value={paymentInfo.lastName}
                                        onChange={handleInputChange}
                                        //disabled={confirmPaymentSuccess}
                                        disabled={true}
                                        name="lastName"
                                        id="lastname"
                                        className="form-control"
                                        validators={['required']}
                                        errorMessages={['Last name is required']}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Fragment>
                }
                {(canInviteGuest && isBookerOwner) ?
                    <Row>
                        <Col>
                            <div className="mb-10"><b>Invitees</b></div>
                            <div className="mb-20">
                                <TextareaAutosize
                                    minRows={3}
                                    id="invitees"
                                    aria-label="minimum height"
                                    onChange={handleEmailAddressesChange}
                                    placeholder={"Enter emails for guests you want to invite followed by comma"}
                                    value={emailAddresses}
                                    disabled={confirmPaymentSuccess}
                                    style={{ width: '100%' }}
                                />
                                {emailError.length > 0 && <span className="form-error-msg">{emailError}</span>}
                            </div>
                        </Col>
                    </Row> : ""
                }

                {(topicOwners && topicOwners.length > 0) &&
                    <Row>
                        <Col>
                            <div className="mb-10"><b>Note</b></div>
                            <div className="mb-20">
                                <TextareaAutosize
                                    minRows={3}
                                    aria-label="minimum height"
                                    onChange={handleTextAreaChange}
                                    placeholder={"Enter any note you want " + topicOwners[0].firstName! + " to read to prepare for the meet."}
                                    value={note}
                                    disabled={confirmPaymentSuccess}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </Col>
                    </Row>
                }


                <Fragment>
                    {(topic?.cost && topic?.cost > 0) ?
                        <div>
                            {existingPayment.length > 0 &&
                                <Row>
                                    <Col>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={paymentRadioValue}
                                                onChange={handleRadioChange}
                                            >
                                                <FormControlLabel value="existing" control={<Radio />} label={existingPayment} />
                                                <FormControlLabel value="new" control={<Radio />} label="Use new payment method" />
                                            </RadioGroup>
                                        </FormControl>
                                        <br />
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    {paymentRadioValue === 'new' ?
                                        <PaymentElement id="payment-element" /> : ""}
                                </Col>
                            </Row>
                        </div> : ""}
                    <Row>
                        <Col>
                            <br />
                            <p className="form-error">{message}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                sx={{ float: 'right', mr: 2 }}
                                size="large"
                                variant="text"
                                onClick={paymentCancel}
                                hidden={false}
                                disabled={isLoading || confirmPaymentSuccess}>
                                Cancel
                            </Button>
                            <LoadingButton
                                sx={{ float: 'right', mr: 2 }}
                                size="large"
                                variant="contained"
                                loading={isLoading}
                                type="submit"
                                hidden={false}
                                disabled={parentLoading || isLoading || !stripe || !elements || confirmPaymentSuccess}>
                                {closeButton ? "Close" : ((isBookerOwner && ((topic?.cost && topic?.cost === 0) || (topic?.costType === apiproto.CostType.free))) ? "Invite Guests" : "Confirm Booking")}
                            </LoadingButton>
                        </Col>
                    </Row>
                </Fragment>

            </ValidatorForm>
        </Fragment>
    );
}

export default BookCheckout;