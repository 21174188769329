import { alpha, Dialog, DialogContentText, IconButton, Menu, MenuItem, MenuProps, styled } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api as apiproto } from "../../apiproto";
import { computeEpochtoPrettyDateStr, computeEpochtoTimeStr, getUserPhotoURL } from "../../utils/eduutils";
import { useAPI } from "../../utils/useAPI";
import { useUser } from "../../utils/useUser";
import CloseIcon from '@mui/icons-material/Close';
import { tdrLog } from "../../utils/utils";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DialogMessageBox from "../../components/DialogMessageBox";
import { ChatBox } from "../../components/conference/ChatBox";
import MenuIcon from '@mui/icons-material/Menu';
import { Col, Row } from "react-bootstrap";
//import { Room } from "livekit-client";

type TopicParams = {
    id: string;
}
type ChildProps = {
    courseid: string | null,
    popopen: boolean,
    onClose: () => void,
    objectid: string | null // This is if you want to drill down on members of room or time    
}
// ratingsCount: the 5 different values, ratingsAverage: the average of the values, review: to show the actual text, bar: true to show distribution of ratings
const TopicAttendees: React.FC<ChildProps> = ({ courseid = null, popopen = false, onClose, objectid = null }) => {
    const [topic, setTopic] = useState<apiproto.ITopic | null>(null);
    const api = useAPI();
    const user = useUser();
    const topicParams = useParams<TopicParams>();
    const [open, setOpen] = React.useState(false);
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: true });
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [recipientId, setRecipientId] = useState("");
    const [chatOpen, setChatOpen] = useState(false);

    const getTopics = (topicId: string) => {
        if (user.userId && user.authenticated) {
            (async () => {
                const topic = await api.getTopic({ members: { entity: { user: { includeDescription: false, assets: {} } } }, rooms: { members: { entity: { user: { includeDescription: false, assets: {} } } }, scheduleSet: { schedules: { times: { members: { entity: { user: { includeDescription: false, assets: {} } } } } } } } }, topicId);
                if (topic && topic.topic) {
                    tdrLog("The topic is " + JSON.stringify(topic.topic))
                    setTopic(topic.topic);
                }
            })()
        }
    }

    const handleChatClose = () => {
        setChatOpen(false);
        setRecipientId("");
    };

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuopen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // Because styled menu is the same for all users, we need to know which one was initially clicked.
        // so we store it in id
        const recipient = event.currentTarget.getAttribute("id");
        if (recipient !== null) {
            setRecipientId(recipient);
        }
        setAnchorEl(event.currentTarget);
    };
    const handleActionMenuClose = () => {
        setAnchorEl(null);
    };

    const cancelAndRefundUser = async (meta: any) => {
        handleActionMenuClose();
        if (meta.action) {
            if (meta.action === 'canceltime') {
                if (!meta.confirm) {
                    setDialogMessage({ title: "Confirm cancellation", message: "Are you sure you want to cancel this user session? If you cancel, any billing for this session will be refunded. If session is free, the user's reservation will be cancelled.", meta: { ...meta, confirm: true }, confirm: true });
                }
            }
            else if (meta.action === 'cancelroom') {
                if (!meta.confirm) {
                    setDialogMessage({ title: "Confirm cancellation", message: "Are you sure you want to cancel this user session? If you cancel, any billing for the sessions paid up front will be refunded. Any subscription will not be renewed. The user will no longer be booked for any sessions.", meta: { ...meta, confirm: true }, confirm: true });
                }
            }

        }
    }

    const sendMessageToUser = () => {
        handleActionMenuClose();
        setChatOpen(true);
    }

    const sendMessageToAll = () => {
        handleActionMenuClose();
        if (objectid !== null) {
            setRecipientId(objectid);
        }
        setChatOpen(true);
    }

    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: {}, confirm: true });
    }

    useEffect(() => {
        if (topicParams.id) getTopics(topicParams.id)
        else if (courseid) {
            getTopics(courseid);
        }
        setOpen(popopen);
    }, [topicParams.id, refresh, popopen]);

    /*
    useEffect(() => {
        setOpen(popopen);
    }, [popopen]);
*/
    const handleCancel = () => {
        clearDialog();
    }

    const handleActionConfirm = (meta: any) => {
        clearDialog();
        if (meta.action && objectid !== null) {
            if (meta.action === 'canceltime') {
                cancelTime(objectid, recipientId);
            }
            else if (meta.action === 'cancelroom') {
                cancelRoom(objectid, recipientId);
            }
        }
    }

    const cancelRoom = async (roomid: string, userid: string) => {
        setIsLoading(true);
        try {
            const result = await api.leaveRoom(roomid, userid);
            if (result !== null) {
                setRefresh(refresh + 1);
            }
        }
        catch (err: any) {
            setDialogMessage({ title: err.name, message: "An error occur cancelling session for user", meta: {}, confirm: false });
        }
        setIsLoading(false);
        return;
    }

    const cancelTime = async (timeid: string, userid: string) => {
        setIsLoading(true);
        try {
            const result = await api.leaveTime(timeid, userid);
            if (result !== null) {
                setRefresh(refresh + 1);
            }
        }
        catch (err: any) {
            processCancelError(err);
        }
        setIsLoading(false);
        return;
    }

    const processCancelError = (err: any) => {
        if (err.name === 'TIME_NOT_AVAILABLE') {
            setDialogMessage({ title: "Error canceling", message: "The user is not booked for this session.", meta: {}, confirm: false });
        }
        else if (err.name === 'CANCEL_TIME_ALREADY_STARTED') {
            setDialogMessage({ title: "Error canceling", message: "You cannot cancel a session that has already started.", meta: {}, confirm: false });
        }
        else {
            setDialogMessage({ title: err.name, message: "An error occur cance;ling session for user" + err.message + " TimeID:" + objectid + "User ID:" + recipientId, meta: {}, confirm: false });
        }
    }

    return (

        <Fragment>
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Dismiss" : "Dismiss"} onCancel={handleCancel} onConfirm={handleActionConfirm} visible={dialogMessage.title.length > 0} />
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={chatOpen}
                onClose={handleChatClose}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleChatClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <DialogContentText>
                        <div className="chat-container-light">
                            <ChatBox recipientId={recipientId} />
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open && !chatOpen}
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    {(topic && topic.members) ?
                        <div>
                            <div className="divpadding">
                                {topic.members!.map((member: apiproto.IMember, index) => (
                                    member.entity?.user &&
                                    <Row style={{
                                        padding: '20px 0px 20px 0px', border:
                                            '1px solid #888888'
                                    }} key={'member' + index}>
                                        {/* Author Data */}
                                        <Col>

                                            {/* Author Avatar */}
                                            <div className="author-avatar">
                                                <Avatar sx={{ mr: 2, float: 'left' }} src={getUserPhotoURL(member.entity?.user?.user!)} />
                                                <b>{member.entity?.user?.lastName} {member.entity?.user?.firstName}</b><br />
                                                ({member.owner ? "Creator" : ""} {member.instructor ? "Host" : ""})                                        <br />
                                            </div>
                                        </Col>
                                        <Col md="4" sm="5" xs="5">
                                            {topic.members && topic.members?.length > 1 &&
                                                <LoadingButton
                                                    style={{ float: 'right' }}
                                                    id={member.entity?.user?.userId!}
                                                    aria-controls={open ? 'action-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    variant="contained"
                                                    disableElevation
                                                    loading={isLoading}
                                                    onClick={sendMessageToAll}
                                                >
                                                    Message All
                                                </LoadingButton>}
                                            <StyledMenu
                                                id="action-menu"
                                                MenuListProps={{
                                                    'aria-labelledby': 'customize-action-button',
                                                }}
                                                anchorEl={anchorEl}
                                                open={menuopen}
                                                onClose={handleActionMenuClose}
                                            >
                                                <MenuItem onClick={(e) => {
                                                    sendMessageToUser();
                                                }} >
                                                    Broadcast Message
                                                </MenuItem>
                                            </StyledMenu>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                            {topic.rooms!.map((room: apiproto.IRoom, roomindex) => (
                                <div key={room.roomId}>
                                    {topic.singleSession !== null ? room.scheduleSet?.schedules!.map((schedule: apiproto.ISchedule, index) => (
                                        <div key={'schedule' + index}>
                                            {schedule.times!.map((time: apiproto.ITime, index) => (
                                                (time.members && time.members.length > 0) && (objectid === null || objectid === time.timeId) &&
                                                <div className="divpadding" key={"csmember" + time.timeId}>
                                                    <div className="attendeeDate row">
                                                        <div className="col">
                                                            {computeEpochtoPrettyDateStr(time.startTime!.toString(), 'ddd, MMM Do')} {computeEpochtoTimeStr(time.startTime!.toString())} - {computeEpochtoTimeStr(time.endTime!.toString())}
                                                        </div>
                                                    </div>

                                                    {time.members!.map((member: apiproto.IMember, index) => (
                                                        member.accepted &&
                                                        <Row style={{ paddingTop: '10px' }} key={'member' + index}>
                                                            {/* Author Data */}
                                                            <Col>
                                                                {/* Author Avatar */}
                                                                <div className="author-avatar">
                                                                    <Avatar sx={{ mr: 2, float: 'left' }} src={getUserPhotoURL(member.entity?.user?.user!)} />
                                                                    <b>{member.entity?.user?.lastName} {member.entity?.user?.firstName}</b>
                                                                </div>
                                                            </Col>
                                                            <Col md="2" sm="3" xs="5">
                                                                <LoadingButton
                                                                    style={{ width: '60px', float: 'right' }}
                                                                    id={member.entity?.user?.userId!}
                                                                    aria-controls={open ? 'action-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    variant="outlined"
                                                                    disableElevation
                                                                    loading={isLoading}
                                                                    onClick={handleClick}
                                                                    startIcon={<MenuIcon />}
                                                                    endIcon={<KeyboardArrowDownIcon />}
                                                                >
                                                                </LoadingButton>
                                                                <StyledMenu
                                                                    id={"action-menu" + member.entity?.user?.userId!}
                                                                    MenuListProps={{
                                                                        'aria-labelledby': 'customized-action-button',
                                                                    }}
                                                                    anchorEl={anchorEl}
                                                                    open={menuopen}
                                                                    onClose={handleActionMenuClose}
                                                                >
                                                                    <MenuItem onClick={(e) => {
                                                                        sendMessageToUser();
                                                                    }} >
                                                                        Send Message
                                                                    </MenuItem>

                                                                    <MenuItem onClick={(e) => {
                                                                        cancelAndRefundUser({ action: 'canceltime' });
                                                                    }} >
                                                                        {(topic.costType === apiproto.CostType.free || topic.cost === 0) ? 'Cancel Booking' : 'Cancel &amp; Refund'}
                                                                    </MenuItem>
                                                                </StyledMenu>
                                                            </Col>

                                                        </Row>
                                                    ))}

                                                </div>
                                            ))}
                                        </div>
                                    )) :
                                        <div>
                                            {room.members && room.members!.length > 0 && (objectid === null || room.roomId === objectid) &&
                                                <div className="attendeeDate row">
                                                    {(room.room?.length === 0 && room.section?.length === 0) ? <div className="col">{"Room " + (roomindex + 1)}</div> :
                                                        <div className="col">
                                                            {room.room?.startsWith("v:") ? "" : room.room} {room.section}
                                                        </div>}
                                                </div>
                                            }
                                            {room.members!.map((member: apiproto.IMember, index) => (
                                                member.accepted && (objectid === null || room.roomId === objectid) &&
                                                <Row style={{ paddingTop: '10px' }} key={'member' + index}>
                                                    {/* Author Data */}
                                                    <Col>
                                                        {/* Author Avatar */}
                                                        < div className="author-avatar" >
                                                            <Avatar sx={{ width: 52, height: 52, mr: 2, float: 'left' }} src={getUserPhotoURL(member.entity?.user?.user!)} />
                                                            <b>{member.entity?.user?.lastName} {member.entity?.user?.firstName}</b>
                                                        </div>
                                                    </Col>
                                                    <Col md="2" sm="3" xs="5">
                                                        <LoadingButton
                                                            style={{ margin: '10px', float: 'right' }}
                                                            id={member.entity?.user?.userId!}
                                                            aria-controls={open ? 'action-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            variant="contained"
                                                            loading={isLoading}
                                                            disableElevation
                                                            onClick={handleClick}
                                                            endIcon={<KeyboardArrowDownIcon />}
                                                        >
                                                            Delete
                                                        </LoadingButton>
                                                        <StyledMenu
                                                            id="action-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'customized-action-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={menuopen}
                                                            onClose={handleActionMenuClose}
                                                        >
                                                            <MenuItem onClick={(e) => {
                                                                cancelAndRefundUser({ action: 'cancelroom' });
                                                            }} disableRipple>
                                                                Cancel &amp; Refund
                                                            </MenuItem>
                                                            <MenuItem onClick={handleActionMenuClose} disableRipple>
                                                                Close
                                                            </MenuItem>
                                                        </StyledMenu>                                                    </Col>

                                                </Row>
                                            ))}
                                        </div>
                                    }
                                </div>
                            ))
                            }

                        </div >

                        : <div></div>
                    }
                </DialogContent >
            </Dialog >
        </Fragment >
    );
};

export default TopicAttendees;
