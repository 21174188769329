import React from "react";
import PageTitle from "../layout/PageTitle";

const Privacy = () => {
    return (<div className="container">
        <PageTitle pageTitle="Timedora -Privacy" />

        <h1>Privacy Policy</h1>
        <br /><br />
        <h4>Information We Collect</h4>

        When you register on Timedora.com (Site), use the Site, complete forms, payment information or register to any of our programs, you directly provide us with most of the information we collect. We also collect information about your communications with Timedora, topic posts, profile description as well as any other content during the use of our Site. In addition, we automatically collect information and analytics data while you access, browse, view or otherwise use the Site. Through the use of the Site, you may receive information from 3rd party sources, providers and vendors which are also collected through our Site.
        <br /><br />
        <h4>Our Legal Basis for Using your Personal Information</h4>

        Under applicable laws where relevant, the processing of your personal information will be justified by a "lawful ground" for processing as detailed below.
        <br /><br />
        <h4>How Do We Use the Information Collected?</h4>
        Personal information is used to provide you with quality service and security and to operate the Site. Analytics data helps us understand how people use the Site so we can improve our software, services and marketplace function so as to perform our obligations to you as a user. Data is used to prevent fraud to ensure software and marketplace integrity and security. We also use the information collected to contact you and send you direct marketing communications, transactional notifications and to promote and advertise the Site. We may also use the data to conduct background checks for providers who may be in certain categories such as education. Information collected may also be needed to comply with lawful requests by public authorities and to comply with applicable laws and regulations.
        <br /><br />
        <h4>How Long Do We Keep Personal Information</h4>
        We will keep personal information only for as long as is required to fulfil the purpose for which it was collected. However, in some cases we will retain personal information for longer periods of time.
        <br /><br />
        <h4>Children</h4>
        This Site is offered and available to users who are at least 18 years of age and of legal age to form a binding contract. Minors under 18 and at least 13 years of age, are only permitted to use the Site through an account owned by a parent or legal guardian with their appropriate permission. Minors under 13 are only permitted to use the Site or content that is in the educational category such as educational classes. As a parent, you have control over the personal information companies collect online from your kids under 13. The Children’s Online Privacy Protection Act gives you tools to do that. You are required to sign up as a guardian or parent for the child if you plan to register for sessions that provide educational content and learnings for your child. We do not knowingly collect personal information from children under 13. Parents should note that since online sessions take place over online videos for the most part, video images and audio of Children are recorded in our system.
        <br /><br />
        <h4>Session video recordings</h4>
        Timedora records video during sessions with providers. Video recordings may be made available to the provider and shared by the provider during sessions.

        In some cases, sessions are taught by a person that is part of an organization, group, or team. In such cases, recordings may be accessed by the entire organization and access would not necessarily be limited to a single individual. In addition, we may use the session recordings for customer support, provide feedback to providers, and for compliance purposes.

        Timedora will obtain additional parental consent before we use any recordings that may contain children's video for promotional or other unexpected purposes. We request that providers DO NOT create copies of the recordings or share the recordings with anyone aside from Permitted Recipients, and also request that Permitted Recipients not download or re-share the recordings. While we expect providers to abide by our standards of conduct, we cannot ultimately control or monitor what  third parties ultimately do with recordings.
        <br /><br />
        <h4>Sharing Personal Information with Third Parties</h4>
        We share personal information with third parties in order to operate the Site, provide our services to you, fulfill obligations imposed on us by applicable laws and regulations, and prevent fraud, infringements and illegal activities.
        <br /><br />
        <h4>Where We Store Personal Information</h4>
        Some of the personal information you provide to us will be stored or processed on our behalf by third party suppliers and data processors and may be located in other jurisdictions, such as the United States and other countries where we may operate and cache data.
        <br /><br />

        <h4>Cookies</h4>
        We use cookies and similar technologies (such as web beacons, pixels, tags, and scripts) to improve and personalize your experience, provide our services, analyze website performance and for marketing purposes.
        <br /><br />

        <h4>Do Not Track (DNT)</h4>
        Our Site does not respond to Do Not Track (DNT) signals.
        <br /><br />
        <h4>External Links</h4>
        The Site contains links to third party sites and if you link to a third party site from the Site, any data you provide to that site and any use of that data by the third party are not under the control of Timedora and are not subject to this Policy.
        <br /><br />
        <h4>Security</h4>
        We implement technical and organizational measures to maintain the security of the Site and your personal information and in preventing unauthorized access, loss, misuse, alteration, destruction or damage to it through industry standard technologies and internal procedures.
        <br /><br />
        <h4>Rights of EU, EEA and UK Users</h4>
        Where we process personal data related to EU, EEA and UK users, further terms apply to our processing in relation to your rights as a data subject under EU data protection laws. If our processing of Personal Data is subject to European Union data protection law, you have certain rights with respect to that data and you can request access to, and rectification or erasure of your Personal Data. You have a right to transfer or receive a copy of the Personal Data in a usable and portable format if any automated processing of Personal Data is based on your consent.  You can object to, or obtain a restriction of, the processing of Personal Data under certain circumstances.

        You can send us specific instructions regarding the use of your data after your death if you are a resident of France. To request for such removal, please contact us at privacy@timedora.com. We may process Personal Data on behalf of another party such as our provider and any such request may be needed to be made directly to that party. For any complaint, we encourage you to contact us first but you have the right to lodge a complaint with a supervisory authority.
        <br /><br />
        <h4>Specific Provisions for California Residents</h4>
        If you are a California resident, you are entitled to specific privacy rights. Under California Business and Professions Code Section 22581,California residents under the age of 18 who are registered users of online sites, services, or applications have a right  to remove, or request and obtain removal of, content or information they have publicly posted. Please send an email to privacy@timedora.com if you wish to remove content or information publicly posted by you. Please note that to the extent we will remove the specific content you request to be removed, we cannot guarantee complete removal of online content or information posted and that the law may not permit or require removal in certain circumstances.
        <br /><br />
        <h4>Updating Personal Information</h4> – We take steps to ensure that the personal information we collect is accurate and up to date, and we provide you with the opportunity to update your information through your account profile settings. In the event that you believe your information is in any way incorrect or inaccurate, please let us know immediately.
        <br /><br />
        <h4>Contact Us</h4> - You can exercise your rights over your personal information, by contacting us at privacy@timdora.com.
        <br /><br />

    </div>)
};

export default Privacy;