import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { emailValidation, getRegularTopicMembers, getTopicShareStatus, KeyCodes } from "../../utils/eduutils";
import { SelectValidator, TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "react-bootstrap";
import { Avatar, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, TextareaAutosize } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from '@mui/icons-material/Close';
import { WithContext as ReactTags } from 'react-tag-input';
import React from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { api as apiproto } from "../../apiproto";
import { useAPI } from "../../utils/useAPI";
import { tdrAlert } from "../../utils/utils";

type ChildProps = {
    topic: apiproto.ITopic | null | undefined,
    owner: apiproto.IUser | null,
    visible: boolean,
    callback?: (value: any) => void | null,
}

type Tag = {
    id: string,
    text: string
}


const TopicShare: React.FC<ChildProps> = ({ topic = null, owner = null, visible = false, callback = () => { } }) => {

    const api = useAPI();
    const [emails, setEmails] = useState<Tag[]>([]);
    const [emailsNew, setEmailsNew] = useState<Tag[]>([]); // We want to be able to distinguish new emails from already added ones
    const [membersToDelete, setMembersToDelete] = useState<apiproto.IMember[]>([]);
    const [linkCopied, setLinkCopied] = useState(false);
    const [emailsCopied, setEmailsCopied] = useState(false);

    const [open, setOpen] = useState(false);
    const [topicStatus, setTopicStatus] = useState("private");
    const [topicOriginalStatus, setTopicOriginalStatus] = useState("private");
    const [submitStatus, setSubmitStatus] = useState({ error: "", submitting: false });
    const [emailError, setEmailError] = useState("");
    const [members, setMembers] = useState<apiproto.IMember[]>([]);
    const [sendInvite, setSendInvite] = useState(false);
    const [bccEmails, setBccEmails] = useState("");
    const [vanityURL, setVanityURL] = useState(topic?.vanityName ? topic.vanityName : "");
    const [vanityError, setVanityError] = useState("");

    const handleDelete = (i: number) => {
        const membersOfEmail = getUserIDByEmail(emails[i].text);
        setMembersToDelete([...membersToDelete, ...membersOfEmail]);
        setEmails(emails.filter((email, index) => index !== i));
        setEmailsNew(emailsNew.filter((email, index) => index !== i));
        //  generateEmailBCC();
    };

    const handleAddition = (email: Tag) => {
        if (emailValidation(email.text)) {
            setEmails([...emails, email]);
            setEmailsNew([...emailsNew, email]);
            setEmailError("")
        }
        else {
            setEmailError("Enter a valid email");
        }
        //    generateEmailBCC();
    };

    const handleDone = (status: string) => {
        callback(status);
    }

    const onCancel = () => {
        // Here we do nothing because we don't want user to accidentally tap outside dialog box and close.
    }


    const onClickCloseButton = () => {
        callback("none");
        setSendInvite(false);
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (vanityError.length > 0) {
            setVanityError("");
        }
        setVanityURL(event.target.value);
    };

    const getUserIDByEmail = (email: string) => {
        const member = members.filter((m) => m.entity?.user?.user?.emailAddress === email);
        return member;
    }

    const generateEmailBCC = () => {
        var emails = "";
        members.forEach((member: apiproto.IMember) => {
            const user = member.entity?.user?.user;
            if (user) {
                emails = user.emailAddress + "," + emails;
            }
        })
        setBccEmails(emails);
    }

    const submitState = async (event: FormEvent) => {
        if (event) {
            event.preventDefault();
        }
        // IF vanity name has change we first see if we can confirm the vanity name
        if (vanityURL !== topic?.vanityName) {
            const result = await updateVanityName();
            if (!result) {
                // We do not proceed if we cannot book the vanity name and it is typed.
                // We set for user to try again
                if (topic?.vanityName && topic.vanityName.length > 0) {
                    setVanityURL(topic.vanityName!)
                }
                else {
                    setVanityURL("")
                }
                return;
            }
        }
        publishTopicConfirm();
    }

    const sendInvites = (clientType: string) => {
        const subject = window.encodeURIComponent("Set up a time with me");
        const body = window.encodeURIComponent("You can reserve a time with me at https://timedora.com/t" + topic?.tinyCode);
        const bcc = window.encodeURIComponent(bccEmails);
        const to = window.encodeURIComponent(owner?.emailAddress!);
        var mailtobookmark = 'mailto:' + to + '?bcc=' + bccEmails + '&subject=' + subject + '&body=' + body;
        if (clientType === 'gmail') {
            mailtobookmark = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=' + to + '&bcc=' + bcc + '&su=' + subject + '&body=' + body;
        }
        else if (clientType === 'yahoo') {
            mailtobookmark = 'https://login.yahoo.com/?.src=ym&lang=&done=https%3A%2F%2Fcompose.mail.yahoo.com%2F%3Fto=' + to + '%26subj%3d' + subject + '%26body%3d' + body + '%26bcc%3d' + bcc;
        }
        else if (clientType === 'outlook') {
            mailtobookmark = 'https://outlook.live.com/owa/?path=/mail/action/compose&&to=' + to + '&bcc=' + bcc + '&subject=' + subject + '&body=' + body
        }
        window.open(mailtobookmark, '_blank', 'noopener,noreferrer');
    }

    const publishTopicConfirm = async () => {
        // TODO - in the transition of state from inviteonly, we need to remove all non owner instructor members- waiting for bulk API

        setEmailError("");
        if (topic === null || topic.topicId === null) {
            handleDone("error");
            return;
        }
        // We check if a price is >0 and make sure payment is setup before allowing
        // user to publish
        /* We comment out now since we will put user under our subaccount to reduce publishin friction
                if ((topic.cost && topic.cost > 0) || topic.costType !== apiproto.CostType.free) {
                    if (owner && !owner.paymentAccountActive && topicStatus !== "private") {
                        handleDone("paymentnotsetup");
                        return;
                    }
                }
                */
        try {
            if (topicStatus === "private") {
                // Unpublish action
                try {
                    setSubmitStatus({ error: "", submitting: true });
                    await api.deleteMember(topic.topicId!, "g_public");
                    /* not sure why we are individually deleting. Maybe old code and not needed after deleteMembers is added
                    for (const member of members) {
                        await api.deleteMember(topic.topicId!, member.entity?.user?.userId!);
                    }      
                    */
                    topic.searchable = false;
                    await api.updateTopic({ searchable: true }, topic, true);
                    await api.deleteMembers(topic.topicId!, { filterValue: false }, { filterValue: false }); // Delete all regular members
                    setSubmitStatus({ error: "", submitting: false });
                    handleDone("private");

                }
                catch (err: any) {
                    setSubmitStatus({ error: "", submitting: false });
                    handleDone("error");
                }
            }
            else if (topicStatus === "restricted") {
                // Publish action
                setSubmitStatus({ error: "", submitting: true });
                try {
                    await api.addMember(topic.topicId!, { entity: { group: { groupId: "g_public" } } });
                }
                catch (err: any) {
                }
                topic.searchable = false;
                await api.updateTopic({ searchable: true }, topic, true);
                setSubmitStatus({ error: "", submitting: false });
                handleDone("restricted");
            }
            else if (topicStatus === "inviteonly") {
                // This is where you add member with emails
                setSubmitStatus({ error: "", submitting: true });
                const emailsToAdd = convertTextToEmailList();
                if (emailsToAdd === null) {
                    setSubmitStatus({ error: "", submitting: false });
                    return;
                }
                var membersToAdd: apiproto.IMember[] = [];
                for (const email of emailsToAdd) {
                    membersToAdd = [...membersToAdd, { entity: { user: { user: { emailAddress: email } } } }];
                }
                try {
                    await api.deleteMember(topic.topicId!, "g_public"); // Delete from g_public group
                    await api.deleteMembers(topic.topicId!, { filterValue: false }, { filterValue: false }); // Delete all regular members
                    await api.addMembers(false, topic.topicId!, membersToAdd)
                    topic.searchable = false;
                    await api.updateTopic({ searchable: true }, topic, true);
                    setMembersToDelete([]);
                    setSendInvite(true);
                }
                catch (err: any) {
                    //TODO : what to do here?
                    tdrAlert(err.name + ' ' + err.message);
                }
                setSubmitStatus({ error: "", submitting: false });
                return;
            }
            else if (topicStatus === "public") {
                // This is where you do semipublic + add search intent and message user it requires approval.
                setSubmitStatus({ error: "", submitting: true });
                try {
                    await api.addMember(topic.topicId!, { entity: { group: { groupId: "g_public" } } });

                }
                catch
                {
                    // here we catch and ignore, because user may already have been a member of g_public and so adding again
                    // causes and error but ok since we want to continue to proceed to make topic allowsearchable to true
                }
                // Set to allow searchable
                topic.searchable = true;
                await api.updateTopic({ searchable: true }, topic, true);
                setSubmitStatus({ error: "", submitting: false });
                handleDone("public");

            }
        }
        catch (err: any) {
            tdrAlert(err.message);
            handleDone("error");
        }
        setSubmitStatus({ error: "", submitting: false });
    }

    const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        event.preventDefault();
        setBccEmails(event.target.value)
        setEmailError("");
    };

    const convertTextToEmailList = () => {
        var str: string = bccEmails.replace(/,\s*$/, "");
        var newemails: string[] = str.split(",");
        if (newemails && newemails.length > 0) {
            for (const email of newemails) {
                if (!emailValidation(email)) {
                    setEmailError(email.length === 0 ? 'Email is not valid.' : email + ' is not valid.');
                    return null;
                }
            };
            return newemails;
        }
        return null;
    }

    const generateVanityURL = () => {
        return window.location.hostname + (window.location.port ? ':' + window.location.port : "") + '/t/' + ((vanityURL.length > 0) ? vanityURL : topic?.tinyCode);
    }

    const updateVanityName = async () => {
        if (vanityURL.length > 0 && vanityURL.length < 8) {
            setVanityError("Vanity name must be 8 characters or more.");
            return false;
        }
        if (topic) {
            const oldVanityName = topic?.vanityName
            topic.vanityName = vanityURL;
            try {
                await api.updateTopic({ vanityName: true }, topic!, true);
            }
            catch (err: any) {
                setVanityError("Vanity name is taken");
                topic!.vanityName = oldVanityName;
                tdrAlert(err.message);
                return false;
            }
            return true;
        }
        return false;
    }

    useEffect(() => {
        setOpen(visible);
        if (topic) {
            const status = getTopicShareStatus(topic);
            if (topic.vanityName && topic.vanityName.length > 0) {
                setVanityURL(topic.vanityName);
            }
            setTopicOriginalStatus(status);
            setTopicStatus(status);
            if (topic.members) {
                const regularMembers = getRegularTopicMembers(topic.members);
                if (regularMembers) {
                    setMembers(regularMembers);
                    const emailtags: Tag[] = [];
                    regularMembers.forEach((member: apiproto.IMember) => {
                        const user = member.entity?.user?.user;
                        if (user && user.emailAddress) {
                            emailtags.push({ id: user.userId!, text: user.emailAddress });
                        }
                    })
                    setEmails(emailtags);
                    generateEmailBCC();
                }
            }
        }
    }, [visible, topic, owner]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (linkCopied) {
            setVanityError("");
            if (vanityURL !== topic?.vanityName) {
                // We want to update the vanity name
                const result = updateVanityName();
                if (!result) {
                    setLinkCopied(false);
                    return;
                }
            }
            timer = setTimeout(() => {
                setLinkCopied(false);
            }, 1000);

        }
        if (emailsCopied) {
            timer = setTimeout(() => {
                setEmailsCopied(false);
            }, 1000);
        }
        return () => {
            if (!timer) {
                clearTimeout(timer);
            }
        };
    }, [linkCopied, emailsCopied]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onClose={onCancel}
        >
            <DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickCloseButton}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <div>
                    <ValidatorForm onSubmit={submitState}>
                        <div className="form-group form-input form-padding">
                            {!sendInvite &&
                                <Row>
                                    <Col>
                                        <SelectValidator
                                            id="status"
                                            name="status"
                                            value={topicStatus}
                                            className="form-control"
                                            label="Status"
                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                                setTopicStatus(event.target.value);
                                            }}>
                                            <MenuItem value="private">Private</MenuItem>
                                            <MenuItem value="inviteonly">Invite Only</MenuItem>
                                            <MenuItem value="restricted">Restricted</MenuItem>
                                            <MenuItem value="public">Public (recommended)</MenuItem>
                                        </SelectValidator>
                                        <div style={{ padding: '10px' }}>                                        {topicStatus === "private" ? "No one can see your page" : (topicStatus === "inviteonly") ? "Only invited people can see your page." : (topicStatus === "restricted" ? "Anyone with the link can see your page." : "Your page is viewable and searchable by anyone.")}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {topicStatus === "inviteonlybutweturnofftagfornowbutmay be useful later on for like autosuggest and search based on name" &&
                                <Row>
                                    <Col>
                                        <div className="form-group form-input form-padding">
                                            <ReactTags
                                                tags={emails}
                                                delimiters={[KeyCodes.comma, KeyCodes.enter]}
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}
                                                inputFieldPosition="inline"
                                                placeholder="Enter emails"
                                                autocomplete
                                                autofocus={false}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {topicStatus === "inviteonly" &&
                                <div className="form-group form-input form-padding">
                                    <Row>
                                        <Col sm="12">
                                            <TextareaAutosize
                                                minRows={4}
                                                maxRows={30}
                                                disabled={sendInvite}
                                                aria-label="maximum height"
                                                placeholder="Enter email addresses separated by commas"
                                                defaultValue={bccEmails}
                                                onChange={handleTextAreaChange}
                                                style={{ width: '100%', paddingInline: 8 }}
                                            />
                                            {emailError.length > 0 && <div className="form-error-msg">{emailError}</div>}
                                        </Col>
                                        <Col>
                                            <Button variant="text"
                                                startIcon={<ContentCopyIcon />}
                                                size="small"
                                                disabled={emailsCopied ? true : false}
                                                color="primary" aria-label="upload picture" component="span" onClick={() => {
                                                    navigator.clipboard.writeText(bccEmails);
                                                    setEmailsCopied(true);
                                                }}>
                                                {emailsCopied ? "Emails Copied" : "Copy Emails"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }


                            {topicStatus !== "private" &&
                                <div className="form-group form-input form-padding">
                                    <Row>
                                        <Col sm="12">
                                            <TextValidator
                                                label={window.location.hostname + (window.location.port ? ':' + window.location.port : "") + '/t/' + (vanityURL.length > 0 ? vanityURL : topic?.tinyCode)}
                                                type="text"
                                                value={vanityURL}
                                                onChange={handleInputChange}
                                                name="vanity"
                                                id="vanity"
                                                className="form-control"
                                                placeholder="Enter vanity name"
                                                disabled={sendInvite}
                                                validators={[]}
                                                errorMessages={[]}
                                            />
                                            {vanityError ? <div className="form-error-msg">{vanityError}</div> : ""}
                                        </Col>
                                        <Col>
                                            <Button variant="text"
                                                startIcon={<ContentCopyIcon />}
                                                size="small"
                                                disabled={linkCopied ? true : false}
                                                color="primary" aria-label="copy link" component="span" onClick={() => {
                                                    navigator.clipboard.writeText(generateVanityURL());
                                                    setLinkCopied(true);
                                                }}>
                                                {linkCopied ? "Link Copied" : "Copy Link"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row>
                                <Col>
                                    {sendInvite && <div>You have added permission for users with emails above to view your topic page and reserve a time with you. You still need to complete the invite by emailing the link to view the page.</div>
                                    }

                                    <div className="form-group form-input form-padding">
                                        <LoadingButton
                                            sx={{ float: 'right', mr: 1 }}
                                            size="large"
                                            variant="contained"
                                            loading={submitStatus.submitting}
                                            type="submit"
                                            disabled={topicOriginalStatus === topicStatus}
                                            hidden={sendInvite}>
                                            Save
                                        </LoadingButton>
                                        <LoadingButton
                                            sx={{ float: 'right', mr: 1 }}
                                            size="large"
                                            variant="contained"
                                            loading={submitStatus.submitting}
                                            type="submit"
                                            disabled={false}
                                            hidden={!sendInvite}>
                                            Mail Client
                                        </LoadingButton>
                                        <LoadingButton
                                            sx={{ float: 'right', mr: 1 }}
                                            size="large"
                                            variant="contained"
                                            loading={submitStatus.submitting}
                                            onClick={() => { sendInvites("yahoo") }}
                                            startIcon={<Avatar sx={{ width: 18, height: 18 }} src={'/images/icons/mail/yahoomailicon.png'} />}
                                            disabled={false}
                                            hidden={!sendInvite}>
                                            Yahoo
                                        </LoadingButton>
                                        <LoadingButton
                                            sx={{ float: 'right', mr: 1 }}
                                            size="large"
                                            variant="contained"
                                            loading={submitStatus.submitting}
                                            onClick={() => { sendInvites("outlook") }}
                                            startIcon={<Avatar sx={{ width: 18, height: 18 }} src={'/images/icons/mail/outlookicon.png'} />}
                                            disabled={false}
                                            hidden={!sendInvite}>
                                            Outlook
                                        </LoadingButton>
                                        <LoadingButton
                                            sx={{ float: 'right', mr: 1 }}
                                            size="large"
                                            variant="contained"
                                            loading={submitStatus.submitting}
                                            onClick={() => { sendInvites("gmail") }}
                                            startIcon={<Avatar sx={{ width: 18, height: 18 }} src={'/images/icons/mail/gmailicon.png'} />}
                                            disabled={false}
                                            hidden={!sendInvite}>
                                            Gmail
                                        </LoadingButton>

                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </ValidatorForm>
                </div>
            </DialogContent >
        </Dialog >)
}

export default TopicShare;