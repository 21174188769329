import { useEffect, useState } from "react";
import { checkIfTopicProfile, computeDuration, computeDurationToHrMin, computeEpochtoTimeNoAMPMStr, computeTimeStrtoEpoch, convertDateToEpoch, ExtendedSchedule, generateProfileTopicURL, generateTopicURL, getTopicShareStatus, roundTimeQuarterHour, SESSIONFREQUENCY, TimeAction } from "../../utils/eduutils";
import { ValidatorForm } from "react-material-ui-form-validator";
import { Col, Row } from "react-bootstrap";
import { DatePicker, TimePicker } from "react-rainbow-components";
import { Divider, Menu, MenuItem, MenuProps } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { api as apiproto } from "../../apiproto";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import AddIcon from '@mui/icons-material/Add';
import GroupIcon from '@mui/icons-material/Group';
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../utils/useAPI";
import VideoCall from "@mui/icons-material/VideoCall";
import DialogMessageBox from "../../components/DialogMessageBox";

type ChildProps = {
    topic: apiproto.ITopic | null | undefined,
    time: apiproto.ITime | null,
    roomid?: string | null,
    scheduleCurrent: ExtendedSchedule | null, // Only needed if we are doing add time since we need to know which schedule to add to
    callback?: (value: any) => void | null,
    onModifyTime?: (value: apiproto.ITime, action: TimeAction) => void | null
}

const ScheduleSingleTimeInput: React.FC<ChildProps> = ({ topic = null, time = null, roomid = null, scheduleCurrent = null, callback = () => { }, onModifyTime = () => { } }) => {

    const api = useAPI();
    const navigate = useNavigate();
    const [startTimeErr, setStartTimeErr] = useState("");
    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(1.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    }));

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (schedule && schedule.frequency && schedule.frequency === SESSIONFREQUENCY.NOREPEAT) {
            // In no repeat, we do not allow delete future
            handleDeleteSingle();
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleMoreActionMenuClose = () => {
        setAnchorEl(null);
    };
    const initialSchedule: ExtendedSchedule =
    {
        // scheduleId: "",
        scheduleId: "1",
        scheduleSetId: null,
        startTime: 0,
        duration: 0,
        repeatCount: -1, // For now we don't do repeat count until we add custom schedule
        repeatEndDate: 0,
        dateRange: new Date(),
        fromDate: new Date(),
        toDate: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
        fromTime: '09:00',
        toTime: '10:00',
        frequency: SESSIONFREQUENCY.WEEKLY.toString(),

        // Customize error:
        toDateErr: "",
        toTimeErr: "",
        durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
        generalErr: "",
    }

    const [schedule, setSchedule] = useState<ExtendedSchedule>(initialSchedule);
    const [oldSchedule, setOldSchedule] = useState<ExtendedSchedule>({ ...initialSchedule });

    const dateStyles = {
        minWidth: 250,
        maxWidth: 280,
        padding: 10,
    };
    const timeStyles = {
        width: 250,
        padding: 10,
    };

    const handleUpdate = () => {
        const newtime = { ...time };
        newtime.startTime = schedule?.startTime;
        newtime.endTime = +schedule?.startTime! + +schedule?.duration!;
        if (newtime.timeId && newtime.timeId !== null) {
            // Update time
            onModifyTime(newtime, TimeAction.update);
        }
        else {
            // Update time
            onModifyTime(newtime, TimeAction.add);
        }
    }

    const handleDeleteSingle = () => {
        // We call delete time
        handleMoreActionMenuClose();
        const newtime = { ...time };
        onModifyTime(newtime, TimeAction.deletesingle);

    }

    const handleDeleteFuture = () => {
        // We call truncate time
        handleMoreActionMenuClose();
        const newtime = { ...time };
        onModifyTime(newtime, TimeAction.deletefuture);
    }

    const handleStartVideo = () => {
        if (topic !== null && topic?.singleSession !== null && topic.topicId) {
            navigate("/conferences/" + window.encodeURIComponent(time?.timeId!));
        }
        else if (roomid !== null) {
            navigate("/conferences/" + window.encodeURIComponent(roomid));
        }
    }
    const handleInvite = async () => {
        if (topic !== null && topic.topicId) {
            // If private, then cannot invite since no one can see the link
            const status = getTopicShareStatus(topic);
            if (status === "private") {
                setDialogMessage({ title: "Topic is private", message: "You cannot invite guests to a session that is set to private. Make sure you publish the page before inviting guests.", meta: {}, confirm: false })
                handleMoreActionMenuClose();
                return;
            }
            if (topic?.singleSession !== null) {
                if (checkIfTopicProfile(topic.topicId)) {
                    const userId = topic.topicId.replace("c_", "");
                    const userResult = await api.getUser({ includeDescription: false }, { userId: userId });
                    if (userResult && userResult.user) {
                        navigate(generateProfileTopicURL(userResult.user.firstName!, userResult.user.lastName!, userResult.user.userId!) + '?invite=' + window.encodeURIComponent(time?.timeId!));
                        return;
                    }
                }
                else {
                    navigate(generateTopicURL(topic) + '?invite=' + window.encodeURIComponent(time?.timeId!));
                }
            }
            else if (roomid !== null) {
                const url = generateTopicURL(topic) + '?invite=' + window.encodeURIComponent(roomid)
                navigate(url);
            }
        }
        else {
            // TODO - not suppose to happen since there should be a topic id
        }
        callback('');

    }
    const isInvalidSchedule = (scheduleSingle: ExtendedSchedule) => {
        var invalid: boolean = false;
        var today = new Date();
        today.setDate(today.getDate() - 1);
        const current = Date.now();
        if (scheduleSingle === null) return false;
        scheduleSingle.toDateErr = "";
        scheduleSingle.toTimeErr = "";
        setStartTimeErr("");
        if (scheduleSingle.fromDate.getTime() < today.getTime()) {
            scheduleSingle.toDateErr = "Cannot set date earlier than today " + (today.toString());
            setSchedule(scheduleSingle);
            invalid = true;
        }
        else if (scheduleSingle.startTime! < current) {
            setStartTimeErr("Cannot set a time in the past.");
            setSchedule(scheduleSingle);
            invalid = true;
        }

        /*
        if (scheduleSingle.fromDate.getTime() > scheduleSingle.toDate.getTime()) {
            scheduleSingle.toDateErr = "End date must be later than start date";
            setSchedule(scheduleSingle);
            invalid = true;
        }*/
        if (scheduleSingle.duration! > 10 * 60 * 60 * 1000) {
            scheduleSingle.toTimeErr = "The duration must be less than 10 hours.";
            setSchedule(scheduleSingle);
            invalid = true;
        }
        return invalid;

    }


    useEffect(() => {
        var fromtimestr = "09:00";
        var totimestr = "10:00";

        if (time === null) {
            const starttime = Date.now();
            const duration = 1 * 60 * 60 * 1000;
            const endtime = Date.now() + duration;

            const newschedule: ExtendedSchedule = {
                scheduleId: scheduleCurrent?.scheduleId,
                scheduleSetId: scheduleCurrent?.scheduleSetId,
                startTime: starttime,
                duration: duration,
                timeZone: scheduleCurrent?.timeZone,
                repeatCount: -1, // For now we don't do repeat count until we add custom schedule
                repeatEndDate: 0,
                // This is the original one for UI
                dateRange: new Date(),
                fromDate: new Date(+starttime),
                toDate: new Date(+endtime),
                fromTime: fromtimestr,
                toTime: totimestr,
                frequency: "", // Not applicable

                // Customize error:
                toDateErr: "",
                toTimeErr: "",
                durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
                generalErr: "",

            }
            setSchedule(newschedule);
            setOldSchedule({ ...newschedule });
        }
        else {
            fromtimestr = computeEpochtoTimeNoAMPMStr(time.startTime!.toString(), time?.schedule?.timeZone);
            totimestr = computeEpochtoTimeNoAMPMStr(time.endTime!.toString(), time?.schedule?.timeZone);
            const newschedule: ExtendedSchedule = {
                scheduleId: time?.scheduleId,
                scheduleSetId: time?.schedule?.scheduleSetId,
                startTime: time?.startTime,
                duration: +time?.endTime! - +time?.startTime!,
                timeZone: time.schedule?.timeZone,
                repeatCount: -1, // For now we don't do repeat count until we add custom schedule
                repeatEndDate: 0,
                // This is the original one for UI
                dateRange: new Date(),
                fromDate: new Date(+time?.startTime!),
                toDate: new Date(+time?.endTime!),
                fromTime: fromtimestr,
                toTime: totimestr,
                frequency: schedule.frequency, // Not applicable

                // Customize error:
                toDateErr: "",
                toTimeErr: "",
                durationStr: 'End time (' + computeDurationToHrMin(60 * 60 * 1000) + ')',
                generalErr: "",
            }
            setSchedule(newschedule);
            setOldSchedule({ ...newschedule });
        }
        if (scheduleCurrent && !time) {
            // We are not active editing an exisint time so we create a new time for the schedule
            const today = roundTimeQuarterHour((new Date()).getTime());
            const todayhrlater = new Date(today.getTime() + (1000 * 60 * 60))
            fromtimestr = computeEpochtoTimeNoAMPMStr(today.getTime().toString(), scheduleCurrent.timeZone);
            totimestr = computeEpochtoTimeNoAMPMStr(todayhrlater.getTime().toString(), scheduleCurrent.timeZone);

            // For adding time since we need to know which schedule to add to
            const newschedule = {
                ...scheduleCurrent,
                dateRange: today,
                fromDate: today,
                toDate: todayhrlater,
                fromTime: fromtimestr,
                toTime: totimestr,
                startTime: convertDateToEpoch(today, fromtimestr, scheduleCurrent.timeZone),
                duration: 60 * 60 * 1000

            };
            setSchedule({ ...newschedule });
            setOldSchedule({ ...newschedule });
        }
    }, [time, scheduleCurrent])

    /******************************************************* 
    * 
    * Dialog box section for handling actions confirmation
    * 
    * *****************************************************/
    const [dialogMessage, setDialogMessage] = useState({ title: "", message: "", meta: {}, confirm: false });

    const clearDialog = () => {
        setDialogMessage({ title: '', message: '', meta: '', confirm: true });

    }


    const handleCancel = () => {
        clearDialog();
    }

    return (
        <div>
            <DialogMessageBox title={dialogMessage.title} message={dialogMessage.message} meta={dialogMessage.meta} confirm={dialogMessage.confirm ? "Confirm" : ""} cancel={dialogMessage.confirm ? "Dismiss" : "Dismiss"} onCancel={handleCancel} visible={dialogMessage.title.length > 0} />

            <ValidatorForm onSubmit={handleUpdate}>

                {
                    <div>
                        <Row>
                            <Col>
                                <div style={dateStyles}>
                                    <DatePicker
                                        required
                                        id="fromDatePicker"
                                        error={schedule!.toDateErr}
                                        value={schedule!.fromDate}
                                        onChange={(value) => {
                                            const tmpschedule = { ...schedule };
                                            tmpschedule.duration = computeDuration(tmpschedule.fromTime, tmpschedule.toTime)
                                            tmpschedule.fromDate = value;
                                            tmpschedule.startTime = convertDateToEpoch(tmpschedule.fromDate, tmpschedule.fromTime, tmpschedule.timeZone);
                                            setSchedule(tmpschedule);
                                            isInvalidSchedule(tmpschedule);
                                        }}
                                        label="Start Date"
                                        formatStyle="large" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={timeStyles}>
                                    <TimePicker
                                        error={startTimeErr}
                                        value={schedule!.fromTime}
                                        label="Start Time"
                                        style={{ width: 180 }}
                                        onChange={(value) => {
                                            const tmpschedule = { ...schedule };
                                            const newfromtime = computeTimeStrtoEpoch(value);
                                            const totime = computeTimeStrtoEpoch(tmpschedule.toTime);
                                            var oldduration = (totime - computeTimeStrtoEpoch(tmpschedule!.fromTime));
                                            // Calculate new starttime
                                            tmpschedule.fromTime = value;
                                            tmpschedule.startTime = convertDateToEpoch(tmpschedule.fromDate, tmpschedule.fromTime, tmpschedule.timeZone);
                                            if (oldduration <= 0) oldduration = 60 * 60 * 1000;
                                            if (newfromtime >= totime) {
                                                const endDate: number = +tmpschedule.startTime!.toString() + +oldduration;
                                                tmpschedule!.toTime = computeEpochtoTimeNoAMPMStr(endDate.toString(), tmpschedule.timeZone);
                                            }
                                            tmpschedule!.duration = computeDuration(tmpschedule!.fromTime, tmpschedule!.toTime
                                            );
                                            tmpschedule!.durationStr = 'End Time (' + computeDurationToHrMin(tmpschedule!.duration) + ')';
                                            setSchedule(tmpschedule);
                                            isInvalidSchedule(tmpschedule);

                                        }}
                                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto" />

                                </div>
                            </Col>
                            <Col>
                                <div style={timeStyles}>
                                    <TimePicker
                                        value={schedule!.toTime}
                                        label={schedule!.durationStr}
                                        error={schedule!.toTimeErr}
                                        style={{ width: 180 }}
                                        onChange={(value) => {
                                            const tmpschedule = { ...schedule };
                                            tmpschedule!.toTimeErr = "";
                                            tmpschedule!.toTime = value;
                                            tmpschedule!.duration = computeDuration(tmpschedule!.fromTime, tmpschedule!.toTime
                                            );
                                            tmpschedule!.durationStr = 'End Time (' + computeDurationToHrMin(tmpschedule!.duration) + ')';
                                            if (tmpschedule.duration > 10 * 60 * 60 * 1000) {
                                                tmpschedule.toTimeErr = "The duration must be less than 10 hours.";
                                            }
                                            setSchedule(tmpschedule);
                                            isInvalidSchedule(tmpschedule);

                                        }}
                                        className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto" />

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ marginLeft: '12px', color: 'black', fontSize: '12px' }}>Time Zone: {schedule?.timeZone}</div>
                            </Col>
                        </Row>
                        <hr />

                        <Row>
                            <Col>
                                {time !== null ?
                                    <div>
                                        <LoadingButton disabled={schedule.toDateErr.length > 0 || schedule.toTimeErr.length > 0 || (oldSchedule.fromTime === schedule.fromTime && oldSchedule.toTime === schedule.toTime && schedule.fromDate === oldSchedule.fromDate)} style={{ margin: '10px', float: 'right' }} size="medium" variant="contained" loading={false} loadingPosition="start" type="submit" startIcon={<EditIcon />}>
                                            Modify
                                        </LoadingButton>
                                        <LoadingButton
                                            disabled={!(schedule.toDateErr.length > 0 || schedule.toTimeErr.length > 0 || (oldSchedule.fromTime === schedule.fromTime && oldSchedule.toTime === schedule.toTime && schedule.fromDate === oldSchedule.fromDate))}
                                            style={{ margin: '10px', float: 'right' }}
                                            id="customized-delete-button"
                                            aria-controls={open ? 'delete-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleClick}
                                            endIcon={schedule.frequency !== SESSIONFREQUENCY.NOREPEAT
                                                ? <KeyboardArrowDownIcon /> : ""}
                                        >
                                            Actions
                                        </LoadingButton>
                                        <StyledMenu
                                            id="delete-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'customized-delete-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleMoreActionMenuClose}
                                        >
                                            <MenuItem onClick={handleStartVideo} disableRipple>
                                                <VideoCall />
                                                Start Video
                                            </MenuItem>                                                {topic?.cost === 0 ?
                                                <MenuItem onClick={handleInvite} disableRipple>
                                                    <GroupIcon />
                                                    {topic?.singleSession !== null ? 'Invite people to this time slot' : 'Invite people to this room'}
                                                </MenuItem> : ""}
                                            <MenuItem onClick={handleDeleteSingle} disableRipple>
                                                <DeleteIcon />
                                                Delete time slot
                                            </MenuItem>
                                            {schedule.frequency !== SESSIONFREQUENCY.NOREPEAT &&
                                                <MenuItem onClick={handleDeleteFuture} disableRipple>
                                                    <DeleteSweepIcon />
                                                    Delete time slot &amp; its future slots.
                                                </MenuItem>
                                            }
                                            <Divider sx={{ my: 0.5 }} />
                                            <MenuItem onClick={handleMoreActionMenuClose} disableRipple>
                                                Cancel
                                            </MenuItem>
                                        </StyledMenu></div> :
                                    <LoadingButton disabled={schedule.toDateErr.length > 0 || schedule.toTimeErr.length > 0} style={{ margin: '10px', float: 'right' }} size="medium" variant="contained" loading={false} loadingPosition="start" type="submit" startIcon={<AddIcon />}>
                                        Add Time
                                    </LoadingButton>}

                            </Col>
                        </Row>

                    </div>
                }
            </ValidatorForm>
        </div>)
}

export default ScheduleSingleTimeInput;