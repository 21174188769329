import { useNavigate } from "react-router-dom";
import React from "react";
import Button from "@mui/material/Button";

const BecomeATeacherAbout3 = () => {

    const navigate = useNavigate();

    const loginClicked = () => {
        let path = '/login?type=signup&cat=expert';
        navigate(path);
    }


    return (
        <div className="container">
            <div className="row d-flex align-items-center">
                {/* ABOUT TEXT */}
                <div className="col-md-7 col-lg-6">
                    <div className="txt-block pc-25">
                        {/* Title */}
                        <h3 className="h3-sm">
                            Free to Start
                        </h3>
                        {/* Text */}
                        <p>
                            Create your own knowledge page for free. Market your knowledge and let others book time with you to tap your knowledge and expertise. Then get paid.
                        </p>
                        {/* List */}
                        <ul className="txt-list mb-15">
                            <li>
                                Free to Sign up.
                            </li>
                            <li>
                                No cost to start. No Credit Cards
                            </li>
                            <li>
                                30% commission when we refer clients to you
                            </li>
                            <li>
                                0% commission when you bring your own clients
                            </li>
                        </ul>
                        <br />
                        {/* Button */}
                        <Button size="large" variant="contained" onClick={loginClicked} sx={{
                            ':hover': {
                                bgcolor: '#ff1f59', // theme.palette.primary.main
                                color: 'white',
                            }, width: 300, height: 60, fontSize: '23px', backgroundColor: 'primary.main', textTransform: 'none'
                        }}>
                            Sign Up as an Expert
                        </Button>
                    </div>
                </div>
                {/* END ABOUT TEXT */}
                {/* ABOUT IMAGE */}
                <div className="col-md-5 col-lg-6">
                    <div className="img-block">
                        <img
                            className="img-fluid"
                            src="images/image-02.png"
                            alt="about-image"
                        />
                    </div>
                </div>
            </div>
            {/* End row */}
        </div>
    );
};

export default BecomeATeacherAbout3;
