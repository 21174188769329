
import { api as apiproto } from "../apiproto";
import Resizer from "react-image-file-resizer";
import { DateTime } from "luxon";

export interface AppIntegrations {
    googleCalendar: boolean,
    googleMeet: boolean,
    zoom: boolean,
    stripe: boolean
}

export interface ExtendedSchedule extends apiproto.ISchedule {
    dateRange: Date,
    fromDate: Date,
    toDate: Date,
    fromTime: string,
    toTime: string,
    frequency: string,
    toDateErr: string,
    toTimeErr: string,
    durationStr: string,
    weekDays?: boolean[],
    weekDaysStr?: string,
    generalErr: string,
}

export interface ExtendedRoom extends apiproto.IRoom {
    roomKey: string,
    scheduleSet: ExtendedScheduleSet | null,
    existingRoom: boolean,
    scheduleSelection: string,
    scheduleSetOpen: HTMLElement | null
}

export type IUserRating = {
    averageScore: number,
    userRatingsCount: number[]
}
export type ActiveSchedule = {
    room: ExtendedRoom | null,
    schedule: ExtendedSchedule | null
}
export interface ExtendedScheduleSet extends apiproto.IScheduleSet {
    schedules: ExtendedSchedule[] | null
}

export interface ExtendedTopic extends apiproto.ITopic {
    category: number,
    topicformat: number,
    topicrooms: ExtendedRoom[],
}

export interface ExtendedTime extends apiproto.ITime {
    fromTime: string,
    toTime: string,
    roomid: string,
    startDateStr: string,
    fullyBooked: boolean,
    enrolled: boolean,
}

export interface IScheduleCard {
    roomId: string,
    timeId: string,
    starttime: number | Long,
    endtime: number | Long,
    schedules: apiproto.ISchedule[],
    sessions: number,
    duration: number | Long
    membersize: number,
    enrolled: boolean
}

export enum SessionType {
    single = 10,
    ongoing = 20,
    multimemberonday1 = 30,
    multi = 40
}


export const SESSIONFREQUENCY = {
    NOREPEAT: '0',
    DAILY: '10',
    WEEKLY: '20',
    MONTHLY: '30',
    WEEKLYCUSTOM: '40', // Allows you to select which days
}

export enum TimeAction {
    noop = 0,
    update = 10,
    add = 20,
    deletesingle = 30,
    deletefuture = 40,
}

export const computeDurationToHrMin = (duration1: number) => {
    var date = new Date(0);
    date.setSeconds(duration1 / 1000); // specify value for SECONDS here

    var timeString: string = date.toISOString().substring(11, 16);
    if (timeString.startsWith("0")) {
        timeString = timeString.replace("0", "");
    }
    timeString = (timeString.replace(':', ' hr ') + ' min').replace("00 hr", "").replace("00 min", "");
    return timeString;
}

export const computeEpochtoDateStr = (starttime: string) => {
    const epochtime: number = +starttime;
    const date = new Date(epochtime);
    return date.toLocaleDateString('en-US', { timeZone: getTimeZone() });
}

export const computeEpochtoPrettyDateStr = (starttime: string, momentformat: string) => {
    const epochtime: number = +starttime;
    const date = new Date(epochtime);
    //    return date.toLocaleDateString('en-US', { weekday: "short", month: "short", day: "numeric", timeZone: getTimeZone() });
    var moment = require('moment-timezone');
    return (moment(date).tz(getTimeZone())).format(momentformat);
}
export const getShortTimeZone = () => {
    var moment = require('moment-timezone');
    return moment().tz(getTimeZone()).zoneAbbr();
}

export const getTimeZone = () => {
    let tz = localStorage.getItem("tz");
    if (!tz || tz === null || tz.length === 0) {
        tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return tz;
}

export const getProfileTimeZoneStr = (always: boolean) => {
    // always=true means no matter what, just return time zone string
    // always= false if browser time zone is same as user profile, return empy string
    let tz = localStorage.getItem("tz");
    if (!tz || tz === null || tz.length === 0) {
        tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    if (tz === Intl.DateTimeFormat().resolvedOptions().timeZone && !always) {
        // If browser time zone is same as user profile time zone, we don't put it out.
        return "";
    }
    return getTimeZoneAbbrev(tz);
}

export const setTimeZone = (timezone: string) => {
    localStorage.setItem("tz", timezone);
}

export const getTimeZoneAbbrev = (timezone: string | null | undefined) => {
    if (timezone && timezone !== null) {
        return DateTime.fromObject({ year: 2012, month: 1 }, { zone: timezone })
            .toFormat('ZZZZZ')
    }
    return "";
}

export const computeEpochtoTimeStr = (starttime: string, timezone?: string | null | undefined) => {
    const epochtime: number = +starttime;
    const date = new Date(epochtime);
    let tz = getTimeZone();
    if (typeof timezone !== 'undefined' && timezone !== null) {
        tz = timezone;
    }
    // TODO- its unclear if you use other locale, if in that culture they show time using military style. Since using en-US seem to be able to show am pm but other locale doesn't seem to work that way.
    return date.toLocaleTimeString('en-US', { timeZone: tz, hour: '2-digit', minute: '2-digit', hour12: true });
}

export const computeEpochtoTimeNoAMPMStr = (starttime: string, timezone?: string | null | undefined) => {
    const epochtime: number = +starttime;
    const date = new Date(epochtime);
    let tz = getTimeZone();
    if (typeof timezone !== 'undefined' && timezone !== null) {
        tz = timezone;
    }
    // en-GB uses 24 hour format and so no am pm complications
    return date.toLocaleTimeString('en-GB', { timeZone: tz, hour: '2-digit', minute: '2-digit' });
}


export const computeTimeStrtoEpoch = (fromTime: string, timezone?: string | undefined | null) => {
    // TODO - this might be ok because nothing really is invovled with localed.
    /*    let tz = getTimeZone();
        if (typeof timezone !== 'undefined' && timezone !== null) {
            tz = timezone;
        }
        Don't think we need this
        */
    const timefrom = fromTime.split(':');
    // Convert to minutes
    const starttime1: number = 60 * Number(timefrom[0]) + Number(timefrom[1]);

    var epoch = starttime1 * 1000 * 60;
    return epoch;
}

export const secondsToDhms = (seconds: number, shortForm: boolean) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : " ";
    var hDisplay = h > 0 ? h + " hr " : " ";
    var mDisplay = m > 0 ? m + " min " : " ";
    var sDisplay = s > 0 ? s + " s " : " ";

    if (!shortForm) {
        dDisplay = d > 0 ? d + (d === 1 ? " day " : " days ") : "";
        hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
        mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
        sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    }
    return dDisplay + hDisplay + mDisplay + sDisplay;

}

export const capitalizeFirstLetterOfEveryWord = (sentence: string) => {
    const words = sentence.split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    return words.join(" ");
}

export const convertDateToEpoch = (date: Date, timestr: string, timezone?: string | null | undefined) => {
    let tz = getTimeZone();
    if (typeof timezone !== 'undefined' && timezone !== null) {
        tz = timezone;
    }
    const datestr1 = date.getFullYear() + "-" + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + 'T' + timestr + ":00";
    var overrideZone = DateTime.fromISO(datestr1, { zone: tz });
    return overrideZone.toMillis();
}

export const getWeekOfMonth = (thedate: Date) => {
    var month = thedate.getMonth()
        , year = thedate.getFullYear()
        , firstWeekday = new Date(year, month, 1).getDay()
        //, lastDateOfMonth = new Date(year, month + 1, 0).getDate()
        , offsetDate = thedate.getDate() + firstWeekday - 1
        , index = 0
        //     , weeksInMonth = index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7)
        , week = index + Math.floor(offsetDate / 7)
        ;
    if (thedate.getDay() < firstWeekday) week--;
    return week;
};

export const getWeekOfMonthStr = (thedate: Date) => {
    var week = getWeekOfMonth(thedate);
    const numstr = ['first', 'second', 'third', 'fourth', 'last', 'last'];
    return numstr[week];
};

export const computeDuration = (fromTime: string, toTime: string) => {
    const timefrom = fromTime.split(':');
    const timeto = toTime.split(':');
    // Convert to minutes
    const endtime1: number = 60 * Number(timeto[0]) + Number(timeto[1]);
    const starttime1: number = 60 * Number(timefrom[0]) + Number(timefrom[1]);

    var duration = (endtime1 - starttime1) * 1000 * 60;
    if (duration < 0) {
        // Something wrong handle later
        duration = 24 * 60 * 60 * 1000 + duration;
    }
    return duration;
}

export const daysOfWeek: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const daysOfWeekShort: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const KeyCodes = {
    comma: 188,
    enter: 13
};

export const getFormat = (courseInfo: apiproto.ITopic) => {
    if (courseInfo.singleSession) {
        return "One Time";
    }
    else if (courseInfo.ongoingSession) {
        return "Ongoing";
    }
    else if (courseInfo.multiSession?.mustBeMemberBeforeFirstSession) {
        return "Multi Sessions (Must join on first class)";
    }
    else {
        return "Multi Sessions";
    }

}

export const getOwners = (members: apiproto.IMember[]) => {
    //const instructors = courseInfo?.members?.filter(member => member.instructor === true);
    var owners = [];
    owners = members.filter(member => member.owner === true);
    if (owners.length > 0) {
        return owners;
    }
    return [];
}

export const isUserOwner = (owners: apiproto.IMember[], userid: string | null) => {
    if (owners.length === 0 || userid === null) {
        return false;
    }
    const owner = owners.filter((m) => m.owner && m.entity?.user?.userId === userid);
    if (owner && owner.length > 0) {
        return true;
    }
    return false;
}

export const isUserMemberOfGroupId = (userInfo: apiproto.IUser, groupid: string) => {
    if (userInfo !== null && userInfo.membersOf && userInfo.membersOf?.length > 0) {
        const groupmembership = userInfo.membersOf?.filter((m) => m.accepted === true && m.obj?.group?.groupId === groupid)
        if (groupmembership && groupmembership.length > 0) {
            return true;
        }
    }
    return false;
}

export const isUserOwnerOfTopic = (userInfo: apiproto.IUser, topic: apiproto.ITopic) => {
    if (userInfo !== null && userInfo.membersOf && userInfo.membersOf?.length > 0) {
        const owner = userInfo.membersOf?.filter((m) => m.accepted === true && m.owner === true && m.obj?.topic?.topicId === topic?.topicId)
        if (owner && owner.length > 0) {
            return true;
        }
    }
    return false;
}

export const PROFILE_PHOTO_ASSET_NAME = "profilepicture";
export const SESSION_PHOTO_ASSET_NAME = "sessionpic";
export const PROFILE_NO_PHOTO = "/images/no-profile-picture.png";

export const getUserPhotoURL = (userInfo: apiproto.IUser) => {
    if (userInfo && userInfo.assets && userInfo.assets.length > 0) {
        const imageAsset = userInfo.assets.filter((m) => m.name === PROFILE_PHOTO_ASSET_NAME)
        if (imageAsset && imageAsset.length > 0 && imageAsset[0].url) {
            return imageAsset[0].url;
        }
    }
    return PROFILE_NO_PHOTO;
}

export const getTopicPhotoURL = (topic: apiproto.ITopic | null) => {
    if (topic === null) {
        return "/images/image-04.png";
    }
    if (topic.assets && topic.assets.length > 0) {
        const imageAsset = topic.assets.filter((m) => m.name === SESSION_PHOTO_ASSET_NAME)
        if (imageAsset && imageAsset.length > 0 && imageAsset[0].url) {
            return imageAsset[0].url;
        }
        else {
            // Return default image
            if (topic && checkIfTopicProfile(topic.topicId)) {
                return "/images/no-profile-picture.png"
            }
            return "/images/image-04.png";
        }
    }
    if (topic && checkIfTopicProfile(topic.topicId)) {
        return "/images/no-profile-picture.png"
    }
    return "/images/image-04.png";
}

export const encodeJSONObject = (obj: any) => {
    var tmpDescription = { ...obj };
    Object.keys(tmpDescription).forEach((descriptionKey: string, index) => {
        const value = Object.values(tmpDescription)[index] as string;
        //        tmpDescription = { ...tmpDescription, [descriptionKey]: encodeURIComponent(value) }
        //        tmpDescription = { ...tmpDescription, [descriptionKey]: value.replace(/\\/g, '\\\\').replace(/\"/g, '\\"') }
        tmpDescription = { ...tmpDescription, [descriptionKey]: value.replace(/"/g, "'") }
    })
    return tmpDescription;
}

export const decodeJSONObject = (obj: any) => {
    var tmpDescription = { ...obj };
    Object.keys(tmpDescription).forEach((descriptionKey: string, index) => {
        const value = Object.values(tmpDescription)[index] as string;
        //        tmpDescription = { ...tmpDescription, [descriptionKey]: decodeURIComponent(value) }
        tmpDescription = { ...tmpDescription, [descriptionKey]: value.replace("\"", '\\"') }

    })
    return tmpDescription;
}


export const getPriceStr = (cost: number | null | undefined, costType: apiproto.CostType | null | undefined, currency: string | null | undefined) => {
    if (cost === null || costType === null || currency === null) {
        return "Free";
    }
    if (currency && currency.length === 0) {
        return "Free";
    }
    // This probably needs some more sophisticated way of displaying at some point based on exchange rates, locale, etc
    if (costType === apiproto.CostType.free || cost === 0) {
        return "Free";
    }
    if (cost) {
        var price = cost.toLocaleString("en-US", { style: "currency", currency: currency ? currency : "USD" });
        if (costType === apiproto.CostType.cost_per_time_charged_as_you_go) {
            price = price + ' / session';
        }
        else if (costType === apiproto.CostType.cost_per_month) {
            price = price + ' / month';

        }
        return price;
    }
    return "Free";

}


const PUNCTUATION_LIST = [".", ",", "!", "?", "'", "{", "}", "(", ")", "[", "]", "/"];

export const trimText = (text = "", min = 800, ideal = 900, max = 1000) => {
    //This main function uses two pointers to move out from the ideal, to find the first instance of a punctuation mark followed by a space. If one cannot be found, it will go with the first space closest to the ideal.

    if (max < min || ideal > max || ideal < min) {
        throw new Error("The minimum length must be less than the maximum, and the ideal must be between the minimum and maximum.")
    }

    if (text.length < ideal) {
        return [text, ''];
    }

    let pointerOne = ideal;
    let pointerTwo = ideal;
    let firstSpace: number | undefined, resultIdx: number | undefined;

    const setSpace = (idx: number) => {
        if (spaceMatch(text[idx])) { firstSpace = firstSpace || idx }
    }

    while (pointerOne < max || pointerTwo > min) {
        if (checkMatch(pointerOne, text, max, min)) {
            resultIdx = pointerOne + 1
            break;
        } else if (checkMatch(pointerTwo, text, max, min)) {
            resultIdx = pointerTwo + 1;
            break;
        } else {
            setSpace(pointerOne);
            setSpace(pointerTwo);
        }

        pointerOne++;
        pointerTwo--;
    }

    if (resultIdx === undefined) {
        if (firstSpace && firstSpace >= min && firstSpace <= max) {
            resultIdx = firstSpace;
        } else if (ideal - min < max - ideal) {
            resultIdx = min;
        } else {
            resultIdx = max;
        }
    }
    return text.slice(0, resultIdx);
}

const spaceMatch = (character: string) => {
    if (character === " ") { return true }
}

const punctuationMatch = (idx: number, text: string) => {
    let punctuationIdx = PUNCTUATION_LIST.indexOf(text[idx]);
    if (punctuationIdx >= 0 && spaceMatch(text[idx + 1])) {
        return true;
    }
}

const checkMatch = (idx: number, text: string, max: number, min: number) => {
    if (idx < max && idx > min && punctuationMatch(idx, text)) {
        return true;
    }
}

export const emailValidation = (email: string) => {
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!email || regex.test(email) === false) {
        return false;
    }
    return true;
}

export const getTopicShareStatus = (topic: apiproto.ITopic) => {
    if (topic && topic.members) {
        const result = topic.members.filter((m) => m.entity?.group?.groupId === 'g_public')
        if (result && result.length > 0) {
            // Means at least it is published and not private 
            if (topic.searchable) {
                return "public";
            }
            else {
                return "restricted";
            }
        }
        else {
            const regularmembers = getRegularTopicMembers(topic.members);
            if (regularmembers.length > 0) {
                return "inviteonly";
            }

        }
        // TODO - when isearchable comes out, need to add
    }
    return "private";
}

export const calculateUserRatings = (topics: apiproto.ITopic[]) => {
    // We now have to add up the rating
    var initialRatingsCount: number[] = [0, 0, 0, 0, 0, 0];
    var totalCount = 0;
    var totalScore = 0;
    var averageScore = 0;
    topics.forEach((topic: apiproto.ITopic) => {
        if (topic && topic.ratingsCount) {
            topic.ratingsCount.forEach((rating: number, index: number) => {
                totalCount += rating;
                totalScore += rating * index;
                initialRatingsCount[index] += rating;
            })
        }
        if (totalCount !== 0) averageScore = (totalScore / totalCount);
    });
    return ({ averageScore: averageScore, userRatingsCount: initialRatingsCount })
}

export const frequencyString = (roomSchedule: apiproto.ISchedule) => {
    if (roomSchedule.daily) return "Daily";
    if (roomSchedule.weekly) {
        if (roomSchedule.weekly.weekDays && roomSchedule.weekly.weekDays.length > 1) {
            let weekdays: boolean[] = [false, false, false, false, false, false, false];
            // If it is weekly, we check if there are multiple days selected
            for (let i = 0; i < roomSchedule.weekly.weekDays.length; i++) {
                weekdays[roomSchedule.weekly.weekDays[i]] = true;
            }
            return "Weekly " + customDaysStr(weekdays);
        }
        else {
            return "Weekly on " + computeEpochtoPrettyDateStr(roomSchedule.startTime!.toString(), 'dddd');
        }
    }
    const fromDate: Date = new Date(+roomSchedule.startTime!.toString());
    if (roomSchedule.monthly) return "Monthly on the " + getWeekOfMonthStr(fromDate) + " " + daysOfWeek[fromDate.getDay()]
    return "One Time";
}

export const getRegularTopicMembers = (topicmembers: apiproto.IMember[]) => {
    const members = topicmembers.filter((m) => m.owner !== true && m.instructor !== true);
    return members;
}


export const generateSEOTitle = (title: string) => {

    if (!title) return ''

    const maxlen = 80
    var len = title.length
    var prevdash = false
    var sb = []
    var c

    for (var i = 0; i < len; ++i) {
        c = title[i]
        if ((c >= 'a' && c <= 'z') || (c >= '0' && c <= '9')) {
            sb.push(c)
            prevdash = false
        } else if (c >= 'A' && c <= 'Z') {
            sb.push(c.toLowerCase())
            prevdash = false
        } else if (c === ' ' || c === ',' || c === '.' || c === '/' ||
            c === '\\' || c === '-' || c === '_' || c === '=') {
            if (!prevdash && sb.length > 0) {
                sb.push('-')
                prevdash = true
            }
        } else if (c.charCodeAt(0) >= 128) {
            var remapped = remapInternationalCharToAscii(c)
            if (remapped) {
                sb.push(remapped)
                prevdash = false
            }
        }
        if (sb.length === maxlen) break
    }

    if (prevdash) return sb.join('').substring(0, sb.length - 1)
    else return sb.join('')
}

const remapInternationalCharToAscii = (c: string) => {
    var s = c.toLowerCase();
    if ("àåáâäãåą".indexOf(s) !== -1) {
        return "a";
    }
    else if ("èéêëę".indexOf(s) !== -1) {
        return "e";
    }
    else if ("ìíîïı".indexOf(s) !== -1) {
        return "i";
    }
    else if ("òóôõöøőð".indexOf(s) !== -1) {
        return "o";
    }
    else if ("ùúûüŭů".indexOf(s) !== -1) {
        return "u";
    }
    else if ("çćčĉ".indexOf(s) !== -1) {
        return "c";
    }
    else if ("żźž".indexOf(s) !== -1) {
        return "z";
    }
    else if ("śşšŝ".indexOf(s) !== -1) {
        return "s";
    }
    else if ("ñń".indexOf(s) !== -1) {
        return "n";
    }
    else if ("ýÿ".indexOf(s) !== -1) {
        return "y";
    }
    else if ("ğĝ".indexOf(s) !== -1) {
        return "g";
    }
    else if (c === 'ř') {
        return "r";
    }
    else if (c === 'ł') {
        return "l";
    }
    else if (c === 'đ') {
        return "d";
    }
    else if (c === 'ß') {
        return "ss";
    }
    else if (c === 'þ') {
        return "th";
    }
    else if (c === 'ĥ') {
        return "h";
    }
    else if (c === 'ĵ') {
        return "j";
    }
    else {
        return "";
    }
}


export const generateProfileURL = (firstName: string, lastName: string, userId: string) => {
    return '/publicprofile/' + generateSEOTitle(firstName + ' ' + lastName) + '/' + window.encodeURIComponent(userId)
}

export const generateTopicURL = (topic: apiproto.ITopic) => {
    return ('/topic/' + generateSEOTitle(topic.name!) + '/' + window.encodeURIComponent(topic.topicId!));
}

export const generateProfileTopicURL = (firstName: string, lastName: string, userId: string) => {

    return ('/topic/' + generateSEOTitle(firstName + ' ' + lastName) + '/' + window.encodeURIComponent("c_" + userId));
    //    return ('/topic/personal-meeting/' + window.encodeURIComponent("c_" + userId));
}

export const resizeFile = (file: Blob) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            600,
            400,
            "JPEG",
            90,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

export const roundTimeQuarterHour = (newtime: number) => {
    var timeToReturn = new Date(newtime);
    timeToReturn.setMilliseconds(0);
    timeToReturn.setSeconds(0);
    if (timeToReturn.getMinutes() > 30) {
        timeToReturn.setMinutes(0);
        timeToReturn.setHours(timeToReturn.getHours() + 1);
    }
    else {
        timeToReturn.setMinutes(30);
    }
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
    return timeToReturn;
}

export const getTimeStr = (timeToReturn: Date) => {
    let minute = "00";
    if (timeToReturn.getMinutes() > 0) {
        minute = timeToReturn.getMinutes() + "";
    }
    let hour = timeToReturn.getHours() + "";
    if (timeToReturn.getHours() < 10) {
        hour = "0" + timeToReturn.getHours();
    }
    return hour + ":" + minute;
}

export const checkIfUserEnrolledInRoom = (timeid: string | null, roomid: string, userInfo: apiproto.IUser) => {
    if (userInfo?.membersOf) {
        const members = userInfo.membersOf;

        //tdrLog("Members of " + JSON.stringify(userInfo.membersOf) + " for " + objid);
        const enrolled = members.filter((m) =>
        (m.accepted
            && (!m.owner && !m.instructor)
            && ((timeid !== null && m.obj?.time?.time?.roomId === roomid && m.obj?.time?.timeId === timeid)
                || (timeid === null && m.obj?.room?.roomId === roomid))
        ));
        //      tdrLog("Enrolled" + JSON.stringify(enrolled));
        if (enrolled && enrolled.length > 0) {
            return true;
        }
    }
    return false;
}



export const checkIfTopicProfile = (id: string | null | undefined) => {
    if (id && id !== null && id.startsWith("c_u_")) {
        return true;
    }
    return false;
}
export const checkIfTopicID = (id: string | null | undefined) => {
    if (id && id !== null && id.startsWith("c_")) {
        return true;
    }
    return false;
}

export const customDaysStr = (weekDays: boolean[]) => {
    let count = 0;
    let daysStr = "";
    for (let i = 0; i < 7; i++) {
        if (weekDays[i]) {
            count++;
            daysStr = daysStr + ", " + daysOfWeekShort[i];
        }
    }
    if (count === 0) {
        daysStr = "Custom";
    }
    else {
        daysStr = " on " + daysStr.substring(1);
    }
    return daysStr;
}

export const getAppIntegrationStatus = (userInfo: apiproto.IUser) => {
    let status: AppIntegrations = { googleMeet: false, zoom: false, googleCalendar: false, stripe: false };
    if (userInfo.paymentAccountActive) {
        status.stripe = true;
    }
    if (userInfo.integrations && userInfo.integrations !== null) {
        // Google Meet
        if (userInfo.integrations.some((e) => { return e.integrationType === apiproto.IntegrationType.GoogleCalendarAndMeet; })) {
            status.googleMeet = true;
            status.googleCalendar = true;
        }
    }
    if (userInfo.integrations && userInfo.integrations !== null) {
        // Zoom
        if (userInfo.integrations.some((e) => { return e.integrationType === apiproto.IntegrationType.Zoom; })) {
            status.zoom = true;
        }
    }
    return status;

}



export const scheduleOneTimeComputation = (topic: apiproto.ITopic, roomid: string | null, schedule: ExtendedSchedule | null, userInfo: apiproto.IUser | null) => {
    // This is for one time sessions
    const dateStrRep = 'ddd MMM Do yy';
    var finaltimes: ExtendedTime[] = [];
    topic?.rooms?.forEach((topicroom: apiproto.IRoom) => {
        // If scheduleid is null and roomid is null, then we compute all timeslots for entire topic
        // If scheduleid is null and roomid is not null, we filter by room
        // If scheduleid is not null and roomid is not null, we filter by schedule
        // If scheduleid is not null and roomid is not null, a bit redundant since scheduleid takes over
        if ((schedule === null && roomid === null) || (roomid !== null && topicroom.roomId === roomid) || (roomid === null && schedule !== null)) {
            topicroom?.scheduleSet?.schedules?.forEach((topicschedule: apiproto.ISchedule) => {
                if (schedule === null || topicschedule.scheduleId === schedule?.scheduleId)
                    topicschedule?.times?.forEach((time: apiproto.ITime) => {
                        // One Time - we just combine all the schedules 
                        var fullyBooked = false;
                        var enrolled = false;
                        if (topic.singleSession !== null) {
                            // We use the membercount in time
                            if (time.memberCount! >= topic.maxParticipants!) {
                                fullyBooked = true;
                            }
                        }
                        else {
                            if (topicroom.memberCount! >= topic.maxParticipants!) {
                                fullyBooked = true;
                            }
                        }
                        if (userInfo !== null) {
                            if (checkIfUserEnrolledInRoom(time.timeId!, topicroom.roomId!, userInfo)) {
                                enrolled = true;
                            }
                        }

                        if (time.startTime && time.endTime) {
                            const newtime: ExtendedTime = { ...time, schedule: topicschedule, fromTime: computeEpochtoTimeStr(time.startTime!.toString()), toTime: computeEpochtoTimeStr(time.endTime!.toString()), roomid: topicroom.roomId!, startDateStr: computeEpochtoPrettyDateStr(time.startTime!.toString(), dateStrRep), fullyBooked: fullyBooked, enrolled: enrolled };
                            finaltimes.push(newtime);
                        }
                    })
            })
        }
        finaltimes = finaltimes.sort((a, b) => a.startTime! > b.startTime! ? 1 : -1)
    })
    return finaltimes;
}

export const getMinTimeOfEnrolledTopic = (topic: apiproto.ITopic) => {
    const today = new Date();
    const currentTime = today.getTime();
    var result = topic?.rooms?.reduce<number>((lastMin1, topicroom) => {
        const result = topicroom?.scheduleSet?.schedules?.reduce<number>((lastMin2, topicschedule) => {
            const result = topicschedule?.times?.reduce<number>((lastMin3, theTime) => {
                if (theTime.startTime && theTime.endTime && (theTime.startTime > currentTime && theTime.endTime > currentTime)) {
                    return theTime.startTime && theTime.startTime < lastMin3 ? (theTime.startTime as number) : lastMin3;
                }
                else {
                    return lastMin3;
                }
            }, lastMin2);
            return result ? result : lastMin2;
        }, lastMin1)
        return result ? result : lastMin1;
    }, Number.MAX_SAFE_INTEGER)
    const finalResult = result ? result : Number.MAX_SAFE_INTEGER;
    return finalResult;

}



export const isSessionReady = (scheduleCard: IScheduleCard | null) => {
    if (scheduleCard === null) return false;
    const now = Date.now() as number;
    const starttime = scheduleCard.starttime as number;
    const endtime = scheduleCard.endtime as number;
    const buffertime = (2 * 60 * 60 * 1000) as number; // This allow the button to be green and pressable without confirmation which is x min before start time and x min after endtime
    const startTimeWithBuffer = +starttime - +buffertime;
    const endTimeWithBuffer = +endtime + +buffertime;
    const howlong = 60 * 15 * 1000;
    if ((startTimeWithBuffer <= now) && (now <= endTimeWithBuffer)) {
        // Allow to start 2 hours before without confirming
        return 0;
    }
    if ((endtime < now) && ((+now - +endtime) > howlong)) {
        // The end time of the session has already passed an hour ago
        return +endtime - +now;
    }
    else if ((starttime > now) && ((+starttime - +now) > howlong)) {
        // The start time is too far out in the future by more than an hour
        return +starttime - +now;
    }
    return 10;
}

export const getNumberWithSuffix = (num: number) => {
    // Get the last digit of the number
    const lastDigit = num % 10;

    // Determine the appropriate suffix based on the last digit
    let suffix;
    if (num >= 11 && num <= 13) {
        // For numbers 11-13, use "th"
        suffix = 'th';
    } else {
        // For all other numbers, use the appropriate suffix based on the last digit
        switch (lastDigit) {
            case 1:
                suffix = 'st';
                break;
            case 2:
                suffix = 'nd';
                break;
            case 3:
                suffix = 'rd';
                break;
            default:
                suffix = 'th';
                break;
        }
    }

    // Return the number with the appropriate suffix
    return num + suffix;
}

export const isAlphaNumeric = (str: string) => {
    var code, i, len;
    for (i = 0, len = str.length; i < len; i++) {
        code = str.charCodeAt(i);
        if (!(code > 47 && code < 58) && // numeric (0-9)
            !(code > 64 && code < 91) && // upper alpha (A-Z)
            !(code > 96 && code < 123)) { // lower alpha (a-z)
            return false;
        }
    }
    return true;
};