import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const MainAbout3 = () => {
    const navigate = useNavigate();

    const loginClicked = () => {
        navigate('/login?type=signup&cat=expert');
    }

    return (
        <section
            id="pricing-2"
            className="bg-whitesmoke division"
        >
            <div className="container">
                <h1 className="h1-sm text-center">
                    <b>Product Features</b>
                </h1>
                <br />
                <div className="row d-flex align-items-center">
                    {/* ABOUT TEXT */}
                    <div className="col-md-9 col-lg-8">
                        <div className="txt-block pc-25">
                            {/* Title */}
                            <div className="hpProdDesc">
                                <h4 className="h4-sm">
                                    <b>Create different session types</b>
                                </h4>
                                {/* Text */}
                                <h5 className="h5-sm">
                                    <b>Flexible scheduling</b>. Create times for one-off meetings such as a virtual consultation for advice. Create ongoing sessions that recurs on a fixed schedule such as a multi session consultation or course.
                                </h5>
                            </div>
                            <br />
                            <div className="hpProdDesc">
                                <h4 className="h4-sm">
                                    <b>Easy scheduling</b>
                                </h4>
                                {/* Text */}
                                <h5 className="h5-sm">
                                    Make it easy for your clients to book date time slots that you set aside. No more back and forth over email or messages to figure out dates and time.
                                </h5>
                            </div>
                            <br />
                            <div className="hpProdDesc">
                                <h4 className="h4-sm">
                                    <b>Convenient Billing</b>
                                </h4>
                                {/* Text */}
                                <h5 className="h5-sm">
                                    Clients can book time slots with you and pay with it using their credit card and other payment methods. No more hassle collecting payments.
                                </h5>
                            </div>
                            <br />
                            <div className="hpProdDesc">
                                <h4 className="h4-sm">
                                    <b>Integrated Video Conferencing</b>
                                </h4>
                                {/* Text */}
                                <h5 className="h5-sm">
                                    Meet with your clients easily with integrated video conferencing. No more searching for Meet or Zoom links. You and your clients can join the right rooms at the right time.
                                </h5>
                            </div>

                        </div>
                    </div>
                    {/* END ABOUT TEXT */}
                    {/* ABOUT IMAGE */}
                    <div className="col-md-3 col-lg-4">
                        <div className="img-block">
                            <img
                                className="img-fluid"
                                src="images/image-02.png"
                                alt="about-image"
                            />

                        </div>
                    </div>
                </div>
                {/* End row */}
                <div className="row">
                    <div className="col text-center">
                        <br />
                        <Button size="large" variant="contained" onClick={loginClicked} sx={{
                            ':hover': {
                                bgcolor: '#ff1f59', // theme.palette.primary.main
                                color: 'white',
                            }, width: 300, height: 60, fontSize: '23px', backgroundColor: 'primary.main', textTransform: 'none'
                        }}>
                            Sign Up As Expert
                        </Button>
                    </div>
                </div>
                <br />

            </div>
            <br />
        </section>
    );
};

export default MainAbout3;
