import { Link } from "react-router-dom";
const Footer2 = () => {
  return (
    <footer id="footer-1" className=" division">
      <div className="container">
        <hr /><br />
        {/* FOOTER CONTENT */}
        <div className="row">
          {/* FOOTER INFO */}
          <div className="col-md-12 col-xl-3">
            <div className="footer-info mb-20">
              {/* Footer Logo */}
              {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 344 x 80 pixels) */}
              <img
                src="/images/timedoralogo.jpg"
                width={172}
                height={40}
                alt="footer-logo"
              />
            </div>
          </div>
          {/* FOOTER PRODUCTS LINKS */}
          <div className="col-md-4 col-xl-3">
            <div className="footer-links mb-40">
              {/* Footer Links */}
              <ul className="foo-links clearfix">
                <li>
                  <b>Company</b>
                </li>
                <li>
                  <Link to="/about">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/contacts">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* FOOTER COMPANY LINKS */}
          <div className="col-md-4 col-xl-3">
            <div className="footer-links mb-40">
              {/* Footer Links */}
              <ul className="clearfix">
                <li>
                  <b>Resources</b>
                </li>
                <li>
                  <Link to="/faqs">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/pricing">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to="/terms">
                    Terms of Use
                  </Link>
                </li>
                <li>
                  <Link to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-xl-3">
            <div className="footer-links mb-40">
              {/* Footer Links */}
              <ul className="clearfix">
                <li>
                  <b>Useful Resources</b>
                </li>
                <li>
                  <a href="https://www.windybot.com">
                    Slack GPT Bot
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
        {/* END FOOTER CONTENT */}
        {/* BOTTOM FOOTER */}
        <div className="bottom-footer">
          <div className="row">
            {/* FOOTER COPYRIGHT */}
            <div className="col-lg-8">
              <ul className="bottom-footer-list">
                <li>
                  <p>© Copyright Timedora, Inc. </p>
                </li>
                <li>
                  <p>{new Date().getFullYear()}</p>
                </li>
              </ul>
            </div>
            {/* FOOTER SOCIALS LINKS */}
            <div className="col-lg-4 text-left divHide">
              <ul className="foo-socials text-center clearfix">
                <li>
                  <Link to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="ico-facebook"
                  >
                    <i className="fab fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="ico-twitter"
                  >
                    <i className="fab fa-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="ico-google-plus"
                  >
                    <i className="fab fa-google-plus-g" />
                  </Link>
                </li>
                <li>
                  <Link to="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="ico-tumblr"
                  >
                    <i className="fab fa-tumblr" />
                  </Link>
                </li>
                {/*
                                  <li><a href="#" class="ico-behance"><i class="fab fa-behance"></i></a></li>	
                                  <li><a href="#" class="ico-dribbble"><i class="fab fa-dribbble"></i></a></li>									
                                  <li><a href="#" class="ico-instagram"><i class="fab fa-instagram"></i></a></li>	
                                  <li><a href="#" class="ico-linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                                  <li><a href="#" class="ico-pinterest"><i class="fab fa-pinterest-p"></i></a></li>								
                                  <li><a href="#" class="ico-youtube"><i class="fab fa-youtube"></i></a></li>										
                                  <li><a href="#" class="ico-vk"><i class="fab fa-vk"></i></a></li>
                                  <li><a href="#" class="ico-yelp"><i class="fab fa-yelp"></i></a></li>
                                  <li><a href="#" class="ico-yahoo"><i class="fab fa-yahoo"></i></a></li>
                                  */}
              </ul>
            </div>
          </div>
        </div>
        {/* END BOTTOM FOOTER */}
      </div>
      {/* End container */}
    </footer>
  );
};

export default Footer2;
